import { QueryChip } from 'components/ContentPanels/components/QueryChip'
import { SuggestedStatusSearches } from 'components/ContentPanels/components/SuggestedStatusSearches'
import { StatusesSearchResults } from 'components/CuratedFolderView/Statuses'
import HorizontalList from 'components/HorizontalList'
import { StatusIdea, WithIntl } from 'interfaces'
import * as React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { tap } from 'rxjs/operators/tap'
import { Subject } from 'rxjs/Subject'
import { searchStatusIdeas } from 'services/content/statuses/actions'
import { message } from 'services/snackbar'
import { StoreThunkDispatch } from 'store/state'
import { searchRequest, updateState } from '../state/actions'
import { SearchContext } from '../state/context'
import { checkFeatureAvailability } from 'services/product'
import styles from './SearchRoot.pcss'
import { NavLink, useNavigate } from 'react-router-dom'
import { randomize } from 'shared/utils'
import { FEATURE_CONTENT_ON_DEMAND } from 'shared/constants'
import { resetScroll } from 'utils/dom'
import { noop } from 'utils/noop'

export function SearchStatusIdeasRoute(props: WithIntl) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const navigate = useNavigate()
  const [{ query, postIdeasTags }, searchDispatch] = React.useContext(SearchContext)
  const [items, setItems] = React.useState<StatusIdea[]>([])
  const [loading, setLoading] = React.useState(false)
  const [hasNextPage, setHasNextPage] = React.useState(true)
  const [selectedTag, setSelectedTag] = React.useState('')
  const page = React.useRef(0)

  React.useEffect(() => {
    resetScroll()
  }, [])

  React.useEffect(() => {
    dispatch(checkFeatureAvailability(
      FEATURE_CONTENT_ON_DEMAND,
      false,
      () => navigate({ pathname: `/content/search/sources/streams?q=${query}` })
    ))
  }, [dispatch, navigate, query])

  React.useEffect(() => {
    searchDispatch(updateState({ activeFilter: 'ideas', activeCategory: 'content' }))
  }, [searchDispatch])

  const search$ = React.useRef<Subject<{ q: string, tag?: string }>>()

  React.useEffect(() => {
    search$.current = new Subject()
    search$.current
      .filter(data => data.q.length > 0)
      .pipe(tap(() => {
        setLoading(true)
      }))
      .flatMap(({ q, tag }) => {
        return dispatch(searchStatusIdeas(q, page.current, tag))
          .map(response => ({ items: response, isTag: !q && Boolean(tag) }))
      })
      .subscribe((response: { items: StatusIdea[], isTag: boolean }) => {
        const newItems = response.isTag ? randomize(response.items) : response.items
        setItems(current => [...current, ...newItems])
        setLoading(false)
        setHasNextPage(response.items.length > 0)
      }, () => {
        setLoading(false)
        dispatch(message(props.intl.formatMessage({ id: 'errors.generic' }), 'error'))
      })

    return () => search$.current?.unsubscribe()
  }, [dispatch, props.intl])

  React.useEffect(() => {
    page.current = 0
    setItems([])
    search$.current?.next({ q: query, tag: selectedTag })
  }, [query, selectedTag])

  React.useEffect(() => {
    if (!query && !selectedTag && postIdeasTags.length > 0) {
      setSelectedTag(postIdeasTags[0])
    }
  }, [query, postIdeasTags, selectedTag])

  const loadNextPage = React.useCallback(() => {
    if (hasNextPage) {
      page.current += 1
      search$.current?.next({ q: query, tag: selectedTag })
    }
  }, [hasNextPage, query, selectedTag])

  const onTagClick = (tag: string) => {
    setSelectedTag(current => tag === current ? '' : tag)
  }

  const onCompose = React.useCallback(() => {
    return !dispatch(checkFeatureAvailability(FEATURE_CONTENT_ON_DEMAND))
  }, [dispatch])

  return (
    <React.Fragment>
      <header className={styles.header}>
        <h2 className={`text-ellipsis ${styles.title}`}>
          <FormattedMessage id="search.navigation.ideas" />
        </h2>
        <NavLink to={`/content/search?q=${query}`}>
          <FormattedMessage id="general.carousel.nav.back" />
        </NavLink>
      </header>
      {postIdeasTags.length > 0 && (
        <HorizontalList className={styles['tags-list']} listHeight={40}>
          {postIdeasTags.map(tag => (
            <QueryChip
              key={tag}
              label={tag}
              isTag
              className={styles['tag-chip']}
              active={selectedTag === tag}
              onClick={onTagClick}
            />
          ))}
        </HorizontalList>
      )}
      {postIdeasTags.length > 0 && (
        <div className={styles['quotes-container']}>
          <SuggestedStatusSearches
            hideSavedSearches
            hideCategories
            activeQuery={query}
            className={styles['text-chip-box']}
            onQueryClick={noop}
          />
          <StatusesSearchResults
            search={{
              active: true,
              loading,
              page: page.current,
              query,
              hasNextPage
            }}
            items={items}
            align="left"
            onLoadMore={loadNextPage}
            onCompose={onCompose}
          />
        </div>
      )}
    </React.Fragment>
  )
}

export default injectIntl(SearchStatusIdeasRoute)
