import * as React from 'react'
import {
  FEED_TYPE_KEYWORD,
  FEED_TYPE_STATUS_IDEAS,
  BrandifyBrand,
  BRAND_FACEBOOK,
  BRAND_TWITTER,
  BRAND_LINKEDIN,
  BRAND_INSTAGRAM,
  BRAND_REDDIT,
  FEED_TYPE_RSS,
  BRAND_PINTEREST
} from 'interfaces'
import { BRAND_TIKTOK, BRAND_GOOGLE, BRAND_YOUTUBE } from 'interfaces/Content/SocialBrand'
import Icon from '@mdi/react'
import {
  mdiFacebook,
  mdiTwitter,
  mdiLinkedin,
  mdiInstagram,
  mdiReddit,
  mdiRss,
  mdiCommentTextOutline,
  mdiPinterest,
  mdiGoogle,
  mdiYoutube
} from '@mdi/js'

import styles from './SocialIcon.pcss'
import { TikTokIcon, XIcon } from 'components/Icons'
const googleBusinessIcon = require('static/img/icons/google-business-profile.png')

export interface SocialIconProps {
  icon: BrandifyBrand | typeof FEED_TYPE_KEYWORD | typeof FEED_TYPE_STATUS_IDEAS | 'unsupported'
  outline?: boolean
  size?: string
  className?: string
  circle?: boolean
  badge?: boolean
  iconClassName?: string
}

export function SocialIcon(props: SocialIconProps) {
  const boxClassName = `${styles['icon-box']} ${props.outline ? styles.outline : ''} ${props.className || ''} ${styles[props.icon]}`
  const iconClassName = `${styles.icon} ${props.iconClassName || ''}`
  const boxStyle = props.size ? {
    width: props.size,
    height: props.size
  } : undefined

  switch (props.icon) {
    case BRAND_FACEBOOK:
      return (
        <span className={boxClassName} style={boxStyle} data-icon={BRAND_FACEBOOK}>
          <Icon
            path={mdiFacebook}
            className={iconClassName}
          />
        </span>
      )
    case BRAND_TWITTER:
      return (
        <span className={boxClassName} style={boxStyle} data-icon={BRAND_TWITTER}>
          <XIcon size={props.size} className={iconClassName} />
        </span>
      )
    case BRAND_REDDIT:
      return (
        <span className={boxClassName} style={boxStyle} data-icon={BRAND_REDDIT}>
          <Icon
            path={mdiReddit}
            className={iconClassName}
          />
        </span>
      )
    case BRAND_LINKEDIN:
      return (
        <span className={boxClassName} style={boxStyle} data-icon={BRAND_LINKEDIN}>
          {props.circle && <span className={styles['linkedin-bg']}></span>}
          <Icon
            path={mdiLinkedin}
            className={iconClassName}
          />
        </span>
      )
    case BRAND_INSTAGRAM:
      return (
        <span className={boxClassName} style={boxStyle} data-icon={BRAND_INSTAGRAM}>
          <Icon
            path={mdiInstagram}
            className={iconClassName}
          />
        </span>
      )
    case BRAND_TIKTOK:
      return (
        <span className={boxClassName} style={boxStyle} data-icon={BRAND_TIKTOK}>
          <TikTokIcon className={iconClassName} size={props.size} dark={!props.outline} />
        </span>
      )
    case FEED_TYPE_KEYWORD:
      return (
        <span className={boxClassName} style={boxStyle} data-icon={BRAND_GOOGLE}>
          <Icon
            path={mdiGoogle}
            className={iconClassName}
          />
        </span>
      )
    case BRAND_GOOGLE:
      return (
        <span className={`${boxClassName} ${styles.badge}`} style={boxStyle} data-icon={BRAND_GOOGLE}>
          {props.badge || props.outline ? (
            <Icon
              path={mdiGoogle}
              className={iconClassName}
            />
          ) : (
            <img src={googleBusinessIcon} />
          )}
        </span>
      )
      // do a case for youtube
    case BRAND_YOUTUBE:
      return (
        <span className={`${boxClassName} ${styles.badge}`} style={boxStyle} data-icon={BRAND_YOUTUBE}>
          <Icon path={mdiYoutube} size={props.size} className={iconClassName} />
          {/* {props.badge || props.outline ? (
            <Icon
              path={mdiGoogle}
              className={iconClassName}
            />
          ) : (
            <img src={googleBusinessIcon} />
          )} */}
        </span>
      )

    case FEED_TYPE_RSS:
      return (
        <span className={`${boxClassName} ${styles['rss-box']}`} style={boxStyle} data-icon={FEED_TYPE_RSS}>
          <Icon
            path={mdiRss}
            className={iconClassName}
          />
        </span>
      )
    case FEED_TYPE_STATUS_IDEAS:
      return (
        <span className={boxClassName} style={boxStyle} data-icon={FEED_TYPE_STATUS_IDEAS}>
          <Icon
            path={mdiCommentTextOutline}
            className={iconClassName}
          />
        </span>
      )
    case BRAND_PINTEREST:
      return (
        <span className={boxClassName} style={boxStyle} data-icon={BRAND_PINTEREST}>
          <Icon
            path={mdiPinterest}
            className={iconClassName}
          />
        </span>
      )
    default:
      return null
  }
}

export default SocialIcon
