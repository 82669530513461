import * as React from 'react'
import {
  BRAND_FACEBOOK,
  BRAND_INSTAGRAM,
  BRAND_LINKEDIN,
  BRAND_PINTEREST,
  BRAND_TWITTER,
  IndexedObject,
  PinterestBoard,
  PostDestination,
  PostDestinationType,
  VIDEO_TYPE
} from 'interfaces'
import { BRAND_GOOGLE } from 'interfaces/Content/SocialBrand'
import { BulkContentPost, FB_POST_TYPE_REEL, POST_TYPE_STORY } from 'services/compose/state'
import styles from './BulkPost.pcss'
import TextEditor from 'components/TextEditor'
import { useDispatch, useSelector } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { fbLiveSearch, linkedInLiveSearch } from 'services/search/actions'
import { RecycleSelector } from 'components/Posts/components/RecycleSelector'
import IconButton from '@mui/material/IconButton'
import IconArrow from '@mui/icons-material/ArrowUpward'
import DeleteIcon from '@mui/icons-material/Close'
import IconDragHandle from '@mui/icons-material/DragIndicator'
import Checkbox from '@mui/material/Checkbox'
import {
  ComposerFacebookPost,
  ComposerInstagramPost,
  ComposerPinterestPost,
  ComposerTwitterPost,
  LocationInfo,
  TweetType
} from 'services/compose/interfaces'
import { FBAlbum } from 'services/compose/interfaces/ComposerFacebookPost'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import SocialIcon from 'components/SocialIcon'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import {
  BRAND_TIKTOK,
  BRAND_YOUTUBE,
  PINTEREST_DESCRIPTION_MAX_LENGTH,
  PINTEREST_TITLE_MAX_LENGTH,
  STOCK_VIDEO_TYPE
} from 'shared/constants'
import { ComposerTwitterPostOptions } from '../../CustomizePostBlock/ComposerTwitterPostOptions'
import InstagramLocationPicker from './InstagramLocationPicker'
import FacebookAlbumsPicker from './FacebookAlbumsPicker'
import Icon from '@mdi/react'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import { mdiShareVariant } from '@mdi/js'
import ComposerGoogleOptions from 'components/Composer/CustomizePostBlock/ComposerGoogleOptions'
import { ComposerGooglePost, GooglePostButtonType } from 'services/compose/interfaces/ComposerGooglePost'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { PlayIcon } from 'components/Icons'
import { NativeVideoPlayer } from 'components/VideoPlayer'
import { BulkPostYoutubeStatus } from './BulkPostYoutubeStatus'
import { ComposerYoutubePost } from 'services/compose/interfaces/ComposerYoutubePost'
import { ComposerTikTokPostOptions } from 'components/Composer/CustomizePostBlock/TikTokOptions'
import { ComposerTikTokPost } from 'services/compose/interfaces/ComposerTikTokPost'
import { TikTokPostProfileSettings, PrivacyStatus, TikTokPrivacyOptions } from 'shared'
import { getLinkDetails, getSelectedProfiles, updateArticlePost } from 'services/compose'
import FirstCommentTextBox from 'components/Composer/Network/FirstCommentTextBox'
import { PinterestBoardSelector } from 'components/Composer/AlbumSelector'
import { connectedDestinationsSelector } from 'services/destinations'
import PPSwitch from 'components/PPSwitch'

interface BulkPostProps {
  post: BulkContentPost
  profiles: PostDestination[]
  selected: boolean
  boards: IndexedObject<Array<{
    id: string
    name: string
    url?: string
    selected?: boolean
  }>>
  allowStatusOverride: boolean
  tabIndex?: number
  tiktokData?: Record<string, { settings: TikTokPostProfileSettings, options: TikTokPrivacyOptions }>
  dndAttributes: any
  dndListeners: any
  onMoveToTop: (id: string) => void
  onMoveToBottom: (id: string) => void
  onRemove: (id: string) => void
  onToggleSelected: (id: string) => void
  onSelectedFBAlbumsChange: (postId: string, albums: IndexedObject<FBAlbum | undefined>, applyToAll: boolean) => void
  onSelectedPIBoardsChange: (postId: string, selection: IndexedObject<PinterestBoard>, applyToAll?: boolean) => void
  onSelectedIGLocationsChange: (postId: string, locations: IndexedObject<LocationInfo | undefined>, applyToAll: boolean) => void
  onStatusChange: (postId: string, text: string, html: string, network: PostDestinationType) => void
  onRecycleChange: (postId: string, value: number | boolean | Date, network: PostDestinationType, applyToAll?: boolean) => void
  onPinChange: (postId: string, data: { title?: string, description?: string, destinationUrl?: string, applyToAll?: boolean }) => void
  onUpdateFBReshare: (postId: string, value: boolean) => void
  onTweetTypeChange: (postId: string, type: TweetType) => void
  onGoogleButtonTypeChange: (postId: string, type?: GooglePostButtonType) => void
  onGooglePostLinkChange: (postId: string, url?: string) => void
  onToggleFBReshareAll: (value: boolean) => void
  onApplyTweetTypeToAll: (type: TweetType) => void
  onYtStatusChange: (postId: string, data: { title: string, description: string }) => void
  onTikTokSettingsChange: (postId: string, settings: TikTokPostProfileSettings) => void
  onYoutubePrivacyChange: (postId: string, privacy: PrivacyStatus) => void
  onPostTypeChange: (postId: string, network: PostDestinationType, postType: string) => void
}

export const BulkPost = React.memo((props: BulkPostProps) => {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const intl = useIntl()
  const { post, selected, profiles, dndAttributes, dndListeners, onTikTokSettingsChange } = props
  const { data, article, id, imageUrl, recycle, network, content } = post
  const isArticle = Boolean(article)
  const isImage = Boolean(imageUrl)
  const isVideo = content?.type === VIDEO_TYPE || content?.type === STOCK_VIDEO_TYPE
  const pin = network === BRAND_PINTEREST ? { ...data } as ComposerPinterestPost : null
  const ytPost = network === BRAND_YOUTUBE ? { ...data } as ComposerYoutubePost : null

  React.useEffect(() => {
    if (article && (!article.imageUrl || article.description?.startsWith('<div'))) {
      dispatch(getLinkDetails({ url: article.url })).unwrap().then((response: any) => {
        const link = response.link
        const image = link.image ? link.image[0] : null
        if (image) {
          dispatch(updateArticlePost({ id: post.id, article: { imageUrl: image, description: link.description || article.description } }))
        }
      })
    }
  }, [article, dispatch, post.id])

  if (pin) {
    pin.title ??= ''
    pin.description = pin.description || ''
    pin.destinationUrl ??= ''
  }
  if (ytPost) {
    ytPost.title ??= ''
    ytPost.description = ytPost.description || ''
  }

  const searchFacebookTags = (query: string) => dispatch(fbLiveSearch(query))
  const searchLinkedinTags = (query: string) => dispatch(linkedInLiveSearch(query))

  let tagSearchFn
  switch (network) {
    case BRAND_FACEBOOK:
      tagSearchFn = searchFacebookTags
      break
    case BRAND_LINKEDIN:
      tagSearchFn = searchLinkedinTags
      break
    default:
      tagSearchFn = undefined
  }

  const moveToTop = () => {
    props.onMoveToTop(id)
  }

  const moveToBottom = () => {
    props.onMoveToBottom(id)
  }

  const remove = () => {
    props.onRemove(id)
  }

  const toggleSelected = () => {
    props.onToggleSelected(id)
  }

  const onStatusChange = (text: string, html: string) => {
    props.onStatusChange(id, text, html, network)
  }

  const onInstagramLocationsChange = (locations: IndexedObject<LocationInfo | undefined>, applyToAll: boolean) => {
    props.onSelectedIGLocationsChange(id, locations, applyToAll)
  }

  const onSelectedPIBoardChange = (ppid: string, board: PinterestBoard) => {
    props.onSelectedPIBoardsChange(post.id, { [ppid]: board })
  }

  const onSelectedFBAlbumsChange = (albums: IndexedObject<FBAlbum | undefined>, applyToAll: boolean) => {
    props.onSelectedFBAlbumsChange(id, albums, applyToAll)
  }

  const onRecycleChange = (value: number | boolean | Date) => {
    props.onRecycleChange(id, value, network)
  }

  const recycleAll = (value: number | boolean | Date) => {
    props.onRecycleChange(id, value, network, true)
  }

  const boards = Object.keys(props.boards).reduce((map: IndexedObject<any>, ppid) => {
    const selectedBoard = (data as ComposerPinterestPost).boards?.find(b => b.ppid === ppid)
    map[ppid] = props.boards[ppid].map(b => ({ ...b, selected: selectedBoard?.id === b.id }))
    return map
  }, {})

  const articlePreviewStyles = {
    backgroundImage: `url(${article?.imageUrl})`
  }

  const onPinTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onPinChange(post.id, { ...pin, title: e.target.value })
  }

  const onPinDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onPinChange(post.id, { ...pin, description: e.target.value })
  }

  const onPinLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onPinChange(post.id, { ...pin, destinationUrl: e.target.value })
  }

  const onYtDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onPinChange(post.id, { ...ytPost, description: e.target.value })
  }

  const onYtTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onPinChange(post.id, { ...ytPost, title: e.target.value })
  }

  const applyPinTitleToAll = () => {
    props.onPinChange(post.id, { title: pin!.title, applyToAll: true })
  }

  const applyPinUrlToAll = () => {
    props.onPinChange(post.id, { destinationUrl: pin!.destinationUrl, applyToAll: true })
  }

  const toggleFBReshare = (value: 'reshare' | 'new') => {
    const current = (post.data as ComposerFacebookPost).reshare
    if (current && value === 'new' || !current && value === 'reshare') {
      props.onUpdateFBReshare(post.id, !current)
    }
  }

  const onTweetTypeChange = (type: TweetType) => {
    props.onTweetTypeChange(post.id, type)
  }

  const selectedFBAlbums = network === BRAND_FACEBOOK
    ? (data as ComposerFacebookPost).albums.reduce((map: any, album) => {
      map[album.ppid] = album
      return map
    }, {})
    : {}

  const isRetweet = network === BRAND_TWITTER && (post.data as ComposerTwitterPost).tweetType === 'retweet'
  const reshareOptions: PPSelectOptions = {
    new: { label: <FormattedMessage id="composer.labels.photos.new-post" /> },
    reshare: { label: <FormattedMessage id="composer.labels.photos.reshare" /> }
  }

  const applyFBReshareToAll = () => {
    const value = Boolean((data as ComposerFacebookPost).reshare)
    props.onToggleFBReshareAll(value)
  }

  const onGoogleButtonTypeChange = (type?: GooglePostButtonType) => {
    props.onGoogleButtonTypeChange(post.id, type)
  }

  const onGooglePostLinkChange = (url: string) => {
    props.onGooglePostLinkChange(post.id, url)
  }

  const handleTikTokSettingsChange = React.useCallback((s: TikTokPostProfileSettings) => {
    onTikTokSettingsChange(post.id, s)
  }, [onTikTokSettingsChange, post.id])

  const onYTPrivacyStatusChange = (value: string) => {
    props.onYoutubePrivacyChange(post.id, value as PrivacyStatus)
  }

  const onIGPostTypeChange = (value: string) => {
    props.onPostTypeChange(post.id, BRAND_INSTAGRAM, value)
  }

  const onFBPostTypeChange = (value: string) => {
    props.onPostTypeChange(post.id, BRAND_FACEBOOK, value)
  }

  const upload = post.imageUpload
  const isUploading = upload && !upload.error && !upload.url
  const imageSrc = imageUrl || upload?.src || content?.imageUrl
  const ytPrivacySelectOptions: PPSelectOptions = {
    public: { label: <FormattedMessage id="composer.labels.youtube.switch-privacy.public" /> },
    private: { label: <FormattedMessage id="composer.labels.youtube.switch-privacy.private" /> },
    unlisted: { label: <FormattedMessage id="composer.labels.youtube.switch-privacy.unlisted" /> }
  }

  const withFirstComment = [BRAND_FACEBOOK, BRAND_INSTAGRAM, BRAND_YOUTUBE, BRAND_LINKEDIN].includes(network)
  const pinterestBoardsLoaded = profiles.every(p => props.boards[p.ppid] && props.boards[p.ppid].length > 0)

  const fbPostTypeOptions = React.useMemo(() => {
    const options = [{
      value: 'post',
      label: 'Post'
    }]
    if (post.videoUrl) {
      options.push({
        value: FB_POST_TYPE_REEL,
        label: 'Reel'
      }, {
        value: POST_TYPE_STORY,
        label: 'Story'
      })
    } else if (post.imageUrl) {
      options.push({
        value: POST_TYPE_STORY,
        label: 'Story'
      })
    }
    return options
  }, [post.videoUrl, post.imageUrl])

  return (
    <React.Fragment>
      <div className={styles['profile-box']}>
        <SocialIcon icon={network} size="32px" />
        <Tooltip
          classes={{ tooltip: styles['tooltip-box'] }}
          title={<div className={styles.tooltip}>{profiles.map(p => p.name).join('\n')}</div>}
        >
          <span className={styles['profiles-label']}>
            <FormattedMessage id="general.profile-selector.multi-profile-selection-label" values={{ count: profiles.length }} />
          </span>
        </Tooltip>
      </div>
      <div className={styles['media-box']}>
        {isArticle && (
          <div className={styles['article-preview']} style={articlePreviewStyles}>
            <div className={styles.heading}>
              <h4>
                <a href={article?.url} target="_blank" rel="noopener">
                  {article?.title}
                </a>
              </h4>
            </div>
          </div>
        )}
        {imageSrc && <img src={imageSrc} className={styles.img} />}
        {isUploading && (
          <div className={styles['loading-overlay']}>
            <CircularProgress size={20} className={styles.loader} />
          </div>
        )}
        {isVideo && (
          <PlayIcon className={styles['icon-play']} />
        )}
        {post.videoUrl && (
          <NativeVideoPlayer videoUrl={post.videoUrl} playBtnClassName={styles['btn-play']} />
        )}
      </div>
      <div className={styles['status-box']}>
        {pin && (
          <div className={styles.pin}>
            <div className={styles['pin-input-row']}>
              <TextField
                value={pin.title}
                placeholder={intl.formatMessage({ id: 'composer.labels.pinterest-title-placeholder' })}
                classes={{ root: styles['title-box'] }}
                FormHelperTextProps={{ className: styles.helper }}
                helperText={(PINTEREST_TITLE_MAX_LENGTH - pin.title.trim().length).toString()}
                onChange={onPinTitleChange}
              />
              <span role="button" className={styles['txt-btn']} onClick={applyPinTitleToAll}>
                <FormattedMessage id="actions.copy-to-all" />
              </span>
            </div>
            <div className={styles['pin-input-row']}>
              <TextField
                value={pin.description}
                multiline
                placeholder={intl.formatMessage({ id: 'composer.labels.pinterest-text-placeholder' })}
                className={styles['description-box']}
                InputProps={{
                  classes: {
                    root: styles['description-input']
                  }
                }}
                FormHelperTextProps={{ className: styles.helper }}
                helperText={(PINTEREST_DESCRIPTION_MAX_LENGTH - pin.description.trim().length).toString()}
                onChange={onPinDescriptionChange}
              />
              <span role="button" className={styles['txt-btn']}></span>
            </div>
            <div className={styles['pin-input-row']}>
              <TextField
                value={pin.destinationUrl}
                placeholder={intl.formatMessage({ id: 'composer.labels.pinterest-link-placeholder' })}
                className={styles['link-box']}
                FormHelperTextProps={{ className: styles.helper }}
                InputProps={{
                  classes: {
                    root: styles['link-input']
                  }
                }}
                onChange={onPinLinkChange}
              />
              <span role="button" className={styles['txt-btn']} onClick={applyPinUrlToAll}>
                <FormattedMessage id="actions.copy-to-all" />
              </span>
            </div>
          </div>
        )}
        {ytPost && (
          <div className={styles.pin}>
            <BulkPostYoutubeStatus
              post={ytPost}
              onTitleChange={onYtTitleChange}
              onDescriptionChange={onYtDescriptionChange}
            />
          </div>
        )}
        {!pin && network !== BRAND_YOUTUBE && (
          <div style={{ position: 'relative' }}>
            <TextEditor
              network={network}
              placeholder={intl.formatMessage({ id: 'composer.multi-post.placeholders.status' })}
              html={props.allowStatusOverride ? data.status.html : undefined}
              initialHTML={data.status.html}
              inputClassName={`${styles['text-editor']} ${isRetweet ? styles.hidden : ''}`}
              containerClassName={styles['text-editor-container']}
              withEmojis
              footerClassName={styles['text-editor-footer']}
              tabIndex={props.tabIndex}
              disabled={isRetweet}
              onChange={onStatusChange}
              searchTags={tagSearchFn}
            />
          </div>
        )}
        {withFirstComment && (
          <FirstCommentTextBox
            network={network as any}
            initialValue={(data as ComposerFacebookPost).firstComment}
            multiPostId={id}
          />
        )}
      </div>
      <div className={styles['options-box']}>
        {network === BRAND_FACEBOOK && post.content?.feed?.type === BRAND_FACEBOOK && (
          <PPSelect
            name="Post as"
            icon={<Icon path={mdiShareVariant} size="16px" />}
            options={reshareOptions}
            selectedValue={(data as ComposerFacebookPost).reshare ? 'reshare' : 'new'}
            className={styles.option}
            withCaret
            footerElement={(
              <Button className={styles['btn-dd-reshare']} onClick={applyFBReshareToAll}>
                <FormattedMessage id="general.labels.apply-to-all" />
              </Button>
            )}
            onSelectionChange={toggleFBReshare}
          />
        )}
        {network === BRAND_FACEBOOK && (
          <React.Fragment>
            <div className={styles.option}>
              <FacebookAlbumsPicker
                profiles={profiles}
                post={{
                  ...data,
                  firstComment: '',
                  albums: (data as ComposerFacebookPost).albums || [],
                  reshare: (data as ComposerFacebookPost).reshare,
                  targeting: {} as any
                }}
                selectedAlbums={selectedFBAlbums}
                onSelectedAlbumsChange={onSelectedFBAlbumsChange}
              />
            </div>
            <div className={styles.option}>
              {fbPostTypeOptions.length > 1 && (
                <div className={styles['option-box']}>
                  <PPSwitch
                    selectedValue={(data as ComposerFacebookPost).postType || 'post'}
                    options={fbPostTypeOptions}
                    onSelectedValueChange={onFBPostTypeChange}
                  />
                </div>
              )}
            </div>
          </React.Fragment>
        )}
        {network === BRAND_PINTEREST && pinterestBoardsLoaded && profiles.map(profile => (
          <PinterestBoardSelector
            key={profile.ppid}
            profile={profile}
            boards={boards[profile.ppid]}
            raised
            className={styles.boardpicker}
            onSelectedBoardChange={onSelectedPIBoardChange}
          />
        ))}
        {network === BRAND_INSTAGRAM && (
          <React.Fragment>
            {isImage && (
              <InstagramLocationPicker
                profiles={profiles}
                className={styles.option}
                selectedLocations={(data as ComposerInstagramPost).locations}
                onSelectedLocationsChange={onInstagramLocationsChange}
              />
            )}
            <div className={styles.option}>
              <PPSwitch
                selectedValue={(data as ComposerInstagramPost).postType || 'post'}
                options={[{
                  value: 'post',
                  label: post.videoUrl ? 'Post/Reel' : 'Post'
                }, {
                  value: POST_TYPE_STORY,
                  label: 'Story'
                }]}
                onSelectedValueChange={onIGPostTypeChange}
              />
            </div>
          </React.Fragment>
        )}
        {network === BRAND_TWITTER && props.post.content?.feed?.type === BRAND_TWITTER && (
          <ComposerTwitterPostOptions
            post={data as ComposerTwitterPost}
            content={props.post.content}
            className={styles.option}
            onApplyToAll={props.onApplyTweetTypeToAll}
            onSelectionChange={onTweetTypeChange}
          />
        )}
        {network === BRAND_GOOGLE && (
          <ComposerGoogleOptions
            post={{
              link: (props.post.data as ComposerGooglePost).link,
              buttonType: (props.post.data as ComposerGooglePost).buttonType
            }}
            align="right"
            onButtonTypeChange={onGoogleButtonTypeChange}
            onLinkChange={onGooglePostLinkChange}
            children={
              <RecycleSelector
                value={recycle}
                horizontal
                onApplyToAll={recycleAll}
                onChange={onRecycleChange}
              />
            }
          />
        )}
        {props.post.network !== BRAND_GOOGLE && (
          <div className={styles.option}>
            <RecycleSelector
              value={recycle}
              horizontal
              onApplyToAll={recycleAll}
              onChange={onRecycleChange}
            />
          </div>
        )}
        {post.network === BRAND_TIKTOK && (
          <div className={styles.option}>
            <ComposerTikTokPostOptions
              popupMode
              singleAction
              actionsClassName={styles['tk-actions']}
              settings={(data as ComposerTikTokPost).settings}
              options={props.tiktokData ? Object.keys(props.tiktokData).reduce((acc, ppid) => {
                acc[ppid] = props.tiktokData![ppid].options
                return acc
              }, {} as Record<string, TikTokPrivacyOptions>) : {}}
              skipFetchSettings
              onChange={handleTikTokSettingsChange}
            />
          </div>
        )}
        {post.network === BRAND_YOUTUBE && (
          <PPSelect
            name={<FormattedMessage id="composer.labels.youtube-privacystatus-placeholder" />}
            options={ytPrivacySelectOptions}
            selectedValue={(data as ComposerYoutubePost).privacyStatus}
            withCaret
            className={styles.option}
            onSelectionChange={onYTPrivacyStatusChange}
          />
        )}
      </div>
      <div className={styles['actions-box']}>
        <div className={styles['actions-left']}>
          <IconButton size="small" onClick={moveToTop}>
            <IconArrow />
          </IconButton>
          <IconButton size="small" onClick={moveToBottom}>
            <IconArrow className={styles.flip} />
          </IconButton>
        </div>
        <div className={styles['drag-handle']} {...dndListeners} {...dndAttributes}>
          <IconDragHandle className={styles['icon-drag']} />
        </div>
        <div className={styles['actions-right']}>
          <IconButton size="small" onClick={remove}>
            <DeleteIcon />
          </IconButton>
          <Checkbox
            checked={selected}
            color="primary"
            onChange={toggleSelected}
          />
        </div>
      </div>
      {
        post.error && (
          <div className={styles['error-box']}>
            {post.error.message}
          </div>
        )
      }
    </React.Fragment>
  )
})

export const SortableBulkPost = React.memo((props: Omit<BulkPostProps, 'dndListeners' | 'dndAttributes'>) => {
  const { post } = props
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: post.id })

  const style = {
    transform: CSS.Translate.toString(transform),
    zIndex: isDragging ? '2' : '1',
    transition,
    position: isDragging ? 'relative' : 'static'
  } as any

  const articleClassName = post.article ? styles.article : ''
  const pinClassName = post.network === BRAND_PINTEREST ? styles.pin : ''
  const errorClassName = post.error ? styles.error : ''

  return (
    <div
      className={`${styles.container} ${articleClassName} ${pinClassName}  ${errorClassName}`}
      ref={setNodeRef}
      style={style}
      data-testid="bulk-post"
    >
      <BulkPost {...props} dndAttributes={attributes} dndListeners={listeners} />
    </div>
  )
})

export default BulkPost
