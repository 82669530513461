import useAsyncAction from 'hooks/useAsyncAction'
import { IndexedObject, PinterestBoard, PostDestination } from 'interfaces'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Observable } from 'rxjs/Observable'
import { selectPinterestBoard, setPinterestProfileBoards } from 'services/compose'
import { activePostIdSelector } from 'services/compose/selectors'
import { getPinterestBoards } from 'services/destinations'
import { StoreThunkDispatch } from 'store/state'
import { AlbumSelector } from './AlbumSelector'

const FILTER_BOARDS_MIN_COUNT = 10

interface PinterestBoardSelectorOwnProps {
  profile: PostDestination
  boards?: Array<{
    id: string
    name: string
    url?: string
    selected?: boolean
  }>
  raised?: boolean
  className?: string
  onSelectedBoardChange?: (ppid: string, board: PinterestBoard) => void
}

interface PinterestBoardSelectorConnectedProps {
  fetchBoards: (profile: PostDestination) => Promise<any>
  setComposerPinterestBoard: (ppid: string, board: PinterestBoard) => void
}

type PinterestBoardSelectorProps = PinterestBoardSelectorOwnProps & PinterestBoardSelectorConnectedProps

export function PinterestBoardSelector(props: PinterestBoardSelectorProps) {
  const dispatch = useDispatch()
  const editPostId = useSelector(activePostIdSelector)
  const [triggerFetchBoards, response, _error, loading] = useAsyncAction((p) => Observable.fromPromise(props.fetchBoards(p)))

  React.useEffect(() => {
    if (Object.keys(props.profile.pinterestBoards || {}).length < 1) {
      triggerFetchBoards(props.profile)
    }
  }, [props.profile.ppid, editPostId])

  React.useEffect(() => {
    if (response) {
      dispatch(setPinterestProfileBoards({ ppid: props.profile.ppid, boards: response.boards }))
    }
  }, [response, props.profile.ppid, dispatch])

  const selectedBoard = props.boards && props.boards.find(b => b.selected)

  const onSelectedBoardChange = (a: PinterestBoard) => {
    if (props.onSelectedBoardChange) {
      props.onSelectedBoardChange(props.profile.ppid, a)
    } else {
      props.setComposerPinterestBoard(props.profile.ppid, a)
    }
  }

  const boardsMap = React.useMemo(() => {
    if (props.boards) {
      return props.boards.reduce((map: IndexedObject<any>, board: any) => {
        map[board.id] = board
        return map
      }, {})
    }

    return {}
  }, [props.boards])

  return (
    <AlbumSelector
      profile={props.profile}
      albums={boardsMap}
      selectedAlbumId={selectedBoard?.id}
      loading={loading}
      raised={props.raised}
      messageEmptySelection={<FormattedMessage id="composer.labels.empty-board" />}
      errorMessageElement={<FormattedMessage id="composer.labels.boards-fetch-failed" />}
      className={props.className}
      withFilter={Object.keys(boardsMap).length > FILTER_BOARDS_MIN_COUNT}
      onSelectionChange={onSelectedBoardChange}
    />
  )
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    fetchBoards: (profile: PostDestination) => dispatch(getPinterestBoards(profile)).unwrap(),
    setComposerPinterestBoard: (ppid: string, board: PinterestBoard) => dispatch(selectPinterestBoard({ ppid, board }))
  }
}

export default connect(null, mapDispatchToProps)(PinterestBoardSelector)
