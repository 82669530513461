import * as React from 'react'
import { connect } from 'react-redux'
import FileFolderCarousel from './FileFolderCarousel'
import { StoreThunkDispatch } from 'store/state'
import { setFileInPreview } from 'services/uploads/actions'
import { MyFile, FileFolder } from 'interfaces'
import { setComposerFile } from 'services/compose'
import { useNavigate, useLocation } from 'react-router-dom'

interface ConnectedFileFolderCarouselOwnProps {
  folder: FileFolder
  title?: React.ReactNode
  navPrefix?: string
  withEmptyViewCTA?: boolean
  withCTACard?: boolean
  hideHeader?: boolean
  navigate?: (url: string) => void
}

interface ConnectedFileFolderCarouselConnectedProps {
  createPostFromFile: (url: string) => void,
  setFileInPreview: (file: MyFile) => void
}

type ConnectedFileFolderCarouselProps = ConnectedFileFolderCarouselOwnProps & ConnectedFileFolderCarouselConnectedProps

function ConnectedFileFolderCarousel(props: ConnectedFileFolderCarouselProps) {
  const nav = useNavigate()
  const location = useLocation()

  const createPostFromFile = (file: MyFile) => {
    props.createPostFromFile(file.url)
    nav('/composer', { state: { floating: true, modal: 'compose', opener: location.pathname, background: location } })
  }

  const navigate = (url: string) => {
    if (props.navigate) {
      props.navigate(url)
      return
    }
    nav(url)
  }

  return (
    <FileFolderCarousel
      folder={props.folder}
      title={props.title}
      navPrefix={props.navPrefix}
      withCTACard={props.withCTACard}
      withEmptyViewCTA={props.withEmptyViewCTA}
      onCreatePost={createPostFromFile}
      navigate={navigate}
      hideHeader={props.hideHeader}
      onCardClick={props.setFileInPreview}
    />
  )
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    createPostFromFile: (url: string) => dispatch(setComposerFile(url)),
    setFileInPreview: (file: MyFile) => dispatch(setFileInPreview(file))
  }
}

export default connect(null, mapDispatchToProps)(ConnectedFileFolderCarousel)
