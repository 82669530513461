import * as React from 'react'
import SimpleLoader from 'components/SimpleLoader'
import { FailedPost as IFailedPost, IndexedObject, WithIntl } from 'interfaces'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { PostErrorView, ERROR_NO_PROFILES_TITLE, ERROR_NO_PROFILES_SUBTITLE } from 'routes/publishing/components/PostErrorView'
import { Observable } from 'rxjs/Observable'
import { catchError } from 'rxjs/operators/catchError'
import { tap } from 'rxjs/operators/tap'
import { Subject } from 'rxjs/Subject'
import { failedPostsCountSelector, selectedProfilesFetchIdsSelector, selectedProfilesSelector } from 'services/post/selectors'
import { message } from 'services/snackbar'
import { StoreThunkDispatch } from 'store/state'
import PostFilesDialog from 'components/Posts/PostFilesDialog'
import styles from './PostViews.pcss'
import { bulkDeletePosts, getFailedPosts, getFailedPostsCount, requeuePost, setSelectedProfiles } from 'services/post/actions'
import { BulkPostActions } from 'routes/publishing/components/BulkActionsMenus'
import { getDerivedStateFromPost, mergeState, resetComposer } from 'services/compose'
import ScrollListener from 'components/ScrollListener'
import { FailedPost } from 'components/Posts'
import { connectedDestinationsSelector } from 'services/destinations'
import { PostsAction, postsReducer } from './reducer'
import { addPosts, deleteMultiplePosts, setPosts } from './actions'
import EmptyView from 'components/EmptyView'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { mdiFeather } from '@mdi/js'
import { NOTIFICATION_DURATION_LONG } from 'components/ConnectedSnackbar'
import { publishPosts } from 'services/post/shareNow/actions'
import { HelpToggleButton } from 'components/App/components/HelpToggleButton'
import ConfirmDialog from 'components/ConfirmDialog'
import PostsFilter from './components/PostsFilter'
import { ContentType } from 'shared/types'
import { POST_TYPES } from 'shared/constants'

const SCROLL_TRESHOLD = 350
type FetchFailedParams = {
  page: number,
  pageIds: string[],
  ppids?: string[],
  query?: string,
  types?: ContentType[],
  searchInArticleSummary?: boolean
}

export function FailedPostsManager(props: WithIntl) {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const selectedProfilesIds = useSelector(selectedProfilesSelector)
  const connectedProfiles = useSelector(connectedDestinationsSelector)
  const selectedProfilesFetchIds = useSelector(selectedProfilesFetchIdsSelector)
  const failedPostsCount = useSelector(failedPostsCountSelector)
  const [posts, postsDispatch] = React.useReducer<React.Reducer<IFailedPost[], PostsAction>>(postsReducer, [])
  const [totalPostsCount, setTotalPostsCount] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [loadingOverlayActive, setLoadingOverlayActive] = React.useState(false)
  const [page, setPage] = React.useState(0)
  const [hasNextPage, setHasNextPage] = React.useState(true)
  const [refreshKey, setRefreshKey] = React.useState(0)
  const [postInMediaPreviewDialog, setPostInMediaPreviewDialog] = React.useState<IFailedPost | null>(null)
  const [selectedPostsIds, setSelectedPostsIds] = React.useState<IndexedObject<boolean> | 'all'>({})
  const [search, setSearch] = React.useState<{ query: string, types: ContentType[], searchInArticleSummary?: boolean }>(
    { query: '', types: POST_TYPES as ContentType[], searchInArticleSummary: true }
  )

  const share$ = React.useRef<Subject<{ postIds: string[], ppids: string[], all: boolean }>>()
  const fetch$ = React.useRef<Subject<FetchFailedParams>>()
  const delete$ = React.useRef<Subject<{ postIds: string[], ppids: string[], all: boolean, query: string, types: ContentType[] }>>()
  const shareNext$ = React.useRef<Subject<{ postIds: string[], ppids: string[], all: boolean, deletePosts?: boolean }>>()
  const scrollElementRef = React.useRef<HTMLElement | undefined>(undefined)
  const selectionRef = React.useRef<{ postIds: string[], profileIds: string[], all: boolean, ppids: string[] }>()

  React.useLayoutEffect(() => {
    scrollElementRef.current = document.querySelector('[data-test="main"]') as HTMLElement
  }, [])

  const onFilterPosts = React.useCallback((query: string, types: ContentType[], searchInArticleSummary?: boolean) => {
    setSearch({ query, types, searchInArticleSummary })
  }, [])

  React.useEffect(() => {
    fetch$.current = new Subject()
    fetch$.current
      .pipe(tap(({ page }) => {
        setLoading(true)
        setPage(page)
      }))
      .switchMap(({ pageIds, page, ppids, query, searchInArticleSummary, types }) => {
        return dispatch(getFailedPosts(page, pageIds, ppids, query, types, searchInArticleSummary)).pipe(
          catchError(() => {
            dispatch(message('Error fetching posts! Please refresh.', 'error'))
            return Observable.of({ error: true })
          })
        )
      })
      .subscribe((response: any) => {
        setLoading(false)
        setLoadingOverlayActive(false)
        setTotalPostsCount(response.totalPostsCount)
        if (!response.error) {
          setHasNextPage(response.totalPostsCount > response.posts.length)
          if (response.page === 0) {
            postsDispatch(setPosts(response.posts))
            dispatch(getFailedPostsCount())
          } else {
            postsDispatch(addPosts(response.posts))
          }
        }
      })

    return () => fetch$.current?.unsubscribe()
  }, [])

  React.useEffect(() => {
    delete$.current = new Subject()
    delete$.current
      .pipe(tap(() => {
        dispatch(message(props.intl.formatMessage({ id: 'post.notifications.deleting-posts' })))
        setLoadingOverlayActive(true)
      }))
      .flatMap(({ ppids, postIds, all }) => {
        return dispatch(bulkDeletePosts(postIds, ppids, 'failed', all))
          .pipe(catchError((error: Error) => {
            dispatch(message(props.intl.formatMessage({ id: 'post.notifications.delete-posts-error' }, { error: error.message }), 'error'))
            return Observable.of({ error })
          }))
      })
      .subscribe((response: any) => {
        if (!response.error) {
          setSelectedPostsIds({})
          if (response.all) {
            return
          }
          setRefreshKey(current => current + 1)
          postsDispatch(deleteMultiplePosts(response.ids))
          dispatch(getFailedPostsCount())
        }
        setLoadingOverlayActive(false)
      })

    return () => delete$.current?.unsubscribe()
  }, [])

  React.useEffect(() => {
    shareNext$.current = new Subject()
    shareNext$.current
      .pipe(tap(() => {
        setLoadingOverlayActive(true)
      }))
      .flatMap(({ ppids, postIds, all, deletePosts }) => {
        return dispatch(requeuePost(postIds, ppids, all))
          .pipe(catchError((error: Error) => {
            if (error.message) {
              dispatch(message(error.message, 'error', NOTIFICATION_DURATION_LONG))
            } else {
              dispatch(message(props.intl.formatMessage({ id: 'errors.generic' }), 'error'))
            }
            return Observable.of({ error })
          }), tap(() => {
            if (deletePosts) {
              delete$.current?.next({ postIds, ppids, all, query: search.query, types: search.types })
            }
          }))
      })
      .subscribe((response: any) => {
        if (!response.error) {
          setSelectedPostsIds({})
          dispatch(message(props.intl.formatMessage({ id: 'post.notifications.posts-requeued' }), 'success'))
        }
        setLoadingOverlayActive(false)
      })

    return () => shareNext$.current?.unsubscribe()
  }, [dispatch, props.intl, search.query, search.types])

  React.useEffect(() => {
    share$.current = new Subject()
    share$.current
      .pipe(tap(() => {
        setLoadingOverlayActive(true)
      }))
      .flatMap(({ ppids, postIds, all }) => {
        return dispatch(publishPosts(postIds, ppids, 'failed', all))
          .pipe(catchError((error: Error) => {
            dispatch(message(props.intl.formatMessage({ id: 'notifications.error-w-message' }, { error: error.message }), 'error'))
            return Observable.of({ error })
          }))
      })
      .subscribe((response: any) => {
        setLoadingOverlayActive(false)
        if (!response.error) {
          setRefreshKey(current => current + 1)
          setSelectedPostsIds({})
          dispatch(message(props.intl.formatMessage({ id: 'notifications.post-posted' }), 'success'))
        }
      })

    return () => share$.current?.unsubscribe()
  }, [dispatch, props.intl])

  React.useEffect(() => {
    postsDispatch(setPosts([]))
  }, [selectedProfilesFetchIds])

  React.useEffect(() => {
    if (selectedProfilesFetchIds.length > 0) {
      const pageIds = selectedProfilesFetchIds.split(',')
      fetch$.current?.next({
        page: 0,
        pageIds,
        ppids: Object.values(connectedProfiles).filter(p => pageIds.includes(p.idNew)).map(p => p.ppid),
        ...search
      })
    }
  }, [selectedProfilesFetchIds, refreshKey, connectedProfiles, search])

  React.useEffect(() => {
    const profileIds = selectedProfilesFetchIds.split(',')
    const ppids = Object.values(connectedProfiles).filter(p => profileIds.includes(p.idNew)).map(p => p.ppid)
    if (selectedPostsIds === 'all') {
      selectionRef.current = {
        all: true,
        postIds: posts.map(p => p.id),
        profileIds,
        ppids
      }
    } else {
      selectionRef.current = {
        all: false,
        profileIds,
        ppids,
        postIds: Object.keys(selectedPostsIds).filter(id => selectedPostsIds[id])
      }
    }
  }, [selectedPostsIds, posts, selectedProfilesFetchIds, connectedProfiles])

  const loadNextPage = React.useCallback(() => {
    if (hasNextPage && !loading) {
      const pageIds = selectedProfilesFetchIds.split(',')
      fetch$.current?.next({
        page: page + 1,
        pageIds,
        ppids: Object.values(connectedProfiles).filter(p => pageIds.includes(p.idNew)).map(p => p.ppid),
        ...search
      })
    }
  }, [page, selectedProfilesFetchIds, hasNextPage, loading, search, connectedProfiles])

  const closePostMediaPreviewDialog = React.useCallback(() => {
    setPostInMediaPreviewDialog(null)
  }, [])

  const onRepost = React.useCallback((post: IFailedPost) => {
    dispatch(resetComposer())
    dispatch(mergeState(getDerivedStateFromPost(post, 'failed')))
    navigate('/composer', { state: { floating: true, modal: 'compose', opener: '/history/failed', background: location } })
  }, [dispatch, navigate, location])

  const togglePostSelected = React.useCallback((id: string) => {
    setSelectedPostsIds(current => {
      if (current === 'all') {
        return posts.reduce((selection: IndexedObject<boolean>, post) => {
          selection[post.id] = post.id !== id
          return selection
        }, {})
      }
      return { ...current, [id]: !current[id] }
    })
  }, [posts])

  const toggleAllPostsSelected = React.useCallback(() => {
    setSelectedPostsIds(current => current === 'all' ? {} : 'all')
  }, [])

  const onDeletePost = React.useCallback((id: string) => {
    delete$.current?.next({
      postIds: [id],
      ppids: selectionRef.current?.ppids || [],
      all: false,
      query: search.query,
      types: search.types
    })
  }, [search.query, search.types])

  const deleteSelectedPosts = React.useCallback(() => {
    if (selectionRef.current) {
      delete$.current?.next({ ...selectionRef.current, query: search.query, types: search.types })
    }
  }, [search.query, search.types])

  const shareNextPosts = React.useCallback((deletePosts?: boolean) => {
    shareNext$.current?.next({
      postIds: selectionRef.current?.postIds || [],
      ppids: selectionRef.current?.ppids || [],
      all: Boolean(selectionRef.current?.all),
      deletePosts: Boolean(deletePosts)
    })
  }, [])

  const shareNextAndDeletePosts = React.useCallback(() => {
    shareNextPosts(true)
  }, [shareNextPosts])

  const onShareNow = React.useCallback((post: { id: string, ppPageId: string }) => {
    share$.current?.next({ all: false, postIds: [post.id], ppids: [post.ppPageId] })
  }, [])

  const onShareNext = React.useCallback((post: { id: string, ppPageId: string }, shouldDelete?: boolean) => {
    shareNext$.current?.next({
      postIds: [post.id],
      ppids: [post.ppPageId],
      all: false,
      deletePosts: Boolean(shouldDelete)
    })
  }, [])

  const deletePostsConfirmMessage = selectedPostsIds === 'all'
    ? props.intl.formatMessage({ id: 'post.delete-all-confirm' }, { count: totalPostsCount, type: 'failed' })
    : undefined

  const selectedPostsCount = React.useMemo(() => {
    return selectedPostsIds !== 'all'
      ? Object.values(selectedPostsIds).filter(Boolean).length
      : totalPostsCount
  }, [totalPostsCount, selectedPostsIds])

  const selectAllProfiles = () => {
    dispatch(setSelectedProfiles({ profiles: Object.values(connectedProfiles), activeView: 'history' }))
  }

  return (
    <div className={`${styles.wrapper} ${styles['wrapper-failed']}`}>
      <div className={styles.content}>
        {loadingOverlayActive && (
          <div className={styles['loading-overlay']}>
            <SimpleLoader small />
          </div>
        )}
        <div className={styles.header}>
          <PostsFilter activeTypes={search.types} className={styles['failed-search']} onFilter={onFilterPosts} />
          {typeof totalPostsCount === 'number' && (
            <div className={styles.count}>
              <FormattedMessage id="post.planned.posts-count-label" values={{ count: totalPostsCount }} />
            </div>
          )}
          <ConfirmDialog
            message="What should happen to the selected failed posts after this action?"
            labelOK="Keep posts in Failed"
            labelCancel="Delete posts from Failed"
            btnCancelClassName={styles['btn-confirm-delete']}
            btnOKClassName={styles['btn-confirm-keep']}
            onDecline={shareNextAndDeletePosts}
          >
            {(confirm: any) => (
              <BulkPostActions
                hidePostActions={!loading && posts.length === 0}
                withDelete
                disabled={selectedPostsCount === 0}
                allSelected={selectedPostsIds === 'all'}
                selectedCount={selectedPostsCount}
                deleteAllConfirmMessage={deletePostsConfirmMessage}
                onBulkShareNext={confirm(shareNextPosts)}
                onToggleAllSelected={toggleAllPostsSelected}
                onBulkDeletePosts={deleteSelectedPosts}
              />
            )}
          </ConfirmDialog>
          <HelpToggleButton />
        </div>
        <div className={styles['failed-list-wrapper']} data-test="post-list">
          <ScrollListener
            scrollElement={scrollElementRef.current}
            emitTreshold={SCROLL_TRESHOLD}
            onScroll={loadNextPage}
          >
            <div>
              {posts.map((post: IFailedPost) => (
                <FailedPost
                  key={post.id}
                  selected={selectedPostsIds === 'all' || Boolean(selectedPostsIds[post.id])}
                  post={post}
                  onRepost={onRepost}
                  onDelete={onDeletePost}
                  onToggleSelected={togglePostSelected}
                  onMediaPreviewBtnClick={setPostInMediaPreviewDialog}
                  onShareNow={onShareNow}
                  onShareNext={onShareNext}
                />
              ))}
            </div>
          </ScrollListener>
        </div>
        {!loading && selectedProfilesIds.length === 0 && (
          <PostErrorView title={ERROR_NO_PROFILES_TITLE} subtitle={ERROR_NO_PROFILES_SUBTITLE} />
        )}
        {!loading && selectedProfilesIds.length !== 0 && posts.length === 0 && (
          <EmptyView
            icon={mdiFeather}
            title="No failed posts"
            subtitle={(
              <div>
                <NavLink to={{ pathname: '/composer' }} state={{ floating: true, modal: 'compose', opener: location.pathname }}>
                  Create a new post
                </NavLink>
                &nbsp;or&nbsp;<NavLink to="/content">search for content</NavLink>
                {!failedPostsCount.loading && failedPostsCount.total > 0 && (
                  <div className={styles['failed-empty-subtitle']}>
                    To see the failed posts for all pages, click <span onClick={selectAllProfiles}>here</span>
                  </div>
                )}
              </div>
            )}
            backdropClassName={styles['failed-empty-overlay']}
            backdrop
          />
        )}
        {loading && <SimpleLoader small />}
      </div>
      <PostFilesDialog
        active={Boolean(postInMediaPreviewDialog)}
        images={postInMediaPreviewDialog?.images || []}
        onClose={closePostMediaPreviewDialog}
      />
    </div>
  )
}

export default injectIntl(FailedPostsManager)
