import { V1 } from './net'
import { streamAdapter, feedAdapter } from 'services/content/util'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getLatestSearches = createAsyncThunk(
  'search/getLatestSearches',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await dispatch(V1.getLatestSearches()).toPromise()
      return response.searches.map((s: any) => s.term)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const addLatestSearch = createAsyncThunk(
  'search/addLatestSearch',
  async (query: string, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(V1.addLatestSearch(query)).toPromise()

      return query
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getRecentlyViewed = createAsyncThunk(
  'search/getRecentlyViewed',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await dispatch(V1.getRecentlyViewed()).toPromise()
      return response.recent.map(source => {
        if ((source as any).handle) {
          return feedAdapter(source as any)
        }
        return streamAdapter(source as any)
      })
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const addRecentlyViewed = createAsyncThunk(
  'search/addRecentlyViewed',
  async (args: {source: any, type: 'feed' | 'stream'}, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(V1.addRecentlyViewed(args.source.id, args.type)).toPromise()

      return args.source
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const deleteRecentSearch = createAsyncThunk(
  'search/deleteRecentSearch',
  async (term: string, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(V1.deleteRecentSearch(term)).toPromise()

      return term
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
