import * as React from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import { MyFile, PHOTO_TYPE, VIDEO_TYPE, GIF_TYPE, FileFolder, WithIntl } from 'interfaces'
import { NativeVideoPlayer } from 'components/VideoPlayer'
import GifPlayer from 'components/GifPlayer'
import DeleteIcon from '@mui/icons-material/Delete'
import FolderIcon from '@mui/icons-material/Folder'
import Button from '@mui/material/Button'
import { bytesToSize } from 'utils/format/byteToSize'
import { FormattedMessage } from 'react-intl'
import { Observable } from 'rxjs/Observable'
import ProgressMedia from 'components/Card/ProgressMedia'
import Tooltip from '@mui/material/Tooltip'

const Clipboard = require('clipboard')
const cssVar = require('styles/variables')
import styles from './FileDialog.pcss'
import SimpleLoader from 'components/SimpleLoader'
import { Subject } from 'rxjs/Subject'
import { StoreThunkDispatch } from 'store/state'
import { useDispatch } from 'react-redux'
import { deleteFile, getStorageInfo } from 'services/uploads/actions'
import { catchError } from 'rxjs/operators/catchError'
import { action, message } from 'services/snackbar'
import { HTTP_STATUS_BAD_REQUEST } from 'services/net'
import { NOTIFICATION_DURATION_LONG } from 'components/ConnectedSnackbar'
import { makeStyles } from '@mui/styles'

const PREFIX = 'FileDialog'

const classes = {
  root: `${PREFIX}-root`
}
const StyledDialog = styled(Dialog)({
  [`& .${classes.root}`]: {
    fontSize: '1.2rem',
    color: cssVar['--alert-red'],
    margin: '0 0 0 4px',

    '&:hover': {
      backgroundColor: cssVar['--pp-black-38']
    }
  }
})

const makeDeleteButtonStyles = makeStyles({
  [`& .${classes.root}`]: {
    fontSize: '1.2rem',
    color: cssVar['--alert-red'],
    margin: '0 0 0 4px',

    '&:hover': {
      backgroundColor: cssVar['--pp-black-38']
    }
  }
})

export interface FileDialogProps extends WithIntl {
  file?: MyFile
  folder?: FileFolder
  setFileInPreview: (file?: MyFile) => void
}

export function FileDialog(props: FileDialogProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [fileDeleteLoading, setFileDeleteLoading] = React.useState(false)
  const [urlCopied, setUrlCopied] = React.useState(false)
  const [imageLoading, setImageLoading] = React.useState(true)
  const file = props.file
  const prettySize = bytesToSize(file?.size || 0)
  const deleteBtnClasses = makeDeleteButtonStyles()
  const clipboard = React.useRef(null)
  const delete$ = React.useRef<Subject<{ file: MyFile, force: boolean }>>()

  React.useEffect(() => {
    delete$.current = new Subject()
    delete$.current
      .flatMap(({ file, force }) => {
        return Observable.fromPromise(dispatch(deleteFile({ file, force })).unwrap())
          .pipe(catchError(response => {
            if (response.status === HTTP_STATUS_BAD_REQUEST && response.response.msg === 'File used in posts.') {
              dispatch(action(
                'warning',
                props.intl.formatMessage({ id: 'find.my-uploads.messages.confirm-delete-used-file' }),
                props.intl.formatMessage({ id: 'actions.delete' }),
                () => {
                  delete$.current?.next({ file, force: true })
                },
                NOTIFICATION_DURATION_LONG
              ))
              return Observable.of({ error: response, handled: true })
            }
            return Observable.of({ error: response })
          }))
          .flatMap((response: any) => {
            if (!response.error) {
              return dispatch(getStorageInfo())
            }
            return Observable.of(response)
          })
      })
      .subscribe((result) => {
        if (result.error && !result.handled) {
          dispatch(message(props.intl.formatMessage({ id: 'errors.generic' }), 'error'))
        }
        setFileDeleteLoading(false)
      })
  }, [dispatch, props.intl])

  const setPathElementRef = (element: HTMLElement | null) => {
    if (file && element) {
      if (clipboard.current) {
        (clipboard.current as any).destroy()
      }

      clipboard.current = new Clipboard(element)
      ; (clipboard.current as any).on('success', () => {
        setUrlCopied(true)
      })
      ; (clipboard.current as any).on('error', (e: any) => {
        console.log('Clipboard error', e)
        setUrlCopied(false)
      })
    }
  }

  const onUrlTooltipOpen = () => {
    setUrlCopied(false)
  }

  const close = () => {
    props.setFileInPreview(undefined)
  }

  const stopImageLoading = () => {
    setImageLoading(false)
  }

  const onDeleteFile = () => {
    if (file) {
      setFileDeleteLoading(true)
      delete$.current?.next({ file, force: false })
      close()
    }
  }

  const pathname = `${props.folder?.name} / ${file?.name}`
  const fixedFooterClass = file?.type === VIDEO_TYPE ? styles['footer-fixed'] : ''

  return (
    <StyledDialog open={Boolean(file)} onClose={close} classes={{ paper: styles.dialog }} className={fixedFooterClass} disableEnforceFocus>
      {
        file && imageLoading && (
          <ProgressMedia
            image={file.url}
            loader={<SimpleLoader className={styles.loader} />}
            className={styles['loader-box']}
            onDone={stopImageLoading}
            onError={stopImageLoading}
          />
        )
      }
      {
        file && !imageLoading && (
          <div className={styles.content}>
            {
              file.type === PHOTO_TYPE && (
                <img src={file.url} className={styles.image} />
              )
            }
            {
              file.type === VIDEO_TYPE && (
                <NativeVideoPlayer
                  controls
                  videoUrl={file.url}
                  className={styles['video-player']}
                  videoElementClassName={styles.video}
                />
              )
            }
            {
              file.type === GIF_TYPE && (
                <GifPlayer
                  loop
                  gifUrl={file.url}
                />
              )
            }
          </div>
        )
      }
      <footer className={styles.footer}>
        <Tooltip
          placement="top"
          title={<FormattedMessage id={urlCopied ? 'notifications.generic-copied' : 'uploads.tooltip.copy-file-url'} />}
          onOpen={onUrlTooltipOpen}
        >
          <p
            className={styles.path}
            data-clipboard-text={file?.url || ''}
            ref={setPathElementRef}
          >
            <FolderIcon className={styles['folder-icon']} />
            <span className="text-ellipsis" title={pathname}>{pathname}</span>
            <span className={styles.filesize}>{` (${prettySize})`}</span>
          </p>
        </Tooltip>
        <Button
          startIcon={<DeleteIcon />}
          classes={deleteBtnClasses}
          disabled={fileDeleteLoading}
          onClick={onDeleteFile}
        >
          <FormattedMessage id="actions.delete" />
        </Button>
      </footer>
    </StyledDialog>
  )
}
