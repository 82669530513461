import * as React from 'react'
import { ComposerFacebookPost, FBAlbum } from 'services/compose/interfaces/ComposerFacebookPost'
import styles from './CustomizePostBlock.pcss'
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import DeleteIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { FormattedMessage } from 'react-intl'
import { BRAND_FACEBOOK, PostDestination } from 'interfaces'
import FacebookAlbumSelector from '../AlbumSelector/FacebookAlbumSelector'
import { EmptyNetworkPostOptions } from './EmptyNetworkPostOptions'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import { mdiShareVariant, mdiImageAlbum } from '@mdi/js'
import Icon from '@mdi/react'
import { FB_POST_TYPE_REEL, POST_TYPE_STORY } from 'services/compose/state'
import { useSelector } from 'react-redux'
import { composerImageUrlsSelector, composerSelectedProfilesSelector } from 'services/compose/selectors'
import PPSwitch from 'components/PPSwitch'

interface ComposerFacebookPostOptionsProps {
  post: ComposerFacebookPost
  profiles: PostDestination[]
  profile: PostDestination | null
  withImage: boolean
  withReshare: boolean
  empty?: boolean
  withVideo?: boolean
  hideEmpty?: boolean
  selectedAlbumsCustom?: FBAlbum[]
  onToggleReshare?: () => void
  onSelectedAlbumChangeCustom?: (profile: PostDestination, album: FBAlbum | undefined) => void
  onPostTypeChange: (postType: string) => void
}

export function ComposerFacebookPostOptions(props: ComposerFacebookPostOptionsProps) {
  const { onPostTypeChange, onToggleReshare } = props
  const imageUrls = useSelector(composerImageUrlsSelector)
  const [albumsPopupActive, setAlbumsPopupActive] = React.useState(false)
  const selectedProfiles = useSelector(composerSelectedProfilesSelector).filter(profile => profile.type === BRAND_FACEBOOK)
  const selectedGroups = selectedProfiles.filter(profile => profile.fbGroup)
  const onlyGroupsSelected = selectedGroups.length > 0 && selectedGroups.length === selectedProfiles.length

  // Reset post type to post if all selected profiles are groups
  React.useEffect(() => {
    if (onlyGroupsSelected) {
      onPostTypeChange('post')
    }
  }, [onlyGroupsSelected, onPostTypeChange])

  const onReshareValueChange = React.useCallback((value: 'new' | 'reshare') => {
    const nextReshare = value === 'reshare'
    if (nextReshare !== props.post.reshare && onToggleReshare) {
      onToggleReshare()
    }
  }, [props.post.reshare, onToggleReshare])

  const postTypeOptions = React.useMemo(() => {
    const options = [{
      value: 'post',
      label: 'Post'
    }]
    if (props.withVideo) {
      options.push({
        value: FB_POST_TYPE_REEL,
        label: 'Reel'
      }, {
        value: POST_TYPE_STORY,
        label: 'Story'
      })
    } else if (!onlyGroupsSelected && props.withImage && imageUrls.length === 1) {
      options.push({
        value: POST_TYPE_STORY,
        label: 'Story'
      })
    }
    return options
  }, [props.withVideo, props.withImage, onlyGroupsSelected, imageUrls.length])

  if (props.empty) {
    return props.hideEmpty ? null : <EmptyNetworkPostOptions />
  }

  const profile = props.profile
  const showAlbums = props.withImage && !props.post.reshare
  const withAlbumsPopup = Boolean(showAlbums && !props.profile)
  const withAlbums = showAlbums && profile

  const openAlbumsPopup = () => {
    setAlbumsPopupActive(true)
  }

  const closeAlbumsPopup = () => {
    setAlbumsPopupActive(false)
  }

  const options: PPSelectOptions = {
    new: { label: <FormattedMessage id="composer.labels.photos.new-post" /> },
    reshare: { label: <FormattedMessage id="composer.labels.photos.reshare" /> }
  }

  return (
    <React.Fragment>
      {postTypeOptions.length > 1 && (
        <div className={styles['option-box']}>
          <PPSwitch
            selectedValue={props.post.postType || 'post'}
            options={postTypeOptions}
            onSelectedValueChange={onPostTypeChange}
          />
        </div>
      )}
      {props.withReshare && (
        <div className={styles['option-box']}>
          <PPSelect
            name="Post as"
            icon={<Icon path={mdiShareVariant} size="16px" />}
            options={options}
            withCaret
            selectedValue={props.post.reshare ? 'reshare' : 'new'}
            labelClassName={styles['select-label']}
            onSelectionChange={onReshareValueChange}
          />
        </div>
      )}
      {withAlbums && (
        <div className={styles['option-box']} key={profile.id}>
          <FacebookAlbumSelector
            hideProfileImage
            profile={profile}
            selectedAlbumsCustom={props.selectedAlbumsCustom}
            onSelectedAlbumChangeCustom={props.onSelectedAlbumChangeCustom}
          />
        </div>
      )}
      {withAlbumsPopup && (
        <React.Fragment>
          <Chip
            label={
              <FormattedMessage
                id={props.post.albums.length === 0 ? 'composer.labels.fb-album-default' : 'composer.labels.fb-album-custom'}
              />
            }
            avatar={<Icon path={mdiImageAlbum} size="20px" />}
            classes={{ root: styles.chip, label: styles.label }}
            onClick={openAlbumsPopup}
          />
          <Dialog open={albumsPopupActive} onClose={closeAlbumsPopup} classes={{ paper: styles['albums-popup'] }}>
            <IconButton className={styles['btn-close']} onClick={closeAlbumsPopup} size="large">
              <DeleteIcon />
            </IconButton>
            <h3 className={styles['popup-title']}>
              <FormattedMessage id="composer.labels.select-multiple-albums" />
            </h3>
            <div className={styles['albums-popup-content']}>
              {props.profiles.map(profile => (
                <div className={styles['option-box']} key={profile.id}>
                  <FacebookAlbumSelector
                    profile={profile}
                    raised
                    selectedAlbumsCustom={props.selectedAlbumsCustom}
                    onSelectedAlbumChangeCustom={props.onSelectedAlbumChangeCustom}
                  />
                </div>
              ))}
            </div>
          </Dialog>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
