import React from 'react'
import { URL_SEGMENT_TO_CONTENT_TYPE_MAP, CONTENT_TYPE_TO_URL_SEGMENT_MAP } from 'adapters'
import { AnyContent, ContentItem, Feed, FilterType, RangeFilter, Stream, VALID_RANGE_FILTERS, WEEK_RANGE_FILTER } from 'interfaces'
import { useLocation, Navigate, useMatch, useParams } from 'react-router-dom'
import StreamViewNavigation from './StreamViewNavigation'
import * as FilterStore from 'utils/filter-store'
import StreamFeedsNavigation from './StreamFeedsNavigation'
import StreamFeedView from './StreamFeedView'
import { BricksSize } from './StreamBricksView'
import { useSelector } from 'react-redux'
import { rangeFeatureMap } from 'services/product'
import { userProductSelector } from 'services/users/selectors'

export function StreamFeedRoute(props: {
  stream: Stream,
  isSavedStream: boolean,
  isMyStreamsView: boolean,
  navigation: StreamViewNavigation,
  streamIds: string[],
  sizes?: BricksSize[],
  align?: 'left' | 'center',
  itemWidth?: number,
  renderFeedRedirect?: (path: string) => React.ReactNode,
  onItemPinned?(stream: Stream, feed: Feed, content: AnyContent): void,
  onItemShared?(stream: Stream, feed: Feed, content: AnyContent): void | boolean,
  onFilterChanged?(stream: Stream, feed: Feed, filter: FilterType): void,
  onRangeChanged?(stream: Stream, feed: Feed, range: RangeFilter): void,
  onGotoClicked?(stream: Stream, feed: Feed, content: AnyContent, by: string): void,
  onContentItemClick?(item: ContentItem): void,
  onView?(stream: Stream, feed: Feed): void
}) {
  const match = useMatch(`${props.navigation.prefix}/feeds/:feed/:type/:range`)
  const params = useParams()
  const location = useLocation()
  const product = useSelector(userProductSelector)
  const features = product?.features || {}
  const isInitialMount$ = React.useRef(true)

  const resolveFeedFilters = () => {
    const storedFilters = FilterStore.getLastUsedFilters()
    const type = URL_SEGMENT_TO_CONTENT_TYPE_MAP[match?.params.type || ''] || storedFilters.type
    let range = match?.params.range || storedFilters.range

    const currentRangeFeatureKey = rangeFeatureMap[range]
    const shouldUpdate = features[currentRangeFeatureKey] === false

    if (shouldUpdate && isInitialMount$.current) {
      range = VALID_RANGE_FILTERS.find(r => features[rangeFeatureMap[r]]) as RangeFilter || WEEK_RANGE_FILTER
      isInitialMount$.current = false
    }

    return {
      type: FilterStore.assureValidType(type),
      range: FilterStore.assureValidRange(range)
    }
  }

  const { type, range } = resolveFeedFilters()
  const feedId = params.feed
  const stream = props.stream
  const isMyStreamsURL = props.isMyStreamsView

  // EXPL: In My Content view, URLs are using user feed ids
  const feed = isMyStreamsURL
    ? stream.feeds.find(f => f.id === feedId)
    : stream.feeds.find(f => f.uniqueSource === feedId)

  React.useEffect(() => {
    if (feed) {
      FilterStore.setFeedFilters(feed, type, range)
    }
  }, [feed, type, range])

  const onRangeChanged = (stream: Stream, f: Feed, r: RangeFilter) => {
    if (props.onRangeChanged) {
      props.onRangeChanged(stream, f, r)
    }
    return false
  }

  // If it's a not-owned private stream, we want to redirect to the home page
  if (props.stream.isPrivate && !props.isSavedStream) {
    return <Navigate to="/content" replace />
  }

  // EXPL: Stream is not in My Streams, redirect to root stream url
  if (isMyStreamsURL && props.streamIds.indexOf(stream.id) === -1) {
    return <Navigate to={`/content/streams/${stream.originalId || stream.id}/feeds/${feedId}`} replace />
  }

  if (feed) {
    // EXPL: If filter is not passed in url or invalid, use stored/default filters for this feed. Otherwise, save the requested filters
    const requestedType = URL_SEGMENT_TO_CONTENT_TYPE_MAP[match?.params.type || '']
    if (!match || requestedType !== type || match?.params.range !== range) {
      const pathWithParams = `${props.navigation.prefix}/feeds/${feedId}/${CONTENT_TYPE_TO_URL_SEGMENT_MAP[type]}/${range}`
      if (props.renderFeedRedirect) {
        return props.renderFeedRedirect(pathWithParams) as any
      }

      return (
        <Navigate
          to={{ pathname: pathWithParams, search: location.search }}
          replace
          state={location.state}
        />
      )
    }

    return (
      <React.Fragment>
        <StreamFeedsNavigation
          stream={props.stream}
          prefix={props.navigation.prefix}
          search={location.search}
          activeFeedId={feed.id}
          linkToUserFeedId={props.isMyStreamsView}
          filterUrl={CONTENT_TYPE_TO_URL_SEGMENT_MAP[type]}
          rangeUrl={range}
        />
        <StreamFeedView
          filter={type}
          range={range}
          align={props.align}
          sizes={props.sizes}
          streamId={props.stream.id}
          stream={props.stream}
          itemWidth={props.itemWidth}
          feed={feed}
          basePath={`${props.navigation.prefix}/feeds`}
          linkToUserFeedId={props.isMyStreamsView}
          onDistinctFeed={props.onView}
          onItemShared={props.onItemShared}
          onItemPinned={props.onItemPinned}
          onRangeChanged={onRangeChanged}
          onGotoClicked={props.onGotoClicked}
          onFilterChanged={props.onFilterChanged}
          onContentItemClick={props.onContentItemClick}
        />
      </React.Fragment>
    )
  }

  return (
    <Navigate to={props.navigation.prefix} replace />
  )
}
