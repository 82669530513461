import * as React from 'react'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { getSavedStatuses } from 'services/compose/saved'
import { Observable } from 'rxjs/Observable'

interface FetchSavedTextsProps {
  force?: boolean
  onFetched?: (texts: any[]) => void
  onError?: (error: any) => void
}

export function FetchSavedTexts(props: FetchSavedTextsProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()

  React.useEffect(() => {
    const sub = Observable.fromPromise(dispatch(getSavedStatuses(props.force)).unwrap())
      .subscribe(props.onFetched, props.onError)
    return () => sub.unsubscribe()
  }, [dispatch, props.force, props.onError, props.onFetched])

  return null
}
