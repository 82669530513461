import { PHOTO_EDITOR_LICENSE } from 'config'
const variables = require('styles/variables')

declare const PhotoEditorSDK: any

export const PHOTO_EDITOR_CONFIG_BASE = {
  license: PHOTO_EDITOR_LICENSE,
  container: '',
  image: '',
  layout: 'advanced',
  theme: 'light',
  tools: [
    PhotoEditorSDK.Tool.TRANSFORM,
    PhotoEditorSDK.Tool.TEXT,
    PhotoEditorSDK.Tool.ADJUSTMENT,
    PhotoEditorSDK.Tool.STICKER,
    PhotoEditorSDK.Tool.FILTER
  ],
  defaultTool: PhotoEditorSDK.Tool.TRANSFORM,
  displayCloseWarning: false,
  assetBaseUrl: '/static/photoeditor-assets',
  mainCanvasActions: [undefined, undefined, PhotoEditorSDK.CanvasAction.CLOSE, PhotoEditorSDK.CanvasAction.EXPORT],
  export: {
    image: {
      format: PhotoEditorSDK.ImageFormat.JPEG,
      enableDownload: false,
      quality: 1,
      exportType: PhotoEditorSDK.ExportFormat.DATA_URL
    }
  },
  custom: {
    themes: {
      light: {
        primary: variables['--pp-green']
      }
    },
    languages: {
      en: {
        mainCanvasActions: {
          buttonExport: 'Save'
        }
      }
    }
  },
  text: {
    // eslint-disable-next-line no-magic-numbers
    defaultColor: [0.19, 0.19, 0.19, 1],
    fonts: [
      { identifier: 'imgly_font_aleo_bold' },
      { identifier: 'imgly_font_amaticsc' },
      { identifier: 'imgly_font_archivo_black' },
      { identifier: 'imgly_font_bungee_inline' },
      // { identifier: 'imgly_font_campton_bold' },
      { identifier: 'imgly_font_carter_one' },
      { identifier: 'imgly_font_codystar' },
      // { identifier: 'imgly_font_galano_grotesque_bold' },
      { identifier: 'imgly_font_krona_one' },
      { identifier: 'imgly_font_kumar_one_outline' },
      { identifier: 'imgly_font_lobster' },
      { identifier: 'imgly_font_molle' },
      { identifier: 'imgly_font_monoton' },
      { identifier: 'imgly_font_nixie_one' },
      { identifier: 'imgly_font_notable' },
      { identifier: 'imgly_font_ostrich_sans_black' },
      { identifier: 'imgly_font_ostrich_sans_bold' },
      { identifier: 'imgly_font_oswald_semi_bold' },
      { identifier: 'imgly_font_palanquin_dark_semi_bold' },
      { identifier: 'imgly_font_poppins' },
      { identifier: 'imgly_font_permanent_marker' },
      { identifier: 'imgly_font_roboto_black_italic' },
      { identifier: 'imgly_font_roboto_light_italic' },
      { identifier: 'imgly_font_sancreek' },
      { identifier: 'imgly_font_stint_ultra_expanded' },
      { identifier: 'imgly_font_trash_hand' },
      { identifier: 'imgly_font_vt323' },
      { identifier: 'imgly_font_yeseva_one' }
    ]
  },
  transform: {
    categories: [
      { identifier: 'imgly_transforms_common' },
      { identifier: 'imgly_transforms_facebook' },
      { identifier: 'imgly_transforms_twitter' },
      {
        identifier: 'imgly_transforms_instagram',
        items: [
          { identifier: 'imgly_transform_instagram_landscape' },
          { identifier: 'imgly_transform_instagram_portrait' },
          { identifier: 'imgly_transform_instagram_square' }
        ]
      }
    ]
  }
}
