import * as React from 'react'
import styles from './RSSArticlesUpload.pcss'
import { WithIntl } from 'interfaces'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { addArticlePosts } from 'services/compose'
import Input from '@mui/material/Input'
import { getArticlesFromRssUrl } from 'services/content/articles/actions'
import { StoreThunkDispatch } from 'store/state'
import { message } from 'services/snackbar'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { Subject } from 'rxjs/Subject'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { ArticleBase } from 'services/compose/interfaces/ArticleBase'

export function RSSArticlesUpload(props: WithIntl & {
  onUploadStarted: () => void,
  onUploadError: (err: string) => void
}) {
  const [value, setValue] = React.useState('')
  const dispatch = useDispatch<StoreThunkDispatch>()
  const rssFeedUrlInputRef = React.useRef<HTMLInputElement>(null)
  const [loading, setLoading] = React.useState(false)
  const fetch$ = React.useRef<Subject<string>>()

  React.useEffect(() => {
    fetch$.current = new Subject()
    fetch$.current.flatMap((url: string) => {
      props.onUploadStarted()
      return dispatch(getArticlesFromRssUrl(url)).pipe(
        catchError(error => Observable.of({ error }))
      )
    }).subscribe((response: any) => {
      setLoading(false)
      if (response.error) {
        props.onUploadError(response.error.response.message)
      } else {
        dispatch(addArticlePosts(response.map((article: ArticleBase) => ({ article }))))
      }
    })
  }, [dispatch])

  const loadArticles = (url: string) => {
    try {
      const testUrl = new URL(url)
      fetch$.current?.next(url)
    } catch (_e) {
      dispatch(message(props.intl.formatMessage({ id: 'errors.invalid-url' }), 'error'))
    }
    setValue('')
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const url = (e.target as HTMLInputElement).value.trim()
    if (url && e.key === 'Enter') {
      loadArticles(url)
    }
  }

  const onChange = (e: any) => {
    setValue(e.target.value)
  }

  const createPosts = () => {
    const url = rssFeedUrlInputRef.current?.value
    loadArticles(url || '')
  }

  return (
    <div className={styles.container} data-testid="rss-article-upload">
      <Input
        disableUnderline
        value={value}
        inputRef={rssFeedUrlInputRef}
        classes={{ root: styles['search-input'] }}
        placeholder={props.intl.formatMessage({
          id: 'composer.labels.submit-rss-feed-url'
        })}
        name="rss-feed-url"
        onKeyDown={onKeyDown}
        onChange={onChange}
      />
      <div>
        <Button
          color="primary"
          variant="contained"
          disabled={loading || !value.trim()}
          className={styles.btn}
          onClick={createPosts}
        >
          <FormattedMessage id="composer.multi-post.actions.upload-articles" />
        </Button>
      </div>
      <div className={styles.link}>
        <Link to="/content/search/sources">
          <FormattedMessage id="composer.multi-post.actions.find-feeds" />
        </Link>
      </div>
    </div>
  )
}

export default injectIntl(RSSArticlesUpload)
