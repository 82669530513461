import * as React from 'react'
import IconCopy from '@mui/icons-material/FileCopyOutlined'
import styles from './Credits.pcss'
import { FormattedMessage } from 'react-intl'
import Tooltip from '@mui/material/Tooltip'

const Clipboard = require('clipboard')

function CopyText(props: {
  title?: React.ReactNode,
  messageTitle?: string,
  messageText?: string,
  showFullText?: boolean,
   onTextCopied: () => void
  }) {
  const clipboard = React.useRef(null)
  const getClipboardText = () => `${props.messageTitle ? props.messageTitle + '\n' : ''}${props.messageText}`

  const setRef = (element: HTMLElement | null) => {
    if (element) {
      if (clipboard.current) {
        (clipboard.current as any).destroy()
      }

      clipboard.current = new Clipboard(
        element,
        {
          text: getClipboardText,
          container: element
        }
      )
      ; (clipboard.current as any).on('success', () => {
        props.onTextCopied()
      })
      ; (clipboard.current as any).on('error', (e: any) => {
        console.log('Clipboard error', e)
      })
    }
  }

  return (
    <div className={styles['list-item']}>
      <Tooltip title={<FormattedMessage id="actions.copy" />}>
        <div className={styles.icon} ref={setRef}>
          <IconCopy />
        </div>
      </Tooltip>
      <div className={styles['text-box']}>
        {props.title && (
          <div className={styles.title}>
            {props.title}
          </div>
        )}
        {props.messageTitle && (
          <div className={`${styles.text} ${styles.bold} text-ellipsis`}>
            {props.messageTitle}
          </div>
        )}
        {props.messageText && (
          <div className={`${styles.text} ${props.showFullText ? '' : 'text-ellipsis'}`}>{props.messageText}</div>
        )}
      </div>
    </div>
  )
}

export default CopyText
