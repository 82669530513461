import * as React from 'react'
import styles from './Composer.pcss'
import { useDispatch, useSelector } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { FetchSavedTexts } from 'components/Fetch'
import ProfileSelection from './ProfileSelection'
import Header from './Header'
import Content from './Content'
import Network from './Network'
import Actions from './Actions'
import {
  composerIsEmptySelector,
  activePostIdSelector,
  multiPostsCountSelector
} from 'services/compose/selectors'
import ComposerFilesDropHandler from './ComposerFilesDropHandler'
import { ComposerUploaderContext, uploaderFilesReducer } from './UploadContext'
import { resetComposer, setComposerBulkUploadFileUrl, setComposerStatus } from 'services/compose'
import { calloutsSelector } from 'services/ui/selectors'
import { toggleCallout } from 'services/ui/actions'
import { PageCallout } from 'components/PageCallout'

declare const __DEV__: boolean

export function Composer(props: { onClose: (reset?: boolean, redirectUrl?: string) => void }) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const composerCallout = useSelector(calloutsSelector).find(c => c.slug === 'composer')
  const multiPostsCount = useSelector(multiPostsCountSelector)
  const [files, uploaderDispatch] = React.useReducer(uploaderFilesReducer, {})
  const [mode, setMode] = React.useState<'default' | 'bulk'>(multiPostsCount > 0 ? 'bulk' : 'default')
  const [dropOverlayVisible, setDropOverlayVisible] = React.useState(false)
  const isEmpty = useSelector(composerIsEmptySelector)
  const isEditingPost = Boolean(useSelector(activePostIdSelector))

  React.useEffect(() => {
    if (multiPostsCount > 0) {
      setMode('bulk')
    }
  }, [multiPostsCount])

  const cancelEvent = (e: Event) => {
    e.preventDefault()
  }

  const dismissCallout = () => {
    dispatch(toggleCallout('composer'))
  }

  React.useEffect(() => {
    window.addEventListener('dragover', cancelEvent, false)
    window.addEventListener('drop', cancelEvent, false)

    return () => {
      window.removeEventListener('dragover', cancelEvent)
      window.removeEventListener('drop', cancelEvent)
    }
  }, [])

  const onUnload = React.useCallback((event: Event) => {
    event.preventDefault()
    event.returnValue = false
    return ''
  }, [])

  React.useEffect(() => {
    if (__DEV__) {
      return
    }
    if (!isEmpty) {
      window.addEventListener('beforeunload', onUnload)
    }
    return () => {
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [isEmpty, onUnload])

  const toggleMode = (value: 'default' | 'bulk') => {
    if (value === 'bulk') {
      setMode('bulk')
      dispatch(setComposerStatus({ network: 'generic', text: '', html: '' }))
    } else {
      setMode('default')
      dispatch(setComposerBulkUploadFileUrl({ url: undefined }))
      dispatch(resetComposer())
    }
  }

  const closeAndReset = (redirectUrl?: string) => {
    props.onClose(true, redirectUrl)
  }

  const showDropOverlay = React.useCallback((e: any) => {
    if (mode === 'default' && e.dataTransfer.types.includes('Files')) {
      setDropOverlayVisible(true)
    }
  }, [mode])

  const hideDropOverlay = React.useCallback(() => {
    setDropOverlayVisible(false)
  }, [])

  return (
    <ComposerUploaderContext.Provider value={{ files, dispatch: uploaderDispatch }}>
      <ComposerFilesDropHandler visible={dropOverlayVisible} onClose={hideDropOverlay} />
      <div
        className={styles.composer}
        data-test="composer"
        onDragEnter={showDropOverlay}
        onDragEnd={hideDropOverlay}
      >
        {composerCallout && <PageCallout data={composerCallout} onClose={dismissCallout} />}
        <Header activeView={mode} onActiveViewChange={toggleMode} onClose={props.onClose} />
        {!isEditingPost && <ProfileSelection />}
        {!isEditingPost && <Content activeView={mode} />}
        <Network activeView={mode} />
        <Actions activeView={mode} onPostCreateStarted={closeAndReset} />
        <FetchSavedTexts />
      </div>
    </ComposerUploaderContext.Provider>
  )
}

export default Composer
