import { FacebookInitOptions } from './FacebookInitOptions'
import { FacebookLoginResponse } from './FacebookLoginResponse'
import { FacebookLoginOptions } from './FacebookLoginOptions'

export declare type WindowWithFacebook = { fbAsyncInit: () => void }

export interface Facebook {
  Event: {
    subscribe(event: string, callback: (...args: any) => void): void
    unsubscribe(event: string, callback: (...args: any) => void): void
  }

  XFBML: {
    parse(element?: string | HTMLElement, callback?: () => void): void
  }

  /**
   * Initializes Facebook SDK
   *
   * @param {FacebookInitOptions} options
   *
   * @memberOf Facebook
   */
  init(options: FacebookInitOptions): void

  /**
   * Get the current login status
   *
   * @param {(response: FacebookLoginResponse) => void} callback
   *
   * @memberOf Facebook
   */
  getLoginStatus(callback: (response: FacebookLoginResponse) => void, forceRequest?: boolean): void

  /**
   * Start the login procedure
   *
   * @param {(response: FacebookLoginResponse) => void} callback
   * @param {FacebookLoginOptions} [options]
   *
   * @memberOf Facebook
   */
  login(callback: (response: FacebookLoginResponse) => void, options?: FacebookLoginOptions): void

  /**
   * Log out of Facebook
   *
   *
   * @memberOf Facebook
   */
  logout(callback: (response: any) => any): void

  /**
   * Make an API call
   *
   * @param {string} url
   * @param {any} options
   * @param {(response: T) => void} callback
   *
   * @memberOf Facebook
   */
  api<T>(url: string, options: any, callback: (response: T) => void): void

  /**
   * Gets the current access token
   *
   */
  getAccessToken(): string
}

export const FB_SDK_VERSION = 'v15.0'

export default Facebook
