import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getTikTokProfilePrivacyOptions } from 'services/compose/tiktok/net'
import { HTTP_STATUS_UNAUTHORIZED, PostDestination, TikTokPostProfileSettings, TikTokPrivacyOptions } from 'shared'
import { StoreThunkDispatch } from 'store/state'
import Icon from '@mdi/react'
import { mdiAlertCircleOutline } from '@mdi/js'
import styles from './TikTokOptions.pcss'
import { getTikTokProfileSettings } from 'services/compose/tiktok/actions'
import { setTikTokProfileSettings } from 'services/compose'
import { TikTokPostSettings } from './TikTokPostSettings'
import { CircularProgress } from '@mui/material'
import { ALLOW_PERSIST_TIKTOK_SETTINGS } from 'config'
import { NavLink } from 'react-router-dom'

interface TikTokProfileSettingsProps {
  profile: PostDestination
  settings?: TikTokPostProfileSettings
  options?: TikTokPrivacyOptions
  className?: string
  showLoader?: boolean
  skipFetchSettings?: boolean
  ignoreSavedSettings?: boolean
  hideSaveOption?: boolean
  onChange?: (settings: TikTokPostProfileSettings) => void
}

export function TikTokProfileSettings(props: TikTokProfileSettingsProps) {
  const { profile, settings, className, showLoader, ignoreSavedSettings, hideSaveOption, skipFetchSettings, onChange } = props
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [options, setOptions] = useState<TikTokPrivacyOptions | null>(props.options || null)
  const [error, setError] = useState<null | React.ReactNode>(null)
  const [loading, setLoading] = useState(false)
  const settingsRef = useRef(settings)

  useEffect(() => {
    settingsRef.current = settings
    let error = !settings?.promoting ? 'Promotion type not selected!' : null
    if (!settings?.privacyLevel) {
      error = 'Privacy level not selected!'
    }
    setError(error)
  }, [settings, settings?.enableComment, settings?.enableDuet, settings?.enableStitch, settings?.privacyLevel, settings?.promoting])

  const defaultSettings: TikTokPostProfileSettings = {
    ppid: profile.ppid,
    privacyLevel: '',
    enableComment: false,
    enableDuet: false,
    enableStitch: false,
    promoting: '' as any
  }

  useEffect(() => {
    if (skipFetchSettings) {
      return
    }
    setLoading(true)
    dispatch(getTikTokProfilePrivacyOptions(profile.ppid)).zip(dispatch(getTikTokProfileSettings(profile.ppid)))
      .toPromise()
      .then(([options, saved]) => {
        const o = options.settings
        const currentSettings = settingsRef.current
        const nextOptions = {
          privacyLevel: o.privacyLevelOptions,
          enableComment: o.enableComment.map(Boolean),
          enableDuet: o.enableDuet.map(Boolean),
          enableStitch: o.enableStitch.map(Boolean),
          maxDuration: o.maxDuration
        }

        const nextSettings = ALLOW_PERSIST_TIKTOK_SETTINGS && !ignoreSavedSettings ? {
          privacyLevel: saved?.privacyLevel || currentSettings?.privacyLevel,
          enableComment: saved?.enableComment || currentSettings?.enableComment,
          enableDuet: saved?.enableDuet || currentSettings?.enableDuet,
          enableStitch: saved?.enableStitch || currentSettings?.enableStitch,
          promoting: saved?.promoting || '' as any
        } : currentSettings

        setOptions(nextOptions)
        setError(null)

        const updatedSettings: TikTokPostProfileSettings = {
          ppid: profile.ppid,
          privacyLevel: nextOptions.privacyLevel.includes(nextSettings?.privacyLevel) ? nextSettings?.privacyLevel as string : '',
          enableComment: nextOptions.enableComment.includes(nextSettings?.enableComment)
            ? nextSettings?.enableComment
            : nextOptions.enableComment[0],
          enableDuet: nextOptions.enableDuet.includes(nextSettings?.enableDuet)
            ? nextSettings?.enableDuet
            : nextOptions.enableDuet[0],
          enableStitch: nextOptions.enableStitch.includes(nextSettings?.enableStitch)
            ? nextSettings?.enableStitch
            : nextOptions.enableStitch[0],
          promoting: nextSettings?.promoting || '' as any
        }

        if (onChange) {
          onChange(updatedSettings)
        } else {
          dispatch(setTikTokProfileSettings(updatedSettings))
        }
      })
      .catch(e => {
        console.error(e)
        if (e.response?.error?.code === HTTP_STATUS_UNAUTHORIZED && e.response.error.data?.type === 'tiktok') {
          setError(
            <React.Fragment>
              <span>We could not load the privacy settings for <b>{profile.name}</b>. </span>
              <span>
                <NavLink to="/settings/networks">Reset your TikTok connection</NavLink> and allow all permissions Post Planner asks for.
              </span>
            </React.Fragment>
          )
        } else {
          setError(
            <React.Fragment>
              We could not load your privacy settings for <b>{profile.name}</b>. Please try again later.
            </React.Fragment>
          )
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [dispatch, profile.name, profile.ppid, onChange, ignoreSavedSettings, skipFetchSettings])

  const profileOptions = options || props.options

  return (
    <div className={`${styles['profile-settings']} ${className || ''}`} data-testid="tiktok-profile-settings">
      {profileOptions && (
        <TikTokPostSettings
          profile={profile}
          settings={settings || defaultSettings}
          options={profileOptions}
          hideSaveOption={hideSaveOption}
          onChange={onChange}
        />
      )}
      {error && !loading && (
        <div className={styles.error}>
          <Icon path={mdiAlertCircleOutline} size="20px" color="#ff7676" />
          <span>{error}</span>
        </div>
      )}
      {loading && showLoader && (
        <div className={styles['loader-box']}>
          <CircularProgress size={20} />
        </div>
      )}
    </div>
  )
}
