import * as React from 'react'
import { AppNavLink } from '../AppNavLink'
import { FormattedMessage, injectIntl } from 'react-intl'
import PostProfilesSelector from './PostProfilesSelector'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from '../AppNavigation.pcss'
import { Bucket, WithIntl } from 'interfaces'
import BucketUpdatePopup from 'routes/publishing/routes/buckets/components/BucketUpdatePopup'
import BucketCreatePopup from 'routes/publishing/routes/buckets/components/BucketCreatePopup'
import BucketsNavMenu from './BucketsNavMenu'
import { useDispatch, useSelector } from 'react-redux'
import { getBuckets } from 'services/post/actions'
import { StoreThunkDispatch } from 'store/state'
import { PlansNavigation } from './PlansNavigation'
import { failedPostsCountSelector } from 'services/post/selectors'
import Tooltip from '@mui/material/Tooltip'

interface PublishingNavProps {
  hideProfiles?: boolean
  activeView: 'calendar' | 'posts' | 'plans' | 'history'
}

export function PublishingNav(props: PublishingNavProps & WithIntl) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const location = useLocation()
  const navigate = useNavigate()
  const [openSubnav, setOpenSubnav] = React.useState<null | 'queue' | 'buckets'>(null)
  const [selectedUpdateBucket, setSelectedUpdateBucket] = React.useState<Bucket | null>(null)
  const [bucketCreatePopupOpen, setBucketCreatePopupOpen] = React.useState<boolean>(false)
  const failedPosts = useSelector(failedPostsCountSelector)

  React.useEffect(() => {
    if (location.pathname.startsWith('/posts/buckets')) {
      setOpenSubnav('buckets')
    } else if (location.pathname.startsWith('/posts/planned')) {
      setOpenSubnav('queue')
    }
  }, [location.pathname])

  const toggleItemExpanded = (item: 'queue' | 'buckets') => () => {
    setOpenSubnav(current => current === item ? null : item)
  }

  const closeBucketUpdatePopup = (updated?: boolean) => {
    setSelectedUpdateBucket(null)
    if (updated) {
      dispatch(getBuckets())
    }
  }

  const openBucketCreatePopup = () => {
    setBucketCreatePopupOpen(true)
  }

  const closeBucketCreatePopup = () => {
    setBucketCreatePopupOpen(false)
  }

  const onBucketCreated = (b: Bucket) => {
    closeBucketCreatePopup()
    navigate(`/posts/buckets/${b.id}/posts`)
  }

  const showFailedPostsBadge = failedPosts.total > 0

  return (
    <nav>
      <h1 className={styles['subnav-title']}>
        <FormattedMessage id={`app.nav.title-${props.activeView}`} />
      </h1>
      {props.activeView === 'posts' && (
        <React.Fragment>
          <AppNavLink
            to="/posts/planned/all"
            label={<FormattedMessage id="app.nav.publishing-planned-planned" />}
          />
          <BucketsNavMenu
            expanded={openSubnav === 'buckets'}
            onCreateClick={openBucketCreatePopup}
            onEditBucketClick={setSelectedUpdateBucket}
            onToggleExpanded={toggleItemExpanded('buckets')}
          />
          <AppNavLink
            to="/posts/planned/drafts"
            label={<FormattedMessage id="app.nav.publishing-drafts" />}
          />
        </React.Fragment>
      )}
      {props.activeView === 'history' && (
        <React.Fragment>
          <AppNavLink
            label={<FormattedMessage id="app.nav.publishing-posted-posts" />}
            to="/history/posted"
            forceExpanded
          />
          <AppNavLink
            label={(
              <span className={styles['label-failed']}>
                <FormattedMessage id="app.nav.publishing-failed-posts" />
                {showFailedPostsBadge && (
                  <Tooltip title="You have failed posts">
                    <div className={styles['notitication-badge']}></div>
                  </Tooltip>
                )}
              </span>
            )}
            to="/history/failed"
          />
        </React.Fragment>
      )}
      {!props.hideProfiles && props.activeView !== 'plans' && (
        <PostProfilesSelector hidden={false} activeView={props.activeView} />
      )}
      {selectedUpdateBucket && (
        <BucketUpdatePopup bucket={selectedUpdateBucket} onClose={closeBucketUpdatePopup} />
      )}
      <BucketCreatePopup open={bucketCreatePopupOpen} onClose={closeBucketCreatePopup} onCreated={onBucketCreated} />
      {props.activeView === 'plans' && <PlansNavigation />}
      <div data-buckets-nav></div>
    </nav>
  )
}

export default injectIntl(PublishingNav)
