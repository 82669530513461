import * as React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import {
  Feed,
  ContentSource,
  RangeFilter,
  FilterType,
  Stream,
  WithIntl,
  InteractionEvent,
  ContentType
} from 'interfaces'
import ContentLayout from 'components/ContentLayout'
import StreamView from 'components/StreamView'
import { recentSourcesSelector } from 'services/content/selectors'
import { getRecentSources, addRecentSource, trackStatusIdeasCategoryInteraction } from 'services/content/recommended/actions'
import { getStreams } from 'services/content/streams/actions'
import { Observable } from 'rxjs/Observable'
import { Subscription } from 'rxjs/Subscription'
import { Subject } from 'rxjs/Subject'
import { Routes, Route, useLocation, Navigate, useNavigate, useParams, useMatch } from 'react-router-dom'
import { URL_SEGMENT_STATUS, URL_SEGMENT_FILE_FOLDER, URL_SEGMENT_FEED } from 'config'
import * as FilterStore from 'utils/filter-store'
import { CONTENT_TYPE_TO_URL_SEGMENT_MAP, URL_SEGMENT_TO_CONTENT_TYPE_MAP } from 'services/content/adapters/mappings'
import RecentlyViewedRootView from './components/RecentlyViewedRootView'
import STATUSES_CATEGORIES, { statusIdeaFeeds } from 'routes/find/routes/statuses/statuses.config'
import { CuratedStatusesFolderView } from 'components/CuratedFolderView'
import { WrappedComponentProps, injectIntl, useIntl } from 'react-intl'
import MyUploadsFolderView from 'routes/find/routes/my-uploads/routes/MyUploadsFolderView'
import { getUploads } from 'services/uploads/actions'
import { injectRouter, WithRouterProps } from 'components/App/injectRouter'
import { RouteErrorView } from 'components/App/components/ErrorFallback/RouteErrorView'

const BASE_URL = '/content/recently-viewed'

interface ConnectedRecentlyViewedProps {
  getRecentSources: (page: number) => Observable<ContentSource[]>
  getFileFolders: () => Promise<any>
  getUserStreams: () => Observable<any>
}

export type RecentlyViewedProps = ConnectedRecentlyViewedProps & WithIntl & WithRouterProps

interface RecentlyViewedState {
  loading: boolean
}

export class RecentlyViewed extends React.Component<RecentlyViewedProps, RecentlyViewedState> {
  private fetchSubscription$: Subscription
  private unmount$: Subject<boolean>

  constructor(props: RecentlyViewedProps) {
    super(props)
    this.state = { loading: true }
  }

  componentDidMount() {
    this.unmount$ = new Subject()
    this.fetchSubscription$ = this.props.getRecentSources(1)
      .zip(this.props.getUserStreams(), Observable.fromPromise(this.props.getFileFolders()))
      .takeUntil(this.unmount$)
      .subscribe(() => {
        this.setState(prevState => ({ ...prevState, loading: false }))
      }, (err) => {
        console.log('[RV fetch data] Error: ', err)
        this.setState(prevState => ({ ...prevState, loading: false }))
      })
  }

  componentWillUnmount() {
    this.unmount$.next(true)
    if (this.fetchSubscription$) {
      this.fetchSubscription$.unsubscribe()
    }
  }

  render() {
    const errorElement = <RouteErrorView />
    return (
      <div data-test="recently-viewed">
        <Routes>
          <Route
            path={`${URL_SEGMENT_FILE_FOLDER}/:folderId`}
            element={(
              <MyUploadsFolderView backUrl={this.props.location.state?.backUrl || BASE_URL} />
            )}
            errorElement={errorElement}
          />
          <Route path={`${URL_SEGMENT_STATUS}/:category`} element={<StatusIdeaRoute />} errorElement={errorElement} />
          <Route path={`${URL_SEGMENT_FEED}/:id/:filter/:range`} element={<StreamFeedRoute />} errorElement={errorElement} />
          <Route path=":type/:id/*" element={<StreamFeedRoute />} errorElement={errorElement} />
          <Route path="/" element={<RecentlyViewedRootView />} errorElement={errorElement} />
        </Routes>
      </div>
    )
  }
}

interface RecentlyViewedStreamRouteProps {
  stream: Stream
  onAddRecentSource: (source: ContentSource) => void
  onNavigateBack: (url?: string) => void
}

class RecentlyViewedStreamRoute extends React.Component<RecentlyViewedStreamRouteProps> {
  componentDidMount(): void {
    this.props.onAddRecentSource(this.props.stream)
  }

  render() {
    return (
      <StreamView
        stream={this.props.stream}
        showBackLink
        navigation={{ prefix: `${BASE_URL}/stream/${this.props.stream.id}`, id: this.props.stream.id }}
        onBackClick={this.props.onNavigateBack}
      />
    )
  }
}

const mapDispatchToProps = (dispatch: StoreThunkDispatch) => ({
  getRecentSources: (page: number) => Observable.fromPromise(dispatch(getRecentSources(page)).unwrap()),
  getFileFolders: () => dispatch(getUploads(false)).unwrap(),
  getUserStreams: () => Observable.fromPromise(dispatch(getStreams(false)).unwrap())
})

const RecentlyViewedWithRouter = injectRouter(RecentlyViewed) as React.ComponentType<WrappedComponentProps<'intl'>>
export default injectIntl(connect(null, mapDispatchToProps)(RecentlyViewedWithRouter))

function StreamFeedRoute() {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const navigate = useNavigate()
  const matchParams = useParams() as { id: string, filter: string, type: string, range: string }
  const feedMatch = useMatch('/content/recently-viewed/:type/:id/:filter/:range')
  const location = useLocation()
  const recentSources = useSelector(recentSourcesSelector)
  const { id, filter, type } = matchParams
  const range = matchParams.range as RangeFilter
  const validRange = FilterStore.assureValidRange(range)
  let validFilter = FilterStore.assureValidType(URL_SEGMENT_TO_CONTENT_TYPE_MAP[filter || ''])
  const source = id && recentSources.length !== 0 ? recentSources.find(item => item.id === id) : undefined
  const sourceFilters = (source as any)?.sources || (source as any)?.filters
  // Do not check "all" and "stock-all" filters...
  if (sourceFilters && !validFilter.includes('all') && !sourceFilters.includes(validFilter as ContentType)) {
    validFilter = sourceFilters[0]
  }

  const navigateBack = React.useCallback(() => {
    navigate(location.state?.backUrl || BASE_URL)
  }, [navigate, location.state?.backUrl])

  const onFilterChanged = React.useCallback((filter: FilterType) => {
    const newPath = feedMatch?.pattern.path.replace(':id', matchParams.id)
      .replace(':type', URL_SEGMENT_FEED)
      .replace(':filter', CONTENT_TYPE_TO_URL_SEGMENT_MAP[filter])
      .replace(':range', matchParams.range)

    if (newPath) {
      navigate(newPath, { replace: true })
    }
  }, [navigate, matchParams.id, matchParams.range, feedMatch?.pattern.path])

  const onRangeChanged = React.useCallback((range: RangeFilter) => {
    const nextPath = feedMatch?.pattern.path.replace(':id', matchParams.id)
      .replace(':type', URL_SEGMENT_FEED)
      .replace(':filter', matchParams.filter)
      .replace(':range', range)

    if (nextPath) {
      navigate(nextPath, { replace: true })
    }
  }, [feedMatch?.pattern.path, matchParams.id, matchParams.filter, navigate])

  // EXPL: If requested source is not in user's most recent, redirect to root source url: /content/feeds(streams)/id
  if (id && !source) {
    const type = matchParams.type || feedMatch?.params.type
    const rootSourceUrl = `/content/${type}s/${id}`
    return <Navigate to={rootSourceUrl} />
  }

  const onAddRecent = () => {
    if (source) {
      dispatch(addRecentSource(source))
    }
  }

  const onNavigateBackFromStream = () => {
    if (location.state?.backUrl) {
      navigate(location.state?.backUrl)
    } else {
      navigateBack()
    }
  }

  if (source && type === 'stream') {
    return (
      <RecentlyViewedStreamRoute
        stream={source as Stream}
        onAddRecentSource={onAddRecent}
        onNavigateBack={onNavigateBackFromStream}
      />
    )
  }

  // EXPL: If range or filter are missing in url or invalid, get them from the store and reload
  if (id && source && type === 'feed' && (range !== validRange || filter !== CONTENT_TYPE_TO_URL_SEGMENT_MAP[validFilter])) {
    const souceFilters = (source as Feed).sources
    const storedFilters = FilterStore.getFeedFilters(source as Feed)
    const isUrlFilterValid = souceFilters.includes(URL_SEGMENT_TO_CONTENT_TYPE_MAP[filter] as ContentType)
    const validFilterForFeed = souceFilters.includes(storedFilters.type as ContentType)
      ? CONTENT_TYPE_TO_URL_SEGMENT_MAP[storedFilters.type]
      : CONTENT_TYPE_TO_URL_SEGMENT_MAP[souceFilters[0]]
    const sourceFilter = isUrlFilterValid ? filter : validFilterForFeed
    const sourceRange = range || storedFilters.range
    return <Navigate to={{ pathname: `${BASE_URL}/${type}/${id}/${sourceFilter}/${sourceRange}` }} state={location.state} />
  }

  if (!source) {
    return null
  }

  const contentTypeFilter = URL_SEGMENT_TO_CONTENT_TYPE_MAP[filter]

  return (
    <ContentLayout
      range={range}
      filter={contentTypeFilter}
      key={source.id}
      source={source as any}
      expanded
      onCollapsed={navigateBack}
      onFilterChanged={onFilterChanged}
      onRangeChanged={onRangeChanged}
    />
  )
}

function StatusIdeaRoute() {
  const intl = useIntl()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const navigate = useNavigate()
  const matchParams = useParams<{ category: string }>()
  const location = useLocation()
  const category = STATUSES_CATEGORIES.find(cat => cat.slug === matchParams.category)
  const feed = statusIdeaFeeds.find(f => f.handle === matchParams.category)

  if (!category) {
    return <Navigate to="/content/statuses" />
  }

  const recordInteraction = (categoryIds: string[], event: InteractionEvent) => {
    if (feed) {
      dispatch(addRecentSource(feed))
    }

    return dispatch(trackStatusIdeasCategoryInteraction({ categoryIds, event })).unwrap()
  }

  const navigateBack = () => {
    navigate(location.state?.backUrl || BASE_URL)
  }

  return (
    <CuratedStatusesFolderView
      folder={category}
      navigation={{
        more: '',
        back: intl.formatMessage({ id: 'general.carousel.nav.back' }),
        prefix: `${BASE_URL}/${URL_SEGMENT_STATUS}`
      }}
      onBackClick={navigateBack}
      trackInteraction={recordInteraction}
    />
  )
}
