import React, { useContext } from 'react'
import styles from './CheckoutProgress.pcss'
import { SignupContext } from '../state/context'

interface SignupProgressProps {
  activeStep: 'create-account' | 'payment-details'
  className?: string
}

const steps = [1, 2, 3, 4] // eslint-disable-line no-magic-numbers

export function CheckoutProgress({ activeStep, className }: SignupProgressProps) {
  const [{ progress: viewConfig }] = useContext(SignupContext)

  return (
    <div className={`${styles['signup-progress']} ${className || ''}`}>
      <div className={styles['progress-row-1']}>
        <div className={`${styles['step-number']} ${styles.active}`}>1</div>
        <div className={`${styles['connector-1']} ${styles.complete}`}></div>
        <div className={`${styles['step-number']} ${styles.active}`}>2</div>
        <div className={`${styles['connector-2']} ${styles[activeStep === 'create-account' ? 'active' : 'complete']}`}></div>
        <div className={`${styles['step-number']} ${activeStep === 'payment-details' ? styles.active : ''}`}>3</div>
        <div className={`${styles['connector-2']} ${styles[activeStep === 'payment-details' ? 'active' : '']}`}></div>
        <div className={styles['step-number']}>4</div>
      </div>
      <div className={styles['progress-row-2']}>
        {steps.map((step) => (
          <span key={step}>{viewConfig[`Step-${step}`]}</span>
        ))}
      </div>
    </div>
  )
}
