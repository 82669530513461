import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'

const ENDPOINT_GET_LOCATIONS = `${LEGACY_URL}/v3/business_listings`
const ENDPOINT_CREATE_LOCATION = `${LEGACY_URL}/v3/business_listings`
const ENDPOINT_ADD_LOCATION_SLOT_ANNUAL = `${LEGACY_URL}/v3/business_listings/add_component/location-annual`
const ENDPOINT_ADD_LOCATION_SLOT_MONTHLY = `${LEGACY_URL}/v3/business_listings/add_component/location`

export function getLocations() {
  const builder = new RequestBuilder(ENDPOINT_GET_LOCATIONS).asGet().expectJSON()
  return authorizedPipe<any>(builder.build())
}

export function addLocationSlot(annual: boolean) {
  const endpoint = annual ? ENDPOINT_ADD_LOCATION_SLOT_ANNUAL : ENDPOINT_ADD_LOCATION_SLOT_MONTHLY
  const builder = new RequestBuilder(endpoint).asPost().expectJSON()

  return authorizedPipe<any>(builder.build())
}

export function addLocation(name: string) {
  const builder = new RequestBuilder(ENDPOINT_CREATE_LOCATION).asPost().expectJSON()
  const data = new FormData()
  data.set('name', name)

  return authorizedPipe<any>(builder.form(data).build())
}
