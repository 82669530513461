import { RequestBuilder, authorizedPipe } from 'services/net'
import { CONTENT_SERVICE_URL } from 'config'
import { InteractionEvent, InteractionTrackingSourceType, StreamRecommended } from 'interfaces'

export const api = Object.freeze({
  getRecentSources: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/interactions/recent`).asGet().expectJSON().withoutCredentials()

    return function (page: number, pageSize: number) {

      return authorizedPipe<any>(
        builder
          .param('event', 'open')
          .param('page', page.toString())
          .param('page_size', pageSize.toString())
          .build()
      )
    }
  }(),
  deleteInteraction: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/interactions/:type`).asDelete().withoutCredentials().asFormUrlEncoded()

    return function (sourceId: string, type: InteractionTrackingSourceType) {
      return authorizedPipe<any>(
        builder
          .partial(':type', type)
          .body({ source_id: sourceId })
          .build()
      )
    }
  }(),
  getPopularStreams: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/interactions/streams/popular`).asGet().expectJSON().withoutCredentials()

    return function () {
      return authorizedPipe<StreamRecommended[]>(
        builder
          .param('event', 'open')
          .build()
      )
    }
  }(),
  getIndustriesStreams: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/interactions/streams/industries`).asGet().expectJSON().withoutCredentials()

    return function () {
      return authorizedPipe<StreamRecommended[]>(
        builder
          .param('event', 'open')
          .build()
      )
    }
  }(),
  getSavedSources: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/interactions/streams/recent`).asGet().expectJSON().withoutCredentials()

    return function () {
      return authorizedPipe<StreamRecommended[]>(
        builder
          .param('event', 'open')
          .asFormUrlEncoded()
          .build()
      )
    }
  }(),
  registerFeedInteraction: function () {
    return function (feedIds: string[], event: InteractionEvent, isQuoteFeed?: boolean) {
      const endpoint = isQuoteFeed ? `${CONTENT_SERVICE_URL}/interactions/quote_feed` : `${CONTENT_SERVICE_URL}/interactions/feeds`
      const builder = new RequestBuilder(endpoint).asPost().withoutCredentials().asFormUrlEncoded()
      return authorizedPipe<any>(
        builder
          .body({
            event,
            ids: feedIds.join(',')
          })
          .build()
      )
    }
  }(),
  registerFileFolderInteraction: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/interactions/my_library_folders`)
      .asPost().withoutCredentials().asFormUrlEncoded()

    return function (folderIds: string[], event: InteractionEvent) {
      return authorizedPipe<any>(
        builder
          .body({
            event,
            ids: folderIds.join(',')
          })
          .build()
      )
    }
  }(),
  registerStatusIdeasCategoryInteraction: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/interactions/status_idea_status_tag`)
      .asPost().withoutCredentials().asFormUrlEncoded()

    return function (categoryIds: string[], event: InteractionEvent) {
      return authorizedPipe<any>(
        builder
          .body({
            event,
            ids: categoryIds.join(',')
          })
          .build()
      )
    }
  }()
})
