import { PostDestination, PostDestinationType } from '../../types'
import { NETWORKS_ORDER } from '../../constants'

export function sortProfilesByNetworkDefault(p1: PostDestination, p2: PostDestination) {
  if (NETWORKS_ORDER[p1.type] < NETWORKS_ORDER[p2.type]) {
    return -1
  }
  return 1
}

export function sortNetworksDefault(n1: PostDestinationType, n2: PostDestinationType) {
  if (NETWORKS_ORDER[n1] < NETWORKS_ORDER[n2]) {
    return -1
  }
  return 1
}
