import * as React from 'react'
import SimpleLoader from 'components/SimpleLoader'
import { Feed, FEED_TYPE_KEYWORD, FilterType, WithIntl } from 'interfaces'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { SearchContext } from '../state/context'
import styles from './SearchRoot.pcss'
import ContentLayout from 'components/ContentLayout'
import { FetchFeed } from 'components/Fetch'
import { useDispatch } from 'react-redux'
import { Subject } from 'rxjs/Subject'
import { addRecentlyViewed } from 'services/search/actions'
import { StoreThunkDispatch } from 'store/state'
import { createFeed, getOriginalFeed } from 'services/content/feeds/actions'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { message } from 'services/snackbar'
import { injectIntl } from 'react-intl'
import { ADD_FEED, updateState } from '../state/actions'
import { resetScroll } from 'utils/dom'

export function SearchFeedRoute(props: WithIntl) {
  const location = useLocation()
  const params = useParams<{ id: string }>()
  const navigate = useNavigate()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [state, searchDispatch] = React.useContext(SearchContext)
  const [feed, setFeed] = React.useState<Feed | null>(null)
  const [loading, setLoading] = React.useState(false)
  const [filter, setFilter] = React.useState<FilterType | undefined>(location.state?.filter as FilterType || undefined)
  const scrollElementRef = React.useRef<HTMLElement | undefined>(undefined)
  const scrollConfig = scrollElementRef.current ? { element: scrollElementRef.current } : undefined

  const queryRef = React.useRef<string>(state.query)
  const create$ = React.useRef<Subject<string>>()

  React.useEffect(() => {
    create$.current = new Subject()
    create$.current
      .distinctUntilChanged()
      .switchMap(name => {
        setLoading(true)
        return Observable.fromPromise(dispatch(createFeed({ handle: name, type: FEED_TYPE_KEYWORD })).unwrap())
          .flatMap((response: any) => {
            return Observable.fromPromise(dispatch(getOriginalFeed(response.originalSource)).unwrap())
          })
          .pipe(catchError(() => {
            return Observable.of({ error: true })
          }))
      })
      .subscribe((response: any) => {
        setLoading(false)
        if (response.error) {
          dispatch(message(props.intl.formatMessage({ id: 'errors.generic-support' }), 'error'))
        } else {
          setFeed(response)
          searchDispatch(updateState({ activeFeed: response }))
          const redirectUrl = location.state.backUrl || `/content/search/sources?q=${queryRef.current}`
          navigate(redirectUrl, { state: { backUrl: location.state.backUrl } })
        }
      })

    return () => create$.current?.unsubscribe()
  }, [])

  React.useEffect(() => {
    scrollElementRef.current = document.querySelector('[data-test="main"]') as HTMLElement
    resetScroll()
  }, [])

  const onQueryChange = React.useCallback((next: string) => {
    if (!loading && feed && feed.name !== next) {
      const cachedFeed = state.createdKeywordFeeds.find(f => f.name === next)
      if (cachedFeed) {
        setFeed(cachedFeed)
      } else {
        setFeed(null)
        searchDispatch(updateState({ activeFeed: undefined }))
        create$.current?.next(queryRef.current)
      }
    }
  }, [loading, feed, state.createdKeywordFeeds, searchDispatch])

  React.useEffect(() => {
    if (queryRef.current !== state.query) {
      queryRef.current = state.query
      onQueryChange(state.query)
    }
  }, [state.query, onQueryChange])

  React.useEffect(() => {
    if (feed?.id) {
      dispatch(addRecentlyViewed({ source: feed, type: 'feed' }))
    }
  }, [dispatch, feed?.id])

  React.useEffect(() => {
    if (!feed && state.activeFeed?.id === params.id) {
      setFeed(state.activeFeed || null)
    }
  }, [params.id, state.activeFeed, feed])

  const trackFeedSaved = React.useCallback((feed: Feed) => {
    searchDispatch({ type: ADD_FEED, payload: feed })
    return true
  }, [dispatch, searchDispatch])

  const onFetched = React.useCallback((f: Feed) => {
    searchDispatch(updateState({ createdKeywordFeeds: [...state.createdKeywordFeeds, f] }))
    setFeed(f)
  }, [searchDispatch, state.createdKeywordFeeds])

  const onFetchFailed = React.useCallback(() => {
    dispatch(message(props.intl.formatMessage({ id: 'errors.generic-support' }), 'error'))
  }, [dispatch, props])

  const goBack = React.useCallback(() => {
    if (location.state?.backUrl) {
      navigate(`${location.state.backUrl}${location.search}`, { state: { filter } })
    } else {
      navigate(`/content/search/sources/${feed?.type || ''}?q=${state.query}`)
    }
  }, [location.state?.backUrl, location.search, navigate, filter, feed?.type, state.query])

  if (!feed) {
    return (
      <div>
        {!loading && <FetchFeed id={params.id as string} onFetched={onFetched} onFailed={onFetchFailed} />}
        <SimpleLoader small />
      </div>
    )
  }

  return (
    <div className={styles['grid-wrapper']}>
      <ContentLayout
        source={feed}
        expanded
        filtersBoxClassName={styles['grid-filters']}
        filter={filter}
        onCollapsed={goBack}
        onSaveSource={trackFeedSaved}
        onFilterChanged={setFilter}
        scroll={scrollConfig}
      />
    </div>
  )
}

export default injectIntl(SearchFeedRoute)
