import * as React from 'react'
import styles from './Common.pcss'

export function PostPreviewDefaultHeader(props: {
  withSubtitle?: boolean,
  instagram?: boolean,
  profile?: { name: string, image: string }
}) {
  const pictureStyles = props.profile ? { backgroundImage: `url(${props.profile.image})` } : undefined
  return (
    <header className={`${styles.header} ${props.instagram ? styles.ig : ''}`}>
      <div className={styles['picture-box']} style={pictureStyles}></div>
      <div className={styles.text}>
        {!props.profile && (
          <div className={styles['header-title']}></div>
        )}
        {props.profile && (
          <div className={`${styles['profile-name']} text-ellipsis`}>{props.profile.name}</div>
        )}
        {props.withSubtitle && <div className={styles.subtitle}></div>}
      </div>
    </header>
  )
}
