import * as React from 'react'
import { useSelector } from 'react-redux'
import { connectedDestinationsSelector } from 'services/destinations'
import { PostDestination } from 'interfaces'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import ProfileAndListSelector from 'routes/publishing/components/ProfileAndListSelector'
import styles from './PlanProfileSelector.pcss'
import IconAdd from '@mui/icons-material/AddCircleOutline'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'

const PAPER_ELEVATION = 8

interface PlanProfileSelectorProps {
  currentSelectedIds: string[]
  planName: string
  // selectedProfileIds: string[]
  // onSelectionChange: (ids: string[]) => void
  onConfirm: (ppids: string[]) => void
}

export function PlanProfileSelector(props: PlanProfileSelectorProps) {
  const { currentSelectedIds, onConfirm } = props
  const [selectedProfileIds, setSelectedProfileIds] = React.useState<string[]>([])
  const profiles: Record<string, PostDestination> = useSelector(connectedDestinationsSelector)
  const visibleProfiles = Object.keys(profiles).reduce((acc, id) => {
    if (!currentSelectedIds.includes(id)) {
      acc[id] = profiles[id]
    }
    return acc
  }, {} as Record<string, PostDestination>)
  const [activeView, setActiveView] = React.useState<'grid' | 'list' | 'network'>('grid')
  const [popoverAnchor, setPopoverAnchor] = React.useState<null | HTMLElement>(null)

  const selectAll = () => {
    setSelectedProfileIds(Object.keys(visibleProfiles))
  }

  const openDropdown = (e: React.MouseEvent) => {
    e.stopPropagation()
    setPopoverAnchor(e.target as HTMLElement)
  }

  const closeDropdown = () => {
    setPopoverAnchor(null)
    setSelectedProfileIds([])
  }

  const submit = () => {
    onConfirm(selectedProfileIds.map(id => profiles[id].ppid).filter(Boolean))
    closeDropdown()
  }

  return (
    <div className={styles.container}>
      <div className={styles['btn-add']} onClick={openDropdown}>
        <IconAdd className={styles['icon-add']} />
      </div>
      <Popper
        open={Boolean(popoverAnchor)}
        className={styles.popper}
        anchorEl={popoverAnchor}
        placement="bottom"
        popperOptions={{ placement: 'auto' }}
      >
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={closeDropdown}>
          <Paper
            className={styles.paper}
            elevation={PAPER_ELEVATION}
          >
            <ProfileAndListSelector
              profiles={visibleProfiles}
              lists={{}}
              profilesLabel={`Add these profiles to ${props.planName}`}
              withLists={false}
              selectedProfileIds={selectedProfileIds}
              multipleProfileSelection
              activeView={activeView}
              allowEmptySelection
              paperProps={{ elevation: 0 }}
              setActiveView={setActiveView}
              onSelectedProfilesChange={setSelectedProfileIds}
            />
            <div className={styles.actions}>
              {Object.keys(visibleProfiles).length > 0 && (
                <span role="button" className={styles['btn-select']} onClick={selectAll}>
                  <FormattedMessage id="actions.select-all" />
                </span>
              )}
              <Button onClick={closeDropdown}>
                <FormattedMessage id="actions.cancel" />
              </Button>
              <Button color="primary" variant="contained" disabled={selectedProfileIds.length === 0} onClick={submit}>
                <FormattedMessage id="actions.confirm" />
              </Button>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

export default PlanProfileSelector
