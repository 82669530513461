import * as React from 'react'
import { IntlProvider } from 'react-intl'

const messagesEN = require('translations/en.json')
const messagesFR = require('translations/fr.json')
const translations: { [language: string]: any } = {
  en: messagesEN,
  fr: messagesFR
}

export function PPIntlProvider(props: React.PropsWithChildren<any>) {
  return (
    <IntlProvider locale="en" messages={translations.en}>
      {props.children}
    </IntlProvider>
  )
}

export default PPIntlProvider
