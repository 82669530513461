import * as React from 'react'
import styles from './ComposerArticlePreview.pcss'
import { setPostAsPhoto } from 'services/compose'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { composerPostAsPhotoSelector } from 'services/compose/selectors'

interface ComposerArticlePreviewProps {
  article: {
    title: string
    url: string
    socialUrl?: string
    imageUrl: string
    description?: string
  }
  className?: string
}

export function ComposerArticlePreview(props: ComposerArticlePreviewProps) {
  const dispatch = useDispatch()
  const postAsPhoto = useSelector(composerPostAsPhotoSelector)

  const imageStyle = { backgroundImage: `url(${props.article.imageUrl})` }

  const onTogglePostAsPhoto = () => {
    dispatch(setPostAsPhoto(!postAsPhoto))
  }

  return (
    <div className={`${styles.article} ${props.className || ''}`} data-testid="composer-article-preview">
      <div className={styles['img-box']} style={imageStyle}>
        <FormControlLabel
          label={<FormattedMessage id="composer.labels.articles.post-as-photo" />}
          control={(
            <Checkbox
              size="small"
              checked={postAsPhoto}
              disableRipple
              color="primary"
              classes={{ checked: styles.checked }}
              onChange={onTogglePostAsPhoto}
            />
          )}
          className={styles.cb}
          classes={{ label: styles['cb-label'] }}
        />
      </div>
      <div className={`${styles.details} ${postAsPhoto ? styles.hidden : ''}`}>
        <a
          href={props.article.url}
          rel="noopener"
          target="_blank"
          className={`${styles.link} text-ellipsis`}
        >
          {props.article.url}
        </a>
        <h3 className={styles.title} title={props.article.title}>
          <a
            href={props.article.socialUrl || props.article.url}
            rel="noopener"
            target="_blank"
          >
            {props.article.title}
          </a>
        </h3>
        {props.article.description && (
          <p className={styles.description}>{props.article.description}</p>
        )}
      </div>
    </div>
  )
}
