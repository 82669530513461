import { StoreThunkAction } from 'store/state'
import { Observable } from 'rxjs/Observable'
import { V1, CopyPostsData } from './net'
import { tap } from 'rxjs/operators/tap'
import { HS_EVENT_POST_COPIED, trackHubspotEvent } from 'services/tracking/hubspot'

export function copyPosts(data: CopyPostsData): StoreThunkAction<Observable<any>> {
  return (dispatch) => {
    return dispatch(V1.copyPosts(data)).pipe(tap(() => {
      dispatch(trackHubspotEvent(HS_EVENT_POST_COPIED))
    }))
  }
}
