import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'

const ENDPOINT_GET_SAVED_STATUSES = `${LEGACY_URL}/v3/saved_post_text`
const ENDPOINT_CREATE_SAVED_STATUS = `${LEGACY_URL}/v3/saved_post_text/create`
const ENDPOINT_DELETE_SAVED_STATUSES = `${LEGACY_URL}/v3/saved_post_text/delete`
const ENDPOINT_UPDATE_SAVED_STATUS = `${LEGACY_URL}/v3/saved_post_text/update`
const ENDPOINT_REORDER_SAVED_STATUS = `${LEGACY_URL}/v3/saved_post_text/reorder`

export const V1 = Object.freeze({
  getSavedStatuses: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_SAVED_STATUSES).asGet().expectJSON()
    return function () {
      return authorizedPipe<any>(builder.build())
    }
  }(),
  saveStatus: function () {
    const builder = new RequestBuilder(ENDPOINT_CREATE_SAVED_STATUS).asPost().expectJSON().asFormUrlEncoded()
    return function (text: string, title: string) {
      return authorizedPipe<any>(
        builder.body({ text, title }).build()
      )
    }
  }(),
  updateSavedText: function () {
    const builder = new RequestBuilder(ENDPOINT_UPDATE_SAVED_STATUS).asPost().expectJSON().asFormUrlEncoded()
    return function (id: string, text: string, title: string) {
      return authorizedPipe<any>(
        builder.body({ id, text, title }).build()
      )
    }
  }(),
  reorderSavedTexts: function () {
    const builder = new RequestBuilder(ENDPOINT_REORDER_SAVED_STATUS).asPost().expectJSON().asFormUrlEncoded()
    return function (ids: string[]) {
      return authorizedPipe<any>(
        builder.body({ order: ids.join(',') }).build()
      )
    }
  }(),
  deleteSavedStatus: function () {
    const builder = new RequestBuilder(ENDPOINT_DELETE_SAVED_STATUSES).asPost().expectJSON().asFormUrlEncoded()
    return function (id: string) {
      return authorizedPipe<any>(
        builder.body({ text_id: id }).build()
      )
    }
  }()
})
