import * as React from 'react'
import { StatusesSearchProps, StatusesSearchResults } from 'components/CuratedFolderView/Statuses'
import styles from './QuotesSearch.pcss'
import { SuggestedQuoteSearches } from './SuggestedQuoteSearches'
import { StatusIdea, WithIntl } from 'interfaces'
import { Subject } from 'rxjs/Subject'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { tap } from 'rxjs/operators/tap'
import { QuotesContext, updateSearch } from '../QuotesContext'
import { getQuotes } from 'admin/services/quotes/actions'
import { catchError } from 'rxjs/operators/catchError'
import { message } from 'services/snackbar'
import { Observable } from 'rxjs/Observable'
import { injectIntl } from 'react-intl'

export const MIN_SEARCH_QUERY_LENGTH = 3

export function QuotesSearch(props: WithIntl & {
  hideSuggestions?: boolean,
  searchElement?: React.ReactNode,
  savedSearchesClassName?: string,
  align?: 'left' | 'center',
  hideSavedSearches?: boolean,
  onCompose?: (item: StatusIdea) => void | boolean
  onQueryClick?: (q: string, isTag?: boolean) => void
}) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const { search, dispatch: quotesDispatch } = React.useContext(QuotesContext)
  const lastSearchRef = React.useRef({ query: '', tag: '' })
  const pageRef = React.useRef(0)
  const searchRef = React.useRef<Subject<{ query: string, page: number, tag: string }>>()
  const [items, setItems] = React.useState<StatusIdea[]>([])

  React.useEffect(() => {
    searchRef.current = new Subject()
    searchRef.current
      .filter(({ query, tag }) => query.length >= MIN_SEARCH_QUERY_LENGTH || tag.length > 0)
      .pipe(tap(({ query, page, tag }) => {
        if (query !== lastSearchRef.current.query || tag !== lastSearchRef.current.tag) {
          setItems([])
        }
        pageRef.current = page
        lastSearchRef.current = { query, tag }
        quotesDispatch(updateSearch({ active: true, loading: true }))
      }))
      .switchMap(({ query, page, tag }) => dispatch(getQuotes(page, query, tag))
        .pipe(catchError((e) => {
          console.log(e)
          dispatch(message(props.intl.formatMessage({ id: 'errors.generic' }), 'error'))
          return Observable.of([])
        }))
      )
      .subscribe((data: StatusIdea[]) => {
        quotesDispatch(updateSearch({ hasNextPage: data.length > 0, loading: false }))
        setItems(items => items.concat(data))
      })

    return () => {
      searchRef.current?.unsubscribe()
    }
  }, [dispatch, props.intl, quotesDispatch])

  React.useEffect(() => {
    if (search.query.length > 0 || (search.tag && search.tag.length > 0)) {
      searchRef.current?.next({ query: search.query, page: search.page, tag: search.tag || '' })
    }
  }, [search.query, search.page, search.tag])

  const loadNextPage = () => {
    if (!search.loading && search.hasNextPage && !search.tag) { // tag search has no paging
      quotesDispatch(updateSearch({ page: search.page + 1 }))
    }
  }

  const onQueryChange = (value: string, isTag?: boolean) => {
    setItems([])
    const update: Partial<StatusesSearchProps> = { page: 0, hasNextPage: true }
    if (isTag) {
      update.tag = value
    } else {
      update.query = value
    }
    quotesDispatch(updateSearch(update))
    if (props.onQueryClick) {
      props.onQueryClick(value, isTag)
    }
  }

  const onTagClick = (q: string, isTag: boolean) => {
    onQueryChange(q, isTag)
  }

  return (
    <section className={styles.container}>
      {!props.hideSuggestions && (
        <SuggestedQuoteSearches
          activeQuery={search.query}
          tag={search.tag}
          searchElement={props.searchElement}
          className={props.savedSearchesClassName}
          hideSavedSearches={props.hideSavedSearches}
          onQueryClick={onQueryChange}
          onSuggestedSearchClick={onTagClick}
        />
      )}
      {search.active && (
        <StatusesSearchResults
          key={search.query}
          items={items}
          search={search}
          align={props.align}
          quotesView
          onLoadMore={loadNextPage}
          onCompose={props.onCompose}
        />
      )}
    </section>
  )
}

export default injectIntl(QuotesSearch)
