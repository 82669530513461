import { V1 } from './net'
import { StoreThunkDispatch } from 'store/state'

export function reorderPosts(ids: string[], moveToBottom?: boolean) {
  return (dispatch: StoreThunkDispatch) => {
    if (moveToBottom) {
      return dispatch(V1.moveToBottom(ids.pop() as string))
    }
    return dispatch(V1.reorderPosts(ids))
  }
}
