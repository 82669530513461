import * as React from 'react'
import { ContentType, ARTICLE_TYPE, VIDEO_TYPE, BRAND_PINTEREST, PostDetails, GIF_TYPE } from 'interfaces'
import PlayIcon from 'components/Icons/PlayIcon'
import BrokenImageIcon from '@mui/icons-material/BrokenImage'
import { FormattedMessage } from 'react-intl'
import { NativeVideoPlayer, FBVideoPlayer } from 'components/VideoPlayer'
import Button from '@mui/material/Button'
import GalleryIcon from '@mui/icons-material/Collections'
import { getImagesUrlsMap, IMAGE_THUMBNAILS_DIR } from 'shared'
import styles from './PostBase.pcss'

interface PostMediaProps {
  postId: string
  contentType: ContentType
  expanded: boolean
  attributes: {
    title?: string
    description?: string
    url?: string
    pinterestBoardUrl?: string
  } | null
  linkUrl?: string
  title?: string
  linkDomain?: string
  profileNetwork: string
  nativeVideoUrl?: string
  fbVideoUrl?: string
  images?: string[]
  details?: PostDetails
  onMediaPreviewBtnClick: (e: React.MouseEvent) => void
}

export const PostMedia = React.memo(({
  postId,
  contentType,
  title,
  linkUrl,
  linkDomain,
  profileNetwork,
  nativeVideoUrl,
  fbVideoUrl,
  images,
  details,
  attributes,
  expanded,
  onMediaPreviewBtnClick
}: PostMediaProps) => {
  const featuredImageUrls = React.useMemo(() => {
    const imageUrl = images && images[0]
    if (!imageUrl || contentType === GIF_TYPE) {
      return null
    }

    const urlArr = imageUrl.split('/')
    urlArr.splice(urlArr.length - 2, 1, IMAGE_THUMBNAILS_DIR)

    const thumb = getImagesUrlsMap([imageUrl])
    const isPPHosted = imageUrl.indexOf('postplanner.com') !== -1
    return {
      original: imageUrl,
      thumb: isPPHosted ? Object.keys(thumb)[0] : imageUrl
    }
  }, [images, contentType])

  const onImageError = React.useCallback((e: any) => {
    if (featuredImageUrls) {
      if (e.target && (e.target as HTMLImageElement).src !== featuredImageUrls.original) {
        (e.target as HTMLImageElement).src = featuredImageUrls.original
      }
    }
  }, [featuredImageUrls])

  const titleElement = (
    <h3 key="title" className={styles.title}>
      <a target="_blank" rel="noopener" href={linkUrl} title={title}>
        {title}
      </a>
    </h3>
  )

  const imageElement = featuredImageUrls
    ? <img key="image" src={featuredImageUrls.thumb} loading="lazy" className={styles.image} onError={onImageError} />
    : <BrokenImageIcon key="broken-image" className={styles['icon-broken-img']} />

  let albumLink = details?.photoAlbum && (
    <a
      href={attributes?.pinterestBoardUrl || `https://www.facebook.com/media/set/?set=a.${details?.photoAlbum.id}`}
      className={`${styles['album-link']} text-ellipsis`}
      rel="noopener"
      target="_blank"
      key="album"
    >
      <span className={styles['album-label']}>
        <FormattedMessage id={`label.generic.${profileNetwork === BRAND_PINTEREST ? 'board' : 'album'}`} />:
      </span>
      {details?.photoAlbum.name}
    </a>
  )

  if (!albumLink && details?.location) {
    albumLink = (
      <div className={`${styles['album-link']} text-ellipsis`} key="album-link">
        <span className={styles['album-label']}>
          <FormattedMessage id="label.generic.location" />:
        </span>
        {details?.location.name}
      </div>
    )
  }

  if (contentType === ARTICLE_TYPE) {
    return (
      <React.Fragment>
        <div key="image-box" className={styles['article-image-box']}>
          {imageElement}
          {albumLink}
        </div>
        <div key="attributes" className={styles['article-attr']}>
          {
            linkDomain && (
              <a key="article-link" href={linkUrl} target="_blank" rel="noopener">{linkDomain}</a>
            )
          }
          {titleElement}
        </div>
      </React.Fragment>
    )
  }

  if (nativeVideoUrl) {
    return (
      <React.Fragment>
        <NativeVideoPlayer
          videoUrl={nativeVideoUrl}
          thumbnailUrl={details?.videoThumbnailUrl}
          togglePlayOnHover={expanded}
          loop
          key="video-player"
          className={styles.video}
        />
        {albumLink}
      </React.Fragment>
    )
  }

  if (fbVideoUrl) {
    return (
      <FBVideoPlayer
        videoUrl={fbVideoUrl}
        videoId={postId}
        thumbnailUrl={featuredImageUrls?.original}
        togglePlayOnHover={expanded}
      />
    )
  }

  if (contentType === VIDEO_TYPE) {
    return (
      <React.Fragment>
        <PlayIcon center key="play-icon" />
        {imageElement}
        {titleElement}
      </React.Fragment>
    )
  }

  const btnSeeAll = images && images.length > 1 && (
    <Button
      key="see-all"
      startIcon={<GalleryIcon />}
      className={styles['btn-see-all']}
      onClick={onMediaPreviewBtnClick}
    >
      {`+${images?.length - 1}`}
    </Button>
  )

  return (
    <React.Fragment>
      {imageElement}
      {btnSeeAll}
      {albumLink}
    </React.Fragment>
  )
})
