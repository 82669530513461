import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import { FileFolder, FileType, HALF_ROUND_DEGREES, MyFile, PHOTO_ROTATION_TOGGLE } from 'interfaces'
import { FormattedMessage, useIntl } from 'react-intl'
import { ColorGradient } from 'utils/colors'
import styles from './EditFileFolderDialog.pcss'
import { ConnectedFileInput } from 'components/FileHelpers/ConnectedFileInput'
import { noop } from 'utils/noop'
import CreateFileFolderForm from 'components/CreateFileFolderForm'
import { MyFilePreview } from './MyFilePreview'
import { Subject } from 'rxjs/Subject'
import { Observable } from 'rxjs/Observable'
import { deleteFile, deleteFolder, getStorageInfo, setEditFolder, updateFolder } from 'services/uploads/actions'
import { useDispatch, useSelector } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { catchError } from 'rxjs/operators/catchError'
import { HTTP_STATUS_BAD_REQUEST } from 'services/net'
import { action, message } from 'services/snackbar'
import { NOTIFICATION_DURATION_LONG } from 'components/ConnectedSnackbar'
import { tap } from 'rxjs/operators/tap'
import Button from '@mui/material/Button'
import ConfirmDialog from 'components/ConfirmDialog'
import { FileUploadInfo } from 'services/uploads/state'
import MemoryFilePreviewCard from 'components/MemoryFilePreviewCard'
import { getFileTypeByMimeType, getMediaDimensions, mapFileTypeToSizeLimit } from 'utils/file/helpers'
import { myUploadsStorageSelector } from 'services/uploads/selectors'
import { useMatch, useNavigate } from 'react-router-dom'

interface EditFileFolderDialogOwnProps {
  folder: FileFolder
  fileUploads: { [name: string]: File }
  setFileUploads: (files: { [name: string]: File }) => void
  onUploadFiles: (files: Array<FileUploadInfo>, folders: FileFolder[]) => void
}

type EditFileFolderDialogProps = EditFileFolderDialogOwnProps

export function EditFileFolderDialog(props: EditFileFolderDialogProps) {
  const intl = useIntl()
  const navigate = useNavigate()
  const { folder } = props
  const match = useMatch('/content/library/:folderId')
  const urlFolderId = match?.params.folderId
  const fileSizeLimits = useSelector(myUploadsStorageSelector).fileSizeLimits
  const dispatch = useDispatch() as StoreThunkDispatch
  const [disabledFiles, setDisabledFiles] = React.useState<Record<string, boolean>>({})
  const [loading, setLoading] = React.useState(false)
  const [fileUploadsErrors, setFileUploadsErrors] = React.useState<{ [name: string]: string }>({})
  const deleteFile$ = React.useRef<Subject<{ file: MyFile, force: boolean }>>()
  const deleteFolder$ = React.useRef<Subject<string>>()
  const [fileUploadsRotation, setFileUploadsRotation] = React.useState<{ [name: string]: string }>({})
  const folderUpdate$ = React.useRef<Subject<{
    id: string,
    name: string,
    color: string,
    isPrivate: boolean,
    featuredImage?: string | File,
    coverImages?: string[]
  }>>()

  const onClose = React.useCallback(() => {
    dispatch(setEditFolder(undefined))
    props.setFileUploads({})
  }, [dispatch])

  React.useEffect(() => {
    folderUpdate$.current = new Subject()
    folderUpdate$.current.mergeMap(data => {
      setLoading(true)
      return Observable.fromPromise(dispatch(updateFolder(data)).unwrap())
        .pipe(
          tap(() => dispatch(getStorageInfo())),
          catchError(error => Observable.of({ error }))
        )
    })
      .subscribe((response: any) => {
        setLoading(false)
        if (response.error) {
          dispatch(message(intl.formatMessage({ id: 'errors.generic' }), 'error'))
        } else {
          dispatch(message('Folder updated!', 'success'))
          onClose()
        }
      })

    return () => {
      folderUpdate$.current?.unsubscribe()
    }
  }, [dispatch, intl, onClose])

  React.useEffect(() => {
    deleteFolder$.current = new Subject()
    deleteFolder$.current
      .flatMap((id) => {
        setLoading(true)
        return Observable.fromPromise(dispatch(deleteFolder(id)).unwrap())
          .pipe(catchError(response => {
            return Observable.of({ error: response })
          })).flatMap((response: any) => {
            if (!response.error) {
              return dispatch(getStorageInfo())
            }
            return Observable.of(response)
          })
      })
      .subscribe((result: any) => {
        setLoading(false)
        if (result.error) {
          dispatch(message(intl.formatMessage({ id: 'errors.generic' }), 'error'))
        } else {
          dispatch(message('Folder deleted!', 'success'))
          if (urlFolderId === folder.id) {
            navigate('/content/library')
          }
          onClose()
        }
      })

    return () => {
      deleteFolder$.current?.unsubscribe()
    }
  }, [dispatch, intl, onClose])

  React.useEffect(() => {
    deleteFile$.current = new Subject()
    deleteFile$.current
      .flatMap(({ file, force }) => {
        return Observable.fromPromise(dispatch(deleteFile({ file, force })).unwrap())
          .pipe(catchError(response => {
            setDisabledFiles(current => ({ ...current, [file.id]: false }))
            if (response.status === HTTP_STATUS_BAD_REQUEST && response.response.msg === 'File used in posts.') {
              dispatch(action(
                'warning',
                intl.formatMessage({ id: 'find.my-uploads.messages.confirm-delete-used-file' }),
                intl.formatMessage({ id: 'actions.delete' }),
                () => { deleteFile$.current?.next({ file, force: true }) },
                NOTIFICATION_DURATION_LONG
              ))
              return Observable.of({ error: response, handled: true })
            }
            return Observable.of({ error: response })
          }))
          .flatMap((response: any) => {
            if (!response.error) {
              return dispatch(getStorageInfo())
            }
            return Observable.of(response)
          })
      })
      .subscribe((result: any) => {
        if (result.error && !result.handled) {
          dispatch(message(intl.formatMessage({ id: 'errors.generic' }), 'error'))
        }
        if (!result.error) {
          dispatch(message('File deleted!', 'success'))
        }
      })

    return () => {
      deleteFile$.current?.unsubscribe()
    }
  }, [dispatch, intl])

  if (!folder) {
    return null
  }

  const images = Object.values(folder.files).reduce((map: Record<string, string>, file) => {
    if (file.type === 'photos') {
      map[file.thumbUrl] = file.thumbUrl
    }
    return map
  }, {})
  if (folder.featuredImage) {
    delete images[folder.featuredImage]
  }

  const update = (name: string, color: ColorGradient, isPrivate?: boolean, featuredImage?: string | File, coverImages?: string[]) => {
    folderUpdate$.current?.next({
      id: folder.id,
      name,
      color: `${color.from},${color.to}`,
      isPrivate: Boolean(isPrivate),
      featuredImage,
      coverImages
    })

    if (Object.keys(props.fileUploads).length > 0) {
      uploadFiles()
    }
    return true
  }

  const removeFile = (file: MyFile) => {
    deleteFile$.current?.next({ file, force: false })
    setDisabledFiles(current => ({ ...current, [file.id]: true }))
  }

  const addMoreFiles = (newFiles: { [name: string]: File }) => {
    props.setFileUploads({ ...props.fileUploads, ...newFiles })
  }

  const onDelete = () => {
    deleteFolder$.current?.next(folder.id)
  }

  const toggleFileUploadRotation = (fileName: string) => {
    const current = fileUploadsRotation[fileName] || 0
    const next = { [fileName]: PHOTO_ROTATION_TOGGLE[current].next }
    setFileUploadsRotation(current => ({ ...current, ...next }))
  }

  const removeFileUpload = (fileName: string) => {
    const nextFiles = { ...props.fileUploads }
    delete nextFiles[fileName]
    props.setFileUploads(nextFiles)
  }

  const onFileUploadError = (fileName: string, error: string) => {
    setFileUploadsErrors(current => ({ ...current, [fileName]: error }))
  }

  const uploadFiles = () => {
    const files = props.fileUploads
    const validSelectedFileNames = Object.keys(files).filter(name => files[name] && !fileUploadsErrors[name])
    const validSelectedFiles = validSelectedFileNames.map(name => {
      const file = files[name]
      const rotateBy = parseInt(fileUploadsRotation[name] || '0', 10)
      const withRotation = rotateBy % HALF_ROUND_DEGREES !== 0
      const type = getFileTypeByMimeType(file.type)
      const mediaElementType = type === FileType.Image || type === FileType.Gif
        ? 'image'
        : type === FileType.Video ? 'video' : undefined

      try {
        const sizes = getMediaDimensions(mediaElementType, `[data-id="${file.name}"]`, withRotation)
        return {
          file,
          width: sizes.width,
          height: sizes.height,
          rotateBy
        }
      } catch (e) {
        console.log('[getMediaDimensions()] Error: ', e)
        return undefined
      }
    })
      .filter(Boolean) as any[]

    props.onUploadFiles(validSelectedFiles, [props.folder])
    close()
  }

  const deletedFilesCount = Object.values(disabledFiles).filter(Boolean).length
  const addedFilesCount = Object.keys(props.fileUploads).length
  const currentFilesCount = folder.filesCount + addedFilesCount - deletedFilesCount

  return (
    <Dialog open onClose={onClose} classes={{ paper: styles.dialog }} maxWidth="xl">
      <div className={styles.grid}>
        <div className={styles['panel-left']}>
          <div className={styles['heading-left']}>
            <h3 className={styles.title}>
              <FormattedMessage id="label.generic.files" />: {currentFilesCount}
            </h3>
            <ConnectedFileInput
              children={(
                <div className={styles['btn-add']}>
                  <span>+ <FormattedMessage id="uploads.actions.add-more-files" /></span>
                </div>
              )}
              openFileDialog={noop}
              setUploaderFiles={addMoreFiles}
            />
          </div>
          <div className={styles['files-grid']}>
            {Object.values(folder.files).filter(file => !disabledFiles[file.id]).map(f => (
              <div className={styles['grid-item']} key={f.id}>
                <MyFilePreview file={f} onRemove={removeFile} />
              </div>
            ))}
            {Object.values(props.fileUploads).map(f => (
              <MemoryFilePreviewCard
                key={f.name}
                file={f}
                validation={{ maxSize: mapFileTypeToSizeLimit(f.type, fileSizeLimits) }}
                rotateBy={fileUploadsRotation[f.name]}
                className={styles['grid-item']}
                mediaElementId={f.name}
                onError={onFileUploadError}
                onRotate={toggleFileUploadRotation}
                onDelete={removeFileUpload}
              />
            ))}
          </div>
        </div>
        <div className={styles['panel-right']}>
          <div className={styles['heading-title']}>
            <span><FormattedMessage id="uploads.manage.edit-folder-title" /></span>
            <ConfirmDialog
              message={<FormattedMessage id="find.my-uploads.messages.confirm-delete-folder" />}
              labelOK={<FormattedMessage id="actions.delete" />}
            >
              {(confirm) => (
                <Button disabled={loading} onClick={confirm(onDelete)}>
                  <FormattedMessage id="uploads.popup.actions.delete-folder" />
                </Button>
              )}
            </ConfirmDialog>

          </div>
          <div className={styles.content}>
            <CreateFileFolderForm
              loading={loading}
              updateFolder={folder}
              filesCount={currentFilesCount}
              images={images}
              onCancel={onClose}
              onSubmit={update}
            />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default EditFileFolderDialog
