import * as React from 'react'
import ConnectedSnackbar from 'components/ConnectedSnackbar'
import MobileWarningDialog from '../MobileWarningDialog'
import SaveFeedDialog from 'components/SaveFeedDialog'
import ContentPopup from 'components/ContentPopup'
import { isMobileScreen } from 'utils/browser'
import { useLocation, useNavigationType } from 'react-router-dom'
import ContentPreviewWindow from 'components/ContentPreviewWindow'
import UpgradePopup from 'components/UpgradePopup'
import TokensChecker from 'components/TokensChecker'
import ConnectedFileDialog from 'components/FileDialog'
import ProfitwellScript from 'components/ProfitwellScript'
import FileUploadManager from 'components/MultiUploadManager'
import HotjarTracker from '../HotjarTracker'
import CanvaProvider from 'components/CanvaProvider'
import PostProgressOverlay from 'components/PostProgress'
import { updateIntercom } from 'services/tracking/login'
import { useDispatch, useSelector } from 'react-redux'
import { AppLayout } from '../AppLayout'
import { EndTrialPopup, TrialExpiredPopup } from 'components/Trial'
import { currentUserSelector } from 'services/users/selectors'
import { User } from 'interfaces'
import ComposerPrompt from 'components/Composer/Prompt'
import { LEGACY_URL } from 'config'
import { StoreThunkDispatch } from 'store/state'
import { Rewardful } from '../Rewardful'

declare const __PROD__: boolean

export interface AuthenticatedAppProps {
  user?: User
}

export function AuthenticatedApp() {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const user = useSelector(currentUserSelector)
  const location = useLocation()
  const navigationType = useNavigationType()

  const prevLocationRef = React.useRef(location)
  const isMobile = React.useRef(isMobileScreen())

  React.useEffect(() => {
    if (navigationType !== 'POP' && (!location.state?.modal)) {
      prevLocationRef.current = location
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationType, location.state?.modal, location.pathname])

  React.useEffect(() => {
    const update = () => {
      navigator.sendBeacon(`${LEGACY_URL}/v3/intercom`)
    }
    window.addEventListener('unload', update, false)
    return () => {
      window.removeEventListener('unload', update)
    }
  }, [])

  React.useEffect(() => {
    dispatch(updateIntercom())
  }, [dispatch])

  const modalActive = Boolean(location.state?.modal && prevLocationRef.current !== location)
  const hideSidebar = location.pathname.startsWith('/onboarding')
  const trialExpired = user?.account.trial === 'expired' && !user?.account.hasChargifyInfo

  return (
    <React.Fragment>
      <AppLayout
        hidden={modalActive}
        blurred={trialExpired}
        hideSidebar={hideSidebar}
      />
      <ConnectedSnackbar />
      <UpgradePopup />
      <SaveFeedDialog />
      <ContentPreviewWindow />
      <TokensChecker />
      <ContentPopup />
      <ConnectedFileDialog />
      <FileUploadManager />
      <PostProgressOverlay />
      <CanvaProvider />
      <ComposerPrompt />
      <TrialExpiredPopup />
      <EndTrialPopup />
      <Rewardful />
      {/* <FreePlanBlocker /> */}
      {isMobile.current && (
        <MobileWarningDialog />
      )}
      {__PROD__ && (
        <React.Fragment>
          <ProfitwellScript />
          <HotjarTracker />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default AuthenticatedApp
