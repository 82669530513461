import { initialState, SnackbarState } from './state'
import { SnackbarAction, ACTION_QUEUE_TREAT, ACTION_NEXT_TREAT } from './actions'

export function getSnackbarReducer(state = initialState(), action: SnackbarAction): SnackbarState {
  switch (action.type) {
    case ACTION_QUEUE_TREAT:
      const pending = action.payload.immediate
        ? [action.payload].concat(state.pending)
        : state.pending.concat([action.payload])

      return Object.freeze({ ...state,
        pending: Object.freeze(pending)
      })

    case ACTION_NEXT_TREAT:
      const [, ...next] = state.pending

      return Object.freeze({ ...state,
        pending: Object.freeze(next)
      })

    default:
      return state
  }
}

export default getSnackbarReducer
