import { IndexedObject, ContentItem, PHOTO_TYPE, ARTICLE_TYPE, VIDEO_TYPE, GIF_TYPE, STATUS_TYPE, ALL_FILTER } from 'interfaces'
import { TEXT_TYPE } from 'interfaces/Content/ContentType'

export type DataState = {
  items: ContentItem[]
  loading: boolean
  error: boolean
}

const UPDATE_STATE = 'UPDATE_FAV_STATE'
export function updateData(type: string, data: DataState) {
  return {
    type: UPDATE_STATE,
    payload: { type, data }
  }
}

export function favoritesReducer(state: IndexedObject<DataState>, action: { type: string, payload: any }) {
  switch (action.type) {
    case UPDATE_STATE: {
      return {
        ...state,
        [action.payload.type]: action.payload.data,
        [ALL_FILTER]: {
          items: [],
          loading: false,
          error: false
        }
      }
    }

    default:
      return state
  }
}

export const QUOTES_FILTER = 'quotes'
export const initialState: IndexedObject<DataState> = {
  [PHOTO_TYPE]: {
    items: [],
    loading: true,
    error: false
  },
  [ARTICLE_TYPE]: {
    items: [],
    loading: true,
    error: false
  },
  [VIDEO_TYPE]: {
    items: [],
    loading: true,
    error: false
  },
  [GIF_TYPE]: {
    items: [],
    loading: true,
    error: false
  },
  [STATUS_TYPE]: {
    items: [],
    loading: true,
    error: false
  },
  [TEXT_TYPE]: {
    items: [],
    loading: true,
    error: false
  },
  [ALL_FILTER]: {
    items: [],
    loading: true,
    error: false
  }
}
