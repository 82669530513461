import * as React from 'react'
import { ARTICLE_TYPE, PHOTO_TYPE, VIDEO_TYPE, WithIntl } from 'interfaces'
import { POST_TYPE_TO_CONTENT_TYPE } from 'interfaces/Composer'
import styles from './PostDraftView.pcss'
import IconButton from '@mui/material/IconButton'
import IconEdit from '@mui/icons-material/Edit'
import IconCloud from '@mui/icons-material/CloudUploadOutlined'
import { PostContentTypeIcon } from 'components/Posts/PostContentTypeIcon'
import { getDomainFromUrl } from 'shared/utils'
import { PostDraftData } from 'shared/types'
import { NativeVideoPlayer } from 'components/VideoPlayer'
import { PlayIcon } from 'components/Icons'
import DeleteIcon from '@mui/icons-material/Delete'
import Checkbox from '@mui/material/Checkbox'
import GalleryIcon from '@mui/icons-material/Collections'
import ProfileAvatar from 'components/ProfileAvatar'
import AvatarGroup from '@mui/material/AvatarGroup'
import { FormattedMessage, injectIntl } from 'react-intl'
import Tooltip from '@mui/material/Tooltip'
import ConfirmDialog from 'components/ConfirmDialog'
import ComposerState from 'services/compose/state'
import { format } from 'date-fns'

const MAX_AVATARS = 4

interface PostDraftViewProps {
  draft: PostDraftData<ComposerState>
  isSelected: boolean
  className?: string
  onEditBtnClick: (draft: PostDraftData<ComposerState>) => void
  onMultiPhotoBtnClick: (images: string[]) => void
  onToggleSelected: (id: string) => void
  onDelete: (id: string) => void
}

export function PostDraftView(props: PostDraftViewProps & WithIntl) {
  const draft = props.draft
  const networksData = draft.networks as any
  const profiles = draft.profiles

  const onDelete = () => {
    props.onDelete(draft.id)
  }

  const onEdit = () => {
    props.onEditBtnClick(draft)
  }

  const toggleSelected = () => {
    props.onToggleSelected(props.draft.id)
  }

  const onMultiPhotoBtnClick = () => {
    props.onMultiPhotoBtnClick(props.draft.photos as string[])
  }

  const photos = draft.photos
  const contentType = POST_TYPE_TO_CONTENT_TYPE[draft.postType]
  const mainImage = draft.featuredImageUrl || (photos ? photos[0] : '')
  const firstComment = (draft.networks.facebook?.details as any)?.firstComment
    || (draft.networks.instagram?.details as any)?.firstComment
    || (draft.networks.youtube?.details as any)?.firstComment
    || (draft.networks.linkedin?.details as any)?.firstComment

  const postType = React.useMemo(() => {
    const networks = Object.keys(networksData || {})
    let type: string | null = null
    for (const network of networks) {
      if (!['facebook', 'instagram'].includes(network)) {
        return contentType
      }
      const specialType = networksData[network].details?.igPostType || networksData[network].details?.postType
      if (type && type !== specialType) {
        return contentType
      }
      type = specialType
    }

    return type || contentType
  }, [contentType, networksData])

  const visibleStatus = React.useMemo(() => {
    const networks = Object.keys(networksData || {}).sort()
    for (const network of networks) {
      if (networksData[network].status) {
        return networksData[network].status
      }
    }
  }, [networksData])

  const profilesTooltip = profiles.map(p => `${p.name}\n`)
  const lastSaved = format(draft.updatedAt, 'LLL dd h:mm a')

  return (
    <article data-draft-id={draft.id} data-testid="post-draft" className={`${styles.container} ${props.className || ''}`}>
      <div className={styles['info-box']}>
        <Tooltip title={<FormattedMessage id="post.labels.draft-last-saved" values={{ date: lastSaved }} />}>
          <div className={styles.time}>
            <IconCloud className={styles['icon-updated']} />
            {lastSaved}
          </div>
        </Tooltip>
        <div className={styles['info-bottom']}>
          {profiles.length === 1 && (
            <ProfileAvatar
              src={draft.profiles[0].image}
              title={draft.profiles[0].name}
              brand={draft.profiles[0].type}
              circle
              className={styles['avatar-big']}
            />
          )}
          {profiles.length > 1 && (
            <div>
              <AvatarGroup
                max={MAX_AVATARS}
                className={`${styles['avatar-group']} ${profiles.length > MAX_AVATARS ? styles.overflow : ''}`}
              >
                {profiles.map(p => (
                  <ProfileAvatar
                    key={p.id}
                    brand={p.type}
                    src={p.image}
                    title={p.name}
                    circle
                    size="24px"
                    className={styles['grouped-avatar']}
                  />
                ))}
              </AvatarGroup>
              <Tooltip title={<div className={styles.tooltip}>{profilesTooltip}</div>} placement="top">
                <div className={styles['label-count']}>{profiles.length} <FormattedMessage id="general.labels.profiles" /></div>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <div className={styles['type-box']}>
        <Tooltip title={<FormattedMessage id={`label.generic.${postType}-post`} defaultMessage={`${postType} post`} />}>
          <span>
            <PostContentTypeIcon type={postType as any} className={styles['icon-type']} />
          </span>
        </Tooltip>
      </div>
      <div className={styles.body}>
        {contentType === PHOTO_TYPE && (
          <div className={styles['img-box']}>
            <img src={mainImage} className={styles.image} />
            {photos && photos.length > 1 && (
              <span
                className={styles['btn-multi-img']}
                onClick={onMultiPhotoBtnClick}
              >
                <GalleryIcon />
                {`+${photos.length - 1}`}
              </span>
            )}
          </div>
        )}
        {contentType === ARTICLE_TYPE && draft.link && (
          <div className={styles['article-box']}>
            <img src={mainImage} className={styles.image} />
            <div className={styles['article-attr']}>
              <a href={draft.link.url} target="_blank" rel="noopener">
                {getDomainFromUrl(draft.link.url)}
              </a>
              <h3 key="title" className={styles.title}>
                <a target="_blank" rel="noopener" href={draft.link.url} title={draft.link.title}>
                  {draft.link.title}
                </a>
              </h3>
            </div>
          </div>
        )}
        {draft.videoUrl && (
          <div className={styles['video-box']}>
            <NativeVideoPlayer
              videoUrl={draft.videoUrl}
              className={styles.video}
            />
          </div>
        )}
        {contentType === VIDEO_TYPE && !draft.videoUrl && (
          <div className={styles['video-box']}>
            <PlayIcon center key="play-icon" />
            <img src={mainImage} className={styles.image} />
          </div>
        )}
        <div className={styles.status}>
          <p className={styles.text}>
            {visibleStatus}
          </p>
        </div>
        {firstComment && (
          <div className={`${styles.comment} text-ellipsis`}>
            {firstComment}
          </div>
        )}
      </div>
      <div className={styles.actions} data-testid="post-draft-actions">
        <Tooltip title={<FormattedMessage id="post.actions.edit-draft" />}>
          <IconButton size="small" className={styles['btn-edit']} onClick={onEdit}>
            <IconEdit fontSize="small" />
          </IconButton>
        </Tooltip>
        <ConfirmDialog
          message={props.intl.formatMessage({ id: 'post.labels.draft-delete-confirm' })}
          labelOK={props.intl.formatMessage({ id: 'actions.yes' })}
          labelCancel={props.intl.formatMessage({ id: 'actions.no' })}
        >
          {(confirm) => (
            <Tooltip title={<FormattedMessage id="post.actions.delete-draft" />}>
              <IconButton size="small" onClick={confirm(onDelete)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </ConfirmDialog>
        <div className={styles['actions-right']}>
          <Checkbox
            checked={props.isSelected}
            color="primary"
            onChange={toggleSelected}
          />
        </div>
      </div>
    </article>
  )
}

export default injectIntl(PostDraftView)
