import * as React from 'react'
import { ComponentClass, FunctionComponent } from 'react'
import { FEED_TYPE_KEYWORD, FEED_TYPE_UNSUPPORTED, FEED_TYPE_STATUS_IDEAS, BrandifyBrand } from 'interfaces'
import { BRAND_TIKTOK } from 'interfaces/Content/SocialBrand'
import SocialIcon from 'components/SocialIcon'

import styles from './Brandify.pcss'

export type BrandifyProps = React.PropsWithChildren<{
  className?: string
  active?: boolean
  circle?: boolean
  offsetClassName?: string
  brand: BrandifyBrand | typeof FEED_TYPE_KEYWORD | undefined | typeof FEED_TYPE_UNSUPPORTED | typeof FEED_TYPE_STATUS_IDEAS
  colorBadge?: boolean
}>

export function Brandify<P>(BrandifyComponent: ComponentClass<P> | FunctionComponent<P>): FunctionComponent<P & BrandifyProps> {
  const Brandify = React.forwardRef((props: P & BrandifyProps, ref: React.RefObject<any>) => {
    const {
      brand,
      className,
      children,
      offsetClassName,
      active,
      colorBadge,
      ...originalBrandifiedProps
    } = props

    const actualBrand = brand || '--brandless'
    const circleClass = (originalBrandifiedProps as any).circle && styles.circle || ''
    const activeClass = active ? styles.active : ''
    const wrapperClassName = `${styles['brandify-box']} ${styles[actualBrand]} ${circleClass} ${activeClass} ${className || ''}`

    let brandElement: any = brand ? (
      <SocialIcon
        key="icon"
        size="20px"
        icon={brand}
        className={styles.icon}
        circle
        badge
      />
    ) : null

    if (colorBadge) {
      brandElement = <div className={styles.badge} key="badge"></div>
    }

    const Component = BrandifyComponent as any

    return (
      <div className={wrapperClassName} data-testid="profile-avatar" data-network={brand}>
        <Component {...originalBrandifiedProps} ref={ref} key="brandified">{children}</Component>
        {brandElement}
      </div>
    )
  }) as React.FunctionComponent<P & BrandifyProps>

  Brandify.displayName = `Brandify(${BrandifyComponent.displayName || BrandifyComponent.name})`
  return Brandify
}

export default Brandify
