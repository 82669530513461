import React, { useEffect, useState } from 'react'
import { RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import IntercomProvider from 'components/IntercomProvider'
import { FacebookSDK } from 'components/FacebookSDK'
import TwitterSDK from 'components/TwitterSDK'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from 'theme/theme-md'
import { MaintenanceView } from 'components/MaintenanceView'
import { MAINTENANCE_ON, MUI_X_LICENSE_KEY } from 'config'
import { initializeGoogleAnalytics } from 'tracking/google-analytics'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from './components/ErrorFallback'
import { StoreThunkDispatch } from 'store/state'
import { useDispatch, useSelector } from 'react-redux'
import GoogleTagManager from './components/GoogleTagManager/GoogleTagManager'
import PPIntlProvider from './components/PPIntlProvider'
import { checkTokens, logout } from 'services/auth'
import { productsSelector } from 'services/product'
import { LicenseInfo } from '@mui/x-license-pro'
import { Root } from 'routes'
import { currentUserSelector } from 'services/users/selectors'
import { HS_EVENT_APP_BOOT, trackHubspotEvent } from 'services/tracking/hubspot'

// EXPL: Bypass type checking, because of error in @types/react
const ErrorHandler = ErrorBoundary as any

declare const __PROD__: boolean

export function App() {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const onReset = () => dispatch(logout())
  const hasUser = Boolean(useSelector(currentUserSelector))
  const products = useSelector(productsSelector)
  const [loading, setLoading] = useState(products.length === 0 && hasUser)

  useEffect(() => {
    let sub: any
    if (hasUser) {
      sub = dispatch(checkTokens()).subscribe(() => {
        setLoading(false)
      }, () => {
        console.log('error fetching products')
        setLoading(false)
      })
    }
    return () => {
      sub?.unsubscribe()
    }
  }, [dispatch, hasUser])

  React.useEffect(() => {
    if (__PROD__) {
      initializeGoogleAnalytics()
    }
    LicenseInfo.setLicenseKey(MUI_X_LICENSE_KEY)
  }, [])

  React.useEffect(() => {
    dispatch(trackHubspotEvent(HS_EVENT_APP_BOOT, { type: 'web' }))
  }, [dispatch])

  if (MAINTENANCE_ON) {
    return (
      <MaintenanceView
        key="maintenance-view"
        title="Maintenance break"
        subtitle="We'll be back online in an hour."
      />
    )
  }

  if (loading) {
    return null
  }
  const router = createBrowserRouter([{
    path: '/*',
    element: <Root />
  }])

  return (
    <PPIntlProvider>
      <ThemeProvider theme={theme}>
        <IntercomProvider>
          <React.Fragment>
            {__PROD__ && <GoogleTagManager />}
            <FacebookSDK />
            <TwitterSDK />
            <ErrorHandler
              FallbackComponent={ErrorFallback}
              onReset={onReset}
            >
              <RouterProvider router={router} />
            </ErrorHandler>
          </React.Fragment>
        </IntercomProvider>
      </ThemeProvider>
    </PPIntlProvider>
  )
}

export default App
