import { PostScheduleInfo } from 'interfaces'
import { ComposerScheduleState } from './state'
import { fromZonedTime } from 'date-fns-tz'

export function getPostScheduleFromComposerState(scheduleState: ComposerScheduleState, timezone: string): PostScheduleInfo {
  const date = new Date(scheduleState.date)
  const time = new Date(scheduleState.time)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const hour = time.getHours().toString().padStart(2, '0')
  const minute = time.getMinutes().toString().padStart(2, '0')
  const scheduledTime = new Date(fromZonedTime(`${year}-${month}-${day} ${hour}:${minute}`, timezone)).toISOString()

  const schedule: PostScheduleInfo = {
    postDate: scheduledTime,
    repeat: false
  }

  if (scheduleState.repeat && scheduleState.repeatCount > 0) {
    const until = new Date(scheduleState.repeatUntilDate)
    // EXPL: repeat_until must have time set to 23:59
    until.setHours(23, 59, 0) // eslint-disable-line no-magic-numbers
    schedule.repeat = true
    schedule.repeatLength = scheduleState.repeatCount
    schedule.repeatPeriod = scheduleState.repeatPeriod
    schedule.repeatUntil = scheduleState.repeatUntil === 'forever'
      ? undefined
      : until.toISOString()
  }
  return schedule
}
