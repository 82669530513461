import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'
import styles from './ErrorFallback.pcss'
import { useNavigate, useRouteError } from 'react-router-dom'

export function RouteErrorView() {
  const navigate = useNavigate()
  const [errorVisible, setErrorVisible] = React.useState(false)
  const error = useRouteError() as any

  const toggleError = () => {
    setErrorVisible(current => !current)
  }

  const onRefresh = () => {
    navigate('/logout')
  }

  return (
    <Dialog open maxWidth="xs">
      <DialogTitle className={styles.title}>
        <FormattedMessage id="errors.app-crash" />
      </DialogTitle>
      <div className={`${styles.message} ${errorVisible ? styles.visible : ''}`}>
        <p>{`Original error: ${error?.name}`}</p>
        {error?.stack && (
          <p>{`Stack trace: ${error.stack}`}</p>
        )}
      </div>
      <DialogActions className={styles.actions}>
        <a onClick={toggleError}>
          {errorVisible ? 'hide error' : 'see error'}
        </a>
        <Button className={styles.button} color="primary" variant="contained" onClick={onRefresh}>
          <FormattedMessage id="actions.refresh" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}
