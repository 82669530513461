import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FacebookDeclinedPermissionsException } from 'services/auth'
import { loginWithFacebook } from 'services/auth/identities/facebook'
import { facebookSDKInitializedSelector } from 'services/external/selectors'
import { StoreThunkDispatch } from 'store/state'
import LoginButton from './LoginButton'
import styles from './LoginButton.pcss'

interface FBLoginButtonProps {
  reAuth?: boolean
  loginParams?: { [key: string]: string }
  className?: string
  onClick?: () => void
  onError?: (error: Error) => void
}

export default function FBLoginButton(props: FBLoginButtonProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const navigate = useNavigate()
  const intl = useIntl()
  const initialized = useSelector(facebookSDKInitializedSelector)

  const onLogin = () => {
    if (props.onClick) {
      props.onClick()
    }

    const options = props.reAuth ? { auth_type: 'rerequest' } : {}

    return dispatch(loginWithFacebook(false, options, props.loginParams))
      .catch((error: Error) => {
        if (props.onError) {
          props.onError(error)
        }

        if (error instanceof FacebookDeclinedPermissionsException) {
          navigate('/login/declined', { replace: true })
        }
      })
  }

  return (
    <LoginButton
      text
      initialized={initialized}
      buttonClassName={styles.facebook}
      progressClassName={styles.progress}
      className={props.className}
      onLogin={onLogin}
      displayInitialized={intl.formatMessage({ id: 'login.login-with-fb' })}
    />
  )
}
