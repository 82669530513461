import { PostingPlan, Schedule, ScheduleTime, PHOTO_TYPE, ARTICLE_TYPE, VIDEO_TYPE, STATUS_TYPE } from 'interfaces'
import capitalize from 'utils/format/capitalize'
import { WEEK_DAYS_SHORT } from 'utils/plan'

const isEqual = require('react-fast-compare')

export const LEGACY_BUCKET_NAMES_MAP: { [legacyName: string]: string } = {
  status: STATUS_TYPE,
  photo: PHOTO_TYPE,
  link: ARTICLE_TYPE,
  video: VIDEO_TYPE
}

const scheduleLabelsOrder = ['Every Day', 'Weekdays', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Weekends', 'Sat', 'Sun', 'No Days']

// Returns a tab name depending on the tab's selected days
/* eslint-disable no-magic-numbers */
export function generateScheduleName(daysInput: string[]) {
  const daysFormatted = daysInput.map(d => d.toLowerCase())
  // Order days of week
  const days = WEEK_DAYS_SHORT.filter((day: string) => daysFormatted.includes(day))

  switch (days.length) {
    case 0:
      return 'No Days'
    case 7:
      return 'Every Day'
    case 6:
      if (!days.includes('sat')) {
        return 'Weekdays, Sun'
      }

      if (!days.includes('sun')) {
        return 'Weekdays, Sat'
      }
      days.splice(-2, 2)
      return days.map(capitalize).join(', ') + ', Weekends'

    default:
      if (isEqual(days, ['sat', 'sun'])) {
        return 'Weekends'
      }

      if (isEqual(days, ['mon', 'tue', 'wed', 'thu', 'fri'])) {
        return 'Weekdays'
      }

      if (days.indexOf('sat') !== -1 && days.indexOf('sun') !== -1) {
        if (days.length === 2) {
          return 'Weekends'
        }
        days.splice(-2, 2)
        return days.map(capitalize).join(', ') + ', Weekends'
      }

      return days.map(capitalize).join(', ')
  }
}
/* eslint-enable no-magic-numbers */

export type PlanResponse = {
  id: string
  timezone: string
  pause: any
  name: any
  ppPageIds: string[]
  tabs: Array<{
    id: string
    slots: Array<{
      time: string
      postTypes: string[]
      postBucketId?: string
      ids: string[]
    }>
    Mon: { active: '1' | '-1' }
    Tue: { active: '1' | '-1' }
    Wed: { active: '1' | '-1' }
    Thu: { active: '1' | '-1' }
    Fri: { active: '1' | '-1' }
    Sat: { active: '1' | '-1' }
    Sun: { active: '1' | '-1' }
  }>
}

export function scheduleAdapter(tab: any) {
  const hours: { [time: string]: ScheduleTime } = {}
  if (tab.slots) {
    tab.slots.forEach((slot: any) => {
      hours[slot.time] = {
        time: slot.time,
        ids: slot.ids,
        baseTime: slot.baseTime,
        buckets: slot.postTypes.map((b: string) => LEGACY_BUCKET_NAMES_MAP[b]),
        postBucketId: slot.bucketId
      }
    })
  }

  if (tab.slot) {
    hours[tab.slot.time] = {
      time: tab.slot.time,
      baseTime: tab.slot.baseTime,
      ids: tab.slot.ids,
      buckets: tab.slot.postTypes.map((b: string) => LEGACY_BUCKET_NAMES_MAP[b]),
      postBucketId: tab.slot.bucketId
    }
  }

  const activeDays: string[] = Object.keys(tab).filter(key => tab[key].active === '1')
  const name = generateScheduleName(activeDays)
  const label = name.split(',')[0]

  return {
    id: tab.id,
    activeDays,
    activeDay: activeDays[0],
    hours,
    name,
    order: scheduleLabelsOrder.indexOf(label)
  }
}

export function planAdapter(plan: any): PostingPlan {
  const schedules: { [scheduleId: string]: Schedule } = {}

  plan.tabs.forEach((tab: any) => {
    schedules[tab.id] = scheduleAdapter(tab)
  })

  return {
    id: plan.id,
    name: (plan as any).name,
    ppids: plan.ppPageIds,
    timezone: plan.timezone,
    schedules,
    isPaused: Boolean(plan.pause)
  }
}
