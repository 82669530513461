import * as React from 'react'
import { FileValidator, FileLoader } from 'components/FileHelpers'
import { injectIntl } from 'react-intl'
import { WithIntl, FileType } from 'interfaces'
import { LINKEDIN_DOCUMENT_EXTENSIONS } from 'shared'
import { ALLOWED_BULK_EXTENSIONS, getFileTypeByMimeType } from 'utils/file'
import { NativeVideoPlayer } from 'components/VideoPlayer'
import ErrorIcon from '@mui/icons-material/WarningRounded'
import RotateIcon from '@mui/icons-material/RotateRight'
import DeleteIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import GifPlayer from 'components/GifPlayer'
import LinearProgress from '@mui/material/LinearProgress'
import Icon from '@mdi/react'
import { mdiMicrosoftExcel } from '@mdi/js'
import styles from './FileUpload.pcss'
import { Upload } from './Upload'

const PROGRESS_COMPLETE = 100
const PROGRESS_CONTENT_MAX = 90

export interface FileUploadProps {
  file: File
  uploadId?: string
  className?: string
  validation?: {
    maxSize?: number // bytes
    allowedFormats?: string[]
  }
  selected?: boolean
  rotateBy?: string
  mediaElementId?: string
  withProgress?: boolean
  skipValidation?: boolean
  loaderClassName?: string
  abortOnUnmount?: boolean
  fileUrl?: string
  onRotate?: (fileName: string) => void
  onClick?: (fileName: string, uploadId?: string) => void
  onDelete?: (fileName: string, uploadId?: string) => void
  onEdit?: (uploadId?: string) => void
  onError?: (fileName: string, error: string, uploadId?: string) => void
  onImageLoaded?: (name: string, src: string) => void
  onFileUploaded: (url: string, file: File, uploadId?: string) => void
  onUploadStarted?: (upload: { abort: (terminate?: boolean) => void }, uploadId?: string) => void
}

export const FileUpload = function FileUpload(props: FileUploadProps & WithIntl) {
  const [validationDone, setValidationDone] = React.useState(false)
  const [isValid, setIsValid] = React.useState(false)
  const [error, setError] = React.useState<string | undefined>(undefined)
  const [fileSrc, setFileSrc] = React.useState<string | undefined>(undefined)
  const [fileUrl, setFileUrl] = React.useState<string | null>(null)
  const [progress, setProgress] = React.useState(0)
  const fileType = getFileTypeByMimeType(props.file.type)
  const fileExtension = props.file.name.substring(props.file.name.lastIndexOf('.'))
  const isExcelFile = ALLOWED_BULK_EXTENSIONS.includes(fileExtension)
  const isDocumentFile = LINKEDIN_DOCUMENT_EXTENSIONS.includes(fileExtension)
  const rotateImageStyles = props.rotateBy !== undefined && fileType === FileType.Image
    ? { transform: `rotate(${props.rotateBy}deg)` }
    : undefined
  const uploadRef = React.useRef<any>()

  React.useEffect(() => {
    return () => {
      if (props.abortOnUnmount && uploadRef.current) {
        uploadRef.current.abort(true)
      }
    }
  }, [props.abortOnUnmount])

  const onError = (fileName: string, message: string) => {
    if (props.onError) {
      props.onError(fileName, message, props.uploadId)
    }
  }

  if (!error && fileType === undefined && !isExcelFile && !isDocumentFile) {
    const errorMessage = props.intl.formatMessage({ id: 'file.messages.invalid-type' })
    setError(errorMessage)
    onError(props.file.name, errorMessage)
  }

  const onValidationPassed = () => {
    setIsValid(true)
    setValidationDone(true)
  }

  const onValidationFailed = (error: string) => {
    setIsValid(false)
    setValidationDone(true)
    setError(error)
    onError(props.file.name, error)
  }

  const onFileLoadSuccess = (src: string) => {
    setFileSrc(src)
    if (props.onImageLoaded) {
      props.onImageLoaded(props.file.name, src)
    }
  }

  const onFileLoadFailed = (error: string) => {
    setError(error)
    onError(props.file.name, error)
    setFileSrc(undefined)
  }

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(props.file.name, props.uploadId)
    }
  }

  const onDelete = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (props.onDelete) {
      props.onDelete(props.file.name, props.uploadId)
    }
  }

  const onEdit = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (props.onEdit) {
      props.onEdit(props.uploadId)
    }
  }

  const toggleRotate = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (props.onRotate) {
      props.onRotate(props.file.name)
    }
  }

  const onUploadError = React.useCallback((_error: Error) => {
    const message = props.intl.formatMessage({ id: 'uploads.error.upload-failed' })
    onError(props.file.name, message)
    setError(message)
  }, [])

  const onFileUploaded = React.useCallback((url: string) => {
    setProgress(PROGRESS_COMPLETE)
    setFileUrl(url)
    props.onFileUploaded(url, props.file, props.uploadId)
  }, [])

  const onUploadProgress = (value: number) => {
    // NOTE: Set max of 95 upload progress in the onProgress event.
    // 100 is set when the request is complete
    const visibleProgress = Math.min(value, PROGRESS_CONTENT_MAX)
    setProgress(visibleProgress)
  }

  const onUploadStarted = (upload: { abort: (terminate?: boolean) => void }) => {
    uploadRef.current = upload
    if (props.onUploadStarted) {
      props.onUploadStarted(upload, props.uploadId)
    }
  }

  const errorClassName = error ? styles['with-error'] : ''
  const selectedClassName = props.selected ? styles.selected : ''
  const isStaticImage = fileSrc && fileType === FileType.Image
  const isVideo = fileType === FileType.Video
  const imageUrl = isStaticImage ? props.fileUrl || fileSrc : fileSrc

  return (
    <div
      className={`${styles.wrapper} ${props.className || ''} ${errorClassName} ${selectedClassName}`}
      data-testid="file-upload"
      onClick={handleClick}
    >
      <FileValidator
        file={props.file}
        maxSize={props.validation?.maxSize}
        allowedFileFormats={props.validation?.allowedFormats}
        disabled={props.skipValidation}
        onValidationError={onValidationFailed}
        onValidationSuccess={onValidationPassed}
      >
        <div className={styles.content}>
          {validationDone && isValid && fileType !== FileType.Video && (
            <FileLoader
              file={props.file}
              withProgress
              className={`${styles.loader} ${props.loaderClassName || ''}`}
              onError={onFileLoadFailed}
              onLoaded={onFileLoadSuccess}
            />
          )}
          <div className={styles.actions} data-testid="file-upload-actions">
            {props.onDelete && (
              <IconButton size="small" className={styles['btn-delete']} onClick={onDelete}>
                <DeleteIcon className={styles.icon} />
              </IconButton>
            )}
            {props.onEdit && (isStaticImage || (isVideo && fileUrl)) && (
              <IconButton size="small" className={styles['btn-edit']} onClick={onEdit}>
                <EditIcon className={styles.icon} />
              </IconButton>
            )}
            {isStaticImage && props.onRotate && (
              <IconButton size="small" className={styles['btn-rotate']} onClick={toggleRotate}>
                <RotateIcon className={styles.icon} />
              </IconButton>
            )}
          </div>
          {isStaticImage && (
            <img src={imageUrl} style={rotateImageStyles} className={styles.image} data-id={props.mediaElementId} />
          )}
          {fileSrc && fileType === FileType.Gif && (
            <GifPlayer gifDataUrl={fileSrc} gifUrl="" mediaElementId={props.mediaElementId} />
          )}
          {validationDone && isValid && fileType === FileType.Video && (
            <NativeVideoPlayer
              videoDataUrl={URL.createObjectURL(props.file)}
              togglePlayOnHover
              muted
              key={props.file.name}
              className={styles.video}
              playBtnClassName={styles['btn-play']}
              mediaElementId={props.mediaElementId}
            />
          )}
          {isExcelFile && (
            <Icon path={mdiMicrosoftExcel} size="42" />
          )}
          {error && (
            <div className={styles['error-msg']}>
              <span className={`${styles.filename} text-ellipsis`} title={props.file.name}>
                <ErrorIcon className={styles['err-icon']} />
                {props.file.name}
              </span>
              <p>{error}</p>
            </div>
          )}
          {validationDone && isValid && !props.fileUrl && (
            <Upload
              file={props.file}
              onError={onUploadError}
              onProgress={onUploadProgress}
              onSuccess={onFileUploaded}
              onUploadStarted={onUploadStarted}
            />
          )}
        </div>
      </FileValidator>
      {props.withProgress && (
        <LinearProgress
          variant="determinate"
          value={progress}
          classes={{ bar: styles.bar }}
          className={styles.loader}
        />
      )}
    </div>
  )
}

FileUpload.defaultProps = {
  withProgress: true
} as any

export default injectIntl(FileUpload)
