import { COMPOSER_STATE_VERSION } from 'services/compose/state'
import { CONTENT_STATE_VERSION } from 'services/content/state'
import { POST_DESTINATIONS_STATE_VERSION } from 'services/destinations/state'
import { LISTS_STATE_VERSION } from 'services/lists'
import { SNACKBAR_STATE_VERSION } from 'services/snackbar/state'
import { USER_STATE_VERSION } from 'services/users'
import { POST_STATE_VERSION } from 'services/post/state'
import { PLAN_STATE_VERSION } from 'services/plan/state'
import { CONTENT_PREVIEW_STATE_VERSION } from 'services/contentPreview'
import { ADMIN_STATE_VERSION } from 'admin/services/state'
import { EXTERNAL_SERVICES_STATE_VERSION } from 'services/external'
import { AUTH_STATE_VERSION } from 'services/auth/state'
import { SETTINGS_STATE_VERSION } from 'services/settings/state'
import { CONTENT_POPUP_VERSION } from 'services/contentPopup'
import { UPLOADS_STATE_VERSION } from 'services/uploads/state'
import { SEARCH_VERSION } from 'services/search/state'

export const PURGE_VERSION = '@version/PURGE'

export function initialState() {
  return {
    composer: COMPOSER_STATE_VERSION,
    content: CONTENT_STATE_VERSION,
    destinations: POST_DESTINATIONS_STATE_VERSION,
    lists: LISTS_STATE_VERSION,
    snackbar: SNACKBAR_STATE_VERSION,
    user: USER_STATE_VERSION,
    post: POST_STATE_VERSION,
    plan: PLAN_STATE_VERSION,
    contentPreview: CONTENT_PREVIEW_STATE_VERSION,
    admin: ADMIN_STATE_VERSION,
    external: EXTERNAL_SERVICES_STATE_VERSION,
    auth: AUTH_STATE_VERSION,
    settings: SETTINGS_STATE_VERSION,
    contentPopup: CONTENT_POPUP_VERSION,
    uploads: UPLOADS_STATE_VERSION,
    search: SEARCH_VERSION
  } as { [key: string]: string }
}

export const CURRENT_VERSION = initialState()

export function hydrationReducer(state = initialState()) {
  return state
}

export function storedVersion(state: { version: { [key: string]: string } }) {
  return state.version
}

export default hydrationReducer
