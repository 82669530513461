import { LocationInfo, TikTokPostProfileSettings, PrivacyStatus } from '../Composer'
import { ContentType } from '../Content/ContentTypes'
import { PostDestinationType } from '../Networks/Socials'
import { Bucket } from './Buckets'

export const POST_TYPE_PLANNED = 'planned'
export const POST_TYPE_PENDING = 'pending'
export const POST_TYPE_POSTED = 'posted'
export const POST_TYPE_FAILED = 'failed'
export const NO_TIME_SLOT_VALUE = 'No Posting Time'
export type PostType = typeof POST_TYPE_PLANNED | typeof POST_TYPE_PENDING | typeof POST_TYPE_POSTED | typeof POST_TYPE_FAILED

export interface SocialProfileTag {
  pageId: string
  name: string
  handle: string
  url: string
  imageUrl: string
}

export interface PostDetails {
  tags?: SocialProfileTag[]
  text?: string
  photoAlbum?: {
    id: string
    name: string
    url: string
  }
  location?: LocationInfo
  firstComment?: string
  sourceFeedName?: string
  sourceFeedType?: string
  sourcePostText?: string
  sourcePostSocialLink?: string
  sourceLink?: string
  sourcePostDate?: string
  tweetUrl?: string
  reshareUrl?: string
  reshareName?: string
  buttonUrl?: string
  buttonType?: string
  settings?: TikTokPostProfileSettings[]
  photoTags?: string[]
  postType?: string
  description?: string
  igPostType?: string
  videoThumbnailUrl?: string
  privacyStatus?: PrivacyStatus
  textBackground?: {
    thumbUrl: string
    backgroundUrl: string
    id: string
    color: string
  }
  documentUrl?: string
  documentName?: string
}

export interface PostBase {
  id: string
  albumId?: string
  description?: string
  privacyStatus?: PrivacyStatus
  typeText: string
  contentType: ContentType
  postType: number
  specialType?: 'reels' | 'stories' | 'document' | 'tobi'
  isPosted?: boolean
  ppPageId: string
  networkIdV3: string
  profileId: string
  time: number
  baseTime?: number
  timeString: string
  timezone: string
  dateString: string
  hoursLocal: number
  weekday: string
  weekdayId: string
  fbUrl?: string
  twName?: string
  twPostType?: 'retweet' | 'quote'
  status: string
  file?: string
  profilePictureUrl: string
  linkImage?: string
  linkUrl?: string
  targetingProfileName?: string
  postedFormatDate?: string
  calendarDate: string
  recycle: number | boolean | Date
  images: string[]
  autoPost: boolean | null
  linkTitle?: string
  linkDescription?: string
  linkDomain?: string
  fbVideoUrl?: string
  nativeVideoUrl?: string
  processingPreview: boolean
  isEmpty: boolean
  details?: PostDetails
  attributes: {
    title?: string
    description?: string
    url?: string
    pinterestBoardUrl?: string
  } | null

  repeatLength: number
  repeatPeriod: string
  repeatUntil: string

  name: string
  network: PostDestinationType
  createdAt: string
  visibleIndex?: number
  buckets: string[]
  bucket?: Bucket
  bucketId?: string
  authorName: string
  igPostType?: string
}

export interface PlannedPost extends PostBase {
  albumId: string
  autoPostOrder: number
  published?: boolean
  isVirtual?: boolean
  virtualPostId?: string
  virtual?: {
    isForever: boolean
    repeatingLabel: string
    type: 'repeat' | 'recycle'
    originalTime: string
  }
}

export interface PendingPost extends PlannedPost {
  date: 'Unknown',
  dateString: 'Unknown'
}

export interface PostedPost extends PostBase {
  repeatUntilPeriod?: string
  repeatUntilPeriodCount: number
  shares: number
  sharesFormatted: string
  sharesPerImpression: number
  likes: number
  likesFormatted: string
  likesPerImpression: number
  comments: number
  commentsFormatted: string
  commentsPerImpression: number
  clicks: number
  clicksFormatted: string
  clicksPerImpression: number
  realLink: string
  saves?: number
  savesFormatted?: string
  impressions?: number
  impressionsFormatted?: string
  isRepin?: boolean
}

export interface FailedPost extends PostBase {
  error: string
}

export interface PostDraft extends Partial<PlannedPost> {
  id: string
  updatedAt: string
}

export type AnyPost = PlannedPost | PostedPost | FailedPost
