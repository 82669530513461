import * as React from 'react'
import { connect } from 'react-redux'
import RecentlyViewedRootView from './RecentlyViewedRootView'
import StoreState, { StoreThunkDispatch } from 'store/state'
import { recentSourcesSelector } from 'services/content/selectors'
import { getRecentSources } from 'services/content/recommended/actions'
import useAsyncAction from 'hooks/useAsyncAction'
import { InteractionTrackingSource } from 'interfaces'
import { Observable } from 'rxjs/Observable'

const BASE_URL = '/content/recently-viewed'
const ACTIVE_SOURCES_LOAD_BATCH_SIZE = 4

interface RecentlyViewedRootViewContainerConnectedProps {
  cachedSources: InteractionTrackingSource[]
  fetchSources: (page: number) => Observable<any>
}

const RecentlyViewedRootViewContainer = React.memo(function (props: RecentlyViewedRootViewContainerConnectedProps) {
  const scrollElement = document.querySelector('[data-test="main"]') as HTMLElement
  const [page, setPage] = React.useState(1)
  const [uiPage, setUiPage] = React.useState(1)
  const [hasNextPage, setHasNextPage] = React.useState(true)
  const [triggerFetch, sourcesResponse, error, loading] = useAsyncAction(props.fetchSources)
  const activeSources = props.cachedSources.slice(0, uiPage * ACTIVE_SOURCES_LOAD_BATCH_SIZE)

  React.useEffect(() => {
    triggerFetch(page)
  }, [page])

  React.useEffect(() => {
    if (sourcesResponse) {
      if (sourcesResponse.length === 0) {
        setHasNextPage(false)
      }
      setUiPage(current => current + 1)
    }
  }, [sourcesResponse])

  const loadNextSourcesBatch = () => {
    if (loading) {
      return
    }

    const nextVisibleSourcesCount = (uiPage + 1) * ACTIVE_SOURCES_LOAD_BATCH_SIZE
    if (nextVisibleSourcesCount <= props.cachedSources.length) {
      setUiPage(current => current + 1)
      return
    }

    if (hasNextPage && !loading) {
      setPage(current => current + 1)
    }
  }

  return (
    <RecentlyViewedRootView
      sources={activeSources}
      baseUrl={BASE_URL}
      loading={loading}
      scrollElement={scrollElement}
      onScrollEndReached={loadNextSourcesBatch}
    />
  )
})

function mapStateToProps(state: StoreState) {
  return {
    cachedSources: recentSourcesSelector(state)
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    fetchSources: (page: number) => Observable.fromPromise(dispatch(getRecentSources(page)).unwrap().then(res => res.items))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentlyViewedRootViewContainer)
