import * as React from 'react'
import Paper, { PaperProps } from '@mui/material/Paper'
import { Bucket, IndexedObject, List, PostDestination, sortNetworksDefault, WithIntl } from 'interfaces'
import { FormattedMessage, injectIntl } from 'react-intl'
import IconButton from '@mui/material/IconButton'
import IconEdit from '@mui/icons-material/Edit'
import GridIcon from '@mui/icons-material/Apps'
import ListIcon from '@mui/icons-material/Reorder'
import SearchIcon from '@mui/icons-material/Search'
import NetworkIcon from '@mui/icons-material/Sort'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconClear from '@mui/icons-material/Close'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import ListEditorDialog from 'components/ListEditorDialog'
import Radio from '@mui/material/Radio'
import { sortByKeyAlphabetically } from 'utils/sort/order'
import { ALL_LIST_KEY } from 'services/lists/selectors'
import IconAddOutline from '@mui/icons-material/AddCircleOutline'
import Icon from '@mdi/react'
import { mdiPail } from '@mdi/js'
import styles from './ProfileAndListSelector.pcss'
import { ProfileListItem } from './ProfileListItem'
import { NavLink } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'

const SEARCH_BOX_HEIGHT = 50
const MIN_SEARCHABLE_PROFILES_COUNT = 10

interface ProfileAndListSelectorOwnProps {
  profiles: { [id: string]: PostDestination }
  lists: { [id: string]: List }
  selectedProfileIds: string[]
  selectedList?: List
  selectedBucketId?: string
  withLists: boolean
  multipleProfileSelection?: boolean
  className?: string
  activeView: 'grid' | 'list' | 'network'
  withListsEditor?: boolean
  withBuckets?: boolean
  buckets?: Bucket[]
  paperProps?: PaperProps
  profilesLabel?: React.ReactNode
  allowEmptySelection?: boolean
  setActiveView: (view: 'grid' | 'list' | 'network') => void
  onSelectedProfilesChange: (profileIds: string[]) => void
  onSelectedListChange?: (id: string | undefined) => void
  onEditListsClick?: () => void
  onBucketClick?: (id: string) => void
  onCreateBucketClick?: () => void
}

type ProfileAndListSelectorProps = ProfileAndListSelectorOwnProps & WithIntl

export function ProfileAndListSelector(props: ProfileAndListSelectorProps) {
  const { lists, selectedProfileIds, selectedList, paperProps, onSelectedListChange } = props
  const { activeView, setActiveView } = props
  const [filter, setFilter] = React.useState('')
  const [listEditorOpen, setListEditorOpen] = React.useState(false)
  const [searchVisible, setSearchVisible] = React.useState(false)

  // Auto-select list if its profiles match the selected profiles
  const idsString = selectedProfileIds.sort().join(',')
  React.useEffect(() => {
    if (!selectedList && onSelectedListChange) {
      const list = Object.values(lists).find(l => l.connectedPages.sort().join(',') === idsString)
      if (list) {
        onSelectedListChange(list.id)
      }
    }
  }, [idsString, selectedList, onSelectedListChange, lists])

  const changeView = (view: 'grid' | 'list' | 'network') => () => {
    setActiveView(view)
  }

  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setFilter(value)
  }

  const clearFilter = () => {
    setFilter('')
  }

  const openListEditor = () => {
    setListEditorOpen(true)
    if (props.onEditListsClick) {
      props.onEditListsClick()
    }
  }

  const closeListEditor = () => {
    setListEditorOpen(false)
  }

  const currentFilter = filter.trim()
  let visibleProfiles = Object.values(props.profiles)
  if (currentFilter.length > 0) {
    visibleProfiles = visibleProfiles.filter(p => p.name.toLowerCase().indexOf(currentFilter.toLowerCase()) !== -1)
  }

  const groupedVisibleProfiles = visibleProfiles.reduce((map: IndexedObject<PostDestination[]>, profile: PostDestination) => {
    if (map[profile.type]) {
      map[profile.type].push(profile)
    } else {
      map[profile.type] = [profile]
    }
    return map
  }, {})
  const groupedVisibleProfilesArray: PostDestination[] = []
  Object.keys(groupedVisibleProfiles).sort(sortNetworksDefault).forEach(network => {
    groupedVisibleProfilesArray.push(...groupedVisibleProfiles[network])
  })

  const onProfileClick = (profile: PostDestination) => {
    onSelectedListChange && onSelectedListChange(undefined)
    if (!props.multipleProfileSelection) {
      props.onSelectedProfilesChange([profile.id])
      return
    }
    const selection = [...props.selectedProfileIds]
    if (selection.includes(profile.id)) {
      if (selection.length > 1 || props.allowEmptySelection) {
        selection.splice(selection.indexOf(profile.id), 1)
      }
    } else {
      selection.push(profile.id)
    }
    props.onSelectedProfilesChange(selection)
  }

  const onListSelectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const listId = (event.target as HTMLInputElement).value
    props.onSelectedProfilesChange(props.lists[listId].connectedPages)
    onSelectedListChange && onSelectedListChange(listId)
  }

  const toggleSearch = () => {
    setSearchVisible(current => !current)
  }

  const onProfileDoubleClick = (profile: PostDestination) => {
    props.onSelectedProfilesChange([profile.id])
  }

  const onBucketClick = (e: React.MouseEvent<HTMLLIElement>) => {
    const id = e.currentTarget.dataset.bucketId
    if (id && props.onBucketClick) {
      props.onBucketClick(id)
    }
  }

  const withSearchClass = searchVisible ? styles['with-search'] : ''
  const noConnectedProfiles = Object.keys(props.profiles).length === 0

  return (
    <div className={`${styles.container} ${props.className || ''}`} data-testid="profiles-and-lists-selector">
      <Paper className={styles.paper} {...(paperProps || {})}>
        <div className={`${styles['menu-item-profiles']} ${styles.listview} ${withSearchClass}`}>
          <div className={styles['menu-item']}>
            <span className={styles['menu-item-title']}>
              {props.profilesLabel || <FormattedMessage id="general.labels.profiles" />}
            </span>
            <div className={styles['menu-item-actions']}>
              <IconButton
                className={`${styles.btn} ${styles['btn-grid']} ${activeView === 'grid' ? styles.active : ''}`}
                size="small"
                disableRipple
                onClick={changeView('grid')}
              >
                <GridIcon className={styles.icon} />
              </IconButton>
              <IconButton
                className={`${styles.btn} ${styles['btn-list']} ${activeView === 'list' ? styles.active : ''}`}
                size="small"
                disableRipple
                onClick={changeView('list')}
              >
                <ListIcon className={styles.icon} />
              </IconButton>
              <IconButton
                className={`${styles.btn} ${styles['btn-network']} ${activeView === 'network' ? styles.active : ''}`}
                size="small"
                disableRipple
                onClick={changeView('network')}
              >
                <NetworkIcon className={styles.icon} />
              </IconButton>
              {Object.keys(props.profiles).length >= MIN_SEARCHABLE_PROFILES_COUNT && (
                <IconButton
                  className={`${styles.btn} ${styles['btn-search']} ${searchVisible ? styles.active : ''}`}
                  size="small"
                  disableRipple
                  onClick={toggleSearch}
                >
                  <SearchIcon className={styles.icon} />
                </IconButton>
              )}
            </div>
          </div>
          <div className={styles.scrollable}>
            <div className={styles.content} data-testid="pls-content">
              {searchVisible && (
                <TextField
                  value={filter}
                  onChange={onFilterChange}
                  placeholder={props.intl.formatMessage({ id: 'post.sidebar.search-placeholder' })}
                  classes={{ root: styles['search-box'] }}
                  style={{ height: `${SEARCH_BOX_HEIGHT}px` }}
                  InputProps={{
                    className: styles['search-input'],
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconClear className={styles['btn-clear-search']} role="button" onMouseDown={clearFilter} />
                      </InputAdornment>
                    )
                  }}
                />
              )}
              {noConnectedProfiles && (
                <div className={styles['msg-empty']}>
                  <FormattedMessage id="general.labels.no-connected-profiles" />
                </div>
              )}
              {activeView === 'network' && (
                <div className={styles['profiles-box']}>
                  {Object.keys(groupedVisibleProfiles).sort(sortNetworksDefault).map(network => {
                    return (
                      <React.Fragment key={network}>
                        <div className={styles['network-name']}>{network}</div>
                        <div className={styles.grid}>
                          {groupedVisibleProfiles[network]
                            .sort(sortByKeyAlphabetically('name'))
                            .map(profile => (
                              <ProfileListItem
                                key={profile.id}
                                grid
                                profile={profile}
                                selected={props.selectedProfileIds.includes(profile.id)}
                                radioSelect={!props.multipleProfileSelection}
                                onClick={onProfileClick}
                                onDoubleClick={onProfileDoubleClick}
                              />
                            ))}
                        </div>
                      </React.Fragment>
                    )
                  })}
                  {activeView === 'network' && !currentFilter && (
                    <NavLink to="/settings/networks" className={styles.link}>
                      <Tooltip title={<FormattedMessage id="actions.add-profile" />} placement="top">
                        <IconAddOutline className={styles['icon-add']} />
                      </Tooltip>
                    </NavLink>
                  )}
                </div>
              )}
              {activeView === 'list' && (
                <ul className={styles['profiles-list-box']}>
                  {groupedVisibleProfilesArray.map(profile => {
                    const isSelected = props.selectedProfileIds.includes(profile.id)
                    return (
                      <ProfileListItem
                        key={profile.id}
                        profile={profile}
                        selected={isSelected}
                        radioSelect={!props.multipleProfileSelection}
                        onClick={onProfileClick}
                        onDoubleClick={onProfileDoubleClick}
                      />
                    )
                  })}
                  <NavLink to="/settings/networks" className={`${styles['text-link']} ${styles['text-link']} ${styles.s}`}>
                    + <FormattedMessage id="actions.add-profile" />
                  </NavLink>
                </ul>
              )}
              {activeView === 'grid' && (
                <div className={styles['profiles-box']}>
                  <div className={styles.grid}>
                    {groupedVisibleProfilesArray.map(profile => {
                      const isSelected = props.selectedProfileIds.includes(profile.id)
                      return (
                        <ProfileListItem
                          key={profile.id}
                          grid
                          profile={profile}
                          selected={isSelected}
                          radioSelect={!props.multipleProfileSelection}
                          onClick={onProfileClick}
                          onDoubleClick={onProfileDoubleClick}
                        />
                      )
                    })}
                    {!currentFilter && (
                      <NavLink to="/settings/networks" className={styles.link}>
                        <Tooltip title={<FormattedMessage id="actions.add-profile" />} placement="top">
                          <IconAddOutline className={styles['icon-add']} />
                        </Tooltip>
                      </NavLink>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {
          props.withLists && !noConnectedProfiles && (
            <div className={styles['menu-item-lists']} data-testid="profile-selector-lists-box">
              <div className={styles['menu-item']}>
                <span className={styles['menu-item-title']}>
                  <FormattedMessage id="general.labels.lists" />
                </span>
                {props.withListsEditor && (
                  <Tooltip title={<FormattedMessage id="list-manager.labels.edit" />}>
                    <IconButton className={`${styles.btn} ${styles['btn-edit']}`} size="small" disableRipple onClick={openListEditor}>
                      <IconEdit className={styles.icon} />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              <div className={styles.scrollable}>
                <div className={styles.content}>
                  <RadioGroup
                    aria-label="lists"
                    name="lists"
                    value={props.selectedList ? props.selectedList.id : false}
                    classes={{ root: styles['lists-box'] }}
                    onChange={onListSelectionChange}
                  >
                    {Object.values(props.lists).sort((l1) => (l1.id === ALL_LIST_KEY ? -1 : 1)).map(list => (
                      <FormControlLabel
                        key={list.id}
                        value={list.id}
                        label={(
                          <span className={styles['list-label']}>
                            <span className="text-ellipsis">{list.name}</span>
                            <span>{`(${list.connectedPages.length})`}</span>
                          </span>
                        )}
                        classes={{ label: styles['list-name'] }}
                        control={<Radio size="small" color="primary" className={styles.radio} />}
                      />
                    ))}
                  </RadioGroup>
                </div>
              </div>
            </div>
          )
        }
        {props.withBuckets && props.buckets && !noConnectedProfiles && (
          <div className={styles['menu-item-buckets']} data-testid="profile-selector-buckets-list">
            <div className={styles['menu-item']}>
              <span className={styles['menu-item-title']}>
                <FormattedMessage id="label.generic.buckets" />
              </span>
            </div>
            <div className={styles.scrollable}>
              <div className={styles.content}>
                <ul className={styles['buckets-list']}>
                  {props.buckets.map(b => (
                    <li
                      key={b.id}
                      data-bucket-id={b.id}
                      className={props.selectedBucketId === b.id ? styles.active : ''}
                      onClick={onBucketClick}
                    >
                      <Icon
                        path={mdiPail}
                        size="18px"
                        color={b.color}
                        className={styles['icon-bucket']}
                      />
                      <div className="text-ellipsis">{b.name}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {props.onCreateBucketClick && (
              <div
                key="add-bucket"
                className={`${styles['btn-action']} ${styles['text-action']} ${styles.s}`}
                onClick={props.onCreateBucketClick}
              >
                + <FormattedMessage id="app.nav.publishing-buckets.create" />
              </div>
            )}
          </div>
        )}
      </Paper>
      {props.withListsEditor && (
        <ListEditorDialog open={listEditorOpen} onClose={closeListEditor} />
      )}
    </div>
  )
}

export default injectIntl(ProfileAndListSelector)
