import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { connectedDestinationsSelector } from 'services/destinations'
import { userListsSelector } from 'services/lists/selectors'
import { IndexedObject, List, PostDestination } from 'interfaces'
import { checkFeatureAvailability } from 'services/product'
import { FEATURE_POST_TO_FB_GROUPS } from 'shared/constants'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import { FetchPostDestinations } from 'components/Fetch'
import { activePostIdSelector, composerSelectedBucketIdSelector, composerSelectedProfilesIdsSelector } from 'services/compose/selectors'
import { setComposerBucket, setComposerProfiles, setPostEdit } from 'services/compose'
import ProfileAndListSelector from 'routes/publishing/components/ProfileAndListSelector'
import { FormattedMessage } from 'react-intl'
import styles from './ProfilesDropdown.pcss'
import ListEditorDialog from 'components/ListEditorDialog'
import CaretIcon from '@mui/icons-material/ArrowDropDown'
import ProfilesIcon from '@mui/icons-material/People'
import Button from '@mui/material/Button'
import { StoreThunkDispatch } from 'store/state'

const PAPER_ELEVATION = 8

interface ProfilesDropdownProps {
  btnClassName?: string
  onSelectionChange?: (ids: string[]) => void
}

export function ProfilesDropdown(props: ProfilesDropdownProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const selectedComposerProfileIds: string[] = useSelector(composerSelectedProfilesIdsSelector)
  const profiles: IndexedObject<PostDestination> = useSelector(connectedDestinationsSelector)
  const lists = useSelector(userListsSelector)
  const selectedBucketId = useSelector(composerSelectedBucketIdSelector)

  const [listEditorOpen, setListEditorOpen] = React.useState(false)
  const [selectedList, setSelectedList] = React.useState<List | undefined>(undefined)
  const [activeView, setActiveView] = React.useState<'grid' | 'list' | 'network'>('grid')
  const [popoverAnchor, setPopoverAnchor] = React.useState<null | HTMLElement>(null)
  const [loading, setLoading] = React.useState(true)
  const editPostId = useSelector(activePostIdSelector)
  const disabled = Boolean(editPostId)

  React.useEffect(() => {
    if (selectedBucketId) {
      setSelectedList(undefined)
    }
  }, [selectedBucketId])

  const onSelectedProfilesChange = React.useCallback((ids: string[], fromList?: boolean) => {
    const profilesArray = ids.map(id => profiles[id]).filter(Boolean)
    const hasFbGroups = profilesArray.find(p => p.fbGroup)
    if (hasFbGroups && !dispatch(checkFeatureAvailability(FEATURE_POST_TO_FB_GROUPS))) {
      return
    }

    if (!fromList) {
      setSelectedList(undefined)
    }

    dispatch(setPostEdit(undefined))
    dispatch(setComposerBucket(undefined))
    dispatch(setComposerProfiles(profilesArray))
    if (props.onSelectionChange) {
      props.onSelectionChange(ids)
    }
  }, [dispatch, profiles, props.onSelectionChange])

  React.useEffect(() => {
    const connectedProfilesIds = Object.keys(profiles)
    if (selectedComposerProfileIds.length > 0 || connectedProfilesIds.length === 0) {
      return
    }
    // EXPL: If no profiles are selected, change selection to all connected profiles.
    if (connectedProfilesIds.length > 0) {
      onSelectedProfilesChange(connectedProfilesIds)
    }
  }, [selectedComposerProfileIds, loading, profiles, onSelectedProfilesChange])

  const onListSelected = React.useCallback((id?: string) => {
    if (id) {
      const list = lists[id]
      if (list.connectedPages.length > 0) {
        setSelectedList(list)
        onSelectedProfilesChange(list.connectedPages, true)
      }
    }
  }, [lists, onSelectedProfilesChange])

  const openListEditor = () => {
    setListEditorOpen(true)
    closeDropdown()
  }

  const closeListEditor = () => {
    setListEditorOpen(false)
  }

  const openDropdown = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (disabled) {
      return
    }
    setPopoverAnchor(e.target as HTMLElement)
  }

  const closeDropdown = () => {
    setPopoverAnchor(null)
  }

  const stopLoading = () => {
    setLoading(false)
  }

  return (
    <React.Fragment>
      <Button
        startIcon={<ProfilesIcon />}
        endIcon={<CaretIcon />}
        disabled={disabled}
        className={props.btnClassName}
        data-testid="profiles-dropdown-btn"
        onClick={openDropdown}
      >
        <FormattedMessage id="composer.labels.profiles-count" values={{ count: selectedComposerProfileIds.length }} />
      </Button>
      <Popper
        open={Boolean(popoverAnchor)}
        className={styles.popper}
        anchorEl={popoverAnchor}
        placement="bottom"
        data-testid="profiles-dropdown-popup"
      >
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={closeDropdown}>
          <Paper
            className={styles.paper}
            elevation={PAPER_ELEVATION}
          >
            <ProfileAndListSelector
              profiles={profiles}
              lists={lists}
              selectedProfileIds={selectedComposerProfileIds}
              selectedList={selectedList}
              multipleProfileSelection
              withLists
              withListsEditor
              activeView={activeView}
              setActiveView={setActiveView}
              onSelectedProfilesChange={onSelectedProfilesChange}
              onSelectedListChange={onListSelected}
              onEditListsClick={openListEditor}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
      <ListEditorDialog open={listEditorOpen} onClose={closeListEditor} />
      <FetchPostDestinations onFetched={stopLoading} onFailed={stopLoading} />
    </React.Fragment>
  )
}

export default ProfilesDropdown
