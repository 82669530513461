import * as React from 'react'
import RefreshIcon from '@mui/icons-material/Refresh'
import { FormattedMessage, injectIntl } from 'react-intl'
import styles from './PostedFilter.pcss'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import { useDispatch } from 'react-redux'
import { message } from 'services/snackbar'
import { WithIntl } from 'interfaces'
import Button from '@mui/material/Button'

interface PostedFilterProps {
  externalPostedVisible: boolean
  allowExternal: boolean
  isUnsupportedNetwork: boolean
  className?: string
  isFBGroupSelected?: boolean
  toggleExternalPostedVisible: () => void
  onRefresh: () => void
}

export function PostedFilter(props: PostedFilterProps & WithIntl) {
  const dispatch = useDispatch()
  const { allowExternal, externalPostedVisible, isFBGroupSelected, toggleExternalPostedVisible } = props
  const allPostsVisible = props.externalPostedVisible

  const warnMultipleProfiles = () => {
    if (!allowExternal) {
      const msg = props.isUnsupportedNetwork
        ? 'post.notifications.external-posted-unsupported'
        : 'post.notifications.external-posted-multi-profiles'
      dispatch(message(props.intl.formatMessage({ id: msg }), 'warning', undefined, true))
    }
  }

  const options: PPSelectOptions = {
    pp: { label: 'Post Planner' },
    all: { label: <span onClick={warnMultipleProfiles}>Any Publisher</span>, disabled: !props.allowExternal }
  }

  const onSelectionChange = React.useCallback((visible: 'pp' | 'all') => {
    if ((visible === 'pp' && externalPostedVisible) || (visible === 'all' && !externalPostedVisible)) {
      toggleExternalPostedVisible()
    }
  }, [externalPostedVisible, toggleExternalPostedVisible])

  return (
    <div data-test="posted-filter" className={`${styles.container} ${props.className || ''}`}>
      <PPSelect
        name="Posted via"
        options={options}
        withCaret
        selectedValue={allPostsVisible ? 'all' : 'pp'}
        onSelectionChange={onSelectionChange}
      />
      {props.externalPostedVisible && (
        <Button
          startIcon={<RefreshIcon className={styles['icon-refresh']} />}
          className={styles['refresh-button']}
          onClick={props.onRefresh}
        >
          <FormattedMessage id="actions.refresh-posts" />
        </Button>
      )}
    </div>
  )
}

export default injectIntl(PostedFilter)
