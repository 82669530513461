import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import styles from './ConnectedProfilesWarningPopup.pcss'
import { FormattedMessage } from 'react-intl'
import { NavLink, useNavigate } from 'react-router-dom'

export function ConnectedProfilesWarningPopup(props: { open: boolean }) {
  const navigate = useNavigate()

  const goToSettings = () => {
    navigate('/settings/networks')
  }

  return (
    <Dialog open={props.open}>
      <div className={styles.container}>
        <img className={styles.img} src="/static/img/misc/choose-profile.png" />
        <h1 className={styles.title}>
          <FormattedMessage id="composer.profiles-popup.title" />
        </h1>
        <h2 className={styles.message}>
          <FormattedMessage id="composer.profiles-popup.message" />
        </h2>
        <Button color="primary" variant="contained" onClick={goToSettings}>
          <FormattedMessage id="composer.profiles-popup.action" />
        </Button>
      </div>
    </Dialog>
  )
}
