import { NotAuthenticatedException } from '../../exceptions'
import { getLegacyCredentials } from './legacy'
import { FacebookDeclinedPermissionsException } from './exceptions'
import { getUserSuccess } from '../../../users/actions'
import PromiseFB from './promisify'
import { StoreThunkAction, StoreThunkDispatch } from 'store/state'
import { FACEBOOK_MINIMAL_SCOPES } from 'config'
import {
  User,
  FacebookLoginOptions,
  FacebookLoginResponse as LoginStatusResponse,
  FacebookUserPermission,
  PPProduct
} from 'interfaces'
import { updateFacebookLoginStatus } from 'services/auth/actions'
import { setProducts } from 'services/product'

/**
 * Login with facebook
 *
 * @export
 * @param {FacebookLoginOptions} [extraOptions] options
 * @returns {StoreThunkAction<Promise<AuthState>>}
 */
export function loginWithFacebook(
  automatic = false,
  extraOptions?: FacebookLoginOptions,
  params?: { [key: string]: string }
): StoreThunkAction<Promise<User>> {

  return (dispatch: StoreThunkDispatch) => new Promise<User>((resolve, reject) => {
    const options: FacebookLoginOptions = Object.assign({ return_scopes: true, scope: FACEBOOK_MINIMAL_SCOPES }, extraOptions)
    PromiseFB.login(options)
      .then(response => ensurePermissions(response, options.scope || []))
      .then(response => {
        dispatch(updateFacebookLoginStatus(response))
        return dispatch(continueWithFacebookLogin(response, automatic, params))
      })
      .then(resolve)
      .catch(error => {
        reject(error.response?.error)
      })
  })
}

type GraphPermissionsResponse = GraphPermissionsSuccess | GraphPermissionsError
type GraphPermissionsSuccess = { data: FacebookUserPermission[] }
type GraphPermissionsError = { error: any }

/**
 * Ensures that the response has the scopes
 *
 * @param {LoginStatusResponse} response
 * @param {string[]} scopes
 * @returns {Promise<LoginStatusResponse>} response
 */
function ensurePermissions(response: LoginStatusResponse, scopes: string[]) {
  return extractPermissions(response, scopes)
    .then(permissions => {
      const declined = scopes.filter(scope => permissions.indexOf(scope) === -1)
      if (declined.length === 0) {
        return response
      }

      throw new FacebookDeclinedPermissionsException(declined)
    })
}

/**
 * Extracts the permissions from the response or fetches them
 *
 * @param {LoginStatusResponse} response
 * @param {string[]} scopes
 * @returns
 */
function extractPermissions(response: LoginStatusResponse, scopes: string[]) {
  if (!response.authResponse) {
    throw new NotAuthenticatedException()
  }

  const authResponse = response.authResponse
  if ('grantedScopes' in authResponse) {
    if (typeof authResponse.grantedScopes === 'string') {
      return Promise.resolve(authResponse.grantedScopes.split(','))
    }
  }

  return PromiseFB.api<GraphPermissionsResponse>(`/${authResponse.userID}/permissions`)
    .then((graphResponse) => {
      if (!graphResponse || 'error' in graphResponse) {
        throw new FacebookDeclinedPermissionsException(scopes)
      }

      return ((graphResponse as GraphPermissionsSuccess).data || [])
        .filter(permission => permission.status === 'granted')
        .map(permission => permission.permission)
    })
}

/**
 * Logout facebook
 */
export function logoutWithFacebook(): StoreThunkAction<Promise<void>> {
  return () => PromiseFB.logout()
}

/**
 * Get the login status for facebook
 *
 * @export
 * @returns {StoreThunkAction<Promise<AuthState>>}
 */
export function getLoginStatus(force?: boolean): StoreThunkAction<Promise<LoginStatusResponse>> {
  return (dispatch) => PromiseFB.getLoginStatus(force).then(response => {
    dispatch(updateFacebookLoginStatus(response))
    return response
  })
}

/**
 * Continue login and by getting the credentials
 *
 * @param {LoginStatusResponse} response
 * @returns {StoreThunkAction<Promise<User>>}
 */
export function continueWithFacebookLogin(
  response: LoginStatusResponse,
  _automatic: boolean,
  params?: { [key: string]: string }
): StoreThunkAction<Promise<User>> {
  if (!response.authResponse) {
    throw new NotAuthenticatedException()
  }

  const auth = response.authResponse
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(getLegacyCredentials(auth.accessToken, params))
      .then(({ user, products }) => {
        const product = products.find((p: PPProduct) => p.id === user.account.product)
        dispatch(getUserSuccess(user, product))
        dispatch(setProducts(products))
        return user
      })
      .catch(error => {
        dispatch(logoutWithFacebook())
        return Promise.reject(error)
      })
  }
}
