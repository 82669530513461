import { Observable } from 'rxjs/Observable'
import { AjaxRequest, AjaxResponse } from 'rxjs/observable/dom/AjaxObservable'
import 'rxjs/add/observable/dom/ajax'
import 'rxjs/add/observable/fromPromise'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/do'
import 'rxjs/add/operator/map'
import 'rxjs/add/observable/throw'
import { NotAuthorizedException, Tags } from './exceptions/NotAuthorizedException'
import { StoreThunkAction, StoreDispatch, getState, StoreThunkDispatch } from 'store/state'
import { serialize, deserialize } from './transform'
import authorizedAjax from './ajax'
import { SignableRequest } from './interfaces'
import { accessTokenSelector } from 'services/users/selectors'

type onRetry<T> = () => Observable<T>
type boundExecute<T> = (zipped: AjaxRequest) => Observable<T>

/**
 * Serializes the body if there is any present
 *
 * @param {SignableRequest} request
 * @returns {SignableRequest}
 */
function serializeBody(request: SignableRequest): SignableRequest {
  return request.body
    ? { ...request, body: serialize(request.body) }
    : request
}

/**
 * Ensure the cross domain
 *
 * @param {SignableRequest} request
 * @returns {SignableRequest}
 */
function ensureCrossDomain(request: SignableRequest): SignableRequest {
  return { ...request, crossDomain: true }
}

/**
 *
 */
function serializeAsJson(request: SignableRequest): SignableRequest {
  if (!request.body || request.body instanceof FormData) {
    return request
  }

  if (request.headers && request.headers['Content-Type'] && request.headers['Content-Type'].split(';').shift() !== 'application/json') {
    return request
  }

  return {
    ...request,

    body: typeof request.body === 'string'
      ? request.body
      : JSON.stringify(request.body)
  }
}

/**
 * Executes the ajax request
 *
 * @template T
 * @param {StoreDispatch} dispatch
 * @param {AjaxRequest} request
 * @returns
 */
function execute<T>(getState: getState, onRetry: onRetry<T>, request: AjaxRequest) {
  const onNotAuthorized = function (message: string, tags: Tags) {
    console.log('[pipe] trying to make a not authorized')
    return new NotAuthorizedException(message, { ...tags })
  }

  const boundDeserialize = function boundDeserialize(response: AjaxResponse) {
    return deserialize(response, onNotAuthorized)
  }

  return authorizedAjax<T>(Observable.ajax(request).map(boundDeserialize) as Observable<T>, getState, onRetry)
}

/**
 * Creates a bound execute that is partially applied
 *
 * @template T
 * @param {getState} getState
 * @param {onRetry<T>} onRetry
 * @returns {boundExecute<T>}
 */
function createExecute<T>(getState: getState, onRetry: onRetry<T>): boundExecute<T> {
  return function (request: AjaxRequest) {
    return execute(getState, onRetry, request)
  }
}

/**
 * Creates a function that will retry the request for left more times
 *
 * @template T
 * @param {SignableRequest} request
 * @param {StoreDispatch} dispatch
 * @param {number} left
 * @returns
 */
function createRetry<T>(request: SignableRequest, dispatch: StoreThunkDispatch, unwrap: typeof identityUnwrap, left: number): onRetry<T> {
  return function retry() {
    if (left <= 0) {
      console.error('[pipe] max retries', request)
      return Observable.throw(new Error('Maximum retries reached'))
    }

    console.log('[pipe] retry', request)
    return dispatch(authorizedPipe<T>(request, unwrap, left - 1))
  }
}

function identityUnwrap<T>(wrapped: T) {
  return Observable.of(wrapped)
}

function addAuthorization(getState: getState): (request: SignableRequest) => SignableRequest {
  return function (request: SignableRequest) {
    if (request.withAuthorization) {
      const token = accessTokenSelector(getState())

      if (!token) {
        throw new NotAuthorizedException('Missing access token')
      }

      const withQuery = request.url.indexOf('?') !== -1
      const authQueryString = `access_token=${encodeURIComponent(token)}`
      if (withQuery) {
        request.url = `${request.url}&${authQueryString}`
      } else {
        request.url = `${request.url}?${authQueryString}`
      }
    }
    return request
  }
}

/**
 * Pipes the request from request to deserialized result
 *
 * @export
 * @template T
 * @param {SignableRequest} request
 * @returns {StoreThunkAction<Observable<T>>}
 */
export function authorizedPipe<T>(
  request: SignableRequest,
  unwrap = identityUnwrap,
  retries = 1
): StoreThunkAction<Observable<T>> {
  return (dispatch: StoreDispatch, getState: getState) => {
    const retryOnce = createRetry<T>(request, dispatch, unwrap, retries)
    const boundExecute = createExecute(getState, retryOnce)
    const addAuth = addAuthorization(getState)

    return Observable.of<SignableRequest>(request)
      .map(addAuth)
      .map(serializeBody)
      .map(serializeAsJson)
      .map(ensureCrossDomain)
      .flatMap(boundExecute)
      .flatMap(unwrap)
      .do({
        error: (e: any) => {
          console.error('[pipe] error', e)
        }
      })
  }
}

export function unauthorizedPipe<T>(
  request: SignableRequest,
  unwrap = identityUnwrap,
  retries = 1
): StoreThunkAction<Observable<T>> {
  return (dispatch: StoreDispatch, getState: getState) => {
    const retryOnce = createRetry<T>(request, dispatch, unwrap, retries)
    const boundExecute = createExecute(getState, retryOnce)

    return Observable.of<SignableRequest>(request)
      .map(serializeBody)
      .map(serializeAsJson)
      .map(ensureCrossDomain)
      .flatMap(boundExecute)
      .flatMap(unwrap)
      .do({
        error: (e: any) => {
          console.error('[pipe] error', e)
        }
      })
  }
}

export default authorizedPipe
