import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { composerPostEditSelector, composerSelectedProfilesSelector, tiktokPostSelector } from 'services/compose/selectors'
import { TikTokProfileSettings } from './TikTokProfileSettings'
import Button from '@mui/material/Button'
import AvatarGroup from '@mui/material/AvatarGroup'
import Avatar from '@mui/material/Avatar'
import styles from './TikTokOptions.pcss'
import Dialog from '@mui/material/Dialog'
import { TikTokPostProfileSettings, TikTokPrivacyOptions } from 'shared'

type ComposerTikTokPostOptionsProps = {
  settings?: Record<string, TikTokPostProfileSettings>,
  popupMode?: boolean,
  actionsClassName?: string,
  singleAction?: boolean,
  options?: Record<string, TikTokPrivacyOptions>,
  skipFetchSettings?: boolean,
  onChange?: (settings?: TikTokPostProfileSettings) => void
}

export function ComposerTikTokPostOptions({
  settings, popupMode, actionsClassName, singleAction, options, skipFetchSettings, onChange
}: ComposerTikTokPostOptionsProps) {
  const profiles = useSelector(composerSelectedProfilesSelector).filter(p => p.type === 'tiktok')
  const post = useSelector(tiktokPostSelector)
  const isEditing = Boolean(useSelector(composerPostEditSelector))
  const [popupOpen, setPopupOpen] = useState(false)

  const openSettingsPopup = () => {
    setPopupOpen(true)
  }

  const closeSettingsPopup = () => {
    setPopupOpen(false)
  }

  const avatarStyles = { width: 24, height: 24 }
  const profilesIcon = (
    <AvatarGroup max={3}>
      {profiles.map(profile => (
        <Avatar key={profile.id} alt={profile.name} src={profile.image} sx={avatarStyles} />
      ))}
    </AvatarGroup>
  )

  const profileSettings = settings || post.settings || {}

  const actions = singleAction ? (
    <Button startIcon={profilesIcon} onClick={openSettingsPopup}>TikTok Settings</Button>
  ) : (
    <div className={`${styles['actions-box']} ${actionsClassName || ''} `}>
      <Button startIcon={profilesIcon} onClick={openSettingsPopup}>Privacy</Button>
      <Button startIcon={profilesIcon} onClick={openSettingsPopup}>Allow</Button>
      <Button startIcon={profilesIcon} onClick={openSettingsPopup}>Promotion video</Button>
    </div>
  )

  return (
    <div>
      {profiles.length === 1 && !popupMode && (
        <TikTokProfileSettings
          key={profiles[0].ppid}
          profile={profiles[0]}
          settings={profileSettings[profiles[0].ppid]}
          options={options && options[profiles[0].ppid]}
          ignoreSavedSettings={isEditing}
          skipFetchSettings={skipFetchSettings}
          onChange={onChange}
        />
      )}
      {(popupMode || profiles.length > 1) && actions}
      <Dialog open={popupOpen} onClose={closeSettingsPopup} keepMounted>
        <div className={styles.popup}>
          {profiles.map(profile => (
            <div key={profile.id} className={styles['settings-box']}>
              <TikTokProfileSettings
                key={profile.ppid}
                profile={profile}
                settings={profileSettings[profile.ppid]}
                options={options && options[profile.ppid]}
                showLoader
                skipFetchSettings={skipFetchSettings}
                ignoreSavedSettings={isEditing}
                className={styles['popup-settings']}
                onChange={onChange}
              />
            </div>
          ))}
        </div>
      </Dialog>
    </div>
  )
}
