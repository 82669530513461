import ApiException, { Tags } from './ApiException'
export { Tags }
export class NotAuthorizedException extends ApiException {
  constructor(message: string, tags?: Tags) {
    super(message, tags)

    Object.setPrototypeOf(this, NotAuthorizedException.prototype)
  }
}
export default NotAuthorizedException
