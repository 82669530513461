import * as React from 'react'
import ErrorOverlay from 'components/ErrorOverlay'
import { ContentFeedType, Feed } from 'interfaces'
import { MIN_SEARCH_LENGTH, SearchResultSectionState } from '../../state/interfaces'
import styles from './SearchResultsCarousel.pcss'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'
import CardList, { CARD_LIST_HEIGHT_SMALL, ITEM_WIDTH_SMALL } from 'components/CardList'
import { AddFeedCard, EmptyFeedCard, FeedCard } from 'components/Cards'
import { SearchFilterKey } from 'routes/search/state/types'
import { BRAND_FACEBOOK, BRAND_LINKEDIN } from 'shared'
import PPSelect from 'components/PPSelect'
import Icon from '@mdi/react'
import { mdiSortVariant } from '@mdi/js'
import { useSelector } from 'react-redux'
import { userDestinationsSelector } from 'services/destinations'

interface SearchResultsFeedCarouselProps extends SearchResultSectionState {
  type: 'facebook' | 'linkedin' | 'reddit' | 'rss'
  activeQuery: string
  selectedFeeds: Feed[]
  newFeedTemplate?: {
    type: ContentFeedType | undefined
    handle: string
    displayName: string
  }
  onRequestNextPage: (section: SearchFilterKey) => void
  onFeedClick: (feed: Feed) => void
  onFeedCreated: (feed: Feed, liveId: string) => void
  onFeedCreateError: (handle: string, type: ContentFeedType) => void
  onFeedSelected: (feed: Feed) => void
}

export function SearchResultsFeedCarousel(props: SearchResultsFeedCarouselProps) {
  const profiles = useSelector(userDestinationsSelector)
  const { type, newFeedTemplate, loading, items, activeQuery, onRequestNextPage } = props
  const [fbSortBy, setFbSortBy] = React.useState('live')
  const error = React.useMemo(() => {
    if (!props.error) {
      return { message: '', subtitle: '' }
    }
    if (type === BRAND_LINKEDIN) {
      const linkedinProfiles = Object.values(profiles).filter(p => p.type === BRAND_LINKEDIN)
      if (linkedinProfiles.length === 0) {
        return {
          message: 'You must connect your LinkedIn account in order to access LinkedIn feeds',
          subtitle: <NavLink className={styles['err-link']} to="/settings/networks">Go here to connect your LinkedIn account</NavLink>
        }
      }
    }
    return { message: props.error, subtitle: '' }
  }, [props.error, type, profiles])

  const withQuery = !loading && activeQuery.length >= MIN_SEARCH_LENGTH
  const isEmpty = items.length === 0 && !withQuery && !props.newFeedTemplate

  const loadNextPage = React.useCallback(() => {
    onRequestNextPage(props.type)
  }, [props.type, onRequestNextPage])

  const cards = React.useMemo(() => {
    const defaultCardsCount = 20
    const loadingCards = []
    const emptyCards = []
    if (props.loading) {
      for (let i = 0; i < defaultCardsCount; i++) {
        loadingCards.push(<EmptyFeedCard loading key={`loading-${i}`} />)
      }
    }
    if (!props.loading && props.items.length === 0) {
      for (let i = 0; i < defaultCardsCount; i++) {
        emptyCards.push(<EmptyFeedCard key={`empty-${i}`} className={styles.card} />)
      }
    }

    const sorted = type === BRAND_FACEBOOK && fbSortBy === 'db'
      ? props.items.slice().sort((a: Feed, b: Feed) => {
        return (b.fanCount || 0) - (a.fanCount || 0)
      })
      : props.items

    return sorted.map((feed: Feed) => {
      const selected = props.selectedFeeds.find(f => f.handle === feed.handle && f.type === feed.type) !== undefined
      return feed.live ? (
        <AddFeedCard
          key={feed.id}
          type={feed.type as ContentFeedType}
          handle={feed.handle}
          className={styles.card}
          displayName={feed.name}
          titleHTML={feed.titleHTML}
          pictureUrl={feed.image}
          selected={selected}
          networkId={feed.networkId}
          onNewFeedClick={props.onFeedClick}
          onFeedCreated={props.onFeedCreated}
          onFeedCreateError={props.onFeedCreateError}
          onSelect={props.onFeedSelected}
        />
      ) : (
        <FeedCard
          className={styles.card}
          key={feed.id}
          feed={feed}
          selected={selected}
          showFansCount="always"
          disableNavigation
          onClick={props.onFeedClick}
          onSelect={props.onFeedSelected}
        />
      )
    }).concat(loadingCards).concat(emptyCards)
  }, [
    props.loading,
    props.items,
    props.selectedFeeds,
    type,
    fbSortBy,
    props.onFeedClick,
    props.onFeedSelected,
    props.onFeedCreated,
    props.onFeedCreateError
  ])

  return (
    <section
      className={`${styles.container} ${isEmpty ? styles.empty : ''}`}
      data-testid="search-result-section"
    >
      <header className={styles.header}>
        <h2 className="text-ellipsis">
          <FormattedMessage id={`search.navigation.${type}`} />
        </h2>
        {items.length !== 0 && (
          <NavLink to={`/content/search/sources/${type}?q=${props.activeQuery}`}>
            <FormattedMessage id="general.carousel.nav.see-all" />
          </NavLink>
        )}
        {type === 'rss' && (
          <p className={styles.hint}>
            <FormattedMessage id="search.sections.rss.no-results-hint" />
          </p>
        )}
        {items.length !== 0 && type === BRAND_FACEBOOK && (
          <PPSelect
            options={{ live: { label: 'Exact match' }, db: { label: 'Popular on Post Planner' } }}
            selectedValue={fbSortBy}
            name="Sort by"
            icon={<Icon path={mdiSortVariant} size="20px" />}
            className={styles.select}
            onSelectionChange={setFbSortBy}
          />
        )}
      </header>
      <CardList
        key={type === BRAND_FACEBOOK ? fbSortBy : undefined}
        containerClassName={styles.carousel}
        itemWidth={ITEM_WIDTH_SMALL}
        listHeight={CARD_LIST_HEIGHT_SMALL}
        disabled={props.items.length === 0}
        onScrollLimit={loadNextPage}
      >
        {!loading && newFeedTemplate && (
          <AddFeedCard
            key="new-feed-template"
            selected={false}
            selectedIds={props.selectedFeeds.map(f => f.uniqueSource)}
            type={newFeedTemplate.type as ContentFeedType}
            handle={newFeedTemplate.handle}
            className={styles.card}
            displayName={newFeedTemplate.displayName}
            titleHTML={`<em>${newFeedTemplate.displayName}</em>`}
            onNewFeedClick={props.onFeedClick}
            onFeedCreated={props.onFeedCreated}
            onFeedCreateError={props.onFeedCreateError}
            onSelect={props.onFeedSelected}
          />
        )}
        {cards}
      </CardList>
      {props.error && !props.newFeedTemplate && (
        <ErrorOverlay
          error={error.message}
          subtitle=""
          subtitleElement={error.subtitle}
          originalError={props.error}
          className={`${styles['error-box']} ${type === 'rss' ? styles.shift : ''}`}
          small
        />
      )}
    </section>
  )
}

export default SearchResultsFeedCarousel
