import * as React from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import ProfileAndListSelector from 'routes/publishing/components/ProfileAndListSelector'
import Paper from '@mui/material/Paper'
import styles from './BucketProfilesPicker.pcss'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { connectedDestinationsSelector } from 'services/destinations'
import { ALL_LIST_KEY, userListsSelector } from 'services/lists/selectors'
import ProfileAvatar from 'components/ProfileAvatar'
import { List, sortProfilesByNetworkDefault } from 'interfaces'
import CaretIcon from '@mui/icons-material/ArrowDropDown'
import ProfilesIcon from '@mui/icons-material/People'
import Button from '@mui/material/Button'
import Popper from '@mui/material/Popper'

const PAPER_ELEVATION = 8
const TRANSITION_TIMEOUT = 250

interface BucketProfilesPickerProps {
  selectedProfileIds: string[]
  allSelectedInitial?: boolean
  onSelectedProfilesChange: (ids: string[]) => void
}

export function BucketProfilesPicker(props: BucketProfilesPickerProps) {
  const [popoverAnchor, setPopoverAnchor] = React.useState<HTMLElement | null>(null)
  const [selectedList, setSelectedList] = React.useState<List | undefined>(undefined)
  const [activeProfilesView, setActiveProfilesView] = React.useState<'grid' | 'list' | 'network'>('grid')
  const profiles = useSelector(connectedDestinationsSelector)
  const lists = useSelector(userListsSelector)

  const bucketProfiles = props.selectedProfileIds.map(id => profiles[id]).filter(Boolean).sort(sortProfilesByNetworkDefault)

  React.useEffect(() => {
    if (props.allSelectedInitial) {
      const allProfilesList = lists[ALL_LIST_KEY]
      if (allProfilesList) {
        props.onSelectedProfilesChange(allProfilesList.connectedPages)
        setSelectedList(allProfilesList)
      }
    }
  }, [lists, props.allSelectedInitial])

  const closeDropdown = () => {
    setPopoverAnchor(null)
  }

  const openDropdown = (e: React.MouseEvent) => {
    e.stopPropagation()
    setPopoverAnchor(e.target as HTMLElement)
  }

  const onSelectedProfilesChange = (ids: string[], fromList?: boolean) => {
    if (!fromList) {
      setSelectedList(undefined)
    }
    props.onSelectedProfilesChange(ids)
  }

  const onListSelected = (id?: string) => {
    if (id) {
      const list = lists[id]
      if (list.connectedPages.length > 0) {
        setSelectedList(list)
        onSelectedProfilesChange(list.connectedPages, true)
      }
    }
  }

  const onProfileClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const profileId = e.currentTarget.dataset.profileId
    if (profileId) {
      const next = props.selectedProfileIds.filter(id => id !== profileId)
      props.onSelectedProfilesChange(next)
      setSelectedList(undefined)
    }
  }

  return (
    <React.Fragment>
      <div className={styles.paper}>
        <div className={styles.left}>
          <Button
            startIcon={<ProfilesIcon />}
            endIcon={<CaretIcon />}
            onClick={openDropdown}
          >
            <FormattedMessage id="composer.labels.profiles-count" values={{ count: props.selectedProfileIds.length }} />
          </Button>
        </div>
        <div className={styles['profiles-list']}>
          {bucketProfiles.map(profile => {
            return (
              <ProfileAvatar
                key={profile.id}
                title={profile.name}
                src={profile.image}
                brand={profile.type}
                circle
                data-profile-id={profile.id}
                className={styles.avatar}
                onClick={onProfileClick}
              />
            )
          })}
          {props.selectedProfileIds.length === 0 && (
            <div className={styles['avatar-empty']}></div>
          )}
        </div>
      </div>
      <Popper
        open={Boolean(popoverAnchor)}
        className={styles.popper}
        anchorEl={popoverAnchor}
        placement="bottom"
        popperOptions={{ placement: 'auto' }}
      >
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={closeDropdown}>
          <Paper elevation={PAPER_ELEVATION}>
            <ProfileAndListSelector
              profiles={profiles}
              lists={lists}
              selectedProfileIds={props.selectedProfileIds}
              selectedList={selectedList}
              multipleProfileSelection
              withLists
              withListsEditor={false}
              activeView={activeProfilesView}
              setActiveView={setActiveProfilesView}
              onSelectedProfilesChange={onSelectedProfilesChange}
              onSelectedListChange={onListSelected}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>
  )
}

export default BucketProfilesPicker
