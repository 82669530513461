import * as React from 'react'
import { Configuration } from 'photoeditorsdk'
import Dialog from '@mui/material/Dialog'
import styles from './PhotoEditor.pcss'
import { PhotoEditor } from './PhotoEditor'
import { dataURLtoFile } from 'utils/file'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { message } from 'services/snackbar'

const DEFAULT_EXPORT_FILE_NAME = 'photoeditor-export.jpg'

interface PhotoEditorDialogProps {
  open: boolean
  editId: string
  image?: string | File
  fileName?: string
  editorConfig?: Omit<Configuration, 'container' | 'license' | 'image'>
  onExport: (file: File, editId: string) => void
  onError: (error: Error) => void
  onClose: () => void
}

export function PhotoEditorDialog(props: PhotoEditorDialogProps) {
  const [imageSrc, setImageSrc] = React.useState<string | null>(null)
  const dispatch = useDispatch()
  const intl = useIntl()

  React.useEffect(() => {
    const image = props.image
    if (!image) {
      return
    }

    if (image instanceof File) {
      const reader = new FileReader()
      reader.onload = () => {
        setImageSrc(reader.result as string)
      }
      reader.onerror = () => {
        setImageSrc(null)
        props.onError(new Error('File could not be loaded.'))
      }
      reader.readAsDataURL(image)
    } else {
      // EXPL: add a random parameter to the url to force-fetch the image instead of loading it from browser cache.
      const url = image.indexOf('?') === -1
        ? `${image}?v=${Date.now()}`
        : `${image}&v=${Date.now()}`
      setImageSrc(url)
      // EXPL: We need this to detect CORS errors in photo editor.
      // If not handled, editor will freeze, but error callback will not be called.
      fetch(url).catch((e) => {
        console.log('[photoeditor] image load failed', e)
        dispatch(message(intl.formatMessage({ id: 'photoeditor.notifications.image-load-failed' }), 'error'))
        setImageSrc(null)
        props.onClose()
      })
    }
  }, [props.image])

  const close = () => {
    setImageSrc(null)
    props.onClose()
  }

  const exportFile = (dataUrl: string) => {
    const file = dataURLtoFile(dataUrl, props.fileName || DEFAULT_EXPORT_FILE_NAME)
    props.onExport(file, props.editId)
    close()
  }

  return (
    <Dialog
      open={props.open}
      maxWidth="lg"
      classes={{ paper: styles.dialog }}
      data-testid="photo-editor-dialog"
      onClose={close}
    >
      <div className={styles['dialog-content']}>
        {imageSrc && (
          <PhotoEditor
            image={imageSrc}
            config={props.editorConfig}
            onClose={close}
            onExport={exportFile}
            onError={props.onError}
          />
        )}
      </div>
    </Dialog>
  )
}
