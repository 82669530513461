import StoreState from 'store/state'
import { V1 } from './net'
import { impersonationSelector } from 'admin/services/selectors'
import { currentUserSelector } from 'services/users/selectors'
import { destinationsAdapter, listsAdapter, PostDestination } from 'shared'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { mapAccountResponse, teamMemberAdapter } from './adapters'
import { defaultPostToDestinationsSelector, getDestinations } from 'services/destinations'
import { defaultTimezoneSelector } from './selectors'
import { updateAllPlansTimezones } from 'services/plan'

export const getAccountSuccess = createAction(
  'ACTION_GET_ACCOUNT_SUCCESS',
  (payload: any) => ({ payload })
)

export const getAccountInfo = createAsyncThunk(
  'settings/getAccount',
  async (_, { dispatch, getState }) => {
    const isImpersonating = impersonationSelector(getState() as StoreState)
    const response = await dispatch(V1.getAccountInfo()).toPromise()
    const data = dispatch(mapAccountResponse(response))
    const payload = { ...data, isImpersonating: Boolean(isImpersonating) }
    dispatch(getAccountSuccess(payload))
    return payload
  }
)

export const downgradeFree = createAsyncThunk(
  'settings/downgradeFree',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const appId = currentUserSelector(getState() as StoreState)?.account.id + ''
      return await dispatch(V1.downgradeFree(appId)).toPromise()
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getDefaults = createAsyncThunk(
  'settings/defaults/get',
  async (_, { dispatch }) => {
    const response = await dispatch(V1.getDefaults()).toPromise()
    const pages = destinationsAdapter(response.pages || [])
    const connectedPageIds = pages.filter(p => p.connected).map(p => p.id)

    return {
      pages,
      lists: listsAdapter(response.lists || [], connectedPageIds),
      timezone: response.userTimezone || '',
      timezones: response.timezones.reduce((zonesObject: { [key: string]: string }, current) => {
        zonesObject[current.zone] = current.label
        return zonesObject
      }, {})
    }
  }
)

export const ACTION_GET_SETTINGS_DEFAULTS_SUCCESS = 'ACTION_GET_SETTINGS_DEFAULTS_SUCCESS'

export const updateDefaults = createAsyncThunk(
  'settings/defaults/update',
  async (args: { profileIds?: string[], timezone?: string, setPlansTimezones?: boolean }, { dispatch, getState }) => {
    const { profileIds, timezone, setPlansTimezones } = args
    const state = getState() as StoreState
    const ids = profileIds || Object.keys(defaultPostToDestinationsSelector(state))
    const zone = timezone || defaultTimezoneSelector(state)

    await dispatch(V1.updateDefaults(ids, zone, setPlansTimezones)).toPromise()
    if (timezone && setPlansTimezones) {
      dispatch(updateAllPlansTimezones(timezone))
    }
    dispatch(getDestinations(true))
    const payload = { profileIds: ids, timezone: zone }
    return payload
  }
)

export const updateGroupPublisher = createAsyncThunk(
  'settings/networks/updateGroupPublisher',
  async (args: { ppid: string, publishBy: string }, { dispatch }) => {
    await dispatch(V1.updateGroupPublisher(args.ppid, args.publishBy)).toPromise()
  }
)

export const getTeamSettings = createAsyncThunk(
  'settings/getTeam',
  async (_, { dispatch }) => {
    const response = await dispatch(V1.getTeam()).toPromise()
    return {
      friends: response.friends,
      isInvited: response.isInvited,
      masterName: response.masterName,
      adminsLimit: parseInt(response.adminsLimit, 10),
      members: Object.keys(response.permissions).reduce((members: any, id) => {
        const current = teamMemberAdapter(response.permissions[id])
        members[id] = { ...current, userId: id }
        return members
      }, {})
    }
  }
)

export const addTeamMember = createAsyncThunk(
  'settings/addTeamMember',
  async (args: { email: string, profiles: PostDestination[] }, { dispatch, rejectWithValue }) => {
    const ppIds = args.profiles.map(p => p.ppid)
    try {
      const data = await dispatch(V1.addTeamMember(args.email, ppIds)).toPromise()
      const accounts = Object.keys(data.permissions).reduce((formatted: any, id: string) => {
        const key = id.indexOf('_') === -1 ? id : id.split('_')[1]
        formatted[key] = data.permissions[id]
        return formatted
      }, {})

      return teamMemberAdapter({ ...data, accounts })
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const removeTeamMember = createAsyncThunk(
  'settings/removeTeamMember',
  async (id: string, { dispatch, rejectWithValue }) => {
    const response = await dispatch(V1.removeTeamMember(id)).toPromise()
    if (response.error) {
      return rejectWithValue(response.error)
    }
    return id
  }
)

export const addMemberPermission = createAsyncThunk(
  'settings/addMemberPermission',
  async (args: { memberId: string, memberName: string, ppid: string, memberFbId?: string }, { dispatch }) => {
    const response = await dispatch(V1.addPermission(args.memberId, args.memberName, args.ppid, args.memberFbId)).toPromise()
    return { memberId: args.memberId, ppid: args.ppid, permissionId: response.permissionId }
  }
)

export const removeMemberPermission = createAsyncThunk(
  'settings/removeMemberPermission',
  async (args: { memberId: string, permissionId: string, ppid: string }, { dispatch, rejectWithValue }) => {
    const { memberId, permissionId, ppid } = args
    const response = await dispatch(V1.removePermission(permissionId, ppid)).toPromise()
    if (response.error) {
      return rejectWithValue(response.error)
    }
    return { memberId, ppid }
  }
)
