import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { BRAND_FACEBOOK, BRAND_TWITTER } from 'interfaces'

export const EXTERNAL_SERVICES_STATE_VERSION = 'external:0'
type SDKSource = typeof BRAND_FACEBOOK | typeof BRAND_TWITTER

interface SDKState {
  initialized: boolean
}

export interface ExternalServicesState {
  [BRAND_FACEBOOK]: SDKState
  [BRAND_TWITTER]: SDKState
}

export function initialState(): ExternalServicesState {
  return {
    [BRAND_FACEBOOK]: Object.freeze({ initialized: false }),
    [BRAND_TWITTER]: Object.freeze({ initialized: false })
  }
}

const externalServicesSlice = createSlice({
  name: 'external',
  initialState: initialState(),
  reducers: {
    sdkInitialized: (state, action: PayloadAction<SDKSource>) => {
      state[action.payload].initialized = true
    },
    sdkRemoved: (state, action: PayloadAction<SDKSource>) => {
      state[action.payload].initialized = false
    }
  }
})

const { reducer, actions } = externalServicesSlice
export const { sdkInitialized, sdkRemoved } = actions
export default reducer
