import { Callout } from 'components/PageCallout'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { initialState } from './state'

const uiSlice = createSlice({
  name: 'ui',
  initialState: initialState(),
  reducers: {
    toggleCallout: (state, action: PayloadAction<string>) => {
      const slug = action.payload.startsWith('/') ? action.payload.slice(1) : action.payload
      const callout = state.callouts.find(c => c.slug === slug)
      if (callout) {
        callout.hidden = !callout.hidden
      }
    },
    setActiveCallout: (state, action: PayloadAction<Callout | undefined>) => {
      state.activeCallout = action.payload
    },
    setTrialPopupOpen: (state, action: PayloadAction<{ handle: string | null, isAnnual?: boolean }>) => {
      state.trialPopupForceOpen = action.payload
    },
    setTrialWidgetHidden: (state, action: PayloadAction<boolean>) => {
      state.trainingWidgetHidden = action.payload
    },
    infoBoxesFetched: (state, action: PayloadAction<{ callouts: Callout[], version: number }>) => {
      const { callouts, version } = action.payload
      const prev = state.callouts.reduce((acc, c) => ({ ...acc, [c.slug]: c }), {} as Record<string, Callout>)
      state.callouts = version === state.calloutsVersion
        ? callouts.map(c => ({ ...c, hidden: prev[c.slug]?.hidden || false }))
        : callouts
      state.calloutsVersion = version
    }
  }
})

const { actions, reducer } = uiSlice
export default reducer
export const { toggleCallout, setActiveCallout, setTrialPopupOpen, setTrialWidgetHidden, infoBoxesFetched } = actions
