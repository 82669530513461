import * as React from 'react'
import { Article, Gif, Photo, Video } from 'interfaces'
import WarningIon from '@mui/icons-material/WarningRounded'
import { FormattedMessage } from 'react-intl'
import { MS_IN_SEC } from 'utils/constants'

import styles from './EmbeddedRedditPost.pcss'

interface EmbeddedRedditPostProps {
  post: Article | Photo | Video | Gif
  className?: string
}

export function EmbeddedRedditPost(props: EmbeddedRedditPostProps) {
  const postUrl = props.post.socialLink
  const timestamp = Math.floor(Date.now() / MS_IN_SEC)

  React.useEffect(() => {
    const s = document.getElementById('reddit-sdk')
    if (s) {
      s.remove()
    }
    const script = document.createElement('script')
    script.src = '//embed.redditmedia.com/widgets/platform.js'
    script.async = true
    script.id = 'reddit-sdk'
    document.body.appendChild(script)

    return () => {
      script.remove()
    }
  }, [])

  return (
    <div className={`${styles.container} ${props.className || ''}`}>
      {
        postUrl && (
          <blockquote
            className="reddit-card"
            data-card-created={timestamp}
          >
            <a href={postUrl}>
              {(props.post as Article).description || (props.post as Article).title}
            </a>
          </blockquote>
        )
      }
      {
        !postUrl && (
          <p className={styles.error}>
            <WarningIon className={styles['error-icon']} />
            <FormattedMessage id="error.reddit-url-missing" />
          </p>
        )
      }
    </div>
  )
}

export default EmbeddedRedditPost
