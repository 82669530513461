import { WritableDraft } from 'immer/dist/internal'
import { PinterestBoard } from 'interfaces'
import ComposerState from '../state'
import { PayloadAction } from '@reduxjs/toolkit'

export const pinterestReducers = {
  selectPinterestBoard: (state: WritableDraft<ComposerState>, action: PayloadAction<{ ppid: string, board: PinterestBoard }>) => {
    const { ppid, board } = action.payload
    let profileBoards = state.pinterestBoards[ppid] || [action.payload.board]
    profileBoards = profileBoards.map(b => ({ ...b, selected: b.id === board.id }))

    state.pinterestBoards[ppid] = profileBoards
  },
  // rewrite all functions above in the same format as selectPinterestBoard
  clearPinterestBoards: (state: WritableDraft<ComposerState>) => {
    state.pinterestBoards = {}
  },
  setPinterestProfileBoards: (
    state: WritableDraft<ComposerState>,
    action: PayloadAction<{ ppid: string, boards: Record<string, { id: string, name: string }> }>
  ) => {
    const { ppid, boards } = action.payload
    const boardsArray = Object.values(boards)
    const prevProfileBoards = state.pinterestBoards[ppid]
    const nextProfileBoards = prevProfileBoards
      ? boardsArray.map(b => ({ ...b, selected: prevProfileBoards.find(board => board.selected && board.id === b.id) }))
      : boardsArray

    state.pinterestBoards[ppid] = nextProfileBoards
  },
  setPinTitle: (state: WritableDraft<ComposerState>, action: PayloadAction<{ title: string, auto: boolean }>) => {
    const { title, auto } = action.payload
    const pin = state.posts.pinterest
    pin.title = title
    pin.status.touched = !auto
  },
  setPinDescription: (state: WritableDraft<ComposerState>, action: PayloadAction<{ description: string, auto: boolean }>) => {
    const { description, auto } = action.payload
    const pin = state.posts.pinterest
    pin.description = description
    pin.status.touched = !auto
  },
  setPinDestinationUrl: (state: WritableDraft<ComposerState>, action: PayloadAction<string>) => {
    state.posts.pinterest.destinationUrl = action.payload
  }
}
