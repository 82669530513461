import { StoreThunkAction, StoreThunkDispatch } from 'store/state'
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/of'
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/filter'
import { Subject } from 'rxjs/Subject'
import 'rxjs/add/operator/share'

import { TIMERANGE_FILTER_DEFAULT } from 'config'
import { V1 } from './net'
import { articlesAdapter, getCacheKey, SortBy } from '../util'
import { Article, Paginated, RangeFilter, ARTICLE_TYPE, NEW_RANGE_FILTER } from 'interfaces'
import { getCachedContent, cacheContentTemp } from '../cache'

function sortArticlesBy(sortBy: SortBy.Likes | SortBy.Date) {
  return sortBy === SortBy.Likes
    ? (article1: Article, article2: Article) => {
      return article2.likes - article1.likes
    } : (article1: Article, article2: Article) => {
      const d1 = new Date(article1.createdAt)
      const d2 = new Date(article2.createdAt)

      return d2.getTime() - d1.getTime()
    }
}

/**
 * Fetches articles from single source or a folder
 *
 * @export
 * @param {string} sourceId the source or folder id
 * @param {boolean} [isStream] is it fetching from a folder
 * @param {number} [page=0] page number (first page is 0)
 * @param {RangeFilter} [rangeFilter=TIMERANGE_FILTER_DEFAULT]
 * @returns {StoreThunkAction<Observable<Paginated<Article[]>>>}
 */
export function getArticles(
  sourceId: string,
  isStream?: boolean,
  page: number = 0,
  rangeFilter: RangeFilter = TIMERANGE_FILTER_DEFAULT,
  sortBy: SortBy = SortBy.Engagement
): StoreThunkAction<Observable<Paginated<Article[]>>> {

  return (dispatch) => {
    const cacheKey = getCacheKey(Boolean(isStream), sourceId, ARTICLE_TYPE, rangeFilter, page, sortBy)
    const cachedArticles = dispatch(getCachedContent(cacheKey))

    if (cachedArticles) {
      return Observable.of(cachedArticles as Paginated<Article[]>)
    }

    const getArticles = isStream
      ? dispatch(V1.getArticlesFromFolder(sourceId, page, rangeFilter, sortBy))
      : dispatch(V1.getArticles(sourceId, page, rangeFilter, sortBy))

    return getArticles
      .map(response => response.content)
      .filter(Boolean)
      .map(articlesAdapter)
      .map(formatted => {
        const sorted = formatted.items
        if (rangeFilter !== NEW_RANGE_FILTER && sorted[0]?.live && sortBy !== SortBy.Engagement) {
          sorted.sort(sortArticlesBy(sortBy as any))
        }
        return { ...formatted, items: sorted }
      })
      .do({
        next: (response) => {
          // Do not cache empty response
          if (response.items.length > 0) {
            dispatch(cacheContentTemp(cacheKey, response))
          }
        }
      })
  }
}

export function getArticlesFromRssUrl(url: string) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.getArticlesFromRss(url))
  }
}

const MAX_CONCURRENT_REQUESTS = 10

export const getArticleData = function () {
  const requestQueue$: Subject<{ url: string, dispatch: StoreThunkDispatch }> = new Subject()
  const results = requestQueue$
    .mergeMap((data) => data.dispatch(V1.getArticleData(data.url)), MAX_CONCURRENT_REQUESTS)
    .map(response => ({
      ...response,
      url: response.requestUrl,
      link: response.url,
      imageSrc: response.image
    }))
    .share()

  const fetch = (url: string, dispatch: StoreThunkDispatch) => {
    requestQueue$.next({ url, dispatch })
  }

  return { results, fetch }
}()
