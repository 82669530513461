import { createElement, Component, ReactNode } from 'react'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import { FilterType } from 'interfaces'
import { MessageDescriptor } from 'react-intl'
import { STOCK_ALL_FILTER } from 'interfaces/Content/StockContentTypes'

export const NO_CONTENT_MESSAGE = (type: FilterType, formatMessage: (descriptor: MessageDescriptor, values?: any) => string) => {
  let contentType = formatMessage({ id: `general.content.filters.${type}` })
  if (type === STOCK_ALL_FILTER) {
    contentType = formatMessage({ id: 'general.labels.stock-content-items' })
  }
  return formatMessage({ id: 'content.msg-no-content' }, { contentType })
}

export const NO_CONTENT_POPULAR_MESSAGE = (type: FilterType, formatMessage: (descriptor: MessageDescriptor, values?: any) => string) => {
  const contentType = formatMessage({ id: `general.content.filters.${type}` })
  return formatMessage({ id: 'content.msg-no-content-curated' }, { contentType })
}

export const AJAX_ERROR_FEED_NOT_FOUND = (formatMessage: (descriptor: MessageDescriptor, values?: any) => string) => {
  return formatMessage({ id: 'content.error.feed-not-found' })
}

export const AJAX_ERROR_FEED_NOT_FOUND_SUBTITLE = (formatMessage: (descriptor: MessageDescriptor, values?: any) => string) => {
  return formatMessage({ id: 'content.error.feed-not-found-subtitle' })
}

export const AJAX_ERROR_STREAM_NOT_FOUND = (formatMessage: (descriptor: MessageDescriptor, values?: any) => string) => {
  return formatMessage({ id: 'content.error.stream-not-found' })
}

export const AJAX_ERROR_STREAM_NOT_FOUND_SUBTITLE = (formatMessage: (descriptor: MessageDescriptor, values?: any) => string) => {
  return formatMessage({ id: 'content.error.stream-not-found-subtitle' })
}

export const AJAX_ERROR_POPULAR_MESSAGE = (formatMessage: (descriptor: MessageDescriptor, values?: any) => string) => {
  return formatMessage({ id: 'content.error.curated-stream' })
}

export const AJAX_ERROR_STREAM_SUBTITLE = (formatMessage: (descriptor: MessageDescriptor, values?: any) => string) => {
  return formatMessage({ id: 'content.error.curated-stream-subtitle' })
}

export const NO_CONTENT_MESSAGE_CAROUSEL = (type: FilterType, formatMessage: (descriptor: MessageDescriptor, values?: any) => string) => {
  let contentType = formatMessage({ id: `general.content.filters.${type}` })
  if (type === STOCK_ALL_FILTER) {
    contentType = formatMessage({ id: 'general.labels.stock-content-items' })
  }
  return formatMessage({ id: 'content.msg.carousel.empty-type' }, { contentType })
}

export const NO_CONTENT_MESSAGE_FAVORITES = (type: FilterType, formatMessage: (descriptor: MessageDescriptor, values?: any) => string) => {
  const contentType = formatMessage({ id: `general.content.filters.${type}` })
  return formatMessage({ id: 'content.msg.carousel.favorites-empty' }, { contentType })
}

export const PROFILES_NOT_CONNECTED_MESSAGE = (formatMessage: (descriptor: MessageDescriptor, values?: any) => string) => {
  return formatMessage({ id: 'content.error.profiles-not-connected' })
}

import styles from './ErrorOverlay.pcss'

export interface ErrorOverlayProps {
  error: string
  subtitle: string
  subtitleElement?: ReactNode
  originalError: any
  className?: string
  offset?: number
  contentType?: string
  small?: boolean
}

interface ErrorOverlayState {
  animate: boolean
}

export class ErrorOverlay extends Component<ErrorOverlayProps, ErrorOverlayState> {

  constructor(props: ErrorOverlayProps) {
    super(props)

    this.state = {
      animate: false
    }
  }

  shouldComponentUpdate(nextProps: ErrorOverlayProps, nextState: ErrorOverlayState) {
    return nextProps.offset !== this.props.offset
      || nextProps.error !== this.props.error
      || nextProps.subtitle !== this.props.subtitle
      || nextState.animate !== this.state.animate
  }

  render() {
    const { error, subtitle, subtitleElement, originalError, offset, contentType, small } = this.props
    const animate = this.state.animate
    const minOffset = 40
    const style = typeof offset === 'undefined'
      ? {}
      : { top: `${offset === 0 ? minOffset : offset}px` }

    const className = `${styles.error} ${animate ? styles.animate : ''} ${this.props.className || ''} ${small ? styles.small : ''}`
    const messageClassName = `${styles.message} ${contentType ? styles[contentType] : ''}`

    return createElement('div', { key: 'error', className, style, 'data-test': 'error-box' },
      createElement('div', { className: messageClassName }, [
        createElement('span', { className: styles['content-icon'], key: 'content-icon' }),
        !contentType && createElement(ErrorIcon, { key: 'icon', className: styles.icon }),
        createElement('h3', { key: 'error', title: originalError }, error),
        createElement('span', { key: 'subtitle', className: styles.subtitle }, [
          subtitle,
          subtitleElement || null
        ])
      ])
    )
  }

  componentDidMount() {
    this.setState((previousState) => ({ ...previousState, animate: true }))
  }
}

export default ErrorOverlay
