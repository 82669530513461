import React from 'react'
import { BrandifyBrand } from 'interfaces'
import { ArticleSharesPerNetwork } from 'interfaces/Content/Article'
import SocialIcon from 'components/SocialIcon'
import numberToHuman from 'utils/format/numberToHuman'
import styles from './SharesPerNetwork.pcss'

interface SharesPerNetworkProps {
  sharesPerNetwork: ArticleSharesPerNetwork
}

function SharesPerNetwork({ sharesPerNetwork }: SharesPerNetworkProps) {
  return (
    <div className={styles['shares-per-network']}>
      {
        Object.entries(sharesPerNetwork)
          .map(([key, value]) => {
            if (key === 'totalShares' || typeof value !== 'number') {
              return null
            }

            return (
              <div key={key} className={styles['shares-per-network-item']}>
                <span className={styles['shares-per-network-item-value']}>{numberToHuman(value)}</span>
                <SocialIcon icon={key as BrandifyBrand} size="12px" />
              </div>
            )
          })
      }
    </div>
  )
}

export default SharesPerNetwork
