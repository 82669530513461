import { FileType } from '../misc'
import { STOCK_PHOTO_TYPE, STOCK_VIDEO_TYPE, STOCK_GIF_TYPE, STOCK_ALL_FILTER } from './StockContentTypes'

export const GIF_TYPE = 'gifs'
export const PHOTO_TYPE = 'photos'
export const ARTICLE_TYPE = 'articles'
export const VIDEO_TYPE = 'videos'
export const STATUS_TYPE = 'status'
export const TEXT_TYPE = 'texts'

export const POST_TYPES: ContentType[] = [PHOTO_TYPE, ARTICLE_TYPE, VIDEO_TYPE, STATUS_TYPE]

export type ContentType = typeof GIF_TYPE
  | typeof PHOTO_TYPE
  | typeof ARTICLE_TYPE
  | typeof VIDEO_TYPE
  | typeof STATUS_TYPE
  | typeof STOCK_PHOTO_TYPE
  | typeof STOCK_VIDEO_TYPE
  | typeof STOCK_GIF_TYPE
  | typeof TEXT_TYPE

export default ContentType

export const ALL_FILTER = 'all'

export type FilterType = ContentType | typeof ALL_FILTER | typeof STOCK_ALL_FILTER

export const VALID_TYPE_FILTERS = [
  PHOTO_TYPE,
  ARTICLE_TYPE,
  VIDEO_TYPE,
  GIF_TYPE,
  STATUS_TYPE,
  STOCK_PHOTO_TYPE,
  STOCK_VIDEO_TYPE,
  STOCK_GIF_TYPE,
  STOCK_ALL_FILTER,
  TEXT_TYPE
]
export const CONTENT_TYPE_TO_FILE_TYPE: { [key: string]: FileType } = {
  [PHOTO_TYPE]: FileType.Image,
  [GIF_TYPE]: FileType.Gif,
  [VIDEO_TYPE]: FileType.Video
}

// export const CONTENT_TYPE_STOCK_MAP: IndexedObject<ContentType> = {
//   [STOCK_PHOTO_TYPE]: PHOTO_TYPE,
//   [STOCK_VIDEO_TYPE]: VIDEO_TYPE,
//   [STOCK_GIF_TYPE]: GIF_TYPE,
//   [ARTICLE_TYPE]: ARTICLE_TYPE,
//   [VIDEO_TYPE]: VIDEO_TYPE
// }
