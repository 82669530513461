import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import WarningIcon from '@mui/icons-material/WarningRounded'
import Logo from 'components/PPLogo'

import styles from './MaintenanceView.pcss'

export function MaintenanceView(props: {
  title: string | React.ReactChild,
  subtitle: string | React.ReactChild,
  action?: React.ReactChild
}) {
  return (
    <Dialog open classes={{ paper: styles.dialog }}>
      <a href="https://postplanner.com">
        <Logo key="logo" className={styles.logo} />
      </a>
      <h1 className={styles.title}>
        <WarningIcon className={styles.icon} />
        {props.title}
      </h1>
      <p className={styles.message}>{props.subtitle}</p>
      {
        props.action && (
          <div className={styles.action}>
            {props.action}
          </div>
        )
      }
    </Dialog>
  )
}
