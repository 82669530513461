import * as React from 'react'
import { FileDialog } from './FileDialog'
import { connect } from 'react-redux'
import StoreState, { StoreThunkDispatch } from 'store/state'
import { setFileInPreview } from 'services/uploads/actions'
import { previewFileSelector, myFileFoldersSelector } from 'services/uploads/selectors'
import { MyFile } from 'interfaces'
import { injectIntl } from 'react-intl'

function mapStateToProps(state: StoreState) {
  const file = previewFileSelector(state)
  const folder = file ? myFileFoldersSelector(state)[file.folderId] : undefined

  return { file, folder }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    setFileInPreview: (file?: MyFile) => dispatch(setFileInPreview(file))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FileDialog))
