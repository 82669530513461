import { RequestBuilder, authorizedPipe } from 'services/net'
import { StatusIdea, StatusesResponse } from 'interfaces'
import { Result } from '../../net/interfaces'
import { CONTENT_SERVICE_URL } from 'config'
import { MS_IN_DAY } from 'utils/constants'

export const ENDPOINT_GET_STATUS_IDEAS = `${CONTENT_SERVICE_URL}/status_ideas/:seed/:ids`
export const ENDPOINT_SEARCH_STATUS_IDEAS = `${CONTENT_SERVICE_URL}/status_ideas/search`
const ENDPOINT_SEARCH_STATUS_IDEAS_BY_TAG = `${CONTENT_SERVICE_URL}/status_ideas/search_tags`
export const ENDPOINT_SEARCH_AUTOCOMPLETE = `${CONTENT_SERVICE_URL}/status_ideas/status_lookahead`
const ENDPOINT_SEARCHES = `${CONTENT_SERVICE_URL}/status_ideas/search/recent`
const ENDPOINT_CREATE_STATUS = `${CONTENT_SERVICE_URL}/status_ideas`
const ENDPOINT_GET_STATUSES_RANGE = `${CONTENT_SERVICE_URL}/status_ideas/admin_search`
const ENDPOINT_DELETE_SEARCH = `${CONTENT_SERVICE_URL}/status_ideas/search/recent/:term`
export const ENDPOINT_GET_TAGS = `${CONTENT_SERVICE_URL}/status_ideas/tags`
export const ENDPOINT_UPDATE_TAG = `${CONTENT_SERVICE_URL}/status_ideas/tags/:id`
const ENDPOINT_DELETE_STATUS = `${CONTENT_SERVICE_URL}/status_ideas/:id`
const ENDPOINT_UPDATE_STATUS = `${CONTENT_SERVICE_URL}/status_ideas/:id`
const ENDPOINT_KEYWORDS = `${CONTENT_SERVICE_URL}/status_ideas/keywords`
const ENDPOINT_DELETE_KEYWORD = `${CONTENT_SERVICE_URL}/status_ideas/keywords/:id`

export interface AutocompleteResult {
  wordCount: number
  word: string
}

export type GetStatusesResult = Result<Array<StatusIdea>, 'statuses'>

export const V1 = Object.freeze({
  getStatusIdeas: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_STATUS_IDEAS).asGet().expectJSON()

    return function (categoryIds: Array<string>, page = 0, seed?: string) {
      const statusesSeed = seed || Math.ceil(Date.now() / MS_IN_DAY) + ''
      return authorizedPipe<StatusesResponse>(
        builder
          .partial(':ids', categoryIds.join(','))
          .partial(':seed', statusesSeed)
          .param('page', page + '')
          .build()
      )
    }
  }(),
  searchStatusIdeas: function () {
    const builder = new RequestBuilder(ENDPOINT_SEARCH_STATUS_IDEAS).asPost().expectJSON().asFormUrlEncoded()

    return function (search: string, page: number, tag?: string) {
      const data: any = { search, page }
      if (tag) {
        data.tag = tag
      }
      return authorizedPipe<{ statusIdeas: Array<StatusIdea> }>(
        builder
          .body(data)
          .build()
      )
    }
  }(),
  searchStatusIdeasByTag: function () {
    const builder = new RequestBuilder(ENDPOINT_SEARCH_STATUS_IDEAS_BY_TAG).asPost().asJSON()
    return function (page: number, tag: string) {
      return authorizedPipe<any>(
        builder
          .param('page', page.toString())
          .body({ search: tag })
          .build()
      )
    }
  }(),
  getStatusesRange: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_STATUSES_RANGE).asPost().asJSON()
    return function (from: number, to: number) {
      return authorizedPipe<any>(
        builder
          .body({ start_id: from, end_id: to })
          .build()
      )
    }
  }(),
  createStatusIdea: function () {
    const builder = new RequestBuilder(ENDPOINT_CREATE_STATUS).asPut().asJSON()
    return function (status: string, tags?: string[]) {
      const data: any = { status }
      if (tags) {
        data.tags = tags
      }

      return authorizedPipe<any>(
        builder.body(data).build()
      )
    }
  }(),
  getQuerySuggestions: function () {
    const builder = new RequestBuilder(ENDPOINT_SEARCH_AUTOCOMPLETE).asPost().expectJSON()

    return function (search: string) {
      return authorizedPipe<Array<AutocompleteResult>>(
        builder
          .body({ search })
          .asFormUrlEncoded()
          .build()
      )
    }
  }(),
  getPastSearches: function () {
    const builder = new RequestBuilder(ENDPOINT_SEARCHES).asGet().expectJSON()
    return function () {
      return authorizedPipe<any>(
        builder.build()
      )
    }
  }(),
  saveSearch: function () {
    const builder = new RequestBuilder(ENDPOINT_SEARCHES).asPost().expectJSON().asJSON()
    return function (query: string) {
      return authorizedPipe<any>(
        builder
          .body({ term: query })
          .build()
      )
    }
  }(),
  deleteSavedSearch: function () {
    const builder = new RequestBuilder(ENDPOINT_DELETE_SEARCH).asDelete().expectJSON()
    return function (text: string) {
      return authorizedPipe<any>(
        builder
          .partial(':term', text)
          .build()
      )
    }
  }(),
  getTags: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_TAGS).asGet().expectJSON()
    return function () {
      return authorizedPipe<any>(
        builder.build()
      )
    }
  }(),
  updateTag: function () {
    const builder = new RequestBuilder(ENDPOINT_UPDATE_TAG).asPost().expectJSON().asJSON()
    return function (id: number, active: boolean) {
      return authorizedPipe<any>(
        builder
          .partial(':id', id.toString())
          .body({ is_visible: active })
          .build()
      )
    }
  }(),
  deleteStatus: function () {
    const builder = new RequestBuilder(ENDPOINT_DELETE_STATUS).asDelete().asJSON()
    return function (id: string) {
      return authorizedPipe<any>(
        builder.partial(':id', id).build()
      )
    }
  }(),
  updateStatus: function () {
    const builder = new RequestBuilder(ENDPOINT_UPDATE_STATUS).asPost().asJSON()
    return function (id: string, status: string, author?: string, tags?: string[]) {
      const data: any = { status, author, tags }
      // if (tags) {
      //   data.tags = tags.map(id => ({ id }))
      // }

      return authorizedPipe<any>(
        builder
          .partial(':id', id)
          .body(data)
          .build()
      )
    }
  }(),
  getKeywords: function () {
    const builder = new RequestBuilder(ENDPOINT_KEYWORDS).asGet()
    return function () {
      return authorizedPipe<any>(
        builder.build()
      )
    }
  }(),
  addKeyword: function () {
    const builder = new RequestBuilder(ENDPOINT_KEYWORDS).asPut().asJSON()
    return function (keyword: string) {
      return authorizedPipe<any>(
        builder.body({ name: keyword }).build()
      )
    }
  }(),
  deleteKeyword: function () {
    const builder = new RequestBuilder(ENDPOINT_DELETE_KEYWORD).asDelete().asJSON()
    return function (id: number) {
      return authorizedPipe<any>(
        builder.partial(':id', id.toString()).build()
      )
    }
  }()
})
