export const HS_EVENT_SUBSCRIPTION_CHANGE = 'pe513577_subscription_change'
export const HS_EVENT_POPUP_UPGRADE = 'pe513577_popup_upgrade'
export const HS_EVENT_NEW_PROFILE = 'pe513577_new_profile'
export const HS_EVENT_POST_CREATED = 'pe513577_post_created'
export const HS_EVENT_POST_PUBLISHED = 'pe513577_post_published'
export const HS_EVENT_POST_COPIED = 'pe513577_post_copied'
export const HS_EVENT_CONTENT_SEARCHED = 'pe513577_searched_content'
export const HS_EVENT_SOURCE_SEARCHED = 'pe513577_searched_sources'
export const HS_EVENT_SOURCE_SAVED = 'pe513577_saved_source'
export const HS_EVENT_CONTENT_FAVORITED = 'pe513577_favorited_content'
export const HS_EVENT_POSTS_SORTED = 'pe513577_posts_sorted'
export const HS_EVENT_POSTS_SHUFFLED = 'pe513577_posts_shuffled'
export const HS_EVENT_BUCKET_CREATED = 'pe513577_bucket_created'
export const HS_EVENT_DRAFT_SAVED = 'pe513577_draft_saved'
export const HS_EVENT_PLAN_ACTION = 'pe513577_plan_action'
export const HS_EVENT_SAVED_TEXT_ACTION = 'pe513577_saved_text_action'
export const HS_EVENT_URL_VISITED = 'visited'
export const HS_EVENT_APP_BOOT = 'app_open'
