import Button from '@mui/material/Button'
import { ARTICLE_TYPE } from 'interfaces'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { composerContentItemSelector, composerLinkSelector } from 'services/compose/selectors'
import Credits from '../Credits'
import ArticleCredits from '../Credits/ArticleCredits'
import SavedTexts from '../SavedTexts'
import styles from './SuggestedTexts.pcss'
import Icon from '@mdi/react'
import { HashtagsGenerator } from './HashtagsGenerator'
import { mdiTextBoxCheckOutline, mdiLightbulbOnOutline, mdiLink, mdiCommentTextOutline, mdiPound, mdiAutoFix } from '@mdi/js'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'

type SuggestedTextsView = 'texts' | 'credits' | 'link' | 'hashtags' | 'writer'

export function SuggestedTexts({ onQuotesButtonClick }: { onQuotesButtonClick?: () => void }) {
  const match = useMatch('/composer/:view')
  const location = useLocation()
  const navigate = useNavigate()
  const content = useSelector(composerContentItemSelector)
  const link = useSelector(composerLinkSelector)
  const withCredits = React.useMemo(() => Boolean(content), [content])
  const withLink = React.useMemo(() => content?.type === ARTICLE_TYPE || Boolean(link), [content, link])
  const [activeTab, setActiveTab] = React.useState<SuggestedTextsView>('texts')

  const switchTab = React.useCallback((tab: SuggestedTextsView) => () => {
    setActiveTab(tab)
    if (tab === 'writer') {
      navigate('/composer/ai', { state: { ...location.state, opener: '/composer' } })
    }
  }, [navigate, location.state])

  React.useEffect(() => {
    if (match?.params?.view === 'ai' && activeTab !== 'writer') {
      switchTab('writer').call(null)
    }
  }, [match?.params?.view, switchTab, activeTab])

  React.useEffect(() => {
    setActiveTab(location.pathname === '/composer/ai' ? 'writer' : 'texts')
  }, [location.pathname])

  React.useEffect(() => {
    if (!withCredits && activeTab === 'credits') {
      setActiveTab('texts')
    }

    if (!withLink && activeTab === 'link') {
      setActiveTab('texts')
    }
  }, [withCredits, withLink, activeTab])

  React.useEffect(() => {
    if (link) {
      setActiveTab('link')
    }
  }, [link])

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <Button
          className={`${styles.tab} ${activeTab === 'writer' ? styles.active : ''}`}
          onClick={switchTab('writer')}
          startIcon={<Icon path={mdiAutoFix} size="16px" />}
        >
          <FormattedMessage id="composer.content-actions.ai-writer" />
        </Button>
        <Button
          className={`${styles.tab} ${activeTab === 'texts' ? styles.active : ''}`}
          onClick={switchTab('texts')}
          startIcon={<Icon path={mdiTextBoxCheckOutline} size="16px" />}
        >
          <FormattedMessage id="composer.content-actions.saved-texts" />
        </Button>
        <Button
          variant="text"
          className={`${styles.tab} ${activeTab === 'hashtags' ? styles.active : ''}`}
          startIcon={<Icon path={mdiPound} size="16px" />}
          onClick={switchTab('hashtags')}
        >
          Hashtags
        </Button>
        {onQuotesButtonClick && (
          <Button
            variant="text"
            className={styles.tab}
            startIcon={<Icon path={mdiLightbulbOnOutline} size="16px" />}
            onClick={onQuotesButtonClick}
          >
            <FormattedMessage id="composer.content-actions.texts" />
          </Button>
        )}
        {withLink && (
          <Button
            variant="text"
            className={`${styles.tab} ${activeTab === 'link' ? styles.active : ''}`}
            startIcon={<Icon path={mdiLink} size="16px" />}
            onClick={switchTab('link')}
          >
            <FormattedMessage id="composer.content-actions.article-info" />
          </Button>
        )}
        {withCredits && (
          <Button
            variant="text"
            className={`${styles.tab} ${activeTab === 'credits' ? styles.active : ''}`}
            startIcon={<Icon path={mdiCommentTextOutline} size="16px" />}
            onClick={switchTab('credits')}
          >
            <FormattedMessage id="composer.content-actions.credits" />
          </Button>
        )}
      </div>
      <div className={styles.content}>
        {activeTab === 'texts' && (
          <SavedTexts />
        )}
        {activeTab === 'link' && (
          <ArticleCredits />
        )}
        {activeTab === 'credits' && (
          <Credits />
        )}
        {activeTab === 'hashtags' && (
          <HashtagsGenerator />
        )}
      </div>
    </div>
  )
}
