import * as React from 'react'
import { Facebook } from 'interfaces'

declare const FB: Facebook
const DEFAULT_POST_WIDTH = 500
import styles from './EmbeddedFBPost.pcss'

interface EmbeddedFBPostProps {
  url: string
  width?: number
  withoutText?: boolean
}

export function EmbeddedFBPost(props: EmbeddedFBPostProps) {
  const errorElement = <p>Oops! Something went wrong :( Please refresh the page and try again.</p>
  const container: React.RefObject<HTMLDivElement> = React.createRef()

  React.useEffect(() => {
    if (!container.current || !props.url || typeof FB === 'undefined') {
      return
    }

    FB.XFBML.parse(container.current)
  }, [props.url])

  if (typeof FB === 'undefined') {
    console.error('[EmbeddedFBPost] FB SDK not loaded')
    return errorElement
  }

  if (!props.url) {
    console.error('[EmbeddedFBPost] url is missing')
    return errorElement
  }

  return (
    <div className={styles.container} ref={container} data-testid="embedded-fb-post">
      <div
        className="fb-post"
        data-href={props.url}
        data-width={props.width || DEFAULT_POST_WIDTH}
        data-show-text={Boolean(!props.withoutText)}
      >
      </div>
    </div>
  )
}

export default EmbeddedFBPost
