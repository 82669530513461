import { createContext } from 'react'
import { STOCK_PHOTO_TYPE } from 'interfaces/Content/StockContentTypes'
import { noop } from 'utils/noop'
import { emptyResultsState, SearchState } from './interfaces'
import {
  SEARCH_CATEGORY_SOURCES,
  SEARCH_FILTER_KEY_FACEBOOK,
  SEARCH_FILTER_KEY_LINKEDIN,
  SEARCH_FILTER_KEY_REDDIT,
  SEARCH_FILTER_KEY_RSS,
  SEARCH_FILTER_KEY_STREAMS
} from './types'

export const initialState: SearchState = {
  value: '',
  query: '',
  scope: [],
  page: 0,
  results: emptyResultsState,
  activeCategory: SEARCH_CATEGORY_SOURCES,
  createdKeywordFeeds: [],
  hasRecentSearches: true,
  latestSearchesActive: false,
  latestSearchesLoading: true,
  selectedFeeds: [],
  quotesTags: [],
  postIdeasTags: [],
  selectedFilters: [
    SEARCH_FILTER_KEY_STREAMS,
    SEARCH_FILTER_KEY_FACEBOOK,
    SEARCH_FILTER_KEY_LINKEDIN,
    SEARCH_FILTER_KEY_REDDIT,
    SEARCH_FILTER_KEY_RSS
  ]
}

type SearchContextType = [SearchState, (_a: any) => void]
export const SearchContext = createContext<SearchContextType>([initialState, noop])
