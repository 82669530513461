import { PostingPlan } from 'interfaces'

export const PLAN_STATE_VERSION = 'plan:3'
export const DEFAULT_PLAN_KEY = 'main'

export interface PlanState {
  plans: Readonly<{
    [planId: string]: Readonly<PostingPlan>
  }>
  planToProfilesMap: Record<string, { id: string, name: string, ppids: string[], isPaused: boolean, isDefault: boolean, order: number }>
  lastViewedPlan: string
  defaultPlan?: Readonly<PostingPlan>
  defaultTimezone: Readonly<string>
  planViewExpanded: boolean
  timezones: Readonly<{ [name: string]: string }>
}

export function initialState(): PlanState {
  return Object.freeze({
    plans: Object.freeze({}),
    planToProfilesMap: {},
    defaultPlan: undefined,
    lastViewedPlan: 'main',
    defaultTimezone: '',
    planViewExpanded: false,
    timezones: {}
  })
}

export default PlanState
