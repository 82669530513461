import * as React from 'react'
import { ContentFeedType, CuratedStreamCategory, FEED_TYPE_KEYWORD, Stream } from 'interfaces'
import Paper from '@mui/material/Paper'
import SocialIcon from 'components/SocialIcon'
import { getGradientStyle } from 'utils/colors'
import { getStreamNavUrl } from './util'
import { NavLink } from 'react-router-dom'
import ConfirmDialog from 'components/ConfirmDialog'
import Tooltip from '@mui/material/Tooltip'
import IconDelete from '@mui/icons-material/Close'
import { FormattedMessage } from 'react-intl'
import { debounce } from 'shared/utils'

import styles from './StreamCard.pcss'
import FeedIcon from 'components/FeedIcon'
import { StreamIcon } from 'components/Icons'
const streamIcon = require('static/img/icons/stream-icon-white.svg')
const MOUSE_LEAVE_HADNDLER_DELAY = 350

interface StreamCardProps {
  stream: Stream
  navigation?: string
  urlBrowse?: string
  curatedCategory?: CuratedStreamCategory
  style?: any
  className?: string
  location?: string
  deleteConfirmationMessage?: string
  disableNavigation?: boolean
  persistentFeedsCount?: boolean
  noHoverEffect?: boolean
  onClick?(stream: Stream): void
  onDelete?(stream: Stream): void
}

export function StreamCard(props: StreamCardProps) {
  const { title, titleHTML, imageUrl, featuredImageUrl, imageUrls, feedsCount, color } = props.stream
  const feedsByType = feedsCount || {}
  const titleProps = titleHTML ? { dangerouslySetInnerHTML: { __html: titleHTML } } : undefined
  const secondaryImage1 = (imageUrls || [])[0]
  const secondaryImage2 = (imageUrls || [])[1] || secondaryImage1
  const withSecondaryImage = Boolean(secondaryImage1)
  let colorClass = ''
  if (props.curatedCategory === 'popular') {
    colorClass = styles.yellow
  } else if (props.curatedCategory === 'industries') {
    colorClass = styles.purple
  }
  const colorStyle = colorClass ? undefined : getGradientStyle(color)
  const featuredImage = featuredImageUrl || imageUrl
  const elementRef = React.useRef<HTMLDivElement>(null)

  const onMouseEnter = () => {
    if (elementRef.current) {
      elementRef.current.classList.add(styles.raised)
    }
  }

  const onMouseLeave = debounce(() => {
    if (elementRef.current) {
      elementRef.current.classList.remove(styles.raised)
    }
  }, MOUSE_LEAVE_HADNDLER_DELAY)

  const navigation = {
    pathname: getStreamNavUrl(props.stream, props.navigation, props.urlBrowse, props.curatedCategory),
    state: props.location ? {
      backUrl: props.location
    } : null
  }

  const onNavigate = (e: React.MouseEvent) => {
    if (props.disableNavigation) {
      e.preventDefault()
    }
  }

  const onClick = () => {
    if (props.onClick) {
      props.onClick(props.stream)
    }
  }

  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete(props.stream)
    }
  }

  const featuredImageBoxStyle = featuredImage ? {
    backgroundImage: `url(${featuredImage})`
  } : undefined

  const secondaryImage1Styles = secondaryImage1 ? {
    backgroundImage: `url(${secondaryImage1})`
  } : undefined

  const secondaryImage2Styles = secondaryImage2 ? {
    backgroundImage: `url(${secondaryImage2})`
  } : undefined

  const withFeaturedImage = Boolean(featuredImage)
  const withFeedIcon = !withFeaturedImage && props.stream.feeds[0]?.type === FEED_TYPE_KEYWORD
  const withSecondaryKeywordImage = withFeedIcon
    && props.stream.feeds[1]?.type === FEED_TYPE_KEYWORD
    && props.stream.feeds[2]?.type === FEED_TYPE_KEYWORD
  const feedsCountVisibleClass = !withFeedIcon || props.persistentFeedsCount ? styles.visible : ''

  return (
    <div
      ref={elementRef}
      className={`${styles.container} ${props.noHoverEffect ? styles.nohover : ''} ${props.className || ''}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {
        props.onDelete && (
          <ConfirmDialog message={props.deleteConfirmationMessage || 'Are you sure?'}>
            {(confirm) => (
              <Tooltip title={<FormattedMessage id="actions.remove" />} placement="top">
                <IconDelete
                  fontSize="small"
                  role="button"
                  className={styles['btn-delete']}
                  onClick={confirm(onDelete)}
                />
              </Tooltip>
            )}
          </ConfirmDialog>
        )
      }
      <Paper
        className={`${styles.paper} ${styles.card} ${colorClass}`}
        style={colorStyle}
        onClick={onClick}
      >
        <NavLink to={navigation} onClick={onNavigate}>
          <h5 className={styles.title} title={title}>
            <img src={streamIcon} className={styles.icon} />
            <span className="text-ellipsis" {...titleProps}>{titleHTML ? null : title}</span>
          </h5>
          <div className={styles['stats-box']}>
            {Boolean(feedsCount) && Object.keys(feedsByType).map(network => {
              if (network === 'total') {
                return null
              }
              return (
                <span className={styles['stats-small']} key={network}>
                  <p>{feedsByType[network]}</p>
                  <SocialIcon
                    outline
                    size="12px"
                    icon={network as ContentFeedType}
                    className={`${styles['feed-icon']} ${styles[`feed-icon-${network}`]}`}
                  />
                </span>
              )
            })}
          </div>
          <div className={styles.images}>
            {withSecondaryImage && <div className={styles['img-box']} style={secondaryImage1Styles}></div>}
            {withSecondaryKeywordImage && (
              <div className={styles['img-box']}>
                <div className={`${styles['feed-keyword']} ${styles['feed-keyword-sm']}`}>
                  {props.stream.feeds[1].name}
                </div>
              </div>
            )}
            <div className={`${styles['img-box']} ${styles.featured}`} style={featuredImageBoxStyle}>
              {withFeedIcon && (
                <div className={styles['feed-keyword']}>
                  {props.stream.feeds[0].name}
                </div>
              )}
              {!featuredImage && (
                <StreamIcon size={66} white />
              )}
              {Boolean(feedsCount) && (
                <div className={`${styles.overlay} ${styles['feeds-count']} ${feedsCountVisibleClass}`}>
                  <p className={styles['stats-large']}>{feedsByType.total}</p>
                  <p>feeds</p>
                </div>
              )}
            </div>
            {withSecondaryImage && <div className={styles['img-box']} style={secondaryImage2Styles}></div>}
            {withSecondaryKeywordImage && (
              <div className={styles['img-box']}>
                <div className={`${styles['feed-keyword']} ${styles['feed-keyword-sm']}`}>
                  {props.stream.feeds[2].name}
                </div>
              </div>
            )}
          </div>
        </NavLink>
      </Paper>
    </div>
  )
}

export default StreamCard
