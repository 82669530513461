import * as React from 'react'
import { NativeVideoPlayer } from 'components/VideoPlayer'
import DeleteIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import styles from './UploaderVideoPreview.pcss'
import CircularProgress from '@mui/material/CircularProgress'
import { useDispatch } from 'react-redux'
import { setComposerVideoMetadata } from 'services/compose'
import { StoreThunkDispatch } from 'store/state'

const VIDEO_LOAD_RETRY_COUNT = 10
const VIDEO_LOAD_RETRY_TIMEOUT = 2500

const LOADER_SIZE = 64
export const COMPOSER_VIDEO_ELEMENT_ID = 'composer-video'
interface UploaderVideoPreviewProps {
  videoUrl: string
  thumbnailUrl?: string
  onDelete?: (url: string) => void
  onEdit?: (url: string) => void
  className?: string
}

export function UploaderVideoPreview(props: UploaderVideoPreviewProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    if (props.videoUrl) {
      setLoading(true)
    }
  }, [props.videoUrl])

  const onLoaded = React.useCallback(() => {
    setLoading(false)
    dispatch(setComposerVideoMetadata(props.videoUrl))
  }, [props.videoUrl, dispatch])

  const onError = React.useCallback(() => {
    setLoading(false)
  }, [])

  const onDelete = React.useCallback(() => {
    if (props.onDelete) {
      props.onDelete(props.videoUrl)
    }
  }, [props.videoUrl, props.onDelete])

  const onEdit = React.useCallback(() => {
    if (props.onEdit) {
      props.onEdit(props.videoUrl)
    }
  }, [props.videoUrl, props.onEdit])

  return (
    <div
      className={`${styles.wrapper} ${props.className || ''}`}
      data-testid="uploader-video-preview"
    >
      {loading && <div className={styles['loader-box']}><CircularProgress size={LOADER_SIZE} /></div>}
      <div className={styles.content}>
        <div className={styles.actions}>
          {props.onDelete && (
            <IconButton size="small" className={styles['btn-delete']} onClick={onDelete}>
              <DeleteIcon className={styles.icon} />
            </IconButton>
          )}
          {props.onEdit && (
            <IconButton size="small" className={styles['btn-edit']} onClick={onEdit}>
              <EditIcon className={styles.icon} />
            </IconButton>
          )}
        </div>
        <NativeVideoPlayer
          key={props.videoUrl}
          videoUrl={props.videoUrl}
          thumbnailUrl={props.thumbnailUrl}
          togglePlayOnHover
          muted
          mediaElementId={COMPOSER_VIDEO_ELEMENT_ID}
          className={styles.video}
          playBtnClassName={styles['btn-play']}
          retry={{
            max: VIDEO_LOAD_RETRY_COUNT,
            timeout: VIDEO_LOAD_RETRY_TIMEOUT
          }}
          onError={onError}
          onCanPlay={onLoaded}
        />
      </div>
    </div>
  )
}
