import { createSlice } from '@reduxjs/toolkit'
import { ContentItem } from 'interfaces'

export const CONTENT_POPUP_VERSION = 'contentPopup:1'

export interface ContentPopupState {
  open: boolean
  items: ContentItem[]
}

export const contentPopupSlice = createSlice({
  name: 'contentPopup',
  initialState: {
    open: false,
    items: []
  },
  reducers: {
    openContentPopup: (state, action) => {
      state.open = true
      state.items = action.payload
    },
    closeContentPopup: (state) => {
      state.open = false
      state.items = []
    }
  }
})

export const { openContentPopup, closeContentPopup } = contentPopupSlice.actions
export default contentPopupSlice.reducer

export * from './selectors'
