import * as React from 'react'
import { SortBy } from 'services/content/util'
import { FormattedMessage } from 'react-intl'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import SortIcon from '@mui/icons-material/Sort'

const SORT_BY_OPTIONS: PPSelectOptions = {
  [SortBy.Engagement]: { label: 'Stars' },
  [SortBy.Likes]: { label: 'Likes' },
  [SortBy.Date]: { label: 'Most Recent' }
}

interface SortBySelectorProps {
  value: SortBy
  options?: PPSelectOptions
  withSharesOption?: boolean
  className?: string
  disabled?: boolean
  onChange: (value: SortBy) => void
}

export function SortBySelector(props: SortBySelectorProps) {
  const options = React.useMemo(() => {
    if (props.options) {
      return props.options
    }

    return props.withSharesOption
      ? {
        ...SORT_BY_OPTIONS,
        [SortBy.Shares]: { label: 'Shares' }
      }
      : SORT_BY_OPTIONS
  }, [props.withSharesOption, props.options])

  return (
    <PPSelect
      name={<FormattedMessage id="label.generic.sort-by" />}
      options={options}
      selectedValue={props.value}
      className={props.className || ''}
      disabled={props.disabled}
      withCaret
      icon={<SortIcon />}
      onSelectionChange={props.onChange}
    />
  )
}

export default SortBySelector
