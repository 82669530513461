import { createSelector } from 'reselect'
import StoreState from 'store/state'

const listings = (state: StoreState) => state.listings
export const locationsUrlSelector = createSelector(listings, state => state.locationsUrl)
export const locationsSelector = createSelector(listings, state => state.locations)
export const reviewsUrlSelector = createSelector(listings, state => state.reviewsUrl)
export const reviewCampaignsUrlSelector = createSelector(listings, state => state.reviewCampaignsUrl)
export const locationsLimitSelector = createSelector(listings, state => state.locationsLimit)
export const upgradeLocationDialogOpenSelector = createSelector(listings, state => state.upgradeLocationDialogOpen)
