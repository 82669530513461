import { Component, Children } from 'react'
import { connect } from 'react-redux'
import { PostDestination } from 'interfaces'
import { getDestinations } from 'services/destinations'
import { StoreState, StoreThunkDispatch } from 'store/state'

export interface FetchPostDestinationsProps {
  force?: boolean // eslint-disable-line react/no-unused-prop-types
  children?: React.ReactNode
  onFetched?(): void
  onFailed?(err: any): void
}

interface ConnectedFetchPostDestinationsProps extends FetchPostDestinationsProps {
  getDestinations(): Promise<PostDestination[]>
}

export class FetchPostDestinations extends Component<ConnectedFetchPostDestinationsProps, any> {
  constructor(props: ConnectedFetchPostDestinationsProps) {
    super(props)

    this.onFetched = this.onFetched.bind(this)
    this.onFailed = this.onFailed.bind(this)
  }

  shouldComponentUpdate() {
    return false
  }

  onFetched() {
    if (this.props.onFetched) {
      this.props.onFetched()
    }
  }

  onFailed(err: any) {
    console.log(err)
    if (this.props.onFailed) {
      this.props.onFailed(err)
    }
  }

  fetch() {
    return this.props.getDestinations().then(this.onFetched).catch(this.onFailed)
  }

  componentDidMount() {
    this.fetch()
  }

  render() {
    return this.props.children
      ? Children.only(this.props.children)
      : null
  }
}

function mapStateToProps(_state: StoreState, ownProps: FetchPostDestinationsProps) {
  return ownProps
}

function mapDispatchToProps(dispatch: StoreThunkDispatch, ownProps: FetchPostDestinationsProps) {
  const force = ownProps.force !== undefined ? ownProps.force : true
  return {
    getDestinations: () => dispatch(getDestinations(force))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FetchPostDestinations as any)
