import * as React from 'react'
import { Feed } from 'interfaces'
import CircularProgress from '@mui/material/CircularProgress'

import styles from './FeedsList.pcss'
const LOADER_SIZE = 20

interface FeedsListProps {
  feeds: Feed[]
  loading?: boolean
  className?: string
  emptyResultsMessage?: string
  onFeedClick?: (feed: Feed) => void
}

const FeedListItem = (props: { feed: Feed, onClick?: (feed: Feed) => void }) => {
  const onClick = () => {
    if (props.onClick) {
      props.onClick(props.feed)
    }
  }
  const feed = props.feed
  const handle = `@${feed.handle.split('/').filter(Boolean).pop()}`
  const name = feed.title || feed.name

  return (
    <li key={feed.id} onClick={onClick} className={styles.feed}>
      <img src={feed.image} className={styles.picture} />
      <div className={styles['feed-info']}>
        <p className={`${styles.name} text-ellipsis`} title={name}>{name}</p>
        <p className={`${styles.handle} text-ellipsis`}>{handle}</p>
      </div>
    </li>
  )
}

export function FeedsList(props: FeedsListProps) {
  return (
    <ul className={`${styles.list} ${props.className || ''}`}>
      {
        props.loading && (
          <li className={styles['loader-box']}>
            <CircularProgress className={styles.loader} size={LOADER_SIZE} />
          </li>
        )
      }
      {
        !props.loading && props.feeds.length === 0 && props.emptyResultsMessage && (
          <p className={styles['msg-empty']}>{props.emptyResultsMessage}</p>
        )
      }
      {
        props.feeds.map(feed => <FeedListItem key={feed.id} feed={feed} onClick={props.onFeedClick} />)
      }
    </ul>
  )
}

export default FeedsList
