const SPECIAL = 'àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
const REPLACE = 'aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
const PATTERN = new RegExp(SPECIAL.split('').join('|'), 'g')

export function slugify(text: string) {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(PATTERN, c => REPLACE.charAt(SPECIAL.indexOf(c)))
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export default slugify
