import { Video, VIDEO_TYPE, Paginated, LegacyVideo, FEED_TYPE_KEYWORD } from 'interfaces'
import { TYPE_FEED_TYPE_MAP } from './mappings'
import mapAvatar from './avatar'
import { parseDate } from 'utils/format/date'
import { STOCK_VIDEO_TYPE } from 'interfaces/Content/StockContentTypes'

const BASE_TEN = 10

export function videoAdapter(video: LegacyVideo, stock?: boolean): Video {
  const feed = video.feed || {}
  const feedType = stock ? FEED_TYPE_KEYWORD : TYPE_FEED_TYPE_MAP[feed.type]
  const feedId = feed.id?.toString()

  return {
    feed: {
      id: feedId,
      uniqueSource: feedId,
      image: feed.image || mapAvatar(feedType, feed),
      name: feed.name,
      type: feedType,
      sources: feed.sources,
      order: 0,
      isFeed: true,
      handle: feed.handle,
      networkId: feed.networkId,
      title: feed.title
    },
    isStock: Boolean(stock),
    id: `${feedType}:${video.id}`,
    rawId: video.id + '',
    hash: video.searchHash,
    live: video.live,
    isFavorite: video.isFavorited,
    type: stock ? STOCK_VIDEO_TYPE : VIDEO_TYPE,
    title: video.title || video.status || '',
    description: video.description,
    videoUrl: video.socialLink,
    imageUrl: video.imageUrl,
    socialLink: video.socialLink,
    shortLink: feed.shortLink,
    comments: video.comments,
    createdAt: parseDate(video.createdAt || new Date()),
    likes: video.likes,
    rating: video.rating / BASE_TEN,
    shares: video.shares,
    tweetText: video.tweetTitle,
    favCount: video.favCount || 0,
    provider: video.adapter,
    status: video.status || video.tweetTitle
  }
}

export function videosAdapter(videos: LegacyVideo[], stock?: boolean): Paginated<Video[]> {
  return {
    items: videos.map(v => videoAdapter(v, stock)),
    hasNext: videos.length !== 0
  }
}
