import { StoreThunkAction } from 'store/state'
import { Observable } from 'rxjs/Observable'
import { V1 } from './net'
import { ContentType } from 'interfaces'

export function updatePostRecycle(postId: string, value: boolean | Date | number): StoreThunkAction<Observable<any>> {
  return (dispatch) => dispatch(V1.updateRecycle(postId, value)).map(() => ({ postId, value }))
}

export function recyclePosts(
  postIds: string[],
  ppids: string[],
  postsType: 'queued' | 'pending' | 'scheduled' | 'failed' | 'planned',
  all?: boolean,
  bucketId?: string,
  query?: string,
  types?: ContentType[],
  value?: boolean | Date | number
): StoreThunkAction<Observable<any>> {
  return (dispatch) => {
    return dispatch(V1.recyclePosts(postIds, ppids, postsType, all, bucketId, query, types, value))
      .map(() => {
        return { postIds, all, recycle: value }
      })
  }
}
