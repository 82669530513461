import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { FileFolder, MyFile } from 'interfaces'
import { myFileFoldersArraySelector, myFileFoldersSelector } from './selectors'
import StoreState from 'store/state'
import { myLibraryFoldersAdapter, storageInfoAdapter } from './adapters'
import { api } from './net'
import { Subscriber } from 'rxjs/Subscriber'
import { getFileThumbUrl } from './util'
import { sortAscending } from 'shared/utils'

export const setFileInPreview = createAction<MyFile | undefined>('uploads/setFileInPreview')
export const setUploaderFile = createAction<File | undefined>('uploads/setUploaderFile')
export const setUploadsDialogOpen = createAction<boolean>('uploads/setUploadsDialogOpen')
export const setUploaderFiles = createAction<{ [name: string]: File }>('uploads/setUploaderFiles')
export const setEditFolder = createAction<FileFolder | undefined>('uploads/setEditFolder')
export const setSelectedFile = createAction<MyFile | undefined>('uploads/setSelectedFile')

export const getUploads = createAsyncThunk(
  'uploads/getUploads',
  async (force: boolean = true, { dispatch, getState }) => {
    if (!force) {
      const storedFolders = myFileFoldersSelector(getState() as StoreState)
      if (Object.keys(storedFolders).length !== 0) {
        return { folders: storedFolders }
      }
    }
    const response = await dispatch(api.getUploads()).toPromise()
    return { folders: myLibraryFoldersAdapter(response.usersUploadsData) }
  }
)

export const getStorageInfo = createAsyncThunk(
  'uploads/getStorageInfo',
  async (_, { dispatch }) => {
    const response = await dispatch(api.getStorageInfo()).toPromise()
    return { storage: storageInfoAdapter(response) }
  }
)

export const deleteFile = createAsyncThunk(
  'uploads/deleteFile',
  async (args: { file: MyFile, force?: boolean }, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(api.deleteFile(args.file, args.force)).toPromise()
      return { file: args.file }
    } catch (error) {
      return rejectWithValue({ ...error, file: args.file })
    }
  }
)

export const deleteFolder = createAsyncThunk(
  'uploads/deleteFolder',
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(api.deleteFolder(id)).toPromise()
      return { id }
    } catch (error) {
      return rejectWithValue({ id, ...error })
    }
  }
)

export const reorderFolders = createAsyncThunk(
  'uploads/reorder',
  async (ids: string[], { dispatch }) => {
    await dispatch(api.reorderFolders(ids)).toPromise()
    return { ids }
  }
)

export const updateFolder = createAsyncThunk(
  'uploads/updateFolder',
  async (
    args: { id: string, name: string, color: string, isPrivate: boolean, featuredImage?: string | File, coverImages?: string[] },
    { dispatch }) => {
    const { id, name, color, isPrivate, featuredImage, coverImages } = args
    await dispatch(api.updateFolder(id, name, color, isPrivate, featuredImage, coverImages)).toPromise()
    const data: any = { id, name, color, isPrivate, coverImages }
    if (typeof featuredImage === 'string') {
      data.featuredImage = featuredImage
    }
    return data
  }
)

type UploadFileArgs = {
  file: File,
  folder: Partial<FileFolder>,
  width: number,
  height: number,
  rotateByDegrees?: number,
  progressSubscriber?: Subscriber<any>,
  uploadId?: string,
  compress?: boolean
}

export const uploadFile = createAsyncThunk(
  'uploads/uploadFile',
  async (args: UploadFileArgs, { dispatch, rejectWithValue }) => {
    const { file, folder, width, height, rotateByDegrees, progressSubscriber, uploadId, compress } = args
    try {
      const response = await dispatch(api.uploadFile(
        file, width, height, folder.id, folder.name, folder.color, folder.isPrivate, rotateByDegrees, progressSubscriber, compress)
      ).toPromise()

      const folderId = folder.id || response.folderId
      return {
        folderId,
        folderName: folder.name,
        folderColor: folder.color,
        folderPrivate: folder.isPrivate,
        file: {
          id: response.fileId,
          key: `${response.fileId}-${response.fileType}`,
          folderId,
          size: file.size,
          width,
          height,
          name: response.fileName,
          url: response.fileLink,
          thumbUrl: getFileThumbUrl(response.fileLink, response.fileType),
          type: response.fileType,
          createdAt: new Date().toISOString()
        }
      }
    } catch (error) {
      return rejectWithValue({ fileName: file.name, source: error, uploadId })
    }
  }
)

export const createFolder = createAsyncThunk(
  'uploads/createFolder',
  async (
    args: { name: string, color: string, isPrivate: boolean, featuredImage?: File, coverImages?: File[] },
    { dispatch, getState, rejectWithValue }
  ) => {
    const { name, color, isPrivate, featuredImage, coverImages } = args
    try {
      const response = await dispatch(api.createFolder(name, color, isPrivate, featuredImage, coverImages)).toPromise()
      const id = response.id.toString()
      const folders = myFileFoldersArraySelector(getState() as StoreState)
      const order = folders.sort(sortAscending('order')).map(f => f.id)
      order.push(id)
      dispatch(reorderFolders(order))
      return {
        folder: {
          id,
          name,
          color,
          isPrivate,
          filesCount: 0,
          featuredImage: response.featured,
          files: {},
          filters: []
        }
      }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const copyFileToFolder = createAsyncThunk(
  'uploads/copyFileToFolder',
  async (args: { file: MyFile, folder: { id: string, name: string } }, { dispatch, rejectWithValue }) => {
    const { file, folder } = args
    try {
      const response = await dispatch(api.copyFileToFolder(file, folder)).toPromise()
      return {
        file: {
          ...file,
          id: response.id,
          key: `${response.id}-${file.type}`,
          url: response.link,
          thumbUrl: getFileThumbUrl(response.link, file.type),
          folderId: folder.id
        },
        folder
      }
    } catch (error) {
      return rejectWithValue({ file, folder, ...error })
    }
  }
)
