import * as React from 'react'
import SimpleLoader from 'components/SimpleLoader'
import { PlannedPost } from 'interfaces'
import { FormattedMessage, injectIntl, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Observable } from 'rxjs/Observable'
import { catchError } from 'rxjs/operators/catchError'
import { tap } from 'rxjs/operators/tap'
import { exhaustMap } from 'rxjs/operators/exhaustMap'
import { Subject } from 'rxjs/Subject'
import {
  bulkDeletePosts,
  fetchPlannedPostsForRange,
  fetchPostedPostsForRange,
  reorderPosts,
  updatePostRecycle
} from 'services/post/actions'
import { setCalendarViewMode, setSelectedProfiles } from 'services/post'
import {
  calendarMonthStartSelector,
  calendarPostedVisibleSelector,
  calendarWeekStartSelector,
  emptySlotsVisibleSelector,
  newPostSelector,
  postsUISelector,
  selectedProfilesFetchIdsSelector,
  selectedProfilesPPIdsSelector,
  virtualPostsVisibleSelector
} from 'services/post/selectors'
import { message } from 'services/snackbar'
import { StoreThunkDispatch } from 'store/state'
import styles from './CalendarRoute.pcss'
import { sortAscending } from 'utils/sort/sortByKey'
import { checkFeatureAvailability } from 'services/product'
import { FEATURE_POST_COPY, FEATURE_POST_RECYCLE } from 'shared/constants'
import { PostsAction, postsReducer } from './reducer'
import { setPosts, updatePostLocation, updateRecyclePost } from './actions'
import PostPreviewWithInfo from './components/PostPreviewWithInfo'
import CopyPostsPopup from 'routes/publishing/components/CopyPostsPopup'
import { mergeState, resetComposer } from 'services/compose'
import { getDerivedStateFromPost } from 'services/compose/update/actions'
import MonthPicker from 'components/CalendarView/components/MonthPicker'
import WeekNav from 'components/CalendarView/components/WeekNav'
import { PostsCalendarView, WeekSelection } from './components/PostsCalendarView/PostsCalendarView'
import { DAYS_IN_WEEK, getMonthViewDays, getMonthViewDaysByWeeks, getWeekViewDays, WEEKDAY_LABELS } from 'utils/calendar'
import CalendarViewOptions from './components/CalendarViewOptions'
import { FetchPostDestinations } from 'components/Fetch'
import { FetchBuckets } from 'components/Fetch/FetchBuckets'
import Button from '@mui/material/Button'
import EmptyView from 'components/EmptyView'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { mdiFeather } from '@mdi/js'
import { map } from 'rxjs/operators/map'
import { initPlans } from 'services/plan/actions'
import { planToProfilesSelector } from 'services/plan/selectors'
import { connectedDestinationsSelector } from 'services/destinations'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'

export function CalendarRoute() {
  const intl = useIntl()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const location = useLocation()
  const navigate = useNavigate()
  const profiles = useSelector(connectedDestinationsSelector)
  const selectedProfilesFetchIds = useSelector(selectedProfilesFetchIdsSelector)
  const newPost = useSelector(newPostSelector)
  const virtualPostsVisible = useSelector(virtualPostsVisibleSelector)
  const [posts, postsDispatch] = React.useReducer<React.Reducer<PlannedPost[], PostsAction>>(postsReducer, [])
  const [loadingOverlayActive, setLoadingOverlayActive] = React.useState(false)
  const [refreshKey, setRefreshKey] = React.useState(0)
  const [postInPreview, setPostInPreview] = React.useState<PlannedPost | null>(null)
  const [withPreview, setWithPreview] = React.useState(false)
  const [postsToCopy, setPostsToCopy] = React.useState<PlannedPost[]>([])
  const emptySlotsVisible = useSelector(emptySlotsVisibleSelector)
  const ppids = useSelector(selectedProfilesPPIdsSelector).join(',')
  const mode = useSelector(postsUISelector).calendarMode
  const showPosted = useSelector(calendarPostedVisibleSelector)
  const weekStartDay = useSelector(calendarWeekStartSelector)
  const monthStart = useSelector(calendarMonthStartSelector)
  const plansInfo = useSelector(planToProfilesSelector)
  const now = new Date()
  const startMonthIndex = React.useRef(now.getMonth())
  const startYear = React.useRef(now.getFullYear())
  const [selectedYear, setSelectedYear] = React.useState(now.getFullYear())
  const [selectedMonthIndex, setSelectedMonth] = React.useState(now.getMonth())
  const [weekSelection, setWeekSelection] = React.useState<WeekSelection>({ date: now, offset: 0 })
  const [loading, setLoading] = React.useState(false)
  const [hasPendingPosts, setHasPendingPosts] = React.useState(false)
  const fetch$ = React.useRef<Subject<{
    ppids: string[],
    startDate: string,
    endDate: string,
    withEmptySlots: boolean,
    withVirtualPosts: boolean
  }>>()
  const [totalPostsCount, setTotalPostsCount] = React.useState<number | null>(null)
  const [showEmptyView, setShowEmptyView] = React.useState(true)

  const planId = React.useMemo(() => {
    const ids = ppids.split(',').filter(Boolean)
    if (ids.length === 1) {
      const id = Object.keys(plansInfo).find(k => plansInfo[k].ppids.includes(ids[0]))
      return id
    }
    return null
  }, [ppids, plansInfo])

  const goToPlan = () => {
    navigate(`/plans/${planId}`)
  }

  const visiblePosts = React.useMemo(() => {
    let visible = emptySlotsVisible ? posts : posts.filter(p => !p.isEmpty)
    if (!showPosted) {
      visible = visible.filter(p => !p.isPosted)
    }
    return visible
  }, [posts, emptySlotsVisible, showPosted])

  const visiblePlannedPosts = React.useMemo(() => {
    return visiblePosts.filter(p => !p.isEmpty && !p.isPosted)
  }, [visiblePosts])

  React.useEffect(() => {
    dispatch(initPlans())
  }, [dispatch])

  React.useEffect(() => {
    fetch$.current = new Subject()
    fetch$.current
      .pipe(tap(() => {
        setLoading(true)
      }))
      .switchMap(({ ppids, startDate, endDate, withEmptySlots, withVirtualPosts }) => {
        return dispatch(fetchPlannedPostsForRange(ppids, startDate, endDate, withEmptySlots, withVirtualPosts))
          .zip(dispatch(fetchPostedPostsForRange(ppids, startDate, endDate)))
          .pipe(
            catchError(() => {
              dispatch(message('Error fetching posts! Please refresh.', 'error'))
              return Observable.of({ error: true })
            })
          )
      })
      .subscribe((response: any) => {
        setLoading(false)
        setLoadingOverlayActive(false)
        setShowEmptyView(true)
        if (response.error) {
          return
        }
        const postsArray = [...response[1].posts.map((p: any) => ({ ...p, isPosted: true })), ...response[0].posts]
        setTotalPostsCount(response[0].totalPostsCount)
        setHasPendingPosts(response[0].hasPendingPosts)
        postsDispatch(setPosts(postsArray))
      })

    return () => fetch$.current?.unsubscribe()
  }, [dispatch])

  const weekDays = React.useMemo(() => {
    const startDayIndex = WEEKDAY_LABELS.indexOf(weekStartDay)
    return getWeekViewDays(new Date(), weekSelection.offset, startDayIndex)
  }, [weekSelection.offset, weekStartDay])

  const monthViewStartsFromCurrentWeek = React.useMemo(() => {
    return monthStart === 'this-week' && selectedMonthIndex === startMonthIndex.current && selectedYear === startYear.current
  }, [monthStart, selectedMonthIndex, selectedYear])

  const monthVisibleDays = React.useMemo(() => {
    if (mode !== 'month') {
      return []
    }
    const startDayIndex = WEEKDAY_LABELS.indexOf(weekStartDay)
    if (monthViewStartsFromCurrentWeek) {
      return getMonthViewDaysByWeeks(new Date(), startDayIndex)
    }
    return getMonthViewDays(selectedYear, selectedMonthIndex, WEEKDAY_LABELS.indexOf(weekStartDay))
  }, [monthViewStartsFromCurrentWeek, selectedMonthIndex, selectedYear, weekStartDay, mode])

  const monthPickerRange = React.useMemo(() => {
    if (!monthViewStartsFromCurrentWeek || monthVisibleDays.length === 0 || monthStart === '1st') {
      return undefined
    }
    return [monthVisibleDays[0].date.getMonth(), monthVisibleDays[monthVisibleDays.length - 1].date.getMonth()] as [number, number]
  }, [monthStart, monthVisibleDays, monthViewStartsFromCurrentWeek])

  React.useEffect(() => {
    if (monthVisibleDays.length > 0) {
      const startDate = monthVisibleDays[0].date.toISOString()
      const endDate = monthVisibleDays[monthVisibleDays.length - 1]?.date
      if (endDate) {
        endDate.setHours(23, 59, 59) // eslint-disable-line no-magic-numbers
      }
      const ppidsArray = ppids.split(',').filter(Boolean)
      if (ppidsArray.length > 0) {
        fetch$.current?.next({
          ppids: ppidsArray,
          startDate,
          endDate: endDate?.toISOString() + '',
          withEmptySlots: emptySlotsVisible,
          withVirtualPosts: virtualPostsVisible
        })
      }
    }
  }, [
    selectedYear,
    selectedMonthIndex,
    ppids,
    refreshKey,
    emptySlotsVisible,
    monthVisibleDays,
    virtualPostsVisible
  ])

  React.useEffect(() => {
    if (mode === 'week') {
      const endDate = new Date(weekDays[weekDays.length - 1].date.getTime())
      endDate.setHours(23, 59, 59) // eslint-disable-line no-magic-numbers
      const ppidsArray = ppids.split(',').filter(Boolean)
      if (ppidsArray.length > 0) {
        fetch$.current?.next({
          ppids: ppidsArray,
          startDate: weekDays[0].date.toISOString(),
          endDate: endDate.toISOString(),
          withEmptySlots: emptySlotsVisible,
          withVirtualPosts: virtualPostsVisible
        })
      }
    }
  }, [ppids, refreshKey, mode, weekDays, emptySlotsVisible, virtualPostsVisible])

  const onSelectedMonthChange = (year: number, month: number) => {
    setSelectedYear(year)
    setSelectedMonth(month)
  }

  const selectNextWeek = React.useCallback(() => {
    setWeekSelection(current => {
      const nextDate = new Date(current.date.getTime())
      nextDate.setDate(nextDate.getDate() + DAYS_IN_WEEK)
      return {
        date: nextDate,
        offset: current.offset + 1
      }
    })
  }, [])

  const selectPrevWeek = React.useCallback(() => {
    setWeekSelection(current => {
      const nextDate = new Date(current.date.getTime())
      nextDate.setDate(nextDate.getDate() - DAYS_IN_WEEK)
      return {
        date: nextDate,
        offset: current.offset - 1
      }
    })
  }, [])

  const goToToday = () => {
    setSelectedYear(now.getFullYear())
    setSelectedMonth(now.getMonth())
    setWeekSelection({ date: now, offset: 0 })
  }

  const delete$ = React.useRef<Subject<{ postIds: string[], ppids: string[], all: boolean, scheduled: boolean, bucketId?: string }>>()
  const reorder$ = React.useRef<Subject<string[]>>()
  const recycle$ = React.useRef<Subject<{ postId: string, value: boolean | Date | number, refresh: boolean }>>()

  const onEdit = (post: PlannedPost) => {
    dispatch(resetComposer())
    dispatch(mergeState(getDerivedStateFromPost(post)))
    navigate('/composer', { state: { floating: true, modal: 'compose', opener: location.pathname, background: location } })
  }

  const nonEmptyPosts = React.useMemo(() => {
    return posts.filter(p => !p.isEmpty)
  }, [posts])

  // Check for newly created/updated posts while posts page is active. Reload posts to fetch the new records
  React.useEffect(() => {
    if (newPost || location.state?.refresh) {
      setRefreshKey(current => current + 1)
    }
  }, [newPost, location.state?.refresh])

  // Clear posts and preview when profile selection changes
  React.useEffect(() => {
    postsDispatch(setPosts([]))
    setPostInPreview(null)
  }, [selectedProfilesFetchIds])

  React.useEffect(() => {
    delete$.current = new Subject()
    delete$.current
      .pipe(tap(() => {
        dispatch(message(intl.formatMessage({ id: 'post.notifications.deleting-posts' })))
        setLoadingOverlayActive(true)
      }))
      .flatMap(({ ppids, postIds, all, scheduled, bucketId }) => {
        return dispatch(bulkDeletePosts(postIds, ppids, scheduled ? 'scheduled' : 'queued', all, bucketId))
          .pipe(catchError((error: Error) => {
            dispatch(message(intl.formatMessage({ id: 'post.notifications.delete-posts-error' }, { error: error.message }), 'error'))
            return Observable.of({ error })
          }))
      })
      .subscribe((response: any) => {
        if (!response.error) {
          setRefreshKey(current => current + 1)
          setPostInPreview(null)
        }
        setLoadingOverlayActive(false)
      })

    return () => delete$.current?.unsubscribe()
  }, [dispatch, intl])

  React.useEffect(() => {
    reorder$.current = new Subject()
    reorder$.current.pipe(
      exhaustMap((ids) => dispatch(reorderPosts(ids)).pipe(catchError(() => {
        dispatch(message(intl.formatMessage({ id: 'post.notifications.update-failed' }), 'error'))
        return Observable.of({ error: true })
      })))
    )
      .subscribe(() => {
        setRefreshKey(current => current + 1)
      })

    return () => reorder$.current?.unsubscribe()
  }, [])

  React.useEffect(() => {
    recycle$.current = new Subject()
    recycle$.current
      .flatMap(({ postId, value, refresh }) => dispatch(updatePostRecycle(postId, value))
        .pipe(
          catchError((e) => {
            const msg = e.response?.error.message || intl.formatMessage({ id: 'errors.generic' })
            dispatch(message(msg, 'error'))
            return Observable.of({ error: true })
          }),
          map((response: any) => ({ ...response, refresh }))
        )
      )
      .subscribe(response => {
        if (!response.error) {
          postsDispatch(updateRecyclePost(response.postId, response.value))
        }
        if (response.refresh) {
          setRefreshKey(current => current + 1)
        }
      })

    return () => recycle$.current?.unsubscribe()
  }, [dispatch, intl])

  const previewSelection = React.useMemo(() => {
    const selectedPostIndex = postInPreview
      ? nonEmptyPosts.findIndex((p: any) => p.id === postInPreview.id)
      : -1
    let hasNext = false
    let hasPrev = false

    if (selectedPostIndex !== -1) {
      hasNext = selectedPostIndex < nonEmptyPosts.length - 1
      hasPrev = selectedPostIndex >= 1
    }
    return {
      selectedPostIndex,
      hasNext,
      hasPrev
    }
  }, [postInPreview, nonEmptyPosts])

  React.useEffect(() => {
    if (withPreview) {
      const firstNonEmptyPost = posts.find(p => !p.isEmpty)
      setPostInPreview(current => {
        if (!current && firstNonEmptyPost) {
          return firstNonEmptyPost
        }
        if (!firstNonEmptyPost) {
          return null
        }
        return posts.find(p => p.id === current?.id) || null
      })
    } else if (!withPreview) {
      setPostInPreview(null)
    }
  }, [withPreview, posts])

  const onDeletePost = React.useCallback((id: string, ppid: string, scheduled: boolean, bucketId?: string) => {
    delete$.current?.next({
      postIds: [id],
      ppids: [ppid],
      all: false,
      scheduled,
      bucketId
    })
  }, [])

  const movePost = React.useCallback((id: string, position: 'top' | 'bottom') => {
    const autoPosts = posts.reduce((autoPosts: any[], p: any) => {
      if (p.autoPost && !p.isEmpty && p.id !== id) {
        autoPosts.push(p)
      }
      return autoPosts
    }, [])
    const ids: string[] = autoPosts.sort(sortAscending('autoPostOrder')).map(p => p.id)
    if (position === 'top') {
      ids.unshift(id)
    } else {
      ids.push(id)
    }
    reorder$.current?.next(ids)
  }, [posts])

  const onRecycleChange = React.useCallback((id: string, value: number | boolean | Date, forceRefresh?: boolean) => {
    const recycleAvailable = dispatch(checkFeatureAvailability(FEATURE_POST_RECYCLE))
    if (!recycleAvailable) {
      return
    }
    const shouldRefresh = forceRefresh || virtualPostsVisible
    recycle$.current?.next({ postId: id, value, refresh: shouldRefresh })
  }, [dispatch, virtualPostsVisible])

  const onCopyPost = React.useCallback((post: PlannedPost) => {
    const copyAllowed = dispatch(checkFeatureAvailability(FEATURE_POST_COPY))
    if (copyAllowed) {
      setPostsToCopy([post])
    }
  }, [])

  const onCopyFinished = React.useCallback(() => {
    setRefreshKey(current => current + 1)
  }, [])

  const closeCopyPostsPopup = React.useCallback(() => {
    setPostsToCopy([])
  }, [])

  const onPostClick = React.useCallback((post: PlannedPost) => {
    if (post.isPosted) {
      dispatch(setSelectedProfiles({ profiles: [profiles[post.profileId]], activeView: 'history' }))
      navigate('/history')
      return
    }
    setWithPreview(true)
    setPostInPreview(post)
  }, [dispatch, navigate, profiles])

  const onTogglePreview = (value: 'on' | 'off') => {
    setWithPreview(value === 'on')
  }

  const selectNextPostForPreview = React.useCallback(() => {
    if (previewSelection.hasNext) {
      const next = nonEmptyPosts[previewSelection.selectedPostIndex + 1]
      setPostInPreview(next)
    }
  }, [previewSelection, nonEmptyPosts])

  const selectPrevPostForPreview = React.useCallback(() => {
    if (previewSelection.hasPrev) {
      const prev = nonEmptyPosts[previewSelection.selectedPostIndex - 1]
      setPostInPreview(prev)
    }
  }, [previewSelection, nonEmptyPosts])

  const deletePost = React.useCallback((post: PlannedPost) => {
    onDeletePost(post.id, post.ppPageId, !post.autoPost, post.bucketId)
  }, [onDeletePost])

  const closePreview = React.useCallback(() => {
    setWithPreview(false)
  }, [])

  const onPostUpdated = (post: PlannedPost) => {
    postsDispatch(updatePostLocation(post.id, post.details?.location))
  }

  const hideEmptyView = () => {
    setShowEmptyView(false)
  }

  const onReorderPosts = React.useCallback((movedPost: PlannedPost, targetPost: PlannedPost) => {
    const profileId = movedPost.profileId
    const bucketId = movedPost.bucketId
    const autoPostsByProfile = posts.filter(p => p.autoPost && p.profileId === profileId && !p.isVirtual)
      .sort(sortAscending('autoPostOrder')) as PlannedPost[]
    const currentIndex = autoPostsByProfile.findIndex(p => p.id === movedPost.id)
    const nextIndex = autoPostsByProfile.findIndex(p => p.id === targetPost.id)

    const ids: string[] = []
    const orderedPosts: PlannedPost[] = []
    autoPostsByProfile.splice(currentIndex, 1)
    autoPostsByProfile.splice(nextIndex, 0, movedPost)
    autoPostsByProfile.forEach((p, i) => {
      p.autoPostOrder = i
      orderedPosts.push(p)
      // Send only post ids that belong to the same bucket/queue
      if (!p.isEmpty && p.bucketId === bucketId) {
        ids.push(p.id)
      }
    })
    setLoadingOverlayActive(true)
    reorder$.current?.next(ids)
  }, [posts])

  const viewModeOptions: PPSelectOptions = {
    month: { label: <FormattedMessage id="post.calendar.labels.month" /> },
    week: { label: <FormattedMessage id="post.calendar.labels.week" /> }
  }

  const switchMode = (mode: 'month' | 'week') => {
    dispatch(setCalendarViewMode(mode))
  }

  const showPendingLink = !loading && hasPendingPosts

  return (
    <div className={styles.page}>
      <FetchPostDestinations />
      <FetchBuckets />
      <div className={styles['header-calendar']}>
        <CalendarViewOptions
          postsCount={totalPostsCount}
          withPreview={withPreview}
          showPendingLink={showPendingLink}
          onTogglePreview={onTogglePreview}
        />
        <div className={`${styles['header-bottom']} ${postInPreview ? styles['w-preview'] : ''}`}>
          <Button onClick={goToToday}>
            <FormattedMessage id="post.calendar.labels.today" />
          </Button>
          <div className={styles.middle}>
            {mode === 'month' ? (
              <MonthPicker
                startMonthIndex={startMonthIndex.current}
                selectedMonthIndex={selectedMonthIndex}
                selectedYear={selectedYear}
                labelRange={monthPickerRange}
                onSelectionChange={onSelectedMonthChange}
              />
            ) : (
              <WeekNav
                days={weekDays}
                withBackNav={weekSelection.offset > 0}
                onNext={selectNextWeek}
                onPrev={selectPrevWeek}
              />
            )}
          </div>
          <div className={styles['options-r']}>
            <PPSelect
              name="View"
              options={viewModeOptions}
              selectedValue={mode}
              className={styles.select}
              id="calendar-view-select"
              onSelectionChange={switchMode}
            />
            {planId && (
              <Button onClick={goToPlan}>
                <FormattedMessage id="post.calendar.labels.edit-plan" />
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {loadingOverlayActive && (
            <div className={styles['loading-overlay']}>
              <SimpleLoader small />
            </div>
          )}
          <div>
            <PostsCalendarView
              year={selectedYear}
              mode={mode}
              monthIndex={selectedMonthIndex}
              posts={visiblePosts}
              loading={loading}
              activePostId={postInPreview?.id}
              selection={weekSelection}
              className={styles['calendar-box']}
              onPostClick={onPostClick}
              onPostOrderChanged={onReorderPosts}
            />
          </div>
        </div>
        <div className={`${styles['preview-box']} ${styles.calendar} ${withPreview ? styles.visible : ''}`}>
          {postInPreview && (
            <PostPreviewWithInfo
              post={postInPreview}
              hasNextPost={previewSelection.hasNext}
              hasPreviousPost={previewSelection.hasPrev}
              className={styles.preview}
              onClose={closePreview}
              onSelectNext={selectNextPostForPreview}
              onSelectPrevious={selectPrevPostForPreview}
              onDelete={deletePost}
              onMove={movePost}
              onEdit={onEdit}
              onCopy={onCopyPost}
              onRecycleChange={onRecycleChange}
              onPostUpdated={onPostUpdated}
            />
          )}
        </div>
      </div>
      <CopyPostsPopup
        open={postsToCopy.length > 0}
        posts={postsToCopy}
        allSelected={false}
        postType="planned"
        onClose={closeCopyPostsPopup}
        onCopyStarted={closeCopyPostsPopup}
        onCopyFinished={onCopyFinished}
      />
      {!loading && !loadingOverlayActive && visiblePlannedPosts.length === 0 && showEmptyView && (
        <EmptyView
          icon={mdiFeather}
          title="No planned posts for this period"
          subtitle={(
            <div>
              <NavLink
                to={{ pathname: '/composer' }}
                state={{ floating: true, modal: 'compose', opener: location.pathname, background: location }}
              >
                Create a new post
              </NavLink>
              &nbsp;or <NavLink to="/content/search/content">search for content</NavLink>
            </div>
          )}
          top="400px"
          backdrop
          onClose={hideEmptyView}
        />
      )}
    </div>
  )
}

export default injectIntl(CalendarRoute)
