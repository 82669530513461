import { createElement, Component } from 'react'
import FeedView, { BricksSize } from 'components/FeedView'
import { Feed, Stream, AnyContent, RangeFilter, ContentItem, WithIntl, FilterType } from 'interfaces'
import { injectIntl } from 'react-intl'

export interface StreamFeedViewProps extends WithIntl {
  feed: Feed
  streamId: string
  stream?: Stream
  filter: FilterType
  range: RangeFilter
  basePath: string
  linkToUserFeedId?: boolean

  sizes?: BricksSize[]
  align?: 'left' | 'center'
  itemWidth?: number

  onItemPinned?(stream: Stream, feed: Feed, content: AnyContent): void
  onItemShared?(stream: Stream, feed: Feed, content: AnyContent): void | boolean
  onGotoClicked?(stream: Stream, feed: Feed, content: AnyContent, by: string): void
  onFilterChanged?(stream: Stream, feed: Feed, filter: FilterType): void
  onRangeChanged?(stream: Stream, feed: Feed, range: RangeFilter): boolean

  onDistinctFeed?(stream: Stream, feed: Feed): void
  onContentItemClick?(item: ContentItem): void
}

export class StreamFeedView extends Component<StreamFeedViewProps, any> {

  constructor(props: StreamFeedViewProps) {
    super(props)

    this.onItemPinned = this.onItemPinned.bind(this)
    this.onItemShared = this.onItemShared.bind(this)
    this.onGotoClicked = this.onGotoClicked.bind(this)
    this.onFilterChanged = this.onFilterChanged.bind(this)
    this.onRangeChanged = this.onRangeChanged.bind(this)
  }

  onItemPinned(feed: Feed, content: AnyContent) {
    if (this.props.onItemPinned) {
      this.props.onItemPinned(this.props.stream || { id: this.props.streamId } as Stream, feed, content)
    }
  }

  onItemShared(feed: Feed, content: AnyContent) {
    if (this.props.onItemShared) {
      return this.props.onItemShared(this.props.stream || { id: this.props.streamId } as Stream, feed, content)
    }
  }

  onFilterChanged(feed: Feed, filter: FilterType) {
    if (this.props.onFilterChanged) {
      this.props.onFilterChanged(this.props.stream || { id: this.props.streamId } as Stream, feed, filter)
    }
  }

  onRangeChanged(feed: Feed, range: RangeFilter) {
    if (this.props.onRangeChanged) {
      return this.props.onRangeChanged(this.props.stream || { id: this.props.streamId } as Stream, feed, range)
    }

    return false
  }

  onGotoClicked(feed: Feed, content: AnyContent, by: string) {
    if (this.props.onGotoClicked) {
      this.props.onGotoClicked(this.props.stream || { id: this.props.streamId } as Stream, feed, content, by)
    }
  }

  render() {
    const { feed, filter, sizes, align, itemWidth, range, linkToUserFeedId, basePath } = this.props
    return createElement(FeedView, {
      feed,
      sizes,
      align,
      itemWidth,
      defaultFilter: filter,
      range,
      linkToUserFeedId,
      basePath,
      onItemShared: this.onItemShared,
      onItemPinned: this.onItemPinned,
      onGotoClicked: this.onGotoClicked,
      onFilterChanged: this.onFilterChanged,
      onRangeChanged: this.onRangeChanged,
      onContentItemClick: this.props.onContentItemClick
    })
  }

  componentDidMount() {
    if (this.props.feed && this.props.onDistinctFeed) {
      this.props.onDistinctFeed(this.props.stream || { id: this.props.streamId } as Stream, this.props.feed)
    }
  }

  componentDidUpdate(previousProps: StreamFeedViewProps) {
    if (this.props.feed && this.props.onDistinctFeed && previousProps.feed !== this.props.feed) {
      this.props.onDistinctFeed(this.props.stream || { id: this.props.streamId } as Stream, this.props.feed)
    }
  }
}

export default injectIntl(StreamFeedView)
