import { StoreThunkAction } from 'store/state'
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/of'
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/filter'
import { TIMERANGE_FILTER_DEFAULT } from 'config'
import { Gif, Paginated, RangeFilter, GIF_TYPE, ContentType } from 'interfaces'
import { V1 } from './net'
import { getCacheKey, PAGE_SIZE_DEFAULT, SortBy } from '../util'
import { cacheContentTemp, getCachedContent } from '../cache'
import { gifsAdapter } from '../adapters/gifs'
import { StockContentProvider } from 'interfaces/Content/StockContentProvider'
import { STOCK_GIF_TYPE } from 'interfaces/Content/StockContentTypes'

/**
 * Fetches gifs from single feed or stream
 *
 * @export
 * @param {string} sourceId feed/stream id
 * @param {boolean} [isStream]
 * @param {number} [page=0] page number (starts at 0)
 * @param {RangeFilter} [rangeFilter=TIMERANGE_FILTER_DEFAULT] time filter
 * @returns {StoreThunkAction<Observable<Paginated<Gif[]>>>}
 */
export function getGifs(
  sourceId: string,
  isStream?: boolean,
  page: number = 0,
  rangeFilter: RangeFilter = TIMERANGE_FILTER_DEFAULT,
  sortBy: SortBy = SortBy.Engagement,
  stock?: boolean,
  stockSource?: StockContentProvider
): StoreThunkAction<Observable<Paginated<Gif[]>>> {

  return (dispatch) => {
    let fetchPage = page
    let cacheKeyType: ContentType = GIF_TYPE
    if (stock) {
      // NOTE: always fetch page 0 for stock content
      fetchPage = 0
      cacheKeyType = STOCK_GIF_TYPE
    }
    const cacheKey = getCacheKey(Boolean(isStream), sourceId, cacheKeyType, rangeFilter, fetchPage, sortBy, stockSource)
    const cachedGifs = dispatch(getCachedContent(cacheKey))

    if (cachedGifs) {
      if (stock) {
        // EXPL: Stock content is cached without paging (all on page 0). It is implemented here:
        const startIndex = page * PAGE_SIZE_DEFAULT
        const endIndex = startIndex + PAGE_SIZE_DEFAULT
        const contentForPage: Paginated<Gif[]> = {
          items: cachedGifs.items.slice(startIndex, endIndex) as Gif[],
          hasNext: cachedGifs.items.length > endIndex - 1
        }

        return Observable.of(contentForPage)
      }
      return Observable.of(cachedGifs as Paginated<Gif[]>)
    }

    const getGifs = isStream
      ? dispatch(V1.getGifsFromFolder(sourceId, page, rangeFilter, sortBy))
      : dispatch(V1.getGifs(sourceId, fetchPage, rangeFilter, sortBy, stockSource))

    return getGifs
      .map(response => response.content)
      .map(gifs => {
        const contentFormatted = gifsAdapter(gifs, stock)
        if (stock) {
          if (gifs.length > 0) {
            dispatch(cacheContentTemp(cacheKey, contentFormatted))
          }

          return {
            ...contentFormatted,
            items: contentFormatted.items.slice(0, PAGE_SIZE_DEFAULT) // paging for stock gifs
          }
        }
        return contentFormatted
      })
      .do({
        next: (response) => {
          // Do not cache empty response
          if (!stock && response.items.length > 0) {
            dispatch(cacheContentTemp(cacheKey, response))
          }
        }
      })
  }
}
