import * as React from 'react'
import styles from './ContentStudioGridWithFilters.pcss'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ALL_FILTER, ALL_RANGE_FILTER, ContentItem, PHOTO_TYPE, StatusIdea, Video, WithIntl } from 'interfaces'
import StockContentSourceFilter from 'components/StockContentSourceFilter'
import { StockContentProvider } from 'interfaces/Content/StockContentProvider'
import { Subject } from 'rxjs/Subject'
import { StoreThunkDispatch } from 'store/state'
import { useDispatch, useSelector } from 'react-redux'
import { tap } from 'rxjs/operators/tap'
import { catchError } from 'rxjs/operators/catchError'
import { STOCK_PHOTO_TYPE } from 'interfaces/Content/StockContentTypes'
import { message } from 'services/snackbar'
import { Observable } from 'rxjs/Observable'
import { searchStockContent } from 'services/search/actions'
import { getQuotes } from 'admin/services/quotes/actions'
import { getFavorites } from 'services/content/favorites'
import ScrollListener from 'components/ScrollListener'
import CardBricks from 'components/CardBricks'
import AnyCard from 'components/Card'
import { renderLoadingCards } from 'routes/find/routes/home/components/utils'
import { getUploads } from 'services/uploads/actions'
import { allFilesFolderSelector, myFileFoldersSelector } from 'services/uploads/selectors'
import LibraryFilesGrid from './LibraryPhotosGrid'
import { FavoriteStatusesView } from 'components/FavoritesView/FavoriteStatusesView'
import { getRandomQuotes } from 'services/content/quotes/actions'
import STATUSES_CATEGORIES from 'routes/find/routes/statuses/statuses.config'
import EmptyContentView, { ContentGridView } from './EmptyContentView/EmptyContentView'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Paper from '@mui/material/Paper'
import CollapsibleTextInput from 'components/CollapsibleTextInput'
import FilterDropdown from './FilterDropdown'
import { mdiImagePlusOutline, mdiClose } from '@mdi/js'
import Icon from '@mdi/react'
import { getStatusIdeas, searchStatusIdeas } from 'services/content/statuses/actions'
import { STOCK_VIDEO_TYPE, VIDEO_TYPE } from 'shared'

const SCROLL_EMIT_TRESHOLD = 240
const PAGE_SIZE_DEFAULT = 30
const LOADING_CARDS_COUNT = 10

const DEFAULT_SEARCH_QUERY = 'scenery'
const STATUS_CATEGORY_QUESTIONS_STREAM_ID = STATUSES_CATEGORIES[0].streams[0].id.toString()

interface ContentStudioGridWithFiltersProps {
  mode: 'image' | 'video'
  onImageSelected: (imageUrl: string) => void
  onVideoSelected: (videoUrl: string) => void
}

type FavoritesState = {
  photos: ContentItem[],
  texts: StatusIdea[],
  loading: boolean,
  error: boolean,
  hasNextPage: boolean
}

export function ContentStudioGridWithFilters(props: ContentStudioGridWithFiltersProps & WithIntl) {
  const { mode, onVideoSelected } = props
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [filter, setFilter] = React.useState<ContentGridView>('stock-image')
  const [query, setQuery] = React.useState('')
  const [stockFilter, setStockFilter] = React.useState<StockContentProvider>(ALL_FILTER)
  const [quotes, setQuotes] = React.useState<StatusIdea[]>([])
  const [statuses, setStatuses] = React.useState<StatusIdea[]>([])
  const [photos, setPhotos] = React.useState<{ items: ContentItem[], loading: boolean, error: boolean }>(
    { items: [], loading: false, error: false }
  )
  const [stockVideos, setStockVideos] = React.useState<{ items: ContentItem[], loading: boolean, error: boolean }>(
    { items: [], loading: false, error: false }
  )
  const [favorites, setFavorites] = React.useState<FavoritesState>({
    photos: [],
    texts: [],
    loading: false,
    error: false,
    hasNextPage: true
  })
  const [textsLoading, setTextsLoading] = React.useState(true)
  const [textsError, setTextsError] = React.useState(false)
  const [favTextSearchQuery, setFavTextSearchQuery] = React.useState('')
  const [photosPage, setPhotosPage] = React.useState(0)
  const [vidsPage, setVidsPage] = React.useState(0)
  const [hasNextPageTexts, setHasNextPageTexts] = React.useState(true)
  const [libraryState, setLibraryState] = React.useState({ loading: true, error: false, page: 0 })
  const [foldersDropdownAnchor, setFoldersDropdownAnchor] = React.useState<HTMLElement | null>(null)
  const [selectedFileFolder, setSelectedFileFolder] = React.useState<string | null>(null)
  const fileFolders = useSelector(myFileFoldersSelector)
  const searchPhotos$ = React.useRef<Subject<string>>()
  const searchVideos$ = React.useRef<Subject<string>>()
  const searchTexts$ = React.useRef<Subject<string>>()
  const lastSearch = React.useRef<string>('')
  const textsPageRef = React.useRef(0)
  const favsPageRef = React.useRef(0)
  const scrollElementRef = React.useRef<HTMLElement | undefined>(undefined)
  const gridRef = React.useRef<any>(null)
  const filesFolder = useSelector(allFilesFolderSelector)
  const seedRef = React.useRef(Date.now().toString())
  const inputRef = React.useRef<HTMLInputElement>(null)

  React.useLayoutEffect(() => {
    scrollElementRef.current = document.querySelector('[data-test="main"]') as HTMLElement
  }, [])

  React.useEffect(() => {
    if (mode === 'image' && filter.indexOf('video') !== -1) {
      switchToView('stock-image')
    } else if (mode === 'video' && ['library-image', 'stock-image', 'photo-favorites'].includes(filter)) {
      switchToView('stock-video')
    }
  }, [mode, filter])

  React.useEffect(() => {
    searchPhotos$.current = new Subject()
    searchPhotos$.current
      .filter(q => Boolean(q))
      .pipe(tap((q: string) => {
        setPhotos(current => ({ ...current, loading: true }))
        lastSearch.current = q
      }))
      .flatMap(query => dispatch(searchStockContent(query, STOCK_PHOTO_TYPE))
        .pipe(
          catchError(() => {
            dispatch(message(props.intl.formatMessage({ id: 'errors.generic' }), 'error'))
            return Observable.of({ error: true })
          })
        ))
      .subscribe((response: any) => {
        if (response.error) {
          setPhotos(current => ({ ...current, loading: false, error: true }))
          return
        }
        setPhotos({ error: false, loading: false, items: response })
      })

    searchPhotos$.current.next(DEFAULT_SEARCH_QUERY)

    return () => {
      searchPhotos$.current?.unsubscribe()
    }
  }, [dispatch, props.intl])

  React.useEffect(() => {
    searchVideos$.current = new Subject()
    searchVideos$.current
      .filter(q => Boolean(q))
      .pipe(tap((q: string) => {
        setStockVideos(current => ({ ...current, loading: true }))
        lastSearch.current = q
      }))
      .flatMap(query => dispatch(searchStockContent(query, STOCK_VIDEO_TYPE))
        .pipe(
          catchError(() => {
            dispatch(message(props.intl.formatMessage({ id: 'errors.generic' }), 'error'))
            return Observable.of({ error: true })
          })
        ))
      .subscribe((response: any) => {
        if (response.error) {
          setStockVideos(current => ({ ...current, loading: false, error: true }))
          return
        }
        setStockVideos({ error: false, loading: false, items: response })
      })

    searchVideos$.current.next(DEFAULT_SEARCH_QUERY)

    return () => {
      searchVideos$.current?.unsubscribe()
    }
  }, [dispatch, props.intl])

  React.useEffect(() => {
    searchTexts$.current = new Subject()
    searchTexts$.current
      .pipe(tap((q: string) => {
        setTextsLoading(true)
        setFavTextSearchQuery(q)
        lastSearch.current = q
      }))
      .flatMap(q => dispatch(q ? getQuotes(textsPageRef.current, q) : getRandomQuotes())
        .zip(
          q ? dispatch(searchStatusIdeas(q, textsPageRef.current))
            : dispatch(getStatusIdeas([STATUS_CATEGORY_QUESTIONS_STREAM_ID], textsPageRef.current, seedRef.current))
              .map(response => response.items)
        )
      )
      .pipe(catchError(() => {
        dispatch(message(props.intl.formatMessage({ id: 'errors.generic' }), 'error'))
        return Observable.of({ error: true })
      }))
      .subscribe((response: any) => {
        setTextsLoading(false)
        if (response.error) {
          setTextsError(true)
          return
        }
        const [quotesResults, statusesResults] = response
        setTextsError(false)
        setHasNextPageTexts(quotesResults.length + statusesResults.length > 0)
        setQuotes(current => textsPageRef.current === 0 ? quotesResults : current.concat(quotesResults))
        setStatuses(current => textsPageRef.current === 0 ? statusesResults : current.concat(statusesResults))
      })

    searchTexts$.current.next('')

    return () => {
      searchTexts$.current?.unsubscribe()
    }
  }, [])

  React.useEffect(() => {
    const sub = new Subject()
    dispatch(getUploads())
      .unwrap()
      .then(() => {
        setLibraryState({ loading: false, page: 0, error: false })
      })
      .catch(() => {
        setLibraryState({ loading: false, error: true, page: 0 })
      })

    sub.next()
    return () => sub.unsubscribe()
  }, [dispatch])

  const fetchFavorites = React.useCallback(async (page: number) => {
    setFavorites(current => ({ ...current, loading: true }))
    try {
      const response: any = await dispatch(getFavorites({ page, type: PHOTO_TYPE, range: ALL_RANGE_FILTER })).unwrap()
      setFavorites(current => ({
        ...current,
        photos: favsPageRef.current === 0 ? response.items : current.photos.concat(response.items),
        loading: false,
        error: false,
        hasNextPage: response.items.length > 0
      }))
    } catch (e) {
      setFavorites(current => ({ ...current, loading: false, error: true }))
    }
  }, [dispatch])

  React.useEffect(() => {
    fetchFavorites(0)
  }, [])

  React.useEffect(() => {
    // recalculate grid items positioning on change
    gridRef.current?.onItemChanged()
  }, [textsLoading, photos.loading, stockFilter])

  const switchToView = (view: ContentGridView) => {
    if (view === 'quotes' || view === 'statuses') {
      switchToTextView(view)
      return
    }
    if (['library-image', 'photo-favorites', 'library-video'].includes(filter)) {
      setQuery('')
    }
    setFilter(view)
    setSelectedFileFolder(null)
  }

  const switchToTextView = (view: ContentGridView) => {
    textsPageRef.current = 0
    seedRef.current = Date.now().toString()
    setQuotes([])
    setStatuses([])
    setHasNextPageTexts(true)
    setTextsError(false)
    searchTexts$.current?.next(query || '')
    setFilter(view)
    setSelectedFileFolder(null)
  }

  const onQueryChange = React.useCallback((value: string) => {
    textsPageRef.current = 0
    setHasNextPageTexts(true)
    setTextsError(false)
    setPhotos({ items: [], loading: false, error: false })
    setStockVideos({ items: [], loading: false, error: false })
    setQuotes([])
    setStatuses([])

    searchPhotos$.current?.next(value)
    searchTexts$.current?.next(value)
    searchVideos$.current?.next(value)
    setQuery(value)
  }, [])

  const loadNextPage = React.useCallback(() => {
    if (filter === 'text-favorites') {
      return
    }

    if (filter === 'stock-image' && photosPage * PAGE_SIZE_DEFAULT < photos.items.length) {
      setPhotosPage(current => current + 1)
    } else if (filter === 'stock-video' && vidsPage * PAGE_SIZE_DEFAULT < stockVideos.items.length) {
      setVidsPage(current => current + 1)
    } else if (filter === 'photo-favorites' && !favorites.loading && favorites.hasNextPage) {
      favsPageRef.current += 1
      fetchFavorites(favsPageRef.current)
    } else if (filter === 'library-image') {
      setLibraryState(current => ({ ...current, page: current.page + 1 }))
    } else if (hasNextPageTexts && !textsLoading) {
      textsPageRef.current += 1
      searchTexts$.current?.next(query)
    }
  }, [
    query,
    photosPage,
    hasNextPageTexts,
    photos.items.length,
    favorites.hasNextPage,
    favorites.loading,
    filter,
    textsLoading,
    stockVideos.items.length,
    vidsPage,
    fetchFavorites
  ])

  const onContentItemSelected = React.useCallback((item: ContentItem) => {
    if (item.type === STOCK_VIDEO_TYPE) {
      props.onVideoSelected((item as Video).videoUrl)
    } else {
      props.onImageSelected(item.imageUrl)
    }
    return true
  }, [props])

  const onTextSelected = React.useCallback(() => {
    dispatch(message(props.intl.formatMessage({ id: 'content-studio.notifications.text-copied' })))
    return true
  }, [dispatch, props.intl])

  const loadingCards = React.useMemo(() => {
    if (filter === 'photo-favorites') {
      return favorites.loading ? renderLoadingCards(LOADING_CARDS_COUNT, false, true, favorites.loading) : []
    }
    if (['stock-image', 'library-image', 'photo-favorites'].includes(filter) && (photos.loading || photos.error)) {
      return renderLoadingCards(LOADING_CARDS_COUNT, false, true, photos.loading)
    }
    if (filter === 'stock-video' && (stockVideos.loading || stockVideos.error)) {
      return renderLoadingCards(LOADING_CARDS_COUNT, false, true, stockVideos.loading)
    }
    if (textsLoading || textsError) {
      return renderLoadingCards(LOADING_CARDS_COUNT, false, true, textsLoading)
    }
    return []
  }, [
    filter,
    photos.loading,
    photos.error,
    stockVideos.loading,
    stockVideos.error,
    textsLoading,
    textsError,
    favorites.loading
  ])

  const cards = React.useMemo(() => {
    if (['stock-image', 'photo-favorites'].includes(filter)) {
      const photosSet = filter === 'stock-image' ? photos.items : favorites.photos
      const photosPageStartIndex = photosPage * PAGE_SIZE_DEFAULT
      const filteredItems = filter === 'photo-favorites' || stockFilter === 'all'
        ? photosSet
        : photosSet.filter(p => p.provider === stockFilter)

      return filteredItems.slice(0, photosPageStartIndex + PAGE_SIZE_DEFAULT)
        .map((item: ContentItem) => {
          const photo = {
            ...item,
            feed: { ...item.feed, name: query }
          }
          return (
            <AnyCard
              key={item.id}
              content={photo as ContentItem}
              children={photo as ContentItem}
              square
              small
              actions={['create-post', 'favorite']}
              studioButtonIcon={<Icon path={mdiImagePlusOutline} size="20px" />}
              actionLabel={<FormattedMessage id={`actions.add-${mode}`} />}
              className={styles['content-card']}
              onCompose={onContentItemSelected}
            />
          )
        }).concat(loadingCards)
    }

    if (filter === 'stock-video') {
      const pageStartIndex = vidsPage * PAGE_SIZE_DEFAULT
      return stockVideos.items.slice(0, pageStartIndex + PAGE_SIZE_DEFAULT)
        .map((item: ContentItem) => {
          const video = {
            ...item,
            feed: { ...item.feed, name: query }
          }
          return (
            <AnyCard
              key={item.id}
              content={video as ContentItem}
              children={video as ContentItem}
              square
              small
              actions={['create-post', 'favorite']}
              studioButtonIcon={<Icon path={mdiImagePlusOutline} size="20px" />}
              actionLabel={<FormattedMessage id={`actions.add-${mode}`} />}
              className={styles['content-card']}
              onCompose={onContentItemSelected}
            />
          )
        }).concat(loadingCards)
    }

    if (['quotes', 'statuses'].includes(filter)) {
      const visibleTexts = filter === 'quotes' ? quotes : statuses
      return visibleTexts.map(item => (
        <AnyCard
          key={item.id}
          content={item as any}
          children={item as any}
          square
          small
          actionLabel={<FormattedMessage id="actions.copy-text" />}
          actionButtonIcon={<Icon path={mdiImagePlusOutline} size="20px" />}
          onCompose={onTextSelected}
        />
      )).concat(loadingCards)
    }
    return []
  }, [
    mode,
    filter,
    photosPage,
    stockFilter,
    loadingCards,
    photos.items,
    query,
    quotes,
    statuses,
    favorites.photos,
    stockVideos.items,
    vidsPage,
    onContentItemSelected,
    onTextSelected
  ])

  const libraryFiles = React.useMemo(() => {
    const files = selectedFileFolder ? fileFolders[selectedFileFolder].files : filesFolder.files
    const type = mode === 'image' ? PHOTO_TYPE : VIDEO_TYPE
    return Object.values(files).filter(file => file.type === type)
  }, [filesFolder, selectedFileFolder, fileFolders, mode])

  const showEmptyView = React.useMemo(() => {
    if (['quotes', 'statuses'].includes(filter)) {
      return cards.length === 0
    }

    if (['library-image', 'library-video'].includes(filter)) {
      return !libraryState.loading && libraryFiles.length === 0
    }
    return false
  }, [filter, cards.length, libraryState.loading, libraryFiles.length])

  const openFoldersDropdown = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    e.preventDefault()
    setFoldersDropdownAnchor((e.target as HTMLElement).closest('[data-chip]') as HTMLElement)
  }, [])

  const closeFoldersDropdown = React.useCallback(() => {
    setFoldersDropdownAnchor(null)
  }, [])

  const onFileFolderClick = React.useCallback((id: string) => {
    setFilter(`library-${mode}`)
    setSelectedFileFolder(id)
    setFoldersDropdownAnchor(null)
  }, [mode])

  const filterPopupProps = { style: { zIndex: '1303' } }

  const clearSearch = () => {
    onQueryChange('')
    searchPhotos$.current?.next(DEFAULT_SEARCH_QUERY)
    searchTexts$.current?.next('')
    searchVideos$.current?.next(DEFAULT_SEARCH_QUERY)
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h2 className={styles.title}>
          <FormattedMessage id={`content-studio.title-content-${mode}`} />
        </h2>
      </header>
      <div className={styles.filters}>
        <FilterDropdown mode={mode} selectedValue={filter} onSelectedValueChange={switchToView} />
        {filter === 'stock-image' && (
          <StockContentSourceFilter
            filterType="stock-photos"
            selectedSource={stockFilter}
            popperProps={filterPopupProps}
            onSelectedSourceChange={setStockFilter}
          />
        )}
        {['stock-image', 'stock-video', 'quotes', 'statuses', 'text-favorites'].includes(filter) && (
          <div className={`${styles['search-box']} ${query ? styles.active : ''}`}>
            <CollapsibleTextInput
              ref={inputRef}
              changeOnEnter
              placeholder={props.intl.formatMessage({ id: 'content-studio.labels.search-placeholder' })}
              actionButton={(
                <div className={`${styles['btn-clear']} ${query ? '' : styles.hidden}`} onClick={clearSearch}>
                  <Icon path={mdiClose} color="rgba(0, 0, 0, 0.54)" size="20px" />
                </div>
              )}
              onValueChanged={onQueryChange}
            />
          </div>
        )}
      </div>
      <ScrollListener
        key={filter}
        emitTreshold={SCROLL_EMIT_TRESHOLD}
        scrollElement={scrollElementRef.current}
        onScroll={loadNextPage}
      >
        <div className={styles['grid-wrapper']}>
          {['stock-image', 'stock-video', 'photo-favorites', 'quotes', 'statuses'].includes(filter) && (
            <CardBricks key={query} ref={gridRef}>{cards}</CardBricks>
          )}
          {['library-image', 'library-video'].includes(filter) && (
            <LibraryFilesGrid
              files={libraryFiles}
              page={libraryState.page}
              loading={libraryState.loading}
              error={libraryState.error}
              onSelect={mode === 'image' ? props.onImageSelected : onVideoSelected}
            />
          )}
          {showEmptyView && (
            <EmptyContentView contentView={filter} className={filter === 'stock-image' ? styles['search-empty-content'] : ''} />
          )}
        </div>
      </ScrollListener>
      {filter === 'text-favorites' && (
        <FavoriteStatusesView
          cardActionLabel={<FormattedMessage id="actions.copy-text" />}
          emptyView={<EmptyContentView contentView="text-favorites" />}
          searchQuery={favTextSearchQuery}
          actionButtonIcon={<Icon path={mdiImagePlusOutline} size="20px" />}
          onCompose={onTextSelected}
        />
      )}
      <Popper
        open={Boolean(foldersDropdownAnchor)}
        anchorEl={foldersDropdownAnchor}
        placement="bottom"
        className={styles.popper}
      >
        <ClickAwayListener onClickAway={closeFoldersDropdown} mouseEvent="onMouseDown">
          <Paper>
            <ul className={styles['ul-folders']}>
              {Object.values(fileFolders).map(folder => (
                <FileFolderListItem
                  key={folder.id}
                  id={folder.id}
                  name={folder.name}
                  active={selectedFileFolder === folder.id}
                  onClick={onFileFolderClick}
                />
              ))}
            </ul>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

function FileFolderListItem(props: { id: string, name: string, active: boolean, onClick: (id: string) => void }) {
  const onClick = () => {
    props.onClick(props.id)
  }
  return (
    <li className={`text-ellipsis ${props.active ? styles['li-active'] : ''}`} onClick={onClick}>
      {props.name}
    </li>
  )
}

export default injectIntl(ContentStudioGridWithFilters)
