import * as React from 'react'
import { WithIntl, Stream, Feed } from 'interfaces'
import { IntlShape, FormattedMessage } from 'react-intl'
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/empty'
import Dialog from '@mui/material/Dialog'
import { FeedCard } from 'components/Cards'
import numberToHuman from 'utils/format/numberToHuman'
import CreateStreamForm from 'components/CreateStreamForm/CreateStreamForm'
import { ColorGradient } from 'utils/colors'
import { FEATURE_FIND_MY_STREAMS_CREATE, FEATURE_FIND_PRIVATE_STREAMS } from 'shared/constants'
import { Subject } from 'rxjs/Subject'
import 'rxjs/add/operator/concatMap'
import styles from './SaveFeedDialog.pcss'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setSelectedFeedsToSave } from 'services/content'
import { SnackType } from 'services/snackbar/interfaces/PendingTreat'
import SocialIcon from 'components/SocialIcon'
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js'

// This is set for optimization purposes and should not be increased until restructure in content backend is made
export const MAX_FEEDS_IN_STREAM = 30
const CURATED_STREAMS_OWNER_ID = '720'

export interface SaveFeedDialogProps extends WithIntl {
  selectedFeeds: Feed[]
  streams: Array<Stream>
  intl: IntlShape
  userId?: string
  createStream: (
    name: string,
    color?: string,
    feeds?: Feed[],
    isPrivate?: boolean,
    file?: File,
    featuredImage?: string,
    secondaryImages?: string[]
  ) => Promise<any>
  addFeeds: (feeds: Feed[], stream: Stream) => Observable<any>
  onClose: () => void
  trackFeedInteraction: (ids: string[]) => Promise<string[]>
  getUserStreams: (force?: boolean) => Promise<Stream[]>
  checkFeatureAvailability: (feature: string) => boolean
  displayNotification: (text: string, type?: SnackType) => void
  removeFeed: (id: string) => Observable<any>
}

export function SaveFeedDialog(props: SaveFeedDialogProps) {
  const location = useLocation()
  const dispatch = useDispatch()
  const { selectedFeeds } = props
  const singleFeed = selectedFeeds.length === 1 ? selectedFeeds[0] : null
  const [loading, setLoading] = React.useState(false)
  const streamToggle$ = React.useRef<Subject<{ stream: Stream, feeds: Feed[] }>>()
  const feedImages = selectedFeeds.map(f => f.image).filter(Boolean) as string[]

  React.useEffect(() => {
    streamToggle$.current = new Subject<{ stream: Stream, feeds: Feed[] }>()
    streamToggle$.current.concatMap(({ stream, feeds }) => {
      if (feeds.length === 0) {
        return Observable.empty()
      }

      if (feeds.length === 1) {
        const feedIndex = stream.feeds.findIndex(f => f.uniqueSource === feeds[0].uniqueSource)
        const isFeedInStream = feedIndex !== -1

        if (isFeedInStream) {
          return props.removeFeed(stream.feeds[feedIndex].id).catch(error => {
            console.error('Remove feed failed,', error)
            return Observable.empty()
          })
        }
      }
      setLoading(true)

      // Add feed to stream
      if (props.userId !== CURATED_STREAMS_OWNER_ID && stream.feedIds.length + feeds.length > MAX_FEEDS_IN_STREAM) {
        props.displayNotification(props.intl.formatMessage(
          { id: 'find.save-feed-panel.labels.feeds-limit-reached' },
          { count: MAX_FEEDS_IN_STREAM }
        ), 'warning')
        return Observable.empty()
      }

      return props.addFeeds(feeds, stream).flatMap(() => {
        if (location.pathname.startsWith('/content/search')) {
          props.trackFeedInteraction(feeds.map(f => f.uniqueSource))
        }
        return Observable.of({})
      }).catch(error => {
        console.error('Add feed failed,', error)
        setLoading(false)
        return Observable.empty()
      })
    }).subscribe(() => {
      props.displayNotification('Feeds added!', 'success')
      setLoading(false)
      props.onClose()
    })

    return () => {
      if (streamToggle$.current) {
        streamToggle$.current.unsubscribe()
      }
    }
  }, [])

  React.useEffect(() => {
    props.getUserStreams(false)
  }, [])

  if (selectedFeeds.length === 0) {
    return null
  }

  // EXPL: We want to display only owned streams (without "My Profiles")
  const userStreams = props.streams.filter(stream => !stream.originalId && !stream.protected)

  const isPrivateStreamCreateAllowed = () => {
    return props.checkFeatureAvailability(FEATURE_FIND_PRIVATE_STREAMS)
  }

  const createStream = (
    name: string,
    color: ColorGradient,
    imageFile: File | null,
    isPrivate?: boolean,
    featuredImage?: string,
    secondaryImages?: string[]
  ) => {
    const createStreamAllowed = props.checkFeatureAvailability(FEATURE_FIND_MY_STREAMS_CREATE)
    if (!createStreamAllowed) {
      return true
    }

    const colorString = `${color.from},${color.to}`
    setLoading(true)
    // Set default images from feeds, if not selected by user
    const mainImage = featuredImage || feedImages[0]
    /* eslint-disable-next-line no-magic-numbers */
    const defaultSecondaryImages = secondaryImages || feedImages.slice(1, 3).filter(Boolean)
    props.createStream(name, colorString, selectedFeeds, isPrivate, imageFile || undefined, mainImage, defaultSecondaryImages)
      .then(() => props.getUserStreams(true))
      .then(() => {
        setLoading(false)
        props.displayNotification(props.intl.formatMessage({ id: 'find.save-feed-dialog.notifications.stream-created' }), 'success')
        props.onClose()
      })
      .catch((error: any) => {
        console.error('Error creating stream: ', error)
        props.displayNotification(props.intl.formatMessage({ id: 'find.save-feed-dialog.notifications.stream-create-error' }), 'error')
        setLoading(false)
      })
    return true
  }

  const removeFeedFromSelection = (id: string) => {
    dispatch(setSelectedFeedsToSave(selectedFeeds.filter(f => f.id !== id)))
  }

  const saveToStream = (feeds: Feed[], str: Stream) => {
    streamToggle$.current?.next({ stream: str, feeds })
    props.onClose()
  }

  return (
    <Dialog open maxWidth="xl" onClose={props.onClose} classes={{ paper: styles.dialog }}>
      <div className={styles.grid}>
        <div className={`${styles['panel-left']} ${props.selectedFeeds.length > 1 ? styles.multi : ''}`}>
          {singleFeed && (
            <div className={styles['feed-box']}>
              <h5 className={styles['feed-name']}>{singleFeed.name}</h5>
              <p className={styles['feed-type']}>{`${singleFeed.type} feed`}</p>
              {singleFeed.fanCount && (
                <p className={styles.followers}>
                  {`${numberToHuman(singleFeed.fanCount)} `}
                  <FormattedMessage id="general.feed.followers" values={{ count: singleFeed.fanCount }} />
                </p>
              )}
              <FeedCard feed={singleFeed} disableNavigation className={styles['feed-card']} />
            </div>
          )}
          {selectedFeeds.length > 1 && (
            <div className={styles['feeds-list-box']}>
              <h4 className={styles['feeds-list-title']}>{`Feeds: ${selectedFeeds.length}`}</h4>
              <ul className={styles['feeds-list']}>
                {selectedFeeds.map(feed => (
                  <FeedListItem key={feed.id} feed={feed} onDelete={removeFeedFromSelection} />
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className={styles['panel-right']}>
          <div className={styles.content}>
            <CreateStreamForm
              loading={loading}
              images={feedImages}
              feeds={props.selectedFeeds}
              streams={userStreams}
              onSaveToStream={saveToStream}
              onSubmit={createStream}
              onCancel={props.onClose}
              isPrivateOptionAllowed={isPrivateStreamCreateAllowed}
            />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export function FeedListItem({ feed, onDelete }: { feed: Feed, onDelete: (id: string) => void }) {
  const handleDelete = () => {
    onDelete(feed.id)
  }

  return (
    <li className={styles['feed-li']}>
      <SocialIcon outline className={styles['feed-li-icon']} icon={feed.type} size="16px" />
      <span className={`${styles['feed-li-name']} text-ellipsis`}>{feed.name}</span>
      <span className={styles['feed-li-btn']} onClick={handleDelete}>
        <Icon path={mdiClose} size="16px" />
      </span>
    </li>
  )
}
