import * as React from 'react'
import styles from '../PostsCalendarView.pcss'
import { CalendarDay } from 'interfaces/Post/Calendar'
import { PlannedPost } from 'interfaces'
import { useDroppable, DragOverlay } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { createPortal } from 'react-dom'
import { CalendarPostSize } from 'services/post/state'
import MiniPostView, { MiniPostViewProps } from '../../MiniPostView'

export type IPostGroup = {
  order: number
  date: string // MM-DD-YYYY
  posts: PlannedPost[]
}

type PostsCalendarDayProps = {
  day: CalendarDay
  postGroup: IPostGroup
  activePostId?: string
  draggedPost: PlannedPost | null
  withDragAndDrop: boolean
  postSize: CalendarPostSize
  onPostClick: (post: PlannedPost) => void
}

export function PostsCalendarDay({
  day,
  postGroup,
  withDragAndDrop,
  activePostId,
  draggedPost,
  postSize,
  onPostClick
}: PostsCalendarDayProps) {
  const { posts, date } = postGroup
  const { setNodeRef, isOver } = useDroppable({
    id: postGroup.date,
    data: {
      day,
      group: postGroup,
      type: 'container'
    }
  })
  const style = isOver ? { backgroundColor: '#caf3ca' } : undefined
  const [order, setOrder] = React.useState(posts.map(p => p.id))
  React.useEffect(() => {
    setOrder(posts.map(p => p.id))
  }, [posts])

  const items = posts.map(post => {
    const isActive = activePostId === post.id

    if (post.isEmpty || post.isPosted) {
      return (
        <div className={styles['post-container']} key={post.id}>
          <MiniPostView
            key={post.id}
            post={post}
            size={postSize}
            className={`${styles['mini-post']} ${isActive ? styles.active : ''}`}
          />
        </div>
      )
    }

    let dndClass: string = ''
    if (
      draggedPost
      && draggedPost.profileId === post.profileId
      && draggedPost.id !== post.id
      && draggedPost.bucketId === post.bucketId
    ) {
      dndClass = styles['drop-target']
    }

    return (
      <DraggableCalendarPost
        key={post.id}
        post={post}
        size={postSize}
        containerClassName={dndClass}
        className={`${styles['mini-post']} ${isActive ? styles.active : ''}`}
        onClick={onPostClick}
      />
    )
  })

  if (withDragAndDrop) {
    return (
      <SortableContext id={date} items={order} strategy={verticalListSortingStrategy}>
        <div ref={setNodeRef} style={{ ...style, height: '100%' }}>
          {items}
        </div>
      </SortableContext>
    )
  }

  return (
    <div className={styles['scrollable-box']}>
      {items}
    </div>
  )
}

export const DraggableCalendarPost = React.memo((props: MiniPostViewProps & { containerClassName: string }) => {
  const { attributes, listeners, setDroppableNodeRef, setDraggableNodeRef, isDragging } = useSortable({
    id: props.post.id,
    data: { post: props.post },
    disabled: !props.post.autoPost || props.post.isPosted
  })

  return (
    <div className={`${styles['post-container']} ${props.containerClassName}`} ref={setDroppableNodeRef}>
      <MiniPostView
        {...props}
        ref={setDraggableNodeRef}
        draggableProps={{
          attributes,
          listeners,
          isDragging,
          style: { opacity: isDragging ? '0.5' : '1' }
        }}
      />
      {isDragging && createPortal(
        <DragOverlay>
          <MiniPostView {...props} />
        </DragOverlay>,
        document.body
      )}
    </div>

  )
}, (prevProps, nextProps) => {
  return prevProps.containerClassName === nextProps.containerClassName && prevProps.size === nextProps.size
})
