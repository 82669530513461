import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'
import { ContentType } from 'interfaces'

const ENDPOINT_BULK_DELETE = `${LEGACY_URL}/v3/posts/delete_posts`

export const V1 = Object.freeze({
  // Delete multiple posts
  deletePosts: function () {
    return function (
      ids: string[],
      pageIds: string[],
      type: 'queued' | 'pending' | 'scheduled' | 'failed' | 'planned',
      all?: boolean,
      bucketId?: string,
      query?: string,
      types?: ContentType[]
    ) {
      const endpoint = ENDPOINT_BULK_DELETE
      const builder = new RequestBuilder(endpoint).asPost().expectJSON()
      const data: any = {
        type,
        pp_page_ids: pageIds
      }

      if (all) {
        data.selected = 'all'
      } else {
        data.post_ids = ids
      }

      if (bucketId) {
        data.type = 'planned_bucket'
        data.bucket_id = bucketId
      }

      if (query) {
        data.search = query
      }
      if (types) {
        data.post_types = types.join(',')
      }

      return authorizedPipe<any>(
        builder
          .body(data)
          .build()
      )
    }
  }()
})
