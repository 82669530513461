import * as React from 'react'
import {
  StockContentProvider,
  STOCK_CONTENT_PROVIDER_GIPHY,
  STOCK_CONTENT_PROVIDER_PIXABAY,
  STOCK_CONTENT_PROVIDERS_ALL,
  STOCK_CONTENT_PROVIDER_PEXELS,
  STOCK_CONTENT_PROVIDER_UNSPLASH
} from 'interfaces/Content/StockContentProvider'
import { FilterType, IndexedObject } from 'interfaces'
import { STOCK_ALL_FILTER, STOCK_GIF_TYPE, STOCK_PHOTO_TYPE, STOCK_VIDEO_TYPE } from 'interfaces/Content/StockContentTypes'

import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import { PopperProps } from '@mui/material/Popper'

const OPTIONS_BY_TYPE: IndexedObject<string[]> = {
  [STOCK_PHOTO_TYPE]: [
    STOCK_CONTENT_PROVIDER_PIXABAY,
    STOCK_CONTENT_PROVIDER_PEXELS,
    STOCK_CONTENT_PROVIDER_UNSPLASH
  ],
  [STOCK_VIDEO_TYPE]: [
    STOCK_CONTENT_PROVIDER_PIXABAY,
    STOCK_CONTENT_PROVIDER_PEXELS
  ],
  [STOCK_GIF_TYPE]: [
    STOCK_CONTENT_PROVIDER_GIPHY
  ],
  [STOCK_ALL_FILTER]: [
    STOCK_CONTENT_PROVIDER_PIXABAY,
    STOCK_CONTENT_PROVIDER_PEXELS,
    STOCK_CONTENT_PROVIDER_GIPHY
  ]
}

interface StockContentSourceFilterProps {
  selectedSource: StockContentProvider
  filterType: FilterType
  className?: string
  popperProps?: Partial<PopperProps>
  onSelectedSourceChange: (source: StockContentProvider) => void
}

export function StockContentSourceFilter(props: StockContentSourceFilterProps) {
  const options: PPSelectOptions = React.useMemo(() => {
    const values = OPTIONS_BY_TYPE[props.filterType] || []
    const optionsMap: PPSelectOptions = {}
    if (values.length > 1) {
      optionsMap[STOCK_CONTENT_PROVIDERS_ALL] = { label: STOCK_CONTENT_PROVIDERS_ALL }
    }
    const map = values.reduce((map: PPSelectOptions, value) => {
      map[value] = { label: value }
      return map
    }, {})

    Object.assign(optionsMap, map)

    return optionsMap
  }, [props.filterType])

  return (
    <PPSelect
      name="Source"
      options={options}
      selectedValue={props.selectedSource}
      className={props.className || ''}
      popperProps={props.popperProps}
      withCaret
      onSelectionChange={props.onSelectedSourceChange}
    />
  )
}

export default StockContentSourceFilter
