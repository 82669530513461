import * as React from 'react'
import styles from './CustomizePostBlock.pcss'
import { FormattedMessage } from 'react-intl'
import { ComposerTwitterPost, TweetType } from 'services/compose/interfaces/ComposerTwitterPost'
import { BRAND_TWITTER, ContentItem } from 'interfaces'
import { EmptyNetworkPostOptions } from './EmptyNetworkPostOptions'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import Icon from '@mdi/react'
import { mdiShareVariant } from '@mdi/js'
import Button from '@mui/material/Button'

interface ComposerTwitterPostOptionsProps {
  post: ComposerTwitterPost
  content?: ContentItem
  empty?: boolean
  hideEmpty?: boolean
  className?: string
  onApplyToAll?: (type: TweetType) => void
  onSelectionChange: (type: TweetType) => void
}

export function ComposerTwitterPostOptions(props: ComposerTwitterPostOptionsProps) {
  const tweetType = props.post.tweetType

  if (props.empty) {
    return props.hideEmpty ? null : <EmptyNetworkPostOptions />
  }

  if (props.content?.feed.type !== BRAND_TWITTER) {
    return null
  }

  const applyToAll = () => {
    if (props.onApplyToAll) {
      props.onApplyToAll(tweetType)
    }
  }

  const options: PPSelectOptions = {
    new: { label: <FormattedMessage id="composer.labels.tweet-type.new" /> },
    retweet: { label: <FormattedMessage id="composer.labels.tweet-type.retweet" /> },
    quote: { label: <FormattedMessage id="composer.labels.tweet-type.quote" /> }
  }

  return (
    <div className={`${styles['option-box']} ${props.className || ''}`}>
      <PPSelect
        name="Tweet as"
        options={options}
        selectedValue={tweetType}
        icon={<Icon path={mdiShareVariant} size="16px" />}
        withCaret
        footerElement={props.onApplyToAll && (
          <Button className={styles['btn-dd-reshare']} onClick={applyToAll}>
            <FormattedMessage id="general.labels.apply-to-all" />
          </Button>
        )}
        onSelectionChange={props.onSelectionChange}
      />
    </div>
  )
}
