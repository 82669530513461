import * as React from 'react'
import SimpleLoader from 'components/SimpleLoader'
import { ContentType, IndexedObject, PendingPost, PlannedPost as IPlannedPost, WithIntl } from 'interfaces'
import { POST_TYPES } from 'interfaces/Content/ContentType'
import { injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { PostErrorView, ERROR_NO_PROFILES_TITLE, ERROR_NO_PROFILES_SUBTITLE } from 'routes/publishing/components/PostErrorView'
import { Observable } from 'rxjs/Observable'
import { catchError } from 'rxjs/operators/catchError'
import { tap } from 'rxjs/operators/tap'
import { Subject } from 'rxjs/Subject'
import { bulkDeletePosts, fetchPendingPosts, shufflePosts, updatePostRecycle } from 'services/post/actions'
import { setPlannedPostsView } from 'services/post'
import {
  emptySlotsVisibleSelector,
  newPostSelector,
  plannedPostsLayoutSelector,
  selectedProfilesFetchIdsSelector,
  selectedProfilesSelector
} from 'services/post/selectors'
import { message } from 'services/snackbar'
import { StoreThunkDispatch } from 'store/state'
import PostFilesDialog from 'components/Posts/PostFilesDialog'
import styles from './PostViews.pcss'
import { checkFeatureAvailability } from 'services/product'
import { FEATURE_POST_COPY, FEATURE_POST_RECYCLE, FEATURE_POST_SHUFFLE } from 'shared/constants'
import { PlannedPost } from 'components/Posts'
import { PostsAction, postsReducer } from './reducer'
import { addPosts, deleteMultiplePosts, setPosts, updateRecyclePost } from './actions'
import { connectedDestinationsSelector } from 'services/destinations'
import { getDerivedStateFromPost, mergeState, resetComposer } from 'services/compose'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import PublishingTopBar from './components/PublishingTopBar'
import PostPreviewWithInfo from './components/PostPreviewWithInfo'
import CopyPostsPopup from 'routes/publishing/components/CopyPostsPopup'
import PlannedPostsList, { PostGroupData } from './components/PlannedPostsList'
import EmptyView from 'components/EmptyView'
import { mdiFeather } from '@mdi/js'
import { publishPosts } from 'services/post/shareNow/actions'
import { HelpToggleButton } from 'components/App/components/HelpToggleButton'
import { PostsGridView } from './components/PostsGridView'
import { initPlans } from 'services/plan/actions'
import { parse } from 'query-string'
import { isPlannedPostsLayout } from 'services/post/state'

export function PendingPostsManager(props: WithIntl) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = parse(location.search)
  const selectedProfilesIds = useSelector(selectedProfilesSelector)
  const selectedProfilesFetchIds = useSelector(selectedProfilesFetchIdsSelector)
  const newPost = useSelector(newPostSelector)
  const connectedProfiles = useSelector(connectedDestinationsSelector)
  const emptySlotsVisible = useSelector(emptySlotsVisibleSelector)
  const activeView = useSelector(plannedPostsLayoutSelector)
  const [posts, postsDispatch] = React.useReducer<React.Reducer<PendingPost[], PostsAction>>(postsReducer, [])
  const [loading, setLoading] = React.useState(false)
  const [page, setPage] = React.useState(0)
  const [hasNextPage, setHasNextPage] = React.useState(true)
  const [totalPostsCount, setTotalPostsCount] = React.useState(0)
  const [loadingOverlayActive, setLoadingOverlayActive] = React.useState(false)
  const [selectedPostsIds, setSelectedPostsIds] = React.useState<IndexedObject<boolean> | 'all'>({})
  const [postInMediaPreviewDialog, setPostInMediaPreviewDialog] = React.useState<IPlannedPost | null>(null)
  const [refreshKey, setRefreshKey] = React.useState(0)
  const [search, setSearch] = React.useState<{ query: string, types: ContentType[], searchInSummary: boolean }>(
    { query: '', types: POST_TYPES, searchInSummary: true }
  )
  const [withPreview, setWithPreview] = React.useState(false)
  const [postInPreview, setPostInPreview] = React.useState<IPlannedPost | null>(null)
  const [postsToCopy, setPostsToCopy] = React.useState<IPlannedPost[]>([])

  const scrollElementRef = React.useRef<HTMLElement | undefined>(undefined)
  const fetch$ = React.useRef<Subject<{ ids: string[], page: number, query?: string, types?: ContentType[], searchInSummary: boolean }>>()
  const delete$ = React.useRef<Subject<{ postIds: string[], ppids: string[], all: boolean }>>()
  const share$ = React.useRef<Subject<{ postIds: string[], ppids: string[], all: boolean, query?: string, types?: ContentType[] }>>()
  const shuffle$ = React.useRef<Subject<{ postIds: string[], ppids: string[], all?: boolean }>>()
  const recycle$ = React.useRef<Subject<{ postId: string, value: boolean | Date | number }>>()
  const selectionRef = React.useRef<{ postIds: string[], profileIds: string[], ppids: string[], all: boolean }>()

  React.useEffect(() => {
    if (activeView === 'calendar' || !['list', 'grid'].includes(queryParams?.view as string)) {
      dispatch(setPlannedPostsView('list'))
      navigate(location.pathname + '?view=list', { replace: true })
      return
    }

    if (isPlannedPostsLayout(queryParams?.view) && queryParams.view !== activeView) {
      dispatch(setPlannedPostsView(queryParams.view))
    }
  }, [activeView, dispatch, queryParams.view, navigate, location.pathname])

  React.useLayoutEffect(() => {
    scrollElementRef.current = document.querySelector('[data-test="main"]') as HTMLElement
  }, [])

  React.useEffect(() => {
    dispatch(initPlans())
  }, [dispatch])

  const nonEmptyPosts = React.useMemo(() => {
    return posts.filter(p => !p.isEmpty)
  }, [posts])

  React.useEffect(() => {
    fetch$.current = new Subject()
    fetch$.current
      .pipe(tap(({ page }) => {
        setLoading(true)
        setPage(page)
      }))
      .switchMap(({ ids, page, query, types, searchInSummary }) => dispatch(fetchPendingPosts(page, ids, query, types, searchInSummary))
        .pipe(
          catchError(() => {
            dispatch(message('Error fetching posts! Please refresh.', 'error'))
            return Observable.of([])
          })
        ))
      .subscribe((response: { posts: PendingPost[], page: number, totalPostsCount: number }) => {
        setLoading(false)
        setHasNextPage(response.posts.length > 0)
        setTotalPostsCount(response.totalPostsCount)
        if (response.page === 0) {
          postsDispatch(setPosts(response.posts))
        } else {
          postsDispatch(addPosts(response.posts))
        }
        setLoadingOverlayActive(false)
      })

    return () => fetch$.current?.unsubscribe()
  }, [])

  // Check for newly created posts while posts page is active. Reload posts to fetch the new records
  React.useEffect(() => {
    if (newPost || location.state?.refresh) {
      setRefreshKey(current => current + 1)
    }
  }, [newPost, location.state?.refresh])

  // Clear posts when profile selection changes
  React.useEffect(() => {
    postsDispatch(setPosts([]))
  }, [selectedProfilesFetchIds])

  // Fetch first page of posts when profile selection changes or posts refresh is requested
  React.useEffect(() => {
    if (selectedProfilesFetchIds.length > 0) {
      fetch$.current?.next({
        page: 0,
        ids: selectedProfilesFetchIds.split(','),
        query: search.query,
        types: search.types,
        searchInSummary: search.searchInSummary
      })
    }
  }, [selectedProfilesFetchIds, refreshKey, search.query, search.types, search.searchInSummary])

  React.useEffect(() => {
    delete$.current = new Subject()
    delete$.current
      .pipe(tap(() => {
        dispatch(message(props.intl.formatMessage({ id: 'post.notifications.deleting-posts' })))
        setLoadingOverlayActive(true)
      }))
      .flatMap(({ ppids, postIds, all }) => {
        return dispatch(bulkDeletePosts(postIds, ppids, 'pending', all))
          .pipe(catchError((error: Error) => {
            dispatch(message(props.intl.formatMessage({ id: 'post.notifications.delete-posts-error' }, { error: error.message }), 'error'))
            return Observable.of({ error })
          }))
      })
      .subscribe((response: any) => {
        if (!response.error) {
          setSelectedPostsIds({})
          postsDispatch(deleteMultiplePosts(response.ids))
          setRefreshKey(current => current + 1)
        } else {
          setLoadingOverlayActive(false)
        }
      })

    return () => delete$.current?.unsubscribe()
  }, [dispatch, props.intl])

  React.useEffect(() => {
    share$.current = new Subject()
    share$.current
      .pipe(tap(() => {
        setLoadingOverlayActive(true)
      }))
      .flatMap(({ ppids, postIds, all, query, types }) => {
        return dispatch(publishPosts(postIds, ppids, 'pending', all, undefined, query, types))
          .pipe(catchError((error: Error) => {
            dispatch(message(props.intl.formatMessage({ id: 'notifications.error-w-message' }, { error: error.message })))
            return Observable.of({ error })
          }))
      })
      .subscribe((response: any) => {
        if (!response.error) {
          setSelectedPostsIds({})
          dispatch(message(props.intl.formatMessage({ id: 'notifications.post-posted' }), 'success'))
          setRefreshKey(current => current + 1)
        } else {
          setLoadingOverlayActive(false)
        }
      })

    return () => share$.current?.unsubscribe()
  }, [dispatch, props.intl])

  React.useEffect(() => {
    shuffle$.current = new Subject()
    shuffle$.current.pipe(tap(() => {
      setLoadingOverlayActive(true)
      dispatch(message(props.intl.formatMessage({ id: 'post.notifications.shuffling-posts' })))
    }))
      .flatMap(({ postIds, ppids, all }) => dispatch(shufflePosts(postIds, ppids, 'pending', all)).pipe(catchError(() => {
        return Observable.of({ error: true })
      })))
      .subscribe(response => {
        if (!response.error) {
          setRefreshKey(current => current + 1)
        } else {
          setLoadingOverlayActive(false)
        }
      })

    return () => shuffle$.current?.unsubscribe()
  }, [])

  React.useEffect(() => {
    recycle$.current = new Subject()
    recycle$.current
      .flatMap(({ postId, value }) => dispatch(updatePostRecycle(postId, value))
        .pipe(catchError((e) => {
          const msg = e.response?.error.message || props.intl.formatMessage({ id: 'errors.generic' })
          dispatch(message(msg, 'error'))
          return Observable.of({ error: true })
        }))
      )
      .subscribe(response => {
        if (!response.error) {
          setSelectedPostsIds({})
          postsDispatch(updateRecyclePost(response.postId, response.value))
        }
      })

    return () => recycle$.current?.unsubscribe()
  }, [])

  React.useEffect(() => {
    const profileIds = selectedProfilesFetchIds.split(',')
    const ppids = Object.values(connectedProfiles).filter(p => profileIds.includes(p.idNew)).map(p => p.ppid)
    if (selectedPostsIds === 'all') {
      selectionRef.current = {
        all: true,
        postIds: posts.map(p => p.id),
        profileIds,
        ppids
      }
    } else {
      selectionRef.current = {
        all: false,
        profileIds,
        ppids,
        postIds: Object.keys(selectedPostsIds).filter(id => selectedPostsIds[id])
      }
    }
  }, [selectedPostsIds, posts, selectedProfilesFetchIds, connectedProfiles])

  React.useEffect(() => {
    if (withPreview) {
      const firstNonEmptyPost = posts.find(p => !p.isEmpty)
      setPostInPreview(current => {
        if (!current && firstNonEmptyPost) {
          return firstNonEmptyPost
        }
        if (!firstNonEmptyPost) {
          return null
        }
        return posts.find(p => p.id === current?.id) || null
      })
    } else {
      setPostInPreview(null)
    }
  }, [withPreview, posts])

  const onTogglePreview = () => {
    setWithPreview(current => !current)
  }

  const onPostClick = React.useCallback((post: IPlannedPost) => {
    setWithPreview(true)
    setPostInPreview(post)
  }, [])

  const toggleAllPostsSelected = React.useCallback(() => {
    setSelectedPostsIds(current => current === 'all' ? {} : 'all')
  }, [])

  const loadNextPage = React.useCallback(() => {
    if (hasNextPage && !loading) {
      fetch$.current?.next({ page: page + 1, ids: selectedProfilesFetchIds.split(','), ...search })
    }
  }, [page, selectedProfilesFetchIds, hasNextPage, loading, search])

  const onDeletePost = React.useCallback((id: string) => {
    delete$.current?.next({
      postIds: [id],
      ppids: selectionRef.current?.ppids || [],
      all: false
    })
  }, [])

  const onRecycleChange = React.useCallback((id: string, value: number | boolean | Date) => {
    const recycleAvailable = dispatch(checkFeatureAvailability(FEATURE_POST_RECYCLE))
    if (!recycleAvailable) {
      return
    }
    recycle$.current?.next({ postId: id, value })
  }, [])

  const onShuffle = React.useCallback(() => {
    const shuffleAvailable = dispatch(checkFeatureAvailability(FEATURE_POST_SHUFFLE))
    if (!shuffleAvailable) {
      return
    }
    shuffle$.current?.next(selectionRef.current)
  }, [posts, selectedPostsIds])

  const togglePostSelected = React.useCallback((id: string) => {
    setSelectedPostsIds(current => {
      if (current === 'all') {
        return posts.reduce((selection: IndexedObject<boolean>, post) => {
          selection[post.id] = post.id !== id
          return selection
        }, {})
      }
      return { ...current, [id]: !current[id] }
    })
  }, [posts])

  const onCopyPosts = React.useCallback(() => {
    const copyAllowed = dispatch(checkFeatureAvailability(FEATURE_POST_COPY))
    if (copyAllowed) {
      if (selectionRef.current?.all) {
        setPostsToCopy(posts)
      } else if (selectionRef.current?.postIds) {
        setPostsToCopy(posts.filter(p => selectionRef.current?.postIds.includes(p.id)))
      }
    }
  }, [posts])

  const closePostMediaPreviewDialog = React.useCallback(() => {
    setPostInMediaPreviewDialog(null)
  }, [])

  const deleteSelectedPosts = React.useCallback(() => {
    delete$.current?.next(selectionRef.current)
  }, [])

  const deletePostsConfirmMessage = selectedPostsIds === 'all'
    ? props.intl.formatMessage({ id: 'post.delete-all-confirm' }, { count: totalPostsCount, type: 'pending' })
    : undefined

  const onEdit = React.useCallback((post: IPlannedPost) => {
    dispatch(resetComposer())
    dispatch(mergeState(getDerivedStateFromPost(post)))
    navigate('/composer', { state: { floating: true, modal: 'compose', opener: location.pathname, background: location } })
  }, [dispatch, location, navigate])

  const onFilter = React.useCallback((query: string, types: ContentType[], searchInSummary: boolean) => {
    setSearch({ query, types, searchInSummary })
    dispatch(setPlannedPostsView('list'))
    navigate(location.pathname + '?view=list', { replace: true })
  }, [dispatch, navigate, location.pathname])

  const previewSelection = React.useMemo(() => {
    const selectedPostIndex = postInPreview
      ? nonEmptyPosts.findIndex((p: any) => p.id === postInPreview.id)
      : -1
    let hasNext = false
    let hasPrev = false

    if (selectedPostIndex !== -1) {
      hasNext = selectedPostIndex < nonEmptyPosts.length - 1
      hasPrev = selectedPostIndex >= 1
    }
    return {
      selectedPostIndex,
      hasNext,
      hasPrev
    }
  }, [postInPreview, nonEmptyPosts])

  const selectNextPostForPreview = React.useCallback(() => {
    if (previewSelection.hasNext) {
      const next = nonEmptyPosts[previewSelection.selectedPostIndex + 1]
      setPostInPreview(next)
    }
  }, [previewSelection, nonEmptyPosts])

  const selectPrevPostForPreview = React.useCallback(() => {
    if (previewSelection.hasPrev) {
      const prev = nonEmptyPosts[previewSelection.selectedPostIndex - 1]
      setPostInPreview(prev)
    }
  }, [previewSelection, nonEmptyPosts])

  const deletePost = React.useCallback((post: IPlannedPost) => {
    onDeletePost(post.id)
  }, [onDeletePost])

  const onCopyPost = React.useCallback((post: IPlannedPost) => {
    const copyAllowed = dispatch(checkFeatureAvailability(FEATURE_POST_COPY))
    if (copyAllowed) {
      setPostsToCopy([post])
    }
  }, [dispatch])

  const closeCopyPostsPopup = React.useCallback(() => {
    setPostsToCopy([])
    setSelectedPostsIds({})
  }, [])

  const refreshPosts = React.useCallback(() => {
    setRefreshKey(current => current + 1)
  }, [])

  const visiblePosts = React.useMemo(() => {
    if (emptySlotsVisible) {
      return posts
    }
    return posts.filter(p => !p.isEmpty)
  }, [posts, emptySlotsVisible])

  const onShareNow = React.useCallback((post: IPlannedPost) => {
    share$.current?.next({ all: false, postIds: [post.id], ppids: [post.ppPageId] })
  }, [])

  const showEmptyPostsMessage = !loading && selectedProfilesIds.length > 0 && visiblePosts.length === 0

  const renderPosts = React.useCallback((data: IndexedObject<PostGroupData>) => {
    const allPosts: IPlannedPost[] = Object.values(data).reduce((posts: IPlannedPost[], group: PostGroupData) => {
      posts.push(...group.posts)
      return posts
    }, [])

    return (
      <div className={styles['pending-timeline']}>
        {allPosts.map(post => (
          <PlannedPost
            key={post.id}
            selected={selectedPostsIds === 'all' || Boolean(selectedPostsIds[post.id])}
            post={post}
            isPending
            onPostClick={onPostClick}
            onEdit={onEdit}
            onCopy={onCopyPost}
            onDelete={onDeletePost}
            onRecycleChange={onRecycleChange}
            onToggleSelected={togglePostSelected}
            onMediaPreviewBtnClick={setPostInMediaPreviewDialog}
            onShareNow={onShareNow}
          />
        ))}
      </div>
    )
  }, [onDeletePost, onEdit, onPostClick, onRecycleChange, selectedPostsIds, togglePostSelected, onShareNow, onCopyPost])

  const selectedPostsCount = React.useMemo(() => {
    return selectedPostsIds !== 'all'
      ? Object.values(selectedPostsIds).filter(Boolean).length
      : totalPostsCount || 0
  }, [totalPostsCount, selectedPostsIds])

  return (
    <div className={styles.page}>
      <PublishingTopBar
        withViewPicker
        withPreviewToggle
        withPostsViewToggle
        postsView="posts/planned/queued/pending"
        activeTypes={search.types}
        showPreview={withPreview}
        emptySlotsVisible={emptySlotsVisible}
        totalCount={totalPostsCount}
        children={<HelpToggleButton />}
        hideViewOptions={['calendar']}
        onTogglePreview={onTogglePreview}
        onFilter={onFilter}
      />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {loadingOverlayActive && (
            <div className={styles['loading-overlay']}>
              <SimpleLoader small />
            </div>
          )}
          {activeView === 'list' && (
            <PlannedPostsList
              posts={visiblePosts}
              selectedPosts={selectedPostsIds}
              activePreviewPostId={postInPreview?.id}
              bulkActionsProps={{
                hidePostActions: false,
                withDelete: true,
                withCopy: true,
                allSelected: selectedPostsIds === 'all',
                disabled: selectedPostsCount === 0,
                selectedCount: selectedPostsCount,
                deleteAllConfirmMessage: deletePostsConfirmMessage,
                onToggleAllSelected: toggleAllPostsSelected,
                onBulkDeletePosts: deleteSelectedPosts,
                onCopy: onCopyPosts,
                onShuffle
              }}
              renderPosts={renderPosts}
              onLoadNextPage={loadNextPage}
              onTogglePostSelected={togglePostSelected}
              onDeletePost={onDeletePost}
              onEditPost={onEdit}
              onCopyPost={onCopyPost}
              onMediaPreviewBtnClick={setPostInMediaPreviewDialog}
              onRecycleChange={onRecycleChange}
              onPostClick={onPostClick}
              onShareNow={onShareNow}
            />
          )}
          {activeView === 'grid' && !showEmptyPostsMessage && (
            <PostsGridView
              posts={visiblePosts}
              selectedPosts={selectedPostsIds}
              bulkActionsProps={{
                hidePostActions: false,
                withDelete: true,
                withCopy: true,
                allSelected: selectedPostsIds === 'all',
                disabled: selectedPostsCount === 0,
                selectedCount: selectedPostsCount,
                deleteAllConfirmMessage: deletePostsConfirmMessage,
                onToggleAllSelected: toggleAllPostsSelected,
                onBulkDeletePosts: deleteSelectedPosts,
                onCopy: onCopyPosts,
                onShuffle
              }}
              withDragAndDrop={false}
              onLoadNextPage={loadNextPage}
              onPostClick={onPostClick}
              onTogglePostSelected={togglePostSelected}
              onDeletePost={onDeletePost}
              onEditPost={onEdit}
              onCopyPost={onCopyPost}
              onShareNow={onShareNow}
            />
          )}
          {!loading && selectedProfilesIds.length === 0 && (
            <PostErrorView title={ERROR_NO_PROFILES_TITLE} subtitle={ERROR_NO_PROFILES_SUBTITLE} />
          )}
          {!loading && selectedProfilesIds.length > 0 && posts.length === 0 && (
            <EmptyView
              icon={mdiFeather}
              title="No pending posts"
              subtitle={(
                <div>
                  All your <NavLink to="/posts/planned/queued">queued posts</NavLink>
                  &nbsp;have active <NavLink to="/plans">time slots</NavLink>
                </div>
              )}
              backdrop
            />
          )}
          {loading && !loadingOverlayActive && <SimpleLoader small />}
        </div>
        <div
          className={`${styles['preview-box']} ${withPreview ? styles.visible : ''}`}
        >
          {postInPreview && (
            <PostPreviewWithInfo
              post={postInPreview}
              hasNextPost={previewSelection.hasNext}
              hasPreviousPost={previewSelection.hasPrev}
              className={styles.preview}
              onClose={onTogglePreview}
              onSelectNext={selectNextPostForPreview}
              onSelectPrevious={selectPrevPostForPreview}
              onDelete={deletePost}
              onEdit={onEdit}
              onCopy={onCopyPost}
              onRecycleChange={onRecycleChange}
              onPostUpdated={refreshPosts}
            />
          )}
        </div>
        <PostFilesDialog
          active={Boolean(postInMediaPreviewDialog)}
          images={postInMediaPreviewDialog?.images || []}
          onClose={closePostMediaPreviewDialog}
        />
        <CopyPostsPopup
          open={postsToCopy.length > 0}
          posts={postsToCopy}
          allSelected={Boolean(selectionRef.current?.all)}
          query={search.query}
          types={search.types}
          postType="pending"
          onClose={closeCopyPostsPopup}
          onCopyStarted={closeCopyPostsPopup}
          onCopyFinished={refreshPosts}
        />
      </div>
    </div>
  )
}

export default injectIntl(PendingPostsManager)
