import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { PostDestination, PostDestinationType } from 'shared'
import { } from 'interfaces'
import { api } from './net'
import { userDestinationsSelector } from './selectors'
import StoreState from 'store/state'
import { destinationsAdapter } from './adapter'
import 'rxjs/add/operator/take'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'

export const getDestinationsSuccess = createAction<PostDestination[]>('destinations/getSuccess')

export const getDestinations = createAsyncThunk(
  'profiles/get',
  async (force: boolean = false, { dispatch, getState }) => {
    if (!force) {
      const cached = userDestinationsSelector(getState() as StoreState)
      if (Object.keys(cached).length > 0) {
        const destinations = Object.values(cached)
        dispatch(getDestinationsSuccess(destinations))
        return { destinations }
      }
    }

    const response = await dispatch(api.getDestinations()).toPromise()
    const destinations = destinationsAdapter(response.postDestinations)
    dispatch(getDestinationsSuccess(destinations))

    return {
      fbPersonalProfiles: destinationsAdapter(response.facebookProfiles),
      destinations
    }
  }
)

export const getAlbums = createAsyncThunk(
  'profiles/getAlbums',
  async (profile: PostDestination, { dispatch }) => {
    const response = await dispatch(api.getAlbums(profile.id, profile.token)).toPromise()
    return {
      profileId: profile.id,
      albums: response.albums.reduce((map: any, album: any) => {
        map[album.id] = { ...album, ppid: profile.ppid }
        return map
      }, {})
    }
  }
)

export const getPinterestBoards = createAsyncThunk(
  'profiles/getPinterestBoards',
  async (profile: PostDestination, { dispatch }) => {
    const response = await dispatch(api.getPinterestBoards(profile.ppid, profile.token)).toPromise()
    return {
      profileId: profile.id,
      ppid: profile.ppid,
      boards: response.albums.reduce((map: any, album: any) => {
        map[album.id] = {
          ...album,
          url: `https://pinterest.com${album.url}`,
          ppid: profile.ppid
        }
        return map
      }, {})
    }
  }
)

export const toggleProfileConnected = createAsyncThunk(
  'profiles/toggleConnected',
  async (args: { id: string, ppid: string, type: PostDestinationType, forceDeletePosts?: boolean }, { dispatch, rejectWithValue }) => {
    const response = await dispatch(api.toggleConnected(args.id, args.ppid, args.type, args.forceDeletePosts))
      .pipe(catchError(error => {
        return Observable.of({ error })
      }))
      .take(1)
      .toPromise()

    if ((response as any).error) {
      return rejectWithValue((response as any).error)
    }
    return args.id
  }
)

export const refreshMyProfiles = createAsyncThunk(
  'profiles/refreshMyProfiles',
  async (_, { dispatch }) => {
    await dispatch(api.refreshMyProfiles()).toPromise()
  }
)

export const removeProfile = createAsyncThunk(
  'profiles/remove',
  async (args: { id: string, ppid: string, forceDelete: boolean }, { dispatch, rejectWithValue }) => {
    const res = await dispatch(api.removeProfile(args.ppid, args.forceDelete))
      .pipe(catchError(error => {
        return Observable.of({ error })
      }))
      .take(1)
      .toPromise()

    if (res.error) {
      return rejectWithValue(res.error)
    }
    return args.id
  }
)
