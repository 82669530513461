import { List, ErrorResponse, LegacyList } from '../types'
import { assert } from '../utils'

export function listAdapter(list: LegacyList, connectedPageIds: string[]): List {
  const pages = list.postDestinations || []
  return {
    id: list.id,
    teamId: list.appId,
    name: list.listName,
    pages,
    connectedPages: pages.filter((id: string) => connectedPageIds.includes(id))
  }
}

export function listsAdapter(response: LegacyList[] | ErrorResponse, connectedPageIds: string[]): List[] {
  const listsResponse = assert<LegacyList[]>(response)
  return listsResponse.map(response => listAdapter(response, connectedPageIds))
}
