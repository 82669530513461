import * as React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import IconCheck from '@mui/icons-material/Check'
import IconError from '@mui/icons-material/Error'
import IconImage from '@mui/icons-material/Image'
import IconGif from '@mui/icons-material/Gif'
import IconVideo from '@mui/icons-material/Videocam'
import { ALLOWED_STATIC_PHOTO_FORMATS, ALLOWED_VIDEO_FORMATS, FILE_FORMAT_GIF } from 'utils/file'
import Tooltip from '@mui/material/Tooltip'
import { FormattedMessage } from 'react-intl'

import styles from './FileUploadSnackbar.pcss'
const PERCENT_MULTIPLIER = 100
const PROGRESS_VALUE_COMPLETE = 100
const CARD_ELEVATION = 4
const AUTOCLOSE_TIMEOUT = 4000 // ms

interface FileUploadSnackbarProps {
  id: string
  file: File
  progress: number
  error?: boolean
  autoCloseTimeout?: number
  onClose: (id: string) => void
}

export function FileUploadSnackbar(props: FileUploadSnackbarProps) {
  const { file, progress, error } = props
  const [autoClose, setAutoClose] = React.useState(false)

  React.useEffect(() => {
    let timer: any
    if (autoClose) {
      timer = setTimeout(complete, props.autoCloseTimeout || AUTOCLOSE_TIMEOUT)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [autoClose])

  React.useEffect(() => {
    if (props.progress === PROGRESS_VALUE_COMPLETE) {
      setAutoClose(true)
    }
  }, [props.progress])

  if (!props.file) {
    return null
  }

  const complete = () => {
    if (error || progress === PROGRESS_VALUE_COMPLETE) {
      props.onClose(props.id)
    }
  }

  const isComplete = progress === PROGRESS_VALUE_COMPLETE
  const fileType = file.type
  const isVideo = ALLOWED_VIDEO_FORMATS.includes(fileType)
  const isImage = ALLOWED_STATIC_PHOTO_FORMATS.includes(fileType)
  const isGif = fileType === FILE_FORMAT_GIF

  return (
    <Paper classes={{ root: styles.paper }} elevation={CARD_ELEVATION} onClick={complete}>
      <div className={styles.message}>
        {isImage && <IconImage className={styles['icon-image']} />}
        {isVideo && <IconVideo className={styles['icon-video']} />}
        {isGif && <IconGif className={styles['icon-gif']} />}
        <span className={`text-ellipsis ${styles.name}`}>{file.name}</span>
        {isComplete && !error && <IconCheck color="primary" className={styles['icon-complete']} />}
        {Boolean(error) && (
          <Tooltip
            title={<FormattedMessage id="uploads.error.upload-failed" />}
            placement="top"
          >
            <IconError className={styles['icon-error']} />
          </Tooltip>
        )}
      </div>
      <LinearProgress
        variant="determinate"
        color="primary"
        value={progress}
        classes={{ barColorPrimary: styles['loader-bar'] }}
      />
    </Paper>
  )
}

export default FileUploadSnackbar
