import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CreatableSelect from 'react-select/creatable'
import { setInstagramTags } from 'services/compose'
import styles from './InstagramMediaTagsSelect.pcss'
import { ComposerInstagramPost } from 'services/compose/interfaces'
import { BRAND_INSTAGRAM } from 'interfaces'
import { composerPostSelector } from 'services/compose/selectors'
import StoreState from 'store/state'

const colourStyles = {
  control: (styles: any) => ({
    ...styles,
    borderRadius: '8px',
    marginTop: '12px',
    border: '1px solid #ddd',
    height: '32px',
    minHeight: '32px'
  }),
  option: (styles: any) => ({ ...styles, fontSize: '13px' }),
  input: (styles: any) => ({ ...styles, width: '240px', fontSize: '13px', paddingLeft: '6px' }),
  placeholder: (styles: any) => ({ ...styles, fontSize: '14px', color: '#999', paddingLeft: '8px', marginBottom: '-4px', marginLeft: '0' }),
  singleValue: (styles: any) => ({ ...styles }),
  dropdownIndicator: () => ({ display: 'none' }),
  indicatorSeparator: () => ({ display: 'none' }),
  valueContainer: (styles: any) => ({ ...styles, marginBottom: '6px' })
}

export function InstagramMediaTagsSelect() {
  const dispatch = useDispatch()
  const post = useSelector((state: StoreState) => composerPostSelector(state, BRAND_INSTAGRAM)) as ComposerInstagramPost

  const onTagsChange = (tags: any[]) => {
    dispatch(setInstagramTags(tags.map(t => t.value)))
  }

  const onTagInputKeyDown = (e: any) => {
    // Do not allow # in user tags
    if (e.key === '#') {
      e.preventDefault()
    }
  }

  return (
    <div className={styles['tags-select']} data-testid="ig-tags-select">
      <CreatableSelect
        isMulti
        placeholder="Tag your media with other IG users (optional)"
        formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
        noOptionsMessage={() => <span style={{ fontSize: '12px' }}>Type a tag and press Enter to add it</span>}
        styles={colourStyles}
        classNames={{ control: ({ isFocused }: any) => styles[`tags-select${isFocused ? '-focus' : ''}`] }}
        defaultValue={post.tags?.map(t => ({ value: t, label: t }))}
        onChange={onTagsChange}
        onKeyDown={onTagInputKeyDown}
        formatOptionLabel={({ value }) => `@${value}`}
      />
    </div>
  )
}
