import { getState, StoreThunkDispatch } from 'store/state'
import { V1 } from './net'
import { tap } from 'rxjs/operators/tap'
import { getUserSuccess } from 'services/users/actions'
import { PPProduct } from 'shared'
import { Observable } from 'rxjs/Observable'
import { MS_IN_SEC } from 'utils/constants'
import { setProducts, formatProducts } from 'services/product'

export const ACTION_LOGIN_ERROR = 'ACTION_LOGIN_ERROR'
export const ACTION_CLEAR_ERRORS = 'ACTION_CLEAR_ERRORS'

export function login(email: string, password: string, params?: { [key: string]: string }) {
  return (dispatch: StoreThunkDispatch, getState: getState) => {
    const authError = getState().auth.error
    // Do not make request if user login is blocked
    if (authError?.retryAt && new Date(authError.retryAt).getTime() > Date.now()) {
      return Observable.throw('')
    }
    const handleError = (error: { message: string, code: string, data: any }) => {
      if (error.code === 'max-attempts-reached') {
        dispatch({
          type: ACTION_LOGIN_ERROR,
          payload: { retryAt: error.data.retryAt * MS_IN_SEC }
        })

        throw new Error()
      }

      if (error.code === 'restricted-access') {
        dispatch({
          type: ACTION_LOGIN_ERROR,
          payload: { restrictedAccess: true }
        })
        throw new Error()
      }
      throw new Error(error.message)
    }

    // Clear errors before login request
    dispatch({
      type: ACTION_CLEAR_ERRORS
    })
    return dispatch(V1.login(email, password, params))
      .map(res => ({
        ...res,
        products: res.subscriptions?.products ? formatProducts(res.subscriptions.products) : []
      }))
      .pipe(tap(
        (response) => {
          if (response.error) {
            handleError(response.error)
          }

          const products = response.products as PPProduct[]
          const product = products.find((p) => p.id === response.user.account.product) as PPProduct
          dispatch(getUserSuccess(response.user, product))
          dispatch(setProducts(products))
        },
        error => {
          handleError(error.response.error)
        }
      ))
  }
}

export function updatePassword(newPassword: string, oldPassword: string) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.updatePassword(newPassword, oldPassword)).pipe(tap(
      (response) => {
        if (response.error) {
          throw new Error(response.error.message)
        }
      }
    ))
  }
}

export function requestPassword(email: string) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.requestPassword(email))
  }
}
