const camelCase = require('camel-case')

declare type IndexedObject = { [index: string]: any }

/**
 * Deeply camelizes the object
 *
 * @param {IndexedObject} obj
 * @returns {IndexedObject}
 */
export function toCamelCase(obj: IndexedObject): IndexedObject
export function toCamelCase(obj: undefined): undefined
export function toCamelCase(obj: null): null
export function toCamelCase(obj: number): number
export function toCamelCase(obj: string): string
export function toCamelCase(obj: IndexedObject | undefined | null | number | string): IndexedObject | undefined | null | number | string {

  if (!obj || typeof obj === 'string' || typeof obj === 'number') {
    return obj
  }

  // Camelize each value
  if (Array.isArray(obj)) {
    return obj.map(toCamelCase) as IndexedObject
  }

  // Camelize the keys and call for each value
  return Object.keys(obj).reduce((result, key) => {
    const camelKey = camelCase(key)
    result[camelKey] = toCamelCase(obj[key])
    return result
  }, {} as IndexedObject)
}

export default toCamelCase
