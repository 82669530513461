import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { Observable } from 'rxjs/Observable'
import { FormattedMessage, injectIntl } from 'react-intl'
import { WithIntl } from 'interfaces'
import Tooltip from '@mui/material/Tooltip'
import numberToHuman from 'utils/format/numberToHuman'

import styles from './FavButton.pcss'

interface FavButtonProps extends WithIntl {
  isFavorite: boolean
  hover: boolean
  count?: number
  className?: string
  onFavorite: () => Observable<any>
  onUnfavorite: () => Observable<any>
}

export function FavButton(props: FavButtonProps) {
  const [saving, setSaving] = React.useState(false)
  const prettyCount = props.count ? numberToHuman(props.count) : 0

  const toggleFavorite = (e: any) => {
    e.stopPropagation()
    if (saving) {
      return
    }

    setSaving(true)
    const callback = props.isFavorite ? props.onUnfavorite : props.onFavorite
    callback().subscribe(() => {
      setSaving(false)
    })
  }

  return (
    <Tooltip title={<FormattedMessage id="content.tooltip.btn-favorite" />} placement="left">
      <IconButton
        onClick={toggleFavorite}
        // MUI classes={{ label: styles['btn-label'] }}
        className={`${styles.btn} ${props.hover ? styles.expanded : ''} ${props.className || ''}`}
        size="large"
      >
        {
          props.isFavorite
            ? <FavoriteIcon className={styles.icon} />
            : <FavoriteBorderIcon className={`${styles.icon} ${styles['icon-outline']}`} />
        }
        {prettyCount !== 0 && <span className={styles.count}>{prettyCount}</span>}
      </IconButton>
    </Tooltip>
  )
}

export default injectIntl(FavButton)
