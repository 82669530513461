import { QueryChip } from 'components/ContentPanels/components/QueryChip'
import HorizontalList from 'components/HorizontalList'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import QuotesSearch from 'routes/find/routes/quotes/components/QuotesSearch'
import { initialState, QuotesContext, quotesReducer, updateSearch } from 'routes/find/routes/quotes/QuotesContext'
import { checkFeatureAvailability } from 'services/product'
import { FEATURE_CONTENT_ON_DEMAND } from 'shared/constants'
import styles from './SearchRoot.pcss'
import { updateState } from '../state/actions'
import { SearchContext } from '../state/context'
import { FormattedMessage } from 'react-intl'
import { resetScroll } from 'utils/dom'
import { StoreThunkDispatch } from 'store/state'

export const SearchQuotesRoute = () => {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const navigate = useNavigate()
  const [{ query, quotesTags }, searchDispatch] = React.useContext(SearchContext)
  const [{ search, savedSearches }, quotesDispatch] = React.useReducer(quotesReducer, initialState)

  React.useEffect(() => {
    resetScroll()
  }, [])

  React.useEffect(() => {
    dispatch(checkFeatureAvailability(
      FEATURE_CONTENT_ON_DEMAND,
      false,
      () => navigate({ pathname: `/content/search/sources/streams?q=${query}` })
    ))
  }, [dispatch, navigate, query])

  React.useEffect(() => {
    searchDispatch(updateState({ activeFilter: 'quotes', activeCategory: 'content' }))
  }, [searchDispatch])

  React.useEffect(() => {
    if (query) {
      quotesDispatch(updateSearch({ query, page: 0, tag: undefined }))
    }
  }, [query])

  React.useEffect(() => {
    if (!query && quotesTags.length > 0) {
      quotesDispatch(updateSearch({ query: '', page: 0, tag: quotesTags[0] }))
    }
  }, [query, quotesTags])

  const onTagClick = React.useCallback((tag: string) => {
    quotesDispatch(updateSearch({ tag: search.tag === tag ? undefined : tag, page: 0 }))
  }, [search.tag])

  const onCompose = React.useCallback(() => {
    return !dispatch(checkFeatureAvailability(FEATURE_CONTENT_ON_DEMAND))
  }, [dispatch])

  return (
    <QuotesContext.Provider value={{ search, savedSearches, dispatch: quotesDispatch }}>
      <div>
        <header className={`${styles.header} ${styles['header-grid']}`}>
          <h2 className={`text-ellipsis ${styles.title}`}>
            <FormattedMessage id="search.navigation.quotes" />
          </h2>
          <NavLink to={`/content/search?q=${query}`}>
            <FormattedMessage id="general.carousel.nav.back" />
          </NavLink>
        </header>
        {quotesTags.length > 0 && (
          <HorizontalList className={styles['tags-list']} listHeight={40}>
            {quotesTags.map(tag => (
              <QueryChip
                key={tag}
                label={tag}
                isTag
                className={styles['tag-chip']}
                active={tag === search.tag}
                onClick={onTagClick}
              />
            ))}
          </HorizontalList>
        )}
        <div className={styles['quotes-container']}>
          <QuotesSearch
            hideSavedSearches
            hideSuggestions
            align="left"
            savedSearchesClassName={styles['text-chip-box']}
            onCompose={onCompose}
          />
        </div>
      </div>
    </QuotesContext.Provider>
  )
}

export default SearchQuotesRoute
