import React, { Component, createElement, ReactElement, RefObject, createRef } from 'react'
import { StatusIdea, WithIntl } from 'interfaces'
import AnyCard from 'components/Card'
import LoadingCard from 'components/Card/LoadingCard'
import CardBricks, { BricksSize } from 'components/CardBricks'
import ScrollListener from 'components/ScrollListener'
import { ScrollConfiguration, FetchableProps } from 'components/SourcesView/SourcesBricksView'
import { StatusesSearchProps } from './StatusesSearchProps'
import { injectIntl } from 'react-intl'
import styles from 'components/SourcesView/SourcesBricksView.pcss'
import { EmptyView } from 'components/EmptyView'

export interface StatusesSearchResultsProps extends WithIntl {
  items: StatusIdea[]
  search: StatusesSearchProps
  className?: string
  align?: 'left' | 'center'
  sizes?: BricksSize[]
  scroll?: ScrollConfiguration
  itemWidth?: number
  quotesView?: boolean
  onLoadMore(): void
  onCompose?(content: StatusIdea): void | boolean
}

interface StatusesSearchResultsState {
  error?: any
  fetch?: ReactElement<FetchableProps>
  errorOffset: number
}

// eslint-disable-next-line no-magic-numbers
const LOADING_CARD_SIZES = [400, 260, 320, 380, 280, 320, 200, 320, 400, 260, 320, 380, 280, 320, 200, 320]

export class StatusesSearchResults extends Component<StatusesSearchResultsProps, StatusesSearchResultsState> {

  private bricks: CardBricks
  private scrollListenerRef: RefObject<ScrollListener>
  private loadingCards: typeof LoadingCard[]

  constructor(props: StatusesSearchResultsProps) {
    super(props)
    this.loadingCards = []

    this.state = {
      error: undefined,
      fetch: undefined,
      errorOffset: 0
    }

    this.scrollListenerRef = createRef()
  }

  renderBricksContent = () => {
    const { itemWidth, search } = this.props
    const loading = search.loading

    const items = this.props.items
    const results: any[] = []

    if (items.length !== 0) {
      const itemCards = items.map(item => createElement(AnyCard, {
        key: item.id,
        children: item,
        content: item,
        width: this.props.itemWidth,
        square: true,
        onCompose: this.props.onCompose
      }))
      if (!loading) {
        return itemCards
      }

      results.push(...itemCards)
    }

    const width = itemWidth

    // render error or loading cards
    return results.concat(LOADING_CARD_SIZES.map((_height, index) => {
      return (
        <LoadingCard
          key={`error-card-${index}`}
          ref={(ref: any) => { this.loadingCards[index] = ref }}
          square
          active={loading}
          width={width}
        />
      )
    }))
  }

  onBricksPacked = () => {
    if (this.scrollListenerRef.current) {
      this.scrollListenerRef.current.emitScroll()
    }

    this.updateErrorOffset()
  }

  updateErrorOffset = () => {
    if (!this.props.search.loading) {
      const tops = this.loadingCards
        .map((node: any) => (node ? node.parentElement : null))
        .filter(Boolean)
        .reduce((results, node: HTMLElement) => {
          const column = node.getAttribute('data-column') as string
          const top = node.getAttribute('data-top') as string
          if (typeof results[column] === 'undefined' || results[column] > +top) {
            results[column] = +top
          }
          return results
        }, {} as { [key: string]: number })

      const offset = Math.max(...Object.keys(tops).map(column => tops[column]))
      if (offset !== this.state.errorOffset) {
        this.setState((pstate) => ({ ...pstate, errorOffset: offset }))
      }

    }
  }

  renderError = () => {
    if (this.props.search.loading || this.props.items.length > 0) {
      return null
    }

    const error = this.props.intl.formatMessage({
      id: this.props.quotesView ? 'content.msg.quotes-search-empty' : 'content.msg.status-ideas-search-empty'
    })
    const subtitle = this.props.intl.formatMessage({ id: 'content.msg.status-ideas-search-empty-subtitle' })

    return createElement(EmptyView, {
      key: 'error',
      title: error,
      subtitle,
      contained: true,
      top: '300px'
    })
  }

  onScroll = () => {
    if (this.props.search.loading || !this.props.search.hasNextPage) {
      return
    }

    this.props.onLoadMore()
  }

  render() {
    const className = `${styles.view} ${this.props.className || ''}`
    return createElement('div', { className }, [

      createElement(ScrollListener, {
        key: 'scroller',
        ref: this.scrollListenerRef,
        className: styles.bricks,
        emitTreshold: 250,
        emitInitial: false,
        onScroll: this.onScroll,
        useWindow: (this.props.scroll && this.props.scroll.useWindow),
        scrollElement: (this.props.scroll && this.props.scroll.element) || document.getElementsByTagName('main')[0]
      }, createElement(CardBricks, {
        key: 'bricks',
        ref: (ref: CardBricks) => { this.bricks = ref },
        onPacked: this.onBricksPacked,
        sizes: this.props.sizes as BricksSize[],
        align: this.props.align
      }, this.renderBricksContent())
      ),

      this.renderError()
    ])
  }

  componentDidUpdate(prevProps: StatusesSearchResultsProps) {
    if (prevProps.search.loading !== this.props.search.loading
      || prevProps.items.length !== this.props.items.length
      || prevProps.search.query !== this.props.search.query) {

      this.bricks.onItemChanged(undefined)
      this.updateErrorOffset()
    }
  }
}

export default injectIntl(StatusesSearchResults)
