import * as React from 'react'
import { GOOGLE_TAG_MANAGER_ID } from 'config'

/* eslint-disable */
function injectScript() {
  return (function (w: any, d: any, s: any, l: any, i: any) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', GOOGLE_TAG_MANAGER_ID)
}
/* eslint-enable */

export function GoogleTagManager() {
  const [initialized, setInitialized] = React.useState(false)

  React.useEffect(() => {
    if (!initialized) {
      injectScript()
      setInitialized(true)
    }
  }, [initialized])

  return null
}

export default GoogleTagManager
