import { createElement, Component, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'
import StreamView from 'components/StreamView'
import FetchStream from 'components/Fetch/FetchStream'
import Loader from 'components/SimpleLoader'
import ErrorOverlay, { AJAX_ERROR_STREAM_NOT_FOUND, AJAX_ERROR_STREAM_NOT_FOUND_SUBTITLE } from 'components/ErrorOverlay'
import Fab from '@mui/material/Fab'
import RefreshIcon from '@mui/icons-material/Refresh'
import Tooltip from '@mui/material/Tooltip'
import { StoreThunkDispatch } from 'store/state'
import { streamsSelector, myProfilesStreamSelector, userStreamsSelector } from 'services/content/selectors'
import { Stream, WithIntl } from 'interfaces'
import { injectIntl } from 'react-intl'
import { refreshMyProfiles } from 'services/destinations'
import { Observable } from 'rxjs/Observable'
import styles from './SingleStream.pcss'

export interface SingleStreamParams {
  stream: string
}

interface ConnectedSingleStreamProps extends WithIntl {
  id: string
  stream: Stream | undefined
  isUserStream: boolean
  isMyProfilesStream: boolean
  refreshProfiles: () => Observable<any>
}

interface SingleStreamState {
  loading: boolean
  refreshingProfiles: boolean
  error?: any
}

const CARD_WIDTH = 264

export class SingleStream extends Component<ConnectedSingleStreamProps, SingleStreamState> {

  constructor(props: ConnectedSingleStreamProps) {
    super(props)

    this.onFetched = this.onFetched.bind(this)
    this.onFailed = this.onFailed.bind(this)
    this.onFeedItemShared = this.onFeedItemShared.bind(this)

    this.state = {
      loading: true,
      error: undefined,
      refreshingProfiles: false
    }
  }

  forceStreamReload = () => {
    this.setState(prevState => ({ ...prevState, loading: true }))
  }

  shouldComponentUpdate(nextProps: ConnectedSingleStreamProps, nextState: SingleStreamState) {
    return this.props.id !== nextProps.id
      || this.props.stream !== nextProps.stream
      || this.state.loading !== nextState.loading
      || this.state.error !== nextState.error
  }

  onFeedItemShared() {
    return false
  }

  onFetched() {
    this.setState((previousState) => ({ ...previousState, loading: false }))
  }

  onFailed(error: any) {
    this.setState((previousState) => ({ ...previousState, loading: false, error }))
  }

  onContentItemShared = () => {
    return false
  }

  renderFetch = () => {
    const { id, stream } = this.props
    return createElement(FetchStream, { key: 'fetch', id, onFetched: this.onFetched, onFailed: this.onFailed },
      stream ? null : createElement(Loader, { title: 'Loading Stream...' })
    )
  }

  refreshProfiles = () => {
    this.setState(prevState => ({ ...prevState, refreshingProfiles: true }))
    this.props.refreshProfiles()
      .subscribe(() => {
        this.forceStreamReload()
        this.setState(prevState => ({ ...prevState, refreshingProfiles: false }))
      }, () => {
        this.setState(prevState => ({ ...prevState, refreshingProfiles: false }))
      })
  }

  renderRefreshProfilesButton = () => {
    if (!this.props.isMyProfilesStream) {
      return undefined
    }

    const loading = this.state.refreshingProfiles

    return createElement(Tooltip, { title: 'Refresh my profiles', placement: 'top', key: 'btn-refresh' } as any,
      createElement(Fab, {
        disabled: loading,
        color: 'primary',
        'aria-label': 'refresh',
        className: styles['btn-refresh'],
        onClick: this.refreshProfiles
      },
      createElement(RefreshIcon)
      )
    )
  }

  renderContent(error: any, stream: Stream) {
    if (error || !stream) {
      return createElement(ErrorOverlay, {
        key: 'error',
        error: AJAX_ERROR_STREAM_NOT_FOUND(this.props.intl.formatMessage),
        subtitle: AJAX_ERROR_STREAM_NOT_FOUND_SUBTITLE(this.props.intl.formatMessage),
        originalError: error
      })
    }

    if (!this.props.isUserStream) {
      return createElement(Navigate, { key: 'root-redirect', replace: true, to: `/content/streams/${this.props.id}` })
    }

    return createElement('div', { key: 'stream', 'data-test': 'single-stream', className: styles.container }, [
      createElement(StreamView, {
        key: 'view',
        stream,
        navigation: {
          prefix: `/content/my-streams/${stream.id}`,
          id: stream.id
        },
        itemWidth: CARD_WIDTH,
        headerButton: this.renderRefreshProfilesButton(),
        onFeedItemShared: this.onFeedItemShared,
        onStreamItemShared: this.onContentItemShared
      })
    ])
  }

  render() {
    const { stream } = this.props
    const { loading, error } = this.state

    return createElement(Fragment, undefined, [
      loading ? this.renderFetch() : null,
      stream ? this.renderContent(error, stream as Stream) : null,
      !loading && !stream && createElement(Navigate, { key: 'not-found', replace: true, to: '/content/my-streams' })
    ])
  }
}

const SingleStreamWithRouter = injectIntl((props: WithIntl) => {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const params = useParams()
  const id = params.stream as string
  const streams = useSelector(streamsSelector)
  const userStreams = useSelector(userStreamsSelector)
  const userStream = userStreams.find(s => s.id === id)
  const stream = userStream || streams[id]

  const myProfilesStream = useSelector(myProfilesStreamSelector)
  const isMyProfilesStream = Boolean(id) && myProfilesStream?.id === id

  const onRefresh = () => {
    return Observable.fromPromise(dispatch(refreshMyProfiles()).unwrap())
  }

  return (
    createElement(SingleStream, {
      id,
      stream,
      isUserStream: Boolean(userStream),
      isMyProfilesStream,
      intl: props.intl,
      refreshProfiles: onRefresh
    })
  )
})

export default SingleStreamWithRouter
