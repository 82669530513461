/* eslint-disable max-len */
import {
  ARTICLE_TYPE,
  BRAND_FACEBOOK,
  BRAND_LINKEDIN,
  BRAND_TWITTER,
  FEED_TYPE_KEYWORD,
  GIF_TYPE,
  PHOTO_TYPE,
  PostDestinationType,
  SocialProfileTag,
  TagInfo,
  VIDEO_TYPE
} from 'shared'
import { Article, ContentItem, Photo, Video } from 'interfaces'
import { INPUT_ACCEPT_VIDEO } from 'utils/file/constants'
import twttr from 'twitter-text'
import { BulkContentPost } from 'services/compose/state'
import { unique } from 'utils/unique'
import { MAX_TWEET_LENGTH } from 'shared/constants'

export function getTwitterRemainingChars(text: string): number {
  const tweetLength = twttr.parseTweet(text).weightedLength
  return MAX_TWEET_LENGTH - tweetLength
}

export function extractUrlsFromText(text: string): string[] {
  return twttr.extractUrls(text)
}

export const BULK_UPLOAD_ERROR_MESSAGE = 'Some posts were not created.'
export const BULK_UPLOAD_ERROR_MESSAGE_TIMEOUT = 10000

const closeComposerDelayTime = 500
export const closeComposerDelay = () => new Promise((resolve) => setTimeout(resolve, closeComposerDelayTime))
export const ERROR_MESSAGE_DISPLAY_TIME = 3500

export function isVideoUrl(url: string) {
  const urlArray = url.split('?')[0].split('.')
  const type = urlArray[urlArray.length - 1]
  return INPUT_ACCEPT_VIDEO.includes(`.${type.toLowerCase()}`) || url.includes('licdn')
}

function createHiddenTextContainer() {
  const container = document.createElement('div')
  // NOTE: To get the innerText of the element, it should not be hidden in any way:
  // display: none, visibility: hidden, height: 0 will not work here.
  // Move the element off screen to be able to properly extract the text
  container.style.whiteSpace = 'pre-wrap' // preserves line breaks
  container.style.position = 'fixed'
  container.style.left = '0'
  container.style.bottom = '0'
  container.style.transform = 'translateX(-110%)'
  return container
}

// Extracts text from html preserving correct number of new lines
export function getTextFromHtml(html: string): string {
  const container = createHiddenTextContainer()
  container.innerHTML = html
  document.body.appendChild(container)

  let text
  const selection = document.getSelection()
  // Cache current selection to be restored later
  const currentRange = selection && selection.rangeCount > 0 ? selection?.getRangeAt(0).cloneRange() : null
  if (selection) {
    const range = new Range()
    range.selectNodeContents(container)
    selection.empty()
    selection.addRange(range)
    text = selection.toString().replace(/\r\n/g, '\n') // Firefox returns \r\n for newlines, replace with \n
  }
  // Restore selection range
  selection?.removeAllRanges()
  if (currentRange) {
    selection?.addRange(currentRange)
  }
  text = text || container.innerText
  container.remove()
  return text
}

export function getFBPostTextFromHtml(html: string) {
  const container = createHiddenTextContainer()
  container.innerHTML = html
  container.querySelectorAll('[data-tag-id]').forEach((tag: HTMLElement) => {
    tag.replaceWith(document.createTextNode(`@[${tag.dataset.tagId}]`))
  })
  document.body.appendChild(container)
  const text = getTextFromHtml(container.innerHTML)
  container.remove()
  return text
}

function createTagNode(tagInfo: SocialProfileTag, network: PostDestinationType, className?: string) {
  const newTagElement = document.createElement('a')
  newTagElement.contentEditable = 'false'
  newTagElement.href = tagInfo.url
  newTagElement.target = '_blank'
  newTagElement.rel = 'noopener'
  newTagElement.dataset.tagId = tagInfo.pageId
  newTagElement.dataset.tagUrl = tagInfo.url
  newTagElement.dataset.tagName = tagInfo.name
  newTagElement.dataset.tagHandle = tagInfo.handle
  newTagElement.dataset.tagImage = tagInfo.imageUrl
  newTagElement.className = className || ''
  newTagElement.innerText = network === BRAND_FACEBOOK ? tagInfo.name : tagInfo.handle

  return newTagElement
}

export function getTagsInfoFromHtml(html: string): TagInfo[] {
  const tags: TagInfo[] = []
  const container = document.createElement('div')
  container.innerHTML = html
  container.querySelectorAll('[data-tag-id]').forEach((element: HTMLElement) => {
    const data = element.dataset
    tags.push({
      pageId: data.tagId,
      name: data.tagName,
      handle: data.tagHandle,
      url: data.tagUrl,
      imageUrl: data.tagImage
    } as TagInfo)
  })
  return tags
}

function escapeLiText(text: string, reverse?: boolean) {
  let reservedSymbols = ['\\', '~', '@', '{', '}', '[', ']', '(', ')', '<', '>', '#', '*', '_', '|']
  let replacementSymbols = ['\\\\', '-', '\\@', '\\{', '\\}', '\\[', '\\]', '\\(', '\\)', '\\<', '\\>', '\\#', '\\*', '\\_', '\\|']
  if (reverse) {
    [reservedSymbols, replacementSymbols] = [replacementSymbols, reservedSymbols]
  }

  for (let index = 0; index < reservedSymbols.length; index++) {
    const symbol = reservedSymbols[index]
    text = text.replaceAll(symbol, replacementSymbols[index])
  }

  return text
}

/**
 * Checks if an element represents an empty line
 * Returns true if each element in the tree has exactly 1 element and the inner-most element is a BR
 *
 * @param {(Element | ChildNode)} element
 * @return {*}  {boolean}
 */
function isEmptyLineElement(element: Element | ChildNode): boolean {
  if (element.childNodes.length === 1) {
    return isEmptyLineElement(element.childNodes[0])
  }
  return element.nodeName === 'BR'
}

function getNodeTextAndTags(node: ChildNode, data: { text: string, tags: TagInfo[] }) {
  if (node.nodeName === 'BR') {
    data.text += '\n'
    return data
  }

  if (node.nodeName === '#text') {
    if (node.parentElement?.dataset.tagId) {
      const tag = node.parentElement?.dataset
      data.text += `@[${escapeLiText(tag.tagName as string)}](${tag.tagId})`
      if (tag?.tagId) {
        data.tags.push({
          pageId: tag.tagId,
          name: tag.tagName,
          handle: tag.tagHandle,
          url: tag.tagUrl,
          imageUrl: tag.tagImage
        } as TagInfo)
      }
    } else {
      data.text += escapeLiText(node.textContent as string)
    }
    return data
  }

  const isEmptyLineDiv = isEmptyLineElement(node)
  const prevSibling = node.previousSibling
  // NOTE: Fix issue with double new lines in pasted text
  const skipNewLine = (prevSibling as HTMLElement)?.dataset?.external === 'true'
  if (node.nodeName === 'DIV' && data.text.trim() !== '' && !isEmptyLineDiv && !skipNewLine) {
    data.text += '\n'
  }

  if (node.hasChildNodes()) {
    for (const childNode of node.childNodes) {
      getNodeTextAndTags(childNode, data)
    }
  }

  return data
}

export function getLinkedinFormattedText(html: string): { text: string, tags: TagInfo[] } {
  const container = document.createElement('div')
  container.innerHTML = html
  const data = { text: '', tags: [] }
  getNodeTextAndTags(container, data)

  /* eslint-disable no-magic-numbers */
  const nbsp = String.fromCharCode(160)
  const space = String.fromCharCode(32)
  /* eslint-enable no-magic-numbers */
  const re = new RegExp(nbsp, 'g')

  return {
    tags: data.tags,
    text: data.text.replace(re, space)
  }
}

export function buildHTMLWithTags(
  text: string,
  tags: SocialProfileTag[],
  network: PostDestinationType,
  tagClassName?: string,
  linkedInFormattedText?: string
) {
  let htmlString = text
  switch (network) {
    case BRAND_FACEBOOK:
      tags.forEach(tag => {
        const tagText = `@[${tag.pageId}]`
        const tagElement = createTagNode(tag, network, tagClassName)
        htmlString = htmlString.replaceAll(tagText, tagElement.outerHTML)
      })
      break

    case BRAND_TWITTER:
      unique(tags, 'handle').forEach(tag => {
        const tagElement = createTagNode(tag, network, tagClassName)
        htmlString = htmlString.replaceAll(tag.handle, tagElement.outerHTML)
      })
      break

    case BRAND_LINKEDIN:
      if (linkedInFormattedText) {
        htmlString = linkedInFormattedText
        tags.forEach(tag => {
          const tagElement = createTagNode(tag, network, tagClassName)
          htmlString = htmlString.replaceAll(`@[${escapeLiText(tag.name)}](${tag.pageId})`, tagElement.outerHTML)
        })
        const container = createHiddenTextContainer()
        container.innerHTML = htmlString
        container.childNodes.forEach((node: Node) => {
          const nodeText = node.textContent
          if (node.nodeName === '#text' && nodeText) {
            node.textContent = escapeLiText(nodeText, true)
          }
        })
        htmlString = container.innerHTML
        container.remove()
        break
      }
      tags.forEach(tag => {
        const tagElement = createTagNode(tag, network, tagClassName)
        htmlString = htmlString.replaceAll(tag.name, tagElement.outerHTML)
      })
      break

    default:
      htmlString = text
  }

  return htmlString
}

export function getTwitterStatusFromContent(content: ContentItem) {
  switch (content.type) {
    case ARTICLE_TYPE:
      return `${(content as Article).title || ''} ${(content as Article).sourceLink}`
    case PHOTO_TYPE:
      return `📸 ${content.feed.name} \n${(content as Photo).shortLink || content.socialLink} `
    case VIDEO_TYPE:
      return content.socialLink
    default:
      return ''
  }
}

export function getPinterestStatusFromContent(content: ContentItem) {
  switch (content.type) {
    case ARTICLE_TYPE:
      return {
        title: (content as any).title || '',
        description: (content as Article).description || '',
        destinationUrl: (content as Article).sourceLink || (content as Article).shortLink || ''
      }
    case VIDEO_TYPE:
      return {
        title: (content as any).title || '',
        description: (content as Video).description || '',
        destinationUrl: (content as Video).videoUrl || (content as Video).socialLink || ''
      }
    case PHOTO_TYPE:
    case GIF_TYPE:
      return {
        title: '',
        description: '',
        destinationUrl: (content as Photo).socialLink
      }
    default:
      return {
        title: '',
        description: '',
        destinationUrl: ''
      }
  }
}

export function removeTagsFromHtml(html: string) {
  const parent = document.createElement('div')
  parent.innerHTML = html
  document.body.appendChild(parent)
  parent.querySelectorAll('[data-tag-id], [data-tag-active]').forEach(node => {
    const contents = node.innerHTML
    node.replaceWith(document.createTextNode(contents))
  })
  const result = parent.innerHTML
  parent.remove()

  return result
}

export function generateBulkPostCaptionFromHtml(html: string, post: BulkContentPost): { html: string, text: string } {
  const parent = createHiddenTextContainer()
  parent.innerHTML = html

  parent.querySelectorAll('[data-code]').forEach((placeholder: HTMLElement) => {
    const attribute = placeholder.dataset?.code
    switch (attribute) {
      case 'title': {
        const title = post.article?.title || (post.content as Article)?.title
        placeholder.replaceWith(document.createTextNode(title || ''))
        break
      }
      case 'description': {
        const description = post.article?.description || (post.content as Article)?.description
        placeholder.replaceWith(document.createTextNode(description || ''))
        break
      }
      case 'link': {
        const articleUrl = post.article?.url || (post.content as Article)?.sourceLink
        placeholder.replaceWith(document.createTextNode(articleUrl || ''))
        break
      }
      case 'caption': {
        const originalCaption = (post.content as Article)?.status
        placeholder.replaceWith(document.createTextNode(originalCaption || ''))
        break
      }
      case 'credit': {
        const content = post.content as Article
        const credits = content && content.feed.type !== FEED_TYPE_KEYWORD
          ? `📸 ${content.feed.name} \n${content.shortLink || content.socialLink || content.sourceLink} `
          : null
        placeholder.replaceWith(document.createTextNode(credits || ''))
        break
      }
      default:
        console.log(`Invalid attribute placeholder: ${attribute}`)
    }
  })

  return { html: parent.innerHTML, text: parent.innerText }
}

export function getTwitterHtmlFromText(text: string) {
  if (!text.includes('@')) {
    return text
  }
  let html = ''
  const parts = text.split(' ')
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i]
    if (part.startsWith('@')) {
      html += `<a href="https://twitter.com/${part.slice(1)}" data-tag="true" target="_blank">${part}</a> `
    } else {
      html += `${part} `
    }
  }
  return html
}
