import * as React from 'react'
import { connect } from 'react-redux'
import RecentlyViewedCarousel from './RecentlyViewedCarousel'
import StoreState, { StoreThunkDispatch } from 'store/state'
import { recentSourcesSelector } from 'services/content/selectors'
import { getRecentSources, addRecentSource, removeRecentlyViewed } from 'services/content/recommended/actions'
import { Subscription } from 'rxjs/Subscription'
import { Feed, Stream, FileFolder, InteractionTrackingSourceType, InteractionTrackingSource } from 'interfaces'
import { Observable } from 'rxjs/Observable'
import useAsyncAction from 'hooks/useAsyncAction'
import { QuoteFeed } from 'interfaces/Content/QuoteFeed'
import { useLocation } from 'react-router-dom'

interface RecentlyViewedCarouselContainerOwnProps {
  navPrefix?: string
  title?: string
  onRecentSourcesFetched?: () => void
}

interface RecentlyViewedCarouselContainerConnectedProps {
  sources: Array<Feed | Stream | FileFolder>
  fetchRecentSources: (page: number) => Observable<any[]>
  recordRecentSource: (source: Feed | Stream | FileFolder) => void
  removeSource: (type: InteractionTrackingSourceType, source: InteractionTrackingSource) => Observable<any>
}

export type RecentlyViewedCarouselContainerProps = RecentlyViewedCarouselContainerOwnProps
  & RecentlyViewedCarouselContainerConnectedProps

function RecentlyViewedCarouselContainer(props: RecentlyViewedCarouselContainerProps) {
  const location = useLocation()
  const [triggerSourceRemove] = useAsyncAction(props.removeSource)
  const [page, setPage] = React.useState(1)
  const [hasNextPage, setHasNextPage] = React.useState(true)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    let subscription: Subscription
    if (!loading && hasNextPage) {
      setLoading(true)
      subscription = props.fetchRecentSources(page).subscribe((sources: any[]) => {
        setLoading(false)
        if (page === 1 && props.onRecentSourcesFetched) {
          props.onRecentSourcesFetched()
        }

        if (sources.length === 0) {
          setHasNextPage(false)
        }
      }, () => {
        if (page === 1 && props.onRecentSourcesFetched) {
          props.onRecentSourcesFetched()
        }
      })
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe()
      }
    }
  }, [page])

  const fetchNextPage = () => {
    if (!loading) {
      setPage(page => page + 1)
    }
  }

  const onSourceClick = (_type: 'stream' | 'feed' | 'file-folder' | 'quote_feed', source: Feed | Stream | FileFolder) => {
    props.recordRecentSource(source)
  }

  const removeSource = (type: InteractionTrackingSourceType, source: InteractionTrackingSource) => {

    triggerSourceRemove(type, source)
  }

  if (!loading && props.sources.length === 0) {
    return null
  }

  return (
    <RecentlyViewedCarousel
      title={props.title}
      sources={props.sources}
      loading={loading}
      location={location.pathname}
      navPrefix={props.navPrefix}
      onSourceClick={onSourceClick}
      onRemoveSource={removeSource}
      onScrollLimit={fetchNextPage}
    />
  )
}

function mapStateToProps(state: StoreState) {
  return {
    sources: recentSourcesSelector(state) as any[]
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    fetchRecentSources: (page: number) => Observable.fromPromise(dispatch(getRecentSources(page)).unwrap().then(res => res.items)),
    recordRecentSource: (source: Feed | Stream | FileFolder | QuoteFeed) => dispatch(addRecentSource(source)),
    removeSource: (type: InteractionTrackingSourceType, source: InteractionTrackingSource) => {
      return Observable.fromPromise(dispatch(removeRecentlyViewed({ type, source })).unwrap())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentlyViewedCarouselContainer)
