import { V1 } from './net'
import {
  ContentType,
  RangeFilter,
  PHOTO_TYPE,
  ARTICLE_TYPE,
  VIDEO_TYPE,
  GIF_TYPE,
  ContentItem,
  Article,
  Gif,
  Text,
  Photo,
  Video,
  FilterType,
  StatusIdea,
  STATUS_TYPE,
  LegacyStatusIdea
} from 'interfaces'
import { TEXT_TYPE } from 'interfaces/Content/ContentType'
import { photosAdapter } from '../adapters/photos'
import { articlesAdapter } from '../adapters/articles'
import { videosAdapter } from '../adapters/videos'
import { gifsAdapter } from '../adapters/gifs'
import { quoteToStatusIdea, statusIdeaAdapter } from 'adapters'
import { HS_EVENT_CONTENT_FAVORITED, trackHubspotEvent } from 'services/tracking/hubspot'
import { textsAdapter } from '../adapters/texts'
import { ActionReducerMapBuilder, PayloadAction, createAction, createAsyncThunk } from '@reduxjs/toolkit'
import ContentState from '../state'

export const addFavoriteImmediate = createAction<ContentItem>('content/favorites/addFavoriteImmediate')
export const removeFavoriteImmediate = createAction<{ id: string, type: ContentType }>('content/favorites/removeFavoriteImmediate')
export const undoRemoveFavorite = createAction<{ id: string, type: ContentType }>('content/favorites/undoRemoveFavorite')
export const getFavoritesSuccess = createAction<{ type: ContentType, items: string[] }>('content/favorites/getFavoritesSuccess')

export const favsExtraReducers = (builder: ActionReducerMapBuilder<ContentState>) => {
  builder
    .addCase(getFavorites.pending, state => {
      state.favorites.isFetching = true
    })
    .addCase(getFavorites.rejected, state => {
      state.favorites.isFetching = false
    })
    .addCase(addFavoriteImmediate, (state, action) => {
      const { type, rawId } = action.payload
      delete state.favorites.removed[type][rawId]
      state.favorites.added[type][rawId] = action.payload
    })
    .addCase(removeFavoriteImmediate, (state, action) => {
      const { id, type } = action.payload
      state.favorites.removed[type][id] = true
      delete state.favorites.added[type][id]
    })
    .addCase(undoRemoveFavorite, (state, action) => {
      const { id, type } = action.payload
      delete state.favorites.removed[type][id]
    })
    .addCase(getFavoritesSuccess, (state, action) => {
      const { type, items } = action.payload
      items.forEach(id => {
        state.favorites.saved[type][id] = true
      })
      state.favorites.isFetching = false
    })
}

export const addFavorite = createAsyncThunk(
  'content/favorites/addFavorite',
  async (item: ContentItem, { dispatch }) => {
    dispatch(addFavoriteImmediate(item))
    try {
      await dispatch(V1.addFavorite(item.rawId, item.type)).toPromise()
      dispatch(trackHubspotEvent(HS_EVENT_CONTENT_FAVORITED))
    } catch (_e) {
      dispatch(removeFavoriteImmediate({ id: item.rawId, type: item.type }))
    }
  }
)

export const removeFavorite = createAsyncThunk(
  'content/favorites/removeFavorite',
  async (args: { item: ContentItem, live?: boolean }, { dispatch }) => {
    const { item, live } = args
    dispatch(removeFavoriteImmediate({ id: item.rawId, type: item.type }))
    try {
      if (live) {
        await dispatch(V1.removeFavoriteLive(item.rawId, item.type, item.feed.id)).toPromise()
      } else {
        await dispatch(V1.removeFavorite(item.rawId, item.type)).toPromise()
      }
    } catch (_e) {
      dispatch(undoRemoveFavorite({ id: item.rawId, type: item.type }))
    }
  }
)

export const getFavorites = createAsyncThunk(
  'content/favorites/getFavorites',
  async (args: { page: number, type: FilterType, range: RangeFilter }, { dispatch, rejectWithValue }) => {
    const { page, type, range } = args
    try {
      const response = await dispatch(V1.getFavorites(page, type, range)).toPromise()
      let data: any = null
      switch (type) {
        case PHOTO_TYPE:
          data = photosAdapter(response)
          break
        case ARTICLE_TYPE:
          data = articlesAdapter(response)
          break
        case VIDEO_TYPE:
          data = videosAdapter(response)
          break
        case GIF_TYPE:
          data = gifsAdapter(response)
          break
        case TEXT_TYPE:
          data = textsAdapter(response)
          break
        default:
          throw new Error(`[getFavorites()] Error: Invalid type: ${type}`)
      }
      const ids = (data.items as any[]).map(i => i.rawId)
      dispatch(getFavoritesSuccess({ type, items: ids }))
      return data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const addFavoriteLive = createAsyncThunk(
  'content/favorites/addFavoriteLive',
  async (item: Photo | Article | Video | Gif | Text, { dispatch }) => {
    const date = typeof item.createdAt === 'object' ? item.createdAt.toISOString() : new Date(item.createdAt).toISOString()
    const data = {
      content_item_type: item.type,
      feed_search_id: item.feed.id,
      source_id: item.feed.id,
      search_id: item.rawId,
      likes: item.likes,
      status: item.status,
      shares: item.shares,
      comments: item.comments,
      published_date: date,
      link: (item as Article).sourceLink || item.socialLink,
      title: (item as Article).title,
      image: item.imageUrl,
      feed_type: item.feed.type,
      details: '{}',
      object_id: item.rawId
    }

    dispatch(addFavoriteImmediate(item))
    try {
      await dispatch(V1.addFavoriteLive(data)).toPromise()
    } catch (_e) {
      dispatch(removeFavoriteImmediate({ id: item.rawId, type: item.type }))
    }
  }
)

export const getFavoriteStatusIdeas = createAsyncThunk(
  'content/favorites/getFavoriteStatusIdeas',
  async (page: number, { dispatch }) => {
    const response = await Promise.all([
      dispatch(V1.getFavoriteQuotes(page)).toPromise(),
      dispatch(V1.getFavoriteStatuses(page)).toPromise()
    ])
    const quotes = response[0].map((q: any, index: number) => ({ ...quoteToStatusIdea(q, index), isFavorite: true }))
    const statuses = response[1].map((s: LegacyStatusIdea) => ({ ...statusIdeaAdapter(s), isFavorite: true }))
    const all = quotes.concat(statuses)

    dispatch(getFavoritesSuccess({ type: STATUS_TYPE as any, items: all.map((item: any) => item.id) }))
    return all
  }
)

export const addFavoriteStatusIdea = createAsyncThunk(
  'content/favorites/addFavoriteStatusIdea',
  async (item: StatusIdea, { dispatch }) => {
    dispatch(addFavoriteImmediate({ ...item, rawId: item.id } as any))
    const itemType = item.isQuote ? 'quote' : 'si'
    try {
      await dispatch(V1.addFavoriteStatusIdea(item.id, itemType)).toPromise()
    } catch (_e) {
      dispatch(removeFavoriteImmediate({ id: item.id, type: item.type }))
    }
  }
)

export const removeFavoriteStatusIdea = createAsyncThunk(
  'content/favorites/removeFavoriteStatusIdea',
  async (item: StatusIdea, { dispatch }) => {
    dispatch(removeFavoriteImmediate({ id: item.id, type: item.type }))
    const itemType = item.isQuote ? 'quote' : 'si'
    try {
      await dispatch(V1.removeFavoriteStatusIdea(item.id, itemType)).toPromise()
    } catch (_e) {
      dispatch(undoRemoveFavorite({ id: item.id, type: item.type }))
    }
  }
)
