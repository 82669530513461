import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Feed } from 'interfaces'
import ContentState from '../state'
import { addFeeds, deleteFeed, getFeed, getOriginalFeed } from './actions'

export const feedsExtraReducers = (builder: ActionReducerMapBuilder<ContentState>) => {
  builder
    .addCase(addFeeds.pending, (state, action) => {
      const { stream, feeds } = action.meta.arg
      const streamId = stream.id
      const userStream = state.user.streams.find(s => s.id === streamId)
      if (userStream) {
        const updatedStreamFeeds = [
          ...userStream.feeds,
          ...feeds.map((f, index) => ({
            ...f,
            order: userStream.feedIds.length + index
          }))
        ]
        userStream.feeds = updatedStreamFeeds
        userStream.feedIds = updatedStreamFeeds.map(f => f.id)
        userStream.feedsCount = updatedStreamFeeds.reduce((result: any, feed: Feed) => {
          if (result[feed.type]) {
            result[feed.type] += 1
          } else {
            result[feed.type] = 1
          }
          result.total += 1
          return result
        }, { total: 0 })
      }
    })
    .addCase(deleteFeed.pending, (state, action) => {
      const { feedId } = action.meta.arg
      const feedIndex = state.user.feeds.indexOf(feedId)
      if (feedIndex !== -1) {
        state.user.feeds.splice(feedIndex, 1)
      }

      state.user.streams.forEach(stream => {
        const index = stream.feedIds.indexOf(feedId)
        const feedIndex = stream.feeds.findIndex(f => f.id === feedId)
        if (index !== -1) {
          stream.feedIds.splice(index, 1)
          const removedFeed = stream.feeds.splice(feedIndex, 1)[0]
          stream.feedsCount = {
            ...stream.feedsCount,
            [removedFeed.type]: stream.feedsCount && stream.feedsCount[removedFeed.type]
              ? stream.feedsCount[removedFeed.type] - 1
              : 0,
            total: stream.feedsCount?.total ? stream.feedsCount.total - 1 : 0
          }
        }
      })
    })
    .addCase(getFeed.fulfilled, (state, action) => {
      state.feeds[action.payload.id] = action.payload
    })
    .addCase(getOriginalFeed.fulfilled, (state, action) => {
      state.feeds[action.payload.id] = action.payload
    })
}
