import { FilterType } from 'interfaces'

import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/empty'
import 'rxjs/add/observable/throw'
import 'rxjs/add/observable/of'

export class FetchError extends Error {

  public readonly type: FilterType
  public readonly source?: Error

  constructor(type: FilterType, source?: Error) {
    super(`No content for: ${type}`)
    this.type = type
    this.source = source

    Object.setPrototypeOf(this, FetchError.prototype)
  }
}

export class EmptyFetchError extends FetchError {
  constructor(type: FilterType) {
    super(type)

    Object.setPrototypeOf(this, EmptyFetchError.prototype)
  }
}

export function createErrorOnEmpty(type: FilterType) {
  return new EmptyFetchError(type)
}

export function createErrorOnEmptyPipe(piped: (err: any) => void) {
  return (errOrContent: any) => {
    if (errOrContent instanceof EmptyFetchError) {
      piped(errOrContent)
      return Observable.empty()
    }

    return Observable.of(errOrContent)
  }
}

export function createTypedErrorPipe(type: FilterType, piped: (err: any) => void) {
  return (error: any) => {
    piped(new FetchError(type, error))

    return Observable.empty()
  }
}

export function createHandledPipe<T>(type: FilterType, observable: Observable<T>, piped: (err: any) => void): Observable<any> {
  return observable
    .defaultIfEmpty(createErrorOnEmpty(type))
    .flatMap(createErrorOnEmptyPipe(piped))
    .catch(createTypedErrorPipe(type, piped))
}
export default EmptyFetchError
