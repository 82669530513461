import { PureComponent, createElement } from 'react'
import { Stream, FilterType } from 'interfaces'
import CuratedFolderViewNavigation from './CuratedFolderViewNavigation'
import styles from './CuratedFolderViewHeader.pcss'

export type CuratedFolderHeaderProps = {
  stream: Stream,
  navigation: CuratedFolderViewNavigation,
  expandedView?: boolean
  onFilterChange?(filter: FilterType): void
  onBackClick?(): void
}

export class CuratedFolderViewHeader extends PureComponent<CuratedFolderHeaderProps, any> {
  onFilterChange = (filter: FilterType) => {
    if (this.props.onFilterChange) {
      this.props.onFilterChange(filter)
    }
  }

  render() {
    const { stream, navigation, onBackClick, expandedView } = this.props

    return createElement('header', {
      className: `${styles.header} ${expandedView ? styles.expanded : ''}`,
      'data-test': 'curated-folder-view-header'
    }, [
      createElement('h2', { key: 'title', className: `${styles.title} text-ellipsis` }, stream.title),
      createElement('a', {
        key: 'link',
        className: `${styles.navigation} hidden-mobile`,
        onClick: onBackClick
      }, navigation.back)
    ])
  }
}

export default CuratedFolderViewHeader
