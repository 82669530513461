interface KnotFunction extends Function {
  _once?: boolean
}

class Knot {

  private readonly extended: any
  private readonly events: { [key: string]: KnotFunction[] }

  constructor(..._args: any[]) {
    this.extended = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {}
    this.events = Object.create(null)

    this.on = this.on.bind(this)
    this.off = this.off.bind(this)
    this.emit = this.emit.bind(this)
    this.once = this.once.bind(this)
  }

  on(name: string, handler: Function) {
    this.events[name] = this.events[name] || []
    this.events[name].push(handler)
    return this
  }

  once(name: string, handler: KnotFunction) {
    handler._once = true
    this.on(name, handler)
    return this
  }

  off(name: string, handler?: KnotFunction) {
    handler
      ? this.events[name].splice(this.events[name].indexOf(handler), 1)
      : delete this.events[name]

    return this
  }

  emit(name: string, ..._args: any[]) {
    const _this = this

    const len = arguments.length
    const args = Array(len > 1 ? len - 1 : 0)

    for (let _len = len, _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key]
    }

    // cache the events, to avoid consequences of mutation
    const cache = this.events[name] && this.events[name].slice()

    // only fire handlers if they exist
    if (cache) {
      cache.forEach(function (handler) {
        // remove handlers added with 'once'
        if (handler._once) {
          _this.off(name, handler)
        }

        // set 'this' context, pass args to handlers
        handler.apply(_this, args)
      })
    }

    return this
  }
}

function runSeries(functions: Function[]) {
  functions.forEach(function (func) {
    return func()
  })
}

function toArray<T>(input: ArrayLike<T>): T[] {
  return Array.prototype.slice.call(input)
}

function fillArray(length: number): Array<0> {
  return Array.apply(null, Array(length)).map(function () {
    return 0
  })
}

export type Size = {
  mq?: string
  columns: number
  gutter: number
}

export class Bricks {
  sizeIndex: number | void
  sizeDetail: Size | void

  columnHeights: number[] | void

  nodeWidth: number | void

  nodes: Element[] | void
  nodesWidths: number[] | void
  nodesHeights: number[] | void

  private container: HTMLElement

  private persist: boolean | void
  private ticking: boolean | void

  private readonly packed: string
  private readonly sizes: Size[]
  private readonly position: boolean
  private readonly selectors: {
    all(): Element[]
    'new'(): Element[]
    column(i: number): Element[]
  }
  private readonly setup: Function[]
  private readonly run: Function[]

  private readonly instance: Knot
  private onLayout: () => void

  constructor(options: { packed: string, sizes: Size[], position?: boolean, container: string | HTMLElement, onLayout?: () => void }) {

    // privates

    this.persist = undefined // packing new elements, or all elements?
    this.ticking = undefined // for debounced resize

    this.sizeIndex = undefined
    this.sizeDetail = undefined

    this.columnHeights = undefined

    this.nodeWidth = undefined

    this.nodes = undefined
    this.nodesWidths = undefined
    this.nodesHeights = undefined
    this.onLayout = options.onLayout || function () {}

    // resolve options

    this.packed = options.packed.indexOf('data-') === 0 ? options.packed : 'data-' + options.packed
    this.sizes = options.sizes.slice().reverse()
    this.position = options.position !== false

    this.container = (options.container as HTMLElement).nodeType
      ? options.container as HTMLElement
      : document.querySelector(options.container as string) as HTMLElement

    const _this = this

    this.selectors = {
      all: function all() {
        return toArray(_this.container.children)
      },
      new: function _new() {
        return toArray(_this.container.children).filter(function (node) {
          return !node.hasAttribute('' + _this.packed)
        })
      },
      column: function column(column: number) {
        return toArray(_this.container.children).filter(function (node) {
          return +(node.getAttribute('data-column') || '-1') === column
        })
      }
    }

    // series

    this.setup = [this.setSizeIndex.bind(this), this.setSizeDetail.bind(this), this.setColumns.bind(this)]
    this.run = [
      this.setNodes.bind(this),
      this.setNodesDimensions.bind(this),
      this.setNodesStyles.bind(this),
      this.setContainerStyles.bind(this)
    ]

    // instance

    this.instance = new Knot({
      pack: this.pack.bind(this),
      update: this.update.bind(this),
      resize: this.resize.bind(this)
    })
  }

  // size helpers

  getSizeIndex() {
    // find index of widest matching media query
    return this.sizes.map(function (size) {
      return size.mq && window.matchMedia('(min-width: ' + size.mq + ')').matches
    }).indexOf(true)
  }

  setSizeIndex() {
    this.sizeIndex = this.getSizeIndex()
  }

  setSizeDetail() {
    // if no media queries matched, use the base case
    this.sizeDetail = this.sizeIndex === -1 ? this.sizes[this.sizes.length - 1] : this.sizes[this.sizeIndex as number]
  }

  // column helpers

  setColumns() {
    this.columnHeights = fillArray((this.sizeDetail as Size).columns)
  }

  // node helpers

  setNodes() {
    this.nodes = this.selectors[this.persist ? 'new' : 'all']()
  }

  setNodesDimensions() {
    // exit if empty container
    if ((this.nodes as Element[]).length === 0) {
      return
    }

    this.nodesWidths = (this.nodes as Element[]).map(function (element) {
      return element.clientWidth
    })
    this.nodesHeights = (this.nodes as Element[]).map(function (element) {
      return element.clientHeight
    })
  }

  resizeNodes(nodes: Element[]) {
    const reduced = nodes.reduce((results, element) => {
      const storedColumn = element.getAttribute('data-column') as string
      const storedIndex = element.getAttribute('data-index') as string
      if (!results[storedColumn] || results[storedColumn].index > +storedIndex) {
        results[storedColumn] = { index: +storedIndex, element }
      }
      return results
    }, {} as { [key: string]: { index: number, element: Element }})

    Object.keys(reduced).forEach((column) => {
      this.updateNodeSize(reduced[column].element as HTMLElement)
    })
  }

  updateNodeSize(element: HTMLElement) {
    const storedColumn = element.getAttribute('data-column') as string
    const storedIndex = element.getAttribute('data-index') as string

    const index = +storedIndex
    const columnTarget = +storedColumn

    // Update size
    const width = (this.nodesWidths as number[])[index]
    if (!width) {
      return
    }

    let nextTop = 0

    // Find all elements below this
    this.selectors.column(columnTarget).forEach((nElement: HTMLElement) => {
      if (nElement.clientWidth && nElement) {
        const nNewHeight = nElement.clientHeight

        const left = nElement.getAttribute('data-left')
        const nodeTop = nextTop + 'px'
        const nodeLeft = left + 'px'

        if (this.position) {
          nElement.style.top = nodeTop
          nElement.style.left = nodeLeft
        } else {
          nElement.style.transform = 'translate3d(' + nodeLeft + ', ' + nodeTop + ', 0)'
        }

        nElement.setAttribute('data-top', nextTop + '')
        nElement.setAttribute('data-updated', '')

        // The node after this starts here
        if (nNewHeight) {
          const gutter = (this.sizeDetail as Size).gutter
          nextTop += nNewHeight + gutter
        }
      }
    })
    ;(this.columnHeights as number[])[columnTarget] = nextTop
  }

  updateHeight(column: number, from: number, to: number, next: number) {
    const gutter = (this.sizeDetail as Size).gutter
    let columnHeight = (this.columnHeights as number[])[column]
    if (from) {
      columnHeight -= (from + gutter)
    }

    if (to) {
      columnHeight += (to + gutter)
      next += to + gutter
    }
    (this.columnHeights as number[])[column] = columnHeight
    return next
  }

  setNodesStyles() {
    (this.nodes as Element[]).forEach((element: HTMLElement, index) => {
      const columnTarget = (this.columnHeights as number[]).indexOf(Math.min.apply(Math, this.columnHeights)) as number

      element.style.position = 'absolute'

      const top = (this.columnHeights as number[])[columnTarget]
      const left = columnTarget * (this.nodesWidths as number[])[index]
        + columnTarget * (this.sizeDetail as Size).gutter

      const nodeTop = top + 'px'
      const nodeLeft = left + 'px'

      // support positioned elements (default) or transformed elements
      if (this.position) {
        element.style.top = nodeTop
        element.style.left = nodeLeft
      } else {
        element.style.transform = 'translate3d(' + nodeLeft + ', ' + nodeTop + ', 0)'
      }

      element.setAttribute('data-column', columnTarget + '')
      element.setAttribute('data-index', index + '')
      element.setAttribute('data-top', top + '')
      element.setAttribute('data-left', left + '')
      element.setAttribute(this.packed, '')

      // ignore nodes with no width and/or height
      this.nodeWidth = (this.nodesWidths as number[])[index]
      const nodeHeight = (this.nodesHeights as number[])[index]

      if (this.nodeWidth && nodeHeight) {
        (this.columnHeights as number[])[columnTarget] += nodeHeight + (this.sizeDetail as Size).gutter
      }
    })

    this.onLayout()
  }

  // container helpers

  setContainerStyles() {
    this.container.style.position = 'relative'
    this.container.style.width = (this.sizeDetail as Size).columns * (this.nodeWidth as number)
      + ((this.sizeDetail as Size).columns - 1) * (this.sizeDetail as Size).gutter + 'px'
    this.container.style.height = Math.max.apply(Math, this.columnHeights) - (this.sizeDetail as Size).gutter + 'px'
  }

  // resize helpers

  resizeFrame() {
    if (!this.ticking) {
      window.requestAnimationFrame(this.resizeHandler.bind(this))
      this.ticking = true
    }
  }

  resizeHandler() {
    if (this.sizeIndex !== this.getSizeIndex()) {
      this.pack()
      this.instance.emit('resize', this.sizeDetail)
    }

    this.ticking = false
  }

  // API

  pack() {
    this.persist = false
    runSeries(this.setup.concat(this.run))

    return this.instance.emit('pack')
  }

  update() {
    this.persist = true
    runSeries(this.run)

    return this.instance.emit('update')
  }

  resize(flag = true as boolean) {

    const action = flag ? 'addEventListener' : 'removeEventListener'

    const f = window[action] as (t: 'resize', listener: Function) => void
    f('resize', this.resizeFrame.bind(this))

    return this.instance
  }
}

export default Bricks
