import { createElement, PureComponent } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import styles from './Loader.pcss'

const SIZE_SMALL = 60
export interface LoaderProps {
  title?: string
  className?: string
  pp?: boolean
  size?: number
  small?: boolean
}

interface LoaderState { }

export class Loader extends PureComponent<LoaderProps, LoaderState> {
  render() {
    const style = {} as any
    const styleDefault = {} as any
    if (this.props.small) {
      style.width = `${SIZE_SMALL}px`
      styleDefault.width = `${SIZE_SMALL}px`
      styleDefault.height = `${SIZE_SMALL}px`
    } else if (this.props.size) {
      style.width = `${this.props.size}px`
      styleDefault.width = `${this.props.size}px`
      styleDefault.height = `${this.props.size}px`
    }
    return createElement('div', { key: 'wrapper', 'data-title': this.props.title, className: styles.wrapper, 'data-testid': 'loader' },
      !this.props.pp
        ? createElement(CircularProgress, {
          color: 'primary',
          style: styleDefault,
          className: `${styles.loader} ${this.props.className || ''}`
        })
        : createElement('img', {
          key: 'pp-loader',
          className: `${styles.img} ${this.props.className || ''}`,
          src: '/static/img/pp-loader.gif',
          style
        })
    )
  }
}

export default Loader
