import ApiException, { Tags } from './ApiException'
export { Tags }
export class DeserializationException extends ApiException {
  constructor(message: string, tags?: Tags) {
    super(message, tags)

    Object.setPrototypeOf(this, DeserializationException.prototype)
  }
}

export default DeserializationException
