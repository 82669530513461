import * as React from 'react'
import { StoreDispatch } from 'store/state'
import { setUploadsDialogOpen, setUploaderFiles } from 'services/uploads/actions'
import { INPUT_ACCEPT_ALL_MEDIA } from 'utils/file'
import { connect } from 'react-redux'
import Tooltip from '@mui/material/Tooltip'

import styles from './ConnectedFileInput.pcss'

interface ConnectedFileInputOwnProps {
  children: React.ReactElement
  singleFileSelection?: boolean
  accept?: string
  disabled?: boolean
  openDialogOnChange?: boolean
  className?: string
  tooltip?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

interface ConnectedFileInputConnectedProps {
  setUploaderFiles?: (files: { [name: string]: File }) => void
  openFileDialog: () => void
}

export type ConnectedFileInputProps = ConnectedFileInputOwnProps & ConnectedFileInputConnectedProps

export const ConnectedFileInput = React.memo(function ConnectedFileInput(props: ConnectedFileInputProps) {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files && props.setUploaderFiles) {
      const filesMap: { [name: string]: File } = {}
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i)
        if (file) {
          filesMap[file.name] = file
        }
      }
      props.setUploaderFiles(filesMap)
    }

    if (props.onChange) {
      props.onChange(e)
    }

    if (props.openDialogOnChange) {
      props.openFileDialog()
    }
  }

  return (
    <Tooltip title={props.tooltip}>
      <div className={`${styles['input-box']} ${props.className || ''}`}>
        <input
          type="file"
          multiple={!props.singleFileSelection}
          accept={props.accept || INPUT_ACCEPT_ALL_MEDIA}
          title=""
          disabled={props.disabled}
          className={styles.input}
          value=""
          onChange={onChange}
        />
        {props.children}
      </div>
    </Tooltip>
  )
})

function mapDispatchToProps(dispatch: StoreDispatch) {
  return {
    setUploaderFiles: (files: { [name: string]: File }) => dispatch(setUploaderFiles(files)),
    openFileDialog: () => dispatch(setUploadsDialogOpen(true))
  }
}

export default connect(undefined, mapDispatchToProps)(ConnectedFileInput)
