import { createSelector } from 'reselect'
import { StoreState } from 'store/state'
import { initialState } from './state'
import { MyFile, FileFolder, ContentType } from 'interfaces'
import { ppGreenColorString } from 'utils/colors'

const uploadsSelector = (state: StoreState) => state.uploads || initialState()
export const myFileFoldersSelector = createSelector(uploadsSelector, (uploads) => uploads.folders)
export const myFileFoldersArraySelector = createSelector(uploadsSelector, (uploads) => Object.values(uploads.folders))
export const myUploadsStorageSelector = createSelector(uploadsSelector, (uploads) => uploads.storage)
export const selectedFileSelector = createSelector(uploadsSelector, (uploads) => uploads.selectedFile)
export const editFolderSelector = createSelector(uploadsSelector, (uploads) => uploads.editFolder)
export const uploadsUploaderFileSelector = createSelector(uploadsSelector, (uploads) => uploads.uploaderFile)
export const uploadDialogFilesSelector = createSelector(uploadsSelector, uploads => uploads.uploadDialogFiles)
export const previewFileSelector = createSelector(uploadsSelector, uploads => uploads.preview)
export const librarySortingSelector = createSelector(uploadsSelector, uploads => uploads.sorting)

export const allFilesFolderSelector = createSelector(myFileFoldersArraySelector, folders => {
  const allFiles = folders.reduce((files: MyFile[], folder: FileFolder) => {
    files.push(...Object.values(folder.files))
    return files
  }, [])

  const filters: { [type: string]: boolean } = {}
  const filesMap = allFiles
    .sort((f1: MyFile, f2: MyFile) => {
      const createdAt1 = new Date(f1.createdAt)
      const createdAt2 = new Date(f2.createdAt)
      return createdAt2.getTime() - createdAt1.getTime() // sort by date from last uploaded to first
    })
    .reduce((filesMap: any, file) => {
      filesMap[file.key] = file
      filters[file.type] = true
      return filesMap
    }, {})

  const folder: FileFolder = {
    id: '',
    featuredImage: '',
    name: 'My Files',
    color: ppGreenColorString,
    files: filesMap,
    isFileFolder: true,
    isPrivate: false,
    filesCount: allFiles.length,
    filters: Object.keys(filters) as ContentType[],
    order: 0
  }
  return folder
})

export const uploadDiaogOpenSelector = createSelector(uploadsSelector, uploads => uploads.uploadDialogOpen)
