import { createElement } from 'react'
import Loader from 'components/SimpleLoader'
import loadable, { LoadableComponent } from '@loadable/component'
import { lazyLoad } from 'utils/chunk'

const AsyncQuotes: LoadableComponent<any> = loadable(() => lazyLoad(
  () => import(
    /* webpackPrefetch: true */
    /* webpackChunkName: 'quotes' */
    './Quotes'
  ),
  'quotes'
), {
  fallback: createElement(Loader, { title: 'Loading Quotes...' })
})

export default AsyncQuotes
