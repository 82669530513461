import * as React from 'react'
import { useDispatch } from 'react-redux'
import { checkFeatureAvailability } from 'services/product'
import { StoreThunkDispatch } from 'store/state'

interface FeatureCheckerProps {
  features: string[]
  closeCallback?: () => void
}

export function FeatureChecker(props: FeatureCheckerProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()

  React.useEffect(() => {
    const features = props.features

    for (const feat of features) {
      const featAvailable = dispatch(checkFeatureAvailability(feat, false, props.closeCallback))
      if (!featAvailable) {
        break
      }
    }
  }, [dispatch, props.closeCallback, props.features])

  return null
}

export default FeatureChecker
