export * from './cdn'

export function getFileExtensionFromUrl(url: string): string | null {
  try {
    const match = url.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)
    return match ? match[1]?.toLowerCase() : null
  } catch (_) {
    return null
  }
}
