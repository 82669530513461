import { RequestBuilder, authorizedPipe } from 'services/net'
import { CONTENT_SERVICE_URL } from 'config'
import { RangeFilter, LegacyGif } from 'interfaces'
import { SortBy } from '../util'
import { StockContentProvider } from 'interfaces/Content/StockContentProvider'

export const ENDPOINT_GIFS_EXTRACT = `${CONTENT_SERVICE_URL}/feeds/:sourceId/content/gifs`
export const ENDPOINT_GIFS_FOLDER_EXTRACT = `${CONTENT_SERVICE_URL}/streams/:folderId/content/gifs`

export const V1 = Object.freeze({
  getGifs: function () {
    return function (
      sourceId: string,
      page: number,
      rangeFilter: RangeFilter,
      sortBy: SortBy,
      stockSource?: StockContentProvider
    ) {
      const builder = new RequestBuilder(ENDPOINT_GIFS_EXTRACT).asGet().expectJSON().withoutCredentials()
      if (stockSource) {
        builder.param('stock_filter', stockSource)
      }
      return authorizedPipe<{ content: LegacyGif[] }>(
        builder
          .partial(':sourceId', sourceId)
          .param('page', page.toString())
          .param('range_filter', rangeFilter)
          .param('sort_by', sortBy)
          .build()
      )
    }
  }(),
  getGifsFromFolder: function () {
    const builder = new RequestBuilder(ENDPOINT_GIFS_FOLDER_EXTRACT).asGet().expectJSON().withoutCredentials()

    return function (folderId: string, page: number, rangeFilter: RangeFilter, sortBy: SortBy) {
      return authorizedPipe<{ content: LegacyGif[] }>(
        builder
          .partial(':folderId', folderId)
          .param('page', page.toString())
          .param('range_filter', rangeFilter)
          .param('sort_by', sortBy)
          .build()
      )
    }
  }()
})
