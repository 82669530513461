import StoreState from 'store/state'
import { createSelector } from 'reselect'
import ComposerState, { FB_POST_TYPE_REEL, POST_TYPE_STORY, initialState } from './state'
import {
  ARTICLE_TYPE,
  BRAND_FACEBOOK,
  BRAND_INSTAGRAM,
  BRAND_LINKEDIN,
  BRAND_PINTEREST,
  BRAND_TWITTER,
  ContentItem,
  FEED_TYPE_TWITTER,
  GIF_TYPE,
  IndexedObject,
  LinkDetails,
  PostDestination,
  PostDestinationType,
  VIDEO_TYPE
} from 'interfaces'
import { BRAND_GOOGLE, BRAND_TIKTOK, BRAND_YOUTUBE } from 'interfaces/Content/SocialBrand'
import { TEXT_TYPE } from 'interfaces/Content/ContentType'
import { NETWORKS_ORDERED } from 'interfaces/User/PostDestinationType'
import { connectedDestinationsSelector } from 'services/destinations'
import { extractUrlsFromText, getTwitterRemainingChars } from 'utils/composer'
import {
  MAX_COMPOSER_PHOTOS_COUNT_TWITTER,
  MAX_COMPOSER_PHOTOS_COUNT_LINKEDIN,
  MAX_GMB_STATUS_LENGTH,
  MAX_YT_TITLE_LENGTH,
  MAX_FACEBOOK_STATUS_LENGTH,
  MAX_INSTAGRAM_HASHTAGS,
  MAX_INSTAGRAM_STATUS_LENGTH,
  MAX_LINKEDIN_STATUS_LENGTH,
  PINTEREST_DESCRIPTION_MAX_LENGTH,
  PINTEREST_TITLE_MAX_LENGTH,
  TIKTOK_ALLOWED_FILE_EXTENSIONS,
  MAX_TIKTOK_VIDEO_DURATION_SEC,
  LINKEDIN_ALLOWED_IMAGE_EXTENSIONS,
  urlRegex,
  MAX_PINTEREST_HASHTAGS,
  HASHTAG_REGEXP,
  MAX_FB_STORY_ASPECT_RATIO,
  MIN_FB_STORY_DURATION,
  MAX_FB_STORY_DURATION,
  MIN_FB_STORY_WIDTH,
  MIN_FB_STORY_HEIGHT,
  MAX_INSTAGRAM_USER_TAGS
} from 'shared/constants'
import {
  ComposerBasePost,
  ComposerFacebookPost,
  ComposerInstagramPost,
  ComposerPinterestPost,
  ComposerTwitterPost,
  ComposerYoutubePost
} from './interfaces'
import { sortByKeyAscending } from 'utils/sort/order'
import { ComposerGooglePost } from './interfaces/ComposerGooglePost'
import { STOCK_GIF_TYPE, STOCK_VIDEO_TYPE } from 'interfaces/Content/StockContentTypes'
import { getFileExtensionFromUrl } from 'shared'
import { ComposerLinkedinPost } from './interfaces/ComposerLinkedinPost'

export const composerSelector = (state: StoreState) => state.composer
export const composerSelectedProfilesSelector = createSelector(
  composerSelector,
  connectedDestinationsSelector,
  (state: ComposerState, profiles: IndexedObject<PostDestination>) => {
    return state.selectedProfiles.map(id => profiles[id]).filter(Boolean) as PostDestination[]
  }
)
export const composerSelectedProfilesIdsSelector = createSelector(composerSelectedProfilesSelector, profiles => profiles.map(p => p.id))
export const composerTabsSelector = createSelector(composerSelectedProfilesSelector, profiles => {
  const networks = profiles.reduce((tabs: IndexedObject<boolean>, profile: PostDestination) => {
    tabs[profile.type] = true
    return tabs
  }, {})
  return Object.keys(networks) as PostDestinationType[]
})
export const composerActiveTabSelector = createSelector(
  composerSelector,
  composerTabsSelector,
  (state: ComposerState, tabs: PostDestinationType[]) => {
    if (tabs.includes(state.activeTab)) {
      return state.activeTab
    }
    return tabs[0] || state.activeTab
  }
)
export const composerNetworksViewSelector = createSelector(composerSelector, (state: ComposerState) => state.networksView)
export const composerContentItemSelector = createSelector(composerSelector, (state: ComposerState) => state.contentItem)
export const composerImageUrlsSelector = createSelector(composerSelector, (state: ComposerState) => state.imageUrls)
export const composerVideoUrlSelector = createSelector(composerSelector, (state: ComposerState) => state.videoUrl)
export const composerVideoDurationSelector = createSelector(composerSelector, (state: ComposerState) => state.videoDuration)
export const composerVideoSizeSelector = createSelector(composerSelector, (state: ComposerState) => state.videoSize)
export const composerVideoThumbnailSelector = createSelector(composerSelector, (state: ComposerState) => state.videoThumbnailUrl)
export const composerVideoThumbnailOffsetSelector = createSelector(composerSelector, (state: ComposerState) => state.videoThumbnailOffset)
export const composerBulkFileSelector = createSelector(composerSelector, (state: ComposerState) => state.bulkUploadFile)
export const composerBulkFileUrlSelector = createSelector(composerBulkFileSelector, file => file?.url)
export const composerRecycleSelector = createSelector(composerSelector, (state: ComposerState) => state.recycle)
export const composerLinkSelector = createSelector(composerSelector, (state: ComposerState) => state.links.find(link => link.selected))
export const composerAllLinksSelector = createSelector(composerSelector, (state: ComposerState) => state.links)
export const composerPostProgressSelector = createSelector(composerSelector, (state: ComposerState) => state.progress)
export const composerNetworkPostsSelector = createSelector(composerSelector, (state: ComposerState) => state.posts)
export const rememberIGLocationsSelector = createSelector(composerSelector, state => state.rememberIGLocations)
export const pinterestProfilesBoardsSelector = createSelector(composerSelector, state => state.pinterestBoards)
export const featuredImageUrlSelector = createSelector(composerSelector, state => state.featuredImageUrl)
export const composerExternalFileSelector = createSelector(composerSelector, state => state.externalFile)
export const composerSelectedBucketIdSelector = createSelector(composerSelector, state => state.selectedBucketId)
export const composerPostAsPhotoSelector = createSelector(composerSelector, (state: ComposerState) => state.postAsPhoto)
export const composerMultiPostSelector = createSelector(composerSelector, (state: ComposerState) => state.multiPost)
export const videoMetadataSelector = createSelector(composerSelector, (state: ComposerState) => state.videoMetadata)
export const composerBulkPostsSelector = createSelector(composerMultiPostSelector, (state) => {
  return Object.values(state.posts).sort(sortByKeyAscending('order'))
})
export const multiPostsArraySelector = createSelector(composerMultiPostSelector, state => Object.values(state.posts))
export const multiPostsUploadingSelector = createSelector(multiPostsArraySelector, posts => {
  return posts.some(p => p.imageUpload && !p.imageUpload.error && !p.imageUpload.url)
})
export const visibleMultiPostsSelector = createSelector(composerBulkPostsSelector, composerTabsSelector, (posts, networks) => {
  return posts.filter(p => networks.includes(p.network))
})
export const multiPostsCountSelector = createSelector(visibleMultiPostsSelector, composerSelectedProfilesSelector, (posts, profiles) => {
  const postsByNetwork = posts.reduce((count: Record<string, number>, post) => {
    if (count[post.network]) {
      count[post.network] += 1
    } else {
      count[post.network] = 1
    }
    return count
  }, {})

  return profiles.reduce((totalCount: number, profile) => {
    const count = typeof postsByNetwork[profile.type] === 'undefined' ? 0 : postsByNetwork[profile.type]
    return totalCount + count
  }, 0)
})
export const useGenericStatusSelector = createSelector(composerSelector, (state: ComposerState) => state.useGenericStatus)
export const composerPostSelector = createSelector(
  [composerNetworkPostsSelector, (_s, network: PostDestinationType) => network],
  (posts, network) => posts[network]
)
export const facebookPostSelector = createSelector(composerSelector, state => state.posts.facebook)
export const instagramPostSelector = createSelector(composerSelector, state => state.posts.instagram)
export const pinterestPostSelector = createSelector(composerSelector, state => state.posts.pinterest)
export const linkedinPostSelector = createSelector(composerSelector, state => state.posts.linkedin)
export const googlePostSelector = createSelector(composerSelector, state => state.posts.google)
export const youtubePostSelector = createSelector(composerSelector, state => state.posts.youtube)
export const twitterPostSelector = createSelector(composerSelector, state => state.posts.twitter)
export const tiktokPostSelector = createSelector(composerSelector, state => state.posts.tiktok)
export const genericPostSelector = createSelector(composerSelector, state => state.posts.generic)
export const activeDraftSelector = createSelector(composerSelector, state => state.draft)
export const activePostIdSelector = createSelector(composerSelector, state => state.postEdit?.id)
export const composerPostEditSelector = createSelector(composerSelector, state => state.postEdit)
export const composerScheduleSelector = createSelector(composerSelector, state => state.schedule)
export const composerPromptSelector = createSelector(composerSelector, state => state.prompt)

export const aiWriterSelector = createSelector(composerSelector, state => state.writer)
export const favoritePromptsSelector = createSelector(aiWriterSelector, writer => writer.favoritePrompts)
export const userAIPromptsSelector = createSelector(aiWriterSelector, writer => {
  return [...writer.userPrompts].sort((a, b) => {
    const createdA = new Date(a.createdAt).getTime()
    const createdB = new Date(b.createdAt).getTime()
    return createdB - createdA
  })
})
export const aiWriterPromptIdSelector = createSelector(aiWriterSelector, writer => writer.aiWriterPromptId)

export const composerStatusesTextSelector = createSelector(composerSelector, (state: ComposerState) => {
  return NETWORKS_ORDERED.reduce((map: IndexedObject<string>, network: string) => {
    map[network] = (state.posts as any)[network].status.text
    return map
  }, {})
})

export const composerIsUploadingSelector = createSelector(composerSelector, state => state.isUploading)

const composerMediaIsEmptySelector = createSelector(
  composerImageUrlsSelector,
  composerVideoUrlSelector,
  composerContentItemSelector,
  composerLinkSelector,
  (imageUrls, videoUrl, contentItem, link) => {
    return imageUrls.length === 0 && !videoUrl && !link && (!contentItem || contentItem.type === TEXT_TYPE)
  }
)

/**
 * ================================================
 * Posts errors and hints selectors
 * ================================================
 */
export interface ComposerPostStatus {
  network: {
    hints: string[]
    errors: string[]
    info: string[]
  },
  caption: {
    hints: string[]
    errors: string[]
    info: string[]
  },
  content: {
    hints: string[]
    errors: string[]
    info: string[]
  },
  hasErrors: boolean
  hasHints: boolean
  isEmpty?: boolean
}
function emptyPostStatus(): ComposerPostStatus {
  return {
    network: {
      hints: [],
      errors: [],
      info: []
    },
    caption: {
      hints: [],
      errors: [],
      info: []
    },
    content: {
      hints: [],
      errors: [],
      info: []
    },
    hasErrors: false,
    hasHints: false
  }
}

export const composerInstagramPostStatusSelector = createSelector(
  instagramPostSelector,
  composerImageUrlsSelector,
  composerSelectedProfilesSelector,
  composerPostAsPhotoSelector,
  composerVideoUrlSelector,
  composerBulkFileUrlSelector,
  composerContentItemSelector,
  composerLinkSelector,
  composerMediaIsEmptySelector,
  (
    post: ComposerInstagramPost,
    imageUrls: string[],
    profiles: PostDestination[],
    postAsPhoto: boolean,
    videoUrl?: string,
    bulkUploadFileUrl?: string,
    content?: ContentItem,
    link?: LinkDetails,
    mediaIsEmpty?: boolean
  ) => {
    const postState = emptyPostStatus()
    const igProfiles = profiles.filter(p => p.type === BRAND_INSTAGRAM)
    if (igProfiles.length === 0) {
      return postState
    }

    if (post.postType === POST_TYPE_STORY && !mediaIsEmpty) {
      postState.content.info.push('composer.info.instagram.story-ratio')
    }

    const commentHashtags: string[] = (post.firstComment.match(HASHTAG_REGEXP) || [])
    const postHashtags: string[] = (post.status.text.match(HASHTAG_REGEXP) || [])
    const hasDuplicatedHashtags = commentHashtags.some(tag => postHashtags.includes(tag))

    if (hasDuplicatedHashtags) {
      postState.caption.hints.push('composer.hints.instagram.hashtags-duplicated')
      postState.hasHints = true
    }

    if (commentHashtags.length > MAX_INSTAGRAM_HASHTAGS) {
      postState.caption.errors.push('composer.errors.instagram.hashtags-limit-first-comment')
      postState.hasErrors = true
    }

    if (post.status.text.length > MAX_INSTAGRAM_STATUS_LENGTH) {
      postState.caption.errors.push('composer.errors.instagram.caption-length')
      postState.hasErrors = true
    }

    if (extractUrlsFromText(post.status.text).length > 0) {
      postState.caption.hints.push('composer.hints.instagram.url-in-caption')
      postState.hasHints = true
    }

    if (post.postType !== POST_TYPE_STORY && post.status.text.indexOf('#') === -1 && post.firstComment.indexOf('#') === -1) {
      postState.caption.hints.push('composer.hints.instagram.no-hashtags')
      postState.hasHints = true
    } else if (postHashtags.length > MAX_INSTAGRAM_HASHTAGS) {
      postState.caption.errors.push('composer.errors.instagram.hashtags-limit')
      postState.hasErrors = true
    }
    // TODO: Vid/Image dimensions check
    // if (videoUrl) {
    //   postState.content.hints.push('composer.labels.instagram-vid-requirements')
    //   postState.content.hints.push('composer.labels.instagram-vid-requirements-hint')
    //   postState.hasHints = true
    // }

    const mediaEmpty = imageUrls.length === 0 && !videoUrl
    if (post.status.text.length === 0 && mediaEmpty && !bulkUploadFileUrl && !content && !link && !postAsPhoto) {
      postState.isEmpty = true
      postState.content.errors.push('composer.errors.instagram.post-empty')
      postState.hasErrors = true
    }

    if (post.postType === POST_TYPE_STORY) {
      if (imageUrls.length > 1) {
        postState.network.hints.push('composer.hints.instagram.multi-photo-stories')
        postState.hasHints = true
      }
      if (post.status.text.length > 0) {
        postState.network.hints.push('composer.hints.instagram.caption-stories')
        postState.hasHints = true
      }
    }

    if (post.tags && post.tags.length > MAX_INSTAGRAM_USER_TAGS) {
      postState.network.hints.push('composer.hints.instagram.user-tags-limit')
      postState.hasHints = true
    }

    const withLocation = igProfiles.some(p => post.locations[p.ppid])
    if (withLocation && (videoUrl || imageUrls.length > 1)) {
      postState.network.hints.push('composer.hints.instagram.post-with-location')
      postState.hasHints = true
    }

    if (mediaEmpty && !postState.isEmpty && !postAsPhoto) {
      postState.content.errors.push('composer.errors.instagram.content-empty')
      postState.hasErrors = true
    }

    return postState
  }
)

export const composerPinterestPostStatusSelector = createSelector(
  pinterestPostSelector,
  composerMediaIsEmptySelector,
  composerSelectedProfilesSelector,
  composerImageUrlsSelector,
  pinterestProfilesBoardsSelector,
  composerVideoUrlSelector,
  composerIsUploadingSelector,
  composerContentItemSelector,
  (
    post: ComposerPinterestPost,
    mediaIsEmpty: boolean,
    profiles: PostDestination[],
    imageUrls: string[],
    boards: { [ppid: string]: Array<{ id: string, name: string, url?: string, selected?: boolean }> },
    videoUrl?: string,
    isUploading?: boolean | 'video',
    content?: ContentItem
  ) => {
    const postState = emptyPostStatus()
    const pinterestProfiles = profiles.filter(p => p.type === BRAND_PINTEREST)
    if (pinterestProfiles.length === 0) {
      return postState
    }

    const isTwitterGif = content?.type === GIF_TYPE && content?.feed.type === FEED_TYPE_TWITTER

    for (const profile of pinterestProfiles) {
      if (!boards[profile.ppid] || !boards[profile.ppid].find(b => b.selected)) {
        postState.caption.errors.push(PINTEREST_BOARD_NOT_SELECTED_MESSAGE_KEY)
        postState.hasErrors = true
        break
      }
    }

    const { title, description, destinationUrl } = post

    if (title.length > PINTEREST_TITLE_MAX_LENGTH) {
      postState.caption.errors.push('composer.errors.pinterest.title-limit-reached')
      postState.hasErrors = true
    }

    if (description?.length > PINTEREST_DESCRIPTION_MAX_LENGTH) {
      postState.caption.errors.push('composer.errors.pinterest.description-limit-reached')
      postState.hasErrors = true
    }

    if (description?.indexOf('\n') !== -1) {
      postState.caption.info.push('composer.info.pinterest.newline')
    }

    if ((description?.match(/#/g) || []).length > MAX_PINTEREST_HASHTAGS) {
      postState.content.hints.push('composer.hints.pinterest.hashtag-limit')
      postState.hasHints = true
    }

    if (videoUrl || isUploading === 'video' || isTwitterGif || content?.type === VIDEO_TYPE || content?.type === STOCK_VIDEO_TYPE) {
      postState.content.errors.push('composer.errors.pinterest.no-video')
      postState.hasErrors = true
    }

    if (imageUrls.length > 1) {
      postState.content.hints.push('composer.hints.pinterest.multi-photo')
      postState.hasHints = true
    }

    if (mediaIsEmpty) {
      if (!title && !description?.trim() && !destinationUrl) {
        postState.isEmpty = true
        postState.content.errors.push('composer.errors.pinterest.post-empty')
        postState.hasErrors = true
      } else {
        postState.content.errors.push('composer.errors.pinterest.content-empty')
        postState.hasErrors = true
      }
    }

    // TODO: add image orientation check: "Optimized Pin images have a square or profile aspect ratio"

    return postState
  }
)

export const composerLinkedinPostStatusSelector = createSelector(
  composerImageUrlsSelector,
  linkedinPostSelector,
  composerMediaIsEmptySelector,
  composerSelectedProfilesSelector,
  (imageUrls: string[], post: ComposerLinkedinPost, mediaIsEmpty: boolean, profiles: PostDestination[]) => {
    const postState = emptyPostStatus()
    postState.isEmpty = false
    const liProfiles = profiles.filter(p => p.type === BRAND_LINKEDIN)
    if (liProfiles.length === 0) {
      return postState
    }

    if (imageUrls.length > MAX_COMPOSER_PHOTOS_COUNT_LINKEDIN) {
      postState.caption.errors.push('composer.errors.linkedin.max-photos')
      postState.hasErrors = true
    }

    for (const image of imageUrls) {
      const fileExtension = getFileExtensionFromUrl(image)
      if (fileExtension && !LINKEDIN_ALLOWED_IMAGE_EXTENSIONS.includes(fileExtension)) {
        postState.caption.errors.push('composer.errors.linkedin.photo-formats')
        postState.hasErrors = true
        break
      }
    }

    const status = post.status.text.trim()

    if (status.length > MAX_LINKEDIN_STATUS_LENGTH) {
      postState.caption.errors.push('composer.errors.linkedin.caption-length')
      postState.hasErrors = true
    } else if (status.length === 0 && mediaIsEmpty && !post.document) {
      postState.isEmpty = true
      postState.content.errors.push('composer.errors.linkedin.post-empty')
      postState.hasErrors = true
    } else if (status.length === 0 && post.document) {
      postState.isEmpty = false
      postState.content.errors.push('composer.errors.linkedin.doc-no-caption')
      postState.hasErrors = true
    }

    if (!mediaIsEmpty && post.document) {
      postState.content.errors.push('composer.errors.linkedin.doc-and-media')
      postState.hasErrors = true
    }

    return postState
  }
)

export const composerFacebookPostStatusSelector = createSelector(
  facebookPostSelector,
  composerMediaIsEmptySelector,
  composerSelectedProfilesSelector,
  composerImageUrlsSelector,
  composerVideoUrlSelector,
  composerVideoDurationSelector,
  videoMetadataSelector,
  (
    post: ComposerFacebookPost,
    mediaIsEmpty: boolean,
    profiles: PostDestination[],
    images: string[],
    videoUrl?: string,
    videoDuration?: number,
    videoMetadata?: { width?: number, height?: number, fileType?: string }
  ) => {
    const postState = emptyPostStatus()
    const isFBGroupSelected = Boolean(profiles.find(p => p.fbGroup))
    if (profiles.find(p => p.type === BRAND_FACEBOOK) === undefined) {
      return postState
    }

    if (post.postType === FB_POST_TYPE_REEL && isFBGroupSelected) {
      postState.content.errors.push('composer.errors.facebook.reel-to-group')
      postState.hasErrors = true
    }

    if (post.postType === POST_TYPE_STORY) {
      if (isFBGroupSelected) {
        postState.content.errors.push('composer.errors.facebook.story-video-to-group')
        postState.hasErrors = true
      }
      if (post.status.text) {
        postState.content.hints.push('composer.hints.facebook.story-caption')
        postState.hasHints = true
      }
    }

    if (videoUrl && post.postType === POST_TYPE_STORY) {
      const fileType = videoMetadata?.fileType || videoUrl.substring(videoUrl.lastIndexOf('.') + 1).toLowerCase()
      if (post.firstComment?.trim()) {
        postState.content.hints.push('composer.hints.facebook.story-first-comment')
        postState.hasHints = true
      }
      if (videoMetadata?.width && videoMetadata?.height) {
        if (videoMetadata.width / videoMetadata.height > MAX_FB_STORY_ASPECT_RATIO) {
          postState.content.errors.push('composer.errors.facebook.story-aspect-ratio')
          postState.hasErrors = true
        }
        if (videoMetadata.width < MIN_FB_STORY_WIDTH || videoMetadata.height < MIN_FB_STORY_HEIGHT) {
          postState.content.errors.push('composer.errors.facebook.story-width-height')
          postState.hasErrors = true
        }
      }
      if (videoDuration && (videoDuration < MIN_FB_STORY_DURATION || videoDuration > MAX_FB_STORY_DURATION)) {
        postState.content.errors.push('composer.errors.facebook.story-duration')
        postState.hasErrors = true
      }
      if (fileType && fileType !== 'mp4') {
        postState.content.hints.push('composer.hints.facebook.story-file-type')
        postState.hasHints = true
      }
    }

    if (post.status.text.trim().length === 0) {
      if (mediaIsEmpty) {
        postState.isEmpty = true
        postState.content.errors.push('composer.errors.facebook.post-empty')
        postState.hasErrors = true
      }
    } else if (post.status.text.length > MAX_FACEBOOK_STATUS_LENGTH) {
      postState.caption.errors.push('composer.errors.facebook.caption-length')
      postState.hasErrors = true
    }

    if (images.length > 1 && profiles.find(p => p.fbGroup && p.ppPublishBy) !== undefined) {
      postState.content.errors.push('composer.errors.facebook.multi-photo-to-group')
      postState.hasErrors = true
    }

    if (isFBGroupSelected && post.status.html.indexOf('data-tag-id') !== -1) {
      postState.caption.hints.push('composer.hints.facebook.tags-to-groups')
      postState.hasHints = true
    }

    if (post.firstComment?.trim()) {
      postState.caption.info.push('composer.hints.facebook.first-comment-info')
      if (isFBGroupSelected) {
        postState.caption.hints.push('composer.hints.facebook.first-comment-group')
        postState.hasHints = true
      }

      const commentHashtags: string[] = (post.firstComment.match(HASHTAG_REGEXP) || [])
      const postHashtags: string[] = (post.status.text.match(HASHTAG_REGEXP) || [])
      const hasDuplicatedHashtags = commentHashtags.some(tag => postHashtags.includes(tag))
      if (hasDuplicatedHashtags) {
        postState.caption.hints.push('composer.hints.facebook.hashtags-duplicated')
        postState.hasHints = true
      }
    }

    return postState
  }
)

export const composerTwitterPostStatusSelector = createSelector(
  twitterPostSelector,
  composerSelectedProfilesSelector,
  composerMediaIsEmptySelector,
  composerImageUrlsSelector,
  composerPostAsPhotoSelector,
  composerContentItemSelector,
  composerLinkSelector,
  (
    post: ComposerTwitterPost,
    profiles: PostDestination[],
    mediaIsEmpty: boolean,
    imageUrls: string[],
    postAsPhoto: boolean,
    content?: ContentItem,
    link?: LinkDetails
  ) => {
    const postState = emptyPostStatus()
    if (profiles.find(p => p.type === BRAND_TWITTER) === undefined) {
      return postState
    }

    const status = post.status.text.trim()
    const isUrlRequired = Boolean(link) || content?.type === ARTICLE_TYPE || content?.type === VIDEO_TYPE

    if (isUrlRequired && post.tweetType === 'new' && !postAsPhoto && extractUrlsFromText(status).length === 0) {
      postState.caption.hints.push('composer.hints.twitter.no-article-url')
      postState.hasHints = true
    }

    if (getTwitterRemainingChars(status) < 0) {
      postState.caption.errors.push('composer.errors.twitter.tweet-length')
      postState.hasErrors = true
    } else if (status.length === 0) {
      if (mediaIsEmpty) {
        postState.content.errors.push('composer.errors.twitter.post-empty')
        postState.hasErrors = true
        postState.isEmpty = true
      }
    }

    if (imageUrls.length > MAX_COMPOSER_PHOTOS_COUNT_TWITTER) {
      postState.content.errors.push('composer.errors.twitter.max-photos')
      postState.hasErrors = true
    }

    return postState
  }
)

export const composerTiktokPostStatusSelector = createSelector(
  tiktokPostSelector,
  composerVideoUrlSelector,
  composerVideoDurationSelector,
  composerSelectedProfilesSelector,
  composerMediaIsEmptySelector,
  (post, url, duration, profiles, isMediaEmpty) => {
    const postState = emptyPostStatus()
    const tiktokProfiles = profiles.filter(p => p.type === BRAND_TIKTOK)
    if (tiktokProfiles.length === 0) {
      return postState
    }

    if (isMediaEmpty && post.status.text.trim().length === 0) {
      postState.isEmpty = true
    }
    const settings = post.settings || {}
    for (const profile of tiktokProfiles) {
      if (!settings[profile.ppid]?.privacyLevel) {
        postState.content.errors.push('composer.errors.tiktok.privacy')
        postState.hasErrors = true
      }
      if (!settings[profile.ppid]?.promoting) {
        postState.content.errors.push('composer.errors.tiktok.promotion')
        postState.hasErrors = true
      }
    }

    if (!url) {
      postState.content.errors.push('composer.errors.tiktok.no-video')
      postState.hasErrors = true
    } else {
      const fileExtension = getFileExtensionFromUrl(url)
      if (!TIKTOK_ALLOWED_FILE_EXTENSIONS.includes(fileExtension as any)) {
        postState.content.errors.push('composer.errors.tiktok.video-type')
        postState.hasErrors = true
      }
      if (duration && duration > MAX_TIKTOK_VIDEO_DURATION_SEC) {
        postState.content.errors.push('composer.errors.tiktok.video-duration')
        postState.hasErrors = true
      }
      if (postState.content.errors.length === 0) {
        postState.content.info = [
          'composer.hints.tiktok.video-resolution',
          'composer.hints.tiktok.video-size'
        ]
      }
    }
    return postState
  }
)

export const composerGooglePostStatusSelector = createSelector(
  googlePostSelector,
  composerMediaIsEmptySelector,
  composerSelectedProfilesSelector,
  composerImageUrlsSelector,
  composerContentItemSelector,
  composerVideoUrlSelector,
  composerContentItemSelector,
  composerLinkSelector,
  (
    post: ComposerBasePost,
    mediaIsEmpty: boolean,
    profiles: PostDestination[],
    imageUrls: string[],
    contentItem: ContentItem | undefined,
    videoUrl?: string,
    content?: ContentItem,
    linkDetails?: LinkDetails
  ) => {
    const postState = emptyPostStatus()
    if (profiles.find(p => p.type === BRAND_GOOGLE) === undefined) {
      return postState
    }

    const status = post.status.text.trim()
    const link = (post as ComposerGooglePost).link?.trim()
    const withVideoLink = content?.type === VIDEO_TYPE || linkDetails?.type === 'video_link'

    let withGifImage = false
    for (let index = 0; index < imageUrls.length; index++) {
      const fileExtension = getFileExtensionFromUrl(imageUrls[index])
      if (fileExtension === 'gif') {
        withGifImage = true
        break
      }
    }

    if (status.length === 0) {
      if (mediaIsEmpty) {
        postState.isEmpty = true
        postState.content.errors.push('composer.errors.google.post-empty')
        postState.hasErrors = true
      }
    } else if (status.length > MAX_GMB_STATUS_LENGTH) {
      postState.content.errors.push('composer.errors.google.post-length')
      postState.hasErrors = true
    }

    if (link && !link.match(urlRegex)) {
      postState.content.hints.push('composer.hints.google.link-invalid-url')
      postState.hasHints = true
    }

    if (!link || !(post as ComposerGooglePost).buttonType) {
      postState.caption.hints.push('composer.hints.google.missing-link-or-btn-type')
      postState.hasHints = true
    }

    if (imageUrls.length > 1) {
      postState.content.hints.push('composer.hints.google.multi-post')
      postState.hasHints = true
    }

    if (videoUrl || contentItem?.type === VIDEO_TYPE || contentItem?.type === STOCK_VIDEO_TYPE || withVideoLink) {
      postState.content.errors.push('composer.errors.google.videos')
      postState.hasErrors = true
    }

    if (withGifImage || contentItem?.type === GIF_TYPE || contentItem?.type === STOCK_GIF_TYPE) {
      postState.content.errors.push('composer.errors.google.gifs')
      postState.hasErrors = true
    }

    return postState
  }
)

export const composerYoutubePostStatusSelector = createSelector(
  youtubePostSelector,
  composerMediaIsEmptySelector,
  composerVideoUrlSelector,
  composerSelectedProfilesSelector,
  composerMediaIsEmptySelector,
  (post: ComposerYoutubePost, mediaIsEmpty, url, profiles, isMediaEmpty) => {
    const postState = emptyPostStatus()
    const ytProfiles = profiles.filter(p => p.type === BRAND_YOUTUBE)

    if (ytProfiles.length === 0) {
      return postState
    }

    const status = post.title.trim()

    if (status.length === 0) {
      if (mediaIsEmpty) {
        postState.isEmpty = true
        postState.content.errors.push('composer.errors.youtube.post-empty')
        postState.hasErrors = true
      }
    } else if (status.length > MAX_YT_TITLE_LENGTH) {
      postState.content.errors.push('composer.errors.youtube.post-length')
      postState.hasErrors = true
    }

    if (isMediaEmpty && post.title.trim().length === 0) {
      postState.isEmpty = true
    }

    if (!url) {
      postState.content.errors.push('composer.errors.youtube.no-video')
      postState.hasErrors = true
    }

    if (!postState.isEmpty) {
      postState.content.info.push('composer.info.youtube.shorts')
    }
    return postState
  }
)

export const composerPostStatusByNetworkSelector = createSelector(
  composerInstagramPostStatusSelector,
  composerPinterestPostStatusSelector,
  composerLinkedinPostStatusSelector,
  composerFacebookPostStatusSelector,
  composerTwitterPostStatusSelector,
  composerTiktokPostStatusSelector,
  composerGooglePostStatusSelector,
  composerYoutubePostStatusSelector,
  (
    igPost: ComposerPostStatus,
    piPost: ComposerPostStatus,
    liPost: ComposerPostStatus,
    fbPost: ComposerPostStatus,
    twPost: ComposerPostStatus,
    tkPost: ComposerPostStatus,
    gPost: ComposerPostStatus,
    ytPost: ComposerPostStatus
  ) => {
    return {
      [BRAND_FACEBOOK]: fbPost,
      [BRAND_TWITTER]: twPost,
      [BRAND_LINKEDIN]: liPost,
      [BRAND_PINTEREST]: piPost,
      [BRAND_INSTAGRAM]: igPost,
      [BRAND_TIKTOK]: tkPost,
      [BRAND_GOOGLE]: gPost,
      [BRAND_YOUTUBE]: ytPost
    }
  }
)

export const composerIsEmptySelector = createSelector(
  composerPostStatusByNetworkSelector,
  composerTabsSelector,
  (statusByNetwork, tabs) => {
    for (const tab of tabs) {
      if (!(statusByNetwork as IndexedObject<ComposerPostStatus>)[tab].isEmpty) {
        return false
      }
    }
    return true
  })

export const composerResetKeySelector = createSelector(composerSelector, state => state.resetKey)
export const savedStatusesSelector = createSelector(composerSelector, state => state.savedStatuses)
export const PINTEREST_BOARD_NOT_SELECTED_MESSAGE_KEY = 'composer.errors.pinterest.no-board'
export const facebookBackgroundTemplatesSelector = createSelector(composerSelector, state => state.facebookBackgroundTemplates)
