import * as React from 'react'
import IconPrev from '@mui/icons-material/ChevronLeft'
import IconNext from '@mui/icons-material/ChevronRight'
import styles from './WeekNav.pcss'
import { CalendarDay } from 'interfaces/Post/Calendar'
import { format } from 'date-fns'

interface WeekNavProps {
  days: CalendarDay[]
  withBackNav: boolean
  className?: string
  onNext: () => void
  onPrev: () => void
}

export function WeekNav(props: WeekNavProps) {
  const labelFrom = format(props.days[0].date, 'MMM d')
  const labelTo = format(props.days[6].date, 'MMM d') // eslint-disable-line no-magic-numbers

  return (
    <div className={`${styles.nav} ${props.className || ''}`}>
      <div className={styles.selection}>
        {(props.withBackNav) && (
          <IconPrev className={styles['btn-nav']} role="button" data-testid="week-nav-prev" onClick={props.onPrev} />
        )}
        <div className={styles.active}>
          {`${labelFrom} - ${labelTo}`}
        </div>
        <IconNext className={styles['btn-nav']} role="button" data-testid="week-nav-next" onClick={props.onNext} />
      </div>
    </div>
  )
}

export default WeekNav
