import * as React from 'react'
import IconEdit from '@mui/icons-material/Edit'
import { List } from 'interfaces'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { connectedDestinationsSelector } from 'services/destinations'
import { userListsSelector } from 'services/lists/selectors'
import { setActiveSidebarView, setSelectedProfiles, setSelectedList, toggleSidebarProfilesExpanded } from 'services/post'
import { sidebarActiveViewSelector, selectedProfilesSelector, selectedListSelector, postsUISelector } from 'services/post/selectors'
import Tooltip from '@mui/material/Tooltip'
import styles from './PostProfilesSelector.pcss'
import { SidebarListSelector } from './SidebarListSelector'
import SidebarProfileSelector from './SidebarProfileSelector'
import ListEditorDialog from 'components/ListEditorDialog'
import { StoreThunkDispatch } from 'store/state'
import { sortByKeyAlphabetically } from 'utils/sort/order'
import IconButton from '@mui/material/IconButton'
import { setComposerBucket } from 'services/compose'
import Collapse from '@mui/material/Collapse'
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight'

interface PostProfilesSelectorProps {
  hidden: boolean
  activeView: 'calendar' | 'posts' | 'history'
  singleProfileSelection?: boolean
}

export function PostProfilesSelector(props: PostProfilesSelectorProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [listEditorOpen, setListEditorOpen] = React.useState(false)
  const profiles = useSelector(connectedDestinationsSelector)
  const connectedProfilesIdsString = Object.keys(profiles).join(',')
  const lists = useSelector(userListsSelector)
  const postSelectorView = useSelector(sidebarActiveViewSelector)
  const selectedPostProfiles = useSelector(selectedProfilesSelector)
  const selectedListId = useSelector(selectedListSelector)
  const ui = useSelector(postsUISelector)

  const toggleProfilesVisible = () => {
    dispatch(toggleSidebarProfilesExpanded())
  }

  const selectedPostProfilesIds = selectedPostProfiles.map(p => p.id)
  const selectedProfilesIdsString = selectedPostProfilesIds.join(',')

  // Auto-select list if its profiles match the selected profiles
  React.useEffect(() => {
    if (!selectedListId) {
      const ids = selectedPostProfilesIds.sort().join(',')
      const list = Object.values(lists).find(l => l.connectedPages.sort().join(',') === ids)
      if (list) {
        dispatch(setSelectedList(list.id))
      }
    }
  }, [selectedPostProfilesIds, selectedListId, lists, dispatch])

  React.useEffect(() => {
    // Handle initial profile selection:
    // if there are no selected profiles, set all profiles as selected
    const selectedProfilesIds = selectedProfilesIdsString.split(',').filter(Boolean)
    if (selectedProfilesIds.length !== 0) {
      return
    }

    const selectedProfileIds = connectedProfilesIdsString.split(',').filter(Boolean)
    if (selectedProfileIds.length !== 0) {
      dispatch(setSelectedProfiles({ profiles: Object.values(profiles), activeView: props.activeView }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProfilesIdsString, connectedProfilesIdsString, dispatch, props.activeView])

  React.useEffect(() => {
    // NOTE: If on Posted page, change selection to a single profile and clear list selection
    if (props.activeView === 'history' && selectedPostProfiles.length > 1 && props.singleProfileSelection) {
      dispatch(setSelectedList(undefined))
      const selectedProfile = selectedPostProfiles.sort(sortByKeyAlphabetically('name'))[0]
      if (selectedProfile) {
        dispatch(setSelectedProfiles({ profiles: [selectedProfile], activeView: props.activeView }))
      }
    }
  }, [props.activeView, dispatch, selectedPostProfiles, props.singleProfileSelection])

  const closeListEditor = () => {
    setListEditorOpen(false)
  }

  const onEditListsClick = () => {
    setListEditorOpen(true)
  }

  const setProfileSelectorView = (view: 'grid' | 'list' | 'network') => {
    dispatch(setActiveSidebarView(view))
  }

  const onPostProfileSelectionChange = (ids: string[]) => {
    dispatch(setSelectedList(undefined))
    dispatch(setComposerBucket(undefined))
    dispatch(setSelectedProfiles({ profiles: ids.map(id => profiles[id]), activeView: props.activeView }))
  }

  const onSelectedListChange = (list: List) => {
    dispatch(setComposerBucket(undefined))
    if (list.connectedPages.length > 0) {
      dispatch(setSelectedProfiles({ profiles: list.connectedPages.map(id => profiles[id]), activeView: props.activeView }))
      dispatch(setSelectedList(list.id))
    }
  }

  return (
    <React.Fragment>
      <div className={`${styles['panel-profiles']} ${props.hidden ? styles.hidden : ''}`}>
        <div className={styles.title}>
          <FormattedMessage id="general.labels.profiles" />
          <span className={`${styles['btn-toggle']} ${ui.sidebarProfilesExpanded ? styles.open : ''}`} onClick={toggleProfilesVisible}>
            <ArrowIcon />
          </span>
        </div>
        <Collapse in={ui.sidebarProfilesExpanded}>
          <SidebarProfileSelector
            profiles={profiles}
            activeView={postSelectorView}
            selectedProfileIds={selectedPostProfilesIds}
            multipleProfileSelection={!props.singleProfileSelection}
            withAddProfileButton
            listViewClassName={styles['list-view']}
            onSelectedProfilesChange={onPostProfileSelectionChange}
            setActiveView={setProfileSelectorView}
          />
        </Collapse>
      </div>
      <div className={`${styles['panel-profiles']} ${props.hidden ? styles.hidden : ''}`}>
        <div className={styles.title}>
          <FormattedMessage id="general.labels.lists" />
          <Tooltip title={<FormattedMessage id="list-manager.labels.edit" />} placement="top">
            <IconButton className={styles['btn-edit-lists']} size="small" onClick={onEditListsClick}>
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
        <SidebarListSelector
          selectedList={selectedListId ? lists[selectedListId] : undefined}
          onSelectedListChange={onSelectedListChange}
        />
        <ListEditorDialog open={listEditorOpen} onClose={closeListEditor} />
      </div>
    </React.Fragment>
  )
}

export default PostProfilesSelector
