import { tap } from 'rxjs/operators/tap'
import { StoreThunkDispatch } from 'store/state'
import { addLocationSlot, getLocations, addLocation } from './net'

export const ACTION_SET_LOCATIONS = 'ACTION_SET_LOCATIONS'

export function fetchLocations() {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(getLocations()).pipe(
      tap(response => {
        dispatch({
          type: ACTION_SET_LOCATIONS,
          payload: response
        })
      })
    )
  }
}

export function createLocationSlot(annual: boolean) {
  return (dispatch: StoreThunkDispatch) => dispatch(addLocationSlot(annual)).pipe(
    tap(response => {
      if (!response.success) {
        throw new Error()
      }
    })
  )
}

export function createLocation(name: string) {
  return (dispatch: StoreThunkDispatch) => dispatch(addLocation(name))
}

export const ACTION_SET_UPGRADE_LOCATION_DIALOG_OPEN = 'ACTION_SET_UPGRADE_LOCATION_DIALOG_OPEN'
export function setUpgradeLocationDialogOpen(open: boolean) {
  return {
    type: ACTION_SET_UPGRADE_LOCATION_DIALOG_OPEN,
    payload: open
  }
}
