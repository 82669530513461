import { Observable } from 'rxjs/Observable'
import { StoreThunkAction, StoreThunkDispatch } from 'store/state'
import { LocationInfo } from '../interfaces'
import { V1 } from './net'

export function searchInstagramLocation(query: string): StoreThunkAction<Observable<LocationInfo[]>> {
  return (dispatch: StoreThunkDispatch) => {
    return (dispatch(V1.searchLocations(query)))
      .map(response => {
        const locations: Array<LocationInfo> = []
        response.feeds.facebook.forEach((feed: any) => {
          // NOTE: To be eligible for tagging, a Page must have latitude and longitude location data.
          if (feed.location?.latitude && feed.location?.longitude) {
            locations.push({
              id: feed.id,
              name: feed.name,
              city: feed.location?.city || '',
              country: feed.location?.country || '',
              imageUrl: feed.profilePicture
            })
          }
        })
        return locations
      })
  }
}
