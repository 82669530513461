import { createElement } from 'react'
import Loader from 'components/SimpleLoader'
import loadable, { LoadableComponent } from '@loadable/component'

const AsyncSingleStreamView: LoadableComponent<any> = loadable<any>(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: 'stream-view' */
  './SingleStreamView'
), {
  fallback: createElement(Loader, { title: 'Loading SingleStreamView...' })
})

export default AsyncSingleStreamView
