import * as React from 'react'
import { connect } from 'react-redux'
import StoreState, { StoreDispatch } from 'store/state'
import { contentPreviewContentSelector, contentPreviewActiveSelector } from 'services/contentPreview/selectors'
import { closeContentPreviewWindow } from 'services/contentPreview'
import Dialog from '@mui/material/Dialog'
import EmbeddedTweet from 'components/EmbeddedTweet'
import { FEED_TYPE_FACEBOOK, FEED_TYPE_TWITTER, FEED_TYPE_KEYWORD, Gif, Video, Photo, Article, FEED_TYPE_REDDIT } from 'interfaces'
import EmbeddedFBPost from 'components/EmbeddedFBPost/EmbeddedFBPost'
import EmbeddedRedditPost from 'components/EmbeddedRedditPost'
import EmbeddedYTPost from 'components/EmbeddedYTPost/EmbeddedYTPost'
import parseUrlDomain from 'utils/format/parseUrlDomain'

import styles from './ContentPreviewWindow.pcss'
import SimpleLoader from 'components/SimpleLoader'

interface ConnectedContentPreviewWindowProps {
  active: boolean
  content?: Photo | Video | Article | Gif
  close: () => void
}

export interface ContentPreviewWindowProps extends ConnectedContentPreviewWindowProps { }

export function ContentPreviewWindow(props: ContentPreviewWindowProps) {
  const [loading, setLoading] = React.useState(true)
  const contentItem = props.content
  const hasContent = Boolean(contentItem)
  const socialLink = hasContent ? (contentItem as any).socialLink : ''
  const isTwitter = contentItem && contentItem.feed.type === FEED_TYPE_TWITTER
  const isFacebook = contentItem && contentItem.feed.type === FEED_TYPE_FACEBOOK
  const isReddit = contentItem && contentItem.feed.type === FEED_TYPE_REDDIT
  const isRedditGalleryImage = isReddit && (contentItem as Photo).isRedditGallery
  const isYouTube = contentItem && contentItem.feed.type === FEED_TYPE_KEYWORD && (/youtube/i).test(parseUrlDomain(socialLink) || '')

  const close = () => {
    props.close()
    setLoading(true)
  }

  const onContentLoaded = () => {
    setLoading(false)
  }

  const error = !contentItem
    // eslint-disable-next-line react/no-unescaped-entities
    ? <h2>Something went wrong and we couldn't load your content.</h2>
    : null

  return (
    <Dialog open={props.active} classes={{ paper: styles.dialog }} data-test="content-preview-window" onClose={close}>
      <div className={styles['player-box']}>
        {
          loading && (
            <div className={styles.loader}>
              <SimpleLoader small />
              {contentItem?.socialLink && (
                <div className={styles['loading-message']}>
                  <p>Loading content...</p>
                  <p>See <a href={contentItem.socialLink} target="_blank" rel="noopener">original post</a></p>
                </div>
              )}
            </div>
          )
        }
        {
          hasContent && isFacebook && (
            <EmbeddedFBPost url={socialLink} />
          )
        }
        {
          hasContent && isTwitter && (
            <EmbeddedTweet url={socialLink} onLoaded={onContentLoaded} />
          )
        }
        {
          hasContent && isReddit && (
            <div>
              <EmbeddedRedditPost post={contentItem as any} />
              {isRedditGalleryImage && (
                <div className={styles['error-box']}>
                  Preview not available.
                  {Boolean(socialLink) && <a href={socialLink} target="_blank" rel="noopener">See original post.</a>}
                </div>
              )}
            </div>
          )
        }
        {
          hasContent && isYouTube && (
            <EmbeddedYTPost url={socialLink} onReady={onContentLoaded} />
          )
        }
      </div>
      {error}
    </Dialog>
  )
}

function mapStateToProps(state: StoreState) {
  return {
    active: contentPreviewActiveSelector(state),
    content: contentPreviewContentSelector(state)
  }
}

function mapDispatchToProps(dispatch: StoreDispatch) {
  return {
    close: () => dispatch(closeContentPreviewWindow())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentPreviewWindow)
