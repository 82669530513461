import * as React from 'react'
import { PlannedPost } from 'interfaces'
import ScrollListener from 'components/ScrollListener'
import styles from './PostsGridView.pcss'
import PostGridItem, { DraggablePostGridItem } from './PostGridItem'
import { BulkPostActions, BulkPostActionsProps } from 'routes/publishing/components/BulkActionsMenus'
import { DndContext, MeasuringStrategy, useSensors, PointerSensor, useSensor, DragOverlay } from '@dnd-kit/core'
import { useDispatch, useSelector } from 'react-redux'
import { selectedProfilesSelector } from 'services/post/selectors'
import { message } from 'services/snackbar'
import { DRAG_DROP_WARNING, INVALID_DROP_MESSAGE } from '../PostsCalendarView/PostsCalendarView'
import itemStyles from './PostGridItem.pcss'
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'

const SCROLL_TRESHOLD = 350
interface PostsGridViewProps {
  posts: PlannedPost[]
  selectedPosts: Record<string, boolean> | 'all'
  bulkActionsProps: BulkPostActionsProps
  withDragAndDrop: boolean
  onLoadNextPage: () => void
  onPostClick: (post: PlannedPost) => void
  onTogglePostSelected: (postId: string) => void
  onEditPost: (post: PlannedPost) => void
  onCopyPost: (post: PlannedPost) => void
  onDeletePost: (id: string) => void
  onShareNow: (post: PlannedPost) => void
  onPostOrderChanged?: (movedPost: PlannedPost, targetPost: PlannedPost) => void
}

export function PostsGridView(props: PostsGridViewProps) {
  const dispatch = useDispatch()
  const { posts, onPostOrderChanged } = props
  const selectedProfiles = useSelector(selectedProfilesSelector)
  const [movingPost, setMovingPost] = React.useState<PlannedPost | null>(null)
  const [postsMap, setPostsMap] = React.useState<Record<string, PlannedPost & { index: number }>>({})
  const [order, setOrder] = React.useState(posts.map(p => p.id))

  React.useEffect(() => {
    setOrder(posts.map(p => p.id))
  }, [posts])

  React.useEffect(() => {
    let index = 0
    const postIndex: Record<string, PlannedPost & { index: number }> = {}
    posts.forEach((post: PlannedPost) => {
      if (!post.isEmpty && !post.isPosted) {
        postIndex[post.id] = { ...post, index: index++ }
      }
    }, {})
    setPostsMap(postIndex)
  }, [posts])

  const scrollElementRef = React.useRef<HTMLElement | undefined>(undefined)

  React.useLayoutEffect(() => {
    scrollElementRef.current = document.querySelector('[data-test="main"]') as HTMLElement
  }, [])

  const onDragEnd = React.useCallback((event: any) => {
    if (selectedProfiles.length > 1) {
      return
    }
    const { over } = event
    const fromPost = postsMap[movingPost?.id || '']
    const toPost = postsMap[over?.data?.current?.post?.id || '']
    setMovingPost(null)
    if (!fromPost || !toPost || fromPost?.id === toPost?.id) {
      return
    }
    if (
      !toPost
      || !toPost.autoPost
      || toPost.profileId !== fromPost.profileId
      || fromPost?.bucketId !== toPost?.bucketId
    ) {
      // eslint-disable-next-line no-magic-numbers
      dispatch(message(INVALID_DROP_MESSAGE, 'warning', 5000, true))
      return
    }
    if (onPostOrderChanged) {
      onPostOrderChanged(fromPost, toPost)
    }

  }, [dispatch, movingPost?.id, onPostOrderChanged, postsMap, selectedProfiles.length])

  const onDragStart = (start: any) => {
    if (selectedProfiles.length > 1) {
      // eslint-disable-next-line no-magic-numbers
      dispatch(message(DRAG_DROP_WARNING, 'info', 5000))
    } else {
      setMovingPost(start.active?.data?.current?.post || null)
    }
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 4
      }
    })
  )

  return (
    <ScrollListener
      scrollElement={scrollElementRef.current}
      emitTreshold={SCROLL_TRESHOLD}
      onScroll={props.onLoadNextPage}
    >
      <div>
        <div className={styles.actions}>
          <BulkPostActions {...props.bulkActionsProps} />
        </div>
        <DndContext
          sensors={sensors}
          measuring={{ strategy: MeasuringStrategy.Always }}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
        >
          <SortableContext items={order} strategy={rectSortingStrategy}>
            <div className={styles.grid}>
              {props.posts.map(post => {
                if (post.isEmpty || post.isPosted) {
                  return (
                    <div className={itemStyles['post-container']}>
                      <PostGridItem
                        key={post.id}
                        post={post}
                        selected={props.selectedPosts === 'all' || !!props.selectedPosts[post.id]}
                        className={styles['grid-item']}
                        onClick={props.onPostClick}
                        onToggleSelected={props.onTogglePostSelected}
                        onCopy={props.onCopyPost}
                        onDelete={props.onDeletePost}
                        onEdit={props.onEditPost}
                        onShareNow={props.onShareNow}
                      />
                    </div>
                  )
                }
                let dndClass: string = ''
                if (
                  movingPost
                  && movingPost.profileId === post.profileId
                  && movingPost.id !== post.id
                  && movingPost.bucketId === post.bucketId
                ) {
                  dndClass = itemStyles['drop-target']
                }
                return (
                  <DraggablePostGridItem
                    key={post.id}
                    post={post}
                    selected={props.selectedPosts === 'all' || !!props.selectedPosts[post.id]}
                    className={styles['grid-item']}
                    containerClassName={dndClass}
                    onClick={props.onPostClick}
                    onToggleSelected={props.onTogglePostSelected}
                    onCopy={props.onCopyPost}
                    onDelete={props.onDeletePost}
                    onEdit={props.onEditPost}
                    onShareNow={props.onShareNow}
                  />
                )
              })}
            </div>
          </SortableContext>
        </DndContext>
      </div>
    </ScrollListener>
  )
}

export default PostsGridView
