import { getState, StoreThunkAction, StoreThunkDispatch } from 'store/state'
import { BRAND_FACEBOOK, BRAND_INSTAGRAM, BRAND_LINKEDIN, BRAND_PINTEREST, BRAND_TWITTER, ContentItem, Video } from 'interfaces'
import { BRAND_GOOGLE } from 'interfaces/Content/SocialBrand'
import {
  composerBulkPostsSelector,
  composerSelectedBucketIdSelector,
  composerSelectedProfilesSelector,
  composerTabsSelector,
  genericPostSelector,
  useGenericStatusSelector
} from '../selectors'
import { ComposerFacebookPost, ComposerInstagramPost, ComposerPinterestPost, ComposerTwitterPost } from '../interfaces'
import { CreatePostRequestData } from 'shared/types'
import {
  getFBPostTextFromHtml,
  getTagsInfoFromHtml,
  getLinkedinFormattedText,
  generateBulkPostCaptionFromHtml,
  getTextFromHtml
} from 'utils/composer'
import { BulkContentPost } from '../state'
import { Observable } from 'rxjs/Observable'
import { V1 } from './net'
import { tap } from 'rxjs/operators/tap'
import { trackTrainingEvent } from 'services/users/actions'
import { EVENT_QUEUE_POSTS } from 'routes/trial/interfaces'
import { ComposerGooglePost } from '../interfaces/ComposerGooglePost'
import { getBuckets } from 'services/post/actions'
import { BRAND_TIKTOK, BRAND_YOUTUBE, STOCK_VIDEO_TYPE, VIDEO_TYPE } from 'shared'
import { ComposerYoutubePost } from '../interfaces/ComposerYoutubePost'
import { ComposerTikTokPost } from '../interfaces/ComposerTikTokPost'
import { ComposerLinkedinPost } from '../interfaces/ComposerLinkedinPost'

export function getMultiPostsData(shareType: 'queue' | 'next'): StoreThunkAction<CreatePostRequestData[]> {
  return (_dispatch: StoreThunkDispatch, getState: getState) => {
    const state = getState()
    const networks = composerTabsSelector(state)
    const allProfiles = composerSelectedProfilesSelector(state)
    const allPosts = composerBulkPostsSelector(state)
    const genericStatus = genericPostSelector(state).status
    const posts = allPosts.filter(post => networks.includes(post.network))
    const useGenericStatus = useGenericStatusSelector(state)

    return posts.map((post: BulkContentPost) => {
      const recycle = post.recycle
      const link = post.article
      const featuredImageUrl = post.imageUrl || post.content?.imageUrl
      const pinterestBoards = post.network === BRAND_PINTEREST ? (post.data as ComposerPinterestPost).boards || [] : []
      const ppPageIds: number[] = []
      const { html: genericStatusHtml } = generateBulkPostCaptionFromHtml(genericStatus.html, post)
      const statusText = useGenericStatus ? getTextFromHtml(genericStatusHtml) : null

      allProfiles.forEach(profile => {
        if (profile.type === post.network) {
          ppPageIds.push(parseInt(profile.ppid, 10)) // eslint-disable-line no-magic-numbers
        }
      })

      const video = post.content?.type === VIDEO_TYPE || post.content?.type === STOCK_VIDEO_TYPE ? post.content : null

      const data: CreatePostRequestData = {
        postType: video ? 'video_link' : featuredImageUrl ? 'photo' : 'link',
        ppPageIds,
        networks: {},
        frontendId: post.id,
        shareType
      }

      if (recycle) {
        data.recycle = true
        if (typeof recycle === 'object') {
          data.recycleUntil = recycle.toISOString()
        } else if (typeof recycle === 'number') {
          data.recycleTimes = recycle
        }
      }

      if (featuredImageUrl) {
        data.photos = [featuredImageUrl]
      }

      if (link) {
        data.link = {
          title: link.title,
          url: link.url,
          description: link.description
        }
        data.postType = 'link'
        data.photos = [link.imageUrl]
      }

      if (video) {
        data.link = {
          title: (video as Video).title,
          description: (video as Video).description,
          url: video.socialLink
        }
      }

      if (post.videoUrl) {
        data.videoUrl = post.videoUrl
        data.postType = 'video'
      }

      if (post.content) {
        let date = (post.content as ContentItem).createdAt
        if (typeof date === 'number' || typeof date === 'string') {
          date = new Date(date).toISOString()
          data.sourcePost = { date }
        } else {
          data.sourcePost = { date: date.toISOString() }
        }
        data.sourcePost.feedName = post.content.feed.name
        data.sourcePost.feedType = post.content.feed.type
        data.sourcePost.socialLink = post.content.socialLink
        data.sourcePost.sourceLink = (post.content as any).sourceLink || post.content.socialLink
      }

      switch (post.network) {
        case BRAND_FACEBOOK: {
          const status = statusText || getFBPostTextFromHtml(post.data.status.html)
          const tags = getTagsInfoFromHtml(post.data.status.html)
          const facebookPost: any = { status, details: {} }
          const fbComposerPost = post.data as ComposerFacebookPost
          const postType = fbComposerPost.postType
          const selectedAlbums = fbComposerPost.albums || []

          if (postType) {
            facebookPost.details.postType = postType
          }
          if (tags.length > 0) {
            facebookPost.details.tags = tags
          }
          if (selectedAlbums.length > 0) {
            facebookPost.details.albums = selectedAlbums.map(a => ({ ...a, ppPageId: a.ppid }))
          }

          if (fbComposerPost.reshare) {
            facebookPost.reshare = true
            if (post.content?.feed.type === BRAND_FACEBOOK) {
              facebookPost.details.reshareUrl = post.content.socialLink
              facebookPost.details.reshareName = post.content.feed.name
            }
          }

          if (fbComposerPost.firstComment) {
            facebookPost.details.firstComment = fbComposerPost.firstComment
          }

          data.networks.facebook = facebookPost
          break
        }

        case BRAND_TWITTER: {
          const status = statusText || post.data.status.text
          const twTags = getTagsInfoFromHtml(post.data.status.html)
          const twitterPost: any = {
            status,
            details: {
              tweetType: (post.data as ComposerTwitterPost).tweetType
            }
          }
          if (twTags.length > 0) {
            twitterPost.details.tags = twTags
          }
          if ((post.content as ContentItem)?.feed.type === BRAND_TWITTER) {
            twitterPost.details.tweetUrl = (post.content as ContentItem).socialLink
            data.sourcePost = data.sourcePost || {}
            data.sourcePost.feedName = (post.content as ContentItem).feed.name
            data.sourcePost.text = (post.content as ContentItem).tweetText
          }
          data.networks.twitter = twitterPost
          break
        }

        case BRAND_INSTAGRAM: {
          const instagramPost: any = {
            status: statusText || post.data.status.text,
            details: {}
          }
          const { locations, firstComment, postType } = post.data as ComposerInstagramPost
          const locationsArray = Object.keys(locations || {}).reduce((arr: any[], ppid) => {
            if (locations[ppid]) {
              arr.push({ ...locations[ppid], ppPageId: ppid })
            }
            return arr
          }, [])

          if (postType) {
            instagramPost.details.ig_post_type = postType
          }

          if (locationsArray.length > 0) {
            instagramPost.details.locations = locationsArray
          }

          if (firstComment) {
            instagramPost.details.firstComment = firstComment
          }

          if (Object.keys(instagramPost.details).length === 0) {
            delete instagramPost.details
          }

          data.networks.instagram = instagramPost
          break
        }

        case BRAND_PINTEREST: {
          const pin = post.data as ComposerPinterestPost
          const pinterestPost: any = {
            status: pin.title,
            description: statusText || pin.description || '',
            destinationUrl: pin.destinationUrl,
            details: { boards: [] }
          }
          if (pinterestBoards.length > 0) {
            pinterestPost.details.boards = pinterestBoards.map(b => ({ ...b, ppPageId: b.ppid }))
          }
          data.networks.pinterest = pinterestPost
          break
        }

        case BRAND_YOUTUBE: {
          data.networks.youtube = {
            status: (post.data as ComposerYoutubePost).title,
            description: statusText || (post.data as ComposerYoutubePost).description || '',
            details: {
              privacyStatus: (post.data as ComposerYoutubePost).privacyStatus
            }
          }
          break
        }

        case BRAND_LINKEDIN: {
          const { tags, text } = getLinkedinFormattedText(post.data.status.html)

          const linkedInPost: any = {
            status: statusText || post.data.status.text,
            details: { tags, text }
          }
          data.networks.linkedin = linkedInPost

          const firstComment = (post.data as ComposerLinkedinPost).firstComment
          if (firstComment) {
            linkedInPost.details.firstComment = firstComment
          }
          break
        }

        case BRAND_GOOGLE: {
          const status = statusText || post.data.status.text
          const googlePost: any = {
            status,
            details: {
              buttonUrl: (post.data as ComposerGooglePost).link,
              buttonType: (post.data as ComposerGooglePost).buttonType
            }
          }

          data.networks.google = googlePost
          break
        }

        case BRAND_TIKTOK: {
          data.networks.tiktok = {
            status: statusText || post.data.status.text,
            details: {
              settings: Object.values((post.data as ComposerTikTokPost).settings)
            }
          }
        }
      }
      return data
    })
  }
}

export function createMultiPost(shareType: 'queue' | 'next', onBeforeCreate?: () => void): StoreThunkAction<Observable<any>> {
  return (dispatch, getState) => {
    const state = getState()
    try {
      const data = dispatch(getMultiPostsData(shareType))
      const selectedBucketId = composerSelectedBucketIdSelector(state)

      if (onBeforeCreate) {
        onBeforeCreate()
      }
      return dispatch(V1.createPost(data, selectedBucketId)).pipe(tap((response: any) => {
        dispatch(trackTrainingEvent({ eventCode: EVENT_QUEUE_POSTS, value: response.count }))
        if (selectedBucketId) {
          dispatch(getBuckets())
        }
      }))
    } catch (error) {
      return Observable.of({ error })
    }
  }
}
