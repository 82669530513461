import * as React from 'react'
import { FileFolder, GIF_TYPE, PHOTO_TYPE, VIDEO_TYPE } from 'interfaces'
import ImageIcon from '@mui/icons-material/Image'
import VideoIcon from '@mui/icons-material/Videocam'
import GifIcon from '@mui/icons-material/Gif'
import { getGradientStyle } from 'utils/colors'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'
import ConfirmDialog from 'components/ConfirmDialog'
import Tooltip from '@mui/material/Tooltip'
import IconDelete from '@mui/icons-material/Close'

import styles from './FileFolderCard.pcss'
const cssVariables = require('styles/variables')
const BASE_TEN = 10
const DEFAULT_CARD_WIDTH = parseInt(cssVariables['--circle-card-default-size'], BASE_TEN)
const NAVIGATION_URL_DEFAULT = '/content/library'

export interface FileFolderCardProps {
  folder: FileFolder
  navUrl?: string
  location?: string
  className?: string
  deleteConfirmationMessage?: string
  disableNavigation?: boolean
  hideFileCount?: boolean
  noHover?: boolean
  onDelete?: (folder: FileFolder) => void
  onClick?: (folder: FileFolder) => void
  onSelectImageClick?: () => void
}

export const FileFolderCard = React.memo(function FileFolderCard(props: FileFolderCardProps) {
  const folder = props.folder
  const element = React.useRef<HTMLDivElement>(null)
  const colorStyle = getGradientStyle(folder.color, 'top left')

  const onMouseEnter = () => {
    if (element.current) {
      element.current.classList.add(styles.raised)
    }
  }

  const onMouseLeave = () => {
    if (element.current) {
      element.current.classList.remove(styles.raised)
    }
  }

  const onClick = () => {
    if (props.onClick) {
      props.onClick(folder)
    }
  }

  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete(folder)
    }
  }

  const navUrl = `${props.navUrl || NAVIGATION_URL_DEFAULT}/${folder.id}`

  const navigation = props.location ? {
    pathname: navUrl,
    state: {
      backUrl: props.location
    }
  } : navUrl

  const onNavigate = (e: React.MouseEvent) => {
    if (props.disableNavigation) {
      e.preventDefault()
    }
  }

  const decorStyle = { backgroundColor: folder.color.split(',')[1] }
  let secondaryImage1
  let secondaryImage2
  let withSecondaryImage
  let secondaryImage1Styles
  let secondaryImage2Styles
  if (folder.coverImages && folder.coverImages.length > 1) {
    withSecondaryImage = true
    secondaryImage1 = withSecondaryImage && folder.coverImages[0]
    secondaryImage1Styles = { backgroundImage: `url(${secondaryImage1})` }
    secondaryImage2 = withSecondaryImage && folder.coverImages[1]
    secondaryImage2Styles = { backgroundImage: `url(${secondaryImage2})` }
  }
  const featuredImageBoxStyle = {
    backgroundImage: `url(${folder.featuredImage})`
  }

  const withImage = Boolean(folder.featuredImage)

  return (
    <div
      ref={element}
      className={`${styles.card} ${styles['card-folder']} ${props.className || ''} ${props.noHover ? styles.nohover : ''}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {
        props.onDelete && (
          <ConfirmDialog message={props.deleteConfirmationMessage || 'Are you sure?'}>
            {(confirm) => (
              <Tooltip title={<FormattedMessage id="actions.remove" />} placement="top">
                <IconDelete
                  fontSize="small"
                  role="button"
                  className={styles['btn-delete']}
                  onClick={confirm(onDelete)}
                />
              </Tooltip>
            )}
          </ConfirmDialog>
        )
      }
      <div className={styles.decor} style={decorStyle}></div>
      <NavLink
        to={navigation}
        className={styles.border}
        style={colorStyle}
        onClick={onNavigate}
      >
        <div className={styles['bg-overlay']}></div>
        <h5 className={`${styles.title} text-ellipsis`}>{folder.name}</h5>

        <div className={styles.files}>
          {
            !props.hideFileCount && (
              <React.Fragment>
                {folder.filesCountByType && folder.filesCountByType[PHOTO_TYPE] && (
                  <div className={styles['stats-box']}>
                    <ImageIcon fontSize="small" className={styles['icon-image']} />
                    <span className={styles.label}>{folder.filesCountByType[PHOTO_TYPE]}</span>
                  </div>
                )}
                {folder.filesCountByType && folder.filesCountByType[VIDEO_TYPE] && (
                  <div className={styles['stats-box']}>
                    <VideoIcon fontSize="small" className={styles['icon-video']} />
                    <span className={styles.label}>{folder.filesCountByType[VIDEO_TYPE]}</span>
                  </div>
                )}
                {folder.filesCountByType && folder.filesCountByType[GIF_TYPE] && (
                  <div className={styles['stats-box']}>
                    <GifIcon fontSize="small" className={styles['icon-gif']} />
                    <span className={styles.label}>{folder.filesCountByType[GIF_TYPE]}</span>
                  </div>
                )}
              </React.Fragment>
            )
          }
        </div>
        <div className={styles.images}>
          {withSecondaryImage && <div className={styles['img-box']} style={secondaryImage1Styles}></div>}
          <div className={`${styles['img-box']} ${styles.featured}`} style={featuredImageBoxStyle}>
            {!props.hideFileCount && (
              <div className={`${styles.overlay} ${styles['files-count']} ${!withImage ? styles.visible : ''}`}>
                <p className={styles['stats-large']}>
                  {folder.filesCount}
                </p>
                <p><FormattedMessage id="general.filefolder.items-label" values={{ count: folder.filesCount }} /></p>
              </div>
            )}
            {Boolean(props.onSelectImageClick) && (
              <div className={styles['no-img-box']} onClick={props.onSelectImageClick}>
                <ImageIcon className={styles['icon-img']} />
                <p className={styles['img-label']}>
                  {withImage && <FormattedMessage id="general.stream.label-change-image" />}
                  {!withImage && <FormattedMessage id="general.stream.label-add-image" />}
                </p>
              </div>
            )}
          </div>
          {withSecondaryImage && <div className={styles['img-box']} style={secondaryImage2Styles}></div>}
        </div>
      </NavLink>
    </div>
  )
})

export default FileFolderCard
