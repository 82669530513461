import { Component } from 'react'
import {
  Feed,
  Photo,
  Article,
  AnyContent,
  Video,
  Gif,
  ARTICLE_TYPE,
  PHOTO_TYPE,
  VIDEO_TYPE,
  GIF_TYPE,
  FilterType,
  Text
} from 'interfaces'
import { TEXT_TYPE } from 'interfaces/Content/ContentType'
import {
  STOCK_VIDEO_TYPE,
  STOCK_GIF_TYPE,
  STOCK_PHOTO_TYPE,
  STOCK_ALL_FILTER
} from 'interfaces/Content/StockContentTypes'
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/of'
import 'rxjs/add/observable/empty'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/mergeAll'
import 'rxjs/add/operator/toArray'
import 'rxjs/add/operator/observeOn'
import 'rxjs/add/operator/defaultIfEmpty'
import { animationFrame } from 'rxjs/scheduler/animationFrame'
import { Subscription } from 'rxjs/Subscription'
import { createHandledPipe } from './FetchError'

export interface FetchFeedContentProps {
  feed: Feed
  hash?: string
  only?: FilterType

  getVideos(): Observable<Video>
  getPhotos(): Observable<Photo>
  getArticles(): Observable<Article>
  getGifs(): Observable<Gif>
  getTexts(): Observable<Text>
  getStockVideos(): Observable<Video>
  getStockPhotos(): Observable<Photo>
  getStockGifs(): Observable<Gif>
  getStockAll(): Observable<AnyContent>
  onFetched(content: AnyContent[]): void
  onFailed?(err: any): void
}

export class FetchFeedContent extends Component<FetchFeedContentProps, any> {

  private subscription: Subscription

  constructor(props: FetchFeedContentProps) {
    super(props)

    this.onFetched = this.onFetched.bind(this)
    this.onFailed = this.onFailed.bind(this)
  }

  shouldComponentUpdate(nextProps: FetchFeedContentProps) {
    return nextProps.feed.id !== this.props.feed.id
      || nextProps.hash !== this.props.hash
  }

  onFetched(content: AnyContent[]) {
    this.props.onFetched(content)
  }

  onFailed(err: any) {
    console.log(err)
    if (this.props.onFailed) {
      this.props.onFailed(err)
    }
  }

  fetch() {
    const only = this.props.only
    let contentTypes = this.props.feed.sources.reduce((result, type) => {
      result[type] = !only || only === type ? true : false
      return result
    }, {} as { [key: string]: boolean })

    // If we don't have the types of sources for that feed, fetch photos, videos and articles by default
    if (this.props.feed.sources.length === 0) {
      contentTypes[VIDEO_TYPE] = true
      contentTypes[PHOTO_TYPE] = true
      contentTypes[ARTICLE_TYPE] = true
    }

    if (only === STOCK_ALL_FILTER) {
      contentTypes = {
        [STOCK_ALL_FILTER]: true
      }
    }

    const requests: Observable<any>[] = []
    if (contentTypes[VIDEO_TYPE]) {
      requests.push(createHandledPipe<AnyContent>(VIDEO_TYPE, this.props.getVideos(), this.onFailed))
    }

    if (contentTypes[PHOTO_TYPE]) {
      requests.push(createHandledPipe<AnyContent>(PHOTO_TYPE, this.props.getPhotos(), this.onFailed))
    }

    if (contentTypes[ARTICLE_TYPE]) {
      requests.push(createHandledPipe<AnyContent>(ARTICLE_TYPE, this.props.getArticles(), this.onFailed))
    }

    if (contentTypes[GIF_TYPE]) {
      requests.push(createHandledPipe<AnyContent>(GIF_TYPE, this.props.getGifs(), this.onFailed))
    }

    if (contentTypes[TEXT_TYPE]) {
      requests.push(createHandledPipe<AnyContent>(TEXT_TYPE, this.props.getTexts(), this.onFailed))
    }

    if (contentTypes[STOCK_VIDEO_TYPE]) {
      requests.push(createHandledPipe<AnyContent>(STOCK_VIDEO_TYPE, this.props.getStockVideos(), this.onFailed))
    }

    if (contentTypes[STOCK_PHOTO_TYPE]) {
      requests.push(createHandledPipe<AnyContent>(STOCK_PHOTO_TYPE, this.props.getStockPhotos(), this.onFailed))
    }

    if (contentTypes[STOCK_GIF_TYPE]) {
      requests.push(createHandledPipe<AnyContent>(STOCK_GIF_TYPE, this.props.getStockGifs(), this.onFailed))
    }

    if (contentTypes[STOCK_ALL_FILTER]) {
      requests.push(createHandledPipe<AnyContent>(STOCK_ALL_FILTER, this.props.getStockAll(), this.onFailed))
    }

    return Observable.of(...requests)
      .mergeAll()
      .toArray()
      .observeOn(animationFrame)
      .subscribe(this.onFetched, this.onFailed)
  }

  componentDidMount() {
    this.subscription = this.fetch()
  }

  componentDidUpdate() {
    this.subscription.unsubscribe()
    this.subscription = this.fetch()
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    return null
  }
}

export default FetchFeedContent
