import React, { useEffect } from 'react'
import styles from './ContentSelectionDrawer.pcss'
import { useDispatch, useSelector } from 'react-redux'
import { contentSelectionSelector } from 'services/content/selectors'
import IconToggle from '@mui/icons-material/KeyboardArrowUp'
import CardList from 'components/CardList'
import { clearContentSelection, removeContentFromSelection, selectAllContent } from 'services/content'
import MiniContentCard from './MiniContentCard'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'
import { Article, ARTICLE_TYPE, ContentItem, GIF_TYPE, MyFile, Photo, PHOTO_TYPE } from 'interfaces'
import { STOCK_PHOTO_TYPE } from 'interfaces/Content/StockContentTypes'
import {
  addComposerImages,
  resetComposer,
  setComposerContent,
  addArticlePosts,
  addImagePosts,
  addMultipostVideoUrl,
  addVideoPosts
} from 'services/compose'
import { useLocation, useNavigate } from 'react-router-dom'
import { ArticleBase } from 'services/compose/interfaces/ArticleBase'
import Icon from '@mdi/react'
import { mdiFeather, mdiViewCarouselOutline } from '@mdi/js'
import { STOCK_VIDEO_TYPE, VIDEO_TYPE } from 'shared'
import { instanceOfMyFile } from 'services/content/util'
import { composerMultiPostSelector } from 'services/compose/selectors'

const LIST_HEIGHT = 108

export function ContentSelectionDrawer() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const multiposts = useSelector(composerMultiPostSelector).posts
  const selection = useSelector(contentSelectionSelector)
  const items = Object.values(selection.items)
  const itemsOnPage = Object.values(selection.itemsOnPage)
  const [open, setOpen] = React.useState(items.length > 0)

  const hasMultiposts = Object.keys(multiposts).length > 0

  useEffect(() => {
    // Open the drawer when first item is selected
    if (items.length === 1) {
      setOpen(true)
    }
  }, [items.length])

  const toggleDrawer = () => {
    setOpen(!open)
  }

  const removeContent = (id: string) => {
    dispatch(removeContentFromSelection(id))
  }

  const photos = items.filter(item => item.type === PHOTO_TYPE || item.type === STOCK_PHOTO_TYPE)
  const photosCount = photos.length
  const withCarouselAction = items.length > 1 && photosCount === items.length

  const onContentAddedToComposer = () => {
    dispatch(clearContentSelection())
    navigate('/composer', { state: { floating: true, modal: 'compose', opener: location.pathname, background: location } })
  }

  const addCarouselPost = () => {
    const imageUrls = photos.map(photo => (photo as Photo).imageUrl || (photo as MyFile).url)
    if (imageUrls.length > 0) {
      dispatch(resetComposer())
      dispatch(addComposerImages(imageUrls))
      onContentAddedToComposer()
    }
  }

  const selectAll = () => {
    dispatch(selectAllContent())
  }

  const allSelected = !itemsOnPage.some(item => selection.items[item.id] === undefined)

  const addMultiPost = () => {
    const articles: Array<{ article: ArticleBase, content?: ContentItem }> = []
    const images: Array<{ url: string, content?: ContentItem }> = []
    const videos: ContentItem[] = []
    const videoUrls: string[] = []

    items.forEach(item => {
      if (item.type === ARTICLE_TYPE) {
        const article = item as Article
        articles.push({
          article: {
            imageUrl: article.imageUrl,
            title: article.title,
            url: article.sourceLink,
            description: article.description
          },
          content: article
        })
        return
      }
      if (instanceOfMyFile(item)) {
        if (item.type === PHOTO_TYPE || item.type === GIF_TYPE) {
          images.push({ url: item.url })
        } else if ((item as MyFile).type === VIDEO_TYPE) {
          videoUrls.push(item.url)
        }
        return
      }
      if (item.type === STOCK_VIDEO_TYPE || item.type === VIDEO_TYPE) {
        videos.push(item as ContentItem)
        return
      }
      images.push({ url: item.imageUrl, content: item })
    })
    const count = images.length + articles.length + videos.length + videoUrls.length
    if (count > 0) {
      if (!hasMultiposts) {
        dispatch(resetComposer())
      }
      if (count === 1) {
        const content = articles[0]?.content || images[0]?.content || videos[0]
        if (content) {
          dispatch(setComposerContent({ content }))
        } else if (images[0] && images[0].url) {
          dispatch(addComposerImages([images[0].url]))
        }
      } else {
        dispatch(addImagePosts(images))
        dispatch(addArticlePosts(articles))
        dispatch(addVideoPosts(videos))
        dispatch(addMultipostVideoUrl(videoUrls))
      }
      onContentAddedToComposer()
    }
  }

  const clearSelection = () => {
    dispatch(clearContentSelection())
  }

  return (
    <div className={`${styles.drawer} ${open ? styles.open : ''} ${items.length === 0 ? styles.hidden : ''}`}>
      <div className={styles['btn-toggle']} onClick={toggleDrawer}>
        <IconToggle className={styles['icon-toggle']} />
      </div>
      <div className={styles.actions}>
        <Button
          color="primary"
          variant="contained"
          startIcon={<Icon size="18px" path={mdiFeather} />}
          onClick={addMultiPost}
        >
          <FormattedMessage
            id={hasMultiposts ? 'content.selection-panel.actions.multi-post-add' : 'content.selection-panel.actions.multi-post'}
            values={{ count: items.length }}
          />
        </Button>
        {withCarouselAction && (
          <Button
            startIcon={<Icon size="18px" path={mdiViewCarouselOutline} />}
            onClick={addCarouselPost}
          >
            <FormattedMessage id="content.selection-panel.actions.carousel" />
          </Button>
        )}
        {itemsOnPage.length > 1 && (
          <div
            role="button"
            className={`${styles['btn-select-all']} ${allSelected && styles.disabled}`}
            onClick={allSelected ? undefined : selectAll}
          >
            <FormattedMessage id="content.selection-panel.actions.select-all" values={{ count: itemsOnPage.length }} />
          </div>
        )}
        <div role="button" className={styles['btn-clear']} onClick={clearSelection}>
          <FormattedMessage id="content.selection-panel.actions.clear-all" />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.list}>
          <CardList listHeight={LIST_HEIGHT}>
            {items.map(item => (
              <MiniContentCard key={item.id} content={item} onRemove={removeContent} />
            ))}
          </CardList>
        </div>
      </div>
    </div>
  )
}

export default ContentSelectionDrawer
