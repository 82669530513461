import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { api } from './api'
import { recentSourcesAdapter } from '../adapters'
import { recentSourcesSelector } from '../selectors'
import StoreState from 'store/state'
import { instanceOfStream } from '../util'
import { getStream } from '../streams/actions'
import { Feed, InteractionEvent, InteractionTrackingSource, InteractionTrackingSourceType } from 'interfaces'
import { V1 as quotesApi } from '../quotes/net'
import { QuoteFeed } from 'interfaces/Content/QuoteFeed'
import { streamsAdapter } from '../adapters/recommended'
import { impersonationSelector } from 'admin/services/selectors'

const RECENT_SOURCES_PAGE_SIZE_DEFAULT = 20

export const getRecentSources = createAsyncThunk(
  'content/recent/get',
  async (page: number = 1, { dispatch }) => {
    const response = await dispatch(api.getRecentSources(page, RECENT_SOURCES_PAGE_SIZE_DEFAULT)).toPromise()
    return {
      items: recentSourcesAdapter(response),
      page
    }
  }
)

export const getMostRecent = createAsyncThunk(
  'content/recent/getMostRecent',
  async (force: boolean = true, { dispatch, getState }) => {
    if (!force) {
      const storedRecent = recentSourcesSelector(getState() as StoreState)
      if (storedRecent.length !== 0) {
        const item = storedRecent[0]
        if (instanceOfStream(item as any)) {
          return dispatch(getStream({ streamId: item.id, force: false, withFeeds: true })).unwrap()
        }
        return storedRecent[0]
      }
    }

    const items = await dispatch(getRecentSources(1)).then(({ payload }: any) => payload.items)
    if (items[0].type === 'stream') {
      return dispatch(getStream({ streamId: items[0].id, force: false, withFeeds: true }))
    }
    return items[0]
  }
)

export const removeRecentlyViewed = createAsyncThunk(
  'content/recent/remove',
  async (
    { type, source }: { type: InteractionTrackingSourceType, source: InteractionTrackingSource },
    { dispatch }
  ) => {
    if (type === 'quote_feed') {
      await dispatch(quotesApi.deleteSavedSearch((source as QuoteFeed).id)).toPromise()
    } else {
      await dispatch(api.deleteInteraction(source.id, type)).toPromise()
    }
    return { type, source }
  }
)

export const getCuratedStreams = createAsyncThunk(
  'content/recommended/getCuratedStreams',
  async (category: 'popular' | 'industries', { dispatch }) => {
    const response = category === 'popular'
      ? await dispatch(api.getPopularStreams()).toPromise()
      : await dispatch(api.getIndustriesStreams()).toPromise()

    return { category, streams: streamsAdapter(response, true) }
  }
)

export const getSavedSources = createAsyncThunk(
  'content/recommended/getSavedSources',
  async (_, { dispatch }) => {
    const response = await dispatch(api.getSavedSources()).toPromise()
    return streamsAdapter(response).filter(s => !s.protected)
  }
)

export const setFeedToSave = createAction<Feed>('content/recommended/setFeedToSave')
export const clearFeedToSave = createAction('content/recommended/clearFeedToSave')

export const trackFeedInteraction = createAsyncThunk(
  'content/recommended/trackFeedInteraction',
  async (args: { feedIds: string[], event: InteractionEvent, isQuoteFeed?: boolean }, { dispatch, getState }) => {
    const { feedIds, event, isQuoteFeed } = args
    const impersonate = impersonationSelector(getState() as StoreState)
    if (impersonate) {
      console.log('[tracking] Tracking is disabled when impersonating user')
      return feedIds
    }

    await dispatch(api.registerFeedInteraction(feedIds, event, isQuoteFeed)).toPromise()
    return feedIds
  }
)

export const trackFileFolderInteraction = createAsyncThunk(
  'content/recommended/trackFolderInteraction',
  async (args: { folderIds: string[], event: InteractionEvent }, { dispatch, getState }) => {
    const { folderIds, event } = args
    const impersonate = impersonationSelector(getState() as StoreState)
    if (impersonate) {
      console.log('[tracking] Tracking is disabled when impersonating user')
      return
    }

    await dispatch(api.registerFileFolderInteraction(folderIds, event)).toPromise()
    return folderIds
  }
)

export const trackStatusIdeasCategoryInteraction = createAsyncThunk(
  'content/recommended/trackStatusIdeasCategoryInteraction',
  async (args: { categoryIds: string[], event: InteractionEvent }, { dispatch, getState }) => {
    const { categoryIds, event } = args
    const impersonate = impersonationSelector(getState() as StoreState)
    if (impersonate) {
      console.log('[tracking] Tracking is disabled when impersonating user')
      return
    }

    await dispatch(api.registerStatusIdeasCategoryInteraction(categoryIds, event)).toPromise()
    return categoryIds
  }
)

export const addRecentSource = createAction<InteractionTrackingSource>('content/recommended/addRecentSource')
