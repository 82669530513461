import { StoreThunkAction } from 'store/state'
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/of'
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/filter'

import { TIMERANGE_FILTER_DEFAULT } from 'config'
import { Photo, Paginated, RangeFilter, PHOTO_TYPE, ContentType, IndexedObject } from 'interfaces'
import { StockContentProvider } from 'interfaces/Content/StockContentProvider'
import { V1 } from './net'
import { photosAdapter, getCacheKey, SortBy } from '../util'
import { cacheContentTemp, getCachedContent } from '../cache'
import { PAGE_SIZE_DEFAULT } from '../cache/utils'
import { STOCK_PHOTO_TYPE } from 'interfaces/Content/StockContentTypes'
import { createMixedArrayFromObject } from 'utils/sort/mixedArray'

/**
 * Fetches photos from single feed or stream
 *
 * @export
 * @param {string} sourceId feed/stream id
 * @param {boolean} [isStream]
 * @param {number} [page=0] page number (starts at 0)
 * @param {RangeFilter} [rangeFilter=TIMERANGE_FILTER_DEFAULT] time filter
 * @returns {StoreThunkAction<Observable<Paginated<Photo[]>>>}
 */
export function getPhotos(
  sourceId: string,
  isStream?: boolean,
  page: number = 0,
  rangeFilter: RangeFilter = TIMERANGE_FILTER_DEFAULT,
  sortBy: SortBy = SortBy.Engagement,
  stock?: boolean,
  stockSource?: StockContentProvider
): StoreThunkAction<Observable<Paginated<Photo[]>>> {

  return (dispatch) => {
    let fetchPage = page
    let cacheKeyType: ContentType = PHOTO_TYPE
    if (stock) {
      // NOTE: always fetch page 0 for stock content
      fetchPage = 0
      cacheKeyType = STOCK_PHOTO_TYPE
    }
    const cacheKey = getCacheKey(Boolean(isStream), sourceId, cacheKeyType, rangeFilter, fetchPage, sortBy, stockSource)
    const cachedPhotos = dispatch(getCachedContent(cacheKey))

    if (cachedPhotos) {
      if (stock) {
        // EXPL: Stock content is cached without paging (all on page 0). It is implemented here:
        const startIndex = page * PAGE_SIZE_DEFAULT
        const endIndex = startIndex + PAGE_SIZE_DEFAULT
        const contentForPage: Paginated<Photo[]> = {
          items: cachedPhotos.items.slice(startIndex, endIndex) as Photo[],
          hasNext: cachedPhotos.items.length > endIndex - 1
        }

        return Observable.of(contentForPage)
      }
      return Observable.of(cachedPhotos as Paginated<Photo[]>)
    }

    const getPhotos = isStream
      ? dispatch(V1.getPhotosFromFolder(sourceId, page, rangeFilter, sortBy))
      : dispatch(V1.getPhotos(sourceId, fetchPage, rangeFilter, sortBy, stockSource))

    return getPhotos
      .map(response => response.content)
      .map(photos => {
        const contentFormatted = photosAdapter(photos, stock)
        if (stock) {
          const contentByProvider = contentFormatted.items.reduce((map: IndexedObject<Photo[]>, item: any) => {
            if (!map[item.provider]) {
              map[item.provider] = []
            }
            map[item.provider].push(item)
            return map
          }, {})
          const mixedContent = createMixedArrayFromObject<Photo>(contentByProvider)

          if (photos.length > 0) {
            dispatch(cacheContentTemp(cacheKey, { hasNext: contentFormatted.hasNext, items: mixedContent }))
          }

          return {
            hasNext: contentFormatted.hasNext,
            items: mixedContent.slice(0, PAGE_SIZE_DEFAULT) // paging for stock photos
          }
        }
        return contentFormatted
      })
      .do({
        next: (response) => {
          // Do not cache empty response
          if (!stock && response.items.length > 0) {
            dispatch(cacheContentTemp(cacheKey, response))
          }
        }
      })
  }
}
