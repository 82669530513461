export function lazyLoad(componentImport: () => Promise<any>, name: string): Promise<any> {
  return new Promise((resolve, reject) => {
    // Check if the page has already been refreshed once
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false')

    componentImport().then((component) => {
      // Reset the refresh flag on success
      window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false')
      resolve(component)
    }).catch((error) => {
      // Refresh the page on error if not already refreshed
      if (!hasRefreshed) {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true')
        return window.location.reload()
      }
      reject(error)
    })
  })
}
