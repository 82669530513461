import * as React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ActiveViewSelector } from '../ActiveViewSelector'
import styles from './PublishingTopBar.pcss'
import { ContentType, WithIntl } from 'interfaces'
import PostsFilter from '../PostsFilter'
import PPSelect, { OPTIONS_TOGGLE_DEFAULT, PPSelectOptions } from 'components/PPSelect'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import Tooltip from '@mui/material/Tooltip'
import { useNavigate } from 'react-router-dom'
import Icon from '@mdi/react'
import { mdiCogOutline, mdiBell } from '@mdi/js'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { ViewOptionSelect } from '../CalendarViewOptions/CalendarViewOptions'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'

interface PublishingTopBarProps {
  totalCount: number | undefined
  activeTypes: ContentType[]
  postsView?: string
  withPostsViewToggle?: boolean
  withEmptySlotsToggle?: boolean
  withViewPicker?: boolean
  emptySlotsVisible?: boolean
  withPreviewToggle?: boolean
  showPreview?: boolean
  draftsView?: boolean
  postsCountLabel?: React.ReactNode
  virtualPostsVisible?: boolean
  showPendingLink?: boolean
  hideViewOptions?: Array<'calendar' | 'list' | 'grid'>
  onToggleVirtualPostsVisible?: () => void
  onTogglePreview?: () => void
  onToggleEmptySlotsVisible?: () => void
  onFilter: (query: string, types: ContentType[], searchInArticleSummary?: boolean) => void
  onEditBtnClick?: () => void
}

export function PublishingTopBar(props: React.PropsWithChildren<PublishingTopBarProps> & WithIntl) {
  const {
    emptySlotsVisible,
    showPreview,
    onTogglePreview,
    onToggleEmptySlotsVisible,
    virtualPostsVisible,
    onToggleVirtualPostsVisible
  } = props
  const navigate = useNavigate()
  const [popupAnchor, setPopupAnchor] = React.useState<HTMLElement | null>(null)
  const elementRef = React.useRef<HTMLDivElement>(null)
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const openPopup = (e: any) => {
    if (buttonRef.current) {
      setPopupAnchor(buttonRef.current)
    }
    e.stopPropagation()
    e.preventDefault()
  }

  const closePopup = () => {
    setPopupAnchor(null)
  }

  const onPostViewChange = (view: string) => {
    navigate(`/${view}`)
  }

  const toggleEmptySlotsVisible = React.useCallback((value: 'on' | 'off') => {
    if (!onToggleEmptySlotsVisible) {
      return
    }

    const visible = value === 'on'
    if (emptySlotsVisible !== visible) {
      onToggleEmptySlotsVisible()
    }
  }, [emptySlotsVisible, onToggleEmptySlotsVisible])

  const toggleVirtualPostsVisible = React.useCallback((value: 'on' | 'off') => {
    if (!onToggleVirtualPostsVisible) {
      return
    }

    const visible = value === 'on'
    if (virtualPostsVisible !== visible) {
      onToggleVirtualPostsVisible()
    }
  }, [virtualPostsVisible, onToggleVirtualPostsVisible])

  const togglePreview = React.useCallback((value: 'on' | 'off') => {
    const withPreview = value === 'on'
    if (showPreview !== withPreview && onTogglePreview) {
      onTogglePreview()
    }
  }, [showPreview, onTogglePreview])

  const goToPending = () => {
    navigate('/posts/planned/queued/pending?view=list')
  }

  const showSettings = props.withEmptySlotsToggle || Boolean(props.onToggleVirtualPostsVisible)

  return (
    <div className={styles['header-planned']} ref={elementRef}>
      {/* {props.withPostsViewToggle && props.postsView && (
        <PPSelect
          name="Posts"
          selectedValue={props.postsView}
          options={POST_VIEW_OPTIONS}
          className={styles['posts-view-select']}
          onSelectionChange={onPostViewChange}
        />
      )} */}
      {props.withViewPicker && (
        <ActiveViewSelector hideOptions={props.hideViewOptions} className={styles['view-switch']} />
      )}
      {props.onEditBtnClick && (
        <Tooltip title={<FormattedMessage id="actions.edit-bucket" />}>
          <IconButton
            className={`${styles['btn-edit']} solid`}
            onClick={props.onEditBtnClick}
            size="large"
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      <PostsFilter
        activeTypes={props.activeTypes}
        draftsView={props.draftsView}
        postsView={props.postsView}
        className={styles['search-container']}
        onFilter={props.onFilter}
        onPostViewChange={props.withPostsViewToggle && props.postsView ? onPostViewChange : undefined}
      />
      {showSettings && (
        <React.Fragment>
          <IconButton ref={buttonRef} size="small" className={`${styles['btn-action']} solid`} onClick={openPopup}>
            <Icon size="20px" path={mdiCogOutline} />
          </IconButton>
          <Popper
            open={Boolean(popupAnchor)}
            anchorEl={popupAnchor}
            disablePortal
            placement="bottom"
            className={styles.popper}
          >
            <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={closePopup}>
              <Paper classes={{ root: styles.paper }}>
                {props.withEmptySlotsToggle && (
                  <ViewOptionSelect
                    label={<FormattedMessage id="post.actions.toggle-empty-slots" />}
                    value={props.emptySlotsVisible ? 'on' : 'off'}
                    options={[{
                      label: <FormattedMessage id="label.generic.show" />,
                      value: 'on'
                    }, {
                      label: <FormattedMessage id="label.generic.hide" />,
                      value: 'off'
                    }]}
                    onChange={toggleEmptySlotsVisible}
                  />
                )}
                {props.onToggleVirtualPostsVisible && (
                  <ViewOptionSelect
                    label={<FormattedMessage id="post.actions.toggle-virtual-posts" />}
                    value={props.virtualPostsVisible ? 'on' : 'off'}
                    options={[{
                      label: <FormattedMessage id="label.generic.show" />,
                      value: 'on'
                    }, {
                      label: <FormattedMessage id="label.generic.hide" />,
                      value: 'off'
                    }]}
                    onChange={toggleVirtualPostsVisible}
                  />
                )}
              </Paper>
            </ClickAwayListener>
          </Popper>
        </React.Fragment>
      )}
      {props.showPendingLink && (
        <Button
          className={styles['btn-pending']}
          startIcon={<Icon path={mdiBell} size="20px" />}
          onClick={goToPending}
        >
          You have Pending posts
        </Button>
      )}
      <div className={styles['header-right']}>
        {typeof props.totalCount === 'number' && (
          <div className={styles['header-label']}>
            <FormattedMessage
              id={props.draftsView ? 'post.planned.drafts-count-label' : 'post.planned.posts-count-label'}
              values={{ count: props.totalCount }}
            />
          </div>
        )}
        {props.postsCountLabel && (
          <div className={styles['header-label']}>
            {props.postsCountLabel}
          </div>
        )}
        {props.withPreviewToggle && (
          <PPSelect
            name={<FormattedMessage id="post.label.preview" />}
            options={OPTIONS_TOGGLE_DEFAULT}
            selectedValue={props.showPreview ? 'on' : 'off'}
            onSelectionChange={togglePreview}
          />
        )}
        {props.children}
      </div>
    </div>
  )
}

export default injectIntl(PublishingTopBar)
