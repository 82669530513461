import { Stream, Paginated, ErrorResponse, ARTICLE_TYPE, PHOTO_TYPE, StreamsResponse, LegacyFolder } from 'interfaces'
import { feedAdapter } from './feeds'

import assert from 'services/errors'

import { slugify } from 'utils/format/slugify'
import { defaultStreamColorString, ppGreenColorString } from 'utils/colors'

export function streamAdapter(folder: LegacyFolder): Stream {
  const originalId = folder.originalFolder || folder.originalStreamId
  const feeds = (folder.feeds || []).map(feedAdapter)
  const feedImages = folder.feedImages || feeds.map(f => f.image).filter(Boolean) as string[]
  let streamImages = folder.images
  if (typeof streamImages === 'string') {
    try {
      streamImages = JSON.parse(streamImages)
    } catch (e: any) {
      console.log('error parsing stream images: ', e)
    }
  }

  return {
    id: '' + folder.id,
    order: +folder.order,
    feedIds: (folder.feeds || []).filter(Boolean).map(feed => feed.id.toString()),
    title: folder.name.replace(/&amp;/g, '&'),
    slug: folder.prettyName || slugify(folder.name || ''),
    public: false,
    isPrivate: Boolean(folder.isPrivate),
    isStream: true,
    imageUrl: folder.imageUrl,
    lastUpdated: folder.lastUpdated,
    createdAt: new Date(folder.createdAt).getTime(),
    originalId: originalId ? originalId.toString() : undefined,
    filters: folder.filters?.length ? folder.filters.filter(f => f.indexOf('stock') === -1) : [ARTICLE_TYPE, PHOTO_TYPE],
    protected: '' + folder.type === '1',
    feeds,
    feedsCount: folder.feedsCount,
    color: '' + folder.type === '1' ? ppGreenColorString : folder.color || defaultStreamColorString,
    type: folder.type ? '' + folder.type : undefined,
    followers: folder.followersCount,
    userId: folder.userId?.toString(),
    featuredImageUrl: folder.imageUrl || feedImages[0],
    // eslint-disable-next-line no-magic-numbers
    imageUrls: streamImages || feedImages.slice(1, 3)
  }
}

export function streamsAdapter(response: StreamsResponse | ErrorResponse): Paginated<Stream[]> {
  const streamsResponse = assert<StreamsResponse>(response)

  return {
    items: (streamsResponse.streams || []).filter(item => !!item).map(streamAdapter),
    hasNext: !!(streamsResponse.pagination && streamsResponse.pagination.next && streamsResponse.pagination.next.length)
  }
}
