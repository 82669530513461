import { V1 } from './net'
import { StoreThunkAction } from 'store/state'
import { Observable } from 'rxjs/Observable'
import { ContentType } from 'interfaces'
import { tap } from 'rxjs/operators/tap'
import { noop } from 'utils/noop'
import { HS_EVENT_POST_CREATED, trackHubspotEvent } from 'services/tracking/hubspot'

export function requeuePost(
  ids: string[],
  ppids: string[],
  all?: boolean,
  posted?: boolean,
  bucketId?: string,
  query?: string,
  types?: ContentType[]
): StoreThunkAction<Observable<any>> {
  return (dispatch) => dispatch(V1.requeuePost(ids, ppids, all, posted, bucketId, query, types)).pipe(tap(
    () => {
      dispatch(trackHubspotEvent(HS_EVENT_POST_CREATED, { type: 'all' }))
      if (posted) {
        dispatch(trackHubspotEvent(HS_EVENT_POST_CREATED, { type: 'history' }))
      }
    },
    (response) => {
      if (response.response?.error?.message) {
        throw new Error(response.response?.error?.message)
      }
    }))
}
