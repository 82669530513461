import * as React from 'react'
import styles from './Optimize.pcss'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {
  Article,
  ARTICLE_TYPE,
  BRAND_FACEBOOK,
  BRAND_INSTAGRAM,
  BRAND_PINTEREST,
  BRAND_TWITTER,
  NETWORKS_NAMES,
  PostDestinationType
} from 'interfaces'
import { BRAND_GOOGLE, BRAND_LINKEDIN } from 'interfaces/Content/SocialBrand'
import TextEditor, { TextEditorProps } from 'components/TextEditor'
import { fbLiveSearch, linkedInLiveSearch } from 'services/search/actions'
import {
  BRAND_YOUTUBE,
  MAX_FACEBOOK_STATUS_LENGTH,
  MAX_GMB_STATUS_LENGTH,
  MAX_INSTAGRAM_STATUS_LENGTH,
  MAX_LINKEDIN_STATUS_LENGTH,
  MAX_TWEET_LENGTH
} from 'shared/constants'
import { extractUrlsFromText, getTwitterRemainingChars } from 'utils/composer'
import { getLinkDetails, setComposerStatus } from 'services/compose'
import {
  composerContentItemSelector,
  composerImageUrlsSelector,
  composerIsEmptySelector,
  composerLinkSelector,
  composerNetworkPostsSelector,
  composerPostAsPhotoSelector,
  composerPostStatusByNetworkSelector,
  composerSelectedProfilesSelector,
  composerTwitterPostStatusSelector,
  composerVideoThumbnailSelector,
  composerVideoUrlSelector,
  featuredImageUrlSelector,
  useGenericStatusSelector
} from 'services/compose/selectors'
import { Subject } from 'rxjs/Subject'
import { StoreThunkDispatch } from 'store/state'
import { ComposerFacebookPost, ComposerInstagramPost, ComposerTwitterPost } from 'services/compose/interfaces'
import TweetBlock from 'components/TweetBlock'
import NetworkOptions from './NetworkOptions'
import PostPreview from 'components/PostPreview'
import SocialIcon from 'components/SocialIcon'
import PinterestPostStatus from './PinterestPostStatus'
import { Icon } from '@mdi/react'
import { mdiCancel, mdiAlertCircleOutline, mdiInformationOutline } from '@mdi/js'
import { ComposerUploaderContext } from 'components/Composer/UploadContext'
import { sortByKeyAscending } from 'utils/sort/order'
import FirstCommentTextBox from './FirstCommentTextBox'
import { InstagramMediaTagsSelect } from '../CustomizePostBlock/InstagramMediaTagsSelect'
import { YoutubePostStatus } from './YoutubePostStatus'
import { TextBackgroundSelector } from '../CustomizePostBlock/TextBackgroundSelector/TextBackgroundSelector'
import { LinkedinPostOptions } from '../CustomizePostBlock/LinkedinPostOptions/LinkedinPostOptions'
import { ComposerLinkedinPost } from 'services/compose/interfaces/ComposerLinkedinPost'
import { Observable } from 'rxjs/Observable'

interface OptimizeProps {
  network: PostDestinationType
  contentElement?: React.ReactNode
}

const MAX_LENGTH_BY_NETWORK: Record<string, number> = {
  [BRAND_LINKEDIN]: MAX_LINKEDIN_STATUS_LENGTH,
  [BRAND_GOOGLE]: MAX_GMB_STATUS_LENGTH,
  [BRAND_INSTAGRAM]: MAX_INSTAGRAM_STATUS_LENGTH,
  [BRAND_FACEBOOK]: MAX_FACEBOOK_STATUS_LENGTH,
  [BRAND_TWITTER]: MAX_TWEET_LENGTH
}

export function Optimize(props: OptimizeProps) {
  const { network } = props
  const intl = useIntl()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const { files } = React.useContext(ComposerUploaderContext)
  const post = useSelector(composerNetworkPostsSelector)[network]
  const link = useSelector(composerLinkSelector)
  const content = useSelector(composerContentItemSelector)
  const imageUrls = useSelector(composerImageUrlsSelector)
  const videoUrl = useSelector(composerVideoUrlSelector)
  const videoThumbnailUrl = useSelector(composerVideoThumbnailSelector)
  const postAsPhoto = useSelector(composerPostAsPhotoSelector)
  const composerTwitterState = useSelector(composerTwitterPostStatusSelector)
  const featuredImage = useSelector(featuredImageUrlSelector)
  const profiles = useSelector(composerSelectedProfilesSelector)
  const firstProfile = profiles.find(p => p.type === network)
  const postStateByNetwork = useSelector(composerPostStatusByNetworkSelector)
  const postState = postStateByNetwork[network]
  const isComposerEmpty = useSelector(composerIsEmptySelector)
  const isUsingGenericStatus = useSelector(useGenericStatusSelector)

  const linkRef = React.useRef(link)
  const getLinkDetails$ = React.useRef<Subject<string>>()
  const skipLinkFetch = React.useRef<boolean>()

  const errorKeys = !isComposerEmpty && postState.hasErrors
    ? postState.caption.errors.concat(postState.content.errors).concat(postState.network.errors)
    : []
  const hintKeys = !isComposerEmpty && postState.hasHints
    ? postState.caption.hints.concat(postState.content.hints, postState.network.hints)
    : []
  const infoKeys = !isComposerEmpty
    ? postState.content.info.concat(postState.network.info).concat(postState.caption.info)
    : []

  const linkedinFirstCommentHint = React.useMemo(() => {
    if (network === BRAND_LINKEDIN && (post as ComposerLinkedinPost).firstComment) {
      const liProfilesWithNoFirstComment = profiles.filter(p => p.type === BRAND_LINKEDIN && !p.isMigrated)
      if (liProfilesWithNoFirstComment.length) {
        return (
          <li key="li-fc">
            <FormattedMessage
              id="composer.hints.linkedin.first-comment-api"
              values={{ profiles: liProfilesWithNoFirstComment.map(p => p.name) }}
            />
          </li>
        )
      }
    }
    return null
  }, [network, (post as ComposerLinkedinPost).firstComment, profiles])

  const imageUrlsSorted = React.useMemo(() => {
    return Object.values(files).filter(f => !f.isVideo).sort(sortByKeyAscending('order')).map(f => f.url) as string[]
  }, [files])

  const withMixedPhotoOrientation = React.useMemo(() => {
    const orientations = Object.values(files).reduce((acc, f) => {
      if (f.orientation && !f.isVideo) {
        acc[f.orientation] = true
      }
      return acc
    }, {} as { [key: string]: boolean })
    return Object.keys(orientations).length > 1
  }, [files])

  if (network === BRAND_INSTAGRAM && withMixedPhotoOrientation) {
    hintKeys.unshift('composer.hints.instagram.mixed-orientation')
  }

  React.useEffect(() => {
    skipLinkFetch.current = (content && content?.type !== ARTICLE_TYPE) || imageUrls.length > 0 || Boolean(videoUrl)
  }, [content, imageUrls, videoUrl])

  React.useEffect(() => {
    getLinkDetails$.current = new Subject()
    const sub = getLinkDetails$.current
      .switchMap((url: string) => Observable.fromPromise(dispatch(getLinkDetails({ url })).unwrap()))
      .subscribe()
    return () => {
      sub.unsubscribe()
    }
  }, [dispatch])

  const tagSearchFn = React.useMemo(() => {
    switch (network) {
      case BRAND_FACEBOOK:
        return (needle: string) => dispatch(fbLiveSearch(needle))
      case BRAND_LINKEDIN:
        return (needle: string) => dispatch(linkedInLiveSearch(needle))
      default:
        return undefined
    }
  }, [network, dispatch])

  const onChange = React.useCallback((network: PostDestinationType) => (text: string, html: string) => {
    const url = extractUrlsFromText(text).pop()
    const lastFetchedUrl = linkRef.current ? linkRef.current.url : ''
    if (url && url !== lastFetchedUrl) {
      const validUrl = url.toLowerCase().startsWith('http') ? url : `http://${url}`
      if (!skipLinkFetch.current && (content && (content as Article).sourceLink !== validUrl)) {
        getLinkDetails$.current?.next(validUrl)
      }
    }
    dispatch(setComposerStatus({ network, text, html }))
  }, [dispatch])

  const editorProps: TextEditorProps = React.useMemo(() => {
    const withTextBackgroundSelector = network === BRAND_FACEBOOK && !link && !content && imageUrls.length === 0 && !videoUrl
    let counter: React.ReactNode = null
    let counterValue = 0

    if (network === BRAND_TWITTER) {
      const remainingChars = getTwitterRemainingChars(post.status.text.trim())
      counter = (
        // eslint-disable-next-line no-magic-numbers
        <div className={`${styles.counter} ${remainingChars < 51 ? styles.visible : ''}`}>
          {remainingChars}
        </div>
      )
    } else if (MAX_LENGTH_BY_NETWORK[network]) {
      counterValue = MAX_LENGTH_BY_NETWORK[network] - post.status.text.length
      counter = (
        // eslint-disable-next-line no-magic-numbers
        <div className={`${styles.counter} ${counterValue < 51 ? styles.visible : ''}`}>
          {counterValue}
        </div>
      )
    }

    let footerElements = counter
    if (withTextBackgroundSelector) {
      footerElements = (
        <React.Fragment>
          <TextBackgroundSelector className={styles.fbbg} />
          {counter}
        </React.Fragment>
      )
    }

    return {
      network,
      html: isUsingGenericStatus ? post.status.html : undefined,
      initialHTML: post.status.html,
      withEmojis: true,
      footerElements,
      id: `composer-text-editor-${network}`,
      inputClassName: `${styles['text-input']} ${network === BRAND_INSTAGRAM ? styles.ig : ''}`,
      footerClassName: styles['text-footer'],
      placeholder: intl.formatMessage({ id: 'composer.status-placeholder-network' }),
      searchTags: tagSearchFn,
      onChange: onChange(network)
    }
  }, [
    network, link, content, imageUrls.length, videoUrl,
    isUsingGenericStatus, post.status.html, post.status.text, intl, tagSearchFn, onChange
  ])

  const isTwitterRetweet = (post as ComposerTwitterPost).tweetType === 'retweet'
  const isTwitterQuote = (post as ComposerTwitterPost).tweetType === 'quote'
  const withTextEditor = !isTwitterRetweet && network !== BRAND_PINTEREST && network !== BRAND_YOUTUBE
  const networkName = NETWORKS_NAMES[network]
  const doc = (post as ComposerLinkedinPost).document
  const documentName = network === BRAND_LINKEDIN ? doc?.name : undefined

  return (
    <div className={styles.container} data-testid={`composer-optimize-${network}`}>
      <div className={`${styles.brand} ${styles[props.network]}`}>
        <SocialIcon
          size={`${network === BRAND_GOOGLE || network === BRAND_TWITTER ? '36px' : '42px'}`}
          icon={network}
          outline={network !== BRAND_GOOGLE}
          className={styles.icon}
        />
      </div>
      <div className={styles['content-box']}>
        <h3 className={styles.title}>{`${networkName} `}<FormattedMessage id="label.generic.post" /></h3>
        <div className={`${styles.section} ${styles.top}`}>
          <div className={styles['section-title']}>
            <FormattedMessage id={`composer.labels.caption-${props.network}`} />
          </div>
          {withTextEditor && (
            <div className={styles['text-box']}>
              <TextEditor {...editorProps} />
            </div>
          )}
          {network === BRAND_PINTEREST && (
            <PinterestPostStatus />
          )}
          {network === BRAND_YOUTUBE && (
            <YoutubePostStatus />
          )}
          {isTwitterRetweet && content && (
            <TweetBlock
              authorName={content.feed.title}
              authorHandle={content.feed.name}
              date={new Date(content.createdAt)}
              text={content.tweetText as string}
              tweetUrl={content.socialLink}
              isRetweet
            />
          )}
          {isTwitterQuote && content && (
            <TweetBlock
              authorName={content.feed.title}
              authorHandle={content.feed.name}
              date={new Date(content.createdAt)}
              text={content.tweetText as string}
              tweetUrl={content.socialLink}
              clampLines={1}
              isQuote
              className={styles['tweet-block']}
            />
          )}
          {(network === BRAND_INSTAGRAM || network === BRAND_FACEBOOK || network === BRAND_LINKEDIN) && (
            <div className={`${styles['comment-box']} ${network === BRAND_INSTAGRAM ? styles['cb-ig'] : ''}`}>
              <FirstCommentTextBox network={network} initialValue={(post as ComposerFacebookPost).firstComment} />
              {/* NOTE: Do not show tag select for video posts or multi-photo posts */}
              {network === BRAND_INSTAGRAM && !videoUrl && imageUrls.length === 1 && (
                <InstagramMediaTagsSelect />
              )}
            </div>
          )}
          {network === BRAND_LINKEDIN && (
            <LinkedinPostOptions />
          )}
        </div>
        <div className={styles.section}>
          <NetworkOptions
            title={(
              <div className={styles['section-title']}>
                {`${networkName} `}<FormattedMessage id="label.generic.settings" />
              </div>
            )}
            network={network}
          />
        </div>
        {errorKeys.length > 0 && (
          <div className={styles['msg-wrapper']}>
            <div className={styles['errors-box']}>
              <div><Icon path={mdiCancel} className={styles['icon-err']} size="28px" /></div>
              <ul className={styles.errors}>
                {errorKeys.map(key => (
                  <li key={key}>
                    <FormattedMessage id={key} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {(hintKeys.length > 0 || linkedinFirstCommentHint) && (
          <div className={styles['msg-wrapper']}>
            <div className={styles['hints-box']}>
              <div><Icon path={mdiAlertCircleOutline} className={styles['icon-err']} size="28px" /></div>
              <ul className={styles.hints}>
                {hintKeys.map(key => (
                  <li key={key}>
                    <FormattedMessage id={key} />
                  </li>
                ))}
                {linkedinFirstCommentHint}
              </ul>
            </div>
          </div>
        )}
        {infoKeys.length > 0 && (
          <div className={styles['msg-wrapper']}>
            <div className={styles['info-box']}>
              <div><Icon path={mdiInformationOutline} className={styles['icon-err']} size="28px" /></div>
              <ul className={styles.info}>
                {infoKeys.map(key => (
                  <li key={key}>
                    <FormattedMessage id={key} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {props.contentElement}
      </div>
      <div className={styles['preview-box']}>
        <PostPreview
          postNetwork={network}
          post={post}
          postAsPhoto={postAsPhoto}
          imageUrls={imageUrlsSorted}
          videoUrl={videoUrl}
          videoThumbnailUrl={videoThumbnailUrl}
          content={content}
          link={link}
          firstComment={(post as ComposerInstagramPost).firstComment}
          twitterPostStatus={composerTwitterState}
          featuredImageUrl={featuredImage}
          previewProfile={firstProfile}
          reshare={network === BRAND_FACEBOOK && (post as ComposerFacebookPost).reshare}
          textBackgroundUrl={network === BRAND_FACEBOOK ? (post as ComposerFacebookPost).textBackground?.backgroundUrl : undefined}
          textColor={network === BRAND_FACEBOOK ? (post as ComposerFacebookPost).textBackground?.color : undefined}
          documentName={documentName}
        />
      </div>
    </div>
  )
}

export default Optimize
