import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { BRAND_FACEBOOK, BRAND_INSTAGRAM, BRAND_PINTEREST, IndexedObject, PinterestBoard, PostDestination } from 'interfaces'
import React, { useCallback } from 'react'
import { BulkContentPost, FB_POST_TYPE_REEL, POST_TYPE_STORY } from 'services/compose/state'
import styles from './NetworkOptionsPopup.pcss'
import { FacebookAlbumSelector, PinterestBoardSelector } from 'components/Composer/AlbumSelector'
import { FBAlbum } from 'services/compose/interfaces/ComposerFacebookPost'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { pinterestProfilesBoardsSelector } from 'services/compose/selectors'
import { InstagramLocationSelector } from 'components/Composer/CustomizePostBlock/InstagramLocationSelector'
import { LocationInfo } from 'services/compose/interfaces'
import { BRAND_TIKTOK, BRAND_YOUTUBE, PrivacyStatus, TikTokPostProfileSettings } from 'shared'
import { TikTokProfileSettings } from 'components/Composer/CustomizePostBlock/TikTokOptions/TikTokProfileSettings'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import PPSwitch from 'components/PPSwitch'

interface NetworkOptionsPopupProps {
  open: boolean
  posts: BulkContentPost[]
  profiles: { [network: string]: PostDestination[] }
  onCancel: () => void
  onConfirm: (
    fbAlbums: IndexedObject<FBAlbum | undefined>,
    piBoards: IndexedObject<PinterestBoard>,
    igLocations: IndexedObject<LocationInfo | undefined>,
    tiktokSettings: Record<string, TikTokPostProfileSettings>,
    ytPrivacy?: PrivacyStatus,
    fbPostType?: string,
    igPostType?: string
  ) => void
}

export function NetworkOptionsPopup(props: NetworkOptionsPopupProps) {
  const boards = useSelector(pinterestProfilesBoardsSelector)
  const [selectedFBAlbums, setSelectedFBAlbums] = React.useState<IndexedObject<FBAlbum | undefined>>({})
  const [selectedPIBoards, setSelectedPIBoards] = React.useState<IndexedObject<PinterestBoard>>({})
  const [selectedIGLocations, setSelectedIGLocations] = React.useState<IndexedObject<LocationInfo | undefined>>({})
  const [tkSettings, setTkSetings] = React.useState<Record<string, TikTokPostProfileSettings>>({})
  const [ytPrivacyStatus, setPrivacyStatus] = React.useState('public')
  const [fbPostType, setFBPostType] = React.useState('post')
  const [igPostType, setIGPostType] = React.useState('post')

  let hasFBImagePost = false
  const postsByNetwork = props.posts.reduce((map: IndexedObject<BulkContentPost[]>, post) => {
    if (post.network === 'facebook' && post.imageUrl) {
      hasFBImagePost = true
    }
    if (map[post.network]) {
      map[post.network].push(post)
    } else {
      map[post.network] = [post]
    }
    return map
  }, {})

  const networks = Object.keys(postsByNetwork)
  const hasPIOptions = networks.includes(BRAND_PINTEREST)
  const hasIGOptions = networks.includes(BRAND_INSTAGRAM)
  const hasTKOptions = networks.includes(BRAND_TIKTOK)
  const hasYTOptions = networks.includes(BRAND_YOUTUBE)
  const isEmpty = !hasFBImagePost && !hasPIOptions && !hasIGOptions && !hasTKOptions && !hasYTOptions

  const boardsByProfile = Object.keys(boards).reduce((map: IndexedObject<PinterestBoard[]>, ppid) => {
    map[ppid] = boards[ppid].map(b => ({ ...b, ppid, selected: selectedPIBoards[ppid]?.id === b.id }))
    return map
  }, {})

  const onSelectedAlbumChange = (p: PostDestination, a: FBAlbum) => {
    setSelectedFBAlbums(current => ({ ...current, [p.ppid]: a }))
  }

  const onSelectedBoardChange = (ppid: string, b: PinterestBoard) => {
    setSelectedPIBoards(current => ({ ...current, [ppid]: b }))
  }

  const onSelectedLocationChange = (location: LocationInfo | undefined, ppid: string) => {
    setSelectedIGLocations(current => ({ ...current, [ppid]: location }))
  }

  const onTkSettingsChange = useCallback((settings: TikTokPostProfileSettings) => {
    setTkSetings(current => ({ ...current, [settings.ppid]: settings }))
  }, [])

  const onConfirm = () => {
    props.onConfirm(
      selectedFBAlbums,
      selectedPIBoards,
      selectedIGLocations,
      tkSettings,
      ytPrivacyStatus as PrivacyStatus,
      fbPostType,
      igPostType
    )
  }

  const privacySelectOptions: PPSelectOptions = {
    public: { label: <FormattedMessage id="composer.labels.youtube.switch-privacy.public" /> },
    private: { label: <FormattedMessage id="composer.labels.youtube.switch-privacy.private" /> },
    unlisted: { label: <FormattedMessage id="composer.labels.youtube.switch-privacy.unlisted" /> }
  }

  const fbPostTypeOptions = React.useMemo(() => {
    const options = [{
      value: 'post',
      label: 'Post'
    }]
    const showReel = props.posts.some(post => post.network === BRAND_FACEBOOK && post.videoUrl)
    const showStory = props.posts.some(post => post.network === BRAND_FACEBOOK && post.imageUrl)

    if (!showReel && !showStory) {
      return []
    }

    if (showReel) {
      options.push({
        value: FB_POST_TYPE_REEL,
        label: 'Reel'
      }, {
        value: POST_TYPE_STORY,
        label: 'Story'
      })
    } else if (showStory) {
      options.push({
        value: POST_TYPE_STORY,
        label: 'Story'
      })
    }
    return options
  }, [props.posts])

  return (
    <Dialog open={props.open}>
      <div className={styles.content}>
        {hasFBImagePost && (
          <div className={styles.section}>
            <h3 className={styles.title}>Facebook</h3>
            <div className={styles.options}>
              {props.profiles[BRAND_FACEBOOK].map(profile => (
                <div className={styles['option-box']} key={profile.id}>
                  <FacebookAlbumSelector
                    profile={profile}
                    raised
                    selectedAlbumsCustom={selectedFBAlbums[profile.ppid] ? [selectedFBAlbums[profile.ppid] as FBAlbum] : []}
                    onSelectedAlbumChangeCustom={onSelectedAlbumChange}
                  />
                </div>
              ))}

              {fbPostTypeOptions.length > 1 && (
                <div className={styles['option-box']}>
                  <PPSwitch
                    selectedValue={fbPostType}
                    options={fbPostTypeOptions}
                    onSelectedValueChange={setFBPostType}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {hasPIOptions && (
          <div className={styles.section}>
            <h3 className={styles.title}>Pinterest</h3>
            <div className={styles.options}>
              {props.profiles[BRAND_PINTEREST].map(p => (
                <div key={p.id} className={styles['option-box']}>
                  <PinterestBoardSelector
                    profile={p}
                    boards={boardsByProfile[p.ppid]}
                    raised
                    onSelectedBoardChange={onSelectedBoardChange}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {hasIGOptions && (
          <div className={styles.section}>
            <h3 className={styles.title}>Instagram</h3>
            <div className={styles.options}>
              {props.profiles[BRAND_INSTAGRAM].map(profile => (
                <div className={styles['option-box']} key={profile.id}>
                  <InstagramLocationSelector
                    profile={profile}
                    selectedLocation={selectedIGLocations[profile.ppid]}
                    raised
                    onSelectedLocationChange={onSelectedLocationChange}
                  />
                </div>
              ))}
              <div className={styles['option-box']}>
                <PPSwitch
                  selectedValue={igPostType}
                  options={[{
                    value: 'post',
                    label: 'Post/Reel'
                  }, {
                    value: POST_TYPE_STORY,
                    label: 'Story'
                  }]}
                  onSelectedValueChange={setIGPostType}
                />
              </div>
            </div>
          </div>
        )}
        {hasTKOptions && (
          <div className={`${styles.section} ${styles.tk}`}>
            <h3 className={styles.title}>TikTok</h3>
            {props.profiles[BRAND_TIKTOK].map(profile => (
              <TikTokProfileSettings
                key={profile.ppid}
                profile={profile}
                ignoreSavedSettings
                hideSaveOption
                showLoader
                onChange={onTkSettingsChange}
              />
            ))}
          </div>
        )}
        {hasYTOptions && (
          <div className={styles.section}>
            <h3 className={styles.title}>YouTube</h3>
            <PPSelect
              name={<FormattedMessage id="composer.labels.youtube-privacystatus-placeholder" />}
              options={privacySelectOptions}
              selectedValue={ytPrivacyStatus}
              withCaret
              raised
              onSelectionChange={setPrivacyStatus}
            />
          </div>
        )}
        {isEmpty && (
          <h4 className={styles.title}>
            <FormattedMessage id="composer.multi-post.network-options-popup.empty" />
          </h4>
        )}
        <div className={styles.actions}>
          <Button onClick={props.onCancel}>
            <FormattedMessage id={isEmpty ? 'actions.ok' : 'actions.cancel'} />
          </Button>
          {!isEmpty && (
            <Button color="primary" variant="contained" onClick={onConfirm}>
              <FormattedMessage id="actions.confirm" />
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  )
}

export default NetworkOptionsPopup
