import { createAction } from '@reduxjs/toolkit'
import { PostDestination } from 'shared'

export * from './getPosts/actions'
export * from './deletePost/actions'
export * from './recyclePost/actions'
export * from './shufflePosts/actions'
export * from './requeuePosted/actions'
export * from './reorderPosts/actions'
export * from './buckets/actions'

export const setSelectedProfiles = createAction<{ profiles: PostDestination[], activeView?: 'calendar' | 'posts' | 'history' }>(
  'posts/setSelectedProfiles'
)
