import * as React from 'react'
import Paper from '@mui/material/Paper'
import { FormattedMessage } from 'react-intl'
import ConfirmDialog from 'components/ConfirmDialog'
import Tooltip from '@mui/material/Tooltip'
import IconDelete from '@mui/icons-material/Close'
import styles from './FeedCard.pcss'
import { NavLink } from 'react-router-dom'
import Icon from '@mdi/react'
import { mdiFormatQuoteClose } from '@mdi/js'

interface QuoteFeedCardProps {
  feed: {
    id: string
    name: string
  }
  small?: boolean
  deleteConfirmationMessage?: string
  className?: string
  onClick?: (id: string, name: string) => void
  onDelete?: (id: string, name: string) => void
}

export function QuoteFeedCard(props: QuoteFeedCardProps) {
  const cardElementRef = React.useRef<HTMLDivElement | null>(null)

  const onMouseEnter = () => {
    if (cardElementRef.current) {
      cardElementRef.current.classList.add(styles.raised)
    }
  }

  const onTransitionEnd = (e: any) => {
    const classes = e.target.classList
    // EXPL: Check if the card has ended transition in collapsed state
    const currentScale = (e.target.getBoundingClientRect().width / e.target.offsetWidth)
    const isCollapsed = currentScale.toFixed(1) === '1.0'
    if (classes.contains(styles.raised) && isCollapsed) {
      classes.remove(styles.raised)
    }
  }

  const onClick = () => {
    if (props.onClick) {
      props.onClick(props.feed.id, props.feed.name)
    }
  }

  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete(props.feed.id, props.feed.name)
    }
  }

  const url = `/content/quotes/${props.feed.name}`
  const feedName = props.feed.name

  return (
    <Paper
      className={`${styles.card} ${props.small ? styles.small : ''} ${styles.quote} ${props.className || ''}`}
      ref={cardElementRef}
      onMouseEnter={onMouseEnter}
      onTransitionEnd={onTransitionEnd}
      onClick={onClick}
    >
      {
        props.onDelete && (
          <ConfirmDialog message={props.deleteConfirmationMessage || 'Are you sure?'}>
            {(confirm) => (
              <Tooltip title={<FormattedMessage id="actions.remove" />} placement="top">
                <IconDelete
                  fontSize="small"
                  role="button"
                  className={styles['btn-delete']}
                  onClick={confirm(onDelete)}
                />
              </Tooltip>
            )}
          </ConfirmDialog>
        )
      }

      <NavLink to={url} className={`${styles['card-link']}`} onClick={onClick}>
        <div className={`${styles['feed-image']} ${styles['image-quote']}`}>
          <div className={styles['quote-img-overlay']}></div>
        </div>
        <div className={styles['item-info']}>
          <h5 className={`${styles.title} text-ellipsis`} title={feedName}>
            {feedName}
          </h5>
          <p className={styles['feed-type']}>
            <Icon className={styles['icon-quote']} path={mdiFormatQuoteClose} />
          </p>
        </div>
      </NavLink>
    </Paper>
  )
}
