import { V1 } from './net'
import { StoreThunkDispatch } from 'store/state'
import { ContentType } from 'interfaces'

export function bulkDeletePosts(
  ids: string[],
  pageIds: string[],
  type: 'queued' | 'pending' | 'scheduled' | 'failed' | 'planned',
  all?: boolean,
  bucketId?: string,
  query?: string,
  types?: ContentType[]
) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.deletePosts(ids, pageIds, type, all, bucketId, query, types))
      .do({
        next: (response) => {
          if (response.error) {
            throw new Error(response.error)
          }
        }
      })
      .map(() => ({ ids }))
  }
}
