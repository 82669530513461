import { StoreThunkAction, StoreThunkDispatch, getState } from 'store/state'
import { Observable } from 'rxjs/Observable'
import { V1 } from './net'
import {
  streamAdapter,
  feedAdapter,
  photoAdapter,
  videoAdapter,
  articleAdapter,
  STOCK_CONTENT_PROVIDER_PARAMS_MAP
} from 'services/content/util'
import {
  Feed,
  Stream,
  LegacyFeed,
  LegacyFolder,
  FEED_TYPE_KEYWORD,
  FilterType,
  PHOTO_TYPE,
  VIDEO_TYPE,
  GIF_TYPE,
  LegacyGif,
  LegacyPhoto,
  LegacyVideo,
  IndexedObject,
  AnyContent,
  Photo,
  ARTICLE_TYPE,
  LegacyArticle
} from 'interfaces'
import { STOCK_ALL_FILTER } from 'interfaces/Content/StockContentTypes'
import { gifAdapter } from 'services/content/adapters/gifs'
import { createMixedArrayFromObject } from 'utils/sort/mixedArray'
import { CONTENT_TYPE_STOCK_MAP } from 'utils/content'

export * from './latest/actions'

export interface SourcesSearchSuccessResponse {
  feeds: IndexedObject<Feed[]>,
  streams: Stream[],
  count: number,
  needle: string,
  scopes: string[],
  page: number
}

export function search(
  needle: string,
  scopes: string[],
  page: number,
  pageSize: number,
  feedId?: string,
  streamId?: string
): StoreThunkAction<Observable<SourcesSearchSuccessResponse>> {
  return (dispatch: StoreThunkDispatch, _getState: getState) => {
    return dispatch(V1.search(needle, scopes, page, pageSize, feedId, streamId))
      .map(data => {
        let sourceByIdFormatted: Feed | Stream
        const filtersByNetwork = data.filters
        const sourceById = data.byId
        const feedsCount = Object.values(data.feeds).reduce((count: number, items: any[]) => {
          count += items.length
          return count
        }, 0)

        const feeds: { [key: string]: Feed[] } = scopes.reduce((results: { [key: string]: any[] }, scope) => {
          results[scope] = (data.feeds[scope] || []).map(f => {
            const feed = feedAdapter(f)
            feed.titleHTML = (f as any).highlightResult?.name?.value
            feed.sources = filtersByNetwork[feed.type]
            return feed
          })
          if (scope === FEED_TYPE_KEYWORD) {
            // Move exact match to first position
            const exactMatchIndex = results[scope].findIndex(f => f.name.toLowerCase() === needle.toLowerCase())
            if (exactMatchIndex !== -1) {
              const item = results[scope].splice(exactMatchIndex, 1)[0]
              results[scope].unshift(item)
            }
          }
          return results
        }, {})

        const streams = (data.streams || []).map(s => {
          const stream = streamAdapter(s)
          stream.titleHTML = (s as any).highlightResult?.name?.value
          return stream
        })
        let count = feedsCount + streams.length

        // NOTE: If we've requested a source by id, it will be included in the response,
        // and possibly - in the results for the requested page
        if (sourceById) {
          if (feedId) {
            sourceByIdFormatted = feedAdapter(sourceById as LegacyFeed)
            sourceByIdFormatted.sources = filtersByNetwork[sourceByIdFormatted.type]
            const pageFeeds = feeds[(sourceByIdFormatted as Feed).type]

            if (pageFeeds.find(f => f.id === sourceByIdFormatted.id) === undefined) {
              pageFeeds.push(sourceByIdFormatted)
            }
          }

          if (streamId) {
            sourceByIdFormatted = streamAdapter(sourceById as LegacyFolder)
            if (streams.find(s => s.id === sourceByIdFormatted.id) === undefined) {
              streams.push(sourceByIdFormatted)
            }
          }
          count += 1
        }

        return { feeds, streams, count, needle, scopes, page }
      })
  }
}

export function fbLiveSearch(needle: string): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.fbLiveSearch(needle))
      .map(response => response.feeds.facebook.map(feedAdapter).map(f => ({
        ...f,
        live: true,
        handle: f.handle.toLowerCase().replace(/\/$/, '') // remove trailing slash
      })))
  }
}

export function twitterLiveSearch(needle: string): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.twitterLiveSearch(needle))
      .map(response => response.feeds.twitter.map(feedAdapter).map(f => ({ ...f, live: true })))
  }
}

export function linkedInLiveSearch(needle: string): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => {
    const rez = dispatch(V1.linkedInLiveSearch(needle))
    return rez.map(response => response.feeds.linkedin.map(feedAdapter).map(f => ({ ...f, live: true })))
  }
}

export function redditLiveSearch(needle: string): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.redditLiveSearch(needle))
      .map(response => response.feeds.reddit.map(feedAdapter).map(f => ({ ...f, live: true })))
  }
}

export function searchStockContent(query: string, type: FilterType, provider: string = '', sortBy?: string) {
  return (dispatch: StoreThunkDispatch) => {
    if (type === STOCK_ALL_FILTER) {
      const photos$ = dispatch(V1.searchStockContent(query, PHOTO_TYPE))
      const videos$ = dispatch(V1.searchStockContent(query, VIDEO_TYPE))
      const gifs$ = dispatch(V1.searchStockContent(query, GIF_TYPE))
      return photos$.zip(videos$, gifs$)
        .map((response: any[]) => {
          const photosFormatted = response[0].content.map((p: LegacyPhoto) => photoAdapter(p, true))
          const videosFormatted = response[1].content.map((v: LegacyVideo) => videoAdapter(v, true))
          const gifsFormatted = response[2].content.map((g: LegacyGif) => gifAdapter(g, true))
          const photosByProvider = photosFormatted.reduce((map: IndexedObject<any[]>, item: any) => {
            if (!map[item.provider]) {
              map[item.provider] = []
            }
            map[item.provider].push(item)
            return map
          }, {})
          const mixedPhotos = createMixedArrayFromObject<Photo>(photosByProvider)
          const photosCount = mixedPhotos.length
          const videosCount = videosFormatted.length
          const gifsCount = gifsFormatted.length
          const maxLength = Math.max(photosCount, videosCount, gifsCount)
          const allContent: AnyContent[] = []
          for (let i = 0; i < maxLength; i++) {
            if (i < photosCount) {
              allContent.push(mixedPhotos[i])
            }
            if (i < videosCount) {
              allContent.push(videosFormatted[i])
            }
            if (i < gifsCount) {
              allContent.push(gifsFormatted[i])
            }
          }

          return allContent
        })
    }

    const filter = CONTENT_TYPE_STOCK_MAP[type]
    const providerWithType = STOCK_CONTENT_PROVIDER_PARAMS_MAP[filter]
      ? STOCK_CONTENT_PROVIDER_PARAMS_MAP[filter][provider] || provider
      : provider
    return dispatch(V1.searchStockContent(query, filter, providerWithType, sortBy)).map(response => {
      if (filter === PHOTO_TYPE) {
        const photos = response.content.map((p: LegacyPhoto) => photoAdapter(p, true))
        const grouped = photos.reduce((map: IndexedObject<any[]>, item: any) => {
          if (!map[item.provider]) {
            map[item.provider] = []
          }
          map[item.provider].push(item)
          return map
        }, {})
        return createMixedArrayFromObject<Photo>(grouped)
      } if (filter === VIDEO_TYPE) {
        return response.content.map((v: LegacyVideo) => videoAdapter(v, true))
      } if (filter === ARTICLE_TYPE) {
        return response.content.map((v: LegacyArticle) => articleAdapter(v))
      } if (filter === GIF_TYPE) {
        return response.content.map((g: LegacyGif) => gifAdapter(g, true))
      }
      return []
    })
  }
}
