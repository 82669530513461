import React from 'react'
import linkStyles from '../AppNavigation.pcss'
import { useDispatch, useSelector } from 'react-redux'
import { planToProfilesArraySelector, planToProfilesSelector } from 'services/plan/selectors'
import { AppNavLink } from '../AppNavLink'
import Collapse from '@mui/material/Collapse'
import SidebarProfileSelector from './SidebarProfileSelector'
import { postsUISelector, sidebarActiveViewSelector } from 'services/post/selectors'
import { connectedDestinationsSelector } from 'services/destinations'
import { useMatch, useNavigate } from 'react-router-dom'
import { setActiveSidebarView, toggleSidebarProfilesExpanded } from 'services/post'
import { FormattedMessage, useIntl } from 'react-intl'
import { CreateUpdatePlanPopup } from './CreateUpdatePlanPopup'
import { createPlan, updatePlan } from 'services/plan/actions'
import { StoreThunkDispatch } from 'store/state'
import styles from './PlansNavigation.pcss'
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight'
import { message } from 'services/snackbar'
import { PostDestination, arrayToRecord } from 'shared'
import { noop } from 'utils/noop'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import { mdiInformationOutline } from '@mdi/js'
import Icon from '@mdi/react'

export function PlansNavigation() {
  const intl = useIntl()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const navigate = useNavigate()
  const match = useMatch('/plans/:planId')
  const postSelectorView = useSelector(sidebarActiveViewSelector)
  const plansInfo = useSelector(planToProfilesSelector)
  const plansArray = useSelector(planToProfilesArraySelector)
  const ui = useSelector(postsUISelector)
  const profiles = useSelector(connectedDestinationsSelector)
  const profilesByPpid = arrayToRecord(Object.values(profiles), 'ppid')
  const planProfilesIds: string[] = (plansInfo[match?.params.planId || '']?.ppids || [])
    .map(ppid => profilesByPpid[ppid]?.id)
    .filter(Boolean)
  const ppidToPlanId = Object.keys(plansInfo).reduce((map: Record<string, string>, planId) => {
    plansInfo[planId].ppids.forEach(ppid => {
      map[ppid] = planId
    })
    return map
  }, {})

  const [createPlanPopupOpen, setCreatePlanPopupOpen] = React.useState<boolean>(false)
  const [editPlan, setEditPlan] = React.useState<PlanBase | null>(null)

  const toggleProfilesVisible = () => {
    dispatch(toggleSidebarProfilesExpanded())
  }

  const openCreatePlanPopup = () => {
    setCreatePlanPopupOpen(true)
  }

  const closePopup = () => {
    setCreatePlanPopupOpen(false)
    setEditPlan(null)
  }

  const setProfileSelectorView = (view: 'grid' | 'list' | 'network') => {
    dispatch(setActiveSidebarView(view))
  }

  const onCreatePlan = (ppids: string[], name: string, copyFrom?: string) => {
    return dispatch(createPlan({ ppids, copyFromPlanId: copyFrom, name }))
      .unwrap()
      .then((plan: any) => {
        setTimeout(() => navigate('/plans/' + plan.id))
      })
      .catch(() => {
        dispatch(message(intl.formatMessage({ id: 'errors.generic' })))
      })
  }

  const onUpdatePlan = (planId: string, name: string, addPpids: string[], copyFrom?: string) => {
    return dispatch(updatePlan({ planId, name, addPpids, copyFrom }))
      .unwrap()
      .then(() => {
        dispatch(message(intl.formatMessage({ id: 'app.nav.publishing-plans.update-success' })))
      })
      .catch(() => {
        dispatch(message(intl.formatMessage({ id: 'errors.generic' })))
      })
  }

  const onProfileClick = ({ ppid }: PostDestination) => {
    navigate(`/plans/${ppidToPlanId[ppid]}`)
  }

  const onEdit = (p: PlanBase) => {
    setEditPlan(p)
    setCreatePlanPopupOpen(true)
  }

  return (
    <div className={linkStyles['plans-nav']}>
      {plansArray.map((p) => (
        <PlanLink plan={p} key={p.id} isDefault={plansInfo[p.id]?.isDefault} onEdit={onEdit} />
      ))}
      <div className={`${linkStyles['child-link']} ${linkStyles['link-action']} ${styles['link-create']}`} onClick={openCreatePlanPopup}>
        + <FormattedMessage id="app.nav.publishing-plans.create" />
      </div>
      <div>
        <div className={styles.title}>
          <FormattedMessage id="general.labels.profiles" />
          <span className={`${styles['btn-toggle']} ${ui.sidebarProfilesExpanded ? styles.open : ''}`} onClick={toggleProfilesVisible}>
            <ArrowIcon />
          </span>
        </div>
        <Collapse in={ui.sidebarProfilesExpanded}>
          <SidebarProfileSelector
            profiles={profiles}
            activeView={postSelectorView}
            selectedProfileIds={planProfilesIds}
            contentClassName={styles['profiles-box']}
            multipleProfileSelection
            onProfileClick={onProfileClick}
            onSelectedProfilesChange={noop}
            setActiveView={setProfileSelectorView}
          />
        </Collapse>
      </div>

      {createPlanPopupOpen && (
        <CreateUpdatePlanPopup
          plan={editPlan}
          onCreate={onCreatePlan}
          onUpdate={onUpdatePlan}
          onClose={closePopup}
        />
      )}
    </div>
  )
}

type PlanBase = { id: string, name: string, ppids: string[] }

function PlanLink({ plan, isDefault, onEdit }: { plan: PlanBase, isDefault?: boolean, onEdit: (p: PlanBase) => void }) {
  const edit = () => {
    onEdit(plan)
  }

  return (
    <AppNavLink
      key={plan.id}
      to={`/plans/${plan.id}`}
      className={linkStyles['plan-link']}
      label={(
        <div className={linkStyles['link-label-box']}>
          <Tooltip title={<FormattedMessage id="actions.edit" />} placement="top">
            <EditIcon className={linkStyles['btn-edit']} onClick={edit} />
          </Tooltip>
          <div className="text-ellipsis">{plan.name}</div>
          {isDefault && (
            <Tooltip title="This plan cannot be deleted">
              <Icon path={mdiInformationOutline} className={linkStyles['icon-i']} size="16px" />
            </Tooltip>
          )}
        </div>
      )}
    />
  )
}
