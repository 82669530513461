import Drawer from '@mui/material/Drawer'
import React, { useRef } from 'react'
import styles from './AIWriter.pcss'
import Chip from '@mui/material/Chip'
import { useDispatch, useSelector } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import {
  addFavoritePrompt,
  removeFavoritePrompt,
  generateCaptions,
  getPromptCategories,
  getPrompts,
  getUserPrompts,
  setPromptId,
  getFavoritePrompts,
  addComposerImages
} from 'services/compose'
import { Prompt, PromptCategory, arrayToRecord, groupByKey } from 'shared'
import { getAssistantLimits, trackSuggestionUsage } from 'services/compose/assistant/net'
import { deleteUserPrompt } from 'services/compose/assistant'
import { PromptCard } from './PromptCard'
import { composerImageUrlsSelector, favoritePromptsSelector, genericPostSelector, userAIPromptsSelector } from 'services/compose/selectors'
import Button from '@mui/material/Button'
import Icon from '@mdi/react'
import { mdiAutoFix, mdiDockRight, mdiClose } from '@mdi/js'
import { Subject } from 'rxjs/Subject'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { message } from 'services/snackbar'
import { CaptionCard, GeneratedCaption } from './PromptCard/CaptionCard'
import CircularProgress from '@mui/material/CircularProgress'
import useAsyncAction from 'hooks/useAsyncAction'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import { map } from 'rxjs/operators/map'
import PPSwitch from 'components/PPSwitch'
import ConfirmDialog from 'components/ConfirmDialog'
import { PromptCategoryType } from 'shared/types/Composer/writer'
import { ImageCarousel } from './ImageCarousel'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'
import { parse } from 'query-string'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

const CATEGORY_FAVORITES_ID = 'favorites'
const VIEW_PROMPTS = 'prompts'
const VIEW_CONTENT = 'content'

export function AIWriter() {
  const navigate = useNavigate()
  const location = useLocation()
  const match = useMatch('/composer/:view')
  const queryParams = parse(location.search)
  const open = match?.params?.view === 'ai' || (location.pathname.startsWith('/content') && queryParams?.view === 'ai')
  const dispatch = useDispatch<StoreThunkDispatch>()
  const postCaption = useSelector(genericPostSelector).status.text
  const [categoryType, setCategoryType] = React.useState<PromptCategoryType>('text')
  const [prompt, setPrompt] = React.useState('')
  const [prompts, setPrompts] = React.useState<Record<string, Prompt[]>>({})
  const [selectedPrompt, setSelectedPrompt] = React.useState<Prompt | null>(null)
  const [categories, setCategories] = React.useState<PromptCategory[]>([])
  const [selectedCategoryId, setSelectedCategoryId] = React.useState<string | null>(null)
  const [loading, setLoading] = React.useState(false)
  const [isGenerating, setIsGenerating] = React.useState(false)
  const [generatedCaption, setGeneratedCaption] = React.useState<GeneratedCaption | null>(null)
  const getCaptions$ = React.useRef<Subject<{ text: string, promptId?: string, variations: number, imageUrl?: string }>>()
  const [variationsCount, setVariationsCount] = React.useState(1)
  // const [activeView, setActiveView] = React.useState(VIEW_PROMPTS)
  const userPrompts = useSelector(userAIPromptsSelector)
  const favoritePrompts = useSelector(favoritePromptsSelector)
  const [deletingPromptIds, setDeletingPromptIds] = React.useState<Record<string, boolean>>({})
  const favoritePromptsMap = arrayToRecord(favoritePrompts, 'id')
  const composerImages = useSelector(composerImageUrlsSelector) as string[]
  const [images, setImages] = React.useState<string[]>([])
  const [useImage, setUseImage] = React.useState(images.length > 0)
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(0)
  const [originalCaption, setOriginalCaption] = React.useState('')
  const containerRef = useRef<HTMLDivElement>(null)
  const textboxRef = useRef<HTMLTextAreaElement>(null)

  const MIN_PROMPT_LENGTH = 10

  const userPromptsByDate = groupByKey(userPrompts, 'dateFormatted')

  React.useEffect(() => {
    if (generatedCaption?.id && userPrompts.find(p => p.id === generatedCaption.id)) {
      setGeneratedCaption(null)
    }
  }, [generatedCaption?.id, userPrompts])

  React.useEffect(() => {
    if (location.state?.imageUrl) {
      setImages([location.state.imageUrl])
      setUseImage(true)
      setCategoryType('text')
    } else {
      setImages(composerImages)
    }
  }, [composerImages, location.state?.imageUrl])

  React.useEffect(() => {
    setSelectedImageIndex(0)
    setUseImage(images.length > 0)
  }, [images.length, open])

  React.useEffect(() => {
    if (location.state?.text) {
      setPrompt(location.state.text)
      setUseImage(false)
      setCategoryType('text')
    } else {
      setPrompt(postCaption)
    }
    setOriginalCaption(location.state?.text || postCaption)
  }, [location.state?.text, postCaption])

  const getLimits = () => {
    return dispatch(getAssistantLimits())
  }

  const [fetchLimits, limits] = useAsyncAction(getLimits)

  React.useEffect(() => {
    fetchLimits()
  }, [dispatch])

  React.useEffect(() => {
    setGeneratedCaption(null)
  }, [selectedCategoryId])

  React.useEffect(() => {
    getCaptions$.current = new Subject()
    getCaptions$.current
      .switchMap(({ text, promptId, variations, imageUrl }) => {
        setIsGenerating(true)
        return dispatch(generateCaptions(text, promptId, variations, imageUrl, categoryType === 'image'))
          .pipe(
            map(response => ({
              ...response,
              id: response.userPromptId,
              prompt: text,
              messages: response.messages || [],
              images: response.images || [],
              isImage: response.images !== undefined
            })),
            catchError(error => {
              const msg = error.response?.error?.message || ''
              return Observable.of({ error: { message: msg } })
            })
          )
      })
      .subscribe((response: any) => {
        setIsGenerating(false)
        if (response.error) {
          dispatch(message('Error generating captions. ' + response.error.message, 'error'))
        } else {
          setGeneratedCaption({ ...response, promptImage: response.imageUrl })
          fetchLimits()
        }
      })

    return () => {
      getCaptions$.current?.unsubscribe()
    }
  }, [dispatch, categoryType])

  React.useEffect(() => {
    setLoading(true)
    dispatch(getPromptCategories())
      .zip(
        dispatch(getPrompts()),
        dispatch(getFavoritePrompts()),
        dispatch(getUserPrompts())
      )
      .toPromise()
      .then(([c, p, f]) => {
        setCategories(c)
        setPrompts(groupByKey(p, 'categoryId'))
        setSelectedCategoryId(f.length > 0 ? CATEGORY_FAVORITES_ID : c[0].id)
        setLoading(false)
      })
  }, [dispatch, open])

  const onClose = () => {
    if (location.state.opener && location.state.opener !== '/composer') {
      navigate(location.state.opener)
    } else {
      navigate('/composer', { state: location.state })
    }
  }

  const goToComposer = () => {
    navigate('/composer', { state: location.state })
  }

  const onPromptFavorite = (p: Prompt) => {
    return dispatch(addFavoritePrompt(p))
  }

  const onPromptUnfavorite = (p: Prompt) => {
    return dispatch(removeFavoritePrompt(p.id))
  }

  const onPromptChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPrompt(event.target.value)
  }, [])

  const onPromptCardClick = (prompt: Prompt) => {
    setSelectedPrompt(prompt)
    if (prompt.imageUrl) {
      setImages([prompt.imageUrl])
      setUseImage(true)
    }
    setPrompt(`${prompt.text} ${originalCaption}`)
  }

  const submit = () => {
    const value = prompt.trim() || selectedPrompt?.text
    if (value && value.length >= MIN_PROMPT_LENGTH) {
      const imageUrl = useImage ? images[selectedImageIndex] : undefined
      getCaptions$.current?.next({
        text: value,
        promptId: selectedPrompt?.id,
        variations: variationsCount,
        imageUrl
      })
      dispatch(getUserPrompts()).toPromise()
      setGeneratedCaption(null)
    } else {
      dispatch(message('Your prompt must be at least 10 characters long.', 'info'))
    }
  }

  const onCaptionCopied = (id: string) => {
    dispatch(message('Caption copied! Now paste it in the composer.', 'info'))
    dispatch(trackSuggestionUsage(id)).toPromise().catch(e => console.error(e))
    dispatch(setPromptId(id))
    goToComposer()
  }

  const onCreateImagePost = (imageUrl: string, promptId: string) => {
    dispatch(addComposerImages([imageUrl]))
    dispatch(trackSuggestionUsage(promptId)).toPromise().catch(e => console.error(e))
    dispatch(setPromptId(promptId))
    goToComposer()
  }

  const onVariationsCountChange = (value: string) => {
    setVariationsCount(Number(value))
  }

  const onCopyPromptClick = (value: string, imageUrl?: string) => {
    setPrompt(value)
    if (imageUrl) {
      setImages([imageUrl])
      setUseImage(true)
    }
    textboxRef.current?.classList.add(styles.animate)
    containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const variationsOptions = {
    1: { label: '1' },
    2: { label: '2' },
    3: { label: '3' },
    4: { label: '4' },
    5: { label: '5' }
  }

  const creditsLeft = limits ? Math.max(0, limits.maxTags - limits.tags) : null
  selectedCategoryId && selectedCategoryId !== CATEGORY_FAVORITES_ID && prompts[selectedCategoryId]
  const visiblePrompts = React.useMemo(() => {
    if (selectedCategoryId === CATEGORY_FAVORITES_ID) {
      return favoritePrompts
    }

    return selectedCategoryId && prompts[selectedCategoryId] ? prompts[selectedCategoryId] : []
  }, [selectedCategoryId, prompts, favoritePrompts])

  // const viewOptions = [{
  //   value: VIEW_PROMPTS,
  //   label: 'Prompts'
  // }, {
  //   value: VIEW_CONTENT,
  //   label: 'Content from AI'
  // }]

  // const categoryTypeOptions: PPSelectOptions = {
  //   text: { label: 'Text captions' },
  //   image: { label: 'Images' }
  // }

  const deleteSavedPrompt = (id: string) => {
    setDeletingPromptIds((current: Record<string, boolean>) => ({ ...current, [id]: true }))
    dispatch(deleteUserPrompt(id)).toPromise().catch(console.error)
  }

  // const onChangeCategoryType = (type: PromptCategoryType) => {
  //   setCategoryType(type)
  //   setSelectedCategoryId(categories.find(c => c.type === type)?.id || null)
  // }

  const onSeletedCategoryChange = (id: string) => {
    setSelectedCategoryId(id)
  }

  const removeImages = () => {
    setUseImage(false)
  }

  const favoriteCustomPrompt = () => {
    const value = prompt.trim()
    if (value.length < MIN_PROMPT_LENGTH) {
      dispatch(message('Your prompt must be at least 10 characters long.', 'info'))
      return
    }
    dispatch(addFavoritePrompt({ text: value, imageUrl: useImage ? images[0] : undefined }))
      .toPromise()
      .then(() => {
        dispatch(message('Prompt saved to favorites!', 'info'))
      })
  }

  const hasContent = isGenerating || generatedCaption || userPrompts.length > 0

  return (
    <Drawer
      anchor="bottom"
      open={open}
      disableEnforceFocus
      classes={{ paper: styles.container }}
      data-testid="ai-writer"
      onClose={onClose}
    >
      <div className={styles.wrapper} ref={containerRef}>
        <div className={styles.layout}>
          <div className={styles.main}>
            <h1 className={styles.heading}>
              <span>Hey AI, create content for me based on my prompt.</span>
              {/* <PPSelect
              options={categoryTypeOptions}
              withCaret
              raised
              selectedValue={categoryType}
              className={styles.select}
              labelClassName={styles['control-btn']}
              onSelectionChange={onChangeCategoryType}
            /> */}
            </h1>
            <div className={styles.col2}>
              <section className={`${styles.section} ${styles['section-45']}`}>
                <h3 className={styles.subtitle}>Choose a category</h3>
                <div className={styles.categories}>
                  {categories.filter(c => c.type === categoryType).map((category) => (
                    <CategoryButton
                      key={category.id}
                      active={category.id === selectedCategoryId}
                      category={category}
                      onClick={onSeletedCategoryChange}
                    />
                  ))}
                </div>
              </section>
              <section className={`${styles.section} ${styles['section-55']}`}>
                <h3 className={`${styles.subtitle} ${styles['with-label']}`}>
                  My prompt
                  {creditsLeft !== null && (
                    <span className={styles.label}>
                      Daily AI credits left: <b className={creditsLeft === 0 ? styles.red : ''}>{creditsLeft}</b>
                    </span>
                  )}
                </h3>
                <div>
                  <div className={styles.textbox}>
                    <textarea
                      ref={textboxRef}
                      className={`${styles.textarea} ${useImage ? styles['w-img'] : ''}`}
                      value={prompt}
                      placeholder="Enter your prompt here..."
                      disabled={loading}
                      onAnimationEnd={() => { textboxRef.current?.classList.remove(styles.animate) }}
                      onChange={onPromptChange}
                    />
                    {useImage && images.length > 0 && (
                      <ImageCarousel
                        images={images}
                        activeImageIndex={selectedImageIndex}
                        className={styles.carousel}
                        onClose={removeImages}
                        onActiveImageIndexChange={setSelectedImageIndex}
                      />
                    )}
                  </div>
                  <div className={styles.actions}>
                    {prompt.length > 0 && (
                      <span role="button" className={`${styles.btn} ${styles['btn-fav']}`} onClick={favoriteCustomPrompt}>
                        Save to favorites
                      </span>
                    )}
                    <PPSelect
                      name="Variations"
                      options={variationsOptions}
                      withCaret
                      raised
                      buttonClassName={styles.btn}
                      selectedValue={variationsCount.toString()}
                      labelClassName={styles['select-label']}
                      onSelectionChange={onVariationsCountChange}
                    />
                    <Button
                      startIcon={<Icon path={mdiAutoFix} size="16px" />}
                      variant="contained"
                      color="primary"
                      className={styles.btn}
                      disabled={isGenerating || !prompt.trim()}
                      onClick={submit}
                    >
                      Generate content
                    </Button>
                  </div>
                </div>
              </section>
            </div>
            <section className={styles.section}>
              <h3 className={styles.subtitle}>
                <span>Choose a prompt</span>
                {/* <PPSwitch
                  options={viewOptions}
                  selectedValue={activeView}
                  btnClassName={styles['control-btn']}
                  onSelectedValueChange={setActiveView}
                /> */}
                {favoritePrompts.length > 0 && (
                  <CategoryButton
                    key="favorites"
                    active={selectedCategoryId === CATEGORY_FAVORITES_ID}
                    className={styles['btn-favorites']}
                    category={{ id: CATEGORY_FAVORITES_ID, name: 'My favorites' } as any}
                    onClick={onSeletedCategoryChange}
                  />
                )}
              </h3>
              <div className={styles.grid}>
                {visiblePrompts.map(p => (
                  <PromptCard
                    key={p.id}
                    prompt={p}
                    isFavorite={!!favoritePromptsMap[p.id]}
                    selected={selectedPrompt?.id === p.id}
                    onClick={onPromptCardClick}
                    onFavorite={onPromptFavorite}
                    onUnfavorite={onPromptUnfavorite}
                  />
                ))}
              </div>
            </section>
          </div>
          {hasContent && (
            <div className={styles.sidebar}>
              <h1 className={styles.heading}>
                Content from AI
              </h1>
              {isGenerating && (
                <div className={styles.loader}>
                  <CircularProgress size="40px" />
                </div>
              )}
              {generatedCaption && (
                <CaptionCard
                  caption={generatedCaption}
                  className={styles['caption-card']}
                  onImageSelected={onCreateImagePost}
                  onCopy={onCaptionCopied}
                />
              )}
              {userPrompts.length > 0 && (
                <ConfirmDialog message="Delete content permanently?" labelOK="Yes">
                  {(confirm) => (
                    <div>
                      {Object.keys(userPromptsByDate).map(label => (
                        <React.Fragment>
                          <h1 className={styles.groupTitle} key={label}>{label}</h1>
                          {userPromptsByDate[label].map(p => (
                            <CaptionCard
                              key={p.id}
                              caption={{
                                id: p.id,
                                messages: p.response,
                                prompt: p.prompt,
                                images: p.images,
                                isImage: p.isImage,
                                promptImage: p.imageUrl
                              }}
                              className={`${styles['caption-card']} ${deletingPromptIds[p.id] ? styles.deleting : ''}`}
                              onCopy={onCaptionCopied}
                              onCopyPrompt={onCopyPromptClick}
                              onDelete={confirm(deleteSavedPrompt)}
                              onImageSelected={onCreateImagePost}
                            />
                          ))}
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                </ConfirmDialog>
              )}
            </div>
          )}
        </div>
      </div>
    </Drawer>
  )
}

type CategoryButtonProps = { category: PromptCategory, active: boolean, className?: string, onClick: (id: string) => void }

function CategoryButton({ category, active, className, onClick }: CategoryButtonProps) {
  const handleClick = () => {
    onClick(category.id)
  }

  return (
    <Chip
      label={category.name}
      className={`${styles.chip} ${active ? styles.active : ''} ${className || ''}`}
      onClick={handleClick}
    />
  )
}
