import { createElement } from 'react'
import Loader from 'components/SimpleLoader'
import loadable, { LoadableComponent } from '@loadable/component'
import { lazyLoad } from 'utils/chunk'

const AsyncPlan: LoadableComponent<any> = loadable(() => lazyLoad(
  () => import(
    /* webpackPrefetch: true */
    /* webpackChunkName: 'plan' */
    './Plan'
  ),
  'plan'
), {
  fallback: createElement(Loader, { title: 'Loading Plan...' })
})

export default AsyncPlan
