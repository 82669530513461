import { Feed, Paginated, ErrorResponse, ContentFeedType, FeedsResponse, LegacyFeed } from 'interfaces'
import mapAvatar from './avatar'
import assert from 'services/errors'
import noop from 'services/noop'
const BASE_TEN = 10

export function feedAdapter(feed: LegacyFeed): Feed {
  const type = feed.type as ContentFeedType

  return {
    id: '' + feed.id,
    streamId: '' + (feed.folderId || feed.streamId),
    uniqueSource: '' + (feed.uniqueSource || feed.uniqueSourceId || feed.id),
    handle: feed.handle.toLowerCase().replace(/\/$/, ''), // remove trailing slash
    type,
    isFeed: true,
    fanCount: typeof feed.fanCount === 'string' ? parseInt(feed.fanCount, BASE_TEN) : feed.fanCount,
    order: +feed.order,
    name: feed.name || feed.handle,
    image: feed.profilePicture || mapAvatar(type, feed),
    sources: feed.filters,
    lastUpdated: feed.lastUpdated,
    coverPhoto: feed.coverPhoto ? feed.coverPhoto.url : undefined,
    isPrivate: feed.isPrivate,
    title: feed.title,
    networkId: feed.networkId || feed.id.toString()
  }
}

export function feedsAdapter(response: FeedsResponse | ErrorResponse): Paginated<Feed[]> {
  const feedsResponse = assert<FeedsResponse>(response)
  return {
    items: (feedsResponse.feeds || []).filter(Boolean).map(feedAdapter).filter(feed => feed.type),
    hasNext: !!(feedsResponse.pagination && feedsResponse.pagination.next && feedsResponse.pagination.next.length)
  }
}

export function createFeedsAdapter(options: { pre?(feed: LegacyFeed): LegacyFeed, post?(feed: Feed): Feed }) {
  const pre = options.pre || noop
  const post = options.post || noop

  return (response: FeedsResponse | ErrorResponse) => {
    const feedsResponse = assert<FeedsResponse>(response)

    const input = { ...feedsResponse, photos: feedsResponse.feeds.map(pre) }
    const output = feedsAdapter(input)
    return { ...output, items: output.items.map(post) }
  }
}
