import * as React from 'react'
import FavoritesView, { FavoritesViewMode } from 'components/FavoritesView'
import { SECTION_URL_FAVORITES } from '../../home.config'

import styles from '../HomeSection.pcss'

export const FavoritesCarousel = React.memo(
  function (props: { title?: string }) {
    return (
      <FavoritesView
        title={props.title}
        mode={FavoritesViewMode.Carousel}
        expandUrl={SECTION_URL_FAVORITES}
        className={styles.container}
      />
    )
  }
)

export default FavoritesCarousel
