import * as React from 'react'
import { FailedPost as IFailedPost, POST_TYPE_FAILED } from 'interfaces'
import PostBase from './PostBase'
import ConfirmDialog from 'components/ConfirmDialog'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Checkbox from '@mui/material/Checkbox'
import DeleteIcon from '@mui/icons-material/Close'
import { useIntl } from 'react-intl'
import { RecycleSelector } from './components/RecycleSelector'
import { mdiFeather, mdiSend, mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'
import styles from './PostBase.pcss'
import { noop } from 'utils/noop'
import { useSelector } from 'react-redux'
import { profilesByPpidSelector } from 'services/destinations'

export interface FailedPostProps {
  post: IFailedPost
  selected: boolean
  onRepost: (post: IFailedPost) => void
  onShareNext: (post: IFailedPost, shouldDelete?: boolean) => void
  onShareNow: (post: IFailedPost) => void
  onDelete: (postId: string) => void
  onToggleSelected: (postId: string) => void
  onMediaPreviewBtnClick: (post: IFailedPost) => void
}

export function FailedPost(props: FailedPostProps) {
  const intl = useIntl()
  const { post, selected } = props
  const profiles = useSelector(profilesByPpidSelector)
  const profile = profiles[post.ppPageId]

  const onMediaPreviewBtnClick = React.useCallback(() => {
    props.onMediaPreviewBtnClick(post)
  }, [props.post.id])

  const reschedulePost = React.useCallback(() => {
    props.onRepost(post)
  }, [props.post.id])

  const shareNow = React.useCallback(() => {
    props.onShareNow(post)
  }, [props.post.id])

  const deletePost = React.useCallback(() => {
    props.onDelete(post.id)
  }, [props.post.id])

  const toggleSelected = React.useCallback(() => {
    props.onToggleSelected(post.id)
  }, [props.post.id])

  const shareNext = React.useCallback(() => {
    props.onShareNext(post)
  }, [])

  const shareNextAndDeletePost = React.useCallback(() => {
    props.onShareNext(post, true)
  }, [])

  const actions = React.useMemo(() => (
    <ConfirmDialog
      message="What should happen to this failed post after this action?"
      labelOK="Keep post in Failed"
      labelCancel="Delete post from Failed"
      btnCancelClassName={styles['btn-confirm-delete']}
      btnOKClassName={styles['btn-confirm-keep']}
      onDecline={shareNextAndDeletePost}
    >
      {(confirm: any) => (
        <div className={styles['actions-failed']}>
          <RecycleSelector
            value={post.recycle}
            className={styles.recycle}
            activeClassName={styles.on}
            disabled
            onChange={noop}
          />
          <div>
            <Tooltip
              placement="top"
              title={intl.formatMessage({ id: 'post.actions.re-post' })}
              onClick={reschedulePost}
            >
              <IconButton size="small" aria-label="re-post">
                <Icon size="22px" path={mdiFeather} />
              </IconButton>
            </Tooltip>
            <Tooltip
              placement="top"
              title={intl.formatMessage({ id: 'post.actions.share-next' })}
              onClick={confirm(shareNext)}
            >
              <IconButton size="small" aria-label="share next">
                <Icon size="20px" path={mdiPlus} />
              </IconButton>
            </Tooltip>
            <Tooltip
              placement="top"
              title={intl.formatMessage({ id: 'post.actions.share-now' })}
              onClick={shareNow}
            >
              <IconButton size="small" aria-label="share now">
                <Icon size="20px" path={mdiSend} />
              </IconButton>
            </Tooltip>
          </div>

          <div className={styles['actions-right']}>
            <ConfirmDialog
              message={intl.formatMessage({ id: 'post.delete-confirm' })}
              labelOK={intl.formatMessage({ id: 'actions.yes', defaultMessage: 'Yes' })}
              labelCancel={intl.formatMessage({ id: 'actions.no', defaultMessage: 'No' })}
            >
              {(confirm) => (
                <Tooltip
                  placement="top"
                  title={intl.formatMessage({ id: 'post.actions.delete' })}
                  onClick={confirm(deletePost)}
                >
                  <IconButton size="small" aria-label="delete" className={styles['btn-delete']}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </ConfirmDialog>
            <Checkbox color="primary" classes={{ root: styles.checkbox }} checked={selected} onChange={toggleSelected} />
          </div>
        </div>
      )}
    </ConfirmDialog>
  ), [deletePost, post.recycle, reschedulePost, selected, shareNext, shareNextAndDeletePost, shareNow, toggleSelected])

  return (
    <PostBase
      actions={actions}
      postId={post.id}
      postType={POST_TYPE_FAILED}
      contentType={post.contentType}
      text={post.status}
      timeFormatted={post.timeString}
      timezone={post.timezone}
      images={post.images}
      profileNetwork={post.network}
      profile={profile}
      error={post.error}
      authorName={post.authorName}
      title={post.linkTitle}
      description={post.linkDescription}
      linkUrl={post.linkUrl}
      nativeVideoUrl={post.nativeVideoUrl}
      fbVideoUrl={post.fbVideoUrl}
      twitterPostType={post.twPostType}
      specialType={post.specialType}
      linkDomain={post.linkDomain}
      onMediaPreviewBtnClick={onMediaPreviewBtnClick}
      attributes={post.attributes}
      date={post.calendarDate}
      details={post.details}
      bucket={post.bucket}
    />
  )
}

export default React.memo(FailedPost)
