import { LEGACY_URL } from 'config'
import { authorizedPipe, RequestBuilder } from 'services/net'
import { StoreThunkAction, StoreThunkDispatch } from 'store/state'

const UPDATE_INTERCOM_ENDPOINT = `${LEGACY_URL}/v3/intercom`

function track() {
  const builder = new RequestBuilder(UPDATE_INTERCOM_ENDPOINT).expectJSON()
  return authorizedPipe<any>(builder.asGet().build())
}

export function updateIntercom(): StoreThunkAction<Promise<any>> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(track()).toPromise()
  }
}
