import * as React from 'react'
import Chip from '@mui/material/Chip'
import styles from './SuggestedQuoteSearches.pcss'
import HorizontalList from 'components/HorizontalList'
import { useDispatch, useSelector } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import {
  deleteRecentQuoteSearch,
  deleteSavedSearch,
  getPastSearches,
  getTags
} from 'services/content/quotes/actions'
import { V1 } from 'admin/services/quotes/net'
import { Subject } from 'rxjs/Subject'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { QuotesContext, saveSearches, deleteSavedSearch as deleteSearchFromContext } from '../QuotesContext'
import { QueryChip } from 'components/ContentPanels/components/QueryChip'
import { recentQuotesSearchesSelector } from 'services/content/selectors'

const CAROUSEL_HEIGHT = 40
interface SuggestedQuoteSearchesProps {
  activeQuery: string
  tag?: string
  searchElement?: React.ReactNode
  className?: string
  hideSavedSearches?: boolean
  onQueryClick: (value: string) => void
  onSuggestedSearchClick: (search: string, isTag: boolean) => void
}

export function SuggestedQuoteSearches(props: SuggestedQuoteSearchesProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const deleteSearch$ = React.useRef<Subject<string>>()
  const { savedSearches, dispatch: quotesDispatch } = React.useContext(QuotesContext)
  const [suggestedSearches, setSuggestedSearches] = React.useState<Array<{ query: string, isTag: boolean }>>([])
  const cachedSearches = useSelector(recentQuotesSearchesSelector)
  const [loading, setLoading] = React.useState(cachedSearches.length === 0)

  const arr = new Array(6).fill('') // eslint-disable-line no-magic-numbers
  const loadingChips = arr.map((_, index) => <Chip key={index} className={`${styles['loading-chip']} ${styles.gradient}`} />)

  React.useEffect(() => {
    quotesDispatch(saveSearches(cachedSearches.map((search: any) => search.term)))
  }, [])

  React.useEffect(() => {
    const sub = dispatch(getPastSearches())
      .pipe(catchError(() => Observable.of([])))
      .subscribe((searches) => {
        setLoading(false)
        quotesDispatch(saveSearches(searches.map((search: any) => search.term)))
      })
    dispatch(getTags())
      .zip(
        dispatch(getPastSearches()),
        dispatch(V1.getKeywords())
      )
      .pipe(catchError(() => Observable.of([[], [], []])))
      .subscribe(([tagsResponse, searches, keywordsResponse]) => {
        const tags = tagsResponse.map((t: any) => ({
          query: t.name,
          isTag: true
        }))
        const keywords = keywordsResponse.map((kw: any) => ({
          query: kw.name,
          isTag: false
        }))
        const unshuffled = [...keywords, ...tags]
        const shuffled = unshuffled.map((value) => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value)
        setSuggestedSearches(shuffled)
        quotesDispatch(saveSearches(searches.map((search: any) => search.term)))
      })

    return () => {
      sub.unsubscribe()
    }
  }, [])

  React.useEffect(() => {
    deleteSearch$.current = new Subject()
    deleteSearch$.current
      .flatMap((text: string) => dispatch(deleteSavedSearch(text))
        .pipe(
          catchError(() => Observable.of({}))
        )
      ).subscribe()

    return () => {
      deleteSearch$.current?.unsubscribe()
    }
  }, [])

  const deleteSearch = (text: string) => {
    deleteSearch$.current?.next(text)
    quotesDispatch(deleteSearchFromContext(text))
    dispatch(deleteRecentQuoteSearch(text))
  }

  return (
    <div className={props.className || ''}>
      {(!props.hideSavedSearches || props.searchElement) && (
        <div className={styles.list}>
          {props.searchElement}
          {!props.hideSavedSearches && loading && (
            <div className={styles['loading-box']}>
              {loadingChips}
            </div>
          )}
          {!props.hideSavedSearches && !loading && (
            savedSearches.map(q => (
              <QueryChip
                key={q}
                label={q}
                className={styles.chip}
                active={q === props.activeQuery}
                onClick={props.onQueryClick}
                onDelete={deleteSearch}
              />
            ))
          )}
        </div>
      )}
      {suggestedSearches.length > 0 ? (
        <HorizontalList className={styles.carousel} listHeight={CAROUSEL_HEIGHT}>
          {suggestedSearches.map(tag => (
            <QueryChip
              key={tag.query}
              label={tag.query}
              isTag={tag.isTag}
              className={styles.chip}
              active={tag.query === props.tag || !tag.isTag && tag.query === props.activeQuery}
              onClick={props.onSuggestedSearchClick}
            />
          ))}
        </HorizontalList>
      ) : (
        <div className={`${styles['loading-box']} ${styles['loading-bottom']}`}>
          {loadingChips}
        </div>
      )}
    </div>
  )
}
