import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'
import { PlannedPost, PostedPost, FailedPost, ContentType } from 'interfaces'
import { PostedPostsSearchParams } from 'shared/types'
export const ENDPOINT_GET_PLANNED_POSTS = `${LEGACY_URL}/v3/posts/get_v3/:filter`
export const ENDPOINT_GET_PLANNED_POSTS_BY_RANGE = `${LEGACY_URL}/v3/posts/get_by_date_v3`
export const ENDPOINT_GET_POSTED_POSTS_BY_RANGE = `${LEGACY_URL}/v3/posts/get_posted_by_date_v3`
export const ENDPOINT_GET_PENDING_POSTS = `${LEGACY_URL}/v3/posts/get_pending_v3`
export const ENDPOINT_GET_POSTED_POSTS = `${LEGACY_URL}/v3/posts/get_my_posted_v3`
export const ENDPOINT_GET_FAILED_POSTS = `${LEGACY_URL}/v3/posts/get_failed_posted_v3`
const PAGE_SIZE_DEFAULT = 50

export const V1 = Object.freeze({
  getPlannedPosts: function () {
    return function (
      page: number,
      pageIds: string[],
      pageSize?: number,
      query?: string,
      types?: ContentType[],
      withEmptySlots?: boolean,
      filter?: 'queued' | 'scheduled' | 'buckets' | 'no-bucket',
      searchInSummary?: boolean,
      withVirtualPosts?: boolean
    ) {
      const queueSource = {
        buckets: 'bucket',
        'no-bucket': 'no-bucket',
        queued: 'all'
      }
      const filterPath = {
        scheduled: 'scheduled',
        queued: 'queued',
        buckets: 'queued',
        'no-bucket': 'queued'
      }
      const endpoint = ENDPOINT_GET_PLANNED_POSTS
      const builder = new RequestBuilder(endpoint).asPost().asFormUrlEncoded().expectJSON()
      const data: any = {
        page,
        page_ids_new: pageIds,
        pageSize: pageSize || PAGE_SIZE_DEFAULT
      }
      if (query) {
        data.search = query
      }
      if (types) {
        data.post_types = types.join(',')
      }
      if (withVirtualPosts) {
        data.virtual_posts = true
      }
      if (withEmptySlots) {
        data.empty_slots = true
      }
      if (searchInSummary) {
        data.search_in_summary = true
      }
      if (filter && filter !== 'scheduled') {
        data.source = queueSource[filter]
      }
      return authorizedPipe<{ posts: PlannedPost[], schedules?: any[], postsCount: number, hasPendingPosts: boolean }>(
        builder
          .body(data)
          .partial(':filter', filter ? filterPath[filter] || '' : '')
          .build()
      )
    }
  }(),

  getPlannedPostsForRange: function () {
    return function (startTime: string, endTime: string, ppids: string[], withEmptySlots?: boolean, withVirtualPosts?: boolean) {
      const endpoint = ENDPOINT_GET_PLANNED_POSTS_BY_RANGE
      const builder = new RequestBuilder(endpoint).asPost().asFormUrlEncoded().expectJSON()
      const data: any = {
        pp_page_ids: ppids,
        date_from: startTime,
        date_to: endTime,
        empty_slots: Boolean(withEmptySlots)
      }
      if (withVirtualPosts) {
        data.virtual_posts = true
      }
      return authorizedPipe<{ posts: PlannedPost[], schedules?: any[], postsCount: number, hasPendingPosts: boolean }>(
        builder.body(data).build()
      )
    }
  }(),

  getPendingPosts: function () {
    return function (page: number, pageIds: string[], query?: string, types?: ContentType[], searchInSummary?: boolean) {
      const endpoint = ENDPOINT_GET_PENDING_POSTS
      const builder = new RequestBuilder(endpoint).asPost().asFormUrlEncoded().expectJSON()

      const data: any = {
        page,
        page_ids_new: pageIds,
        pageSize: PAGE_SIZE_DEFAULT
      }

      if (query) {
        data.search = query
      }
      if (types) {
        data.post_types = types.join(',')
      }
      if (searchInSummary) {
        data.search_in_summary = true
      }

      return authorizedPipe<{ posts: PlannedPost[], schedules?: any[], postsCount: number }>(
        builder
          .body(data)
          .build()
      )
    }
  }(),

  getPostedPosts: function () {
    return function ({
      page, pageIds, sortBy, ppids, bucketId, query, types, sortDirection, searchInArticleSummary, startDate, endDate
    }: PostedPostsSearchParams) {
      const endpoint = ENDPOINT_GET_POSTED_POSTS
      const builder = new RequestBuilder(endpoint).asPost().asFormUrlEncoded().expectJSON()
      const data: any = {
        page,
        page_ids_new: pageIds,
        sort_by: sortBy,
        pageSize: PAGE_SIZE_DEFAULT
      }

      if (ppids) {
        data.pp_page_ids = ppids
      }

      if (bucketId) {
        data.bucket_id = bucketId
      }

      if (query) {
        data.search = query
      }
      if (types) {
        data.post_types = types.join(',')
      }

      if (sortDirection) {
        data.sort_order = sortDirection
      }

      if (searchInArticleSummary) {
        data.search_in_summary = true
      }

      if (startDate && endDate) {
        const start = new Date(startDate)
        const end = new Date(endDate)
        data.date_from = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`
        data.date_to = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`
      }

      return authorizedPipe<{ posts: PostedPost[], showsPostedByExternalApps: boolean, postsCount: number, paging: any }>(
        builder
          .body(data)
          .build()
      )
    }
  }(),
  getPostedPostsForRange: function () {
    return function (startDate: Date, endDate: Date, ppids: string[]) {
      const builder = new RequestBuilder(ENDPOINT_GET_POSTED_POSTS_BY_RANGE).asPost().asFormUrlEncoded().expectJSON()
      return authorizedPipe<any>(
        builder
          .body({
            pp_page_ids: ppids,
            date_from: `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`,
            date_to: `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
          })
          .build()
      )
    }
  }(),

  getFailedPosts: function () {
    return function (
      page: number, pageIds: string[], ppids?: string[], query?: string, types?: ContentType[], searchInArticleSummary?: boolean
    ) {
      const endpoint = ENDPOINT_GET_FAILED_POSTS
      const builder = new RequestBuilder(endpoint).asPost().asFormUrlEncoded().expectJSON()
      const body: any = { page, pageSize: PAGE_SIZE_DEFAULT, page_ids_new: pageIds }

      if (ppids) {
        body.pp_page_ids = ppids
      }

      if (query) {
        body.search = query
      }
      if (types) {
        body.post_types = types.join(',')
      }

      if (searchInArticleSummary) {
        body.search_in_summary = true
      }
      return authorizedPipe<{ posts: FailedPost[], postsCount: number }>(
        builder
          .body(body).build()
      )
    }
  }(),
  getFailedPostsCount: function () {
    return function (_ppids?: string[]) {
      const builder = new RequestBuilder(`${LEGACY_URL}/v3/posts/get_failed_count`).asGet().asFormUrlEncoded().expectJSON()

      return authorizedPipe<{ data: Array<{ totalCount: string, ppPageId: string }> }>(
        builder.build()
      )
    }
  }(),
  getPostById: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/posts/details/:id`).asGet().asFormUrlEncoded().expectJSON()

    return function (id: string) {
      return authorizedPipe<any>(
        builder.partial(':id', id).build()
      )
    }
  }()
})
