import * as React from 'react'
import { connect } from 'react-redux'
import { StoreThunkDispatch, StoreState } from 'store/state'
import {
  ContentSource,
  ContentType,
  AnyContent,
  Feed,
  Stream,
  PostDestination,
  INTERACTION_EVENT_OPEN,
  ContentItem,
  FilterType,
  RangeFilter
} from 'interfaces'
import { ContentLayoutHeader } from './ContentLayoutHeader'
import { FetchFeedPageContent, FetchStreamPageContent } from 'components/Fetch'
import CarouselView from './CarouselView'
import { createStream, deleteStream, getStreams, trackStreamInteraction } from 'services/content/streams/actions'
import { addRecentSource, setFeedToSave, trackFeedInteraction } from 'services/content/recommended/actions'
import { userStreamIdsSelector, userStreamsSelector, userFeedsSelector, userSavedStreamsSelector } from 'services/content/selectors'
import { Observable } from 'rxjs/Observable'
import { Subscription } from 'rxjs/Subscription'
import { Subject } from 'rxjs/Subject'
import SourcesBricksView, { BricksSize, FetchableProps, ScrollConfiguration } from 'components/SourcesView/SourcesBricksView'
import { instanceOfStream } from 'services/content/util'
import * as FilterStore from 'utils/filter-store'
import { checkFeatureAvailability, checkProductLimit } from 'services/product'
import { FEATURE_FIND_MY_STREAMS_SAVE, LIMIT_SAVED_STREAMS } from 'shared/constants'
import { IntlShape, injectIntl } from 'react-intl'
import { NewFeedMessage } from 'components/FeedView'

const isEqual = require('react-fast-compare')
import styles from './ContentLayout.pcss'

export interface ContentLayoutProps {
  source: ContentSource
  items?: AnyContent[]
  loading?: boolean
  hidden?: boolean
  browseUrl?: string
  browseLinkText?: string
  navPrefix?: string
  expanded?: boolean
  filter?: FilterType
  range?: RangeFilter
  filtersBoxClassName?: string
  destinations?: { [key: string]: Readonly<PostDestination> }
  // disable auto tracking content interactions
  disableAutoInteractionsTracking?: boolean
  hideNavLink?: boolean
  defaultRange?: RangeFilter
  scroll?: ScrollConfiguration
  gridSizes?: BricksSize[]
  hideHeader?: boolean

  onExpanded?: (source: ContentSource) => void
  onCollapsed?: (source: ContentSource) => void
  onRangeChanged?: (range: RangeFilter) => void
  onFilterChanged?: (filter: FilterType) => void
  onContentItemClick?(content: ContentItem): void
  onContentItemCompose?(content: ContentItem): void
  onSaveSource?(source: ContentSource): void | boolean
}

interface ConnectedContentLayoutProps extends ContentLayoutProps {
  userStreamIds: string[]
  userStreams: Stream[]
  userFeeds: Feed[]
  intl: IntlShape
  savedStreamsCount: number
  trackFeedInteraction: (id: string) => Observable<string[]>
  trackStreamInteraction: (id: string) => Observable<string[]>
  saveStream: (name: string, id: string, color?: string) => Observable<any>
  deleteStream: (id: string) => Observable<string>
  setFeedToSave: (feed: Feed) => any
  getUserStreams: () => Observable<Stream[]>
  addRecentSource: (source: ContentSource) => any
  checkFeatureAvailability: (feature: string) => boolean
  checkProductLimit: (limitKey: string, value: number) => boolean
}

interface ContentLayoutState {
  expanded: boolean
  page: number
  range: RangeFilter
  items: AnyContent[]
  filter: FilterType
  saveLoading: boolean
  error?: any
}

export class ContentLayout extends React.Component<ConnectedContentLayoutProps, ContentLayoutState> {
  static getDerivedStateFromProps(props: ConnectedContentLayoutProps, state: ContentLayoutState) {
    const nextState: Partial<ContentLayoutState> = {}
    if (props.expanded !== state.expanded) {
      nextState.expanded = props.expanded
    }

    if (props.filter && props.filter !== state.filter) {
      nextState.filter = props.filter
    }

    if (props.range && props.range !== state.range) {
      nextState.range = props.range
    }

    return nextState
  }

  private trackViewInteraction$: Subscription
  private deleteStream$: Subscription
  private saveStream$: Subscription
  private unmount$: Subject<boolean>
  private interactions: string[] = []

  get isStream(): boolean {
    return instanceOfStream(this.props.source)
  }

  get isSavedSource(): boolean {
    const stream = this.props.source as Stream
    const feed = this.props.source as Feed
    const uniqueId = this.isStream
      ? stream.originalId || stream.id
      : feed.uniqueSource || feed.id

    return this.isStream
      ? !!this.props.userStreams.find(s => s.id === stream.id || s.originalId === uniqueId)
      : !!this.props.userFeeds.find(f => f.uniqueSource === uniqueId)
  }

  get defaultFilters() {
    if (this.isStream) {
      return FilterStore.getStreamFilters(this.props.source as Stream)
    }

    const storedFilters = FilterStore.getFeedFilters(this.props.source as Feed)
    if ((this.props.source as Feed).sources.includes(storedFilters.type as ContentType)) {
      return storedFilters
    }

    return {
      type: (this.props.source as Feed).sources[0],
      range: storedFilters.range
    }
  }

  get contentTypes(): ContentType[] {
    if (this.isStream) {
      return (this.props.source as Stream).filters
    }

    return (this.props.source as Feed).sources
  }

  constructor(props: ConnectedContentLayoutProps) {
    super(props)

    this.state = {
      expanded: !!this.props.expanded,
      filter: this.props.filter || this.defaultFilters.type,
      range: this.props.range || this.defaultFilters.range,
      page: 0,
      items: [],
      saveLoading: false
    }

    this.unmount$ = new Subject()

    this.renderGrid = this.renderGrid.bind(this)
    this.onFilterChanged = this.onFilterChanged.bind(this)
    this.onRangeChanged = this.onRangeChanged.bind(this)
    this.expand = this.expand.bind(this)
    this.collapse = this.collapse.bind(this)
    this.createLoadMore = this.createLoadMore.bind(this)
    this.trackView = this.trackView.bind(this)
    this.onSaveSource = this.onSaveSource.bind(this)
  }

  shouldComponentUpdate(nextProps: ConnectedContentLayoutProps, nextState: ContentLayoutState) {
    return !isEqual(this.state, nextState) || !isEqual(this.props, nextProps)
  }

  componentWillUnmount() {
    this.unmount$.next(true)

    if (this.trackViewInteraction$) {
      this.trackViewInteraction$.unsubscribe()
    }

    if (this.deleteStream$) {
      this.deleteStream$.unsubscribe()
    }

    if (this.saveStream$) {
      this.saveStream$.unsubscribe()
    }
  }

  setSaveLoading = (isLoading: boolean) => {
    this.setState(prevState => ({ ...prevState, saveLoading: isLoading }))
  }

  trackView() {
    if (this.props.disableAutoInteractionsTracking) {
      return
    }

    const isStream = this.isStream
    const sourceId = isStream
      ? (this.props.source as Stream).originalId || this.props.source.id
      : (this.props.source as Feed).uniqueSource

    // Prevent multiple records of the same source
    if (this.interactions.includes(sourceId)) {
      return
    }

    const trackInteractionObservable = this.isStream
      ? this.props.trackStreamInteraction(this.props.source.id)
      : this.props.trackFeedInteraction(sourceId)

    this.props.addRecentSource(this.props.source)
    this.trackViewInteraction$ = trackInteractionObservable
      .takeUntil(this.unmount$.asObservable())
      .subscribe(() => {
        this.interactions.push(sourceId)
      })
  }

  updateFilters(type: FilterType, range: RangeFilter) {
    if (this.isStream) {
      FilterStore.setStreamFilters((this.props.source as Stream).id, type, range)
      return
    }

    FilterStore.setFeedFilters((this.props.source) as Feed, type, range)
  }

  onFilterChanged(filter: FilterType) {
    this.updateFilters(filter, this.state.range)
    this.setState(prevState => ({ ...prevState, filter }))
    if (this.props.onFilterChanged) {
      this.props.onFilterChanged(filter)
    }
  }

  onRangeChanged(range: RangeFilter) {
    this.updateFilters(this.state.filter, range)
    this.setState(prevState => ({ ...prevState, range }))
    if (this.props.onRangeChanged) {
      this.props.onRangeChanged(range)
    }
  }

  onItemShared = (item: AnyContent) => {
    if (this.props.onContentItemCompose) {
      this.props.onContentItemCompose(item as ContentItem)
    }
  }

  expand() {
    this.setState(prevState => ({ ...prevState, expanded: true }))
    if (this.props.onExpanded) {
      this.props.onExpanded(this.props.source)
    }
  }

  collapse() {
    this.setState(prevState => ({ ...prevState, expanded: false }))
    if (this.props.onCollapsed) {
      this.props.onCollapsed(this.props.source)
    }
  }

  createLoadMore(props: FetchableProps) {
    return this.isStream
      ? (
        <FetchStreamPageContent
          key="stream-fetch"
          {...props as any}
          stream={this.props.source as Stream}
        />
      )
      : <FetchFeedPageContent key="feed-fetch" feed={this.props.source as Feed} {...props} />
  }

  renderGrid() {
    this.trackView()
    const showNewFeedNote = !this.isStream && (this.props.source as Feed).isNew

    return (
      <SourcesBricksView
        isStream={this.isStream}
        feedType={this.props.source?.type as any}
        filter={this.state.filter}
        filtersContainerClassName={this.props.filtersBoxClassName}
        range={this.state.range}
        items={this.state.items}
        contentTypes={this.contentTypes}
        errorElement={showNewFeedNote ? <NewFeedMessage className={styles['new-feed-msg']} /> : undefined}
        createLoadMore={this.createLoadMore}
        onFilterChange={this.onFilterChanged}
        onRangeChanged={this.onRangeChanged}
        onContentItemClick={this.props.onContentItemClick}
        onItemShared={this.onItemShared}
        scroll={this.props.scroll}
        sizes={this.props.gridSizes}
      />
    )
  }

  onSaveSource() {
    if (this.props.onSaveSource) {
      const abort = this.props.onSaveSource(this.props.source)
      if (abort) {
        return
      }
    }

    const id = this.props.source.id
    if (!this.isStream) {
      this.props.setFeedToSave(this.props.source as Feed)
      return
    }

    if (this.isSavedSource) {
      const userStreamToDelete = this.props.userStreams.find(s => s.id === id || s.originalId === id)
      if (userStreamToDelete) {
        this.setSaveLoading(true)
        this.deleteStream$ = this.props.deleteStream(userStreamToDelete.id)
          .takeUntil(this.unmount$)
          .subscribe(() => this.setSaveLoading(false))
      }
      return
    }

    const saveStreamAllowed = this.props.checkFeatureAvailability(FEATURE_FIND_MY_STREAMS_SAVE)
    if (!saveStreamAllowed) {
      return
    }

    const withinLimit = this.props.checkProductLimit(LIMIT_SAVED_STREAMS, this.props.savedStreamsCount + 1)
    if (!withinLimit) {
      return
    }

    const stream = this.props.source as Stream
    this.setSaveLoading(true)
    this.saveStream$ = this.props.saveStream(stream.title, stream.originalId || stream.id, stream.color)
      .flatMap(this.props.getUserStreams)
      .takeUntil(this.unmount$)
      .subscribe(() => this.setSaveLoading(false))
  }

  render() {
    if (this.props.hidden) {
      return null
    }

    const { items, loading, source, browseUrl, browseLinkText, hideNavLink, navPrefix } = this.props
    const isStream = this.isStream
    const title = isStream ? (source as Stream).title : (source as Feed).name
    const defaultBrowseText = this.props.intl.formatMessage({ id: 'general.carousel.nav.see-all', defaultMessage: 'see all' })
    const browseText = browseLinkText || defaultBrowseText

    return (
      <div className={styles.wrapper} data-test="content-layout">
        {!this.props.hideHeader && (
          <ContentLayoutHeader
            source={source}
            hideNavLink={hideNavLink}
            sourceSaved={this.isSavedSource}
            title={title}
            filter={this.state.filter}
            browseLinkText={browseText}
            backLinkText={this.props.intl.formatMessage({ id: 'general.carousel.nav.back', defaultMessage: 'back' })}
            browseUrl={browseUrl}
            prefix={navPrefix}
            saveLoading={this.state.saveLoading}
            contentTypes={this.contentTypes}
            expanded={this.state.expanded}
            onFilterChanged={this.onFilterChanged}
            onExpandLinkClick={this.expand}
            onBackLinkClick={this.collapse}
            onSave={this.onSaveSource}
          />
        )}

        {
          this.state.expanded
            ? this.renderGrid()
            : (
              <CarouselView
                source={source}
                items={items}
                loading={loading}
                defaultRange={this.props.defaultRange}
                filter={this.state.filter}
                key="list-view"
                destinations={this.props.destinations}
                onContentItemClick={this.props.onContentItemClick}
                onContentItemCompose={this.props.onContentItemCompose}
              />
            )
        }
      </div>
    )
  }
}

function mapStateToProps(state: StoreState) {
  return {
    userStreams: userStreamsSelector(state),
    userStreamIds: userStreamIdsSelector(state),
    userFeeds: userFeedsSelector(state),
    savedStreamsCount: userSavedStreamsSelector(state).length
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    trackFeedInteraction: (id: string) => {
      return Observable.fromPromise(dispatch(trackFeedInteraction({ feedIds: [id], event: INTERACTION_EVENT_OPEN })).unwrap())
    },
    trackStreamInteraction: (id: string) => {
      return Observable.fromPromise(dispatch(trackStreamInteraction({ streamIds: [id], event: INTERACTION_EVENT_OPEN })).unwrap())
    },
    saveStream: (name: string, id: string, color?: string) => Observable.fromPromise(dispatch(createStream({ name, id, color })).unwrap()),
    deleteStream: (id: string) => Observable.fromPromise(dispatch(deleteStream(id)).unwrap()),
    setFeedToSave: (feed: Feed) => dispatch(setFeedToSave(feed)),
    getUserStreams: () => Observable.fromPromise(dispatch(getStreams(true)).unwrap()),
    addRecentSource: (source: ContentSource) => dispatch(addRecentSource(source)),
    checkFeatureAvailability: (feature: string) => dispatch(checkFeatureAvailability(feature)),
    checkProductLimit: (limitKey: string, value: number) => dispatch(checkProductLimit(limitKey, value))
  }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ContentLayout))
