import { Component, createElement } from 'react'
import { findDOMNode } from 'react-dom'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import Paper from '@mui/material/Paper'
import { STATUS_TYPE, StatusIdea, WithIntl } from 'interfaces'
import StoreState, { StoreThunkDispatch } from 'store/state'
import { message } from 'services/snackbar'
import styles from './StatusIdeaCard.pcss'
import FavButton from './FavButton'
import { Observable } from 'rxjs/Observable'
import { addFavoriteStatusIdea, removeFavoriteStatusIdea } from 'services/content/favorites'
import { favStateSelectorForItem } from 'services/content/selectors'
import { catchError } from 'rxjs/operators/catchError'
import { checkFeatureAvailability, getProductUpgradeByFeature, promptUpgrade } from 'services/product'
import { FEATURE_FIND_STATUS_IDEAS } from 'shared/constants'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { FeatherIcon } from 'components/Icons'
import { useNavigate, useLocation } from 'react-router-dom'
import { fontSizesFor } from 'utils/fontSize'
import ContentCardUpgradeOverlay from './ContentCardUpgradeOverlay'
import { mdiAutoFix } from '@mdi/js'
import Icon from '@mdi/react'

const MAX_STATUS_LENGTH_CENTERED = 300
const Clipboard = require('clipboard')

export interface StatusIdeaCardProps {
  content: StatusIdea
  small?: boolean
  className?: string
  actionLabel?: React.ReactNode
  actionButtonIcon?: React.ReactNode
  onCompose?(content: StatusIdea): void | boolean
}

interface StatusIdeaCardState {
  expanded: boolean
}

type ConnectedStatusIdeaCardProps = StatusIdeaCardProps & WithIntl & {
  favState: {
    added: boolean
    removed: boolean
  }
  location: any
  navigate: any
  showNotification(text: string): void
  addFavorite: (item: StatusIdea) => Observable<any>
  removeFavorite: (item: StatusIdea) => Observable<any>
  isTextContentAllowed(noPopup?: boolean): boolean
  onUpgrade: (feature: string) => void
}

export class StatusIdeaCard extends Component<ConnectedStatusIdeaCardProps, StatusIdeaCardState> {
  private clipboard?: any
  private btnCompose: HTMLButtonElement | null
  private statusesAllowed: boolean

  constructor(props: ConnectedStatusIdeaCardProps) {
    super(props)
    this.state = { expanded: false }
    this.onCompose = this.onCompose.bind(this)
    this.statusesAllowed = this.props.isTextContentAllowed(true)
  }

  componentDidMount() {
    const element = findDOMNode(this) as HTMLElement
    this.btnCompose = element && element.querySelector('[data-btn-compose]')
    if (!this.btnCompose) {
      return
    }

    this.createClipboard()
  }

  componentDidUpdate() {
    if (this.btnCompose) {
      this.createClipboard()
    } else if (this.clipboard) {
      this.clipboard.destroy()
      this.clipboard = undefined
    }
  }

  componentWillUnmount() {
    if (this.clipboard) {
      this.clipboard.destroy()
    }
  }

  expand = () => {
    this.setState({ expanded: true })
  }

  collapse = () => {
    this.setState({ expanded: false })
  }

  createClipboard() {
    if (this.clipboard) {
      this.clipboard.destroy()
    }

    this.clipboard = new Clipboard(this.btnCompose, {
      text: this.getStatus
    }) as Clipboard

    this.clipboard.on('success', (e: any) => {
      e.clearSelection()
    })
    this.clipboard.on('error', console.error)
  }

  getStatus = () => {
    if (this.props.content.author) {
      return `${this.props.content.status}\n~${this.props.content.author}`
    }
    return this.props.content.status
  }

  addFavorite = () => this.props.addFavorite(this.props.content)

  removeFavorite = () => this.props.removeFavorite(this.props.content)

  onCompose() {
    if (this.props.onCompose) {
      const shouldAbort = this.props.onCompose(this.props.content)
      if (shouldAbort) {
        return
      }
    }
    if (!this.statusesAllowed) {
      return
    }
    this.props.showNotification(this.props.intl.formatMessage({ id: 'notifications.status-copied' }))
    this.props.navigate('/composer', { state: { floating: true, opener: this.props.location.pathname, background: this.props.location } })
  }

  sendToAIWriter = () => {
    const state = {
      floating: true,
      modal: 'compose',
      opener: this.props.location.pathname + this.props.location.search,
      text: `${this.props.content.status}${this.props.content.author ? `\n~${this.props.content.author}` : ''}`,
      background: this.props.location
    }
    this.props.navigate('/composer/ai', { state })
  }

  render() {
    const className = `${styles.card} ${styles.square} ${this.props.className || ''} ${this.props.small ? styles.small : ''}`
    const currentFavState = this.props.favState
    const isFavorite = currentFavState.added || (Boolean(this.props.content.isFavorite) && !currentFavState.removed)
    let favCount = this.props.content.favCount
    if (currentFavState.added) {
      favCount += 1
    } else if (currentFavState.removed && favCount > 0) {
      favCount -= 1
    }

    const icon = this.props.actionButtonIcon || createElement(FeatherIcon, { key: 'icon-feather', className: styles['icon-compose'] })

    return createElement(Paper, { className /* onMouseOver: this.expand, onMouseOut: this.collapse */ },
      createElement('div', { className: styles['content-wrapper'], 'data-testid': 'status-idea-card' }, [
        this.renderContent(),
        createElement('div', {
          key: 'actions',
          className: styles.actions,
          'data-testid': 'status-idea-card-actions'
        }, [
          createElement(FavButton, {
            key: 'favorite',
            isFavorite,
            count: favCount,
            className: `${styles.btn} ${isFavorite ? styles.visible : ''}`,
            hover: this.state.expanded,
            onFavorite: this.addFavorite,
            onUnfavorite: this.removeFavorite
          }),
          createElement(Tooltip, {
            key: 'fav-t',
            placement: 'left',
            title: this.props.actionLabel || createElement(FormattedMessage, { id: 'content-card.actions.create-post' }),
            children: createElement(IconButton, {
              key: 'create',
              color: 'primary',
              size: 'small',
              'data-btn-compose': true,
              className: styles.btn,
              onClick: this.onCompose
            } as any, icon)
          }),
          createElement(Tooltip, {
            key: 'ai',
            placement: 'left',
            title: createElement(FormattedMessage, { id: 'content-card.actions.ai' }),
            children: createElement(IconButton, {
              key: 'btn-ai',
              size: 'small',
              className: `${styles.btn} ${styles['btn-ai']}`,
              onClick: this.sendToAIWriter
            } as any, createElement(Icon, { key: 'icon-ai', path: mdiAutoFix, size: '20px' }))
          })
        ]),
        !this.statusesAllowed ? createElement(ContentCardUpgradeOverlay, {
          key: 'upgrade',
          contentType: STATUS_TYPE,
          feature: FEATURE_FIND_STATUS_IDEAS,
          favButton: null,
          className: styles['upgrade-overlay'],
          onUpgrade: this.props.onUpgrade
        }) : null
      ])
    )
  }

  renderContent() {
    const { content } = this.props
    const highlightedText = createElement('span', { key: content.id, dangerouslySetInnerHTML: { __html: content.highlighted } })
    const status = content.highlighted ? highlightedText : content.status

    const fontSizes = fontSizesFor(content.status)
    const style = fontSizes.normal
    const wrapperClassName = `${styles['content-box']} ${content.author ? styles['align-left'] : ''}`
    const contentClassName = `${styles.content} ${content.status.length <= MAX_STATUS_LENGTH_CENTERED ? styles['centered-y'] : ''}`

    return [
      createElement('div', { key: 'content-box', className: wrapperClassName },
        createElement('div', { key: 'content', className: contentClassName, style }, [
          status,
          content.author ? createElement('div', { className: styles.author, key: 'author' }, `~${content.author}`) : null
        ])
      )
    ]
  }
}

function mapStateToProps(state: StoreState, ownProps: StatusIdeaCardProps) {
  const { id, type } = ownProps.content

  return {
    favState: favStateSelectorForItem(state, state, id, type)
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    showNotification: (text: string) => { dispatch(message(text)) },
    addFavorite: (item: StatusIdea) => Observable.fromPromise(dispatch(addFavoriteStatusIdea(item)).unwrap())
      .pipe(catchError(() => Observable.of({ error: true }))),
    removeFavorite: (item: StatusIdea) => Observable.fromPromise(dispatch(removeFavoriteStatusIdea(item)).unwrap())
      .pipe(catchError(() => Observable.of({ error: true }))),
    isTextContentAllowed: (noPopup?: boolean) => dispatch(checkFeatureAvailability(FEATURE_FIND_STATUS_IDEAS, noPopup)),
    onUpgrade: (feature: string) => {
      const product = dispatch(getProductUpgradeByFeature(feature))
      if (product) {
        dispatch(promptUpgrade({ feature, product: product.handles.annual }))
      }
    }
  }
}

const ConnectedStatusIdeaCard = connect(mapStateToProps, mapDispatchToProps)(StatusIdeaCard)

function StatusIdeaCardWithRouter(props: any) {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    createElement(ConnectedStatusIdeaCard, { ...props, location, navigate })
  )
}

export default injectIntl(StatusIdeaCardWithRouter)
