import { StoreThunkDispatch, getState } from 'store/state'
import { LEGACY_URL } from 'config'
import { Observable } from 'rxjs/Observable'
import { accessTokenSelector } from 'services/users/selectors'

export const ENDPOINT_LEGACY_LOGOUT = `${LEGACY_URL}/login/logout_v3`

export const V1 = Object.freeze({
  logout: function () {

    return function (token: string) {
      return () => Observable.ajax({
        url: `${ENDPOINT_LEGACY_LOGOUT}?access_token=${encodeURIComponent(token)}`,
        method: 'POST',
        headers: {
          Accept: 'application/vnd.postplanner.v2+json'
        },
        crossDomain: true,
        withCredentials: true
      })
    }
  }()
})

export function legacyLogout() {
  return (dispatch: StoreThunkDispatch, getState: getState) => {
    const token = accessTokenSelector(getState())
    return dispatch(V1.logout(token || ''))
  }
}
