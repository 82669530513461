import { ContentType, PHOTO_TYPE, VIDEO_TYPE, GIF_TYPE } from 'interfaces'

export function getFileThumbUrl(url: string, type: ContentType) {
  if (type === PHOTO_TYPE) {
    return `${url.substring(0, url.lastIndexOf('.')).replace('/o/', '/300xauto/')}.jpg`
  }

  if (type === VIDEO_TYPE || type === GIF_TYPE) {
    return `${url.substring(0, url.lastIndexOf('.')).replace('/o/', '/p/')}.jpg`
  }

  return url
}
