import { RequestBuilder, authorizedPipe } from 'services/net'
import { CONTENT_SERVICE_URL } from 'config'
import { RangeFilter } from 'interfaces'
import { SortBy } from '../util'

export const ENDPOINT_TEXTS_EXTRACT = `${CONTENT_SERVICE_URL}/feeds/:sourceId/content/texts`
export const ENDPOINT_TEXTS_STREAM_EXTRACT = `${CONTENT_SERVICE_URL}/streams/:folderId/content/texts`

export const V1 = Object.freeze({

  getTexts: function () {
    return function (
      sourceId: string,
      page: number,
      rangeFilter: RangeFilter,
      sortBy: SortBy
    ) {
      const builder = new RequestBuilder(ENDPOINT_TEXTS_EXTRACT).asGet().expectJSON().withoutCredentials()

      return authorizedPipe<{ content: any[] }>(
        builder
          .partial(':sourceId', sourceId)
          .param('page', page.toString())
          .param('range_filter', rangeFilter)
          .param('sort_by', sortBy)
          .build()
      )
    }
  }(),
  getTextsFromStream: function () {
    const builder = new RequestBuilder(ENDPOINT_TEXTS_STREAM_EXTRACT).asGet().expectJSON().withoutCredentials()

    return function (folderId: string, page: number, rangeFilter: RangeFilter, sortBy: SortBy) {
      return authorizedPipe<{ content: any[] }>(
        builder
          .partial(':folderId', folderId)
          .param('page', page.toString())
          .param('range_filter', rangeFilter)
          .param('sort_by', sortBy)
          .build()
      )
    }
  }()
})
