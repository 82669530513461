import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { currentUserSelector } from 'services/users/selectors'

declare const __DEV__: boolean

function injectHubspotScript(): HTMLScriptElement {
  return (function (d, s, id) {
    const element = document.getElementById(id) as HTMLScriptElement
    if (element) {
      return element
    }
    const fjs = d.getElementsByTagName(s)[0]
    const target = (fjs && fjs.parentNode) || document.head
    const js = d.createElement(s) as HTMLScriptElement
    js.id = id
    js.type = 'text/javascript'
    js.async = true
    js.defer = true
    js.src = '//js.hs-scripts.com/513577.js'
    target.insertBefore(js, fjs)
    return js
  })(document, 'script', 'hs-script-loader')
}

export default function HubspotProvider() {
  const location = useLocation()
  const user = useSelector(currentUserSelector)

  const onConversationsAPIReady = () => {
    console.log(`HubSpot Conversations API: ${(window as any).HubSpotConversations}`)
  }

  React.useEffect(() => {
    // (window as any).hsConversationsSettings = {}
    /*
     If external API methods are already available, use them.
    */
    if ((window as any).HubSpotConversations) {
      onConversationsAPIReady()
    } else {
      /*
        Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
        These callbacks will be called once the external API has been initialized.
      */
      (window as any).hsConversationsOnReady = [onConversationsAPIReady]
    }

    const script = injectHubspotScript()
    return () => {
      script.remove()
    }
  }, [])

  // EXPL: Identify user in HS
  React.useEffect(() => {
    const _hsq = (window as any)._hsq || []
    _hsq.push(['identify', {
      email: user?.email,
      firstname: user?.name,
      lastname: user?.lastName
    }])
    _hsq.push(['trackPageView'])
  }, [user?.email, user?.lastName, user?.name])

  // Track page views
  React.useEffect(() => {
    const _hsq = (window as any)._hsq || []
    _hsq.push(['setPath', location.pathname])
    _hsq.push(['trackPageView'])
  }, [location.pathname])

  return null
}
