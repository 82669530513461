import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {
  composerFacebookPostStatusSelector,
  composerIsUploadingSelector,
  composerLinkedinPostStatusSelector,
  composerPinterestPostStatusSelector,
  composerSelectedProfilesIdsSelector,
  composerTiktokPostStatusSelector,
  composerTwitterPostStatusSelector,
  composerInstagramPostStatusSelector,
  activePostIdSelector,
  composerIsEmptySelector,
  composerTabsSelector,
  composerScheduleSelector,
  composerRecycleSelector,
  composerSelectedBucketIdSelector,
  activeDraftSelector,
  multiPostsCountSelector,
  composerBulkFileSelector,
  composerContentItemSelector,
  composerGooglePostStatusSelector,
  composerResetKeySelector,
  composerPostEditSelector,
  composerYoutubePostStatusSelector
} from 'services/compose/selectors'
import Accordion from '../Accordion'
import styles from './Actions.pcss'
import { mdiCheckCircleOutline, mdiCancel, mdiAlertCircleOutline } from '@mdi/js'
import { Icon } from '@mdi/react'
import { IndexedObject } from 'interfaces'
import { RecycleSelector } from 'components/Posts/components/RecycleSelector'
import Button from '@mui/material/Button'
import ComposerActionsButton from './ComposerActionsButton'
import { Subject } from 'rxjs/Subject'
import { checkProductLimit, promptUpgrade, userProductUpgradeSelector } from 'services/product'
import { LIMIT_SCHEDULED_POSTS_COUNT } from 'shared/constants'
import { message } from 'services/snackbar'
import { setSchedule, resetComposer, setRecycle, updatePost } from 'services/compose'
import { createPost, getPostData } from 'services/compose/create/createPost'
import { catchError } from 'rxjs/operators/catchError'
import { tap } from 'rxjs/operators/tap'
import { StoreThunkDispatch } from 'store/state'
import { Observable } from 'rxjs/Observable'
import toCamelCase from 'utils/toCamelCase'
import { deleteDrafts } from 'services/post/drafts/actions'
import { useNavigate, useLocation } from 'react-router-dom'
import { ComposerUploaderContext } from '../UploadContext'
import BulkActions from './BulkActions'
import ComposerScheduleForm from '../ScheduleOptions/ComposerScheduleForm'
import SaveDraftButton from '../Header/SaveDraftButton'
import AddToBucketButton from './AddToBucketButton'

const SCHEDULE_ADD_MINUTES_INITIAL = 60

interface ActionsProps {
  onPostCreateStarted: (redirectUrl?: string) => void
  activeView: 'default' | 'bulk'
}

export function Actions(props: ActionsProps) {
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const { files } = React.useContext(ComposerUploaderContext)
  const [isUpdating, setIsUpdating] = React.useState(false)
  const postEdit = useSelector(composerPostEditSelector)
  const productUpgrade = useSelector(userProductUpgradeSelector)
  const selectedProfilesIds = useSelector(composerSelectedProfilesIdsSelector)
  const selectedProfilesCount = selectedProfilesIds.length
  const multiPostsCount = useSelector(multiPostsCountSelector)
  const bulkUploadFile = useSelector(composerBulkFileSelector)
  const contentItem = useSelector(composerContentItemSelector)
  const igStatus = useSelector(composerInstagramPostStatusSelector)
  const piStatus = useSelector(composerPinterestPostStatusSelector)
  const liStatus = useSelector(composerLinkedinPostStatusSelector)
  const fbStatus = useSelector(composerFacebookPostStatusSelector)
  const twStatus = useSelector(composerTwitterPostStatusSelector)
  const tkStatus = useSelector(composerTiktokPostStatusSelector)
  const ytStatus = useSelector(composerYoutubePostStatusSelector)
  const googleStatus = useSelector(composerGooglePostStatusSelector)
  const isUploading = useSelector(composerIsUploadingSelector)
  const hasProfiles = selectedProfilesCount > 0
  const isInEditMode = Boolean(useSelector(activePostIdSelector))
  const isEmpty = useSelector(composerIsEmptySelector)
  const networks = useSelector(composerTabsSelector)
  const schedule = useSelector(composerScheduleSelector)
  const recycle = useSelector(composerRecycleSelector)
  const selectedBucketId = useSelector(composerSelectedBucketIdSelector)
  const draftId = useSelector(activeDraftSelector)?.id
  const resetKey = useSelector(composerResetKeySelector)
  const [isOpen, setIsOpen] = React.useState(!isEmpty)

  React.useEffect(() => {
    setIsOpen(isInEditMode || !isEmpty || props.activeView === 'bulk')
  }, [isEmpty, props.activeView, isInEditMode])

  const onSectionExpandedToggle = (nextValue: boolean) => {
    setIsOpen(nextValue)
  }

  const createPost$ = React.useRef<Subject<{ shareType?: 'now' | 'next' | 'queue', scheduled?: boolean, bucketId?: string }>>()
  const update$ = React.useRef<Subject<void>>()

  let postsCount = selectedProfilesCount
  if (props.activeView === 'bulk') {
    postsCount = bulkUploadFile?.rows || multiPostsCount
  }

  React.useEffect(() => {
    update$.current = new Subject()
    update$.current
      .flatMap(() => {
        setIsUpdating(true)
        return dispatch(updatePost(files))
      })
      .pipe(
        catchError((error) => {
          return Observable.of({ error })
        })
      )
      .subscribe((response: any) => {
        if (response.error) {
          const serverMessage = response.error.response.error.message
          dispatch(message(`${serverMessage}. ${intl.formatMessage({ id: 'notifications.post-update-failed' })}`, 'error'))
        } else {
          dispatch(message(intl.formatMessage({ id: 'notifications.post-updated' }), 'success'))
          const locationState = location.state as any || {}
          const opener = locationState.opener
          const backUrl = opener && opener !== '/composer' ? opener : '/posts'
          navigate(backUrl, {
            state: {
              refresh: Date.now().toString(),
              search: locationState.search,
              ppid: postEdit?.ppid,
              updatedPostId: postEdit?.id
            }
          })
          dispatch(resetComposer())
        }
        setIsUpdating(false)
      })
  }, [dispatch, navigate, intl, location.state, files, postEdit?.ppid, postEdit?.id])

  React.useEffect(() => {
    if (schedule.isActive) {
      const timeout = 400
      setTimeout(() => {
        const mainSection = document.querySelector('[data-test="composer"]')
        if (mainSection) {
          const top = mainSection.scrollHeight
          mainSection.scrollTo({
            top,
            left: 0,
            behavior: 'smooth'
          })
        }
      }, timeout)
    }
  }, [schedule.isActive])

  const create = React.useCallback((
    shareType?: 'now' | 'next' | 'queue',
    scheduled?: boolean,
    draftId?: string,
    bucketId?: string,
    onBeforeCreate?: (bucketId?: string) => void
  ) => {
    const postData = dispatch(getPostData(shareType, scheduled, files))
    if (onBeforeCreate) {
      onBeforeCreate(bucketId)
    }
    return dispatch(createPost(postData, bucketId, Boolean(contentItem)))
      .map((response: any) => ({ ...response, shareType, postData }))
      .pipe(
        catchError(error => {
          if (!error.response.error) {
            // EXPL: There's no error data, show generic error message
            dispatch(message(intl.formatMessage({ id: 'errors.create-post' }), 'error'))
            return Observable.of({ error: true, handled: true })
          }
          const errorData = toCamelCase(error.response.error)
          return Observable.of({ error: errorData, postData } as any)
        }),
        tap(() => {
          if (draftId) {
            dispatch(deleteDrafts([draftId]))
              .toPromise()
              .then(() => dispatch(resetComposer()))
          }
        })
      )
  }, [dispatch, intl, files, contentItem])

  const onBeforeCreate = (bucketId?: string) => {
    dispatch(message(intl.formatMessage({ id: 'composer.notifications.create-post-started' }), 'info'))
    const redirectUrl = bucketId ? `/posts/buckets/${bucketId}/posts` : undefined
    props.onPostCreateStarted(redirectUrl)
  }

  const saveToBucket = React.useCallback((shareType: 'queue' | 'next') => {
    if (selectedBucketId) {
      if (isInEditMode) {
        update$.current?.next()
      } else {
        createPost$.current?.next({ bucketId: selectedBucketId, shareType })
      }
    }
  }, [isInEditMode, selectedBucketId])

  React.useEffect(() => {
    createPost$.current = new Subject()
    createPost$.current
      .flatMap((params) => create(params.shareType, params.scheduled, draftId, params.bucketId, onBeforeCreate))
      .subscribe((response: any) => {
        if (response.handled) {
          return
        }

        if (response.error && !Array.isArray(response.error)) {
          const { message: msg, messageKey, data } = response.error
          if (data && response.postData) {
            const newPostsCount = response.postData.ppPageIds.length
            const nextPlannedPostsCount = data.plannedPostsCount + newPostsCount

            if (nextPlannedPostsCount > data.plannedPostsLimit) {
              const withinLimit = dispatch(checkProductLimit(LIMIT_SCHEDULED_POSTS_COUNT, nextPlannedPostsCount))
              if (!withinLimit) {
                dispatch(message(intl.formatMessage({ id: 'composer.notifications.planned-limit-reached' }), 'error'))
                if (productUpgrade) {
                  dispatch(promptUpgrade({ feature: LIMIT_SCHEDULED_POSTS_COUNT, product: productUpgrade.handles.annual }))
                }
              }
            } else {
              dispatch(message(intl.formatMessage({ id: messageKey, defaultMessage: msg }), 'error'))
            }
          } else {
            dispatch(message(msg, 'error'))
          }

          return
        }

        switch (response.shareType) {
          case 'queue':
            dispatch(message(intl.formatMessage({ id: 'notifications.post-added-to-plan' }), 'success'))
            break
          case 'next':
            dispatch(message(intl.formatMessage({ id: 'notifications.post-share-next' }), 'success'))
            break
          case 'now':
            dispatch(message(intl.formatMessage({ id: 'notifications.post-posted' }), 'success'))
            break
          default:
            dispatch(message(intl.formatMessage({ id: 'notifications.post-scheduled' }), 'success'))
        }
      })
  }, [dispatch, draftId, create])

  const isReady = !isUploading
    && !igStatus.hasErrors && !igStatus.isEmpty
    && !liStatus.hasErrors && !liStatus.isEmpty
    && !piStatus.hasErrors && !piStatus.isEmpty
    && !twStatus.hasErrors && !twStatus.isEmpty
    && !tkStatus.hasErrors && !tkStatus.isEmpty
    && !fbStatus.hasErrors && !fbStatus.isEmpty
    && !ytStatus.hasErrors && !ytStatus.isEmpty
    && !googleStatus.hasErrors && !googleStatus.isEmpty
    && hasProfiles

  const isOptimized = !igStatus.hasHints
    && !liStatus.hasHints
    && !piStatus.hasHints
    && !twStatus.hasHints
    && !fbStatus.hasHints
    && !tkStatus.hasHints
    && !googleStatus.hasHints
    && !ytStatus.hasHints

  React.useEffect(() => {
    if (props.activeView !== 'default') {
      return
    }

    const onKeyUp = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.shiftKey && e.key === 'Enter') {
        if (!isReady) {
          dispatch(message('Your post is not ready! Fix the errors and try again.', 'warning'))
          return
        }

        if (schedule.isActive) {
          schedulePost()
          return
        }
        if (selectedBucketId) {
          saveToBucket('queue')
          return
        }
        if (isInEditMode) {
          saveEdit()
        } else {
          shareNow()
        }
      }
    }

    window.addEventListener('keyup', onKeyUp)
    return () => {
      window.removeEventListener('keyup', onKeyUp)
    }
  }, [dispatch, isInEditMode, isReady, props.activeView, saveToBucket, schedule.isActive, selectedBucketId])

  const emptyPostsMessage = React.useMemo(() => {
    if (isEmpty) {
      return null
    }
    const emptyPostNetworks: IndexedObject<boolean> = {
      Facebook: Boolean(fbStatus.isEmpty),
      Instagram: Boolean(igStatus.isEmpty),
      LinkedIn: Boolean(liStatus.isEmpty),
      Pinterest: Boolean(piStatus.isEmpty),
      TikTok: Boolean(tkStatus.isEmpty),
      Twitter: Boolean(twStatus.isEmpty),
      YouTube: Boolean(ytStatus.isEmpty)
    }

    const list = Object.keys(emptyPostNetworks).filter(key => emptyPostNetworks[key])
    if (list.length === 0 || list.length === networks.length) {
      return null
    }
    if (list.length === 1) {
      return `Your ${list} post is empty!`
    }

    if (list.length === 2) {
      return `Your ${list[0]} and ${list[1]} posts are empty!`
    }
    if (list.length > 2) {
      const last = list.splice(-1)
      return `Your ${list.join(', ')} and ${last} posts are empty`
    }
    return null
  }, [
    isEmpty,
    igStatus.isEmpty,
    liStatus.isEmpty,
    piStatus.isEmpty,
    twStatus.isEmpty,
    fbStatus.isEmpty,
    tkStatus.isEmpty,
    ytStatus.isEmpty,
    networks
  ])

  const statusBarClassName = React.useMemo(() => {
    let className = isReady ? styles.green : styles.red
    if (isReady && !isOptimized) {
      className = styles.yellow
    }
    if (emptyPostsMessage === null && isEmpty) {
      className = styles.transparent
    }
    return `${styles['status-bar']} ${className}`
  }, [emptyPostsMessage, isEmpty, isOptimized, isReady])

  const onRecycleChange = (value: boolean | Date | number) => { dispatch(setRecycle(value)) }

  const schedulePost = () => {
    createPost$.current?.next({ scheduled: true })
  }

  const addToPlan = () => {
    createPost$.current?.next({ shareType: 'queue' })
  }

  const shareNext = () => {
    createPost$.current?.next({ shareType: 'next' })
  }

  const shareNow = () => {
    createPost$.current?.next({ shareType: 'now' })
  }

  const saveEdit = () => {
    update$.current?.next()
  }

  const scheduleInitialTime = React.useMemo(() => {
    const now = new Date()
    now.setMinutes(now.getMinutes() + SCHEDULE_ADD_MINUTES_INITIAL)
    return now
  }, [])

  const toggleScheduleActive = () => {
    const current = schedule.isActive
    if (!current) {
      const dateTime = scheduleInitialTime.toISOString()
      dispatch(setSchedule({ time: dateTime, date: dateTime }))
    }
    dispatch(setSchedule({ isActive: !schedule.isActive }))
  }

  const onScheduleError = (e: Error) => {
    dispatch(message('An error occurred: ' + e.message, 'error'))
  }

  const onMultiPostCreated = (redirectUrl: string) => {
    navigate(redirectUrl, {
      state: {
        refresh: Date.now().toString(),
        search: location.state?.search
      }
    })
    dispatch(resetComposer())
  }

  if (props.activeView === 'bulk' && postsCount === 0) {
    return null
  }

  return (
    <Accordion
      key={resetKey}
      title={<FormattedMessage id={isInEditMode ? 'composer.titles.confirm-edit' : 'composer.titles.create'} />}
      order={isInEditMode ? '2' : '4'}
      open={isOpen}
      id={isInEditMode ? 'composer-actions-edit' : 'composer-actions'}
      onToggle={onSectionExpandedToggle}
    >
      {props.activeView === 'default' ? (
        <div data-testid="composer-actions">
          <div className={styles.top}>
            <div className={styles.left}>
              <div className={styles.state}>
                <div className={statusBarClassName}>
                  {isReady && isOptimized && (
                    <Icon path={mdiCheckCircleOutline} />
                  )}
                  {!isReady && (
                    <Icon path={mdiCancel} />
                  )}
                  {isReady && !isOptimized && (
                    <Icon path={mdiAlertCircleOutline} />
                  )}
                </div>
                <div className={`${styles['message-box']} ${isEmpty ? styles.hidden : ''}`}>
                  <p className={styles['msg-status']}>
                    {isReady && isOptimized && (
                      <FormattedMessage id={isInEditMode ? 'composer.labels.post-edit-ready' : 'composer.labels.post-ready'} />
                    )}
                    {isReady && !isOptimized && (
                      <FormattedMessage
                        id={isInEditMode ? 'composer.labels.post-edit-not-optimized' : 'composer.labels.post-not-optimized'}
                      />
                    )}
                    {!isReady && !emptyPostsMessage && (
                      <FormattedMessage id={isInEditMode ? 'composer.labels.post-edit-not-ready' : 'composer.labels.post-not-ready'} />
                    )}
                    {emptyPostsMessage}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.right}>
              {!schedule.isActive && (
                <RecycleSelector
                  value={recycle}
                  horizontal
                  withIcon
                  className={styles['btn-recycle']}
                  onChange={onRecycleChange}
                />
              )}
              {isInEditMode && !selectedBucketId && (
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!isReady || isUpdating}
                  className={styles['btn-edit']}
                  onClick={saveEdit}
                >
                  <FormattedMessage id="composer.actions.save-edit" />
                </Button>
              )}
              {!isInEditMode && <SaveDraftButton className={styles['btn-draft']} />}
              {!isInEditMode && !selectedBucketId && (
                <ComposerActionsButton
                  disabled={!isReady || schedule.isActive}
                  className={styles['btn-group']}
                  btnClassName={styles['btn-actions']}
                  postsCount={selectedProfilesCount}
                  onAddToPlan={addToPlan}
                  onShareNext={shareNext}
                  onShareNow={shareNow}
                  onSchedule={toggleScheduleActive}
                />
              )}
              {selectedBucketId && (
                <AddToBucketButton
                  disabled={!isReady}
                  postsCount={selectedProfilesCount}
                  btnClassName={styles['btn-bucket']}
                  onSubmit={saveToBucket}
                />
              )}
            </div>
          </div>
          <div className={`${styles['schedule-box']} ${schedule.isActive ? styles.active : ''}`}>
            <ComposerScheduleForm
              submitDisabled={!isReady}
              hideActions={isInEditMode}
              className={styles['schedule-content']}
              initialTime={scheduleInitialTime}
              onSubmit={schedulePost}
              onCancel={toggleScheduleActive}
              onError={onScheduleError}
            />
          </div>
        </div>
      ) : (
        <BulkActions onMultiPostCreated={onMultiPostCreated} onBulkUploadStarted={props.onPostCreateStarted} />
      )}
    </Accordion>
  )
}

export default Actions
