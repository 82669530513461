import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import ToBottomIcon from '@mui/icons-material/ArrowDownward'
import ToTopIcon from '@mui/icons-material/ArrowUpward'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import CopyIcon from '@mui/icons-material/FileCopyOutlined'
import MoveIcon from '@mui/icons-material/DragIndicator'
import { FormattedMessage, injectIntl } from 'react-intl'
import ConfirmDialog from 'components/ConfirmDialog'
import Tooltip from '@mui/material/Tooltip'
import styles from './PostActions.pcss'
import { WithIntl } from 'interfaces'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'

export interface PostedActionsProps {
  className?: string
  btnClassName?: string
  showMoveButtons: boolean | null
  dragHandleProps?: DraggableProvidedDragHandleProps | null
  hideDragHandle?: boolean
  classNameMid?: string
  classNameRight?: string
  onEdit: () => void
  onDelete?: () => void
  onCopy?: () => void
  onMoveToTop?: () => void
  onMoveToBottom?: () => void
}

export function PostActions(props: PostedActionsProps & WithIntl) {
  return (
    <div className={`${styles['actions-grid']} ${props.className || ''}`} data-testid="post-actions">
      <div className={styles.left}>
        <Tooltip title={<FormattedMessage id="post.actions.edit" />} placement="top">
          <IconButton key="edit" size="small" onClick={props.onEdit} className={`${styles['btn-action']} ${props.btnClassName || ''}`}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {props.onCopy && (
          <Tooltip title={<FormattedMessage id="post.actions.copy" />} placement="top">
            <IconButton
              key="copy"
              size="small"
              className={`${styles['btn-action']} ${props.btnClassName || ''}`}
              onClick={props.onCopy}
            >
              <CopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        {props.onDelete && (
          <ConfirmDialog
            message={props.intl.formatMessage({ id: 'post.delete-confirm', defaultMessage: 'Delete post? This action cannot be undone.' })}
            labelOK={props.intl.formatMessage({ id: 'actions.yes', defaultMessage: 'Yes' })}
            labelCancel={props.intl.formatMessage({ id: 'actions.no', defaultMessage: 'No' })}
          >
            {(confirm) => (
              <Tooltip title={<FormattedMessage id="post.actions.delete" />} placement="top">
                <IconButton
                  key="delete"
                  size="small"
                  data-btn-delete
                  className={`${styles['btn-action']} ${props.btnClassName || ''}`}
                  onClick={confirm(props.onDelete)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </ConfirmDialog>
        )}
      </div>
      <div
        className={`${styles.middle} ${!props.showMoveButtons && !props.dragHandleProps ? styles.empty : ''} ${props.classNameMid || ''}`}
      >
        {props.dragHandleProps && (
          <div className={`${styles.move} ${props.hideDragHandle ? styles.hidden : ''}`} {...props.dragHandleProps}>
            <IconButton aria-label="move" disableRipple size="large">
              <MoveIcon />
            </IconButton>
          </div>
        )}
      </div>
      {props.showMoveButtons && (
        <div className={`${styles.right} ${props.classNameRight || ''}`}>
          <Tooltip title={<FormattedMessage id="post.actions.move-to-top" />} placement="top">
            <IconButton
              key="move-top"
              size="small"
              className={`${styles['btn-action']} ${props.btnClassName || ''}`}
              onClick={props.onMoveToTop}
            >
              <ToTopIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={<FormattedMessage id="post.actions.move-to-bottom" />} placement="top">
            <IconButton
              key="move-bottom"
              size="small"
              className={`${styles['btn-action']} ${props.btnClassName || ''}`}
              onClick={props.onMoveToBottom}
            >
              <ToBottomIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export default injectIntl(PostActions)
