import * as React from 'react'
import CardBricks from 'components/CardBricks'
import { ContentItem, GIF_TYPE } from 'interfaces'
import { useDispatch } from 'react-redux'
import { updateState } from 'routes/search/state/actions'
import { SearchContext } from 'routes/search/state/context'
import { CONTENT_TYPE_FILTERS } from 'routes/search/state/interfaces'
import { StoreThunkDispatch } from 'store/state'
import styles from './SearchStockRoute.pcss'
import { PAGE_SIZE_DEFAULT } from 'services/content/util'
import ScrollListener from 'components/ScrollListener'
import AnyCard from 'components/Card'
import { renderLoadingCards } from 'routes/find/routes/home/components/utils'
import ContentTypeFilter from 'components/ContentTypeFilter'
import { STOCK_PHOTO_TYPE, STOCK_VIDEO_TYPE } from 'interfaces/Content/StockContentTypes'
import EmptyView from 'components/EmptyView'
import { checkFeatureAvailability } from 'services/product'
import { FEATURE_CONTENT_ON_DEMAND, STOCK_GIF_TYPE } from 'shared/constants'
import { NavLink, useLocation } from 'react-router-dom'
import { SEARCH_CATEGORY_CONTENT, SEARCH_FILTER_KEY_GIFS, SEARCH_FILTER_KEY_STOCK, SearchFilterKey } from 'routes/search/state/types'
import { FormattedMessage } from 'react-intl'
import { resetScroll } from 'utils/dom'

const SCROLL_THRESHOLD = 350
const STOCK_FILTERS = [STOCK_PHOTO_TYPE, STOCK_VIDEO_TYPE]

export function SearchStockRoute({ gifsRoute }: { gifsRoute?: boolean }) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const location = useLocation()
  const [{ query, activeFilter, results }, searchDispatch] = React.useContext(SearchContext)
  const { items, loading } = results[activeFilter as SearchFilterKey] || {}
  const [page, setPage] = React.useState(0)
  const [activeStockType, setActiveStockType] = React.useState(location.state?.stockFilter || STOCK_PHOTO_TYPE)
  const scrollElementRef = React.useRef<HTMLElement | undefined>(undefined)

  React.useLayoutEffect(() => {
    scrollElementRef.current = document.querySelector('[data-test="main"]') as HTMLElement
    resetScroll()
  }, [])

  React.useEffect(() => {
    searchDispatch(updateState({
      activeFilter: gifsRoute ? SEARCH_FILTER_KEY_GIFS : SEARCH_FILTER_KEY_STOCK,
      activeCategory: SEARCH_CATEGORY_CONTENT
    }))
  }, [gifsRoute, searchDispatch])

  const loadingCards = React.useMemo(() => {
    if (loading) {
      return renderLoadingCards()
    }
    return []
  }, [loading])

  const pageStartIndex = page * PAGE_SIZE_DEFAULT
  const feedName = query.trim()

  const itemsForType = items
    ? gifsRoute
      ? items.filter(item => item.type === GIF_TYPE || item.type === STOCK_GIF_TYPE)
      : items.filter(i => i.type === activeStockType)
    : []
  const visibleItems = itemsForType
    .slice(0, pageStartIndex + PAGE_SIZE_DEFAULT)
    .map((item: ContentItem) => ({
      ...item,
      feed: { ...item.feed, name: feedName }
    }))

  const emptyCards = React.useMemo(() => {
    if (!loading && visibleItems.length === 0) {
      // eslint-disable-next-line no-magic-numbers
      return renderLoadingCards(30, false, false, false)
    }
    return []
  }, [visibleItems.length, loading])

  const onCompose = React.useCallback(() => {
    return !dispatch(checkFeatureAvailability(FEATURE_CONTENT_ON_DEMAND))
  }, [dispatch])

  if (!activeFilter) {
    return null
  }

  const onLoadNextPage = () => {
    if (!loading && visibleItems.length < itemsForType.length) {
      setPage(current => current + 1)
    }
  }

  return (
    <div className={styles.box}>
      <header className={styles.header}>
        <h2 className="text-ellipsis">
          <FormattedMessage id={`search.navigation.${gifsRoute ? SEARCH_FILTER_KEY_GIFS : SEARCH_FILTER_KEY_STOCK}`} />
        </h2>
        <NavLink to={`/content/search?q=${query}`}>
          <FormattedMessage id="general.carousel.nav.back" />
        </NavLink>
        {!gifsRoute && (
          <div className={styles.filters}>
            <ContentTypeFilter
              options={STOCK_FILTERS}
              selected={activeStockType}
              onFilterSelected={setActiveStockType}
            />
          </div>
        )}
      </header>
      {!loading && query && visibleItems.length === 0 && (
        <EmptyView
          title={`No ${CONTENT_TYPE_FILTERS[activeStockType].toLowerCase()} available for that search`}
          subtitle={gifsRoute
            ? 'Please try a different keyword, topic, brand or industry.'
            : 'Please try a different keyword or topic.'}
          contained
          top="300px"
        />
      )}
      <ScrollListener emitTreshold={SCROLL_THRESHOLD} scrollElement={scrollElementRef.current} onScroll={onLoadNextPage}>
        <CardBricks key={query + activeStockType}>
          {visibleItems.map(item => (
            <AnyCard
              key={item.id}
              content={item}
              children={item}
              onCompose={onCompose}
            />
          )).concat(loadingCards, emptyCards)}
        </CardBricks>
      </ScrollListener>
    </div>
  )
}

export default SearchStockRoute
