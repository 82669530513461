import * as React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import ContentTypeFilter from 'components/ContentTypeFilter'

import styles from './FileFolderViewHeader.pcss'
import PPSelect from 'components/PPSelect'
import { mdiSortVariant, mdiSortAscending } from '@mdi/js'
import Icon from '@mdi/react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

export interface FileFolderViewHeaderProps {
  folderName: string
  folderId: string
  activeFilter: string
  filters: string[]
  title?: React.ReactNode
  navPrefix?: string
  backUrl?: string
  sorting?: {
    sortBy: 'name' | 'date'
    sortDirection: 'asc' | 'desc'
    onChange: (sortBy: 'name' | 'date', sortDirection: 'asc' | 'desc') => void
  }
  onFilterChange: (filter: string) => void
}

export function FileFolderViewHeader(props: FileFolderViewHeaderProps) {
  const { sortBy, sortDirection, onChange } = props.sorting || {}

  const onSortByChange = (value: string) => {
    if (onChange && sortDirection) {
      onChange(value as 'name' | 'date', sortDirection)
    }
  }

  const toggleSortDirection = () => {
    if (onChange && sortBy) {
      onChange(sortBy, sortDirection === 'asc' ? 'desc' : 'asc')
    }
  }

  const toggleSortTooltip = React.useMemo(() => {
    if (sortBy === 'name') {
      return sortDirection === 'asc' ? 'Z-A' : 'A-Z'
    }
    return sortDirection === 'asc' ? 'Oldest first' : 'Newest first'
  }, [sortBy, sortDirection])

  return (
    <header className={styles.header}>
      {
        props.title ? props.title : (
          <React.Fragment>
            <h2 className={styles.title}>{props.folderName}</h2>
            {
              props.navPrefix && (
                <Link className={styles.link} to={`${props.navPrefix}/${props.folderId}`}>
                  <FormattedMessage id="general.carousel.nav.see-all" />
                </Link>
              )
            }
            {
              props.backUrl && (
                <Link className={styles.link} to={props.backUrl}>
                  <FormattedMessage id="general.carousel.nav.back" />
                </Link>
              )
            }
          </React.Fragment>
        )
      }
      {props.filters.length > 1 && (
        <ContentTypeFilter
          renderSingle
          all={props.filters.length > 1}
          options={props.filters}
          selected={props.activeFilter}
          onFilterSelected={props.onFilterChange}
        />
      )}
      {props.sorting && (
        <div className={styles['sort-box']}>
          <PPSelect
            options={{ date: { label: 'Date added' }, name: { label: 'Name' } }}
            selectedValue={props.sorting.sortBy}
            name="Sort by"
            icon={<Icon path={mdiSortVariant} size="20px" />}
            className={styles.select}
            onSelectionChange={onSortByChange}
          />
          <Tooltip title={toggleSortTooltip}>
            <IconButton size="small" className={`${styles['btn-sort-dir']} solid`} onClick={toggleSortDirection}>
              <Icon
                path={mdiSortAscending}
                size="20px"
                className={`${styles['sort-icon']} ${props.sorting.sortDirection === 'asc' ? styles.flip : ''}`}
              />
            </IconButton>
          </Tooltip>
        </div>
      )}

    </header>
  )
}

export default FileFolderViewHeader
