import {
  BRAND_GOOGLE,
  BRAND_INSTAGRAM,
  BRAND_TIKTOK,
  BRAND_YOUTUBE,
  NETWORKS_NAMES,
  PostDestinationType
} from 'shared'
import {
  ComposerInstagramPost,
  ComposerPinterestPost,
  ComposerTwitterPost,
  ComposerYoutubePost,
  LocationInfo,
  TweetType
} from '../interfaces'
import { ContentItem, PinterestBoard } from 'interfaces'
import { ArticleBase } from '../interfaces/ArticleBase'
import { ComposerFacebookPost, FBAlbum } from '../interfaces/ComposerFacebookPost'
import ComposerState, { BulkContentPost, COMPOSER_STATUS_KEY_GENERIC, PostNetworkData } from '../state'
import { PrivacyStatus, TikTokPostProfileSettings } from 'shared/types'
import { PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'
import { BRAND_FACEBOOK, BRAND_PINTEREST, BRAND_TWITTER, NETWORKS_ORDERED } from 'shared/constants'
import { generateBulkPostCaptionFromHtml } from 'utils/composer'
import { ComposerTikTokPost } from '../interfaces/ComposerTikTokPost'

export const multipostReducers = {
  addImagePosts: (state: WritableDraft<ComposerState>, action: PayloadAction<Array<{ url: string, content?: ContentItem }>>) => {
    let count = Object.keys(state.multiPost.posts).length
    NETWORKS_ORDERED.forEach((network: PostDestinationType) => {
      action.payload.forEach((image) => {
        count += 1
        setInitialPostState(state.multiPost.posts, count, network, state.posts.instagram.locations, image)
      })
    })

    state.imageUrls = []
    state.contentItem = undefined
    state.featuredImageUrl = undefined
  },
  addImageUploadPosts: (state: WritableDraft<ComposerState>, action: PayloadAction<Record<string, { file: File }>>) => {
    let count = Object.keys(state.multiPost.posts).length
    NETWORKS_ORDERED.forEach((network: PostDestinationType) => {
      Object.keys(action.payload).forEach((fileId: string) => {
        const file = action.payload[fileId].file
        count += 1
        setInitialPostState(state.multiPost.posts, count, network, state.posts.instagram.locations, undefined, undefined, file)
      })
    })
    state.imageUrls = []
    state.contentItem = undefined
    state.featuredImageUrl = undefined
  },
  addArticlePosts: (state: WritableDraft<ComposerState>, action: PayloadAction<Array<{ article: ArticleBase, content?: ContentItem }>>) => {
    let count = Object.keys(state.multiPost.posts).length
    NETWORKS_ORDERED.forEach((network: PostDestinationType) => {
      action.payload.forEach((data) => {
        count += 1
        setInitialPostState(state.multiPost.posts, count, network, state.posts.instagram.locations, undefined, data)
      })
    })

    state.imageUrls = []
    state.contentItem = undefined
    state.featuredImageUrl = undefined
  },
  addVideoPosts: (state: WritableDraft<ComposerState>, action: PayloadAction<Array<ContentItem>>) => {
    let count = Object.keys(state.multiPost.posts).length
    NETWORKS_ORDERED.forEach((network: PostDestinationType) => {
      action.payload.forEach((video) => {
        count += 1
        setInitialPostState(state.multiPost.posts, count, network, state.posts.instagram.locations, undefined, undefined, undefined, video)
      })
    })

    state.imageUrls = []
    state.contentItem = undefined
    state.featuredImageUrl = undefined
  },
  addMultipostVideoUrl: (state: WritableDraft<ComposerState>, action: PayloadAction<string[]>) => {
    let count = Object.keys(state.multiPost.posts).length
    NETWORKS_ORDERED.forEach((network: PostDestinationType) => {
      action.payload.forEach((videoUrl) => {
        count += 1
        setInitialPostState(
          state.multiPost.posts, count, network, state.posts.instagram.locations, undefined, undefined, undefined, undefined, videoUrl
        )
      })
    })

    state.imageUrls = []
    state.contentItem = undefined
    state.featuredImageUrl = undefined
  },
  imagesUploadDone: (
    state: WritableDraft<ComposerState>,
    action: PayloadAction<Record<string, { file: File, url?: string, error?: string }>>
  ) => {
    const photos = action.payload
    Object.values(state.multiPost.posts).forEach((post) => {
      if (post.imageUpload) {
        const upload = photos[post.imageUpload.file.name]
        post.imageUpload = upload
        post.imageUrl = upload.url
        post.error = upload.error ? { message: upload.error, code: '' } : post.error
      }
    })
  },
  imageUploadUpdate: (state: WritableDraft<ComposerState>, action: PayloadAction<{ fileName: string, error?: string, src?: string }>) => {
    const { fileName, error, src } = action.payload
    Object.values(state.multiPost.posts).forEach((post) => {
      if (post.imageUpload?.file.name === fileName) {
        post.imageUpload.error = error
        post.imageUpload.src = src
        post.imageUrl = undefined
        post.error = error ? { message: error, code: '' } : post.error
      }
    })
  },
  removeMultiPosts: (state: WritableDraft<ComposerState>, action: PayloadAction<{ ids: string[], all?: boolean }>) => {
    if (action.payload.all) {
      state.multiPost.posts = {}
    } else {
      action.payload.ids.forEach((id) => {
        delete state.multiPost.posts[id]
      })
    }
  },
  reorderMultiPosts: (state: WritableDraft<ComposerState>, action: PayloadAction<string[]>) => {
    action.payload.forEach((id, index) => {
      state.multiPost.posts[id].order = index + 1
    })
  },
  editMultiPosts: (
    state: WritableDraft<ComposerState>,
    action: PayloadAction<{
      id: string,
      network: PostDestinationType,
      data: Partial<PostNetworkData> & { applyToAll?: boolean },
      imageUrl?: string,
      recycle?: boolean | Date | number
    }>
  ) => {
    const withStatusEdit = action.payload.data?.status?.text
    const { applyToAll, ...networkData } = action.payload.data
    if (withStatusEdit) {
      state.useGenericStatus = false
    }
    const post = state.multiPost.posts[action.payload.id]
    if (action.payload.imageUrl) {
      post.imageUrl = action.payload.imageUrl
    }
    if (action.payload.recycle !== undefined) {
      post.recycle = action.payload.recycle
    }
    if (networkData) {
      post.data = { ...post.data, ...networkData }
    }

    Object.values(state.multiPost.posts).forEach((p) => {
      if (p.id !== action.payload.id && applyToAll && networkData) {
        if (withStatusEdit) {
          p.data.status = { html: '', text: withStatusEdit, touched: false }
        } else {
          p.data = { ...p.data, ...networkData }
        }
      }
    })
  },
  handleMultiPostError: (state: WritableDraft<ComposerState>, action: PayloadAction<Array<{ frontendId: string, error: string }>>) => {
    const errorsMap = action.payload.reduce((map: Record<string, string>, e) => {
      map[e.frontendId] = e.error
      return map
    }, {})

    Object.keys(state.multiPost.posts).forEach((id) => {
      if (errorsMap[id]) {
        state.multiPost.posts[id].error = { message: errorsMap[id], code: '' }
      } else {
        delete state.multiPost.posts[id]
      }
    })
  },
  setMultiPostError: (
    state: WritableDraft<ComposerState>,
    action: PayloadAction<{ postId: string, error: { code: string, message: string } }>
  ) => {
    state.multiPost.posts[action.payload.postId].error = action.payload.error
  },
  setMultiPostsRecycle: (
    state: WritableDraft<ComposerState>,
    action: PayloadAction<{ postIds: string[], value: Date | boolean | number, applyToAll?: boolean }>
  ) => {
    const { postIds, value, applyToAll } = action.payload
    if (applyToAll) {
      Object.keys(state.multiPost.posts).forEach((id) => {
        state.multiPost.posts[id].recycle = value
      })
    } else {
      postIds.forEach((id) => {
        state.multiPost.posts[id].recycle = value
      })
    }
  },
  applyGenericStatusToPosts: (state: WritableDraft<ComposerState>, action: PayloadAction<string[]>) => {
    const genericStatusHtml = state.posts[COMPOSER_STATUS_KEY_GENERIC].status.html
    action.payload.forEach((id) => {
      const { html, text } = generateBulkPostCaptionFromHtml(genericStatusHtml, state.multiPost.posts[id])
      state.multiPost.posts[id].data.status = {
        html,
        text,
        touched: false
      }

      if (state.multiPost.posts[id].network === BRAND_PINTEREST || state.multiPost.posts[id].network === BRAND_YOUTUBE) {
        (state.multiPost.posts[id].data as ComposerPinterestPost).description = text
      }
    })
  },
  resetMultiPostState: (state: WritableDraft<ComposerState>) => {
    state.multiPost = { posts: {} }
  },
  setMultiPostPiBoards: (
    state: WritableDraft<ComposerState>,
    action: PayloadAction<{ postId: string, selection: Record<string, PinterestBoard>, applyToAll?: boolean }>
  ) => {
    const { postId, selection, applyToAll } = action.payload
    const boardsArray = Object.keys(selection).map(ppid => ({ ...selection[ppid], ppid }))
    if (applyToAll) {
      Object.values(state.multiPost.posts).forEach(post => {
        if (post.network === BRAND_PINTEREST) {
          (post.data as ComposerPinterestPost).boards = boardsArray
        }
      })
    } else {
      (state.multiPost.posts[postId].data as ComposerPinterestPost).boards = boardsArray
    }
  },
  setMultiPostIGLocations: (
    state: WritableDraft<ComposerState>,
    action: PayloadAction<{ postId: string, locations: Record<string, LocationInfo | undefined>, applyToAll: boolean }>
  ) => {
    const { postId, locations, applyToAll } = action.payload
    if (applyToAll) {
      Object.values(state.multiPost.posts).forEach(post => {
        if (post.network === BRAND_INSTAGRAM) {
          (post.data as ComposerInstagramPost).locations = locations
        }
      })
    } else {
      (state.multiPost.posts[postId].data as ComposerInstagramPost).locations = locations
    }
  },
  setMultiPostFBAlbums: (
    state: WritableDraft<ComposerState>,
    action: PayloadAction<{ postId: string, albums: Record<string, FBAlbum | undefined>, applyToAll: boolean }>
  ) => {
    const { postId, albums, applyToAll } = action.payload
    const albumsArr = Object.values(albums).filter(Boolean) as FBAlbum[]
    if (applyToAll) {
      Object.values(state.multiPost.posts).forEach(post => {
        if (post.network === BRAND_FACEBOOK) {
          (post.data as ComposerFacebookPost).albums = albumsArr
        }
      })
    } else {
      (state.multiPost.posts[postId].data as ComposerFacebookPost).albums = albumsArr
    }
  },
  setMultiPostFBReshareAll: (state: WritableDraft<ComposerState>, action: PayloadAction<boolean>) => {
    Object.values(state.multiPost.posts).forEach(post => {
      if (post.network === BRAND_FACEBOOK && post.content?.feed.type === BRAND_FACEBOOK) {
        (post.data as ComposerFacebookPost).reshare = action.payload
      }
    })
  },
  setMultiPostTweetTypeAll: (state: WritableDraft<ComposerState>, action: PayloadAction<TweetType>) => {
    Object.values(state.multiPost.posts).forEach(post => {
      if (post.network === BRAND_TWITTER && post.content?.feed.type === BRAND_TWITTER) {
        (post.data as ComposerTwitterPost).tweetType = action.payload
      }
    })
  },
  setMultiPostNetworkSettings: (
    state: WritableDraft<ComposerState>,
    action: PayloadAction<{
      postIds: string[],
      fbAlbums: Record<string, FBAlbum | undefined>,
      piBoards: Record<string, PinterestBoard>,
      igLocations: Record<string, LocationInfo | undefined>,
      tkSettings: Record<string, TikTokPostProfileSettings>,
      ytPrivacy?: PrivacyStatus,
      fbPostType?: string,
      igPostType?: string
    }>
  ) => {
    const { postIds, fbAlbums, piBoards, igLocations, tkSettings, ytPrivacy, fbPostType, igPostType } = action.payload
    const albumsArr = Object.values(fbAlbums).filter(Boolean) as FBAlbum[]
    const boardsArray = Object.keys(piBoards).map(ppid => ({ ...piBoards[ppid], ppid }))

    postIds.forEach(id => {
      const post = state.multiPost.posts[id]
      switch (post.network) {
        case BRAND_FACEBOOK:
          (post.data as ComposerFacebookPost).albums = albumsArr
          if (fbPostType) {
            (post.data as ComposerFacebookPost).postType = fbPostType
          }
          break
        case BRAND_INSTAGRAM:
          (post.data as ComposerInstagramPost).locations = igLocations
          if (igPostType) {
            (post.data as ComposerInstagramPost).postType = igPostType
          }
          break
        case BRAND_PINTEREST:
          (post.data as ComposerPinterestPost).boards = boardsArray
          break
        case BRAND_TIKTOK:
          const tkData = post.data as ComposerTikTokPost
          tkData.settings = { ...tkData.settings, ...tkSettings }
          break
        case BRAND_YOUTUBE:
          if (ytPrivacy) {
            (post.data as ComposerYoutubePost).privacyStatus = ytPrivacy
          }
          break
      }
    })
  },
  setMultipostTikTokProfileSettings: (
    state: WritableDraft<ComposerState>,
    action: PayloadAction<{ postId: string, settings: TikTokPostProfileSettings }>
  ) => {
    const { postId, settings } = action.payload
    const post = state.multiPost.posts[postId]
    const tkData = post.data as ComposerTikTokPost
    tkData.settings = { ...tkData.settings, [settings.ppid]: settings }
    if (post.videoUrl) {
      if (!settings.privacyLevel) {
        post.error = { code: 'composer.errors.tiktok.privacy', message: 'Please choose a video privacy level.' }
      } else if (!settings.promoting) {
        post.error = { message: 'Please choose a video promotion setting.', code: 'composer.errors.tiktok.promotion' }
      } else {
        post.error = undefined
      }
    }
  },
  initializeTikTokSettings: (state: WritableDraft<ComposerState>, action: PayloadAction<Record<string, TikTokPostProfileSettings>>) => {
    const settings = action.payload
    Object.values(state.multiPost.posts).forEach(post => {
      if (post.network === BRAND_TIKTOK) {
        const tkData = post.data as ComposerTikTokPost
        tkData.settings = { ...tkData.settings, ...settings }
      }
    })
  },
  setMultipostFirstComment: (state: WritableDraft<ComposerState>, action: PayloadAction<{ postId: string, comment: string }>) => {
    const { postId, comment } = action.payload
    const post = state.multiPost.posts[postId]
    const fbData = post.data as ComposerFacebookPost
    fbData.firstComment = comment
  },
  updateArticlePost: (state: WritableDraft<ComposerState>, action: PayloadAction<{ id: string, article: Partial<ArticleBase> }>) => {
    const { id, article } = action.payload
    const post = state.multiPost.posts[id]
    post.article = { ...(post.article as ArticleBase), ...article }
  }
}

function setInitialPostState(
  draft: Record<string, BulkContentPost>,
  order: number,
  network: PostDestinationType,
  igLocations: Record<string, LocationInfo | undefined>,
  image?: { url: string, content?: ContentItem },
  article?: { article: ArticleBase, content?: ContentItem },
  file?: File,
  video?: ContentItem,
  videoUrl?: string
) {
  const id = Math.random().toString().split('.')[1]
  draft[id] = {
    id,
    network,
    imageUrl: image?.url,
    content: image?.content || article?.content || video,
    article: article?.article,
    imageUpload: file ? { file } : undefined,
    order,
    recycle: false,
    data: { status: { html: '', text: '', touched: false } },
    videoUrl
  }

  switch (network) {
    case BRAND_FACEBOOK:
      (draft[id].data as ComposerFacebookPost).reshare = false
      ; (draft[id].data as ComposerFacebookPost).albums = []
      break
    case BRAND_TWITTER:
      (draft[id].data as ComposerTwitterPost).tweetType = 'new'
      break
    case BRAND_PINTEREST:
      (draft[id].data as ComposerPinterestPost).boards = []
      if (video || videoUrl) {
        draft[id].error = {
          message: 'Pinterest does not support video posts.',
          code: 'composer.errors.pinterest.no-video'
        }
      }
      break
    case BRAND_YOUTUBE:
    case BRAND_TIKTOK: {
      if (!videoUrl) {
        draft[id].error = {
          message: `${NETWORKS_NAMES[network]} posts require a video file. Please upload a video.`,
          code: `composer.errors.${network}.no-video`
        }
      }
      break
    }
    case BRAND_INSTAGRAM:
      (draft[id].data as ComposerInstagramPost).locations = igLocations
      if (video) {
        draft[id].imageUrl = video.imageUrl
      }
      break
    case BRAND_GOOGLE:
      if (video || videoUrl) {
        draft[id].error = {
          message: 'Google My Business does not support video posts.',
          code: 'composer.errors.google.videos'
        }
      }
      break
  }
}
