import { Bucket, ContentType, PinterestBoard, PostDestination } from 'interfaces'
import { Observable } from 'rxjs/Observable'
import { tap } from 'rxjs/operators/tap'
import { connectedDestinationsSelector } from 'services/destinations'
import { HS_EVENT_BUCKET_CREATED, trackHubspotEvent } from 'services/tracking/hubspot'
import { bucketAdapter } from 'shared/adapters'
import StoreState, { getState, StoreThunkAction, StoreThunkDispatch } from 'store/state'
import { postAdapter } from '../adapters/posts'
import { bucketPresetsSelector, bucketsMapSelector, bucketsSelector } from '../selectors'
import { DeleteMethod, V1 } from './net'
import { FBAlbum } from 'services/compose/interfaces/ComposerFacebookPost'
import { EVENT_ADD_BUCKET } from 'routes/trial/interfaces'
import { trackTrainingEvent } from 'services/users/actions'
import { getRandomElements } from 'shared/utils'
import { bucketColorsPreset } from 'utils/colors'
import { createAsyncThunk } from '@reduxjs/toolkit'

export { DeleteMethod }

export const getBuckets = createAsyncThunk(
  'posts/buckets/get',
  async (force: boolean = true, { dispatch, getState, rejectWithValue }) => {
    if (!force) {
      const stored = bucketsSelector(getState() as StoreState)
      if (stored.length > 0) {
        return stored
      }
    }
    try {
      const response = await dispatch(V1.getBuckets()).toPromise().then(response => response.data.map(bucketAdapter))
      return response
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

type CreateBucketParams = {
  name: string,
  color: string,
  description: string,
  isPublic: boolean,
  ppids: string[],
  activeFrom?: string,
  activeTo?: string
}
export const createBucket = createAsyncThunk(
  'posts/buckets/create',
  async (params: CreateBucketParams, { dispatch, rejectWithValue }) => {
    const { name, color, description, isPublic, ppids, activeFrom, activeTo } = params
    try {
      const response = await dispatch(V1.createBucket(name, color, description, isPublic, ppids, activeFrom, activeTo)).toPromise()
      const bucket = bucketAdapter(response)
      dispatch(trackTrainingEvent({ eventCode: EVENT_ADD_BUCKET }))
      dispatch(trackHubspotEvent(HS_EVENT_BUCKET_CREATED))
      return bucket
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

type UpdateBucketParams = {
  id: string,
  name: string,
  color: string,
  description: string,
  isPublic: boolean,
  ppids: string[],
  copyFromPpid: string | undefined,
  activeFrom: string | undefined,
  activeTo: string | undefined,
  fbAlbums?: FBAlbum[],
  piBoards?: PinterestBoard[]
}
export const updateBucket = createAsyncThunk(
  'posts/buckets/update',
  async (params: UpdateBucketParams, { dispatch, getState, rejectWithValue }) => {
    const { id, name, color, description, isPublic, ppids, copyFromPpid, activeFrom, activeTo, fbAlbums, piBoards } = params
    try {
      const response = await dispatch(V1.updateBucket(
        id, name, color, description, isPublic, ppids, copyFromPpid, activeFrom, activeTo, fbAlbums, piBoards
      )).toPromise()
      const bucket = bucketAdapter(response)
      const profiles = connectedDestinationsSelector(getState() as StoreState)
      return {
        ...response,
        bucket,
        profiles: Object.values(profiles).filter(p => bucket.ppids.includes(p.ppid))
      } as { bucket: Bucket, profiles: PostDestination[] } & any
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const deleteBucket = createAsyncThunk(
  'posts/buckets/delete',
  async (params: { id: string, method: DeleteMethod, targetBucketId?: string | null }, { dispatch, rejectWithValue }) => {
    const { id, method, targetBucketId } = params
    try {
      await dispatch(V1.deleteBucket(id, method, targetBucketId)).toPromise()
      return id
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export function getBucketPosts(
  bucketId: string,
  page: number,
  ppid: string,
  query?: string,
  types?: ContentType[],
  searchInSummary?: boolean
) {
  return (dispatch: StoreThunkDispatch, getState: getState) => {
    const buckets = bucketsMapSelector(getState())
    const bucket = buckets[bucketId]
    return dispatch(V1.getBucketPosts(bucketId, page, ppid, query, types, searchInSummary)).map(response => ({
      posts: response.posts.map((p, index) => {
        const post = postAdapter(p, index)
        return { ...post, bucket }
      }),
      page,
      totalPostsCount: response.postsCount,
      paging: response.paging
    }))
  }
}

export const reorderBuckets = createAsyncThunk(
  'posts/buckets/reorder',
  async (ids: string[], { dispatch, rejectWithValue }) => {
    try {
      await dispatch(V1.reorderBuckets(ids)).toPromise()
      return ids
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getDefaultBuckets = createAsyncThunk(
  'posts/buckets/getDefault',
  async (force: boolean = true, { dispatch, getState, rejectWithValue }) => {
    if (!force) {
      const cached = bucketPresetsSelector(getState() as StoreState)
      if (cached.length > 0) {
        return cached
      }
    }
    try {
      const response = await dispatch(V1.getDefaultBuckets()).toPromise()
      return response.data.map((bucket: any) => ({
        ...bucket,
        name: bucket.name.trim(),
        isDefault: parseInt(bucket.isPreselected, 10) === 1,
        color: bucket.color || getRandomElements(bucketColorsPreset, 1)[0]
      }))
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
