import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { FeatherIcon } from 'components/Icons'
import OpenIcon from '@mui/icons-material/OpenInNew'
import ListIcon from '@mui/icons-material/FormatListBulleted'
import { FormattedMessage } from 'react-intl'
import { mdiPailPlus } from '@mdi/js'
import Icon from '@mdi/react'
import CopyIcon from '@mui/icons-material/FileCopyOutlined'
import ShareIcon from '@mui/icons-material/Send'

import styles from './PostedActions.pcss'

interface PostedActionsMenuProps {
  link: string
  isBucketPost: boolean
  className?: string
  onRequeue: () => void
  onRepost: () => void
  onCopy: () => void
  onShareNow?: () => void
}

export function PostedActionsMenu(props: PostedActionsMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget as any)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const requeue = () => {
    props.onRequeue()
    closeMenu()
  }

  const repost = () => {
    props.onRepost()
    closeMenu()
  }

  const copy = () => {
    props.onCopy()
    closeMenu()
  }

  const shareNow = () => {
    if (props.onShareNow) {
      props.onShareNow()
    }
    closeMenu()
  }

  const stopPropagation = (e: any) => {
    e.stopPropagation()
  }

  return (
    <div className={props.className || ''}>
      <IconButton
        aria-label="More"
        aria-controls="long-menu"
        aria-haspopup="true"
        size="small"
        onClick={openMenu}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        classes={{ paper: styles.menu }}
        onClose={closeMenu}
        onClick={stopPropagation}
      >
        {props.link && (
          <a href={props.link} className={styles['link-view']} rel="noopener" target="_blank">
            <MenuItem key="open" onClick={closeMenu}>
              <OpenIcon className={`${styles.icon} ${styles.open}`} />
              <span><FormattedMessage id="post.actions.view" /></span>
            </MenuItem>
          </a>
        )}
        <MenuItem key="re-post" onClick={repost}>
          <FeatherIcon className={`${styles.icon} ${styles['icon-repost']}`} />
          <span><FormattedMessage id="post.actions.re-post" /></span>
        </MenuItem>
        <MenuItem key="re-plan" onClick={requeue}>
          {props.isBucketPost ? (
            <Icon path={mdiPailPlus} className={styles.icon} size="24px" />
          ) : (
            <ListIcon className={styles.icon} />
          )}
          <span><FormattedMessage id={props.isBucketPost ? 'post.buckets.actions.requeue' : 'post.actions.re-plan'} /></span>
        </MenuItem>
        <MenuItem key="copy" onClick={copy}>
          <CopyIcon className={`${styles.icon}`} />
          <span><FormattedMessage id="post.actions.copy" /></span>
        </MenuItem>
        {props.onShareNow && (
          <MenuItem onClick={shareNow}>
            <ShareIcon className={styles.icon} />
            <FormattedMessage id="post.actions.share-now" />
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}

export default PostedActionsMenu
