import * as React from 'react'
import { AppNavLink } from './AppNavLink'
import { FormattedMessage } from 'react-intl'
import styles from './SettingsNav.pcss'
import { mdiPlayCircle } from '@mdi/js'
import Icon from '@mdi/react'

export function SettingsNav() {
  return (
    <nav className={styles['nav-s']}>
      <h1 className={styles['subnav-title']}>
        <FormattedMessage id="app.nav.title-settings" />
      </h1>
      <AppNavLink
        label={<FormattedMessage id="app.nav.settings-networks" />}
        to="/settings/networks"
        end
      />
      <AppNavLink
        label={<FormattedMessage id="app.nav.settings-account" />}
        to="/settings/account"
      />
      <AppNavLink
        label={<FormattedMessage id="app.nav.settings-team" />}
        to="/settings/team"
      />
      <AppNavLink
        label={<FormattedMessage id="app.nav.settings-help" />}
        to={{ pathname: 'https://help.postplanner.com/' }}
        external
        target="_blank"
      />
      <AppNavLink
        label={<FormattedMessage id="app.nav.settings-logout" />}
        to="/logout"
      />
      <div className={styles['bottom-s']}>
        <a
          href="https://apps.apple.com/app/apple-store/id1573125897"
          rel="noopener"
          target="_blank"
          className={styles['img-link']}
        >
          <img src="/static/img/misc/ios-350px.png" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.postplanner.app"
          rel="noopener"
          target="_blank"
          className={styles['img-link']}
        >
          <img src="/static/img/misc/play-350px.png" />
        </a>
      </div>
    </nav>
  )
}
