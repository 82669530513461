import { ALLOW_PERSIST_TIKTOK_SETTINGS } from 'config'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getTikTokProfileSettings } from 'services/compose/tiktok/actions'
import { getTikTokProfilePrivacyOptions } from 'services/compose/tiktok/net'
import { TikTokPostProfileSettings, TikTokPrivacyOptions } from 'shared/types'
import { StoreThunkDispatch } from 'store/state'

export type TikTokData = { settings: TikTokPostProfileSettings, options: TikTokPrivacyOptions }

export function useTikTokDataFetch(ppids: string): [Record<string, TikTokData>, boolean] {
  const [tiktokData, setTiktokData] = useState<Record<string, TikTokData>>({})
  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch<StoreThunkDispatch>()

  useEffect(() => {
    const fetchTikTokData = async () => {
      setLoading(true)
      await Promise.all(ppids.split(',').map(ppid => {
        return dispatch(getTikTokProfilePrivacyOptions(ppid)).zip(dispatch(getTikTokProfileSettings(ppid)))
          .toPromise()
          .then(([options, saved]) => {
            const o = options.settings
            const nextOptions = {
              privacyLevel: o.privacyLevelOptions,
              enableComment: o.enableComment.map(Boolean),
              enableDuet: o.enableDuet.map(Boolean),
              enableStitch: o.enableStitch.map(Boolean),
              maxDuration: o.maxDuration
            }

            const defaultSettings: TikTokPostProfileSettings = {
              ppid,
              privacyLevel: 'PUBLIC_TO_EVERYONE',
              enableComment: false,
              enableDuet: false,
              enableStitch: false,
              promoting: 'OFF'
            }

            const nextSettings = ALLOW_PERSIST_TIKTOK_SETTINGS ? {
              privacyLevel: saved?.privacyLevel || defaultSettings.privacyLevel,
              enableComment: saved?.enableComment || defaultSettings.enableComment,
              enableDuet: saved?.enableDuet || defaultSettings.enableDuet,
              enableStitch: saved?.enableStitch || defaultSettings.enableStitch,
              promoting: saved?.promoting || '' as any
            } : defaultSettings

            const updatedSettings: TikTokPostProfileSettings = {
              ppid,
              privacyLevel: nextOptions.privacyLevel.includes(nextSettings?.privacyLevel) ? nextSettings?.privacyLevel as string : '',
              enableComment: nextOptions.enableComment.includes(nextSettings?.enableComment)
                ? nextSettings?.enableComment
                : nextOptions.enableComment[0],
              enableDuet: nextOptions.enableDuet.includes(nextSettings?.enableDuet)
                ? nextSettings?.enableDuet
                : nextOptions.enableDuet[0],
              enableStitch: nextOptions.enableStitch.includes(nextSettings?.enableStitch)
                ? nextSettings?.enableStitch
                : nextOptions.enableStitch[0],
              promoting: nextSettings?.promoting || '' as any
            }

            setTiktokData(current => ({
              ...current,
              [ppid]: { options: nextOptions, settings: updatedSettings }
            }))
          })
      }))
        .finally(() => setLoading(false))
    }
    fetchTikTokData()
  }, [dispatch, ppids])

  return [tiktokData, loading]
}
