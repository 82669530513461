import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Stream } from 'interfaces'
import { CardList, ITEM_WIDTH_SMALL, CARD_LIST_HEIGHT_SMALL } from 'components/CardList'
import { StreamCard } from 'components/Cards'
import { renderLoadingCards } from '../utils'

import styles from '../HomeSection.pcss'
import EmptyView from 'components/EmptyView'
const LOADING_CARDS_COUNT = 10

export interface CuratedStreamsCarouselProps {
  category: 'popular' | 'industries' | 'my-streams'
  streams: Stream[]
  navPrefix: string
  loading: boolean
  title?: string
}

export const CuratedStreamsCarousel = React.memo(function (props: CuratedStreamsCarouselProps) {
  if (props.category === 'my-streams' && props.streams.length === 0) {
    return null
  }

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h2 className={`${styles.title} text-ellipsis`}>
          {props.title || <FormattedMessage id={`find.home.sections.${props.category}`} />}
        </h2>
        <NavLink to={props.navPrefix} className={`${styles.navigation} text-ellipsis`}>
          <FormattedMessage id="general.carousel.nav.see-all" />
        </NavLink>
      </header>
      <CardList
        containerClassName={styles['card-list']}
        itemWidth={ITEM_WIDTH_SMALL}
        listHeight={CARD_LIST_HEIGHT_SMALL}
      >
        {
          props.streams.map(stream => (
            <StreamCard
              key={stream.id}
              stream={stream}
              urlBrowse={`/content/${props.category}/${stream.id}`}
              curatedCategory={props.category}
            />
          )).concat(props.loading || props.streams.length === 0 ? renderLoadingCards(LOADING_CARDS_COUNT, true, true) : [])
        }
      </CardList>
    </section>
  )
})

export default CuratedStreamsCarousel
