import { RequestBuilder, authorizedPipe } from 'services/net'
import { CONTENT_SERVICE_URL } from 'config'

export const ENDPOINT_GET_LOCATIONS = `${CONTENT_SERVICE_URL}/search/live/facebook`

export const V1 = Object.freeze({
  searchLocations: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_LOCATIONS).asGet().expectJSON()
    const pageSizeDefault = '100'

    return function (query: string, pageSize?: number) {
      const resultsCount = typeof pageSize === 'number' ? pageSize.toString() : pageSizeDefault
      return authorizedPipe<any>(
        builder
          .param('needle', query)
          .param('page_size', resultsCount)
          .build()
      )
    }
  }()
})
