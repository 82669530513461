import { quoteToStatusIdea } from 'adapters'
import { QuoteFeed } from 'interfaces/Content/QuoteFeed'
import { V1 } from './net'
import { createAction } from '@reduxjs/toolkit'
import { addRecentSource } from '../recommended/actions'
import { StoreThunkAction, StoreThunkDispatch } from 'store/state'
import { tap } from 'rxjs/operators/tap'
import { Observable } from 'rxjs/Observable'

export function getRandomQuotes() {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.getRandomQuotes())
    .map(response => response.quotes.map(quoteToStatusIdea))
}

export function updateTag(id: number, active: boolean) {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.updateTag(id, active)).map((response) => {
    if (response.error) {
      throw new Error(response.msg)
    }
    return {
      id, active
    }
  })
}

export function getTags(all?: boolean) {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.getTags()).map(response => {
    if (all) {
      return response.map((tag: any) => ({ ...tag, active: Boolean(tag.isVisible) }))
    }
    return response
      .filter((tag: any) => tag.isVisible)
      .map((tag: any) => ({ ...tag, active: Boolean(tag.isVisible) }))
  })
}

export const setPastSearches = createAction<any[]>('content/quotes/setPastSearches')

export function getPastSearches() {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.getPastSearches())
    .pipe(tap((response: any) => {
      dispatch(setPastSearches(response.searches))
    }))
    .map(response => response.searches)
}

export function deleteSavedSearch(text: string) {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.deleteSavedSearch(text))
}

export const deleteRecentQuoteSearch = createAction<string>('content/quotes/deleteRecentQuoteSearch')
export const quoteSearchSaved = createAction<{ term: string, isTag: boolean }>('content/quotes/quoteSearchSaved')
export function saveQuoteSearch(query: string, trackInteraction?: boolean): StoreThunkAction<Observable<string>> {
  return (dispatch: StoreThunkDispatch) => {
    const feed: QuoteFeed = {
      id: query,
      name: query,
      createdAt: new Date().toISOString(),
      isQuoteFeed: true
    }

    if (trackInteraction) {
      dispatch(addRecentSource(feed))
    }

    dispatch(quoteSearchSaved({ term: query, isTag: false }))

    return dispatch(V1.saveSearch(query)).map(() => query)
  }
}
