import * as React from 'react'
import { searchStockContent } from 'services/search/actions'
import styles from './SearchRoot.pcss'
import { updateState } from '../state/actions'
import { SearchContext } from '../state/context'
import { switchMap } from 'rxjs/operators/switchMap'
import { tap } from 'rxjs/operators/tap'
import { useDispatch } from 'react-redux'
import { Observable } from 'rxjs/Observable'
import { StoreThunkDispatch } from 'store/state'
import { catchError } from 'rxjs/operators/catchError'
import { Subject } from 'rxjs/Subject'
import { ContentItem } from 'interfaces'
import { renderLoadingCards } from 'routes/find/routes/home/components/utils'
import CardBricks from 'components/CardBricks'
import AnyCard from 'components/Card'
import EmptyView from 'components/EmptyView'
import { checkFeatureAvailability } from 'services/product'
import { FEATURE_CONTENT_ON_DEMAND } from 'shared/constants'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { resetScroll } from 'utils/dom'
import PPSelect from 'components/PPSelect'
import SortIcon from '@mui/icons-material/Sort'

export const SearchGoogleRoute = () => {
  const navigate = useNavigate()
  const params = useParams()

  const [{ query, activeFilter }, searchDispatch] = React.useContext(SearchContext)
  const [loading, setLoading] = React.useState(false)
  const [sortBy, setSortBy] = React.useState('rel')
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [items, setItems] = React.useState<ContentItem[]>([])
  const search$ = React.useRef<Subject<{ q: string, type: 'articles' | 'videos', sortBy: string }>>(new Subject())
  const scrollElementRef = React.useRef<HTMLElement | undefined>(undefined)
  const feedName = query.trim()

  React.useLayoutEffect(() => {
    scrollElementRef.current = document.querySelector('[data-test="main"]') as HTMLElement
    resetScroll()
  }, [])

  React.useEffect(() => {
    searchDispatch(updateState({ activeFilter: params.type as any, activeCategory: 'content' }))
  }, [params.type, searchDispatch])

  React.useEffect(() => {
    const sub = search$.current.filter(({ q }) => Boolean(q)).pipe(
      tap(() => {
        setLoading(true)
        setItems([])
      }),
      switchMap(({ q, type, sortBy }) => {
        return dispatch(searchStockContent(q, type as any, 'google', sortBy)).pipe(catchError((error) => {
          return Observable.of({ error })
        }))
      })
    )
      .subscribe((response: any) => {
        setLoading(false)
        if (!response.error) {
          setItems(response.map((item: ContentItem) => ({
            ...item,
            feed: { ...item.feed, name: feedName }
          })))
        }
      })

    return () => {
      sub.unsubscribe()
    }
  }, [dispatch, feedName, sortBy])

  React.useEffect(() => {
    const hasAccess = dispatch(checkFeatureAvailability(
      FEATURE_CONTENT_ON_DEMAND,
      false,
      () => navigate({ pathname: `/content/search/sources/streams?q=${query}` })
    ))

    if (!hasAccess) {
      return
    }
    if (query) {
      search$.current.next({ q: query, type: activeFilter as any, sortBy })
    }
  }, [query, activeFilter, dispatch, navigate, sortBy])

  const onCompose = React.useCallback(() => {
    return !dispatch(checkFeatureAvailability(FEATURE_CONTENT_ON_DEMAND))
  }, [dispatch])

  const cards = React.useMemo(() => {
    const count = 30
    let loadingCards = []
    let emptyCards = []
    if (loading) {
      loadingCards = renderLoadingCards(count)
    } if (items.length === 0) {
      emptyCards = renderLoadingCards(count, false, false, false)
    }
    return items.map(item => (
      <AnyCard
        key={item.id}
        content={item}
        children={item}
        onCompose={onCompose}
      />
    )).concat(loadingCards).concat(emptyCards)
  }, [items, loading, onCompose])

  const onSortByChange = (value: string) => {
    setSortBy(value)
  }

  return (
    <div className={`${styles.box} ${styles['top-offset']}`}>
      <header className={styles.header}>
        <h2 className={`text-ellipsis ${styles.title}`}>
          <FormattedMessage id={`search.navigation.${activeFilter}`} />
        </h2>
        <NavLink to={`/content/search?q=${query}`}>
          <FormattedMessage id="general.carousel.nav.back" />
        </NavLink>
        <PPSelect
          name={<FormattedMessage id="label.generic.sort-by" />}
          selectedValue={sortBy}
          icon={<SortIcon />}
          options={{
            rel: { label: 'Relevance' },
            date: { label: 'Recency' }
            // score: { label: 'Stars' }
          }}
          withCaret
          onSelectionChange={onSortByChange}
        />
      </header>
      <CardBricks key={query + sortBy}>
        {cards}
      </CardBricks>
      {!loading && query && items.length === 0 && (
        <EmptyView
          title={`No ${activeFilter} available for that search`}
          subtitle="Please try a different keyword, topic, brand or industry."
          contained
          top="300px"
        />
      )}
    </div>
  )
}

export default SearchGoogleRoute
