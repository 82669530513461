import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'

export const ENDPOINT_CHECK_TOKENS = `${LEGACY_URL}/c/app_status`

export const V1 = Object.freeze({
  checkTokens: function () {
    const builder = new RequestBuilder(ENDPOINT_CHECK_TOKENS).asGet().expectJSON()

    return function () {
      return authorizedPipe<any>(builder.build())
    }
  }()
})
