import * as React from 'react'
import { SelectableAvatar } from 'components/ProfileAvatar'
import { PostDestination } from 'interfaces'
import { orderProfilesByNetwork } from 'utils/sort/order'

import styles from './ProfileAvatarGridList.pcss'

interface ProfileAvatarGridListProps {
  profiles: { [id: string]: PostDestination }
  selectedIds: string[]
  className?: string
  itemClassName?: string
  onProfileClick?: (id: string) => void
}

export function ProfileAvatarGridList(props: ProfileAvatarGridListProps) {
  const handleProfileClick = (p: PostDestination) => () => {
    if (props.onProfileClick) {
      props.onProfileClick(p.id)
    }
  }

  const orderedProfiles = orderProfilesByNetwork(Object.values(props.profiles))

  return (
    <div className={`${styles.grid} ${props.className || ''}`}>
      {
        orderedProfiles.map(p => {
          const selected = Boolean(props.selectedIds && props.selectedIds.includes(p.id))
          return (
            <span
              key={p.id}
              className={`${styles.item} ${selected ? styles.active : ''} ${props.itemClassName || ''}`}
              onClick={handleProfileClick(p)}
            >
              <SelectableAvatar
                title={p.name}
                brand={p.type}
                src={p.image}
                circle={!p.fbGroup}
                selected={selected}
              />
            </span>
          )
        })
      }
    </div>
  )
}

export default ProfileAvatarGridList
