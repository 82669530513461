import { Stream, ContentType, AnyContent, ARTICLE_TYPE, GIF_TYPE, IndexedObject, PHOTO_TYPE, VIDEO_TYPE } from 'interfaces'
import { STOCK_PHOTO_TYPE, STOCK_VIDEO_TYPE, STOCK_GIF_TYPE } from 'interfaces/Content/StockContentTypes'

export function createStreamTypesMap(streams: Stream[]) {
  const streamsMap: { [key: string]: { filters: ContentType[], originalStreamId?: string } } = {}
  streams.forEach(stream => {
    streamsMap[stream.id] = { filters: stream.filters }
    if (stream.originalId || stream.public) {
      streamsMap[stream.id].originalStreamId = stream.originalId || stream.id
    }
  })

  return streamsMap
}

export interface StreamsContentState {
  stream: Stream,
  items: AnyContent[],
  loading: boolean,
  loaded: boolean
}

export const CONTENT_TYPE_STOCK_MAP: IndexedObject<ContentType> = {
  [STOCK_PHOTO_TYPE]: PHOTO_TYPE,
  [STOCK_VIDEO_TYPE]: VIDEO_TYPE,
  [STOCK_GIF_TYPE]: GIF_TYPE,
  [ARTICLE_TYPE]: ARTICLE_TYPE,
  [VIDEO_TYPE]: VIDEO_TYPE,
  [PHOTO_TYPE]: PHOTO_TYPE,
  [GIF_TYPE]: GIF_TYPE
}
