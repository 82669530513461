import { SelectableAvatar } from 'components/ProfileAvatar'
import React, { useMemo } from 'react'
import { PostDestination } from 'shared'
import styles from './BucketProfile.pcss'
import Tooltip from '@mui/material/Tooltip'

interface BucketProfileProps {
  profile: PostDestination
  isSelected: boolean
  postsCount: number
  postingTimesCount: number
  onClick: (ppid: string) => void
}

export function BucketProfile({ profile, isSelected, postsCount, postingTimesCount, onClick }: BucketProfileProps) {
  const handleClick = () => onClick(profile.ppid)

  const colorClass = useMemo(() => {
    // eslint-disable-next-line no-magic-numbers
    return postsCount === 0 ? styles.red : postsCount < 6 ? styles.yellow : styles.green
  }, [postsCount])

  const tooltip = React.useMemo(() => {
    let value = `${profile.name} — ${postsCount} ${postsCount === 1 ? ' post' : ' posts'}`
    if (postingTimesCount === 0) {
      value = `${value} — No posting times`
    }
    return value
  }, [postingTimesCount, postsCount, profile.name])

  return (
    <Tooltip title={tooltip} placement="top">
      <div data-ppid={profile.ppid} className={styles.box} onClick={handleClick}>
        <div className={`${styles.badge} ${colorClass}`}>{postsCount}</div>
        <SelectableAvatar
          src={profile.image}
          brand={profile.type}
          title=""
          circle
          radio
          className={`${styles.profile} ${postingTimesCount === 0 ? styles.alert : ''}`}
          selected={isSelected}
        />
      </div>
    </Tooltip>
  )
}
