import { Component, Children, PropsWithChildren } from 'react'
import { connect } from 'react-redux'
import { List } from 'interfaces'
import { StoreThunkDispatch } from 'store/state'
import { getLists } from 'services/lists/actions'

export interface FetchListsProps {
  force?: boolean
  onFetched?(destinations: List[]): void
  onFailed?(err: any): void
}

interface ConnectedFetchListsProps extends FetchListsProps {
  getLists(): Promise<List[]>
}

export class FetchLists extends Component<PropsWithChildren<ConnectedFetchListsProps>, void> {
  constructor(props: ConnectedFetchListsProps) {
    super(props)

    this.onFetched = this.onFetched.bind(this)
    this.onFailed = this.onFailed.bind(this)
  }

  shouldComponentUpdate() {
    return false
  }

  onFetched(destinations: List[]) {
    if (this.props.onFetched) {
      this.props.onFetched(destinations)
    }
  }

  onFailed(err: any) {
    if (this.props.onFailed) {
      this.props.onFailed(err)
    }
  }

  fetch() {
    this.props.getLists().then(this.onFetched).catch(this.onFailed)
  }

  componentDidUpdate() {
    this.fetch()
  }

  render() {
    return this.props.children
      ? Children.only(this.props.children)
      : null
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch, ownProps: FetchListsProps) {
  const force = ownProps.force !== undefined ? ownProps.force : true // true by default
  return {
    getLists: () => dispatch(getLists(force))
  }
}

export default connect(null, mapDispatchToProps)(FetchLists as any)
