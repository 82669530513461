import QuotesFeedView from 'components/QuotesFeedView'
import { QuoteFeed } from 'interfaces/Content/QuoteFeed'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { saveQuoteSearch } from 'services/content/quotes/actions'
import { StoreThunkDispatch } from 'store/state'

export function QuotesFeed() {
  const params = useParams()
  const dispatch = useDispatch<StoreThunkDispatch>()

  const feed = React.useMemo(() => {
    return {
      id: params.name,
      name: params.name,
      createdAt: new Date().toISOString()
    }
  }, [params.name])

  React.useEffect(() => {
    dispatch(saveQuoteSearch(feed.name || '', true)).toPromise()
  }, [feed.name, dispatch])

  return (
    <QuotesFeedView feed={feed as QuoteFeed} gridView />
  )
}
