import { FileFolder, MyFile } from 'interfaces'
import { Prompt } from 'shared'
import { MAX_VIDEO_SIZE_BYTES, MAX_PHOTO_SIZE_BYTES } from 'utils/file/constants'

export const UPLOADS_STATE_VERSION = 'uploads:3'
export interface FileUploadInfo { file: File, width: number, height: number, rotateBy?: number }

export interface StorageInfo {
  used: number
  free: number
  limit: number
  fileSizeLimits: { [key: string]: number }
}

export interface UploadsState {
  folders: Readonly<{ [id: string]: FileFolder }>
  selectedFile: Readonly<MyFile | undefined>
  uploaderFile: Readonly<File | undefined>
  preview: Readonly<MyFile | undefined>
  uploadDialogOpen: Readonly<boolean>
  uploadDialogFiles: Readonly<{ [name: string]: File }>
  storage: Readonly<StorageInfo>
  aiPrompts: Prompt[]
  editFolder?: FileFolder
  sorting: Record<string, { sortBy: 'name' | 'date', sortDirection: 'asc' | 'desc' }>
}

export function initialState(): UploadsState {
  return {
    folders: {},
    aiPrompts: [],
    selectedFile: undefined,
    uploaderFile: undefined,
    preview: undefined,
    uploadDialogOpen: false,
    uploadDialogFiles: Object.freeze({}),
    storage: {
      used: 0,
      free: 0,
      limit: 0,
      fileSizeLimits: Object.freeze({
        photos: MAX_PHOTO_SIZE_BYTES,
        videos: MAX_VIDEO_SIZE_BYTES,
        gifs: MAX_PHOTO_SIZE_BYTES
      })
    },
    sorting: {}
  }
}

export default UploadsState
