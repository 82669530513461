import { ARTICLE_TYPE, ContentFeedType, Feed, Stream } from 'interfaces'
import { STOCK_PHOTO_TYPE, STOCK_VIDEO_TYPE, STOCK_GIF_TYPE } from 'interfaces/Content/StockContentTypes'
import { Subscription } from 'rxjs/Subscription'
import { SEARCH_FILTERS, SearchFilterKey, SearchResultsCategory } from './types'

export const MIN_SEARCH_LENGTH = 3
export const HITS_PER_PAGE = 20
export interface SearchResultSectionState {
  page: number
  hasNextPage: boolean
  loading: boolean
  items: any[]
  searchSubscription$: Subscription | null
  error?: any
}

export interface SearchState {
    value: string // current search input value
    /** Changing any of these 3 values - query, scope, page - will trigger new search and update the results */
    query: string // last executed search query
    scope: SearchFilterKey[] // scope determines the search that will be executed
    page: number // used to trigger !next! page search for a specific scope
    quotesTag?: string
    ideasTag?: string
    results: Record<SearchFilterKey, SearchResultSectionState>
    activeFilter?: SearchFilterKey
    activeCategory: SearchResultsCategory
    selectedFilters: SearchFilterKey[]
    activeFiltersPreset?: string
    createdKeywordFeeds: Feed[]
    latestSearchesActive: boolean
    latestSearchesLoading: boolean
    hasRecentSearches: boolean
    selectedFeeds: Feed[]
    quotesTags: string[]
    postIdeasTags: string[]
    activeStream?: Stream
    activeFeed?: Feed
    newFeedTemplate?: {
      type: ContentFeedType | undefined
      handle: string
      displayName: string
    }
    error?: {
      title: string
      subtitle?: string
    }
  }

export const emptyResultsState: Record<SearchFilterKey, SearchResultSectionState> = SEARCH_FILTERS.reduce((acc, filter) => {
  acc[filter.key] = {
    page: 0,
    hasNextPage: true,
    loading: false,
    items: [],
    searchSubscription$: null
  }
  return acc
}, {} as Record<SearchFilterKey, SearchResultSectionState>)

export const QUOTES_SEARCH_CONTENT_TYPE = 'quotes'
export const STATUSES_SEARCH_CONTENT_TYPE = 'post-ideas'
export type SearchContentType = typeof STOCK_PHOTO_TYPE
  | typeof STOCK_VIDEO_TYPE
  | typeof ARTICLE_TYPE
  | typeof STOCK_GIF_TYPE
  | typeof QUOTES_SEARCH_CONTENT_TYPE
  | typeof STATUSES_SEARCH_CONTENT_TYPE

export const CONTENT_TYPE_FILTERS: Record<string, string> = {
  [STOCK_PHOTO_TYPE]: 'images',
  [STOCK_VIDEO_TYPE]: 'videos',
  [STOCK_GIF_TYPE]: 'gifs',
  [ARTICLE_TYPE]: 'articles',
  [QUOTES_SEARCH_CONTENT_TYPE]: 'quotes',
  [STATUSES_SEARCH_CONTENT_TYPE]: 'post ideas'
}
