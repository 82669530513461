import * as React from 'react'
import { StoreThunkDispatch } from 'store/state'
import { useDispatch, useSelector } from 'react-redux'
import StreamsLoader from 'components/StreamsLoader'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { Stream, WithIntl } from 'interfaces'
import { addRecentSource } from 'services/content/recommended/actions'
import { StreamViewWithFetch } from 'components/StreamView'
import styles from 'routes/routes.pcss'
import { injectIntl } from 'react-intl'
import { getContentPanels } from 'admin/services/content-panels/actions'
import { message } from 'services/snackbar'
import Loader from 'components/SimpleLoader'
import { contentGroupsSelector, contentPanelsSelector } from 'services/content/selectors'
import { StreamGroup } from 'admin/interfaces'
import { RouteErrorView } from 'components/App/components/ErrorFallback/RouteErrorView'

function StreamGroupView() {
  const matchParams = useParams()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const panels = useSelector(contentPanelsSelector)
  const groups = useSelector(contentGroupsSelector)
  const [loading, setLoading] = React.useState(panels.length === 0)
  const [error, setError] = React.useState(false)
  const groupsArray = Object.values(groups)
  const group = groupsArray.find(g => g.slug === matchParams.group)

  React.useEffect(() => {
    const sub = dispatch(getContentPanels(true))
      .subscribe(() => {
        setLoading(false)
        setError(false)
      }, () => {
        setLoading(false)
        setError(true)
      })
    return () => sub.unsubscribe()
  }, [])

  React.useEffect(() => {
    if (error && !loading && panels.length === 0) {
      dispatch(message('Error fetching data! Please refresh.', 'error'))
    }
  }, [panels, error, loading, dispatch])

  if (!group && !loading) {
    return groupsArray.length > 0 ? <Navigate to="/" /> : null
  }

  const baseUrl = `/content/${group?.slug}`
  const streamPath = `${baseUrl}/:stream`

  const trackInteraction = (stream: Stream) => {
    dispatch(addRecentSource(stream))
  }

  return (
    <section className={styles.page}>
      {loading && <Loader />}
      {!loading && (
        <Routes key="switch">
          <Route
            key="stream-route"
            path=":stream/*"
            element={(
              <StreamRoute
                baseUrl={baseUrl}
                group={group}
                onMount={trackInteraction}
              />
            )}
            errorElement={<RouteErrorView />}
          />
          <Route
            key="root"
            path="/"
            element={(
              <StreamsLoader
                streamUrlPattern="slug-id"
                streams={group?.streams || []}
                browseUrlBase={baseUrl}
              />
            )}
            errorElement={<RouteErrorView />}
          />
        </Routes>
      )}
    </section>
  )
}

const StreamRoute = injectIntl((props: { baseUrl: string, group?: StreamGroup, onMount: (stream: Stream) => void } & WithIntl) => {
  const matchParams = useParams()
  const streamId = matchParams.stream && matchParams.stream.split('-').pop()
  const stream = props.group?.streams.find((s: any) => s.id === streamId)
  if (!stream) {
    return <Navigate to={props.baseUrl} />
  }

  const navigation = {
    prefix: `${props.baseUrl}/${stream.slug}-${stream.id}`,
    more: props.intl.formatMessage({ id: 'general.carousel.nav.see-all', defaultMessage: 'see all' }),
    back: props.intl.formatMessage({ id: 'general.carousel.nav.back', defaultMessage: 'back' })
  }

  return (
    <StreamViewWithFetch
      streamId={stream.id}
      navigation={navigation}
      showBackLink
      onMount={props.onMount}
    />
  )
})

export default injectIntl(StreamGroupView)
