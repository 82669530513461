export const BRAND_FACEBOOK = 'facebook'
export const BRAND_TWITTER = 'twitter'
export const BRAND_INSTAGRAM = 'instagram'
export const BRAND_PINTEREST = 'pinterest'
export const BRAND_LINKEDIN = 'linkedin'
export const BRAND_GOOGLE = 'google'
export const BRAND_YOUTUBE = 'youtube'
export const BRAND_REDDIT = 'reddit'
export const BRAND_TIKTOK = 'tiktok'

export type SocialBrand = typeof BRAND_FACEBOOK
  | typeof BRAND_TWITTER
  | typeof BRAND_INSTAGRAM
  | typeof BRAND_PINTEREST
  | typeof BRAND_LINKEDIN
  | typeof BRAND_REDDIT
  | typeof BRAND_TIKTOK
  | typeof BRAND_YOUTUBE

export default SocialBrand
