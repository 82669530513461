import { RequestBuilder, authorizedPipe } from 'services/net'
import { ADMIN_SERVICE_URL } from 'config'
import { PPProduct } from 'interfaces'
import { Observable } from 'rxjs/Observable'

const ENDPOINT_GET_PLANS = `${ADMIN_SERVICE_URL}/plans`
const ENDPOINT_UPDATE_PLAN = `${ADMIN_SERVICE_URL}/plans/:id`

export const V1 = Object.freeze({
  updatePlan: function () {
    const builder = new RequestBuilder(ENDPOINT_UPDATE_PLAN).asPost().expectJSON()

    return function (plan: PPProduct) {
      return authorizedPipe<any>(
        builder
          .partial(':id', plan.id)
          .bodyJSONstringify(plan)
          .build()
      )
    }
  }()
})
