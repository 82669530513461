import { V1 } from './net'
import { createAsyncThunk } from '@reduxjs/toolkit'

/**
 * Fetch details about web page by its url
 *
 */
export const getLinkDetails = createAsyncThunk(
  'composer/getLinkDetails',
  async (params: { url: string, isPinterestDestinationUrl?: boolean }, { dispatch, rejectWithValue }) => {
    const { url, isPinterestDestinationUrl } = params
    try {
      const response = await dispatch(V1.getLinkDetails(url)).toPromise()
      if (response.url) {
        return { link: response, isPinterestDestinationUrl }
      }
      return rejectWithValue('Invalid link response')

    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
