import {
  ARTICLE_TYPE,
  BRAND_FACEBOOK,
  BRAND_INSTAGRAM,
  BRAND_LINKEDIN,
  BRAND_PINTEREST,
  BRAND_TWITTER,
  PHOTO_TYPE,
  PostBase,
  RepeatPeriod,
  REPEAT_PERIOD_WEEKS,
  STATUS_TYPE,
  Video,
  VIDEO_TYPE
} from 'interfaces'
import { BRAND_GOOGLE } from 'interfaces/Content/SocialBrand'
import { UploaderFilesState } from 'components/Composer/UploadContext'
import { StoreThunkAction } from 'store/state'
import { buildHTMLWithTags, getTextFromHtml } from 'utils/composer'
import { resetComposer } from '../actions'
import { getPostData } from '../create/createPost'
import { ComposerBasePost, ComposerFacebookPost, ComposerInstagramPost, ComposerPinterestPost, ComposerTwitterPost } from '../interfaces'
import { composerPostEditSelector, composerScheduleSelector } from '../selectors'
import ComposerState, { COMPOSER_STATUS_KEY_GENERIC, initialState } from '../state'
import { V1 } from './net'
import { ComposerGooglePost, GooglePostButtonType } from '../interfaces/ComposerGooglePost'
import { CreatePostRequestData } from 'shared/types'
import { BRAND_TIKTOK, BRAND_YOUTUBE } from 'shared'
import { ComposerTikTokPost } from '../interfaces/ComposerTikTokPost'
import { ComposerYoutubePost } from '../interfaces/ComposerYoutubePost'
import { parse } from 'date-fns'
import { ComposerLinkedinPost } from '../interfaces/ComposerLinkedinPost'

export function updatePost(files?: UploaderFilesState, post?: CreatePostRequestData, reset?: boolean): StoreThunkAction<any> {
  return (dispatch, getState) => {
    const isScheduled = composerScheduleSelector(getState()).isActive
    const data = post || dispatch(getPostData(isScheduled ? undefined : 'queue', isScheduled, files))
    const postEdit = composerPostEditSelector(getState()) as any
    if (reset) {
      dispatch(resetComposer())
    }
    return dispatch(V1.updatePost(postEdit.id, data, postEdit.bucketId))
  }
}

export function getDerivedStateFromPost(post: PostBase, type: 'planned' | 'posted' | 'failed' = 'planned'): Partial<ComposerState> {
  const { contentType, network } = post

  const state: Partial<ComposerState> = {
    postEdit: type === 'planned' ? { id: post.id, bucketId: post.bucket?.id, ppid: post.ppPageId } : undefined,
    activeTab: network,
    recycle: post.recycle,
    selectedProfiles: [post.profileId],
    selectedBucketId: undefined,
    posts: getPostsStateByNetwork(post)
  }

  if (!post.autoPost && type === 'planned') {
    const dateTime = parse(post.dateString, 'dd.LLL.yy hh:mm a', new Date()).toISOString()
    state.schedule = {
      isActive: true,
      time: dateTime,
      date: dateTime,
      repeat: Boolean(post.repeatLength),
      repeatCount: post.repeatLength || 1,
      repeatPeriod: post.repeatPeriod as RepeatPeriod || REPEAT_PERIOD_WEEKS,
      repeatUntil: post.repeatUntil === 'forever' ? 'forever' : 'date',
      repeatUntilDate: post.repeatUntil === 'forever' ? new Date().toISOString() : post.repeatUntil
    }
  }

  if (network === BRAND_PINTEREST && post.details?.photoAlbum) {
    const board = { ...post.details.photoAlbum, selected: true }
    state.pinterestBoards = { [post.ppPageId]: [board] }
  }

  switch (contentType) {
    case PHOTO_TYPE:
      return {
        ...state,
        featuredImageUrl: post.images.length > 1 ? post.images[0] : undefined,
        imageUrls: post.images,
        contentItem: post.details?.sourceFeedName ? {
          type: PHOTO_TYPE,
          socialLink: post.details.sourcePostSocialLink,
          feed: {
            name: post.details.sourceFeedName,
            type: post.details.sourceFeedType
          },
          imageUrl: post.images[0],
          createdAt: new Date()
        } as any : undefined
      }
    case ARTICLE_TYPE: {
      const contentItem = post.details?.sourceFeedName ? {
        type: ARTICLE_TYPE,
        socialLink: post.details?.sourcePostSocialLink || post.linkUrl as string,
        sourceLink: post.details?.sourceLink || post.linkUrl as string,
        title: post.linkTitle,
        description: post.linkDescription,
        feed: {
          name: post.details.sourceFeedName,
          type: post.details.sourceFeedType
        },
        imageUrl: post.images[0],
        createdAt: new Date()
      } as any : undefined

      return {
        ...state,
        contentItem,
        links: contentItem ? [] : [{
          description: post.linkDescription || '',
          domain: post.linkDomain || '',
          image: post.images,
          title: post.linkTitle || '',
          type: 'link',
          url: post.linkUrl || '',
          selected: true
        }]
      }
    }

    case VIDEO_TYPE: {
      if (post.nativeVideoUrl) {
        return {
          ...state,
          videoUrl: post.nativeVideoUrl,
          videoThumbnailUrl: post.details?.videoThumbnailUrl
        }
      }
      if (post.fbVideoUrl) {
        return {
          ...state,
          contentItem: {
            type: VIDEO_TYPE,
            socialLink: post.details?.sourcePostSocialLink || post.linkUrl as string,
            title: post.linkTitle,
            description: post.linkDescription,
            videoUrl: post.linkUrl,
            imageUrl: post.images[0],
            feed: {
              type: BRAND_FACEBOOK,
              name: post.details?.sourceFeedName
            },
            createdAt: new Date()
          } as Video
        }
      }

      const contentItem = post.details?.sourceFeedName ? {
        type: VIDEO_TYPE,
        socialLink: post.details.sourcePostSocialLink,
        feed: {
          name: post.details.sourceFeedName,
          type: post.details.sourceFeedType
        },
        imageUrl: post.images[0],
        createdAt: new Date()
      } as any : undefined
      return {
        ...state,
        contentItem,
        links: contentItem ? [] : [{
          description: post.linkDescription || '',
          domain: post.linkDomain || '',
          image: post.images,
          title: post.linkTitle || '',
          type: 'video_link',
          url: post.linkUrl || '',
          selected: true
        }]
      }
    }

    case STATUS_TYPE:
    default:
      return state
  }
}

function getPostsStateByNetwork(post: PostBase) {
  const { network, status, details, twPostType, ppPageId } = post
  const emptyState = initialState().posts
  const linkedInFormattedText = network === BRAND_LINKEDIN ? details?.text : undefined
  const htmlStatus = buildHTMLWithTags(status, details?.tags || [], network, undefined, linkedInFormattedText)
    .replace(/\n/g, '<br/>').replace(/\r/g, '')
  const postForNetwork: ComposerBasePost = {
    status: {
      text: status,
      html: htmlStatus,
      touched: true
    }
  }

  switch (network) {
    case BRAND_FACEBOOK:
      (postForNetwork as ComposerFacebookPost).albums = details?.photoAlbum
        ? [{ ...details?.photoAlbum, ppid: ppPageId }]
        : []
      ; (postForNetwork as ComposerFacebookPost).reshare = Boolean(details?.reshareUrl)
      ; (postForNetwork as ComposerFacebookPost).firstComment = post.details?.firstComment || ''
      ; (postForNetwork as ComposerFacebookPost).postType = post.details?.postType
      if (post.details?.textBackground) {
        (postForNetwork as ComposerFacebookPost).textBackground = post.details.textBackground
      }
      postForNetwork.status.text = getTextFromHtml(htmlStatus)
      break

    case BRAND_TWITTER:
      (postForNetwork as ComposerTwitterPost).tweetType = twPostType || 'new'
      break

    case BRAND_PINTEREST:
      (postForNetwork as ComposerPinterestPost).title = post.attributes?.title || ''
      ; (postForNetwork as ComposerPinterestPost).description = post.attributes?.description || ''
      ; (postForNetwork as ComposerPinterestPost).destinationUrl = post.attributes?.url || ''
      break

    case BRAND_INSTAGRAM:
      const location = post.details?.location
        ; (postForNetwork as ComposerInstagramPost).firstComment = post.details?.firstComment || ''
      ; (postForNetwork as ComposerInstagramPost).postType = post.igPostType
      ; (postForNetwork as ComposerInstagramPost).locations = location
        ? { [post.ppPageId]: location }
        : {}
      if (post.details?.photoTags) {
        (postForNetwork as ComposerInstagramPost).tags = post.details?.photoTags
      }
      break

    case BRAND_GOOGLE: {
      (postForNetwork as ComposerGooglePost).buttonType = post.details?.buttonType as GooglePostButtonType
      ; (postForNetwork as ComposerGooglePost).link = post.details?.buttonUrl || ''
      break
    }

    case BRAND_TIKTOK: {
      const settings = post.details?.settings?.find(s => s.ppid === ppPageId)
      if (settings) {
        (postForNetwork as ComposerTikTokPost).settings = {
          [ppPageId]: {
            ...settings,
            enableComment: Boolean(settings.enableComment),
            enableDuet: Boolean(settings.enableDuet),
            enableStitch: Boolean(settings.enableStitch)
          }
        }
      }
      break
    }

    case BRAND_YOUTUBE: {
      (postForNetwork as ComposerYoutubePost).firstComment = post.details?.firstComment || ''
      ; (postForNetwork as ComposerYoutubePost).description = post.description || ''
      ; (postForNetwork as ComposerYoutubePost).title = status
      ; (postForNetwork as ComposerYoutubePost).privacyStatus = post.privacyStatus || details?.privacyStatus || 'public'
      break
    }

    case BRAND_LINKEDIN: {
      (postForNetwork as ComposerLinkedinPost).firstComment = post.details?.firstComment || ''
      if (details?.documentUrl && details?.documentName) {
        (postForNetwork as ComposerLinkedinPost).document = { url: details?.documentUrl, name: details?.documentName }
      }
    }
  }

  return {
    ...emptyState,
    [COMPOSER_STATUS_KEY_GENERIC]: {
      status: {
        text: postForNetwork.status.text,
        html: postForNetwork.status.html,
        touched: true
      }
    },
    [network]: postForNetwork
  }
}
