import { StoreThunkAction } from 'store/state'
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/dom/ajax'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/toPromise'

import toCamelCase from 'utils/toCamelCase'
import { LEGACY_URL } from 'config'

export const ERROR_NO_EMAIL_RETURNED = 'ERROR_NO_EMAIL_RETURNED'
const HTTP_FAILURE_CODE_START = 400

export function getLegacyCredentials(token: string, params?: { [key: string]: string }): StoreThunkAction<Promise<any>> {
  return () => {
    return Observable.ajax({
      url: LEGACY_URL + `/login/test_fb_token`,
      method: 'POST',
      body: { ...params, token },
      headers: {
        Accept: 'application/vnd.postplanner.v2+json'
      },
      crossDomain: true,
      withCredentials: true
    }).toPromise()
      .then(response => {
        const error = response.response.error
        const errorMessage = error && error.indexOf('no_email_returned') !== 0 ? ERROR_NO_EMAIL_RETURNED : response.responseText
        if (response.status === 0 || response.status >= HTTP_FAILURE_CODE_START || error) {
          throw new Error(errorMessage)
        }

        return {
          user: toCamelCase(response.response.user),
          products: response.response.subscriptions.products
        }
      })
      .catch(error => {
        const message = typeof error.response?.error === 'string' ? error.response.error : error.response?.error?.message
        if (typeof message === 'string' && message.toLowerCase().indexOf('no_email_returned') !== -1) {
          throw new Error(ERROR_NO_EMAIL_RETURNED)
        }
        throw error
      })
  }
}
