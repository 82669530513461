import * as React from 'react'
import { PostType } from 'interfaces'
import WarningIcon from '@mui/icons-material/WarningRounded'

import styles from './PostErrorView.pcss'

export const ERROR_NO_PROFILES_TITLE = 'Oops! Looks like you haven\'t selected any profiles.'
export const ERROR_NO_PROFILES_SUBTITLE = 'Choose one from the panel on the left.'
export const getNoPostsTitle = (type: PostType) => `You don't have any ${type} posts.`
export const ERROR_POSTS_GENERAL_TITLE = 'Something went wrong and we couldn\'t fetch your posts.'
export const ERROR_POSTS_GENERAL_SUBTITLE = 'Please try again.'

export interface PostErrorViewProps {
  title: string
  subtitle?: string
}

export function PostErrorView(props: PostErrorViewProps) {
  return (
    <div data-test="posts-error-view" className={styles.error}>
      <h1 className={styles.title}>
        <WarningIcon className={styles.icon} />
        {props.title}
      </h1>
      {
        props.subtitle && (
          <h2 className={styles.subtitle}>{props.subtitle}</h2>
        )
      }
    </div>
  )
}

export default PostErrorView
