import { Component, createElement, Children, RefObject, createRef, PropsWithChildren } from 'react'
import { Subject } from 'rxjs/Subject'
import 'rxjs/add/operator/takeUntil'
import 'rxjs/add/operator/debounceTime'

export interface ScrollListenerProps {
  emitTreshold: number
  emitInitial?: boolean
  className?: string
  disabled?: boolean
  useWindow?: boolean
  scrollElement?: Element
  onScrollDebounceTime?: number
  onScroll(offset: number, top: number): void
}

export class ScrollListener extends Component<PropsWithChildren<ScrollListenerProps>, any> {
  private onScroll$: Subject<{ offset: number, top: number }>
  private unmount$: Subject<boolean>
  private requestAnimationFrameId: number | undefined
  private scrollComponentRef: RefObject<HTMLDivElement>

  constructor(props: ScrollListenerProps) {
    super(props)

    this.onScroll = this.onScroll.bind(this)
    this.emitScroll = this.emitScroll.bind(this)
    this.scrollComponentRef = createRef()
    this.onScroll$ = new Subject()
  }

  shouldComponentUpdate(nextProps: any) {
    return this.props.className !== nextProps.className
      || this.props.children !== nextProps.children
  }

  onScroll() {
    if (this.requestAnimationFrameId || this.props.disabled) {
      return
    }

    this.requestAnimationFrameId = requestAnimationFrame(this.emitScroll)
  }

  calculateTopPosition(el: HTMLElement): number {
    return !el
      ? 0
      : el.offsetTop + this.calculateTopPosition(el.offsetParent as HTMLElement)
  }

  emitScroll() {
    const scrollSelf = this.scrollComponentRef.current

    if (!scrollSelf) {
      return
    }

    this.requestAnimationFrameId = undefined
    if (this.props.useWindow) {
      const scrollElement = window
      const scrollTop = scrollElement.pageYOffset !== undefined
        ? scrollElement.pageYOffset
        : (document.documentElement || document.body.parentNode || document.body).scrollTop

      const offset = this.calculateTopPosition(scrollSelf)
        + scrollSelf.offsetHeight
        - scrollTop
        - window.innerHeight

      if (offset < this.props.emitTreshold) {
        this.onScroll$.next({ offset, top: scrollTop })
      }

      return
    }

    const parent = this.props.scrollElement || scrollSelf.parentNode as Element
    const offset = scrollSelf.scrollHeight - parent.scrollTop - parent.clientHeight

    if (offset < this.props.emitTreshold) {
      this.onScroll$.next({ offset, top: parent.scrollTop })
    }
  }

  attachScrollListener() {
    const { useWindow, scrollElement } = this.props
    const containerElement = this.scrollComponentRef.current ? this.scrollComponentRef.current.parentNode : null
    const scrollableElement = useWindow
      ? window
      : scrollElement || containerElement as Element
    scrollableElement.addEventListener('scroll', this.onScroll, false)
    window.addEventListener('resize', this.onScroll, false)
  }

  detachScrollListener() {
    const { useWindow, scrollElement } = this.props
    const containerElement = this.scrollComponentRef.current ? this.scrollComponentRef.current.parentNode : null
    const scrollableElement = useWindow
      ? window
      : scrollElement || containerElement as Element
    scrollableElement.removeEventListener('scroll', this.onScroll, false)
    window.removeEventListener('resize', this.onScroll, false)
  }

  componentDidMount() {
    this.unmount$ = new Subject()
    this.attachScrollListener()
    this.onScroll$
      .debounceTime(this.props.onScrollDebounceTime || 0)
      .takeUntil(this.unmount$)
      .subscribe((value) => {
        this.props.onScroll(value.offset, value.top)
      })

    if (this.props.emitInitial) {
      this.props.onScroll(0, 0)
    }
  }

  componentDidUpdate(previousProps: ScrollListenerProps) {
    if (previousProps.useWindow !== this.props.useWindow || previousProps.scrollElement !== this.props.scrollElement) {
      this.detachScrollListener()
      this.attachScrollListener()
    }
  }

  componentWillUnmount() {
    this.unmount$.next(true)
    this.onScroll$.unsubscribe()
    this.detachScrollListener()
  }

  render() {
    const className = this.props.className || ''
    return createElement('div', { className, ref: this.scrollComponentRef },
      Children.only(this.props.children)
    )
  }
}

export default ScrollListener
