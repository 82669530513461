import * as React from 'react'
import { WithIntl } from 'interfaces'
import GridIcon from '@mui/icons-material/Apps'
import ListIcon from '@mui/icons-material/Reorder'
import SearchIcon from '@mui/icons-material/Search'
import NetworkIcon from '@mui/icons-material/Sort'
import styles from './ProfilesLayoutPicker.pcss'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconClear from '@mui/icons-material/Close'
import Tooltip from '@mui/material/Tooltip'
import { FormattedMessage, injectIntl } from 'react-intl'

const SEARCH_BOX_HEIGHT = 32
export type ProfilesLayout = 'grid' | 'list' | 'network'

interface ProfilesLayoutPickerProps {
  selectedValue: ProfilesLayout
  withSearch?: boolean
  className?: string
  onChange: (layout: ProfilesLayout) => void
  onFilter?: (value: string) => void
}

export function ProfilesLayoutPicker(props: ProfilesLayoutPickerProps & WithIntl) {
  const [searchVisible, setSearchVisible] = React.useState(false)
  const [filter, setFilter] = React.useState('')

  const changeView = (view: ProfilesLayout) => () => {
    props.onChange(view)
  }

  const toggleSearch = () => {
    setSearchVisible(current => !current)
  }

  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setFilter(value)
  }

  const onKeyDown = React.useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!props.withSearch || !props.onFilter) {
      return
    }

    const value = (e.target as HTMLInputElement).value.trim()
    if (e.key === 'Enter') {
      props.onFilter(value)
    }
  }, [props.onFilter, props.withSearch])

  const clearFilter = () => {
    setFilter('')
    setSearchVisible(false)
    if (props.onFilter) {
      props.onFilter('')
    }
  }

  return (
    <div className={props.className}>
      <div className={styles.actions}>
        <Tooltip title={<FormattedMessage id="post.sidebar.tooltips.grid" />}>
          <IconButton
            className={`${styles.btn} ${props.selectedValue === 'grid' ? styles.active : ''}`}
            size="small"
            disableRipple
            onClick={changeView('grid')}
          >
            <GridIcon className={styles.icon} />
          </IconButton>
        </Tooltip>
        <Tooltip title={<FormattedMessage id="post.sidebar.tooltips.list" />}>
          <IconButton
            className={`${styles.btn} ${props.selectedValue === 'list' ? styles.active : ''}`}
            size="small"
            disableRipple
            onClick={changeView('list')}
          >
            <ListIcon className={styles.icon} />
          </IconButton>
        </Tooltip>
        <Tooltip title={<FormattedMessage id="post.sidebar.tooltips.network" />}>
          <IconButton
            className={`${styles.btn} ${props.selectedValue === 'network' ? styles.active : ''}`}
            size="small"
            disableRipple
            onClick={changeView('network')}
          >
            <NetworkIcon className={styles.icon} />
          </IconButton>
        </Tooltip>
        {props.withSearch && (
          <Tooltip title={<FormattedMessage id="post.sidebar.tooltips.search" />}>
            <IconButton
              className={`${styles.btn} ${styles['btn-search']} ${searchVisible ? styles.active : ''} nobg`}
              size="small"
              disableRipple
              onClick={toggleSearch}
            >
              <SearchIcon className={styles.icon} />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {searchVisible && (
        <div className={styles['search-container']}>
          <TextField
            value={filter}
            onChange={onFilterChange}
            onKeyDown={onKeyDown}
            placeholder={props.intl.formatMessage({ id: 'post.sidebar.search-placeholder' })}
            classes={{ root: styles['search-box'] }}
            style={{ height: `${SEARCH_BOX_HEIGHT}px` }}
            InputProps={{
              className: styles['search-input'],
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconClear className={styles['btn-clear-search']} role="button" onMouseDown={clearFilter} />
                </InputAdornment>
              )
            }}
          />
        </div>
      )}
    </div>
  )
}

export default injectIntl(ProfilesLayoutPicker)
