import { connect } from 'react-redux'
import { RangeFilter, PHOTO_TYPE, ARTICLE_TYPE, STATUS_TYPE, VIDEO_TYPE, ContentType, GIF_TYPE } from 'interfaces'
import { getPhotos } from 'services/content/photos/actions'
import { getArticles } from 'services/content/articles/actions'
import { getVideos } from 'services/content/videos/actions'
import { getGifs } from 'services/content/gifs/actions'
import { getTexts } from 'services/content/texts/actions'
import { getStatusIdeas } from 'services/content/statuses/actions'
import { StoreState, StoreThunkDispatch } from 'store/state'
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/of'
import 'rxjs/add/observable/empty'
import 'rxjs/add/operator/mergeMap'
import { FetchStreamContent, FetchStreamContentProps } from './FetchStreamContent'
import { SortBy } from 'services/content/util'
import { TEXT_TYPE } from 'interfaces/Content/ContentType'

export interface FetchStreamPageContentProps extends FetchStreamContentProps {
  page: number
  range: RangeFilter
  sortBy?: SortBy

  skip?: ContentType[]
  only?: ContentType
}

function mapStateToProps(_state: StoreState, ownProps: FetchStreamContentProps) {
  const { page, range, stream, only, sortBy } = ownProps as FetchStreamPageContentProps

  return {
    ...ownProps,
    hash: `stream:${stream.id}:${only || 'any'}:${page}:${range}:${sortBy || 'any'}` as string
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch, ownProps: FetchStreamPageContentProps) {
  const { page, range, stream, skip, only, sortBy } = ownProps
  const id = stream.originalId || stream.id

  const skipVideos = (only && only !== VIDEO_TYPE) || (skip && skip.includes(VIDEO_TYPE))
  const skipGifs = (only && only !== GIF_TYPE) || (skip && skip.includes(GIF_TYPE))
  const skipPhotos = (only && only !== PHOTO_TYPE) || (skip && skip.includes(PHOTO_TYPE))
  const skipArticles = (only && only !== ARTICLE_TYPE) || (skip && skip.includes(ARTICLE_TYPE))
  const skipStatuses = (only && only !== STATUS_TYPE) || (skip && skip.includes(STATUS_TYPE))
  const skipTexts = (only && only !== TEXT_TYPE) || (skip && skip.includes(TEXT_TYPE))

  return {
    getVideos: () => skipVideos
      ? Observable.empty()
      : dispatch(getVideos(id, true, page, range, sortBy)).flatMap((v: any) => Observable.of(...v.items)),
    getGifs: () => skipGifs
      ? Observable.empty()
      : dispatch(getGifs(id, true, page, range, sortBy)).flatMap((v: any) => Observable.of(...v.items)),
    getPhotos: () => skipPhotos
      ? Observable.empty()
      : dispatch(getPhotos(id, true, page, range, sortBy)).flatMap((p: any) => Observable.of(...p.items)),
    getArticles: () => skipArticles
      ? Observable.empty()
      : dispatch(getArticles(id, true, page, range, sortBy)).flatMap((a: any) => Observable.of(...a.items)),
    getTexts: () => skipTexts
      ? Observable.empty()
      : dispatch(getTexts(id, true, page, range, sortBy)).flatMap((a: any) => Observable.of(...a.items)),
    getStatusIdeas: (categoryIds: Array<string>) => {
      if (skipStatuses || categoryIds.length === 0) {
        return Observable.empty()
      }
      return dispatch(getStatusIdeas(categoryIds, page)).flatMap((s: any) => Observable.of(...s.items))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FetchStreamContent)
