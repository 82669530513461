import { createSelector } from 'reselect'
import { StoreState } from 'store/state'
import { initialState } from './state'

import { IndexedObject, PostDestination, sortNetworksDefault } from 'interfaces'
import { sortByKeyAlphabetically } from 'utils/sort/order'

export const destinationsSelector = (state: StoreState) => state.destinations || initialState()
export const userDestinationsSelector = createSelector(destinationsSelector, (destinations) => destinations.destinations)
export const pinterestBoardsByPpidSelector = createSelector(destinationsSelector, (destinations) => destinations.pinterestBoardsByPpid)

export const connectedDestinationsSelector = createSelector(
  userDestinationsSelector,
  (destinations) => Object.keys(destinations).reduce(
    (results, key) => {
      const destination = destinations[key]
      if (destination.connected) {
        results[destination.id] = destination
      }
      return results
    },
    {} as { [key: string]: Readonly<PostDestination> }
  )
)

export const sortedConnectedProfilesSelector = createSelector(
  connectedDestinationsSelector,
  (profiles: { [key: string]: Readonly<PostDestination> }) => {
    const sorted: PostDestination[] = []
    const grouped = Object.values(profiles).reduce((map: IndexedObject<PostDestination[]>, profile: PostDestination) => {
      if (map[profile.type]) {
        map[profile.type].push(profile)
      } else {
        map[profile.type] = [profile]
      }
      return map
    }, {})
    Object.keys(grouped).sort(sortNetworksDefault).forEach(network => {
      sorted.push(...grouped[network].sort(sortByKeyAlphabetically('name')))
    })
    return sorted
  }
)

export const connectedProfilesImagesSelector = createSelector(connectedDestinationsSelector, profilesMap => {
  return Object.values(profilesMap).reduce((map: IndexedObject<string>, profile) => {
    map[profile.ppid] = profile.image
    return map
  }, {})
})

export const defaultPostToDestinationsSelector = createSelector(
  connectedDestinationsSelector,
  (destinations) => Object.keys(destinations).reduce(
    (results, key) => {
      const destination = destinations[key]
      if (destination.default) {
        results[destination.id] = destination
      }
      return results
    },
    {} as { [key: string]: Readonly<PostDestination> }
  )
)

export const facebookPersonalProfilesSelector = createSelector(destinationsSelector, state => state.facebookPersonalProfiles)
export const profilesByPpidSelector = createSelector(userDestinationsSelector, profiles => {
  return Object.values(profiles).reduce((map: Record<string, PostDestination>, profile: PostDestination) => {
    map[profile.ppid] = profile
    return map
  }, {})
})
