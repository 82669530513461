export interface UserQuoteData {
  title: string,
  name: string,
  website: string
  websiteUrl: string,
  imageUrl: string
  quote: string
  role: string
}

export const userQuotes: UserQuoteData[] = [{
  title: 'Customer Success',
  name: 'Deb Laflamme',
  imageUrl: 'https://staticpp.postplanner.com/utils/login/deb_laflamme.jpg',
  website: 'For the Love of Your Biz',
  websiteUrl: 'https://ftloyb.com',
  quote: `Post Planner has allowed me to skyrocket the growth of my social marketing business. The new app is amazing!`,
  role: ''
}, {
  title: 'THIS APP WORKS!',
  name: 'Joshua Becker',
  role: 'WSJ bestselling author',
  imageUrl: 'https://staticpp.postplanner.com/utils/login/signin_sidebar_circle_joshua-becker.png',
  website: '@becomingminimalist.com',
  websiteUrl: 'https://becomingminimalist.com',
  quote: `<b>My followers have increased by 389%</b> in 3 weeks. `
    + `My brand is growing stronger every day. Post Planner deserves all the credit.`
}, {
  title: 'Customer Results',
  name: 'Rachel Pedersen',
  imageUrl: 'https://staticpp.postplanner.com/utils/login/rachel_pedersen.jpg',
  website: 'rachelpedersen.com',
  websiteUrl: 'https://rachelpedersen.com',
  quote: `Post Planner is hands down my favorite platform for scheduling content. Its content will engage and entertain your audience!`,
  role: ''
}, {
  title: 'Customer Results',
  name: 'Kristy Richardson',
  imageUrl: '/static/img/login/kristy-r.png',
  website: 'OnMyKidsPlate.com',
  websiteUrl: 'http://onmykidsplate.com',
  quote: `<b>Post Planner helped me increase my followers by 12x in a year!</b><br/><br/>
  I started with 25k followers in 2021. I'm at 320k today!<br/><br/>
  My page's reach is now in the millions!<br/><br/>
  <b>Thank you, Post Planner!</b><br/><br/>`,
  role: ''
}]
