import React, { Component, Children } from 'react'
import { connect } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { LiveArticleData } from 'interfaces'
import { getArticleData } from 'services/content/articles/actions'
import { Subscription } from 'rxjs/Subscription'

export interface FetchArticleDataOwnProps {
  url: string
  children?: React.ReactNode
  dispatch: StoreThunkDispatch
  onFetched(data: LiveArticleData): void
}

type FetchArticleDataProps = FetchArticleDataOwnProps

export class FetchArticleData extends Component<FetchArticleDataProps, any> {
  private subscription: Subscription

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate() {
    this.subscription.unsubscribe()
    this.fetch()
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  shouldComponentUpdate(nextProps: FetchArticleDataProps) {
    return nextProps.url !== this.props.url
  }

  fetch = () => {
    this.subscription = getArticleData.results
      .filter(data => data.url === this.props.url || data.link === this.props.url)
      .subscribe(this.props.onFetched)

    getArticleData.fetch(this.props.url, this.props.dispatch)
  }

  render() {
    return this.props.children
      ? Children.only(this.props.children)
      : null
  }
}

export default connect(undefined)(FetchArticleData)
