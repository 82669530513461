import { InteractionTrackingSource, FileFolder } from 'interfaces'
import { feedAdapter, streamAdapter } from './recommended'
import { statusIdeaFeeds } from 'routes/find/routes/statuses/statuses.config'
import { QuoteFeed } from 'interfaces/Content/QuoteFeed'

export { statusIdeaFeeds }

export function recentSourcesAdapter(items: any[]) {
  const itemsFormatted: InteractionTrackingSource[] = []

  items.forEach(item => {
    switch (item.objectType) {
      case 'feeds':
        itemsFormatted.push(feedAdapter(item))
        break
      case 'streams':
        itemsFormatted.push(streamAdapter(item))
        break
      case 'status_idea_status_tag':
        const feed = statusIdeaFeeds.find(f => f.streamId === item.tagId.toString())
        if (feed) {
          itemsFormatted.push(feed)
        }
        break
      case 'my_library_folders':
        const folder: FileFolder = {
          id: item.folderId.toString(),
          name: item.folderName,
          color: item.folderColor,
          isFileFolder: true,
          filesCount: item.filesCount,
          files: {},
          order: item.order || 0,
          isPrivate: false,
          filters: [],
          featuredImage: item.featuredImage
        }
        itemsFormatted.push(folder)
        break
      case 'quote_feed':
        const quoteFeed: QuoteFeed = {
          ...item,
          name: item.term,
          id: item.term,
          isQuoteFeed: true
        }
        itemsFormatted.push(quoteFeed)
    }
  })

  return itemsFormatted
}
