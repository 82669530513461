import * as React from 'react'
import styles from './PinterestPostPreview.pcss'
import StarIcon from '@mui/icons-material/StarBorderRounded'

interface PinterestPostPreviewProps {
  imageUrl?: string
  text?: string
  empty?: boolean
}

export function PinterestPostPreview(props: PinterestPostPreviewProps) {
  return (
    <article className={`${!props.imageUrl ? styles['no-img'] : ''} ${props.empty ? styles.empty : ''} ${styles.pin}`}>
      <div className={styles['img-box']}>
        <img src={props.imageUrl} className={styles.image} />
        <div className={styles['icon-zoom']}></div>
      </div>
      <div className={styles['title-box']}>
        {props.text ? (
          <h3 className={styles.title}>{props.text}</h3>
        ) : (
          <div className={styles['text-empty-box']}>
            <div></div>
            <div></div>
          </div>
        )}
        <StarIcon className={styles.icon} />
      </div>
    </article>
  )
}
