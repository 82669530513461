import { POST_TYPE_POSTED, PostedPost, ContentType } from 'interfaces'
import { StoreThunkAction, StoreThunkDispatch } from 'store/state'
import { Observable } from 'rxjs/Observable'
import { V1 } from './net'
import { postsResponseAdapter } from '../adapters/posts'
import { mapPostsToBuckets } from './mapPostsToBuckets'
import { PostedPostsSearchParams } from 'shared/types'

/** FETCH */
export function getPostedPosts(params: PostedPostsSearchParams): StoreThunkAction<Observable<{
  posts: PostedPost[],
  showExternal: boolean,
  page: number,
  totalPostsCount: number,
  paging: { pages: number }
}>> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.getPostedPosts(params))
      .map((response) => {
        const posts = postsResponseAdapter<PostedPost>(response, POST_TYPE_POSTED)
        return {
          posts: dispatch(mapPostsToBuckets(posts)),
          showExternal: Boolean(response.showsPostedByExternalApps),
          page: params.page,
          paging: response.paging,
          totalPostsCount: response.postsCount
        }
      })
  }
}

export function fetchPostedPostsForRange(
  ppids: string[],
  startDate: string,
  endDate: string
): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.getPostedPostsForRange(new Date(startDate), new Date(endDate), ppids))
      .map(response => {
        const posts = postsResponseAdapter<PostedPost>(response)
        return {
          posts: dispatch(mapPostsToBuckets(posts)),
          totalPostsCount: response.postsCount
        }
      })
  }
}
