import * as React from 'react'
import parseUrlDomain from 'utils/format/parseUrlDomain'
import { PostPreviewDefaultHeader } from './PostPreviewDefaultHeader'
import styles from './TwitterPostPreview.pcss'
import RetweetIcon from '@mui/icons-material/Repeat'
import { PlayIcon } from 'components/Icons'
import { ImagesPreviewGrid } from './ImagesPreviewGrid'
import { PostPreviewVideoPlayer } from './PostPreviewVideoPlayer'
import { getTwitterHtmlFromText } from 'utils/composer'

interface TwitterPostPreviewProps {
  profile?: { name: string, image: string }
  status?: string
  tweetText?: string
  empty?: boolean
  emptyText?: boolean
  featuredImageUrl?: string
  imageUrls?: string[]
  retweet?: boolean
  quote?: boolean
  videoUrl?: string
  videoThumbnailUrl?: string
  isVideoPost?: boolean
  article?: {
    url: string
    title: string
    description?: string
    isVideo?: boolean
  }
}

export function TwitterPostPreview(props: TwitterPostPreviewProps) {
  const withContent = props.article || props.featuredImageUrl || props.videoUrl
  const additionalImages = props.imageUrls ? props.imageUrls.filter(url => url !== props.featuredImageUrl) : []
  const isEmpty = props.empty
    || props.emptyText
    || (!props.videoUrl && !props.status && !props.article && !props.featuredImageUrl && !props.quote && !props.retweet)

  const articleBlock = props.article && !props.article?.isVideo && (
    <div className={styles['article-box']}>
      <div className={`${styles.title} text-ellipsis`}>{props.article.title}</div>
      {props.article.description && (
        <div className={`${styles.description} text-ellipsis`}>{props.article.description}</div>
      )}
      <div className={styles.link}>{parseUrlDomain(props.article.url)}</div>
    </div>
  )

  let originalTweetText = props.tweetText
  if (!originalTweetText && (props.retweet || props.quote) && props.article?.isVideo) {
    originalTweetText = props.article?.title
  }

  const statusHtml = React.useMemo(() => {
    return getTwitterHtmlFromText(props.status || '')
  }, [props.status])

  return (
    <article className={`${styles.post} ${isEmpty ? styles.empty : ''}`}>
      {props.retweet && (
        <div className={styles.retweet}>
          <RetweetIcon />
          <span>You retweeted</span>
        </div>
      )}
      <PostPreviewDefaultHeader profile={props.retweet ? undefined : props.profile} />
      <main className={styles.main}>
        {props.emptyText && (
          <div className={styles['empty-text']}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
        {props.status && !props.retweet && (
          // eslint-disable-next-line react/no-danger
          <div className={`${styles.status} ${styles['status-tw']}`} dangerouslySetInnerHTML={{ __html: statusHtml }}></div>
        )}
        {props.retweet && originalTweetText && (
          <div className={styles.status}>{originalTweetText}</div>
        )}
        {isEmpty && !props.emptyText && (
          <div className={styles.content}>
            <div className={styles['img-box']}></div>
          </div>
        )}
        {withContent && !props.quote && (
          <div className={styles.content}>
            {!props.videoUrl && props.featuredImageUrl && additionalImages.length === 0 && (
              <div className={styles['img-box']}>
                <img src={props.featuredImageUrl} className={styles.image} />
                {props.isVideoPost && (
                  <PlayIcon className={`${styles['icon-centered']} ${styles.icon}`} />
                )}
              </div>
            )}
            {props.featuredImageUrl && additionalImages.length > 0 && (
              <ImagesPreviewGrid
                square
                featuredImageUrl={props.featuredImageUrl}
                imageUrls={additionalImages}
                className={styles['images-grid']}
              />
            )}
            {articleBlock}
            {!props.article && props.videoUrl && (
              <PostPreviewVideoPlayer thumbnailUrl={props.videoThumbnailUrl} videoUrl={props.videoUrl} />
            )}
          </div>
        )}
        {props.quote && (
          <div className={styles.quote}>
            <PostPreviewDefaultHeader />
            {originalTweetText && (
              <div className={styles['tweet-text']}>{originalTweetText}</div>
            )}
            {props.featuredImageUrl && (
              <div className={styles['tw-img']}>
                <img src={props.featuredImageUrl} />
                {props.article?.isVideo && (
                  <PlayIcon className={`${styles['icon-centered']} ${styles.icon}`} />
                )}
              </div>
            )}
            {articleBlock}
          </div>
        )}
      </main>
      <footer className={styles.footer}></footer>
    </article>
  )
}
