import * as React from 'react'
import styles from './ImagesPreviewGrid.pcss'

const MAX_IMAGES = 4

interface ImagesPreviewGridProps {
  featuredImageUrl: string
  imageUrls: string[]
  square?: boolean
  className?: string
}

export function ImagesPreviewGrid(props: ImagesPreviewGridProps) {
  const [isVertical, setIsVertical] = React.useState(false)

  React.useEffect(() => {
    const img = new Image()
    img.onload = () => {
      setIsVertical(img.naturalHeight > img.naturalWidth)
    }
    img.src = props.featuredImageUrl
  }, [props.featuredImageUrl])

  const imagesCount = props.imageUrls.length + 1

  let gridItemsClass = styles[`grid-${MAX_IMAGES}`]
  if (imagesCount < MAX_IMAGES) {
    gridItemsClass = styles[`grid-${imagesCount}`]
  }
  const squareClass = props.square ? styles.square : ''
  const verticalClass = isVertical ? styles.vertical : ''

  const hiddenImagesCount = imagesCount - MAX_IMAGES

  return (
    <div className={`${styles.grid} ${gridItemsClass} ${squareClass} ${verticalClass} ${props.className || ''}`}>
      <div className={`${styles.img} ${styles.featured}`} style={{ backgroundImage: `url(${props.featuredImageUrl})` }}></div>
      {props.imageUrls.slice(0, MAX_IMAGES - 1).map((src, index) => (
        <div key={src} className={styles.img} style={{ backgroundImage: `url(${src})` }}>
          {hiddenImagesCount > 0 && index + 1 === MAX_IMAGES - 1 && (
            <div className={styles.count}>
              <span>+{hiddenImagesCount}</span>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
