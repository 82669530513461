import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'

export const ENDPOINT_UPGRADE_SUBSCRIPTION = `${LEGACY_URL}/subscriptions/upgrade_to/:subscription`
export const ENDPOINT_END_TRIAL = `${LEGACY_URL}/v3/trial_events/end_cc_trial`

export const V1 = Object.freeze({
  upgrade: function () {
    const builder = new RequestBuilder(ENDPOINT_UPGRADE_SUBSCRIPTION).asPost().expectJSON()

    return function (handle: string) {
      return authorizedPipe<any>(
        builder
          .partial(':subscription', handle)
          .build()
      )
    }
  }(),
  endTrial: function () {
    const builder = new RequestBuilder(ENDPOINT_END_TRIAL).asPost().expectJSON()

    return function (handle: string) {
      return authorizedPipe<any>(
        builder
          .param('plan', handle)
          .build()
      )
    }
  }()
})
