import React from 'react'
import styles from './TrialWidget.pcss'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Paper from '@mui/material/Paper'
import { TrialTask } from 'routes/trial/interfaces'
import { FormattedMessage, useIntl } from 'react-intl'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import Icon from '@mdi/react'
import { mdiPlayCircle, mdiCheck, mdiClose } from '@mdi/js'
import { useDispatch, useSelector } from 'react-redux'
import { currentUserSelector, isTrialActiveSelector, trialTasksSelector } from 'services/users/selectors'
import { StoreThunkDispatch } from 'store/state'
import { getTrialTasks } from 'services/users/actions'
import { taskUrls, taskVideoUrls } from 'routes/trial/components/TrialTasksGroup'
import Dialog from '@mui/material/Dialog'
import EmbeddedYTPost from 'components/EmbeddedYTPost/EmbeddedYTPost'
import CircularProgress from '@mui/material/CircularProgress'
import { isTrialWidgetHiddenSelector } from 'services/ui/selectors'
import { setTrialWidgetHidden } from 'services/ui/actions'

const POPUP_ELEVATION = 8

export function TrialWidget() {
  const navigate = useNavigate()
  const isHidden = useSelector(isTrialWidgetHiddenSelector)
  const user = useSelector(currentUserSelector)
  const isTrial = useSelector(isTrialActiveSelector)
  const location = useLocation()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const intl = useIntl()
  const tasks = useSelector(trialTasksSelector)
  const completedTasks = tasks.filter(t => t.completed)
  const showWidget = !isHidden && completedTasks.length < tasks.length && !location.pathname.startsWith('/onboarding')
  const showCloseBtn = !isTrial && user?.account.hasChargifyInfo
  const [loading, setLoading] = React.useState(tasks.length === 0)
  const [anchor, setAnchor] = React.useState<HTMLDivElement | null>(null)
  // const [activeVideoUrl, setActiveVideoUrl] = React.useState<string | null>(null)
  // const [videoLoading, setVideoLoading] = React.useState(false)
  const buttonRef = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    if (location.state?.intro) {
      setAnchor(buttonRef.current)
    }
  }, [location.state?.intro])

  const hideWidget = (e: any) => {
    e.stopPropagation()
    dispatch(setTrialWidgetHidden(true))
  }

  // const openVideoPopup = (url: string) => {
  //   setActiveVideoUrl(url)
  //   setVideoLoading(true)
  // }

  // const closeVideoPopup = () => {
  //   setActiveVideoUrl(null)
  //   setVideoLoading(false)
  // }

  // const onVideoReady = () => {
  //   setVideoLoading(false)
  // }

  const fetchTasks = () => {
    dispatch(getTrialTasks())
      .unwrap()
      .catch((error) => {
        console.log('Failed to fetch trial info!', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (!showWidget) {
    return null
  }

  const openPopup = () => {
    setAnchor(buttonRef.current)
    fetchTasks()
  }

  const closePopup = () => {
    setAnchor(null)
  }

  const onClick = (code: string) => {
    const url = taskUrls[code]
    navigate(url)
  }

  // eslint-disable-next-line no-magic-numbers
  const completed = tasks.length > 0 ? Math.round((completedTasks.length / tasks.length) * 100) : 0

  return (
    <React.Fragment>
      {!loading && (
        <div className={styles.wrapper}>
          <div className={styles.btn} ref={buttonRef} onClick={openPopup}>
            <span className={styles.badge}></span>
            {showCloseBtn && (
              <span className={styles.close} onClick={hideWidget}>
                <Icon path={mdiClose} size="14px" />
              </span>
            )}
            <span>Get Started</span>
          </div>
        </div>
      )}
      <Popper
        open={Boolean(anchor)}
        anchorEl={anchor}
        placement="top"
        className={styles.popper}
      >
        <ClickAwayListener onClickAway={closePopup}>
          <Paper classes={{ root: styles.paper }} elevation={POPUP_ELEVATION}>
            <div className={styles['paper-inner']}>
              <div className={styles.header}>
                <h2 className={styles.title}>5 steps to get started</h2>
                <div className={styles['progress-box']}>
                  <span>{`${completed}%`}</span>
                  <span className={styles.progress}>
                    <div className={styles.bar} style={{ width: `${completed}%` }}></div>
                  </span>
                </div>
              </div>
              <div className={styles.content}>
                {tasks.map(t => (
                  <TaskRow
                    key={t.code}
                    task={t}
                    active={location.pathname.startsWith(taskUrls[t.code])}
                    onClick={onClick}
                  />
                ))}
              </div>
              <div className={styles.footer}>
                <NavLink to="/training">
                  <Icon path={mdiPlayCircle} size="30px" />
                  <span>All training videos</span>
                </NavLink>
              </div>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
      {/* <Dialog open={Boolean(activeVideoUrl)} onClose={closeVideoPopup}>
        <div className={styles['dialog-box']}>
          <div className={styles['player-box']}>
             {activeVideoUrl && <EmbeddedYTPost url={activeVideoUrl} onReady={onVideoReady} />}
            {videoLoading && (
              <div className={styles.loader}>
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </Dialog> */}
    </React.Fragment>
  )
}

interface TaskRowProps {
  task: TrialTask
  active?: boolean
  // onVideoClick?: (url: string) => void
  onClick: (code: string) => void
}

function TaskRow({ task, active, onClick }: TaskRowProps) {
  const click = () => {
    onClick(task.code)
  }

  const onPlayClick = () => {
    // e.preventDefault()
    // e.stopPropagation()
    // if (onVideoClick) {
    //   onVideoClick(task.videoUrl || '')
    // }
    window.open(taskVideoUrls[task.code], '_blank')
  }

  return (
    <div className={`${styles['task-box']} ${active ? styles.active : ''}`} key={task.code} onClick={click}>
      <div className={`${styles.number} ${task.completed ? styles.done : ''}`}>
        {task.completed ? <Icon path={mdiCheck} size="20px" /> : task.order}
      </div>
      <div className={styles.right}>
        <div className={styles.taskTitle}>
          <FormattedMessage id={`trial-page.group-title.${task.code}`} values={{ value: task.maxValue }} />
        </div>
        <div className={styles.subtitle}>
          <FormattedMessage id={`trial-page.group-subtitle.${task.code}`} />
        </div>
        <span className={styles['btn-play']} onClick={onPlayClick}>
          <Icon path={mdiPlayCircle} size="20px" />
          <span>Watch video</span>
        </span>
      </div>
    </div>
  )
}
