import * as React from 'react'
import styles from './Media.pcss'
import { useDispatch, useSelector } from 'react-redux'
import {
  addComposerImages, setComposerExternalFile, setComposerImages,
  setComposerVideoThumbnail, setComposerVideoThumbnailOffset, uploadPreview
} from 'services/compose'
import IconSearch from '@mui/icons-material/Search'
import { FormattedMessage, injectIntl } from 'react-intl'
import Button from '@mui/material/Button'
import { ConnectedFileInput } from 'components/FileHelpers/ConnectedFileInput'
import { ARTICLE_TYPE, BRAND_FACEBOOK, BRAND_TWITTER, GIF_TYPE, PHOTO_TYPE, VIDEO_TYPE, WithIntl } from 'interfaces'
import { TEXT_TYPE } from 'interfaces/Content/ContentType'
import { BRAND_TIKTOK } from 'interfaces/Content/SocialBrand'
import { ALLOWED_PHOTO_FORMATS, INPUT_ACCEPT_PHOTO, INPUT_ACCEPT_VIDEO } from 'utils/file'
import { noop } from 'utils/noop'
import { checkFeatureAvailability } from 'services/product'
import {
  ALLOWED_VIDEO_FORMATS,
  BRAND_INSTAGRAM,
  MAX_COMPOSER_PHOTOS_COUNT_FACEBOOK,
  MAX_COMPOSER_PHOTOS_COUNT_TWITTER,
  FEATURE_POST_MULTI_PHOTO,
  STOCK_VIDEO_TYPE
} from 'shared/constants'
import {
  composerAllLinksSelector,
  composerContentItemSelector,
  composerExternalFileSelector,
  composerImageUrlsSelector,
  composerIsUploadingSelector,
  composerTabsSelector,
  composerVideoUrlSelector,
  composerVideoThumbnailSelector
} from 'services/compose/selectors'
import { message } from 'services/snackbar'
import { addFiles, ComposerUploaderContext, removeFile, resetState } from 'components/Composer/UploadContext'
import ActionSelect from 'components/ActionSelect'
import { ContentStudioPopup } from 'components/ContentStudioPopup'
import CanvaButton from 'components/CanvaButton'
import MediaPreview from './MediaPreview'
import { ComposerContentPreview } from './ComposerContentPreview'
import { STOCK_PHOTO_TYPE, STOCK_GIF_TYPE } from 'interfaces/Content/StockContentTypes'
import MediaSearchPanel from 'components/Composer/MediaSearchPanel'
import PhotoIcon from '@mui/icons-material/InsertPhoto'
import Icon from '@mdi/react'
import { mdiPalette } from '@mdi/js'
import VideoThumbnailGenerator from './VideoThumbnailGenerator'
import { Dialog } from '@mui/material'
import { StoreThunkDispatch } from 'store/state'

export function Media(props: WithIntl) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const content = useSelector(composerContentItemSelector)
  const links = useSelector(composerAllLinksSelector)
  const videoUrl = useSelector(composerVideoUrlSelector)
  const videoThumbnail = useSelector(composerVideoThumbnailSelector)
  const imageUrls = useSelector(composerImageUrlsSelector)
  const networks = useSelector(composerTabsSelector)
  const externalFile = useSelector(composerExternalFileSelector)
  const [mediaPanelOpen, setMediaPanelOpen] = React.useState(false)
  const [studioPopupOpen, setStudioPopupOpen] = React.useState(false)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const { files, dispatch: uploaderDispatch } = React.useContext(ComposerUploaderContext)
  const isUploading = useSelector(composerIsUploadingSelector)
  const withFileUploadError = Object.values(files).find(f => Boolean(f.error)) !== undefined
  const videoUpload = Object.values(files).find(f => f.isVideo)

  React.useEffect(() => {
    if (content?.type === VIDEO_TYPE || content?.type === ARTICLE_TYPE || content?.type === STOCK_VIDEO_TYPE) {
      uploaderDispatch(resetState())
    }
  }, [content, uploaderDispatch])

  // EXPL: Remove videos from local state if images are added
  React.useEffect(() => {
    // NOTE: Wait for the state to be updated after video is selected (onFilesSelected)
    // This fixes some cases where the selected video is not added to the state
    setTimeout(() => {
      if (imageUrls.length > 0 && videoUpload) {
        if (videoUpload) {
          uploaderDispatch(removeFile(videoUpload.id))
        }
      }
    }, 50) // eslint-disable-line no-magic-numbers
  }, [imageUrls.length, uploaderDispatch, files, videoUpload])

  const hints = React.useMemo(() => {
    const hintsArray: string[] = []
    if (withFileUploadError) {
      hintsArray.push('composer.hints.generic.file-upload-failed')
    }
    if (isUploading) {
      hintsArray.push('composer.labels.uploading-files')
    }
    return hintsArray
  }, [isUploading, withFileUploadError])

  React.useEffect(() => {
    if (externalFile) {
      uploaderDispatch(addFiles([{ file: externalFile, isEdited: true, isVideo: externalFile.type.includes('video') }]))
    }
    dispatch(setComposerExternalFile(undefined))
  }, [dispatch, externalFile, uploaderDispatch])

  React.useEffect(() => {
    if (imageUrls.length > 1) {
      dispatch(checkFeatureAvailability(FEATURE_POST_MULTI_PHOTO, false, () => {
        uploaderDispatch(resetState())
        dispatch(setComposerImages([imageUrls[0]]))
      }))
    }
  }, [imageUrls, dispatch, uploaderDispatch])

  const closeMediaPanel = () => {
    setMediaPanelOpen(false)
  }

  const openMediaPanel = () => {
    setMediaPanelOpen(true)
  }

  const onFilesSelected = (selection: { [name: string]: File }) => {
    const filesTotalCount = Object.keys(selection).length
    if (filesTotalCount > 0) {
      const photos: File[] = []
      const videos: File[] = []

      Object.values(selection).forEach(file => {
        if (ALLOWED_PHOTO_FORMATS.includes(file.type)) {
          photos.push(file)
        } else if (ALLOWED_VIDEO_FORMATS.includes(file.type)) {
          videos.push(file)
        }
      })

      if (photos.length + videos.length === 0) {
        return
      }

      if (photos.length + videos.length < filesTotalCount) {
        dispatch(message(props.intl.formatMessage({ id: 'composer.notifications.dropped-some-invalid-files' }), 'warning'))
      }

      if (networks.includes(BRAND_TWITTER) && photos.length > MAX_COMPOSER_PHOTOS_COUNT_TWITTER) {
        dispatch(message(props.intl.formatMessage(
          { id: 'composer.notifications.tw-photos-count-limit-hit' },
          { count: MAX_COMPOSER_PHOTOS_COUNT_TWITTER }
        ), 'warning'))
      }

      if (networks.includes(BRAND_FACEBOOK) && photos.length > MAX_COMPOSER_PHOTOS_COUNT_FACEBOOK) {
        dispatch(message(props.intl.formatMessage(
          { id: 'composer.notifications.fb-photos-count-limit-hit' },
          { count: MAX_COMPOSER_PHOTOS_COUNT_FACEBOOK }
        ), 'warning'))
      }

      if (photos.length > 0 && videos.length > 0) {
        dispatch(message(props.intl.formatMessage({ id: 'composer.notifications.images-and-video' }), 'warning'))
        return
      }

      if (photos.length > 0) {
        const filesState = photos.map(photo => ({ file: photo }))
        uploaderDispatch(addFiles(filesState))
      } else if (videos.length > 1) {
        dispatch(message(props.intl.formatMessage({ id: 'composer.notifications.one-video-only' }), 'warning'))
      } else if (videos.length === 1) {
        dispatch(setComposerImages([]))
        uploaderDispatch(resetState())
        uploaderDispatch(addFiles([{ file: videos[0], isVideo: true }]))
      }
    }
  }

  const onCanvaImageReady = (url: string) => {
    dispatch(addComposerImages([url]))
  }

  const clearFiles = () => {
    uploaderDispatch(resetState())
  }

  const withImages = imageUrls.length > 0
    || content?.type === PHOTO_TYPE
    || content?.type === STOCK_PHOTO_TYPE
    || content?.type === GIF_TYPE
    || content?.type === STOCK_GIF_TYPE

  const openContentStudio = () => {
    setStudioPopupOpen(true)
  }

  const closeContentStudio = () => {
    setStudioPopupOpen(false)
  }

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen)
  }

  const thumbChosen = async (thumb: string, offset: number) => {
    const response = await fetch(thumb)
    const blob = await response.blob()
    const file = new File(
      [blob],
      'fileName.jpg',
      {
        type: 'image/jpeg',
        lastModified: Date.now()
      }
    )
    dispatch(uploadPreview(file, videoUrl as string)).subscribe(res => {
      dispatch(setComposerVideoThumbnail(res.url))
      dispatch(setComposerVideoThumbnailOffset(offset))
    })
    setDialogOpen(!dialogOpen)
  }
  const createActions = [{
    element: (
      <CanvaButton
        className={styles['btn-canva']}
        onGeneratedImage={onCanvaImageReady}
      />
    )
  }, {
    actionProps: {
      label: <FormattedMessage id="composer.labels.content-studio" />,
      value: 'studio',
      onClick: openContentStudio
    }
  }]

  const fileFormats = [...INPUT_ACCEPT_PHOTO, ...INPUT_ACCEPT_VIDEO].join(',')
  const showThumbnailPicker = videoUpload?.file && (networks.includes(BRAND_TIKTOK) || networks.includes(BRAND_INSTAGRAM))

  const thumbnailMessage = React.useMemo(() => {
    const withInstagram = networks.includes(BRAND_INSTAGRAM)
    const withTiktok = networks.includes(BRAND_TIKTOK)
    const networksLabel = withInstagram
      ? withTiktok ? 'Instagram and TikTok' : 'Instagram'
      : withTiktok ? 'TikTok' : ''
    return `Choose a video thumbnail for ${networksLabel}. Other networks will select one automatically.`
  }, [networks])

  return (
    <div data-testid="composer-media">
      <div className={styles['btn-box']}>
        <ConnectedFileInput
          accept={fileFormats}
          className={styles.action}
          openFileDialog={noop}
          setUploaderFiles={onFilesSelected}
        >
          <Button startIcon={<PhotoIcon />} className={styles['file-btn']}>
            <FormattedMessage id="composer.labels.upload-files" />
          </Button>
        </ConnectedFileInput>
        <ActionSelect
          name="Create"
          icon={<Icon path={mdiPalette} size="20px" />}
          actions={createActions}
          className={styles.action}
          popperProps={{ keepMounted: true }}
        />
        <Button startIcon={<IconSearch />} className={styles.action} onClick={openMediaPanel}>
          <FormattedMessage id="composer.labels.content-search-placeholder" />
        </Button>
      </div>
      <div className={styles['uploader-box']}>
        <MediaPreview className={styles['preview-box']} />
        {showThumbnailPicker && (
          <React.Fragment>
            <div className={styles['thumb-title']}>
              {thumbnailMessage}
            </div>
            <div className={styles['thumbnail-box']}>
              {videoThumbnail && <img src={videoThumbnail} />}
              <Button disabled={isUploading === 'video'} onClick={toggleDialog}>
                {`${videoThumbnail ? 'Change' : 'Choose'} thumbnail`}
              </Button>
            </div>
          </React.Fragment>
        )}
        {((content && content.type !== TEXT_TYPE) || links?.length > 0) && !withImages && (
          <ComposerContentPreview className={styles['content-preview']} />
        )}
      </div>
      <div className={styles.hints}>
        {hints.map(key => (
          <div key={key}>
            <FormattedMessage id={key} />
          </div>
        ))}
      </div>
      <MediaSearchPanel open={mediaPanelOpen} onVideoUrlAdded={clearFiles} onClose={closeMediaPanel} />
      <ContentStudioPopup open={studioPopupOpen} onClose={closeContentStudio} />
      {videoUpload?.file && (
        <Dialog open={dialogOpen}>
          <VideoThumbnailGenerator
            videoFile={videoUpload!.file}
            onClose={toggleDialog}
            onGenerateThumb={thumbChosen}
          />
        </Dialog>
      )}
    </div>
  )
}

export default injectIntl(Media)
