import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './state'
import {
  addMemberPermission,
  addTeamMember,
  getAccountInfo,
  getDefaults,
  getTeamSettings,
  removeMemberPermission,
  removeTeamMember,
  updateDefaults
} from './actions'

export * from './actions'
export * from './state'
export * from './selectors'

const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccountInfo.fulfilled, (state, action) => {
      state.account = action.payload as any
    })

    builder.addCase(getDefaults.fulfilled, (state, action) => {
      const defaults = action.payload as any
      state.defaults = {
        profiles: defaults.pages.filter((p: any) => p.default === 1),
        lists: defaults.lists,
        timezone: defaults.timezone,
        timezones: defaults.timezones
      }
    })

    builder.addCase(getTeamSettings.fulfilled, (state, action) => {
      state.team = action.payload as any
    })

    builder.addCase(updateDefaults.fulfilled, (state, action) => {
      const { profileIds, timezone } = action.payload
      state.defaults.profiles = state.defaults.profiles.filter(profile => profileIds.includes(profile.id))
      state.defaults.timezone = timezone
    })

    builder.addCase(addTeamMember.fulfilled, (state, action) => {
      if (state.team) {
        const newMember = action.payload
        state.team.members[newMember.adminId] = newMember as any
      }
    })

    builder.addCase(removeTeamMember.fulfilled, (state, action) => {
      if (state.team) {
        delete state.team.members[action.payload]
      }
    })

    builder.addCase(addMemberPermission.fulfilled, (state, action) => {
      if (state.team) {
        const addProfileId = action.payload.ppid
        state.team.members[action.payload.memberId].accounts[addProfileId] = action.payload.permissionId
      }
    })

    builder.addCase(removeMemberPermission.fulfilled, (state, action) => {
      if (state.team) {
        delete state.team.members[action.payload.memberId].accounts[action.payload.ppid]
      }
    })
  }
})

export default settingsSlice.reducer
