import { PendingTreat } from './interfaces'
import { SnackType } from './interfaces/PendingTreat'

export const ACTION_QUEUE_TREAT = 'ACTION_QUEUE_TREAT'
export const ACTION_NEXT_TREAT = 'ACTION_NEXT_TREAT'

type QueueTreat = { type: typeof ACTION_QUEUE_TREAT, payload: PendingTreat }
type NextTreat = { type: typeof ACTION_NEXT_TREAT }

export type SnackbarAction = QueueTreat
  | NextTreat

export function queue(payload: PendingTreat): QueueTreat {
  return {
    type: ACTION_QUEUE_TREAT,
    payload
  }
}

export function next(): NextTreat {
  return {
    type: ACTION_NEXT_TREAT
  }
}

export function message(message: string, type?: SnackType, timeout?: number, immediate?: boolean) {
  return queue({ content: message, timeout, type, immediate })
}

export function action(type: SnackType, message: string, label: string, actionFn: any, timeout?: number) {
  return queue({ content: message, timeout, type, action: actionFn, label })
}

export function warning(message: string, label: string, actionFn: Function, timeout: number) {
  return action('warning', message, label, actionFn, timeout)
}
