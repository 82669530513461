import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import ContentState from '../state'
import { deleteStream, getStream, getStreams, getUserStream } from './actions'
import { orderComparator } from 'shared/utils'

export const streamsExtraReducers = (builder: ActionReducerMapBuilder<ContentState>) => {
  builder
    .addCase(getStreams.fulfilled, (state, action) => {
      state.user.streams = [...action.payload].sort(orderComparator)
    })
    .addCase(deleteStream.fulfilled, (state, action) => {
      const streamId = action.payload
      const streamIndex = state.user.streams.findIndex(s => s.id === streamId)
      if (streamIndex !== -1) {
        state.user.streams.splice(streamIndex, 1)
      }
      delete state.streams[streamId]
      Object.values(state.recentSources).forEach((sources: any) => {
        sources = sources.filter((s: any) => s.id !== action.payload)
      })
    })
    .addCase(getStream.fulfilled, (state, action) => {
      state.streams[action.payload.id] = action.payload
    })
    .addCase(getUserStream.fulfilled, (state, action) => {
      const stream = action.payload
      const index = state.user.streams.findIndex(s => s.id === stream.id)
      if (index !== -1) {
        state.user.streams[index] = stream
      } else {
        state.user.streams.push(stream)
      }
    })
}
