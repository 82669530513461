import { Component } from 'react'
import { connect } from 'react-redux'
import {
  Stream,
  Photo,
  Article,
  Video,
  StatusIdea,
  AnyContent,
  Gif,
  ContentType,
  ARTICLE_TYPE,
  PHOTO_TYPE,
  STATUS_TYPE,
  VIDEO_TYPE,
  GIF_TYPE
} from 'interfaces'
import { getPhotos } from 'services/content/photos/actions'
import { getArticles } from 'services/content/articles/actions'
import { getVideos } from 'services/content/videos/actions'
import { getGifs } from 'services/content/gifs/actions'
import { getTexts } from 'services/content/texts/actions'
import { getStatusIdeas } from 'services/content/statuses/actions'
import { StoreState, StoreThunkDispatch } from 'store/state'
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/of'
import 'rxjs/add/observable/empty'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/mergeAll'
import 'rxjs/add/operator/toArray'
import 'rxjs/add/operator/observeOn'
import 'rxjs/add/operator/defaultIfEmpty'
import { animationFrame } from 'rxjs/scheduler/animationFrame'
import { Subscription } from 'rxjs/Subscription'
import { createHandledPipe } from './FetchError'
import { TEXT_TYPE } from 'interfaces/Content/ContentType'

export interface FetchStreamContentProps {
  stream: Stream
  hash?: string

  onFetched(content: AnyContent[]): void
  onFailed?(err: any): void
}

export interface ConnectedFetchStreamContentProps extends FetchStreamContentProps {
  hash: string
  only?: ContentType

  getVideos(): Observable<Video>
  getPhotos(): Observable<Photo>
  getArticles(): Observable<Article>
  getGifs(): Observable<Gif>
  getTexts(): Observable<any>
  getStatusIdeas(source: string[]): Observable<StatusIdea>
}

export class FetchStreamContent extends Component<ConnectedFetchStreamContentProps, any> {

  private subscription: Subscription

  constructor(props: ConnectedFetchStreamContentProps) {
    super(props)

    this.fetch = this.fetch.bind(this)
    this.onFetched = this.onFetched.bind(this)
    this.onFailed = this.onFailed.bind(this)
  }

  shouldComponentUpdate(nextProps: ConnectedFetchStreamContentProps) {
    return nextProps.stream.id !== this.props.stream.id
      || nextProps.hash !== this.props.hash
  }

  onFetched(content: AnyContent[]) {
    this.props.onFetched(content)
  }

  onFailed(err: any) {
    console.log(err)
    if (this.props.onFailed) {
      this.props.onFailed(err)
    }
  }

  fetch() {
    const filters = this.props.stream.filters
    const only = this.props.only
    const types = filters && filters.length ? filters : [PHOTO_TYPE, ARTICLE_TYPE, VIDEO_TYPE]

    const skipVideos = only && only !== VIDEO_TYPE
    const skipPhotos = only && only !== PHOTO_TYPE
    const skipArticles = only && only !== ARTICLE_TYPE
    const skipGifs = only && only !== GIF_TYPE
    const skipStatuses = only && only !== STATUS_TYPE
    const skipTexts = only && only !== TEXT_TYPE

    const fetchPhotos = !skipPhotos && types.includes(PHOTO_TYPE)
    const fetchArticles = !skipArticles && types.includes(ARTICLE_TYPE)
    const fetchVideos = !skipVideos && types.includes(VIDEO_TYPE)
    const fetchGifs = !skipGifs && types.includes(GIF_TYPE)
    const fetchStatuses = !skipStatuses && types.includes(STATUS_TYPE)
    const fetchTexts = !skipTexts && types.includes(TEXT_TYPE)

    const requests = ([] as Observable<AnyContent>[])
      .concat(fetchPhotos ? [createHandledPipe(PHOTO_TYPE, this.props.getPhotos(), this.onFailed)] : [])
      .concat(fetchArticles ? [createHandledPipe(ARTICLE_TYPE, this.props.getArticles(), this.onFailed)] : [])
      .concat(fetchVideos ? [createHandledPipe(VIDEO_TYPE, this.props.getVideos(), this.onFailed)] : [])
      .concat(fetchGifs ? [createHandledPipe(GIF_TYPE, this.props.getGifs(), this.onFailed)] : [])
      .concat(fetchStatuses ? [createHandledPipe(STATUS_TYPE, this.props.getStatusIdeas([]), this.onFailed)] : [])
      .concat(fetchTexts ? [createHandledPipe(TEXT_TYPE, this.props.getTexts(), this.onFailed)] : [])

    return Observable.of(...requests)
      .mergeAll()
      .toArray()
      .observeOn(animationFrame)
      .subscribe(this.onFetched, this.onFailed)
  }

  componentDidMount() {
    this.subscription = this.fetch()
  }

  componentDidUpdate() {
    this.subscription.unsubscribe()
    this.subscription = this.fetch()
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    return null
  }
}

function mapStateToProps(_state: StoreState, ownProps: FetchStreamContentProps) {
  const hash = ownProps.hash || ''

  return {
    ...ownProps,
    hash
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch, ownProps: FetchStreamContentProps) {
  const { stream } = ownProps
  const id = stream.originalId || stream.id

  return {
    getVideos: () => dispatch(getVideos(id, true, undefined, undefined))
      .flatMap(v => Observable.of(...v.items)),
    getPhotos: () => dispatch(getPhotos(id, true, undefined, undefined))
      .flatMap(p => Observable.of(...p.items)),
    getArticles: () => dispatch(getArticles(id, true, undefined, undefined))
      .flatMap(a => Observable.of(...a.items)),
    getGifs: () => dispatch(getGifs(id, true, undefined, undefined))
      .flatMap(a => Observable.of(...a.items)),
    getTexts: () => dispatch(getTexts(id, true, undefined, undefined))
      .flatMap(a => Observable.of(...a.items)),
    getStatusIdeas: (categoryIds: Array<string>) => {
      if (categoryIds.length === 0) {
        return Observable.empty()
      }
      return dispatch(getStatusIdeas(categoryIds)).flatMap(s => Observable.of(...s.items))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FetchStreamContent as any)
