import {
  PHOTO_TYPE,
  VIDEO_TYPE,
  FEED_TYPE_TWITTER,
  FEED_TYPE_INSTAGRAM,
  FEED_TYPE_LINKEDIN,
  FEED_TYPE_RSS,
  ALL_RANGE_FILTER,
  MONTH_RANGE_FILTER,
  YEAR_RANGE_FILTER,
  GIF_TYPE,
  FEED_TYPE_REDDIT,
  IndexedObject
} from 'interfaces'
import {
  ARTICLE_TYPE,
  TEXT_TYPE,
  STOCK_GIF_TYPE,
  STOCK_PHOTO_TYPE,
  STOCK_VIDEO_TYPE,
  FEATURE_CONTENT_TYPE_PHOTO,
  FEATURE_CONTENT_AGE_ALL,
  FEATURE_CONTENT_AGE_MONTH,
  FEATURE_CONTENT_AGE_YEAR,
  FEATURE_CONTENT_ON_DEMAND,
  FEATURE_CONTENT_SOURCE_INSTAGRAM,
  FEATURE_CONTENT_SOURCE_LINKEDIN,
  FEATURE_CONTENT_SOURCE_REDDIT,
  FEATURE_CONTENT_SOURCE_RSS,
  FEATURE_CONTENT_SOURCE_TWITTER,
  FEATURE_CONTENT_TYPE_ARTICLE,
  FEATURE_CONTENT_TYPE_GIF,
  FEATURE_CONTENT_TYPE_STOCK,
  FEATURE_CONTENT_TYPE_TEXT,
  FEATURE_CONTENT_TYPE_VIDEO,
  FEATURE_FIND_PRIVATE_STREAMS,
  FEATURE_POST_IG_FIRST_COMMENT,
  FEATURE_POST_TAGGING,
  LIMIT_MY_LIBRARY_STORAGE,
  LIMIT_POSTS_PER_DAY_COUNT,
  LIMIT_POST_DRAFTS,
  LIMIT_PROFILES_COUNT,
  LIMIT_SAVED_TEXTS,
  LIMIT_SCHEDULED_POSTS_COUNT,
  LIMIT_USERS_COUNT
} from 'shared/constants'

export const contentTypeFeatureMap: { [type: string]: string } = {
  [PHOTO_TYPE]: FEATURE_CONTENT_TYPE_PHOTO,
  [VIDEO_TYPE]: FEATURE_CONTENT_TYPE_VIDEO,
  [GIF_TYPE]: FEATURE_CONTENT_TYPE_GIF,
  [TEXT_TYPE]: FEATURE_CONTENT_TYPE_TEXT,
  [STOCK_VIDEO_TYPE]: FEATURE_CONTENT_TYPE_STOCK,
  [STOCK_GIF_TYPE]: FEATURE_CONTENT_TYPE_STOCK,
  [STOCK_PHOTO_TYPE]: FEATURE_CONTENT_TYPE_STOCK,
  [ARTICLE_TYPE]: FEATURE_CONTENT_TYPE_ARTICLE
}

export const feedTypeFeatureMap: { [type: string]: string } = {
  [FEED_TYPE_TWITTER]: FEATURE_CONTENT_SOURCE_TWITTER,
  [FEED_TYPE_RSS]: FEATURE_CONTENT_SOURCE_RSS,
  [FEED_TYPE_INSTAGRAM]: FEATURE_CONTENT_SOURCE_INSTAGRAM,
  [FEED_TYPE_LINKEDIN]: FEATURE_CONTENT_SOURCE_LINKEDIN,
  [FEED_TYPE_REDDIT]: FEATURE_CONTENT_SOURCE_REDDIT
}

export const rangeFeatureMap: { [type: string]: string } = {
  [MONTH_RANGE_FILTER]: FEATURE_CONTENT_AGE_MONTH,
  [YEAR_RANGE_FILTER]: FEATURE_CONTENT_AGE_YEAR,
  [ALL_RANGE_FILTER]: FEATURE_CONTENT_AGE_ALL
}

export const CONTENT_BLOCKING_FEATURE_KEYS = [
  FEATURE_CONTENT_TYPE_PHOTO,
  FEATURE_CONTENT_TYPE_TEXT,
  FEATURE_CONTENT_TYPE_STOCK,
  FEATURE_CONTENT_TYPE_VIDEO,
  FEATURE_CONTENT_TYPE_GIF,
  FEATURE_CONTENT_TYPE_ARTICLE,
  FEATURE_CONTENT_AGE_ALL,
  FEATURE_CONTENT_AGE_YEAR,
  FEATURE_CONTENT_AGE_MONTH,
  FEATURE_CONTENT_SOURCE_TWITTER,
  FEATURE_CONTENT_SOURCE_RSS,
  FEATURE_CONTENT_SOURCE_REDDIT
]

export const UPGRADE_EVENT_TYPES_MAP: IndexedObject<string> = {
  [FEATURE_POST_TAGGING]: 'tagging',
  [FEATURE_POST_IG_FIRST_COMMENT]: 'first_comment',
  [FEATURE_CONTENT_TYPE_TEXT]: 'content_texts',
  [FEATURE_CONTENT_TYPE_PHOTO]: 'content_images',
  [FEATURE_CONTENT_TYPE_VIDEO]: 'content_videos',
  [FEATURE_CONTENT_TYPE_ARTICLE]: 'content_articles',
  [FEATURE_CONTENT_TYPE_GIF]: 'content_gifs',
  [FEATURE_CONTENT_AGE_MONTH]: 'content_month',
  [FEATURE_CONTENT_AGE_YEAR]: 'content_year',
  [FEATURE_CONTENT_AGE_ALL]: 'content_5years',
  [FEATURE_CONTENT_ON_DEMAND]: 'content_ondemand',
  [FEATURE_FIND_PRIVATE_STREAMS]: 'private_streams',
  [LIMIT_PROFILES_COUNT]: 'limit_profiles',
  [LIMIT_SCHEDULED_POSTS_COUNT]: 'limit_posts',
  [LIMIT_POSTS_PER_DAY_COUNT]: 'limit_posts_per_day',
  [LIMIT_USERS_COUNT]: 'limit_users',
  [LIMIT_POST_DRAFTS]: 'limit_drafts',
  [LIMIT_MY_LIBRARY_STORAGE]: 'limit_data',
  [LIMIT_SAVED_TEXTS]: 'limit_saved_texts'
}
