import { Component, createElement, ReactElement } from 'react'
import { Stream, Feed } from 'interfaces'
import StreamViewNavigation from './StreamViewNavigation'
import SaveButton from 'components/SaveButton'
import { FormattedMessage } from 'react-intl'
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight'
import { Link } from 'react-router-dom'

import styles from './StreamViewHeader.pcss'

export type StreamViewHeaderProps = {
  stream: Stream,
  isSaved: boolean
  saving?: boolean
  navigation: StreamViewNavigation,
  showBackLink?: boolean
  hideSaveButton?: boolean
  customButton?: ReactElement
  activeFeed?: Feed
  location?: {
    search?: string
    state?: any
  }

  onBackClick?(): void
  onSave?(stream: Stream): void
}

interface StreamViewHeaderState {
  saved: boolean
}

export class StreamViewHeader extends Component<StreamViewHeaderProps, StreamViewHeaderState> {

  constructor(props: StreamViewHeaderProps) {
    super(props)

    this.state = { saved: this.props.isSaved }
  }

  shouldComponentUpdate(nextProps: StreamViewHeaderProps) {
    return nextProps.stream !== this.props.stream
      || nextProps.navigation !== this.props.navigation
      || nextProps.isSaved !== this.props.isSaved
      || nextProps.customButton !== this.props.customButton
      || nextProps.saving !== this.props.saving
      || nextProps.activeFeed !== this.props.activeFeed
  }

  onSave = () => {
    if (this.props.onSave) {
      this.props.onSave(this.props.stream)
    }
  }

  renderFeedBreadcrumb = () => {
    const feed = this.props.activeFeed
    if (!feed) {
      return []
    }

    return [
      createElement(ArrowIcon, { key: 'arrow-icon', className: styles['arrow-icon'] }),
      createElement('h2', {
        key: 'feed-name',
        title: feed.name,
        className: `${styles.title} text-ellipsis`
      }, createElement(Link, { key: 'feed-link', to: `/content/feeds/${feed.uniqueSource}` }, feed.name))
    ]
  }

  renderStreamName = () => {
    const stream = this.props.stream
    const locationProps = this.props.location
    return createElement('h2', { key: 'title', title: stream.title, className: `${styles.title} ${styles['stream-title']} text-ellipsis` },
      this.props.activeFeed
        ? createElement(Link, {
          key: 'stream-link',
          to: {
            pathname: this.props.navigation.prefix,
            search: locationProps?.search
          },
          state: locationProps?.state
        }, stream.title)
        : stream.title
    )
  }

  render() {
    const { onBackClick, showBackLink, isSaved, hideSaveButton, customButton, saving } = this.props

    return createElement('header', { className: styles.header, key: 'header' }, [
      hideSaveButton || this.state.saved
        ? null
        : createElement(SaveButton, {
          key: 'save-btn',
          saved: isSaved,
          loading: saving,
          className: styles['btn-save'],
          onClick: this.onSave
        }),
      this.renderStreamName(),
      this.renderFeedBreadcrumb(),
      customButton || null,
      showBackLink ? createElement('span', { key: 'back', onClick: onBackClick, className: styles.navigation },
        createElement(FormattedMessage, { id: 'general.carousel.nav.back', defaultMessage: 'back' })
      ) : null
    ])
  }
}

export default StreamViewHeader
