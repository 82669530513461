import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { message } from 'services/snackbar'
import { isEmailValid } from 'utils/email'
import { SignupContext } from '../state/context'
import styles from './CreateAccountForm.pcss'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { createAccount } from 'services/checkout'
import { setAccount } from '../state/actions'
import { StoreThunkDispatch } from 'store/state'
import { parse } from 'query-string'
import PromiseFB from 'services/auth/identities/facebook/promisify'
import { CheckoutProgress } from '../CheckoutProgress'
import { currentUserSelector } from 'services/users/selectors'

type SecurityCode = { required: boolean, value: string }
const MSG_VERIFY_EMAIL = `We've sent a verification code to your email. Please enter it and click the button below to continue.`
const ERROR_MSG_DEFAULT = 'Something went wrong! Please refresh the page and try again.'

export function CreateAccountForm() {
  const dataLayer = (window as any).dataLayer || [] // Google Tag Manager dataLayer
  const dispatch = useDispatch<StoreThunkDispatch>()
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [{ signupViewConfig }, signupDispatch] = useContext(SignupContext)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [securityCode, setSecurityCode] = useState<SecurityCode>({ required: false, value: '' })
  const user = useSelector(currentUserSelector)

  const queryParams = parse(location.search)
  const productHandle = queryParams.plan?.toString() || ''
  const teamHash = queryParams.team_hash?.toString() || ''

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  const goToLogin = (ott?: string) => {
    navigate(`/login${ott ? '?ott=' + ott : ''}`)
  }

  const trackSubmit = (type: 'no_cc_trial' | 'cc_trial') => {
    dataLayer.push({
      'event': 'pp.form_submission',
      'event_parameters': {
        'form': 'create_account',
        'plan': productHandle,
        'trial_type': type
      }
    })
  }

  const reCaptchaVerify = useCallback(() => {
    if ((window as any).Cypress) {
      return Promise.resolve('test-cypress-recaptcha-token')
    }
    if (!executeRecaptcha) {
      dispatch(message('Something went wrong! Please refresh the page and try again.', 'error'))
      return Promise.reject(null)
    }
    return executeRecaptcha('submit')
  }, [executeRecaptcha, dispatch])

  // If user is logged in, redirect to home
  if (user) {
    return <Navigate to="/" />
  }

  const onAccountCreated = (response: any, account: any) => {
    if (response.completed) {
      trackSubmit('no_cc_trial')
      goToLogin(response.loginOtt)
    } else if (response.completed === false) {
      signupDispatch(setAccount({ ...account, ...response }))
      trackSubmit('cc_trial')
      // Wait for context to be updated before going to next step
      // Also Fixes issues with navigation in Firefox
      setTimeout(() => {
        navigate('/signup/payment-details')
      }, 150) // eslint-disable-line no-magic-numbers
    } else {
      const msg = response.error?.message || ERROR_MSG_DEFAULT
      dispatch(message(msg, 'error'))
      setLoading(false)
    }
  }

  const signupWithFacebook = async () => {
    setLoading(true)
    try {
      const captchaToken = await reCaptchaVerify()
      if (!captchaToken) {
        dispatch(message('ReCaptcha verification failed. Please try again.', 'error'))
        return
      }
      await PromiseFB.login()
      const userInfo: any = await PromiseFB.api('/me', { fields: 'first_name, last_name, email, id' })
      const { first_name: fName, last_name: lName, email, id } = userInfo
      if (!fName || !lName || !email || !id) {
        dispatch(message('We could not get your info from Facebook. Please fill in the form.', 'error'))
        return
      }
      const mail = email.trim()
      const response = await dispatch(createAccount(fName, lName, mail, captchaToken, productHandle, id, securityCode.value)).toPromise()
      if (response.error?.code === 'email-verification-required') {
        setSecurityCode({ required: true, value: '' })
        return
      } if (response.error?.code === 'wrong-security-code') {
        dispatch(message('Wrong security code! Please try again.', 'error'))
        return
      }
      const account = {
        firstName: fName,
        lastName: lName,
        email: mail,
        productHandle
      }
      onAccountCreated(response, account)
    } catch (e) {
      const msg = e?.response?.error?.message || ''
      dispatch(message('An error occurred. Please try again. ' + msg, 'error'))
      setLoading(false)
    }
  }

  const submit = async () => {
    try {
      const captchaToken = await reCaptchaVerify()
      if (!captchaToken) {
        dispatch(message('ReCaptcha verification failed. Please try again.', 'error'))
        return
      }

      const fName = firstName.trim()
      const lName = lastName.trim()
      if (!fName || !lName) {
        dispatch(message('Please enter your first and last name.', 'warning'))
        return
      }
      if (!isEmailValid(email)) {
        dispatch(message('Please enter a valid email.', 'warning'))
        return
      }

      setLoading(true)
      const mail = email.trim()
      const response = await dispatch(
        createAccount(fName, lName, mail, captchaToken, productHandle, undefined, securityCode.value, teamHash)
      ).toPromise()
      if (response.error?.code === 'email-verification-required') {
        setSecurityCode({ required: true, value: '' })
        setLoading(false)
        return
      } if (response.error?.code === 'wrong-security-code') {
        dispatch(message('Wrong security code! Please try again.', 'error'))
        return
      }
      const account = {
        firstName: fName,
        lastName: lName,
        email: mail,
        productHandle
      }
      onAccountCreated(response, account)
    } catch (e) {
      const msg = e?.response?.error?.message || ''
      dispatch(message('An error occurred. Please try again. ' + msg, 'error'))
      setLoading(false)
    }
  }

  const title = productHandle ? signupViewConfig.title : signupViewConfig.titleNoCC

  return (
    <div>
      <CheckoutProgress activeStep="create-account" />
      <div className={styles.layout}>
        <h1 className={styles.title} style={signupViewConfig.titleStyles}>{title}</h1>
        <div className={styles.form}>
          <div className={styles.row}>
            <div className={styles['flex-50']}>
              <TextField
                value={firstName}
                variant="outlined"
                name="firstName"
                placeholder={signupViewConfig.firstNamePlaceholder}
                className={styles.input}
                onChange={(e) => { setFirstName(e.target.value) }}
              />
            </div>
            <div className={styles['flex-50']}>
              <TextField
                value={lastName}
                variant="outlined"
                name="lastName"
                placeholder={signupViewConfig.lastNamePlaceholder}
                className={styles.input}
                onChange={(e) => { setLastName(e.target.value) }}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles['flex-100']}>
              <TextField
                value={email}
                type="email"
                variant="outlined"
                placeholder={signupViewConfig.emailPlaceholder}
                className={styles.input}
                onChange={(e) => { setEmail(e.target.value) }}
              />
            </div>
          </div>
          {securityCode.required && (
            <div className={styles.row}>
              <div className={styles['flex-100']}>
                <div className={styles.warning}>
                  {MSG_VERIFY_EMAIL}
                </div>
                <TextField
                  value={securityCode.value}
                  type="text"
                  name="securityCode"
                  variant="outlined"
                  placeholder="Verification code"
                  className={styles.input}
                  onChange={(e) => { setSecurityCode({ required: true, value: e.target.value }) }}
                />
              </div>
            </div>
          )}
          <div className={styles.actions}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={signupViewConfig.mainButtonStyles}
              className={styles['btn-submit']}
              disabled={loading}
              data-testid="btn-create-account"
              onClick={submit}
            >
              {signupViewConfig.mainButtonLabel}
            </Button>
          </div>
        </div>
        <div className={styles.divider}>
          <span>OR</span>
        </div>
        <div className={styles.footer}>
          <Button
            variant="contained"
            size="large"
            className={styles['btn-fb']}
            disabled={loading}
            style={signupViewConfig.facebookButtonStyles}
            onClick={signupWithFacebook}
          >
            Connect with Facebook
          </Button>
          <div className={styles['login-box']}>
            <span>Already have an account?</span>
            <span role="button" className={styles['btn-login']} onClick={() => goToLogin()}>Log in</span>
          </div>
        </div>
      </div>
    </div>
  )
}
