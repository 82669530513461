import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ConfirmDialog from 'components/ConfirmDialog'
import { RecyclerIcon } from 'components/Icons'
import DeleteIcon from '@mui/icons-material/Close'
import CopyIcon from '@mui/icons-material/FileCopyOutlined'
import SettingsIcon from '@mui/icons-material/Tune'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styles from './MultiPostBulkActions.pcss'

interface MultiPostBulkActionsProps {
  allSelected: boolean
  disabled: boolean
  onToggleAllSelected: () => void
  onDelete: () => void
  onRecycle: () => void
  onApplyGenericStatus: () => void
  onEditNetworkSettings: () => void
}

export default function MultiPostBulkActions(props: MultiPostBulkActionsProps) {
  const intl = useIntl()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const openBulkActionsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget as any)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const onDelete = () => {
    props.onDelete()
    closeMenu()
  }

  const applyGenericStatus = () => {
    props.onApplyGenericStatus()
    closeMenu()
  }

  const onRecycle = () => {
    props.onRecycle()
    closeMenu()
  }

  const onEditNetworkSettings = () => {
    props.onEditNetworkSettings()
    closeMenu()
  }

  return (
    <div className={styles.container}>
      <Button
        className={styles['btn-actions']}
        disabled={props.disabled}
        onClick={openBulkActionsMenu}
      >
        <FormattedMessage id="post.actions.select-bulk-action" />
      </Button>
      <Checkbox
        checked={props.allSelected}
        color="primary"
        onChange={props.onToggleAllSelected}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        classes={{ paper: styles.menu }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={closeMenu}
        PopoverClasses={{ paper: styles.menu }}
      >
        <ConfirmDialog
          message={<FormattedMessage id="label.generic.are-you-sure" />}
          labelOK={<FormattedMessage id="actions.confirm" />}
        >
          {(confirm) => (
            <MenuItem className={styles['menu-item']} onClick={confirm(applyGenericStatus)}>
              <CopyIcon className={styles.icon} fontSize="small" />
              <FormattedMessage id={`composer.actions.multi-post.copy-status-${props.allSelected ? 'all' : 'selected'}`} />
            </MenuItem>
          )}
        </ConfirmDialog>
        <MenuItem key="settings" className={styles['menu-item']} onClick={onEditNetworkSettings}>
          <SettingsIcon className={styles.icon} fontSize="small" />
          <FormattedMessage id={`composer.actions.multi-post.network-settings-${props.allSelected ? 'all' : 'selected'}`} />
        </MenuItem>
        <MenuItem key="recycle" className={styles['menu-item']} onClick={onRecycle}>
          <RecyclerIcon style={{ width: '20px', height: '20px' }} className={styles.icon} />
          <FormattedMessage id={`post.actions.recycle-${props.allSelected ? 'all' : 'selected'}`} />
        </MenuItem>
        <ConfirmDialog
          message={<FormattedMessage id="post.delete-bulk-confirm" />}
          labelOK={<FormattedMessage id="actions.confirm" />}
        >
          {(confirm) => (
            <MenuItem className={styles['menu-item']} onClick={confirm(onDelete)}>
              <DeleteIcon className={styles.icon} fontSize="small" />
              <FormattedMessage id={props.allSelected ? 'post.actions.delete-all' : 'post.actions.delete-selected'} />
            </MenuItem>
          )}
        </ConfirmDialog>
      </Menu>
    </div>
  )
}
