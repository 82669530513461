import * as React from 'react'
import { UserQuoteData, userQuotes } from './userQuotes'

import styles from './UserQuotePanel.pcss'
const quoteIcon = require('static/img/login/signup-sidebar-quotemark.png')

export function UserQuotePanel(props: { data: UserQuoteData }) {
  const { title, name, website, role, websiteUrl, imageUrl, quote } = props.data

  return (
    <div className={styles.container}>
      <img className={styles.img} alt={name} src={imageUrl} />
      <div className={styles.content}>
        {/* <h3 className={styles.title}>{title}</h3> */}
        <img src={quoteIcon} className={styles['icon-quote']} />
        <p className={styles.quote} dangerouslySetInnerHTML={{ __html: quote }}></p>
        <h5 className={styles.name}>{name}</h5>
        <p className={styles.role}>{role}</p>
        <a className={styles.link} href={websiteUrl} target="_blank" rel="noopener">{website}</a>
      </div>
    </div>
  )
}

export function RandomUserQuote() {
  // eslint-disable-next-line no-magic-numbers
  const quote = userQuotes[3] // Show only Joshua Becker for now. [Math.floor(Math.random() * userQuotes.length)]
  return <UserQuotePanel data={quote} />
}
