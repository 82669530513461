import * as React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import Paper from '@mui/material/Paper'
import { MyFile, WithIntl, PHOTO_TYPE, VIDEO_TYPE, GIF_TYPE } from 'interfaces'
import GifPlayer from 'components/GifPlayer'
import { fromNow } from 'utils/format/date'
import { NativeVideoPlayer } from 'components/VideoPlayer'
import ConfirmDialog from 'components/ConfirmDialog'
import DeleteIcon from '@mui/icons-material/Delete'
import CircularProgress from '@mui/material/CircularProgress'
import Tooltip from '@mui/material/Tooltip'
import ProgressMedia from 'components/Card/ProgressMedia'
import { FeatherIcon } from 'components/Icons'
import IconPalette from '@mui/icons-material/Palette'
import styles from './FileCard.pcss'
import { useDispatch, useSelector } from 'react-redux'
import { action, message } from 'services/snackbar'
import { StoreThunkDispatch } from 'store/state'
import { Subject } from 'rxjs/Subject'
import { deleteFile, getStorageInfo } from 'services/uploads/actions'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { HTTP_STATUS_BAD_REQUEST } from 'services/net'
import { NOTIFICATION_DURATION_LONG } from 'components/ConnectedSnackbar/ConnectedSnackbar'
import IconButton from '@mui/material/IconButton'
import IconAdd from '@mui/icons-material/Add'
import IconCheck from '@mui/icons-material/Check'
import { useLocation, useNavigate } from 'react-router-dom'
import { composerImageUrlsSelector, multiPostsArraySelector } from 'services/compose/selectors'
import { promptCompose } from 'services/compose'
import { addContentToSelection, removeContentFromSelection } from 'services/content'
import { mdiAutoFix } from '@mdi/js'
import Icon from '@mdi/react'
import { format } from 'date-fns'
import { currentUserSelector } from 'services/users/selectors'

const BASE_TEN = 10
const cssVariables = require('styles/variables')
const LOADER_SIZE = 36
const CARD_SIZE_SMALL = parseInt(cssVariables['--square-card-size-small'], BASE_TEN)
const CARD_SIZE_DEFAULT = parseInt(cssVariables['--square-card-size-default'], BASE_TEN)

export interface FileCardProps extends WithIntl {
  file: MyFile
  selected?: boolean
  small?: boolean
  className?: string
  hideDetails?: boolean
  actions?: React.ReactNode

  onCreatePost?: (file: MyFile) => void
  onClick?: (file: MyFile) => void
}

export function FileCard(props: FileCardProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const isBeta = useSelector(currentUserSelector)?.roles?.isBeta
  const dispatch = useDispatch<StoreThunkDispatch>()
  const multiPosts = useSelector(multiPostsArraySelector)
  const composerCarouselImages = useSelector(composerImageUrlsSelector)
  const [pop, setPop] = React.useState(false)
  const [hovered, setHovered] = React.useState(false)
  const [imageLoading, setImageLoading] = React.useState(true)
  const [imgSrc, setImgSrc] = React.useState(props.file.thumbUrl)
  const delete$ = React.useRef<Subject<{ file: MyFile, force?: boolean }>>()

  React.useEffect(() => {
    delete$.current = new Subject()
    delete$.current
      .flatMap(({ file, force }) => {
        return Observable.fromPromise(dispatch(deleteFile({ file, force })).unwrap())
          .pipe(catchError(response => {
            if (response.status === HTTP_STATUS_BAD_REQUEST && response.response.msg === 'File used in posts.') {
              dispatch(action(
                'warning',
                props.intl.formatMessage({ id: 'find.my-uploads.messages.confirm-delete-used-file' }),
                props.intl.formatMessage({ id: 'actions.delete' }),
                () => {
                  delete$.current?.next({ file, force: true })
                },
                NOTIFICATION_DURATION_LONG
              ))
              return Observable.of({ error: response, handled: true })
            }
            return Observable.of({ error: response })
          }))
          .flatMap((response: any) => {
            if (!response.error) {
              return dispatch(getStorageInfo())
            }
            return Observable.of(response)
          })
      })
      .subscribe((result) => {
        if (result.error && !result.handled) {
          dispatch(message(props.intl.formatMessage({ id: 'errors.generic' }), 'error'))
        }
      })
  }, [dispatch, props.intl])

  const file = props.file
  const classNamePop = pop ? styles.pop : ''
  const classNameSmall = props.small ? styles.small : ''
  const classNameLoading = props.file.loading ? styles.loading : ''
  const classNameImgLoading = imageLoading ? styles['img-loading'] : ''
  const classType = file.type === PHOTO_TYPE ? styles.img : ''
  const optionalClasses = `${classNameSmall} ${classNamePop} ${classNameLoading} ${classNameImgLoading} ${classType}`
  const time = fromNow(file.createdAt)
  const dateTimeString = format(file.createdAt, 'MMM d, yyyy')

  const onImageError = () => {
    if (file.type === PHOTO_TYPE && imgSrc !== props.file.url) {
      setImgSrc(props.file.url)
    }
  }

  const stopImageLoading = () => {
    setImageLoading(false)
  }

  const createPost = () => {
    if (props.onCreatePost) {
      // EXPL: Open prompt if composer already has carousel or multi-post
      if (composerCarouselImages.length > 1 || multiPosts.length > 0) {
        dispatch(promptCompose({ content: { type: PHOTO_TYPE, imageUrl: file.url } as any, action: 'create' }))
        return
      }

      props.onCreatePost(props.file)
    }
  }

  const onDelete = () => {
    delete$.current?.next({ file: props.file, force: false })
  }

  const onMouseOver = () => {
    setHovered(true)
    setPop(true)
  }

  const onMouseOut = () => {
    setHovered(false)
  }

  const onTransitionEnd = () => {
    if (!hovered && pop) {
      setPop(false)
    }
  }

  const onCardClick = () => {
    if (props.onClick) {
      props.onClick(props.file)
    }
  }

  const cardHeight = props.small ? CARD_SIZE_SMALL : CARD_SIZE_DEFAULT

  const contentBlockStyles = {
    width: `${file.width / (file.height / cardHeight)}px`,
    height: `${cardHeight}px`
  }

  const openInStudio = () => {
    navigate('/content/studio', { state: { imageUrl: file.url } })
  }

  const toggleFileSelected = () => {
    if (props.selected) {
      dispatch(removeContentFromSelection(file.id))
    } else {
      dispatch(addContentToSelection(file))
    }
  }

  const openInAIWriter = () => {
    const url = location.pathname === '/composer' ? '/composer/ai' : `${location.pathname}?view=ai`
    navigate(url, { state: { imageUrl: props.file.url, opener: location.pathname, background: location } })
  }

  // const sendToAIAction = isBeta && file.type === PHOTO_TYPE ? (
  //   <Tooltip
  //     title={props.intl.formatMessage({ id: 'content-card.actions.ai' })}
  //     placement="left"
  //     children={(
  //       <IconButton
  //         color="primary"
  //         size="small"
  //         className={styles.btn}
  //         onClick={openInAIWriter}
  //       >
  //         <Icon path={mdiAutoFix} />
  //       </IconButton>
  //     )}
  //   />
  // ) : null

  return (
    <Paper
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onTransitionEnd={onTransitionEnd}
      className={`${styles.card} ${props.className || ''} ${optionalClasses}`}
    >
      {!props.hideDetails && (
        <div className={styles.time}>
          <Tooltip title={dateTimeString}><span>{time}</span></Tooltip>
        </div>
      )}
      {imageLoading ? (
        <div className={styles.content} style={contentBlockStyles}>
          <ProgressMedia
            image={imgSrc || ''}
            loader={<CircularProgress size={LOADER_SIZE} className={styles.loader} />}
            className={styles['loader-box']}
            onDone={stopImageLoading}
            onError={stopImageLoading}
          />
        </div>
      ) : (
        <div className={styles.content} style={contentBlockStyles} onClick={onCardClick}>
          {
            file.type === PHOTO_TYPE && (
              <img src={imgSrc} className={styles.image} onError={onImageError} />
            )
          }
          {
            file.type === VIDEO_TYPE && (
              <NativeVideoPlayer
                videoUrl={file.url}
                togglePlayOnHover
                muted
                loop
                className={styles['video-box']}
              />
            )
          }
          {
            file.type === GIF_TYPE && (
              <GifPlayer
                loop
                gifUrl={file.url}
                className={styles['gif-box']}
              />
            )
          }
        </div>
      )}
      <div className={`${styles.name} text-ellipsis`}>{file.name}</div>
      {props.actions && (
        <div className={styles.actions}>
          {props.actions}
          {/* {sendToAIAction} */}
        </div>
      )}
      {!props.actions && (
        <div className={styles['actions-vert']}>
          <Tooltip
            title={<FormattedMessage id={props.selected ? 'label.generic.selected' : 'content-card.actions.add-content'} />}
            placement="left"
            children={(
              <IconButton
                color="primary"
                size="small"
                className={styles.btn}
                onClick={toggleFileSelected}
              >
                {props.selected ? (
                  <IconCheck className={styles['icon-compose']} />
                ) : (
                  <IconAdd className={styles['icon-compose']} />
                )}
              </IconButton>
            )}
          />
          <Tooltip
            title={props.intl.formatMessage({ id: 'content-card.actions.create-post' })}
            placement="left"
            children={(
              <IconButton
                color="primary"
                size="small"
                className={styles.btn}
                onClick={createPost}
              >
                <FeatherIcon className={styles['icon-compose']} />
              </IconButton>
            )}
          />
          {/* {sendToAIAction} */}
          {(file.type === PHOTO_TYPE) && (
            <Tooltip
              title={props.intl.formatMessage({ id: 'content-card.actions.edit-image' })}
              placement="left"
              children={(
                <IconButton
                  color="primary"
                  size="small"
                  className={styles.btn}
                  onClick={openInStudio}
                >
                  <IconPalette className={styles['icon-compose']} />
                </IconButton>
              )}
            />
          )}
          <ConfirmDialog
            message={props.intl.formatMessage({ id: 'find.my-uploads.messages.confirm-delete-file' }, { br: <br /> } as any)}
            labelOK={props.intl.formatMessage({ id: 'actions.yes' })}
            labelCancel={props.intl.formatMessage({ id: 'actions.cancel' })}
          >
            {
              (confirm) => (
                <Tooltip
                  title={props.intl.formatMessage({ id: 'actions.delete' })}
                  placement="left"
                  children={(
                    <IconButton
                      size="small"
                      onClick={confirm(onDelete)}
                      className={`${styles['btn-delete']} `}
                    >
                      <DeleteIcon className={styles['icon-delete']} />
                    </IconButton>
                  )}
                />
              )
            }
          </ConfirmDialog>
        </div>
      )}
    </Paper>
  )
}

export default injectIntl(FileCard)
