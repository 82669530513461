import * as React from 'react'
import { connect } from 'react-redux'
import StoreState, { StoreThunkDispatch } from 'store/state'
import { logout } from 'services/auth'
import Loader from 'components/SimpleLoader'
import { facebookSDKInitializedSelector } from 'services/external/selectors'
import { useNavigate } from 'react-router-dom'

interface LogoutProps {
  fbInitialized: boolean
  logout: () => void
}

function Logout(props: LogoutProps) {
  const navigate = useNavigate()

  React.useEffect(() => {
    if (props.fbInitialized) {
      props.logout()
      navigate('/login', { replace: true })
    }
  }, [props.fbInitialized])

  return <Loader />
}

function mapStateToProps(state: StoreState) {
  return {
    fbInitialized: facebookSDKInitializedSelector(state)
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    logout: () => dispatch(logout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout)
