import * as React from 'react'
import Drawer from '@mui/material/Drawer'
import styles from '../TextContentSearchPanel/TextContentSearchPanel.pcss'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
  ALL_RANGE_FILTER,
  ContentItem,
  ContentType,
  GIF_TYPE,
  IndexedObject,
  MyFile,
  PHOTO_TYPE,
  VIDEO_TYPE,
  WithIntl
} from 'interfaces'
import { StoreThunkDispatch } from 'store/state'
import { Observable } from 'rxjs/Observable'
import { useDispatch, useSelector } from 'react-redux'
import { Subject } from 'rxjs/Subject'
import { tap } from 'rxjs/operators/tap'
import { getFavorites } from 'services/content/favorites'
import { catchError } from 'rxjs/operators/catchError'
import CollapsibleTextInput from 'components/CollapsibleTextInput'
import PPSwitch from 'components/PPSwitch'
import IconArrowRight from '@mui/icons-material/TrendingFlat'
import { searchStockContent } from 'services/search/actions'
import { message } from 'services/snackbar'
import Chip from '@mui/material/Chip'
import CardList from 'components/CardList'
import AnyCard from 'components/Card'
import { renderLoadingCards } from 'routes/find/routes/home/components/utils'
import { CHIP_ACTIVE_CLASS } from 'theme/theme-md'
import EmptyView from 'components/EmptyView'
import { mdiHeartOutline, mdiImagePlusOutline, mdiFolderMultipleImage, mdiMagnify } from '@mdi/js'
import {
  STOCK_CONTENT_PROVIDER_GIPHY,
  STOCK_CONTENT_PROVIDER_PEXELS,
  STOCK_CONTENT_PROVIDER_PIXABAY,
  STOCK_CONTENT_PROVIDER_UNSPLASH
} from 'interfaces/Content/StockContentProvider'
import { getUploads } from 'services/uploads/actions'
import { allFilesFolderSelector } from 'services/uploads/selectors'
import FileCard from 'components/FileCard'
import { FetchRandomMedia } from './FetchRandomMedia'
import { setComposerFile } from 'services/compose'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Icon from '@mdi/react'
import { NavLink } from 'react-router-dom'
import { STOCK_VIDEO_TYPE } from 'shared'

const SOURCE_FAVORITES = 'favorites'
const SOURCE_LIBRARY = 'library'

type MediaSource = typeof SOURCE_FAVORITES
  | typeof SOURCE_LIBRARY
  | typeof STOCK_CONTENT_PROVIDER_PEXELS
  | typeof STOCK_CONTENT_PROVIDER_UNSPLASH
  | typeof STOCK_CONTENT_PROVIDER_PIXABAY
  | typeof STOCK_CONTENT_PROVIDER_GIPHY

const SOURCES_BY_CATEGORY: IndexedObject<MediaSource[]> = {
  [PHOTO_TYPE]: [
    SOURCE_FAVORITES,
    SOURCE_LIBRARY,
    STOCK_CONTENT_PROVIDER_PEXELS,
    STOCK_CONTENT_PROVIDER_PIXABAY,
    STOCK_CONTENT_PROVIDER_UNSPLASH
  ],
  [VIDEO_TYPE]: [SOURCE_FAVORITES, SOURCE_LIBRARY, STOCK_CONTENT_PROVIDER_PEXELS, STOCK_CONTENT_PROVIDER_PIXABAY],
  [GIF_TYPE]: [SOURCE_FAVORITES, SOURCE_LIBRARY, STOCK_CONTENT_PROVIDER_GIPHY]
}

const DEFAULT_SOURCE_BY_CATEGORY: Record<string, MediaSource> = {
  [PHOTO_TYPE]: STOCK_CONTENT_PROVIDER_PEXELS,
  [VIDEO_TYPE]: STOCK_CONTENT_PROVIDER_PEXELS,
  [GIF_TYPE]: STOCK_CONTENT_PROVIDER_GIPHY
}

const categories = [{
  value: PHOTO_TYPE,
  label: <FormattedMessage id="general.content.filters.photos" />
}, {
  value: VIDEO_TYPE,
  label: <FormattedMessage id="general.content.filters.videos" />
}, {
  value: GIF_TYPE,
  label: <FormattedMessage id="general.content.filters.gifs" />
}]

interface MediaSearchPanelOwnProps {
  open: boolean
  onClose: () => void
  onVideoUrlAdded: () => void
}

type MediaSearchPanelProps = MediaSearchPanelOwnProps & WithIntl

export function MediaSearchPanel(props: MediaSearchPanelProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [activeCategory, setActiveCategory] = React.useState<ContentType>(PHOTO_TYPE)
  const [activeSource, setActiveSource] = React.useState<MediaSource>(STOCK_CONTENT_PROVIDER_PEXELS)
  const [query, setQuery] = React.useState('')
  const [items, setItems] = React.useState<ContentItem[]>([])
  const [favPhotos, setFavPhotos] = React.useState<ContentItem[]>([])
  const [favVideos, setFavVideos] = React.useState<ContentItem[]>([])
  const [favGifs, setFavGifs] = React.useState<ContentItem[]>([])
  const [loading, setLoading] = React.useState(false)
  const [favLoading, setFavLoading] = React.useState(false)
  const search$ = React.useRef<Subject<{ query: string, type: ContentType, source: MediaSource }>>(new Subject())
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [randomItems, setRandomItems] = React.useState<any>({
    PHOTO_TYPE: {},
    VIDEO_TYPE: {},
    GIF_TYPE: {}
  })
  const filesFolder = useSelector(allFilesFolderSelector)

  React.useEffect(() => {
    dispatch(getUploads()).unwrap()
      .finally(() => {
        setLoading(false)
      })
  }, [dispatch])

  React.useEffect(() => {
    dispatch(getUploads()).unwrap()
      .finally(() => {
        setLoading(false)
      })
  }, [dispatch])

  React.useEffect(() => {
    const fetchFavorites = async () => {
      setFavLoading(true)
      try {
        const response = await Promise.all([
          dispatch(getFavorites({ page: 0, type: PHOTO_TYPE, range: ALL_RANGE_FILTER })).unwrap(),
          dispatch(getFavorites({ page: 0, type: VIDEO_TYPE, range: ALL_RANGE_FILTER })).unwrap(),
          dispatch(getFavorites({ page: 0, type: GIF_TYPE, range: ALL_RANGE_FILTER })).unwrap()
        ])
        setFavPhotos(response[0].items)
        setFavVideos(response[1].items)
        setFavGifs(response[2].items)
        setFavLoading(false)
      } catch (e) {
        console.log(`Error fetching favorites: ${e}`)
        setFavLoading(false)
      }
    }
    if (props.open) {
      fetchFavorites()
    }
  }, [props.open, dispatch])

  React.useEffect(() => {
    setActiveSource(current => {
      if (SOURCES_BY_CATEGORY[activeCategory].includes(current)) {
        return current
      }
      return DEFAULT_SOURCE_BY_CATEGORY[activeCategory]
    })
  }, [activeCategory])

  React.useEffect(() => {
    search$.current = new Subject()
    search$.current
      .pipe(
        tap(() => setLoading(true))
      )
      .flatMap(({ query, type, source }) => {
        const action = searchStockContent(query, type, source)
        return dispatch(action)
          .pipe(
            catchError(() => {
              dispatch(message(props.intl.formatMessage({ id: 'errors.generic' }), 'error'))
              return Observable.of({ error: true })
            })
          )
      })
      .subscribe((response: any) => {
        if (!response.error) {
          setItems(current => [...current, ...response])
        }
        setLoading(false)
      })

    return () => {
      search$.current.unsubscribe()
    }
  }, [dispatch, props.intl])

  React.useEffect(() => {
    setItems([])
  }, [query, activeSource, activeCategory])

  React.useEffect(() => {
    if (activeSource === SOURCE_FAVORITES) {
      return
    }
    const value = query.trim()
    if (value) {
      search$.current?.next({ query: value, type: activeCategory, source: activeSource })
    }
  }, [query, activeCategory, activeSource])

  const onRandomItemsFetched = React.useCallback((type: ContentType, items: ContentItem[], provider: string) => {
    setRandomItems((current: any) => ({
      ...current,
      [type]: {
        ...current[type],
        [provider]: items
      }
    }))
  }, [])

  const loadingCards = React.useMemo(() => {
    if ((activeSource === SOURCE_FAVORITES && !favLoading) || !loading || activeSource === SOURCE_LIBRARY) {
      return null
    }

    return renderLoadingCards(undefined, false, true, loading)
  }, [activeSource, favLoading, loading])

  const visibleContentItems = React.useMemo(() => {
    if (activeSource === SOURCE_FAVORITES) {
      switch (activeCategory) {
        case PHOTO_TYPE:
          return favPhotos
        case VIDEO_TYPE:
          return favVideos
        case GIF_TYPE:
          return favGifs
        default:
          return []
      }
    }

    return items
  }, [activeCategory, activeSource, favGifs, favPhotos, favVideos, items])

  const visibleFiles = React.useMemo(() => {
    if (activeSource !== SOURCE_LIBRARY) {
      return []
    }
    const files = Object.values(filesFolder.files)
    return files.filter(file => file.type === activeCategory)
  }, [activeCategory, activeSource, filesFolder.files])

  const createPostFromFile = (file: MyFile) => {
    dispatch(setComposerFile(file.url))
    if (file.type === VIDEO_TYPE) {
      props.onVideoUrlAdded()
    }
    onMediaAdded(null, false)
  }

  const onMediaAdded = (content: any, aiWriter: boolean) => {
    if (aiWriter) {
      props.onClose()
    } else {
      dispatch(message(props.intl.formatMessage({ id: 'general.cards.notification.added-to-composer' }), 'info', undefined, true))
    }

    if (content.type === STOCK_VIDEO_TYPE) {
      props.onVideoUrlAdded()
    }
  }

  const visibleRandomItems = randomItems[activeCategory] ? randomItems[activeCategory][activeSource] || [] : []
  const showRandom = !query && !loading && visibleContentItems.length === 0

  return (
    <Drawer
      anchor="bottom"
      open={props.open}
      disableEnforceFocus
      classes={{ paper: styles.container }}
      data-testid="media-search-panel"
      onClose={props.onClose}
    >
      <div className={styles.top}>
        <div className={styles['top-header']}>
          <CollapsibleTextInput
            ref={inputRef}
            initialActive
            changeOnEnter
            disabled={activeSource === SOURCE_FAVORITES || activeSource === SOURCE_LIBRARY}
            placeholder={props.intl.formatMessage({ id: 'search.hint.search-for-media' })}
            className={styles['search-box']}
            activeClassName={styles['search-active']}
            id="media-search-input"
            onValueChanged={setQuery}
          />
        </div>
        <div className={styles.nav}>
          <PPSwitch
            options={categories}
            selectedValue={activeCategory}
            onSelectedValueChange={setActiveCategory as any}
          />
          <IconArrowRight className={styles.arrow} />
          {SOURCES_BY_CATEGORY[activeCategory].map(s => (
            <NavChip key={s} value={s} isActive={activeSource === s} onClick={setActiveSource} />
          ))}
        </div>
      </div>
      <div className={styles.content}>
        <CardList key={activeSource + query + activeCategory}>
          {activeSource !== SOURCE_LIBRARY && visibleContentItems.map(item => (
            <AnyCard
              key={item.id}
              content={item}
              children={item}
              square
              small
              hideDetails
              actions={['create-post', 'open-studio']}
              onCompose={onMediaAdded}
            />
          ))}
          {activeSource === SOURCE_LIBRARY && visibleFiles.map(file => (
            <FileCardWithAction key={file.id} file={file} onClick={createPostFromFile} />
          ))}
          {loadingCards}
          {showRandom && visibleRandomItems.map((item: any) => (
            <AnyCard
              key={item.id}
              content={item}
              children={item}
              square
              small
              actions={['create-post', 'open-studio']}
              hideDetails
              onCompose={onMediaAdded}
            />
          ))}
        </CardList>
        {activeSource === SOURCE_FAVORITES && !favLoading && visibleContentItems.length === 0 && (
          <EmptyView
            title={`No ${activeCategory} favorited yet`}
            icon={mdiHeartOutline}
            subtitle={(
              <div>
                Click ♡ on any content to save it to your favorites.
              </div>
            )}
            carousel
            className={styles.error}
          />
        )}
        {activeSource !== SOURCE_FAVORITES && activeSource !== SOURCE_LIBRARY && !loading && visibleContentItems.length === 0 && query && (
          <EmptyView
            carousel
            icon={mdiMagnify}
            title={`No ${activeCategory} available for that search`}
            subtitle="Please try a different keyword, topic, brand or industry."
            className={styles.error}
          />
        )}
        {activeSource === SOURCE_LIBRARY && visibleFiles.length === 0 && (
          <EmptyView
            title={props.intl.formatMessage({ id: `uploads.error.carousel-no-${activeCategory}` })}
            carousel
            icon={mdiFolderMultipleImage}
            subtitle={<span>Go to <NavLink to="/content/library">your Library</NavLink> to upload and organize content.</span>}
            className={styles.error}
          />
        )}
      </div>
      <FetchRandomMedia onFetched={onRandomItemsFetched} />
    </Drawer>
  )
}

function FileCardWithAction(props: { file: MyFile, onClick: (file: MyFile) => void }) {
  const onClick = () => {
    props.onClick(props.file)
  }

  return (
    <FileCard
      small
      file={props.file}
      hideDetails
      className={styles.file}
      actions={
        <Tooltip title={<FormattedMessage id={`actions.add-${props.file.type === 'videos' ? 'video' : 'image'}`} />} placement="left">
          <IconButton
            size="small"
            color="primary"
            className={styles['btn-select']}
            onClick={onClick}
          >
            <Icon path={mdiImagePlusOutline} size="20px" />
          </IconButton>
        </Tooltip>
      }
    />
  )
}

function NavChip(props: { value: MediaSource, isActive: boolean, onClick: (value: MediaSource) => void }) {
  const onClick = () => {
    props.onClick(props.value)
  }

  return (
    <Chip
      label={<FormattedMessage id={`general.content.filters.${props.value}`} />}
      className={`${styles.chip} ${props.isActive ? CHIP_ACTIVE_CLASS : ''}`}
      onClick={onClick}
    />
  )
}

export default injectIntl(MediaSearchPanel)
