import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'
import { CreatePostRequestData } from 'shared/types'
import toSnakeCase from 'utils/toSnakeCase'

export const ENDPOINT_CREATE_POST = `${LEGACY_URL}/v3/composer_new`

export const V1 = Object.freeze({
  createPost: function () {
    return function (data: CreatePostRequestData, bucketId?: string) {
      const builder = new RequestBuilder(ENDPOINT_CREATE_POST).asPost().asFormUrlEncoded().expectJSON()
      const requestData = JSON.stringify(toSnakeCase({ ...data, bucketId, videoThumbnail: data.videoThumbnail ? 1 : undefined }))

      return authorizedPipe<any>(
        builder
          .body({ data: requestData })
          .build()
      )
    }
  }()
})
