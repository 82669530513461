// Extracts the domain part of URL, including leading http://, https:// and www
export function getDomainFromUrl(url: string): string {
  if (!url) {
    return ''
  }

  // eslint-disable-next-line
  const matches = url.match(/^(?:https?:)?(?:\/\/)?([^\/\?]+)/gi)
  const domain = matches && matches[0]
  return domain || ''
}

export function isFacebookLink(url: string) {
  const facebookLinkRegex = /^(https?:\/\/)?((www\.)?facebook\.com|([a-zA-Z0-9-]+\.)?fb\.me)\/[a-zA-Z0-9(\.\?)?]/
  return facebookLinkRegex.test(url)
}
