import * as React from 'react'
import RecentlyViewedCarousel from '../RecentlyViewedCarousel'
import MostRecentSourceCarousel from '../MostRecentSourceCarousel'
import MyProfilesStreamContentCarousel from '../MyProfilesStreamContentCarousel'
import RecentUploadsCarousel from '../RecentUploadsCarousel'
import CuratedStreamsCarousel from '../CuratedStreamsCarousel'
import FavoritesCarousel from '../FavoritesCarousel'
import PostPlannerBlogCarousel from '../PostPlannerBlogCarousel'
import { StreamGroups, StreamGroupCarousel } from '../StreamGroupCarousel'
import { useDispatch, useSelector } from 'react-redux'
import { HomeContentPanelCarousel, StatusIdeasPanel, QuotesPanel } from 'components/ContentPanels'
import { StoreThunkDispatch } from 'store/state'
import { getHomeContentBlocks } from 'admin/services/home-config/actions'
import { getContentPanels } from 'admin/services/content-panels/actions'
import Loader from 'components/SimpleLoader'
import {
  BLOCK_FAVORITES,
  BLOCK_LATEST_FROM_BLOG,
  BLOCK_MBM,
  BLOCK_MOST_RECENT,
  BLOCK_MY_LIBRARY,
  BLOCK_MY_LIBRARY_FOLDERS,
  BLOCK_MY_PROFILES_CONTENT,
  BLOCK_MY_STREAMS,
  BLOCK_QUOTES,
  BLOCK_RECENTLY_VIEWED,
  BLOCK_STATUS_IDEAS
} from '../../home.config'
import { contentBlocksSelector, contentGroupsSelector, contentPanelsSelector } from 'services/content/selectors'
import MyLibraryFoldersCarousel from '../MyLibraryFoldersCarousel'

export function HomeView() {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const blocks = useSelector(contentBlocksSelector)
  const panels = useSelector(contentPanelsSelector)
  const groups = useSelector(contentGroupsSelector)
  const [loading, setLoading] = React.useState(true)
  const [loadingRecent, setLoadingRecent] = React.useState(true)

  React.useEffect(() => {
    const sub = dispatch(getHomeContentBlocks(true))
      .zip(dispatch(getContentPanels(true)))
      .subscribe(() => {
        setLoading(false)
      }, (e) => {
        setLoading(false)
        console.log('Error fetching data: ', e)
      })

    return () => {
      sub.unsubscribe()
    }
  }, [])

  const onRecentSourcesFetched = () => {
    setLoadingRecent(false)
  }

  return (
    <section data-test="home">
      {loading && blocks.length === 0 && <Loader />}
      {blocks.length > 0 && (
        blocks.map(block => {
          const group = block.groupId && groups[block.groupId]
          if (group) {
            return <StreamGroupCarousel key={block.key} group={group} />
          }

          const panel = block.contentPanelId && panels.find(p => p.id === block.contentPanelId)
          if (panel) {
            return <HomeContentPanelCarousel key={block.key} panel={panel} />
          }

          switch (block.key) {
            case BLOCK_MOST_RECENT:
              return <MostRecentSourceCarousel title={block.name} key={block.key} loading={loadingRecent} />
            case BLOCK_RECENTLY_VIEWED:
              return <RecentlyViewedCarousel title={block.name} key={block.key} onRecentSourcesFetched={onRecentSourcesFetched} />
            case BLOCK_QUOTES:
              return <QuotesPanel key={block.key} />
            case BLOCK_FAVORITES:
              return <FavoritesCarousel title={block.name} key={block.key} />
            case BLOCK_MY_STREAMS:
              return <CuratedStreamsCarousel title={block.name} key={block.key} category="my-streams" />
            case BLOCK_MY_LIBRARY:
              return <RecentUploadsCarousel title={block.name} key={block.key} />
            case BLOCK_MY_PROFILES_CONTENT:
              return <MyProfilesStreamContentCarousel title={block.name} key={block.key} />
            case BLOCK_STATUS_IDEAS:
              return <StatusIdeasPanel title={block.name} key={block.key} />
            case BLOCK_LATEST_FROM_BLOG:
              return <PostPlannerBlogCarousel key={block.key} />
            case BLOCK_MY_LIBRARY_FOLDERS:
              return <MyLibraryFoldersCarousel title={block.name} key={block.key} />
            default:
              console.warn('Block key not found!', block.name)
              return null
          }
        })
      )}
      {!loading && blocks.length === 0 && (
        <React.Fragment>
          <MostRecentSourceCarousel loading={loadingRecent} />
          <RecentlyViewedCarousel onRecentSourcesFetched={onRecentSourcesFetched} />
          <QuotesPanel />
          <FavoritesCarousel />
          <StreamGroups />
          <CuratedStreamsCarousel category="my-streams" />
          <RecentUploadsCarousel />
          <MyProfilesStreamContentCarousel />
          <StatusIdeasPanel />
          <PostPlannerBlogCarousel />
        </React.Fragment>
      )}
    </section>
  )
}

export default HomeView
