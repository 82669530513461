import { createElement, memo } from 'react'
import { ProfileAvatar, BrandifiedAvatar } from 'components/ProfileAvatar'
import { Feed, FEED_TYPE_UNSUPPORTED, FEED_TYPE_INSTAGRAM, FEED_TYPE_KEYWORD, ContentFeedType } from 'interfaces'
import DeleteIcon from '@mui/icons-material/Close'

import styles from './FeedIcon.pcss'

export interface FeedIconProps {
  size?: number
  className?: string
  feed: Feed
  showTooltip?: boolean
  active?: boolean
  onDelete?: (feedId: string, type: ContentFeedType) => void
}

export const FeedIcon = memo(({ className, feed, size, showTooltip, active, onDelete }: FeedIconProps) => {
  if (feed.type === FEED_TYPE_UNSUPPORTED) {
    throw new Error('Unsupported feed type')
  }

  const hashtag = feed.type === FEED_TYPE_INSTAGRAM && feed.handle.indexOf('#') === 0

  const props = {
    key: feed.id,
    background: 'grey',
    circle: true,
    brand: feed.type,
    src: feed.image,
    title: feed.name,
    className,
    size,
    hashtag,
    active,
    keyword: feed.type === FEED_TYPE_KEYWORD ? feed.name : undefined
  }

  const onDeleteClick = (e: React.MouseEvent<any>) => {
    e.preventDefault()
    e.stopPropagation()
    if (onDelete) {
      onDelete(feed.id, feed.type)
    }
  }

  return createElement('div', { className: styles['icon-wrapper'] }, [
    showTooltip
      ? createElement(ProfileAvatar, props)
      : createElement(BrandifiedAvatar, props),
    onDelete && createElement(DeleteIcon, {
      key: 'btn-delete',
      className: styles['btn-delete'],
      onClick: onDeleteClick
    })
  ])
})

export default FeedIcon
