import React, { Component, Children } from 'react'
import { connect } from 'react-redux'

import { Feed, Stream } from 'interfaces'
import { getStreams } from 'services/content/streams/actions'
import { StoreState, StoreThunkDispatch } from 'store/state'

import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/throw'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/observeOn'
import 'rxjs/add/operator/zip'
import { animationFrame } from 'rxjs/scheduler/animationFrame'
import { Subscription } from 'rxjs/Subscription'

export interface FetchMyStreamsProps {
  // eslint-disable-next-line react/no-unused-prop-types
  forceFetch?: boolean
  onFetched({ streams, feeds }: { streams: Stream[], feeds: Feed[] }): void
  onFailed?(err: any): void
  children?: React.ReactNode
}

interface ConnectedFetchMyStreamsProps extends FetchMyStreamsProps {
  getStreams(): Observable<Stream[]>
}

export class FetchMyStreams extends Component<ConnectedFetchMyStreamsProps, void> {
  private subscription: Subscription

  constructor(props: ConnectedFetchMyStreamsProps) {
    super(props)

    this.onFetched = this.onFetched.bind(this)
    this.onFailed = this.onFailed.bind(this)
  }

  shouldComponentUpdate() {
    return false
  }

  onFetched(content: { streams: Stream[], feeds: Feed[] }) {
    this.props.onFetched(content)
  }

  onFailed(err: any) {
    console.log(err)
    if (this.props.onFailed) {
      this.props.onFailed(err)
    }
  }

  fetch() {
    return this.props.getStreams()
      .mergeMap((streams) => {
        return Observable.of({ streams })
      })
      .observeOn(animationFrame)
      .subscribe(this.onFetched, this.onFailed)
  }

  componentDidMount() {
    this.subscription = this.fetch()
  }

  componentDidUpdate() {
    this.subscription.unsubscribe()
    this.subscription = this.fetch()
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    return this.props.children
      ? Children.only(this.props.children)
      : null
  }
}

function mapStateToProps(_state: StoreState, ownProps: FetchMyStreamsProps) {
  return ownProps
}

function mapDispatchToProps(dispatch: StoreThunkDispatch, ownProps: FetchMyStreamsProps) {
  return {
    getStreams: () => Observable.fromPromise(dispatch(getStreams(ownProps.forceFetch)).unwrap())
  }
}

const ConnectedFetch = connect(mapStateToProps, mapDispatchToProps)(FetchMyStreams as any)
ConnectedFetch.defaultProps = {
  forceFetch: true
}
export default ConnectedFetch
