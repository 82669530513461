import * as React from 'react'
import { Icon } from '@mdi/react'
import { mdiRefresh } from '@mdi/js'
import IconInfinity from '@mui/icons-material/AllInclusive'
import styles from './RepeatIndicator.pcss'
import { FormattedMessage } from 'react-intl'
import Tooltip from '@mui/material/Tooltip'
import { format } from 'date-fns'

interface RepeatIndicatorProps {
  repeatLength: number
  repeatPeriod: string
  repeatUntil: string
  className?: string
}

export function RepeatIndicator(props: RepeatIndicatorProps) {
  const labelUntil = React.useMemo(() => {
    if (!props.repeatLength) {
      return ''
    }
    return props.repeatUntil === 'forever' ? 'forever' : format(props.repeatUntil, 'LLL d yyyy')
  }, [props.repeatLength, props.repeatUntil])

  return (
    <Tooltip
      title={labelUntil ? (
        <FormattedMessage
          id="post.planned.recycle-msg"
          values={{
            repeatLength: props.repeatLength,
            repeatPeriod: props.repeatPeriod,
            repeatUntil: labelUntil
          }}
        />
      ) : ''}
    >
      <div className={`${styles.box} ${props.className || ''} ${!props.repeatLength ? styles.inactive : ''}`}>
        <Icon path={mdiRefresh} className={styles['icon-repeat']} />
        <div className={styles['value-box']}>
          {props.repeatUntil === 'forever' ? (
            <IconInfinity className={styles.icon} />
          ) : (
            <span className={styles.small}>{labelUntil}</span>
          )}
        </div>
      </div>
    </Tooltip>
  )
}
