import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import { FeatureInfo, PlanLimitInfo, PPProduct, User } from 'interfaces'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  UPGRADE_EVENT_TYPES_MAP,
  closeUpgradePrompt,
  featureUpgradeSelector,
  getFeaturesAndLimits,
  getProductByHandle,
  onCloseUpgradeSelector,
  productUpgradeSelector,
  toggleContentUpgradePopupDismissed,
  upgradeProduct,
  featuresSelector,
  limitsSelector,
  productsSelector
} from 'services/product'
import StoreState, { StoreThunkDispatch } from 'store/state'
import { Observable } from 'rxjs/Observable'
import { connect, useDispatch, useSelector } from 'react-redux'
import { message } from 'services/snackbar'
import CheckIcon from '@mui/icons-material/Check'
import { checkSubscription } from 'services/users/actions'
import { catchError } from 'rxjs/operators/catchError'
import { currentUserSelector, userIsAdminSelector, userProductSelector } from 'services/users/selectors'
import capitalize from 'utils/format/capitalize'
import { bytesToSize } from 'utils/format/byteToSize'
import { event as googleEvent } from 'react-ga'
import { CURRENCY_SIGN_MAP, FEATURE_GENERIC, LIMIT_MY_LIBRARY_STORAGE } from 'shared/constants'
import styles from './UpgradePopup.pcss'
import PPSwitch from 'components/PPSwitch'
import { NOTIFICATION_DURATION_LONG } from 'components/ConnectedSnackbar'
import { HS_EVENT_POPUP_UPGRADE, trackHubspotEvent } from 'services/tracking/hubspot'
import { useNavigate } from 'react-router-dom'
import ConfirmDialog from 'components/ConfirmDialog'
interface UpgradePopupConnectedProps {
  options: {
    feature?: string
    product?: string
    onClose?: () => void
  }
  user?: User
  features: { [key: string]: FeatureInfo }
  limits: { [key: string]: PlanLimitInfo }
  currentProduct?: PPProduct
  isAdmin: boolean
  closeDialog: () => void
  upgrade: (handle: string) => Observable<any>
  showNotification: (text: string, type?: any, duration?: number) => void
  refreshProductInfo: () => Promise<any>
  dismissContentUpgradePopup: () => void
}

type UpgradePopupProps = UpgradePopupConnectedProps

// Plan ids that require confirmation before upgrading
const CONFIRM_UPGRADE_IDS = ['prod-500', 'prod-600']

export function UpgradePopup(props: UpgradePopupProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const intl = useIntl()
  const navigate = useNavigate()
  const products = useSelector(productsSelector)
  const [loading, setLoading] = React.useState(false)
  const [isAnnual, setIsAnnual] = React.useState(true)
  const planUpgradeHandle = props.options.product
  const product = planUpgradeHandle ? dispatch(getProductByHandle(planUpgradeHandle)) : null
  const planUpgradeDisplayName = capitalize(product?.name)
  const feature = props.options.feature || FEATURE_GENERIC
  const upgradeItem = feature !== FEATURE_GENERIC ? props.features[feature] || props.limits[feature] : null
  const currency = props.user?.currency as string

  React.useEffect(() => {
    dispatch(getFeaturesAndLimits()).unwrap()
  }, [dispatch])

  React.useEffect(() => {
    if (props.options?.feature) {
      googleEvent({
        action: 'Impression',
        category: 'Interactions - Pop Up',
        label: upgradeItem?.name,
        value: 0
      })

      const type = UPGRADE_EVENT_TYPES_MAP[props.options.feature] || props.options.feature
      dispatch(trackHubspotEvent(HS_EVENT_POPUP_UPGRADE, { type }))
    }
  }, [dispatch, props.options.feature, upgradeItem?.name])

  const close = () => {
    props.closeDialog()
    if (props.options.onClose) {
      props.options.onClose()
    }
  }

  const onClick = () => {
    googleEvent({
      action: 'Click',
      category: 'Interactions - Pop Up',
      label: upgradeItem?.name,
      value: 10 // eslint-disable-line no-magic-numbers
    })
  }

  const onUpgrade = () => {
    if (planUpgradeHandle) {
      const nextProduct = dispatch(getProductByHandle(planUpgradeHandle))
      const hasChargifyInfo = Boolean(props.user?.account.chargifySubscriptionId) && props.user?.account.chargifySubscriptionId !== '0'
      const upgradeHandle = isAnnual ? nextProduct?.handles.annual : nextProduct?.handles.monthly
      if (props.currentProduct?.order === 0 || !hasChargifyInfo) { // redirect Free and Trial users to payment details page
        navigate(`/signup/payment-details?plan=${upgradeHandle}`)
        return
      }

      setLoading(true)

      props.upgrade(upgradeHandle as string)
        .flatMap(() => Observable.fromPromise(props.refreshProductInfo()))
        .pipe(catchError((error) => Observable.of({ error })))
        .subscribe((response) => {
          if (response.error) {
            if (response.error.isChargify) {
              props.showNotification(intl.formatMessage(
                { id: 'upgrade-popup.notification-self-service' },
                {
                  a: (...text: any) => (
                    <a href={props.user?.account.selfServicePage} target="_blank">{text}</a>
                  )
                } as any
              ), 'error', NOTIFICATION_DURATION_LONG)
            } else {
              props.showNotification(
                intl.formatMessage({ id: 'notifications.error-w-message' }, { error: response.error.message }),
                'error'
              )
            }
            setLoading(false)
            return
          }
          props.showNotification(intl.formatMessage(
            { id: 'notifications.upgrade-success' },
            { subscription: nextProduct?.name.toUpperCase() }
          ), 'success')
          setLoading(false)
          close()
        })
    }
  }

  const dismiss = () => {
    props.dismissContentUpgradePopup()
    close()
  }

  let currentLimit: any = props.limits[props.options.feature || '']
    ? props.limits[props.options.feature || ''].plans[props.currentProduct?.id || '']
    : null

  if (typeof currentLimit === 'number' && upgradeItem?.key === LIMIT_MY_LIBRARY_STORAGE) {
    const prevProduct = product && products[products.findIndex(p => p.id === product.id) - 1]
    const prevPlanLimit = props.limits[props.options.feature || '']?.plans[prevProduct?.id || '']
    if (prevProduct) {
      currentLimit = bytesToSize(prevPlanLimit)
    } else {
      currentLimit = bytesToSize(currentLimit)
    }
  }

  let title: any = upgradeItem?.isFeature ? `${upgradeItem.name} is available on ${planUpgradeDisplayName} plan and above.`
    : currentLimit === -1
      ? `Unlimited ${upgradeItem?.name} is only available with the ${planUpgradeDisplayName} plan and above.`
      : `More than ${currentLimit} ${upgradeItem?.name} is only available with the ${planUpgradeDisplayName} plan and above.`

  if (!upgradeItem) {
    title = null
  }

  const onAnnualChange = (value: 'annual' | 'monthly') => {
    setIsAnnual(value === 'annual')
  }

  const annualPrice = product?.price.annual[currency] ? product.price.annual[currency] / 12 : 0 // eslint-disable-line no-magic-numbers
  const monthlyPrice = product?.price.monthly ? product?.price.monthly[currency] : 0

  return (
    <Dialog
      open={Boolean(planUpgradeHandle)}
      maxWidth="md"
      classes={{ paper: styles.dialog }}
      onClose={close}
    >
      <div onClick={onClick}>
        <div className={styles.content}>
          <div className={styles['img-box']}>
            <img src={upgradeItem?.image} />
          </div>
          <div className={styles['description-box']}>
            <h3 className={styles.title}>{title}</h3>
            {upgradeItem?.salesPitch && (
              <p className={styles.description} dangerouslySetInnerHTML={{ __html: upgradeItem?.salesPitch }}></p>
            )}
            {upgradeItem?.upgradePopupDismissText && (
              <a className={styles.link} onClick={dismiss}>
                <CheckIcon className={styles.icon} />
                {upgradeItem?.upgradePopupDismissText}
              </a>
            )}
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles['footer-content']}>
            <div className={styles.left}>
              <div className={styles['left-content']}>
                <p className={styles.name}>{`${planUpgradeDisplayName} plan`}</p>
                <p className={styles.price}>
                  {
                    `${CURRENCY_SIGN_MAP[currency]}${isAnnual
                      ? annualPrice.toFixed(2)
                      : monthlyPrice?.toFixed(2)}/mo`
                  }
                </p>

                <PPSwitch
                  options={[{
                    value: 'monthly',
                    label: 'Monthly'
                  }, {
                    value: 'annual',
                    label: 'Annual'
                  }]}
                  selectedValue={isAnnual ? 'annual' : 'monthly'}
                  className={styles.switch}
                  onSelectedValueChange={onAnnualChange}
                />
              </div>
            </div>
            <div className={styles.right}>
              {CONFIRM_UPGRADE_IDS.includes(product?.id as string) && isAnnual ? (
                <ConfirmDialog message={(
                  <div className={styles['confirm-msg']}>
                    <p>{`Upgrade to ${planUpgradeDisplayName} Annual?`}</p>
                    <p>
                      <FormattedMessage
                        id="upgrade-popup.confirm-upgrade-hint"
                        values={{
                          a: (...text) => (
                            // eslint-disable-next-line max-len
                            <a href="https://help.postplanner.com/en/articles/8797516-what-are-the-differences-between-plans" target="_blank">
                              {text}
                            </a>
                          )
                        }}
                      />
                    </p>
                  </div>
                )}
                >
                  {confirm => (
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      className={styles['btn-upgrade']}
                      // MUI classes={{ label: styles['btn-label'] }}
                      disabled={loading || !props.isAdmin}
                      onClick={confirm(onUpgrade)}
                    >
                      {upgradeItem?.upgradeActionText || <FormattedMessage id="actions.upgrade" />}
                    </Button>
                  )}
                </ConfirmDialog>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={styles['btn-upgrade']}
                  // MUI classes={{ label: styles['btn-label'] }}
                  disabled={loading || !props.isAdmin}
                  onClick={onUpgrade}
                >
                  {upgradeItem?.upgradeActionText || <FormattedMessage id="actions.upgrade" />}
                </Button>
              )}
              {!props.isAdmin && (
                <p className={styles['disabled-hint']}>
                  <FormattedMessage id="upgrade-popup.member-upgrade-message" />
                </p>
              )}
            </div>
          </div>
          <div className={styles['footer-link']}>
            <a
              role="button"
              href="https://help.postplanner.com/en/articles/8797516-what-are-the-differences-between-plans"
              target="_blank"
            >
              {`Learn more about the ${planUpgradeDisplayName} plan`}
            </a>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

function mapStateToProps(state: StoreState) {
  return {
    options: {
      feature: featureUpgradeSelector(state),
      product: productUpgradeSelector(state),
      onClose: onCloseUpgradeSelector(state)
    },
    currentProduct: userProductSelector(state),
    features: featuresSelector(state),
    limits: limitsSelector(state),
    user: currentUserSelector(state),
    isAdmin: userIsAdminSelector(state)
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    refreshProductInfo: () => dispatch(checkSubscription())
      .catch((error) => {
        console.log('[checkSubscription()] Error: ', error)
      }),
    closeDialog: () => dispatch(closeUpgradePrompt()),
    upgrade: (handle: string) => dispatch(upgradeProduct(handle)),
    showNotification: (text: string, type?: any, duration?: number) => dispatch(message(text, type, duration)),
    dismissContentUpgradePopup: () => dispatch(toggleContentUpgradePopupDismissed(true))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpgradePopup)
