import * as React from 'react'
import Button from '@mui/material/Button'
import styles from './PPSwitch.pcss'

interface PPSwitchProps {
  options: Array<{ value: string, label: React.ReactNode | string, icon?: React.ReactNode, disabled?: boolean }>
  selectedValue: string
  multiSelection?: boolean
  className?: string
  btnClassName?: string
  btnActiveClassName?: string
  withButtonStyles?: boolean
  id?: string
  onSelectedValueChange: (value: string) => void
}

export function PPSwitch(props: PPSwitchProps) {
  const onClick = (value: string) => {
    if (props.multiSelection) {
      const values = props.selectedValue.split(',')
      const index = values.indexOf(value)
      if (index > -1) {
        values.splice(index, 1)
      } else {
        values.push(value)
      }
      props.onSelectedValueChange(values.join(','))
      return
    }
    if (value !== props.selectedValue) {
      props.onSelectedValueChange(value)
    }
  }

  const values = props.multiSelection ? props.selectedValue.split(',') : [props.selectedValue]

  return (
    <div
      data-testid={props.id || 'pp-switch'}
      className={`${styles.switch} ${props.withButtonStyles ? '' : styles.default} ${props.className || ''}`}
    >
      {props.options.map(o => (
        <SwitchButton
          key={o.value}
          value={o.value}
          label={o.label}
          disabled={o.disabled}
          className={props.btnClassName}
          active={values.includes(o.value)}
          icon={o.icon}
          activeCassName={props.btnActiveClassName}
          onClick={onClick}
        />
      ))}
    </div>
  )
}

type SwitchButtonProps = {
  value: string,
  label: string | React.ReactNode,
  active: boolean,
  disabled?: boolean,
  icon?: React.ReactNode,
  className?: string,
  activeCassName?: string,
  onClick: (value: string) => void
}
function SwitchButton(props: SwitchButtonProps) {
  const onClick = () => {
    props.onClick(props.value)
  }

  const classes = [styles.btn, props.className || '']
  if (props.active) {
    classes.push(styles.active, props.activeCassName || '')
  }

  return (
    <Button
      variant="text"
      disabled={props.disabled}
      startIcon={props.icon}
      className={classes.join(' ')}
      onClick={onClick}
    >
      {props.label}
    </Button>
  )
}

export default PPSwitch
