import NotInitializedException from '../../../exceptions/NotInitializedException'

export class FacebookNotInitializedException extends NotInitializedException {
  constructor(message?: string) {
    super(message)

    Object.setPrototypeOf(this, FacebookNotInitializedException.prototype)
  }
}

export default FacebookNotInitializedException
