export const TIMESTAMP_MULTIPLIER = 1000

export function parseDate(date: string | number | Date): Date {
  if (typeof date === 'object') {
    return date
  }

  if (typeof date === 'number') {
    return new Date(date * TIMESTAMP_MULTIPLIER)
  }

  return new Date(date)
}
