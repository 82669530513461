import * as React from 'react'
import Chip from '@mui/material/Chip'
import { CHIP_ACTIVE_CLASS } from 'theme/theme-md'

export function CategoryChip(props: {
  id: string,
  name: string,
  active: boolean,
  className?: string,
  onClick: (id: string, name: string) => void }
) {
  const onClick = () => {
    props.onClick(props.id, props.name)
  }

  return (
    <Chip
      label={props.name}
      className={`${props.className || ''} ${props.active ? CHIP_ACTIVE_CLASS : ''}`}
      onClick={onClick}
    />
  )
}
