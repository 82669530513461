import { V1 } from './net'
import { StoreThunkAction } from 'store/state'
import { Observable } from 'rxjs/Observable'
import { tap } from 'rxjs/operators/tap'
import { noop } from 'utils/noop'
import { ContentType } from 'interfaces'

export function publishPosts(
  ids: string[],
  ppids: string[],
  postType: 'planned' | 'pending' | 'posted' | 'failed',
  all?: boolean,
  bucketId?: string,
  query?: string,
  contentTypes?: ContentType[]
): StoreThunkAction<Observable<any>> {
  return (dispatch) => dispatch(V1.publishPosts(ids, ppids, postType, all, bucketId, query, contentTypes)).pipe(tap(
    noop,
    (response) => {
      if (response.response?.error?.message) {
        throw new Error(response.response?.error?.message)
      }
    }))
}
