import { createElement } from 'react'
import MDPlayIcon from '@mui/icons-material/PlayArrowRounded'

import styles from './PlayIcon.pcss'

export function PlayIcon(
  { className, size, center, onClick }: { className?: string, size?: number, center?: boolean, onClick?: () => void }
) {
  const cssClass = `${styles.icon} ${className || ''} ${center ? styles.center : ''}`
  const style = size ? { width: size + 'px', height: size + 'px' } : {}

  return createElement(MDPlayIcon, { key: 'icon-play', className: cssClass, style, onClick })
}

export default PlayIcon
