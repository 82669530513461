/* eslint-disable no-magic-numbers */
import { CuratedStreamCategory, CURATED_STREAM_CATEGORY_MY_STREAMS, Stream } from 'interfaces'

export const getImageScale = (factor: any) => {
  if (typeof factor !== 'number') {
    return 100
  }

  if (factor < 11) {
    return 400
  }
  if (factor < 31) {
    return 225
  }
  if (factor < 61) {
    return 115
  }

  return 100
}

export function getStreamNavUrl(stream: Stream, navigation?: string, urlBrowse?: string, curatedCategory?: CuratedStreamCategory) {
  if (curatedCategory) {
    return curatedCategory === CURATED_STREAM_CATEGORY_MY_STREAMS
      ? `/content/my-streams/${stream.id}`
      : `/content/${curatedCategory}/${stream.slug}-${stream.id}`
  }

  if (urlBrowse) {
    return urlBrowse
  }

  if (navigation) {
    return `${navigation}/streams/${stream.id}`
  }

  return `/content/streams/${stream.id}`
}
