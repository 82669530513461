import * as React from 'react'
import { PlannedPost as IPlannedPost, POST_TYPE_PLANNED } from 'interfaces'
import PostBase from './PostBase'
import PlannedActionsMenu from './PlannedActionsMenu'
import styles from './PostBase.pcss'
import { useSelector } from 'react-redux'
import { profilesByPpidSelector } from 'services/destinations'
import { planToProfilesSelector } from 'services/plan/selectors'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

export interface PlannedPostProps {
  post: IPlannedPost
  isPending?: boolean
  selected: boolean
  draggableAttributes?: any
  draggableListeners?: any
  className?: string
  withBucket?: boolean
  style?: React.CSSProperties
  innerRef?: (element: any) => any
  onEdit: (post: IPlannedPost) => void
  onDelete: (postId: string) => void
  onShareNow?: (post: IPlannedPost) => void
  onCopy?: (post: IPlannedPost) => void
  onMovePost?: (postId: string, position: 'top' | 'bottom') => void
  onRecycleChange: (postId: string, value: boolean | Date | number, forceRefresh?: boolean) => void
  onToggleSelected: (postId: string) => void
  onMediaPreviewBtnClick: (post: IPlannedPost) => void
  onPostClick?: (post: IPlannedPost) => void
}

export const PlannedPost = React.memo((props: PlannedPostProps) => {
  const { post } = props
  const plans = useSelector(planToProfilesSelector)
  const profiles = useSelector(profilesByPpidSelector)
  const profile = profiles[post.ppPageId]
  const planId = Object.keys(plans).find(id => plans[id].ppids.includes(post.ppPageId))
  const plan = planId && plans[planId] ? { id: planId, isPaused: plans[planId].isPaused } : null

  const onMediaPreviewBtnClick = React.useCallback(() => {
    props.onMediaPreviewBtnClick(post)
  }, [props.post.id, props.onMediaPreviewBtnClick])

  const editPost = React.useCallback(() => {
    props.onEdit(post)
  }, [props.post, props.onEdit])

  const deletePost = React.useCallback(() => {
    props.onDelete(post.id)
  }, [props.post.id, props.onDelete])

  const copyPost = React.useCallback(() => {
    if (props.onCopy) {
      props.onCopy(post)
    }
  }, [props.post, props.onCopy])

  const moveToTop = React.useCallback(() => {
    if (props.onMovePost) {
      props.onMovePost(post.id, 'top')
    }
  }, [props.post.id, props.onMovePost])

  const moveToBottom = React.useCallback(() => {
    if (props.onMovePost) {
      props.onMovePost(post.id, 'bottom')
    }
  }, [props.post.id, props.onMovePost])

  const toggleSelected = React.useCallback(() => {
    props.onToggleSelected(post.id)
  }, [props.post.id, props.onToggleSelected])

  const onPostClick = React.useCallback(() => {
    if (props.onPostClick) {
      props.onPostClick(props.post)
    }
  }, [props.onPostClick, props.post])

  const onRecycleChange = (value: boolean | Date | number) => {
    const id = post.isVirtual ? post.virtualPostId as string : post.id
    props.onRecycleChange(id, value, post.isVirtual)
  }

  const onShareNow = () => {
    if (props.onShareNow) {
      props.onShareNow(props.post)
    }
  }

  const draggableProps = {
    ...props.draggableAttributes,
    ...props.draggableListeners
  }

  const actions = (
    <PlannedActionsMenu
      withMoveControls={props.post.autoPost && !props.isPending}
      dragHandleProps={props.post.autoPost ? draggableProps : null}
      className={styles['actions-planned']}
      withRecycle={Boolean(post.autoPost)}
      withRepeat={!post.autoPost && Boolean(post.repeatLength)}
      recycle={post.recycle}
      repeatLength={post.repeatLength}
      repeatPeriod={post.repeatPeriod}
      repeatUntil={post.repeatUntil}
      selected={props.selected}
      virtual={post.virtual}
      onToggleSelected={toggleSelected}
      onRecycleChange={onRecycleChange}
      onDelete={deletePost}
      onEdit={editPost}
      onCopy={props.onCopy ? copyPost : undefined}
      onMoveToTop={moveToTop}
      onMoveToBottom={moveToBottom}
      onShareNow={props.onShareNow ? onShareNow : undefined}
    />
  )

  return (
    <PostBase
      disableExpand
      isVirtual={post.isVirtual}
      virtual={post.virtual}
      actions={actions}
      postId={post.id}
      postType={POST_TYPE_PLANNED}
      contentType={post.contentType}
      text={post.status}
      baseTime={post.baseTime}
      timeFormatted={post.timeString}
      timezone={post.timezone}
      pending={post.dateString === 'Unknown'}
      images={post.images}
      title={post.linkTitle}
      description={post.linkDescription}
      linkUrl={post.linkUrl}
      linkDomain={post.linkDomain}
      profile={profile}
      profileNetwork={post.network}
      inactive={post.published}
      innerRef={props.innerRef}
      nativeVideoUrl={post.nativeVideoUrl}
      fbVideoUrl={post.fbVideoUrl}
      className={props.className}
      specialType={post.specialType}
      twitterPostType={post.twPostType}
      onMediaPreviewBtnClick={onMediaPreviewBtnClick}
      processingPreview={post.processingPreview}
      attributes={post.attributes}
      date={post.calendarDate}
      details={post.details}
      bucket={post.bucket}
      showBucket={props.withBucket}
      plan={plan}
      style={props.style}
      onClick={onPostClick}
    />
  )
})

export const DraggablePlannedPost = React.memo((props: PlannedPostProps) => {
  const { attributes, listeners, transform, transition, isDragging, setNodeRef } = useSortable({
    id: props.post.id,
    data: { post: props.post },
    disabled: !props.post.autoPost
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? '2' : '1'
  } as any

  return (
    <PlannedPost
      innerRef={setNodeRef}
      style={style}
      draggableAttributes={attributes}
      draggableListeners={listeners}
      {...props}
    />
  )
})
