import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import ArrowRight from '@mui/icons-material/KeyboardArrowRight'
import ArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import { useDimensions } from 'hooks/useDimensions'

export const CARD_LIST_HEIGHT_SMALL = 148
export const CARD_LIST_HEIGHT_DEFAULT = 240
export const ITEM_WIDTH_DEFAULT = 240
export const ITEM_WIDTH_SMALL = 144
export const CARD_LIST_HEIGHT_BIG = 272
const SPACING_VERTICAL_DEFAULT = 1.25
const SCROLL_BY_CONTAINER_RATIO = 0.75 // scroll step, relative to the container size (75%)

export interface CardListProps {
  children?: React.ReactNode[]
  itemWidth: number | 'auto'
  listHeight: number
  containerClassName?: string
  listClassName?: string
  disabled?: boolean
  skipCardHoverCheck?: boolean
  scrollBy?: number
  verticalSpacing?: number

  onScrolled?(direction: 'left' | 'right'): void
  onScrollLimit?(): void
}

import styles from './CardList.pcss'

export function CardList(props: CardListProps) {
  const [xPosition, _setXPosition] = React.useState(0)
  const [containerRef, containerRect] = useDimensions([React.Children.count(props.children)], true)
  const [listRef, listRect] = useDimensions([React.Children.count(props.children)], true)

  const observerOptions: IntersectionObserverInit = {
    root: containerRef.current,
    threshold: [0, 1]
  }
  const onIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(e => {
      if (e.boundingClientRect.height > e.intersectionRect.height) {
        // vertical intersection, skipping
        return
      }
      e.target.classList.toggle(styles['no-hover'], e.isIntersecting && e.intersectionRatio < 1)
    })
  }
  const observer = React.useRef(new IntersectionObserver(onIntersection, observerOptions)).current as IntersectionObserver

  let scrollStep: number = props.scrollBy || 0
  if (scrollStep === 0) {
    scrollStep = props.itemWidth !== 'auto'
      ? containerRect.width - props.itemWidth
      : containerRect.width * SCROLL_BY_CONTAINER_RATIO
  }

  const vertSpacing = typeof props.verticalSpacing === 'undefined' ? SPACING_VERTICAL_DEFAULT : props.verticalSpacing
  const maxXPosition = Math.max(listRect.width - containerRect.width, 0) // Cannot be negative value
  const xRef = React.useRef(xPosition)

  const setXPosition = (pos: number) => {
    _setXPosition(pos)
    xRef.current = pos
  }

  const valueWithinRange = (value: number) => {
    if (value < 0) {
      return 0
    }
    if (value > maxXPosition) {
      return maxXPosition
    }
    return value
  }

  // EXPL: maxXPosition can change on window resize. Force current x position to be within the valid range
  React.useEffect(() => {
    const position = valueWithinRange(xRef.current)
    if (xRef.current !== position) {
      setXPosition(position)
    }
  }, [maxXPosition])

  // EXPL: Watch children for visibility changes. If a child element is intersecting with container edges,
  // we want to make it "no-hoverable" using the no-hover css class
  React.useEffect(() => {
    if (props.skipCardHoverCheck || !listRef.current) {
      return
    }

    for (let i = 0; i < listRef.current.children.length; i++) {
      const element = listRef.current.children.item(i)
      if (element) {
        observer.observe(element)
      }
    }

    return () => {
      observer.disconnect()
    }
  }, [props.children?.length])

  const scrollLeft = () => {
    const next = valueWithinRange(xPosition - scrollStep)
    setXPosition(next)

    if (props.onScrolled) {
      props.onScrolled('left')
    }
  }

  const scrollRight = () => {
    const next = valueWithinRange(xPosition + scrollStep)
    setXPosition(next)

    if (next >= maxXPosition && props.onScrollLimit) {
      props.onScrollLimit()
    }
    if (props.onScrolled) {
      props.onScrolled('right')
    }
  }

  const buttonStyles = {
    height: `${props.listHeight}px`
  }

  const listStyles = {
    height: `${props.listHeight}px`,
    transform: `translateX(${-xPosition}px)`
  }

  const containerStyles = {
    height: `${props.listHeight * vertSpacing}px`
  }

  return (
    <div
      ref={containerRef as any}
      style={containerStyles}
      className={`${styles.container} ${props.containerClassName || ''}`}
    >
      <IconButton
        style={buttonStyles}
        className={`${styles['scroll-left']} ${xPosition === 0 ? styles.hide : ''}`}
        disableRipple
        disabled={props.disabled}
        onClick={scrollLeft}
        size="large"
      >
        <ArrowLeft className={styles['arrow-icon']} />
      </IconButton>
      <IconButton
        style={buttonStyles}
        className={`${styles['scroll-right']} ${xPosition === maxXPosition ? styles.hide : ''}`}
        disableRipple
        disabled={props.disabled}
        onClick={scrollRight}
        size="large"
      >
        <ArrowRight className={styles['arrow-icon']} />
      </IconButton>
      <ul
        ref={listRef as any}
        style={listStyles}
        className={`${styles.list} ${props.listClassName || ''}`}
      >
        {
          React.Children.toArray(props.children).map((child: React.ReactElement, index: number) => {
            return (
              <li className={styles.item} key={child.key || index}>
                {child}
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

CardList.defaultProps = {
  listHeight: CARD_LIST_HEIGHT_DEFAULT,
  itemWidth: ITEM_WIDTH_DEFAULT,
  skipCardHoverCheck: true
}

export default CardList
