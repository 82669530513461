import * as React from 'react'
import { ContentType, ContentSource, Stream, Feed, FilterType } from 'interfaces'
import { instanceOfStream } from 'services/content/util'
import { NavLink } from 'react-router-dom'
import FeedIcon from 'components/FeedIcon'
import StreamFeedsNavigation from 'components/StreamView/StreamFeedsNavigation'
import SaveButton from 'components/SaveButton'
import Tooltip from '@mui/material/Tooltip'
import IconPrivate from '@mui/icons-material/VisibilityOffOutlined'
import { FormattedMessage } from 'react-intl'
import ContentTypeFilter from 'components/ContentTypeFilter'

const isEqual = require('react-fast-compare')
import styles from './ContentLayoutHeader.pcss'

export interface ContentLayoutHeaderProps {
  title: string
  source: ContentSource
  browseLinkText: string
  backLinkText: string
  browseUrl?: string
  filter: FilterType
  sourceSaved: boolean
  contentTypes: ContentType[]
  expanded: boolean
  saveLoading: boolean
  prefix?: string
  hideNavLink?: boolean
  onExpandLinkClick: () => void
  onBackLinkClick: () => void
  onFilterChanged: (filter: FilterType) => void
  onSave: () => void
}

interface ContentLayoutHeaderState { }

export class ContentLayoutHeader extends React.Component<ContentLayoutHeaderProps, ContentLayoutHeaderState> {
  private sourceSaved: boolean // keeps initial state of the "Save" button

  constructor(props: ContentLayoutHeaderProps) {
    super(props)

    this.sourceSaved = this.props.sourceSaved
  }

  shouldComponentUpdate(nextProps: ContentLayoutHeaderProps) {
    return !isEqual(this.props, nextProps)
  }

  render() {
    const {
      onExpandLinkClick,
      browseLinkText,
      backLinkText,
      filter,
      contentTypes,
      onFilterChanged,
      title,
      expanded,
      onBackLinkClick,
      browseUrl,
      sourceSaved,
      source,
      saveLoading,
      onSave,
      hideNavLink,
      prefix
    } = this.props
    const typeFilter = expanded
      ? null
      : (
        <ContentTypeFilter
          all={false}
          options={contentTypes}
          selected={filter}
          onFilterSelected={onFilterChanged}
        />
      )
    const navUrl = browseUrl || '/'
    const isStream = instanceOfStream(source)

    const navLink = expanded
      ? <a onClick={onBackLinkClick} className={styles.navigation}>{backLinkText}</a>
      : <NavLink to={navUrl} onClick={onExpandLinkClick} className={styles.navigation}>{browseLinkText}</NavLink>

    const avatar = isStream
      ? null
      : <FeedIcon feed={source as Feed} className={styles.avatar} />

    const expandedClass = expanded ? styles.expanded : ''
    const feeds = isStream && expanded
      ? <StreamFeedsNavigation stream={source as Stream} prefix={prefix || '/content'} />
      : null

    const privateIcon = isStream && (source as Stream).isPrivate && (
      <Tooltip placement="top" title={<FormattedMessage id="find.my-streams.labels.private-stream" />}>
        <IconPrivate className={styles['icon-private']} />
      </Tooltip>
    )

    // const lockedIcon = isStream && (source as Stream).originalId && (
    //   <Tooltip placement="top" title={<FormattedMessage id="find.my-streams.labels.unowned-stream" />}>
    //     <img src="/static/img/pp-logo-green.svg" width="20" />
    //   </Tooltip>
    // )

    return (
      <React.Fragment>
        <header className={`${styles.header} ${expandedClass}`} data-test="content-layout-header">
          {
            // NOTE: We want to show Save button always for feeds, but for streams - only if not saved
            (!this.sourceSaved || !isStream) && (
              <SaveButton
                loading={saveLoading}
                onClick={onSave}
                saved={isStream ? sourceSaved : undefined}
                className={styles['btn-save']}
              />
            )
          }
          {avatar}
          {privateIcon}
          {/* {lockedIcon} */}
          <h2 className={`${styles.title} text-ellipsis`} title={title}>{title}</h2>
          {!hideNavLink && navLink}
          {typeFilter}
        </header>
        {feeds}
      </React.Fragment>
    )
  }
}

export default ContentLayoutHeader
