import React, { useState } from 'react'
import styles from './BulkActions.pcss'
import { useDispatch, useSelector } from 'react-redux'
import {
  composerBulkFileSelector,
  composerPinterestPostStatusSelector,
  composerSelectedBucketIdSelector,
  composerSelectedProfilesSelector,
  multiPostsCountSelector,
  multiPostsUploadingSelector,
  PINTEREST_BOARD_NOT_SELECTED_MESSAGE_KEY
} from 'services/compose/selectors'
import { StoreThunkDispatch } from 'store/state'
import { message, warning } from 'services/snackbar'
import { Subject } from 'rxjs/Subject'
import { createMultiPost } from 'services/compose/multi/create'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { BULK_UPLOAD_ERROR_MESSAGE, BULK_UPLOAD_ERROR_MESSAGE_TIMEOUT } from 'utils/composer'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '@mui/material/Button'
import { CreatePostRequestData } from 'shared/types'
import { LIMIT_SCHEDULED_POSTS_COUNT } from 'shared/constants'
import { createPost, getPostData } from 'services/compose/create/createPost'
import { checkProductLimit } from 'services/product'
import toCamelCase from 'utils/toCamelCase'
import { setComposerBulkUploadFileUrl, handleMultiPostError } from 'services/compose'
import ComposerActionsButton from './ComposerActionsButton'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import AddToBucketButton from './AddToBucketButton'

function BulkActions(props: { onMultiPostCreated: (redirectUrl?: string) => void, onBulkUploadStarted?: (redirectUrl?: string) => void }) {
  const { onMultiPostCreated } = props
  const intl = useIntl()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const selectedProfiles = useSelector(composerSelectedProfilesSelector)
  const bulkUploadFile = useSelector(composerBulkFileSelector)
  const multiPostsCount = useSelector(multiPostsCountSelector)
  const multiPostsUploading = useSelector(multiPostsUploadingSelector)
  const selectedBucketId = useSelector(composerSelectedBucketIdSelector)
  const pinterestPostStatus = useSelector(composerPinterestPostStatusSelector)
  const [loading, setLoading] = useState(false)

  const createMulti$ = React.useRef<Subject<'queue' | 'next'>>()
  const createBulk$ = React.useRef<Subject<CreatePostRequestData>>()

  React.useEffect(() => {
    createBulk$.current = new Subject()
    createBulk$.current.flatMap((data) => {
      if (props.onBulkUploadStarted) {
        const redirectUrl = selectedBucketId ? `/posts/buckets/${selectedBucketId}/posts` : '/posts/planned/all'
        props.onBulkUploadStarted(redirectUrl)
      }
      return dispatch(createPost(data, selectedBucketId)).pipe(catchError(error => {
        if (!error.response.error) {
          // EXPL: There's no error data, show generic error message
          dispatch(message(intl.formatMessage({ id: 'errors.create-post' }), 'error'))
          return Observable.of({ error: true, handled: true })
        }
        const errorData = toCamelCase(error.response.error)
        return Observable.of({ error: errorData, postData: data } as any)
      }))
    })
      .subscribe((response: any) => {
        dispatch(setComposerBulkUploadFileUrl({ url: undefined }))
        if (response.handled) {
          return
        }

        if (response.error) {
          const { message: msg, messageKey, data, bulkUploadResultsUrl } = response.error
          if (bulkUploadResultsUrl) {
            dispatch(warning(
              BULK_UPLOAD_ERROR_MESSAGE,
              'download failed posts',
              () => { window.open(bulkUploadResultsUrl, '_self') },
              BULK_UPLOAD_ERROR_MESSAGE_TIMEOUT
            ))
            return
          }

          const newPostsCount = response.postData.ppPageIds.length
          const nextPlannedPostsCount = data.plannedPostsCount + newPostsCount

          if (nextPlannedPostsCount > data.plannedPostsLimit) {
            const withinLimit = dispatch(checkProductLimit(LIMIT_SCHEDULED_POSTS_COUNT, nextPlannedPostsCount))
            if (!withinLimit) {
              dispatch(message(intl.formatMessage({ id: 'composer.notifications.planned-limit-reached' }), 'warning'))
            }
          } else {
            dispatch(message(intl.formatMessage({ id: messageKey, defaultMessage: msg }), 'error'))
          }
          return
        }
        dispatch(message(intl.formatMessage({ id: 'notifications.post-added-to-plan' }), 'success'))
      })

    return () => {
      createBulk$.current?.unsubscribe()
    }
  }, [dispatch, selectedBucketId, intl])

  React.useEffect(() => {
    createMulti$.current = new Subject()
    createMulti$.current
      .flatMap((shareType) => {
        setLoading(true)
        dispatch(message(intl.formatMessage({ id: 'composer.notifications.create-post-started' }), 'info'))
        return dispatch(createMultiPost(shareType))
          .pipe(catchError(error => {
            if (!error.response.error) {
              // EXPL: There's no error data, show generic error message
              dispatch(message(intl.formatMessage({ id: 'errors.create-post' }), 'error'))
              return Observable.of({ error: true, handled: true })
            }
            const errorData = toCamelCase(error.response.error)
            return Observable.of({ error: errorData } as any)
          }))
      })
      .subscribe(response => {
        setLoading(false)
        if (response.posts?.length > 0) {
          if (response.downloadReport) {
            dispatch(warning(
              intl.formatMessage({ id: 'composer.notifications.multipost-failed' }),
              intl.formatMessage({ id: 'composer.notifications.multipost-get-error-report' }),
              () => { window.open(response.downloadReport, '_self') },
              BULK_UPLOAD_ERROR_MESSAGE_TIMEOUT
            ))
          }

          dispatch(message(intl.formatMessage({ id: 'composer.notifications.multipost-failed' }), 'warning'))
          dispatch(handleMultiPostError(response.posts))
        } else if (response.error) {
          const { message: msg, messageKey, data } = response.error
          const nextPlannedPostsCount = data.plannedPostsCount + multiPostsCount

          if (nextPlannedPostsCount > data.plannedPostsLimit) {
            const withinLimit = dispatch(checkProductLimit(LIMIT_SCHEDULED_POSTS_COUNT, nextPlannedPostsCount))
            if (!withinLimit) {
              dispatch(message(intl.formatMessage({ id: 'composer.notifications.planned-limit-reached' }), 'warning'))
            }
          } else {
            dispatch(message(intl.formatMessage({ id: messageKey, defaultMessage: msg }), 'error'))
          }
        } else {
          const redirectUrl = selectedBucketId ? `/posts/buckets/${selectedBucketId}/posts` : '/posts/planned/all'
          onMultiPostCreated(redirectUrl)
          dispatch(message(intl.formatMessage({ id: 'notifications.multipost-added' }), 'success'))
        }
      })

    return () => {
      createMulti$.current?.unsubscribe()
    }
  }, [dispatch, intl, onMultiPostCreated, multiPostsCount, selectedBucketId])

  const queueMultiPosts = () => {
    createMulti$.current?.next('queue')
  }

  const shareNextMultiPosts = () => {
    createMulti$.current?.next('next')
  }

  const queueBulkPosts = React.useCallback(() => {
    const data = dispatch(getPostData('queue'))
    createBulk$.current?.next(data)
  }, [dispatch])

  const withError = pinterestPostStatus.caption.errors.includes(PINTEREST_BOARD_NOT_SELECTED_MESSAGE_KEY)

  React.useEffect(() => {
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.shiftKey && e.key === 'Enter') {
        if (bulkUploadFile) {
          if (withError) {
            dispatch(message('There\'s an issue with your post. Check the network options in Step 3.', 'warning'))
            return
          }
          queueBulkPosts()
          return
        }

        if (!loading && multiPostsCount > 0) {
          createMulti$.current?.next('queue')
        }
      }
    }
    window.addEventListener('keyup', onKeyUp)
    return () => {
      window.removeEventListener('keyup', onKeyUp)
    }
  }, [bulkUploadFile, dispatch, loading, multiPostsCount, queueBulkPosts, withError])

  const shareNextBulkPosts = () => {
    const data = dispatch(getPostData('next'))
    createBulk$.current?.next(data)
  }

  const onAddToBucket = (shareType: 'queue' | 'next') => {
    createMulti$.current?.next(shareType)
  }

  const bulkUploadPostsCount = bulkUploadFile ? bulkUploadFile.rows * selectedProfiles.length : 0

  return (
    <div className={styles.container} data-testid="bulk-actions">
      {bulkUploadFile && (
        <React.Fragment>
          {selectedBucketId && (
            <Button
              variant="contained"
              color="primary"
              disabled={withError}
              className={styles.btn}
              onClick={queueBulkPosts}
            >
              <FormattedMessage
                id="composer.actions.bulk-upload-bucket"
                values={{ posts: bulkUploadPostsCount }}
              />
            </Button>
          )}
          {!selectedBucketId && (
            // <React.Fragment>
            //   <Button
            //     variant="contained"
            //     color="primary"
            //     disabled={withError}
            //     className={styles.btn}
            //     onClick={shareNextBulkPosts}
            //   >
            //     <FormattedMessage id="composer.actions.bulk-upload-next" />
            //   </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={withError}
              className={styles.btn}
              onClick={queueBulkPosts}
            >
              <FormattedMessage
                id="composer.actions.bulk-upload-queue"
                values={{ count: bulkUploadPostsCount }}
              />
            </Button>
            // </React.Fragment>
          )}
        </React.Fragment>
      )}
      {multiPostsCount > 0 && !selectedBucketId && (
        <ComposerActionsButton
          disabled={loading || multiPostsUploading}
          postsCount={multiPostsCount}
          onAddToPlan={queueMultiPosts}
          onShareNext={shareNextMultiPosts}
        />
      )}
      {multiPostsCount > 0 && selectedBucketId && (
        <AddToBucketButton
          disabled={loading || multiPostsUploading}
          postsCount={multiPostsCount}
          btnClassName={styles.btn}
          onSubmit={onAddToBucket}
        />
      )}
      <Backdrop open={loading} className={styles.backdrop}>
        <div className={styles['loader-content']}>
          <CircularProgress />
          <p>Creating posts, please wait...</p>
        </div>
      </Backdrop>
    </div>
  )
}

export default BulkActions
