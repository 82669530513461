import * as React from 'react'
import { List, PostDestination, WithIntl } from 'interfaces'
import { FormattedMessage, injectIntl } from 'react-intl'
import Button from '@mui/material/Button'
import ProfileAvatarGridList from 'components/ProfileAvatarGridList'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DeleteIcon from '@mui/icons-material/Close'
import IconAdd from '@mui/icons-material/Add'
import EditableLabel from 'components/EditableLabel'
import ConfirmDialog from 'components/ConfirmDialog'
import Loader from 'components/SimpleLoader'
import { ALL_LIST_KEY } from 'services/lists/selectors'
import styles from './ListEditorDialog.pcss'
import IconButton from '@mui/material/IconButton'
import { sortByKeyAscending } from 'shared'
const LOADER_SIZE_SM = 16

export interface ListEditorDialogProps extends WithIntl {
  open: boolean
  lists: { [id: string]: List }
  profiles: { [id: string]: PostDestination }
  className?: string
  loading?: boolean
  creatingList?: boolean
  onClose: () => void
  onCreateList: (name: string, profiles: string[]) => void
  onUpdateList: (id: string, name: string, profiles: string[]) => void
  onDeleteList: (id: string) => void
}

export function ListEditorDialog(props: ListEditorDialogProps) {
  const [activeEditListId, setActiveEditListId] = React.useState<string | undefined>(undefined)
  const [isCreatingList, setIsCreatingList] = React.useState(false)
  const [newListName, setNewListName] = React.useState('')
  const [newListError, setNewListError] = React.useState('')
  const [newListProfiles, setNewListProfiles] = React.useState<string[]>([])
  const activeEditList = activeEditListId ? props.lists[activeEditListId] : undefined

  const handleEditListClick = (id: string) => () => {
    setActiveEditListId(id)
    setNewListName('')
    setIsCreatingList(false)
    setNewListError('')
    setNewListProfiles([])
  }

  const handleNewListNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setNewListName(value)
  }

  const handleNewListInputFocus = () => {
    setActiveEditListId(undefined)
    setNewListError('')
  }

  const handleNewListSave = () => {
    if (props.creatingList) {
      return
    }
    const name = newListName.trim()
    if (!name) {
      setNewListError(props.intl.formatMessage({ id: 'list-manager.labels.error-empty-name' }))
      return
    }
    if (newListProfiles.length === 0) {
      setNewListError(props.intl.formatMessage({ id: 'list-manager.labels.error-empty-profiles' }))
      return
    }

    props.onCreateList(name, newListProfiles)
    setNewListError('')
    setNewListName('')
    setNewListProfiles([])
    setIsCreatingList(false)
  }

  const onEditListProfileClick = (id: string) => {
    if (activeEditList?.id === ALL_LIST_KEY) {
      return
    }
    if (activeEditList) {
      const i = activeEditList.pages.indexOf(id)
      const pages = activeEditList.pages.slice()
      if (i !== -1) {
        pages.splice(i, 1)
      } else {
        pages.push(id)
      }
      props.onUpdateList(activeEditList.id, activeEditList.name, pages)
      return
    }

    const idIndex = newListProfiles.indexOf(id)
    const nextIds = newListProfiles.slice()
    if (idIndex !== -1) {
      nextIds.splice(idIndex, 1)
    } else {
      nextIds.push(id)
    }
    setNewListProfiles(nextIds)
  }

  const onListNameChange = (name?: string) => {
    if (name && activeEditList) {
      props.onUpdateList(activeEditList.id, name, activeEditList.pages)
    }
  }

  const onDeleteListClick = () => {
    if (activeEditList) {
      props.onDeleteList(activeEditList.id)
      setActiveEditListId(undefined)
    }
  }

  const toggleCreateListMode = () => {
    setIsCreatingList(current => !current)
    setActiveEditListId(undefined)
  }

  const onDoneEditing = () => {
    setActiveEditListId(undefined)
  }

  return (
    <Dialog open={props.open} classes={{ paper: styles.dialog }} onClose={props.onClose}>
      <IconButton className={styles['btn-close']} onClick={props.onClose} size="large">
        <DeleteIcon />
      </IconButton>
      {props.loading && <Loader />}
      {!props.loading && (
        <section
          data-test="list-manager"
          className={`${styles.container} ${props.className || ''}`}
        >
          <div className={styles.content}>
            <div className={styles['lists-box']}>
              <div className={styles.section}>
                <h3 className={styles['section-title']}>
                  <span>
                    <FormattedMessage id="list-manager.labels.edit" />
                  </span>
                  {Boolean(activeEditListId) && (
                    <Button variant="contained" color="primary" size="small" onClick={onDoneEditing}>
                      <FormattedMessage id="actions.done" />
                    </Button>
                  )}
                </h3>
                <ul className={styles['ul-lists']}>
                  {
                    Object.values(props.lists).sort(sortByKeyAscending('order')).map(list => {
                      const active = activeEditList && activeEditList.id === list.id
                      return (
                        <li
                          key={list.id}
                          className={`text-ellipsis ${styles['li-list-name']} ${active ? styles.active : ''}`}
                          onClick={handleEditListClick(list.id)}
                        >
                          <EditableLabel
                            value={list.name}
                            editMode={list.id === ALL_LIST_KEY ? false : Boolean(active)}
                            inputClassName={styles['input-edit-list']}
                            className={styles['input-label']}
                            onChange={onListNameChange}
                          />
                          {(!active || list.id === ALL_LIST_KEY) && (
                            <span className={styles.count}>{`(${list.connectedPages.length})`}</span>
                          )}
                          {
                            list.id !== ALL_LIST_KEY && (
                              <ConfirmDialog message={props.intl.formatMessage({ id: 'list-manager.labels.confirm-delete' })}>
                                {(confirm) => (
                                  <DeleteIcon role="button" className={styles['btn-delete']} onClick={confirm(onDeleteListClick)} />
                                )}
                              </ConfirmDialog>
                            )
                          }
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
              <div className={`${styles.section} ${styles['section-create']} ${isCreatingList ? styles.active : ''}`}>
                <h3 className={styles['section-title']} onClick={toggleCreateListMode}>
                  <span>
                    <FormattedMessage id="list-manager.labels.create-new" />
                  </span>
                  {!isCreatingList && <IconAdd className={styles['icon-add']} />}
                </h3>
                {isCreatingList && (
                  <div className={styles['input-box']}>
                    <TextField
                      error={Boolean(newListError)}
                      helperText={newListError}
                      value={newListName}
                      InputProps={{
                        classes: {
                          input: styles.input,
                          root: styles['input-root']
                        }
                      }}
                      placeholder={props.intl.formatMessage({ id: 'list-manager.labels.new-list-hint' })}
                      onChange={handleNewListNameChange}
                      onFocus={handleNewListInputFocus}
                    />
                    <Button
                      disabled={props.creatingList}
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleNewListSave}
                    >
                      {props.creatingList ? <CircularProgress size={LOADER_SIZE_SM} /> : <FormattedMessage id="actions.save" />}
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className={styles['profiles-box']}>
              <div className={styles.section}>
                <h3 className={styles['section-title']}>
                  <FormattedMessage id="list-manager.labels.profiles-in-list" />
                  {isCreatingList && ` (${newListProfiles.length})`}
                  {activeEditList && ` (${activeEditList.connectedPages.length})`}
                </h3>
                {(isCreatingList || activeEditList) ? (
                  <ProfileAvatarGridList
                    profiles={props.profiles}
                    selectedIds={activeEditList ? activeEditList.pages : newListProfiles}
                    className={`${styles['profiles-grid']} ${activeEditList?.id === ALL_LIST_KEY ? styles.readonly : ''}`}
                    itemClassName={styles['grid-item']}
                    onProfileClick={onEditListProfileClick}
                  />
                ) : (
                  <p className={styles.message}>
                    <FormattedMessage id="list-manager.labels.list-not-selected" />
                  </p>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </Dialog>
  )
}

export default injectIntl(ListEditorDialog)
