import IconButton from '@mui/material/IconButton'
import GifPlayer from 'components/GifPlayer'
import { NativeVideoPlayer } from 'components/VideoPlayer'
import { GIF_TYPE, MyFile, PHOTO_TYPE, VIDEO_TYPE } from 'interfaces'
import React from 'react'
import styles from './MyFilePreview.pcss'
import DeleteIcon from '@mui/icons-material/Close'

interface MyFilePreviewProps {
  file: MyFile
  disabled?: boolean
  className?: string
  onRemove: (file: MyFile) => void
}

export function MyFilePreview(props: MyFilePreviewProps) {
  const { file, className, disabled, onRemove } = props
  const [imgSrc, setImgSrc] = React.useState(props.file.thumbUrl)

  const onImageError = () => {
    if (file.type === PHOTO_TYPE && imgSrc !== props.file.url) {
      setImgSrc(props.file.url)
    }
  }

  const remove = () => {
    onRemove(file)
  }

  return (
    <div className={`${styles.wrapper} ${className || ''} ${disabled ? styles.disabled : ''}`}>
      <IconButton size="small" className={styles['btn-delete']} onClick={remove}>
        <DeleteIcon className={styles.icon} />
      </IconButton>
      {file.type === PHOTO_TYPE && (
        <img src={imgSrc} className={styles.img} onError={onImageError} />
      )}
      {file.type === VIDEO_TYPE && (
        <NativeVideoPlayer
          videoUrl={file.url}
          togglePlayOnHover
          muted
          loop
          playBtnClassName={styles['icon-vid']}
          className={styles['video-box']}
        />
      )}
      {file.type === GIF_TYPE && (
        <GifPlayer
          loop
          small
          gifUrl={file.url}
          className={styles['gif-box']}
        />
      )}
    </div>
  )
}
