import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'

const ENDPOINT_TOGGLE_EXTERNAL_POSTS = `${LEGACY_URL}/posted_gathering/toggle_posted_by_external_apps_v3`
const ENDPOINT_FORCE_FETCH_EXTERNAL_POSTS = `${LEGACY_URL}/posted_gathering/refresh_now_v3`

export const V1 = Object.freeze({
  toggleExternalPosts: function () {
    return function (ppid: string) {
      const builder = new RequestBuilder(ENDPOINT_TOGGLE_EXTERNAL_POSTS).asPost().expectJSON().asFormUrlEncoded()

      return authorizedPipe<any>(
        builder
          .body({ pp_page_id: ppid })
          .build()
      )
    }
  }(),
  forceFetchExternalPosts: function () {
    return function (ppid: string) {
      const builder = new RequestBuilder(ENDPOINT_FORCE_FETCH_EXTERNAL_POSTS).asPost().expectJSON().asFormUrlEncoded()

      return authorizedPipe<any>(
        builder
          .body({ pp_page_id: ppid })
          .build()
      )
    }
  }()
})
