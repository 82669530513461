import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import MemoryFilePreviewCard, { MyFilePreview } from 'components/MemoryFilePreviewCard'
import { FormattedMessage, injectIntl } from 'react-intl'
import { WithIntl, FileFolder, FileType, IndexedObject, MyFile } from 'interfaces'
import { PHOTO_ROTATION_TOGGLE, HALF_ROUND_DEGREES, DEFAULT_STUDIO_EXPORT_FILE_NAME } from 'utils/file'
import { ColorGradient } from 'utils/colors'
import { Observable } from 'rxjs/Observable'
import useAsyncAction from 'hooks/useAsyncAction'
import { getMediaDimensions, getFileTypeByMimeType, mapFileTypeToSizeLimit } from 'utils/file/helpers'
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import FileFolderCard from 'components/FileFolderCard'
import CreateFileFolderForm from 'components/CreateFileFolderForm'
import { ConnectedFileInput } from 'components/FileHelpers/ConnectedFileInput'
import { noop } from 'utils/noop'
import { bytesToSize } from 'utils/format/byteToSize'

import styles from './MultiUploadManager.pcss'
import PPSwitch from 'components/PPSwitch'
import { FileUploadInfo } from 'services/uploads/state'
import { SnackType } from 'services/snackbar/interfaces/PendingTreat'

const MIN_FOLDER_NAME_LENGTH = 2
const FILTER_VISIBLE_FOR_FOLDERS_COUNT = 5
const BASE_TEN = 10

export interface MultiUploadManagerProps extends WithIntl {
  folders: FileFolder[]
  files: { [name: string]: File }
  dialogOpen: boolean
  fileSizeLimits: { [key: string]: number }
  copyFile?: MyFile
  setFiles: (files: { [name: string]: File }) => void
  onCloseDialog: () => void
  createFolder: (
    name: string,
    color: string,
    isPrivate: boolean,
    featuredImage?: File,
    coverImages?: File[]
  ) => Promise<any>
  uploadFiles: (files: Array<FileUploadInfo>, folders: FileFolder[]) => void
  showNotification: (text: string, type?: SnackType, timeout?: number) => void
  clearCopyFile: () => void
  onCopyFile: (file: MyFile, folder: { id: string, name: string }) => void
  onCopyFileToNewFolder: (file: MyFile, folder: { name: string, color: string, isPrivate: boolean }) => void
}

export function MultiUploadManager(props: MultiUploadManagerProps) {
  const { files, setFiles } = props
  const [imagesSrcs, setImagesSrcs] = React.useState<IndexedObject<string>>({})
  const [filter, setFilter] = React.useState('')
  const [fileErrors, setFileErrors] = React.useState<{ [name: string]: string }>({})
  const [fileRotation, setFileRotation] = React.useState<{ [name: string]: string }>({})
  const [selectedFolders, setSelectedFolders] = React.useState<{ [id: string]: boolean }>({})
  const [mode, setMode] = React.useState<'create' | 'save'>('create')
  const [isCreatingFolder, setIsCreatingFolder] = React.useState(false)
  const newFolderName = React.useRef('')

  const createNewFolder = (name: string, color: string, isPrivate?: boolean, featuredImage?: File, coverImages?: File[]) => {
    setIsCreatingFolder(true)
    props.createFolder(name, color, Boolean(isPrivate), featuredImage, coverImages)
      .then(response => {
        const f = response?.folder
        if (f) {
          uploadFiles({ id: f.id, name: f.name })
          newFolderName.current = ''
        }
      })
      .catch((err) => {
        const msg = err.response?.message || err.message
        props.showNotification(
          props.intl.formatMessage({ id: 'notifications.error-w-message' }, { error: msg }),
          'error',
          4000 // eslint-disable-line no-magic-numbers
        )
      })
      .finally(() => setIsCreatingFolder(false))
  }

  React.useEffect(() => {
    // EXPL: Reset rotation of image added from Content Studio when dialog is closed.
    // This prevents consecutive added images to appear rotated by default.
    if (!props.dialogOpen && fileRotation[DEFAULT_STUDIO_EXPORT_FILE_NAME] !== '0') {
      setFileRotation(current => ({ ...current, [DEFAULT_STUDIO_EXPORT_FILE_NAME]: '0' }))
    }
  }, [fileRotation, props.dialogOpen])

  const modeOptions = [{
    value: 'create',
    label: 'New folder'
  }]

  if (props.folders.length > 0) {
    modeOptions.push({
      value: 'save',
      label: 'Existing folder'
    })
  }

  const onFileError = (fileName: string, error: string) => {
    setFileErrors(prevState => ({ ...prevState, [fileName]: error }))
  }

  const onFolderClick = (folder: FileFolder) => {
    const selection = { ...selectedFolders, [folder.id]: !selectedFolders[folder.id] }
    setSelectedFolders(selection)
  }

  const clearAllFiles = () => {
    setFiles({})
    setFileErrors({})
    setImagesSrcs({})
  }

  const close = () => {
    props.onCloseDialog()
    clearAllFiles()
    setSelectedFolders({})
  }

  const createFolder = (
    name: string,
    color: ColorGradient,
    isPrivate?: boolean | undefined,
    featuredImage?: string | File,
    coverImages?: string[]
  ) => {
    const folderName = name.trim()
    if (folderName.length < MIN_FOLDER_NAME_LENGTH) {
      return true
    }
    newFolderName.current = name
    let featuredImageFile = typeof featuredImage === 'string' ? props.files[featuredImage || ''] : undefined
    if (!featuredImageFile && typeof featuredImage === 'object') {
      featuredImageFile = featuredImage
    }

    if (props.copyFile) {
      props.onCopyFileToNewFolder(
        props.copyFile,
        { name: folderName, color: `${color.from},${color.to}`, isPrivate: Boolean(isPrivate) }
      )
      close()
      return true
    }

    const coverImagesFiles = coverImages ? coverImages.map(name => props.files[name]) : undefined
    createNewFolder(folderName, `${color.from},${color.to}`, isPrivate, featuredImageFile, coverImagesFiles)
    return true
  }

  const toggleFileRotation = (fileName: string) => {
    const current = fileRotation[fileName] || 0
    const next = { [fileName]: PHOTO_ROTATION_TOGGLE[current].next }
    setFileRotation(prevRotations => ({ ...prevRotations, ...next }))
  }

  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setFilter(value)
  }

  const resetFilter = () => {
    setFilter('')
  }

  const uploadFiles = (folder?: { id: string, name: string }) => {
    const validSelectedFileNames = Object.keys(files).filter(name => files[name] && !fileErrors[name])
    const validSelectedFiles = validSelectedFileNames.map(name => {
      const file = files[name]
      const rotateBy = parseInt(fileRotation[name] || '0', BASE_TEN)
      const withRotation = rotateBy % HALF_ROUND_DEGREES !== 0
      const type = getFileTypeByMimeType(file.type)
      const mediaElementType = type === FileType.Image || type === FileType.Gif
        ? 'image'
        : type === FileType.Video ? 'video' : undefined

      try {
        const sizes = getMediaDimensions(mediaElementType, `[data-id="${file.name}"]`, withRotation)
        return {
          file,
          width: sizes.width,
          height: sizes.height,
          rotateBy
        }
      } catch (e) {
        console.log('[getMediaDimensions()] Error: ', e)
        return undefined
      }
    })
      .filter(Boolean) as any[]

    const folders = folder ? [folder as FileFolder] : Object.keys(selectedFolders)
      .map(id => Boolean(selectedFolders[id]) && props.folders.find(f => f.id.toString() === id))
      .filter(Boolean) as FileFolder[]

    props.uploadFiles(validSelectedFiles, folders)
    close()
  }

  const startUpload = () => {
    const myFile = props.copyFile
    if (myFile) {
      const folders = Object.keys(selectedFolders).map(id => {
        return {
          id,
          name: props.folders.find(f => f.id === id)?.name || ''
        }
      })

      folders.forEach(f => {
        props.onCopyFile(myFile, f)
      })
      close()
      return
    }
    uploadFiles()
  }

  const removeFile = (fileName: string) => {
    const nextFiles = { ...props.files }
    delete nextFiles[fileName]
    setFiles(nextFiles)
    setImagesSrcs(current => {
      const next = { ...current }
      delete next[fileName]
      return next
    })
  }

  const onFileLoaded = (name: string, src: string) => {
    setImagesSrcs(current => ({
      ...current,
      [name]: src
    }))
  }

  const addMoreFiles = (newFiles: { [name: string]: File }) => {
    setFiles({ ...files, ...newFiles })
  }

  const visibleFolders = props.folders.filter(f => f.name.indexOf(filter) !== -1)
  const hasSelectedFolders = Object.values(selectedFolders).filter(Boolean).length > 0
  const filesTotalSize = Object.values(files).reduce((total, file) => total + file.size, 0)
  const filesTotalSizeFormatted = bytesToSize(filesTotalSize)
  const filesCount = Object.keys(files).length

  return (
    <Dialog open={props.dialogOpen} onClose={close} classes={{ paper: styles.dialog }} maxWidth="xl">
      <div className={styles.grid}>
        <div className={styles['panel-left']}>
          <div className={styles['heading-left']}>
            <h3 className={styles.title}>
              <FormattedMessage id="label.generic.files" />: {filesCount}
            </h3>
            {!props.copyFile && (
              <ConnectedFileInput
                children={(
                  <div className={styles['btn-add']}>
                    <span>+ <FormattedMessage id="uploads.actions.add-more-files" /></span>
                  </div>
                )}
                openFileDialog={noop}
                setUploaderFiles={addMoreFiles}
              />
            )}
          </div>
          {!props.copyFile && (
            <p className={styles.stats}>
              <span>{filesCount} <FormattedMessage id="general.labels.files" values={{ count: filesCount }} /> </span>
              <span>{`(${filesTotalSizeFormatted})`}</span>
            </p>
          )}
          <div className={styles['files-grid']}>
            {props.copyFile && (
              <MyFilePreview
                key="external"
                file={props.copyFile}
                className={styles['grid-item']}
                onDelete={props.clearCopyFile}
              />
            )}
            {!props.copyFile && Object.values(files).map(f => (
              <MemoryFilePreviewCard
                key={f.name}
                file={f}
                validation={{ maxSize: mapFileTypeToSizeLimit(f.type, props.fileSizeLimits) }}
                noValidation={f.name === DEFAULT_STUDIO_EXPORT_FILE_NAME}
                rotateBy={fileRotation[f.name]}
                className={styles['grid-item']}
                mediaElementId={f.name}
                onError={onFileError}
                onRotate={toggleFileRotation}
                onDelete={removeFile}
                onImageLoaded={onFileLoaded}
              />
            ))}
          </div>
        </div>
        <div className={styles['panel-right']}>
          <div className={styles['heading-title']}>
            <FormattedMessage
              id="uploads.popup.title"
              values={{
                count: filesCount,
                typeSelect: (
                  <PPSwitch
                    options={modeOptions}
                    selectedValue={mode}
                    withButtonStyles
                    className={styles.select}
                    onSelectedValueChange={setMode as any}
                  />
                )
              }}
            />
            {mode === 'save' && props.folders.length > FILTER_VISIBLE_FOR_FOLDERS_COUNT && (
              <div className={styles['search-wrapper']}>
                <FormControl>
                  <Input
                    value={filter}
                    startAdornment={(
                      <InputAdornment position="start">
                        <SearchIcon className={styles['icon-search']} />
                      </InputAdornment>
                    )}
                    classes={{ input: styles['input-root'] }}
                    onChange={onFilterChange}
                    placeholder={props.intl.formatMessage({ id: 'uploads.hint.search-folders' })}
                  />
                </FormControl>
              </div>
            )}
          </div>
          <div>
            {mode === 'save' && (
              <React.Fragment>
                {props.folders.length > 0 ? (
                  <React.Fragment>
                    <div className={styles['folders-grid-wrapper']}>
                      <div className={styles['folders-grid']}>
                        {visibleFolders.map(folder => {
                          const selected = selectedFolders[folder.id]
                          return (
                            <div className={styles['folder-box']} key={folder.id}>
                              <div className={`${styles['folder-border']} ${selected ? styles.selected : ''}`}>
                                <FileFolderCard folder={folder} disableNavigation className={styles.folder} onClick={onFolderClick} />
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className={styles.actions}>
                      <Button variant="text" onClick={close}>
                        <FormattedMessage id="actions.cancel" />
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!hasSelectedFolders}
                        className={styles['btn-save']}
                        onClick={startUpload}
                      >
                        <FormattedMessage id="actions.save" />
                      </Button>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className={styles['msg-empty']}>
                    <FormattedMessage id="uploads.popup.msg-no-folders" />
                  </div>
                )}
              </React.Fragment>
            )}

            {mode === 'create' && (
              <CreateFileFolderForm
                loading={isCreatingFolder}
                filesCount={Object.keys(files).length}
                images={imagesSrcs}
                onCancel={close}
                onSubmit={createFolder}
              />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default injectIntl(MultiUploadManager)
