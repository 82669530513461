import * as React from 'react'
import { LEGACY_URL } from 'config'
import SimpleLoader from 'components/SimpleLoader'
import { useSelector } from 'react-redux'
import { accessTokenSelector } from 'services/users/selectors'
import { useLocation } from 'react-router-dom'

const FORM_ACTION = `${LEGACY_URL}/authorize`
export const FB_TOKEN_KEY: string = 'pp_fb_token'

export function Authorize() {
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const location = useLocation()
  const accessToken = useSelector(accessTokenSelector)

  React.useEffect(() => {
    if (accessToken && formRef.current && window.opener) {
      formRef.current.submit()
    }
  }, [accessToken, formRef.current])

  const token: string = window.opener ? (window.opener as any)[FB_TOKEN_KEY] : ''
  const action = `${FORM_ACTION}?access_token=${accessToken}${location.search ? '&' + location.search.slice(1) : ''}`

  return (
    <form method="POST" action={action} ref={formRef} style={{ textAlign: 'center', padding: '36px' }}>
      <SimpleLoader />
      <p style={{ margin: '16px' }}>Please wait...</p>
      <input name="access_token_short" value={token} type="hidden" />
    </form>
  )
}

export default Authorize
