import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import { Stream, WithIntl } from 'interfaces'
import { injectIntl } from 'react-intl'
import { ColorGradient } from 'utils/colors'
import styles from './EditStreamDialog.pcss'
import CreateStreamForm from 'components/CreateStreamForm'
import { useDispatch } from 'react-redux'
import { checkFeatureAvailability } from 'services/product'
import { FEATURE_FIND_PRIVATE_STREAMS } from 'shared/constants'
import { StoreThunkDispatch } from 'store/state'
import { Subject } from 'rxjs/Subject'
import { deleteStream, updateStream } from 'services/content/streams/actions'
import { deleteFeed } from 'services/content/feeds/actions'
import { catchError } from 'rxjs/operators/catchError'
import { message } from 'services/snackbar'
import { Observable } from 'rxjs/Observable'
import { FeedListItem } from 'components/SaveFeedDialog'
import ConfirmDialog from 'components/ConfirmDialog'

interface EditStreamDialogOwnProps {
  stream: Stream
  open: boolean
  onClose: () => void
}

type EditStreamDialogProps = EditStreamDialogOwnProps & WithIntl

export function EditStreamDialog(props: EditStreamDialogProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [deletedFeeds, setDeletedFeeds] = React.useState<Record<string, boolean>>({})
  const [loading, setLoading] = React.useState(false)
  const imageUrls: string[] = props.stream.feeds.map(f => f.image).filter(Boolean) as string[]
  if (props.stream.featuredImageUrl) {
    imageUrls.unshift(props.stream.featuredImageUrl)
  }
  const deleteFeed$ = React.useRef<Subject<string>>()
  const deleteStream$ = React.useRef<Subject<string>>()
  const update$ = React.useRef<Subject<any>>()

  React.useEffect(() => {
    update$.current = new Subject()
    update$.current
      .flatMap(data => {
        setLoading(true)
        return Observable.fromPromise(dispatch(updateStream(data)).unwrap())
          .pipe(catchError((e) => {
            dispatch(message('An error occured, please try again!', 'error'))
            return Observable.of({ error: e })
          }))
      })
      .subscribe((res: any) => {
        setLoading(false)
        if (!res.error) {
          dispatch(message('Stream updated!', 'success'))
          props.onClose()
        }
      })

    return () => update$.current?.unsubscribe()
  }, [dispatch])

  React.useEffect(() => {
    deleteFeed$.current = new Subject()
    deleteFeed$.current
      .flatMap(id => Observable.fromPromise(dispatch(deleteFeed({ feedId: id }))).pipe(catchError((e) => {
        dispatch(message('An error occured, please try again!', 'error'))
        return Observable.of({ error: e, id })
      })))
      .subscribe((response: any) => {
        if (response.error) {
          setDeletedFeeds(current => ({ ...current, [response.id]: false }))
        } else {
          dispatch(message('Feed removed!', 'success', undefined, true))
        }
      })

    return () => deleteFeed$.current?.unsubscribe()
  }, [dispatch])

  React.useEffect(() => {
    deleteStream$.current = new Subject()
    deleteStream$.current
      .flatMap(id => Observable.fromPromise(dispatch(deleteStream(id))).pipe(catchError((e) => {
        dispatch(message('There was an error deleting your stream, please try again!', 'error'))
        return Observable.of({ error: e })
      })))
      .subscribe((res: any) => {
        setLoading(false)
        if (!res.error) {
          dispatch(message('Stream deleted!', 'success'))
          props.onClose()
        }
      })

    return () => deleteStream$.current?.unsubscribe()
  }, [dispatch])

  const feeds = props.stream.feeds.filter(f => !deletedFeeds[f.id])

  const update = (
    name: string,
    color: ColorGradient,
    image: File | null,
    isPrivate?: boolean,
    featuredImage?: string,
    secondaryImages?: string[]
  ) => {
    if (!name || !color) {
      return true
    }
    const colorString = `${color.from},${color.to}`
    const colorChanged = colorString !== props.stream.color
    const isRenamed = props.stream.title !== name
    const privateChanged = props.stream.isPrivate !== isPrivate

    if (privateChanged && isPrivate) {
      const privateStreamsAllowed = isPrivateStreamAllowed()
      if (!privateStreamsAllowed) {
        return true
      }
    }

    if (!isRenamed && !image && !privateChanged && !featuredImage && !colorChanged) {
      return true
    }

    update$.current?.next({
      id: props.stream.id,
      name,
      color: colorString,
      file: image,
      isPrivate,
      featuredImage,
      secondaryImages
    })
    return true
  }

  const removeFeed = (id: string) => {
    deleteFeed$.current?.next(id)
    setDeletedFeeds(current => ({ ...current, [id]: true }))
  }

  const removeStream = () => {
    setLoading(true)
    deleteStream$.current?.next(props.stream.id)
  }

  const isPrivateStreamAllowed = () => {
    return dispatch(checkFeatureAvailability(FEATURE_FIND_PRIVATE_STREAMS))
  }

  return (
    <Dialog open={props.open} maxWidth="xl" onClose={props.onClose} classes={{ paper: styles.dialog }}>
      <div className={styles.grid}>
        <div className={styles['panel-left']}>
          {feeds.length > 0 && (
            <div className={styles['feeds-list-box']}>
              <h4 className={styles['feeds-list-title']}>{`Feeds: ${feeds.length}`}</h4>

              <ConfirmDialog
                message="Deleting the last feed in this stream will result in the stream being deleted."
                labelOK="Delete feed and stream"
              >
                {(confirm) => (
                  <ul className={styles['feeds-list']}>
                    {feeds.map(feed => (
                      <FeedListItem
                        key={feed.id}
                        feed={feed}
                        onDelete={feeds.length === 1 ? confirm(removeStream) : removeFeed}
                      />
                    ))}
                  </ul>
                )}
              </ConfirmDialog>

            </div>
          )}
        </div>
        <div className={styles['panel-right']}>
          <div className={styles.content}>
            <CreateStreamForm
              loading={loading}
              updateStream={props.stream}
              images={imageUrls || []}
              feeds={feeds}
              streams={[]}
              onSubmit={update}
              onCancel={props.onClose}
              isPrivateOptionAllowed={isPrivateStreamAllowed}
              onDelete={removeStream}
            />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default injectIntl(EditStreamDialog)
