import { V1 } from './net'
import { StoreThunkAction } from 'store/state'
import { Observable } from 'rxjs/Observable'
import { tap } from 'rxjs/operators/tap'
import { HS_EVENT_POSTS_SHUFFLED, trackHubspotEvent } from 'services/tracking/hubspot'

export function shufflePosts(
  postIds: string[],
  ppids: string[],
  postsType: 'queued' | 'pending' | 'scheduled' | 'failed' | 'planned',
  all?: boolean, bucketId?: string
): StoreThunkAction<Observable<any>> {
  return (dispatch) => {
    return dispatch(V1.shufflePosts(postIds, ppids, postsType, all, bucketId)).pipe(tap(() => {
      dispatch(trackHubspotEvent(HS_EVENT_POSTS_SHUFFLED))
    }))
  }
}
