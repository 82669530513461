import * as React from 'react'
import { MAX_FEEDS_IN_STREAM } from 'components/SaveFeedDialog'
import { ContentFeedType, Feed, Stream } from 'interfaces'
import { injectIntl, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { message } from 'services/snackbar'
import { ADD_FEED, REMOVE_FEED, REPLACE_FEED, searchRequest, updateState } from '../state/actions'
import { SearchContext } from '../state/context'
import styles from './SearchRoot.pcss'
import { useNavigate } from 'react-router-dom'
import { SearchResultsFeedCarousel, SearchContentCarousel, SearchResultsStreamsCarousel } from './SearchResultsCarousel'
import {
  SEARCH_FILTER_KEY_ARTICLES,
  SEARCH_FILTER_KEY_FACEBOOK,
  SEARCH_FILTER_KEY_GIFS,
  SEARCH_FILTER_KEY_LINKEDIN,
  SEARCH_FILTER_KEY_POST_IDEAS,
  SEARCH_FILTER_KEY_QUOTES,
  SEARCH_FILTER_KEY_REDDIT,
  SEARCH_FILTER_KEY_RSS,
  SEARCH_FILTER_KEY_STOCK,
  SEARCH_FILTER_KEY_STREAMS,
  SEARCH_FILTER_KEY_VIDEOS,
  SearchFilterKey
} from '../state/types'
import { resetScroll } from 'utils/dom'

export function SearchRoot() {
  const dispatch = useDispatch()
  const intl = useIntl()
  const navigate = useNavigate()
  const [state, searchDispatch] = React.useContext(SearchContext)
  const { selectedFeeds, results, newFeedTemplate, query, selectedFilters } = state

  React.useEffect(() => {
    resetScroll()
  }, [])

  React.useEffect(() => {
    searchDispatch(updateState({ activeFilter: undefined }))
  }, [searchDispatch])

  const toggleFeedSelected = React.useCallback((feed: Feed) => {
    if (selectedFeeds?.length >= MAX_FEEDS_IN_STREAM) {
      dispatch(message(intl.formatMessage(
        { id: 'search.notifications.max-feeds-limit-reached' },
        { count: MAX_FEEDS_IN_STREAM }
      )))
      return
    }

    if (selectedFeeds.find((f: Feed) => f.handle === feed.handle && f.type === feed.type)) {
      searchDispatch({
        type: REMOVE_FEED,
        payload: { id: feed.id, type: feed.type }
      })
      return
    }

    searchDispatch({
      type: ADD_FEED,
      payload: feed
    })
  }, [selectedFeeds, searchDispatch, dispatch, intl])

  const requestNextPage = React.useCallback((section: SearchFilterKey) => {
    const { hasNextPage, loading, items, page } = results[section]
    if (hasNextPage && items.length !== 0 && !loading) {
      searchDispatch(searchRequest(query, [section], page + 1))
    }
  }, [results, query, searchDispatch])

  const onStreamClick = React.useCallback((stream: Stream) => {
    searchDispatch(updateState({ activeFeed: undefined, activeStream: stream }))
    navigate(`/content/search/sources/streams/${stream.id}`)
  }, [navigate, searchDispatch])

  const onFeedClick = React.useCallback((feed: Feed) => {
    searchDispatch(updateState({ activeFeed: feed, activeStream: undefined }))
    navigate(`/content/search/sources/${feed.type}/${feed.id}`)
  }, [navigate, searchDispatch])

  const onFeedCreated = React.useCallback((feed: Feed, liveId: string) => {
    // Replace live feed with newly created feed from db
    searchDispatch({
      type: REPLACE_FEED,
      payload: {
        id: liveId,
        newFeed: { ...feed, live: true }
      }
    })
  }, [searchDispatch])

  const onFeedCreateError = React.useCallback((handle: string, type: ContentFeedType) => {
    searchDispatch({
      type: REMOVE_FEED,
      payload: { id: handle, type }
    })
  }, [searchDispatch])

  return (
    <section className={styles.wrapper} data-test="search-result">
      {selectedFilters.map((key: SearchFilterKey) => {
        const section = results[key]
        switch (key) {
          case SEARCH_FILTER_KEY_STREAMS: {
            return (
              <SearchResultsStreamsCarousel
                {...section}
                key={key}
                activeQuery={query}
                onStreamClick={onStreamClick}
                onRequestNextPage={requestNextPage}
              />
            )
          }
          case SEARCH_FILTER_KEY_FACEBOOK:
          case SEARCH_FILTER_KEY_LINKEDIN:
          case SEARCH_FILTER_KEY_REDDIT:
          case SEARCH_FILTER_KEY_RSS: {
            return (
              <SearchResultsFeedCarousel
                {...section}
                key={key}
                type={key}
                activeQuery={query}
                selectedFeeds={selectedFeeds}
                newFeedTemplate={newFeedTemplate?.type === key ? newFeedTemplate : undefined}
                onRequestNextPage={requestNextPage}
                onFeedClick={onFeedClick}
                onFeedCreated={onFeedCreated}
                onFeedSelected={toggleFeedSelected}
                onFeedCreateError={onFeedCreateError}
              />
            )
          }

          case SEARCH_FILTER_KEY_QUOTES:
          case SEARCH_FILTER_KEY_POST_IDEAS:
          case SEARCH_FILTER_KEY_STOCK:
          case SEARCH_FILTER_KEY_GIFS:
          case SEARCH_FILTER_KEY_ARTICLES:
          case SEARCH_FILTER_KEY_VIDEOS: {
            return (
              <SearchContentCarousel key={key} filter={key} />
            )
          }

          default: {
            return (
              <div key={key}>{key}</div>
            )
          }
        }
      })}
    </section>
  )
}

export default injectIntl(SearchRoot)
