import * as React from 'react'
import Button from '@mui/material/Button'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { useDispatch, useSelector } from 'react-redux'
import { activeDraftSelector, composerIsEmptySelector } from 'services/compose/selectors'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Subject } from 'rxjs/Subject'
import { tap } from 'rxjs/operators/tap'
import { createDraft, updateDraft } from 'services/post/drafts/actions'
import { resetComposer, setDraft } from 'services/compose'
import { StoreThunkDispatch } from 'store/state'
import { WithIntl } from 'interfaces'
import { message } from 'services/snackbar'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { MS_IN_SEC } from 'utils/constants'
import Tooltip from '@mui/material/Tooltip'
import styles from './SaveDraftButton.pcss'
import { checkProductLimit } from 'services/product'
import { LIMIT_POST_DRAFTS } from 'shared/constants'
import { ComposerUploaderContext } from '../../UploadContext'
import { map } from 'rxjs/operators/map'
import { formatDistanceToNow } from 'date-fns'

const AUTOSAVE_INTERVAL_SECONDS = 30

interface SaveDraftButtonProps {
  disabled?: boolean
  className?: string
}

export function SaveDraftButton(props: SaveDraftButtonProps & WithIntl) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const { files } = React.useContext(ComposerUploaderContext)
  const composerDraft = useSelector(activeDraftSelector)
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const withAutosave = React.useMemo(() => Boolean(composerDraft), [composerDraft])
  const [isSaving, setIsSaving] = React.useState(false)
  const isComposerEmpty = useSelector(composerIsEmptySelector)
  const create$ = React.useRef<Subject<{ resetWhenComplete: boolean }>>()
  const update$ = React.useRef<Subject<{ isAutoSave: boolean, resetWhenComplete: boolean }>>()

  React.useEffect(() => {
    create$.current = new Subject()
    create$.current
      .pipe(
        tap(() => {
          setIsSaving(true)
        })
      )
      .switchMap(({ resetWhenComplete }) => dispatch(createDraft(files))
        .pipe(
          catchError((error) => {
            return Observable.of({ error })
          }),
          map((response) => {
            return { ...response, resetWhenComplete }
          })
        )
      )
      .subscribe((response: any) => {
        setIsSaving(false)
        if (response.error) {
          if (response.error.code === 'limits-reached') {
            // eslint-disable-next-line no-magic-numbers
            dispatch(checkProductLimit(LIMIT_POST_DRAFTS, response.error.data.currentCount + 1, parseInt(response.error.data.limit, 10)))
          }
          dispatch(message(props.intl.formatMessage({ id: 'composer.notifications.draft-save-failed' }), 'error'))
        } else {
          dispatch(message(props.intl.formatMessage({ id: 'composer.notifications.draft-created' }), 'success'))
          if (response.resetWhenComplete) {
            dispatch(resetComposer())
          } else {
            dispatch(setDraft({ id: response.draftId, updatedAt: new Date().toISOString() }))
          }

        }
      })

    return () => create$.current?.unsubscribe()
  }, [dispatch, files, props.intl])

  React.useEffect(() => {
    update$.current = new Subject()
    update$.current
      .filter(() => Boolean(composerDraft))
      .pipe(
        tap(() => {
          setIsSaving(true)
        })
      )
      .switchMap(({ isAutoSave, resetWhenComplete }) => dispatch(updateDraft(composerDraft?.id as string, files))
        .pipe(
          map((response) => {
            return { ...response, isAutoSave, resetWhenComplete }
          }),
          catchError(() => {
            if (!isAutoSave) {
              dispatch(message(props.intl.formatMessage({ id: 'composer.notifications.draft-save-failed' }), 'error'))
            }
            return Observable.of({ error: true })
          }))
      )
      .subscribe((response: any) => {
        setIsSaving(false)
        if (!response.isAutoSave) {
          dispatch(message(props.intl.formatMessage({ id: 'composer.notifications.draft-updated' }), 'success'))
        }
        if (response.resetWhenComplete) {
          dispatch(resetComposer())
        } else {
          dispatch(setDraft({ id: response.draftId, updatedAt: new Date().toISOString() }))
        }
      })

    return () => update$.current?.unsubscribe()
  }, [composerDraft, dispatch, props.intl, files])

  React.useEffect(() => {
    if (withAutosave) {
      const interval = setInterval(() => {
        update$.current?.next({ isAutoSave: true, resetWhenComplete: false })
      }, AUTOSAVE_INTERVAL_SECONDS * MS_IN_SEC)

      return () => clearInterval(interval)
    }
    return () => null
  }, [withAutosave])

  const labelId = React.useMemo(() => {
    if (isSaving) {
      return 'composer.labels.draft.saving'
    }
    return 'composer.labels.draft.create'
  }, [isSaving])

  const tooltip = React.useMemo(() => {
    if (!composerDraft?.updatedAt) {
      return ''
    }
    return (
      <FormattedMessage
        id="composer.notifications.draft-last-update"
        values={{ timeAgo: formatDistanceToNow(composerDraft?.updatedAt, { addSuffix: true }) }}
      />
    )
  }, [composerDraft?.updatedAt])

  const save = React.useCallback((shouldReset: boolean) => {
    if (composerDraft) {
      update$.current?.next({ isAutoSave: false, resetWhenComplete: shouldReset })
    } else {
      create$.current?.next({ resetWhenComplete: shouldReset })
    }
  }, [composerDraft])

  const handleClick = () => {
    setOpen(true)
  }

  const closePopup = () => {
    setOpen(false)
  }

  const saveAndContinue = () => {
    save(false)
    setOpen(false)
  }

  const saveAndReset = () => {
    save(true)
    setOpen(false)
  }

  return (
    <div>
      <Tooltip title={tooltip}>
        <Button
          variant="text"
          size="small"
          ref={anchorRef}
          endIcon={<ArrowDropDownIcon />}
          disabled={isComposerEmpty || isSaving || props.disabled}
          className={`${styles.btn} ${props.className}`}
          onClick={handleClick}
        >
          <FormattedMessage id={labelId} />
        </Button>
      </Tooltip>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} disablePortal className={styles.popper}>
        <Paper>
          <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={closePopup}>
            <MenuList>
              <MenuItem onClick={saveAndContinue} className={styles['menu-item']}>
                Save draft and continue
              </MenuItem>
              <MenuItem onClick={saveAndReset} className={styles['menu-item']}>
                Save draft and next post
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  )
}

export default injectIntl(SaveDraftButton)
