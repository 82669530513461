import * as React from 'react'
import { colors, ColorGradient, bucketColorsPreset } from 'utils/colors'

import styles from './ColorSelector.pcss'

interface ColorSelectorProps {
  colors?: ColorGradient[] | string[]
  solidColors?: boolean
  initialColor?: ColorGradient | string
  boxSize?: number
  className?: string
  onSelectionChanged: (color: ColorGradient | string) => void
}

export function ColorSelector(props: ColorSelectorProps) {
  const displayGradients: ColorGradient[] = React.useMemo(() => {
    if (props.solidColors) {
      return []
    }

    return props.colors as ColorGradient[] || colors
  }, [props.solidColors, props.colors])

  const initialGradient = props.solidColors || typeof props.initialColor === 'string'
    ? colors[0]
    : props.initialColor || colors[0]

  const [selectedGradient, setSelectedGradient] = React.useState(initialGradient)
  const [selectedColor, setSelectedColor] = React.useState(
    typeof props.initialColor === 'string' ? props.initialColor : bucketColorsPreset[0]
  )

  const sizeStyles = props.boxSize ? {
    width: props.boxSize + 'px',
    height: props.boxSize + 'px'
  }
    : {}

  const getBoxStyles = (color: ColorGradient | string) => {
    return Object.assign(
      {}, sizeStyles,
      props.solidColors
        ? { backgroundColor: color as string }
        : { backgroundImage: `linear-gradient(to top right, ${(color as ColorGradient).from}, ${(color as ColorGradient).to})` }
    )
  }

  const onBoxClick = (c: ColorGradient | string) => () => {
    if (typeof c === 'string') {
      setSelectedColor(c)
    } else {
      setSelectedGradient(c)
    }
    props.onSelectionChanged(c)
  }

  const isColorSelected = (color: ColorGradient) => {
    return color.from === selectedGradient.from && color.to === selectedGradient.to
  }

  return (
    <div className={`${styles['color-selector']} ${props.className || ''}`} data-testid="color-selector">
      {props.solidColors ? bucketColorsPreset.map((color, key) => (
        <div
          key={key}
          className={`${styles.box} ${color === selectedColor ? styles.selected : ''}`}
          style={getBoxStyles(color)}
          onClick={onBoxClick(color)}
        >
        </div>
      )) : displayGradients.map((color, key) => (
        <div
          key={key}
          className={`${styles.box} ${isColorSelected(color) ? styles.selected : ''}`}
          style={getBoxStyles(color)}
          onClick={onBoxClick(color)}
        >
        </div>
      ))}
    </div>
  )
}

export default ColorSelector
