import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { extractTweetId } from 'utils/extractTweetId'

const ppGreen = require('styles/variables')['--pp-green']

export interface TweetOptions {
  cards?: 'hidden'
  conversation?: 'none'
  theme?: 'dark' | 'light'
  linkColor?: string
  width?: number
  align?: 'left' | 'right' | 'center',
  dnt?: boolean
}

const DEFAULT_TWEET_WIDTH = 520 // 520 is the max allowed value by Twitter

export interface EmbeddedTweetProps {
  url: string
  options?: TweetOptions
  onLoaded?: () => void
  onFailed?: () => void
}

const defaultOptions: TweetOptions = {
  width: DEFAULT_TWEET_WIDTH,
  align: 'center',
  conversation: 'none',
  linkColor: ppGreen,
  dnt: true
}

export function EmbeddedTweet(props: EmbeddedTweetProps) {
  const errorElement = <p><FormattedMessage id="errors.generic" /></p>
  const elementRef = React.useRef(null)
  const options = props.options || defaultOptions
  const tweetId = extractTweetId(props.url)

  React.useEffect(() => {
    const twitterWidgets = (window as any).twttr?.widgets
    if (!twitterWidgets) {
      return
    }
    twitterWidgets.createTweet(tweetId, elementRef.current, options)
      .then(props.onLoaded)
      .catch(props.onFailed)
  }, [props.url])

  if (!props.url) {
    console.error('[EmbeddedTweet] missing tweet url')
    return errorElement
  }

  if (!(window as any).twttr || !tweetId) {
    console.error('[EmbeddedTweet] twitter sdk not loaded or tweet url is invalid')
    return errorElement
  }

  return (
    <blockquote ref={elementRef}></blockquote>
  )
}

export default EmbeddedTweet
