/* eslint-disable no-magic-numbers */
export function bytesToSize(bytes: number) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) {
    return '0 KB'
  }

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)).toString(), 10)
  if (i === 0) {
    return `${bytes} ${sizes[i]}`
  }

  return `${(bytes / (1000 ** i)).toFixed(1)} ${sizes[i]}`
}
