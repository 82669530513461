import { Component, createElement, ReactElement } from 'react'
import { Feed, AnyContent, RangeFilter, ContentItem, FilterType, ALL_FILTER } from 'interfaces'
import FetchFeedPageContent from 'components/Fetch/FetchFeedPageContent'
import SourcesBricksView, { BricksSize, FetchableProps } from 'components/SourcesView/SourcesBricksView'
import { STOCK_ALL_FILTER } from 'interfaces/Content/StockContentTypes'

const isEqual = require('react-fast-compare')

export { BricksSize }

export interface ScrollConfiguration {
  element?: Element
  useWindow?: boolean
}

export interface FeedBricksViewProps {
  feed: Feed
  filter: FilterType
  items: AnyContent[]

  align?: 'left' | 'center'
  sizes?: BricksSize[]
  scroll?: ScrollConfiguration
  itemWidth?: number
  range?: RangeFilter
  skipContentFeatureChecks?: boolean

  onItemPinned?(feed: Feed, content: AnyContent): void
  onItemShared?(feed: Feed, content: AnyContent): void | boolean
  onGotoClicked?(feed: Feed, content: AnyContent, by: string): void
  onRangeChanged?(feed: Feed, range: RangeFilter): void
  onFilterChange(filter: FilterType): void
  onContentItemClick?(content: ContentItem): void
}

interface FeedBricksViewState { }

export class FeedBricksView extends Component<FeedBricksViewProps, FeedBricksViewState> {

  constructor(props: FeedBricksViewProps) {
    super(props)

    this.createLoadMore = this.createLoadMore.bind(this)
    this.onItemPinned = this.onItemPinned.bind(this)
    this.onItemShared = this.onItemShared.bind(this)
    this.onGotoClicked = this.onGotoClicked.bind(this)
    this.onFeedClicked = this.onFeedClicked.bind(this)
    this.onRangeChanged = this.onRangeChanged.bind(this)

    this.state = {
    }
  }

  validateFilter = () => {
    const current = this.props.filter
    const allowed = this.props.feed.sources
    let validFilterForSource: FilterType = allowed.find(f => f === current) || this.props.feed.sources[0]
    if (current === ALL_FILTER) {
      validFilterForSource = ALL_FILTER
    } else if (current === STOCK_ALL_FILTER) {
      validFilterForSource = STOCK_ALL_FILTER
    }

    if (validFilterForSource !== current) {
      this.props.onFilterChange(validFilterForSource)
    }
  }

  componentDidMount() {
    this.validateFilter()
  }

  componentDidUpdate(prevProps: FeedBricksViewProps) {
    if (prevProps.feed.id !== this.props.feed.id || prevProps.filter !== this.props.filter) {
      this.validateFilter()
    }
  }

  shouldComponentUpdate(nextProps: FeedBricksViewProps) {
    return !isEqual(this.props, nextProps)
  }

  onItemPinned(content: AnyContent) {
    if (this.props.onItemPinned) {
      this.props.onItemPinned(this.props.feed, content)
    }
  }

  // eslint-disable-next-line consistent-return
  onItemShared(content: AnyContent) {
    if (this.props.onItemShared) {
      return this.props.onItemShared(this.props.feed, content)
    }
  }

  onGotoClicked(content: AnyContent, by: string) {
    if (this.props.onGotoClicked) {
      this.props.onGotoClicked(this.props.feed, content, by)
    }
  }

  onFeedClicked(content: AnyContent) {
    this.onGotoClicked(content, 'attribution')
  }

  onRangeChanged(range: RangeFilter) {
    if (this.props.onRangeChanged) {
      return this.props.onRangeChanged(this.props.feed, range)
    }

    return false
  }

  createLoadMore(props: FetchableProps): ReactElement<any> {
    console.log('[feed bricks] load more', props)

    const feed = this.props.feed
    const page = props.page
    const range = props.range
    return createElement(FetchFeedPageContent, {
      ...props,
      feed,
      key: `fetch[${feed.id}.${page}:${range}:${props.sortBy}]`
    } as any)
  }

  render(): ReactElement<any> {
    const { feed, filter, items, align, sizes, scroll, itemWidth, range } = this.props

    return createElement(SourcesBricksView, {
      filter,
      items,
      align,
      sizes,
      scroll,
      itemWidth,
      range,
      feedType: feed.type,
      contentTypes: feed.sources,
      sourcesHash: feed.id + '',
      skipContentCardsFeatureChecks: this.props.skipContentFeatureChecks,
      preventTopOverflow: true,
      createLoadMore: this.createLoadMore,
      onFilterChange: this.props.onFilterChange,
      onRangeChanged: this.onRangeChanged,
      onItemShared: this.onItemShared,
      onItemPinned: this.onItemPinned,
      onSourceClick: this.onGotoClicked,
      onFeedClick: this.onFeedClicked,
      onContentItemClick: this.props.onContentItemClick
    })
  }
}

export default FeedBricksView
