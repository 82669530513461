import * as React from 'react'
import Paper from '@mui/material/Paper'
import ImageIcon from '@mui/icons-material/Image'
import { ColorGradient, getGradientStyle } from 'utils/colors'
import { FormattedMessage } from 'react-intl'

import styles from './StreamCardPreview.pcss'
import { StreamIcon } from 'components/Icons'

interface StreamCardPreviewProps {
  name: string
  color: ColorGradient
  imageSrc?: string
  secondaryImages: string[]
  displaySingleImage?: boolean
  className?: string
}

export function StreamCardPreview(props: StreamCardPreviewProps) {
  const secondaryImage1 = props.secondaryImages[0]
  const secondaryImage2 = props.secondaryImages[1]
  const colorStyle = getGradientStyle(`${props.color.from},${props.color.to}`)

  const withImage = Boolean(props.imageSrc)
  const imgStyles = withImage ? {
    backgroundImage: `url(${props.imageSrc})`
  } : {}

  return (
    <Paper className={`${styles.paper} ${styles.card} ${styles.preview} ${props.className || ''}`} style={colorStyle}>
      <h5 className={styles.title}>
        <span className="text-ellipsis">{props.name || <FormattedMessage id="general.stream.label-add-name" />}</span>
      </h5>
      <div className={styles.images}>
        {!props.displaySingleImage && (
          <div className={styles['img-box']}>
            {secondaryImage1 && <img src={secondaryImage1} className={styles.image} />}
          </div>
        )}
        <div className={`${styles['img-box']} ${styles.featured}`} style={imgStyles}>
          {!props.imageSrc && (
            <div className={styles['no-img-box']}>
              <StreamIcon size={68} />
            </div>
          )}
        </div>
        {!props.displaySingleImage && (
          <div className={styles['img-box']}>
            {secondaryImage2 && <img src={secondaryImage2} className={styles.image} />}
          </div>
        )}
      </div>
    </Paper>
  )
}

export default StreamCardPreview
