import { SignableRequest, Etagged } from './interfaces'

const HTTP_VERB_GET = 'GET'
const HTTP_VERB_POST = 'POST'
const HTTP_VERB_PATCH = 'PATCH'
const HTTP_VERB_PUT = 'PUT'
const HTTP_VERB_DELETE = 'DELETE'

const MIME_TYPE_JSON = 'application/json'
const MIME_TYPE_JSON_API = 'application/vnd.postplanner.v2+json'
const MIME_TYPE_FORM_URL_ENCODED = 'application/x-www-form-urlencoded'

const CHARSET_UTF8 = 'UTF-8'

const HEADER_KEY_ACCEPT = 'Accept'
const HEADER_KEY_CONTENT_TYPE = 'Content-Type'
const HEADER_KEY_IF_MATCH = 'If-Match'

const HEADER_ACCEPT_JSON = { [HEADER_KEY_ACCEPT]: MIME_TYPE_JSON_API }
const HEADER_CONTENT_TYPE_JSON = { [HEADER_KEY_CONTENT_TYPE]: `${MIME_TYPE_JSON};charset=${CHARSET_UTF8}` }
const HEADER_CONTENT_TYPE_FORM_URL_ENCODED = { [HEADER_KEY_CONTENT_TYPE]: `${MIME_TYPE_FORM_URL_ENCODED};charset=${CHARSET_UTF8}` }

function encodeParameters(params: { [key: string]: string}) {
  const bound = function (key: string) { return encodeParameter(key, params[key]) }
  return Object.keys(params).sort().map(bound)
}

function encodeParameter(key: string, value: string): string {
  return `${key}=${value}`
}

export class RequestBuilder {
  private request: Partial<SignableRequest>
  private params: { [key: string]: string }
  private partials: { [key: string]: string }

  constructor(...parts: string[]) {
    this.request = {
      url: parts.join('/'),
      crossDomain: true,
      withCredentials: true,
      withAuthorization: true
    }
  }

  withoutCredentials(): this {
    this.request.withCredentials = false
    return this
  }

  withCredentials(): this {
    this.request.withCredentials = true
    return this
  }

  withoutAuthorization(): this {
    this.request.withAuthorization = false
    return this
  }

  notCrossDomain(): this {
    this.request.crossDomain = false
    return this
  }

  asGet(): this {
    this.request.method = HTTP_VERB_GET
    return this
  }

  asPost(): this {
    this.request.method = HTTP_VERB_POST
    return this
  }

  asPut(): this {
    this.request.method = HTTP_VERB_PUT
    return this
  }

  asPatch(): this {
    this.request.method = HTTP_VERB_PATCH
    return this
  }

  asDelete(): this {
    this.request.method = HTTP_VERB_DELETE
    return this
  }

  expectJSON(): this {
    this.request.headers = Object.assign(this.request.headers || {}, HEADER_ACCEPT_JSON)
    return this
  }

  onlyModified<T extends Etagged>(resource: T) {
    const etag = resource.__etag
    this.request.headers = Object.assign(this.request.headers || {}, { [HEADER_KEY_IF_MATCH]: etag })
    return this
  }

  asJSON(): this {
    this.request.headers = Object.assign(this.request.headers || {}, HEADER_CONTENT_TYPE_JSON)
    return this
  }

  asFormUrlEncoded(): this {
    this.request.headers = Object.assign(this.request.headers || {}, HEADER_CONTENT_TYPE_FORM_URL_ENCODED)
    return this
  }

  body(body: any): this {
    this.request.body = body
    return this
  }

  bodyJSONstringify(body: any): this {
    this.request.body = JSON.stringify(body)
    return this
  }

  form(form: FormData): this {
    this.request.body = form
    return this
  }

  param(key: string, value: string): this {
    this.params = Object.assign({}, this.params, { [encodeURIComponent(key)]: encodeURIComponent(value) })
    return this
  }

  partial(partial: string, replacement: string): this {
    this.partials = Object.assign({}, this.partials, { [partial]: encodeURIComponent(replacement) })
    return this
  }

  build(): SignableRequest {
    if (!this.request.url || !this.request.method) {
      throw new Error('[request-builder] missing url or method')
    }

    let url = this.request.url
    if (this.partials) {
      url = Object.keys(this.partials).reduce((partialedUrl, partial) => partialedUrl.replace(partial, this.partials[partial]), url)
    }

    if (this.params) {
      const query = encodeParameters(this.params).join('&')
      url = `${url}?${query}`
    }

    return { ...this.request as SignableRequest, url }
  }
}

export default RequestBuilder
