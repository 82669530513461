import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { StreamGroup } from 'admin/interfaces'
import { CardList, ITEM_WIDTH_SMALL, CARD_LIST_HEIGHT_SMALL } from 'components/CardList'
import { StreamCard } from 'components/Cards'

import styles from '../HomeSection.pcss'

export interface StreamGroupCarouselProps {
  group: StreamGroup
}

export const StreamGroupCarousel = React.memo(function (props: StreamGroupCarouselProps) {
  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h2 className={`${styles.title} text-ellipsis`}>
          {props.group.name}
        </h2>
        <NavLink to={`/content/${props.group.slug}`} className={`${styles.navigation} text-ellipsis`}>
          <FormattedMessage id="general.carousel.nav.see-all" />
        </NavLink>
      </header>
      <CardList
        containerClassName={styles['card-list']}
        itemWidth={ITEM_WIDTH_SMALL}
        listHeight={CARD_LIST_HEIGHT_SMALL}
      >
        {
          props.group.streams.map(stream => (
            <StreamCard
              key={stream.id}
              stream={stream}
              urlBrowse={`/content/${props.group.slug}/${stream.slug}-${stream.id}`}
            />
          ))
        }
      </CardList>
    </section>
  )
})

export default StreamGroupCarousel
