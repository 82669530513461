/* eslint-disable react/button-has-type */
import { ClickAwayListener } from '@mui/material'
import React, { useRef } from 'react'
import Button from '@mui/material/Button'
import styles from './VideoThumbnailGenerator.pcss'

interface VideoThumbnailGeneratorProps {
  videoFile: File
  onClose: () => void
  onGenerateThumb: (thumb: string, offset: number | undefined) => void
}

export function VideoThumbnailGenerator(props: VideoThumbnailGeneratorProps) {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const thumbnailCanvasRef = useRef<HTMLCanvasElement | null>(null)
  const [currentTime, setCurrentTime] = React.useState('')

  React.useEffect(() => {
    if (props.videoFile) {
      const video = videoRef.current
      if (video) {
        video.src = URL.createObjectURL(props.videoFile)
      }
    }
  }, [props.videoFile])

  // Display current video time
  React.useEffect(() => {
    const video = videoRef.current
    if (video) {
      const onTimeUpdate = () => {
        setCurrentTime(video.currentTime.toFixed(2))
      }
      video.addEventListener('timeupdate', onTimeUpdate)
    }

  }, [])

  const generateThumbnail = () => {
    const video = videoRef.current
    const thumbnailCanvas = thumbnailCanvasRef.current
    const thumbnailCtx = thumbnailCanvas?.getContext('2d')

    if (video && thumbnailCanvas && thumbnailCtx) {
      thumbnailCtx.drawImage(video, 0, 0, video?.offsetWidth, video?.offsetHeight)
      props.onGenerateThumb(thumbnailCanvas.toDataURL('image/png'), videoRef.current?.currentTime)
    }
  }

  return (
    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={props.onClose}>
      <div className={styles.container}>
        <Button
          variant="contained"
          color="primary"
          className={styles.btn}
          onClick={generateThumbnail}
        >
          Select as thumbnail{currentTime ? ' - ' + currentTime : ''}
        </Button>
        <video ref={videoRef} controls className={styles.video}>
          Your browser does not support the video tag.
        </video>
        <canvas
          ref={thumbnailCanvasRef}
          width={videoRef.current?.offsetWidth}
          height={videoRef.current?.offsetHeight}
          className={styles.canvas}
        >
        </canvas>
      </div>
    </ClickAwayListener>
  )
}

export default VideoThumbnailGenerator
