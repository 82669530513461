import * as React from 'react'
import { connect } from 'react-redux'
import StoreState, { StoreThunkDispatch } from 'store/state'
import { allFilesFolderSelector } from 'services/uploads/selectors'
import { getUploads } from 'services/uploads/actions'
import { FileFolder, WithIntl } from 'interfaces'
import { Observable } from 'rxjs/Observable'
import { injectIntl } from 'react-intl'
import { ConnectedFileFolderCarousel } from 'routes/find/routes/my-uploads/components/FileFolderCarousel'
import { SECTION_URL_MY_LIBRARY } from '../../home.config'

interface RecentUploadsCarouselProps extends WithIntl {
  folder: FileFolder
  title?: string
  fetchUploads: () => Promise<any>
}

const RecentUploadsCarousel = React.memo(function (props: RecentUploadsCarouselProps) {
  React.useEffect(() => {
    props.fetchUploads()
  }, [])

  const folder = {
    ...props.folder,
    name: props.title || props.intl.formatMessage({ id: `find.home.sections.my-library` })
  }

  return (
    <ConnectedFileFolderCarousel
      folder={folder}
      withEmptyViewCTA
      withCTACard
      navPrefix={SECTION_URL_MY_LIBRARY}
    />
  )
})

function mapStateToProps(state: StoreState) {
  return {
    folder: allFilesFolderSelector(state)
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    fetchUploads: () => dispatch(getUploads()).unwrap()
  }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RecentUploadsCarousel))
