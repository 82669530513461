import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'

import styles from './ErrorFallback.pcss'

export interface ErrorFallbackProps {
  error: Error
  resetErrorBoundary: () => void
}

export function ErrorFallback(props: ErrorFallbackProps) {
  const [errorVisible, setErrorVisible] = React.useState(false)
  const isWebpackError = props.error.message?.toLowerCase().match(/(loading).*(chunk)/g)

  React.useEffect(() => {
    // Reload the page if it's a webpack chunk loading error
    if (isWebpackError) {
      window.location.reload()
    }
  }, [isWebpackError])

  const toggleError = () => {
    setErrorVisible(current => !current)
  }

  if (isWebpackError) {
    return null
  }

  return (
    <Dialog open maxWidth="xs">
      <DialogTitle className={styles.title}>
        <FormattedMessage id="errors.app-crash" />
      </DialogTitle>
      <div className={`${styles.message} ${errorVisible ? styles.visible : ''}`}>
        <p>{`Original error: ${props.error.message}`}</p>
        <p>{`Stack trace: ${props.error.stack}`}</p>
      </div>
      <DialogActions className={styles.actions}>
        <a onClick={toggleError}>
          {errorVisible ? 'hide error' : 'see error'}
        </a>
        <Button className={styles.button} color="primary" variant="contained" onClick={props.resetErrorBoundary}>
          <FormattedMessage id="actions.refresh" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}
