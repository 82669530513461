import { AnyContent, Paginated } from 'interfaces'
import { StoreThunkAction, getState, StoreThunkDispatch } from 'store/state'
import { contentCacheForKeySelector } from '../selectors'
import { cacheContent, expireCache } from '..'

// Cache time in ms
const CACHE_TTL = 600000 // 10 minutes

/**
 * Returns Content from cache
 *
 * @export
 * @param {string} key
 * @returns {(StoreThunkAction<Paginated<AnyContent[]> | undefined>)}
 */
export function getCachedContent(key: string): StoreThunkAction<Paginated<AnyContent[]> | undefined> {
  return (_dispatch: StoreThunkDispatch, getState: getState) => {
    const cachedContent = contentCacheForKeySelector(getState(), key)

    if (cachedContent) {
      return cachedContent
    }

    return undefined
  }
}

function getExpirationTimer(callback: () => any, timeout: number) {
  return window.setTimeout(callback, timeout)
}

export function cacheContentTemp(key: string, content: Paginated<AnyContent[]>, ttl = CACHE_TTL): StoreThunkAction<any> {
  return (dispatch) => {
    const expireTimer = getExpirationTimer(() => dispatch(expireCache(key)), ttl)
    return dispatch(cacheContent({ key, content, timer: expireTimer }))
  }
}
