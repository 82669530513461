import { createElement, Component } from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

const LOADER_SIZE = 24
export const LOGIN_BTN_ID = 'btn-login'
import styles from './LoginButton.pcss'

export interface LoginButtonProps {
  buttonClassName: string
  progressClassName: string
  className?: string
  initialized: boolean
  displayInitialized: string
  text?: boolean
  onLogin: () => Promise<any>
}

interface LoginButtonState {
  loading: boolean
}

function setStateIsLoading(previousState: LoginButtonState) {
  return { ...previousState, loading: true }
}

function setStateIsNotLoading(previousState: LoginButtonState) {
  return { ...previousState, loading: false }
}

export class LoginButton extends Component<LoginButtonProps, LoginButtonState> {
  private activeButtonProps: ButtonProps
  private disabledButtonProps: ButtonProps

  constructor(props: LoginButtonProps) {
    super(props)

    this.activeButtonProps = {
      key: 'primary',
      id: LOGIN_BTN_ID,
      variant: 'contained',
      onClick: this.onLogin.bind(this),
      classes: {
        root: `${props.buttonClassName} ${props.className || ''} ${styles.button}`
      }
    }

    this.disabledButtonProps = {
      ...this.activeButtonProps,
      disabled: true,
      className: styles['btn-loading'],
      classes: {
        root: `${this.activeButtonProps?.classes?.root} ${styles.disabled} ${styles.button}`
      }
    }

    this.state = { loading: false }

    this.onLogin = this.onLogin.bind(this)
    this.onLoginSuccess = this.onLoginSuccess.bind(this)
    this.onLoginFailure = this.onLoginFailure.bind(this)
  }

  shouldComponentUpdate(nextProps: LoginButtonProps, nextState: LoginButtonState) {
    return nextState.loading !== this.state.loading
      || nextProps.initialized !== this.props.initialized
  }

  onLogin() {
    this.setState(setStateIsLoading)
    this.props.onLogin()
      .then(this.onLoginSuccess)
      .catch(this.onLoginFailure)
    return false
  }

  onLoginSuccess() {
    this.setState(setStateIsNotLoading)
  }

  onLoginFailure() {
    this.setState(setStateIsNotLoading)
  }

  render() {
    const enabled = !this.state.loading && this.props.initialized

    if (this.props.text) {
      return createElement('a', {
        className: `${styles['btn-text']} ${enabled ? '' : styles.disabled} ${this.props.className || ''}`,
        onClick: this.onLogin
      }, this.props.displayInitialized)
    }

    if (enabled) {
      return createElement(Button, { ...this.activeButtonProps, onClick: this.onLogin }, this.props.displayInitialized)
    }

    return createElement(Button, this.disabledButtonProps,
      createElement(CircularProgress, { className: this.props.progressClassName, size: LOADER_SIZE })
    )
  }
}

export default LoginButton
