import CreativeEditorSDK, { Configuration } from '@cesdk/cesdk-js'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './CreativeEditor.pcss'
import { CREATIVE_EDITOR_SDK_LICENSE, CREATIVE_EDITOR_USER_ID } from 'config'

const CE_SDK_CONFIG: Configuration = {
  license: CREATIVE_EDITOR_SDK_LICENSE,
  userId: CREATIVE_EDITOR_USER_ID,
  role: 'Adopter',
  baseURL: '/static/photoeditor/assets',
  ui: {
    elements: {
      view: 'default',
      blocks: {
        // Hide the add/remove page button
        '//ly.img.ubq/page': {
          manage: false
        }
      },
      libraries: {
        insert: {
          entries: (defaultEntries) => {
            return defaultEntries.filter(entry => ['ly.img.text', 'ly.img.vectorpath', 'ly.img.sticker'].includes(entry.id))
          }
        }
      },
      navigation: {
        action: {
          export: false
        }
      }
    }
  }
}

interface CreativeEditorProps extends React.HTMLAttributes<HTMLDivElement> {
  config?: Partial<Configuration>
  containerClassName?: string
  video?: boolean
  configure?: (instance: CreativeEditorSDK) => Promise<void>
  onInstanceChange?: (instance: CreativeEditorSDK | null) => void
  onExport?: (blobs: Blob[], options: any) => void
}

export function CreativeEditor({
  config, containerClassName, configure, video, onInstanceChange, onExport, ...rest
}: CreativeEditorProps) {
  const containerRef = useRef(null)

  useEffect(() => {
    if (!containerRef.current) return

    const container = containerRef.current
    let instance: CreativeEditorSDK | null = null
    let removed = false

    const configuration = { ...config, ...CE_SDK_CONFIG }

    if (onExport) {
      configuration.ui!.elements!.navigation = {
        action: {
          export: {
            show: true,
            format: video ? ['video/mp4'] : ['image/png']
          }
        }
      }
      configuration.callbacks = {
        ...configuration.callbacks,
        onExport
      }
    }

    if (video) {
      configuration.ui!.elements!.libraries = {
        backgroundTrackLibraryEntries: ['ly.img.image', 'ly.img.video']
      } as any
    }

    CreativeEditorSDK.create(container, configuration).then(
      async (_instance) => {
        if (removed) {
          _instance.dispose()
          return
        }

        instance = _instance
        instance.addDefaultAssetSources()
        if (video) {
          instance.addDemoAssetSources({ sceneMode: 'Video' })
          await instance.createVideoScene()
        } else {
          await instance.createDesignScene()
        }

        instance.engine.editor.setSettingBool('placeholderControls/showButton', false)
        instance.engine.editor.setSettingBool('placeholderControls/showOverlay', false)

        if (configure) {
          await configure(instance)
        }
        if (onInstanceChange) {
          onInstanceChange(instance)
        }
      }
    )
    const cleanup = () => {
      removed = true
      instance?.dispose()
      instance = null
      if (onInstanceChange) {
        onInstanceChange(null)
      }
    }
    return cleanup
  }, [containerRef, config, configure, onInstanceChange, onExport, video])

  return <div ref={containerRef} {...rest} className={`${styles.editor} ${containerClassName || ''}`}></div>
}

// These typed hooks allow for autocomplete inside jsx files
export const useConfig = useMemo<Partial<Configuration>>
export const useConfigure = useCallback<
  (instance: CreativeEditorSDK) => Promise<void>
>
export const useCreativeEditor = useState<CreativeEditorSDK | undefined>
export const useCreativeEditorRef = useRef<CreativeEditorSDK | undefined>
