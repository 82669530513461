import * as React from 'react'
import { FileValidator, FileLoader } from 'components/FileHelpers'
import { injectIntl } from 'react-intl'
import { WithIntl, FileType } from 'interfaces'
import { getFileTypeByMimeType } from 'utils/file'
import { NativeVideoPlayer } from 'components/VideoPlayer'
import ErrorIcon from '@mui/icons-material/WarningRounded'
import RotateIcon from '@mui/icons-material/RotateRight'
import DeleteIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import GifPlayer from 'components/GifPlayer'
import Tooltip from '@mui/material/Tooltip'

import styles from './MemoryFilePreviewCard.pcss'

export interface MemoryFilePreviewCardProps extends WithIntl {
  file: File
  className?: string
  validation?: {
    maxSize?: number // bytes
    allowedFormats?: string[]
  }
  selected?: boolean
  rotateBy: string
  mediaElementId?: string
  noValidation?: boolean
  onRotate?: (fileName: string) => void
  onClick?: (fileName: string) => void
  onDelete?: (fileName: string) => void
  onError: (fileName: string, error: string) => void
  onImageLoaded?: (name: string, src: string) => void
}

export const MemoryFilePreviewCard = React.memo(function MemoryFilePreviewCard(props: MemoryFilePreviewCardProps) {
  const [validationDone, setValidationDone] = React.useState(false)
  const [isValid, setIsValid] = React.useState(false)
  const [error, setError] = React.useState<string | undefined>(undefined)
  const [fileSrc, setFileSrc] = React.useState<string | undefined>(undefined)
  const fileType = getFileTypeByMimeType(props.file.type)
  const rotateImageStyles = props.rotateBy !== undefined && fileType === FileType.Image
    ? { transform: `rotate(${props.rotateBy}deg)` }
    : undefined

  if (!error && fileType === undefined) {
    const errorMessage = props.intl.formatMessage({ id: 'file.messages.invalid-type' })
    setError(errorMessage)
    props.onError(props.file.name, errorMessage)
  }

  const onValidationPassed = () => {
    setIsValid(true)
    setValidationDone(true)
  }

  const onValidationFailed = (error: string) => {
    if (props.noValidation) {
      onValidationPassed()
      return
    }
    setIsValid(false)
    setValidationDone(true)
    setError(error)
    props.onError(props.file.name, error)
  }

  const onFileLoadSuccess = (src: string) => {
    setFileSrc(src)
    if (props.onImageLoaded) {
      props.onImageLoaded(props.file.name, src)
    }
  }

  const onFileLoadFailed = (error: string) => {
    setError(error)
    props.onError(props.file.name, error)
    setFileSrc(undefined)
  }

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(props.file.name)
    }
  }

  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete(props.file.name)
    }
  }

  const toggleRotate = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (props.onRotate) {
      props.onRotate(props.file.name)
    }
  }

  const errorClassName = error ? styles['with-error'] : ''
  const selectedClassName = props.selected ? styles.selected : ''
  const isStaticImage = fileSrc && fileType === FileType.Image

  return (
    <div
      className={`${styles.wrapper} ${props.className || ''} ${errorClassName} ${selectedClassName}`}
      data-testid="file-preview-card"
      onClick={handleClick}
    >
      <FileValidator
        file={props.file}
        maxSize={props.validation?.maxSize}
        allowedFileFormats={props.validation?.allowedFormats}
        onValidationError={onValidationFailed}
        onValidationSuccess={onValidationPassed}
      >
        <div className={styles.content}>
          {
            validationDone && isValid && fileType !== FileType.Video && (
              <FileLoader
                file={props.file}
                withProgress
                className={styles.loader}
                onError={onFileLoadFailed}
                onLoaded={onFileLoadSuccess}
              />
            )
          }
          {
            props.onDelete && (
              <IconButton size="small" className={styles['btn-delete']} onClick={onDelete}>
                <DeleteIcon className={styles.icon} />
              </IconButton>
            )
          }
          {
            isStaticImage && props.onRotate && (
              <IconButton size="small" className={styles['btn-rotate']} onClick={toggleRotate}>
                <RotateIcon className={styles.icon} />
              </IconButton>
            )
          }
          {
            isStaticImage && (
              <img src={fileSrc} style={rotateImageStyles} className={styles.image} data-id={props.mediaElementId} />
            )
          }
          {
            fileSrc && fileType === FileType.Gif && (
              <GifPlayer gifDataUrl={fileSrc} gifUrl="" mediaElementId={props.mediaElementId} />
            )
          }
          {
            validationDone && isValid && fileType === FileType.Video && (
              <NativeVideoPlayer
                videoDataUrl={URL.createObjectURL(props.file)}
                togglePlayOnHover
                muted
                className={styles.video}
                playBtnClassName={styles['btn-play']}
                mediaElementId={props.mediaElementId}
              />
            )
          }
          {
            error && (
              <Tooltip placement="top" title={error}>
                <p className={styles['error-msg']}>
                  <span className={styles.filename} title={props.file.name}>
                    <ErrorIcon className={styles['err-icon']} />
                    {props.file.name}
                  </span>
                </p>
              </Tooltip>
            )
          }
        </div>
      </FileValidator>
    </div>
  )
})

export default injectIntl(MemoryFilePreviewCard)
