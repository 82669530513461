import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { ContentType, FileFolder, Stream, Feed, ARTICLE_TYPE } from 'interfaces'
import { CarouselView } from 'components/ContentLayout'
import { ConnectedFileFolderCarousel } from 'routes/find/routes/my-uploads/components/FileFolderCarousel'
import StatusIdeasCategoryListView from 'components/CuratedFolderView/StatusIdeasCategoryListView'
import STATUSES_CATEGORIES from 'routes/find/routes/statuses/statuses.config'
import CardList from 'components/CardList'
import { renderLoadingCards } from '../utils'
import ContentTypeFilter from 'components/ContentTypeFilter'

const LOADING_CARDS_COUNT = 10
import styles from '../HomeSection.pcss'
import { QuoteFeed } from 'interfaces/Content/QuoteFeed'
import QuotesFeedView from 'components/QuotesFeedView'

export interface MostRecentSourceCarouselProps {
  title?: string
  source?: Feed | Stream | FileFolder | QuoteFeed
  filters: ContentType[]
  activeFilter?: ContentType
  navPrefix: string
  onActiveFilterChange: (filter: ContentType) => void
}

export const MostRecentSourceCarousel = React.memo(function MostRecentSourceCarousel(props: MostRecentSourceCarouselProps) {
  const { source, filters, activeFilter, onActiveFilterChange } = props

  const renderCarousel = () => {
    if (source?.isStream || source?.isFeed) {
      return (
        <CarouselView
          source={source as any}
          filter={activeFilter || ARTICLE_TYPE}
        />
      )
    }

    if (source?.isFileFolder) {
      return (
        <ConnectedFileFolderCarousel
          folder={source as FileFolder}
          title={(
            <header className={styles.header}>
              <h2 className={`${styles.title} text-ellipsis`}>
                {props.title || <FormattedMessage id="find.home.sections.most-recent" />}
              </h2>
              {
                source && (
                  <NavLink to={props.navPrefix} className={`${styles.navigation} text-ellipsis`}>
                    {(source as any).name || (source as any).title}
                  </NavLink>
                )
              }
            </header>
          )}
        />
      )
    }

    if (source?.isStatusIdeasFeed) {
      const folder = STATUSES_CATEGORIES.find(cat => cat.slug === (source as Feed).handle)
      if (folder) {
        return (
          <StatusIdeasCategoryListView folder={folder} />
        )
      }
    }

    if (source?.isQuoteFeed) {
      return (
        <QuotesFeedView hideHeader feed={source as any} />
      )
    }

    return (
      <CardList containerClassName={styles['list-loading']}>
        {renderLoadingCards(LOADING_CARDS_COUNT, false, true)}
      </CardList>
    )
  }

  return (
    <section className={styles.container}>
      {
        !source?.isFileFolder && (
          <header className={styles.header}>
            <h2 className={`${styles.title} text-ellipsis`}>
              <FormattedMessage id="find.home.sections.most-recent" />
            </h2>
            {
              source && (
                <NavLink to={props.navPrefix} className={`${styles.navigation} text-ellipsis`}>
                  {(source as any).name || (source as any).title}
                </NavLink>
              )
            }
            {
              filters.length !== 0 && (
                <ContentTypeFilter
                  all={false}
                  renderSingle={source?.isFileFolder}
                  options={filters}
                  selected={activeFilter}
                  onFilterSelected={onActiveFilterChange}
                />
              )
            }
          </header>
        )
      }

      {renderCarousel()}
    </section>
  )
})

export default MostRecentSourceCarousel
