import { PostDestination, List, PPProduct } from 'interfaces'

export const SETTINGS_STATE_VERSION = 'settings:1'

export interface TeamMember {
  accounts: Readonly<{
    [id: string]: string
  }>
  adminId: string
  originalAdminId?: string
  adminName: string
  email: string
  fbAccountId: string
  isAdmin: 0 | 1
  teamRole: string
  twAccountId: string
  adminFbId?: string
  photoUrl?: string
  invitationLink?: string
}

export interface TeamState {
  adminsLimit: number
  friends: boolean
  isInvited: 0 | 1
  masterName: string
  members: Readonly<{
    [userId: string]: Readonly<TeamMember>
  }>
}

export interface AccountState {
  profile: Readonly<{
    company: Readonly<string>
    email: Readonly<string>
    industry: Readonly<string>
    name: Readonly<string>
    lastName: Readonly<string>
  }>

  subscription: Readonly<{
    name: Readonly<string>
    description: Readonly<string>
    usersLimit: Readonly<number>
    profilesLimit: Readonly<number>
    billingPortalLink: Readonly<string>
    updatePaymentLink: Readonly<string>
    useCustomProfilesLimit?: boolean
  }>

  creditCard?: Readonly<{
    ownerName: Readonly<string>
    // visa, master, discover, american_express, diners_club, jcb, switch, solo, dankort, maestro, forbrugsforeningen, laser
    type: Readonly<string>
    expirationMonth: Readonly<number>
    expirationYear: Readonly<number>
  }>
}

export interface SettingsState {
  defaults: Readonly<{
    timezone: Readonly<string>
    timezones: Readonly<{ [name: string]: string }>
    profiles: Readonly<PostDestination[]>
    lists: Readonly<List[]>
  }>

  team?: Readonly<TeamState>
  account?: Readonly<AccountState>
}

export function initialState(): SettingsState {
  return {
    defaults: {
      timezone: '',
      profiles: [],
      lists: [],
      timezones: {}
    },
    team: undefined,
    account: undefined
  }
}

export default SettingsState
