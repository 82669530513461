import React from 'react'

export const XIcon = ({ size = '16', className = '' }) => {
  return (
    <img
      src="/static/img/icons/x-icon.svg"
      style={{ width: `${size}px`, height: `${size}px` }}
      className={className}
      alt="X Logo"
    />
  )
}

export default XIcon
