import * as React from 'react'
import { AddFeedCard, AddFeedCardProps } from './AddFeedCard'
import { StoreThunkDispatch } from 'store/state'
import { createFeed, getOriginalFeed } from 'services/content/feeds/actions'
import { ContentFeedType, Feed, FEED_TYPE_KEYWORD } from 'interfaces'
import { getFeedImageByUrl, getFeedHandle } from 'utils/search'
import { message } from 'services/snackbar'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

interface AddFeedCardContainerProps extends Omit<AddFeedCardProps, 'onAddClick'> {
  selected: boolean
  selectedIds?: string[]
  onFeedCreated?: (feed: Feed, liveId: string) => void
  onFeedCreateError?: (handle: string, type: ContentFeedType, error: Error) => void
  onSelect?: (feed: Feed, replaceFeed?: Feed) => void
}

function AddFeedCardContainer(props: AddFeedCardContainerProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const intl = useIntl()
  const [loading, setLoading] = React.useState(false)
  const [newFeed, setNewFeed] = React.useState<Feed | null>(null)

  const onSelect = (feed?: Feed) => {
    if (!props.onSelect) {
      return
    }

    if (feed) {
      props.onSelect(feed)
      return
    }

    const tempFeed: Feed = {
      name: props.displayName || props.handle,
      id: props.handle,
      handle: props.handle,
      isFeed: true,
      networkId: props.networkId,
      order: 0,
      sources: [],
      type: props.type,
      uniqueSource: props.handle,
      image: props.pictureUrl,
      live: true
    }

    props.onSelect(tempFeed)
    handleCreateFeed((f: Feed) => {
      if (props.onSelect) {
        props.onSelect(f, tempFeed)
      }
      if (props.onFeedCreated) {
        props.onFeedCreated(f, props.handle)
      }
    })
  }

  const onClick = () => {
    handleCreateFeed((feed: Feed) => {
      if (props.onFeedCreated) {
        props.onFeedCreated(feed, props.handle)
      }
      if (props.onNewFeedClick) {
        props.onNewFeedClick(feed)
      }
    })
  }

  const handleCreateFeed = (onCreated: (feed: Feed) => void, onError?: (error: Error) => void) => {
    if (loading) {
      return
    }
    setLoading(true)

    dispatch(createFeed({
      handle: getFeedHandle(props.type, props.handle),
      type: props.type,
      image: props.pictureUrl,
      name: props.displayName,
      networkId: props.networkId
    }))
      .unwrap()
      .then(response => dispatch(getOriginalFeed((response as any).originalSource)).unwrap())
      .then((feed) => {
        console.log('[AddFeedCard] create feed success', feed)
        const createdFeed: Feed = { ...feed, isNew: true, image: feed.image || props.pictureUrl }
        setLoading(false)
        setNewFeed(createdFeed)
        onCreated(createdFeed)
      })
      .catch((error) => {
        console.log('[AddFeedCard] create feed error', error)
        const errorText = props.type === FEED_TYPE_KEYWORD
          ? intl.formatMessage({ id: 'errors.generic' })
          : intl.formatMessage({ id: 'errors.invalid-url' })

        dispatch(message(errorText, 'error'))
        setLoading(false)
        setNewFeed(null)
        if (onError) {
          onError(error)
        }
        if (props.onFeedCreateError) {
          props.onFeedCreateError(props.handle, props.type, error)
        }
      })
  }

  const picture = props.pictureUrl || getFeedImageByUrl(props.type, props.handle)

  const isSelected = props.selectedIds && newFeed ? props.selectedIds.includes(newFeed.uniqueSource) : props.selected

  return (
    <AddFeedCard
      handle={props.handle}
      type={props.type}
      newFeed={newFeed}
      onClick={onClick}
      onNewFeedClick={props.onNewFeedClick}
      pictureUrl={picture}
      displayName={props.displayName}
      titleHTML={props.titleHTML}
      networkId={props.networkId}
      loading={loading}
      selected={isSelected}
      className={props.className}
      onSelectButtonClick={props.onSelect ? onSelect : undefined}
    />
  )
}

export default AddFeedCardContainer
