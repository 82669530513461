import * as React from 'react'
import { BRAND_GOOGLE, Bucket, PinterestBoard, PostDestination, WithIntl } from 'interfaces'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import styles from './BucketUpdatePopup.pcss'
import { FormattedMessage, injectIntl } from 'react-intl'
import { EditableBucket } from '../EditableBucket'
import useAsyncAction from 'hooks/useAsyncAction'
import { useDispatch, useSelector } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { deleteBucket, DeleteMethod, updateBucket } from 'services/post/actions'
import { message, warning } from 'services/snackbar'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import { connectedDestinationsSelector, getAlbums, getPinterestBoards } from 'services/destinations'
import { bucketsSelector } from 'services/post/selectors'
import { Subject } from 'rxjs/Subject'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import BucketProfilesPicker from '../BucketProfilesPicker'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import { BucketOptions } from '../BucketOptions'
import Checkbox from '@mui/material/Checkbox'
import { DateRange } from '@mui/x-date-pickers-pro'
import ProfileAvatar from 'components/ProfileAvatar'
import { arrayToRecord, groupByKey } from 'shared/utils'
import { SocialIcon } from 'components/SocialIcon'
import { FacebookAlbumSelector } from 'components/Composer/AlbumSelector/FacebookAlbumSelector'
import { PinterestBoardSelector } from 'components/Composer/AlbumSelector/PinterestBoardSelector'
import { FBAlbum } from 'services/compose/interfaces/ComposerFacebookPost'
import { pinterestProfilesBoardsSelector } from 'services/compose/selectors'
import PPSwitch from 'components/PPSwitch'
import { BucketCard } from 'components/Buckets'
import { add, format } from 'date-fns'
import { UTCDate } from '@date-fns/utc'

interface BucketUpdatePopupProps {
  bucket: Bucket
  onClose: (refresh?: boolean) => void
}

export function BucketUpdatePopup(props: BucketUpdatePopupProps & WithIntl) {
  const { onClose } = props
  const now = new Date()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const boards = useSelector(pinterestProfilesBoardsSelector)
  const allBuckets = useSelector(bucketsSelector)
  const otherBuckets = React.useMemo(() => allBuckets.filter(b => b.id !== props.bucket.id), [allBuckets, props.bucket.id])
  const [mode, setMode] = React.useState<'edit' | 'delete'>('edit')
  const [deleteMethod, setDeleteMethod] = React.useState<DeleteMethod>(DeleteMethod.Delete)
  const [selectedTargetBucketId, setSelectedTargetBucketId] = React.useState<string>(otherBuckets[0]?.id || '')
  const [updated, setUpdated] = React.useState(props.bucket)
  const [copyFromPpid, setCopyFromPpid] = React.useState<string>('none')
  const from = props.bucket.activeFrom ? new UTCDate(props.bucket.activeFrom) : now
  // eslint-disable-next-line no-magic-numbers
  const to = props.bucket.activeTo ? new UTCDate(props.bucket.activeTo) : add(now, { days: 30 })
  const [dateRange, setDateRange] = React.useState<DateRange<Date>>([from, to])
  const [withTimePeriod, setWithTimePeriod] = React.useState(Boolean(props.bucket.activeFrom && props.bucket.activeTo))
  const [selectedFBAlbums, setSelectedFBAlbums] = React.useState<Record<string, FBAlbum | undefined>>({})
  const [selectedPinterestboards, setSelectedPinterestBoards] = React.useState<Record<string, PinterestBoard>>({})
  const [updateLoading, setUpdateLoading] = React.useState(false)

  const onUpdate = (
    id: string,
    name: string,
    color: string,
    description: string,
    isPublic: boolean,
    ppids: string[],
    copyPostsFromPpid: string | undefined,
    activeFrom: string | undefined,
    activeTo: string | undefined,
    fbAlbums?: FBAlbum[],
    piBoards?: PinterestBoard[]
  ) => {
    const data = {
      id,
      name,
      color,
      description,
      isPublic,
      ppids,
      copyFromPpid: copyPostsFromPpid,
      activeFrom,
      activeTo,
      fbAlbums,
      piBoards
    }
    setUpdateLoading(true)
    return dispatch(updateBucket(data))
      .unwrap()
      .then(response => {
        onClose(true)
        if (response.copyResult && response.copyResult.downloadReport) {
          dispatch(warning(
            props.intl.formatMessage(
              { id: 'post.buckets.edit.copy-posts-response' },
              { countOk: response.copyResult.countOk, countError: response.copyResult.countErrors }
            ),
            props.intl.formatMessage({ id: 'post.buckets.edit.copy-posts.download-report' }),
            () => { window.open(response.copyResult.downloadReport, '_blank') },
            5000 // eslint-disable-line no-magic-numbers
          ))
        }
      })
      .catch(() => {
        dispatch(message(props.intl.formatMessage({ id: 'notifications.generic.update-failed' }), 'error'))
      })
      .finally(() => {
        setUpdateLoading(false)
      })
  }
  const profiles = useSelector(connectedDestinationsSelector)
  const profilesArray = Object.values(profiles)
  const profilesByPpid = arrayToRecord(profilesArray, 'ppid')
  const initialSelectedProfileIds: Record<string, boolean> = props.bucket.ppids.reduce((map: Record<string, boolean>, ppid) => {
    const p = profilesByPpid[ppid]
    if (p) {
      map[p.id] = true
    }
    return map
  }, {})
  const [selectedProfileIds, setSelectedProfileIds] = React.useState<Record<string, boolean>>(initialSelectedProfileIds)
  const removedProfileIds = Object.keys(initialSelectedProfileIds).filter(id => !selectedProfileIds[id])
  const addedProfileIds = Object.keys(selectedProfileIds).filter(id => !initialSelectedProfileIds[id])
  const addedProfilesByNetwork = groupByKey(addedProfileIds.map(id => profiles[id]), 'type')

  // Clear Copy posts settings if no profiles are added
  React.useEffect(() => {
    if (addedProfileIds.length === 0) {
      setCopyFromPpid('none')
      setSelectedFBAlbums({})
      setSelectedPinterestBoards({})
    }
  }, [addedProfileIds.length])

  const fetchFBAlbums = React.useCallback((profile: PostDestination) => {
    return dispatch(getAlbums(profile)).unwrap()
  }, [dispatch])

  const fetchPIBoards = React.useCallback((profile: PostDestination) => {
    return dispatch(getPinterestBoards(profile)).unwrap()
  }, [dispatch])

  const toggleMode = () => {
    setMode(current => current === 'edit' ? 'delete' : 'edit')
  }

  const onSubmit = () => {
    if (!updated.name) {
      dispatch(message(props.intl.formatMessage({ id: 'post.buckets.form.error-name-empty' }), 'warning'))
      return
    }
    const ppids = Object.keys(selectedProfileIds).map(id => profiles[id].ppid)
    let from = dateRange[0] ? format(dateRange[0], 'yyyy-LL-dd') : undefined
    let to = dateRange[1] ? format(dateRange[1], 'yyyy-LL-dd') : undefined
    if (!withTimePeriod) {
      from = undefined
      to = undefined
    }
    const withCopyPosts = copyFromPpid !== 'none'
    const copyPpid = withCopyPosts ? copyFromPpid : undefined
    const albums = withCopyPosts ? Object.values(selectedFBAlbums).filter(Boolean) as FBAlbum[] : undefined
    const boards = withCopyPosts ? Object.values(selectedPinterestboards).filter(Boolean) : undefined
    onUpdate(
      props.bucket.id,
      updated.name,
      updated.color + '',
      updated.description || '',
      Boolean(updated.isPublic),
      ppids,
      copyPpid,
      from,
      to,
      albums,
      boards
    )
  }

  const onDeleteMethodChange = (_e: any, value: any) => {
    setDeleteMethod((DeleteMethod as any)[value])
  }

  const onDelete = React.useCallback(() => {
    dispatch(deleteBucket({
      id: props.bucket.id,
      method: deleteMethod,
      targetBucketId: selectedTargetBucketId
    }))
      .unwrap()
      .then(() => { onClose() })
      .catch(() => {
        dispatch(message(props.intl.formatMessage({ id: 'errors.generic' }), 'error'))
      })
  }, [dispatch, props.bucket.id, props.intl, deleteMethod, selectedTargetBucketId, onClose])

  const onSelectedBucketChange = (id: string) => {
    setSelectedTargetBucketId(id)
  }

  const onChange = React.useCallback((b: Partial<Bucket>) => {
    setUpdated(current => ({ ...current, ...b }))
  }, [])

  const bucketsOptions: PPSelectOptions = otherBuckets.reduce((map: PPSelectOptions, bucket) => {
    map[bucket.id] = { label: bucket.name }
    return map
  }, {})

  const toggleWithTimePeriod = () => {
    setWithTimePeriod(!withTimePeriod)
  }

  const close = () => {
    props.onClose()
  }

  const onSelectedProfilesChange = (ids: string[]) => setSelectedProfileIds(ids.reduce((map: Record<string, boolean>, id) => {
    map[id] = true
    return map
  }, {}))

  const onRemovedProfileClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const id = e.currentTarget.dataset.profileId
    if (id) {
      setSelectedProfileIds(current => ({ ...current, [id]: true }))
    }
  }

  const onAddedProfileClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const id = e.currentTarget.dataset.profileId
    if (id) {
      const next = { ...selectedProfileIds }
      delete next[id]
      setSelectedProfileIds(next)
    }
  }

  const onFBSelectedAlbumChange = React.useCallback((profile: PostDestination, album: FBAlbum | undefined) => {
    setSelectedFBAlbums(current => ({
      ...current,
      [profile.id]: album
    }))
  }, [])

  const onSelectedBoardChange = React.useCallback((ppid: string, board: PinterestBoard) => {
    setSelectedPinterestBoards(current => ({
      ...current,
      [ppid]: board
    }))
  }, [])

  const profilesDropdownOptions: PPSelectOptions = Object.keys(profilesByPpid).reduce((map: any, ppid: string, index) => {
    const profile = profilesByPpid[ppid]
    if (initialSelectedProfileIds[profile.id] && !removedProfileIds.includes(profile.id)) {
      map[ppid] = {
        label: (
          <div className={styles['select-item']}>
            <SocialIcon
              badge
              className={styles.icon}
              icon={profile.type}
              size="16px"
            />
            <span className={`${styles.text} text-ellipsis`}>{profilesByPpid[ppid].name}</span>
          </div>
        ),
        order: index
      }
    }
    return map
  }, {})

  profilesDropdownOptions.none = {
    label: (
      <div className={styles['select-item']}>
        <FormattedMessage id="label.generic.none" />
      </div>
    ),
    order: -1
  }

  const showSettings = addedProfilesByNetwork.pinterest?.length > 0 || addedProfilesByNetwork.facebook?.length > 0

  return (
    <Dialog open onClose={close}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>
            <FormattedMessage id={`post.buckets.form.title-${mode}`} />
          </h2>
          {mode === 'edit' && (
            <Button
              variant="text"
              className={styles['btn-toggle']}
              onClick={toggleMode}
            >
              <FormattedMessage id="post.buckets.form.label-delete" />
            </Button>
          )}
        </div>
        <BucketCard bucket={updated} noSelection className={styles['bucket-card']} />
        <div className={styles.content}>
          {mode === 'edit' && (
            <React.Fragment>
              <div className={styles['section-title']}>
                <span className={styles.step}>1</span>
                <FormattedMessage id="post.buckets.form.title-name-color" />
              </div>
              <EditableBucket
                bucket={props.bucket}
                loading={updateLoading}
                className={styles['bucket-form']}
                onChange={onChange}
              />

              <div>
                <div className={styles['section-title']}>
                  <span className={styles.step}>2</span>
                  <FormattedMessage id="post.buckets.form.title-profiles" />
                </div>
                <div className={styles['profiles-box']}>
                  <BucketProfilesPicker
                    selectedProfileIds={Object.keys(selectedProfileIds)}
                    onSelectedProfilesChange={onSelectedProfilesChange}
                  />
                </div>
                {removedProfileIds.length > 0 && (
                  <div className={`${styles.subsection} ${styles['sub-profiles']}`}>
                    <div className={styles['section-title']}>
                      <FormattedMessage id="post.buckets.form.title-removed-profiles" />
                    </div>
                    <div className={styles['profiles-list']}>
                      {removedProfileIds.map(id => {
                        const profile = profiles[id]
                        if (!profile) {
                          return null
                        }
                        return (
                          <ProfileAvatar
                            key={profile.id}
                            title={profile.name}
                            src={profile.image}
                            brand={profile.type}
                            circle
                            data-profile-id={profile.id}
                            className={styles.avatar}
                            onClick={onRemovedProfileClick}
                          />
                        )
                      })}
                    </div>
                    <div className={styles.hint}>
                      <FormattedMessage id="post.buckets.form.removed-profiles-hint" />
                    </div>
                  </div>
                )}
                {addedProfileIds.length > 0 && (
                  <div className={`${styles.subsection} ${styles['sub-profiles']}`}>
                    <div className={styles['section-title']}>
                      <FormattedMessage id="post.buckets.form.title-added-profiles" />
                    </div>
                    <div className={styles['profiles-list']}>
                      {addedProfileIds.map(id => {
                        const profile = profiles[id]
                        if (!profile) {
                          return null
                        }
                        return (
                          <ProfileAvatar
                            key={profile.id}
                            title={profile.name}
                            src={profile.image}
                            brand={profile.type}
                            circle
                            data-profile-id={profile.id}
                            className={styles.avatar}
                            onClick={onAddedProfileClick}
                          />
                        )
                      })}
                    </div>
                    <div className={styles.hint}>
                      <PPSelect
                        name={<FormattedMessage id="post.buckets.form.copy-from-profile" />}
                        selectedValue={copyFromPpid || 'None'}
                        options={profilesDropdownOptions}
                        withCaret
                        raised
                        noLabelCaps
                        onSelectionChange={setCopyFromPpid}
                      />
                    </div>
                    {copyFromPpid !== 'none' && showSettings && (
                      <div className={styles['network-settings']}>
                        <div className={styles['section-title']}>
                          <FormattedMessage id="post.buckets.form.post-network-settings" />
                        </div>
                        {addedProfilesByNetwork.facebook?.length > 0 && addedProfilesByNetwork.facebook.map(profile => (
                          <div className={styles['option-box']} key={profile.id}>
                            <FacebookAlbumSelector
                              profile={profile}
                              raised
                              fetchAlbums={fetchFBAlbums}
                              selectedAlbums={Object.values(selectedFBAlbums).filter(Boolean) as FBAlbum[]}
                              onSelectedAlbumChange={onFBSelectedAlbumChange}
                            />
                          </div>
                        ))}
                        {addedProfilesByNetwork.pinterest?.length > 0 && addedProfilesByNetwork.pinterest.map(p => {
                          const profileBoards = boards[p.ppid]?.length > 0 ? boards[p.ppid] : Object.values(p.pinterestBoards || {})

                          return (
                            <div key={p.id} className={styles['option-box']}>
                              <PinterestBoardSelector
                                profile={p}
                                boards={profileBoards?.map(b => ({ ...b, selected: b.id === selectedPinterestboards[p.ppid]?.id })) || []}
                                raised
                                fetchBoards={fetchPIBoards}
                                setComposerPinterestBoard={onSelectedBoardChange}
                              />
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className={styles['options-title']}>
                <div className={styles['section-title']}>
                  <span className={styles.step}>3</span>
                  <FormattedMessage id="post.buckets.form.title-time-period" />
                </div>
                <PPSwitch
                  selectedValue={withTimePeriod ? 'on' : 'off'}
                  options={[{ value: 'off', label: 'Always' }, { value: 'on', label: 'Custom time period' }]}
                  className={styles.switch}
                  onSelectedValueChange={toggleWithTimePeriod}
                />
              </div>
              <div className={styles['options-box']}>
                <BucketOptions
                  dateRange={dateRange}
                  onChange={setDateRange}
                  disabled={!withTimePeriod}
                />
              </div>
              <div className={styles.actions}>
                <Button variant="text" onClick={close}>
                  <FormattedMessage id="actions.cancel" />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={styles['btn-save']}
                  disabled={updateLoading}
                  onClick={onSubmit}
                >
                  <FormattedMessage id="actions.save-bucket" />
                </Button>
              </div>
            </React.Fragment>
          )}
          {mode === 'delete' && (
            <div className={styles['delete-box']}>
              <RadioGroup
                value={deleteMethod}
                classes={{ root: styles['radio-group'] }}
                onChange={onDeleteMethodChange}
              >
                <FormControlLabel
                  label={(
                    <span className={styles['radio-label']}>
                      <FormattedMessage id="post.buckets.form.hint.delete" />
                    </span>
                  )}
                  classes={{ root: styles['radio-option'] }}
                  className={deleteMethod === DeleteMethod.Delete ? styles.active : ''}
                  control={(
                    <Radio
                      size="small"
                      color="primary"
                      value={DeleteMethod.Delete}
                      className={styles.radio}
                    />
                  )}
                />
                <FormControlLabel
                  label={(
                    <span className={styles['radio-label']}>
                      <FormattedMessage id="post.buckets.form.hint.delete-queue-posts" />
                    </span>
                  )}
                  classes={{ root: styles['radio-option'] }}
                  className={deleteMethod === DeleteMethod.Queue ? styles.active : ''}
                  control={(
                    <Radio
                      size="small"
                      color="primary"
                      value={DeleteMethod.Queue}
                      className={styles.radio}
                    />
                  )}
                />
                <FormControlLabel
                  label={(
                    <span className={styles['radio-label']}>
                      <FormattedMessage id="post.buckets.form.hint.delete-copy-posts" />
                    </span>
                  )}
                  classes={{ root: styles['radio-option'] }}
                  className={deleteMethod === DeleteMethod.Copy ? styles.active : ''}
                  control={(
                    <Radio
                      size="small"
                      color="primary"
                      value={DeleteMethod.Copy}
                      className={styles.radio}
                    />
                  )}
                />
              </RadioGroup>
              <div>
                <div className={`${styles['bucket-select-box']} ${deleteMethod === DeleteMethod.Copy ? styles.active : ''}`}>
                  <PPSelect
                    options={bucketsOptions}
                    withCaret
                    noLabelCaps
                    labelClassName={`${styles['select-btn']} text-ellipsis`}
                    popperProps={{ style: { zIndex: '1303' } }}
                    name={<FormattedMessage id="post.buckets.form.label-select-bucket" />}
                    selectedValue={selectedTargetBucketId || ''}
                    onSelectionChange={onSelectedBucketChange}
                  />
                </div>
              </div>
              <div className={styles.actions}>
                <Button variant="text" onClick={toggleMode}>
                  <FormattedMessage id="actions.cancel" />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={styles['btn-save']}
                  disabled={deleteMethod === DeleteMethod.Copy && !selectedTargetBucketId}
                  onClick={onDelete}
                >
                  <FormattedMessage id="actions.delete" />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  )
}

export default injectIntl(BucketUpdatePopup)
