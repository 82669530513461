import { RefObject, useEffect, useRef, useState } from 'react'

export function useElementSize<T extends Element>(deps: any[]): [RefObject<T>, { width: number, height: number }] {
  const elementRef = useRef<T>(null)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const observerRef = useRef<ResizeObserver>()

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect()
    } else {
      observerRef.current = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        for (const entry of entries) {
          if (entry.target.isSameNode(elementRef.current)) {
            setWidth(entry.contentRect.width)
            setHeight(entry.contentRect.height)
          }
        }
      })
    }
  }, [...deps])

  useEffect(() => {
    if (elementRef.current) {
      observerRef.current?.observe(elementRef.current)
    }

    return () => {
      observerRef.current?.disconnect()
    }
  }, [elementRef])

  return [elementRef, { width, height }]
}
