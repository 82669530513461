import * as React from 'react'
import ReactPlayer from 'react-player/youtube'

import styles from './EmbeddedYTPost.pcss'

interface EmbeddedYTPostProps {
  url: string
  onReady: () => void
}

function EmbeddedYTPost({ url, onReady }: EmbeddedYTPostProps): React.ReactElement {
  return (
    <ReactPlayer className={styles.player} url={url} onReady={onReady} volume={1} controls />
  )
}

export default EmbeddedYTPost
