import * as React from 'react'
import { isAuthenticated as isAuthenticatedSelector } from 'services/users/selectors'
import { Route, Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

export interface UnAuthenticatedRouteProps {
  redirect?: string
}

/**
 * Route that only mounts a component if the user is NOT authenticated.
 * Redirects to / or a given to "path" otherwise.
 *
 * @export
 * @template P The props type for the component
 * @param {UnAuthenticatedRouteProps<P>} { component, to, ...rest } the props
 * @returns Route element with conditional rendering
 */
export function UnAuthenticatedRoute(props: React.PropsWithChildren<UnAuthenticatedRouteProps>): JSX.Element {
  const location = useLocation()
  const params = location.search.toLowerCase()
  const isAuthenticated = useSelector(isAuthenticatedSelector)

  if (!isAuthenticated || params.indexOf('ott=') !== -1 || params.indexOf('hash=') !== -1) {
    return <React.Fragment>{props.children}</React.Fragment>
  }

  const from = location.state?.from || { pathname: props.redirect || '/' }
  console.log(`[unauthenticated-route] redirect: ${from.pathname} (default: ${props.redirect}) with auth: ${isAuthenticated}`)

  return <Navigate to={from.pathname !== '/logout' ? from.pathname : '/'} replace />
}

export default UnAuthenticatedRoute
