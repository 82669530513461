import memoize from 'memoize-one'

const FONTSIZES = [
  { length: 50, normal: { fontSize: '1.8rem' } },
  { length: 100, normal: { fontSize: '1.5rem' } },
  { length: 175, normal: { fontSize: '1.4rem' } },
  { length: 225, normal: { fontSize: '1.3rem' } },
  { length: Number.MAX_VALUE, normal: { fontSize: '1.2rem' } }
]

export const fontSizesFor = memoize((text: string) => {
  const length = text.length
  for (const size of FONTSIZES) {
    if (size.length > length) {
      return { normal: size.normal }
    }
  }
  return FONTSIZES[FONTSIZES.length - 1]
})
