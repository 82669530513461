import * as React from 'react'
import ErrorOverlay from 'components/ErrorOverlay'
import { MIN_SEARCH_LENGTH, SearchResultSectionState } from '../../state/interfaces'
import styles from './SearchResultsCarousel.pcss'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'
import CardList, { CARD_LIST_HEIGHT_SMALL, ITEM_WIDTH_SMALL } from 'components/CardList'
import { EmptyStreamCard, StreamCard } from 'components/Cards'
import { Stream } from 'interfaces'
import { SEARCH_FILTER_KEY_STREAMS, SearchFilterKey } from 'routes/search/state/types'

interface SearchResultsStreamCarouselProps extends SearchResultSectionState {
  activeQuery: string
  onRequestNextPage: (section: SearchFilterKey) => void
  onStreamClick: (stream: Stream) => void
}

export function SearchResultsStreamCarousel(props: SearchResultsStreamCarouselProps) {
  const { loading, items, activeQuery, onRequestNextPage } = props
  const withQuery = !loading && activeQuery.length >= MIN_SEARCH_LENGTH
  const isEmpty = items.length === 0 && !withQuery

  const loadNextPage = React.useCallback(() => {
    onRequestNextPage('streams')
  }, [onRequestNextPage])

  const cards = React.useMemo(() => {
    const defaultCardsCount = 20
    const loadingCards = []
    const emptyCards = []
    if (props.loading) {
      for (let i = 0; i < defaultCardsCount; i++) {
        loadingCards.push(<EmptyStreamCard key={`loading-${i}`} loading />)
      }
    }
    if (!props.loading && props.items.length === 0) {
      for (let i = 0; i < defaultCardsCount; i++) {
        emptyCards.push(<EmptyStreamCard flat key={`empty-${i}`} className={styles.card} />)
      }
    }

    return props.items.map((stream: Stream) => (
      <StreamCard
        stream={stream}
        key={stream.id}
        onClick={props.onStreamClick}
        className={styles.card}
        persistentFeedsCount
      />
    )).concat(loadingCards).concat(emptyCards)
  }, [props.loading, props.items, props.onStreamClick])

  return (
    <section
      className={`${styles.container} ${isEmpty ? styles.empty : ''}`}
      data-testid="search-result-section"
    >
      <header className={styles.header}>
        <h2 className="text-ellipsis">
          <FormattedMessage id={`search.navigation.${SEARCH_FILTER_KEY_STREAMS}`} />
        </h2>
        {items.length !== 0 && (
          <NavLink to={`/content/search/sources/streams?q=${props.activeQuery}`}>
            <FormattedMessage id="general.carousel.nav.see-all" />
          </NavLink>
        )}
        <p className={styles.hint}>
          <FormattedMessage id="search.sections.streams.no-results-hint" />
        </p>
      </header>
      <CardList
        containerClassName={`${styles.carousel} ${styles.streams}`}
        itemWidth={ITEM_WIDTH_SMALL}
        listHeight={CARD_LIST_HEIGHT_SMALL}
        disabled={props.items.length === 0}
        onScrollLimit={loadNextPage}
      >
        {cards}
      </CardList>
      {props.error && (
        <ErrorOverlay
          error={props.error}
          subtitle=""
          originalError={props.error}
          className={`${styles['error-box']} ${styles.shift}`}
          small
        />
      )}
    </section>
  )
}

export default SearchResultsStreamCarousel
