import * as React from 'react'
import { useState, useEffect, useMemo } from 'react'
import classnames from 'classnames'

// Components
import LinearProgress from '@mui/material/LinearProgress'

// Utils
import { noop } from 'utils/noop'

// Styles
import styles from './ProgressMedia.pcss'

export interface ProgressMediaProps {
  active?: boolean
  height?: number
  image: string | null
  useXhr?: boolean
  className?: string
  loader?: React.ReactElement

  onDone?(width: number, height: number): void
  onError?(): void
}

interface ProgressMediaState {
  loading: boolean
  progress: number
}

function loadXhr(xhr: XMLHttpRequest, image: string) {
  xhr.open('GET', image, true)
  xhr.overrideMimeType('text/plain; charset=x-user-defined')
  xhr.send(null)
}

function loadImage(image: HTMLImageElement, src: string) {
  image.src = src
}

function ProgressMedia({
  active,
  height,
  className,
  loader,
  image,
  useXhr,
  onError,
  onDone
}: ProgressMediaProps) {
  const [state, setState] = useState<ProgressMediaState>({
    loading: true,
    progress: 0
  })
  const request = useMemo(() => useXhr ? new XMLHttpRequest() : new Image(), [useXhr])
  const style = height
    ? { height }
    : undefined
  const progressbarProps = {
    variant: active ? undefined : 'determinate' as 'determinate',
    value: active ? undefined : state.progress
  }

  useEffect(() => {
    function onMediaReady() {
      setState(s => ({ ...s, loading: false }))

      if (useXhr) {
        onDone?.(0, 0)
        return
      }

      const image = request as HTMLImageElement
      onDone?.(image.naturalWidth, image.naturalHeight)
    }

    function onMediaProgress(progressEvent: ProgressEvent) {
      if (!progressEvent.lengthComputable) {
        return
      }

      const { loaded, total } = progressEvent
      const progress = total > 0
        ? loaded * 1.0 / total
        : 0

      setState(s => ({ ...s, progress }))
    }

    function onMediaError() {
      setState(s => ({ ...s, loading: false }))
      onError?.()
    }

    request.onload = onMediaReady
    request.onerror = onMediaError
    request.onprogress = onMediaProgress

    if (!image) {
      onError?.()
      return
    }

    if (useXhr) {
      loadXhr(request as XMLHttpRequest, image)
    } else {
      loadImage(request as HTMLImageElement, image)
    }

    setState(s => ({ ...s, loading: true }))

    return () => {
      if (useXhr) {
        (request as XMLHttpRequest).abort()
      }

      request.onload = noop
      request.onerror = noop
      request.onprogress = noop
    }
  }, [image, useXhr, onError, onDone, request])

  return (
    <div key="content" className={classnames(styles.content, className || '', style)}>
      {loader || (
        <React.Fragment>
          <div key="profile" className={styles.profile}></div>

          <div key="progress" className={styles.progress}>
            <LinearProgress key="top" className={styles['progress-linear']} {...progressbarProps} />

            <LinearProgress key="bottom" className={styles['progress-linear']} {...progressbarProps} />
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default ProgressMedia
