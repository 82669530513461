export type WindowWithIntercom = Window & {
  Intercom(func: 'boot', settings: Partial<IntercomSettings> & { app_id: string }): void
  Intercom(func: 'update', settings?: Partial<IntercomSettings>): void
  Intercom(func: 'shutdown' | 'reattach_activator'): void
}

declare const __DEV__: boolean
declare const window: WindowWithIntercom

export interface IntercomSettings extends IntercomCustomAttributes {
  app_id: string
  email: string
  created_at: number,
  name: string,
  user_id: string
}

interface IntercomCustomAttributes {
  [key: string]: string | number | boolean
}

/**
 * Identifies the user to intercom
 *
 * @export
 * @param {string} identifier unique identifier
 * @returns {IntercomBootAction}
 */
export function boot(settings: Partial<IntercomSettings> & { app_id: string }) {
  try {
    if (__DEV__) {
      console.log(`[intercom] boot`, settings)
      return
    }
    window.Intercom('boot', settings)
  } catch (err) {
    console.error('[intercom]', err)
  }
}

/**
 * Set properties on the people's profile
 *
 * @export
 * @param {IntercomSettings} settings the properties to set
 * @param {CompatibleAction} [action] optional action to attach this to
 * @returns {IntercomUpdateAction}
 */
export function update(settings: Partial<IntercomSettings> | undefined) {
  try {
    if (__DEV__) {
      console.log(`[intercom] update`, settings)
      return
    }
    window.Intercom('update', settings)
  } catch (err) {
    console.error('[intercom]', err)
  }
}

export function shutdown() {
  try {
    if (__DEV__) {
      console.log('[intercom] shutdown')
      return
    }
    window.Intercom('shutdown')
  } catch (err) {
    console.error('[intercom]', err)
  }
}
