/* eslint-disable max-len */
import * as React from 'react'

function ErrorLogo({ className }: { className?: string }) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 600.1 720"
      style={{ enableBackground: 'new 0 0 600.1 720' } as any}
      className={className}
    >
      <g>
        <path
          d="M335.4,257.2c-19.5-19.5-51.1-19.5-70.6,0c-19.5,19.5-19.5,51.1,0,70.6c19.5,19.5,51.1,19.5,70.6,0
    C354.9,308.3,354.9,276.7,335.4,257.2z M493.5,486c106.8-106.9,106.9-280.1,0-387C386.7-7.9,213.4-7.9,106.5,99
    c-106.9,106.9-106.9,280.1,0,387c2.6,2.6,212.8,211,212.8,211c5.5,5.5,14.5,5.5,20,0l89.4-89.4c5.6-5.6,5.5-14.6,0-20.1
    c0,0-210.5-207.8-213.1-210.5c-46.7-46.6-46.7-122.3,0-168.9c46.6-46.7,122.3-46.6,168.9,0c46.7,46.7,46.7,122.3,0,168.9
    c-21.3,21.3-48.4,31.5-76.4,34.7l116.2,116.2c3.3,3.3,8.4,4,12.5,1.7C457.1,517.8,476.2,503.4,493.5,486z"
        />
      </g>
    </svg>
  )
}

export default ErrorLogo
