import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/catch'
import { ApiException, NotAuthorizedException } from './exceptions'
import { getState } from 'store/state'

type O<T> = Observable<T>

/**
 * Enables reauthorization on the requests in request$
 *
 * It's important that the request$' items will re-sign if they were retried. This means that any branching cush as flatmap and others
 * have to happen either after this call or before the signing.
 *
 * @export
 * @template T
 * @param {Observable<T>} request$
 * @param {StoreDispatch} dispatch
 * @param {getState} getState
 * @param {() => Observable<T>} onRetry
 * @returns
 */
export function authorizedAjax<T>(request$: O<T>, _getState: getState, onRetry: () => O<T>, silent = false) {
  return request$
    .catch((error: Error | ApiException) => {
      console.error('[authorizedAjax] there was an error', error)
      if (error instanceof NotAuthorizedException) {
        if (!silent) {
          console.log('[authorizedAjax] changing signer', error.request)
        }

        return onRetry()
      }

      return Observable.throw(error)
    })
}

export default authorizedAjax
