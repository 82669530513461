import * as React from 'react'
import { getImageDimensionsFromUrl } from 'utils/file/helpers'
import { bytesToSize } from 'utils/format/byteToSize'

import styles from './FileInfo.pcss'

interface FileInfoProps {
  file?: File
  fileUrl?: string
  hideSize?: boolean
  hideDimensions?: boolean
  className?: string
  onMetadataLoaded?: (orientation: 'landscape' | 'portrait') => void
}

export function FileInfo(props: FileInfoProps) {
  const { onMetadataLoaded } = props
  const [fileSize, setFileSize] = React.useState<number | null>(null)
  const [mediaWidth, setMediaWidth] = React.useState<number | null>(null)
  const [mediaHeight, setMediaHeight] = React.useState<number | null>(null)

  React.useEffect(() => {
    if (props.file) {
      setFileSize(props.file.size)

      const reader = new FileReader()
      reader.onload = () => {
        const img = new Image()
        img.onload = () => {
          setMediaWidth(img.naturalWidth)
          setMediaHeight(img.naturalHeight)
          const orientation = img.naturalWidth > img.naturalHeight ? 'landscape' : 'portrait'
          if (onMetadataLoaded) {
            onMetadataLoaded(orientation)
          }
        }
        img.onerror = (e: any) => {
          console.log('[FileInfo] error loading image: ', e)
        }
        img.src = reader.result as string
      }
      reader.onerror = (e: any) => {
        console.log('[FileInfo] error reading file: ', e)
      }
      reader.readAsDataURL(props.file)
    }
  }, [props.file, onMetadataLoaded])

  React.useEffect(() => {
    if (props.fileUrl) {
      getImageDimensionsFromUrl(props.fileUrl).then(dimensions => {
        setMediaWidth(dimensions.width)
        setMediaHeight(dimensions.height)
        if (onMetadataLoaded) {
          const orientation = dimensions.width > dimensions.height ? 'landscape' : 'portrait'
          onMetadataLoaded(orientation)
        }
      })

      fetch(props.fileUrl, { method: 'head' }).then(res => {
        const size = res.headers.get('content-length')
        if (size) {
          setFileSize(parseInt(size, 10)) // eslint-disable-line no-magic-numbers
        }
      })
    }
  }, [props.fileUrl, onMetadataLoaded])

  return (
    <div className={`${styles['info-box']} ${props.className || ''}`}>
      {!props.hideSize && fileSize && (
        <span className={styles.bold}>{bytesToSize(fileSize)}</span>
      )}
      {!props.hideDimensions && mediaWidth && mediaHeight && (
        <span>{`(${mediaWidth}x${mediaHeight})`}</span>
      )}
    </div>
  )
}
