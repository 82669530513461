import { createAsyncThunk } from '@reduxjs/toolkit'
import { Feed, Stream } from 'interfaces'
import { api } from './api'
import { HS_EVENT_SOURCE_SAVED, trackHubspotEvent } from 'services/tracking/hubspot'
import { ContentFeedType } from 'shared'
import { TYPE_FEED_TYPE_MAP, feedAdapter } from '../adapters'
import { getStreams } from '../streams/actions'
import { feedsSelector } from '../selectors'
import StoreState from 'store/state'

export const addFeeds = createAsyncThunk(
  'content/feeds/addFeeds',
  async (args: { feeds: Feed[], stream: Stream }, { dispatch, rejectWithValue }) => {
    const { feeds, stream } = args
    try {
      const response = await dispatch(api.copyFeeds(feeds, stream.id)).toPromise()

      feeds.forEach(feed => {
        dispatch(trackHubspotEvent(HS_EVENT_SOURCE_SAVED, { type: feed.type }))
      })
      return { streamId: stream.id, feeds: response }
    } catch (_e) {
      return rejectWithValue({ streamId: stream.id, feeds })
    }
  }
)

export const getFeed = createAsyncThunk(
  'content/feeds/getFeed',
  async (args: { feedId: string, force?: boolean }, { dispatch, getState, rejectWithValue }) => {
    const { feedId, force } = args
    if (!force) {
      const storedFeeds = feedsSelector(getState() as StoreState)
      const feed = storedFeeds[feedId]
      if (feed) {
        return feed
      }
    }
    try {
      const response = await dispatch(api.getFeed(feedId)).toPromise()
      return feedAdapter(response.feeds[0])
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getOriginalFeed = createAsyncThunk(
  'content/feeds/getOriginalFeed',
  async (feedId: string, { dispatch, rejectWithValue }) => {
    try {
      const response = await dispatch(api.getOriginalFeed(feedId)).toPromise()
      return feedAdapter(response)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const createFeed = createAsyncThunk(
  'content/feeds/createFeed',
  async (
    args: { handle: string, type: ContentFeedType, image?: string, name?: string, networkId?: string },
    { dispatch, rejectWithValue }
  ) => {
    const { handle, type, image, name, networkId } = args
    try {
      const response: any = await dispatch(api.createFeed(handle, type, image, name, networkId)).toPromise()
      if (response.error) {
        return rejectWithValue(response.error)
      }
      const data = response[0] || response
      const feed: Feed = {
        ...data,
        id: data.originalSource,
        image,
        networkId,
        type: TYPE_FEED_TYPE_MAP[data.type],
        isFeed: true,
        uniqueSource: data.originalSource
      }
      return feed
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const deleteFeed = createAsyncThunk(
  'content/feeds/deleteFeed',
  async (args: { feedId: string, refreshStream?: boolean }, { dispatch, rejectWithValue }) => {
    const { feedId, refreshStream } = args
    try {
      await dispatch(api.deleteFeed(feedId)).toPromise()
        .then(() => {
          if (refreshStream) {
            dispatch(getStreams(true))
          }
        })
      return feedId
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
