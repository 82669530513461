import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'

export const ENDPOINT_LOGIN = `${LEGACY_URL}/login/email`
export const ENDPOINT_UPDATE_PASSWORD = `${LEGACY_URL}/settings/profile/update_password`
export const ENDPOINT_REQUEST_PASSWORD = `${LEGACY_URL}/settings/profile/forgotten_password`

export const V1 = Object.freeze({
  login: function () {
    const builder = new RequestBuilder(ENDPOINT_LOGIN).asPost().expectJSON().withoutAuthorization()
    return function (email: string, password: string, params?: { [key: string]: string }) {
      const data = new FormData()
      data.append('email', email)
      data.append('password', password)
      if (params) {
        Object.keys(params).forEach(key => {
          data.append(key, params[key])
        })
      }
      return authorizedPipe<any>(builder.form(data).build())
    }
  }(),
  updatePassword: function () {
    const builder = new RequestBuilder(ENDPOINT_UPDATE_PASSWORD).asPost().expectJSON()

    return function (newPassword: string, oldPassword: string) {
      const data = { password_new: newPassword, password_old: oldPassword }
      return authorizedPipe<any>(
        builder.bodyJSONstringify(data).build()
      )
    }
  }(),
  requestPassword: function () {
    const builder = new RequestBuilder(ENDPOINT_REQUEST_PASSWORD).asPost().expectJSON().asFormUrlEncoded()
      .withoutAuthorization()

    return function (email: string) {
      return authorizedPipe<any>(
        builder.body({ email }).build()
      )
    }
  }()
})
