import * as React from 'react'
import styles from './SavedTexts.pcss'
import IconCopy from '@mui/icons-material/FileCopyOutlined'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Close'
import MoveToTopIcon from '@mui/icons-material/ArrowUpward'
import SaveIcon from '@mui/icons-material/Check'
import { message } from 'services/snackbar'
import { WithIntl } from 'interfaces'
import Tooltip from '@mui/material/Tooltip'
import UndoIcon from '@mui/icons-material/Undo'

const Clipboard = require('clipboard')

export const SavedTextListItem = injectIntl((props: {
  data: { id: string, text: string, title?: string },
  isInEditMode: boolean,
  small?: boolean,
  onStartEdit: (id: string) => void,
  onStopEdit: (id: string) => void,
  onUpdate: (id: string, text: string, title: string) => void,
  onDelete: (id: string) => void,
  onMoveToTop?: (id: string) => void
} & WithIntl) => {
  const dispatch = useDispatch()
  const resetValue = React.useRef('')
  const textElementRef = React.useRef<HTMLDivElement>(null)
  const titleElementRef = React.useRef<HTMLDivElement>(null)
  const clipboard = React.useRef(null)

  React.useEffect(() => {
    if (textElementRef.current) {
      textElementRef.current.innerText = props.data.text
    }
    if (titleElementRef.current) {
      titleElementRef.current.innerText = props.data.title || ''
    }
  }, [props.data.text, props.data.title])

  React.useEffect(() => {
    if (props.isInEditMode && textElementRef.current) {
      const range = new Range()
      range.selectNodeContents(textElementRef.current)
      range.collapse()
      document.getSelection()?.removeAllRanges()
      document.getSelection()?.addRange(range)
    }
  }, [props.isInEditMode])

  const setCopyRef = (element: HTMLElement | null) => {
    if (element) {
      if (clipboard.current) {
        (clipboard.current as any).destroy()
      }

      clipboard.current = new Clipboard(element, {
        text: () => textElementRef.current?.innerText || '',
        container: element
      })

      ; (clipboard.current as any).on('success', () => {
        dispatch(message(props.intl.formatMessage({ id: 'notifications.status-copied' })))
      })
    }
  }

  const onStartEdit = () => {
    props.onStartEdit(props.data.id)
    resetValue.current = textElementRef.current?.innerText || ''
  }

  const onDelete = () => {
    props.onDelete(props.data.id)
  }

  const onUpdate = () => {
    const text = textElementRef.current?.innerText.trim()
    const title = titleElementRef.current?.innerText.trim() || ''
    if (text) {
      props.onUpdate(props.data.id, text, title)
    }
  }

  const undo = () => {
    props.onStopEdit(props.data.id)
    if (textElementRef.current) {
      textElementRef.current.innerText = resetValue.current
    }
  }

  const onPaste = (event: React.ClipboardEvent) => {
    event.preventDefault()
    // Paste text only, without formatting
    document.execCommand('inserttext', false, event.clipboardData.getData('text/plain'))
  }

  const moveToTop = () => {
    if (props.onMoveToTop) {
      props.onMoveToTop(props.data.id)
    }
  }

  const hideTitle = !props.isInEditMode && !props.data.title

  return (
    <li className={styles.li} data-testid="saved-text">
      <div className={styles['text-wrapper']}>
        <div className={styles['btn-box']}>
          <Tooltip title={<FormattedMessage id="actions.copy" />} placement="top">
            <div className={styles['btn-copy']} data-testid="btn-copy" ref={setCopyRef}><IconCopy /></div>
          </Tooltip>
        </div>
        <div className={styles['text-content']}>
          <div
            className={`${styles.editor} ${styles['text-title']} ${hideTitle ? styles['text-title-hidden'] : ''}`
              + `${props.small ? styles.small : ''} ${props.isInEditMode ? styles.editing : ''}`}
            ref={titleElementRef}
            contentEditable={props.isInEditMode}
            data-testid="saved-text-title"
            onPaste={onPaste}
          >
          </div>
          <div
            className={`${styles.editor} ${props.small ? styles.small : ''} ${props.isInEditMode ? styles.editing : ''}`}
            ref={textElementRef}
            contentEditable={props.isInEditMode}
            data-testid="saved-text-content"
            onPaste={onPaste}
          >
          </div>
        </div>
      </div>
      <div className={`${styles['editor-actions']} ${props.isInEditMode ? styles.visible : ''}`} data-testid="saved-text-actions">
        {props.isInEditMode && (
          <React.Fragment>
            <IconButton size="small" className={styles['btn-save']} data-btn-update onClick={onUpdate}>
              <SaveIcon />
            </IconButton>
            <IconButton size="small" data-btn-undo onClick={undo}>
              <UndoIcon />
            </IconButton>
          </React.Fragment>
        )}
        {!props.isInEditMode && (
          <React.Fragment>
            <IconButton size="small" data-btn-edit onClick={onStartEdit}>
              <EditIcon />
            </IconButton>
            {props.onMoveToTop && (
              <IconButton size="small" onClick={moveToTop}>
                <MoveToTopIcon />
              </IconButton>
            )}
            <IconButton size="small" className={styles['btn-delete']} data-btn-delete onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </React.Fragment>
        )}
      </div>
    </li>
  )
})
