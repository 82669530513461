import StoreState, { StoreThunkDispatch } from 'store/state'
import { sortByKeyAscending } from 'utils/sort/order'
import { savedStatusesSelector } from '../selectors'
import { V1 } from './net'
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import ComposerState from '../state'

export const getSavedStatuses = createAsyncThunk(
  'composer/getSavedStatuses',
  async (force: boolean = false, { dispatch, getState, rejectWithValue }) => {
    if (!force) {
      const cached = savedStatusesSelector(getState() as StoreState)
      if (cached.length > 0) {
        return cached
      }
    }
    try {
      const response = await dispatch(V1.getSavedStatuses()).toPromise()
      return response.sort(sortByKeyAscending('order'))
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const deleteSavedStatus = createAsyncThunk(
  'composer/deleteSavedStatus',
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(V1.deleteSavedStatus(id)).toPromise()
      return id
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export function saveStatusText(text: string, title: string) {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.saveStatus(text, title))
}

export function updateSavedText(id: string, text: string, title: string) {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.updateSavedText(id, text, title))
}

export function reorderSavedTexts(ids: string[]) {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.reorderSavedTexts(ids))
}

export const savedTextsExtraReducers = (builder: ActionReducerMapBuilder<ComposerState>) => {
  builder
    .addCase(getSavedStatuses.fulfilled, (state, action) => {
      state.savedStatuses = action.payload
    })
    .addCase(deleteSavedStatus.fulfilled, (state, action) => {
      const index = state.savedStatuses.findIndex(s => s.id === action.payload)
      state.savedStatuses.splice(index, 1)
    })
}
