import { ContentType, RangeFilter } from 'interfaces'
import { StockContentProvider } from 'interfaces/Content/StockContentProvider'
import { SortBy } from '../util'

export const PAGE_SIZE_DEFAULT = 20

export function getCacheKey(
  isStream: boolean,
  id: string,
  type: ContentType,
  range: RangeFilter,
  page: number,
  sortBy: SortBy,
  stockSource?: StockContentProvider
) {
  return `${isStream ? 'stream' : 'feed'}:${id}:${type}:${range}:${page}:${sortBy}:${stockSource || ''}`
}
