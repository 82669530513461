import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'
import { ContentType } from 'interfaces'
import ComposerState from 'services/compose/state'
import { PostDraftData } from 'shared/types'
const ENDPOINT_GET_DRAFTS = `${LEGACY_URL}/v3/post_drafts/get`
const ENDPOINT_CREATE_UPDATE_DRAFT = `${LEGACY_URL}/v3/post_drafts/save`
const ENDPOINT_DELETE_DRAFTS = `${LEGACY_URL}/v3/post_drafts/delete`
const PAGE_SIZE_DEFAULT = 50

export const V1 = Object.freeze({
  getDrafts: function () {
    return function (ppids: string[], page: number, pageSize?: number, query?: string, types?: ContentType[], searchInSummary?: boolean) {
      const endpoint = ENDPOINT_GET_DRAFTS
      const builder = new RequestBuilder(endpoint).asPost().expectJSON()
      const data: any = {
        page,
        pp_page_ids: ppids,
        page_size: pageSize || PAGE_SIZE_DEFAULT
      }
      if (query) {
        data.search = query
      }
      if (types) {
        data.post_types = types.join(',')
      }
      if (searchInSummary) {
        data.search_in_summary = true
      }

      return authorizedPipe<{ drafts: any[], totalCount: number }>(
        builder
          .bodyJSONstringify(data)
          .build()
      )
    }
  }(),
  createDraft: function () {
    const builder = new RequestBuilder(ENDPOINT_CREATE_UPDATE_DRAFT).asPost().expectJSON()

    return function (data: Omit<PostDraftData<ComposerState>, 'id' | 'updatedAt'>) {
      return authorizedPipe<any>(
        builder
          .bodyJSONstringify({ ...data, post_type: data.postType })
          .build()
      )
    }
  }(),
  updateDraft: function () {
    const builder = new RequestBuilder(ENDPOINT_CREATE_UPDATE_DRAFT).asPost().expectJSON()

    return function (id: string, data: Omit<PostDraftData<ComposerState>, 'id' | 'updatedAt'>) {
      return authorizedPipe<any>(
        builder
          .bodyJSONstringify({ ...data, draft_id: id, post_type: data.postType })
          .build()
      )
    }
  }(),
  deleteDrafts: function () {
    const builder = new RequestBuilder(ENDPOINT_DELETE_DRAFTS).asPost().expectJSON()

    return function (ids: string[], all?: boolean) {
      return authorizedPipe<any>(
        builder
          .bodyJSONstringify({ draft_ids: all ? 'all' : ids })
          .build()
      )
    }
  }()
})
