import React from 'react'
import {
  Article,
  Video,
  ContentItem,
  ARTICLE_TYPE,
  FEED_TYPE_KEYWORD,
  VIDEO_TYPE
} from 'interfaces'
import StatsBar from './StatsBar'
import SharesPerNetwork from './SharesPerNetwork'
import parseUrlDomain from 'utils/format/parseUrlDomain'
import styles from './ContentCard.pcss'
import numberToHuman from 'utils/format/numberToHuman'
import Rating from '@mui/material/Rating'
import StarIcon from '@mui/icons-material/Star'

export interface ContentCardFooterProps {
  content: ContentItem
}

export function ContentCardFooter({ content }: ContentCardFooterProps) {
  const type = content.type
  const feedType = content.feed.type
  const link = content.socialLink
  const title = (content as Article).title || (content as Video).description
  const isArticle = type === ARTICLE_TYPE
  const isVideo = type === VIDEO_TYPE
  const showStats = !isArticle && ['facebook', 'twitter', 'reddit'].includes(feedType)
  const showStarRating = !content.isStock
  const withSharesByNetwork = isArticle && Object.keys((content as Article).sharesPerNetwork).length > 0

  return (
    <div className={`${styles.footer} ${isVideo ? styles.video : ''}`}>
      {showStats && (
        <div className={styles['network-bar']}>
          <div className={styles['network-bar-stats']}>
            <StatsBar
              type={feedType}
              likes={content.likes}
              comments={content.comments}
              shares={content.shares}
            />
          </div>
        </div>
      )}
      {isArticle && (
        <p className={`${styles['source-link']} text-ellipsis`}>
          <a
            href={(content as Article).sourceLink}
            target="_blank"
            rel="noopener"
          >
            {parseUrlDomain((content as Article).sourceLink)}
          </a>
        </p>
      )}

      {(isArticle || isVideo) && title && (
        <h5 className={`${styles.title} ${withSharesByNetwork ? styles['w-stats'] : ''}`}>
          <a href={link} target="_blank" rel="noopener" className={styles['title-link']}>
            {title}
          </a>
        </h5>
      )}

      <div className={styles['footer-actions']} data-card-actions>
        {(isArticle || showStarRating) && (
          <div className={styles['shares-and-stars']}>
            {showStarRating && (
              <div className={styles['star-rating']}>
                <Rating
                  key="rating"
                  classes={{ icon: styles['rating-icon'] }}
                  readOnly
                  value={content.rating}
                  size="small"
                  emptyIcon={(
                    <StarIcon fontSize="inherit" />
                  )}
                />
              </div>
            )}

            {isArticle && (
              <span className={styles['total-shares']}>
                SHARES: {numberToHuman(content.shares)}
              </span>
            )}
            {withSharesByNetwork && (
              <div className={styles['shares-per-network']}>
                <SharesPerNetwork sharesPerNetwork={(content as Article).sharesPerNetwork} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ContentCardFooter
