import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { fontSizesFor } from 'utils/fontSize'
import styles from './CardStatusText.pcss'

const MAX_LENGTH_DEFAULT = 300
const MAX_LENGTH_SMALL = 200

export default function CardStatusText(props: { text: string, trim?: number, small?: boolean }) {
  const [expanded, setExpanded] = useState(false)
  const expand = () => setExpanded(true)

  let trim = props.trim || MAX_LENGTH_DEFAULT
  if (!props.trim && props.small) {
    trim = MAX_LENGTH_SMALL
  }
  const visibleText = props.text.length <= trim || expanded
    ? props.text
    : (
      <div>
        {`${props.text.substring(0, trim)}...`}
        <b onClick={expand}><FormattedMessage id="actions.see-all" /></b>
      </div>
    )

  const style = fontSizesFor(props.text).normal

  return (
    <div className={`${styles.container} ${expanded ? styles.block : ''}`} style={style}>
      {visibleText}
    </div>
  )
}
