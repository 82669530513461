import React from 'react'
import styles from './BucketsHome.pcss'
import { FetchBuckets } from 'components/Fetch/FetchBuckets'
import { useDispatch, useSelector } from 'react-redux'
import { bucketsSelector } from 'services/post/selectors'
import { BucketCard } from 'components/Buckets'
import { Bucket } from 'shared/types'
import { useNavigate } from 'react-router-dom'
import { StoreThunkDispatch } from 'store/state'
import { message } from 'services/snackbar'
import { getDefaultBuckets } from 'services/post/actions'
import { useIntl } from 'react-intl'
import { mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'
import { arrayToRecord } from 'shared'

interface BucketsHomeProps {
  onRequestCreateBucket: ({ name, description, color }: { name: string, description: string, color: string }) => void
}

export function BucketsHome(props: BucketsHomeProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const intl = useIntl()
  const navigate = useNavigate()
  const buckets = useSelector(bucketsSelector)
  const bucketNamesMap = React.useMemo(() => {
    return arrayToRecord(buckets, 'name')
  }, [buckets])
  const [defaultBuckets, setDefaultBuckets] = React.useState<Array<Bucket>>([])

  React.useEffect(() => {
    dispatch(getDefaultBuckets())
      .unwrap()
      .then(response => {
        setDefaultBuckets(response)
      })
      .catch(() => {
        dispatch(message(intl.formatMessage({ id: 'errors.generic-support' })))
      })
  }, [dispatch, intl])

  const goToPosts = (bucketId: string) => {
    navigate(`/posts/buckets/${bucketId}/posts`)
  }

  const visibleDefaultBuckets = defaultBuckets.filter(b => !bucketNamesMap[b.name])

  return (
    <div>
      {buckets.length > 0 && (
        <section>
          <h2 className={styles.title}>Your buckets</h2>
          <div className={styles.grid}>
            {buckets.map(b => (
              <BucketCard bucket={b} key={b.id} selected className={styles.bucket} onClick={goToPosts} />
            ))}
          </div>
        </section>
      )}
      {visibleDefaultBuckets.length > 0 && (
        <section>
          <h2 className={styles.title}>Suggested buckets</h2>
          <div className={styles.grid}>
            {visibleDefaultBuckets.map((b, index) => (
              <DefaultBucket bucket={b} key={b.name + index} onClick={props.onRequestCreateBucket} />
            ))}
          </div>
        </section>
      )}
      <FetchBuckets />
    </div>
  )
}

const DefaultBucket = ({ bucket, onClick }: { bucket: Bucket, onClick: (b: Bucket & { description: string }) => void }) => {
  const handleClick = () => {
    onClick({ ...bucket, description: bucket.description || '' })
  }

  return (
    <BucketCard
      bucket={bucket}
      actionIcon={<Icon path={mdiPlus} size="20px" className={styles.check} />}
      className={styles.bucket}
      actionIconBoxClassName={styles['icon-add']}
      onClick={handleClick}
    />
  )
}
