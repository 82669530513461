import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'
import { LinkDetails } from 'interfaces'

export const ENDPOINT_GET_LINK_DETAILS = `${LEGACY_URL}/form_post/get_link_details`

export const V1 = Object.freeze({
  getLinkDetails: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_LINK_DETAILS).asPost().expectJSON()

    return function (url: string) {
      return authorizedPipe<LinkDetails>(
        builder
          .body({ url })
          .asFormUrlEncoded()
          .build()
      )
    }
  }()
})
