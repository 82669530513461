import { STATUS_TYPE, Feed, FEED_TYPE_STATUS_IDEAS, CuratedFolder } from 'interfaces'

const QUESTIONS: CuratedFolder = {
  id: '1',
  title: 'questions',
  slug: 'questions',
  tracking: 'Questions',
  streams: [
    { types: [STATUS_TYPE], id: 7 }
  ],
  defaultFilter: STATUS_TYPE,
  public: true,
  isStatusIdeasStream: true,
  image: '/static/img/feeds/statuses/bg-questions.jpg',
  count: 2074
}

const ASK_FOR_RESPONSE: CuratedFolder = {
  id: '2',
  title: 'call to action',
  slug: 'call-to-action',
  tracking: 'call to action',
  streams: [
    { types: [STATUS_TYPE], id: 16 }
  ],
  defaultFilter: STATUS_TYPE,
  public: true,
  isStatusIdeasStream: true,
  image: '/static/img/feeds/statuses/bg-likes.jpg',
  count: 342
}

const FILL_IN_THE_BLANK: CuratedFolder = {
  id: '3',
  title: 'fill in the blank',
  slug: 'fill-in-the-blank',
  tracking: 'Fill In Blank',
  streams: [
    { types: [STATUS_TYPE], id: 17 }
  ],
  defaultFilter: STATUS_TYPE,
  public: true,
  isStatusIdeasStream: true,
  image: '/static/img/feeds/statuses/bg-fill-in.jpg',
  count: 773
}

const CONTESTS: CuratedFolder = {
  id: '4',
  title: 'contests',
  slug: 'contests',
  tracking: 'contests',
  streams: [
    { types: [STATUS_TYPE], id: 18 }
  ],
  defaultFilter: STATUS_TYPE,
  public: true,
  isStatusIdeasStream: true,
  image: '/static/img/feeds/statuses/bg-contest.jpg',
  count: 130
}

const INSERT_YOUR_BRAND: CuratedFolder = {
  id: '5',
  title: 'insert brand',
  slug: 'insert-brand',
  tracking: 'insert brand',
  streams: [
    { types: [STATUS_TYPE], id: 1 }
  ],
  defaultFilter: STATUS_TYPE,
  public: true,
  isStatusIdeasStream: true,
  image: '/static/img/feeds/statuses/bg-brand.jpg',
  count: 251
}

const FAN_LOVE: CuratedFolder = {
  id: '6',
  title: 'follower love',
  slug: 'follower-love',
  tracking: 'Fan Love',
  streams: [
    { types: [STATUS_TYPE], id: 3 }
  ],
  defaultFilter: STATUS_TYPE,
  public: true,
  isStatusIdeasStream: true,
  image: '/static/img/feeds/statuses/bg-fan-love.jpg',
  count: 94
}

const PROMO: CuratedFolder = {
  id: '7',
  title: 'promotional',
  slug: 'promotional',
  tracking: 'promotional',
  streams: [
    { types: [STATUS_TYPE], id: 6 }
  ],
  defaultFilter: STATUS_TYPE,
  public: true,
  isStatusIdeasStream: true,
  image: '/static/img/feeds/statuses/bg-promo.jpg',
  count: 51
}

const TRIVIA: CuratedFolder = {
  id: '8',
  title: 'trivia',
  slug: 'trivia',
  tracking: 'trivia',
  streams: [
    { types: [STATUS_TYPE], id: 19 }
  ],
  defaultFilter: STATUS_TYPE,
  public: true,
  isStatusIdeasStream: true,
  image: '/static/img/feeds/statuses/bg-trivia.jpg',
  count: 852
}

export const QUOTES: CuratedFolder = {
  id: '9',
  title: 'deep thoughts',
  slug: 'deep-thoughts',
  tracking: 'deep thoughts',
  streams: [
    { types: [STATUS_TYPE], id: 8 }
  ],
  defaultFilter: STATUS_TYPE,
  public: true,
  isStatusIdeasStream: true,
  image: '/static/img/feeds/statuses/bg-deep-thoughts.jpg',
  count: 7627
}

const WEEKENDS_AND_WORK: CuratedFolder = {
  id: '10',
  title: 'weekends | work',
  slug: 'weekends-and-work',
  tracking: 'Weekends',
  streams: [
    { types: [STATUS_TYPE], id: 4 }
  ],
  defaultFilter: STATUS_TYPE,
  public: true,
  isStatusIdeasStream: true,
  image: '/static/img/feeds/statuses/bg-weekends.jpg',
  count: 290
}

const FAMILY_AND_PETS: CuratedFolder = {
  id: '11',
  title: 'family | pets',
  slug: 'family-and-pets',
  tracking: 'Family & Pets',
  streams: [
    { types: [STATUS_TYPE], id: 12 }
  ],
  defaultFilter: STATUS_TYPE,
  public: true,
  isStatusIdeasStream: true,
  image: '/static/img/feeds/statuses/bg-family-pets.jpg',
  count: 267
}

const MUSIC: CuratedFolder = {
  id: '12',
  title: 'music | culture | food | sports',
  slug: 'music-and-culture',
  tracking: 'music | culture | food | sports',
  streams: [
    { types: [STATUS_TYPE], id: 10 }
  ],
  defaultFilter: STATUS_TYPE,
  public: true,
  isStatusIdeasStream: true,
  image: '/static/img/feeds/statuses/bg-music.jpg',
  count: 601
}

const SOCIAL_MEDIA: CuratedFolder = {
  id: '13',
  title: 'social media',
  slug: 'social-media',
  tracking: 'Social Media',
  streams: [
    { types: [STATUS_TYPE], id: 5 }
  ],
  defaultFilter: STATUS_TYPE,
  public: true,
  isStatusIdeasStream: true,
  image: '/static/img/feeds/statuses/bg-facebook.jpg',
  count: 393
}

export const STATUSES_CATEGORIES: CuratedFolder[] = [
  QUESTIONS,
  FILL_IN_THE_BLANK,
  CONTESTS,
  SOCIAL_MEDIA,
  WEEKENDS_AND_WORK,
  MUSIC,
  FAMILY_AND_PETS,
  FAN_LOVE,
  TRIVIA,
  ASK_FOR_RESPONSE,
  INSERT_YOUR_BRAND,
  PROMO
]

export default STATUSES_CATEGORIES

export const statusIdeaFeeds: Feed[] = STATUSES_CATEGORIES.map((folder: CuratedFolder) => {
  const feed: Feed = {
    id: folder.streams[0].id.toString(),
    handle: folder.slug,
    name: folder.title,
    streamId: folder.streams[0].id.toString(),
    order: 0,
    sources: [],
    type: FEED_TYPE_STATUS_IDEAS,
    isFeed: false,
    isStatusIdeasFeed: true,
    uniqueSource: folder.slug,
    image: folder.image,
    itemsCount: folder.count
  }

  return feed
})
