import loadable, { LoadableComponent } from '@loadable/component'
import { lazyLoad } from 'utils/chunk'

const AsyncFind: LoadableComponent<any> = loadable(() => lazyLoad(
  () => import(
    /* webpackPrefetch: true */
    /* webpackChunkName: 'find' */
    './Find'
  ),
  'find'
))
export default AsyncFind
