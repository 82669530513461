import * as React from 'react'
import { NavLink as BaseNavLink, NavLinkProps } from 'react-router-dom'

interface PPNavLinkProps extends NavLinkProps {
  activeClassName: string
  external?: boolean
}

export const PPNavLink = React.forwardRef((props: PPNavLinkProps, ref: React.RefObject<any>) => {
  const { activeClassName, className, external, ...linkProps } = props
  if (external) {
    const href = typeof linkProps.to === 'string' ? linkProps.to : linkProps.to.pathname
    return (
      <a
        href={href}
        rel="noopener"
        target="_blank"
        className={className as string}
      >
        {linkProps.children as any}
      </a>
    )
  }

  return (
    <BaseNavLink
      ref={ref}
      {...linkProps}
      className={({ isActive }) => `${className} ${isActive ? activeClassName : ''}`}
    />
  )
})
