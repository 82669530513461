import { PPProduct } from 'interfaces'
import toSnakeCase from 'utils/toSnakeCase'
import { FEATURE_CONTENT_AGE_ALL, FEATURE_CONTENT_AGE_MONTH, FEATURE_CONTENT_AGE_YEAR } from 'shared/constants'
import { differenceInDays } from 'date-fns'

export const getContentAgeFeature = (date: string | number | Date) => {
  const DAYS_IN_WEEK = 7
  const DAYS_IN_MONTH = 31
  const DAYS_IN_YEAR = 365
  const diffDays = Math.abs(differenceInDays(date, Date.now()))

  // NEW and WEEK are available for all, skip check
  if (diffDays <= DAYS_IN_WEEK) {
    return null
  }

  if (diffDays <= DAYS_IN_MONTH) {
    return FEATURE_CONTENT_AGE_MONTH
  }

  if (diffDays <= DAYS_IN_YEAR) {
    return FEATURE_CONTENT_AGE_YEAR
  }

  return FEATURE_CONTENT_AGE_ALL
}

export function formatProducts(products: PPProduct[]): PPProduct[] {
  return products.map(p => {
    const featuresFormatted = toSnakeCase(p.features || {})
    const limitsFormatted = toSnakeCase(p.limits || {})

    const features = Object.keys(featuresFormatted).reduce((map: Record<string, boolean>, key: string) => {
      map[key.toUpperCase()] = Boolean(featuresFormatted[key])
      return map
    }, {})

    const limits = Object.keys(limitsFormatted).reduce((map: Record<string, number>, key: string) => {
      map[key.toUpperCase()] = limitsFormatted[key]
      return map
    }, {})

    return { ...p, features, limits }
  })
}
