import { Bucket } from '../../interfaces'

export function bucketAdapter(bucket: any): Bucket {
  const now = new Date().getTime()

  return {
    id: bucket.id,
    name: bucket.name.trim(),
    color: bucket.color.split(',')[0],
    ppids: bucket.ppPageIds,
    isPublic: bucket.isPubic,
    isActive: bucket.isActive,
    order: bucket.order || 0,
    pages: bucket.pages.map((p: any) => ({
      isActive: p.isActive,
      plannedPostsCount: p.plannedPostsCount,
      ppid: p.ppPageId,
      slotsCount: p.activeSlotsCount
    })),
    postsCount: bucket.pages.reduce((count: number, page: any) => {
      count += page.plannedPostsCount
      return count
    }, 0),
    activeFrom: bucket.activeFrom,
    activeTo: bucket.activeTo,
    isExpired: bucket.activeTo ? new Date(bucket.activeTo).getTime() < now : false,
    description: bucket.description
  }
}
