import { ReactChild } from 'react'

export { ContentCard, ContentCardProps } from './ContentCard'
export { ContentCardFooter, ContentCardFooterProps } from './ContentCardFooter'
export { LoadingCard } from './LoadingCard'

export { StatusIdeaCard, StatusIdeaCardProps } from './StatusIdeaCard'
export { default, AnyCard, AnyCardProps } from './AnyCard'

export function getKey(child: ReactChild, fallback: any): string {
  if (typeof child === 'object' && child.hasOwnProperty('props')) {
    const { content, children } = child.props

    if (content && content.id.indexOf('undefined') === -1) {
      return content.id + ''
    }

    if (typeof children === 'object' && 'id' in children && children.id.indexOf('undefined') === -1) {
      return children.id + ''
    }
  }

  return fallback
}
