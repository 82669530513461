import React, { Component, Children } from 'react'
import { connect } from 'react-redux'

import { Feed } from 'interfaces'
import { getOriginalFeed } from 'services/content/feeds/actions'
import { StoreState, StoreThunkDispatch } from 'store/state'

import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/throw'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/first'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/observeOn'
import { animationFrame } from 'rxjs/scheduler/animationFrame'
import { Subscription } from 'rxjs/Subscription'

export interface FetchFeedProps {
  id: string

  onFetched(feed: Feed): void
  onFailed?(err: any): void
}

interface ConnectedFetchFeedProps extends FetchFeedProps {
  getOriginalFeed(): Observable<Feed>
}

export class FetchFeed extends Component<React.PropsWithChildren<ConnectedFetchFeedProps>, any> {

  private subscription: Subscription

  constructor(props: ConnectedFetchFeedProps) {
    super(props)

    this.onFetched = this.onFetched.bind(this)
    this.onFailed = this.onFailed.bind(this)
  }

  shouldComponentUpdate(nextProps: ConnectedFetchFeedProps) {
    return nextProps.id !== this.props.id
  }

  onFetched(feed: Feed) {
    this.props.onFetched(feed)
  }

  onFailed(err: any) {
    console.log(err)
    if (this.props.onFailed) {
      this.props.onFailed(err)
    }
  }

  fetch() {
    return this.props.getOriginalFeed()
      .mergeMap((feed) => {
        if (feed.type === 'unsupported') {
          return Observable.throw(new Error('Feed type is not supported'))
        }

        return Observable.of(feed)
      })
      .observeOn(animationFrame)
      .subscribe(this.onFetched, this.onFailed)
  }

  componentDidMount() {
    this.subscription = this.fetch()
  }

  componentDidUpdate() {
    this.subscription.unsubscribe()
    this.subscription = this.fetch()
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    return this.props.children
      ? Children.only(this.props.children)
      : null
  }
}

function mapStateToProps(_state: StoreState, ownProps: FetchFeedProps) {
  return ownProps
}

function mapDispatchToProps(dispatch: StoreThunkDispatch, ownProps: FetchFeedProps) {
  const { id } = ownProps

  return {
    getOriginalFeed: () => Observable.fromPromise(dispatch(getOriginalFeed(id)).unwrap())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FetchFeed)
