import * as React from 'react'
import { getWeekViewDays, Weekday, WEEKDAY_LABELS, WEEKDAY_MONDAY } from 'utils/calendar'
import styles from './CalendarWeekView.pcss'
import { CalendarDay, CalendarDay as ICalendarDay } from 'interfaces/Post/Calendar'
import { HOURS_IN_DAY, MS_IN_SEC, SEC_IN_MIN } from 'utils/constants'
import CalendarWeekViewHeader from '../CalendarViewHeader/CalendarWeekViewHeader'

interface CalendarWeekViewProps {
  referenceDate: Date
  expanded: boolean
  isEmpty: boolean
  startDay?: Weekday
  className?: string
  highlightCurrentDay?: boolean
  cellClassName?: string
  withTimeCells?: boolean
  withNightTimeToggle?: boolean
  stickyHeader?: boolean
  hiddenHours?: string[]
  expandBtnTooltip?: string
  collapseBtnTooltip?: string
  renderCell: (day: ICalendarDay, hour?: number) => React.ReactNode
  onCellClick?: (day: ICalendarDay, hour: number) => void
  onToggleExpanded?: () => void
  renderHeaderSubtitle?: (day: CalendarDay) => React.ReactNode
}

export function CalendarWeekView(props: CalendarWeekViewProps) {
  const { withNightTimeToggle, onToggleExpanded } = props
  const [nightTimeVisible, setNightTimeVisible] = React.useState(!withNightTimeToggle)
  const startDay = props.startDay || WEEKDAY_MONDAY
  const [now, setNow] = React.useState(new Date())

  React.useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date())
      // eslint-disable-next-line no-magic-numbers
    }, 10 * SEC_IN_MIN * MS_IN_SEC) // 10 minutes

    return () => clearInterval(interval)
  }, [])

  const visibleDays = React.useMemo(() => {
    const days = getWeekViewDays(props.referenceDate, 0, WEEKDAY_LABELS.indexOf(startDay))

    return days.map(day => {
      const isToday = day.date.getDate() === now.getDate()
        && day.date.getMonth() === now.getMonth()
        && day.date.getFullYear() === now.getFullYear()
      return { ...day, isToday }
    })
  }, [props.referenceDate, now, startDay])

  const hours: number[] = new Array(HOURS_IN_DAY).fill(null)

  const handleToggle = () => {
    if (onToggleExpanded) {
      onToggleExpanded()
    }
    if (withNightTimeToggle) {
      setNightTimeVisible(!nightTimeVisible)
    }
  }

  const showToggle = withNightTimeToggle || onToggleExpanded
  const isExpanded = props.expanded || Boolean(withNightTimeToggle && nightTimeVisible)
  const timeCellClass = props.withTimeCells ? styles['w-time-cells'] : ''
  const stickyHeaderClass = props.stickyHeader ? styles['sticky-header'] : ''

  return (
    <section
      className={`${styles['calendar-scrollbox']} ${props.className || ''} ${timeCellClass} ${stickyHeaderClass}`}
      data-testid="calendar-week-view"
    >
      <CalendarWeekViewHeader
        days={visibleDays}
        expanded={isExpanded}
        className={styles['header-week']}
        highlightCurrentDay={props.highlightCurrentDay}
        expandBtnTooltip={props.expandBtnTooltip}
        collapseBtnTooltip={props.collapseBtnTooltip}
        onToggleExpanded={showToggle ? handleToggle : undefined}
        renderHeaderSubtitle={props.renderHeaderSubtitle}
      />
      <div className={`${styles.grid} ${props.isEmpty ? styles.empty : ''}`}>
        {!props.isEmpty && hours.map((_, index) => {
          const time = index === 12 || index === 0 ? 12 : index % 12 // eslint-disable-line no-magic-numbers
          const ampm = index > 11 ? 'PM' : 'AM' // eslint-disable-line no-magic-numbers
          const hour = `${time} ${ampm}`

          const isNightTime = ampm === 'AM' && (time < 6 || time === 12) // eslint-disable-line no-magic-numbers
          if (!nightTimeVisible && isNightTime) {
            return null
          }

          let isRowEmpty = true
          if (!props.expanded) {
            for (const day of visibleDays) {
              if (props.renderCell(day, index)) {
                isRowEmpty = false
                break
              }
            }
            if (isRowEmpty) {
              return null
            }
          }

          if (!props.expanded && props.hiddenHours && props.hiddenHours.includes(hour)) {
            return null
          }

          return (
            <div className={styles.row} key={index} data-testid="week-view-row">
              <div className={styles['cell-time']}>
                <div>{hour}</div>
              </div>
              <div className={styles['row-data']}>
                {visibleDays.map((day: ICalendarDay) => (
                  // <div className={`${styles.cell} ${day.isToday ? styles.active : ''}`} key={day.dayIndex}>
                  //   {props.renderCell(day, index)}
                  // </div>
                  <WeekViewCell
                    key={day.dayIndex}
                    day={day}
                    hour={index}
                    now={{ hour: now.getHours(), minute: now.getMinutes() }}
                    highlightCurrentDay={props.highlightCurrentDay}
                    className={props.cellClassName}
                    onClick={props.onCellClick}
                    renderContent={props.renderCell}
                  />
                ))}
              </div>
            </div>
          )
        })}

        {props.isEmpty && visibleDays.map((day: ICalendarDay) => (
          <div key={day.dayIndex} className={`${styles.column} ${day.isToday ? styles.active : ''}`}>
          </div>
        ))}
      </div>
    </section>
  )
}

interface WeekViewCellProps {
  day: ICalendarDay
  hour: number
  highlightCurrentDay?: boolean
  className?: string
  now: {
    hour: number
    minute: number
  }
  renderContent: (day: ICalendarDay, hour: number) => React.ReactNode
  onClick?: (day: ICalendarDay, hour: number) => void
}

function WeekViewCell({ day, hour, highlightCurrentDay, className, now, onClick, renderContent }: WeekViewCellProps) {
  const activeClass = day.isToday && highlightCurrentDay ? styles.active : ''

  const timeIndicator = React.useMemo(() => {
    if (!day.isToday || hour !== now.hour) {
      return null
    }
    const style = {
      // eslint-disable-next-line no-magic-numbers
      top: `${(now.minute / 60) * 100}%`
    }
    return (
      <div className={styles.timeline} style={style}></div>
    )
  }, [day.isToday, hour, now.hour, now.minute])

  const handleClick = () => {
    if (onClick) {
      onClick(day, hour)
    }
  }

  return (
    <div
      className={`${styles.cell} ${activeClass} ${className || ''}`}
      onClick={handleClick}
    >
      {timeIndicator}
      {renderContent(day, hour)}
    </div>
  )
}

export default CalendarWeekView
