export const BRAND_FACEBOOK = 'facebook'
export const BRAND_TWITTER = 'twitter'
export const BRAND_INSTAGRAM = 'instagram'
export const BRAND_PINTEREST = 'pinterest'
export const BRAND_LINKEDIN = 'linkedin'
export const BRAND_GOOGLE = 'google'
export const BRAND_REDDIT = 'reddit'
export const BRAND_TIKTOK = 'tiktok'
export const BRAND_YOUTUBE = 'youtube'

export const NETWORKS_ORDER: Record<string, number> = {
  [BRAND_FACEBOOK]: 1,
  [BRAND_INSTAGRAM]: 2,
  [BRAND_GOOGLE]: 3,
  [BRAND_LINKEDIN]: 4,
  [BRAND_PINTEREST]: 5,
  [BRAND_TIKTOK]: 6,
  [BRAND_TWITTER]: 7,
  [BRAND_YOUTUBE]: 8
}

export const NETWORKS_NAMES: Record<string, string> = {
  [BRAND_FACEBOOK]: 'Facebook',
  [BRAND_INSTAGRAM]: 'Instagram',
  [BRAND_LINKEDIN]: 'LinkedIn',
  [BRAND_PINTEREST]: 'Pinterest',
  [BRAND_TWITTER]: 'X/Twitter',
  [BRAND_TIKTOK]: 'TikTok',
  [BRAND_GOOGLE]: 'Google Business',
  [BRAND_YOUTUBE]: 'YouTube'
}

export const NETWORKS_ORDERED = [
  BRAND_FACEBOOK,
  BRAND_INSTAGRAM,
  BRAND_GOOGLE,
  BRAND_LINKEDIN,
  BRAND_PINTEREST,
  BRAND_TIKTOK,
  BRAND_TWITTER,
  BRAND_YOUTUBE
]
