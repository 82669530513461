import { CreatePostRequestData } from 'shared/types'
import { EVENT_QUEUE_POSTS } from 'routes/trial/interfaces'
import { HS_EVENT_POST_CREATED, trackHubspotEvent } from 'services/tracking/hubspot'
import { trackTrainingEvent } from 'services/users/actions'
import { StoreThunkDispatch } from 'store/state'
import { createAction } from '@reduxjs/toolkit'

export const createPostRequest = createAction<{ post: CreatePostRequestData, id: string }>('composer/createPost/request')
export const createPostSuccess = createAction<{ post: CreatePostRequestData, id: string }>('composer/createPost/success')
type PostFailurePayload = { error: Error, source: { post: CreatePostRequestData, id: string } }
export const createPostFailure = createAction<PostFailurePayload>('composer/createPost/failure')

export function trackPostCreated(data: CreatePostRequestData, response: any, _bucketId?: string, withContent?: boolean) {
  return (dispatch: StoreThunkDispatch) => {
    dispatch(trackHubspotEvent(HS_EVENT_POST_CREATED, { type: 'all' }))
    if (withContent) {
      dispatch(trackHubspotEvent(HS_EVENT_POST_CREATED, { type: 'pp_content' }))
    }
    if (data.bulkUploadFileUrl) {
      dispatch(trackHubspotEvent(HS_EVENT_POST_CREATED, { type: 'bulk_content' }))
    }

    const postsCreated = response.count || response.countOk
    if (typeof postsCreated === 'number') {
      dispatch(trackTrainingEvent({ eventCode: EVENT_QUEUE_POSTS, value: postsCreated }))
    }
  }
}
