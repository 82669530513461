import React from 'react'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import TimeIcon from '@mui/icons-material/Schedule'
import { FormattedMessage, useIntl } from 'react-intl'
import Dialog from '@mui/material/Dialog'
import styles from './HistoryRangePicker.pcss'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3'
import { LocalizationProvider, DateRange } from '@mui/x-date-pickers-pro'
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker'
import { sub } from 'date-fns'

export type HistoryPostsTimeRange = 'latest' | 'week' | 'month' | 'year' | 'all' | 'custom'

interface HistoryRangePickerProps {
  value: HistoryPostsTimeRange
  className?: string
  datePickerMode?: boolean
  dialogOpen?: boolean
  onClose?: () => void
  onValueChange: (value: HistoryPostsTimeRange, startDate?: string, endDate?: string) => void
}

export function HistoryRangePicker(props: HistoryRangePickerProps) {
  const intl = useIntl()
  const today = new Date()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [range, setRange] = React.useState<DateRange<Date>>([sub(today, { months: 1 }), today])

  React.useEffect(() => {
    if (typeof props.dialogOpen !== 'undefined') {
      setDialogOpen(props.dialogOpen)
    }
  }, [props.dialogOpen])

  const options: PPSelectOptions = {
    latest: { label: intl.formatMessage({ id: 'post.posted.time-range.latest' }) },
    week: { label: intl.formatMessage({ id: 'post.posted.time-range.week' }) },
    month: { label: intl.formatMessage({ id: 'post.posted.time-range.month' }) },
    year: { label: intl.formatMessage({ id: 'post.posted.time-range.year' }) },
    all: { label: intl.formatMessage({ id: 'post.posted.time-range.all' }) },
    custom: { label: intl.formatMessage({ id: 'post.posted.time-range.custom' }) }
  }

  const onSelectionChange = (value: HistoryPostsTimeRange) => {
    if (value === 'custom') {
      setDialogOpen(true)
    } else if (value === 'all') {
      props.onValueChange(value)
    } else {
      const start = getRangeStartByKey(value)?.toISOString()
      const end = new Date().toISOString()
      props.onValueChange(value, start, end)
    }
  }

  const confirmCustomRange = () => {
    const start = range[0]?.toISOString()
    const end = range[1]?.toISOString()
    props.onValueChange('custom', start, end)
    closeDialog()
  }

  const closeDialog = () => {
    setDialogOpen(false)
    props.onClose?.()
  }

  const rangeSelected = Boolean(range[0] && range[1])

  return (
    <div className={props.className}>
      {!props.datePickerMode && (
        <PPSelect
          options={options}
          selectedValue={props.value}
          withCaret
          name={<FormattedMessage id="content.filters.label.period" />}
          icon={<TimeIcon />}
          onSelectionChange={onSelectionChange}
        />
      )}
      <Dialog className={styles.dialog} open={dialogOpen}>
        <DialogTitle className={styles['dialog-title']}>
          <FormattedMessage id="post.posted.time-range-popup.title" />
        </DialogTitle>
        <div className={styles['dialog-content']}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDateRangePicker
              autoFocus={false}
              displayStaticWrapperAs="desktop"
              value={range}
              maxDate={today}
              onChange={setRange}
              slotProps={{ fieldSeparator: { children: 'to' } } as any}
            />
          </LocalizationProvider>
        </div>
        <div className={styles.actions}>
          <Button className={styles.btn} onClick={closeDialog}>
            <FormattedMessage id="actions.cancel" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={styles.btn}
            disabled={!rangeSelected}
            onClick={confirmCustomRange}
          >
            <FormattedMessage id="actions.ok" />
          </Button>
        </div>
      </Dialog>
    </div>
  )
}

export function getRangeStartByKey(key: HistoryPostsTimeRange): Date | null {
  let start: Date | null = null
  const now = Date.now()

  /* eslint-disable no-magic-numbers */
  switch (key) {
    case 'latest':
      start = sub(now, { hours: 48 })
      break

    case 'week':
      start = sub(now, { days: 7 })
      break

    case 'month':
      start = sub(now, { days: 7 })
      break

    case 'year':
      start = sub(now, { years: 1 })
      break

    default:
      console.log('Cannot get start date from key: ', key)
  }
  /* eslint-enable no-magic-numbers */
  return start
}

export default HistoryRangePicker
