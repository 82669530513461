import Tooltip from '@mui/material/Tooltip'
import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'services/snackbar'
import styles from './TrialPopups.pcss'
import IconCopy from '@mui/icons-material/FileCopyOutlined'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { defaultProductUpgradeHandleSelector, getProductByHandle, activeProductsSelector, upgradeProduct, endTrial } from 'services/product'
import { trialPopupForceOpenWithHandleSelector } from 'services/ui/selectors'
import { setTrialPopupOpen } from 'services/ui/actions'
import { downgradeFree, getAccountInfo } from 'services/settings'
import { Subject } from 'rxjs/Subject'
import useAsyncAction from 'hooks/useAsyncAction'
import { checkTokens } from 'services/auth'
import { catchError } from 'rxjs/operators/catchError'
import { StoreThunkDispatch } from 'store/state'
import { fetchCouponCode, getTrialTasks } from 'services/users/actions'
import { Observable } from 'rxjs/Observable'
import { TrialTask } from 'routes/trial/interfaces'
import { currentUserSelector, isCCTrialSelector, isNOCCTrialSelector } from 'services/users/selectors'
import { NOTIFICATION_DURATION_LONG } from 'components/ConnectedSnackbar'
import { ProductSelect } from 'components/ProductSelect'
import { PPProduct } from 'interfaces'
import { CURRENCY_SIGN_MAP } from 'shared'

const Clipboard = require('clipboard')

export function EndTrialPopup() {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const plans = useSelector(activeProductsSelector)
  const freePlan = plans.find(p => p.order === 0) as PPProduct
  const openWithPlan = useSelector(trialPopupForceOpenWithHandleSelector)
  const user = useSelector(currentUserSelector)
  const isCCTrial = useSelector(isCCTrialSelector)
  const isNOCCTrial = useSelector(isNOCCTrialSelector)
  // NOTE: Do not show Free plan or plans above Business to CC-trial users
  // eslint-disable-next-line no-magic-numbers
  const visiblePlans = isCCTrial ? plans.filter(p => p.order > 0 && p.order < 500) : plans.filter(p => p.order < 500)
  const currency = user?.currency as string || 'usd'

  const [selectedProduct, setSelectedProduct] = React.useState({
    handle: 'prod-400-monthly',
    isAnnual: false
  })
  const [loading, setLoading] = React.useState(true)
  const [isUpgrading, setUpgrading] = React.useState(false)
  // TEMP: hardcode coupon code const [fetchCoupon, coupon] = useAsyncAction(() => Observable.fromPromise(dispatch(fetchCouponCode())))
  const coupon = { code: '10DISCOUNT101', percent: 10 }

  const checkStatus$ = React.useRef<Subject<any>>()
  const clipboard = React.useRef(null)

  const getClipboardText = () => coupon?.code || ''

  React.useEffect(() => {
    // fetchCoupon()
    checkStatus$.current = new Subject()
    checkStatus$.current
      .flatMap(() => {
        return dispatch(checkTokens())
          .pipe(catchError((error) => Observable.of(error)))
      })
      .subscribe(() => { setLoading(false) })

    checkStatus$.current.next()

    return () => {
      checkStatus$.current?.unsubscribe()
    }
  }, [dispatch])

  const fetchTasks = () => {
    dispatch(getTrialTasks())
      .unwrap()
      .catch(() => {
        dispatch(message(intl.formatMessage({ id: 'errors.generic-support' }), 'error'))
      })
  }

  React.useEffect(() => {
    if (openWithPlan.handle) {
      setSelectedProduct({ handle: openWithPlan.handle, isAnnual: Boolean(openWithPlan.isAnnual) })
      fetchTasks()
      // fetchCoupon()
    }
  }, [openWithPlan.handle, openWithPlan.isAnnual])

  const selectedPlanDiscountPrice = React.useMemo(() => {
    const product = dispatch(getProductByHandle(selectedProduct.handle))
    if (product && coupon?.percent !== undefined) {
      const price = selectedProduct.isAnnual ? product.price.annual[currency] : product.price.monthly[currency]
      // eslint-disable-next-line no-magic-numbers
      const discount = price * (coupon.percent / 100)
      return (price - discount).toFixed(2)
    }
    return '0'
  }, [dispatch, selectedProduct.handle, selectedProduct.isAnnual, coupon.percent, currency])

  const setCopyRef = (element: HTMLElement | null) => {
    if (element) {
      if (clipboard.current) {
        (clipboard.current as any).destroy()
      }

      clipboard.current = new Clipboard(element, {
        text: getClipboardText,
        container: element
      })

      ; (clipboard.current as any).on('success', () => {
        dispatch(message(intl.formatMessage({ id: 'notifications.coupon-copied' })))
      })
    }
  }

  const closePopup = () => {
    dispatch(setTrialPopupOpen({ handle: null }))
  }

  const onUpgrade = () => {
    setUpgrading(true)

    if (isNOCCTrial) {
      closePopup()
      setUpgrading(false)
      navigate('/signup/payment-details?plan=prod-400-annual')
      return
    }

    if ([freePlan.handles.monthly, freePlan.handles.annual].includes(selectedProduct.handle)) {
      dispatch(downgradeFree()).unwrap()
        .then(() => {
          checkStatus$.current?.next()
          navigate('/settings/account')
        })
        .then(() => dispatch(getAccountInfo()).unwrap())
        .catch(() => {
          dispatch(message(intl.formatMessage({ id: 'errors.generic-support' }), 'error'))
        })
        .finally(() => {
          closePopup()
          setUpgrading(false)
        })
      return
    }

    const product = dispatch(getProductByHandle(selectedProduct.handle))
    dispatch(endTrial(selectedProduct.handle))
      .flatMap(() => dispatch(upgradeProduct(selectedProduct.handle)))
      .subscribe(
        () => {
          dispatch(message(intl.formatMessage(
            { id: 'notifications.upgrade-success' },
            { subscription: product?.name.toUpperCase() }
          ), 'success'))
          checkStatus$.current?.next()
          closePopup()
          setUpgrading(false)
        },
        error => {
          if (error.isChargify) {
            dispatch(message(intl.formatMessage(
              { id: 'upgrade-popup.notification-self-service' },
              {
                a: (text: string) => (
                  <a href={user?.account.selfServicePage} target="_blank">{text}</a>
                )
              } as any
            ), 'error', NOTIFICATION_DURATION_LONG))
          } else {
            dispatch(message(intl.formatMessage({ id: 'notifications.error-w-message' }, { error: error.message }), 'error'))
          }
          closePopup()
          setUpgrading(false)
        }
      )
  }

  return (
    <Dialog
      open={!loading && Boolean(openWithPlan.handle)}
      maxWidth="md"
      classes={{ root: styles['dialog-root'] }}
      onClose={closePopup}
    >
      <div className={`${styles.content} ${styles.trial} ${styles.promo}`}>
        <div className={styles['end-now-box']}>
          <div className={styles.title}>
            <FormattedMessage
              id="popup.trial-end.now.title"
              values={{
                percent: coupon?.percent,
                color: (...text) => <span className={styles.hl}>{text}</span>
              }}
            />
          </div>
          <div className={styles['step-title']}>
            <FormattedMessage
              id={`popup.trial-end.${isCCTrial ? 'cc' : 'nocc'}.subtitle`}
              values={{
                percent: coupon?.percent,
                br: <br />,
                color: (...text) => <span className={styles.hl}>{text}</span>
              }}
            />
          </div>
          {isCCTrial && (
            <div className={styles.text}>
              <FormattedMessage id="popup.trial-end.cc.hint" />
            </div>
          )}
          {isNOCCTrial && (
            <React.Fragment>
              <div className={styles.text}>
                <FormattedMessage
                  id="popup.trial-end.nocc.copy-code"
                  values={{
                    code: coupon?.code,
                    button: (...text) => (
                      <Tooltip title={<FormattedMessage id="actions.click-to-copy" />}>
                        <span ref={setCopyRef}>
                          <span className={styles['hl-code']}>{text}</span>
                          <IconCopy fontSize="small" className={styles['icon-copy']} />
                        </span>
                      </Tooltip>
                    )
                  }}
                />
              </div>
              <div className={styles.text}>
                <FormattedMessage id="popup.trial-end.nocc.hint" />
              </div>
            </React.Fragment>
          )}
        </div>

        {/* Plan Select */}
        {isCCTrial && (
          <div className={styles['plan-box']}>
            <div className={styles['select-box']}>
              <ProductSelect
                products={visiblePlans}
                currency={currency}
                selectedProduct={selectedProduct}
                className={styles['plan-select']}
                selectClassName={styles['select-inner']}
                menuItemClassName={styles.li}
                MenuProps={{
                  PopoverClasses: { root: styles['select-popover'] },
                  classes: { list: styles.menu }
                }}
                onSelectionChange={setSelectedProduct}
              />
            </div>
            <div className={styles.text}>
              <FormattedMessage
                id="popup.trial-end.now.msg-plans"
                values={{
                  a: (...text) => (
                    // eslint-disable-next-line max-len
                    <a href="https://help.postplanner.com/en/articles/8797516-what-are-the-differences-between-plans" target="_blank">
                      {text}
                    </a>
                  )
                }}
              />
            </div>
          </div>
        )}
        {/* End Plan Select */}

        {isCCTrial && (
          <div className={`${styles.text} ${styles.price}`}>
            <FormattedMessage
              id="popup.trial-end.cc.price-label"
              values={{ price: `${CURRENCY_SIGN_MAP[currency]}${selectedPlanDiscountPrice}` }}
            />
          </div>
        )}
        <div className={`${styles.actions} ${styles.end}`}>
          <Button color="primary" variant="contained" size="large" disabled={isUpgrading} onClick={onUpgrade}>
            <FormattedMessage id={`popup.trial-end.now.${isNOCCTrial ? 'nocc-' : ''}action`} />
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
