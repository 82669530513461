import { FEED_TYPE_FACEBOOK, FEED_TYPE_TWITTER, FEED_TYPE_RSS, ContentFeedType } from 'interfaces'
import mapAvatar from 'services/content/adapters/avatar'
import { urlRegex } from 'shared/constants'

// For specific search query get its most probable feed types
export function getSearchFilterByQuery(query: string): 'facebook' | 'rss' | null {
  if (query.trim()) {
    const fbRegex = new RegExp('(www\.)?fb\.|facebook\.', 'i') // eslint-disable-line
    // eslint-disable-next-line
    const urlRe = new RegExp(urlRegex, 'gm')

    if (fbRegex.test(query)) {
      return FEED_TYPE_FACEBOOK
    } if (query.match(urlRe)) {
      if (query.indexOf('fb.me') !== -1 || query.indexOf('fb.com') !== -1 || query.indexOf('facebook.com') !== -1) {
        return FEED_TYPE_FACEBOOK
      }
      return FEED_TYPE_RSS

    }
  }
  return null
}

function getLastURLSegment(handle: string) {
  // remove trailing '/'
  if (handle.lastIndexOf('/') === handle.length - 1) {
    handle = handle.slice(0, -1)
  }

  handle = handle.indexOf('@') === 0 ? handle.slice(1) : handle.slice(handle.lastIndexOf('/') + 1)
  return handle
}

export function getFeedShortUrl(type: ContentFeedType, urlLong: string) {
  const url = urlLong.trim().toLowerCase()
  if (!url) {
    return ''
  }

  const facebookShortDomain = 'fb.com'

  switch (type) {
    case FEED_TYPE_FACEBOOK:
      const fbShort = url.replace('https://', '')
        .replace('http://', '')
        .replace('www.facebook.com', facebookShortDomain)
        .replace('facebook.com', facebookShortDomain)
      return fbShort

    case FEED_TYPE_TWITTER:
      const twShort = getLastURLSegment(url)
      return `@${twShort}`.replace('@@', '@') // remove double "@"

    case FEED_TYPE_RSS:
      return url.replace('https://', '').replace('http://', '')

    default:
      return url
  }
}

export function getFeedHandle(type: ContentFeedType, feedUrl: string) {
  const url = feedUrl.trim()
  if (!url) {
    return ''
  }

  switch (type) {
    case FEED_TYPE_TWITTER:
    case FEED_TYPE_RSS:
      return getFeedShortUrl(type, url)

    case FEED_TYPE_FACEBOOK:
      if (url.indexOf('profile.php?id=') !== -1) {
        const regex = /www\.facebook\.com\/profile\.php\?id=([\d]+)/
        const newUrl = url.replace(regex, 'www.facebook.com/$1')
        return newUrl
      }
      return url

    default:
      return url
  }
}

export function getFeedImageByUrl(type: ContentFeedType, feedUrl: string) {
  const url = feedUrl.trim().toLowerCase()
  if (!url) {
    return ''
  }

  return mapAvatar(type, { url, handle: url })
}
