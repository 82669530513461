import * as React from 'react'
import {
  ContentType,
  PostType,
  STATUS_TYPE,
  PostDestinationType,
  WithIntl,
  PostDetails,
  BRAND_FACEBOOK,
  Bucket,
  PostDestination,
  BRAND_LINKEDIN,
  NO_TIME_SLOT_VALUE
} from 'interfaces'
import ProfileAvatar from 'components/ProfileAvatar'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import { FormattedMessage, injectIntl } from 'react-intl'
import TweetBlock from 'components/TweetBlock'
import PostText from './PostText'
import { PostContentTypeIcon } from './PostContentTypeIcon'
import styles from './PostBase.pcss'
import FBReshareLabel from './components/FBReshareLabel'
import Icon from '@mdi/react'
import { mdiPail } from '@mdi/js'
import { NavLink } from 'react-router-dom'
import PostPreviewText from 'components/PostPreview/components/PostPreviewText'
import { COLLAPSE_TEXT_LABEL_FB, TRUNCATE_MAX_LINES_FB, TRUNCATE_TEXT_FB } from 'components/PostPreview/components/FacebookPostPreview'
import { PostMedia } from './PostMedia'
import ClickAwayListener from '@mui/material/ClickAwayListener'

export interface PostBaseProps {
  postId: string
  contentType: ContentType
  postType: PostType
  text: string
  baseTime?: number | null
  timeFormatted: string
  timezone: string
  actions: React.ReactNode
  profile: PostDestination
  profileNetwork: PostDestinationType
  innerRef?: any
  title?: string
  description?: string
  linkUrl?: string
  linkDomain?: string
  images?: string[]
  error?: string
  inactive?: boolean
  nativeVideoUrl?: string
  fbVideoUrl?: string
  className?: string
  specialType?: 'reels' | 'stories' | 'document' | 'tobi'
  twitterPostType?: 'retweet' | 'quote'
  processingPreview?: boolean
  authorName?: string
  attributes: {
    title?: string
    description?: string
    url?: string
    pinterestBoardUrl?: string
  } | null
  withDate?: boolean
  date: string
  details?: PostDetails
  disableExpand?: boolean
  bucket?: Bucket
  showBucket?: boolean
  isVirtual?: boolean
  virtual?: {
    isForever: boolean
    repeatingLabel: string
    type: 'repeat' | 'recycle'
    originalTime: string
  }
  pending?: boolean
  plan?: { id: string, isPaused: boolean } | null
  style?: React.CSSProperties
  onMediaPreviewBtnClick: () => void
  onClick?: () => void
}

export const PostBase = React.memo((props: PostBaseProps & WithIntl) => {
  const [expanded, setExpanded] = React.useState(false)
  const inactiveClass = props.inactive ? styles.inactive : ''
  const expandedClass = expanded && !props.disableExpand ? styles.expanded : ''
  const baseClasses = `${styles.post} ${styles[props.contentType] || ''} ${styles[props.postType] || ''} ${props.className || ''}`
  const withErrorClass = props.error ? styles['with-error'] : ''
  const virtualClass = props.isVirtual ? styles.virtual : ''
  const baseClass = `${baseClasses} ${expandedClass} ${inactiveClass} ${withErrorClass} ${virtualClass}`

  const virtualTooltip = React.useMemo(() => {
    if (!props.virtual) {
      return null
    }

    let type = 'repeating'
    let verb = 'repeated'

    if (props.virtual.type === 'recycle') {
      type = 'recycling'
      verb = 'recycled'
    }

    return `This is a ${type} version of your original post set to publish on ${props.virtual.originalTime}.\n`
      + `It will be ${verb} if the original post publishes successfully.`
  }, [props.virtual])

  const onMediaPreviewBtnClick = React.useCallback((e: React.MouseEvent) => {
    e.stopPropagation()
    props.onMediaPreviewBtnClick()
  }, [])

  const toggleExpanded = React.useCallback(() => {
    setExpanded(current => !current)
  }, [])

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    if ((e.target as HTMLElement).closest('[data-actions]')) {
      return
    }
    if (props.onClick) {
      props.onClick()
    }
  }

  const postType = props.specialType || props.contentType
  const isTwitterQuote = props.twitterPostType === 'quote'
  const isRetweet = props.twitterPostType === 'retweet'
  const isReshare = props.details?.reshareUrl && props.profileNetwork === BRAND_FACEBOOK
  const withFirstComment = Boolean(props.details?.firstComment)
  const twQuoteTextClassName = isTwitterQuote ? styles['tw-caption'] : ''
  const singleLineClassName = (isTwitterQuote || withFirstComment || isReshare) && !expanded ? 'text-ellipsis' : ''

  const dateTooltip = `${props.date} ${props.timeFormatted}\n${props.timezone}${props.baseTime ? `\nPosts: within 20min` : ''}`

  const timeElement = React.useMemo(() => {
    if (props.timeFormatted !== NO_TIME_SLOT_VALUE || !props.plan) {
      return (
        <p className={styles.time}>{props.timeFormatted}{props.baseTime ? ' ish' : ''}</p>
      )
    }
    return (
      <NavLink to={`/plans/${props.plan.id}`}>
        <p className={`${styles.time} ${styles.bold}`}>{props.plan.isPaused ? 'Posting Paused' : props.timeFormatted}</p>
      </NavLink>
    )
  }, [props.timeFormatted, props.plan, props.baseTime])

  return (
    <Tooltip title={virtualTooltip} classes={{ popper: styles.tooltip, tooltip: styles['tooltip-virtual'] }}>
      <ClickAwayListener onClickAway={() => setExpanded(false)}>
        <article
          data-post-id={props.postId}
          data-testid="post-base"
          data-contenttype={props.contentType}
          className={baseClass}
          ref={props.innerRef}
          style={props.style}
        >
          <div className={styles.outleft}>
            {props.withDate && (
              <p className={styles.time}>{props.date}</p>
            )}
            <Tooltip classes={{ tooltip: styles.tooltip }} title={props.withDate || props.pending ? '' : dateTooltip}>
              {timeElement}
            </Tooltip>
          </div>
          <Paper className={styles.paper} elevation={0}>
            {props.bucket && (
              <Tooltip title={`Bucket: ${props.bucket.name}`} placement="top">
                <div className={styles['bucket-indicator']} style={{ backgroundColor: props.bucket.color }}></div>
              </Tooltip>
            )}
            <div className={styles['content-box']}>
              <div className={styles.profile}>
                {props.showBucket && props.bucket ? (
                  <Tooltip title={props.bucket.name} placement="top">
                    <Icon path={mdiPail} size="40px" className={styles['icon-bucket']} color={props.bucket.color} />
                  </Tooltip>
                ) : (
                  <ProfileAvatar
                    title={props.profile?.name || ''}
                    brand={props.profile?.type}
                    src={props.profile?.image}
                    className={styles.avatar}
                    circle={!props.profile.fbGroup}
                  />
                )}
              </div>
              <div className={styles.type} onClick={toggleExpanded}>
                <div className={styles['type-box']}>
                  <Tooltip title={<FormattedMessage id={`label.generic.${postType}-post`} />}>
                    <span>
                      <PostContentTypeIcon type={postType as any} />
                    </span>
                  </Tooltip>
                </div>
              </div>
              <div className={styles.body} onClick={onClick}>
                {props.contentType !== STATUS_TYPE && (
                  <div className={styles['img-container']} onClick={props.fbVideoUrl ? undefined : toggleExpanded}>
                    {!props.processingPreview && (
                      <PostMedia
                        postId={props.postId}
                        contentType={props.contentType}
                        images={props.images}
                        title={props.title}
                        linkUrl={props.linkUrl}
                        linkDomain={props.linkDomain}
                        profileNetwork={props.profileNetwork}
                        nativeVideoUrl={props.nativeVideoUrl}
                        fbVideoUrl={props.fbVideoUrl}
                        attributes={props.attributes}
                        details={props.details}
                        expanded={expanded}
                        onMediaPreviewBtnClick={onMediaPreviewBtnClick}
                      />
                    )}
                    {props.processingPreview && (
                      <div className={styles['no-preview-box']}>
                        <span className={styles['no-preview-msg']}>
                          <ErrorIcon className={styles['icon-warning']} />
                          <FormattedMessage id="post.label.generating-preview" />
                        </span>
                      </div>
                    )}
                  </div>
                )}
                {props.contentType === STATUS_TYPE && props.details?.textBackground && (
                  <div className={styles['img-container']} onClick={toggleExpanded}>
                    <PostPreviewText
                      text={props.text}
                      network="facebook"
                      expandLabel={TRUNCATE_TEXT_FB}
                      collapseLabel={COLLAPSE_TEXT_LABEL_FB}
                      maxLinesDefault={TRUNCATE_MAX_LINES_FB}
                      className={styles['txt-bg']}
                      backgroundImageUrl={props.details.textBackground.backgroundUrl}
                      color={props.details.textBackground.color}
                    />
                  </div>
                )}
                <div className={styles['text-container']} onClick={toggleExpanded}>
                  {props.attributes ? (
                    <div>
                      {props.attributes.title && <p className={styles['status-title']}>{props.attributes.title}</p>}
                      {props.attributes.description && <p className={styles['status-description']}>{props.attributes.description}</p>}
                    </div>
                  ) : props.details?.textBackground ? null : (
                    <PostText
                      text={props.text}
                      network={props.profileNetwork}
                      tags={props.details?.tags}
                      linkedinFormattedText={props.profileNetwork === BRAND_LINKEDIN ? props.details?.text : undefined}
                      className={`${styles.text} ${twQuoteTextClassName} ${singleLineClassName}`}
                    />
                  )}
                  {(isTwitterQuote || isRetweet) && (
                    <TweetBlock
                      authorHandle={props.details?.sourceFeedName}
                      authorName={props.details?.sourceFeedName}
                      date={props.details?.sourcePostDate ? new Date(props.details?.sourcePostDate) : undefined}
                      text={props.details?.sourcePostText || ''}
                      headlineOnly={!expanded && isTwitterQuote}
                      className={isTwitterQuote ? styles['tw-quote'] : ''}
                      isQuote={isTwitterQuote}
                      isRetweet={isRetweet}
                      clampLines={expanded ? undefined : 1}
                      tweetUrl={props.details?.tweetUrl}
                    />
                  )}
                  {isReshare && (
                    <FBReshareLabel
                      url={props.details?.reshareUrl as string}
                      profileName={props.details?.reshareName}
                      className={styles['original-post']}
                    />
                  )}
                  {withFirstComment && (
                    <div className={`${styles.comment} text-ellipsis`}>
                      {props.details?.firstComment}
                    </div>
                  )}
                </div>
                <div className={styles.actions} data-actions>
                  {props.actions}
                </div>
              </div>
            </div>
            {props.error && (
              <div className={styles.error}>
                <div className={styles['error-title-box']}>
                  <span>
                    <ErrorIcon className={styles['error-icon']} />
                    Failed post
                  </span>
                  <span>
                    <FormattedMessage id="post.label.post-author" values={{ author: props.authorName }} />
                  </span>
                </div>
                <div className={styles['err-bottom']}>
                  <p className={styles['error-text']}>
                    <span className={styles.bold}>Failure reason: </span>
                    {/* eslint-disable-next-line react/no-danger */}
                    <span dangerouslySetInnerHTML={{ __html: props.error }}></span>
                  </p>
                  <a
                    href="https://help.postplanner.com/en/articles/8797762-how-do-i-manage-my-failed-posts"
                    rel="noopener"
                    target="_blank"
                  >
                    Click here to troubleshoot
                  </a>
                </div>
              </div>
            )}
          </Paper>
        </article>
      </ClickAwayListener>
    </Tooltip>
  )
})

export default injectIntl(PostBase)
