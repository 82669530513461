import { Album, BRAND_PINTEREST, IndexedObject, PostDestination } from 'interfaces'
import * as React from 'react'
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import IconCheck from '@mui/icons-material/Check'
import IconClear from '@mui/icons-material/Close'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import styles from './AlbumSelector.pcss'
import Icon from '@mdi/react'
import { mdiImageAlbum, mdiBulletinBoard } from '@mdi/js'
import { FormattedMessage, useIntl } from 'react-intl'
import Tooltip from '@mui/material/Tooltip'

export interface AlbumSelectorProps {
  profile: PostDestination
  albums: IndexedObject<Album>
  selectedAlbumId?: string
  messageEmptySelection: React.ReactElement
  errorMessageElement: React.ReactElement
  error?: boolean
  loading: boolean
  className?: string
  allowEmpty?: boolean
  raised?: boolean
  hideProfileImage?: boolean
  withFilter?: boolean
  onSelectionChange: (album: Album | undefined) => void
}

export function AlbumSelector(props: AlbumSelectorProps) {
  const intl = useIntl()
  const [dropdownAnchor, setDropdownAnchor] = React.useState<HTMLElement | null>(null)
  const [filter, setFilter] = React.useState('')

  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value)
  }

  const clearFilter = () => {
    setFilter('')
  }

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (dropdownAnchor) {
      setDropdownAnchor(null)
    } else {
      const anchor = (e.target as HTMLDivElement).closest('[data-anchor]')
      setDropdownAnchor(anchor as HTMLElement)
    }
  }

  const closeDropdown = () => {
    setDropdownAnchor(null)
    setFilter('')
  }

  const onSelectionChange = (a: Album) => {
    props.onSelectionChange(a)
    closeDropdown()
  }

  const clearSelection = () => {
    props.onSelectionChange(undefined)
    closeDropdown()
  }

  const profileAlbums = props.albums || {}
  const isPinterest = props.profile.type === BRAND_PINTEREST
  const search = props.withFilter ? filter.toLowerCase() : null
  const visibleAlbums = props.withFilter && search
    ? Object.values(profileAlbums).filter(a => a.name.toLowerCase().includes(search))
    : Object.values(profileAlbums)

  return (
    <div className={`${styles.container} ${props.className || ''}`}>
      <Chip
        avatar={props.hideProfileImage ? (
          <Icon path={isPinterest ? mdiBulletinBoard : mdiImageAlbum} size="20px" />
        ) : (
          <Tooltip title={props.profile.name}>
            <div className={styles['img-profile-box']}>
              <img src={props.profile.image} />
            </div>
          </Tooltip>
        )}
        label={(
          <span className={styles['label-box']}>
            <FormattedMessage id={isPinterest ? 'label.generic.board' : 'label.generic.album'} />:&nbsp;
            {props.selectedAlbumId ? profileAlbums[props.selectedAlbumId]?.name : props.messageEmptySelection}
          </span>
        )}
        classes={{ root: styles.chip, label: `${styles.label} text-ellipsis`, avatar: styles.avatar }}
        data-anchor
        onClick={onClick}
      />
      <Popper
        open={Boolean(dropdownAnchor)}
        anchorEl={dropdownAnchor}
        placement="bottom-start"
        className={`${styles.popper} ${props.raised ? styles.raised : ''}`}
      >
        <ClickAwayListener onClickAway={closeDropdown} mouseEvent="onMouseDown">
          <Paper>
            {props.withFilter && (
              <div className={styles.filter}>
                <TextField
                  placeholder={intl.formatMessage({ id: 'label.generic.placeholder-filter' })}
                  value={filter}
                  className={styles['input-filter']}
                  InputProps={{
                    endAdornment: filter.length > 0 ? (
                      <IconClear className={styles['icon-clear']} fontSize="small" onClick={clearFilter} />
                    ) : null
                  }}
                  onChange={onFilterChange}
                />
              </div>
            )}
            {Boolean(profileAlbums) && (
              <ul className={styles.list}>
                {props.allowEmpty && (
                  <li
                    className={!props.selectedAlbumId ? styles.highlight : ''}
                    onClick={clearSelection}
                  >
                    {props.messageEmptySelection}
                    {!props.selectedAlbumId && <IconCheck color="primary" fontSize="small" className={styles.icon} />}
                  </li>
                )}
                {!props.loading && props.error && (
                  <li className={styles.warning}>
                    {props.errorMessageElement}
                  </li>
                )}
                {!props.loading && !props.error && Object.keys(profileAlbums).length === 0 && (
                  <li className={styles.warning}>
                    No albums.
                  </li>
                )}
                {visibleAlbums.map((b: Album) => (
                  <AlbumListItem key={b.id} album={b} selected={props.selectedAlbumId === b.id} onClick={onSelectionChange} />
                ))}
              </ul>
            )}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

const AlbumListItem = (props: { album: Album, selected: boolean, onClick: (album: Album) => void }) => {
  const onClick = () => {
    props.onClick(props.album)
  }

  return (
    <li className={props.selected ? styles.highlight : ''} data-testid="album-list-item" onClick={onClick}>
      {props.album.name}
      {props.selected && <IconCheck color="primary" fontSize="small" className={styles.icon} />}
    </li>
  )
}
