import { IndexedObject, PostBase } from 'interfaces'
import {
  ACTION_ADD_POSTS,
  ACTION_SET_POSTS,
  ACTION_RECYCLE_POSTS_SUCCESS,
  ACTION_UPDATE_POST_LOCATION,
  ACTION_DELETE_POSTS,
  ACTION_UPDATE_RECYCLE_POST,
  ACTION_REPLACE_POST
} from '../actions'

export type PostsAction = { type: string, payload?: any }

export function postsReducer<T extends PostBase>(posts: T[], action: PostsAction): T[] {
  switch (action.type) {
    case ACTION_SET_POSTS:
      return action.payload

    case ACTION_ADD_POSTS:
      return [...posts, ...action.payload]

    case ACTION_RECYCLE_POSTS_SUCCESS: {
      const recycleAll = action.payload.all
      const ids = action.payload.postIds.reduce((map: IndexedObject<boolean>, id: string) => {
        map[id] = true
        return map
      }, {})

      return posts.map(p => {
        if (recycleAll || ids[p.id]) {
          return Object.assign(p, { recycle: p.recycle || true })
        }
        return p
      })
    }

    case ACTION_UPDATE_POST_LOCATION: {
      const postIndex = posts.findIndex(p => p.id === action.payload.postId)
      if (postIndex !== -1) {
        const updated = [...posts]
        const current = posts[postIndex]
        const updatedPost = Object.assign({}, current, { details: { ...current.details, location: action.payload.location } })
        updated.splice(postIndex, 1, updatedPost)
        return updated
      }

      return posts
    }

    case ACTION_DELETE_POSTS: {
      const deleted = action.payload.reduce((map: IndexedObject<boolean>, id: string) => {
        map[id] = true
        return map
      }, {})

      return posts.filter(p => !deleted[p.id])
    }

    case ACTION_UPDATE_RECYCLE_POST: {
      const postIndex = posts.findIndex(p => p.id === action.payload.id)
      if (postIndex !== -1) {
        const updated = [...posts]
        const updatedPost = Object.assign({}, posts[postIndex], { recycle: action.payload.value })
        updated.splice(postIndex, 1, updatedPost)
        return updated
      }

      return posts
    }

    case ACTION_REPLACE_POST: {
      const postIndex = posts.findIndex((p: any) => p.postId === action.payload.postId)
      if (postIndex !== -1) {
        const updated = [...posts]
        const post = {
          ...action.payload,
          time: posts[postIndex].time,
          date: (posts[postIndex] as any).date,
          timeString: posts[postIndex].timeString,
          weekday: posts[postIndex].weekday
        }
        updated.splice(postIndex, 1, post)
        return updated
      }

      return posts
    }

    default:
      return posts
  }
}
