import * as React from 'react'
import Button from '@mui/material/Button'
import { PhotoEditor } from 'components/PhotoEditor'
import { WithIntl } from 'interfaces'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'services/snackbar'
import styles from './ContentStudio.pcss'
import { dataURLtoFile, DEFAULT_STUDIO_EXPORT_FILE_NAME } from 'utils/file'
import { setComposerExternalFile, promptCompose, addImagePosts } from 'services/compose'
import { noop } from 'utils/noop'
import { setUploaderFiles, setUploadsDialogOpen } from 'services/uploads/actions'
import Backdrop from '@mui/material/Backdrop'
import SimpleLoader from 'components/SimpleLoader'
import ContentStudioGridWithFilters from './components/ContentStudioGridWithFilters'
import FeatureChecker from 'components/FeatureChecker/FeatureChecker'
import { FEATURE_CONTENT_STUDIO } from 'shared/constants'
import { useNavigate, useLocation } from 'react-router-dom'
import FileUpload from 'components/FileUpload'
import { composerContentItemSelector, composerImageUrlsSelector, multiPostsArraySelector } from 'services/compose/selectors'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import { VideoEditorDialog } from 'components/VideoEditorDialog'
import CreativeEditorSDK from '@cesdk/cesdk-js'

declare const PhotoEditorSDK: any
const EMPTY_IMAGE_URL = 'static/img/pesdk/pesdk-empty.jpg'
const TWITTER_CDN_HOSTNAME = 'pbs.twimg.com'

interface ContentStudioOwnProps {
  className?: string
  hideMultipost?: boolean
  onAddedToComposer?: () => void
}

export function ContentStudio(props: ContentStudioOwnProps & WithIntl) {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [mode, setMode] = React.useState<'image' | 'video'>('image')
  const initialImagePath = `${window.location.origin}/${EMPTY_IMAGE_URL}`
  const [isCreatingFile, setIsCreatingFile] = React.useState(false)
  const [isLoadingVideo, setIsLoadingVideo] = React.useState(false)
  const [imageUrl, setImageUrl] = React.useState(location.state?.imageUrl || initialImagePath)
  const [editorKey, setEditorKey] = React.useState(0)
  const [fileUpload, setFileUpload] = React.useState<File | null>(null)
  const photoEditorApi = React.useRef<any>()
  const cesdk = React.useRef<CreativeEditorSDK>()
  const composerContentItem = useSelector(composerContentItemSelector)
  const composerCarouselImages = useSelector(composerImageUrlsSelector)
  const multiPosts = useSelector(multiPostsArraySelector)

  const modeOptions: PPSelectOptions = {
    image: { label: 'Image' },
    video: { label: 'Video' }
  }

  const onVideoEditorExport = React.useCallback((file: File) => {
    console.log('Exporting video', file)
  }, [])

  const onVideoEditorInit = React.useCallback((sdk: CreativeEditorSDK) => {
    cesdk.current = sdk
  }, [])

  const onPhotoEditorInitialized = (api: any) => {
    photoEditorApi.current = api
  }

  const onPhotoEditorError = React.useCallback(() => {
    dispatch(message(props.intl.formatMessage({ id: 'general.media-editor.error' }), 'error'))
  }, [dispatch, props.intl])

  const setImage = React.useCallback((src: string) => {
    let url = src.indexOf('?') === -1 ? `${src}?v=${Date.now()}` : `${src}&v=${Date.now()}`
    // EXPL: Twitter images can't be loaded with url params
    if (src.indexOf(TWITTER_CDN_HOSTNAME) !== -1) {
      url = src
    }
    setImageUrl(url)
  }, [])

  React.useEffect(() => {
    if (location.state?.imageUrl) {
      setMode('image')
      setImage(location.state?.imageUrl)
      setEditorKey(current => current + 1)
    }
  }, [location.state?.imageUrl, setImage])

  const generateImage = React.useCallback(() => {
    return new Promise((resolve, reject) => {
      if (!photoEditorApi.current) {
        dispatch(message(props.intl.formatMessage({ id: 'errors.generic' }), 'error'))
        return reject('Editor not initialized!')
      }
      setIsCreatingFile(true)
      setTimeout(() => {
        photoEditorApi.current.export().then((dataUrl: string) => {
          const file = dataURLtoFile(dataUrl, DEFAULT_STUDIO_EXPORT_FILE_NAME)
          setIsCreatingFile(false)
          resolve(file)
        }).catch(() => {
          setIsCreatingFile(false)
          dispatch(message(props.intl.formatMessage({ id: 'general.media-editor.error' }), 'error'))
          reject('Error generating image.')
        })
      })
    })
  }, [dispatch, props.intl])

  const generateVideo = React.useCallback(() => {
    const sdk = cesdk.current
    const page = sdk?.engine.scene.getCurrentPage()
    if (sdk && typeof page === 'number') {
      setIsCreatingFile(true)
      return sdk.engine.block.exportVideo(page, 'video/mp4' as any, console.log, {})
        .then((blob: Blob) => {
          const file = new File([blob], `video-export-${Date.now()}.mp4`, { type: blob.type })
          setIsCreatingFile(false)
          return file
        })
    }
    dispatch(message(props.intl.formatMessage({ id: 'errors.generic' }), 'error'))
    return Promise.reject('Error generating video.')
  }, [dispatch, props.intl])

  const addToComposer = () => {
    // EXPL: Open prompt if composer already has carousel or multi-post
    if (composerCarouselImages.length > 1 || multiPosts.length > 0) {
      dispatch(promptCompose({ content: {} as any, action: 'create' }))
      return
    }

    dispatch(message(props.intl.formatMessage({ id: `content-studio.notifications.creating-${mode}` })))
    const generateFile = mode === 'image' ? generateImage : generateVideo
    generateFile().then((file: File) => {
      dispatch(setComposerExternalFile(file))
      if (props.onAddedToComposer) {
        props.onAddedToComposer()
      } else {
        navigate('/composer', { state: { floating: true, modal: 'compose', opener: location.pathname, background: location } })
      }
    })
  }

  const addMultiPost = () => {
    generateImage().then((file: File) => {
      if (!composerContentItem && composerCarouselImages.length === 0) {
        dispatch(message(props.intl.formatMessage({ id: 'content-studio.notifications.creating-image' })))
        setFileUpload(file)
      } else {
        dispatch(promptCompose({ content: {} as any, action: 'multipost' }))
      }
    })
  }

  const onFileUploaded = (url: string) => {
    setFileUpload(null)
    dispatch(addImagePosts([{ url }]))
    if (props.onAddedToComposer) {
      props.onAddedToComposer()
    } else {
      navigate('/composer', { state: { floating: true, modal: 'compose', opener: location.pathname, background: location } })
    }
  }

  const onFileUploadError = () => {
    dispatch(message(props.intl.formatMessage({ id: 'content-studio.notifications.upload-error' }), 'error'))
    setFileUpload(null)
  }

  const saveToLibrary = () => {
    switch (mode) {
      case 'image':
        dispatch(message(props.intl.formatMessage({ id: 'content-studio.notifications.creating-image' })))
        generateImage().then((file: File) => {
          dispatch(setUploaderFiles({ [file.name]: file }))
          dispatch(setUploadsDialogOpen(true))
        })
        break

      case 'video':
        dispatch(message(props.intl.formatMessage({ id: 'content-studio.notifications.creating-video' })))
        generateVideo().then((file: File) => {
          dispatch(setUploaderFiles({ [file.name]: file }))
          dispatch(setUploadsDialogOpen(true))
        })
        break
    }
  }

  const onVideoSelected = (url: string) => {
    const engine = cesdk.current?.engine
    if (engine) {
      setIsLoadingVideo(true)
      dispatch(message('Loading video...'))
      engine.scene.createFromVideo(url).finally(() => {
        setIsLoadingVideo(false)
      })
    }
  }

  const goHome = () => {
    navigate('/')
  }

  return (
    <div className={`${styles.wrapper} ${props.className || ''}`}>
      <FeatureChecker features={[FEATURE_CONTENT_STUDIO]} closeCallback={goHome} />
      <header className={styles.header}>
        <h1 className={styles.title}>
          <FormattedMessage
            id="content-studio.title"
            values={{
              select: () => (
                <PPSelect
                  options={modeOptions}
                  selectedValue={mode}
                  withCaret
                  buttonClassName={styles['header-btn']}
                  className={styles.select}
                  noLabelCaps
                  onSelectionChange={setMode as any}
                />
              )
            }}
          />
        </h1>
        <div className={styles.actions}>
          <Button variant="text" disabled={isCreatingFile} onClick={saveToLibrary}>
            <FormattedMessage id="content-studio.actions.save-to-library" />
          </Button>
          {mode === 'image' && !props.hideMultipost && (
            <Button variant="contained" color="primary" disabled={isCreatingFile} onClick={addMultiPost}>
              <FormattedMessage id="content-studio.actions.multi-post" />
            </Button>
          )}
          <Button variant="contained" color="primary" disabled={isCreatingFile} onClick={addToComposer}>
            <FormattedMessage id="content-studio.actions.add-to-composer" />
          </Button>
        </div>
      </header>
      <div className={styles['editor-box']}>
        {mode === 'image' && (
          <PhotoEditor
            key={editorKey}
            image={imageUrl}
            config={{
              defaultTool: PhotoEditorSDK.Tool.TEXT,
              mainCanvasActions: []
            }}
            className={styles.editor}
            onExport={noop}
            onError={onPhotoEditorError}
            onInitialized={onPhotoEditorInitialized}
          />
        )}
        {mode === 'video' && (
          <VideoEditorDialog
            open
            inline
            hideExportButton
            editorClassName={styles.editor}
            onInit={onVideoEditorInit}
            onExport={noop}
          />
        )}
      </div>
      <div className={styles['content-box']}>
        <ContentStudioGridWithFilters mode={mode} onImageSelected={setImage} onVideoSelected={onVideoSelected} />
      </div>
      <Backdrop open={isCreatingFile || isLoadingVideo || Boolean(fileUpload)} className={styles.backdrop}>
        <SimpleLoader />
      </Backdrop>
      {fileUpload && (
        <FileUpload
          file={fileUpload}
          rotateBy="0"
          skipValidation
          onFileUploaded={onFileUploaded}
          onError={onFileUploadError}
        />
      )}
    </div>
  )
}

export default injectIntl(ContentStudio)
