import * as React from 'react'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { Subject } from 'rxjs/Subject'
import { uploadFile } from 'services/compose'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { Subscriber } from 'rxjs/Subscriber'
import 'rxjs/add/operator/switchMap'

export interface UploadProps {
  file: File
  // eslint-disable-next-line react/no-unused-prop-types
  chunkSize?: number // bytes
  onError: (error: Error) => void
  onProgress: (progress: number) => void
  onSuccess: (fileUrl: string, response: any) => void
  onUploadStarted?: (upload: { abort: (terminate?: boolean) => void }) => void
}

export function Upload(props: UploadProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const upload$ = React.useRef<Subject<File | null>>()

  const onProgress = (e: ProgressEvent) => {
    const value = (e.loaded / e.total) * 100 // eslint-disable-line no-magic-numbers
    props.onProgress(value)
  }

  React.useEffect(() => {
    upload$.current = new Subject()
    const subscription = upload$.current
      .switchMap((file: File | null) => {
        // EXPL: null is passed when we want to cancel the currently running request
        // without causing the Observable stream to complete.
        if (file === null) {
          return Observable.of(null)
        }

        const progress$ = new Subscriber(onProgress)
        if (props.onUploadStarted) {
          props.onUploadStarted({
            abort: (terminate?: boolean) => {
              if (terminate) {
                upload$.current?.next(null)
              } else {
                upload$.current?.unsubscribe()
              }
            }
          })
        }
        return dispatch(uploadFile(file, progress$)).pipe(catchError(error => Observable.of({ error })))
      })
      .filter(response => Boolean(response))
      .subscribe((response: any) => {
        if (response.error) {
          props.onError(response.error)
        } else {
          props.onSuccess(response.url, response)
        }
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [dispatch, props.onSuccess, props.onError])

  React.useEffect(() => {
    upload$.current?.next(props.file)
  }, [props.file])

  return null
}

export default Upload
