import { LEGACY_URL } from 'config'
import { ContentType, PinterestBoard, PlannedPost } from 'interfaces'
import { FBAlbum } from 'services/compose/interfaces/ComposerFacebookPost'
import { RequestBuilder, authorizedPipe } from 'services/net'
const ENDPOINT_GET_BUCKETS = `${LEGACY_URL}/v3/buckets/get`
const ENDPOINT_BUCKETS = `${LEGACY_URL}/v3/buckets`
const ENDPOINT_DELETE_BUCKET = `${LEGACY_URL}/v3/buckets/delete/:id`
const ENDPOINT_REORDER_BUCKETS = `${LEGACY_URL}/v3/buckets/reorder`
const ENDPOINT_BUCKET_POSTS = `${LEGACY_URL}/v3/posts/get_bucket_posts`

const PAGE_SIZE_DEFAULT = 20

export enum DeleteMethod {
  Delete = 'Delete',
  Queue = 'Queue',
  Copy = 'Copy'
}

export const V1 = Object.freeze({
  getBuckets: function () {
    return function () {
      const builder = new RequestBuilder(ENDPOINT_GET_BUCKETS).asGet().expectJSON()
      return authorizedPipe<{ data: any[] }>(builder.build())
    }
  }(),
  createBucket: function () {
    return function (
      name: string,
      color: string,
      description: string,
      isPublic: boolean,
      ppids: string[],
      activeFrom?: string,
      activeTo?: string
    ) {
      const builder = new RequestBuilder(ENDPOINT_BUCKETS).asPost().expectJSON()
      const data: any = {
        name,
        color,
        description,
        is_public: isPublic,
        pp_page_ids: ppids
      }

      if (activeFrom && activeTo) {
        data.active_from = activeFrom
        data.active_to = activeTo
      }
      return authorizedPipe<any>(builder.body(data).build())
    }
  }(),
  updateBucket: function () {
    return function (
      id: string,
      name: string,
      color: string,
      description: string,
      isPublic: boolean,
      ppids: string[],
      copyFromPpid: string | undefined,
      activeFrom: string | undefined,
      activeTo: string | undefined,
      fbAlbums?: FBAlbum[],
      piBoards?: PinterestBoard[]
    ) {
      const builder = new RequestBuilder(ENDPOINT_BUCKETS).asPost().expectJSON()
      const data: any = {
        id,
        name,
        color,
        description,
        is_public: isPublic,
        pp_page_ids: ppids,
        copy_from_pp_page_id: copyFromPpid || null,
        active_from: activeFrom || null,
        active_to: activeTo || null
      }

      const networks: any = {}
      if (fbAlbums) {
        networks.facebook = {
          details: {
            albums: fbAlbums.map(a => ({ ...a, ppPageId: a.ppid }))
          }
        }
      }
      if (piBoards) {
        networks.pinterest = {
          details: {
            boards: piBoards.map(b => ({ ...b, pp_page_id: b.ppid }))
          }
        }
      }

      data.networks = networks

      return authorizedPipe<any>(
        builder
          .body(data)
          .build()
      )
    }
  }(),
  deleteBucket: function () {
    return function (id: string,
      method: DeleteMethod,
      targetBucketId?: string | null
    ) {
      const builder = new RequestBuilder(ENDPOINT_DELETE_BUCKET).asPost().expectJSON()
      let data: any = {}
      switch (method) {
        case DeleteMethod.Copy:
          data = {
            action: 'delete_copy_bucket',
            copy_to_bucket: targetBucketId
          }
          break
        case DeleteMethod.Queue:
          data = {
            action: 'delete_keep_posts'
          }
          break
        default:
          data = { action: 'delete_everything' }
      }

      return authorizedPipe<any>(
        builder
          .partial(':id', id)
          .body(data)
          .build()
      )
    }
  }(),
  getBucketPosts: function () {
    return function (bucketId: string, page: number, ppid: string, query?: string, types?: ContentType[], searchInSummary?: boolean) {
      const builder = new RequestBuilder(ENDPOINT_BUCKET_POSTS).asPost().asFormUrlEncoded().expectJSON()
      const data: any = {
        page,
        pp_page_ids: [ppid],
        page_size: PAGE_SIZE_DEFAULT,
        bucket_id: bucketId
      }

      if (query) {
        data.search = query
      }
      if (types) {
        data.post_types = types.join(',')
      }
      if (searchInSummary) {
        data.search_in_summary = true
      }

      return authorizedPipe<{ posts: PlannedPost[], schedules?: any[], postsCount: number, hasPendingPosts: boolean, paging: any }>(
        builder
          .body(data)
          .build()
      )
    }
  }(),
  reorderBuckets: function () {
    return function (bucketIds: string[]) {
      const builder = new RequestBuilder(ENDPOINT_REORDER_BUCKETS).asPost().expectJSON()
      return authorizedPipe<any>(builder.body({ bucket_ids: bucketIds }).build())
    }
  }(),
  getDefaultBuckets: function () {
    return function () {
      const builder = new RequestBuilder(`${LEGACY_URL}/v3/buckets/get_default_buckets`).asGet().expectJSON()
      return authorizedPipe<any>(builder.param('all', '1').build())
    }
  }(),
  createDefaultBuckets: function () {
    return function (buckets: Record<string, string>) {
      const builder = new RequestBuilder(`${LEGACY_URL}/v3/buckets/create_default_buckets`).asPost().expectJSON()
      return authorizedPipe<any>(builder.body(buckets).build())
    }
  }()
})
