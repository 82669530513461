import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import IconCheck from '@mui/icons-material/Check'
import styles from './CalendarViewOptions.pcss'
import { FormattedMessage } from 'react-intl'
import { CalendarMonthStartOption, CalendarPostSize } from 'services/post/state'
import { useDispatch, useSelector } from 'react-redux'
import {
  calendarMonthStartSelector,
  calendarPostSizeSelector,
  calendarWeekStartSelector,
  emptySlotsVisibleSelector,
  calendarPostedVisibleSelector,
  postsUISelector,
  virtualPostsVisibleSelector
} from 'services/post/selectors'
import {
  setCalendarMonthStart,
  setCalendarPostedVisible,
  setCalendarPostSize,
  setCalendarWeekStart,
  toggleEmptySlotsVisible,
  toggleVirtualPostsVisible
} from 'services/post'
import { Icon } from '@mdi/react'
import { Weekday } from 'utils/calendar'
import PPSelect from 'components/PPSelect'
import { PPSelectOptions } from 'components/PPSelect/PPSelect'
import { mdiCogOutline, mdiBell } from '@mdi/js'
import { HelpToggleButton } from 'components/App/components/HelpToggleButton'
import { ActiveViewSelector } from '../ActiveViewSelector'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router'

interface CalendarViewOptionsProps {
  postsCount: number | null
  withPreview: boolean
  showPendingLink?: boolean
  onTogglePreview: (value: 'on' | 'off') => void
}

export function CalendarViewOptions(props: CalendarViewOptionsProps) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [popupAnchor, setPopupAnchor] = React.useState<HTMLElement | null>(null)
  const postSize = useSelector(calendarPostSizeSelector)
  const weekStart = useSelector(calendarWeekStartSelector)
  const monthStart = useSelector(calendarMonthStartSelector)
  const withEmptySlots = useSelector(emptySlotsVisibleSelector)
  const withPosted = useSelector(calendarPostedVisibleSelector)
  const virtualPostsVisible = useSelector(virtualPostsVisibleSelector)
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const openPopup = (e: any) => {
    if (buttonRef.current) {
      setPopupAnchor(buttonRef.current)
    }
    e.stopPropagation()
    e.preventDefault()
  }

  const onToggleVirtualPostsVisible = React.useCallback(() => {
    dispatch(toggleVirtualPostsVisible())
  }, [dispatch])

  const closePopup = () => {
    setPopupAnchor(null)
  }

  const updatePostSize = (size: CalendarPostSize) => {
    dispatch(setCalendarPostSize(size))
  }

  const toggleEmptySlots = React.useCallback((value: 'on' | 'off') => {
    const visible = value === 'on'
    if (withEmptySlots !== visible) {
      dispatch(toggleEmptySlotsVisible())
    }
  }, [withEmptySlots, dispatch])

  const onMonthStartChange = (value: CalendarMonthStartOption) => {
    dispatch(setCalendarMonthStart(value))
  }

  const onWeekStartChange = (value: Weekday) => {
    dispatch(setCalendarWeekStart(value))
  }

  const onTogglePostedVisible = (value: 'on' | 'off') => {
    dispatch(setCalendarPostedVisible(value === 'on'))
  }

  const toggleOptions: PPSelectOptions = {
    on: { label: <FormattedMessage id="label.generic.on" /> },
    off: { label: <FormattedMessage id="label.generic.off" /> }
  }

  const onOffToggleOptions = [{
    label: <FormattedMessage id="label.generic.show" />,
    value: 'on'
  }, {
    label: <FormattedMessage id="label.generic.hide" />,
    value: 'off'
  }]

  const postSizeOptions = [
    { label: 'Small', value: 'small' },
    { label: 'Medium', value: 'medium' },
    { label: 'Large', value: 'large' }
  ]

  const goToPending = () => {
    navigate('/posts/planned/queued/pending?view=list')
  }

  return (
    <div className={styles.container} data-testid="calendar-view-options">
      <div>
        <ActiveViewSelector className={styles['view-switch']} />
        <React.Fragment>
          <IconButton
            ref={buttonRef}
            size="small"
            className={`${styles['btn-action']} solid`}
            data-testid="btn-calendar-settings"
            onClick={openPopup}
          >
            <Icon size="20px" path={mdiCogOutline} />
          </IconButton>
          <Popper
            open={Boolean(popupAnchor)}
            anchorEl={popupAnchor}
            disablePortal
            placement="bottom"
            className={styles.popper}
          >
            <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={closePopup}>
              <Paper classes={{ root: styles.paper }}>
                {/** Empty slots toggle */}
                <ViewOptionSelect
                  label={<FormattedMessage id="post.actions.toggle-empty-slots-calendar" />}
                  value={withEmptySlots ? 'on' : 'off'}
                  options={onOffToggleOptions}
                  onChange={toggleEmptySlots}
                />

                {/** Virtual posts toggle */}
                <ViewOptionSelect
                  label={<FormattedMessage id="post.actions.toggle-virtual-posts" />}
                  value={virtualPostsVisible ? 'on' : 'off'}
                  options={onOffToggleOptions}
                  onChange={onToggleVirtualPostsVisible}
                />

                {/** History posts toggle */}
                <ViewOptionSelect
                  label={<FormattedMessage id="post.calendar.labels.toggle-posted" />}
                  value={withPosted ? 'on' : 'off'}
                  options={onOffToggleOptions}
                  onChange={onTogglePostedVisible}
                />

                {/** Posts size */}
                <ViewOptionSelect
                  label={<FormattedMessage id="post.calendar.labels.post-size" />}
                  value={postSize}
                  options={postSizeOptions}
                  onChange={updatePostSize}
                />

                {/** Week start */}
                <ViewOptionSelect
                  label={<FormattedMessage id="post.actions.toggle-week-start" />}
                  value={weekStart}
                  options={[{
                    label: <FormattedMessage id="calendar.days.sunday" />,
                    value: 'sunday'
                  }, {
                    label: <FormattedMessage id="calendar.days.monday" />,
                    value: 'monday'
                  }]}
                  onChange={onWeekStartChange}
                />

                {/** Month start */}
                <ViewOptionSelect
                  label={<FormattedMessage id="post.actions.toggle-month-start" />}
                  value={monthStart}
                  options={[{
                    label: <FormattedMessage id="post.calendar.labels.this-week" />,
                    value: 'this-week'
                  }, {
                    label: <FormattedMessage id="post.calendar.labels.first" />,
                    value: '1st'
                  }]}
                  onChange={onMonthStartChange}
                />
              </Paper>
            </ClickAwayListener>
          </Popper>
        </React.Fragment>
        {props.showPendingLink && (
          <Button
            className={styles['btn-pending']}
            startIcon={<Icon path={mdiBell} size="20px" />}
            onClick={goToPending}
          >
            You have Pending posts
          </Button>
        )}
      </div>
      <div className={styles['header-right']}>
        {typeof props.postsCount === 'number' && (
          <div className={styles.count}>
            <FormattedMessage id="post.planned.posts-count-label" values={{ count: props.postsCount }} />
          </div>
        )}
        <PPSelect
          name={<FormattedMessage id="post.label.preview" />}
          options={toggleOptions}
          selectedValue={props.withPreview ? 'on' : 'off'}
          className={styles.select}
          onSelectionChange={props.onTogglePreview}
        />
        <HelpToggleButton />
      </div>
    </div>
  )
}

interface ViewOptionSelectProps {
  label: React.ReactNode
  options: Array<{ label: React.ReactNode, value: string, disabled?: boolean }>
  value: string
  onChange: (value: string) => void
}

export function ViewOptionSelect({ label, options, value, onChange }: ViewOptionSelectProps) {
  const onClick = (value: string, disabled?: boolean) => () => {
    if (!disabled) {
      onChange(value)
    }
  }

  return (
    <div className={styles.option} data-testid="view-option-select">
      <div className={styles.label}>
        {label}:
      </div>
      <div className={styles.switch}>
        {options.map(o => {
          const active = o.value === value
          return (
            <div
              key={o.value}
              className={`${active ? styles['tab-active'] : ''} ${o.disabled ? styles.disabled : ''}`}
              onClick={onClick(o.value, o.disabled)}
            >
              {o.label}
              {active && <IconCheck color="primary" fontSize="small" className={styles.icon} />}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CalendarViewOptions
