import { StoreThunkAction } from 'store/state'
import { V1 } from './net'
import { stopImpersonateUser } from 'admin/services/actions'
import { legacyLogout } from './legacy/logout'
import { FacebookLoginResponse, IndexedObject, PPProduct } from 'interfaces'
import { tap } from 'rxjs/operators/tap'
import { checkSubscriptionSuccess } from 'services/users/actions'
import { message } from 'services/snackbar'
import { formatProducts, setProducts } from 'services/product'
import { impersonationSelector } from 'admin/services/selectors'
import { updateIntercom } from 'services/tracking/login'
import { HTTP_STATUS_UNAUTHORIZED } from 'shared/constants'
import { createAction } from '@reduxjs/toolkit'

const AUTO_LOGOUT_MESSAGE = 'Oops. An error occurred and you\'ve been logged out. Please log back in to continue.'
const AUTO_LOGOUT_MESSAGE_TIMEOUT = 3500
// eslint-disable-next-line no-magic-numbers
export const CHECK_TOKENS_INTERVAL_DEFAULT = 30 * 60 * 1000 // ms (30 minutes)

export * from './login/actions'

export const LOGOUT = '@auth/LOGOUT'
export type LogoutAction = { type: typeof LOGOUT }
export const logoutAction = createAction(LOGOUT)

export function logout(callback?: (e?: Error) => void): StoreThunkAction<void> {
  console.debug('logging out...')
  return (dispatch) => {
    dispatch(stopImpersonateUser())
    dispatch(updateIntercom())
    dispatch(legacyLogout())
      .subscribe(() => {
        if (callback) {
          callback()
        }
      }, (e: Error) => {
        console.log('[logout()] Error: ', e)
        if (callback) {
          callback(e)
        }
      })
    dispatch({ type: LOGOUT })
    dispatch(logoutAction())
  }
}

export const ACTION_CHECK_TOKENS_SUCCESS = 'ACTION_CHECK_TOKENS_SUCCESS'
export const ACTION_CHECK_TOKENS_FAILURE = 'ACTION_CHECK_TOKENS_FAILURE'
export const ACTION_SKIP_TOKENS_REFRESH = 'ACTION_SKIP_TOKENS_REFRESH'

export function skipTokensRefresh(dismissDialog?: boolean) {
  return {
    type: ACTION_SKIP_TOKENS_REFRESH,
    payload: { dismissDialog: Boolean(dismissDialog) }
  }
}

export const ACTION_SET_TRIAL_STATUS = 'ACTION_SET_TRIAL_STATUS'

export const setTrialStatus = createAction(
  ACTION_SET_TRIAL_STATUS,
  (payload: { trial: any, status: any, hasChargifyInfo: boolean, chargifySubscriptionId: any }) => ({ payload })
)

export function checkTokens(): StoreThunkAction<any> {
  return (dispatch, getState) => {
    const state = getState()
    const isImpersonating = impersonationSelector(state) !== undefined

    return dispatch(V1.checkTokens()).pipe(
      tap(
        (response: any) => {
          if (isImpersonating) {
            return
          }
          const products = formatProducts(response.subscriptions.products as PPProduct[])
          const user = response.user
          const trialEndsAt = user.account.trialEndsAt || user.account.trialEndedAt
          const productId = user.account.product
          const productInfo = products.find(p => p.id === productId)
          dispatch(checkSubscriptionSuccess(
            productId,
            productInfo as PPProduct,
            { isAdmin: Boolean(user.roles?.isAdmin), isBeta: Boolean(user.roles?.isBeta) },
            trialEndsAt
          ))
          dispatch(setTrialStatus({
            trial: user.account.trial,
            status: user.account.status,
            hasChargifyInfo: user.account.hasChargifyInfo,
            chargifySubscriptionId: user.account.chargifySubscriptionId
          }))
          const expiredTokens = Object.keys(user.tokensExpired).reduce((tokensMap: IndexedObject<any[]>, network: string) => {
            if (Array.isArray(user.tokensExpired[network])) {
              tokensMap[network] = user.tokensExpired[network]
            } else {
              tokensMap[network] = [{ name: network }]
            }

            return tokensMap
          }, {})
          dispatch({ type: ACTION_CHECK_TOKENS_SUCCESS, payload: expiredTokens })
          dispatch(setProducts(products))
        },
        error => {
          dispatch({ type: ACTION_CHECK_TOKENS_FAILURE, payload: error })
          if (error.status === HTTP_STATUS_UNAUTHORIZED) {
            // There's something wrong with user auth, log them out.
            dispatch(message(AUTO_LOGOUT_MESSAGE, 'error', AUTO_LOGOUT_MESSAGE_TIMEOUT))
          } else if (error.response.error.message) {
            dispatch(message(error.response.error.message, 'error', AUTO_LOGOUT_MESSAGE_TIMEOUT))
          }
          dispatch(logout())
        }
      )
    )
  }
}

export const ACTION_UPDATE_FB_LOGIN_STATUS = 'ACTION_UPDATE_FB_LOGIN_STATUS'
export function updateFacebookLoginStatus(status: FacebookLoginResponse) {
  return {
    type: ACTION_UPDATE_FB_LOGIN_STATUS,
    payload: status
  }
}
