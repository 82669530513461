export const MAX_VIDEO_SIZE_MB = 200
export const MAX_PHOTO_SIZE_MB = 10
export const MAX_GIF_SIZE_MB = 8
export const BYTES_IN_MB = 1e6
export const BYTES_IN_GB = 1e9
export const MAX_VIDEO_SIZE_BYTES = MAX_VIDEO_SIZE_MB * BYTES_IN_MB
export const MAX_PHOTO_SIZE_BYTES = MAX_PHOTO_SIZE_MB * BYTES_IN_MB
export const MAX_GIF_SIZE_BYTES = MAX_GIF_SIZE_MB * BYTES_IN_MB

export const FILE_FORMAT_GIF = 'image/gif'
export const ALLOWED_PHOTO_FORMATS = ['image/jpeg', 'image/pjpeg', 'image/png', 'image/x-png', 'image/gif', 'image/jpg']
export const ALLOWED_STATIC_PHOTO_FORMATS = ['image/jpeg', 'image/pjpeg', 'image/png', 'image/x-png', 'image/jpg']
export const ALLOWED_VIDEO_FORMATS = [
  'video/3g2',
  'video/3gp',
  'video/3gpp',
  'video/asf',
  'video/avi',
  'video/dat',
  'video/flv',
  'video/x-flv',
  'video/m4v',
  'video/x-m4v',
  'video/mkv',
  'video/mod',
  'video/mov',
  'video/mp4',
  'video/mpe',
  'video/mpeg',
  'video/mpeg4',
  'video/mpg',
  'video/nsv',
  'video/ogm',
  'video/ogv',
  'video/qt',
  'video/tod',
  'video/vob',
  'video/wmv',
  'video/quicktime',
  'video/x-ms-wmv',
  'video/webm',
  'video/x-matroska' // mime type for mkv files
]

export const ALLOWED_BULK_EXTENSIONS = [
  '.xls',
  '.xlsx',
  '.csv'
]

export const INPUT_ACCEPT_BULK = 'application/vnd.ms-excel,.xls,.xlsx,.csv'
export const INPUT_ACCEPT_ALL = '*'
export const INPUT_ACCEPT_VIDEO = ALLOWED_VIDEO_FORMATS.map(format => format.replace('video/', '.'))
export const INPUT_ACCEPT_PHOTO = ALLOWED_PHOTO_FORMATS.map(format => format.replace('image/', '.'))
export const INPUT_ACCEPT_PHOTO_STATIC = ALLOWED_STATIC_PHOTO_FORMATS.map(format => format.replace('image/', '.'))
export const INPUT_ACCEPT_GIF = '.gif'
export const INPUT_ACCEPT_ALL_MEDIA = ([] as string[]).concat(INPUT_ACCEPT_PHOTO, INPUT_ACCEPT_VIDEO).join(',')

export const PHOTO_ROTATION_TOGGLE: { [deg: string]: { next: string } } = {
  '0': { next: '90' },
  '90': { next: '180' },
  '180': { next: '270' },
  '270': { next: '0' }
}

export const FILE_SIZE_LIMITS_COMPOSER = {
  photos: MAX_PHOTO_SIZE_BYTES,
  videos: MAX_VIDEO_SIZE_BYTES,
  gifs: MAX_GIF_SIZE_BYTES
}

export const HALF_ROUND_DEGREES = 180
export const CHUNK_SIZE_DEFAULT = 0.25 * BYTES_IN_MB // eslint-disable-line no-magic-numbers
export const DEFAULT_STUDIO_EXPORT_FILE_NAME = 'photoeditor-export.jpg'
