import React from 'react'
import { URL_SEGMENT_TO_CONTENT_TYPE_MAP, CONTENT_TYPE_TO_URL_SEGMENT_MAP } from 'adapters'
import { AnyContent, ContentItem, FilterType, RangeFilter, Stream, VALID_RANGE_FILTERS, WEEK_RANGE_FILTER } from 'interfaces'
import { useLocation, useMatch, useNavigate, Navigate } from 'react-router-dom'
import StreamViewNavigation from './StreamViewNavigation'
import * as FilterStore from 'utils/filter-store'
import StreamFeedsNavigation from './StreamFeedsNavigation'
import StreamBricksView, { BricksSize, ScrollConfiguration } from './StreamBricksView'
import { useSelector } from 'react-redux'
import { userProductSelector } from 'services/users/selectors'
import { rangeFeatureMap } from 'services/product'

export function StreamRoute(props: {
  stream: Stream,
  navigation: StreamViewNavigation,
  sizes?: BricksSize[],
  align?: 'left' | 'center',
  itemWidth?: number,
  scroll?: ScrollConfiguration,
  isSavedStream: boolean,
  isMyStreamsView: boolean,
  streamIds: string[],
  renderStreamRedirect?: (pathname: string) => React.ReactNode,
  onItemPinned?(stream: Stream, content: AnyContent): void,
  onItemShared?(stream: Stream, content: AnyContent): void | boolean,
  onFilterChanged?(stream: Stream, filter: FilterType): void,
  onRangeChanged?(stream: Stream, range: RangeFilter): void,
  onGotoClicked?(stream: Stream, content: AnyContent, by: string): void,
  onContentItemClick?(item: ContentItem): void
}) {
  const location = useLocation()
  const match = useMatch(`${props.navigation.prefix}/:type/:range`)
  const navigate = useNavigate()
  const product = useSelector(userProductSelector)
  const features = product?.features || {}
  const isInitialMount$ = React.useRef(true)

  const resolveStreamFilters = () => {
    const storedFilters = FilterStore.getStreamFilters(props.stream)
    const type = URL_SEGMENT_TO_CONTENT_TYPE_MAP[match?.params.type || ''] || storedFilters.type
    let range = match?.params.range || storedFilters.range

    const currentRangeFeatureKey = rangeFeatureMap[range]
    const shouldUpdate = features[currentRangeFeatureKey] === false

    if (shouldUpdate && isInitialMount$.current) {
      range = VALID_RANGE_FILTERS.find(r => features[rangeFeatureMap[r]]) as RangeFilter || WEEK_RANGE_FILTER
      isInitialMount$.current = false
    }

    return {
      type: FilterStore.assureValidType(type),
      range: FilterStore.assureValidRange(range)
    }
  }

  const streamId = props.stream.id
  const originalId = props.stream.originalId
  const { type, range } = resolveStreamFilters()
  const requestedType = URL_SEGMENT_TO_CONTENT_TYPE_MAP[match?.params.type || '']

  React.useEffect(() => {
    FilterStore.setStreamFilters(streamId, type, range)
  }, [range, streamId, type])

  const contentTypes = props.stream.filters.length !== 0 ? props.stream.filters : FilterStore.DEFAULT_STREAM_TYPES

  const onRangeChange = (stream: Stream, r: RangeFilter) => {
    if (props.onRangeChanged) {
      props.onRangeChanged(stream, r)
    }
    const nextPath = match?.pattern.path.replace(':type', CONTENT_TYPE_TO_URL_SEGMENT_MAP[type]).replace(':range', r) + location.search
    navigate(nextPath, { state: location.state })
    return true
  }

  const onFilterChange = (f: FilterType) => {
    if (props.onFilterChanged) {
      props.onFilterChanged(props.stream, f)
    }
    const nextPath = match?.pattern.path.replace(':type', CONTENT_TYPE_TO_URL_SEGMENT_MAP[f]).replace(':range', range) + location.search
    navigate(nextPath, { state: location.state })
  }

  // If it's a not-owned private stream, we want to redirect to the home page
  if (props.stream.isPrivate && !props.isSavedStream) {
    return <Navigate to="/content" replace />
  }

  if (!match || !match.params.type || !match.params.range || requestedType !== type || match.params.range !== range) {
    const pathname = `${props.navigation.prefix}/${CONTENT_TYPE_TO_URL_SEGMENT_MAP[type]}/${range}`
    if (props.renderStreamRedirect) {
      return props.renderStreamRedirect(pathname) as any
    }
    return (
      <Navigate
        replace
        to={{ pathname, search: location.search }}
        state={location.state}
      />
    )
  }

  // EXPL: It's not user owned stream. Redirect to normal stream view
  if (props.isMyStreamsView && props.streamIds.indexOf(streamId) === -1) {
    return <Navigate to={`/content/streams/${originalId || streamId}`} replace />
  }

  return (
    <React.Fragment>
      <StreamFeedsNavigation
        stream={props.stream}
        prefix={props.navigation.prefix}
        search={location.search}
        linkToUserFeedId={props.isMyStreamsView}
      />
      <StreamBricksView
        stream={props.stream}
        items={[]}
        filter={type}
        range={range}
        contentTypes={contentTypes}
        align={props.align}
        sizes={props.sizes}
        itemWidth={props.itemWidth}
        scroll={props.scroll}
        onItemShared={props.onItemShared}
        onItemPinned={props.onItemPinned}
        onRangeChanged={onRangeChange}
        onFilterChanged={onFilterChange}
        onGotoClicked={props.onGotoClicked}
        onContentItemClick={props.onContentItemClick}
      />
    </React.Fragment>
  )
}
