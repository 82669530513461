import * as React from 'react'
import { PlannedPost, PostedPost, VIDEO_TYPE } from 'interfaces'
import styles from './MiniPostView.pcss'
import { PostContentTypeIcon } from 'components/Posts/PostContentTypeIcon'
import { MiniProfileAvatar } from 'components/ProfileAvatar'
import Tooltip from '@mui/material/Tooltip'
import { FormattedMessage } from 'react-intl'
import { NativeVideoPlayer } from 'components/VideoPlayer'
import { PlayIcon } from 'components/Icons'
import { CalendarPostSize } from 'services/post/state'
import { getImagesUrlsMap } from 'shared'
import { RecycleBadge } from '../RecycleBadge'
import { COLLAPSE_TEXT_LABEL_FB, TRUNCATE_MAX_LINES_FB, TRUNCATE_TEXT_FB } from 'components/PostPreview/components/FacebookPostPreview'
import PostPreviewText from 'components/PostPreview/components/PostPreviewText'

export interface MiniPostViewProps {
  post: PlannedPost | PostedPost
  size?: CalendarPostSize
  className?: string
  draggableProps?: {
    attributes: any
    listeners: any
    style: any
    isDragging: boolean
  }
  onClick?: (post: PlannedPost | PostedPost) => void
}

const Post = React.forwardRef((props: MiniPostViewProps, ref?: React.ForwardedRef<any>) => {
  const { post, draggableProps, onClick } = props
  const size: CalendarPostSize = props.size || 'small'
  const imageUrlsMap = React.useMemo(() => getImagesUrlsMap(props.post.images), [props.post.images])

  const onImageError = React.useCallback((e: any) => {
    e.target.src = imageUrlsMap[e.target.src]
  }, [imageUrlsMap])

  const handleClick = React.useCallback(() => {
    if (onClick) {
      onClick(post)
    }
  }, [post, onClick])

  const contentType = post.contentType
  const postType = post.specialType || contentType
  const classLarge = size === 'large' ? styles.lg : ''
  const classMedium = size === 'medium' ? styles.md : ''
  const sizeClass = classLarge || classMedium
  const postedClass = props.post.isPosted ? styles.posted : ''
  const padClass = props.post.bucket ? styles['w-padding'] : ''
  const emptyClass = props.post.isEmpty ? styles.empty : ''
  const virtualClass = (post as any).isVirtual ? styles.virtual : ''

  return (
    <div
      className={`${styles.post} ${sizeClass} ${postedClass} ${padClass} ${emptyClass} ${virtualClass} ${props.className || ''}`}
      ref={ref}
      {...draggableProps?.attributes}
      {...draggableProps?.listeners}
      style={draggableProps?.style}
      data-testid={`mini-post${props.post.isEmpty ? '-empty' : ''}`}
      onClick={handleClick}
    >
      {props.post.bucket && (
        <Tooltip title={`Bucket: ${props.post.bucket.name}`} placement="top">
          <div className={styles['bucket-indicator']} style={{ backgroundColor: props.post.bucket.color }}></div>
        </Tooltip>
      )}
      <div className={styles.content}>
        <div className={styles.time}>
          {props.post.timeString.replace(' ', '').toLowerCase()}
          {props.post.baseTime && ' ish'}
        </div>
        {!props.post.autoPost && props.post.repeatLength > 0 && (
          <RecycleBadge
            recycle={props.post.recycle}
            autoPost={props.post.autoPost}
            repeatLength={props.post.repeatLength}
            repeatPeriod={props.post.repeatPeriod}
            repeatUntil={props.post.repeatUntil}
            className={styles['r-badge']}
          />
        )}
        <MiniProfileAvatar
          imageUrl={props.post.profilePictureUrl}
          network={props.post.network}
          tooltip={props.post.name}
          className={styles.avatar}
        />
        {!props.post.isEmpty && (
          <Tooltip title={<FormattedMessage id={`label.generic.${postType}-post`} />}>
            <div className={styles.type}>
              <PostContentTypeIcon className={styles.icon} type={postType as any} />
            </div>
          </Tooltip>
        )}
        {Boolean(props.post.recycle) && (
          <RecycleBadge
            recycle={props.post.recycle}
            autoPost={props.post.autoPost}
            repeatLength={props.post.repeatLength}
            repeatPeriod={props.post.repeatPeriod}
            repeatUntil={props.post.repeatUntil}
            className={styles['r-badge']}
          />
        )}
      </div>
      {size !== 'small' && (
        <div className={`${styles['content-expanded']} ${styles[contentType]}`}>
          {contentType === 'photos' && (
            <div className={styles['img-box']}>
              <img src={Object.keys(imageUrlsMap)[0]} onError={onImageError} />
            </div>
          )}
          {contentType === 'status' && !post.details?.textBackground && (
            <div className={styles.status}>{post.status}</div>
          )}
          {post.details?.textBackground && (
            <div className={styles['img-box']}>
              <PostPreviewText
                text={post.status}
                network="facebook"
                expandLabel={TRUNCATE_TEXT_FB}
                collapseLabel={COLLAPSE_TEXT_LABEL_FB}
                maxLinesDefault={TRUNCATE_MAX_LINES_FB}
                className={styles['txt-bg']}
                backgroundImageUrl={post.details.textBackground.backgroundUrl}
                color={post.details.textBackground.color}
              />
            </div>
          )}
          {contentType === 'articles' && (
            <div>
              <div className={`${styles['img-box']} ${styles['img-article']}`}>
                <img src={props.post.images[0]} />
              </div>
              {props.post.linkDomain && (
                <span className={`${styles.link} text-ellipsis`}>
                  {props.post.linkDomain}
                </span>
              )}
              <h4 className={styles.title} title={props.post.linkTitle}>
                {props.post.linkTitle}
              </h4>
            </div>
          )}
          {contentType === VIDEO_TYPE && props.post.nativeVideoUrl && (
            <div className={styles['video-box']}>
              <NativeVideoPlayer
                videoUrl={props.post.nativeVideoUrl}
                loop
                playBtnClassName={styles['btn-play']}
                className={styles.video}
              />
            </div>
          )}
          {props.post.fbVideoUrl && (
            <div className={`${styles['img-box']} ${styles['video-box']}`}>
              <img src={props.post.images[0]} />
              <PlayIcon className={styles['btn-play']} />
            </div>
          )}
        </div>
      )}
    </div>
  )
})
export const MiniPostView = React.memo(Post, (prevProps, nextProps) => prevProps.size === nextProps.size)
export default MiniPostView
