import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { WithIntl } from 'interfaces'
import { injectIntl } from 'react-intl'

const LOADER_MAX_VALUE = 100

export interface FileLoaderProps extends WithIntl {
  file: File
  withProgress?: boolean
  className?: string
  onLoaded: (fileSrc: string) => void
  onError: (error: string) => void
}

export const FileLoader = React.memo(function FileLoader(props: FileLoaderProps) {
  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    const reader = new FileReader()

    reader.onprogress = (p) => {
      const loaded = (p.loaded / p.total) * LOADER_MAX_VALUE
      if (progress !== Math.ceil(loaded)) {
        requestAnimationFrame(() => {
          setProgress(loaded)
        })
      }
    }

    reader.onerror = () => {
      props.onError(props.intl.formatMessage({ id: 'file.messages.read-error' }))
    }

    reader.onload = () => {
      requestAnimationFrame(() => {
        setProgress(LOADER_MAX_VALUE)
      })

      props.onLoaded(reader.result as string)
    }

    reader.readAsDataURL(props.file)
  }, [props.file])

  return props.withProgress ? (
    <CircularProgress variant="determinate" value={progress} className={props.className || ''} />
  ) : null
})

export default injectIntl(FileLoader)
