import produce from 'immer'
import { IndexedObject } from 'interfaces'
import React, { createContext } from 'react'
import { sortByKeyAscending } from 'utils/sort/order'
const ID_LENGTH = 8
export interface FileState {
  id: string
  file?: File
  isVideo?: boolean
  scene?: string
  url?: string
  error?: string
  isUploading?: boolean
  isFeatured?: boolean
  isEdited?: boolean
  order?: number
  orientation?: 'landscape' | 'portrait'
  upload?: { abort: (terminate?: boolean) => void }
}

export type UploaderFilesState = IndexedObject<FileState>

export const ComposerUploaderContext = createContext<{ files: UploaderFilesState, dispatch: React.Dispatch<any> }>({
  files: {}, dispatch: (_action: any) => {}
})

const ACTION_ADD_FILES = 'ACTION_ADD_FILES'
export function addFiles(files: Array<Omit<FileState, 'id'>>) {
  return {
    type: ACTION_ADD_FILES,
    payload: files
  }
}

const ACTION_REMOVE_FILE = 'ACTION_REMOVE_FILE'
export function removeFile(id: string) {
  return {
    type: ACTION_REMOVE_FILE,
    payload: id
  }
}

const ACTION_UPDATE_FILE = 'ACTION_UPDATE_FILE'
export function updateFileState(id: string, nextState: Partial<FileState>) {
  return {
    type: ACTION_UPDATE_FILE,
    payload: { id, nextState }
  }
}

const ACTION_RESET = 'ACTION_RESET'
export function resetState() {
  return {
    type: ACTION_RESET
  }
}

export function uploaderFilesReducer(state: UploaderFilesState, action: { type: string, payload?: any }): UploaderFilesState {
  switch (action.type) {
    case ACTION_ADD_FILES: {
      const filesArray = Object.values(state)
      let lastIndex = filesArray.length > 0 ? Math.max(...Object.values(state).map(file => file.order || 1)) : 0
      const filesMap = action.payload.reduce((map: IndexedObject<FileState>, file: FileState) => {
        lastIndex += 1
        const id = Math.random().toPrecision(ID_LENGTH).split('.')[1]
        map[id] = { ...file, id, order: lastIndex }
        return map
      }, {})
      return {
        ...state,
        ...filesMap
      }
    }

    case ACTION_REMOVE_FILE: {
      const next = produce(state, draft => {
        delete draft[action.payload]
        Object.values(draft).sort(sortByKeyAscending('order')).forEach((file: FileState, index) => {
          file.order = index + 1
        })
      })

      return next
    }

    case ACTION_UPDATE_FILE: {
      const id = action.payload.id
      return {
        ...state,
        [id]: {
          ...state[id],
          ...action.payload.nextState
        }
      }
    }

    case ACTION_RESET:
    default:
      return {}
  }
}
