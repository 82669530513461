import { V1 } from './net'
import { PostDestination } from 'interfaces'
import StoreState from 'store/state'
import { BackgroundTemplate, FBAlbum } from '../interfaces/ComposerFacebookPost'
import { ActionReducerMapBuilder, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { facebookBackgroundTemplatesSelector } from '../selectors'
import ComposerState, { POST_TYPE_STORY } from '../state'
import { WritableDraft } from 'immer/dist/internal'

export const facebookReducers = {
  toggleFBPostReshare: (state: WritableDraft<ComposerState>) => {
    state.posts.facebook.reshare = !state.posts.facebook.reshare
  },
  selectComposerFBAlbum: (
    state: WritableDraft<ComposerState>,
    action: PayloadAction<{ profile: PostDestination, album: FBAlbum | undefined }>
  ) => {
    const { profile, album } = action.payload
    const index = state.posts.facebook.albums.findIndex(a => a.ppid === profile.ppid)
    if (index !== -1) {
      state.posts.facebook.albums.splice(index, 1)
    }
    if (album) {
      state.posts.facebook.albums.push(album)
    }
  },
  setFbFirstComment: (state: WritableDraft<ComposerState>, action: PayloadAction<string>) => {
    state.posts.facebook.firstComment = action.payload
  },
  setFBPostType: (state: WritableDraft<ComposerState>, action: PayloadAction<string | undefined>) => {
    state.posts.facebook.postType = action.payload
    state.posts.facebook.reshare = action.payload === POST_TYPE_STORY ? false : state.posts.facebook.reshare
  },
  setFacebookTextBackground: (state: WritableDraft<ComposerState>, action: PayloadAction<BackgroundTemplate | undefined>) => {
    state.posts.facebook.textBackground = action.payload
  }
}

export const facebookExtraReducers = (builder: ActionReducerMapBuilder<ComposerState>) => {
  builder.addCase(getFacebookPostTemplates.fulfilled, (state, action) => {
    state.facebookBackgroundTemplates = action.payload
  })
}

export const getFacebookPostTemplates = createAsyncThunk(
  'composer/facebook/getPostTemplates',
  async (force: boolean = false, { dispatch, getState }) => {
    if (!force) {
      const cached = facebookBackgroundTemplatesSelector(getState() as StoreState)
      if (cached.length > 0) {
        return cached
      }
    }
    const response = await dispatch(V1.getTemplates()).toPromise()
    return response
  }
)
