import * as React from 'react'
import CalendarMonthView from 'components/CalendarView/CalendarMonthView'
import styles from './PostsCalendarView.pcss'
import SimpleLoader from 'components/SimpleLoader'
import Backdrop from '@mui/material/Backdrop'
import { CalendarDay } from 'interfaces/Post/Calendar'
import { PlannedPost } from 'interfaces'
import CalendarWeekView from 'components/CalendarView/components/CalendarWeekView'
import { useDispatch, useSelector } from 'react-redux'
import {
  calendarMonthStartSelector,
  calendarPostSizeSelector,
  calendarWeekStartSelector,
  postsWeekViewExpandedSelector,
  selectedProfilesSelector
} from 'services/post/selectors'
import { setWeekViewExpanded } from 'services/post'
import { DndContext, MeasuringStrategy, useSensors, PointerSensor, useSensor } from '@dnd-kit/core'
import { IPostGroup, PostsCalendarDay } from './PostsCalendarDay'
import { message } from 'services/snackbar'
import { DraggableCalendarPost } from './PostsCalendarDay/PostsCalendarDay'
import { formatInTimeZone } from 'date-fns-tz'

export const INVALID_DROP_MESSAGE = `Oops!.. You can't drop your post there. Please drag it to a valid time slot outlined in green.`
export const DRAG_DROP_WARNING = 'Drag-and-drop re-ordering is only available when a single profile is selected.'
  + ' Please double-click any profile in the sidebar to select it alone. Then try again.'
const SNACK_DURATION_LONG = 5000

interface PostsCalendarViewProps {
  mode: 'month' | 'week'
  posts: PlannedPost[]
  year: number
  monthIndex: number
  loading: boolean
  activePostId?: string
  className?: string
  selection: WeekSelection
  onPostClick: (post: PlannedPost) => void
  onPostOrderChanged?: (movedPost: PlannedPost, targetPost: PlannedPost) => void
}

export type WeekSelection = {
  date: Date
  offset: number
}

export function PostsCalendarView(props: PostsCalendarViewProps) {
  const { posts, onPostOrderChanged } = props
  const dispatch = useDispatch()
  const weekViewExpanded = useSelector(postsWeekViewExpandedSelector)
  const weekStartDay = useSelector(calendarWeekStartSelector)
  const monthStart = useSelector(calendarMonthStartSelector)
  const postSize = useSelector(calendarPostSizeSelector)
  const selectedProfiles = useSelector(selectedProfilesSelector)
  const [movingPost, setMovingPost] = React.useState<PlannedPost | null>(null)
  const [postsByDay, setPostsByDay] = React.useState<Record<string, IPostGroup>>({})
  const [postsMap, setPostsMap] = React.useState<Record<string, PlannedPost & { index: number }>>({})

  React.useEffect(() => {
    let order = 0
    let index = 0
    const postIndex: Record<string, PlannedPost & { index: number }> = {}
    const grouped = props.posts.reduce((groups: { [title: string]: IPostGroup }, post: PlannedPost) => {
      if (!post.isEmpty && !post.isPosted) {
        postIndex[post.id] = { ...post, index: index++ }
      }

      const postTime = formatInTimeZone(post.time, post.timezone, 'M-d-yyyy', { timeZone: post.timezone })
      const group = groups[postTime]
      if (!group) {
        groups[postTime] = {
          posts: [post],
          order: order + 1,
          date: postTime
        }
        order += 1
      } else {
        group.posts.push(post)
      }
      return groups
    }, {})
    setPostsMap(postIndex)
    setPostsByDay(grouped)
  }, [props.posts])

  const toggleWeekViewExpanded = React.useCallback(() => {
    dispatch(setWeekViewExpanded(!weekViewExpanded))
  }, [weekViewExpanded, dispatch])

  const renderPostsForTime = React.useCallback((day: CalendarDay, hour?: number) => {
    const postsForTime = props.posts.filter(post => {
      const postTime = formatInTimeZone(post.time, post.timezone, 'd')

      const postDate = parseInt(postTime, 10)
      return postDate === day.date.getDate() && (typeof hour === 'undefined' || post.hoursLocal === hour)
    })

    if (postsForTime.length === 0) {
      return null
    }

    return postsForTime.map(post => {
      const isActive = props.activePostId === post.id
      let dndClass: string = ''
      if (
        movingPost
        && movingPost.profileId === post.profileId
        && movingPost.id !== post.id
        && movingPost.bucketId === post.bucketId
      ) {
        dndClass = styles['drop-target']
      }
      return (
        <DraggableCalendarPost
          key={post.id}
          post={post}
          size={postSize}
          containerClassName={dndClass}
          className={`${styles['mini-post']} ${isActive ? styles.active : ''}`}
          onClick={props.onPostClick}
        />
      )
    })
  }, [props.activePostId, props.onPostClick, props.posts, postSize, movingPost])

  const renderPostsForDay = React.useCallback((day: CalendarDay) => {
    const date = day.date.getDate()
    const month = day.date.getMonth() + 1
    const year = day.date.getFullYear()
    const group = postsByDay[`${month}-${date}-${year}`] || {
      posts: [],
      order: 0,
      date: `${month}-${date}`
    }

    return (
      <PostsCalendarDay
        day={day}
        postGroup={group}
        withDragAndDrop={props.mode === 'month'}
        draggedPost={movingPost}
        postSize={postSize}
        activePostId={props.activePostId}
        onPostClick={props.onPostClick}
      />
    )
  }, [postsByDay, props.mode, props.activePostId, props.onPostClick, movingPost, postSize])

  const onDragEnd = React.useCallback((event: any) => {
    if (selectedProfiles.length > 1) {
      return
    }
    if (props.mode === 'week') {
      const fromPost = postsMap[movingPost?.id || '']
      const toPost = postsMap[event.over?.data?.current?.post?.id || '']
      setMovingPost(null)
      if (
        !toPost
        || !toPost.autoPost
        || toPost.profileId !== fromPost.profileId
        || fromPost?.bucketId !== toPost?.bucketId
      ) {
        dispatch(message(INVALID_DROP_MESSAGE, 'warning', SNACK_DURATION_LONG, true))
        return
      }
      if (onPostOrderChanged) {
        onPostOrderChanged(fromPost, toPost)
      }
      return
    }
    const { active, over } = event
    const draggedPost = movingPost
    setMovingPost(null)
    if (!over) {
      return
    }

    if (draggedPost && active.id !== over.id) {
      const activeContainer = active.data.current.sortable.containerId
      const overContainer = over.data.current?.sortable?.containerId || over.id
      const activeIndex = active.data.current.sortable.index

      const originalPostGroup = postsByDay[activeContainer]
      const destinationPostGroup = postsByDay[overContainer]

      if (!destinationPostGroup) {
        dispatch(message(INVALID_DROP_MESSAGE, 'warning', SNACK_DURATION_LONG))
      }

      let overIndex = over.data.current.type === 'container'
        ? over.data.current.group.posts.length
        : over.data.current?.sortable?.index || 0

      const isMovingForward = originalPostGroup.order < destinationPostGroup.order
        || (originalPostGroup.order === destinationPostGroup.order && overIndex > activeIndex)

      if (over.data.current.type === 'container' && isMovingForward) {
        overIndex = destinationPostGroup.posts.length - 1
      }

      const targetSlot = destinationPostGroup.posts[overIndex]
      const targetPost = posts.find(p => p.id === targetSlot?.id) as PlannedPost

      // EXPL: Check if post is dropped at a valid slot:
      //  - not on a scheduled post (not autoPost)
      //  - not on post of different profile
      //  - slots buckets not matching
      if (
        !targetSlot
        || !targetSlot.autoPost
        || targetSlot.profileId !== draggedPost.profileId
        || draggedPost?.bucketId !== targetPost?.bucketId
      ) {
        dispatch(message(INVALID_DROP_MESSAGE, 'warning', SNACK_DURATION_LONG, true))
        return
      }

      if (!draggedPost || !targetPost) {
        console.error('[onDragEnd()] Error: Source or target Post not found.')
        return
      }

      // Check if the slot allows the post type
      if (targetPost.buckets.includes(draggedPost.contentType)) {
        if (onPostOrderChanged) {
          onPostOrderChanged(draggedPost, targetPost)
        }
      }
    }
  }, [dispatch, movingPost, onPostOrderChanged, posts, postsByDay, selectedProfiles.length, props.mode, postsMap])

  const onDragStart = (start: any) => {
    if (selectedProfiles.length > 1) {
      dispatch(message(DRAG_DROP_WARNING, 'info', SNACK_DURATION_LONG))
    } else {
      setMovingPost(start.active?.data?.current?.post || null)
    }
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8
      }
    })
  )

  return (
    <DndContext
      sensors={sensors}
      measuring={{ strategy: MeasuringStrategy.Always }}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
    >
      <div className={props.className || ''}>
        {props.mode === 'month' ? (
          <CalendarMonthView
            month={props.monthIndex}
            year={props.year}
            startDay={weekStartDay}
            monthStart={monthStart}
            renderDay={renderPostsForDay}
            className={styles['calendar-month']}
          />
        ) : (
          <CalendarWeekView
            referenceDate={props.selection.date}
            expanded={weekViewExpanded}
            isEmpty={props.posts.length === 0}
            className={styles['calendar-week']}
            startDay={weekStartDay}
            renderCell={renderPostsForTime}
            highlightCurrentDay
            onToggleExpanded={toggleWeekViewExpanded}
          />
        )}
        <Backdrop open={props.loading} className={styles.backdrop}>
          <SimpleLoader />
        </Backdrop>
      </div>
    </DndContext>
  )
}
