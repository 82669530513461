import ApiException, { Tags } from './ApiException'
export { Tags }
export class SerializationException extends ApiException {
  constructor(message: string, tags?: Tags) {
    super(message, tags)

    Object.setPrototypeOf(this, SerializationException.prototype)
  }
}

export default SerializationException
