import { Article, ARTICLE_TYPE, Paginated, AnyContent, LegacyArticle, FEED_TYPE_KEYWORD } from 'interfaces'
import { TYPE_FEED_TYPE_MAP } from './mappings'
import mapAvatar from './avatar'
import { parseDate } from 'utils/format/date'
import { SortBy } from '../util'

const BASE_TEN = 10

export function articleAdapter(article: LegacyArticle): Article {
  const feedType = !article.feed ? FEED_TYPE_KEYWORD : TYPE_FEED_TYPE_MAP[article.feed?.type]
  const feedId = article.feed?.id?.toString()
  const shares = article.shares || 0
  const sharesPerNetwork = {
    facebook: article.sharesPerNetwork?.facebookShareCount || null,
    twitter: article.sharesPerNetwork?.twitterShareCount || null,
    pinterest: article.sharesPerNetwork?.pinterestShareCount || null,
    linkedin: article.sharesPerNetwork?.linkedinShareCount || null,
    reddit: article.sharesPerNetwork?.redditShareCount || null,
    totalShares: article.sharesPerNetwork?.totalShareCount || 0
  }

  return {
    id: `${feedType}:${article.id}`,
    rawId: article.id + '',
    hash: article.searchHash,
    title: article.title,
    type: ARTICLE_TYPE,
    description: article.description,
    socialLink: article.socialLink,
    shortLink: article.feed?.shortLink,
    sourceLink: article.sourceLink,
    createdAt: parseDate(article.createdAt || new Date()),
    comments: 0, // articles does not have comments info
    imageUrl: article.imageUrl,
    likes: article.likes,
    rating: article.rating / BASE_TEN,
    shares,
    live: article.live,
    scrape: article.scrape,
    isFavorite: article.isFavorited,
    tweetText: article.tweetTitle,
    feed: {
      isFeed: article.feed ? true : false,
      id: feedId,
      uniqueSource: feedId,
      image: article.feed ? article.feed.image || mapAvatar(feedType, article.feed) : '',
      name: article.feed?.name,
      type: feedType,
      sources: article.feed?.sources,
      order: 0,
      title: article.feed?.title,
      handle: article.feed?.handle,
      networkId: article.feed?.networkId
    },
    favCount: article.favCount || 0,
    sharesPerNetwork,
    status: article.status || article.tweetTitle
  }
}

export function articlesAdapter(articles: LegacyArticle[]): Paginated<Article[]> {
  return {
    items: articles.map(articleAdapter),
    hasNext: articles.length !== 0
  }
}

/**
 * Removes duplicated articles from array, adding them to the "duplicates" array of the first item with the given key
 *
 * @export
 * @param {Article[]} articles
 * @returns {Article[]}
 */
export function dedupeArticles(articles: Article[]): Article[] {
  const groupByKey = 'sourceLink'
  const articlesMap = articles.reduce((grouped: { [key: string]: Article }, item: Article) => {
    const key = item[groupByKey]
    if (!grouped[key]) {
      grouped[key] = { ...item, duplicates: [] }
    } else {
      (grouped[key].duplicates as any[]).push(item)
    }
    return grouped
  }, {})

  return Object.values(articlesMap)
}

/**
 * Removes duplicated articles from mixed content array,
 * adding them to the "duplicates" array of the first article with the given key
 *
 * @export
 * @param {AnyContent[]} articles
 * @returns {AnyContent[]}
 */
export function dedupeMixedArticles(items: AnyContent[]): AnyContent[] {
  const groupByKey = 'sourceLink'
  const articlesMap = items.reduce((grouped: { [key: string]: AnyContent }, item: AnyContent) => {
    if (item.type !== ARTICLE_TYPE) {
      grouped[item.id] = item
      return grouped
    }

    const key = (item as any)[groupByKey]
    if (!grouped[key]) {
      grouped[key] = { ...item, duplicates: [] }
    } else {
      ((grouped[key] as Article).duplicates as any[]).push(item)
    }
    return grouped
  }, {})

  return Object.values(articlesMap)
}
