import IconButton from '@mui/material/IconButton'
import IconDelete from '@mui/icons-material/Close'
import { Article, MyFile, Photo, VIDEO_TYPE } from 'interfaces'
import React from 'react'
import { SelectableContent } from 'services/content/state'
import styles from './MiniContentCard.pcss'
import { PlayIcon } from 'components/Icons'
import { STOCK_VIDEO_TYPE } from 'interfaces/Content/StockContentTypes'
import { instanceOfMyFile } from 'services/content/util'
import { NativeVideoPlayer } from 'components/VideoPlayer'

const ICON_PLAY_SIZE = 40
const ICON_PLAY_SIZE_SMALL = 28

interface MiniContentCardProps {
  content: SelectableContent
  onRemove: (id: string) => void
}

export function MiniContentCard(props: MiniContentCardProps) {
  const { content, onRemove } = props
  const imageUrl = (content as Photo).thumbnailUrl || (content as Photo).imageUrl || (content as MyFile).thumbUrl

  const style = { backgroundImage: `url(${imageUrl})` }
  const isVideo = content.type === VIDEO_TYPE || content.type === STOCK_VIDEO_TYPE
  const title = (content as Article).title
  const isVideoFile = instanceOfMyFile(content) && content.type === VIDEO_TYPE

  const remove = () => {
    onRemove(content.id)
  }

  return (
    <div className={styles.card} style={style}>
      <IconButton size="small" className={`${styles['btn-delete']} solid`} onClick={remove}>
        <IconDelete fontSize="small" />
      </IconButton>
      {isVideo && !isVideoFile && (
        <PlayIcon
          size={title ? ICON_PLAY_SIZE_SMALL : ICON_PLAY_SIZE}
          className={`${styles['icon-play']} ${title ? styles.translate : ''}`}
        />
      )}
      {isVideoFile && (
        <div className={styles.video}>
          <NativeVideoPlayer muted videoUrl={content.url} playBtnClassName={styles['btn-play']} />
        </div>
      )}
      {title && (
        <h3 className={styles.title}>{title}</h3>
      )}
    </div>
  )
}

export default MiniContentCard
