import { FailedPost, ContentType } from 'interfaces'
import { StoreThunkAction, StoreThunkDispatch } from 'store/state'
import { Observable } from 'rxjs/Observable'
import { V1 } from './net'
import { postsResponseAdapter } from '../adapters/posts'
import { mapPostsToBuckets } from './mapPostsToBuckets'
import { createAsyncThunk } from '@reduxjs/toolkit'

/** FETCH POSTS ACTIONS */
type FailedResponse = { posts: FailedPost[], page: number, totalPostsCount: number }
export function getFailedPosts(
  page: number, pageIds: string[], ppids?: string[], query?: string, types?: ContentType[], searchInArticleSummary?: boolean

): StoreThunkAction<Observable<FailedResponse>> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.getFailedPosts(page, pageIds, ppids, query, types, searchInArticleSummary))
      .map(response => {
        const posts = postsResponseAdapter<FailedPost>(response)
        return {
          posts: dispatch(mapPostsToBuckets(posts)),
          page,
          totalPostsCount: response.postsCount
        }
      })
  }
}

export const getFailedPostsCount = createAsyncThunk(
  'post/failed/getCount',
  async (ppids: string[] = [], { dispatch, rejectWithValue }) => {
    try {
      const response = await dispatch(V1.getFailedPostsCount(ppids)).toPromise()
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
