import { createElement, Component, ReactElement } from 'react'
import { connect } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import Loader from 'components/SimpleLoader'
import { Stream, PostDestination, WithIntl } from 'interfaces'
import { StoreState } from 'store/state'
import { userStreamsSelector } from 'services/content/selectors'
import { FetchMyStreams } from 'components/Fetch'
import FetchPostDestinations from 'components/Fetch/FetchPostDestinations'
import { userDestinationsSelector } from 'services/destinations/selectors'
import SingleStream from './SingleStream'
import StreamsLoader from 'components/StreamsLoader'
import styles from './MyStreamsView.pcss'
import { injectRouter, WithRouterProps } from 'components/App/injectRouter'
import { EmptyStreamCard } from 'components/Cards'
import { injectIntl } from 'react-intl'

const isEqual = require('react-fast-compare')
const BASE_URL = '/content/my-streams'

interface ConnectedMyStreamsViewProps {
  streams: Stream[]
  destinations: { [key: string]: Readonly<PostDestination> }
}

type MyStreamsProps = ConnectedMyStreamsViewProps & WithRouterProps & WithIntl

interface MyStreamsViewState {
  streamsFetched: boolean
  error?: any
}

export class MyStreamsView extends Component<MyStreamsProps, MyStreamsViewState> {
  constructor(props: MyStreamsProps) {
    super(props)

    this.state = {
      streamsFetched: this.props.streams.length !== 0,
      error: undefined
    }

    this.renderStreams = this.renderStreams.bind(this)
    this.onFetched = this.onFetched.bind(this)
    this.onFailed = this.onFailed.bind(this)
  }

  shouldComponentUpdate(nextProps: MyStreamsProps, nextState: MyStreamsViewState) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)
  }

  onFetched() {
    this.setState((previousState) => ({ ...previousState, streamsFetched: true, error: undefined }))
  }

  onFailed(error: any) {
    this.setState((previousState) => ({ ...previousState, streamsFetched: true, error }))
  }

  renderLoading() {
    return createElement(Loader, { title: 'Loading Stream...' })
  }

  renderEmptyView(): ReactElement<any> {
    console.log('[MyStreams] error', this.state.error)
    // eslint-disable-next-line no-magic-numbers
    const emptyCards = new Array(100).fill('').map((_, i) => {
      return createElement(EmptyStreamCard, { key: i, className: styles.card })
    })
    return createElement('div', { key: 'grid' }, [
      createElement('header', { key: 'header', className: styles.header }, [
        createElement('h2', { key: 'title', className: styles['grid-title'] }, 'Streams'),
        createElement(
          'p',
          { key: 'hint', className: styles.hint },
          this.props.intl.formatMessage({ id: 'search.sections.streams.no-results-hint' })
        )
      ]),
      createElement('div', { className: styles.grid, key: 'grid-content' }, emptyCards)
    ])
  }

  renderStreams(): ReactElement<any> {
    return createElement(StreamsLoader, {
      streams: this.props.streams,
      browseUrlBase: BASE_URL,
      destinations: this.props.destinations
    })
  }

  onCloseUpgradeDialog = () => {
    this.props.navigate('/')
  }

  renderStreamsRoute = () => {
    if (this.props.streams.length === 0) {
      return !this.state.streamsFetched
        ? this.renderLoading()
        : this.renderEmptyView()
    }
    return this.renderStreams()
  }

  render() {
    return createElement('div', { 'data-test': 'my-streams' }, [
      createElement(FetchMyStreams, { key: 'fetch', onFetched: this.onFetched, onFailed: this.onFailed }),
      createElement(Routes, { key: 'routes' }, [
        createElement(Route, { key: 'stream', path: `:stream/*`, element: createElement(SingleStream) }),
        createElement(Route, {
          key: 'all-streams',
          path: '/',
          element: this.renderStreamsRoute()
        })
      ]),
      createElement(FetchPostDestinations, {
        key: 'fetch-destinations',
        onFetched: () => { /* no-op */ },
        onFailed: () => { /* no-op */ }
      })
    ])
  }
}

function mapStateToProps(state: StoreState) {
  return {
    // NOTE: Empty streams are not displayed in main view
    streams: userStreamsSelector(state).filter(s => !!s.feedIds.length),
    destinations: userDestinationsSelector(state)
  }
}

const MyStreamsWithRouter = injectRouter(injectIntl(MyStreamsView))

export default connect(mapStateToProps)(MyStreamsWithRouter)
