import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'

export function getTikTokProfilePrivacyOptions(ppid: string) {
  const builder = new RequestBuilder(`${LEGACY_URL}/v3/albums/get_settings`).asGet().expectJSON()
  return authorizedPipe<any>(
    builder.param('pp_page_id', ppid).build()
  )
}

export function saveTikTokProfileSettings(ppid: string, settings: any) {
  const builder = new RequestBuilder(`${LEGACY_URL}/v3/albums/save_page_settings`).asPost().expectJSON()
  return authorizedPipe<any>(
    builder.param('pp_page_id', ppid).body(settings).build()
  )
}

export function getTikTokProfileSettings(ppid: string) {
  const builder = new RequestBuilder(`${LEGACY_URL}/v3/albums/get_page_settings`).asGet().expectJSON()
  return authorizedPipe<any>(
    builder.param('pp_page_id', ppid).build()
  )
}
