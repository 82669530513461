import { RequestBuilder, authorizedPipe } from 'services/net'
import { CONTENT_SERVICE_URL } from 'config'
import { MyFile } from 'interfaces'
import { Subscriber } from 'rxjs/Subscriber'

export const api = Object.freeze({
  uploadFile: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/my_library`).asPost().expectJSON()

    return function (
      file: File,
      width: number,
      height: number,
      folderId?: string,
      folderName?: string,
      folderColor?: string,
      folderPrivate?: boolean,
      rotateByDegrees?: number,
      progressSubscriber?: Subscriber<any>,
      compress?: boolean
    ) {
      const data = new FormData()
      data.append('file', file)
      data.append('width', width.toString())
      data.append('height', height.toString())
      data.append('folder_id', folderId || '')
      data.append('folder_name', folderName || '')
      data.append('folder_color', folderColor || '')
      data.append('folder_private', folderPrivate ? 'true' : '')
      data.append('rotate_angle', rotateByDegrees ? rotateByDegrees.toString() : '')

      const request = builder
        .form(data)
        .param('compress', compress ? 'true' : 'false')
        .build()

      return authorizedPipe<any>({
        ...request,
        progressSubscriber
      })
    }
  }(),
  copyFileToFolder: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/my_library/copy/:type/:fileId`).asPost().asJSON().expectJSON()

    return function (file: MyFile, folder: { id: string, name: string }) {
      return authorizedPipe<any>(
        builder
          .partial(':type', file.type)
          .partial(':fileId', file.id)
          .bodyJSONstringify({
            file_info: { link: file.url },
            folder_info: folder
          })
          .build()
      )
    }
  }(),
  createFolder: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/my_library/folders`).asPost().expectJSON()

    return function (name: string, color: string, isPrivate: boolean, featuredImage?: File, coverImages?: File[]) {
      const data = new FormData()
      data.append('name', name)
      data.append('color', color)
      data.append('is_private', isPrivate.toString())
      if (featuredImage) {
        data.append('files', featuredImage)
      }
      if (coverImages && coverImages[0]) {
        data.append('files', coverImages[0])
      }
      if (coverImages && coverImages[1]) {
        data.append('files', coverImages[1])
      }

      return authorizedPipe<any>(
        builder
          .form(data)
          .build()
      )
    }
  }(),
  deleteFile: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/my_library`).asDelete().expectJSON().asJSON()

    return function (file: MyFile, force?: boolean) {
      const data: any = {
        file_info: {
          folder_id: file.folderId,
          upload_id: file.id,
          type: file.type,
          link: file.url,
          force
        }
      }

      return authorizedPipe<any>(
        builder
          .bodyJSONstringify(data)
          .build()
      )
    }
  }(),

  deleteFolder: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/my_library/folders/:id`).asDelete().expectJSON().asFormUrlEncoded()

    return function (id: string) {
      return authorizedPipe<any>(
        builder
          .partial(':id', id)
          .build()
      )
    }
  }(),
  getUploads: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/my_library`).asGet().expectJSON()

    return function () {
      return authorizedPipe<any>(
        builder
          .param('page', '0')
          .param('type', 'all')
          .build()
      )
    }
  }(),
  getStorageInfo: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/my_library/storage_info`).asGet().expectJSON()

    return function () {
      return authorizedPipe<any>(
        builder.build()
      )
    }
  }(),
  reorderFolders: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/my_library/folders/reorder`).asJSON().asPatch().expectJSON()

    return function (orderedIds: string[]) {
      return authorizedPipe<any>(
        builder
          .body({ ids: orderedIds })
          .build()
      )
    }
  }(),
  updateFolder: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/my_library/folders/:id`).asPatch().expectJSON()

    return function (
      id: string,
      name: string,
      color: string,
      isPrivate: boolean,
      featuredImage?: string | File,
      coverImages?: string[]
    ) {
      const data = new FormData()
      data.append('name', name)
      data.append('color', color)
      data.append('is_private', isPrivate.toString())
      if (typeof featuredImage === 'string') {
        data.append('featured_image_url', featuredImage)
      }
      if (typeof featuredImage === 'object') {
        data.append('file', featuredImage)
      }
      if (coverImages && coverImages.length > 0) {
        data.append('cover_images', JSON.stringify(coverImages))
      }

      return authorizedPipe<any>(
        builder
          .partial(':id', id)
          .form(data)
          .build()
      )
    }
  }()
})
