import * as React from 'react'
import { PostDestinationType, BRAND_FACEBOOK, BRAND_TWITTER, BRAND_LINKEDIN, BRAND_INSTAGRAM, BRAND_PINTEREST } from 'interfaces'
import ShareIcon from '@mui/icons-material/Reply'
import LikeIcon from '@mui/icons-material/ThumbUp'
import RetweetIcon from '@mui/icons-material/Repeat'
import FavIcon from '@mui/icons-material/Favorite'
import Icon from '@mdi/react'
import { mdiCommentText, mdiCursorDefaultClick, mdiContentSave, mdiEye, mdiInformationOutline } from '@mdi/js'
import styles from './PostedActions.pcss'
import { FormattedMessage } from 'react-intl'
import Tooltip from '@mui/material/Tooltip'

interface PostedStatsProps {
  likes: string
  shares: string
  clicks: string
  comments: string
  saves?: string
  impressions?: string
  isRepin?: boolean
  network: PostDestinationType
}

export function PostedStats(props: PostedStatsProps) {
  const showShares = props.network === BRAND_FACEBOOK || props.network === BRAND_TWITTER || props.network === BRAND_PINTEREST
  const showComments = props.network === BRAND_FACEBOOK || props.network === BRAND_LINKEDIN || props.network === BRAND_INSTAGRAM
  const showClicks = props.network === BRAND_FACEBOOK || props.network === BRAND_PINTEREST
  const isPinterest = props.network === BRAND_PINTEREST
  const showPinterestStats = isPinterest && !props.isRepin

  const likeIcon = React.useMemo(() => {
    if (props.network === BRAND_INSTAGRAM || props.network === BRAND_TWITTER) {
      return <FavIcon />
    }
    return <LikeIcon />
  }, [props.network])

  if (props.isRepin) {
    return (
      <div className={styles.stats}>
        <div className={styles['pi-message']}>
          <Icon path={mdiInformationOutline} className={styles['mdi-icon']} />
          <FormattedMessage id="post.labels.no-repin-stats" />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.stats}>
      <Tooltip title={<FormattedMessage id="post.posted.stats.clicks" />}>
        <span key="clicks" className={`${styles['stats-box']} ${showClicks ? '' : styles.hidden}`}>
          <Icon path={mdiCursorDefaultClick} className={styles['mdi-icon']} />
          <span className={styles.numbers}>{props.clicks}</span>
        </span>
      </Tooltip>
      {!isPinterest && (
        <React.Fragment>
          <Tooltip title={<FormattedMessage id="post.posted.stats.comments" />}>
            <span key="comments" className={`${styles['stats-box']} ${showComments ? '' : styles.hidden}`}>
              <Icon path={mdiCommentText} className={styles['mdi-icon']} />
              <span className={styles.numbers}>{props.comments}</span>
            </span>
          </Tooltip>
          <Tooltip title={<FormattedMessage id="post.posted.stats.likes" />}>
            <span key="likes" className={`${styles['stats-box']} ${!likeIcon ? styles.hidden : ''}`}>
              {likeIcon}
              <span className={styles.numbers}>{props.likes}</span>
            </span>
          </Tooltip>
        </React.Fragment>
      )}
      <Tooltip title={<FormattedMessage id={`post.posted.stats.${props.network === BRAND_TWITTER ? 'retweets' : 'shares'}`} />}>
        <span key="shares" className={`${styles['stats-box']} ${showShares ? '' : styles.hidden}`}>
          {props.network === BRAND_TWITTER ? <RetweetIcon /> : <ShareIcon className={styles['icon-share']} />}
          <span className={styles.numbers}>{props.shares}</span>
        </span>
      </Tooltip>
      {isPinterest && (
        <React.Fragment>
          <Tooltip title={<FormattedMessage id="post.posted.stats.saves" />}>
            <span key="saves" className={`${styles['stats-box']} ${showPinterestStats ? '' : styles.hidden}`}>
              <Icon path={mdiContentSave} className={styles['mdi-icon']} />
              <span className={styles.numbers}>{props.saves}</span>
            </span>
          </Tooltip>
          <Tooltip title={<FormattedMessage id="post.posted.stats.views" />}>
            <span key="impressions" className={`${styles['stats-box']} ${showPinterestStats ? '' : styles.hidden}`}>
              <Icon path={mdiEye} className={styles['mdi-icon']} />
              <span className={styles.numbers}>{props.impressions}</span>
            </span>
          </Tooltip>
        </React.Fragment>
      )}
    </div>
  )
}

export default PostedStats
