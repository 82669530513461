import { PostBase } from 'interfaces'
import { getState, StoreDispatch, StoreThunkAction } from 'store/state'
import { bucketsMapSelector } from '../selectors'

export function mapPostsToBuckets<T extends PostBase>(posts: T[]): StoreThunkAction<T[]> {
  return (_dispatch: StoreDispatch, getState: getState) => {
    const buckets = bucketsMapSelector(getState())

    return posts.map((p: any) => ({
      ...p,
      bucket: p.bucketId ? buckets[p.bucketId] : undefined
    }))
  }
}
