import * as React from 'react'
import parseUrlDomain from 'utils/format/parseUrlDomain'
import { PlayIcon } from 'components/Icons'
import styles from './FacebookPostPreview.pcss'
import { ImagesPreviewGrid } from './ImagesPreviewGrid'
import { PostPreviewDefaultHeader } from './PostPreviewDefaultHeader'
import { PostPreviewVideoPlayer } from './PostPreviewVideoPlayer'
import PostPreviewText from './PostPreviewText'
import { ARTICLE_TYPE, ContentItem, SocialProfileTag } from 'interfaces'
import { StoryPreview } from './InstagramPostPreview'

export const TRUNCATE_MAX_LINES_FB = 5
export const TRUNCATE_TEXT_FB = 'See More'
export const COLLAPSE_TEXT_LABEL_FB = 'See Less'

interface FacebookPostPreviewProps {
  profile?: { name: string, image: string }
  tags?: SocialProfileTag[]
  statusText?: string
  featuredImageUrl?: string
  imageUrls?: string[]
  isVideoPost?: boolean
  videoUrl?: string
  videoThumbnailUrl?: string
  article?: {
    url: string
    title: string
    description?: string
  }
  contentItem?: ContentItem
  reshare?: boolean
  empty?: boolean
  withFirstComment?: boolean
  isStory?: boolean
  textBackgroundUrl?: string
  textColor?: string
}

export function FacebookPostPreview(props: FacebookPostPreviewProps) {
  const { isStory } = props
  const isEmpty = props.empty || (!props.featuredImageUrl && !props.article && !props.statusText && !props.videoUrl)
  const additionalImages = props.imageUrls ? props.imageUrls.filter(url => url !== props.featuredImageUrl) : []

  if (isStory) {
    return (
      <StoryPreview
        isFacebook
        name={props.profile?.name || ''}
        profileImage={props.profile?.image || ''}
        imageUrl={props.featuredImageUrl}
        videoUrl={props.videoUrl}
        text={props.statusText}
        videoThumbnailUrl={props.videoThumbnailUrl}
      />
    )
  }

  return (
    <article className={`${styles.post} ${isEmpty ? styles.empty : ''}`}>
      <PostPreviewDefaultHeader withSubtitle profile={props.profile} />
      <main className={props.reshare && props.contentItem?.type === ARTICLE_TYPE ? styles.rounded : ''}>
        {props.statusText && (
          <PostPreviewText
            text={props.statusText || ''}
            tags={props.tags}
            network="facebook"
            expandLabel={TRUNCATE_TEXT_FB}
            collapseLabel={COLLAPSE_TEXT_LABEL_FB}
            maxLinesDefault={TRUNCATE_MAX_LINES_FB}
            className={styles.status}
            backgroundImageUrl={props.textBackgroundUrl}
            color={props.textColor}
          />
        )}
        {!props.videoUrl && props.featuredImageUrl && additionalImages.length === 0 && (
          <div className={styles['img-box']}>
            <img src={props.featuredImageUrl} className={styles.image} />
            {props.isVideoPost && <PlayIcon className={`${styles['icon-centered']} ${styles.icon}`} />}
          </div>
        )}
        {props.featuredImageUrl && additionalImages.length > 0 && (
          <ImagesPreviewGrid featuredImageUrl={props.featuredImageUrl} imageUrls={additionalImages} />
        )}
        {props.videoUrl && (
          <PostPreviewVideoPlayer thumbnailUrl={props.videoThumbnailUrl} videoUrl={props.videoUrl} />
        )}
        {isEmpty && (
          <div className={styles['img-box']}></div>
        )}
        {props.article && (
          <div className={styles['article-box']}>
            <div className={styles.link}>{parseUrlDomain(props.article.url)}</div>
            <div className={`${styles.title} text-ellipsis`}>{props.article.title}</div>
            {props.article.description && (
              <div className={`${styles.description} text-ellipsis`}>{props.article.description}</div>
            )}
          </div>
        )}
        {props.withFirstComment && (
          <div className={styles.comment}>1 Comment</div>
        )}
      </main>
      <footer className={styles.footer}>
        {props.reshare && props.contentItem && props.contentItem.type !== ARTICLE_TYPE && (
          <div className={styles['reshare-box']}>
            <div className={`${styles['page-name']} text-ellipsis`}>{props.contentItem.feed.name}</div>
            <div className={styles.date}></div>
            {props.contentItem.status && (
              <div className={`${styles['reshare-text']} text-ellipsis`}>{props.contentItem.status}</div>
            )}
          </div>
        )}
      </footer>
    </article>
  )
}
