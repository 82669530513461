import * as React from 'react'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import FeedCard from '../FeedCard'
import { Feed, ContentFeedType, FEED_TYPE_KEYWORD } from 'interfaces'
import SocialIcon from 'components/SocialIcon'
import CircularProgress from '@mui/material/CircularProgress'
import IconAdd from '@mui/icons-material/Add'
import IconCheck from '@mui/icons-material/Check'
import Tooltip from '@mui/material/Tooltip'
import { FormattedMessage } from 'react-intl'

import styles from './AddFeedCard.pcss'
const LOADER_SIZE = 24

export interface AddFeedCardProps {
  handle: string
  type: ContentFeedType
  selected: boolean
  titleHTML?: string
  displayName?: string
  pictureUrl?: string
  loading?: boolean
  newFeed?: Feed | null
  className?: string
  name?: string
  networkId? : string

  onClick?: () => void
  onSelectButtonClick?: (feed?: Feed) => void
  onNewFeedClick?: (feed: Feed) => void
}

function getSocialIcon(type: ContentFeedType) {
  const className = `${styles['s-icon']} ${styles[type]}`
  return <SocialIcon icon={type} className={className} size="24px" outline={type !== FEED_TYPE_KEYWORD} />
}

export function AddFeedCard(props: AddFeedCardProps) {
  const socialIcon = getSocialIcon(props.type)
  const isKeywordFeed = props.type === FEED_TYPE_KEYWORD
  const overlay = <div className={`${styles['img-overlay']} ${styles['keyword-overlay']}`}></div>

  const onFeedClick = () => {
    if (props.newFeed && props.onNewFeedClick) {
      props.onNewFeedClick(props.newFeed)
    }
  }

  const imageStyles = {
    backgroundImage: `url('${props.pictureUrl || ''}')`
  }
  if (props.type === FEED_TYPE_KEYWORD) {
    imageStyles.backgroundImage = `url('static/img/feeds/keyword-content_card-bg.jpg')`
  }

  const title = props.displayName || props.name || props.handle
  const titleProps = props.titleHTML ? { dangerouslySetInnerHTML: { __html: props.titleHTML } } : undefined

  const onAddClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (props.onSelectButtonClick) {
      props.onSelectButtonClick()
    }
  }

  const selected = props.selected

  return props.newFeed ? (
    <FeedCard
      feed={props.newFeed}
      onClick={onFeedClick}
      onSelect={props.onSelectButtonClick}
      selected={selected}
      className={props.className || ''}
    />
  ) : (
    <Paper
      className={`${styles.card} ${styles['add-feed-card']} ${styles[props.type]} ${props.className || ''}`}
      data-testid="add-feed-card"
      onClick={props.onClick}
    >
      {
        props.onSelectButtonClick && (
          <Tooltip
            PopperProps={{
              sx: {
                '&[data-popper-reference-hidden]': {
                  visibility: 'hidden',
                  'pointer-events': 'none'
                }
              }
            }}
            title={<FormattedMessage id={selected ? 'label.generic.selected' : 'actions.select'} />}
            placement="top"
          >
            <IconButton className={`${styles['btn-add']} ${selected ? styles.selected : ''}`} size="medium" onClick={onAddClick}>
              {selected ? <IconCheck /> : <IconAdd />}
            </IconButton>
          </Tooltip>
        )
      }
      <div className={styles.content}>
        {
          props.loading && (
            <div className={styles['loader-box']}>
              <CircularProgress className={styles.loader} size={LOADER_SIZE} />
            </div>
          )
        }
        <div className={styles['feed-image']} style={imageStyles} key="image">
          {isKeywordFeed && <p className={styles['title-kw']}>{title}</p>}
          {isKeywordFeed && overlay}
        </div>
        <div className={`${styles['item-info']} ${styles[props.type]}`}>
          <h5 className={`${styles.title} text-ellipsis`} {...titleProps} title={title}>
            {props.titleHTML ? null : title}
          </h5>
          <p className={styles['feed-type']}>{socialIcon}</p>
        </div>
      </div>
    </Paper>
  )
}

export default AddFeedCard
