export const WEEKDAY_MONDAY = 'monday'
export const WEEKDAY_TUESDAY = 'tuesday'
export const WEEKDAY_WEDNESDAY = 'wednesday'
export const WEEKDAY_THURSDAY = 'thursday'
export const WEEKDAY_FRIDAY = 'friday'
export const WEEKDAY_SATURDAY = 'saturday'
export const WEEKDAY_SUNDAY = 'sunday'

export const WEEK_DAYS_SHORT = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']

export type Weekday = typeof WEEKDAY_SUNDAY
  | typeof WEEKDAY_MONDAY
  | typeof WEEKDAY_TUESDAY
  | typeof WEEKDAY_WEDNESDAY
  | typeof WEEKDAY_THURSDAY
  | typeof WEEKDAY_FRIDAY
  | typeof WEEKDAY_SATURDAY

// NOTE: Ordered by Date.getDay() index - starting from Sunday
export const WEEKDAY_LABELS: Weekday[] = [
  WEEKDAY_SUNDAY,
  WEEKDAY_MONDAY,
  WEEKDAY_TUESDAY,
  WEEKDAY_WEDNESDAY,
  WEEKDAY_THURSDAY,
  WEEKDAY_FRIDAY,
  WEEKDAY_SATURDAY
]
export const DAYS_IN_WEEK = 7

export const MONTH_JANUARY = 'january'
export const MONTH_FEBRUARY = 'february'
export const MONTH_MARCH = 'march'
export const MONTH_APRIL = 'april'
export const MONTH_MAY = 'may'
export const MONTH_JUNE = 'june'
export const MONTH_JULY = 'july'
export const MONTH_AUGUST = 'august'
export const MONTH_SEPTEMBER = 'september'
export const MONTH_OCTOBER = 'october'
export const MONTH_NOVEMBER = 'november'
export const MONTH_DECEMBER = 'december'

export type MonthOfYear = typeof MONTH_JANUARY
  | typeof MONTH_FEBRUARY
  | typeof MONTH_MARCH
  | typeof MONTH_APRIL
  | typeof MONTH_MAY
  | typeof MONTH_JUNE
  | typeof MONTH_JULY
  | typeof MONTH_AUGUST
  | typeof MONTH_SEPTEMBER
  | typeof MONTH_OCTOBER
  | typeof MONTH_NOVEMBER
  | typeof MONTH_DECEMBER

export const MONTHS_OF_YEAR = [
  MONTH_JANUARY,
  MONTH_FEBRUARY,
  MONTH_MARCH,
  MONTH_APRIL,
  MONTH_MAY,
  MONTH_JUNE,
  MONTH_JULY,
  MONTH_AUGUST,
  MONTH_SEPTEMBER,
  MONTH_OCTOBER,
  MONTH_NOVEMBER,
  MONTH_DECEMBER
]

export const MAX_YEAR_DEFAULT = 3000
