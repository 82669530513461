import * as React from 'react'
import { PPRSSFeed, PP_BLOG_NAV_LINK } from '../../components/PostPlannerBlogCarousel'
import FeedView from 'components/FeedView'
import { ARTICLE_TYPE, Feed, RangeFilter, WEEK_RANGE_FILTER } from 'interfaces'
import { FormattedMessage } from 'react-intl'
import styles from './PostPlannerBlogContentView.pcss'

export function PostPlannerBlogContentView() {
  const [range, setRange] = React.useState<RangeFilter>(WEEK_RANGE_FILTER)

  const changeRange = (_feed: Feed, r: RangeFilter) => {
    setRange(r)
  }

  return (
    <div>
      <header>
        <h1 className={`${styles.title} text-ellipsis`}>
          <FormattedMessage id="find.home.sections.pp-blog" />
        </h1>
      </header>
      <FeedView
        feed={PPRSSFeed}
        defaultFilter={ARTICLE_TYPE}
        range={range}
        basePath={`/content/${PP_BLOG_NAV_LINK}`}
        onRangeChanged={changeRange}
        skipContentFeatureChecks
      />
    </div>
  )
}

export default PostPlannerBlogContentView
