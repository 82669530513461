import * as React from 'react'
import { getMonthViewDays, getMonthViewDaysByWeeks, Weekday, WEEKDAY_LABELS, WEEKDAY_MONDAY } from 'utils/calendar'
import CalendarMonthViewHeader from './components/CalendarViewHeader'
import styles from './CalendarMonthView.pcss'
import CalendarDay from './components/CalendarDay'
import { CalendarDay as ICalendarDay } from 'interfaces/Post/Calendar'
import { CalendarMonthStartOption } from 'services/post/state'

interface CalendarMonthViewProps {
  year: number
  month: number
  monthStart: CalendarMonthStartOption
  startDay?: Weekday
  className?: string
  renderDay?: (day: ICalendarDay) => React.ReactNode
}

export function CalendarMonthView(props: CalendarMonthViewProps) {
  const startDay = props.startDay || WEEKDAY_MONDAY
  const now = new Date()
  now.setHours(0, 0, 0)
  const currentTime = now.getTime()
  const today = now.getDate()
  const currentMonth = now.getMonth()
  const currentYear = now.getFullYear()

  const visibleDays = React.useMemo(() => {
    const startDayIndex = WEEKDAY_LABELS.indexOf(startDay)
    if (props.monthStart === '1st' || props.month !== currentMonth || props.year !== currentYear) {
      return getMonthViewDays(props.year, props.month, startDayIndex)
    }
    return getMonthViewDaysByWeeks(new Date(), startDayIndex)
  }, [startDay, props.monthStart, props.month, props.year, currentMonth, currentYear])

  return (
    <section className={`${styles['calendar-scrollbox']} ${props.className || ''}`} data-testid="calendar-month-view">
      <CalendarMonthViewHeader startDay={startDay} />
      <div className={styles.grid}>
        {visibleDays.map((day) => {
          const time = day.date.getTime()
          const month = day.date.getMonth()
          const year = day.date.getFullYear()
          const isToday = day.date.toDateString() === now.toDateString()

          return (
            <CalendarDay
              key={time}
              day={day}
              active={isToday}
              // disabled={year < currentYear || month < currentMonth || (month === currentMonth && today > day.date.getDate())}
              disabled={!isToday && time < currentTime}
            >
              {props.renderDay && props.renderDay(day)}
            </CalendarDay>
          )
        })}
      </div>
    </section>
  )
}

export default CalendarMonthView
