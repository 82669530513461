import * as React from 'react'
import { FetchBuckets } from 'components/Fetch/FetchBuckets'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import BucketPostsView from './components/BucketPostsView'
import { bucketsSelector } from 'services/post/selectors'
import BucketCreatePopup from './components/BucketCreatePopup'
import { BucketsHome } from './BucketsHome'

type BucketTemplate = { name: string, description: string, color: string }

export function BucketsRoute() {
  const params = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(true)
  const [fetchKey, setFetchKey] = React.useState(0)
  const buckets = useSelector(bucketsSelector)
  const [createPopupOpen, setCreatePopupOpen] = React.useState(false)
  const [selectedTemplate, setSelectedTemplate] = React.useState<BucketTemplate | undefined>(undefined)

  React.useEffect(() => {
    if (selectedTemplate) {
      setCreatePopupOpen(true)
    }
  }, [buckets.length, loading, selectedTemplate])

  const onBucketPostsUpdated = () => {
    setFetchKey(current => current + 1)
  }

  const stopLoading = React.useCallback(() => {
    setLoading(false)
  }, [])

  const activeBucket = React.useMemo(() => {
    return buckets.find(b => b.id === params.id)
  }, [params.id, buckets])

  React.useEffect(() => {
    if (activeBucket && (activeBucket.id !== params.id || !params.type)) {
      navigate(`/posts/buckets/${activeBucket.id}/${params.type || 'posts'}`)
    }
  }, [activeBucket, params.id, params.type, navigate])

  const onCreatePopupClose = () => {
    if (buckets.length === 0) {
      navigate('/posts/planned/all')
    } else {
      setCreatePopupOpen(false)
      setSelectedTemplate(undefined)
    }
  }

  const openCreateBucketPopup = (template: BucketTemplate) => {
    setSelectedTemplate(template)
  }

  return (
    <div>
      {!activeBucket && (
        <BucketsHome onRequestCreateBucket={openCreateBucketPopup} />
      )}
      <FetchBuckets key={fetchKey} onFetched={stopLoading} onError={stopLoading} />

      <BucketCreatePopup
        open={createPopupOpen}
        init={selectedTemplate}
        onClose={onCreatePopupClose}
        onCreated={onCreatePopupClose}
      />
      {!loading && activeBucket && (
        <BucketPostsView
          activeView={params.type === 'posted' ? 'posted' : 'planned'}
          bucket={activeBucket}
          onBucketPostsUpdated={onBucketPostsUpdated}
        />
      )}
    </div>
  )
}

export default BucketsRoute
