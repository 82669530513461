import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import EmojiIcon from '@mui/icons-material/InsertEmoticon'
import { EmojiData } from 'emoji-mart'
import NimblePicker from 'emoji-mart/dist/components/picker/nimble-picker'

const data = require('emoji-mart/data/facebook.json')
import styles from './EmojiPicker.pcss'

interface EmojiPickerProps {
  className?: string
  onSelect: (emoji: EmojiData) => void
}

export function EmojiPicker(props: EmojiPickerProps) {
  const [active, setActive] = React.useState(false)

  const toggleActive = () => {
    setActive(!active)
  }

  return (
    <div className={`${styles.container} ${props.className || ''}`}>
      <IconButton onClick={toggleActive} className={styles['btn-open']} size="large">
        <EmojiIcon fontSize="medium" className={styles['btn-icon']} />
      </IconButton>
      {
        active && (
          <ClickAwayListener onClickAway={toggleActive}>
            <div className={styles.picker}>
              <NimblePicker data={data} set="facebook" showPreview={false} onSelect={props.onSelect} />
            </div>
          </ClickAwayListener>
        )
      }
    </div>
  )
}

export default EmojiPicker
