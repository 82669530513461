import React, { Component, createElement } from 'react'
import { STATUS_TYPE, StatusIdea, WithIntl, InteractionEvent, CuratedFolder } from 'interfaces'
import { Route, useLocation, Routes } from 'react-router-dom'
import { TIMERANGE_FILTER_DEFAULT } from 'config'
import CuratedFolderViewHeader from './CuratedFolderViewHeader'
import CuratedFolderViewNavigation from './CuratedFolderViewNavigation'
import StatusesBricksView from './Statuses'
import CuratedFolderListView from './CuratedFolderListView'
import { FetchCuratedFolderPageContent } from 'components/Fetch'

const cssVariables = require('styles/variables')
const CARD_WIDTH = cssVariables['--square-card-default-size']

import ErrorOverlay, {
  AJAX_ERROR_POPULAR_MESSAGE,
  NO_CONTENT_POPULAR_MESSAGE,
  AJAX_ERROR_STREAM_SUBTITLE
} from 'components/ErrorOverlay'
import { injectIntl } from 'react-intl'
import { Observable } from 'rxjs/Observable'

import styles from './CuratedFolderView.pcss'

export interface CuratedStatusesFolderViewProps extends WithIntl {
  folder: CuratedFolder
  navigation: CuratedFolderViewNavigation
  hideHeader?: boolean
  forceGridView?: boolean

  trackInteraction: (categoryIds: string[], event: InteractionEvent) => Promise<any>
  onBackClick?: () => void
  onListViewScrolled?(folder: CuratedFolder, direction: 'left' | 'right'): void
  onItemShared?(folder: CuratedFolder, content: StatusIdea): void
}

interface CuratedStatusesFolderViewState {
  loading: boolean
  items: StatusIdea[]
  page: number
  seed: string
  error?: any
}

export class CuratedStatusesFolderView extends Component<CuratedStatusesFolderViewProps, CuratedStatusesFolderViewState> {
  constructor(props: CuratedStatusesFolderViewProps) {
    super(props)

    this.state = {
      loading: true,
      items: [],
      page: 0,
      seed: Date.now().toString(),
      error: undefined
    }
  }

  onFetched = (items: StatusIdea[]) => {
    this.setState((previousState) => ({ ...previousState, loading: false, items: previousState.items.concat(items) }))
  }

  onFailed = (error: any) => {
    this.setState((previousState) => ({ ...previousState, loading: false, error }))
  }

  maybeLoadMore = () => {
    if (this.state.loading || this.state.error) {
      return
    }

    const page = this.state.page + 1
    this.setState((previousState) => ({ ...previousState, loading: true, page }))
  }

  renderList = () => {
    const { folder } = this.props
    const { items, loading, page } = this.state

    return [
      loading
        ? createElement(FetchCuratedFolderPageContent, {
          key: 'fetch',
          folder,
          page,
          statusesSeed: this.state.seed,
          range: TIMERANGE_FILTER_DEFAULT,
          onFetched: this.onFetched,
          onFailed: this.onFailed
        })
        : null,

      createElement(CuratedFolderListView, {
        key: 'items',
        stream: folder as any,
        items,
        loading,

        onScrolled: this.props.onListViewScrolled,
        onItemShared: this.props.onItemShared,

        onScrollLimit: this.maybeLoadMore
      } as any),

      items.length === 0
        ? this.renderError()
        : null
    ]
  }

  renderError = () => {
    if (this.state.loading) {
      return null
    }

    if (this.state.error) {
      const originalError = this.state.error
      const error = AJAX_ERROR_POPULAR_MESSAGE(this.props.intl.formatMessage)
      const subtitle = AJAX_ERROR_STREAM_SUBTITLE(this.props.intl.formatMessage)

      return createElement(ErrorOverlay, { key: 'error', originalError, error, subtitle, className: styles.error })
    }

    const originalError = this.state.error
    const error = NO_CONTENT_POPULAR_MESSAGE(STATUS_TYPE, this.props.intl.formatMessage)
    const subtitle = this.props.intl.formatMessage({ id: 'content.msg-no-content-curated-subtitle' })

    return createElement(ErrorOverlay, { key: 'error', originalError, error, subtitle, className: styles.error })
  }

  renderBricks = () => {
    const { folder } = this.props
    const { items, page, loading } = this.state

    return createElement(StatusesBricksView, {
      key: 'items',
      seed: this.state.seed,
      items,
      folder,
      itemWidth: CARD_WIDTH,
      startPage: page + (loading ? 0 : 1),
      trackInteraction: this.props.trackInteraction,
      onItemShared: this.props.onItemShared
    })
  }

  onBackClick = () => {
    this.setState(prevState => ({
      ...prevState,
      loading: true,
      items: []
    }))

    if (this.props.onBackClick) {
      this.props.onBackClick()
    }
  }

  renderWhenMatched = () => {
    const { folder, navigation, hideHeader, forceGridView } = this.props

    return createElement(ContentView, {
      hideHeader,
      stream: folder,
      navigation,
      forceGridView,
      onBackClick: this.onBackClick,
      renderGrid: this.renderBricks,
      renderList: this.renderList
    })
  }

  render() {
    const { folder, navigation } = this.props
    const morePath = `${navigation.prefix}/${folder.slug}`

    return createElement(Routes, { key: 'routes' }, [
      createElement(Route, { key: 'folder', path: ':category', element: this.renderWhenMatched() }),
      createElement(Route, { key: 'list', path: '/', element: this.renderWhenMatched() })
    ])
  }
}

function ContentView(props: {
  stream: CuratedFolder,
  navigation: any,
  forceGridView?: boolean,
  hideHeader?: boolean,
  onBackClick: () => void,
  renderGrid: () => React.ReactNode,
  renderList: () => React.ReactNode
}) {
  const location = useLocation()

  return createElement('section', { className: styles.folder }, [
    !props.hideHeader ? createElement(CuratedFolderViewHeader, {
      key: 'header',
      stream: props.stream as any,
      navigation: props.navigation,
      onBackClick: props.onBackClick,
      expandedView: location.pathname !== props.navigation.prefix
    }) : null,

    location.pathname === props.navigation.prefix && !props.forceGridView
      ? props.renderList()
      : props.renderGrid()
  ])
}

export default injectIntl(CuratedStatusesFolderView)
