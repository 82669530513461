import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Bucket, ContentType, PostDestinationType } from 'interfaces'
import ProfileAvatar from 'components/ProfileAvatar'
import styles from './EmptyPostSlot.pcss'
import Tooltip from '@mui/material/Tooltip'

interface EmptyPostSlotProps {
  profile: {
    type: PostDestinationType
    image: string
    name: string
  }
  timeString: string
  baseTime?: number
  bucket?: Bucket
  className?: string
}

export const EmptyPostSlot = React.memo((props: EmptyPostSlotProps) => {
  return (
    <div className={`${styles.container} ${props.className || ''}`}>
      <div className={styles['time-box']}>
        {`${props.timeString} ${props.baseTime ? ' ish' : ''}`}
      </div>
      <div className={styles.content}>
        {props.bucket && (
          <Tooltip title={`Bucket: ${props.bucket.name}`} placement="top">
            <div className={styles['bucket-indicator']} style={{ backgroundColor: props.bucket.color }}></div>
          </Tooltip>
        )}
        <div className={styles['avatar-box']}>
          <ProfileAvatar
            brand={props.profile.type}
            src={props.profile.image}
            title={props.profile.name}
            circle
            size="34px"
          />
        </div>
        <div className={styles.body}>
          <div className={styles.actions}>
            <span className={styles.label}>
              <FormattedMessage id="post.labels.empty-slot" />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
})

export default EmptyPostSlot
