import * as React from 'react'
import StoreState, { StoreThunkDispatch } from 'store/state'
import { myProfilesStreamSelector } from 'services/content/selectors'
import { getStreams } from 'services/content/streams/actions'
import { connect } from 'react-redux'
import { CarouselView } from 'components/ContentLayout'
import { Stream, PostDestination, FilterType, ContentType, ARTICLE_TYPE } from 'interfaces'
import { getStreamFilters } from 'utils/filter-store'
import { userDestinationsSelector } from 'services/destinations'
import { NavLink } from 'react-router-dom'
import { SECTION_URL_MY_PROFILES } from '../../home.config'
import { FormattedMessage } from 'react-intl'
import ContentTypeFilter from 'components/ContentTypeFilter'

import styles from '../HomeSection.pcss'

interface MyProfilesStreamContentCarouselProps {
  stream: Stream | undefined
  profiles: { [id: string]: PostDestination }
  title?: string
  fetchUserStreams: () => Promise<any>
}

const MyProfilesStreamContentCarousel = React.memo(function (props: MyProfilesStreamContentCarouselProps) {
  const [filter, setFilter] = React.useState<string | undefined>(undefined)

  React.useEffect(() => {
    props.fetchUserStreams()
  }, [])

  React.useEffect(() => {
    if (!props.stream) {
      return
    }
    const storedFilter = getStreamFilters(props.stream).type
    setFilter(storedFilter as ContentType)
  }, [])

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h2 className={`${styles.title} text-ellipsis`}>
          {props.title || <FormattedMessage id="find.home.sections.my-profiles" />}
        </h2>
        <NavLink
          to={`${SECTION_URL_MY_PROFILES}/${props.stream?.id || ''}`}
          className={`${styles.navigation} text-ellipsis`}
        >
          <FormattedMessage id="general.carousel.nav.see-all" />
        </NavLink>
        <ContentTypeFilter
          options={props.stream?.filters || []}
          selected={filter}
          onFilterSelected={setFilter}
        />
      </header>
      <CarouselView
        source={props.stream}
        filter={filter as FilterType || ARTICLE_TYPE}
        destinations={props.profiles}
      />
    </section>
  )
})

function mapStateToProps(state: StoreState) {
  return {
    stream: myProfilesStreamSelector(state),
    profiles: userDestinationsSelector(state)
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    fetchUserStreams: () => dispatch(getStreams(true)).unwrap()
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfilesStreamContentCarousel)
