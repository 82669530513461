import { IndexedObject } from '../misc/IndexedObject'
import { BRAND_FACEBOOK, BRAND_INSTAGRAM, BRAND_TWITTER, BRAND_LINKEDIN, BRAND_PINTEREST, BRAND_YOUTUBE } from '../Content'
import { BRAND_GOOGLE, BRAND_TIKTOK } from 'interfaces/Content/SocialBrand'
import PostDestination from './PostDestination'

export type PostDestinationType = typeof BRAND_FACEBOOK
  | typeof BRAND_TWITTER
  | typeof BRAND_INSTAGRAM
  | typeof BRAND_LINKEDIN
  | typeof BRAND_PINTEREST
  | typeof BRAND_TIKTOK
  | typeof BRAND_GOOGLE
  | typeof BRAND_YOUTUBE

export const NETWORKS_ORDER: IndexedObject<number> = {
  [BRAND_FACEBOOK]: 1,
  [BRAND_INSTAGRAM]: 2,
  [BRAND_GOOGLE]: 3,
  [BRAND_LINKEDIN]: 4,
  [BRAND_PINTEREST]: 5,
  [BRAND_TIKTOK]: 6,
  [BRAND_TWITTER]: 7,
  [BRAND_YOUTUBE]: 8
}

export const NETWORKS_NAMES: IndexedObject<string> = {
  [BRAND_FACEBOOK]: 'Facebook',
  [BRAND_INSTAGRAM]: 'Instagram',
  [BRAND_LINKEDIN]: 'LinkedIn',
  [BRAND_PINTEREST]: 'Pinterest',
  [BRAND_TWITTER]: 'X/Twitter',
  [BRAND_TIKTOK]: 'TikTok',
  [BRAND_GOOGLE]: 'Google Business',
  [BRAND_YOUTUBE]: 'YouTube'
}

export function sortProfilesByNetworkDefault(p1: PostDestination, p2: PostDestination) {
  if (NETWORKS_ORDER[p1.type] < NETWORKS_ORDER[p2.type]) {
    return -1
  }
  return 1
}

export function sortNetworksDefault(n1: PostDestinationType, n2: PostDestinationType) {
  if (NETWORKS_ORDER[n1] < NETWORKS_ORDER[n2]) {
    return -1
  }
  return 1
}

export const NETWORKS_ORDERED = [
  BRAND_FACEBOOK,
  BRAND_INSTAGRAM,
  BRAND_GOOGLE,
  BRAND_LINKEDIN,
  BRAND_PINTEREST,
  BRAND_TIKTOK,
  BRAND_TWITTER,
  BRAND_YOUTUBE
]

export default PostDestinationType
