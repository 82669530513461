export const MAX_PRODUCT_ORDER = 7 // The highest level plan. No Upgrades from here

/** Feature keys */
export const FEATURE_FIND_STATUS_IDEAS = 'FIND_STATUS_IDEAS'
export const FEATURE_FIND_MY_STREAMS_CREATE = 'FIND_MY_STREAMS_CREATE'
export const FEATURE_FIND_MY_STREAMS_SAVE = 'FIND_MY_STREAMS_SAVE'
export const FEATURE_FIND_PRIVATE_STREAMS = 'FIND_PRIVATE_STREAMS'
export const FEATURE_FIND_FAVORITES = 'FIND_FAVORITES'

export const FEATURE_POST_TO_FB_GROUPS = 'POST_TO_FB_GROUPS'
export const FEATURE_POST_RECYCLE = 'POST_RECYCLE'
export const FEATURE_POST_INSIGHTS_ACTIONS_SORT = 'POST_INSIGHTS_ACTIONS_SORT'
export const FEATURE_POST_INSIGHTS_ACTIONS_REPLAN = 'POST_INSIGHTS_ACTIONS_REPLAN'
export const FEATURE_POST_INSIGHTS_VIEW = 'POST_INSIGHTS_VIEW'
export const FEATURE_POST_CANVA = 'POST_CANVA'
export const FEATURE_POST_SHUFFLE = 'POST_SHUFFLE'
export const FEATURE_POST_BULK_UPLOAD = 'POST_BULK_UPLOAD'
export const FEATURE_POST_TARGETING = 'POST_TARGETING'
export const FEATURE_POST_REPEAT = 'POST_REPEAT'
export const FEATURE_POST_COPY = 'POST_COPY'
export const FEATURE_POST_TAGGING = 'POST_TAGGING'
export const FEATURE_POST_TO_FBGROUP_FROM_PAGE = 'POST_FROM_PAGE_TO_GROUP'
export const FEATURE_POST_IG_FIRST_COMMENT = 'POST_IG_FIRST_COMMENT'
export const FEATURE_POST_MULTI_PHOTO = 'POST_MULTI_PHOTO'
export const FEATURE_POST_FB_TEXT_ON_IMAGE = 'POST_FB_TEXT_ON_IMAGE'

export const FEATURE_CONTENT_TYPE_VIDEO = 'CONTENT_TYPE_VIDEO'
export const FEATURE_CONTENT_TYPE_PHOTO = 'CONTENT_TYPE_PHOTO'
export const FEATURE_CONTENT_TYPE_ARTICLE = 'CONTENT_TYPE_ARTICLE'
export const FEATURE_CONTENT_TYPE_GIF = 'CONTENT_TYPE_GIF'
export const FEATURE_CONTENT_TYPE_TEXT = 'CONTENT_TYPE_TEXT'
export const FEATURE_CONTENT_TYPE_STOCK = 'CONTENT_TYPE_STOCK'

export const FEATURE_CONTENT_AGE_NEW = 'CONTENT_AGE_NEW'
export const FEATURE_CONTENT_AGE_MONTH = 'CONTENT_AGE_MONTH'
export const FEATURE_CONTENT_AGE_YEAR = 'CONTENT_AGE_YEAR'
export const FEATURE_CONTENT_AGE_ALL = 'CONTENT_AGE_ALL'

export const FEATURE_CONTENT_SOURCE_TWITTER = 'CONTENT_SOURCE_TWITTER'
export const FEATURE_CONTENT_SOURCE_RSS = 'CONTENT_SOURCE_RSS'
export const FEATURE_CONTENT_SOURCE_LINKEDIN = 'CONTENT_SOURCE_LINKEDIN'
export const FEATURE_CONTENT_SOURCE_INSTAGRAM = 'CONTENT_SOURCE_INSTAGRAM'
export const FEATURE_CONTENT_SOURCE_REDDIT = 'CONTENT_SOURCE_REDDIT'
export const FEATURE_CONTENT_ON_DEMAND = 'FEATURE_CONTENT_ON_DEMAND'
export const FEATURE_CONTENT_STUDIO = 'CONTENT_STUDIO'
export const FEATURE_GENERIC = 'GENERIC'

/** Limit keys */
export const LIMIT_PROFILES_COUNT = 'PROFILES_COUNT'
export const LIMIT_USERS_COUNT = 'USERS_COUNT'
export const LIMIT_SCHEDULED_POSTS_COUNT = 'SCHEDULED_POSTS_COUNT'
export const LIMIT_POSTS_PER_DAY_COUNT = 'POSTS_PER_DAY_COUNT'
export const LIMIT_MY_LIBRARY_STORAGE = 'MY_LIBRARY_STORAGE'
export const LIMIT_SAVED_TEXTS = 'SAVED_TEXTS_COUNT'
export const LIMIT_POST_DRAFTS = 'POST_DRAFTS_COUNT'
export const LIMIT_POST_BUCKETS = 'POST_BUCKETS_COUNT'
export const LIMIT_SAVED_STREAMS = 'CONTENT_SAVED_STREAMS_COUNT'

// Features that are available to all users and does not show in admin panel
export const HIDDEN_FEATURES = [
  FEATURE_POST_TARGETING,
  FEATURE_FIND_MY_STREAMS_CREATE,
  FEATURE_FIND_MY_STREAMS_SAVE,
  FEATURE_POST_TO_FB_GROUPS,
  FEATURE_POST_RECYCLE,
  FEATURE_POST_REPEAT,
  FEATURE_POST_INSIGHTS_VIEW,
  FEATURE_POST_INSIGHTS_ACTIONS_REPLAN,
  FEATURE_POST_INSIGHTS_ACTIONS_SORT,
  FEATURE_POST_CANVA,
  FEATURE_POST_SHUFFLE,
  FEATURE_POST_BULK_UPLOAD,
  FEATURE_FIND_FAVORITES,
  FEATURE_CONTENT_TYPE_ARTICLE,
  FEATURE_CONTENT_SOURCE_TWITTER,
  FEATURE_CONTENT_SOURCE_RSS,
  FEATURE_CONTENT_SOURCE_REDDIT,
  FEATURE_CONTENT_TYPE_STOCK,
  FEATURE_POST_COPY,
  FEATURE_POST_TO_FBGROUP_FROM_PAGE,
  FEATURE_POST_MULTI_PHOTO,
  'CONTENT_SOURCE_KEYWORD'
]

export const HIDDEN_LIMITS = [
  LIMIT_POST_BUCKETS,
  LIMIT_SAVED_STREAMS
]
