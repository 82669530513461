import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'
import { PostTarget } from 'interfaces'

export const ENDPOINT_CREATE_TARGET = `${LEGACY_URL}/targeting/create_edit_targeting`
export const ENDPOINT_GET_TARGETS = `${LEGACY_URL}/targeting/get`
export const ENDPOINT_DELETE_TARGET = `${LEGACY_URL}/targeting/delete_targeting`

export const V1 = Object.freeze({
  createPostTarget: function () {
    const builder = new RequestBuilder(ENDPOINT_CREATE_TARGET).asPost().expectJSON()

    return function (target: PostTarget) {
      const locationKeys = Object.keys(target.locations)
      const data: FormData = new FormData()
      data.append('targeting_profile_name', target.name)
      target.educationStatuses.forEach((ed: string) => { data.append('targeting_education_status[]', ed) })
      target.locales.forEach((loc: string) => { data.append('targeting_locale[]', loc) })
      if (target.minAge && target.maxAge) {
        data.append('targeting_age_group[]', `${target.minAge}-${target.maxAge}`)
      }
      if (locationKeys.length !== 0) {
        locationKeys.forEach(key => {
          const ids = target.locations[key]
          ids.forEach((id: string) => { data.append(`targeting_${key}[]`, id) })
        })
      }
      if (target.timezone) {
        data.append('timezone_checkbox', 'on')
        data.append('targeting_timezone[]', target.timezone)
      }
      if (target.gender) {
        data.append('targeting_gender[]', target.gender)
      }

      return authorizedPipe<any>(
        builder
          .form(data)
          .build()
      )
    }
  }(),
  getTargets: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_TARGETS).asGet().expectJSON()

    return function () {
      return authorizedPipe<any>(builder.build())
    }
  }(),
  deleteTarget: function () {
    const builder = new RequestBuilder(ENDPOINT_DELETE_TARGET).asPost().expectJSON().asFormUrlEncoded()

    return function (name: string) {
      return authorizedPipe<any>(builder.body({ targeting_profile_name: name }).build())
    }
  }(),
  getTemplates: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/post_templates/get_all`).asGet().expectJSON()

    return function () {
      return authorizedPipe<any>(builder.build())
    }
  }()
})
