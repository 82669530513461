import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { LocationInfo } from 'services/compose/interfaces'
import { InstagramLocationSelector } from '../../../CustomizePostBlock/InstagramLocationSelector'
import styles from './InstagramLocationPicker.pcss'
import { IndexedObject, PostDestination } from 'interfaces'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import IconLocation from '@mui/icons-material/Place'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

interface InstagramLocationPickerProps {
  profiles: PostDestination[]
  selectedLocations: IndexedObject<LocationInfo | undefined>
  className?: string
  onSelectedLocationsChange: (selection: IndexedObject<LocationInfo | undefined>, applyToAll?: boolean) => void
}

export function InstagramLocationPicker(props: InstagramLocationPickerProps) {
  const intl = useIntl()
  const [selection, setSelection] = React.useState<IndexedObject<LocationInfo | undefined>>(props.selectedLocations)
  const [applyToAll, setApplyToAll] = React.useState(false)
  const [popupOpen, setPopupOpen] = React.useState(false)

  React.useEffect(() => {
    setSelection(props.selectedLocations)
  }, [props.selectedLocations])

  const updateSelectedLocation = (location: LocationInfo | undefined, ppid: string) => {
    setSelection(current => ({
      ...current,
      [ppid]: location
    }))
  }

  const toggleApplyToAll = () => {
    setApplyToAll(!applyToAll)
  }

  const openPopup = () => {
    setPopupOpen(true)
  }

  const closePopup = () => {
    setPopupOpen(false)
    setApplyToAll(false)
  }

  const confirmSelection = () => {
    props.onSelectedLocationsChange(selection, applyToAll)
    closePopup()
  }

  const emptyLocationLabel = intl.formatMessage({ id: 'composer.labels.instagram.empty-location' })
  const singleLocationLabel = intl.formatMessage({ id: 'label.generic.location' })
    + `: ${props.selectedLocations[props.profiles[0].ppid]?.name || 'none'}`
  const label = props.profiles.length === 1
    ? singleLocationLabel || emptyLocationLabel
    : (
      <FormattedMessage id={
        Object.keys(props.selectedLocations).length > 1
          ? 'composer.labels.instagram.locations-selected'
          : emptyLocationLabel
      }
      />
    )

  return (
    <div className={props.className}>
      <Button startIcon={<IconLocation fontSize="small" className={styles['icon-location']} />} onClick={openPopup}>
        {label}
      </Button>
      <Dialog open={popupOpen} onClose={closePopup} classes={{ root: styles['popup-root'], paper: styles['albums-popup'] }}>
        <IconButton className={styles['btn-close']} onClick={closePopup}>
          <CloseIcon />
        </IconButton>
        <h3 className={styles['popup-title']}>
          <FormattedMessage id="composer.labels.select-multiple-locations" />
        </h3>
        <div className={styles['albums-popup-content']}>
          {props.profiles.map(profile => (
            <div className={styles['option-box']} key={profile.id}>
              <InstagramLocationSelector
                profile={profile}
                selectedLocation={selection[profile.ppid]}
                onSelectedLocationChange={updateSelectedLocation}
              />
            </div>
          ))}
        </div>
        <div className={styles.actions}>
          <div className={styles.cb}>
            <FormControlLabel
              label={<FormattedMessage id="general.labels.apply-to-all" />}
              classes={{ label: styles.label }}
              control={(
                <Checkbox
                  checked={applyToAll}
                  color="primary"
                  size="small"
                  onChange={toggleApplyToAll}
                />
              )}
            />
          </div>
          <Button onClick={closePopup}>
            <FormattedMessage id="actions.cancel" />
          </Button>
          <Button color="primary" variant="contained" onClick={confirmSelection}>
            <FormattedMessage id="actions.confirm" />
          </Button>
        </div>
      </Dialog>
    </div>
  )
}

export default InstagramLocationPicker
