import {
  Feed,
  RangeFilter,
  ARTICLE_TYPE,
  PHOTO_TYPE,
  ContentType,
  VIDEO_TYPE,
  Stream,
  FilterType,
  VALID_RANGE_FILTERS
} from 'interfaces'
import { VALID_TYPE_FILTERS } from 'interfaces/Content'
import { TIMERANGE_FILTER_DEFAULT } from 'config'
import { SortBy } from 'services/content/util'

const feedKey = (id: string) => `feed-${id}`
const streamKey = (id: string) => `stream-${id}`
const STORAGE_KEY_TYPE = 'type'
const STORAGE_KEY_RANGE = 'range'
const STORAGE_KEY_FILTERS = 'content-filters'
const STORAGE_KEY_SORT_BY = 'sort-by'
export const DEFAULT_STREAM_TYPES: ContentType[] = [ARTICLE_TYPE, PHOTO_TYPE, VIDEO_TYPE]

export interface ContentFilters {
  type: FilterType
  range: RangeFilter
}

export function getLastUsedFilters(): ContentFilters {
  const defaults = { range: TIMERANGE_FILTER_DEFAULT, type: ARTICLE_TYPE } as ContentFilters
  if (!localStorage) {
    console.error('Local Storage not supported')
    return defaults
  }

  const filters = localStorage.getItem(STORAGE_KEY_FILTERS)
  return filters ? JSON.parse(filters) : defaults
}

export function setLastUsedFilters(type: FilterType, range: RangeFilter) {
  if (!localStorage) {
    console.error('Local Storage not supported')
    return
  }

  const filters = { [STORAGE_KEY_RANGE]: range, [STORAGE_KEY_TYPE]: type }

  localStorage.setItem(STORAGE_KEY_FILTERS, JSON.stringify(filters))
}

export function getFeedDefaultFilters(feed: Feed): ContentFilters {
  const lastUsed = getLastUsedFilters()
  const type = feed.sources.includes(lastUsed.type as any) ? lastUsed.type : feed.sources[0]

  return { type, range: lastUsed.range }
}

export function getStreamDefaultFilters(): ContentFilters {
  return getLastUsedFilters()
}

export function getFeedFilters(feed: Feed): ContentFilters {
  const defaults = getFeedDefaultFilters(feed)
  if (!localStorage) {
    console.error('Local Storage not supported')
    return defaults
  }

  const key = feedKey(feed.uniqueSource)
  const filters = localStorage.getItem(key)
  const range = getLastUsedFilters().range

  return filters ? Object.assign({}, JSON.parse(filters), { [STORAGE_KEY_RANGE]: range }) : defaults
}

export function setFeedFilters(feed: Feed, type: FilterType, range: RangeFilter) {
  if (!localStorage) {
    console.error('Local Storage not supported')
    return
  }

  const key = feedKey(feed.uniqueSource)
  const value = JSON.stringify({ [STORAGE_KEY_TYPE]: type, [STORAGE_KEY_RANGE]: range })
  try {
    setLastUsedFilters(type, range)
    localStorage.setItem(key, value)
  } catch (e) {
    console.error(`Congratulations, you've reached the Storage limit! You now have ${localStorage.length} items stored.`, e)
  }
}

export function getStreamFilters(stream: Stream): ContentFilters {
  const defaults = getStreamDefaultFilters()
  if (stream.filters.indexOf(defaults.type as ContentType) === -1) {
    defaults.type = stream.filters[0] || ARTICLE_TYPE
  }

  if (!localStorage) {
    console.error('Local Storage not supported')
    return defaults
  }

  const key = streamKey(stream.id)
  const filters = localStorage.getItem(key)
  const range = getLastUsedFilters().range

  if (filters) {
    const filtersResolved = JSON.parse(filters)
    if (stream.filters.indexOf(filtersResolved[STORAGE_KEY_TYPE]) === -1) {
      filtersResolved[STORAGE_KEY_TYPE] = stream.filters[0] || ARTICLE_TYPE
    }
    return Object.assign({}, filtersResolved, { [STORAGE_KEY_RANGE]: range })
  }

  return defaults
}

export function setStreamFilters(id: string, type: FilterType, range: RangeFilter) {
  if (!localStorage) {
    console.error('Local Storage not supported')
    return
  }

  const key = streamKey(id)
  const value = JSON.stringify({ [STORAGE_KEY_TYPE]: type, [STORAGE_KEY_RANGE]: range })
  try {
    setLastUsedFilters(type, range)
    localStorage.setItem(key, value)
  } catch (e) {
    console.error(`Congratulations, you've reached the Storage limit! You now have ${localStorage.length} items stored.`, e)
  }
}

export function isRangeValid(range: string) {
  return VALID_RANGE_FILTERS.includes(range)
}

export function isValidTypeFilter(type: string) {
  return VALID_TYPE_FILTERS.includes(type)
}

export function assureValidType(type: string): FilterType {
  if (VALID_TYPE_FILTERS.includes(type)) {
    return type as FilterType
  }

  return ARTICLE_TYPE
}

export function assureValidRange(range: string): RangeFilter {
  if (VALID_RANGE_FILTERS.includes(range)) {
    return range as RangeFilter
  }
  return TIMERANGE_FILTER_DEFAULT
}

export function setLastUsedSortBy(value: SortBy) {
  try {
    localStorage.setItem(STORAGE_KEY_SORT_BY, value)
  } catch (e) {
    console.error(`Congratulations, you've reached the Storage limit! You now have ${localStorage.length} items stored.`, e)
  }
}

export function getLastUsedSortBy() {
  return localStorage.getItem(STORAGE_KEY_SORT_BY)
}
