import * as React from 'react'
import { Photo } from 'interfaces'

import styles from './ComposerPhotoContentPreview.pcss'

export function ComposerPhotoContentPreview(props: { photo: Photo, className?: string }) {
  return (
    <div className={`${styles.photo} ${props.className || ''}`}>
      <img src={props.photo.imageUrl} />
    </div>
  )
}
