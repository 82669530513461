import * as React from 'react'
import PostDraftView from 'components/Posts/components/PostDraftView'
import { ContentType, WithIntl } from 'interfaces'
import styles from './Drafts.pcss'
import { Subject } from 'rxjs/Subject'
import { tap } from 'rxjs/operators/tap'
import { deleteDrafts, getDrafts } from 'services/post/drafts/actions'
import { catchError } from 'rxjs/operators/catchError'
import { message } from 'services/snackbar/actions'
import { Observable } from 'rxjs/Observable'
import { useDispatch, useSelector } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { injectIntl } from 'react-intl'
import { POST_TYPES } from 'interfaces/Content/ContentType'
import ScrollListener from 'components/ScrollListener'
import PostFilesDialog from 'components/Posts/PostFilesDialog'
import PublishingTopBar from './components/PublishingTopBar'
import SimpleLoader from 'components/SimpleLoader'
import { BulkPostActions } from 'routes/publishing/components/BulkActionsMenus'
import { mergeState, setDraft } from 'services/compose'
import { composerResetKeySelector } from 'services/compose/selectors'
import { selectedProfilesPPIdsSelector } from 'services/post/selectors'
import EmptyView from 'components/EmptyView'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { mdiFeather } from '@mdi/js'
import ComposerState from 'services/compose/state'
import { PostDraftData } from 'shared/types'
import { HelpToggleButton } from 'components/App/components/HelpToggleButton'

const INFINITE_SCROLL_THRESHOLD = 350

export function DraftsManager(props: WithIntl) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const location = useLocation()
  const navigate = useNavigate()
  const selectedPPIds = useSelector(selectedProfilesPPIdsSelector).join(',')
  const [drafts, setDrafts] = React.useState<PostDraftData<ComposerState>[]>([])
  const [selectedDrafts, setSelectedDrafts] = React.useState<string[]>([])
  const [loading, setLoading] = React.useState(false)
  const [page, setPage] = React.useState(0)
  const [hasNextPage, setHasNextPage] = React.useState(true)
  const [totalDraftsCount, setDraftsCount] = React.useState(0)
  const [mediaPopupImages, setMediaPopupImages] = React.useState<string[]>([])
  const [refreshKey, setRefreshKey] = React.useState(0)
  const [search, setSearch] = React.useState<{ query: string, types: ContentType[], searchInSummary: boolean }>(
    { query: '', types: POST_TYPES, searchInSummary: true }
  )
  const [isEmptyViewClosed, setIsEmptyViewClosed] = React.useState(false)
  const composerResetKey = useSelector(composerResetKeySelector)

  const fetch$ = React.useRef<Subject<{ ids: string[], page: number, query?: string, types?: ContentType[], searchInSummary?: boolean }>>()
  const delete$ = React.useRef<Subject<{ ids: string[], all?: boolean }>>()

  React.useEffect(() => {
    setRefreshKey(current => current + 1)
  }, [composerResetKey])

  React.useEffect(() => {
    if (location.state?.search) {
      setSearch(location.state.search)
    }
  }, [location.state?.search])

  React.useEffect(() => {
    fetch$.current = new Subject()
    fetch$.current
      .pipe(tap(({ page }) => {
        setLoading(true)
        setPage(page)
      }))
      .switchMap(({ ids, page, query, types, searchInSummary }) => dispatch(getDrafts(ids, page, query, types, searchInSummary)).pipe(
        catchError(() => {
          dispatch(message('Error fetching drafts! Please refresh.', 'error'))
          return Observable.of({ error: true })
        })
      ))
      .subscribe((response: any) => {
        setLoading(false)
        setIsEmptyViewClosed(false)
        if (!response.error) {
          setHasNextPage(response.paging.current + 1 < response.paging.pages)
          setDrafts(current => response.paging.page === 0 ? response.drafts : [...current, ...response.drafts])
          setDraftsCount(response.totalDraftsCount)
        }
      })

    return () => fetch$.current?.unsubscribe()
  }, [])

  React.useEffect(() => {
    delete$.current = new Subject()
    delete$.current
      .pipe(tap(() => {
        dispatch(message(props.intl.formatMessage({ id: 'post.notifications.deleting-drafts' })))
        setLoading(true)
      }))
      .flatMap(({ ids, all }) => {
        return dispatch(deleteDrafts(ids, all))
          .pipe(catchError((error: Error) => {
            dispatch(message(
              props.intl.formatMessage({ id: 'post.notifications.delete-drafts-error' }, { error: error.message }),
              'error'
            ))
            return Observable.of({ error })
          }))
      })
      .subscribe(() => {
        setLoading(false)
        setSelectedDrafts([])
        setRefreshKey(current => current + 1)
      })

    return () => delete$.current?.unsubscribe()
  }, [dispatch, props.intl])

  React.useEffect(() => {
    fetch$.current?.next({
      ids: selectedPPIds.split(','),
      page: 0,
      query: search.query,
      types: search.types,
      searchInSummary: search.searchInSummary
    })
  }, [refreshKey, search.query, search.types, selectedPPIds, search.searchInSummary])

  const loadNextPage = React.useCallback(() => {
    if (hasNextPage && !loading) {
      fetch$.current?.next({
        ids: selectedPPIds.split(','),
        page: page + 1,
        ...search
      })
    }
  }, [page, hasNextPage, loading, search, selectedPPIds])

  const onEditDraft = React.useCallback((data: PostDraftData<ComposerState>) => {
    dispatch(mergeState(data.state))
    dispatch(setDraft({ id: data.id, updatedAt: data.updatedAt }))
    navigate('/composer', { state: { floating: true, modal: 'compose', opener: '/posts/planned/drafts', search, background: location } })
  }, [navigate, dispatch, search, location])

  const deleteDraft = React.useCallback((id: string) => {
    delete$.current?.next({ ids: [id] })
  }, [])

  const deleteSelected = React.useCallback(() => {
    delete$.current?.next({ ids: selectedDrafts, all: selectedDrafts.length === drafts.length })
  }, [selectedDrafts, drafts.length])

  const toggleAllSelected = React.useCallback(() => {
    setSelectedDrafts(current => {
      if (current.length > 0) {
        return []
      }
      return drafts.map(d => d.id)
    })
  }, [drafts])

  const toggleDraftSelected = React.useCallback((id: string) => {
    setSelectedDrafts(current => {
      const index = current.indexOf(id)
      let next: string[] = []
      if (index === -1) {
        next = [...current, id]
      } else {
        next = [...current]
        next.splice(index, 1)
      }
      return next
    })
  }, [])

  const openMediaPopup = React.useCallback((photos: string[]) => {
    setMediaPopupImages(photos)
  }, [])

  const closeMediaPopup = () => {
    setMediaPopupImages([])
  }

  const onFilter = React.useCallback((query: string, types: ContentType[], searchInSummary: boolean) => {
    setSearch({ query, types, searchInSummary })
  }, [])

  const closeEmptyView = () => {
    setIsEmptyViewClosed(true)
  }

  const isEmpty = !loading && drafts.length === 0

  return (
    <div className={styles['drafts-container']}>
      <PublishingTopBar
        activeTypes={search.types}
        totalCount={totalDraftsCount}
        draftsView
        onFilter={onFilter}
        children={<HelpToggleButton />}
      />
      <div className={styles['actions-box']}>
        {!isEmpty && (
          <BulkPostActions
            withDelete
            disabled={selectedDrafts.length === 0}
            selectedCount={selectedDrafts.length}
            allSelected={selectedDrafts.length === drafts.length}
            hidePostActions={false}
            onToggleAllSelected={toggleAllSelected}
            onBulkDeletePosts={deleteSelected}
          />
        )}
      </div>
      <ScrollListener emitTreshold={INFINITE_SCROLL_THRESHOLD} onScroll={loadNextPage}>
        <div>
          {drafts.map(draft => (
            <PostDraftView
              key={draft.id}
              draft={draft}
              isSelected={selectedDrafts.includes(draft.id)}
              className={styles.draft}
              onMultiPhotoBtnClick={openMediaPopup}
              onToggleSelected={toggleDraftSelected}
              onEditBtnClick={onEditDraft}
              onDelete={deleteDraft}
            />
          ))}
        </div>
      </ScrollListener>
      <PostFilesDialog
        active={Boolean(mediaPopupImages.length > 0)}
        images={mediaPopupImages}
        onClose={closeMediaPopup}
      />
      {isEmpty && !isEmptyViewClosed && (
        <EmptyView
          icon={mdiFeather}
          title="No saved drafts"
          subtitle={(
            <div>
              <NavLink to={{ pathname: '/composer' }} state={{ floating: true, modal: 'compose', opener: location.pathname, search }}>
                Start a new post
              </NavLink>
              &nbsp;and click “Save as draft” to save it
            </div>
          )}
          backdrop
          backdropClassName={styles['empty-backdrop']}
          onClose={closeEmptyView}
        />
      )}
      {loading && (
        <div className={styles['loading-overlay']}>
          <SimpleLoader small />
        </div>
      )}
    </div>

  )
}

export default injectIntl(DraftsManager)
