import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import ContentState from '../state'
import { addRecentSource, clearFeedToSave, getCuratedStreams, getRecentSources, removeRecentlyViewed, setFeedToSave } from './actions'
import { instanceOfStream } from '../util'
import { unique } from 'utils/unique'
import { deleteFolder } from 'services/uploads/actions'
import { action } from 'services/snackbar'

export const recommendedExtraReducers = (builder: ActionReducerMapBuilder<ContentState>) => {
  builder
    .addCase(getCuratedStreams.fulfilled, (state, action) => {
      state.curatedStreams[action.payload.category] = action.payload.streams
    })
    .addCase(getRecentSources.fulfilled, (state, action) => {
      const { items, page } = action.payload
      state.recentSources[page] = items
      Object.keys(state.recentSources).forEach((key: string) => {
        if (parseInt(key, 10) > page) {
          delete state.recentSources[key]
        }
      })
    })
    .addCase(addRecentSource, (state, action) => {
      const source = action.payload
      const id = source.id
      const currentCuratedStreams = state.curatedStreams
      let nextCuratedStreams: any = null

      if (instanceOfStream(source as any)) {
        const isPopular = Boolean(currentCuratedStreams.popular.find(s => s.id === id))
        const isIndustries = Boolean(currentCuratedStreams.industries.find(s => s.id === id))

        if (isPopular) {
          const nextPopular = unique([source, ...currentCuratedStreams.popular], 'id')
          nextCuratedStreams = Object.assign({}, currentCuratedStreams, { popular: [...nextPopular] })
        }

        if (isIndustries) {
          const nextIndustries = unique([source, ...currentCuratedStreams.industries], 'id')
          nextCuratedStreams = Object.assign({}, currentCuratedStreams, { industries: [...nextIndustries] })
        }
      }

      const currentRecentFirstPage = state.recentSources['1']
      const uniqueSources = unique([action.payload, ...(currentRecentFirstPage || [])], 'id')
      const nextRecentFirstPage = [...uniqueSources]
      const nextRecent = { ...state.recentSources, '1': nextRecentFirstPage } as { [key: string]: any }

      state.recentSources = nextRecent
      state.curatedStreams = nextCuratedStreams || state.curatedStreams
    })
    .addCase(removeRecentlyViewed.fulfilled, (state, action) => {
      const { source } = action.payload
      Object.keys(state.recentSources).forEach((key: string) => {
        state.recentSources[key] = state.recentSources[key].filter((s: any) => s.id.toString() !== source.id.toString())
      })
    })
    .addCase(setFeedToSave, (state, action) => {
      state.selectedFeedsToSave = [action.payload]
    })
    .addCase(clearFeedToSave, (state) => {
      state.selectedFeedsToSave = []
    })
    .addCase(deleteFolder.fulfilled, (state, action) => {
      Object.keys(state.recentSources).forEach((page: string) => {
        state.recentSources[page] = state.recentSources[page].filter((source: any) => {
          return source.id !== action.payload.id || !(source as any).isFileFolder
        })
      })
    })
}
