import * as React from 'react'
import { PHOTO_TYPE, PlannedPost, VIDEO_TYPE } from 'interfaces'
import styles from './PostGridItem.pcss'
import { PostContentTypeIcon } from 'components/Posts/PostContentTypeIcon'
import { FBVideoPlayer, NativeVideoPlayer } from 'components/VideoPlayer'
import BrokenImageIcon from '@mui/icons-material/BrokenImage'
import Tooltip from '@mui/material/Tooltip'
import ProfileAvatar from 'components/ProfileAvatar'
import { useSelector } from 'react-redux'
import { profilesByPpidSelector } from 'services/destinations'
import { RecycleBadge } from '../RecycleBadge'
import { ARTICLE_TYPE, GIF_TYPE, IMAGE_THUMBNAILS_DIR, STATUS_TYPE, getImagesUrlsMap } from 'shared'
import { fontSizesFor } from 'utils/fontSize'
import PlannedActionsMenu from 'components/Posts/PlannedActionsMenu'
import { useSortable } from '@dnd-kit/sortable'
import { createPortal } from 'react-dom'
import { DragOverlay } from '@dnd-kit/core'
import { FormattedMessage } from 'react-intl'
import { format } from 'date-fns'

interface PostGridItemProps {
  post: PlannedPost
  selected: boolean
  className?: string
  draggableProps?: {
    attributes: any
    listeners: any
    style: any
    isDragging: boolean
  }
  onClick: (post: PlannedPost) => void
  onToggleSelected: (postId: string) => void
  onEdit: (post: PlannedPost) => void
  onCopy: (post: PlannedPost) => void
  onDelete: (id: string) => void
  onShareNow: (post: PlannedPost) => void
}

const PostGridItem = React.forwardRef((props: PostGridItemProps, ref: React.ForwardedRef<any>) => {
  const { post, className, selected, draggableProps, onCopy, onDelete, onEdit, onShareNow, onClick, onToggleSelected } = props
  const { ppPageId } = post

  const featuredImageUrls = React.useMemo(() => {
    const imageUrl = post.images && post.images[0]
    if (!imageUrl || post.contentType === GIF_TYPE) {
      return null
    }

    const urlArr = imageUrl.split('/')
    urlArr.splice(urlArr.length - 2, 1, IMAGE_THUMBNAILS_DIR)

    const thumb = getImagesUrlsMap([imageUrl])
    const isPPHosted = imageUrl.indexOf('postplanner.com') !== -1
    return {
      original: imageUrl,
      thumb: isPPHosted ? Object.keys(thumb)[0] : imageUrl
    }
  }, [post.images, post.contentType])

  const [imageUrl, setImageUrl] = React.useState(featuredImageUrls?.thumb || '')
  const profiles = useSelector(profilesByPpidSelector)
  const profile = profiles[ppPageId]

  const handleClick = () => {
    if (!post.isEmpty) {
      onClick(post)
    }
  }

  const postType = post.specialType || post.contentType

  const imageStyles = post.isEmpty ? undefined : {
    backgroundImage: `url(${imageUrl})`
  }

  const onImageError = React.useCallback((e: any) => {
    if (featuredImageUrls) {
      if (e.target && (e.target as HTMLImageElement).src !== featuredImageUrls.original) {
        (e.target as HTMLImageElement).src = featuredImageUrls.original
        setImageUrl(featuredImageUrls.original)
      }
    }
  }, [featuredImageUrls])

  const textStyle: any = { ...fontSizesFor(post.status).normal }
  if (post.details?.textBackground) {
    textStyle.backgroundImage = `url(${post.details.textBackground.backgroundUrl})`
    textStyle.fontWeight = 'bold'
    textStyle.color = '#fff'
  }

  const toggleSelected = () => {
    onToggleSelected(post.id)
  }

  const editPost = React.useCallback(() => {
    onEdit(post)
  }, [post, onEdit])

  const deletePost = React.useCallback(() => {
    onDelete(post.id)
  }, [post.id, onDelete])

  const copyPost = React.useCallback(() => {
    if (onCopy) {
      onCopy(post)
    }
  }, [post, onCopy])

  const shareNow = () => {
    onShareNow(post)
  }

  const time = post.time ? format(post.time, 'LLL d') : null

  return (
    <div
      ref={ref}
      {...draggableProps?.attributes}
      {...draggableProps?.listeners}
      style={draggableProps?.style}
      className={`${styles.post} ${post.isEmpty ? styles.empty : ''} ${styles[post.contentType]} ${className || ''}`}
      onClick={handleClick}
    >
      <img style={{ display: 'none' }} onError={onImageError} src={featuredImageUrls?.thumb} />
      {post.bucket && (
        <Tooltip title={`Bucket: ${post.bucket.name}`}>
          <div className={styles['bucket-indicator']} style={{ backgroundColor: post.bucket.color }}></div>
        </Tooltip>
      )}
      <div className={styles['content-box']} style={imageStyles}>
        {post.isEmpty && (
          <span className={styles.nopost}><FormattedMessage id="post.labels.empty-slot" /></span>
        )}
        <div className={styles.header}>
          <div className={styles.left}>
            <ProfileAvatar
              brand={profile.type}
              circle
              size="32px"
              title={profile.name}
              src={profile.image}
              className={styles.avatar}
            />
            <div className={styles['time-box']}>
              <div className={styles.date}>{time}</div>
              <div className={styles.time}>
                {`${post.timeString} ${post.baseTime ? ' ish' : ''}`}
                <RecycleBadge
                  recycle={post.recycle}
                  repeatLength={post.repeatLength}
                  repeatPeriod={post.repeatPeriod}
                  repeatUntil={post.repeatUntil}
                  autoPost={post.autoPost}
                  className={styles.rbadge}
                />
              </div>
            </div>
          </div>
          {!post.isEmpty && (
            <div className={styles.right}>
              <PlannedActionsMenu
                withMoveControls={false}
                className={styles.actions}
                withRecycle={false}
                withRepeat={false}
                recycle={post.recycle}
                repeatLength={post.repeatLength}
                repeatPeriod={post.repeatPeriod}
                repeatUntil={post.repeatUntil}
                selected={selected}
                virtual={post.virtual}
                checkboxProps={{ className: styles.cb, classes: { checked: styles['cb-checked'] } }}
                checkboxContainerClassName={styles['cb-box']}
                virtualLabelClassName={styles['repeat-label']}
                menuBtnClassName={styles['menu-btn']}
                onToggleSelected={toggleSelected}
                onDelete={deletePost}
                onEdit={editPost}
                onCopy={copyPost}
                onShareNow={shareNow}
              />
            </div>
          )}
        </div>
        {post.contentType === ARTICLE_TYPE && (
          <React.Fragment>
            <div className={styles['article-img']} style={imageStyles}>
              {!featuredImageUrls && (
                <BrokenImageIcon className={styles['icon-broken-img']} />
              )}
            </div>
            <div className={styles.article}>
              {post.linkDomain && (
                <div className={`${styles.domain} text-ellipsis`}>
                  <a key="article-link" href={post.linkUrl} target="_blank" rel="noopener">{post.linkDomain}</a>
                </div>
              )}
              <div className={styles.title}>
                <a target="_blank" rel="noopener" href={post.linkUrl} title={post.linkTitle}>
                  {post.linkTitle}
                </a>
              </div>
            </div>
          </React.Fragment>
        )}
        {post.contentType === STATUS_TYPE && (
          <div className={styles.text} style={textStyle}>{post.status}</div>
        )}
        {post.nativeVideoUrl && (
          <div className={styles['video-box']}>
            <NativeVideoPlayer togglePlayOnHover videoUrl={post.nativeVideoUrl} />
          </div>
        )}
        {post.fbVideoUrl && (
          <FBVideoPlayer
            videoUrl={post.fbVideoUrl}
            videoId={post.id}
            thumbnailUrl={imageUrl}
          />
        )}
        <PostContentTypeIcon type={postType as any} className={styles['post-type-icon']} />
      </div>
    </div>
  )
})

export default PostGridItem

export const DraggablePostGridItem = React.memo((props: PostGridItemProps & { containerClassName: string }) => {
  const { attributes, listeners, setDroppableNodeRef, setDraggableNodeRef, isDragging } = useSortable({
    id: props.post.id,
    data: { post: props.post },
    disabled: !props.post.autoPost || props.post.isPosted
  })

  return (
    <div className={`${styles['post-container']} ${props.containerClassName}`} ref={setDroppableNodeRef}>
      <PostGridItem
        {...props}
        ref={setDraggableNodeRef}
        draggableProps={{
          attributes,
          listeners,
          isDragging,
          style: { opacity: isDragging ? '0.5' : '1' }
        }}
      />
      {isDragging && createPortal(
        <DragOverlay>
          <PostGridItem {...props} />
        </DragOverlay>,
        document.body
      )}
    </div>

  )
}, (prevProps, nextProps) => {
  return prevProps.containerClassName === nextProps.containerClassName && prevProps.selected === nextProps.selected
})
