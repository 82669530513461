import { FeatureInfo, PlanLimitInfo } from 'interfaces'
import { PPProduct } from 'interfaces/Product/PPProduct'

export interface ProductState {
  products: PPProduct[]
  features: FeatureInfo[]
  limits: PlanLimitInfo[]
  featureUpgrade?: string
  productUpgrade?: string
  displayContentUpgradePopup: boolean
  closeUpgradePopupCallback?: () => void
}

export function initialState(): ProductState {
  return {
    products: [],
    displayContentUpgradePopup: true,
    features: [],
    limits: []
  }
}
