import * as React from 'react'
import ColorSelector from 'components/ColorSelector'
import Button from '@mui/material/Button'
import { FileFolder, IndexedObject, WithIntl } from 'interfaces'
import { FormattedMessage, injectIntl } from 'react-intl'
import { defaultStreamColor, ColorGradient, getGradientFromString } from 'utils/colors'
import FileFolderCard from 'components/FileFolderCard'
import ImageSelector from 'components/CreateStreamForm/ImageSelector'
import { getRandomElements } from 'shared/utils'
import styles from './CreateFileFolderForm.pcss'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import TextField from '@mui/material/TextField'
import { useDispatch } from 'react-redux'
import { message } from 'services/snackbar'

interface CreateFileFolderFormProps extends WithIntl {
  loading: boolean
  filesCount: number
  images: IndexedObject<string>
  updateFolder?: FileFolder
  onSubmit: (
    name: string,
    color: ColorGradient,
    isPrivate?: boolean,
    featuredImage?: string | File,
    coverImages?: string[]
  ) => boolean | void
  onCancel?: () => void
  isPrivateOptionAllowed?: () => boolean
}

export function CreateFileFolderForm(props: CreateFileFolderFormProps) {
  const dispatch = useDispatch()
  const imageUrls = Object.values(props.images)
  const [name, setName] = React.useState(props.updateFolder?.name || '')
  const [color, setColor] = React.useState(props.updateFolder ? getGradientFromString(props.updateFolder.color) : defaultStreamColor)
  const [imageFile, setImageFile] = React.useState<File | null>(null)
  const [imageSrc, setImageSrc] = React.useState(props.updateFolder?.featuredImage || imageUrls[0])
  const [selectedImageIndex, setSelectedImageIndex] = React.useState<number | null>(props.updateFolder ? null : 0)
  const [secondaryImages, setSecondaryImages] = React.useState<string[]>(props.updateFolder?.coverImages || [])
  const [isPrivate, setIsPrivate] = React.useState(props.updateFolder?.isPrivate || false)
  const [colorPickerKey, setColorPickerKey] = React.useState(0)
  // Display single image when folder files are less than 3 or when creating new folder
  const singleImageMode = !props.updateFolder || Object.keys(props.images).length <= 2

  React.useEffect(() => {
    const images = Object.values(props.images)
    if ((!imageSrc || images.indexOf(imageSrc) === -1) && !props.updateFolder && Object.values(props.images)[0]) {
      setImageSrc(images[0])
      setSelectedImageIndex(0)
    }
  }, [imageSrc, props.images, props.updateFolder])

  const resetForm = () => {
    setName('')
    setColor(defaultStreamColor)
    setImageFile(null)
    setImageSrc('')
    setIsPrivate(false)
    setSecondaryImages([])
    setSelectedImageIndex(null)
    setColorPickerKey(current => current + 1)
  }

  const toggleIsPrivate = () => {
    setIsPrivate(current => !current)
  }

  const onCancel = () => {
    if (props.onCancel) {
      props.onCancel()
    }
    resetForm()
  }

  const onSubmit = () => {
    const folderName = name.trim()
    if (folderName.length === 0) {
      dispatch(message('Please enter folder name', 'warning'))
      return
    }

    if (isPrivate && props.isPrivateOptionAllowed && !props.isPrivateOptionAllowed()) {
      return
    }

    let featuredImage: any = imageFile
    if (!featuredImage && typeof selectedImageIndex === 'number') {
      featuredImage = Object.keys(props.images)[selectedImageIndex]
    }

    const skipReset = props.onSubmit(name, color, isPrivate, featuredImage, secondaryImages)
    if (!skipReset) {
      resetForm()
    }
  }

  const randomizeSecondaryImages = (excludeIndex?: number) => {
    if (singleImageMode) {
      return
    }
    const secondarySelection = Object.values(props.images)
    if (typeof excludeIndex === 'number') {
      secondarySelection.splice(excludeIndex, 1)
    }
    const randomPick = getRandomElements(secondarySelection, 2)
    setSecondaryImages(randomPick)
  }

  const onFileChanged = (file: File) => {
    setImageFile(file)
    const fileReader = new FileReader()
    fileReader.onload = () => {
      setImageSrc(fileReader.result as string)
      setSelectedImageIndex(null)
      randomizeSecondaryImages()
    }
    fileReader.onerror = () => console.error('File could not be loaded.')
    fileReader.readAsDataURL(file)
  }

  const onSelectedImageIndexChange = (index: number) => {
    const selectedImageKey = Object.keys(props.images)[index]
    setImageSrc(props.images[selectedImageKey] || '')
    setSelectedImageIndex(index)
    randomizeSecondaryImages(index)
    setImageFile(null)
  }

  const onValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setName(value)
  }

  const accessSelectOptions: PPSelectOptions = {
    private: { label: 'Private (me only)' },
    public: { label: 'Public (available to team members)' }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.label}>
          <FileFolderCard
            folder={{
              name: name || props.intl.formatMessage({ id: 'uploads.labels.new-folder-empty-name' }),
              color: `${color.from},${color.to}`,
              files: {},
              filesCount: props.filesCount,
              filters: [],
              order: 0,
              id: 'new',
              isFileFolder: true,
              isPrivate: false,
              featuredImage: imageSrc,
              coverImages: secondaryImages
            }}
            noHover
            hideFileCount
            disableNavigation
            className={styles['file-folder-preview']}
          />
        </div>
        <div className={styles['row-value']}>
          <TextField
            value={name}
            disabled={props.loading}
            placeholder={props.intl.formatMessage({ id: 'uploads.hint.input-name' })}
            classes={{ root: styles['input-box'] }}
            onChange={onValueChanged}
          />
          <div className={styles['color-box']}>
            <ColorSelector
              key={colorPickerKey}
              initialColor={color}
              className={styles['color-selector']}
              onSelectionChanged={setColor as any}
            />
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>
          Cover image
        </div>
        <div className={styles['row-value']}>
          <ImageSelector
            imageUrls={Object.values(props.images)}
            imageFileSrc={imageFile ? imageSrc : undefined}
            selectedIndex={selectedImageIndex}
            className={styles['img-selector']}
            onFileChanged={onFileChanged}
            onSelectedIndexChange={onSelectedImageIndexChange}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>
          Accessibility
        </div>
        <div className={styles['row-value']}>
          <PPSelect
            raised
            options={accessSelectOptions}
            selectedValue={isPrivate ? 'private' : 'public'}
            onSelectionChange={toggleIsPrivate}
          />
        </div>
      </div>
      <div className={styles.actions}>
        <Button variant="text" onClick={onCancel}>
          <FormattedMessage id="actions.cancel" defaultMessage="cancel" />
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={name.trim().length === 0 || props.loading}
          className={styles['btn-save']}
          onClick={onSubmit}
        >
          <FormattedMessage
            id={props.updateFolder ? 'uploads.popup.actions.update-folder' : 'uploads.popup.actions.create-folder'}
            values={{ count: props.filesCount }}
          />
        </Button>
      </div>
    </div>
  )
}

export default injectIntl(CreateFileFolderForm)
