import { connect } from 'react-redux'
import { RangeFilter, ContentType, STATUS_TYPE, ARTICLE_TYPE, PHOTO_TYPE, VIDEO_TYPE } from 'interfaces'
import { getPhotos } from 'services/content/photos/actions'
import { getArticles } from 'services/content/articles/actions'
import { getStatusIdeas } from 'services/content/statuses/actions'
import { getVideos } from 'services/content/videos/actions'
import { StoreState, StoreThunkDispatch } from 'store/state'
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/of'
import 'rxjs/add/observable/empty'
import 'rxjs/add/operator/mergeMap'
import { FetchCuratedFolderContent, FetchCuratedFolderContentProps } from './FetchCuratedFolderContent'
import { SortBy } from 'services/content/util'

export interface FetchCuratedFolderPageContentProps extends FetchCuratedFolderContentProps {
  page: number
  range: RangeFilter
  sortBy?: SortBy
  skip?: ContentType[]
}

function mapStateToProps(_state: StoreState, ownProps: FetchCuratedFolderContentProps) {
  const { page, range, folder, sortBy } = ownProps as FetchCuratedFolderPageContentProps
  return {
    ...ownProps,
    hash: `folder:${folder.slug}:${page}:${range}:${sortBy || 'any'}` as string | undefined
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch, ownProps: FetchCuratedFolderPageContentProps) {
  const { page, range, skip, statusesSeed, sortBy } = ownProps

  const skipVideos = skip && skip.find(s => s === VIDEO_TYPE)
  const skipPhotos = skip && skip.find(s => s === PHOTO_TYPE)
  const skipArticles = skip && skip.find(s => s === ARTICLE_TYPE)
  const skipStatuses = skip && skip.find(s => s === STATUS_TYPE)

  return {
    getVideos: (folderId: string) => skipVideos
      ? Observable.empty()
      : dispatch(getVideos(folderId, true, page, range, sortBy)).flatMap(v => Observable.of(...v.items)),
    getPhotos: (folderId: string) => skipPhotos
      ? Observable.empty()
      : dispatch(getPhotos(folderId, true, page, range, sortBy)).flatMap(p => Observable.of(...p.items)),
    getArticles: (folderId: string) => skipArticles
      ? Observable.empty()
      : dispatch(getArticles(folderId, true, page, range, sortBy)).flatMap(a => Observable.of(...a.items)),
    getStatusIdeas: (categoryIds: Array<string>) => {
      if (skipStatuses || categoryIds.length === 0) {
        return Observable.empty()
      }
      return dispatch(getStatusIdeas(categoryIds, page, statusesSeed)).flatMap(s => Observable.of(...s.items))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FetchCuratedFolderContent) as any
