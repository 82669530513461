import * as React from 'react'
import {
  ContentType,
  PHOTO_TYPE,
  ARTICLE_TYPE,
  VIDEO_TYPE,
  GIF_TYPE,
  FilterType,
  FEED_TYPE_FACEBOOK,
  ContentItem,
  STATUS_TYPE,
  ALL_RANGE_FILTER,
  StatusIdea,
  ALL_FILTER,
  IndexedObject
} from 'interfaces'
import { TEXT_TYPE } from 'interfaces/Content/ContentType'
import { FormattedMessage } from 'react-intl'
import { NavLink, useNavigate } from 'react-router-dom'
import * as FilterStore from 'utils/filter-store'
import FavoritesIcon from '@mui/icons-material/Favorite'
import { addedFavoritesSelector, savedFavoritesSelector } from 'services/content/selectors'
import { StoreThunkDispatch } from 'store/state'
import { useDispatch, useSelector } from 'react-redux'
import styles from './FavoritesView.pcss'
import EmptyView from 'components/EmptyView'
import { mdiHeartOutline, mdiLightbulbOnOutline, mdiFormatQuoteClose, mdiCommentTextOutline } from '@mdi/js'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import CardBricks from 'components/CardBricks'
import { favoritesReducer, initialState, QUOTES_FILTER, updateData } from './state'
import { Subject } from 'rxjs/Subject'
import { getFavorites, getFavoriteStatusIdeas } from 'services/content/favorites'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { renderLoadingCards } from 'routes/find/routes/home/components/utils'
import StatusIdeaCard from 'components/Card/StatusIdeaCard'
import AnyCard from 'components/Card'
import CardList from 'components/CardList'
import { unique } from 'utils/unique'
import IconAll from '@mui/icons-material/DoneAll'
import Icon from '@mdi/react'
import ArticleIcon from '@mui/icons-material/Link'
import VideoIcon from '@mui/icons-material/Videocam'
import GifIcon from '@mui/icons-material/Gif'
import PhotoIcon from '@mui/icons-material/Image'
import { setVisibleSelectableItems } from 'services/content'
import { arrayToRecord } from 'shared'

const FAV_FEED_TYPES: ContentType[] = [PHOTO_TYPE, ARTICLE_TYPE, VIDEO_TYPE, GIF_TYPE, TEXT_TYPE, STATUS_TYPE]
const ALL_FAVORITES_FILTERS = [ALL_FILTER, ...FAV_FEED_TYPES, QUOTES_FILTER]

export enum FavoritesViewMode {
  Carousel,
  Grid
}

interface FavoritesViewOwnProps {
  mode: FavoritesViewMode
  title?: string
  expandUrl?: string
  hideNav?: boolean
  className?: string
  onContentItemCompose?(content: ContentItem): void | boolean
  onContentItemClick?(content: ContentItem): void
}

export type FavoritesViewProps = FavoritesViewOwnProps

const favoritesFeed: any = {
  uniqueSource: 'favorites',
  type: FEED_TYPE_FACEBOOK,
  sources: FAV_FEED_TYPES
}

export function FavoritesView(props: FavoritesViewProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const navigate = useNavigate()
  const liveFavorites = useSelector(addedFavoritesSelector)
  const cachedFavs: any = useSelector(savedFavoritesSelector)
  const [filter, setFilter] = React.useState<string>(FilterStore.getFeedFilters(favoritesFeed).type)
  const [data, favDispatch] = React.useReducer(favoritesReducer, initialState)

  const icons: IndexedObject<React.ReactNode> = {
    [PHOTO_TYPE]: <PhotoIcon className={styles.icon} />,
    [ARTICLE_TYPE]: <ArticleIcon className={styles.icon} />,
    [VIDEO_TYPE]: <VideoIcon className={styles.icon} />,
    [GIF_TYPE]: <GifIcon className={styles.icon} />,
    [TEXT_TYPE]: <Icon path={mdiCommentTextOutline} size="18px" className={styles.icon} />,
    [STATUS_TYPE]: <Icon path={mdiLightbulbOnOutline} size="18px" className={styles.icon} />,
    [QUOTES_FILTER]: <Icon path={mdiFormatQuoteClose} size="18px" className={styles.icon} />,
    [ALL_FILTER]: <IconAll className={styles.icon} />
  }

  const fetchFavorites = React.useCallback(async (type: ContentType) => {
    try {
      const response: any = type === STATUS_TYPE
        ? await dispatch(getFavoriteStatusIdeas(0)).unwrap()
        : await dispatch(getFavorites({ page: 0, type, range: ALL_RANGE_FILTER })).unwrap()

      const items = Array.isArray(response) ? response : response.items
      favDispatch(updateData(type, { error: false, items, loading: false }))
    } catch (e) {
      favDispatch(updateData(type, { error: true, items: [], loading: false }))
      console.log(`Error fetching favorites of type ${type}`, e)
    }
  }, [dispatch])

  React.useEffect(() => {
    FAV_FEED_TYPES.forEach(type => fetchFavorites(type))
  }, [fetchFavorites])

  const filterOptions = ALL_FAVORITES_FILTERS.reduce((options: PPSelectOptions, key) => {
    options[key] = {
      label: (
        <span className={styles.label}>
          {icons[key]}
          <FormattedMessage id={key === ALL_FILTER ? 'label.generic.all' : `general.content.filters.${key}`} />
        </span>
      )
    }
    return options
  }, {})

  const onTypeChange = (type: FilterType | typeof QUOTES_FILTER) => {
    setFilter(type)
    FilterStore.setFeedFilters(favoritesFeed, type as any, ALL_RANGE_FILTER)
  }

  const navigateBack = (e: React.MouseEvent) => {
    e.preventDefault()
    navigate(-1)
  }

  const items = React.useMemo(() => {
    const isGridView = props.mode === FavoritesViewMode.Grid
    const contentType = filter === QUOTES_FILTER ? STATUS_TYPE : filter
    if (contentType !== ALL_FILTER) {
      const state = data[contentType]
      if (state.loading || state.error) {
        return renderLoadingCards(undefined, false, !isGridView, !state.error)
      }
      if (contentType === STATUS_TYPE) {
        const liveFavTexts = Object.values(liveFavorites[STATUS_TYPE])
        const quotesOnly = filter === QUOTES_FILTER
        const items: StatusIdea[] = unique([...state.items, ...liveFavTexts], 'id')
          .filter((text: any) => Boolean(text.isQuote) === quotesOnly) as any
        return items.map(item => (
          <StatusIdeaCard key={item.id} content={item} small={!isGridView} />
        ))
      }
    }

    let items = filter === ALL_FILTER ? Object.values(data).map(data => data.items).flat() : data[contentType].items
    if (filter === ALL_FILTER) {
      const allLiveFavs = Object.values(liveFavorites).map(favs => Object.values(favs)).flat()
      items = unique([...items, ...allLiveFavs], 'id')
    } else {
      const liveFavsOfType = Object.values(liveFavorites[filter])
      items = unique([...items, ...liveFavsOfType], 'id')
    }

    return items.map(item => item.type === STATUS_TYPE ? (
      <StatusIdeaCard key={item.id} content={item as any} small={!isGridView} />
    ) : (
      <AnyCard
        key={item.id}
        content={item}
        children={item}
        square={!isGridView}
        small={!isGridView}
        onClick={props.onContentItemClick}
        onCompose={props.onContentItemCompose}
      />
    ))
  }, [data, filter, props.mode, liveFavorites])

  React.useEffect(() => {
    if (props.mode === FavoritesViewMode.Grid && [PHOTO_TYPE, ARTICLE_TYPE, VIDEO_TYPE, GIF_TYPE].includes(filter)) {
      dispatch(setVisibleSelectableItems(arrayToRecord(data[filter].items as any[], 'id')))
    } else {
      dispatch(setVisibleSelectableItems({}))
    }
  }, [filter, data, props.mode, dispatch])

  const errorView = React.useMemo(() => {
    const contentType = filter === QUOTES_FILTER ? STATUS_TYPE : filter
    if (items.length > 0 || data[contentType].loading) {
      return null
    }
    const isCarousel = props.mode === FavoritesViewMode.Carousel
    return (
      <EmptyView
        title={<FormattedMessage id={`favorites.labels.no-${filter}`} />}
        icon={mdiHeartOutline}
        subtitle={(
          <div>
            Click ♡ on any content to save it to your favorites.
          </div>
        )}
        carousel={isCarousel}
        className={isCarousel ? undefined : styles.error}
      />
    )
  }, [data, filter, items.length, props.mode])

  if (props.mode === FavoritesViewMode.Carousel) {
    const cachedItems = Object.assign({}, ...Object.values(cachedFavs))
    if (Object.keys(cachedItems).length === 0) {
      return null
    }
  }

  const expandUrl = props.expandUrl || '/content/favorites'
  const expanded = props.mode === FavoritesViewMode.Grid
  const contentType = filter === QUOTES_FILTER ? STATUS_TYPE : filter

  return (
    <section
      className={`${styles.container} ${props.className || ''}`}
      data-test="favorites-view"
    >
      <header className={styles.header}>
        <h2 className={`${styles.title} text-ellipsis`}>
          {expanded && <FavoritesIcon className={styles.avatar} />}
          {props.title || <FormattedMessage id="find.home.sections.favorites" />}
        </h2>
        {props.hideNav ? null : expanded ? (
          <a onClick={navigateBack} className={`${styles.navigation} text-ellipsis`}>
            <FormattedMessage id="general.carousel.nav.back" />
          </a>
        ) : (
          <NavLink to={expandUrl} className={`${styles.navigation} text-ellipsis`}>
            <FormattedMessage id="general.carousel.nav.see-all" />
          </NavLink>
        )}
        <PPSelect
          options={filterOptions}
          selectedValue={filter}
          withCaret
          raised
          onSelectionChange={onTypeChange}
        />
      </header>
      {expanded ? (
        <div className={styles.wrapper}>
          <CardBricks key={`${filter}-${data[contentType].loading}`}>
            {items}
          </CardBricks>
          {errorView}
        </div>
      ) : (
        <div className={styles.carousel}>
          <CardList key={filter}>
            {items}
          </CardList>
          {errorView}
        </div>
      )}
    </section>
  )
}

export default FavoritesView
