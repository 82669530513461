import * as React from 'react'
import Tooltip from '@mui/material/Tooltip'

export function withTooltip<T>(WrappedComponent: React.ComponentClass<T> | React.FunctionComponent<T>) {
  const WithTooltip = function (props: T & { title: string }) {
    const { title, ...componentProps } = props
    return (
      <Tooltip title={title}>
        <WrappedComponent {...componentProps as any} />
      </Tooltip>
    )
  }
  WithTooltip.displayName = `Tooltip(${WrappedComponent.displayName || WrappedComponent.name})`
  return WithTooltip
}
