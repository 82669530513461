import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import SocialIcon from 'components/SocialIcon'
import { PostDestinationType } from 'interfaces'
import * as React from 'react'
import styles from './MiniProfileAvatar.pcss'

interface MiniProfileAvatarProps {
  imageUrl: string
  tooltip: string | React.ReactElement
  network: PostDestinationType
  className?: string
}

export function MiniProfileAvatar(props: MiniProfileAvatarProps) {
  return (
    <Tooltip title={props.tooltip}>
      <div className={`${styles.box} ${props.className || ''}`}>
        <div className={styles.img}>
          {/* <img src={props.imageUrl} /> */}
          <Avatar src={props.imageUrl} className={styles.avatar} />
        </div>
        <SocialIcon icon={props.network} size="14px" className={styles.icon} />
      </div>
    </Tooltip>
  )
}
