import * as React from 'react'
import { CardList, ITEM_WIDTH_SMALL, CARD_LIST_HEIGHT_SMALL } from 'components/CardList'
import FileFolderCard from 'components/FileFolderCard'
import { FormattedMessage, injectIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { FileFolder, WithIntl } from 'interfaces'
import { renderLoadingCards } from '../utils'
import { URL_SEGMENT_FILE_FOLDER } from 'config'
import styles from '../HomeSection.pcss'
import { useDispatch, useSelector } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { myFileFoldersArraySelector } from 'services/uploads/selectors'
import { getUploads } from 'services/uploads/actions'
import EmptyView from 'components/EmptyView'
import { sortByKeyAscending } from 'utils/sort/order'
const LOADING_CARDS_COUNT = 10

export interface MyLibraryFoldersCarouselProps extends WithIntl {
  title?: string
}

export const MyLibraryFoldersCarousel = React.memo(function (props: MyLibraryFoldersCarouselProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const folders = useSelector(myFileFoldersArraySelector)
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    dispatch(getUploads()).unwrap().finally(() => setLoading(false))
  }, [])

  if (folders.length === 0) {
    return null
  }

  const isEmpty = !loading && folders.length === 0

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h2 className={`${styles.title} text-ellipsis`}>
          {props.title || <FormattedMessage id="find.home.sections.my-library-folders" />}
        </h2>
        <NavLink to="/content/library" className={`${styles.navigation} text-ellipsis`}>
          <FormattedMessage id="general.carousel.nav.see-all" />
        </NavLink>
      </header>

      <CardList
        itemWidth={ITEM_WIDTH_SMALL}
        listHeight={CARD_LIST_HEIGHT_SMALL}
        containerClassName={styles['card-list']}
      >
        {
          folders.sort(sortByKeyAscending('order')).map(item => (
            <FileFolderCard
              key={item.id}
              folder={item as FileFolder}
              navUrl={`/content/${URL_SEGMENT_FILE_FOLDER}`}
              location="/content"
            />
          )).concat(loading ? renderLoadingCards(LOADING_CARDS_COUNT, true, true) : [])
        }
      </CardList>
      {isEmpty && (
        <EmptyView
          title={props.intl.formatMessage({ id: `uploads.error.empty-folders-carousel-title` })}
          subtitle={(
            <div>
              Go to <NavLink to="/content/library">Library</NavLink> to upload media files.
            </div>
          )}
          carousel
          className={styles['sm-error-box']}
        />
      )}
    </section>
  )
})

export default injectIntl(MyLibraryFoldersCarousel)
