import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/of'
import 'rxjs/add/observable/throw'

import { EmptyPayloadException } from './exceptions'
import { Result, ErrorResult } from './interfaces'

/**
 * Unwraps the wrapped value or returns an error observable
 *
 * @export
 * @template T
 * @template K
 * @template R
 * @param {K} key
 * @param {(R | ErrorResult)} wrapped
 * @returns
 */
export function unwrap<T, K extends string, R extends Result<T, K>>(key: K, wrapped: R | ErrorResult): Observable<T> {
  if ('error' in wrapped) {
    return Observable.throw(new Error((wrapped as ErrorResult).error))
  }

  const payload = (wrapped as Result<T, K>)[key]
  return payload
    ? Observable.of(payload)
    : Observable.throw(new EmptyPayloadException(`Nothing found at ${key}`))
}

/**
 * Create the function to unwrap a value
 *
 * @export
 * @template T The result type after unwrapping
 * @template K the key string name that should be unwrapped
 * @template R The type before unwrapping
 * @param {K} key the key that should be unwrapped
 * @returns Observable of T or ErrorResult
 */
export function createUnwrap<T, K extends string, R extends Result<T, K>>(key: K) {
  return function (wrapped: R | ErrorResult): Observable<T> {
    return unwrap(key, wrapped)
  }
}
