import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import ImageIcon from '@mui/icons-material/Image'
import { INPUT_ACCEPT_PHOTO } from 'utils/file/constants'

import styles from './AvatarUploader.pcss'

export interface AvatarUploaderProps {
  imageUrl?: string
  className?: string
  labelClassName?: string
  emptyOverlayClassName?: string
  labelAdd?: string
  labelChange?: string
  icon?: React.ReactElement
  onFileUploaded: (file: File) => void
  onError: (error: string) => void
}

export function AvatarUploader(props: AvatarUploaderProps) {
  const [image, setImage] = React.useState(props.imageUrl || '')
  const labelChange = props.labelChange || 'CHANGE PHOTO'
  const labelAdd = props.labelAdd || 'ADD PHOTO'

  const onFileSelected = (e: any) => {
    const file = e.target.files ? e.target.files[0] : undefined
    e.target.value = ''
    if (!file) {
      return
    }

    const fileReader = new FileReader()
    fileReader.onload = () => {
      setImage(fileReader.result as string)
      props.onFileUploaded(file)
    }
    fileReader.onerror = () => props.onError('File could not be loaded.')
    fileReader.readAsDataURL(file)
  }

  const emptyIcon = props.icon
    ? React.cloneElement(props.icon, { key: 'icon', className: styles['icon-no-avatar'] })
    : null

  return (
    <div className={`${styles.container} ${props.className || ''}`} data-testid="avatar-uploader">
      {!image && (
        <div className={styles['image-overlay-box']}>
          {emptyIcon || <ImageIcon className={styles['icon-img']} />}
          <div className={`${styles['upload-image-text']} ${props.emptyOverlayClassName || ''}`}>
            {labelAdd}
          </div>
        </div>
      )}
      {image && <Avatar className={styles.avatar} src={image} />}
      <div className={styles['btn-edit']}>
        {image && <div className={`${styles['label-change']} ${props.labelClassName || ''}`}>{labelChange}</div>}
        <input type="file" name="uploader" accept={INPUT_ACCEPT_PHOTO.join(',')} onChange={onFileSelected} />
      </div>
    </div>
  )
}

export default AvatarUploader
