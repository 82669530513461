import { DndContext, useDroppable, useSensors, useSensor, PointerSensor, MeasuringStrategy } from '@dnd-kit/core'
import { SortableContext, rectSortingStrategy, useSortable } from '@dnd-kit/sortable'
import * as React from 'react'
import { PlannedPost as IPlannedPost, ContentType, IndexedObject } from 'interfaces'
import { ScrollListener } from 'components/ScrollListener'
import { PostGroup } from '../PostGroup'
import { DraggablePlannedPost } from 'components/Posts/PlannedPost'
import EmptyPostSlot from 'components/Posts/EmptyPostSlot'
import { useDispatch } from 'react-redux'
import { message } from 'services/snackbar'
import BulkPostActions, { BulkPostActionsProps } from 'routes/publishing/components/BulkActionsMenus/BulkPostActions'
import styles from './PlannedPostsList.pcss'
import { isElementInViewport } from 'utils/dom'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { reorder } from 'shared'

const SCROLL_TRESHOLD = 350
const POST_DROP_ERROR_MESSAGE_TIMEOUT = 4000

export interface PostGroupData {
  posts: IPlannedPost[]
  index?: number
  key?: string
}

export interface PlannedPostsListProps {
  posts: IPlannedPost[]
  selectedPosts: { [id: string]: boolean } | 'all'
  activePreviewPostId?: string
  bulkActionsProps: BulkPostActionsProps
  bucketsView?: boolean
  skipReorderChecks?: boolean
  onLoadNextPage: () => void
  onRecycleChange: (postId: string, currentValue: number | boolean | Date, forceRefresh?: boolean) => void
  onTogglePostSelected: (id: string) => void
  onEditPost: (post: IPlannedPost) => void
  onCopyPost: (post: IPlannedPost) => void
  onDeletePost: (id: string) => void
  onShareNow?: (post: IPlannedPost) => void
  onMovePost?: (id: string, position: 'top' | 'bottom') => void
  onPostOrderChanged?: (movedPost: IPlannedPost, targetPost: IPlannedPost) => void
  onMediaPreviewBtnClick: (post: IPlannedPost) => void
  onPostClick: (post: IPlannedPost) => void
  renderPosts?: (postGroups: IndexedObject<PostGroupData>) => React.ReactNode
}

export const PlannedPostsList = React.memo((props: PlannedPostsListProps) => {
  const dispatch = useDispatch()
  const { skipReorderChecks, onPostOrderChanged } = props
  const [movingPost, setMovingPost] = React.useState<IPlannedPost | null>(null)
  const [postGroups, setPostGroups] = React.useState<IndexedObject<PostGroupData>>({})
  const scrollElementRef = React.useRef<HTMLElement | undefined>(undefined)
  const [order, setOrder] = React.useState(props.posts.map(p => p.id))

  React.useLayoutEffect(() => {
    scrollElementRef.current = document.querySelector('[data-test="main"]') as HTMLElement
  }, [])

  React.useLayoutEffect(() => {
    const scrollOffsetTop = 120
    if (props.activePreviewPostId) {
      const element = document.querySelector(`[data-post-id="${props.activePreviewPostId}"]`) as HTMLElement
      if (element && !isElementInViewport(element, scrollOffsetTop)) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }, [props.activePreviewPostId])

  React.useEffect(() => {
    const grouped = props.posts.reduce((groups: { [title: string]: PostGroupData }, post: IPlannedPost) => {
      const group = groups[post.weekday]
      if (!group) {
        groups[post.weekday] = {
          posts: [post]
        }
      } else {
        group.posts.push(post)
      }
      return groups
    }, {})

    Object.keys(grouped).forEach((weekday, index) => {
      const posts = grouped[weekday].posts.map((p, index) => {
        return {
          postId: p.id,
          profileId: p.profileId,
          autoPost: Boolean((p as IPlannedPost).autoPost),
          order: (p as IPlannedPost).autoPostOrder,
          index
        }
      })

      grouped[weekday].key = JSON.stringify({ posts, weekday, groupIndex: index })
      grouped[weekday].index = index
    })
    setPostGroups(grouped)
  }, [props.posts])

  const onDragStart = (data: any) => {
    const post = props.posts.find(p => p.id === data.active.id)
    if (post) {
      setMovingPost(post)
    }
  }

  const onDragEnd = React.useCallback((data: any) => {
    setMovingPost(null)
    const { active, over } = data
    const draggedPost = active.data?.current?.post as IPlannedPost
    const targetPost = over?.data?.current?.post as IPlannedPost | null

    if (!targetPost) {
      return
    }

    // EXPL: Check if post is dropped at a valid slot:
    //  - not on a scheduled post (not autoPost)
    //  - not on post of different profile
    //  - slots buckets not matching

    const bucketsMatching = draggedPost.bucketId === targetPost.bucketId
    const dropInvalid = !targetPost.autoPost || targetPost.profileId !== draggedPost.profileId || !bucketsMatching

    if (!skipReorderChecks && dropInvalid) {
      dispatch(message(
        `Oops!.. You can't drop your post there. Please drag it to a valid time slot outlined in green.`,
        'warning',
        POST_DROP_ERROR_MESSAGE_TIMEOUT
      ))
      return
    }

    const oldIndex = order.indexOf(active.id)
    const newIndex = order.indexOf(over.id)
    const reordered = reorder(order, oldIndex, newIndex)
    setOrder(reordered)

    if (onPostOrderChanged) {
      onPostOrderChanged(draggedPost, targetPost)
    }
  }, [skipReorderChecks, onPostOrderChanged, dispatch, order])

  if (props.posts.length === 0) {
    return null
  }

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis]}
      layoutMeasuring={{ strategy: MeasuringStrategy.Always }}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
    >
      <div className={styles.wrapper} data-test="post-list">
        <div className={styles.actions}>
          <BulkPostActions {...props.bulkActionsProps} />
        </div>
        <ScrollListener
          scrollElement={scrollElementRef.current}
          emitTreshold={SCROLL_TRESHOLD}
          onScroll={props.onLoadNextPage}
        >
          <div>
            <SortableContext items={order} strategy={rectSortingStrategy}>
              {props.renderPosts && props.renderPosts(postGroups)}
              {!props.renderPosts && Object.keys(postGroups)
                .map((title) => (
                  <PostGroup title={title} key={`group-${title}`} className={styles.group}>
                    {postGroups[title].posts.map((post) => {
                      if (post.isEmpty) {
                        const className = movingPost ? `${styles['empty-slot']} ${styles['slot-disabled']}` : styles['empty-slot']
                        return (
                          <EmptyPostSlot
                            timeString={post.timeString}
                            baseTime={post.baseTime}
                            profile={{
                              type: post.network,
                              image: post.profilePictureUrl,
                              name: post.name as string
                            }}
                            bucket={post.bucket}
                            className={className}
                          />
                        )
                      }

                      let className = props.activePreviewPostId === post.id ? styles.highlight : ''
                      if (
                        movingPost
                        && movingPost.profileId === post.profileId
                        && post.buckets.includes(movingPost.contentType)
                        && movingPost.buckets.includes(post.contentType)
                        && movingPost.id !== post.id
                        && movingPost.bucketId === post.bucketId
                      ) {
                        className = (movingPost as IPlannedPost).autoPostOrder < (post as IPlannedPost).autoPostOrder
                          ? styles['drop-below']
                          : styles['drop-above']
                      }

                      return (
                        <DraggablePlannedPost
                          key={post.id}
                          selected={props.selectedPosts === 'all' || !!props.selectedPosts[post.id]}
                          post={post}
                          className={className}
                          withBucket={props.bucketsView}
                          onPostClick={props.onPostClick}
                          onEdit={props.onEditPost}
                          onCopy={props.onCopyPost}
                          onDelete={props.onDeletePost}
                          onMovePost={props.onMovePost}
                          onRecycleChange={props.onRecycleChange}
                          onToggleSelected={props.onTogglePostSelected}
                          onMediaPreviewBtnClick={props.onMediaPreviewBtnClick}
                          onShareNow={props.onShareNow}
                        />
                      )
                    })}
                  </PostGroup>
                ))}
            </SortableContext>
          </div>
        </ScrollListener>
      </div>
    </DndContext>
  )
})

export default PlannedPostsList
