import * as React from 'react'
import {
  ARTICLE_TYPE,
  BRAND_FACEBOOK,
  BRAND_INSTAGRAM,
  BRAND_PINTEREST,
  BRAND_TWITTER,
  ContentItem,
  PostDestinationType
} from 'interfaces'
import { useDispatch, useSelector } from 'react-redux'
import {
  composerContentItemSelector,
  composerImageUrlsSelector,
  composerLinkSelector,
  composerSelectedProfilesSelector,
  composerVideoUrlSelector,
  composerPostSelector
} from 'services/compose/selectors'
import { BRAND_GOOGLE } from 'interfaces/Content/SocialBrand'
import { setFBPostType, toggleFBPostReshare, setComposerTweetType } from 'services/compose'
import { ComposerFacebookPost } from 'services/compose/interfaces/ComposerFacebookPost'
import { ComposerTwitterPost, TweetType } from 'services/compose/interfaces/ComposerTwitterPost'
import { ComposerFacebookPostOptions } from '../CustomizePostBlock/ComposerFacebookPostOptions'
import { ComposerPinterestPostOptions } from '../CustomizePostBlock/ComposerPinterestPostOptions'
import { ComposerTwitterPostOptions } from '../CustomizePostBlock/ComposerTwitterPostOptions'
import { ComposerInstagramPostOptions } from '../CustomizePostBlock/ComposerInstagramPostOptions'
import styles from './NetworkOptions.pcss'
import ComposerGoogleOptions from '../CustomizePostBlock/ComposerGoogleOptions'
import { BRAND_TIKTOK } from 'shared'
import { ComposerTikTokPostOptions } from '../CustomizePostBlock/TikTokOptions'
import { FB_POST_TYPE_REEL, POST_TYPE_STORY } from 'services/compose/state'
import StoreState from 'store/state'

type NetworkOptionsProps = {
  network: PostDestinationType
  title?: React.ReactNode
  contentClassName?: string
}

export function NetworkOptions(props: NetworkOptionsProps) {
  const dispatch = useDispatch()
  const post = useSelector((state: StoreState) => composerPostSelector(state, props.network))
  const imageUrls = useSelector(composerImageUrlsSelector)
  const selectedProfiles = useSelector(composerSelectedProfilesSelector)
  const link = useSelector(composerLinkSelector)
  const content = useSelector(composerContentItemSelector)
  const videoUrl = useSelector(composerVideoUrlSelector)
  const { postType } = post as any

  React.useEffect(() => {
    if (props.network === BRAND_FACEBOOK) {
      // EXPL: Only vids can be posted as reel
      // Multi-image posts can't be posted as reel or story
      // Reset the post type
      if ((!videoUrl && imageUrls.length !== 1) || (postType === FB_POST_TYPE_REEL && !videoUrl)) {
        dispatch(setFBPostType(undefined))
      }
    }
  }, [videoUrl, dispatch, props.network, postType, imageUrls.length])

  const toggleReshare = React.useCallback(() => {
    dispatch(toggleFBPostReshare())
  }, [dispatch])

  const setTweetType = React.useCallback((type: TweetType) => {
    dispatch(setComposerTweetType(type))
  }, [dispatch])

  const onPostTypeChange = React.useCallback((postType: string) => {
    const selectedPostType = postType === 'post' ? undefined : postType
    dispatch(setFBPostType(selectedPostType))
  }, [dispatch])

  const options = React.useMemo(() => {
    switch (props.network) {
      case BRAND_FACEBOOK: {
        const fbProfiles = selectedProfiles.filter(p => p.type === BRAND_FACEBOOK)
        const firstFbProfile = fbProfiles.length === 1 ? fbProfiles[0] : null
        const isFBContent = !link && content?.feed.type === BRAND_FACEBOOK
        const singleProfile = fbProfiles.length === 1 ? fbProfiles[0] : null
        const withImage = imageUrls.length > 0 && content?.type !== ARTICLE_TYPE && !link
        const showAlbums = withImage && !(post as ComposerFacebookPost).reshare
        const withAlbumsPopup = Boolean(showAlbums && !singleProfile)
        const withAlbums = showAlbums && singleProfile
        const empty = !isFBContent && !withAlbums && !withAlbumsPopup && !videoUrl
        const withReshare = isFBContent && (post as ComposerFacebookPost).postType !== POST_TYPE_STORY

        if (empty) {
          return null
        }

        return (
          <div className={`${styles['shift-l']} ${styles['flex-row']}`}>
            <ComposerFacebookPostOptions
              post={post as ComposerFacebookPost}
              withImage={withImage}
              withReshare={withReshare}
              profiles={fbProfiles}
              profile={firstFbProfile}
              withVideo={Boolean(videoUrl)}
              onToggleReshare={toggleReshare}
              onPostTypeChange={onPostTypeChange}
            />
          </div>
        )
      }

      case BRAND_TWITTER: {
        if (content?.feed.type !== BRAND_TWITTER) {
          return null
        }

        return (
          <ComposerTwitterPostOptions
            content={content as ContentItem}
            post={post as ComposerTwitterPost}
            className={styles['shift-l']}
            onSelectionChange={setTweetType}
          />
        )
      }

      case BRAND_PINTEREST: {
        return (
          <ComposerPinterestPostOptions
            profiles={selectedProfiles.filter(p => p.type === BRAND_PINTEREST)}
            className={styles['shift-l']}
          />
        )
      }

      case BRAND_INSTAGRAM: {
        return (
          <ComposerInstagramPostOptions className={styles['settings-box']} />
        )
      }

      case BRAND_GOOGLE: {
        return (
          <ComposerGoogleOptions />
        )
      }

      case BRAND_TIKTOK: {
        return <ComposerTikTokPostOptions />
      }

      default:
        return null
    }
  }, [content, imageUrls.length, link, post, props.network, selectedProfiles, setTweetType, toggleReshare, videoUrl, onPostTypeChange])

  return (
    <div className={props.contentClassName}>
      {options ? props.title : null}
      {options && (
        <div className={styles.options}>
          {options}
        </div>
      )}
    </div>
  )
}

export default NetworkOptions
