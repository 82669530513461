import { createElement } from 'react'
import { Loader } from 'components/SimpleLoader'
import loadable, { LoadableComponent } from '@loadable/component'
import { lazyLoad } from 'utils/chunk'

const AsyncSearch: LoadableComponent<any> = loadable(() => lazyLoad(
  () => import(
    /* webpackPrefetch: true */
    /* webpackChunkName: 'search' */
    './Search'
  ),
  'search'
), {
  fallback: createElement(Loader, { title: 'Loading Search...' })
})

export default AsyncSearch
