import { createElement } from 'react'
import Loader from 'components/SimpleLoader'
import loadable, { LoadableComponent } from '@loadable/component'
import { lazyLoad } from 'utils/chunk'

const AsyncTraining: LoadableComponent<any> = loadable(() => lazyLoad(
  () => import(
    /* webpackPrefetch: true */
    /* webpackChunkName: 'intro' */
    './Training'
  ),
  'training'
), {
  fallback: createElement(Loader, { title: 'Loading Trial...' })
})

export default AsyncTraining
