import StoreState from 'store/state'
import { listsAdapter } from './adapters/lists'
import { userListsSelector } from './selectors'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { V1 } from './net'
import { connectedDestinationsSelector } from 'services/destinations'

export const getLists = createAsyncThunk(
  'lists/get',
  async (force: boolean = false, { dispatch, getState }) => {
    const state = getState() as StoreState

    if (!force) {
      const storedLists = userListsSelector(state) as any
      const keys = Object.keys(storedLists)
      if (keys.length) {
        return keys.map(key => storedLists[key])
      }
    }

    const connectedPages = connectedDestinationsSelector(state)
    const connectedPageIds = Object.keys(connectedPages)

    const response = await dispatch(V1.getLists()).toPromise()
    return listsAdapter(response, connectedPageIds)
  }
)

export const updateList = createAsyncThunk(
  'lists/update',
  async (list: { id: string, name: string, pages: string[] }, { dispatch }) => {
    await dispatch(V1.updateList(list.id, list.name, list.pages)).toPromise()
    return list
  }
)

export const createList = createAsyncThunk(
  'lists/create',
  async (list: { name: string, pages: string[] }, { dispatch, getState }) => {
    const lists = userListsSelector(getState() as StoreState)
    const response = await dispatch(V1.createList(list.name, list.pages)).toPromise()
    dispatch(getLists(true))
    return { ...list, id: response.listId, order: Object.keys(lists).length }
  }
)

export const deleteList = createAsyncThunk(
  'lists/delete',
  async (id: string, { dispatch }) => {
    await dispatch(V1.deleteList(id)).toPromise()
    return id
  }
)

export const reorderLists = createAsyncThunk(
  'lists/reorder',
  async (ids: string[], { dispatch }) => {
    await dispatch(V1.reorderLists(ids)).toPromise()
    return ids
  }
)
