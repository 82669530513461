import ContentState, { SelectableContent, initialState } from './state'
import { deleteRecentQuoteSearch, setPastSearches, quoteSearchSaved } from './quotes/actions'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { contentBlocksFetched } from 'admin/services/home-config/actions'
import { contentPanelsFetched } from 'admin/services/content-panels/actions'
import { favsExtraReducers } from './favorites'
import { addRecentStatusSearch, setPastStatusesSearches, setRecentStatusSearches } from './statuses/actions'
import { feedsExtraReducers } from './feeds'
import { recommendedExtraReducers } from './recommended'
import { streamsExtraReducers } from './streams'
import { AnyContent, Feed, LiveArticleData, Paginated } from 'interfaces'

const contentSlice = createSlice({
  name: 'content',
  initialState: initialState(),
  reducers: {
    cacheContent: (state, action: PayloadAction<{ key: string, content: Paginated<AnyContent[]>, timer: number }>) => {
      const { key, content, timer } = action.payload
      content.items.forEach(contentItem => {
        const id = contentItem.id
        const item = state.cache.data[id]
        if (item) {
          state.cache.data[id].count = item.count + 1
          return
        }

        state.cache.data[id] = {
          item: contentItem,
          count: 1
        }
      })

      state.cache.sources[key] = {
        content: {
          hasNext: content.hasNext,
          items: content.items.map(item => item.id)
        },
        timer
      }
    },
    expireCache: (state, action: PayloadAction<string>) => {
      const key = action.payload
      const source = state.cache.sources[key]

      if (!source) {
        return
      }

      source.content.items.forEach(id => {
        const dataRecord = state.cache.data[id]
        if (dataRecord.count === 1) {
          delete state.cache.data[id]
        } else {
          state.cache.data[id].count = dataRecord.count - 1
        }
      })

      delete state.cache.sources[key]
    },
    setSelectedFeedsToSave: (state: ContentState, action: PayloadAction<Feed[]>) => {
      state.selectedFeedsToSave = action.payload
    },
    clearSelectedFeedsToSave: (state: ContentState) => {
      state.selectedFeedsToSave = []
    },
    addContentToSelection: (state, action: PayloadAction<SelectableContent>) => {
      state.selection.items[action.payload.id] = action.payload
    },
    removeContentFromSelection: (state, action: PayloadAction<string>) => {
      delete state.selection.items[action.payload]
    },
    clearContentSelection: (state) => {
      state.selection.items = {}
    },
    toggleSelectionPanel: (state, action: PayloadAction<boolean>) => {
      const isOpen = typeof action.payload === 'boolean' ? action.payload : !state.selection.panelOpen
      state.selection.panelOpen = isOpen
    },
    setVisibleSelectableItems: (state, action: PayloadAction<Record<string, SelectableContent>>) => {
      state.selection.itemsOnPage = action.payload
    },
    addLiveArticleData: (state, action: PayloadAction<Record<string, LiveArticleData & { id: string }>>) => {
      state.selection.liveArticleData = { ...state.selection.liveArticleData, ...action.payload }
    },
    selectAllContent: (state) => {
      // Merge live article data with items on page
      const itemsOnPage = Object.keys(state.selection.itemsOnPage).reduce((acc, id) => {
        if (state.selection.liveArticleData && state.selection.liveArticleData[id]) {
          acc[id] = {
            ...state.selection.itemsOnPage[id],
            title: state.selection.liveArticleData[id].title,
            sourceLink: state.selection.liveArticleData[id].link,
            imageUrl: state.selection.liveArticleData[id].imageSrc
          } as any
        } else {
          acc[id] = state.selection.itemsOnPage[id]
        }
        return acc
      }, {} as Record<string, SelectableContent>)
      state.selection.items = { ...state.selection.items, ...itemsOnPage }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(contentBlocksFetched, (state, action) => {
        state.blocks = action.payload.filter((b: any) => b.active)
      })
      .addCase(contentPanelsFetched, (state, action) => {
        state.panels = action.payload
        state.groups = action.payload.reduce((groups: any, panel: any) => {
          panel.streamGroups.forEach((g: any) => {
            groups[g.id] = g
          })
          return groups
        }, {})
      })
      .addCase(setPastSearches, (state, action) => {
        state.recentQuotesSearches = action.payload
      })
      .addCase(deleteRecentQuoteSearch, (state, action) => {
        state.recentQuotesSearches = state.recentQuotesSearches.filter(s => s.term !== action.payload)
      })
      .addCase(quoteSearchSaved, (state, action) => {
        const q = action.payload.term
        const index = state.recentQuotesSearches.findIndex(s => s.term === q)
        if (index === -1) {
          state.recentQuotesSearches = [{ term: q, isTag: false }, ...state.recentQuotesSearches]
        } else {
          const next = [...state.recentQuotesSearches]
          next.splice(index, 1)
          state.recentQuotesSearches = [{ term: q, isTag: false }, ...next]
        }
      })
      .addCase(setPastStatusesSearches, (state, action) => {
        state.recentStatusesSearches = action.payload
      })
      .addCase(addRecentStatusSearch, (state, action) => {
        const index = state.recentStatusesSearches.indexOf(action.payload)
        if (index === -1) {
          state.recentStatusesSearches = [action.payload, ...state.recentStatusesSearches]
        } else {
          const next = [...state.recentStatusesSearches]
          next.splice(index, 1)
          state.recentStatusesSearches = [action.payload, ...next]
        }
      })
      .addCase(setRecentStatusSearches, (state, action) => {
        state.recentStatusesSearches = action.payload
      })

    favsExtraReducers(builder)
    feedsExtraReducers(builder)
    recommendedExtraReducers(builder)
    streamsExtraReducers(builder)
  }
})

export const {
  cacheContent,
  expireCache,
  addContentToSelection,
  removeContentFromSelection,
  clearContentSelection,
  toggleSelectionPanel,
  setVisibleSelectableItems,
  selectAllContent,
  setSelectedFeedsToSave,
  clearSelectedFeedsToSave,
  addLiveArticleData
} = contentSlice.actions
const { reducer } = contentSlice
export default reducer
