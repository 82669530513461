import * as React from 'react'
import { findDOMNode } from 'react-dom'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import {
  Feed,
  FEED_TYPE_INSTAGRAM,
  FEED_TYPE_TWITTER,
  FEED_TYPE_FACEBOOK,
  FEED_TYPE_STATUS_IDEAS,
  FEED_TYPE_KEYWORD,
  ContentFeedType
} from 'interfaces'
import { SocialIcon } from 'components/SocialIcon'
import numberToHuman from 'utils/format/numberToHuman'
import { FormattedMessage } from 'react-intl'
import ConfirmDialog from 'components/ConfirmDialog'
import Tooltip from '@mui/material/Tooltip'
import IconDelete from '@mui/icons-material/Close'
import IconAdd from '@mui/icons-material/Add'
import IconCheck from '@mui/icons-material/Check'
import SearchIcon from '@mui/icons-material/Search'
import styles from './FeedCard.pcss'
import { NavLink } from 'react-router-dom'

const FEED_TYPES_WITH_STATS = [FEED_TYPE_FACEBOOK, FEED_TYPE_TWITTER, FEED_TYPE_STATUS_IDEAS]

function getFansLabel(type: ContentFeedType, count: number) {
  switch (type) {
    case FEED_TYPE_FACEBOOK:
      return <FormattedMessage id="general.feed.fans" values={{ count }} />
    case FEED_TYPE_TWITTER:
      return <FormattedMessage id="general.feed.followers" values={{ count }} />
    case FEED_TYPE_STATUS_IDEAS:
      return <FormattedMessage id="general.feed.status-ideas" values={{ count }} />
    default:
      return null
  }
}

export interface FeedCardProps {
  feed: Feed
  navigation?: string
  style?: any
  className?: string
  showFansCount?: 'always' | 'hover'
  location?: string
  disableNavigation?: boolean
  deleteConfirmationMessage?: string
  small?: boolean
  noHighlight?: boolean
  selected?: boolean
  onDelete?: (feed: Feed) => void
  onSaveButtonClick?: (feed: Feed) => void
  onClick?: (feed: Feed) => void
  onSelect?: (feed: Feed) => void
}

interface FeedCardState { }

export class FeedCard extends React.Component<FeedCardProps, FeedCardState> {
  private cardElement: HTMLElement | null

  get nameStartChar() {
    return this.props.feed.name[0].toUpperCase()
  }

  get isInstagramHandleCard() {
    const { type, handle } = this.props.feed
    return type === FEED_TYPE_INSTAGRAM && handle.indexOf('@') === 0
  }

  constructor(props: FeedCardProps) {
    super(props)

    this.onSaveFeed = this.onSaveFeed.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onTransitionEnd = this.onTransitionEnd.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  componentDidMount() {
    this.cardElement = findDOMNode(this) as HTMLElement
    this.cardElement.addEventListener('transitionend', this.onTransitionEnd)
  }

  componentWillUnmount() {
    if (this.cardElement) {
      this.cardElement.removeEventListener('transitionend', this.onTransitionEnd)
    }
  }

  onMouseEnter() {
    if (this.cardElement) {
      this.cardElement.classList.add(styles.raised)
    }
  }

  onTransitionEnd(e: any) {
    const classes = e.target.classList
    // EXPL: Check if the card has ended transition in collapsed state
    const currentScale = (e.target.getBoundingClientRect().width / e.target.offsetWidth)
    const isCollapsed = currentScale.toFixed(1) === '1.0'
    if (classes.contains(styles.raised) && isCollapsed) {
      classes.remove(styles.raised)
    }
  }

  onSaveFeed(e: Event) {
    e.preventDefault()
    e.stopPropagation()
    if (this.props.onSaveButtonClick) {
      this.props.onSaveButtonClick(this.props.feed)
    }
  }

  onClick() {
    if (this.props.onClick) {
      this.props.onClick(this.props.feed)
    }
  }

  onSelect = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (this.props.onSelect) {
      this.props.onSelect(this.props.feed)
    }
  }

  getSocialIcon = () => {
    const type = this.props.feed.type

    return <SocialIcon
      icon={type}
      className={styles['s-icon']}
      size={this.props.small ? '16px' : '24px'}
      outline={type !== FEED_TYPE_KEYWORD}
    />
  }

  renderFanCount = () => {
    const visible = this.props.showFansCount === 'always'
    const { fanCount, type, itemsCount } = this.props.feed
    const count = type === FEED_TYPE_STATUS_IDEAS ? itemsCount : fanCount

    if (!FEED_TYPES_WITH_STATS.includes(type)) {
      return null
    }

    const className = `${styles['img-overlay']} ${visible ? styles.visible : ''}`
    const countFormatted = numberToHuman(count || 0)

    return (
      <div className={className}>
        <div className={styles['fan-count']}>
          {
            Boolean(count) && [
              <p key="number" className={styles['fans-number']}>{`${countFormatted}`}</p>,
              <p key="text" className={styles['fans-text']}>{getFansLabel(type, count || 0)}</p>
            ]
          }
        </div>
      </div>
    )
  }

  onLinkClick = (e: React.MouseEvent) => {
    if (this.props.disableNavigation) {
      e.preventDefault()
    }
  }

  onDelete = () => {
    if (this.props.onDelete) {
      this.props.onDelete(this.props.feed)
    }
  }

  render() {
    const { uniqueSource, name, type, titleHTML } = this.props.feed
    const passedClassName = this.props.className
    const isStatusIdeasCard = type === FEED_TYPE_STATUS_IDEAS
    const isKeywordFeed = type === FEED_TYPE_KEYWORD
    const selected = this.props.selected

    const feedLink = this.props.navigation
      ? `${this.props.navigation}${isStatusIdeasCard ? '/' : '/feed/'}${uniqueSource}`
      : `/content/feeds/${uniqueSource}`

    const navigation = {
      pathname: feedLink,
      state: this.props.location ? {
        backUrl: this.props.location
      } : null
    }

    const socialIcon = this.getSocialIcon()

    const feedName = titleHTML
      ? <span dangerouslySetInnerHTML={{ __html: titleHTML }} />
      : name

    const imageStyles = !isKeywordFeed
      ? { backgroundImage: `url('${this.props.feed.image}')` }
      : undefined

    const fanCount = this.renderFanCount()

    return (
      <Paper
        className={`${styles.card} ${passedClassName || ''} ${this.props.small ? styles.small : ''}`}
        style={this.props.style}
        data-testid="feed-card"
        onMouseEnter={this.onMouseEnter}
        onClick={this.onClick}
      >
        {
          this.props.onDelete && (
            <ConfirmDialog message={this.props.deleteConfirmationMessage || 'Are you sure?'}>
              {(confirm) => (
                <Tooltip title={<FormattedMessage id="actions.remove" />} placement="top">
                  <IconDelete
                    fontSize="small"
                    role="button"
                    className={styles['btn-delete']}
                    onClick={confirm(this.onDelete)}
                  />
                </Tooltip>
              )}
            </ConfirmDialog>
          )
        }
        {
          this.props.onSelect && (
            <Tooltip
              title={<FormattedMessage id={selected ? 'label.generic.selected' : 'actions.select'} />}
              PopperProps={{
                sx: {
                  '&[data-popper-reference-hidden]': {
                    visibility: 'hidden',
                    'pointer-events': 'none'
                  }
                }
              }}
            >
              <IconButton
                className={`${styles['btn-add']} ${selected ? styles.selected : ''}`}
                onClick={this.onSelect}
                size="large"
              >
                {selected ? <IconCheck /> : <IconAdd />}
              </IconButton>
            </Tooltip>
          )
        }
        <NavLink to={navigation} className={`${styles['card-link']}`} onClick={this.onLinkClick}>
          <div className={styles['feed-image']} style={imageStyles}>
            {isKeywordFeed && <p className={styles['keyword-title']}>{feedName}</p>}
            {isKeywordFeed && <div className={`${styles['img-overlay']} ${styles['keyword-overlay']}`}></div>}
            {isStatusIdeasCard && <div className={`${styles['img-overlay']} ${styles['status-overlay']}`}></div>}
            {fanCount}
          </div>
          <div className={`${styles['item-info']} ${styles[type]}`}>
            <h5
              className={`${styles.title} ${this.props.noHighlight ? styles['no-highlight'] : ''} text-ellipsis`}
              title={name}
            >
              {feedName}
            </h5>
            <p className={styles['feed-type']}>{socialIcon}</p>
          </div>
        </NavLink>
      </Paper>
    )
  }
}

export default FeedCard
