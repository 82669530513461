import { PPProduct } from 'interfaces'
import React, { useContext } from 'react'
import styles from './PaymentDetailsSidebar.pcss'
import { CURRENCY_SIGN_MAP, LIMIT_PROFILES_COUNT, LIMIT_USERS_COUNT } from 'shared/constants'
import { SignupContext } from '../state/context'

interface PaymentDetailsSidebarProps {
  plan: PPProduct
  isAnnual: boolean
  isTrial: boolean
  isNoCCTrial: boolean
  currency: string
  price: number
}

export function PaymentDetailsSidebar({ plan, isAnnual, isTrial, isNoCCTrial, currency, price }: PaymentDetailsSidebarProps) {
  const [{ paymentViewConfig }] = useContext(SignupContext)
  const { name, limits } = plan
  // Plans with order <= 200 are considered basic plans
  const isBasicPlan = plan.order <= 200 // eslint-disable-line no-magic-numbers

  const faqs = Object.values(paymentViewConfig.faqs).filter(f => f.isTrial === isTrial)
  const priceFormatted = price.toFixed(2).replace('.00', '')
  const todaysPrice = isNoCCTrial ? priceFormatted : '0'

  return (
    <div className={styles.sidebar}>
      <h3 className={styles.title}>
        {`Plan: ${name} ${isAnnual ? 'annual' : 'monthly'}`}
      </h3>
      <ul className={styles.list}>
        <li className={styles.listItem}>{`${limits[LIMIT_USERS_COUNT]} users`}</li>
        <li className={styles.listItem}>{`${limits[LIMIT_PROFILES_COUNT]} social profiles`}</li>
        <li className={styles.listItem}>{`${isBasicPlan ? 'Core' : 'Premium'} content`}</li>
        <li className={styles.listItem}>{`${isBasicPlan ? 'Basic' : 'Advanced'} analytics`}</li>
      </ul>
      <div className={styles.faqs}>
        {faqs.map(faq => (
          <div className={styles.faq} key={faq.id}>
            <p className={styles.bold}>{faq.q}</p>
            <span>{faq.a}</span>
          </div>
        ))}
      </div>
      <div className={styles.section}>
        <div className={styles.title}>Summary</div>
        <div className={styles['section-content']}>
          <div className={styles.bold}>
            <span>{paymentViewConfig.priceLabel}</span>
            <span>{` ${CURRENCY_SIGN_MAP[currency]}${todaysPrice}`}</span>
          </div>
          {!isNoCCTrial && (
            <div className={styles['summary-2']}>
              <span>{`${paymentViewConfig.priceAfterTrialLabel} `}</span>
              <span>{CURRENCY_SIGN_MAP[currency]}{priceFormatted}</span>
            </div>
          )}
          <div className={styles['summary-3']}>
            {`Plan renews every ${isAnnual ? '365' : '30'} days`}
          </div>
        </div>
      </div>
    </div>
  )
}
