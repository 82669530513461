import * as React from 'react'
import { ComposerPinterestPost } from 'services/compose/interfaces/ComposerPinterestPost'
import TextField from '@mui/material/TextField'
import styles from './PinterestPostStatus.pcss'
import { extractUrlsFromText } from 'utils/composer'
import { PINTEREST_DESCRIPTION_MAX_LENGTH, PINTEREST_TITLE_MAX_LENGTH } from 'shared/constants'
import { Article, ARTICLE_TYPE, BRAND_PINTEREST, ContentItem, LinkDetails, WithIntl } from 'interfaces'
import { FormattedMessage, injectIntl } from 'react-intl'
import IconInfo from '@mui/icons-material/InfoOutlined'
import { Subject } from 'rxjs/Subject'
import { Observable } from 'rxjs/Observable'
import StoreState, { StoreThunkDispatch } from 'store/state'
import {
  composerContentItemSelector,
  composerLinkSelector,
  composerPinterestPostStatusSelector,
  ComposerPostStatus,
  composerResetKeySelector,
  composerPostSelector,
  composerPostAsPhotoSelector
} from 'services/compose/selectors'
import { setPinDescription, setPinDestinationUrl, setPinTitle, getLinkDetails } from 'services/compose'
import { connect } from 'react-redux'
import { useEffectUpdateOnly } from 'hooks/useEffectUpdateOnly'
import { debounce } from 'shared/utils'

const INPUT_DEBOUNCE_TIME = 250
interface PinterestPostStatusConnectedProps {
  post: ComposerPinterestPost
  linkDetails?: LinkDetails
  resetKey: number
  status: ComposerPostStatus
  postAsPhoto: boolean
  content?: ContentItem
  setTitle: (title: string, auto: boolean) => void
  setDescription: (text: string, auto: boolean) => void
  setDestinationUrl: (url: string) => void
  fetchLinkDetails: (url: string) => Observable<{ link: LinkDetails }>
}

type PinterestPostStatusProps = PinterestPostStatusConnectedProps & WithIntl

export function PinterestPostStatus(props: PinterestPostStatusProps) {
  const [title, setTitle] = React.useState(props.post.title)
  const [description, setDescription] = React.useState(props.post.description || '')
  const [destinationUrl, setDestinationUrl] = React.useState(props.post.destinationUrl)
  const saveTitle = debounce(props.setTitle, INPUT_DEBOUNCE_TIME)
  const saveDescription = debounce(props.setDescription, INPUT_DEBOUNCE_TIME)

  const titleRef = React.useRef<string>()
  const descriptionRef = React.useRef<string>()

  React.useEffect(() => {
    titleRef.current = title
  }, [title])

  React.useEffect(() => {
    if (props.postAsPhoto) {
      if (destinationUrl) {
        link$.current?.next('')
        setDestinationUrl('')
      }
    } else if (props.linkDetails?.type === 'link') {
      const url = props.linkDetails.url
      if (url !== destinationUrl) {
        setDestinationUrl(url || '')
        props.setDestinationUrl(url)
      }
    }
  }, [props.postAsPhoto, destinationUrl, props.linkDetails?.url, props.linkDetails?.type])

  React.useEffect(() => {
    if (!props.post.status.touched) {
      setDescription(current => {
        if (current !== props.post.description) {
          return props.post.description
        }
        return current
      })
    }
  }, [props.post.status.touched, props.post.description])

  React.useEffect(() => {
    descriptionRef.current = description
  }, [description])

  const linkDetails$ = React.useRef<LinkDetails | undefined>()
  React.useEffect(() => {
    linkDetails$.current = props.linkDetails
    if (props.linkDetails?.type === 'link') {
      updateTitle(props.linkDetails.title || '', true)
      updateDescription(props.linkDetails.description, true)
      setDestinationUrl(props.linkDetails.url || '')
    } else if (props.content?.type === ARTICLE_TYPE) {
      updateTitle((props.content as Article).title || '', true)
      updateDescription((props.content as Article).description, true)
      setDestinationUrl((props.content as Article).sourceLink || '')
    }
  }, [props.linkDetails, props.content?.type, props.content])

  useEffectUpdateOnly(() => {
    setTitle('')
    setDescription('')
    setDestinationUrl('')
  }, [props.resetKey])

  const link$ = React.useRef<Subject<string>>()
  React.useEffect(() => {
    link$.current = new Subject()
    const sub = link$.current
      .debounceTime(INPUT_DEBOUNCE_TIME)
      .map(value => {
        props.setDestinationUrl(value)
        const url = extractUrlsFromText(value)[0]
        const lastFetchedUrl = linkDetails$.current?.url
        if (url && url !== lastFetchedUrl) {
          const validUrl = url.toLowerCase().startsWith('http') ? url : `http://${url}`
          return validUrl
        }
        return null
      })
      .filter(Boolean)
      .switchMap(props.fetchLinkDetails)
      .subscribe((response: any) => {
        const link = response.link
        if (link.title && !titleRef.current) {
          setTitle(link.title)
          saveTitle(link.title, true)
        }

        if (link.description && !descriptionRef.current) {
          setDescription(link.description)
          saveDescription(link.description, true)
        }
      })

    return () => {
      sub.unsubscribe()
    }
  }, [])

  const updateTitle = (text: string, auto: boolean) => {
    setTitle(text)
    saveTitle(text, auto)
  }

  const updateDescription = (text: string, auto: boolean) => {
    setDescription(text || '')
    saveDescription(text || '', auto)
  }

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    updateTitle(value, false)
  }

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    updateDescription(value, false)
  }

  const onLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    link$.current?.next(value)
    setDestinationUrl(value)
  }

  const titleRemainingChars = PINTEREST_TITLE_MAX_LENGTH - title.length
  // eslint-disable-next-line no-magic-numbers
  const titleHelper = titleRemainingChars < 51 ? (PINTEREST_TITLE_MAX_LENGTH - title.length).toString() : undefined
  const descriptionRemainingChars = PINTEREST_DESCRIPTION_MAX_LENGTH - description.length
  // eslint-disable-next-line no-magic-numbers
  const descriptionHelper = descriptionRemainingChars < 51 ? (PINTEREST_DESCRIPTION_MAX_LENGTH - description.length).toString() : undefined

  return (
    <div className={styles.container}>
      <TextField
        value={title}
        placeholder={props.intl.formatMessage({ id: 'composer.labels.pinterest-title-placeholder' })}
        classes={{ root: styles['title-box'] }}
        helperText={titleHelper}
        FormHelperTextProps={{ className: styles.helper }}
        name="Pin title"
        onChange={onTitleChange}
      />
      <TextField
        value={description}
        multiline
        placeholder={props.intl.formatMessage({ id: 'composer.labels.pinterest-text-placeholder' })}
        className={styles['description-box']}
        classes={{ root: styles['description-input'] }}
        FormHelperTextProps={{ className: styles.helper }}
        helperText={descriptionHelper}
        name="Pin description"
        onChange={onDescriptionChange}
      />
      {props.status.caption.info?.map(key => (
        <div className={styles.hint} key={key}>
          <IconInfo />
          <p><FormattedMessage id={key} /></p>
        </div>
      ))}
      <TextField
        value={destinationUrl}
        placeholder={props.intl.formatMessage({ id: 'composer.labels.pinterest-link-placeholder' })}
        className={styles['link-box']}
        classes={{ root: styles['link-input'] }}
        onChange={onLinkChange}
      />
    </div>
  )
}

function mapStateToProps(state: StoreState) {
  return {
    post: composerPostSelector(state, BRAND_PINTEREST),
    postAsPhoto: composerPostAsPhotoSelector(state),
    status: composerPinterestPostStatusSelector(state),
    content: composerContentItemSelector(state),
    linkDetails: composerLinkSelector(state),
    resetKey: composerResetKeySelector(state)
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    setTitle: (title: string, auto: boolean) => dispatch(setPinTitle({ title, auto })),
    setDescription: (text: string, auto: boolean) => dispatch(setPinDescription({ description: text, auto })),
    setDestinationUrl: (url: string) => dispatch(setPinDestinationUrl(url)),
    fetchLinkDetails: (url: string) => {
      return Observable.fromPromise(dispatch(getLinkDetails({ url, isPinterestDestinationUrl: true })).unwrap())
    }
  }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PinterestPostStatus))
