import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import FormControlLabel from '@mui/material/FormControlLabel'
import Paper from '@mui/material/Paper'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import DatePicker from 'components/DatePicker'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { DAYS_IN_WEEK } from 'utils/calendar'
import styles from './RecycleSelector.pcss'

interface RecycleDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (value: boolean | Date | number) => void
}

function getInitialDate() {
  const value = new Date()
  value.setDate(value.getDate() + DAYS_IN_WEEK)
  return value
}

export function RecycleDialog(props: RecycleDialogProps) {
  const [selectedRadioValue, setSelectedRadioValue] = useState<'forever' | 'off' | 'date' | 'number'>('off')
  const [date, setDate] = React.useState<Date>(getInitialDate)
  const [count, setCount] = React.useState(1)

  React.useEffect(() => {
    if (props.open) {
      setDate(getInitialDate())
    }
  }, [props.open])

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value as any
    setSelectedRadioValue(value)
  }

  const onDateChange = (date: Date) => {
    setDate(date)
    setSelectedRadioValue('date')
  }

  const onRepeatCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: any = e.target.value
    value = parseInt(value, 10)
    if (Number.isNaN(value) || value < 1) {
      value = 1
    }
    setCount(value)
    setSelectedRadioValue('number')
  }

  const onApply = () => {
    let value: boolean | Date | number = false
    switch (selectedRadioValue) {
      case 'forever':
        value = true
        break
      case 'date':
        value = date
        break
      case 'number':
        value = count
        break
    }
    props.onSubmit(value)
  }

  return (
    <Dialog open={props.open}>
      <Paper classes={{ root: styles.paper }}>
        <RadioGroup aria-label="recycle" name="recycle" value={selectedRadioValue} onChange={handleRadioChange}>
          <FormControlLabel
            value="off"
            classes={{ label: styles.label }}
            control={<Radio size="small" color="primary" />}
            label={<FormattedMessage id="general.labels.recycle-stop" />}
          />
          <FormControlLabel
            value="forever"
            classes={{ label: styles.label }}
            control={<Radio size="small" color="primary" />}
            label={<FormattedMessage id="general.labels.recycle-forever" />}
          />
          <FormControlLabel
            value="date"
            classes={{ label: styles.label }}
            control={<Radio size="small" color="primary" />}
            label={(
              <div className={styles['label-calendar-box']}>
                <FormattedMessage id="general.labels.recycle-until" />
                <DatePicker
                  value={date}
                  minDate={new Date()}
                  className={styles.picker}
                  onChange={onDateChange}
                />
              </div>
            )}
          />
          <FormControlLabel
            value="number"
            classes={{ label: styles.label }}
            control={<Radio size="small" color="primary" />}
            label={(
              <div className={styles['label-number-box']}>
                <FormattedMessage id="general.labels.recycle" />
                <TextField
                  type="number"
                  value={count}
                  className={styles['input-number']}
                  onChange={onRepeatCountChange}
                />
                <FormattedMessage id="general.labels.times" />
              </div>
            )}
          />
        </RadioGroup>
        <div className={styles.actions}>
          <Button size="small" variant="text" className={styles.btn} onClick={props.onClose}>
            <FormattedMessage id="actions.cancel" />
          </Button>
          <Button size="small" variant="text" color="primary" className={styles.btn} onClick={onApply}>
            <FormattedMessage id="actions.confirm" />
          </Button>
        </div>
      </Paper>
    </Dialog>
  )
}
