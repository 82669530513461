import * as React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import styles from './PostsFilter.pcss'
import { ARTICLE_TYPE, ContentType, PHOTO_TYPE, STATUS_TYPE, VIDEO_TYPE, WithIntl } from 'interfaces'
import { POST_TYPES } from 'interfaces/Content/ContentType'
import FiltersIcon from '@mui/icons-material/Tune'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import IconCheck from '@mui/icons-material/Check'
import IconClose from '@mui/icons-material/Close'
import CollapsibleTextInput from 'components/CollapsibleTextInput/CollapsibleTextInput'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import HistoryRangePicker, { HistoryPostsTimeRange, getRangeStartByKey } from '../HistoryRangePicker/HistoryRangePicker'
import Button from '@mui/material/Button'
import { useEffectUpdateOnly } from 'hooks/useEffectUpdateOnly'

const PAPER_ELEVATION = 8
export const POST_VIEW_OPTIONS = [{
  label: <FormattedMessage id="app.nav.publishing-planned-all" />,
  value: 'posts/planned/all'
}, {
  label: <FormattedMessage id="app.nav.publishing-planned-queued" />,
  value: 'posts/planned/queued'
}, {
  label: <FormattedMessage id="app.nav.publishing-planned-queued-buckets" />,
  value: 'posts/planned/buckets'
}, {
  label: <FormattedMessage id="app.nav.publishing-planned-queued-queue" />,
  value: 'posts/planned/no-bucket'
}, {
  label: <FormattedMessage id="app.nav.publishing-pending-posts" />,
  value: 'posts/planned/queued/pending'
}, {
  label: <FormattedMessage id="app.nav.publishing-planned-scheduled" />,
  value: 'posts/planned/scheduled'
}]

interface PostsFilterProps {
  activeTypes: ContentType[]
  className?: string
  draftsView?: boolean
  postsView?: string
  range?: HistoryPostsTimeRange
  onRangeChange?: (r: HistoryPostsTimeRange, start?: string, end?: string) => void
  onFilter: (query: string, types: ContentType[], searchInSummary?: boolean) => void
  onPostViewChange?: (type: string) => void
}

export function PostsFilter(props: PostsFilterProps & WithIntl) {
  const [selectedTypes, setSelectedTypes] = React.useState<ContentType[]>(props.activeTypes)
  const [popupAnchor, setPopupAnchor] = React.useState<HTMLElement | null>(null)
  const [searchInSummary, setSearchInSummary] = React.useState(true)
  const [rangeDialogOpen, setRangeDialogOpen] = React.useState(false)
  const [showClear, setShowClear] = React.useState(false)
  const btnRef = React.useRef<HTMLButtonElement>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const { onFilter } = props

  React.useEffect(() => {
    setSelectedTypes(props.activeTypes)
  }, [props.activeTypes])

  const onSummarySearchChange = () => {
    const q = inputRef.current?.value || ''
    onFilter(q, selectedTypes, !searchInSummary)
    setSearchInSummary(!searchInSummary)
  }

  const openFiltersPopup = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setPopupAnchor(btnRef.current || null)
  }

  const closeFiltersPopup = () => {
    setPopupAnchor(null)
    setSelectedTypes(props.activeTypes)
  }

  const onToggleType = (type: ContentType) => {
    const nextTypes = selectedTypes.includes(type) ? selectedTypes.filter(t => t !== type) : [...selectedTypes, type]
    setSelectedTypes(nextTypes)
    const q = inputRef.current?.value || ''
    onFilter(q, nextTypes, searchInSummary)
  }

  const onPostTypeChange = (value: string) => {
    if (value !== props.postsView) {
      props.onPostViewChange?.(value)
      closeFiltersPopup()
    }
  }

  const onSearch = React.useCallback((value: string) => {
    onFilter(value, selectedTypes, searchInSummary)
    setShowClear(true)
  }, [onFilter, selectedTypes, searchInSummary])

  const clearSearch = () => {
    props.onFilter('', POST_TYPES)
    setShowClear(false)
    setSelectedTypes(POST_TYPES)
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const resetFilters = () => {
    clearSearch()
    if (props.onPostViewChange) {
      props.onPostViewChange('posts/planned/all')
    }
    if (props.onRangeChange) {
      props.onRangeChange('all')
    }
  }

  const closeRangeDialog = () => {
    setRangeDialogOpen(false)
  }

  const onSelectedRangeChange = (r: HistoryPostsTimeRange, startDate?: string, endDate?: string) => {
    if (startDate && endDate) {
      props.onRangeChange?.(r, startDate, endDate)
    } else if (r === 'custom' && !startDate && !endDate) {
      setRangeDialogOpen(true)
    } else {
      setRangeDialogOpen(false)
      if (r === 'all') {
        props.onRangeChange?.(r)
      } else {
        const start = getRangeStartByKey(r)?.toISOString()
        const end = new Date().toISOString()
        props.onRangeChange?.(r, start, end)
      }
    }
    closeFiltersPopup()
  }

  const contentTypeOptions = [{
    label: 'Images',
    value: PHOTO_TYPE
  }, {
    label: 'Videos',
    value: VIDEO_TYPE
  }, {
    label: 'Articles/Links',
    value: ARTICLE_TYPE
  }, {
    label: 'Text-only',
    value: STATUS_TYPE
  }]

  const rangeOptions = [{
    label: <FormattedMessage id="post.posted.time-range.latest" />,
    value: 'latest'
  }, {
    label: <FormattedMessage id="post.posted.time-range.week" />,
    value: 'week'
  }, {
    label: <FormattedMessage id="post.posted.time-range.month" />,
    value: 'month'
  }, {
    label: <FormattedMessage id="post.posted.time-range.year" />,
    value: 'year'

  }, {
    label: <FormattedMessage id="post.posted.time-range.all" />,
    value: 'all'
  }, {
    label: <FormattedMessage id="post.posted.time-range.custom" />,
    value: 'custom'
  }]

  const withRangeSelect = props.range && props.onRangeChange

  return (
    <div className={`${styles.container} ${props.className || ''}`}>
      <CollapsibleTextInput
        ref={inputRef}
        changeOnEnter
        initialActive
        disableCollapse
        type="search"
        placeholder={props.intl.formatMessage({
          id: props.draftsView ? 'post.search-drafts-placeholder' : 'post.search-posts-placeholder'
        })}
        className={styles.search}
        activeClassName={styles['search-active']}
        actionButton={(
          <div className={styles['input-actions']}>
            {showClear && (
              <IconButton
                size="small"
                className={styles['btn-filters']}
                onClick={clearSearch}
              >
                <IconClose className={styles['icon-clear']} />
              </IconButton>
            )}
            <IconButton
              ref={btnRef}
              size="small"
              className={styles['btn-filters']}
              onClick={openFiltersPopup}
            >
              <FiltersIcon className={styles['icon-filters']} />
            </IconButton>
          </div>

        )}
        onValueChanged={onSearch}
      />
      <Popper
        open={Boolean(popupAnchor)}
        anchorEl={popupAnchor}
        className={styles.popup}
        placement="bottom"
      >
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={closeFiltersPopup}>
          <Paper className={styles.wrapper} elevation={PAPER_ELEVATION}>
            <div className={styles['popup-content']}>
              {props.postsView && props.onPostViewChange && (
                <ViewOptionSelect
                  label={<FormattedMessage id="post.filters.post-type" />}
                  values={[props.postsView || '']}
                  options={POST_VIEW_OPTIONS}
                  onToggleValue={onPostTypeChange}
                />
              )}
              {withRangeSelect && (
                <ViewOptionSelect
                  label={<FormattedMessage id="post.filters.posted-range" />}
                  values={[props.range || '']}
                  options={rangeOptions}
                  onToggleValue={onSelectedRangeChange}
                />
              )}
              <ViewOptionSelect
                label={<FormattedMessage id="post.filters.content-type" />}
                values={selectedTypes}
                options={contentTypeOptions}
                onToggleValue={onToggleType}
              />
              <div className={styles.bottom}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      size="small"
                      checked={searchInSummary}
                      disabled={!selectedTypes.includes(ARTICLE_TYPE)}
                      onChange={onSummarySearchChange}
                    />
                  )}
                  classes={{ label: styles['cb-label'] }}
                  label={props.intl.formatMessage({ id: 'post.search-posts.search-in-summary' })}
                />
                <div>
                  <Button className={styles['btn-reset']} onClick={resetFilters}>
                    <FormattedMessage id="actions.reset" />
                  </Button>
                </div>
              </div>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
      {withRangeSelect && (
        <HistoryRangePicker
          value={props.range as HistoryPostsTimeRange}
          datePickerMode
          dialogOpen={rangeDialogOpen}
          onClose={closeRangeDialog}
          onValueChange={onSelectedRangeChange}
        />
      )}
    </div>
  )
}

interface ViewOptionSelectProps {
  label: React.ReactNode
  options: Array<{ label: React.ReactNode, value: string, disabled?: boolean }>
  values: string[]
  onToggleValue: (value: string) => void
}

export function ViewOptionSelect({ label, options, values, onToggleValue }: ViewOptionSelectProps) {
  const onClick = (value: string, disabled?: boolean) => () => {
    if (!disabled) {
      onToggleValue(value)
    }
  }

  return (
    <div className={styles.option}>
      <div className={styles.label}>
        {label}:
      </div>
      <div className={styles.switch}>
        {options.map(o => {
          const active = values.includes(o.value)
          return (
            <div
              key={o.value}
              className={`${active ? styles['tab-active'] : ''} ${o.disabled ? styles.disabled : ''}`}
              onClick={onClick(o.value, o.disabled)}
            >
              {o.label}
              {active && <IconCheck color="primary" fontSize="small" className={styles.icon} />}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default injectIntl(PostsFilter)
