import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isAuthenticated as isAuthenticatedSelector, lastLoginTimeSelector, currentUserSelector } from 'services/users/selectors'
import { useLocation, Navigate } from 'react-router-dom'
import { MS_IN_HOUR } from 'utils/constants'
import { logout } from 'services/auth'
import { hydrationSelector } from 'store/selectors'
import { adminLoggedInSelector } from 'admin/services/selectors'
import { StoreThunkDispatch } from 'store/state'

const AUTO_LOGOUT_INTERVAL_HOURS = 720 // 30 days
const AUTO_LOGOUT_INTERVAL_MS = AUTO_LOGOUT_INTERVAL_HOURS * MS_IN_HOUR

export interface AuthenticatedRouteProps {
  redirect?: string
  admin?: boolean
}

/**
 * Route that only mounts a component if the user is authenticated.
 * Redirects to /login or a given to "path" otherwise.
 *
 * @export
 * @returns Route element with conditional rendering
 */
export function AuthenticatedRoute(props: React.PropsWithChildren<AuthenticatedRouteProps>) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const location = useLocation()
  const isAuthenticated = useSelector(props.admin ? adminLoggedInSelector : isAuthenticatedSelector)
  const isHydrated = useSelector(hydrationSelector)
  const lastLoginTime = useSelector(lastLoginTimeSelector)
  const user = useSelector(currentUserSelector)

  React.useEffect(() => {
    if (!isHydrated || !isAuthenticated) {
      return
    }
    const now = Date.now()
    const autoLogout = lastLoginTime && lastLoginTime + AUTO_LOGOUT_INTERVAL_MS < now
    // EXPL: We want to enforce a max time between logins of 24hrs.
    // If user is logged in and more than 24hrs have passed, log them out
    if (autoLogout) {
      dispatch(logout())
    }
  }, [lastLoginTime, isAuthenticated, isHydrated, dispatch])

  if (!isHydrated) {
    return null
  }

  if (user && user.finishedSetup === 0 && !location.pathname.startsWith('/onboarding')) {
    return <Navigate to="/onboarding/timezone" replace />
  }

  if (!isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: props.redirect || '/login',
          search: location?.search || ''
        }}
        replace
        state={{ from: location }}
      />
    )
  }

  return (
    <React.Fragment>{props.children}</React.Fragment>
  )
}

export default AuthenticatedRoute
