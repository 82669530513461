import { createSelector } from 'reselect'
import { StoreState } from 'store/state'
import { initialState, ContentState } from './state'
import {
  Feed,
  Stream,
  ContentType,
  FilterType,
  ALL_FILTER,
  InteractionTrackingSource,
  FileFolder,
  ARTICLE_TYPE,
  GIF_TYPE,
  PHOTO_TYPE,
  VIDEO_TYPE,
  IndexedObject
} from 'interfaces'
import { myFileFoldersSelector } from 'services/uploads/selectors'
import { STOCK_GIF_TYPE, STOCK_PHOTO_TYPE, STOCK_VIDEO_TYPE } from 'interfaces/Content/StockContentTypes'
import { randomize } from 'shared/utils'
import { unique } from 'utils/unique'
import { TEXT_TYPE } from 'interfaces/Content/ContentType'

export const contentSelector = (state: StoreState) => state.content || initialState()
export const streamsSelector = createSelector(contentSelector, (content: ContentState) => content.streams)
export const feedsSelector = createSelector(contentSelector, (content: ContentState) => content.feeds)
export const userContentSelector = createSelector(contentSelector, (content: ContentState) => content.user)
export const userStreamIdsSelector = createSelector(userContentSelector, (content) => content.streams.map(stream => stream.id))
// Selects only saved streams (not created by the user)
export const userSavedStreamsSelector = createSelector(userContentSelector, content => content.streams.filter(s => Boolean(s.originalId)))
export const curatedStreamsSelector = createSelector(contentSelector, (content: ContentState) => content.curatedStreams)
export const userFeedsSelector = createSelector(userContentSelector, (userContent) => {
  return userContent.streams.reduce((allFeeds: Feed[], stream) => {
    allFeeds.push(...stream.feeds)
    return allFeeds
  }, [])
})
export const userStreamsSelector = createSelector(userContentSelector, userContent => userContent.streams)
export const myProfilesStreamSelector = createSelector(userStreamsSelector, (streams) => streams.find(s => !!s.protected))

export const recentSourcesSelector = createSelector(
  contentSelector,
  myFileFoldersSelector,
  (content: ContentState, fileFolders: { [key: string]: FileFolder }) => {
    const recentSourcesArray = [].concat.apply([], Object.values(content.recentSources))
    return (recentSourcesArray as InteractionTrackingSource[]).map(source => {
      if ((source as any).isFileFolder) {
        return { ...source, ...fileFolders[source.id] }
      }
      return source
    })
  }
)

export const mostRecentSourceSelector = createSelector(recentSourcesSelector, recent => recent[0])
export const selectedFeedsToSaveSelector = createSelector(contentSelector, (content: ContentState) => content.selectedFeedsToSave)
export const contentCacheSelector = createSelector(contentSelector, (content: ContentState) => content.cache)

export const contentCacheForKeySelector = createSelector([contentCacheSelector, (_s, key: string) => key], (cache, key: string) => {
  const source = cache.sources[key]

  if (!source) {
    return undefined
  }

  return {
    items: source.content.items.map(id => cache.data[id].item).filter(Boolean),
    hasNext: source.content.hasNext
  }
})

export const favoritesSelector = createSelector(contentSelector, (content) => content.favorites)
export const addedFavoritesSelector = createSelector(favoritesSelector, (favs) => {
  return favs.added
})
export const savedFavoritesSelector = createSelector(favoritesSelector, (favs) => favs.saved)
export const removedFavoritesSelector = createSelector(favoritesSelector, (favs) => favs.removed)
export const isFetchingFavoritesSelector = createSelector(favoritesSelector, favs => favs.isFetching)

export const addedFavoritesSelectorForType = createSelector(
  [addedFavoritesSelector, (_s, type: FilterType) => type],
  (added, type) => {
    if (type === ALL_FILTER) {
      return Object.values(added).reduce((allItems, itemsOfType) => {
        allItems = { ...allItems, ...itemsOfType }
        return allItems
      }, {})
    }

    return added[type]
  }
)

export const favStateSelectorForItem = createSelector(
  [
    addedFavoritesSelector,
    removedFavoritesSelector,
    (_added, _removed, id: string) => id,
    (_added, _removed, _id, type: ContentType) => type
  ],
  (added, removed, id: string, type: ContentType) => {
    const addedFavIds = Object.keys(added[type])
    const removedFavIds = Object.keys(removed[type])
    const isAdded = addedFavIds.includes(id)
    const isRemoved = removedFavIds.includes(id)

    return {
      added: isAdded,
      removed: isRemoved
    }
  }
)

export const hasFavoritesSelector = createSelector(favoritesSelector, favs => {
  const allTypes = [PHOTO_TYPE, ARTICLE_TYPE, VIDEO_TYPE, GIF_TYPE, STOCK_PHOTO_TYPE, STOCK_VIDEO_TYPE, STOCK_GIF_TYPE, TEXT_TYPE]
  const allContent = allTypes.reduce((content: string[], type: string) => {
    content.push(...Object.keys(favs.added[type]))
    content.push(...Object.keys(favs.saved[type]))
    return content
  }, [])

  return allContent.length > 0
})

export const contentBlocksSelector = createSelector(contentSelector, content => content.blocks)
export const contentPanelsSelector = createSelector(contentSelector, content => content.panels)
export const contentGroupsSelector = createSelector(contentSelector, content => content.groups)
export const recentStatusesSearchesSelector = createSelector(contentSelector, content => content.recentStatusesSearches)
export const recentQuotesSearchesSelector = createSelector(contentSelector, content => content.recentQuotesSearches)
export const searchPresetsSelector = createSelector(contentPanelsSelector, panels => {
  const searchPanel = panels.find(p => p.name === 'Search')
  if (!searchPanel) {
    return {}
  }

  return searchPanel.streamGroups.reduce((map: IndexedObject<Array<Stream | Feed>>, group) => {
    if (group.name.toLowerCase() === 'streams') {
      map.streams = randomize(group.streams)
    } else {
      const type = group.name.toLowerCase()
      const array = group.streams.reduce((feeds: Feed[], stream) => {
        feeds.push(...stream.feeds.filter(f => f.type === type))
        return feeds
      }, [])
      map[type] = randomize(unique(array, 'id'))
    }
    return map
  }, {})
})

export const contentSelectionSelector = createSelector(contentSelector, content => content.selection)
export const selectedContentItemsSelector = createSelector(contentSelectionSelector, selection => selection.items)
