import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import styles from './CopyPostsPopup.pcss'
import {
  ARTICLE_TYPE,
  ContentType,
  IndexedObject,
  List,
  PHOTO_TYPE,
  PinterestBoard,
  PlannedPost,
  PostDestination,
  PostType,
  VIDEO_TYPE, WithIntl
} from 'interfaces'
import { BRAND_GOOGLE } from 'interfaces/Content/SocialBrand'
import { FormattedMessage, injectIntl } from 'react-intl'
import Button from '@mui/material/Button'
import IconError from '@mui/icons-material/WarningRounded'
import { useDispatch, useSelector } from 'react-redux'
import { connectedDestinationsSelector, getAlbums, getPinterestBoards } from 'services/destinations'
import SidebarProfileSelector from 'components/App/components/AppNavigationSidebar/publishing/SidebarProfileSelector'
import { SidebarListSelector } from 'components/App/components/AppNavigationSidebar/publishing/SidebarListSelector'
import { userListsSelector } from 'services/lists/selectors'
import { FacebookAlbumSelector } from 'components/Composer/AlbumSelector/FacebookAlbumSelector'
import { StoreThunkDispatch } from 'store/state'
import { FBAlbum } from 'services/compose/interfaces/ComposerFacebookPost'
import { PinterestBoardSelector } from 'components/Composer/AlbumSelector/PinterestBoardSelector'
import { pinterestProfilesBoardsSelector } from 'services/compose/selectors'
import { Subject } from 'rxjs/Subject'
import { message, warning } from 'services/snackbar'
import IconCheck from '@mui/icons-material/Check'
import { copyPosts } from 'services/post/copy/actions'
import { CopyPostsData } from 'services/post/copy/net'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { NOTIFICATION_DURATION_LONG } from 'components/ConnectedSnackbar'
import { bucketsSelector } from 'services/post/selectors'
import BucketSelect from 'components/BucketSelect'
import PPSelect from 'components/PPSelect'
import { GOOGLE_BUTTON_TYPES } from 'shared/constants'

const ERROR_MSG_TIMEOUT = 6000

interface CopyPostsPopupProps {
  open: boolean
  posts: PlannedPost[]
  allSelected: boolean
  postType: PostType | 'scheduled'
  types?: ContentType[]
  query?: string
  bucketId?: string
  onClose: () => void
  onCopyStarted: () => void
  onCopyFinished?: () => void
}

export function CopyPostsPopup(props: CopyPostsPopupProps & WithIntl) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [profilesView, setProfilesView] = React.useState<string>('grid')
  const [selectedListId, setSelectedListId] = React.useState<string | null>(null)
  const [selectedProfileIds, setSelectedProfileIds] = React.useState<string[]>([])
  const [selectedFBAlbums, setSelectedFBAlbums] = React.useState<IndexedObject<FBAlbum | undefined>>({})
  const [selectedPinterestboards, setSelectedPinterestBoards] = React.useState<IndexedObject<PinterestBoard>>({})
  const [selectedBucketId, setSelectedBucketId] = React.useState<string | undefined>(undefined)
  const [selectedBucketProfileIds, setSelectedBucketProfileIds] = React.useState<string[]>([])
  const [googleButtonType, setGoogleButtonType] = React.useState<string | undefined>(undefined)
  const profiles = useSelector(connectedDestinationsSelector)
  const lists = useSelector(userListsSelector)
  const buckets = useSelector(bucketsSelector)
  const boards = useSelector(pinterestProfilesBoardsSelector)
  const copyPosts$ = React.useRef<Subject<CopyPostsData>>()

  React.useEffect(() => {
    if (props.posts.length === 1 && props.posts[0].network === BRAND_GOOGLE) {
      setGoogleButtonType(props.posts[0].details?.buttonType)
    }
  }, [props.posts])

  const fetchFBAlbums = React.useCallback((profile: PostDestination) => {
    return dispatch(getAlbums(profile)).unwrap()
  }, [dispatch])

  const fetchPIBoards = React.useCallback((profile: PostDestination) => {
    return dispatch(getPinterestBoards(profile)).unwrap()
  }, [dispatch])

  const onSelectedBucketChange = (id: string | undefined) => {
    setSelectedBucketId(id)
    setSelectedListId(null)
    if (!id) {
      setSelectedBucketProfileIds([])
      return
    }
    const ppids = id && buckets.find(b => b.id === id)?.ppids
    if (ppids) {
      const ids = ppids.map(ppid => Object.values(profiles).find(p => p.ppid === ppid)?.id).filter(Boolean) as string[]
      setSelectedProfileIds(ids)
      setSelectedBucketProfileIds(ids)
    }
  }

  React.useEffect(() => {
    copyPosts$.current = new Subject()
    copyPosts$.current.flatMap(data => dispatch(copyPosts(data)).pipe(catchError((data) => {
      return Observable.of({ error: data.response?.error || data })
    })))
      .subscribe((response) => {
        if (props.onCopyFinished) {
          props.onCopyFinished()
        }

        if (response.countErrors) {
          const msg = (
            <div>
              {response.countOk > 0 && (
                <div className={styles['msg-error']}>
                  <IconCheck className={styles['icon-ok']} />
                  {props.intl.formatMessage({ id: 'post.notifications.posts-copy-result-success' }, { count: response.countOk })}
                </div>
              )}
              <div className={styles['msg-error']}>
                <IconError className={styles['icon-error']} />
                {props.intl.formatMessage({ id: 'post.notifications.posts-copy-result-failed' }, { count: response.countErrors })}
              </div>
            </div>
          )
          dispatch(warning(
            msg as any,
            'download report',
            () => { window.open(response.downloadReport, '_self') },
            ERROR_MSG_TIMEOUT
          ))
          return
        }

        if (response.error) {
          if (response.error.message) {
            dispatch(message(response.error.message, 'error', NOTIFICATION_DURATION_LONG))
          } else {
            dispatch(message(props.intl.formatMessage({ id: 'post.notifications.posts-copy-failed' }), 'error'))
          }
          return
        }

        dispatch(message(props.intl.formatMessage({ id: 'post.notifications.posts-copied' }), 'success'))
      })
    return () => copyPosts$.current?.unsubscribe()
  }, [])

  const types = React.useMemo(() => {
    const postTypes: IndexedObject<boolean> = {}
    for (const post of props.posts) {
      postTypes[post.contentType] = true
    }
    return postTypes
  }, [props.posts])

  const profilesByNetwork = React.useMemo(() => {
    return selectedProfileIds.reduce((map: IndexedObject<PostDestination[]>, id: string) => {
      const profile = profiles[id]
      if (map[profile.type]) {
        map[profile.type].push(profile)
      } else {
        map[profile.type] = [profile]
      }
      return map
    }, {})
  }, [selectedProfileIds, profiles])

  const onSelectedListChange = (list: List) => {
    setSelectedListId(list.id)
    setSelectedProfileIds(list.pages.filter(id => Boolean(profiles[id])))
    setSelectedBucketId(undefined)
    setSelectedBucketProfileIds([])
  }

  const onSelectedProfilesChange = (ids: string[]) => {
    setSelectedProfileIds(ids)
    setSelectedListId(null)
  }

  const onFBSelectedAlbumChange = React.useCallback((profile: PostDestination, album: FBAlbum | undefined) => {
    setSelectedFBAlbums(current => ({
      ...current,
      [profile.id]: album
    }))
  }, [])

  const onSelectedBoardChange = React.useCallback((ppid: string, board: PinterestBoard) => {
    setSelectedPinterestBoards(current => ({
      ...current,
      [ppid]: board
    }))
  }, [])

  const submit = () => {
    const postIds: string[] = []
    const postPPIds: string[] = []
    for (const post of props.posts) {
      postIds.push(post.id)
      postPPIds.push(post.ppPageId)
    }
    if (!selectedBucketId && selectedProfileIds.length === 0) {
      dispatch(message('Select target Bucket or profiles!', 'warning'))
      return
    }
    copyPosts$.current?.next({
      copyFromPPIds: postPPIds,
      copyToPPIds: selectedProfileIds.map(id => profiles[id].ppid),
      fbAlbums: Object.values(selectedFBAlbums).filter(Boolean) as FBAlbum[],
      piBoards: Object.values(selectedPinterestboards).filter(Boolean) as PinterestBoard[],
      postIds,
      postType: props.postType,
      all: props.allSelected,
      query: props.query,
      types: props.types,
      bucketId: selectedBucketId,
      fromBucket: props.bucketId,
      googleButtonType
    })
    props.onCopyStarted()
  }

  const visibleProfiles = selectedBucketId
    ? Object.keys(profiles).reduce((map: IndexedObject<PostDestination>, id: string) => {
      const profile = profiles[id]
      if (selectedBucketProfileIds.includes(profile.id)) {
        map[id] = profile
      }
      return map
    }, {})
    : profiles

  return (
    <Dialog open={props.open} classes={{ paper: styles.dialog }} maxWidth="lg" onClose={props.onClose}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>
            <FormattedMessage id="post.labels.copy-posts-title" />
          </h1>
        </div>
        <div className={styles.content}>
          <div className={styles['buckets-box']}>
            <p><FormattedMessage id="label.generic.buckets" /></p>
            <BucketSelect
              buckets={buckets}
              emptySelectionLabel="No bucket"
              selectedBucketId={selectedBucketId}
              className={styles['buckets-select']}
              onSelectedBucketChange={onSelectedBucketChange}
            />
          </div>
          <div className={styles['profiles-box']}>
            <p><FormattedMessage id="general.labels.profiles" /></p>
            <SidebarProfileSelector
              actionsClassName={styles['actions-profiles']}
              profiles={visibleProfiles}
              activeView={profilesView as any}
              selectedProfileIds={selectedProfileIds}
              multipleProfileSelection
              onSelectedProfilesChange={onSelectedProfilesChange}
              setActiveView={setProfilesView}
            />
          </div>
          <div className={styles['network-settings']}>
            {types[PHOTO_TYPE] && profilesByNetwork.facebook?.length > 0 && profilesByNetwork.facebook.map(profile => (
              <div className={styles['option-box']} key={profile.id}>
                <FacebookAlbumSelector
                  profile={profile}
                  raised
                  fetchAlbums={fetchFBAlbums}
                  selectedAlbums={Object.values(selectedFBAlbums).filter(Boolean) as FBAlbum[]}
                  onSelectedAlbumChange={onFBSelectedAlbumChange}
                />
              </div>
            ))}
            {profilesByNetwork.pinterest?.length > 0 && profilesByNetwork.pinterest.map(p => {
              const profileBoards = boards[p.ppid]?.length > 0 ? boards[p.ppid] : Object.values(p.pinterestBoards || {})

              return (
                <div key={p.id} className={styles['option-box']}>
                  <PinterestBoardSelector
                    profile={p}
                    boards={profileBoards?.map(b => ({ ...b, selected: b.id === selectedPinterestboards[p.ppid]?.id })) || []}
                    raised
                    fetchBoards={fetchPIBoards}
                    setComposerPinterestBoard={onSelectedBoardChange}
                  />
                </div>
              )
            })}
            {profilesByNetwork.google?.length > 0 && props.posts.length === 1 && props.posts[0].network === BRAND_GOOGLE && (
              <div className={styles['option-box']}>
                <PPSelect
                  name={props.intl.formatMessage({ id: 'composer.labels.google.btn-type' })}
                  options={GOOGLE_BUTTON_TYPES}
                  selectedValue={googleButtonType || 'NONE'}
                  className={styles['g-select']}
                  popperProps={{ style: { zIndex: '1303' } }}
                  onSelectionChange={setGoogleButtonType}
                />
              </div>
            )}
            <div className={styles.errors}>
              {profilesByNetwork.instagram?.length > 0 && types[ARTICLE_TYPE] && (
                <p className={styles.msg}>
                  <IconError className={styles.icon} />
                  <FormattedMessage id="posts.copy-errors.instagram-article" />
                </p>
              )}
              {profilesByNetwork.pinterest?.length > 0 && types[VIDEO_TYPE] && (
                <p className={styles.msg}>
                  <IconError className={styles.icon} />
                  <FormattedMessage id="posts.copy-errors.pinterest-video" />
                </p>
              )}
              {profilesByNetwork.google?.length > 0 && types[VIDEO_TYPE] && (
                <p className={styles.msg}>
                  <IconError className={styles.icon} />
                  <FormattedMessage id="posts.copy-errors.google-video" />
                </p>
              )}
            </div>
          </div>
          <div className={styles['lists-box']}>
            <p><FormattedMessage id="general.labels.lists" /></p>
            <SidebarListSelector
              selectedList={selectedListId ? lists[selectedListId] : undefined}
              onSelectedListChange={onSelectedListChange}
            />
          </div>
        </div>
        <div className={styles.actions}>
          <Button className={styles['btn-cancel']} variant="text" onClick={props.onClose}>
            <FormattedMessage id="actions.cancel" />
          </Button>
          <Button className={styles['btn-submit']} color="primary" variant="contained" onClick={submit}>
            <FormattedMessage id="post.actions.copy-posts" />
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default injectIntl(CopyPostsPopup)
