import * as React from 'react'
import { TimePicker as MDTimePicker, TimePickerProps as MDTimePickerProps } from '@mui/x-date-pickers/TimePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import styles from './TimePicker.pcss'
const css = require('styles/variables')

export interface TimePickerProps {
  value: Date
  ampm?: boolean
  disabled?: boolean
  className?: string
  inputClassName?: string
  withActions?: boolean
  MDPickerProps?: Partial<MDTimePickerProps<any>>
  onChange: (date: Date) => void
}

export function TimePicker(props: TimePickerProps) {
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState(props.value)

  React.useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const onChange = (next: Date) => {
    if (props.withActions) {
      setValue(next)
    }
    props.onChange(next)
  }

  const openPicker = () => {
    setOpen(true)
  }

  const closePicker = () => {
    setOpen(false)
  }

  const pickerProps = props.MDPickerProps || {}

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={`${props.className || ''} ${styles.box}`}>
        <MDTimePicker
          {...pickerProps}
          open={open}
          ampm={props.ampm}
          value={value}
          closeOnSelect={false}
          timeSteps={{ minutes: 1 }}
          disabled={props.disabled}
          className={`${styles.root} ${props.disabled ? styles.disabled : ''}`}
          slotProps={{
            textField: {
              InputProps: { classes: { root: styles.pointer, input: props.inputClassName } }
            },
            inputAdornment: {
              className: styles['icon-btn']
            },
            actionBar: props.withActions ? {
              actions: ['cancel', 'accept']
            } : undefined,
            desktopPaper: {
              sx: {
                '.MuiClock-root': { position: 'relative', paddingBottom: '8px', marginTop: '32px' },
                '.MuiClock-pmButton, .MuiClock-amButton': { width: '32px', height: '32px' },
                '.MuiClock-pmButton > span, .MuiClock-amButton > span': { lineHeight: '16px' },
                '.MuiClock-pmButton:hover, .MuiClock-amButton:hover': { backgroundColor: css['--pp-green'], color: 'white' },
                '.MuiPickersArrowSwitcher-root': { zIndex: '1' },
                '.MuiMultiSectionDigitalClockSection-item': { fontSize: '12px', width: '56px' },
                '.MuiMultiSectionDigitalClockSection-item.Mui-selected': { color: css['--pp-white'] },
                '.MuiMultiSectionDigitalClockSection-item:after': { display: 'none' },
                '.MuiMultiSectionDigitalClockSection-root': { width: '64px' },
                '.MuiMultiSectionDigitalClockSection-root:last-child:after': { display: 'none' }
              }
            }
          }}
          onAccept={props.onChange}
          onChange={onChange}
          onOpen={openPicker}
          onClose={closePicker}
        />
      </div>
    </LocalizationProvider>
  )
}

export default TimePicker
