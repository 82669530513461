import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { RecyclerIcon } from 'components/Icons'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import styles from './RecycleSelector.pcss'
import DatePicker from 'components/DatePicker'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import IconInfinity from '@mui/icons-material/AllInclusive'
import { useDispatch } from 'react-redux'
import { checkFeatureAvailability } from 'services/product'
import { FEATURE_POST_RECYCLE } from 'shared/constants'
import { StoreThunkDispatch } from 'store/state'
import { format } from 'date-fns'

const DAYS_IN_WEEK = 7
const BASE_TEN = 10
const DATE_PICKER_POPUP_CLASSNAME = 'recycle_selector-datepicker'
const recycleIconStyles = {
  width: '18px',
  height: '18px'
}

interface RecycleSelectorProps {
  value: boolean | Date | number
  triggerElement?: React.ReactNode
  className?: string
  activeClassName?: string
  horizontal?: boolean
  big?: boolean
  disabled?: boolean
  withIcon?: boolean
  onChange: (value: boolean | Date | number) => void
  onApplyToAll?: (value: boolean | Date | number) => void
}

export function RecycleSelector(props: RecycleSelectorProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const withCustomTrigger = Boolean(props.triggerElement)
  const containerRef = React.useRef<HTMLDivElement | null>(null)
  const btnRef = React.useRef<HTMLButtonElement | null>(null)
  const [popupAnchor, setPopupAnchor] = React.useState<HTMLElement | null>(null)
  const [selectedRadioValue, setSelectedRadioValue] = React.useState<'forever' | 'off' | 'date' | 'number'>('off')
  const [date, setDate] = React.useState<Date>(() => {
    const value = new Date()
    value.setDate(value.getDate() + DAYS_IN_WEEK)
    return value
  })
  const [count, setCount] = React.useState(1)

  React.useEffect(() => {
    if (!props.value) {
      setSelectedRadioValue('off')
    } else if (typeof props.value === 'object') {
      setSelectedRadioValue('date')
      setDate(props.value as Date)
    } else if (typeof props.value === 'number') {
      setCount(props.value)
      setSelectedRadioValue('number')
    } else {
      setSelectedRadioValue('forever')
    }
  }, [props.value])

  const valueString = React.useMemo(() => {
    if (withCustomTrigger) {
      return ''
    }

    if (typeof props.value === 'number') {
      return `${props.value} times`
    }

    if (props.value === true) {
      return 'forever'
    }

    if (typeof props.value === 'object') {
      return `until ${(props.value as Date).toLocaleDateString()}`
    }

    return 'off'
  }, [props.value, withCustomTrigger])

  const active = Boolean(props.value)
  const activeClassName = active && !withCustomTrigger ? `${styles.active} ${props.activeClassName || ''}` : ''
  const horizontalClass = props.horizontal ? styles.horizontal : ''
  const disabledClass = props.disabled ? styles.disabled : ''
  const bigClass = props.big ? styles.big : ''

  const onClick = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (props.disabled) {
      return
    }

    if (!popupAnchor && containerRef.current) {
      setPopupAnchor(containerRef.current)
    }
  }, [props.disabled, popupAnchor])

  const onBtnClick = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    if (props.disabled) {
      return
    }

    if (!popupAnchor && btnRef.current) {
      setPopupAnchor(btnRef.current)
    }
  }, [props.disabled, popupAnchor])

  const onRepeatCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: any = e.target.value
    value = parseInt(value, BASE_TEN)
    if (Number.isNaN(value) || value < 1) {
      value = 1
    }
    setCount(value)
    setSelectedRadioValue('number')
  }

  const closeDropdown = (e?: any) => {
    // EXPL: This is to prevent closing the popover on click away when date picker is open
    if (e?.target.closest(`.${DATE_PICKER_POPUP_CLASSNAME}`)) {
      return
    }
    setPopupAnchor(null)
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value as any
    setSelectedRadioValue(value)
    if (value === 'forever' || value === 'off') {
      onApply(value)
    }
  }

  const onDateChange = (date: Date) => {
    setDate(date)
    setSelectedRadioValue('date')
  }

  const onApply = (value?: any) => {
    const nextValue = typeof value === 'string' ? value : selectedRadioValue
    const isRecycleAllowed = dispatch(checkFeatureAvailability(FEATURE_POST_RECYCLE))
    if (!isRecycleAllowed) {
      return
    }

    switch (nextValue) {
      case 'forever':
        props.onChange(true)
        break
      case 'off':
        props.onChange(false)
        break
      case 'date':
        props.onChange(date)
        break
      case 'number':
        props.onChange(count)
        break
    }
    closeDropdown(null)
  }

  const applyToAll = () => {
    if (!props.onApplyToAll) {
      return
    }
    const isRecycleAllowed = dispatch(checkFeatureAvailability(FEATURE_POST_RECYCLE))
    if (!isRecycleAllowed) {
      return
    }

    switch (selectedRadioValue) {
      case 'forever':
        props.onApplyToAll(true)
        break
      case 'off':
        props.onApplyToAll(false)
        break
      case 'date':
        props.onApplyToAll(date)
        break
      case 'number':
        props.onApplyToAll(count)
        break
    }
    closeDropdown(null)
  }

  const onPaperClick = (e: any) => {
    e.stopPropagation()
  }

  return (
    <React.Fragment>
      {props.horizontal && !props.triggerElement && (
        <Button
          ref={btnRef}
          className={`${styles['btn-trigger']} ${active ? styles.highlight : ''}`}
          startIcon={props.withIcon && <RecyclerIcon className={styles['icon-recycle']} style={recycleIconStyles} />}
          data-testid="recycle-selector-btn"
          onClick={onBtnClick}
        >
          {`Recycle: ${valueString}`}
        </Button>
      )}
      {!props.horizontal && (
        <div
          ref={containerRef}
          className={`${styles.box} ${props.className || ''} ${activeClassName} ${horizontalClass} ${disabledClass} ${bigClass}`}
          data-testid="recycle-selector-btn"
          onClick={onClick}
        >
          <Tooltip title={`Recycle ${valueString}`}>
            {(props.triggerElement as React.ReactElement) || (
              <IconButton size="small" className={`${styles['btn-recycle']}`}>
                <RecyclerIcon className={styles['icon-recycle']} style={recycleIconStyles} />
              </IconButton>
            )}
          </Tooltip>
          {props.value && !props.triggerElement && (
            <div className={styles['value-box']}>
              {typeof props.value === 'boolean' && (
                <IconInfinity className={styles.icon} />
              )}
              {typeof props.value === 'number' && `${props.value}x`}
              {typeof props.value === 'object' && (
                <span className={styles.small}>{format(props.value, 'LLL d')}</span>
              )}
            </div>
          )}
        </div>
      )}
      <Popper
        open={Boolean(popupAnchor)}
        anchorEl={popupAnchor}
        placement="bottom-start"
        className={styles.popper}
      >
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={closeDropdown}>
          <Paper classes={{ root: styles.paper }} data-testid="recycle-selector-popup" onClick={onPaperClick}>
            <RadioGroup aria-label="recycle" name="recycle" value={selectedRadioValue} onChange={handleRadioChange}>
              {props.value && (
                <FormControlLabel
                  value="off"
                  classes={{ label: styles.label }}
                  control={<Radio size="small" color="primary" />}
                  label={<FormattedMessage id="general.labels.recycle-stop" />}
                />
              )}
              <FormControlLabel
                value="forever"
                classes={{ label: styles.label }}
                control={<Radio size="small" color="primary" />}
                label={<FormattedMessage id="general.labels.recycle-forever" />}
              />
              <FormControlLabel
                value="date"
                classes={{ label: styles.label }}
                control={<Radio size="small" color="primary" />}
                label={(
                  <div className={styles['label-calendar-box']}>
                    <FormattedMessage id="general.labels.recycle-until" />
                    <DatePicker
                      value={date}
                      minDate={new Date()}
                      className={styles.picker}
                      calendarClassName={DATE_PICKER_POPUP_CLASSNAME}
                      onChange={onDateChange}
                    />
                  </div>
                )}
              />
              <FormControlLabel
                value="number"
                classes={{ label: styles.label }}
                control={<Radio size="small" color="primary" />}
                label={(
                  <div className={styles['label-number-box']}>
                    <FormattedMessage id="general.labels.recycle" />
                    <TextField
                      type="number"
                      value={count}
                      className={styles['input-number']}
                      onChange={onRepeatCountChange}
                    />
                    <FormattedMessage id="general.labels.times" />
                  </div>
                )}
              />
            </RadioGroup>
            <div className={styles.actions}>
              {props.onApplyToAll && (
                <Button size="small" variant="text" color="primary" className={styles.btn} onClick={applyToAll}>
                  <FormattedMessage id="general.labels.apply-to-all" />
                </Button>
              )}
              <Button size="small" variant="text" color="primary" className={styles.btn} data-testid="btn-done" onClick={onApply}>
                <FormattedMessage id="actions.done" />
              </Button>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>
  )
}
