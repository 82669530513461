import {
  PostDestinationType,
  LinkDetails,
  BRAND_FACEBOOK,
  BRAND_TWITTER,
  BRAND_PINTEREST,
  BRAND_INSTAGRAM,
  BRAND_LINKEDIN,
  ContentItem,
  IndexedObject
} from 'interfaces'
import { BRAND_GOOGLE, BRAND_TIKTOK, BRAND_YOUTUBE } from 'interfaces/Content/SocialBrand'
import { RepeatPeriod, REPEAT_PERIOD_WEEKS } from 'interfaces/Composer/ComposerPostTypes'
import { ComposerInstagramPost } from './interfaces'
import { ComposerBasePost } from './interfaces/ComposerBasePost'
import { ComposerFacebookPost, BackgroundTemplate } from './interfaces/ComposerFacebookPost'
import { ComposerPinterestPost } from './interfaces/ComposerPinterestPost'
import { ComposerTwitterPost } from './interfaces/ComposerTwitterPost'
import { ArticleBase } from './interfaces/ArticleBase'
import { ComposerGooglePost } from './interfaces/ComposerGooglePost'
import { ComposerTikTokPost } from './interfaces/ComposerTikTokPost'
import { CreatePostRequestData, Prompt } from 'shared/types'
import { ComposerYoutubePost } from './interfaces/ComposerYoutubePost'
import { ComposerLinkedinPost } from './interfaces/ComposerLinkedinPost'

export const COMPOSER_STATE_VERSION = 'composer:15'
export const COMPOSER_STATUS_KEY_GENERIC = 'generic'
export const IG_POST_TYPE_REEL = 'reels'
export const FB_POST_TYPE_REEL = 'reels'
export const POST_TYPE_STORY = 'stories'
export type ContentCardComposeAction = 'create' | 'carousel' | 'multipost'

export const emptyStatus = {
  text: '',
  html: '',
  touched: false
}

const DEFAULT_SCHEDULE_TIME_OFFSET_MINUTES = 60
const defaultScheduleTime = new Date()
defaultScheduleTime.setMinutes(defaultScheduleTime.getMinutes() + DEFAULT_SCHEDULE_TIME_OFFSET_MINUTES)
const defaultScheduleTimeString = defaultScheduleTime.toISOString()

export interface ComposerScheduleState {
  isActive: boolean
  time: string
  date: string
  repeat: boolean
  repeatCount: number
  repeatPeriod: RepeatPeriod
  repeatUntil: 'date' | 'forever'
  repeatUntilDate: string
}

export type PostNetworkData = ComposerBasePost
  | ComposerFacebookPost
  | ComposerTwitterPost
  | ComposerInstagramPost
  | ComposerPinterestPost
  | ComposerYoutubePost
  | ComposerTikTokPost
  | ComposerLinkedinPost

export interface BulkContentPost {
  id: string
  network: PostDestinationType
  imageUrl?: string
  videoUrl?: string
  article?: ArticleBase
  content?: ContentItem
  imageUpload?: { file: File, url?: string, src?: string, error?: string }
  data: PostNetworkData
  order: number
  recycle: boolean | Date | number
  error?: {
    message: string
    code: string
  }
}

export interface ComposerState {
  posts: {
    [BRAND_FACEBOOK]: ComposerFacebookPost
    [BRAND_TWITTER]: ComposerTwitterPost
    [BRAND_PINTEREST]: ComposerPinterestPost
    [BRAND_INSTAGRAM]: ComposerInstagramPost
    [BRAND_LINKEDIN]: ComposerLinkedinPost
    [BRAND_TIKTOK]: ComposerTikTokPost
    [BRAND_GOOGLE]: ComposerGooglePost
    [BRAND_YOUTUBE]: ComposerYoutubePost
    [COMPOSER_STATUS_KEY_GENERIC]: ComposerBasePost
  }
  multiPost: {
    posts: IndexedObject<BulkContentPost>
  }
  useGenericStatus: boolean
  draft?: {
    id: string
    updatedAt: string
  }
  imageUrls: string[]
  featuredImageUrl?: string
  videoUrl?: string
  videoDuration?: number
  videoSize?: number
  videoThumbnailUrl?: string
  videoThumbnailOffset?: number
  videoMetadata?: {
    width?: number
    height?: number
    fileType?: string
  }
  postAsPhoto: boolean
  bulkUploadFile?: {
    url: string
    rows: number
    name: string
  }
  recycle: boolean | Date | number
  contentItem?: ContentItem
  links: LinkDetails[]
  selectedProfiles: Array<string>
  selectedBucketId?: string
  activeTab: PostDestinationType
  networksView: 'list' | 'tabs'
  progress: {
    [id: string]: {
      post: CreatePostRequestData
      error?: string
      complete?: boolean
    }
  }
  resetKey: number
  isUploading: boolean | 'video'
  savedStatuses: any[]
  pinterestBoards: { [ppid: string]: Array<{ id: string, name: string, url?: string, selected?: boolean }> }
  rememberIGLocations: boolean
  externalFile?: File
  postEdit?: {
    id: string
    ppid: string
    bucketId?: string
  }
  schedule: ComposerScheduleState
  prompt: {
    content?: ContentItem
    action?: ContentCardComposeAction
  }
  writer: {
    favoritePrompts: Prompt[]
    userPrompts: Prompt[]
    aiWriterPromptId?: string
  }
  facebookBackgroundTemplates: BackgroundTemplate[]
}

export function initialState(): ComposerState {
  return {
    posts: {
      [BRAND_FACEBOOK]: {
        albums: [],
        reshare: false,
        status: { ...emptyStatus },
        firstComment: '',
        targeting: Object.freeze({
          dialogActive: false,
          targets: [],
          newTarget: {},
          selectedTargets: []
        })
      },
      [BRAND_TWITTER]: {
        status: { ...emptyStatus },
        tweetType: 'new'
      },
      [BRAND_PINTEREST]: {
        status: { ...emptyStatus },
        title: '',
        description: '',
        destinationUrl: ''
      },
      [BRAND_INSTAGRAM]: {
        status: { ...emptyStatus },
        firstComment: '',
        locations: {}
      },
      [BRAND_LINKEDIN]: {
        status: { ...emptyStatus },
        firstComment: '',
        document: undefined
      },
      [BRAND_TIKTOK]: {
        status: { ...emptyStatus },
        settings: {}
      },
      [BRAND_GOOGLE]: {
        status: { ...emptyStatus },
        link: ''
      },
      [BRAND_YOUTUBE]: {
        status: { ...emptyStatus },
        title: '',
        description: '',
        firstComment: '',
        privacyStatus: 'public'
      },
      [COMPOSER_STATUS_KEY_GENERIC]: {
        status: { ...emptyStatus }
      }
    },
    imageUrls: [],
    recycle: false,
    selectedProfiles: [],
    progress: {},
    activeTab: BRAND_FACEBOOK,
    networksView: 'list',
    resetKey: 0,
    links: [],
    isUploading: false,
    savedStatuses: [],
    pinterestBoards: {},
    postAsPhoto: false,
    schedule: {
      isActive: false,
      date: defaultScheduleTimeString,
      time: defaultScheduleTimeString,
      repeat: false,
      repeatCount: 1,
      repeatPeriod: REPEAT_PERIOD_WEEKS,
      repeatUntil: 'forever',
      repeatUntilDate: defaultScheduleTimeString
    },
    multiPost: {
      posts: {}
    },
    prompt: {},
    useGenericStatus: true,
    rememberIGLocations: true,
    writer: {
      favoritePrompts: [],
      userPrompts: []
    },
    draft: undefined,
    facebookBackgroundTemplates: []
  }
}

export default ComposerState
