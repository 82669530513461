import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Weekday, WEEKDAY_LABELS } from 'utils/calendar'
import styles from './CalendarViewHeader.pcss'

interface CalendarMonthViewHeaderProps {
  startDay: Weekday
  highlightedDays?: number
  className?: string
  cellClassName?: string
}

export function CalendarMonthViewHeader(props: CalendarMonthViewHeaderProps) {
  const daysOrdered = React.useMemo(() => {
    const splitIndex = WEEKDAY_LABELS.indexOf(props.startDay)
    return WEEKDAY_LABELS.slice(splitIndex).concat(WEEKDAY_LABELS.slice(0, splitIndex))
  }, [props.startDay])

  return (
    <header className={`${styles.header} ${props.className || ''}`}>
      {daysOrdered.map((day, index) => {
        const highlight = props.highlightedDays !== undefined && props.highlightedDays >= index + 1
        return (
          <div key={day} className={`${styles['header-cell']} ${props.cellClassName || ''} ${highlight ? styles.highlight : ''}`}>
            <FormattedMessage id={`calendar.days.short.${day}`} />
          </div>
        )
      })}
    </header>
  )
}

export default CalendarMonthViewHeader
