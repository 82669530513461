import * as React from 'react'
import { ContentPanel, StreamGroup } from 'admin/interfaces'
import { StreamCard } from 'components/Cards'
import { ContentPanelBase } from './ContentPanelBase'
import { Stream } from 'interfaces'
import { Subject } from 'rxjs/Subject'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { getRandomStreams } from 'services/content/streams/actions'

interface HomeContentPanelCarouselProps {
  panel: ContentPanel
  className?: string
}

const carouselHeight = 148
type StreamsCategory = {
  id: string
  name: string
  streams: Stream[]
  slug: string
}

export function HomeContentPanelCarousel(props: HomeContentPanelCarouselProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const categories = props.panel.streamGroups
    .filter(g => g.active && g.streams.length > 0)
    .map(g => ({
      id: g.id,
      name: g.name,
      streams: g.streams,
      slug: g.slug
    }))
  const [activeCategory, setActiveCategory] = React.useState<StreamsCategory | null>(categories[0])
  const [loading, setLoading] = React.useState(false)
  const [visibleStreams, setVisibleStreams] = React.useState<Stream[]>(activeCategory?.streams || [])
  const fetchRandom$ = React.useRef<Subject<boolean>>()

  React.useEffect(() => {
    fetchRandom$.current = new Subject()
    fetchRandom$.current.switchMap((fetch: boolean) => {
      if (fetch) {
        setLoading(true)
        return Observable.fromPromise(dispatch(getRandomStreams()).unwrap()).pipe(catchError((error) => Observable.of({ error })))
      }
      return Observable.of({ error: true })
    }).subscribe((response: any) => {
      setLoading(false)
      if (response.error) {
        setActiveCategory(categories[0])
      } else {
        setVisibleStreams(response)
      }
    })

    return () => fetchRandom$.current?.unsubscribe()
  }, [])

  const navLink = activeCategory ? {
    text: `see all ${activeCategory.name}`,
    url: `/content/${activeCategory.slug}`
  } : undefined

  const loadRandomStreams = () => {
    setActiveCategory(null)
    fetchRandom$.current?.next(true)
  }

  const onActiveCategoryChange = (id: string) => {
    const next = props.panel.streamGroups.find(g => g.id === id)
    if (next) {
      fetchRandom$.current?.next(false)
      setActiveCategory({ id, name: next.name, streams: next.streams, slug: next.slug })
      setLoading(false)
      setVisibleStreams(next.streams)
    }
  }

  const items = React.useMemo(() => {
    return visibleStreams.map(stream => (
      <StreamCard
        key={stream.id}
        stream={stream}
        urlBrowse={`/content/${activeCategory?.slug || 'streams'}/${stream.id}`}
      />
    ))
  }, [visibleStreams, activeCategory])

  return (
    <ContentPanelBase
      title={props.panel.name}
      items={items}
      loading={loading}
      categories={categories}
      activeCategoryId={activeCategory?.id}
      contentCarouselHeight={carouselHeight}
      navLink={navLink}
      className={props.className}
      onCategoryClick={onActiveCategoryChange}
      onLoadRandom={loadRandomStreams}
    />
  )
}
