import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Accordion from '../Accordion'
import TextEditor, { CaptionPlaceholder } from 'components/TextEditor'
import styles from './Content.pcss'
import { COMPOSER_STATUS_KEY_GENERIC } from 'services/compose/state'
import { useDispatch, useSelector } from 'react-redux'
import {
  composerContentItemSelector,
  composerImageUrlsSelector,
  composerLinkSelector,
  composerResetKeySelector,
  composerVideoUrlSelector,
  genericPostSelector,
  multiPostsCountSelector,
  visibleMultiPostsSelector
} from 'services/compose/selectors'
import { getLinkDetails, setComposerStatus } from 'services/compose'
import Button from '@mui/material/Button'
import IconSearch from '@mui/icons-material/Search'
import Media from './Media'
import { Subject } from 'rxjs/Subject'
import { extractUrlsFromText } from 'utils/composer'
import { StoreThunkDispatch } from 'store/state'
import { SuggestedTexts } from './SuggestedTexts'
import { Icon } from '@mdi/react'
import { mdiAlertCircleOutline } from '@mdi/js'
import TextContentSearchPanel from '../TextContentSearchPanel'
import { ARTICLE_TYPE, PHOTO_TYPE } from 'interfaces'
import BulkView from '../BulkView'
import { useElementSize } from 'hooks/useElementSize'
import { Observable } from 'rxjs/Observable'

const ARTICLE_CAPTION_CODES: CaptionPlaceholder[] = ['title', 'description', 'link']
const GENERIC_CAPTION_CODES: CaptionPlaceholder[] = ['caption', 'credit']

export function Content(props: { activeView: 'default' | 'bulk' }) {
  const intl = useIntl()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const genericStatus = useSelector(genericPostSelector)
  const composerKey = useSelector(composerResetKeySelector)
  const link = useSelector(composerLinkSelector)
  const content = useSelector(composerContentItemSelector)
  const imageUrls = useSelector(composerImageUrlsSelector)
  const videoUrl = useSelector(composerVideoUrlSelector)
  const multiPostsCount = useSelector(multiPostsCountSelector)
  const multiPosts = useSelector(visibleMultiPostsSelector)
  const [textsPanelOpen, setTextsPanelOpen] = React.useState(false)
  const [showAtWarning, setShowAtWarning] = React.useState(false)
  const [captionCodes, setCaptionCodes] = React.useState<CaptionPlaceholder[] | undefined>(undefined)
  const isDefaultView = props.activeView === 'default'

  const linkRef = React.useRef(link)
  const getLinkDetails$ = React.useRef<Subject<string>>()
  const skipLinkFetch = React.useRef<boolean>()
  const [mainContentRef, mainContentRect] = useElementSize<HTMLDivElement>([isDefaultView, multiPostsCount])
  const sideContentRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const h = mainContentRect.height
    if (h && sideContentRef.current) {
      sideContentRef.current.style.height = `${h}px`
    }
  }, [mainContentRect.height])

  React.useEffect(() => {
    if (props.activeView !== 'bulk') {
      setCaptionCodes(undefined)
      return
    }
    let withArticles = false
    let withPhotoContentItems = false
    for (const post of multiPosts) {
      const postContentType = post.content?.type
      if (postContentType === ARTICLE_TYPE || post.article) {
        withArticles = true
      } else if (postContentType === PHOTO_TYPE) {
        withPhotoContentItems = true
      }
    }
    if (withArticles) {
      setCaptionCodes(ARTICLE_CAPTION_CODES.concat(GENERIC_CAPTION_CODES))
    } else if (withPhotoContentItems) {
      setCaptionCodes(GENERIC_CAPTION_CODES)
    } else {
      setCaptionCodes(undefined)
    }
  }, [props.activeView, multiPosts])

  React.useEffect(() => {
    skipLinkFetch.current = (content && content?.type !== ARTICLE_TYPE) || imageUrls.length > 0 || Boolean(videoUrl)
  }, [content, imageUrls, videoUrl])

  React.useEffect(() => {
    getLinkDetails$.current = new Subject()
    const sub = getLinkDetails$.current
      .switchMap((url: string) => Observable.fromPromise(dispatch(getLinkDetails({ url })).unwrap()))
      .subscribe()
    return () => {
      sub.unsubscribe()
    }
  }, [dispatch])

  const onChange = (text: string, html: string) => {
    if (html.indexOf('@') !== -1) {
      setShowAtWarning(true)
    } else {
      setShowAtWarning(false)
    }
    const url = extractUrlsFromText(text).pop()
    const lastFetchedUrl = linkRef.current ? linkRef.current.url : ''
    if (url && url !== lastFetchedUrl) {
      const validUrl = url.toLowerCase().startsWith('http') ? url : `http://${url}`
      // NOTE: Only fetch link details if no content item is added to the composer
      if (!skipLinkFetch.current) {
        getLinkDetails$.current?.next(validUrl)
      }
    }
    dispatch(setComposerStatus({ network: COMPOSER_STATUS_KEY_GENERIC, text, html }))
  }

  const openTextsPanel = () => {
    setTextsPanelOpen(true)
  }

  const closeTextsPanel = () => {
    setTextsPanelOpen(false)
  }

  return (
    <Accordion
      title={<FormattedMessage id="composer.titles.content" />}
      contentClassName={isDefaultView ? undefined : styles['bulk-content']}
      order="2"
      id="composer-content"
    >
      {(isDefaultView || multiPostsCount > 0) && (
        <React.Fragment>
          <div className={styles.section}>
            <div className={styles.layout}>
              <div className={styles.left} ref={mainContentRef}>
                <div className={styles['text-box']}>
                  <TextEditor
                    network={COMPOSER_STATUS_KEY_GENERIC}
                    inputClassName={styles.textarea}
                    containerClassName={`${styles['textarea-container']} ${captionCodes && captionCodes.length > 0 ? styles.bulk : ''}`}
                    withEmojis
                    templateStrings={captionCodes}
                    initialHTML={genericStatus.status.html}
                    key={composerKey}
                    placeholder={intl.formatMessage({ id: 'composer.status-placeholder-content' })}
                    id="composer-text-editor-generic"
                    onChange={onChange}
                  />
                </div>
                {isDefaultView && (
                  <div className={`${styles['section-bottom']}`}>
                    {showAtWarning && (
                      <div className={styles['hints-box']}>
                        <div><Icon path={mdiAlertCircleOutline} className={styles['icon-err']} size="28px" /></div>
                        <span className={styles.hints}>
                          <FormattedMessage id="composer.sections.content.at-warning" />
                        </span>
                      </div>
                    )}
                    <div className={styles.actions}>
                      <Media />
                    </div>
                  </div>
                )}
              </div>

              <div ref={sideContentRef} className={`${styles.right} ${isDefaultView ? styles.exp : ''}`}>
                <SuggestedTexts onQuotesButtonClick={openTextsPanel} />
              </div>
            </div>
          </div>
          <TextContentSearchPanel open={textsPanelOpen} onClose={closeTextsPanel} />
        </React.Fragment>
      )}
      {!isDefaultView && <BulkView />}
    </Accordion>
  )
}

export default Content
