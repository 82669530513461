import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'
import { PostDestinationType } from 'shared/types'

export const api = Object.freeze({
  getDestinations: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/post_destinations`).asGet().expectJSON()

    return function () {
      return authorizedPipe<any>(
        builder.build()
      )
    }
  }(),
  getAlbums: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/form_post/get_page_albums`).asGet().expectJSON()

    return function (profileId: string, pageToken: string) {
      return authorizedPipe<any>(
        builder
          .param('page', profileId)
          .param('page_token', pageToken)
          .build()
      )
    }
  }(),
  getPinterestBoards: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/albums/get_page_albums`).asGet().expectJSON()

    return function (ppid: string, pageToken: string) {
      return authorizedPipe<any>(
        builder
          .param('pp_page_id', ppid)
          .param('access_token', pageToken)
          .build()
      )
    }
  }(),
  toggleConnected: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/settings/networks/connect_page`).asPost().expectJSON()

    return function (profileId: string, ppid: string, profileType: PostDestinationType, forceDeletePosts?: boolean) {
      const data = new FormData()
      data.append('pp_page_id', ppid)
      data.append('page_id', profileId)
      data.append('page_type', profileType)
      if (forceDeletePosts) {
        data.append('force_delete', 'true')
      }
      return authorizedPipe<{ result: string }>(
        builder
          .form(data)
          .build()
      )
    }
  }(),
  setAutoActivate: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/onboarding/auto_connect/:value`).asGet().expectJSON()

    return function (value: boolean) {
      return authorizedPipe<{ result: string }>(
        builder.partial(':value', value ? '1' : '0').build()
      )
    }
  }(),
  refreshMyProfiles: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/my_profiles/add_my_profiles`).asPost().expectJSON()

    return function () {
      return authorizedPipe<any>(
        builder
          .asFormUrlEncoded()
          .build()
      )
    }
  }(),
  removeProfile: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/settings/networks/delete_page`).asPost().expectJSON()

    return function (ppid: string, forceDelete: boolean) {
      return authorizedPipe<{ success: string, error?: string }>(
        builder
          .bodyJSONstringify({ pp_page_id: ppid, force_delete: forceDelete })
          .build()
      )
    }
  }()
})
