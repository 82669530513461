import { useRef, useEffect } from 'react'
// Hook equivalent to useEffect, but skips callback on first render
export function useEffectUpdateOnly(fn: () => void, deps: any[]) {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current) {
      fn()
    } else {
      didMountRef.current = true
    }
  }, deps)
}
