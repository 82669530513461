import React from 'react'
import IconButton from '@mui/material/IconButton'
import { useDispatch, useSelector } from 'react-redux'
import { activeCalloutSelector } from 'services/ui/selectors'
import { toggleCallout } from 'services/ui/actions'
import { mdiHelp } from '@mdi/js'
import Icon from '@mdi/react'
import styles from './HelpToggleButton.pcss'

export function HelpToggleButton({ className }: { className?: string }) {
  const dispatch = useDispatch()
  const activeCallout = useSelector(activeCalloutSelector)

  if (!activeCallout || !activeCallout.hidden) {
    return null
  }

  const toggle = () => {
    dispatch(toggleCallout(activeCallout.slug))
  }

  return (
    <span className={`${styles.box} ${className || ''}`}>
      <IconButton
        size="small"
        className={styles.btn}
        onClick={toggle}
      >
        <Icon size="20px" path={mdiHelp} />
      </IconButton>
    </span>
  )
}
