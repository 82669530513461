import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { parse } from 'query-string'
import { ERROR_NO_EMAIL_RETURNED } from 'services/auth/identities/facebook/legacy'
import TextField from '@mui/material/TextField'
import Logo from 'components/PPLogo'
import { RandomUserQuote } from '../../components/UserQuotePanel/UserQuotePanel'
import { isMobileScreen } from 'utils/browser'
import MobileWarningDialog from 'components/App/components/MobileWarningDialog'
import ConnectedSnackbar from 'components/ConnectedSnackbar'
import EmailLoginForm from 'components/EmailLoginForm'
import styles from './Landing.pcss'
import { useNavigate, useLocation } from 'react-router-dom'
import FBLoginButton from '../../components/LoginButton/FBLoginButton'

interface LandingState {
  error?: string
  emailMissing: boolean
  email?: string
}

export function Landing() {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const isMobileRef = React.useRef(isMobileScreen())
  const [state, setState] = React.useState<LandingState>({ emailMissing: false })

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setState(prevState => ({ ...prevState, email: value }))
  }

  React.useEffect(() => {
    if (state.email) {
      navigate(`${location.pathname}?email=${state.email}`, { replace: true })
    }
  }, [navigate, location.pathname, state.email])

  const onLoginClick = () => {
    setState(prev => ({ ...prev, error: undefined, emailMissing: false }))
  }

  const onLoginError = (error: Error) => {
    console.log('[FB login error]', error)
    if (error.message === ERROR_NO_EMAIL_RETURNED) {
      setState(prev => ({ ...prev, error: undefined, emailMissing: true }))
      return
    }

    setState(prev => ({
      ...prev,
      error: intl.formatMessage({ id: 'errors.login.with-text' }, { message: error.message }),
      emailMissing: false
    }))
  }

  const loginParams = parse(location.search) as { [key: string]: string }

  return (
    <section data-test="landing" className={styles.wrapper}>
      {isMobileRef.current && <MobileWarningDialog />}
      <section className={styles.sidebar}>
        <div className={styles['side-content']}>
          <RandomUserQuote />
        </div>
      </section>
      <div className={styles.main}>
        <Logo className={styles.logo} key="logo" />
        <h1 className={styles.title}>Post Planner</h1>

        <EmailLoginForm
          loginParams={loginParams}
          fbLoginSection={(
            <section className={styles.actions}>
              <div className={styles['fb-box']}>
                <span>
                  <FBLoginButton
                    key="action-facebook"
                    loginParams={loginParams}
                    className={styles['btn-fb-login']}
                    onClick={onLoginClick}
                    onError={onLoginError}
                  />
                </span>
              </div>
              {state.emailMissing && (
                <div className={styles['email-wrapper']}>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  <p className={styles.error}>We couldn't get your email. Please enter it below and try logging in again.</p>
                  <TextField
                    type="email"
                    placeholder="Email"
                    classes={{ root: styles.email }}
                    onChange={handleEmailChange}
                  />
                </div>
              )}
              {state.error && (
                <p className={styles.error}>
                  {state.error}
                </p>
              )}
            </section>
          )}
        />
      </div>
      <ConnectedSnackbar />
    </section>
  )
}

export default Landing
