import * as React from 'react'
import { ComposerFacebookPost, FBAlbum } from 'services/compose/interfaces/ComposerFacebookPost'
import styles from './FacebookAlbumsPicker.pcss'
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import DeleteIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { FormattedMessage } from 'react-intl'
import { IndexedObject, PostDestination } from 'interfaces'
import { mdiImageAlbum } from '@mdi/js'
import Icon from '@mdi/react'
import { FacebookAlbumSelector } from 'components/Composer/AlbumSelector'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'

interface FacebookAlbumsPickerProps {
  post: ComposerFacebookPost
  profiles: PostDestination[]
  selectedAlbums: IndexedObject<FBAlbum | undefined>
  onSelectedAlbumsChange: (albums: IndexedObject<FBAlbum | undefined>, applyToAll: boolean) => void
}

export function FacebookAlbumsPicker(props: FacebookAlbumsPickerProps) {
  const [albumsPopupActive, setAlbumsPopupActive] = React.useState(false)
  const [applyToAll, setApplyToAll] = React.useState(false)
  const [selection, setSelection] = React.useState<IndexedObject<FBAlbum | undefined>>(props.selectedAlbums)

  React.useEffect(() => {
    setSelection(props.selectedAlbums)
  }, [props.selectedAlbums])

  const openAlbumsPopup = () => {
    setAlbumsPopupActive(true)
  }

  const closeAlbumsPopup = () => {
    setAlbumsPopupActive(false)
    setApplyToAll(false)
  }

  const toggleApplyToAll = () => {
    setApplyToAll(!applyToAll)
  }

  const confirmSelection = () => {
    props.onSelectedAlbumsChange(selection, applyToAll)
    closeAlbumsPopup()
  }

  const onSelectedAlbumChange = (profile: PostDestination, album: FBAlbum | undefined) => {
    setSelection(current => ({ ...current, [profile.ppid]: album }))
  }

  return (
    <React.Fragment>
      <Chip
        label={
          <FormattedMessage
            id={props.post.albums.length === 0 ? 'composer.labels.fb-album-default' : 'composer.labels.fb-album-custom'}
          />
        }
        avatar={<Icon path={mdiImageAlbum} size="20px" />}
        classes={{ root: styles.chip, label: styles.label }}
        onClick={openAlbumsPopup}
      />
      <Dialog open={albumsPopupActive} onClose={closeAlbumsPopup} classes={{ paper: styles['albums-popup'] }}>
        <IconButton className={styles['btn-close']} onClick={closeAlbumsPopup}>
          <DeleteIcon />
        </IconButton>
        <h3 className={styles['popup-title']}>
          <FormattedMessage id="composer.labels.select-multiple-albums" />
        </h3>
        <div className={styles['albums-popup-content']}>
          {props.profiles.map(profile => (
            <div className={styles['option-box']} key={profile.id}>
              <FacebookAlbumSelector
                profile={profile}
                raised
                selectedAlbumsCustom={[selection[profile.ppid]].filter(Boolean) as FBAlbum[]}
                onSelectedAlbumChangeCustom={onSelectedAlbumChange}
              />
            </div>
          ))}
        </div>
        <div className={styles.actions}>
          <div className={styles.cb}>
            <FormControlLabel
              label={<FormattedMessage id="general.labels.apply-to-all" />}
              classes={{ label: styles.label }}
              control={(
                <Checkbox
                  checked={applyToAll}
                  color="primary"
                  size="small"
                  onChange={toggleApplyToAll}
                />
              )}
            />
          </div>
          <Button onClick={closeAlbumsPopup}>
            <FormattedMessage id="actions.cancel" />
          </Button>
          <Button color="primary" variant="contained" onClick={confirmSelection}>
            <FormattedMessage id="actions.confirm" />
          </Button>
        </div>
      </Dialog>
    </React.Fragment>
  )
}
