import * as React from 'react'
import { Article, ARTICLE_TYPE, WithIntl } from 'interfaces'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { composerContentItemSelector, composerLinkSelector } from 'services/compose/selectors'
import { message } from 'services/snackbar'
import CopyText from './CopyText'

export function ArticleCredits(props: WithIntl) {
  const dispatch = useDispatch()
  const content = useSelector(composerContentItemSelector) as Article
  const link = useSelector(composerLinkSelector)

  if (content?.type !== ARTICLE_TYPE && !link) {
    return null
  }

  const title = (content as Article)?.title || link?.title
  const description = (content as Article)?.description || link?.description
  const url = (content as Article)?.sourceLink || link?.url
  const fullText = `${title || ''}\n\n${description || ''}\n\n${url}`

  const onCopy = () => {
    dispatch(message(props.intl.formatMessage({ id: 'notifications.status-copied' }), 'info'))
  }

  return (
    <div>
      {fullText && (
        <CopyText
          title={<FormattedMessage id="composer.labels.text-copy.link-full" />}
          messageText={fullText}
          showFullText
          onTextCopied={onCopy}
        />
      )}
      {title && (
        <CopyText
          title={<FormattedMessage id="composer.labels.text-copy.article-title" />}
          messageText={title}
          showFullText
          onTextCopied={onCopy}
        />
      )}
      {description && (
        <CopyText
          title={<FormattedMessage id="composer.labels.text-copy.article-description" />}
          messageText={description}
          showFullText
          onTextCopied={onCopy}
        />
      )}
      <CopyText
        title={<FormattedMessage id="composer.labels.text-copy.article-url" />}
        messageText={url}
        showFullText
        onTextCopied={onCopy}
      />
    </div>
  )
}

export default injectIntl(ArticleCredits)
