import * as React from 'react'
import CardList from 'components/CardList'
import { NavLink } from 'react-router-dom'
import styles from './ContentPanelBase.pcss'
import { CategoryChip } from './components/CategoryChip'
import Icon from '@mdi/react'
import { mdiShuffleVariant } from '@mdi/js'
import { FormattedMessage } from 'react-intl'
import { renderLoadingCards } from 'routes/find/routes/home/components/utils'

const LOADING_CARDS_COUNT = 20

interface ContentPanelBaseProps {
  title: string
  categories: Array<{ id: string, name: string }>
  activeCategoryId?: string
  navLink?: {
    text: string
    url: string
  }
  loading: boolean
  items?: React.ReactNode[]
  carouselElement?: React.ReactNode
  contentCarouselHeight: number
  className?: string
  onCategoryClick: (id: string) => void
  onLoadRandom: () => void
}

const CATEGORY_LIST_HEIGHT = 40

export function ContentPanelBase(props: ContentPanelBaseProps) {
  const [carouselKey, setCarouselKey] = React.useState(0)

  const onCategoryClick = (id: string) => {
    props.onCategoryClick(id)
    setCarouselKey(current => current + 1)
  }

  return (
    <section className={`${styles.section} ${props.className || ''}`}>
      <div className={styles.bg}></div>
      <h2 className={`${styles.title} text-ellipsis`}>
        {props.title}
        <span className={styles['text-btn']} onClick={props.onLoadRandom}>
          <Icon size="20px" path={mdiShuffleVariant} />
          <FormattedMessage id="label.generic.random" />
        </span>
      </h2>
      <CardList listHeight={CATEGORY_LIST_HEIGHT} containerClassName={styles['category-list']}>
        {props.categories.map(cat => (
          <CategoryChip
            key={cat.id}
            id={cat.id}
            name={cat.name}
            active={props.activeCategoryId === cat.id}
            onClick={onCategoryClick}
          />
        ))}
      </CardList>
      <div className={styles.content}>
        {props.navLink && (
          <NavLink to={props.navLink.url} className={styles.link}>
            {props.navLink.text}
          </NavLink>
        )}
        {props.items && (
          <CardList listHeight={props.contentCarouselHeight} key={carouselKey}>
            {props.loading ? renderLoadingCards(LOADING_CARDS_COUNT, true, true, true) : props.items}
          </CardList>
        )}
        {props.carouselElement && (
          <div style={{ height: `${props.contentCarouselHeight}px` }}>{props.carouselElement}</div>
        )}
      </div>
    </section>
  )
}
