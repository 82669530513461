import * as React from 'react'
import Button from '@mui/material/Button'
import Popper, { PopperProps } from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import styles from './ActionSelect.pcss'

export type ActionSelectOptions = Array<{
  element?: React.ReactNode
  actionProps?: {
    label: React.ReactNode
    value: string
    disabled?: boolean
    onClick: (value: string) => void
  }
}>

interface ActionSelectProps {
  actions: ActionSelectOptions
  name: React.ReactNode
  icon?: React.ReactNode
  className?: string
  buttonClassName?: string
  popperProps?: Partial<PopperProps>
}

export function ActionSelect(props: ActionSelectProps) {
  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null)
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const close = React.useCallback(() => {
    setAnchor(null)
  }, [])

  const open = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (buttonRef.current) {
      setAnchor(buttonRef.current)
    }
    e.stopPropagation()
    e.preventDefault()
  }

  const onClick = (callback: (val: string) => void) => (value: string) => {
    close()
    callback(value)
  }

  return (
    <div className={`${styles.box} ${props.className || ''}`}>
      <Button
        startIcon={props.icon}
        variant="text"
        className={props.buttonClassName || ''}
        ref={buttonRef}
        onClick={open}
      >
        {props.name}
      </Button>
      <Popper
        open={Boolean(anchor)}
        anchorEl={anchor}
        placement="bottom"
        className={styles.popper}
        {...props.popperProps}
      >
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={close}>
          <Paper>
            <ul className={styles.list}>
              {props.actions.map((action, index) => {
                if (action.element) {
                  return (
                    <li key={index} className={styles.li} onClick={close}>
                      {action.element}
                    </li>
                  )
                }
                if (action.actionProps) {
                  return (
                    <ListItem
                      key={action.actionProps.value}
                      label={action.actionProps.label}
                      disabled={action.actionProps.disabled}
                      value={action.actionProps.value}
                      onClick={onClick(action.actionProps.onClick)}
                    />
                  )
                }
                return null
              })}
            </ul>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

function ListItem(props: {
  label: React.ReactNode,
  value: string,
  disabled?: boolean,
  onClick: (value: string) => void
}) {
  const onClick = () => {
    if (props.disabled) {
      return
    }
    props.onClick(props.value)
  }

  return (
    <li
      key={props.value}
      className={`${styles.li} ${props.disabled ? styles.disabled : ''}`}
      onClick={onClick}
    >
      {props.label}
    </li>
  )
}

export default ActionSelect
