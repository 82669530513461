import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import IconPrev from '@mui/icons-material/KeyboardArrowLeft'
import IconNext from '@mui/icons-material/KeyboardArrowRight'
import {
  Article,
  ARTICLE_TYPE,
  FEED_TYPE_TWITTER,
  Gif,
  GIF_TYPE,
  Photo,
  PHOTO_TYPE,
  Video,
  VIDEO_TYPE
} from 'interfaces'
import styles from './ComposerContentPreview.pcss'
import { ComposerArticlePreview } from './ComposerArticlePreview'
import { ComposerPhotoContentPreview } from './ComposerPhotoContentPreview'
import { ComposerVideoContentPreview } from './ComposerVideoContentPreview'
import { useDispatch, useSelector } from 'react-redux'
import { composerAllLinksSelector, composerContentItemSelector, composerPostAsPhotoSelector } from 'services/compose/selectors'
import { clearComposerContent, clearComposerLink, setSelectedLink } from 'services/compose'

export function ComposerContentPreview(props: { className?: string }) {
  const dispatch = useDispatch()
  const [selectedArticleIndex, setSelectedArticleIndex] = React.useState(0)
  const content = useSelector(composerContentItemSelector)
  const links = useSelector(composerAllLinksSelector)
  const postAsPhoto = useSelector(composerPostAsPhotoSelector)

  const article = content as Article
  const gif = content as Gif
  const isTwitterGif = content && content?.type === GIF_TYPE && content.feed.type === FEED_TYPE_TWITTER
  const twitterGifAsVideo: Video | null = isTwitterGif ? {
    ...gif,
    id: gif.id,
    videoUrl: gif.gifUrl,
    type: VIDEO_TYPE
  } : null

  const articles = React.useMemo(() => {
    const data: any[] = []
    if (content?.type === ARTICLE_TYPE) {
      data.push({
        title: article.title,
        description: article.description,
        imageUrl: article.imageUrl,
        url: article.sourceLink,
        socialUrl: article.socialLink
      })
    }
    links.forEach((link) => {
      data.push({
        title: link.title,
        description: link.description,
        imageUrl: link.image[0],
        url: link.url
      })
    })
    return data
  }, [links, content, article])

  const articleInfo = React.useMemo(() => {
    return articles[selectedArticleIndex]
  }, [selectedArticleIndex, articles])

  const onClear = React.useCallback(() => {
    if (content?.type && content.type !== ARTICLE_TYPE) {
      dispatch(clearComposerContent())
      return
    }

    if (articles.length > 0) {
      const selected: any = articles[selectedArticleIndex]
      if (content?.type === ARTICLE_TYPE && selected.url === (content as Article).sourceLink) {
        dispatch(clearComposerContent())
      } else {
        dispatch(clearComposerLink(selected.url))
      }
    }
  }, [articles, content, dispatch, selectedArticleIndex])

  const prev = React.useCallback(() => {
    setSelectedArticleIndex(current => {
      if (current === 0) {
        return Math.max(articles.length - 1, 0)
      }
      return current - 1
    })
  }, [articles.length])

  const next = React.useCallback(() => {
    setSelectedArticleIndex(current => {
      if (current === articles.length - 1) {
        return 0
      }
      return current + 1
    })
  }, [articles.length])

  React.useEffect(() => {
    setSelectedArticleIndex(current => {
      if (current >= articles.length) {
        return 0
      }
      return current
    })
  }, [articles.length])

  React.useEffect(() => {
    const articleUrl = (articles[selectedArticleIndex] as any)?.url
    dispatch(setSelectedLink(articleUrl))
  }, [selectedArticleIndex, articles.length, dispatch])

  return (
    <div className={`${styles.container} ${props.className || ''}`} data-test="content-preview">
      <div className={styles.content}>
        <div className={`${styles.actions} ${postAsPhoto ? styles.small : ''}`}>
          {!postAsPhoto && articles.length > 1 && (
            <React.Fragment>
              <IconButton size="small" onClick={prev}>
                <IconPrev />
              </IconButton>
              <IconButton size="small" onClick={next}>
                <IconNext />
              </IconButton>
            </React.Fragment>
          )}
          <IconButton size="small" className={styles['btn-clear']} onClick={onClear}>
            <CloseIcon />
          </IconButton>
        </div>
        {articleInfo && (
          <ComposerArticlePreview article={articleInfo} className={styles['article-box']} />
        )}
        {content?.type === PHOTO_TYPE && (
          <ComposerPhotoContentPreview className={styles['photo-box']} photo={content as Photo} />
        )}
        {content?.type === VIDEO_TYPE && (
          <ComposerVideoContentPreview video={content as Video} className={styles['video-box']} />
        )}
        {twitterGifAsVideo && (
          <ComposerVideoContentPreview video={twitterGifAsVideo} className={styles['video-box']} />
        )}
      </div>
    </div>
  )
}
