import { IndexedObject, PostDestination, sortNetworksDefault } from 'interfaces'

const BASE_TEN = 10

export function orderComparator<T extends { order: number }>(a: T, b: T) {
  if (a.order < b.order) {
    return -1
  }
  if (a.order === b.order) {
    return 0
  }
  return 1
}

export const sortByKeyAscending = (key: string) => (f1: any, f2: any) => {
  if (parseInt(f1[key], BASE_TEN) < parseInt(f2[key], BASE_TEN)) {
    return -1
  }
  if (parseInt(f1[key], BASE_TEN) === parseInt(f2[key], BASE_TEN)) {
    return 0
  }
  return 1
}

export function sortByKeyAlphabetically(key: string, reverse?: boolean) {
  return function (o1: IndexedObject<any>, o2: IndexedObject<any>) {
    if (reverse) {
      return o2[key].localeCompare(o1[key])
    }
    return o1[key].localeCompare(o2[key])
  }
}

// Moves an item in an array from startIndex to endIndex position
export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

// Orderes profiles by network first and then by name
export function orderProfilesByNetwork(profiles: PostDestination[]) {
  const groupedProfiles = profiles.reduce((map: IndexedObject<PostDestination[]>, p: PostDestination) => {
    if (map[p.type]) {
      map[p.type].push(p)
    } else {
      map[p.type] = [p]
    }
    return map
  }, {})

  const orderedProfiles: PostDestination[] = []

  Object.keys(groupedProfiles)
    .sort(sortNetworksDefault)
    .forEach(network => {
      const orderedByName = groupedProfiles[network].sort(sortByKeyAlphabetically('name'))
      orderedProfiles.push(...orderedByName)
    })

  return orderedProfiles
}

export default orderComparator

export const sortByDateKeyAscending = (key: string) => (f1: any, f2: any) => {
  try {
    const t1 = f1[key].getTime()
    const t2 = f2[key].getTime()
    if (t1 < t2) {
      return 1
    }
    if (t1 === t2) {
      return 0
    }
    return -1
  } catch (e) {
    console.log('[sortByDateKeyAscending()] error', e)
    return 0
  }
}
