import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'
import { CreatePostRequestData } from 'shared/types'
import toSnakeCase from 'utils/toSnakeCase'

export const ENDPOINT_UPDATE_POST = `${LEGACY_URL}/v3/composer_new`

export const V1 = Object.freeze({
  updatePost: function () {
    const builder = new RequestBuilder(ENDPOINT_UPDATE_POST).asPost().asFormUrlEncoded().expectJSON()

    return function (postId: string, data: CreatePostRequestData, bucketId?: string) {
      return authorizedPipe<any>(
        builder
          .body({ data: JSON.stringify(toSnakeCase({ ...data, postId, bucketId })) })
          .build()
      )
    }
  }()
})
