import * as React from 'react'
import Button from '@mui/material/Button'
import styles from './ComposerBulkUploadBlock.pcss'
import FileUploader from 'components/FileUploader'
import { FileUploaderView, WithIntl } from 'interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { composerBulkFileSelector, composerSelectedProfilesSelector } from 'services/compose/selectors'
import { deleteFile, setComposerBulkUploadFileUrl } from 'services/compose'
import { FormattedMessage, injectIntl } from 'react-intl'
import { message } from 'services/snackbar'
import { Upload } from 'components/FileUpload'
import { noop } from 'utils/noop'
import { StoreThunkDispatch } from 'store/state'
import Dialog from '@mui/material/Dialog'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Close'

const MAX_BULK_POSTS = 3000

interface ComposerBulkUploadBlockProps {
  className?: string
  onUploadStarted: () => void
  onFileUploaded: () => void
  onUploadError: () => void
}

export function ComposerBulkUploadBlock(props: ComposerBulkUploadBlockProps & WithIntl) {
  const selectedProfiles = useSelector(composerSelectedProfilesSelector)
  const [file, setFile] = React.useState<File | null>(null)
  const [prevUrl, setPrevUrl] = React.useState('')
  const [uploaderKey, setUploaderKey] = React.useState(0)
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [warningDialogOpen, setWarningDialogOpen] = React.useState(false)
  const bulkUploadFile = useSelector(composerBulkFileSelector)

  const { onUploadError } = props

  React.useEffect(() => {
    if (!bulkUploadFile) {
      setUploaderKey(current => current + 1)
    }
  }, [bulkUploadFile])

  const clearFile = () => {
    dispatch(setComposerBulkUploadFileUrl({ url: undefined }))
    setUploaderKey(current => current + 1)
  }

  const onError = () => {
    dispatch(message(props.intl.formatMessage({ id: 'composer.errors.upload-generic' }), 'error'))
  }

  const onFileSelected = (f: File) => {
    dispatch(setComposerBulkUploadFileUrl({ url: undefined }))
    setFile(f)
    if (prevUrl) {
      dispatch(deleteFile(prevUrl))
    }
  }

  const onFileUploaded = React.useCallback((url: string, response: any) => {
    const rows: number = response?.rows
    if (rows) {
      const postsCount = rows * selectedProfiles.length
      if (postsCount > MAX_BULK_POSTS) {
        setWarningDialogOpen(true)
      }

      dispatch(message(props.intl.formatMessage({ id: 'uploads.notifications.file-uploaded' }), 'success'))
      setPrevUrl(url)
      dispatch(setComposerBulkUploadFileUrl({ url, name: file?.name, rows }))
      props.onFileUploaded()
    } else {
      onFileUploadError()
    }
  }, [dispatch, props.intl, selectedProfiles.length, file])

  const onFileUploadError = React.useCallback(() => {
    dispatch(message(props.intl.formatMessage({ id: 'uploads.error.upload-failed' }), 'error'))
    dispatch(setComposerBulkUploadFileUrl({ url: undefined }))
    onUploadError()
  }, [dispatch, props.intl, onUploadError])

  const clearFileAndCloseWarningDialog = () => {
    setWarningDialogOpen(false)
    setUploaderKey(current => current + 1)
    dispatch(setComposerBulkUploadFileUrl({ url: undefined }))
  }

  const closeWarningDialog = () => {
    setWarningDialogOpen(false)
  }

  return (
    <div className={`${styles.block} ${props.className || ''}`}>
      {file && (
        <Upload
          file={file}
          onUploadStarted={props.onUploadStarted}
          onError={onFileUploadError}
          onProgress={noop}
          onSuccess={onFileUploaded}
        />
      )}
      <div>
        <FileUploader
          key={uploaderKey}
          view={FileUploaderView.Bulk}
          className={styles.uploader}
          onError={onError}
          clearExcelFileButton={prevUrl ? (
            <Tooltip key="btn-remove" title={props.intl.formatMessage({ id: 'composer.actions.remove-bulk-file' })}>
              <DeleteIcon fontSize="small" className={styles['btn-delete']} onClick={clearFile} />
            </Tooltip>
          ) : undefined}
          onFileUploaded={onFileSelected}
        />
      </div>
      <Dialog open={warningDialogOpen} classes={{ paper: styles.dialog }}>
        <div className={styles.message}>
          <FormattedMessage id="uploads.bulk.rows-limit-warning.message" values={{ count: MAX_BULK_POSTS }} />
          <p><FormattedMessage id="uploads.bulk.rows-limit-warning.note" values={{ count: MAX_BULK_POSTS }} /></p>
        </div>
        <div className={styles.actions}>
          <Button onClick={clearFileAndCloseWarningDialog}>
            <FormattedMessage id="uploads.bulk.rows-limit-warning.action-cancel" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={styles['btn-submit']}
            onClick={closeWarningDialog}
          >
            <FormattedMessage id="uploads.bulk.rows-limit-warning.action-accept" />
          </Button>
        </div>
      </Dialog>
    </div>
  )
}

export default injectIntl(ComposerBulkUploadBlock)
