import { Bucket, ContentType, PostDestination } from 'interfaces'
import { Weekday } from 'utils/calendar'

export const POST_STATE_VERSION = 'post:9'

export type PlannedPostsLayout = 'list' | 'grid' | 'calendar'
export function isPlannedPostsLayout(value: any): value is PlannedPostsLayout {
  return value === 'list' || value === 'grid' || value === 'calendar'
}
export type CalendarPostSize = 'small' | 'medium' | 'large'
export type CalendarMonthStartOption = '1st' | 'this-week'

export interface AutoPostSlot {
  id: string
  time: number
  ppPageId: string
  buckets: ContentType[]
  dateString: string
  timeString: string
  weekday: string
  used?: boolean
}

export interface PostState {
  autoPostSlots: AutoPostSlot[]
  ui: {
    selectedProfiles: PostDestination[]
    selectedListId?: string
    selectionActiveView: 'grid' | 'list' | 'network'
    showEmptySlots: boolean
    showVirtualPosts: boolean
    plannedPostsView: PlannedPostsLayout
    calendarMode: 'month' | 'week'
    weekViewExpanded: boolean
    calendarPostSize: CalendarPostSize
    calendarMonthStart: CalendarMonthStartOption
    calendarWeekStart: Weekday
    showPostedInCalendar: boolean
    sidebarProfilesExpanded: boolean
  }
  buckets: Bucket[]
  bucketPresets: Array<{ name: string, color: string, description: string, tag: string }>
  newPost?: string // new post created from the composer while on Post page.
  failed: {
    byPpid: { [ppid: string]: number }
    total: number
    loading: boolean
  }
}

export function initialState(): PostState {
  return Object.freeze({
    newPost: undefined,
    autoPostSlots: [],
    buckets: [],
    ui: {
      selectedProfiles: [],
      selectionActiveView: 'grid',
      showEmptySlots: true,
      plannedPostsView: 'calendar',
      calendarMode: 'month',
      weekViewExpanded: true,
      calendarPostSize: 'small',
      calendarMonthStart: 'this-week',
      calendarWeekStart: 'sunday',
      showPostedInCalendar: true,
      showVirtualPosts: false,
      sidebarProfilesExpanded: true
    },
    bucketPresets: [],
    failed: {
      byPpid: {},
      total: 0,
      loading: false
    }
  } as PostState)
}

export default PostState
