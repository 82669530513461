import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import * as React from 'react'

import styles from './TagPopup.pcss'

export function TagPopup(props: { anchor: HTMLElement | null }) {
  const element = props.anchor
  if (!element) {
    return null
  }
  const name = element.dataset.tagName
  const handle = element.dataset.tagHandle
  const imageUrl = element.dataset.tagImage

  return (
    <Popper
      open={Boolean(props.anchor)}
      anchorEl={props.anchor}
      className={styles.popup}
      placement="top"
    >
      <Paper className={styles.container}>
        <div className={styles['img-box']}>
          <img src={imageUrl} />
        </div>
        <div className={styles['tag-info']}>
          <p className={`${styles.name} text-ellipsis`} title={name}>{name}</p>
          {handle !== name && <p className={`${styles.handle} text-ellipsis`}>{handle}</p>}
        </div>
      </Paper>
    </Popper>
  )
}

export default TagPopup
