import * as React from 'react'
import { Bucket } from 'interfaces'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { getBuckets } from 'services/post/actions'

interface FetchBucketsProps {
  force?: boolean
  onFetched?: (buckets: Bucket[]) => void
  onError?: (error: any) => void
}

export function FetchBuckets(props: FetchBucketsProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()

  React.useEffect(() => {
    dispatch(getBuckets(props.force)).unwrap().then(props.onFetched).catch(props.onError)
  }, [dispatch, props.force, props.onError, props.onFetched])

  return null
}
