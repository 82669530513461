import * as React from 'react'
import { StoreState, StoreThunkDispatch } from 'store/state'
import { connect } from 'react-redux'
import { checkTokens, skipTokensRefresh, CHECK_TOKENS_INTERVAL_DEFAULT } from 'services/auth'
import { tokensStateSelector } from 'store/selectors'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import { Observable } from 'rxjs/Observable'
import { Subject } from 'rxjs/Subject'
import {
  BRAND_FACEBOOK,
  FacebookLoginResponse,
  BRAND_LINKEDIN,
  BRAND_INSTAGRAM,
  BRAND_PINTEREST,
  BRAND_TWITTER,
  BRAND_GOOGLE,
  BRAND_TIKTOK
} from 'interfaces'
import { getLoginStatus } from 'services/auth/identities/facebook'
import { facebookSDKInitializedSelector } from 'services/external/selectors'
import { NavLink, useNavigate } from 'react-router-dom'
import ProfileAvatar from 'components/ProfileAvatar'
import styles from './TokensChecker.pcss'
import { FormattedMessage } from 'react-intl'
import { differenceInMilliseconds } from 'date-fns'

const TWITTER_CONNECT_MESSAGE = 'Recent changes at Twitter require that you update your connections for each of your Twitter accounts. '
+ 'Please re-connect each account with both the "Connect (old)" and "Connect (new)" '
+ 'buttons to make sure your posts continue to publish properly.'

interface TokensCheckerOwnProps { navigate: any }

type ExpiredProfile = {
  name: string
  picture: string
}
interface TokensCheckerConnectedProps {
  lastCheck?: Date,
  expiredFacebookProfiles: Array<{
    name: string
    pageId: string
  }>
  expiredLinkedinProfiles: Array<ExpiredProfile>
  expiredInstagramProfiles: Array<ExpiredProfile>
  expiredPinterestProfiles: Array<ExpiredProfile>
  expiredTwitterProfiles: Array<ExpiredProfile>
  expiredGoogleProfiles: Array<ExpiredProfile>
  expiredTiktokProfiles: Array<ExpiredProfile>
  dialogDismissed: boolean
  fbInitialized: boolean
  checkTokens: () => Observable<any>
  skipTokensRefresh: (dismissDialog?: boolean) => void
  getFBLoginStatus: () => Promise<FacebookLoginResponse>
}

type TokensCheckerProps = TokensCheckerOwnProps & TokensCheckerConnectedProps

/**
 * TokensChecker component checks fb tokens every {interval} ms
 */
export class TokensChecker extends React.PureComponent<TokensCheckerProps> {
  private check$: Subject<any>
  private timer: number

  get timeSinceLastCheck(): number {
    const now = new Date()
    return differenceInMilliseconds(now, this.props.lastCheck || now)
  }

  componentDidMount() {
    this.check$ = new Subject()
    this.check$.flatMap(this.props.checkTokens).subscribe()

    this.timer = this.initTimer(CHECK_TOKENS_INTERVAL_DEFAULT)

    if (!this.props.lastCheck || Math.abs(this.timeSinceLastCheck) > CHECK_TOKENS_INTERVAL_DEFAULT) {
      this.checkTokens()
    }
  }

  checkTokens = () => {
    if (this.props.fbInitialized) {
      this.props.getFBLoginStatus()
    }
    this.check$.next()
  }

  componentWillUnmount() {
    window.clearInterval(this.timer)
    this.check$.unsubscribe()
  }

  initTimer = (interval: number) => {
    return window.setInterval(this.checkTokens, interval)
  }

  gotoSettings = () => {
    this.props.navigate('/settings/networks')
    this.props.skipTokensRefresh(true)
  }

  skipRefreshAndDismissDialog = () => {
    this.props.skipTokensRefresh(true)
  }

  render() {
    const {
      expiredFacebookProfiles,
      expiredLinkedinProfiles,
      expiredInstagramProfiles,
      expiredGoogleProfiles,
      expiredTiktokProfiles,
      expiredTwitterProfiles,
      expiredPinterestProfiles,
      dialogDismissed
    } = this.props
    const expiredProfilesCount = expiredFacebookProfiles.length
      + expiredLinkedinProfiles.length
      + expiredInstagramProfiles.length
      + expiredPinterestProfiles.length
      + expiredGoogleProfiles.length
      + expiredTiktokProfiles.length
      + expiredTwitterProfiles.length

    const dialogActive = !dialogDismissed && expiredProfilesCount > 0
    // Show twitter-specific text if all profiles are Twitter
    const showTwitterTextOnly = expiredProfilesCount === expiredTwitterProfiles.length

    return (
      <Dialog
        open={Boolean(dialogActive)}
        maxWidth="xs"
        classes={{ paper: styles.dialog }}
        onClose={this.skipRefreshAndDismissDialog}
      >
        <h1 className={styles.title}>Action Required!</h1>
        {showTwitterTextOnly ? (
          <p>{TWITTER_CONNECT_MESSAGE}</p>
        ) : (
          <p>
            Your posting tokens for the profiles listed below have been invalidated.
            <br />
            To make sure your posts keep going out to these pages, go to
            <NavLink to="/settings/networks" onClick={this.skipRefreshAndDismissDialog}> Settings</NavLink>
            {' '}
            and
            click the Refresh/Connect button in the corresponding section.
          </p>
        )}
        {!showTwitterTextOnly && expiredTwitterProfiles.length > 0 && (
          <p>{TWITTER_CONNECT_MESSAGE}</p>
        )}
        <div className={styles['profiles-box']}>
          {expiredFacebookProfiles.map(p => {
            const imageUrl = `//graph.facebook.com/${p.pageId}/picture?type=large`
            return (
              <div className={styles['avatar-box']} key={p.pageId}>
                <ProfileAvatar src={imageUrl} brand={BRAND_FACEBOOK} circle title={p.name} />
                <span className={styles['profile-name']}>{p.name}</span>
              </div>
            )
          })}
          {expiredInstagramProfiles.map(p => (
            <div className={styles['avatar-box']} key={p.name}>
              <ProfileAvatar src={p.picture} brand={BRAND_INSTAGRAM} circle title={p.name} />
              <span className={styles['profile-name']}>{p.name}</span>
            </div>
          ))}
          {expiredLinkedinProfiles.map(p => (
            <div className={styles['avatar-box']} key={p.name}>
              <ProfileAvatar src={p.picture} brand={BRAND_LINKEDIN} circle title={p.name} />
              <span className={styles['profile-name']}>{p.name}</span>
            </div>
          ))}
          {expiredPinterestProfiles.map(p => (
            <div className={styles['avatar-box']} key={p.name}>
              <ProfileAvatar src={p.picture} brand={BRAND_PINTEREST} circle title={p.name} />
              <span className={styles['profile-name']}>{p.name}</span>
            </div>
          ))}
          {expiredTwitterProfiles.map(p => (
            <div className={styles['avatar-box']} key={p.name}>
              <ProfileAvatar src={p.picture} brand={BRAND_TWITTER} circle title={p.name} />
              <span className={styles['profile-name']}>{p.name}</span>
            </div>
          ))}
          {expiredGoogleProfiles.map(p => (
            <div className={styles['avatar-box']} key={p.name}>
              <ProfileAvatar src={p.picture} brand={BRAND_GOOGLE} circle title={p.name} />
              <span className={styles['profile-name']}>{p.name}</span>
            </div>
          ))}
          {expiredTiktokProfiles.map(p => (
            <div className={styles['avatar-box']} key={p.name}>
              <ProfileAvatar src={p.picture} brand={BRAND_TIKTOK} circle title={p.name} />
              <span className={styles['profile-name']}>{p.name}</span>
            </div>
          ))}
        </div>
        <div className={styles.actions}>
          <Button
            variant="text"
            onClick={this.skipRefreshAndDismissDialog}
          >
            <FormattedMessage id="actions.cancel" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.gotoSettings}
          >
            Open Settings
          </Button>
        </div>
      </Dialog>
    )
  }
}

function mapStateToProps(state: StoreState) {
  const tokensState = tokensStateSelector(state)
  const expiredFacebookProfiles = tokensState.refreshRequired?.facebook || []
  const expiredLinkedinProfiles = tokensState.refreshRequired?.linkedin || []
  const expiredInstagramProfiles = tokensState.refreshRequired?.instagram || []
  const expiredPinterestProfiles = tokensState.refreshRequired?.pinterest || []
  const expiredTwitterProfiles = tokensState.refreshRequired?.twitter || []
  const expiredGoogleProfiles = tokensState.refreshRequired?.google || []
  const expiredTiktokProfiles = tokensState.refreshRequired?.tiktok || []

  return {
    lastCheck: tokensState.lastCheck ? new Date(tokensState.lastCheck) : new Date(),
    expiredFacebookProfiles,
    expiredLinkedinProfiles,
    expiredInstagramProfiles,
    expiredPinterestProfiles,
    expiredTwitterProfiles,
    expiredTiktokProfiles,
    expiredGoogleProfiles,
    dialogDismissed: tokensState.dialogDismissed,
    fbInitialized: facebookSDKInitializedSelector(state)
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    checkTokens: () => dispatch(checkTokens()),
    skipTokensRefresh: (dismissDialog?: boolean) => dispatch(skipTokensRefresh(dismissDialog)),
    getFBLoginStatus: () => dispatch(getLoginStatus())
  }
}

function TokensCheckerWithRouter(props: any) {
  const navigate = useNavigate()

  return <TokensChecker {...props} navigate={navigate} />
}

export default connect(mapStateToProps, mapDispatchToProps)(TokensCheckerWithRouter)
