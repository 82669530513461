import * as React from 'react'
import { connect } from 'react-redux'
import { ContentItem } from 'interfaces'
import CardList from 'components/CardList'
import AnyCard from 'components/Card'
import Dialog from '@mui/material/Dialog'
import StoreState, { StoreDispatch } from 'store/state'
import { closeContentPopup, contentPopupActiveSelector, contentPopupItemsSelector } from 'services/contentPopup'

import styles from './ContentPopup.pcss'

interface ContentPopupProps {
  open: boolean
  items: ContentItem[]
  closePopup(): void
}

export function ContentPopup(props: ContentPopupProps) {
  return (
    <Dialog open={props.open} onClose={props.closePopup} classes={{ paper: styles.dialog }}>
      <CardList containerClassName={styles.list}>
        {
          props.items
            .map((item, index) => (
              <AnyCard
                key={`${item.id}-${index}`}
                content={item as ContentItem}
                children={item as ContentItem}
              />
            ))
        }
      </CardList>
    </Dialog>
  )
}

function mapStateToProps(state: StoreState) {
  const open = contentPopupActiveSelector(state)
  const items = contentPopupItemsSelector(state)

  return { open, items }
}

function mapDispatchToProps(dispatch: StoreDispatch) {
  return {
    closePopup: () => { dispatch(closeContentPopup()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentPopup)
