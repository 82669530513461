import * as React from 'react'
import Button from '@mui/material/Button'
import styles from './SaveButton.pcss'
import { Tooltip } from '@mui/material'
import { FormattedMessage } from 'react-intl'

export interface SaveButtonProps {
  saved?: boolean
  key?: string
  className?: string
  loading?: boolean
  onClick?(): void
}

export function SaveButton({ saved, onClick, className, loading }: SaveButtonProps) {
  const translationId = saved ? 'actions.label.saved' : 'actions.save'
  const withTooltip = typeof saved === 'boolean'

  return (
    <Tooltip title={withTooltip ? <FormattedMessage id={translationId} /> : ''} placement="top">
      <Button
        disabled={loading}
        color="primary"
        variant="contained"
        className={`${className || ''} ${styles['btn-save']}`}
        onClick={onClick}
      >
        {saved && <FormattedMessage id="actions.label.saved" />}
        {!saved && <span>+ <FormattedMessage id="actions.save" /></span>}
      </Button>
    </Tooltip>
  )
}

export default SaveButton
