import { buildHTMLWithTags, getTwitterHtmlFromText } from 'utils/composer'
import TagPopup from 'components/TagPopup'
import { PostDestinationType, SocialProfileTag } from 'shared/types'
import * as React from 'react'

import styles from './PostText.pcss'
import { BRAND_TWITTER } from 'interfaces'

export interface PostTextProps {
  text: string
  network: PostDestinationType
  tags?: SocialProfileTag[]
  linkedinFormattedText?: string
  className?: string
}

export const PostText = React.memo((props: PostTextProps) => {
  const [tagPopupAnchor, setTagPopupAnchor] = React.useState<HTMLElement | null>(null)
  const textElementRef = React.useRef<HTMLDivElement>(null)
  const withTags = Boolean(props.tags)
  const [isParsed, setIsParsed] = React.useState(!withTags)

  const onTagMouseEnter = (e: Event) => {
    const element = e.target as HTMLElement
    setTagPopupAnchor(element)
  }

  const onTagMouseLeave = () => {
    setTagPopupAnchor(null)
  }

  React.useEffect(() => {
    const textElement = textElementRef.current
    if (props.network === BRAND_TWITTER) {
      if (textElement) {
        textElement.innerHTML = getTwitterHtmlFromText(props.text)
      }
      return
    }
    if (props.tags && Array.isArray(props.tags) && textElement) {
      const htmlString = buildHTMLWithTags(props.text, props.tags, props.network, styles.tag, props.linkedinFormattedText)
      textElement.innerHTML = htmlString
      textElement.querySelectorAll('[data-tag-id]').forEach(element => {
        element.addEventListener('mouseenter', onTagMouseEnter)
        element.addEventListener('mouseleave', onTagMouseLeave)
      })
      setIsParsed(true)
      return
    }

    if (textElement) {
      textElement.innerHTML = props.text
    }
  }, [props.network, props.tags, props.text, props.linkedinFormattedText])

  return (
    <React.Fragment>
      <div ref={textElementRef} className={`${isParsed ? '' : styles.hidden} ${props.className || ''}`}>
      </div>
      <TagPopup anchor={tagPopupAnchor} />
    </React.Fragment>
  )
})

export default PostText
