import { Callout } from '../../components/PageCallout'

export interface AppUIState {
  callouts: Callout[]
  calloutsVersion: number
  activeCallout?: Callout
  trialPopupForceOpen: {
    handle: string | null
    isAnnual?: boolean
  }
  trainingWidgetHidden?: boolean
}

export function initialState(): AppUIState {
  return {
    callouts: [],
    calloutsVersion: 1,
    trialPopupForceOpen: { handle: null }
  }
}
