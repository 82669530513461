import { RequestBuilder, authorizedPipe } from 'services/net'
import { CONTENT_SERVICE_URL } from 'config'
import { ContentSource } from 'interfaces'

export const ENDPOINT_LATEST_SEARCHES = `${CONTENT_SERVICE_URL}/search/recent`
export const ENDPOINT_RECENTLY_VIEWED = `${CONTENT_SERVICE_URL}/search/recently-viewed`
const ENDPOINT_DELETE_RECENT_SEARCH = `${CONTENT_SERVICE_URL}/search/recent/:term`

export const V1 = Object.freeze({
  getLatestSearches: function () {
    const builder = new RequestBuilder(ENDPOINT_LATEST_SEARCHES).asGet().expectJSON()

    return function () {
      return authorizedPipe<{ searches: Array<{ term: string }> }>(
        builder.build()
      )
    }
  }(),

  addLatestSearch: function () {
    const builder = new RequestBuilder(ENDPOINT_LATEST_SEARCHES).asPost().expectJSON().asFormUrlEncoded()

    return function (query: string) {
      return authorizedPipe<any>(
        builder.body({ term: query }).build()
      )
    }
  }(),

  getRecentlyViewed: function () {
    const builder = new RequestBuilder(ENDPOINT_RECENTLY_VIEWED).asGet().expectJSON()

    return function () {
      return authorizedPipe<{ recent: ContentSource[] }>(
        builder.build()
      )
    }
  }(),

  addRecentlyViewed: function () {
    const builder = new RequestBuilder(ENDPOINT_RECENTLY_VIEWED).asPost().expectJSON().asFormUrlEncoded()

    return function (id: string, type: 'feed' | 'stream') {
      return authorizedPipe<any>(
        builder
          .body({ id, type })
          .build()
      )
    }
  }(),
  deleteRecentSearch: function () {
    const builder = new RequestBuilder(ENDPOINT_DELETE_RECENT_SEARCH).asDelete().expectJSON().asFormUrlEncoded()
    return function (term: string) {
      return authorizedPipe<any>(
        builder
          .partial(':term', term)
          .build()
      )
    }
  }()
})
