import * as React from 'react'
import { connect } from 'react-redux'
import ListEditorDialog from './ListEditorDialog'
import StoreState, { StoreThunkDispatch } from 'store/state'
import { userListsSelector } from 'services/lists/selectors'
import { getLists, createList, updateList, deleteList } from 'services/lists/actions'
import { connectedDestinationsSelector } from 'services/destinations'
import { PostDestination, List } from 'interfaces'

interface ListEditorDialogConnectedProps {
  lists: { [id: string]: List }
  profiles: { [id: string]: PostDestination }
  onListCreate: (name: string, profiles: string[]) => Promise<any>
  onListUpdate: (id: string, name: string, profiles: string[]) => Promise<any>
  onListDelete: (id: string) => Promise<any>
  fetchLists: () => Promise<any>
}

interface ListEditorDialogOwnProps {
  open: boolean
  onClose: () => void
}

export type ListEditorDialogProps = ListEditorDialogConnectedProps & ListEditorDialogOwnProps

interface ListEditorDialogState {
  loading: boolean
  creatingList: boolean
}

class ListEditorDialogContainer extends React.Component<ListEditorDialogProps, ListEditorDialogState> {
  constructor(props: ListEditorDialogProps) {
    super(props)

    this.state = {
      loading: true,
      creatingList: false
    }
  }

  componentDidMount() {
    this.fetchLists()
  }

  fetchLists = () => {
    this.props.fetchLists().finally(this.loadingDone)
  }

  loadingDone = () => {
    this.setState(prevState => ({ ...prevState, loading: false }))
  }

  creatingListDone = () => {
    this.setState(prevState => ({ ...prevState, creatingList: false }))
  }

  onCreateList = (name: string, profiles: string[]) => {
    this.setState(prevState => ({ ...prevState, creatingList: true }))
    this.props.onListCreate(name, profiles).finally(this.creatingListDone)
  }

  onUpdateList = (id: string, name: string, profiles: string[]) => {
    this.props.onListUpdate(id, name, profiles).then(this.fetchLists)
  }

  onDeleteList = (id: string) => {
    this.props.onListDelete(id).then(this.fetchLists)
  }

  render() {
    return (
      <ListEditorDialog
        open={this.props.open}
        lists={this.props.lists}
        profiles={this.props.profiles}
        loading={this.state.loading}
        creatingList={this.state.creatingList}
        onCreateList={this.onCreateList}
        onUpdateList={this.onUpdateList}
        onDeleteList={this.onDeleteList}
        onClose={this.props.onClose}
      />
    )
  }
}

function mapStateToProps(state: StoreState) {
  return {
    lists: userListsSelector(state),
    profiles: connectedDestinationsSelector(state)
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    onListCreate: (name: string, pages: string[]) => dispatch(createList({ name, pages })),
    onListUpdate: (id: string, name: string, pages: string[]) => dispatch(updateList({ id, name, pages })),
    onListDelete: (id: string) => dispatch(deleteList(id)),
    fetchLists: () => dispatch(getLists(true))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListEditorDialogContainer)
