/* eslint-disable react/no-danger */
import * as React from 'react'
import styles from './PageCallout.pcss'
import IconClose from '@mui/icons-material/Close'
import { Icon } from '@mdi/react'
import { mdiHelpNetwork, mdiHelpCircle, mdiPlayCircle, mdiTooltipCheck } from '@mdi/js'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isTrialActiveSelector, trialEndsAtSelector } from 'services/users/selectors'
import { FormattedMessage } from 'react-intl'
import Button from '@mui/material/Button'
import { setTrialPopupOpen } from 'services/ui/actions'
import { defaultProductUpgradeHandleSelector } from 'services/product'
import { formatDistanceToNow } from 'date-fns'

export interface Callout {
  slug: string
  title: string
  paragraph: string
  linkText: string
  linkText2: string
  linkText3: string
  linkUrl: string
  linkUrl2: string
  linkUrl3: string
  linkIcon: string
  linkIcon2: string
  linkIcon3: string
  exact: boolean
  hidden?: boolean
}

export const availableIcons = [{
  key: 'mdiHelpNetwork',
  value: mdiHelpNetwork
},
{
  key: 'mdiHelpCircle',
  value: mdiHelpCircle
},
{
  key: 'mdiYoutube',
  value: mdiPlayCircle
},
{
  key: 'mdiTooltipCheck',
  value: mdiTooltipCheck
}]

interface PageCalloutProps {
  data: Callout
  action?: {
    label: string
    disabled?: boolean
    onClick: () => void
  }
  hideTrialButton?: boolean
  onClose?: (slug: string) => void
}

export function PageCallout(props: PageCalloutProps) {
  const dispatch = useDispatch()
  const { title, linkText, linkText2, linkUrl, linkUrl2, linkText3, linkUrl3, linkIcon, linkIcon2, linkIcon3, paragraph, slug } = props.data
  const defaultUpgradeHandle = useSelector(defaultProductUpgradeHandleSelector)
  const navigate = useNavigate()
  const isTrialActive = useSelector(isTrialActiveSelector)
  const trialEnd = useSelector(trialEndsAtSelector)
  const trialEndTimeLabel = formatDistanceToNow(trialEnd || new Date(), { addSuffix: isTrialActive })

  if (props.data.hidden) {
    return null
  }

  const openTrialPopup = () => {
    dispatch(setTrialPopupOpen({ handle: defaultUpgradeHandle, isAnnual: true }))
  }

  const dismiss = () => {
    if (props.onClose) {
      props.onClose(slug)
    }
  }

  const onClick = (e: any) => {
    if (e.target.tagName === 'A') {
      const link = e.target.getAttribute('href')
      if (!link.startsWith('http')) {
        e.preventDefault()
        navigate(link)
      }
    }
  }

  return (
    <div className={`${styles.container} ${slug === 'composer' ? styles.composer : ''}`}>
      <header className={styles.header}>
        <h1 className={styles.title}>
          <span>{title}</span>
          {props.action && (
            <Button
              color="primary"
              variant="contained"
              disabled={props.action.disabled}
              onClick={props.action.onClick}
            >
              {props.action.label}
            </Button>
          )}
        </h1>
        {props.onClose && <IconClose className={styles['icon-close']} onClick={dismiss} />}
        {isTrialActive && trialEnd && !props.hideTrialButton && (
          <div className={styles['trial-box']}>
            <div>
              <FormattedMessage
                id={
                  isTrialActive
                    ? 'settings.account.labels.trial-expire-days'
                    : 'settings.account.labels.trial-expired'
                }
                values={{ time: trialEndTimeLabel }}
              />
            </div>
            <Button color="primary" variant="contained" onClick={openTrialPopup}>
              <FormattedMessage id="trial-page.header.action-end-trial" />
            </Button>
          </div>
        )}
      </header>
      <div
        className={styles.content}
        onClick={onClick}
        dangerouslySetInnerHTML={{ __html: paragraph }}
      >
      </div>
      <div className={styles.links}>
        {linkText && linkUrl && linkIcon && (
          <a className={styles.link} target="_blank" href={linkUrl}>
            <Icon size="20px" className={styles['icon-help']} path={availableIcons.find(i => i.key === linkIcon)?.value ?? ''} />
            {linkText}
          </a>
        )}
        {linkText2 && linkUrl2 && linkIcon2 && (
          <a className={styles.link} target="_blank" href={linkUrl2}>
            <Icon size="20px" className={styles['icon-help']} path={availableIcons.find(i => i.key === linkIcon2)?.value ?? ''} />
            {linkText2}
          </a>
        )}

        {linkText3 && linkUrl3 && linkIcon3 && (
          <a className={styles.link} target="_blank" href={linkUrl3}>
            <Icon size="20px" className={styles['icon-help']} path={availableIcons.find(i => i.key === linkIcon3)?.value ?? ''} />
            {linkText3}
          </a>
        )}
      </div>
    </div>
  )
}
