import { createSlice } from '@reduxjs/toolkit'
import { Photo, Video, Article, Gif } from 'interfaces'

export const CONTENT_PREVIEW_STATE_VERSION = 'contentpreview:1'

export interface ContentPreviewState {
  active: boolean
  content?: Photo | Video | Article | Gif
}

const contentPreviewSlice = createSlice({
  name: 'contentPreview',
  initialState: {
    active: false,
    content: undefined
  },
  reducers: {
    openContentPreviewWindow: (state, action) => {
      state.active = true
      state.content = action.payload
    },
    closeContentPreviewWindow: (state) => {
      state.active = false
      state.content = undefined
    }
  }
})

export const { openContentPreviewWindow, closeContentPreviewWindow } = contentPreviewSlice.actions
export default contentPreviewSlice.reducer
