export class FacebookDeclinedPermissionsException extends Error {

  public readonly declined: string[]

  constructor(declined: string[]) {
    super(`Scopes were declined: ${declined}`)
    this.declined = declined

    Object.setPrototypeOf(this, FacebookDeclinedPermissionsException.prototype)
  }
}

export default FacebookDeclinedPermissionsException
