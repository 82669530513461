import * as React from 'react'
import PostPreview from 'components/PostPreview'
import {
  ARTICLE_TYPE,
  BRAND_FACEBOOK,
  BRAND_PINTEREST,
  BRAND_TWITTER,
  NO_TIME_SLOT_VALUE,
  PlannedPost,
  PostBase,
  VIDEO_TYPE
} from 'interfaces'
import { BRAND_GOOGLE, BRAND_INSTAGRAM } from 'interfaces/Content/SocialBrand'
import styles from './PostPreviewWithInfo.pcss'
import { FormattedMessage, useIntl } from 'react-intl'
import SocialIcon from 'components/SocialIcon'
import IconPrev from '@mui/icons-material/ChevronLeft'
import IconNext from '@mui/icons-material/ChevronRight'
import { RecycleSelector } from 'components/Posts/components/RecycleSelector'
import PostActions from './PostActions'
import Button from '@mui/material/Button'
import { InstagramLocationSelector } from 'components/Composer/CustomizePostBlock/InstagramLocationSelector'
import { useDispatch, useSelector } from 'react-redux'
import { connectedDestinationsSelector } from 'services/destinations'
import { LocationInfo } from 'services/compose/interfaces'
import { getDerivedStateFromPost, mergeState, resetComposer, updatePost } from 'services/compose'
import { getPostData } from 'services/compose/create/createPost'
import { StoreThunkDispatch } from 'store/state'
import { message } from 'services/snackbar'
import { Subject } from 'rxjs/Subject'
import { Observable } from 'rxjs/Observable'
import { format } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

interface PostPreviewWithInfoProps {
  post: PlannedPost
  hasPreviousPost: boolean
  hasNextPost: boolean
  className?: string
  onSelectPrevious: () => void
  onSelectNext: () => void
  onDelete: (post: PlannedPost) => void
  onMove?: (id: string, position: 'top' | 'bottom') => void
  onEdit: (post: PlannedPost) => void
  onCopy: (post: PlannedPost) => void
  onRecycleChange: (id: string, value: number | boolean | Date, forceRefresh?: boolean) => void
  onClose: () => void
  onPostUpdated?: (post: PlannedPost) => void
}

export function PostPreviewWithInfo(props: PostPreviewWithInfoProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const intl = useIntl()
  const [igLocation, setIgLocation] = React.useState(props.post.details?.location)
  const profiles = useSelector(connectedDestinationsSelector)
  const postProfile = profiles[props.post.profileId]

  const update$ = React.useRef<Subject<PostBase>>()

  React.useEffect(() => {
    update$.current = new Subject()
    update$.current.switchMap((post) => {
      dispatch(resetComposer())
      dispatch(mergeState(getDerivedStateFromPost(post)))
      const postData = dispatch(getPostData(post.autoPost ? 'queue' : undefined, !post.autoPost))
      return dispatch(updatePost(undefined, postData, true)).zip(Observable.of(post))
    }).subscribe(([_response, post]) => {
      if (props.onPostUpdated) {
        props.onPostUpdated(post)
      }
    }, () => {
      dispatch(message(intl.formatMessage({ id: 'errors.generic' }), 'error'))
    })

    return () => {
      update$.current?.unsubscribe()
    }
  }, [])

  React.useEffect(() => {
    if (props.post.network === BRAND_INSTAGRAM) {
      setIgLocation(props.post.details?.location)
    }
  }, [props.post.details?.location, props.post.network])

  const postStatus = React.useMemo(() => {
    const data: any = {
      status: {
        text: props.post.status,
        html: props.post.status,
        touched: false
      }
    }
    if (props.post.network === BRAND_PINTEREST) {
      data.title = (props.post as any).title
    } else if (props.post.network === BRAND_GOOGLE) {
      data.buttonLink = props.post.details?.buttonUrl
      data.buttonType = props.post.details?.buttonType
    } else if (props.post.network === BRAND_INSTAGRAM) {
      data.locations = props.post.details?.location ? { [props.post.details.location.id]: props.post.details.location } : {}
      data.postType = props.post.igPostType
    }
    return data
  }, [props.post])

  const content = React.useMemo(() => {
    if (props.post.contentType === ARTICLE_TYPE || props.post.contentType === VIDEO_TYPE) {
      return {
        title: props.post.linkTitle,
        description: props.post.linkDescription,
        type: props.post.contentType,
        sourceLink: props.post.linkUrl,
        shortLink: props.post.linkUrl
      } as any
    }
    return undefined
  }, [props.post])

  const profile = React.useMemo(() => {
    return {
      name: props.post.name,
      image: props.post.profilePictureUrl
    } as any
  }, [props.post.name, props.post.profilePictureUrl])

  const onRecycleChange = (value: number | boolean | Date) => {
    const id = props.post.virtualPostId || props.post.id
    props.onRecycleChange(id, value, props.post.isVirtual)
  }

  const onDelete = () => {
    props.onDelete(props.post)
  }

  const moveToTop = () => {
    if (props.onMove) {
      props.onMove(props.post.id, 'top')
    }
  }

  const moveToBottom = () => {
    if (props.onMove) {
      props.onMove(props.post.id, 'bottom')
    }
  }

  const onEdit = () => {
    props.onEdit(props.post)
  }

  const copy = () => {
    props.onCopy(props.post)
  }

  const imageUrls = props.post.images || []

  const postTypeLabel = React.useMemo(() => {
    if (props.post.isPosted) {
      return 'Posted to: '
    }
    const autoPostLabel = props.post.autoPost ? 'Queued' : 'Scheduled'
    let postType = ''
    switch (props.post.network) {
      case BRAND_FACEBOOK:
        postType = props.post.details?.reshareName ? 'Re-share' : 'Post'
        break
      case BRAND_TWITTER:
        postType = props.post.twPostType === 'quote'
          ? 'Quote tweet'
          : props.post.twPostType === 'retweet' ? 'Retweet' : 'Tweet'
        break
      case BRAND_PINTEREST:
        postType = 'Pin'
        break
      default:
        postType = 'Post'
    }

    return `${autoPostLabel} ${postType} to: `
  }, [props.post.autoPost, props.post.details?.reshareName, props.post.network, props.post.twPostType, props.post.isPosted])

  const recycleValue = React.useMemo(() => {
    if (typeof props.post.recycle === 'number') {
      return `${props.post.recycle} times`
    }

    if (props.post.recycle === true) {
      return 'Forever'
    }

    if (typeof props.post.recycle === 'object') {
      return `Until ${(props.post.recycle as Date).toLocaleDateString()}`
    }

    return ''
  }, [props.post.recycle])

  const repeatLabel = React.useMemo(() => {
    if (props.post.autoPost || props.post.repeatLength === 0) {
      return null
    }

    return <FormattedMessage
      id="post.planned.repeat-value-msg"
      values={{
        repeatLength: props.post.repeatLength,
        repeatPeriod: props.post.repeatPeriod,
        repeatUntil: props.post.repeatUntil === 'forever' ? 'Forever' : format(props.post.repeatUntil, 'd LLL yyyy')
      }}
    />
  }, [props.post.repeatLength, props.post.repeatPeriod, props.post.repeatUntil, props.post.autoPost])

  const timeLabel = React.useMemo(() => {
    if (props.post.timeString === NO_TIME_SLOT_VALUE) {
      return NO_TIME_SLOT_VALUE
    }

    const dateTime = formatInTimeZone(new Date(props.post.time), props.post.timezone, 'MMMM do, hh:mm a')
    if (props.post.baseTime) {
      return `${dateTime} ish`
    }
    return dateTime
  }, [props.post.timeString, props.post.time, props.post.timezone, props.post.baseTime])

  const onIGLocationChange = (location: LocationInfo | undefined) => {
    setIgLocation(location)
    const post = props.post
    update$.current?.next({ ...post, details: { ...post.details, location } })
  }

  const isPinterest = Boolean(props.post.attributes?.pinterestBoardUrl)
  const attachedDocumentName = props.post.details?.documentName

  return (
    <div className={props.className || ''} data-testid="post-preview-wrapper">
      <div className={styles.nav}>
        <div className={styles.date}>
          {props.hasPreviousPost && (
            <IconPrev className={styles['btn-nav']} role="button" onClick={props.onSelectPrevious} />
          )}
          <div className={styles['date-label']}>
            {timeLabel}
          </div>
          {props.hasNextPost && (
            <IconNext className={styles['btn-nav']} role="button" onClick={props.onSelectNext} />
          )}
        </div>
        <Button variant="text" className={styles['btn-toggle']} onClick={props.onClose}>
          <FormattedMessage id="actions.close" />
        </Button>
      </div>
      <div className={styles['info-box']}>
        <div className={styles['info-line']}>
          <span>
            {postTypeLabel}
          </span>
          <span className={`${styles.profile} text-ellipsis`}>
            <SocialIcon icon={props.post.network} className={styles.icon} />
            {props.post.name}
          </span>
        </div>
        {props.post.bucket && (
          <div className={styles['info-line']}>
            <span className="text-ellipsis">
              {`${props.post.autoPost ? 'Queued' : 'Scheduled'} from bucket "${props.post.bucket.name}"`}
            </span>
          </div>
        )}
        {props.post.network === BRAND_INSTAGRAM && (
          <div className={`${styles['info-line']} ${styles['location-box']}`}>
            <span>
              <FormattedMessage id="label.generic.location" />:
            </span>
            <span>
              <InstagramLocationSelector
                profile={postProfile}
                hideAvatar
                hideLabel
                selectedLocation={igLocation}
                onSelectedLocationChange={onIGLocationChange}
              />
            </span>
          </div>
        )}
        {props.post.details?.photoAlbum && !isPinterest && (
          <div className={styles['info-line']}>
            <span>
              <FormattedMessage id="label.generic.album" />:
            </span>
            <span>
              <a href={`https://www.facebook.com/media/set/?set=a.${props.post.details?.photoAlbum.id}`} target="_blank" rel="noopener">
                {props.post.details?.photoAlbum?.name}
              </a>
            </span>
          </div>
        )}
        {isPinterest && props.post.details?.photoAlbum?.name && (
          <div className={styles['info-line']}>
            <span>
              <FormattedMessage id="label.generic.board" />:
            </span>
            <span>
              <a href={props.post.attributes?.pinterestBoardUrl} target="_blank" rel="noopener">
                {props.post.details.photoAlbum.name}
              </a>
            </span>
          </div>
        )}
        {!props.post.autoPost && props.post.repeatLength > 0 && (
          <div className={styles['info-line']}>
            <span>
              <FormattedMessage id="general.labels.repeat" />:
            </span>
            <span className={styles.util} onClick={onEdit}>{repeatLabel}</span>
          </div>
        )}
        {Boolean(props.post.recycle) && (
          <div className={styles['info-line']}>
            <span>
              <FormattedMessage id="general.labels.recycle" />:
            </span>

            <RecycleSelector
              value={props.post.recycle}
              triggerElement={
                <span className={`${styles['recycle-selector']} ${styles.util}`}>{recycleValue}</span>
              }
              className={styles['recycle-box']}
              onChange={onRecycleChange}
            />
          </div>
        )}
        <div className={styles['info-line']}>
          <span>
            <FormattedMessage id="post.labels.preview-info.created-date" />:
          </span>
          <span>
            {format(props.post.createdAt, 'd LLL yyyy')}
          </span>
        </div>
        {props.post.baseTime && (
          <div className={styles['info-line']}>
            <span>
              <FormattedMessage id="post.labels.preview-info.posts-within" />:
            </span>
            <span>within 20 minutes</span>
          </div>
        )}
      </div>
      <div className={styles['preview-box']}>
        {!props.post.isPosted && !props.post.isVirtual && (
          <PostActions
            showMoveButtons={props.post.autoPost}
            className={styles.actions}
            btnClassName={styles['action-btn']}
            classNameMid={styles['actions-mid']}
            classNameRight={styles['actions-right']}
            onEdit={onEdit}
            onDelete={onDelete}
            onMoveToTop={props.onMove ? moveToTop : undefined}
            onMoveToBottom={props.onMove ? moveToBottom : undefined}
            onCopy={copy}
          />
        )}
        <PostPreview
          post={postStatus}
          tags={props.post.details?.tags}
          imageUrls={imageUrls}
          featuredImageUrl={props.post.nativeVideoUrl ? undefined : imageUrls[0]}
          postNetwork={props.post.network}
          previewProfile={profile}
          videoUrl={props.post.nativeVideoUrl}
          videoThumbnailUrl={props.post.details?.videoThumbnailUrl}
          content={content}
          firstComment={props.post.details?.firstComment}
          reshare={Boolean(props.post.details?.reshareName)}
          textBackgroundUrl={props.post.details?.textBackground?.backgroundUrl}
          textColor={props.post.details?.textBackground?.color}
          documentName={attachedDocumentName}
        />
      </div>
    </div>
  )
}

export default PostPreviewWithInfo
