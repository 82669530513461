import React from 'react'
import { parse } from 'query-string'
import { Navigate, useLocation } from 'react-router-dom'
import PostsRoute from './PostsRoute'
import { CalendarRoute } from './CalendarRoute'
import { useSelector, useDispatch } from 'react-redux'
import { plannedPostsLayoutSelector } from 'services/post/selectors'
import { isPlannedPostsLayout } from 'services/post/state'
import { setPlannedPostsView } from 'services/post'

export function PostsRoot() {
  const dispatch = useDispatch()
  const location = useLocation()
  const queryParams = parse(location.search)
  const activeView = useSelector(plannedPostsLayoutSelector)

  React.useEffect(() => {
    if (isPlannedPostsLayout(queryParams?.view) && queryParams.view !== activeView) {
      dispatch(setPlannedPostsView(queryParams.view))
    }
  }, [activeView, dispatch, queryParams.view])

  if (location.pathname !== '/posts/planned/all' && queryParams.view === 'calendar') {
    return <Navigate to={location.pathname + `?view=list`} />
  }

  if (!queryParams?.view || !isPlannedPostsLayout(queryParams.view)) {
    const view = isPlannedPostsLayout(activeView) ? activeView : 'list'
    return <Navigate to={location.pathname + `?view=${view}`} />
  }

  if (queryParams?.view === 'calendar') {
    return <CalendarRoute />
  }

  return <PostsRoute />
}
