import { ContentSource } from 'interfaces'

export const SEARCH_VERSION = 'search:0'

export interface SearchState {
  latest: Readonly<{
    searches: ReadonlyArray<string>
    sources: ReadonlyArray<ContentSource>
  }>
}

export function initialState(): SearchState {
  return Object.freeze({
    latest: Object.freeze({
      searches: [],
      sources: []
    })
  })
}

export default SearchState
