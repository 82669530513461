const WWW_PREFIX = /^www\./

export function parseUrlDomain(url: string): string | undefined {
  try {
    const domain = new URL(url).hostname
    if (WWW_PREFIX.test(domain)) {
      return domain.slice(4) // eslint-disable-line no-magic-numbers
    }

    return domain
  } catch (ignore) {
    return undefined
  }
}

export default parseUrlDomain
