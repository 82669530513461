import * as React from 'react'
import PlayIcon from '@mui/icons-material/PlayArrowRounded'
import styles from './TikTokPostPreview.pcss'
import { PostPreviewDefaultHeader } from './PostPreviewDefaultHeader'
import { PostPreviewVideoPlayer } from './PostPreviewVideoPlayer'
import PostPreviewText from './PostPreviewText'

const TRUNCATE_MAX_LINES = 2
const TRUNCATE_TEXT = 'more'
const COLLAPSE_TEXT_LABEL = 'less'

interface TikTokPostPreviewProps {
  profile?: { name: string, image: string }
  videoUrl?: string
  videoThumbnailUrl?: string
  status?: string
}

export function TikTokPostPreview(props: TikTokPostPreviewProps) {
  return (
    <article className={styles.post}>
      <PostPreviewDefaultHeader profile={props.profile} />
      <PostPreviewText
        text={props.status || ''}
        network="tiktok"
        expandLabel={TRUNCATE_TEXT}
        collapseLabel={COLLAPSE_TEXT_LABEL}
        maxLinesDefault={TRUNCATE_MAX_LINES}
        className={styles['tk-status']}
      />
      <main className={`${styles['tk-video-box']} ${!props.videoUrl ? styles.empty : ''}`}>
        {props.videoUrl && (
          <PostPreviewVideoPlayer
            videoUrl={props.videoUrl}
            thumbnailUrl={props.videoThumbnailUrl}
            icon={<PlayIcon className={`${styles['icon-centered']} ${styles.icon}`} />}
          />
        )}
      </main>
    </article>
  )
}
