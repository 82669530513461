import * as React from 'react'
import { TrialTask, TrialTasksGroup as ITrialTasksGroup } from '../interfaces'
import styles from './TrialTasksGroup.pcss'
import IconDropdown from '@mui/icons-material/ArrowDropDown'
import IconCheck from '@mui/icons-material/Check'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'
import { IndexedObject } from 'interfaces'
import { Icon } from '@mdi/react'
import { mdiPail, mdiCalendarMonth, mdiChartBoxOutline, mdiTooltipImageOutline, mdiFormatListBulletedSquare, mdiCogOutline } from '@mdi/js'
import { getTotal } from '../getTotal'

const icons: IndexedObject<React.ReactNode> = {
  get_set_up: <Icon path={mdiCogOutline} size="48px" className={styles.icon} />,
  content_firehose: <Icon path={mdiTooltipImageOutline} size="48px" className={styles.icon} />,
  post_buckets: <Icon path={mdiPail} size="40px" className={styles.icon} />,
  historical_posts: <Icon path={mdiChartBoxOutline} size="40px" className={styles.icon} />,
  fill_up_queue: <Icon path={mdiFormatListBulletedSquare} size="40px" className={styles.icon} />,
  view_calendar_posts: <Icon path={mdiCalendarMonth} size="40px" className={styles.icon} />
}

export const taskUrls: IndexedObject<string> = {
  connect_networks: '/settings/networks',
  activate_profiles: '/settings/networks',
  activate_social_profile: '/settings/networks',
  add_team_member: '/settings/team',
  download_mobile_app: '/training?mobile-download=true',
  stream_with_n_feeds: '/content/search/sources/facebook?q=',
  save_stream: '/content/search/sources/streams',
  search_item: '/content/search/content/quotes?q=',
  favorite_item: '/content/search/content/quotes',
  my_library_upload: '/content/library',
  create_content_studio_image: '/content/studio',
  create_bucket: '/posts/buckets',
  add_post_to_bucket: '/posts/buckets',
  add_post_bucket: '/composer',
  link_bucket_to_plan: '/plans',
  non_pp_posted: '/history/posted',
  sort_posts_by_likes: '/history/posted',
  post_re_queue: '/history/posted',
  custom_plan: '/plans',
  setup_plan: '/plans',
  queue_post: '/composer',
  create_a_post: '/composer',
  recycling_post: '/composer',
  save_draft: '/composer',
  repeating_post: '/composer',
  add_saved_text: '/composer',
  shuffle_post: '/posts/planned/all',
  month_week_view: '/posts/planned/all?view=calendar',
  change_posts_size: '/posts/planned/all?view=calendar',
  toggle_empty_slots: '/posts/planned/all?view=calendar',
  see_post_preview: '/posts/planned/all?view=calendar'
}

export const taskVideoUrls: Record<string, string> = {
  activate_social_profile: 'https://www.loom.com/share/a08188251e774ad3907a0e7088dbb847',
  add_post_to_bucket: 'https://www.loom.com/share/a08188251e774ad3907a0e7088dbb847?t=294',
  setup_plan: 'https://www.loom.com/share/a08188251e774ad3907a0e7088dbb847?t=138',
  save_stream: 'https://www.loom.com/share/a08188251e774ad3907a0e7088dbb847?t=652',
  create_a_post: 'https://www.loom.com/share/a08188251e774ad3907a0e7088dbb847?t=932'
}

interface TrialTasksGroupProps {
  group: ITrialTasksGroup
  // onVideoPlay: (url: string) => void
}

export function TrialTasksGroup(props: TrialTasksGroupProps) {
  const [expanded, setExpanded] = React.useState(false)

  const tasksCount = props.group.tasks.length
  const tasksLeft: TrialTask[] = []
  const tasksCompleted: TrialTask[] = []

  props.group.tasks.forEach(task => {
    if (task.completed) {
      tasksCompleted.push(task)
    } else {
      tasksLeft.push(task)
    }
  })

  const toggle = () => {
    setExpanded(!expanded)
  }

  const navigate = (code: string) => {
    const url = taskUrls[code]
    window.open(`${window.origin}${url}`, '_blank')
  }

  const totalEarningsCurrent = getTotal('earningsMax', tasksCompleted)
  const totalDiscount = getTotal('discount', props.group.tasks)
  const discountCurrent = getTotal('discount', tasksCompleted)

  const taskGroupCompleted = tasksLeft.length === 0
  const headingProgressClass = totalEarningsCurrent > 0 && !taskGroupCompleted ? styles['in-progress'] : ''
  const progressbarStyles = {
    // eslint-disable-next-line no-magic-numbers
    width: `${(100 / tasksCount) * (tasksCount - tasksLeft.length)}%`
  }

  return (
    <div className={`${styles.container} ${expanded ? styles.expanded : ''}`}>
      <div className={`${styles.heading} ${styles.row} ${taskGroupCompleted ? styles.completed : ''} ${headingProgressClass}`}>
        <div className={styles['cell-xs']}>
          {icons[props.group.code]}
        </div>
        <div className={`${styles['cell-l']}`}>
          <h4 className="text-ellipsis" onClick={toggle}>
            <FormattedMessage id={`trial-page.group-title.${props.group.code}`} />
          </h4>
        </div>
        <div className={styles['cell-m']}>
          <span onClick={toggle}>
            <FormattedMessage
              id={taskGroupCompleted ? 'trial-page.labels.completed' : 'trial-page.labels.steps-left'}
              values={taskGroupCompleted ? undefined : { count: tasksLeft.length }}
            />
          </span>
          <IconDropdown className={styles.toggle} onClick={toggle} />
        </div>
        <div className={`${styles['cell-s']} ${styles.black}`}>
          <span className={styles.percent}>
            {totalDiscount}
          </span>
        </div>
        <div className={styles['cell-s']}>
          <span className={styles.percent}>
            {discountCurrent}
          </span>
        </div>
        <div className={styles['cell-s']}>
          <span className={styles.price}>
            {totalEarningsCurrent.toFixed(2)}
          </span>
        </div>
      </div>
      <div className={styles.progress}>
        <div className={styles.bar} style={progressbarStyles}></div>
      </div>
      {props.group.tasks.map(task => (
        <TrialTaskRow
          key={task.code}
          task={task}
          // onVideoPlay={props.onVideoPlay}
          onAction={navigate}
        />
      ))}
    </div>
  )
}

function TrialTaskRow(props: { task: TrialTask, onAction: (code: string) => void }) {
  const { code, order, completed, discount, earningsMax, videoUrl, maxValue } = props.task

  // const onPlay = () => {
  //   if (videoUrl) {
  //     props.onVideoPlay(videoUrl)
  //   }
  // }

  const onAction = () => {
    props.onAction(props.task.code)
  }

  const classAlert = !completed ? styles.red : ''

  return (
    <div className={`${styles.row} ${completed ? styles.completed : styles.red}`}>
      <div className={styles['cell-xs']}>
        <div className={styles.circle}>
          {completed ? (<IconCheck className={styles['icon-check']} />) : order}
        </div>
      </div>
      <div className={`${styles['cell-l']}`}>
        <h4 className="text-ellipsis">
          <FormattedMessage id={`trial-page.task-title.${code}`} values={{ value: maxValue }} />
        </h4>
      </div>
      <div className={styles['cell-m']}>
        {videoUrl && (
          <a href={videoUrl} target="_blank">
            <Button size="small" className={styles['btn-play']}>
              <FormattedMessage id="actions.learn-how" />
            </Button>
          </a>
        )}
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={styles['btn-action']}
          onClick={onAction}
        >
          <FormattedMessage id="actions.take-action" />
        </Button>
      </div>
      <div className={`${styles['cell-s']} ${styles.black}`}>
        <span className={styles.percent}>
          {discount}
        </span>
      </div>
      <div className={styles['cell-s']}>
        <span className={`${styles.percent} ${classAlert}`}>
          {completed ? discount : 0}
        </span>
      </div>
      <div className={styles['cell-s']}>
        <span className={`${styles.price} ${classAlert}`}>
          {completed ? earningsMax : 0}
        </span>
      </div>
    </div>
  )
}

export default TrialTasksGroup
