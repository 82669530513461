import { ComponentClass, createElement, FunctionComponent } from 'react'
import { Provider } from 'react-redux'
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer, createTransform, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import { createBrowserHistory } from 'history'
import reducers from 'store/reducers'
import StoreState, { StoreThunkDispatch } from 'store/state'
import { CURRENT_VERSION, storedVersion, PURGE_VERSION } from 'store/version'
import { logout } from 'services/auth'
import { PERSIST_CONFIG_KEY, REDUX_STORE_VERSION } from 'config'
import * as ReactGA from 'react-ga'
import storage from 'redux-persist/lib/storage'
import createMigrate from 'redux-persist/lib/createMigrate'
// import persistReducer from 'redux-persist/lib/persistReducer'

declare const __PROD__: boolean

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory()
if (__PROD__) {
  // Initialize google analytics page view tracking
  history.listen(location => {
    ReactGA.set({ page: location.pathname }) // Update the user's current page
    ReactGA.pageview(location.pathname) // Record a pageview for the given page
  })
}

const config = {
  key: PERSIST_CONFIG_KEY,
  version: REDUX_STORE_VERSION,
  storage,
  migrate: createMigrate({
    [REDUX_STORE_VERSION]: () => ({
      _persist: {
        version: REDUX_STORE_VERSION,
        rehydrated: false
      }
    })
  }),
  // which keys in the store will NOT be persisted
  blacklist: ['contentPreview', 'snackbar', 'upgrade', 'contentPopup', 'search', 'uploads', 'composer', 'post', 'plan'],
  transforms: [
    createTransform(
      // transform state on its way to being serialized and persisted.
      (inboundState, _key) => inboundState,
      // transform state being rehydrated
      (outboundState: any) => {
        const { cache, ...state } = outboundState
        // do not store content cache
        return {
          ...state,
          cache: { data: {}, sources: {} },
          selection: {
            items: {},
            itemsOnPage: {},
            panelOpen: false
          }
        }
      },
      // define which reducers this transform gets called for.
      { whitelist: ['content'] }
    )
  ]
}

const composerPersistConfig = {
  key: 'composer',
  storage,
  whitelist: ['selectedInstagramLocation', 'pinterestBoards', 'selectedProfiles', 'activeTab', 'networksView']
}

const postsPersistConfig = {
  key: 'posts',
  storage,
  whitelist: ['ui']
}

const appReducer = persistReducer(config, combineReducers({
  ...reducers,
  composer: persistReducer(composerPersistConfig, reducers.composer),
  post: persistReducer(postsPersistConfig, reducers.post)
}))

const store = configureStore({
  reducer: appReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      ignoredPaths: [/^content\..*\.createdAt$/]
    }
  })
})

export const persistor = persistStore(store, undefined, () => {
  const version = storedVersion(store.getState() as StoreState)
  if (!version) {
    console.log('[store] new version', version)
    return
  }

  // Put all updated version keys in an array
  const keys = ['version'] as string[]
  if (typeof version === 'object') {
    Object.keys(CURRENT_VERSION).reduce((result, key) => {
      if (CURRENT_VERSION[key] !== version[key]) {
        result.push(key)
      }

      return result
    }, keys)
  } else {
    keys.push(...Object.keys(CURRENT_VERSION))
  }

  // Purge store if there's new version in any of the state section versions
  if (keys.length > 1) {
    console.log('[store] version mismatch (purging)', keys, version, CURRENT_VERSION)
    // Clear the store state and logout
    persistor.purge().then(() => {
      store.dispatch({ type: PURGE_VERSION })
      ; (store.dispatch as StoreThunkDispatch)(logout())
    })
  }
})

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('store/reducers', () => {
    const nextRootReducer = require('store/reducers').default
    store.replaceReducer(nextRootReducer)
  })
}

export function withProvider<P>(WithoutProvider: FunctionComponent<P> | ComponentClass<P>) {
  const withProvider = function withProvider(props: P) {
    // NOTE: expose store when run in Cypress
    if ((window as any).Cypress) {
      (window as any).store = store
    }

    return createElement(Provider, {
      store,
      children: createElement(
        WithoutProvider as FunctionComponent<P & { history: any }>,
        Object.assign<{}, P, { history: any }>({}, props, { history })
      )
    } as any)
  } as FunctionComponent<P>
  withProvider.displayName = `withProvider(${WithoutProvider.displayName || WithoutProvider.name})`
  return withProvider
}

export default withProvider
