import * as React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './QuotesPanel.pcss'
import { FormattedMessage, injectIntl } from 'react-intl'
import { SuggestedQuoteSearches } from 'routes/find/routes/quotes/components/SuggestedQuoteSearches'
import { Subject } from 'rxjs/Subject'
import { tap } from 'rxjs/operators/tap'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { StatusIdea, WithIntl } from 'interfaces'
import { getQuotes } from 'admin/services/quotes/actions'
import { QUOTES } from 'routes/find/routes/statuses/statuses.config'
import { CuratedFolderListView } from 'components/CuratedFolderView'
import Icon from '@mdi/react'
import { mdiShuffleVariant, mdiClose } from '@mdi/js'
import { initialState, QuotesContext, quotesReducer, saveSearches, updateSearch } from 'routes/find/routes/quotes/QuotesContext'
import { getRandomQuotes, saveQuoteSearch } from 'services/content/quotes/actions'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { MIN_SEARCH_QUERY_LENGTH } from 'routes/find/routes/quotes/components/QuotesSearch'
import CollapsibleTextInput from 'components/CollapsibleTextInput'
import EmptyView from 'components/EmptyView'
interface QuotesPanelProps {
  className?: string
}

export function QuotesPanel(props: QuotesPanelProps & WithIntl) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const storeDispatch = useDispatch<StoreThunkDispatch>()
  const save$ = React.useRef<Subject<string>>()
  const [{ search, savedSearches }, quotesDispatch] = React.useReducer(quotesReducer, initialState)
  const [items, setItems] = React.useState<StatusIdea[]>([])
  const [listKey, setListKey] = React.useState(Date.now())
  const lastSearchRef = React.useRef('')
  const pageRef = React.useRef(0)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const fetch$ = React.useRef<Subject<{ query: string, page: number, tag?: string }>>()

  React.useEffect(() => {
    fetch$.current = new Subject()
    fetch$.current
      .pipe(tap(({ query, page }) => {
        pageRef.current = page
        lastSearchRef.current = query
        quotesDispatch(updateSearch({ active: true, loading: true }))
      }))
      .switchMap(({ query, page, tag }) => {
        const request = query || tag ? dispatch(getQuotes(page, query, tag)) : dispatch(getRandomQuotes())
        return request
          .pipe(catchError(() => {
            return Observable.of([])
          }))
      })
      .subscribe((data: StatusIdea[]) => {
        setItems(current => ([...current, ...data]))
        quotesDispatch(updateSearch({ hasNextPage: data.length > 0, loading: false }))
      })

    return () => {
      fetch$.current?.unsubscribe()
    }
  }, [])

  React.useEffect(() => {
    save$.current = new Subject()
    save$.current
      .filter(q => q.length >= MIN_SEARCH_QUERY_LENGTH)
      .flatMap(q => {
        quotesDispatch(saveSearches([q]))
        return storeDispatch(saveQuoteSearch(q))
      })
      .pipe(
        tap((value: string) => {
          dispatch(saveSearches([value]))
        }),
        catchError(() => Observable.of({}))
      )
      .subscribe()

    return () => {
      save$.current?.unsubscribe()
    }
  }, [])

  React.useEffect(() => {
    fetch$.current?.next({ query: search.query, page: search.page, tag: search.tag })
  }, [search.query, search.page, search.tag])

  const refreshResults = () => {
    fetch$.current?.next({ query: search.query, page: 0, tag: search.tag })
  }

  const loadNextPage = () => {
    if (!search.loading && search.hasNextPage && !search.tag) { // tag search has no paging
      quotesDispatch(updateSearch({ page: search.page + 1 }))
    }
  }

  const onQueryChange = React.useCallback((value: string, isTag?: boolean, forceReset?: boolean, clearTag?: boolean) => {
    const valueChanged = forceReset || value !== lastSearchRef.current || Boolean(isTag) !== Boolean(search.tag)
    const shouldClearTag = clearTag || (isTag && value === search.tag)
    if (valueChanged) {
      setItems([])
      setListKey(Date.now())
    }
    quotesDispatch(updateSearch({
      query: isTag ? search.query : value,
      tag: shouldClearTag ? '' : isTag ? value : search.tag,
      ...(valueChanged && { page: 0 }),
      ...(valueChanged && { hasNextPage: true })
    }))
    if (!valueChanged && forceReset) {
      refreshResults()
    }
  }, [search])

  const shuffle = () => {
    if (search.query) {
      onQueryChange('')
    } else if (search.page === 0) {
      setItems([])
      setListKey(Date.now())
      fetch$.current?.next({ query: '', page: 0 })
    } else {
      quotesDispatch(updateSearch({ page: 0 }))
    }
  }

  const onSearch = (value: string) => {
    const q = value.trim()
    if (q.length > 0) {
      save$.current?.next(value)
    }
    onQueryChange(value)
  }

  const onTagClick = (q: string, isTag: boolean) => {
    onQueryChange(q, isTag, false, !isTag)
    if (!isTag && inputRef.current) {
      inputRef.current.value = q
    }
  }

  const onQueryClick = (q: string) => {
    onQueryChange(q, false, true)
    if (inputRef.current) {
      inputRef.current.value = q
    }
  }

  const clearSearch = () => {
    onSearch('')
  }

  return (
    <QuotesContext.Provider value={{ search, savedSearches, dispatch: quotesDispatch }}>
      <section className={`${styles.section} ${props.className || ''}`}>
        <header className={styles['quotes-header']}>
          <h2 className={`${styles.title} text-ellipsis`}>
            <FormattedMessage id="find.titles.quotes" />
          </h2>
          <span className={styles['text-btn']} onClick={shuffle}>
            <Icon size="20px" path={mdiShuffleVariant} />
            <FormattedMessage id="label.generic.random" />
          </span>
        </header>
        <SuggestedQuoteSearches
          activeQuery={search.query}
          tag={search.tag}
          searchElement={(
            <CollapsibleTextInput
              changeOnEnter
              initialActive
              className={styles.searchbox}
              ref={inputRef}
              activeClassName={styles['search-active']}
              actionButton={(
                <div className={`${styles['btn-clear']} ${search.query ? '' : styles.hidden}`} onClick={clearSearch}>
                  <Icon path={mdiClose} color="rgba(0, 0, 0, 0.54)" size="20px" />
                </div>
              )}
              placeholder={props.intl.formatMessage({ id: 'find.quotes.search-hint' })}
              onValueChanged={onSearch}
            />
          )}
          onQueryClick={onQueryClick}
          onSuggestedSearchClick={onTagClick}
        />
        <div className={styles.content}>
          <NavLink to={`/content/quotes${search.query ? '?q=' + search.query : ''}`} className={styles.link}>
            {search.query && <FormattedMessage id="general.carousel.nav.see-all-results" values={{ label: search.query }} />}
            {!search.query && (
              <React.Fragment>
                <FormattedMessage id="general.carousel.nav.see-all" />
                <span className={styles['query-text']}>
                  {search.query || <FormattedMessage id="find.titles.quotes" />}
                </span>
              </React.Fragment>
            )}
          </NavLink>
          <CuratedFolderListView
            stream={QUOTES as any}
            items={items}
            key={listKey}
            loading={search.loading}
            onScrollLimit={loadNextPage}
          />
          {!search.loading && search.query && items.length === 0 && (
            <EmptyView
              carousel
              className={styles['error-overlay']}
              title={<FormattedMessage id="content.msg.quotes-search-empty" />}
              subtitle={<FormattedMessage id="content.msg.quotes-search-empty-subtitle" />}
            />
          )}
        </div>
      </section>
    </QuotesContext.Provider>
  )
}

export default injectIntl(QuotesPanel)
