import * as React from 'react'
import { NavLinkProps } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import IconDropdown from '@mui/icons-material/KeyboardArrowRight'
import styles from './AppNavigation.pcss'
import { PPNavLink } from 'components/PPNavLink'
import Collapse from '@mui/material/Collapse'

interface AppNavLinkOwnProps {
  expanded?: boolean
  icon?: React.ReactNode
  label: React.ReactNode
  items?: React.ReactNode
  actions?: React.ReactNode
  forceExpanded?: boolean
  withTooltip?: boolean
  subNavClassName?: string
  toggleExpandedOnClick?: boolean
  small?: boolean
  expandedItemKey?: string
  hideToggle?: boolean
  external?: boolean
  onToggleExpanded?: (id?: string) => void
}

type AppNavLinkProps = AppNavLinkOwnProps & NavLinkProps

export function AppNavLink(props: AppNavLinkProps) {
  const {
    expanded,
    icon,
    label,
    items,
    onToggleExpanded,
    withTooltip,
    toggleExpandedOnClick,
    subNavClassName,
    small,
    expandedItemKey,
    actions,
    forceExpanded,
    hideToggle,
    external,
    ...linkProps
  } = props

  const onToggle = React.useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (onToggleExpanded) {
      onToggleExpanded(props.id)
    }
    // setExpanded(current => !current)
  }, [props.id, onToggleExpanded])

  const onClick = React.useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (props.onClick) {
      props.onClick(event)
    }

    if (props.toggleExpandedOnClick && props.onToggleExpanded) {
      props.onToggleExpanded(props.id)
    }

  }, [props.toggleExpandedOnClick, props.onClick, props.id])

  return (
    <div className={`${styles['link-box']} ${props.small ? styles.small : ''} ${props.forceExpanded ? styles.fixed : ''}`}>
      <Tooltip title={withTooltip ? label : ''} placement="right">
        <PPNavLink
          {...linkProps}
          external={external}
          className={`${styles.link} ${props.className || ''} text-ellipsis`}
          activeClassName={styles.active}
          onClick={onClick}
        >
          {icon && (
            <div className={styles['icon-box']}>
              {icon}
            </div>
          )}
          <span className={`${styles.label} text-ellipsis`}>{label}</span>
          <div className={styles.toggle}>
            {Boolean(items) && !props.hideToggle && (
              <IconDropdown
                className={`${styles['btn-toggle-submenu']} ${expanded ? styles['btn-flipped'] : ''}`}
                onClick={onToggle}
              />
            )}
          </div>
          {props.actions && (
            <div className={styles.actions}>
              {props.actions}
            </div>
          )}
        </PPNavLink>
      </Tooltip>
      {items && (
        <Collapse in={expanded || forceExpanded} timeout="auto" unmountOnExit>
          <ul className={`${styles.items} ${props.subNavClassName || ''}`}>
            {items}
          </ul>
        </Collapse>
      )}
    </div>
  )
}
