import * as React from 'react'
import classnames from 'classnames'
import {
  ContentItem,
  VIDEO_TYPE,
  Article,
  Video,
  FEED_TYPE_FACEBOOK,
  FEED_TYPE_TWITTER,
  GIF_TYPE,
  Gif,
  Photo,
  FEED_TYPE_REDDIT,
  FEED_TYPE_KEYWORD,
  FEED_TYPE_LINKEDIN
} from 'interfaces'
import { STOCK_GIF_TYPE, STOCK_VIDEO_TYPE, STOCK_PHOTO_TYPE } from 'interfaces/Content/StockContentTypes'

// Components
import { YTVideoPlayer } from 'components/VideoPlayer'
import GifPlayer from 'components/GifPlayer'
import { PlayIcon } from 'components/Icons'

// Utils
import parseUrlDomain from 'utils/format/parseUrlDomain'

// Styles
import styles from './ContentCard.pcss'
import mediaStyles from './ContentCardMedia.pcss'

export interface ContentCardMediaProps {
  content: ContentItem & { imageUrl: string, thumbnailUrl?: string }
  className?: string
  hideProvider?: boolean
  small?: boolean
  onClick: () => void
  openContentPreviewWindow: (content: Video | Photo | Article | Gif) => void
}

function StockSourceLink({ content }: { content: ContentItem}) {
  if (!content.isStock || !content.provider) {
    return null
  }

  return (
    <a
      href={content.socialLink}
      rel="noopener"
      target="_blank"
      className={mediaStyles['provider-link']}
    >
      {content.provider}
    </a>
  )
}

export function ContentCardMedia({
  content,
  openContentPreviewWindow,
  onClick,
  hideProvider,
  className,
  small
}: ContentCardMediaProps) {
  const type = content.type
  const imageUrl = type === STOCK_PHOTO_TYPE ? content.thumbnailUrl : content.imageUrl
  const feedType = content.feed.type
  const backgroundStyles = {
    backgroundImage: `url('${imageUrl}')`
  }
  const isYouTubeLink = (/youtube/i).test(parseUrlDomain(content.socialLink || (content as Article).sourceLink) || '')

  function handleClick(e: React.MouseEvent<any>) {
    if (feedType === FEED_TYPE_FACEBOOK || feedType === FEED_TYPE_TWITTER || feedType === FEED_TYPE_REDDIT) {
      e.preventDefault()
      openContentPreviewWindow(content as any)
    }

    onClick()
  }

  if (type === VIDEO_TYPE && feedType === FEED_TYPE_KEYWORD && isYouTubeLink) {
    return (
      <div className={classnames(styles['image-overlay'], className || '')}>
        <YTVideoPlayer
          videoUrl={(content as Video).videoUrl}
          videoId={content.id}
          togglePlayOnHover
          contentItem={content as Video}
          thumbnailUrl={imageUrl}
          className={styles['video-container']}
          videoContainerClassName={small ? styles['video-box-small'] : styles['video-box']}
          imageClassName={styles.media}
          withBackground
        />
      </div>
    )
  }

  if ([GIF_TYPE, STOCK_GIF_TYPE].includes(type)) {
    const backgroundImage = content.imageUrl !== (content as Gif).gifUrl ? content.imageUrl : undefined

    return (
      <div className={classnames(styles['image-overlay'], className || '')} onClick={handleClick}>
        {!hideProvider && <StockSourceLink content={content} />}

        <GifPlayer
          gifUrl={(content as Gif).gifUrl}
          backgroundImage={backgroundImage}
          loop
          className={styles['video-container']}
        />
      </div>
    )
  }

  return (
    <React.Fragment>
      {!hideProvider && <StockSourceLink content={content} />}

      <a
        href={content.socialLink || (content as Article).sourceLink}
        target="_blank"
        rel="noopener"
        className={classnames(styles['image-overlay'], className || '')}
        data-testid="content-card-media"
        onClick={handleClick}
      >
        {[VIDEO_TYPE, STOCK_VIDEO_TYPE].includes(type) && (
          <PlayIcon className={styles['icon-play']} />
        )}
        <img src={imageUrl} className={styles.media} loading="lazy" />
        <div className={mediaStyles['bg-image']} style={backgroundStyles}></div>
      </a>
    </React.Fragment>
  )
}

export default ContentCardMedia
