import { createElement, Component } from 'react'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import Tooltip from '@mui/material/Tooltip'
import styles from './TallImageWarning.pcss'

export interface TallImageWarningProps {
  withWrapper?: boolean
  className?: string
}

interface TallImageWarningState { }

export class TallImageWarning extends Component<TallImageWarningProps, TallImageWarningState> {
  render() {
    const { withWrapper, className } = this.props
    const textClassName = !withWrapper ? className : ''

    const text = createElement('span', { key: 'tall-image-text', className: `${styles['tall-image-sign']} ${textClassName}` }, [
      'tall image',
      createElement(Tooltip, {
        key: 'tooltip',
        title: 'Full image will post. Only preview is cropped.',
        children: createElement(InfoIcon, {
          key: 'info-icon',
          className: styles['icon-info']
        })
      })
    ])
    return withWrapper
      ? createElement('div', { key: 'tall-image', className: `${styles.wrapper} ${className}` }, text)
      : text
  }
}

export default TallImageWarning
