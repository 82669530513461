import * as React from 'react'
import styles from './BulkView.pcss'
import { ComposerBulkUploadBlock } from './components/ComposerBulkUploadBlock'
import { useDispatch, useSelector } from 'react-redux'
import { multiPostsCountSelector } from 'services/compose/selectors'
import CircularProgress from '@mui/material/CircularProgress'
import RSSArticlesUpload from './components/RSSArticlesUpload'
import MultiImageUpload from './components/MultiImageUpload'
import { IndexedObject } from 'interfaces'
import FileUpload from 'components/FileUpload'
import { addImagePosts, addImageUploadPosts, imagesUploadDone, imageUploadUpdate } from 'services/compose'
import { FormattedMessage, useIntl } from 'react-intl'
import { message } from 'services/snackbar'

export function BulkView() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState(false)
  const multiPostsCount = useSelector(multiPostsCountSelector)
  const [photos, setPhotos] = React.useState<IndexedObject<{ file: File, url?: string, error?: string }>>({})

  const onFileUploaded = (url: string, file: File, id: string) => {
    setPhotos(current => ({
      ...current,
      [id]: { file, url }
    }))
  }

  const onImageUploadError = (fileName: string, error: string, id: string) => {
    setPhotos(current => ({
      ...current,
      [id]: { ...current[id], error }
    }))
    dispatch(imageUploadUpdate({ fileName, error }))
  }

  const onPhotosAdded = (added: IndexedObject<{ file: File }>) => {
    setPhotos(current => ({
      ...current,
      ...added
    }))
    dispatch(addImageUploadPosts(added))
  }

  const onUploadStarted = () => {
    setLoading(true)
  }

  const onUploadFinished = () => {
    setLoading(false)
  }

  const onFileLoaded = (name: string, src: string) => {
    dispatch(imageUploadUpdate({ fileName: name, error: undefined, src }))
  }

  React.useEffect(() => {
    if (multiPostsCount > 0) {
      setLoading(false)
    }
  }, [multiPostsCount])

  React.useEffect(() => {
    const uploads = Object.values(photos)
    if (uploads.length === 0) {
      return
    }
    const isUploading = uploads.find(p => !p.url && !p.error) !== undefined

    if (!isUploading) {
      dispatch(imagesUploadDone(photos))
    }
  }, [photos, dispatch])

  const onUploadErrorGeneric = React.useCallback((err?: string) => {
    dispatch(message(err || intl.formatMessage({ id: 'errors.generic-support' }), 'error'))
    setLoading(false)
  }, [dispatch])

  return (
    <div className={`${styles.content} ${multiPostsCount > 0 ? styles.hidden : ''}`} data-testid="composer-bulk-view">
      <div className={styles.main}>
        <div>
          <MultiImageUpload onPhotosAdded={onPhotosAdded} />
        </div>
        <div>
          <RSSArticlesUpload
            onUploadStarted={onUploadStarted}
            onUploadError={onUploadErrorGeneric}
          />
        </div>
        <div>
          <ComposerBulkUploadBlock
            onUploadStarted={onUploadStarted}
            onUploadError={onUploadErrorGeneric}
            onFileUploaded={onUploadFinished}
          />
        </div>
        {loading && (
          <div className={styles['loading-overlay']}>
            <div className={styles['loader-box']}>
              <CircularProgress size="20px" />
              <span><FormattedMessage id="composer.multi-post.labels.uploading" /></span>
            </div>
          </div>
        )}
      </div>
      <div className={styles.hidden}>
        {Object.keys(photos).map(key => (
          <FileUpload
            key={key}
            file={photos[key].file}
            uploadId={key}
            rotateBy="0"
            loaderClassName={styles.hidden}
            onFileUploaded={onFileUploaded}
            onImageLoaded={onFileLoaded}
            onError={onImageUploadError}
          />
        ))}
      </div>
    </div>
  )
}

export default BulkView
