import { getState, StoreThunkDispatch } from 'store/state'
import { LEGACY_URL } from 'config'
import capitalize from 'utils/format/capitalize'
import { PPProduct, User } from 'interfaces'
import { currentUserSelector, isTrialActiveSelector } from 'services/users/selectors'
import { getProductById } from 'services/product'
import { CURRENCY_SIGN_MAP, LIMIT_USERS_COUNT, LIMIT_PROFILES_COUNT } from 'shared'

export function mapAccountResponse(response: any) {
  return (dispatch: StoreThunkDispatch, getState: getState) => {
    const state = getState()
    const user = currentUserSelector(state) as User
    const account = response.account
    const isTrial = isTrialActiveSelector(state)
    const trialEndsAt = account.trialEndsAt || account.trialEndedAt
    const productInfo = dispatch(getProductById(account.product)) as PPProduct
    const isAnnualPlan = Boolean(account.isAnnual)

    const card = account.card
    const profile = response.profile
    const creditCard = card ? {
      ownerName: `${card.firstName} ${card.lastName}`,
      type: card.cardType,
      expirationMonth: card.expirationMonth,
      expirationYear: card.expirationYear
    } : undefined
    // eslint-disable-next-line no-magic-numbers
    const annualPricePerMonth = (productInfo.price.annual[user.currency] / 12).toFixed(2).replace('.00', '')
    const monthlyPrice = isAnnualPlan
      ? `${CURRENCY_SIGN_MAP[user.currency]}${annualPricePerMonth}/mo`
      : `${CURRENCY_SIGN_MAP[user.currency]}${productInfo.price.monthly[user.currency]}/mo`
    const name = `${capitalize(productInfo.name)} ${isTrial ? '(Trial)' : ''}`
    const description = productInfo.order > 0 // do not show description for Free plan
      ? `${name}: ${monthlyPrice} billed ${isAnnualPlan ? 'yearly' : 'monthly'}`
      : name

    const customProfilesLimit = account.profilesLimit
    const planProfilesLimit = productInfo.limits[LIMIT_PROFILES_COUNT]
    const useCustomProfilesLimit = customProfilesLimit && customProfilesLimit > planProfilesLimit

    const subscription = {
      name,
      description,
      masterAdmin: account.masterAdmin,
      usersLimit: productInfo.limits[LIMIT_USERS_COUNT],
      profilesLimit: useCustomProfilesLimit ? customProfilesLimit : planProfilesLimit,
      useCustomProfilesLimit,
      billingPortalLink: account.billingPortalLink,
      updatePaymentLink: `${LEGACY_URL}/settings/account_info/chargify_redirect?billing_portal_link=${account.billingPortalLink}`,
      chargifySubscriptionId: account.chargifySubscriptionId
    }

    return { profile, subscription, creditCard, productInfo, trialEndsAt }
  }
}

export function teamMemberAdapter(data: any) {
  let accounts = data.accounts || {}
  if (Array.isArray(data.accounts)) {
    accounts = data.accounts.reduce((map: any, acc: string) => {
      map[acc] = acc
      return map
    }, {})
  }

  const fbId = data.adminFbId || data.fbAccountId
  const photoUrl = fbId ? `//graph.facebook.com/${fbId}/picture?type=large` : undefined

  return {
    ...data,
    accounts,
    photoUrl,
    adminId: data.adminId || data.originalAdminId
  }
}
