import { StatusesSearchProps } from 'components/CuratedFolderView/Statuses'
import { createContext } from 'react'
import { uniqueStrings } from 'utils/unique'

interface QuotesState {
  search: StatusesSearchProps
  savedSearches: string[]
}

export const initialState: QuotesState = {
  search: {
    active: false,
    loading: false,
    page: 0,
    query: '',
    tag: undefined,
    hasNextPage: true
  },
  savedSearches: []
}

export const QuotesContext = createContext({ ...initialState, dispatch: (_action: any) => {} })

const ACTION_UPDATE_SEARCH = 'ACTION_UPDATE_SEARCH'
export function updateSearch(values: Partial<StatusesSearchProps>) {
  return {
    type: ACTION_UPDATE_SEARCH,
    payload: values
  }
}

const ACTION_SAVE_SEARCH = 'ACTION_SAVE_SEARCH'
export function saveSearches(queries: string[]) {
  return {
    type: ACTION_SAVE_SEARCH,
    payload: queries
  }
}

const ACTION_DELETE_SAVED_SEARCH = 'ACTION_DELETE_SAVED_SEARCH'
export function deleteSavedSearch(value: string) {
  return {
    type: ACTION_DELETE_SAVED_SEARCH,
    payload: value
  }
}

export function quotesReducer(state: QuotesState, action: { type: string, payload: any }): QuotesState {
  switch (action.type) {
    case ACTION_UPDATE_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload
        }
      }
    case ACTION_SAVE_SEARCH:
      return {
        ...state,
        savedSearches: uniqueStrings([
          ...action.payload.map((q: string) => q.toLowerCase()),
          ...state.savedSearches
        ])
      }

    case ACTION_DELETE_SAVED_SEARCH:
      return {
        ...state,
        savedSearches: state.savedSearches.filter(value => value !== action.payload)
      }
    default:
      return state
  }
}
