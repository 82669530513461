import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import {
  composerContentItemSelector,
  composerImageUrlsSelector,
  composerPromptSelector,
  multiPostsArraySelector
} from 'services/compose/selectors'
import { FormattedMessage } from 'react-intl'
import { closeComposerPrompt } from 'services/compose'
import styles from './ComposerPrompt.pcss'
import { PHOTO_TYPE } from 'interfaces'
import { useLocation, useNavigate } from 'react-router-dom'

export function ComposerPrompt() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const composerContentItem = useSelector(composerContentItemSelector)
  const multiPosts = useSelector(multiPostsArraySelector)
  const carouselImages = useSelector(composerImageUrlsSelector)
  const prompt = useSelector(composerPromptSelector)
  const open = Boolean(prompt.content)

  let messageKey: any = null
  switch (prompt.action) {
    case 'carousel':
      if (multiPosts.length > 0) {
        messageKey = 'composer.prompt.multipost-blocks-carousel'
      } else if (composerContentItem && composerContentItem.type !== PHOTO_TYPE) {
        messageKey = 'composer.prompt.content-blocks-carousel'
      }
      break

    case 'multipost':
      if (composerContentItem || carouselImages.length > 0) {
        messageKey = 'composer.prompt.content-blocks-multipost'
      }
      break

    case 'create':
      if (carouselImages.length > 0 || composerContentItem) {
        messageKey = 'composer.prompt.content-blocks-create'
      } else if (multiPosts.length > 0) {
        messageKey = 'composer.prompt.multipost-blocks-create'
      }
      break
  }

  const openComposer = () => {
    closePrompt()
    navigate('/composer', { state: { floating: true, modal: 'compose', opener: location.pathname, background: location } })
  }

  const closePrompt = () => {
    dispatch(closeComposerPrompt())
  }

  return (
    <Dialog open={open} classes={{ paper: styles.paper }}>
      <DialogTitle className={styles.message}>
        {messageKey && <FormattedMessage id={messageKey} />}
      </DialogTitle>
      <div className={styles.actions}>
        <React.Fragment>
          <Button onClick={closePrompt}>
            <FormattedMessage id="actions.cancel" />
          </Button>
          <Button variant="contained" color="primary" onClick={openComposer}>
            <FormattedMessage id="actions.ok" />
          </Button>
        </React.Fragment>
      </div>
    </Dialog>
  )
}

export default ComposerPrompt
