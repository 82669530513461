import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import styles from './NewFeedMessage.pcss'
const icon = require('static/img/icons/10-minute_icon.png')

export function NewFeedMessage(props: { className?: string }) {
  return (
    <div className={`${styles.container} ${props.className || ''}`}>
      <img className={styles.icon} src={icon} />
      <div className={styles.text}>
        <h2 className={styles.title}>
          <FormattedMessage id="general.feed.title-new-feed" />
        </h2>
        <h4 className={styles.subtitle}>
          <FormattedMessage id="general.feed.subtitle-new-feed" />
        </h4>
      </div>
    </div>
  )
}

export default NewFeedMessage
