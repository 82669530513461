import * as React from 'react'
import { PostDestination } from 'interfaces'
import { SelectableAvatar } from 'components/ProfileAvatar'
import SocialIcon from 'components/SocialIcon'
import IconCheck from '@mui/icons-material/Check'
import styles from './ProfileAndListSelector.pcss'
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js'

const AVATAR_SIZE = 36

interface ProfileListItemProps {
  profile: PostDestination
  selected: boolean
  grid?: boolean
  radioSelect?: boolean
  onClick: (profile: PostDestination) => void
  onDoubleClick: (profile: PostDestination) => void
  onDelete?: (ppid: string) => void
}

export function ProfileListItem(props: ProfileListItemProps) {
  const { profile, onClick, onDoubleClick } = props
  const handleClick = () => {
    onClick(profile)
  }

  const handleDoubleClick = () => {
    onDoubleClick(profile)
  }

  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete(profile.ppid)
    }
  }

  if (props.grid) {
    return (
      <div
        className={`${styles['grid-list-item']} ${props.selected ? '' : styles.dimmed}`}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
      >
        <SelectableAvatar
          key={profile.id}
          selected={props.selected}
          title={profile.name}
          brand={profile.type}
          src={profile.image}
          size={AVATAR_SIZE}
          circle={!profile.fbGroup}
          radio={props.radioSelect}
        />
      </div>
    )
  }

  return (
    <li
      className={`${styles['list-item']} ${props.selected ? styles.selected : ''}`}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
    >
      <SocialIcon icon={profile.type} size="16px" className={`${styles[`icon-${profile.type}`]} ${styles['profile-icon']}`} />
      <span className={`${styles['profile-label']} text-ellipsis`}>{profile.name}</span>
      {props.onDelete && (
        <span className={styles['btn-delete']} onClick={onDelete}>
          <Icon path={mdiClose} size="16px" />
        </span>
      )}
      {props.selected && <IconCheck className={styles['icon-check']} />}
    </li>
  )
}
