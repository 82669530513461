import * as React from 'react'
import { ALL_RANGE_FILTER, ContentItem, StatusIdea } from 'interfaces'
import styles from './FavoritesView.pcss'
import { getFavorites, getFavoriteStatusIdeas } from 'services/content/favorites'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import CardBricks from 'components/CardBricks'
import StatusIdeaCard from 'components/Card/StatusIdeaCard'
import { renderLoadingCards } from 'routes/find/routes/home/components/utils'
import { message } from 'services/snackbar'
import { TEXT_TYPE } from 'shared'
import AnyCard from 'components/Card'

interface FavoriteStatusesViewProps {
  cardActionLabel?: React.ReactNode
  emptyView?: React.ReactNode
  searchQuery?: string
  showEmptyCards?: boolean
  filter?: 'status' | 'quotes'
  actionButtonIcon?: React.ReactNode
  onCompose?: (content: StatusIdea) => boolean | void
}

export function FavoriteStatusesView(props: FavoriteStatusesViewProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [items, setItems] = React.useState<StatusIdea[]>([])
  const [favTexts, setFavTexts] = React.useState<ContentItem[]>([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const fetchFavs = async () => {
      try {
        const response: any = await dispatch(getFavoriteStatusIdeas(0)).unwrap()
        const texts = await dispatch(getFavorites({ page: 0, type: TEXT_TYPE, range: ALL_RANGE_FILTER })).unwrap()
        setFavTexts(Array.isArray(texts) ? texts : texts.items)
        setItems(response)
      } catch (e) {
        dispatch(message('Oops! We couldn\'t fetch your favorites. Please refresh the page.', 'error'))
        console.log(`Error fetching favorite status ideas: ${e}`)
      }
      setLoading(false)
    }

    fetchFavs()
  }, [dispatch])

  const loadingCards = React.useMemo(() => {
    if (loading || (items.length === 0 && props.showEmptyCards)) {
      return renderLoadingCards(undefined, false, false, loading)
    }
    return []
  }, [loading, items.length, props.showEmptyCards])

  const visibleItems = React.useMemo(() => {
    let filtered = props.searchQuery
      ? items.filter(item => item.status.toLowerCase().indexOf((props.searchQuery as string).toLowerCase()) !== -1)
      : items

    if (props.filter) {
      const quotesOnly = props.filter === 'quotes'
      filtered = filtered.filter(item => Boolean(item.isQuote) === quotesOnly)
    }

    return filtered
  }, [props.searchQuery, items, props.filter])

  const visibleTexts = React.useMemo(() => {
    const filtered = props.searchQuery
      ? favTexts.filter(item => item.status?.toLowerCase().indexOf((props.searchQuery as string).toLowerCase()) !== -1)
      : favTexts

    return filtered
  }, [props.searchQuery, favTexts])

  const showEmptyView = !loading && visibleItems.length === 0

  return (
    <div className={styles.wrapper}>
      {visibleItems.length + loadingCards.length > 0 && (
        <CardBricks key={`${props.searchQuery?.toString()}+${visibleItems.length}`}>
          {visibleItems.map(item => (
            <StatusIdeaCard
              key={item.id}
              actionButtonIcon={props.actionButtonIcon}
              content={item}
              actionLabel={props.cardActionLabel}
              onCompose={props.onCompose}
            />
          )).concat(visibleTexts.map(item => (
            <AnyCard key={item.id} content={item} children={item} square />
          ))).concat(loadingCards)}
        </CardBricks>
      )}
      {showEmptyView && props.emptyView}
    </div>
  )
}
