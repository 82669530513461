import * as React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import styles from './Compose.pcss'
import { resetComposer, setComposerBulkUploadFileUrl } from 'services/compose'
import { useDispatch, useSelector } from 'react-redux'
import { composerIsEmptySelector } from 'services/compose/selectors'
import Composer from 'components/Composer'
import { AIWriter } from 'components/AIWriter'

declare const __DEV__: boolean

export function Compose() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isEmpty = useSelector(composerIsEmptySelector)
  const prevLocationState = React.useRef<any>({})

  React.useEffect(() => {
    if (location.state?.opener !== '/composer') {
      prevLocationState.current = location.state
    }
  }, [location.state])

  const onUnload = React.useCallback((event: Event) => {
    event.preventDefault()
    event.returnValue = false
    return ''
  }, [])

  React.useEffect(() => {
    if (__DEV__) {
      return
    }
    if (!isEmpty) {
      window.addEventListener('beforeunload', onUnload)
    }
    return () => {
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [isEmpty, onUnload])

  const close = (reset?: boolean, redirectUrl?: string) => {
    if (redirectUrl) {
      navigate(redirectUrl)
    } else if (prevLocationState.current) {
      const { opener, search, nextState } = prevLocationState.current
      navigate(opener || '/', { state: { search, ...nextState } })
    } else {
      navigate('/')
    }

    dispatch(setComposerBulkUploadFileUrl({ url: undefined }))
    if (reset) {
      dispatch(resetComposer())
    }
  }

  return (
    <Dialog open fullScreen disableEnforceFocus classes={{ root: styles.dialog, paper: styles.paper }}>
      <Composer onClose={close} />
      <AIWriter />
    </Dialog>
  )
}

export default Compose
