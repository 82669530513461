import * as React from 'react'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { FormattedMessage } from 'react-intl'
import styles from './ComposerActionsButton.pcss'

interface ComposerActionsButtonProps {
  disabled: boolean
  postsCount: number
  className?: string
  btnClassName?: string
  onAddToPlan: () => void
  onShareNext: () => void
  onSchedule?: () => void
  onShareNow?: () => void
}

export function ComposerActionsButton(props: ComposerActionsButtonProps) {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: MouseEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const handleClick = (callback: () => void) => () => {
    callback()
    setOpen(false)
  }

  return (
    <div className={`${styles.container} ${props.className || ''}`} data-testid="composer-actions-button">
      <ButtonGroup
        disabled={props.disabled}
        variant="contained"
        color="primary"
        ref={anchorRef}
        disableRipple
        className={`${styles['btn-group']} ${props.btnClassName || ''}`}
      >
        <Button className={styles['btn-main']} data-testid="composer-actions-btn-queue" onClick={handleClick(props.onAddToPlan)}>
          <FormattedMessage id="composer.actions.add-to-plan" values={{ count: props.postsCount }} />
        </Button>
        <Button
          color="primary"
          size="small"
          disableElevation
          data-testid="composer-actions-menu-toggle"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} disablePortal className={styles.popper}>
        <Paper data-testid="composer-actions-menu">
          <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClose}>
            <MenuList>
              <MenuItem onClick={handleClick(props.onShareNext)} className={styles['menu-item']}>
                <FormattedMessage id="composer.actions.share-next" values={{ count: props.postsCount }} />
              </MenuItem>
              {props.onShareNow && (
                <MenuItem onClick={handleClick(props.onShareNow)} className={styles['menu-item']}>
                  <FormattedMessage id="composer.actions.share-now" values={{ count: props.postsCount }} />
                </MenuItem>
              )}
              {props.onSchedule && (
                <MenuItem onClick={handleClick(props.onSchedule)} className={styles['menu-item']}>
                  <FormattedMessage id="composer.actions.schedule-post" />
                </MenuItem>
              )}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  )
}

export default ComposerActionsButton
