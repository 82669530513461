import { AddTimeParams, api } from './net'
import StoreState from 'store/state'
import { planAdapter, scheduleAdapter } from './adapter'
import { planToProfilesSelector, plansSelector } from './selectors'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { trackTrainingEvent } from 'services/users/tracking'
import { HS_EVENT_PLAN_ACTION, trackHubspotEvent } from 'services/tracking/hubspot'
import { EVENT_SETUP_PLAN } from 'routes/trial/interfaces'
import { connectedDestinationsSelector, getDestinations } from 'services/destinations'
import { ScheduleTime } from 'interfaces/Plan'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'

export const getPlan = createAsyncThunk(
  'plan/get',
  async ({ id, force }: { id: string, force?: boolean }, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as StoreState
    const storedPlans = plansSelector(state)
    const planIds = Object.keys(planToProfilesSelector(state))

    if (!force && storedPlans[id]) {
      return { data: storedPlans[id], planId: id }
    }

    try {
      const response = await dispatch(api.getPlan(id)).toPromise()
      if (response.error) {
        return rejectWithValue(response.error)
      }

      const state = getState() as StoreState
      const connectedProfiles = connectedDestinationsSelector(state)
      const connectedProfilesPpids = Object.values(connectedProfiles).reduce((acc, profile) => {
        if (profile.ppid) {
          acc[profile.ppid] = true
        }
        return acc
      }, {} as Record<string, boolean>)
      const planIndex = planIds.indexOf(response.plan.id)
      return {
        data: planAdapter({
          ...response.plan,
          name: response.plan.name || `Plan ${planIndex + 1}`,
          ppPageIds: (response.ppPageIds || []).filter((ppid: string) => connectedProfilesPpids[ppid])
        }),
        planId: response.plan.id || id
      }
    } catch (error) {
      console.log('[getPlan()]', error)
      return rejectWithValue(error)
    }
  }
)

export const createPlan = createAsyncThunk(
  'plan/create',
  async (params: { ppids: string[], name: string, copyFromPlanId?: string }, { dispatch, rejectWithValue }) => {
    const { ppids, name, copyFromPlanId } = params
    try {
      const response = await dispatch(api.createPlan(ppids, name, copyFromPlanId)).toPromise()
      if (response.error) {
        return rejectWithValue(response.error)
      }
      dispatch(trackTrainingEvent({ eventCode: EVENT_SETUP_PLAN }))
      dispatch(trackHubspotEvent(HS_EVENT_PLAN_ACTION, { type: 'created' }))
      return { id: response.planId, ppids, name }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const deletePlan = createAsyncThunk(
  'plan/delete',
  async (ppid: string, { dispatch }) => {
    await dispatch(api.deletePlan(ppid)).toPromise()
    return ppid
  }
)

export const initPlans = createAsyncThunk(
  'plan/init',
  async (force: boolean = false, { dispatch, getState }) => {
    const [plans, tzResponse] = await Promise.all([
      dispatch(api.getPostingPlans()).toPromise(),
      force ? dispatch(api.getTimezones()).toPromise() : Promise.resolve(null),
      dispatch(getDestinations(force))
    ])

    const state = getState() as StoreState
    const connectedProfiles = connectedDestinationsSelector(state)
    const connectedProfilesPpids = Object.values(connectedProfiles).reduce((acc, profile) => {
      if (profile.ppid) {
        acc[profile.ppid] = true
      }
      return acc
    }, {} as Record<string, boolean>)

    // NOTE: Always add default plan first
    const defaultPlanId = Object.keys(plans).find(key => plans[key].isDefault)
    const plansMap: Record<string, { name: string, ppids: string[], isPaused: boolean, isDefault: boolean }> = {}
    let index = 0
    if (defaultPlanId) {
      plansMap[defaultPlanId] = {
        ...plans[defaultPlanId],
        isDefault: true,
        id: defaultPlanId,
        ppids: plans[defaultPlanId].ppPageIds.filter((ppid: string) => connectedProfilesPpids[ppid]),
        name: plans[defaultPlanId].name || `Plan ${index += 1}`,
        order: index
      }
    }

    Object.keys(plans).forEach((id: string) => {
      const plan = plans[id]
      const ppids = plan.ppPageIds.filter((ppid: string) => connectedProfilesPpids[ppid])
      if (!plan.isDefault && ppids.length > 0) { // filter out plans without profiles
        plansMap[id] = {
          ...plan,
          id,
          ppids,
          name: plan.name || `Plan ${index += 1}`,
          order: index
        }
      }
    })

    const data: { plans: Record<string, any>, userTimezone?: string, timezones?: Record<string, string> } = { plans: plansMap }
    if (tzResponse) {
      data.userTimezone = tzResponse.userTimezone
      data.timezones = tzResponse.timezones.reduce((zonesObject: { [key: string]: string }, current: any) => {
        zonesObject[current.zone] = current.label
        return zonesObject
      }, {})
    }
    return data
  }
)

export const addSchedule = createAsyncThunk(
  'plan/addSchedule',
  async (params: { planId: string, ppid: string, scheduleId: string }, { dispatch, rejectWithValue }) => {
    const response = await dispatch(api.addSchedule(params.planId, params.scheduleId)).toPromise()
    if (response.error) {
      return rejectWithValue(response.error)
    }
    dispatch(trackTrainingEvent({ eventCode: EVENT_SETUP_PLAN }))
    return {
      schedule: scheduleAdapter(response.plan.tabs[0]),
      ppid: params.ppid
    }
  }
)

export const deleteSchedule = createAsyncThunk(
  'plan/deleteSchedule',
  async (params: { ppid: string, scheduleId: string }, { dispatch }) => {
    await dispatch(api.deleteSchedule(params.scheduleId)).toPromise()
    return { scheduleId: params.scheduleId, ppid: params.ppid }
  }
)

export const addTime = createAsyncThunk(
  'plan/addTime',
  async (params: AddTimeParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await dispatch(api.addTime(params)).toPromise()
      if (response.error) {
        return rejectWithValue(response.error)
      }

      const ids = typeof response.ids === 'number'
        ? [response.ids.toString()]
        : response.ids.map((id: number) => id.toString())

      dispatch(trackHubspotEvent(HS_EVENT_PLAN_ACTION, { type: 'edited' }))
      dispatch(trackTrainingEvent({ eventCode: EVENT_SETUP_PLAN }))
      return {
        planId: params.planId,
        scheduleId: params.scheduleId || response.tabId,
        hour: params.hour,
        minute: params.minute,
        timeOfDay: params.timeOfDay,
        ids,
        range: params.range,
        bucketId: params.bucketId
      }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const deleteTime = createAsyncThunk(
  'plan/deleteTime',
  async (
    { planId, scheduleId, timeslot }: { planId: string, scheduleId: string, timeslot: ScheduleTime },
    { dispatch, rejectWithValue }
  ) => {
    const response = await dispatch(api.deleteTime(timeslot.ids)).toPromise()
    if (response.error) {
      return rejectWithValue(response.error)
    }
    dispatch(trackHubspotEvent(HS_EVENT_PLAN_ACTION, { type: 'edited' }))
    dispatch(trackTrainingEvent({ eventCode: EVENT_SETUP_PLAN }))
    return { planId, scheduleId, timeslot }
  }
)

type UpdateTimeParams = {
  planId: string,
  scheduleId: string,
  timeKey: string,
  ids: string[],
  hour: string,
  minute: string,
  timeOfDay: string,
  bucketId?: string
  range?: boolean
}

export const updateTime = createAsyncThunk(
  'plan/updateTime',
  async (params: UpdateTimeParams, { dispatch, rejectWithValue }) => {
    const { planId, scheduleId, timeKey, ids, hour, minute, timeOfDay, bucketId, range } = params
    const response = await dispatch(api.updateTime(ids, hour, minute, timeOfDay, bucketId, range)).toPromise()
    if (response.error) {
      return rejectWithValue(response.error)
    }
    dispatch(trackHubspotEvent(HS_EVENT_PLAN_ACTION, { type: 'edited' }))
    dispatch(trackTrainingEvent({ eventCode: EVENT_SETUP_PLAN }))
    return { planId, scheduleId, ids, hour, minute, timeOfDay, timeKey, bucketId, range }
  }
)

export const updatePlanTimezone = createAsyncThunk(
  'plan/updateTimezone',
  async ({ planId, timezone }: { planId: string, timezone: string }, { dispatch }) => {
    await dispatch(api.updateTimezone(planId, timezone)).toPromise()
    return { planId, timezone }
  }
)

export const togglePlanPaused = createAsyncThunk(
  'plan/togglePaused',
  async (planId: string, { dispatch }) => {
    await dispatch(api.togglePlanPaused(planId)).toPromise()
    return { planId }
  }
)

export const addProfilesToPlan = createAsyncThunk(
  'plan/updateProfiles',
  async ({ planId, ppids }: { planId: string, ppids: string[] }, { dispatch }) => {
    await dispatch(api.addProfilesToPlan(planId, ppids)).toPromise()
    return { planId, ppids }
  }
)

export const updatePlan = createAsyncThunk(
  'plan/update',
  async (
    { planId, name, addPpids, copyFrom }: { planId: string, name: string, addPpids?: string[], copyFrom?: string },
    { dispatch, rejectWithValue }) => {
    try {
      await dispatch(api.updatePlan(planId, name, addPpids, copyFrom)).toPromise()
      return { planId, name, addedPpids: addPpids }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
