import Button from '@mui/material/Button'
import React, { useState } from 'react'
import { PostDestination, TikTokPostProfileSettings, TikTokPrivacyOptions } from 'shared'
import { PPSelect, PPSelectOptions } from 'components/PPSelect'
import styles from './TikTokOptions.pcss'
import { setTikTokProfileSettings } from 'services/compose'
import { useDispatch } from 'react-redux'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { ALLOW_PERSIST_TIKTOK_SETTINGS } from 'config'
import { saveTikTokProfileSettings } from 'services/compose/tiktok/net'
import { StoreThunkDispatch } from 'store/state'

interface TikTokPostSettingsProps {
  settings: TikTokPostProfileSettings
  options: TikTokPrivacyOptions
  profile: PostDestination
  hideSaveOption?: boolean
  onChange?: (settings: TikTokPostProfileSettings, persist?: boolean) => void
}

const PRIVACY_OPTIONS_LABELS: Record<string, string> = {
  PUBLIC_TO_EVERYONE: 'Public (Everyone)',
  MUTUAL_FOLLOW_FRIENDS: 'Friends (Followers that follow you back)',
  FOLLOWER_OF_CREATOR: 'Followers (People who follow you)',
  SELF_ONLY: 'Private (Visible to me only)'
}

const PROMOTION_OPTIONS_LABELS: Record<string, string> = {
  OFF: 'Off',
  SELF: 'Yes, my brand',
  '3RD-PARTY': 'Yes, 3rd-party brand',
  BOTH: 'Yes, my brand + 3rd party brand'
}

export function TikTokPostSettings({ settings, options, profile, hideSaveOption, onChange }: TikTokPostSettingsProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [values, setValues] = useState<TikTokPostProfileSettings>(settings)
  const [shouldSaveSettings, setShouldSaveSettings] = useState(false)

  const toggleSaveSettings = () => {
    setShouldSaveSettings(!shouldSaveSettings)
  }

  const onSettingsChanged = (newSettings: TikTokPostProfileSettings) => {
    if (onChange) {
      onChange(newSettings)
    } else {
      dispatch(setTikTokProfileSettings(newSettings))
    }
  }

  React.useEffect(() => {
    if (shouldSaveSettings) {
      dispatch(saveTikTokProfileSettings(profile.ppid, values)).toPromise().catch(console.error)
    }
  }, [dispatch, profile.ppid, shouldSaveSettings, values])

  React.useEffect(() => {
    setValues({
      ppid: settings.ppid,
      enableComment: settings.enableComment,
      enableDuet: settings.enableDuet,
      enableStitch: settings.enableStitch,
      privacyLevel: settings.privacyLevel,
      promoting: settings.promoting
    })
  }, [settings.enableComment, settings.enableDuet, settings.enableStitch, settings.privacyLevel, settings.promoting, settings.ppid])

  const allowOptions: PPSelectOptions = {
    none: {
      label: 'No comments, Duets or Stitch'
    },
    enableComment: {
      label: 'Comments',
      disabled: options.enableComment.length < 2
    },
    enableDuet: {
      label: 'Duet',
      disabled: options.enableDuet.length < 2
    },
    enableStitch: {
      label: 'Stitch',
      disabled: options.enableStitch.length < 2
    }
  }

  const selectedAllowValues = `${values.enableComment ? 'enableComment,' : ''}`
    + `${values.enableDuet ? 'enableDuet,' : ''}`
    + `${values.enableStitch ? 'enableStitch,' : ''}`
      .slice(0, -1)

  const onAllowOptionChange = (allowValues: string) => {
    const selection = allowValues.split(',')
    const withSelection = values.enableComment || values.enableDuet || values.enableStitch

    if (selection.includes('none') && withSelection) {
      setValues({
        ...values,
        enableComment: false,
        enableDuet: false,
        enableStitch: false
      })
      return
    }
    const next = {
      ...values,
      enableComment: selection.includes('enableComment'),
      enableDuet: selection.includes('enableDuet'),
      enableStitch: selection.includes('enableStitch')
    }
    setValues(next)
    onSettingsChanged(next)
  }

  const privacyOptions = options.privacyLevel.reduce((acc, option) => {
    acc[option] = {
      label: PRIVACY_OPTIONS_LABELS[option]
    }
    return acc
  }, { empty: { label: 'Select an option', disabled: true, hidden: true } } as PPSelectOptions)

  const promotionOptions = Object.keys(PROMOTION_OPTIONS_LABELS).reduce((acc, option) => {
    acc[option] = {
      label: PROMOTION_OPTIONS_LABELS[option]
    }
    return acc
  }, { empty: { label: 'Select an option', disabled: true, hidden: true } } as PPSelectOptions)

  const onPrivacyChange = (option: string) => {
    const next = { ...values, privacyLevel: option }
    setValues(next)
    onSettingsChanged(next)
  }

  const onPromotionTypeChange = (option: string) => {
    const next = { ...values, promoting: option as any }
    setValues(next)
    onSettingsChanged(next)
  }

  const avatar = (
    <Tooltip title={profile.name}>
      <Avatar alt={profile.name} src={profile.image} sx={{ width: 24, height: 24 }} />
    </Tooltip>
  )

  return (
    <div data-testid="tiktok-post-settings">
      <div className={styles.content}>
        <div className={styles.row}>
          <PPSelect
            options={privacyOptions}
            selectedValue={values.privacyLevel || 'empty'}
            raised
            withCaret
            name="Privacy"
            className={styles.select}
            icon={avatar}
            onSelectionChange={onPrivacyChange}
          />
        </div>
        <div className={styles.row}>
          <PPSelect
            options={allowOptions}
            selectedValue={selectedAllowValues || 'none'}
            raised
            withCaret
            multiSelection
            name="Allow"
            className={styles.select}
            icon={avatar}
            onSelectionChange={onAllowOptionChange}
          />
        </div>
        <div className={styles.row}>
          <PPSelect
            name="Promotion video"
            options={promotionOptions}
            selectedValue={values.promoting || 'empty'}
            raised
            withCaret
            className={styles.select}
            icon={avatar}
            onSelectionChange={onPromotionTypeChange}
          />
        </div>
        {ALLOW_PERSIST_TIKTOK_SETTINGS && !hideSaveOption && (
          <div className={styles.row}>
            <FormControlLabel
              label="Remember this choice"
              labelPlacement="end"
              classes={{ label: styles['cb-label'] }}
              control={(
                <Checkbox
                  checked={shouldSaveSettings}
                  color="primary"
                  size="small"
                  onChange={toggleSaveSettings}
                />
              )}
            />
          </div>
        )}
      </div>
    </div>
  )
}
