import Tooltip from '@mui/material/Tooltip'
import { Bucket, IndexedObject, WithIntl } from 'interfaces'
import * as React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { bucketsSelector } from 'services/post/selectors'
import { AppNavLink } from '../../AppNavLink'
import styles from '../../AppNavigation.pcss'
import EditIcon from '@mui/icons-material/Edit'
import { DndContext, useDroppable, useSensor, useSensors, PointerSensor } from '@dnd-kit/core'
import { reorder } from 'shared/utils'
import { Subject } from 'rxjs/Subject'
import { SortableContext, rectSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers'
import { StoreThunkDispatch } from 'store/state'
import { reorderBuckets } from 'services/post/actions'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { message } from 'services/snackbar'
import { sortByKeyAscending } from 'utils/sort/order'
import { PPNavLink } from 'components/PPNavLink'

interface BucketsNavMenuProps {
  expanded: boolean
  onToggleExpanded: () => void
  onCreateClick: () => void
  onEditBucketClick: (bucket: Bucket) => void
}

export function BucketsNavMenu(props: BucketsNavMenuProps & WithIntl) {
  const location = useLocation()
  const dispatch: StoreThunkDispatch = useDispatch()
  const buckets = useSelector(bucketsSelector)
  const bucketsMap = React.useMemo(() => buckets.reduce((map: IndexedObject<Bucket>, b: Bucket) => {
    map[b.id] = b
    return map
  }, {}), [buckets])
  const orderedIds = React.useMemo(() => {
    return [...buckets].sort(sortByKeyAscending('order')).map(b => b.id)
  }, [buckets])
  const [order, setOrder] = React.useState<string[]>(orderedIds)
  const orderRef = React.useRef<string[]>(orderedIds)
  const { setNodeRef: setDroppableNodeRef } = useDroppable({
    id: 'droppable'
  })

  React.useEffect(() => {
    setOrder(orderedIds)
    orderRef.current = orderedIds
  }, [orderedIds])

  const reorder$ = React.useRef<Subject<string[]>>()

  React.useEffect(() => {
    reorder$.current = new Subject()
    reorder$.current.switchMap((ids) => {
      return Observable.fromPromise(dispatch(reorderBuckets(ids)).unwrap())
        .pipe(catchError((error) => {
          dispatch(message(props.intl.formatMessage({ id: 'notifications.generic.update-failed' }), 'error'))
          return Observable.of({ error })
        }))
    })
      .subscribe((response: any) => {
        if (response.error) {
          // revert to previous ordering
          setOrder(orderRef.current)
        } else {
          setOrder(response)
          orderRef.current = response
        }
      })
  }, [dispatch, props.intl])

  const createBucketButton = (
    <div key="add-bucket" className={`${styles['child-link']} ${styles['link-action']}`} onClick={props.onCreateClick}>
      + <FormattedMessage id="app.nav.publishing-buckets.create" />
    </div>
  )

  const onDragEnd = (event: any) => {
    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = order.indexOf(active.id)
      const newIndex = order.indexOf(over.id)
      const reordered = reorder(order, oldIndex, newIndex)
      setOrder(reordered)
      reorder$.current?.next(reordered)
    }
  }

  const activeBucketId = location.pathname.replace('/posts/buckets/', '').split('/')[0]

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5
      }
    })
  )

  return (
    <DndContext sensors={sensors} onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]}>
      <SortableContext items={order} strategy={rectSortingStrategy} modifiers={[restrictToParentElement]}>
        <div ref={setDroppableNodeRef} className={styles['buckets-nav']}>
          <AppNavLink
            label={<FormattedMessage id="app.nav.publishing-buckets" />}
            to="/posts/buckets"
            expanded={props.expanded}
            subNavClassName={styles['list-padded-bottom']}
            expandedItemKey={activeBucketId}
            items={buckets.length > 0 ? order.map(id => bucketsMap[id]).filter(Boolean).map(bucket => (
              <DraggableBucketLink key={bucket.id} active={bucket.id === activeBucketId} bucket={bucket} onEdit={props.onEditBucketClick} />
            )).concat(createBucketButton) : createBucketButton}
            toggleExpandedOnClick
            onToggleExpanded={props.onToggleExpanded}
          />
        </div>
      </SortableContext>
    </DndContext>
  )
}

function DraggableBucketLink(props: { bucket: Bucket, active: boolean, onEdit: (b: Bucket) => void }) {
  const colorStyle = { backgroundColor: props.bucket.color }

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: props.bucket.id })

  const onEdit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    props.onEdit(props.bucket)
  }

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    pointerEvents: isDragging ? 'none' : 'all'
  } as any

  return (
    <div ref={setNodeRef} style={style}>
      <AppNavLink
        to={`/posts/buckets/${props.bucket.id}`}
        className={styles['child-link']}
        small
        expanded={props.active}
        hideToggle
        label={(
          <div className={styles['link-label-box']} {...listeners} {...attributes}>
            <Tooltip title={<FormattedMessage id="post.buckets.edit-bucket-tooltip" />} placement="top">
              <EditIcon className={styles['btn-edit']} onClick={onEdit} />
            </Tooltip>
            <div style={colorStyle} className={styles['color-badge']}></div>
            <div className="text-ellipsis">{props.bucket.name}</div>
            <span className={styles['b-posts']}>{`(${props.bucket.postsCount})`}</span>
          </div>
        )}
        items={(
          <React.Fragment>
            <PPNavLink
              to={`/posts/buckets/${props.bucket.id}/posts`}
              end
              className={`${styles['child-link']} ${styles['pad-left']}`}
              activeClassName={styles.active}
            >
              <FormattedMessage id="app.nav.publishing-bucket-posts" />
            </PPNavLink>
            <PPNavLink
              end
              to={`/posts/buckets/${props.bucket.id}/posted`}
              className={`${styles['child-link']} ${styles['pad-left']}`}
              activeClassName={styles.active}
            >
              <FormattedMessage id="app.nav.publishing-posted-posts" />
            </PPNavLink>
          </React.Fragment>
        )}
      />
    </div>
  )
}

export default injectIntl(BucketsNavMenu)
