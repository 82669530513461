import * as React from 'react'
import { useNavigate, useLocation, useParams, useMatch, PathMatch, To, NavigateOptions, Location } from 'react-router-dom'
import { IndexedObject } from 'interfaces'

export type WithRouterProps = {
  location: Location
  params: IndexedObject<string>
  match: PathMatch
  navigate: (to: To | number, options?: NavigateOptions) => void
}

export function injectRouter(
  Component: React.ComponentClass<any> | React.FunctionComponent<any>
): React.FunctionComponent<any> {
  function WithRouter(props: any) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const match = useMatch(location.pathname)

    return (
      <Component {...props} location={location} navigate={navigate} params={params} match={match} />
    )
  }

  WithRouter.displayName = `WithRouter(${Component.displayName})`
  return WithRouter
}
