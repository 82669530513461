import { RequestBuilder, authorizedPipe } from 'services/net'
import { LegacyArticle, RangeFilter } from 'interfaces'
import { CONTENT_SERVICE_URL } from 'config'
import { SortBy } from '../util'
import { ArticleBase } from 'services/compose/interfaces/ArticleBase'

export const ENDPOINT_ARTICLES_EXTRACT = `${CONTENT_SERVICE_URL}/feeds/:sourceId/content/articles`
export const ENDPOINT_ARTICLES_FOLDER_EXTRACT = `${CONTENT_SERVICE_URL}/streams/:folderId/content/articles`

export const V1 = Object.freeze({
  getArticles: function () {
    const builder = new RequestBuilder(ENDPOINT_ARTICLES_EXTRACT).asGet().expectJSON().withoutCredentials()

    return function (sourceId: string, page: number, rangeFilter: RangeFilter, sortBy: SortBy) {
      return authorizedPipe<{ content: LegacyArticle[] }>(
        builder
          .partial(':sourceId', sourceId)
          .param('page', page.toString())
          .param('range_filter', rangeFilter)
          .param('sort_by', sortBy)
          .build()
      )
    }
  }(),
  getArticlesFromFolder: function () {
    const builder = new RequestBuilder(ENDPOINT_ARTICLES_FOLDER_EXTRACT).asGet().expectJSON().withoutCredentials()

    return function (folderId: string, page: number, rangeFilter: RangeFilter, sortBy: SortBy) {
      return authorizedPipe<{ content: LegacyArticle[] }>(
        builder
          .partial(':folderId', folderId)
          .param('page', page.toString())
          .param('range_filter', rangeFilter)
          .param('sort_by', sortBy)
          .build()
      )
    }
  }(),
  getArticlesFromRss: function () {
    const builder = new RequestBuilder(ENDPOINT_ARTICLES_EXTRACT).asGet().expectJSON().withoutCredentials()

    return function (url: string) {
      return authorizedPipe<ArticleBase[]>(
        builder
          .partial(':sourceId', '0')
          .param('range_filter', 'all')
          .param('rss_url', url)
          .build()
      )
    }
  }(),
  getArticleData: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/article_details`).asGet().expectJSON()

    return function (url: string) {
      return authorizedPipe<any>(
        builder
          .param('url', url)
          .build()
      )
    }
  }()
})
