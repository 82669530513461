const SI_POSTFIXES = ['', 'k', 'M', 'G', 'T', 'P', 'E']
const DECIMAL_PLACES_PER_TIER = 3
const BASE_TEN = 10

export function numberToHuman(value: number) {
  const tier = Math.log10(Math.abs(value)) / DECIMAL_PLACES_PER_TIER | 0

  if (tier === 0) {
    return value
  }

  const postfix = SI_POSTFIXES[tier]
  const scale = Math.pow(BASE_TEN, tier * DECIMAL_PLACES_PER_TIER)

  const scaled = value / scale
  let formatted = scaled.toFixed(1) + ''
  /* eslint-disable-next-line no-magic-numbers */
  if (formatted.length >= 5) {
    formatted = scaled.toFixed(0) + ''
  }

  // If exactly .0, remove .0
  if (/\.0$/.test(formatted)) {
    formatted = formatted.substr(0, formatted.length - 2)
  }

  return formatted + postfix
}

export default numberToHuman
