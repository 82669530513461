import { Photo, PHOTO_TYPE, Paginated, LegacyPhoto, FEED_TYPE_KEYWORD } from 'interfaces'
import { TYPE_FEED_TYPE_MAP } from './mappings'
import mapAvatar from './avatar'
import { parseDate } from 'utils/format/date'
import { STOCK_PHOTO_TYPE } from 'interfaces/Content/StockContentTypes'

const BASE_TEN = 10

export function photoAdapter(photo: LegacyPhoto, stock?: boolean): Photo {
  const feed = photo.feed || {}
  const feedType = stock ? FEED_TYPE_KEYWORD : TYPE_FEED_TYPE_MAP[feed.type]
  const feedId = feed.id?.toString()

  return {
    id: `${feedType}:${photo.id}`,
    rawId: photo.id + '',
    hash: photo.searchId,
    type: stock ? STOCK_PHOTO_TYPE : PHOTO_TYPE,
    imageUrl: photo.imageUrl,
    thumbnailUrl: photo.preview || photo.thumbnail || photo.imageUrl,
    comments: photo.comments,
    createdAt: parseDate(photo.createdAt),
    likes: photo.likes,
    rating: photo.rating / BASE_TEN,
    shares: photo.shares,
    socialLink: photo.socialLink,
    shortLink: feed.shortLink,
    live: photo.live,
    isFavorite: photo.isFavorited,
    tweetText: photo.tweetTitle,
    isStock: Boolean(stock),
    feed: {
      id: feedId,
      uniqueSource: feedId,
      isFeed: true,
      image: feed.image || mapAvatar(feedType, feed),
      name: feed.name,
      type: feedType,
      sources: feed.sources,
      order: 0,
      handle: feed.handle,
      networkId: feed.networkId,
      title: feed.title
    },
    favCount: photo.favCount || 0,
    isRedditGallery: Boolean(photo.isRedditGallery),
    provider: photo.adapter,
    status: photo.status || photo.tweetTitle
  }
}

export function photosAdapter(photos: LegacyPhoto[], stock?: boolean): Paginated<Photo[]> {
  return {
    items: photos.map(p => photoAdapter(p, stock)),
    hasNext: photos.length !== 0
  }
}
