import * as React from 'react'
import { isVideoUrl } from 'utils/composer'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import GifIcon from '@mui/icons-material/Gif'

const Freezeframe = require('freezeframe')
import styles from './GifPlayer.pcss'

interface GifPlayerProps {
  gifUrl: string
  gifDataUrl?: string
  backgroundImage?: string
  loop?: boolean
  small?: boolean
  className?: string
  imageClassName?: string
  mediaElementId?: string
}

function isGifUrl(url: string) {
  return url.toLowerCase().endsWith('.gif')
}

export function GifPlayer(props: GifPlayerProps) {
  let mediaUrl = props.gifUrl || props.gifDataUrl + ''
  // Remove query params from gif url
  if (props.gifUrl) {
    mediaUrl = mediaUrl.split('?')[0]
  }
  const isGif = props.gifDataUrl || isGifUrl(mediaUrl)
  const isVideo = isVideoUrl(mediaUrl)
  const invalidUrl = !isGif && !isVideo
  const videoRef = React.createRef<HTMLVideoElement>()
  const gifRef = React.createRef<HTMLDivElement>()
  const backgroundStyle = props.backgroundImage ? { backgroundImage: `url(${props.backgroundImage})` } : {}

  React.useEffect(() => {
    if (isGif && gifRef.current) {
      // eslint-disable-next-line no-new
      new Freezeframe(gifRef.current, { trigger: 'hover' })
    }
  }, [props.gifUrl])

  const playVideo = () => {
    const player = videoRef.current as HTMLVideoElement
    if (player) {
      player.play()
    }
  }

  const pauseVideo = () => {
    const player = videoRef.current as HTMLVideoElement
    if (player) {
      player.pause()
    }
  }

  const gifSign = !invalidUrl && (
    <div className={`${styles.center} ${styles['gif-icon-box']} ${props.small ? styles.sm : ''}`}>
      <GifIcon className={styles['gif-icon']} />
    </div>
  )

  return (
    <div className={`${styles.wrapper} ${props.className || ''}`}>
      {
        invalidUrl && (
          <div className={`${styles.error} ${styles.center}`}>
            <ErrorIcon className={styles.icon} />
            <p>Error loading media!</p>
          </div>
        )
      }
      {
        props.backgroundImage && (
          <div className={`${styles.background} ${props.imageClassName || ''}`} style={backgroundStyle}></div>
        )
      }
      <div className={styles.media}>
        {gifSign}
        {
          isVideo && (
            <video
              controlsList="nodownload nofullscreen noremoteplayback"
              ref={videoRef}
              loop={Boolean(props.loop)}
              muted
              data-id={props.mediaElementId}
              className={`${styles.video} ${styles.center}`}
              onMouseEnter={playVideo}
              onMouseLeave={pauseVideo}
            >
              <source src={mediaUrl} />
              <p>Your browser does not support videos! Please try another one.</p>
            </video>
          )
        }
        {
          isGif && (
            <div
              ref={gifRef}
              className={`freezeframe ${styles.gif}`}
            >
              <img src={mediaUrl} data-id={props.mediaElementId} />
            </div>
          )
        }
      </div>
    </div>
  )
}

export default GifPlayer
