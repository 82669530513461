import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'

const ENDPOINT_REORDER_POSTS = `${LEGACY_URL}/v3/posts/auto_posts_order_set_v3`

export const V1 = Object.freeze({
  reorderPosts: function () {
    return function (postIds: string[]) {
      const endpoint = ENDPOINT_REORDER_POSTS
      const builder = new RequestBuilder(endpoint).asPost().asFormUrlEncoded().expectJSON()
      const data = postIds.map((id: string, index: number) => ({
        post_id: id,
        auto_post_order: index
      }))

      return authorizedPipe<any>(
        builder
          .body({ auto_posts: JSON.stringify(data) })
          .build()
      )
    }
  }(),
  moveToBottom: function () {
    return function (postId: string) {
      const builder = new RequestBuilder(`${LEGACY_URL}/v3/posts/move_to_bottom/:postId`).asPost().asFormUrlEncoded().expectJSON()

      return authorizedPipe<any>(
        builder.partial(':postId', postId).build()
      )
    }
  }()
})
