import { PendingTreat } from './interfaces'

export const SNACKBAR_STATE_VERSION = 'snackbar:1'

export function initialState(): SnackbarState {
  return Object.freeze({
    pending: Object.freeze([])
  })
}

export interface SnackbarState {
  pending: ReadonlyArray<PendingTreat>
}

export default SnackbarState
