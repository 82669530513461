import { FacebookLoginResponse } from 'interfaces'

export const AUTH_STATE_VERSION = 'auth:5'

export interface AuthState {
  tokens: Readonly<{
    lastCheck?: string
    dialogDismissed: boolean
    dialogDismissedAt?: string
    refreshRequired?: {
      [network: string]: Array<{
        name: string
        pageId?: string
        picture?: string
        missingPermissions?: string[]
      }>
    }
  }>
  facebookLoginStatus?: FacebookLoginResponse
  error?: {
    retryAt?: number // The time when user can try logging in again, after being blocked
    restrictedAccess?: boolean
  }
}

export function initialState(): AuthState {
  return {
    tokens: Object.freeze({
      lastCheck: undefined,
      refreshRequired: undefined,
      dialogDismissed: false
    })
  }
}

export default AuthState
