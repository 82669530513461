import * as React from 'react'
import { FetchPostDestinations } from 'components/Fetch'
import { Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { bucketsSelector } from 'services/post/selectors'
import { FetchBuckets } from 'components/Fetch/FetchBuckets'
import { StoreThunkDispatch } from 'store/state'

export function HistoryRoute() {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const buckets = useSelector(bucketsSelector)
  const [loading, setLoading] = React.useState(buckets.length === 0)

  const onLoaded = () => {
    setLoading(false)
  }

  if (loading) {
    return <FetchBuckets onError={onLoaded} onFetched={onLoaded} />
  }

  return (
    <React.Fragment>
      <Outlet />
      <FetchPostDestinations />
    </React.Fragment>
  )
}

export default HistoryRoute
