import * as React from 'react'
import { IndexedObject, PostDestination, sortNetworksDefault, WithIntl } from 'interfaces'
import styles from './SidebarProfileSelector.pcss'
import IconAdd from '@mui/icons-material/AddCircleOutline'
import Tooltip from '@mui/material/Tooltip'
import { FormattedMessage, injectIntl } from 'react-intl'
import { sortByKeyAlphabetically } from 'utils/sort/order'
import { ProfileListItem } from 'routes/publishing/components/ProfileAndListSelector/ProfileListItem'
import { NavLink } from 'react-router-dom'
import ProfilesLayoutPicker from './ProfilesLayoutPicker'

const MIN_SEARCHABLE_PROFILES_COUNT = 10
const SEARCH_BOX_HEIGHT = 32

interface SidebarProfileSelectorOwnProps {
  profiles: { [id: string]: PostDestination }
  selectedProfileIds: string[]
  multipleProfileSelection?: boolean
  withAddProfileButton?: boolean
  className?: string
  hideActions?: boolean
  actionsClassName?: string
  contentClassName?: string
  listViewClassName?: string
  activeView: 'grid' | 'list' | 'network'
  renderProfileGridItem?: (profile: PostDestination) => React.ReactChild
  setActiveView: (view: 'grid' | 'list' | 'network') => void
  onSelectedProfilesChange: (profileIds: string[]) => void
  onDelete?: (ppid: string) => void
  onProfileClick?: (profile: PostDestination) => void
}

type SidebarProfileSelectorProps = SidebarProfileSelectorOwnProps & WithIntl

export function SidebarProfileSelector(props: SidebarProfileSelectorProps) {
  const { activeView, setActiveView } = props
  const [filter, setFilter] = React.useState('')

  const currentFilter = filter.trim()
  let visibleProfiles = Object.values(props.profiles)
  if (currentFilter.length > 0) {
    visibleProfiles = visibleProfiles.filter(p => p.name.toLowerCase().indexOf(currentFilter?.toLowerCase()) !== -1)
  }

  const groupedVisibleProfiles = visibleProfiles.reduce((map: IndexedObject<PostDestination[]>, profile: PostDestination) => {
    if (map[profile.type]) {
      map[profile.type].push(profile)
    } else {
      map[profile.type] = [profile]
    }
    return map
  }, {})
  const groupedVisibleProfilesArray: PostDestination[] = []
  Object.keys(groupedVisibleProfiles).sort(sortNetworksDefault).forEach(network => {
    groupedVisibleProfilesArray.push(...groupedVisibleProfiles[network].sort(sortByKeyAlphabetically('name')))
  })

  const onProfileClick = (profile: PostDestination) => {
    if (props.onProfileClick) {
      props.onProfileClick(profile)
    }
    if (!props.multipleProfileSelection) {
      props.onSelectedProfilesChange([profile.id])
      return
    }
    const selection = [...props.selectedProfileIds]
    if (selection.includes(profile.id)) {
      if (selection.length > 1) {
        selection.splice(selection.indexOf(profile.id), 1)
      }
    } else {
      selection.push(profile.id)
    }
    props.onSelectedProfilesChange(selection)
  }

  const onProfileDoubleClick = (profile: PostDestination) => {
    props.onSelectedProfilesChange([profile.id])
  }

  const viewClass = styles['view-' + props.activeView]

  return (
    <div className={`${styles.container} ${viewClass} ${props.className || ''}`}>
      {!props.hideActions && (
        <ProfilesLayoutPicker
          selectedValue={activeView}
          withSearch={Object.keys(props.profiles).length >= MIN_SEARCHABLE_PROFILES_COUNT}
          className={props.actionsClassName}
          onFilter={setFilter}
          onChange={setActiveView}
        />
      )}
      <div className={`${styles.content} ${props.contentClassName || ''}`}>
        {activeView === 'network' && (
          <div className={`${styles['profiles-box']} ${styles.sorted}`}>
            {Object.keys(groupedVisibleProfiles).sort(sortNetworksDefault).map(network => {
              return (
                <React.Fragment key={network}>
                  <div className={styles['network-name']}>{network}</div>
                  <div className={styles.grid} key={network}>
                    {groupedVisibleProfiles[network]
                      .map(profile => props.renderProfileGridItem
                        ? props.renderProfileGridItem(profile)
                        : (
                          <ProfileListItem
                            key={profile.id}
                            grid
                            profile={profile}
                            selected={props.selectedProfileIds.includes(profile.id)}
                            radioSelect={!props.multipleProfileSelection}
                            onClick={onProfileClick}
                            onDoubleClick={onProfileDoubleClick}
                          />
                        ))}
                  </div>
                </React.Fragment>
              )
            })}
            {props.withAddProfileButton && !currentFilter && (
              <NavLink to="/settings/networks" className={`${styles['text-link']} ${styles['text-action']} ${styles.s}`}>
                + <FormattedMessage id="actions.add-profile" />
              </NavLink>
            )}
          </div>
        )}
        {activeView === 'list' && (
          <ul className={`${styles['profiles-list-box']} ${props.listViewClassName || ''}`}>
            {groupedVisibleProfilesArray.map(profile => {
              const isSelected = props.selectedProfileIds.includes(profile.id)
              return (
                <ProfileListItem
                  key={profile.id}
                  profile={profile}
                  selected={isSelected}
                  radioSelect={!props.multipleProfileSelection}
                  onClick={onProfileClick}
                  onDoubleClick={onProfileDoubleClick}
                  onDelete={props.onDelete}
                />
              )
            })}
            {props.withAddProfileButton && !currentFilter && (
              <li>
                <NavLink to="/settings/networks" className={`${styles['text-link']} ${styles['text-action']} ${styles.s}`}>
                  + <FormattedMessage id="actions.add-profile" />
                </NavLink>
              </li>
            )}
          </ul>
        )}
        {activeView === 'grid' && (
          <div className={styles['profiles-box']}>
            <div className={styles.grid}>
              {groupedVisibleProfilesArray.map(profile => {
                const isSelected = props.selectedProfileIds.includes(profile.id)
                return props.renderProfileGridItem
                  ? props.renderProfileGridItem(profile)
                  : (
                    <ProfileListItem
                      key={profile.id}
                      grid
                      profile={profile}
                      selected={isSelected}
                      radioSelect={!props.multipleProfileSelection}
                      onClick={onProfileClick}
                      onDoubleClick={onProfileDoubleClick}
                    />
                  )
              })}
              {props.withAddProfileButton && !currentFilter && (
                <NavLink to="/settings/networks" className={styles.link}>
                  <Tooltip title={<FormattedMessage id="actions.add-profile" />} placement="top">
                    <IconAdd className={styles['icon-add']} />
                  </Tooltip>
                </NavLink>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default injectIntl(SidebarProfileSelector)
