import * as React from 'react'
import {
  RangeFilter,
  ALL_RANGE_FILTER,
  MONTH_RANGE_FILTER,
  WEEK_RANGE_FILTER,
  NEW_RANGE_FILTER,
  YEAR_RANGE_FILTER
} from 'interfaces'
import { FormattedMessage } from 'react-intl'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import TimeIcon from '@mui/icons-material/Schedule'

const OPTIONS = [
  { value: NEW_RANGE_FILTER, label: 'new' },
  { value: WEEK_RANGE_FILTER, label: 'week' },
  { value: MONTH_RANGE_FILTER, label: 'month' },
  { value: YEAR_RANGE_FILTER, label: 'year' },
  { value: ALL_RANGE_FILTER, label: 'all' }
] as { value: RangeFilter, label: string }[]

export interface RangeFilterSelectProps {
  range: RangeFilter
  hideNew?: boolean
  onRangeSelected(range: RangeFilter): void
}

export function RangeFilterSelect(props: RangeFilterSelectProps) {
  const options = React.useMemo(() => props.hideNew ? OPTIONS.slice(1) : OPTIONS, [props.hideNew])
  const formattedOptions: PPSelectOptions = options.reduce((map: PPSelectOptions, o) => {
    map[o.value] = {
      label: <FormattedMessage id={`general.content.ranges.${o.label}`} />
    }
    return map
  }, {})

  return (
    <PPSelect
      options={formattedOptions}
      selectedValue={props.range}
      withCaret
      icon={<TimeIcon />}
      onSelectionChange={props.onRangeSelected}
    />
  )
}

export default RangeFilterSelect
