import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { loginWithFacebook } from 'services/auth/identities/facebook'
import { facebookSDKInitializedSelector } from 'services/external/selectors'
import { StoreThunkDispatch } from 'store/state'
import LoginButton from './LoginButton'
import styles from './LoginButton.pcss'

interface FBAllowPermissionsButtonProps {
  className?: string
  onClick?: () => void
}

export default function FBAllowPermissionsButton(props: FBAllowPermissionsButtonProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const intl = useIntl()
  const initialized = useSelector(facebookSDKInitializedSelector)

  const onLogin = () => {
    if (props.onClick) {
      props.onClick()
    }
    return dispatch(loginWithFacebook(false, { auth_type: 'rerequest' }))
  }

  return (
    <LoginButton
      initialized={initialized}
      buttonClassName={styles.reauth}
      progressClassName={styles.progress}
      displayInitialized={intl.formatMessage({ id: 'login.allow-email-perm' })}
      className={props.className}
      onLogin={onLogin}
    />
  )
}
