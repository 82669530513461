import { createElement, Component } from 'react'
import { Feed, Stream } from 'interfaces'
import SaveButton from 'components/SaveButton'
import FeedIcon from 'components/FeedIcon'

import styles from './FeedViewHeader.pcss'

export interface FeedViewHeaderProps {
  feed: Feed
  ownFeed: boolean
  streams: Stream[]
  allFeeds: Readonly<Feed>[]

  onSourceChange(sourceUrl: string): void
  onSave(feed: Feed): void
}

interface FeedViewHeaderState { }

export class FeedViewHeader extends Component<FeedViewHeaderProps, FeedViewHeaderState> {
  shouldComponentUpdate(nextProps: FeedViewHeaderProps) {
    return this.props.feed.id !== nextProps.feed.id
      || this.props.streams.length !== nextProps.streams.length
      || this.props.allFeeds.length !== nextProps.allFeeds.length
  }

  onSave = () => {
    if (this.props.onSave) {
      this.props.onSave(this.props.feed)
    }
  }

  render() {
    const feed = this.props.feed
    const name = feed.name

    return createElement('header', { className: styles.header }, [
      createElement(SaveButton, { key: 'save', onClick: this.onSave, className: styles['btn-save'] }),
      createElement(FeedIcon, { key: 'icon', className: styles.avatar as string, feed, showTooltip: true }),
      createElement('h2', { key: 'title', title: name, className: `${styles.title} text-ellipsis` }, name)
    ])
  }
}

export default FeedViewHeader
