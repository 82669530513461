import { RequestBuilder, authorizedPipe } from 'services/net'
import { CONTENT_SERVICE_URL } from 'config'
import { ARTICLE_TYPE, ContentType, LegacyFeed, LegacyFolder, VIDEO_TYPE } from 'interfaces'

export const ENDPOINT_SEARCH = `${CONTENT_SERVICE_URL}/search/algolia`
export const ENDPOINT_SEARCH_FB_LIVE = `${CONTENT_SERVICE_URL}/search/live/facebook`
export const ENDPOINT_SEARCH_TWITTER_LIVE = `${CONTENT_SERVICE_URL}/search/live/twitter`
export const ENDPOINT_SEARCH_LINKEDIN_LIVE = `${CONTENT_SERVICE_URL}/search/live/linkedin`
export const ENDPOINT_SEARCH_REDDIT_LIVE = `${CONTENT_SERVICE_URL}/search/live/reddit`
export const ENDPOINT_SEARCH_STOCK_CONTENT = `${CONTENT_SERVICE_URL}/search/keyword`

export interface SearchResponse {
  feeds: {
    [key: string]: LegacyFeed[]
  },
  filters: {
    [network: string]: ContentType[]
  }
  streams: LegacyFolder[],
  byId: LegacyFeed | LegacyFolder
}

export const V1 = Object.freeze({
  search: function () {

    const builder = new RequestBuilder(ENDPOINT_SEARCH).asPost().expectJSON().asFormUrlEncoded().withoutCredentials()

    return function (needle: string, scopes: string[], page: number, pageSize: number, feedId?: string, streamId?: string) {
      const data = {
        needle,
        scopes: JSON.stringify(scopes),
        page,
        page_size: pageSize,
        feed_id: feedId || '',
        stream_id: streamId || ''
      }

      return authorizedPipe<SearchResponse>(
        builder
          .body(data)
          .build()
      )
    }
  }(),
  fbLiveSearch: function () {
    const builder = new RequestBuilder(ENDPOINT_SEARCH_FB_LIVE).asGet().expectJSON().asFormUrlEncoded().withoutCredentials()

    return function (needle: string) {
      return authorizedPipe<SearchResponse>(
        builder
          .param('needle', needle)
          .build()
      )
    }
  }(),
  twitterLiveSearch: function () {
    const builder = new RequestBuilder(ENDPOINT_SEARCH_TWITTER_LIVE).asGet().expectJSON().asFormUrlEncoded().withoutCredentials()

    return function (needle: string) {
      return authorizedPipe<SearchResponse>(
        builder
          .param('needle', needle)
          .build()
      )
    }
  }(),
  linkedInLiveSearch: function () {
    const builder = new RequestBuilder(ENDPOINT_SEARCH_LINKEDIN_LIVE).asGet().expectJSON().asFormUrlEncoded().withoutCredentials()

    return function (needle: string) {
      return authorizedPipe<SearchResponse>(
        builder
          .param('needle', needle)
          .build()
      )
    }
  }(),
  redditLiveSearch: function () {
    const builder = new RequestBuilder(ENDPOINT_SEARCH_REDDIT_LIVE).asGet().expectJSON().asFormUrlEncoded().withoutCredentials()

    return function (needle: string) {
      return authorizedPipe<SearchResponse>(
        builder
          .param('needle', needle)
          .build()
      )
    }
  }(),
  searchStockContent: function () {
    const builder = new RequestBuilder(ENDPOINT_SEARCH_STOCK_CONTENT).asGet().expectJSON().withoutCredentials()

    return function (query: string, type: string, filter: string = '', sortBy?: string) {
      const source = type === ARTICLE_TYPE || type === VIDEO_TYPE ? 'newsapi' : filter
      return authorizedPipe<any>(
        builder
          .param('keyword', query)
          .param('content_type', type)
          .param('stock_filter', filter)
          .param('provider', source)
          .param('sort_by', sortBy || '')
          .build()
      )
    }
  }()
})
