import * as React from 'react'
import { Bucket, WithIntl } from 'interfaces'
import styles from './EditableBucket.pcss'
import ColorSelector from 'components/ColorSelector'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { FormattedMessage, injectIntl, useIntl } from 'react-intl'
import { defaultBucketColor } from 'utils/colors'
import { mdiPail } from '@mdi/js'
import Icon from '@mdi/react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { getDefaultBuckets } from 'services/post/actions'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Paper from '@mui/material/Paper'

interface EditableBucketProps {
  bucket: Partial<Bucket>
  confirmActionLabel?: React.ReactNode
  loading?: boolean
  className?: string
  onSubmit?: (bucket: Partial<Bucket>) => void
  onChange?: (bucket: Partial<Bucket>) => void
  onCancel?: () => void
}

export function EditableBucket(props: EditableBucketProps & WithIntl) {
  const intl = useIntl()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [name, setName] = React.useState(props.bucket.name || '')
  const [description, setDescription] = React.useState(props.bucket.description || '')
  const [color, setColor] = React.useState(props.bucket.color || defaultBucketColor)
  const [isPublic, setIsPublic] = React.useState(props.bucket.isPublic || false)
  const [defaultBuckets, setDefaultBuckets] = React.useState<Array<{ name: string, description: string, color: string }>>([])

  React.useEffect(() => {
    dispatch(getDefaultBuckets())
      .unwrap()
      .then((data: any) => {
        setDefaultBuckets(data)
      })
  }, [dispatch])

  React.useEffect(() => {
    if (props.onChange) {
      props.onChange({ name, color, description, isPublic })
    }
  }, [props.onChange, name, color, isPublic, description])

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setDescription(value)
  }

  const onChange = (name: string, description?: string, color?: string) => {
    setName(name)
    if (description) {
      setDescription(description)
    }
    if (color) {
      setColor(color)
    }
  }

  const toggleIsPublic = () => {
    setIsPublic(current => !current)
  }

  const onSubmit = () => {
    if (props.onSubmit) {
      props.onSubmit({
        name,
        color,
        isPublic
      })
    }
  }

  return (
    <div className={`${styles.wrapper} ${props.className || ''}`}>
      <div className={styles.top}>
        <div className={styles.left}>
          <BucketNameInput value={name} disabled={props.loading} suggested={defaultBuckets} onChange={onChange} />
          <TextField
            classes={{ root: `${styles.input} ${styles.description}` }}
            placeholder={intl.formatMessage({ id: 'post.buckets.form.description-placeholder' })}
            value={description}
            onChange={onDescriptionChange}
          />
        </div>
        <div className={styles.right}>
          {/* <TextField
          classes={{ root: styles.input }}
          placeholder={props.intl.formatMessage({ id: 'post.buckets.form.name-placeholder' })}
          value={name}
          onChange={onNameChange}
          disabled={props.loading}
        /> */}
          <ColorSelector
            solidColors
            className={styles['color-selector']}
            initialColor={color}
            onSelectionChanged={setColor as any}
          />
        </div>
      </div>
      {props.onCancel || props.onSubmit ? (
        <div className={styles.actions}>
          {/* <FormControlLabel
                    label={
                      <span className={styles['cb-label']}>
                        <FormattedMessage id="post.buckets.form.label-public" />
                      </span>
                    }
                    classes={{ root: styles['cb-public-box'] }}
                    control={
                      <Checkbox
                        color="primary"
                        classes={{ root: styles['cb-private'] }}
                        onChange={toggleIsPublic}
                        checked={isPublic}
                      />
                    }
                  /> */}
          {props.onCancel && (
            <Button variant="text" onClick={props.onCancel}>
              <FormattedMessage id="actions.cancel" />
            </Button>
          )}
          {props.onSubmit && (
            <Button
              variant="contained"
              color="primary"
              disabled={name.trim().length === 0 || props.loading}
              className={styles['btn-save']}
              onClick={onSubmit}
            >
              {props.confirmActionLabel || (
                <FormattedMessage id="actions.save" />
              )}
            </Button>
          )}
        </div>
      ) : null}
    </div>
  )
}

interface BucketNameInputProps {
  value: string
  suggested: Array<{ name: string, description: string, color: string }>
  disabled?: boolean
  onChange: (name: string, description?: string, color?: string) => void
}

function BucketNameInput({ value, disabled, suggested, onChange }: BucketNameInputProps) {
  const intl = useIntl()
  const containerRef = React.useRef<HTMLDivElement | null>(null)
  const [dropdownAnchor, setDropdownAnchor] = React.useState<HTMLElement | null>(null)

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  const onClose = () => {
    setDropdownAnchor(null)
  }

  const openDropdown = () => {
    if (containerRef.current && value.trim().length === 0) {
      setDropdownAnchor(containerRef.current)
    }
  }

  const onSuggestionClick = (name: string, description: string, color: string) => {
    onChange(name, description, color)
    setDropdownAnchor(null)
  }

  return (
    <div ref={containerRef}>
      <TextField
        classes={{ root: styles.input }}
        placeholder={intl.formatMessage({ id: 'post.buckets.form.name-placeholder' })}
        value={value}
        disabled={disabled}
        onChange={onNameChange}
        onClick={openDropdown}
      />
      <Popper
        open={Boolean(dropdownAnchor)}
        anchorEl={dropdownAnchor}
        className={styles.menu}
        placement="bottom"
        // classes={{ root: styles.menu, paper: styles['menu-list'] }}
      >
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={onClose}>
          <Paper className={styles['menu-list']}>
            <ul>
              {suggested.map(b => (
                <BucketListItem
                  key={b.name}
                  name={b.name}
                  description={b.description}
                  color={b.color}
                  onClick={onSuggestionClick}
                />
              ))}
            </ul>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

type BucketListItemProps = {
  name: string,
  description: string,
  color: string,
  onClick: (name: string, description: string, color: string) => void
}
function BucketListItem({ name, description, color, onClick }: BucketListItemProps) {
  const handleClick = () => {
    onClick(name, description, color)
  }

  return (
    <MenuItem className={styles.li} onClick={handleClick}>
      <Icon className={styles.icon} path={mdiPail} color={color} size="16px" />
      <span className="text-ellipsis">{name}</span>
    </MenuItem>
  )
}

export default injectIntl(EditableBucket)
