import { IndexedObject, MyFile } from 'interfaces'
import {
  STOCK_CONTENT_PROVIDERS_ALL,
  STOCK_CONTENT_PROVIDER_PEXELS,
  STOCK_CONTENT_PROVIDER_PIXABAY,
  STOCK_CONTENT_PROVIDER_SHUTTERSTOCK,
  STOCK_CONTENT_PROVIDER_UNSPLASH
} from 'interfaces/Content/StockContentProvider'
import { SelectableContent } from './state'

export * from './adapters/articles'
export * from './adapters/feeds'
export * from './adapters/photos'
export * from './adapters/statuses'
export * from './adapters/streams'
export * from './adapters/videos'
export * from './cache/utils'
export { instanceOfFeed, instanceOfFileFolder, instanceOfStream } from './adapters/recommended'

export enum SortBy {
  Engagement = 'e',
  Likes = 'l',
  Date = 'r',
  Shares = 's'
}

export const STOCK_CONTENT_PROVIDER_PARAMS_MAP: IndexedObject<IndexedObject<string>> = {
  videos: {
    [STOCK_CONTENT_PROVIDER_PIXABAY]: 'pixabay_video',
    [STOCK_CONTENT_PROVIDER_SHUTTERSTOCK]: 'shutterstock_video',
    [STOCK_CONTENT_PROVIDER_PEXELS]: 'pexels_video',
    [STOCK_CONTENT_PROVIDERS_ALL]: 'all',
    google: 'serpsbot_video'
  },
  photos: {
    [STOCK_CONTENT_PROVIDER_PIXABAY]: 'pixabay_image',
    [STOCK_CONTENT_PROVIDER_PEXELS]: 'pexels_image',
    [STOCK_CONTENT_PROVIDER_UNSPLASH]: 'unsplash_image',
    [STOCK_CONTENT_PROVIDER_SHUTTERSTOCK]: 'shutterstock_image',
    [STOCK_CONTENT_PROVIDERS_ALL]: 'all'
  },
  articles: {
    google: 'newsapi_article'
  }
}

export function instanceOfMyFile(item: SelectableContent): item is MyFile {
  return 'folderId' in item
}
