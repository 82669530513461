import { LEGACY_URL } from 'config'
import { authorizedPipe, RequestBuilder } from 'services/net'

const ENDPOINT_TRACK_EVENT = `${LEGACY_URL}/v3/trial_events/add`
const ENDPOINT_GET_EVENT_GROUPS = `${LEGACY_URL}/v3/trial_events/groups`
const ENDPOINT_GET_EVENTS = `${LEGACY_URL}/v3/trial_events/all`
const ENDPOINT_GET_COUPON_CODE = `${LEGACY_URL}/v3/trial_events/discount_code`

export function trackEvent(eventCode: string, value?: number) {
  const builder = new RequestBuilder(ENDPOINT_TRACK_EVENT).asPost().expectJSON()
  return authorizedPipe<any>(
    builder
      .body({ code: eventCode, quantity: value || 1 })
      .build()
  )
}

export function getTrialEventGroups() {
  const builder = new RequestBuilder(ENDPOINT_GET_EVENT_GROUPS).asGet().expectJSON().asFormUrlEncoded()
  return authorizedPipe<any>(
    builder.build()
  )
}

export function getTrialEvents() {
  const builder = new RequestBuilder(ENDPOINT_GET_EVENTS).asGet().expectJSON().asFormUrlEncoded()
  return authorizedPipe<any>(
    builder.build()
  )
}

export function getCouponCode() {
  const builder = new RequestBuilder(ENDPOINT_GET_COUPON_CODE).asGet().expectJSON().asFormUrlEncoded()
  return authorizedPipe<any>(
    builder.build()
  )
}

export function getTrainingVideoSections() {
  const builder = new RequestBuilder(`${LEGACY_URL}/sections/get_sections`).asGet().expectJSON().asFormUrlEncoded()
  return authorizedPipe<any>(
    builder.build()
  )
}

export function getTrainingVideos(sectionId?: string) {
  const builder = new RequestBuilder(`${LEGACY_URL}/sections/get_items`).asGet().expectJSON().asFormUrlEncoded()
  if (sectionId) {
    builder.param('section_id', sectionId || '')
  }
  return authorizedPipe<any>(
    builder.build()
  )
}

export function updateProfile(firstName?: string, lastName?: string, introProgress?: number, email?: string) {
  const builder = new RequestBuilder(`${LEGACY_URL}/settings/profile/update`).asPost().expectJSON()
  const data: any = {}

  if (firstName) {
    data.first_name = firstName
  }
  if (lastName) {
    data.last_name = lastName
  }
  if (introProgress !== undefined) {
    data.finished_setup = introProgress
  }
  if (email) {
    data.email = email
  }

  return authorizedPipe<any>(
    builder
      .bodyJSONstringify(data)
      .build()
  )
}
