import * as React from 'react'
import { FileFolder, Feed, Stream, FEED_TYPE_STATUS_IDEAS } from 'interfaces'
import ScrollListener from 'components/ScrollListener'
import { instanceOfFeed, instanceOfStream, instanceOfFileFolder } from 'services/content/util'
import { ConnectedFileFolderCarousel } from 'routes/find/routes/my-uploads/components/FileFolderCarousel'
import ContentLayout from 'components/ContentLayout'
import STATUSES_CATEGORIES from 'routes/find/routes/statuses/statuses.config'
import StatusIdeasCategoryListView from 'components/CuratedFolderView/StatusIdeasCategoryListView'
import Loader from 'components/SimpleLoader'
import { NavLink } from 'react-router-dom'
import { URL_SEGMENT_STREAM, URL_SEGMENT_FEED, URL_SEGMENT_STATUS, URL_SEGMENT_FILE_FOLDER } from 'config'

import styles from '../../../../components/HomeSection.pcss'
import { QuoteFeed } from 'interfaces/Content/QuoteFeed'
import QuotesFeedView from 'components/QuotesFeedView'

export interface RecentlyViewedRootViewProps {
  sources: Array<Feed | Stream | FileFolder | QuoteFeed>
  loading: boolean
  baseUrl: string
  scrollElement: HTMLElement
  onScrollEndReached: () => void
}

const SCROLL_EMIT_TRESHOLD = 300

export const RecentlyViewedRootView = React.memo(function (props: RecentlyViewedRootViewProps) {
  const renderSource = (source: Feed | Stream | FileFolder | QuoteFeed) => {
    const isFeed = instanceOfFeed(source as any)
    const isStream = instanceOfStream(source as any)
    const isStatusIdeasFeed = isFeed && (source as Feed).type === FEED_TYPE_STATUS_IDEAS

    if (isFeed && !isStatusIdeasFeed) {
      const sourceUrl = `${props.baseUrl}/${URL_SEGMENT_FEED}/${(source as Feed).uniqueSource}`

      return (
        <ContentLayout
          key={`${(source as any).type}-${source.id}`}
          expanded={false}
          browseUrl={sourceUrl}
          source={source as any}
        />
      )
    }

    if (isStream) {
      const sourceUrl = `${props.baseUrl}/${URL_SEGMENT_STREAM}/${source.id}`

      return (
        <ContentLayout
          key={`${(source as any).type}-${source.id}`}
          expanded={false}
          browseUrl={sourceUrl}
          source={source as any}
        />
      )
    }

    if (isStatusIdeasFeed) {
      const folder = STATUSES_CATEGORIES.find(cat => cat.slug === source.id)

      if (folder) {
        return (
          <section className={styles.container} key={`statuses-${source.id}`}>
            <header className={styles.header}>
              <h2 className={`${styles.title} text-ellipsis`}>
                {(source as any).name || (source as any).title}
              </h2>
              <NavLink to={`${props.baseUrl}/${URL_SEGMENT_STATUS}/${source.id}`} className={`${styles.navigation} text-ellipsis`}>
                {(source as any).name || (source as any).title}
              </NavLink>
            </header>
            <StatusIdeasCategoryListView folder={folder} />
          </section>
        )
      }
    }

    if (instanceOfFileFolder(source as any)) {
      return (
        <ConnectedFileFolderCarousel
          folder={source as FileFolder}
          navPrefix={`${props.baseUrl}/${URL_SEGMENT_FILE_FOLDER}`}
          key={`folder-${source.id}`}
        />
      )
    }

    if (source.isQuoteFeed) {
      return (
        <QuotesFeedView feed={source as QuoteFeed} />
      )
    }

    return null
  }

  return (
    <ScrollListener
      emitTreshold={SCROLL_EMIT_TRESHOLD}
      scrollElement={props.scrollElement}
      emitInitial
      onScroll={props.onScrollEndReached}
    >
      <div>
        {props.sources.map(renderSource)}
        {props.loading && <Loader title="Loading sources..." />}
      </div>
    </ScrollListener>
  )
})

export default RecentlyViewedRootView
