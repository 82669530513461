import AuthState, { initialState } from './state'
import {
  ACTION_CHECK_TOKENS_SUCCESS,
  ACTION_LOGIN_ERROR,
  ACTION_SKIP_TOKENS_REFRESH,
  ACTION_UPDATE_FB_LOGIN_STATUS,
  CHECK_TOKENS_INTERVAL_DEFAULT,
  LOGOUT
} from './actions'
import { ACTION_CLEAR_ERRORS } from './login/actions'
import { differenceInMilliseconds } from 'date-fns'

export function authReducer(state = initialState(), action: any): AuthState {
  const { type, payload } = action

  switch (type) {
    case ACTION_CHECK_TOKENS_SUCCESS:
      const expiredProfilesCount: number = Object.values(payload).reduce((count: number, current: any[]) => {
        count += current.length
        return count
      }, 0) as number

      const isRefreshRequired = expiredProfilesCount > 0
      const refreshRequiredNext = isRefreshRequired ? payload : undefined
      const now = new Date()
      const timeSinceDialogDismissed = state.tokens.dialogDismissedAt
        ? differenceInMilliseconds(now, state.tokens.dialogDismissedAt || now)
        : 0
      // Open dialog if "CHECK_TOKENS_INTERVAL_DEFAULT" time have passed since closed.
      const dialogDismissed = timeSinceDialogDismissed > CHECK_TOKENS_INTERVAL_DEFAULT ? false : state.tokens.dialogDismissed

      return {
        ...state,
        tokens: {
          ...state.tokens,
          lastCheck: new Date().toISOString(),
          refreshRequired: refreshRequiredNext,
          dialogDismissed
        }
      }

    case ACTION_SKIP_TOKENS_REFRESH:
      const dismiss = action.payload.dismissDialog
      return {
        ...state,
        tokens: {
          ...state.tokens,
          dialogDismissed: dismiss,
          dialogDismissedAt: dismiss ? new Date().toISOString() : state.tokens.dialogDismissedAt,
          lastCheck: new Date().toISOString()
        }
      }

    case ACTION_UPDATE_FB_LOGIN_STATUS:
      return {
        ...state,
        facebookLoginStatus: action.payload
      }

    case ACTION_LOGIN_ERROR:
      return {
        ...state,
        error: action.payload
      }

    case ACTION_CLEAR_ERRORS:
      return {
        ...state,
        error: {
          ...state.error,
          restrictedAccess: false
        }
      }

    case LOGOUT:
      return initialState()

    default:
      return state
  }
}

export default authReducer
