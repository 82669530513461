import { LEGACY_URL } from 'config'
import { authorizedPipe, RequestBuilder } from 'services/net'
import { StoreThunkDispatch } from 'store/state'

const ENDPOINT_TRACK_HS_EVENT = `${LEGACY_URL}/v3/events/add`

export function trackHubspotEvent(event: string, props?: any) {
  return (dispatch: StoreThunkDispatch) => {
    const builder = new RequestBuilder(ENDPOINT_TRACK_HS_EVENT).asPost().expectJSON()
    dispatch(authorizedPipe(builder.bodyJSONstringify({ event, data: props }).build())).toPromise()
  }
}
