import { AjaxRequest } from 'rxjs/observable/dom/AjaxObservable'

export type Tags = { [tag: string]: any }
export class ApiException extends Error {

  public readonly tags: Tags | undefined

  constructor(message: string, tags?: Tags) {
    super(message)

    this.tags = tags

    Object.setPrototypeOf(this, ApiException.prototype)
  }

  get xhr() {
    return this.tags && this.tags.xhr as XMLHttpRequest
  }

  get request() {
    return this.tags && this.tags.request as AjaxRequest
  }
}

export default ApiException
