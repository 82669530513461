import { REHYDRATE } from 'redux-persist'

function initialState() {
  return false
}

export function hydrationReducer(state = initialState(), action: any) {
  if (action.type === REHYDRATE) {
    return true
  }

  return state
}

export default hydrationReducer
