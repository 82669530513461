import { createElement, Component, createRef, RefObject } from 'react'
import { findDOMNode } from 'react-dom'
import { connect } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'

import { Stream, PostDestination, ContentItem, RangeFilter } from 'interfaces'
import { ScrollListener } from 'components/ScrollListener'
import { createStreamTypesMap, StreamsContentState } from 'utils/content'
import ContentLayout from 'components/ContentLayout'
import { getStreamsContent } from 'services/content/streams-content/actions'

import { Observable } from 'rxjs/Observable'
import { Subscription } from 'rxjs/Subscription'
import { Subject } from 'rxjs/Subject'
import { Observer } from 'rxjs/Observer'
import 'rxjs/add/operator/debounceTime'

const isEqual = require('react-fast-compare')
const BATCH_SIZE_DEFAULT = 2

export interface StreamsLoaderProps {
  streams: Stream[]
  browseUrlBase: string
  prettyPaths?: boolean
  // dash-separated keys of stream, used to create the stream url. Example: 'slug-id'. Keys will be replaced with values for each stream
  streamUrlPattern?: string
  batchSize?: number
  hideNavLink?: boolean
  destinations?: { [key: string]: Readonly<PostDestination> }
  // Load only streams data and leave content loading to child components
  streamsOnly?: boolean
  onContentItemClick?(content: ContentItem): void
  onContentItemCompose?(content: ContentItem): void
}

interface ConnectedStreamsLoaderProps extends StreamsLoaderProps {
  getStreamsContent(streams: Stream[]): Promise<StreamsContentState[]>
}

interface StreamsLoaderState {
  loading: boolean
  content: { [key: string]: StreamsContentState }
  page: number
  hasNext: boolean
}

export class StreamsLoader extends Component<ConnectedStreamsLoaderProps, StreamsLoaderState> {

  // eslint-disable-next-line react/static-property-placement
  static defaultProps: Partial<ConnectedStreamsLoaderProps> = {
    streamsOnly: true
  }

  private scrollListener: RefObject<ScrollListener>
  private scrollElement: HTMLDivElement
  private fetchContent$: Subscription
  private unmount$: Subject<boolean>

  get batchSize() {
    return this.props.batchSize || BATCH_SIZE_DEFAULT
  }

  constructor(props: ConnectedStreamsLoaderProps) {
    super(props)

    const content: { [key: string]: StreamsContentState } = {}
    this.props.streams.forEach(stream => {
      content[stream.id] = {
        stream,
        items: [],
        loading: false,
        loaded: false
      }
    })

    this.state = {
      loading: false,
      content,
      page: 0,
      hasNext: true
    }
    this.scrollListener = createRef<ScrollListener>()
    this.unmount$ = new Subject()

    this.fetchContentNextPage = this.fetchContentNextPage.bind(this)
    this.onContentFetched = this.onContentFetched.bind(this)
  }

  shouldComponentUpdate(nextProps: ConnectedStreamsLoaderProps, nextState: StreamsLoaderState) {
    return !isEqual(this.state, nextState) || !isEqual(this.props, nextProps)
  }

  componentDidUpdate(prevProps: ConnectedStreamsLoaderProps) {
    if (!isEqual(this.props.streams, prevProps.streams)) {
      this.fetchContentNextPage()
    }
  }

  componentDidMount() {
    this.scrollElement = document.getElementsByTagName('main')[0] as HTMLDivElement
    this.fetchContentNextPage()
  }

  componentWillUnmount() {
    this.unmount$.next(true)
    if (this.fetchContent$) {
      this.fetchContent$.unsubscribe()
    }
  }

  fetchContentNextPage() {
    if (this.state.loading || !this.state.hasNext) {
      return
    }

    const contentFetchDebounce = 100
    const streamsUpdateDebounce = 500
    const startIndex = this.state.page * this.batchSize
    const nextStreams = this.props.streams.slice(startIndex, startIndex + this.batchSize)
    const nextStreamIds = nextStreams.map(s => s.id)
    const content = { ...this.state.content }

    Object.keys(this.state.content)
      .filter(key => nextStreamIds.indexOf(key) !== -1)
      .forEach(id => { content[id] = { ...this.state.content[id], loading: true } })

    if (this.props.streams.length !== 0 && nextStreams.length === 0) {
      this.setState(prevState => ({ ...prevState, hasNext: false }))
    }

    if (nextStreams.length === 0) {
      return
    }

    this.setState(prevState => ({ ...prevState, loading: true, content }))

    if (this.props.streamsOnly) {
      this.fetchContent$ = Observable.create((observer: Observer<any[]>) => observer.next(
        nextStreams.map(stream => ({
          stream,
          items: [],
          loading: false,
          loaded: true
        }))
      ))
        .debounceTime(streamsUpdateDebounce)
        .subscribe(this.onContentFetched)
      return
    }

    this.fetchContent$ = Observable.fromPromise(this.props.getStreamsContent(nextStreams))
      .debounceTime(contentFetchDebounce)
      .takeUntil(this.unmount$.asObservable())
      .subscribe(this.onContentFetched)
  }

  onContentFetched(streamsContent: StreamsContentState[]) {
    const content = { ...this.state.content }
    const hasNext = streamsContent.length === this.batchSize

    streamsContent.forEach(c => {
      content[c.stream.id] = c
    })

    this.setState(prevState => ({
      ...prevState,
      content,
      loading: false,
      page: prevState.page + 1,
      hasNext
    }))

    const scrollListener = this.scrollListener.current
    const hostElement = findDOMNode(this) as HTMLElement

    if (hasNext && scrollListener && hostElement && (
      // rows does not fill the screen
      hostElement.clientHeight < this.scrollElement.clientHeight
      // scroll is at the bottom of the container
      || this.scrollElement.scrollHeight - this.scrollElement.scrollTop === this.scrollElement.clientHeight
    )) {
      scrollListener.emitScroll()
    }
  }

  render() {
    const { streams, browseUrlBase, prettyPaths, streamsOnly, hideNavLink } = this.props

    return createElement(ScrollListener, {
      emitTreshold: 350,
      scrollElement: this.scrollElement,
      onScroll: this.fetchContentNextPage,
      ref: this.scrollListener
    },
    createElement('div', undefined,
      streams.map(stream => this.state.content[stream.id])
        .filter(Boolean)
        .filter(content => content.loaded || (!content.loaded && content.loading))
        .map(content => {
          let browseUrl = prettyPaths ? `${browseUrlBase}/${content.stream.slug}` : `${browseUrlBase}/${content.stream.id}`
          const loading = streamsOnly ? undefined : content.loading
          const items = streamsOnly ? undefined : content.items
          if (this.props.streamUrlPattern) {
            const streamUrl = this.props.streamUrlPattern.split('-')
              .map(prop => (content.stream as any)[prop] || '')
              .filter(Boolean)
              .join('-')
            browseUrl = `${browseUrlBase}/${streamUrl}`
          }

          return createElement(ContentLayout, {
            key: `stream-${content.stream.id}`,
            source: content.stream,
            loading,
            items,
            hideNavLink,
            destinations: this.props.destinations,
            browseUrl,
            onContentItemClick: this.props.onContentItemClick,
            onContentItemCompose: this.props.onContentItemCompose
          })
        })
    )
    )
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    getStreamsContent: (streams: Stream[]) => {
      const streamsContentMap = createStreamTypesMap(streams)
      return dispatch(getStreamsContent(streamsContentMap)).unwrap()
        .then((response: any) => {
          return response.map((data: any) => ({
            stream: streams.find(s => s.id === data.id),
            items: data.content,
            loading: false,
            loaded: true
          }))
        })
    }
  }
}

export default connect(undefined, mapDispatchToProps)(StreamsLoader)
