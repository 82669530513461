import { createSlice } from '@reduxjs/toolkit'
import { List } from 'interfaces'
import * as actions from './actions'

export const LISTS_STATE_VERSION = 'lists:1'

export function initialState(): ListsState {
  return Object.freeze({
    lists: Object.freeze({})
  })
}

export interface ListsState {
  lists: Readonly<{ [id: string]: Readonly<List> }>
}

const listsSlice = createSlice({
  name: 'lists',
  initialState: initialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.getLists.fulfilled, (state, action) => {
      state.lists = action.payload.reduce(
        (result, list) => {
          result[list.id] = Object.freeze({ ...list })
          return result
        }, {} as { [key: string]: Readonly<List> }
      )
    })

    builder.addCase(actions.updateList.fulfilled, (state, action) => {
      const list = Object.assign({}, state.lists[action.payload.id], action.payload)
      state.lists[list.id] = list
    })

    builder.addCase(actions.deleteList.fulfilled, (state, action) => {
      const lists = Object.assign({}, state.lists) as any
      delete lists[action.payload]
      state.lists = lists
    })
  }
})

const { reducer } = listsSlice
export { reducer as listsReducer }
export default ListsState
