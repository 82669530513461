import * as React from 'react'
import { FEED_TYPE_FACEBOOK, FEED_TYPE_TWITTER, Video, VIDEO_TYPE } from 'interfaces'
import styles from './ComposerVideoContentPreview.pcss'
import { PlayIcon } from 'components/Icons'
import { NativeVideoPlayer } from 'components/VideoPlayer'
import { EmbeddedFBPost } from 'components/EmbeddedFBPost'
import { isFacebookLink } from 'shared'

interface ComposerVideoContentPreviewProps {
  video: Video
  className?: string
}

export function ComposerVideoContentPreview(props: ComposerVideoContentPreviewProps) {
  const { video, className } = props
  const [previewError, setPreviewError] = React.useState(false)
  const isFacebookVideo = video.type === VIDEO_TYPE && video.feed.type === FEED_TYPE_FACEBOOK && isFacebookLink(video.videoUrl)
  const isTwitterGif = video.feed.type === FEED_TYPE_TWITTER && Boolean((video as any).gifUrl)

  const detailsElement = video.title ? (
    <div className={styles.details}>
      <h3 className={styles.title}>
        <a href={video.shortLink || video.socialLink} rel="noopener" target="_blank">{video.title}</a>
      </h3>
    </div>
  ) : null

  return (
    <div className={className || ''} data-testid="composer-video-content-preview">
      {isFacebookVideo && (
        <div className={styles['fb-video-box']}>
          <EmbeddedFBPost url={video.videoUrl} />
        </div>
      )}
      {!isFacebookVideo && !isTwitterGif && (
        <div className={`${styles.video} ${previewError ? styles.error : ''}`}>
          <PlayIcon className={styles.icon} />
          <a href={video.socialLink} rel="noopener" target="_blank" className={styles.link}>
            <img src={video.imageUrl} onError={() => setPreviewError(true)} />
          </a>
          {previewError && <div className={styles['error-msg']}>Preview not available</div>}
          {detailsElement}
        </div>
      )}
      {isTwitterGif && (
        <NativeVideoPlayer
          backgroundImage={video.imageUrl}
          loop
          videoUrl={(video as any).gifUrl}
          className={styles['native-player']}
        />
      )}
    </div>
  )
}
