import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { findDOMNode } from 'react-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import classnames from 'classnames'

// Interfaces
import {
  ContentItem,
  PHOTO_TYPE,
  VIDEO_TYPE,
  Article,
  LiveArticleData,
  Video,
  Photo,
  Gif,
  AnyContent,
  ARTICLE_TYPE,
  GIF_TYPE,
  FEED_TYPE_FACEBOOK,
  WithIntl,
  FEED_TYPE_LINKEDIN,
  FEED_TYPE_KEYWORD
} from 'interfaces'
import { TEXT_TYPE } from 'interfaces/Content/ContentType'
import { STOCK_GIF_TYPE, STOCK_PHOTO_TYPE, STOCK_VIDEO_TYPE } from 'interfaces/Content/StockContentTypes'

// Components
import Paper from '@mui/material/Paper'
import BrokenImageIcon from '@mui/icons-material/BrokenImage'
import FetchArticleData from 'components/Fetch/FetchArticleData'
import ContentCardMedia from './ContentCardMedia'
import ContentCardFooter from './ContentCardFooter'
import FavButton from './FavButton'
import ProgressMedia from './ProgressMedia'
import ContentCardUpgradeOverlay from './ContentCardUpgradeOverlay'

// Services
import StoreState, { StoreThunkDispatch } from 'store/state'
import { openContentPreviewWindow } from 'services/contentPreview'
import { addFavorite, removeFavorite, addFavoriteLive } from 'services/content/favorites'
import {
  checkFeatureAvailability,
  contentTypeFeatureMap,
  feedTypeFeatureMap,
  getContentAgeFeature,
  getProductUpgradeByFeature,
  promptUpgrade
} from 'services/product'
import { FEATURE_FIND_FAVORITES } from 'shared/constants'
import { favStateSelectorForItem, selectedContentItemsSelector } from 'services/content/selectors'

// Utils
import { Observable } from 'rxjs/Observable'
import { debounce } from 'shared/utils'
import { fromNow } from 'utils/format/date'

// Styles
import styles from './ContentCard.pcss'
import { setComposerContent, setComposerFile, promptCompose } from 'services/compose'
import SocialIcon from 'components/SocialIcon'
import Tooltip from '@mui/material/Tooltip'
import { injectIntl } from 'react-intl'
import ContentCardActions, { ContentCardAction } from './ContentCardActions'
import { composerImageUrlsSelector, multiPostsArraySelector } from 'services/compose/selectors'
import CardStatusText from './CardStatusText'
import { addContentToSelection, removeContentFromSelection } from 'services/content'
import { format } from 'date-fns'

const FREE_FEED_TYPES = [FEED_TYPE_FACEBOOK, FEED_TYPE_LINKEDIN]

// Constants
const RESIZE_TIMEOUT = 250
const DEFAULT_ASPECT_RATIO = 0.5625
const DEFAULT_BASE_WIDTH = 264
const DEFAULT_BASE_WIDTH_SMALL = 232

export const CONTENT_TRANSITION_NAME = {
  enterActive: styles.loading,
  appearActive: styles.loading,
  leaveActive: styles.loading,
  enter: styles.in,
  appear: styles.in,
  leave: styles.out
}

export interface ContentCardProps {
  content: ContentItem
  studioButtonIcon?: React.ReactNode
  square?: boolean
  className?: string
  width?: number
  small?: boolean
  skipFeatureChecks?: boolean
  actionLabel?: React.ReactNode
  hideDetails?: boolean
  actions?: ContentCardAction[]

  onClick?(content: ContentItem): void
  onCompose?(content: ContentItem, aiWriter: boolean): void | boolean
  onSourceClick?(content: ContentItem, by: 'image' | 'title' | 'goto' | 'domain'): void
  onImageLoadingFailed?(content: ContentItem): void
  onSizeChanged?(self: HTMLDivElement): void
  onLiveArticleDataFetched?(itemId: string, data: LiveArticleData): void
}

interface ContentCardState {
  loading: boolean
  overlay: boolean
  aspectHeight: number
  aspectRatio: number

  liveArticleData?: LiveArticleData
}

type HasImage = ContentItem & { imageUrl: string }

/*
Image - takes to original social media post, new tab
Title - takes to original social media post, new tab
Go to icon - takes to original social media post, new tab
Domain - takes to article in the blog, new tab
Social Feed Name - opens feed in the app, new tab
*/
function setStateNotLoading(previousState: ContentCardState) { return { ...previousState, loading: false } }
function setStateOverlayShown(previousState: ContentCardState) { return { ...previousState, overlay: true } }
function setStateOverlayHidden(previousState: ContentCardState) { return { ...previousState, overlay: false } }

export function ContentCard({
  content,
  width,
  small,
  square,
  skipFeatureChecks,
  className,
  actionLabel,
  hideDetails,
  actions,
  studioButtonIcon,
  onClick,
  onCompose,
  onSourceClick,
  onSizeChanged,
  onImageLoadingFailed,
  onLiveArticleDataFetched
}: ContentCardProps & WithIntl) {
  const multiPosts = useSelector(multiPostsArraySelector)
  const composerCarouselImages = useSelector(composerImageUrlsSelector)
  const favState = useSelector((state: StoreState) => favStateSelectorForItem(state, state, content.rawId, content.type))
  const selectedContentItems = useSelector(selectedContentItemsSelector)
  const dispatch = useDispatch<StoreThunkDispatch>()
  const imageUrl = content.type === STOCK_PHOTO_TYPE ? content.thumbnailUrl : content.imageUrl
  const cardElement = useRef<HTMLDivElement>(null)

  const componentWidth = width || small ? DEFAULT_BASE_WIDTH_SMALL : DEFAULT_BASE_WIDTH
  const [state, setState] = useState<ContentCardState>({
    loading: content.type !== TEXT_TYPE,
    overlay: false,
    aspectHeight: componentWidth * DEFAULT_ASPECT_RATIO,
    aspectRatio: DEFAULT_ASPECT_RATIO
  })
  const [favCount, setFavCount] = useState(content.favCount)

  const isFavorite = favState.added || (Boolean(content.isFavorite) && !favState.removed)
  const transitionName = CONTENT_TRANSITION_NAME
  const timestamp = fromNow(content.createdAt)
  const dateTime = new Date(content.createdAt).toISOString()
  const dateTimeString = format(content.createdAt, 'MMM d, yyyy')

  const tallImageMaxRatio = 2.5
  const wideImageMinRatio = 0.25
  const cardClassName = classnames(
    styles.card,
    {
      [styles.square]: square,
      [styles.small]: small,
      [styles.loading]: state.loading,
      [styles.gif]: [PHOTO_TYPE, GIF_TYPE, STOCK_PHOTO_TYPE, STOCK_GIF_TYPE].includes(content.type),
      [styles.article]: content.type === ARTICLE_TYPE,
      [styles.video]: [VIDEO_TYPE, STOCK_VIDEO_TYPE].includes(content.type),
      [styles.stock]: content.isStock,
      [styles.nodetails]: hideDetails,
      [styles.text]: content.type === TEXT_TYPE
    },
    className
  )
  const cardStyle = width ? { width } : undefined
  const cardImageClassName = classnames(
    {
      [styles['tall-image']]: state.aspectRatio > tallImageMaxRatio,
      [styles['wide-image']]: state.aspectRatio < wideImageMinRatio,
      [styles.stretch]: state.aspectRatio >= 1
    }
  )

  const checkFeatureAvailabilityHandle = (feature: string, skipPopup?: boolean) => {
    return dispatch(checkFeatureAvailability(feature, skipPopup))
  }

  // To make response add to wrapper { paddingTop: `${this.state.aspectRatio * PERCENTS_IN_WHOLE}%` }
  const wrapperStyle = state.loading
    ? { height: state.aspectHeight }
    : undefined

  const isFeedTypeBlocked = !content.isStock && !FREE_FEED_TYPES.includes(content.feed.type)
    && !checkFeatureAvailabilityHandle(feedTypeFeatureMap[content.feed.type], true)
  const isContentTypeBlocked = !checkFeatureAvailabilityHandle(contentTypeFeatureMap[content.type], true)
  const contentAgeFeature = content.isStock ? null : getContentAgeFeature(content.createdAt)
  const isContentRangeBlocked = contentAgeFeature && !checkFeatureAvailabilityHandle(contentAgeFeature, true)
  let blockedFeature: boolean | string = isFeedTypeBlocked && feedTypeFeatureMap[content.feed.type]

  if (!blockedFeature && isContentTypeBlocked) {
    blockedFeature = contentTypeFeatureMap[content.type]
  } else if (!blockedFeature && isContentRangeBlocked) {
    blockedFeature = contentAgeFeature as string
  }

  const setupComposer = (contentProp?: AnyContent) => {
    const data = contentProp || content as AnyContent

    if (data.type === STOCK_VIDEO_TYPE) {
      dispatch(setComposerFile((data as Video).videoUrl))
      return
    }

    dispatch(setComposerContent({ content: data as ContentItem, sourceType: content.feed.type }))
  }

  const onUpgrade = (feature: string) => {
    const product = dispatch(getProductUpgradeByFeature(feature))

    if (product) {
      dispatch(promptUpgrade({ feature, product: product.handles.annual }))
    }
  }

  const checkIsContentBlocked = (aiWriter: boolean) => {
    let abortCompose: void | boolean = false

    if (onCompose) {
      abortCompose = onCompose(content, aiWriter)
    }

    return abortCompose
  }

  const handleSourceClick = (by: 'image' | 'title' | 'goto' | 'domain') => {
    onSourceClick?.(content, by)
  }

  const handleClick = () => {
    onClick?.(content)
  }

  const onTransitionEnd = (e: React.TransitionEvent<HTMLDivElement>) => {
    const element = (e.target as HTMLElement)
    const classes = element.classList

    // EXPL: Check if the card has ended transition in collapsed state
    const currentScale = (element.getBoundingClientRect().width / element.offsetWidth)
    const isCollapsed = currentScale.toFixed(1) === '1.0'

    if (classes.contains(styles.raised) && isCollapsed) {
      classes.remove(styles.raised)

      if (element.parentElement) {
        element.parentElement.classList.remove(styles.raised)
      }
    }
  }

  const showOverlay = () => {
    const cardElementDOMNode = findDOMNode(cardElement.current) as HTMLDivElement
    cardElementDOMNode.classList.add(styles.raised)
    setState(setStateOverlayShown)
  }

  const hideOverlay = () => {
    setState(setStateOverlayHidden)
  }

  const onMouseEnterParent = () => {
    const cardElementDOMNode = findDOMNode(cardElement.current) as HTMLDivElement
    const cardParentElement = cardElementDOMNode.parentElement as HTMLLIElement
    cardParentElement.classList.add(styles.raised)
  }

  const onLoadingDone = (width = 0, height = 0) => {
    if (!square && width !== 0) {
      const aspectHeight = Math.round(componentWidth / width * height)
      const aspectRatio = aspectHeight / componentWidth
      setState(
        (previousState) => ({ ...previousState, aspectHeight, aspectRatio })
      )
      handleSizeChanged()
    }

    setState(setStateNotLoading)
  }

  const onLoadingFailed = () => {
    console.warn('loading failed')
    const aspectRatio = 1
    const aspectHeight = 0
    setState(
      (previousState) => ({ ...previousState, aspectHeight, aspectRatio })
    )
    handleSizeChanged()
    setState(setStateNotLoading)

    if (content.type === PHOTO_TYPE && onImageLoadingFailed) {
      onImageLoadingFailed(content)
    }
  }

  const onArticleDataFetch = (data: LiveArticleData) => {
    setState(prevState => ({ ...prevState, liveArticleData: data }))
    if (onLiveArticleDataFetched) {
      onLiveArticleDataFetched(content.id, data)
    }
  }

  const onCreatePost = (skipAddToComposer?: boolean) => {
    // EXPL: Open prompt if composer already has carousel or multi-post
    const isPhoto = content.type === PHOTO_TYPE || content.type === STOCK_PHOTO_TYPE
    if (!isPhoto && composerCarouselImages.length > 0 || multiPosts.length > 0) {
      dispatch(promptCompose({ content, action: 'create' }))
      return true
    }
    const gifUrl = (content as Gif).gifUrl
    if (content.type === GIF_TYPE && gifUrl && gifUrl.toLowerCase().endsWith('.mp4')) {
      dispatch(setComposerFile(gifUrl))
    }
    if (!skipAddToComposer) {
      setupComposer(getLiveContent() as AnyContent)
    }
    return false
  }

  const getLiveContent = () => {
    const liveData = state.liveArticleData

    if (!liveData) {
      return content
    }

    return {
      ...content,
      title: liveData.title,
      imageUrl: liveData.imageSrc,
      sourceLink: liveData.link
    }
  }

  const liveContent = getLiveContent()

  const handleAddFavorite = () => {
    const favoritesAllowed = dispatch(checkFeatureAvailability(FEATURE_FIND_FAVORITES))

    if (favoritesAllowed) {
      return content.live
        ? Observable.fromPromise(dispatch(addFavoriteLive(getLiveContent() as any)).unwrap())
        : Observable.fromPromise(dispatch(addFavorite(getLiveContent())).unwrap())
    }

    return Observable.empty()
  }

  const handleRemoveFavorite = () => {
    return Observable.fromPromise(dispatch(removeFavorite({ item: content, live: content.live })).unwrap())
  }

  const handleOpenContentPreviewWindow = (content: Video | Photo | Article | Gif) => {
    return dispatch(openContentPreviewWindow(content))
  }

  const _handleSizeChanged = debounce(() => onSizeChanged?.(cardElement.current as HTMLDivElement), RESIZE_TIMEOUT)

  const handleSizeChanged = useCallback(() => {
    _handleSizeChanged()
  }, [_handleSizeChanged])

  useEffect(() => {
    setFavCount(prevCount => {
      if (favState.added) {
        return prevCount++
      }

      if (favState.removed && prevCount > 0) {
        return prevCount--
      }

      return prevCount
    })
  }, [favState])

  useEffect(() => {
    if (state.loading) {
      const aspectHeight = componentWidth
      const aspectRatio = aspectHeight / componentWidth

      if (state.aspectHeight !== aspectHeight || state.aspectRatio !== aspectRatio) {
        setState(
          (previousState) => ({ ...previousState, aspectHeight, aspectRatio })
        )
        handleSizeChanged()
      }
    }

    const cardElementDOMNode = findDOMNode(cardElement.current) as HTMLDivElement
    const cardParentElement = cardElementDOMNode.parentElement as HTMLLIElement
    cardParentElement.addEventListener('mouseenter', onMouseEnterParent)

    return () => {
      cardParentElement.removeEventListener('mouseenter', onMouseEnterParent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onContentSelected = () => {
    dispatch(addContentToSelection(getLiveContent() as any))
  }

  const onContentDeselected = () => {
    dispatch(removeContentFromSelection(content.id))
  }

  const fetchLiveData = (content.type === ARTICLE_TYPE && (content as Article).scrape)

  return (
    <Paper
      ref={cardElement}
      className={cardClassName}
      onMouseEnter={showOverlay}
      onMouseLeave={hideOverlay}
      onTransitionEnd={onTransitionEnd}
      style={cardStyle}
      data-testid="content-card"
      onClick={handleClick}
    >
      {!skipFeatureChecks && blockedFeature && (
        <ContentCardUpgradeOverlay
          key="upgrade-overlay"
          feature={blockedFeature}
          contentType={liveContent.type}
          imageUrl={(liveContent as any).imageUrl}
          className={styles['upgrade-overlay']}
          onUpgrade={onUpgrade}
          favButton={isFavorite ? <FavButton
            isFavorite={isFavorite}
            hover={state.overlay}
            count={favCount}
            className={classnames(
              styles['btn-favorite'],
              styles['btn-favorite-upgrade-overlay'],
              { [styles['fav-visible']]: favState && !state.loading }
            )}
            onFavorite={handleAddFavorite}
            onUnfavorite={handleRemoveFavorite}
          /> : null}
        />
      )}

      {!content.isStock && (
        <div className={styles.top}>
          <Tooltip title={dateTimeString}>
            <time
              key="timestamp"
              dateTime={dateTime}
              className={styles.timestamp}
            >
              {timestamp}
            </time>
          </Tooltip>
          <a
            href={`/content/feeds/${content.feed.uniqueSource || content.feed.id}`}
            className={classnames(styles['feed-name'], 'text-ellipsis')}
          >
            {content.feed.type !== FEED_TYPE_KEYWORD
            && <SocialIcon icon={content.feed.type} size="12px" className={styles['feed-network-icon']} />}
            <span className="text-ellipsis">{content.feed.name}</span>
          </a>
        </div>
      )}
      <ContentCardActions
        content={liveContent}
        favCount={favCount}
        isFavButtonVisible={favState && !state.loading}
        isStock={content.isStock}
        isFavorite={isFavorite}
        skipFeatureChecks={skipFeatureChecks}
        className={styles.actions}
        createPostLabel={actionLabel}
        actions={actions}
        studioButtonIcon={studioButtonIcon}
        isSelected={Boolean(selectedContentItems[content.id])}
        onAddFavorite={handleAddFavorite}
        onRemoveFavorite={handleRemoveFavorite}
        isContentBlocked={checkIsContentBlocked}
        onCreatePost={onCreatePost}
        onSelected={onContentSelected}
        onDeselected={onContentDeselected}
      />

      <TransitionGroup
        key="content"
        component="div"
        className={styles.content}
        style={wrapperStyle}
      >
        {content.type !== TEXT_TYPE && (
          <CSSTransition classNames={transitionName} timeout={250}>
            {state.loading ? (
              <ProgressMedia
                key="progress-media"
                image={imageUrl || null}
                onDone={onLoadingDone}
                onError={onLoadingFailed}
                className={styles.progress}
                height={state.aspectHeight}
              />
            ) : (
              <React.Fragment>
                {state.aspectHeight !== 0 && (liveContent as any).imageUrl ? (
                  <ContentCardMedia
                    key="media"
                    content={liveContent as HasImage}
                    className={cardImageClassName}
                    small={small}
                    hideProvider={hideDetails}
                    onClick={() => handleSourceClick('image')}
                    openContentPreviewWindow={handleOpenContentPreviewWindow}
                  />
                ) : (
                  <div key="no-image" className={styles['no-image-box']}>
                    <BrokenImageIcon key="broken-image" className={styles['icon-no-image']} />
                  </div>
                )}
                {!liveContent.isStock && <ContentCardFooter key="card-footer" content={liveContent} />}
              </React.Fragment>
            )}
          </CSSTransition>
        )}
        {content.type === TEXT_TYPE && (
          <React.Fragment>
            <CardStatusText text={content.status || ''} small={small} />
            <ContentCardFooter key="card-footer" content={liveContent} />
          </React.Fragment>
        )}
      </TransitionGroup>

      {fetchLiveData && (
        <FetchArticleData
          key="live-fetch"
          url={(content as Article).sourceLink || (content as Article).socialLink}
          onFetched={onArticleDataFetch}
        />
      )}
    </Paper>
  )
}

export default injectIntl(ContentCard)
