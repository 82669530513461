import { Component, Children, PropsWithChildren } from 'react'
import { connect } from 'react-redux'

import { Stream } from 'interfaces'
import { getStream } from 'services/content/streams/actions'
import { StoreState, StoreThunkDispatch } from 'store/state'

import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/first'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/observeOn'
import { animationFrame } from 'rxjs/scheduler/animationFrame'
import { Subscription } from 'rxjs/Subscription'

export interface FetchStreamOwnProps {
  id: string

  onFetched(folder: Stream): void
  onFailed?(err: any): void
}

interface ConnectedFetchStreamProps extends FetchStreamOwnProps {
  getStream(): Observable<Stream>
}

export type FetchStreamProps = FetchStreamOwnProps & ConnectedFetchStreamProps

export class FetchStream extends Component<PropsWithChildren<FetchStreamProps>, void> {

  private subscription: Subscription

  constructor(props: FetchStreamProps) {
    super(props)

    this.onFetched = this.onFetched.bind(this)
    this.onFailed = this.onFailed.bind(this)
  }

  shouldComponentUpdate(nextProps: FetchStreamProps) {
    return nextProps.id !== this.props.id
  }

  onFetched(folder: Stream) {
    this.props.onFetched(folder)
  }

  onFailed(err: any) {
    console.log(err)
    if (this.props.onFailed) {
      this.props.onFailed(err)
    }
  }

  fetch() {
    return this.props.getStream()
      .observeOn(animationFrame)
      .subscribe(this.onFetched, this.onFailed)
  }

  componentDidMount() {
    this.subscription = this.fetch()
  }

  componentDidUpdate() {
    this.subscription.unsubscribe()
    this.subscription = this.fetch()
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    return this.props.children
      ? Children.only(this.props.children)
      : null
  }
}

function mapStateToProps(_state: StoreState, ownProps: FetchStreamOwnProps) {
  return ownProps
}

function mapDispatchToProps(dispatch: StoreThunkDispatch, ownProps: FetchStreamOwnProps) {
  const { id } = ownProps

  return {
    getStream: () => Observable.fromPromise(dispatch(getStream({ streamId: id, force: true, withFeeds: true })).unwrap())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FetchStream as any)
