import React from 'react'
import TextField from '@mui/material/TextField'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ComposerYoutubePost } from 'services/compose/interfaces/ComposerYoutubePost'
import { composerResetKeySelector, composerPostSelector } from 'services/compose/selectors'
import { BRAND_YOUTUBE, MAX_YT_TITLE_LENGTH, PrivacyStatus } from 'shared'
import styles from './YoutubePostStatus.pcss'
import { setYtDescription, setYtPrivacy, setYtTitle } from 'services/compose'
import { debounce } from 'shared/utils'
import { useEffectUpdateOnly } from 'hooks/useEffectUpdateOnly'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import StoreState from 'store/state'

const INPUT_DEBOUNCE_TIME = 250

export function YoutubePostStatus() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const resetKey = useSelector(composerResetKeySelector)
  const post = useSelector((s: StoreState) => composerPostSelector(s, BRAND_YOUTUBE)) as ComposerYoutubePost
  const [title, setTitle] = React.useState(post.status.text)
  const [description, setDescription] = React.useState(post.description || '')
  const [privacyStatus, setPrivacyStatus] = React.useState(post.privacyStatus)

  const saveTitle = debounce((title: string) => dispatch(setYtTitle(title)), INPUT_DEBOUNCE_TIME)
  const saveDescription = debounce((text: string) => dispatch(setYtDescription(text)), INPUT_DEBOUNCE_TIME)
  const savePrivacy = debounce((privacy: PrivacyStatus) => dispatch(setYtPrivacy(privacy)), INPUT_DEBOUNCE_TIME)

  const titleRef = React.useRef<string>()
  const descriptionRef = React.useRef<string>()
  const privacyRef = React.useRef<string>()

  React.useEffect(() => {
    titleRef.current = title
  }, [title])

  React.useEffect(() => {
    descriptionRef.current = description
  }, [description])

  React.useEffect(() => {
    privacyRef.current = privacyStatus
  }, [privacyStatus])

  React.useEffect(() => {
    if (!post.status.touched) {
      setDescription(current => {
        if (current !== post.description) {
          return post.description
        }
        return current
      })
    }
  }, [post.status.touched, post.description])

  useEffectUpdateOnly(() => {
    setTitle('')
    setDescription('')
    setPrivacyStatus(post.privacyStatus)
  }, [resetKey])

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value
    setTitle(text)
    saveTitle(text)
  }

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value
    setDescription(text)
    saveDescription(text)
  }

  const titleRemainingChars = MAX_YT_TITLE_LENGTH - title.length
  // eslint-disable-next-line no-magic-numbers
  const titleHelper = titleRemainingChars < 51 ? (MAX_YT_TITLE_LENGTH - title.length).toString() : undefined

  const privacySelectOptions: PPSelectOptions = {
    public: { label: <FormattedMessage id="composer.labels.youtube.switch-privacy.public" /> },
    private: { label: <FormattedMessage id="composer.labels.youtube.switch-privacy.private" /> },
    unlisted: { label: <FormattedMessage id="composer.labels.youtube.switch-privacy.unlisted" /> }
  }

  const onPrivacyStatusChange = (selected: string) => {
    savePrivacy(selected as PrivacyStatus)
    setPrivacyStatus(selected as PrivacyStatus)
  }

  return (
    <div className={styles.container}>
      <TextField
        value={title}
        placeholder={intl.formatMessage({ id: 'composer.labels.youtube-title-placeholder' })}
        classes={{ root: styles['title-box'] }}
        helperText={titleHelper}
        FormHelperTextProps={{ className: styles.helper }}
        name="YT Title"
        onChange={onTitleChange}
      />
      <TextField
        value={description}
        multiline
        maxRows={25}
        placeholder={intl.formatMessage({ id: 'composer.labels.youtube-description-placeholder' })}
        className={styles['description-box']}
        classes={{ root: styles['description-input'] }}
        name="YT Description"
        onChange={onDescriptionChange}
      />
      <PPSelect
        name={<FormattedMessage id="composer.labels.youtube-privacystatus-placeholder" />}
        options={privacySelectOptions}
        selectedValue={privacyStatus}
        withCaret
        onSelectionChange={onPrivacyStatusChange}
      />
    </div>
  )
}
