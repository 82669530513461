import { StoreThunkAction } from 'store/state'
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/of'
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/filter'

import { TIMERANGE_FILTER_DEFAULT } from 'config'
import { Video, Paginated, RangeFilter, VIDEO_TYPE, ContentType, IndexedObject } from 'interfaces'
import { StockContentProvider } from 'interfaces/Content/StockContentProvider'
import { V1 } from './net'
import { videosAdapter, getCacheKey, SortBy, PAGE_SIZE_DEFAULT } from '../util'
import { getCachedContent, cacheContentTemp } from '../cache'
import { STOCK_VIDEO_TYPE } from 'interfaces/Content/StockContentTypes'
import { createMixedArrayFromObject } from 'utils/sort/mixedArray'

/**
 * Fetches videos from single source or a folder
 *
 * @export
 * @param {string} sourceId source/folder id
 * @param {boolean} [isStream]
 * @param {number} [page=0] page number (starts at 0)
 * @param {RangeFilter} [rangeFilter=TIMERANGE_FILTER_DEFAULT] time filter
 * @returns {StoreThunkAction<Observable<Paginated<Video[]>>>}
 */
export function getVideos(
  sourceId: string,
  isStream?: boolean,
  page: number = 0,
  rangeFilter: RangeFilter = TIMERANGE_FILTER_DEFAULT,
  sortBy: SortBy = SortBy.Engagement,
  stock?: boolean,
  stockSource?: StockContentProvider
): StoreThunkAction<Observable<Paginated<Video[]>>> {

  return (dispatch, _getState) => {
    let fetchPage = page
    let cacheKeyType: ContentType = VIDEO_TYPE
    if (stock) {
      // NOTE: always fetch page 0 for stock content
      fetchPage = 0
      cacheKeyType = STOCK_VIDEO_TYPE
    }
    const cacheKey = getCacheKey(Boolean(isStream), sourceId, cacheKeyType, rangeFilter, fetchPage, sortBy, stockSource)
    const cachedVideos = dispatch(getCachedContent(cacheKey))

    if (cachedVideos) {
      if (stock) {
        // EXPL: Stock content is cached without paging (all on page 0). It is implemented here:
        const startIndex = page * PAGE_SIZE_DEFAULT
        const endIndex = startIndex + PAGE_SIZE_DEFAULT
        const contentForPage: Paginated<Video[]> = {
          items: cachedVideos.items.slice(startIndex, endIndex) as Video[],
          hasNext: cachedVideos.items.length > endIndex - 1
        }

        return Observable.of(contentForPage)
      }
      return Observable.of(cachedVideos as Paginated<Video[]>)
    }

    const getVideos = isStream
      ? dispatch(V1.getVideosFromFolder(sourceId, page, rangeFilter, sortBy))
      : dispatch(V1.getVideos(sourceId, fetchPage, rangeFilter, sortBy, stockSource))

    return getVideos
      .map(response => response.content)
      .map(vids => {
        const contentFormatted = videosAdapter(vids, stock)
        if (stock) {
          const contentByProvider = contentFormatted.items.reduce((map: IndexedObject<Video[]>, item: any) => {
            if (!map[item.provider]) {
              map[item.provider] = []
            }
            map[item.provider].push(item)
            return map
          }, {})
          const mixedContent = createMixedArrayFromObject<Video>(contentByProvider)

          if (vids.length > 0) {
            dispatch(cacheContentTemp(cacheKey, { hasNext: contentFormatted.hasNext, items: mixedContent }))
          }

          return {
            hasNext: contentFormatted.hasNext,
            items: mixedContent.slice(0, PAGE_SIZE_DEFAULT) // paging for stock videos
          }
        }
        return contentFormatted
      })
      .do({
        next: (response) => {
          // Do not cache empty response
          if (!stock && response.items.length > 0) {
            dispatch(cacheContentTemp(cacheKey, response))
          }
        }
      })
  }
}
