import { Stream, Feed, ContentFeedType, ContentSource, AnyContent, FileFolder, StreamRecommended, FeedRecommended } from 'interfaces'
import { TYPE_FEED_TYPE_MAP } from './mappings'
import mapAvatar from './avatar'
import slugify from 'utils/format/slugify'
import { defaultStreamColorString, ppGreenColorString } from 'utils/colors'
const BASE_TEN = 10

export function streamAdapter(streamRecommended: StreamRecommended, isPublic?: boolean): Stream {
  const type = streamRecommended.type + ''
  const originalId = streamRecommended.originalFolder || streamRecommended.originalStreamId

  const slug = streamRecommended.prettyName || slugify(streamRecommended.name)
  let imageUrls: string[] = typeof streamRecommended.images === 'string' ? JSON.parse(streamRecommended.images) : streamRecommended.images
  if (!imageUrls && streamRecommended.feedImages && typeof streamRecommended.feedImages[0] === 'string') {
    imageUrls = streamRecommended.feedImages
  }

  if (!imageUrls) {
    imageUrls = []
  }

  const featuredImageUrl = streamRecommended.imageUrl || imageUrls[0]

  return {
    id: streamRecommended.id + '',
    originalId: originalId ? originalId.toString() : undefined,
    feedIds: new Array<string>(streamRecommended.feedsCount ? streamRecommended.feedsCount.total || 0 : 0),
    feeds: (streamRecommended.feeds || []).map(feedAdapter),
    order: 0,
    isStream: true,
    public: !!isPublic,
    title: streamRecommended.name.replace(/&amp;/g, '&'),
    slug,
    filters: streamRecommended.filters,
    feedsCount: streamRecommended.feedsCount,
    color: type === '1' ? ppGreenColorString : streamRecommended.color || defaultStreamColorString,
    type,
    protected: '' + streamRecommended.type === '1',
    imageUrl: featuredImageUrl,
    followers: streamRecommended.followersCount,
    createdAt: 0,
    userId: streamRecommended.userId?.toString(),
    featuredImageUrl,
    // eslint-disable-next-line no-magic-numbers
    imageUrls
  }
}

export function streamsAdapter(streamsRecommended: StreamRecommended[], isPublic?: boolean): Stream[] {
  return streamsRecommended.map(item => streamAdapter(item, isPublic))
}

export function feedAdapter(feed: FeedRecommended): Feed {
  const type = typeof feed.type === 'string'
    ? feed.type as ContentFeedType
    : TYPE_FEED_TYPE_MAP[feed.type]

  const uid = feed.uniqueSource || feed.uniqueSourceId

  return {
    id: feed.id.toString(),
    coverPhoto: feed.profilePicture,
    handle: feed.handle,
    image: feed.profilePicture || mapAvatar(type, feed),
    name: feed.name,
    order: 0,
    isFeed: true,
    type,
    fanCount: typeof feed.fanCount === 'string' ? parseInt(feed.fanCount, BASE_TEN) : feed.fanCount,
    sources: feed.filters,
    uniqueSource: uid ? uid.toString() : feed.id.toString()
  }
}

export function instanceOfStream(item: ContentSource | AnyContent | FileFolder): item is Stream {
  return 'feeds' in item
}

export function instanceOfFeed(item: ContentSource | AnyContent | FileFolder): item is Feed {
  return 'uniqueSource' in item
}

export function instanceOfFileFolder(item: ContentSource | AnyContent | FileFolder): item is FileFolder {
  return 'files' in item
}
