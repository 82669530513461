import * as React from 'react'
import ContentStudio from 'routes/find/routes/studio'
import Dialog from '@mui/material/Dialog'
import styles from './ContentStudioPopup.pcss'

interface ContentStudioPopupProps {
  open: boolean
  onClose: (withImage: boolean) => void
}

export function ContentStudioPopup(props: ContentStudioPopupProps) {
  const onFileCreated = () => {
    props.onClose(true)
  }

  const close = () => {
    props.onClose(false)
  }

  return (
    <Dialog open={props.open} maxWidth="xl" classes={{ paper: styles.dialog }} onClose={close}>
      <ContentStudio hideMultipost className={styles.studio} onAddedToComposer={onFileCreated} />
    </Dialog>
  )
}
