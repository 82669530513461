import * as React from 'react'
import styles from './PostPreviewText.pcss'
import { BRAND_FACEBOOK, SocialProfileTag } from 'interfaces'
import { buildHTMLWithTags } from 'utils/composer'

interface PostPreviewTextProps {
  text: string
  tags?: SocialProfileTag[]
  network?: string
  maxLinesDefault: number
  expandLabel: string
  collapseLabel: string
  className: string
  color?: string
  backgroundImageUrl?: string
}

export const PostPreviewText = React.memo((props: PostPreviewTextProps) => {
  const [expanded, setExpanded] = React.useState(false)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [showToggle, setShowToggle] = React.useState(false)

  React.useEffect(() => {
    if (!containerRef.current) {
      return
    }
    if (props.network === BRAND_FACEBOOK && props.tags && containerRef.current) {
      const text = buildHTMLWithTags(props.text, props.tags, props.network)
      containerRef.current.innerHTML = text
    } else {
      containerRef.current.innerHTML = props.text
    }
  }, [props.tags, props.text, props.network])

  React.useEffect(() => {
    if (!containerRef.current) {
      return
    }

    const withToggle = containerRef.current.clientHeight < containerRef.current.scrollHeight
    setShowToggle(withToggle)
  }, [props.text.length])

  const trimClassName = expanded ? '' : styles[`trim-${props.maxLinesDefault}`]

  const toggle = () => {
    setExpanded(current => !current)
  }

  const style: any = props.backgroundImageUrl ? { backgroundImage: `url(${props.backgroundImageUrl})` } : undefined
  if (props.color) {
    style.color = props.color
  }

  return (
    <div className={`${props.className} ${props.backgroundImageUrl ? styles['txt-bg'] : ''}`} style={style}>
      <div>
        <div className={trimClassName} ref={containerRef}></div>
        {(showToggle || expanded) && (
          <div className={styles.actions}>
            <span className={styles.btn} onClick={toggle}>
              {expanded ? props.collapseLabel : props.expandLabel}
            </span>
          </div>
        )}
      </div>
    </div>
  )
})

export default PostPreviewText
