export function unique(array: any[], compareKey: string): any[] {
  const unique = {} as { [key: string]: any }
  const distinct: any[] = []

  array.forEach(function (item) {
    if (!unique[item[compareKey]]) {
      distinct.push(item)
      unique[item[compareKey]] = true
    }
  })

  return distinct
}

export function uniqueStrings(array: string[]) {
  return array.filter((value: string, index: number, self: string[]) => {
    return self.indexOf(value) === index
  })
}
