import * as React from 'react'
import { isVideoUrl } from 'utils/composer'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import { FormattedMessage } from 'react-intl'
import { PlayIcon } from 'components/Icons'

import styles from './NativeVideoPlayer.pcss'
const DEFAULT_VIDEO_CONTROLS_LIST = 'nodownload nofullscreen noremoteplayback'

export interface NativeVideoPlayerProps {
  videoUrl?: string
  videoDataUrl?: string
  thumbnailUrl?: string
  togglePlayOnHover?: boolean
  controls?: boolean
  autoplay?: boolean
  loop?: boolean
  muted?: boolean
  controlsList?: string
  backgroundImage?: string
  className?: string
  mediaElementId?: string
  videoElementClassName?: string
  playBtnClassName?: string
  retry?: {
    max: number
    timeout: number // ms
  }
  onCanPlay?: () => void
  onError?: () => void
}

export function NativeVideoPlayer(props: NativeVideoPlayerProps) {
  const isValidUrl = Boolean(props.videoDataUrl) || (props.videoUrl && isVideoUrl(props.videoUrl))
  const [playing, setPlayind] = React.useState(props.autoplay)
  const [error, setError] = React.useState(!isValidUrl)
  const [retry, setRetry] = React.useState(0)

  React.useEffect(() => {
    setRetry(0)
  }, [props.videoUrl])

  const videoRef = React.createRef<HTMLVideoElement>()
  const backgroundStyle = props.backgroundImage ? { backgroundImage: `url(${props.backgroundImage})` } : {}

  const playVideo = () => {
    const player = videoRef.current as HTMLVideoElement
    if (player) {
      player.play()
      setPlayind(true)
    }
  }

  const hoverPlay = () => {
    if (props.togglePlayOnHover) {
      playVideo()
    }
  }

  const hoverPause = () => {
    if (props.togglePlayOnHover) {
      pauseVideo()
    }
  }

  const pauseVideo = () => {
    const player = videoRef.current as HTMLVideoElement
    if (player) {
      player.pause()
      setPlayind(false)
    }
  }

  const togglePlay = () => {
    const player = videoRef.current as HTMLVideoElement
    if (!player) {
      return
    }

    if (player.paused) {
      player.play()
      setPlayind(true)
    } else {
      player.pause()
      setPlayind(false)
    }
  }

  const onVideoError = () => {
    if (props.retry && retry < props.retry.max) {
      setTimeout(() => {
        console.log('retrying...')
        setRetry(retry + 1)
      // eslint-disable-next-line no-magic-numbers
      }, props.retry.timeout)
    } else {
      setError(true)
      if (props.onError) {
        props.onError()
      }
    }
  }

  const onCanPlay = () => {
    if (props.onCanPlay) {
      props.onCanPlay()
    }
  }

  return (
    <div className={`${styles['player-box']} ${props.className || ''}`}>
      {
        error && (
          <div className={`${styles.error} ${styles.center}`}>
            <ErrorIcon className={styles.icon} />
            <p><FormattedMessage id="errors.video.loading" /></p>
          </div>
        )
      }
      {
        props.backgroundImage && (
          <div className={`${styles.background}`} style={backgroundStyle}></div>
        )
      }
      <div className={styles.media}>
        {
          !playing && (
            <span className={styles['btn-play']} onClick={playVideo}>
              <PlayIcon className={props.playBtnClassName || ''} />
            </span>
          )
        }
        <video
          poster={props.thumbnailUrl}
          ref={videoRef}
          key={retry}
          preload="metadata"
          autoPlay={props.autoplay}
          controls={props.controls}
          controlsList={props.controlsList || DEFAULT_VIDEO_CONTROLS_LIST}
          loop={props.loop}
          muted={props.muted}
          data-id={props.mediaElementId}
          className={`${styles.video} ${props.videoElementClassName || ''}`}
          onClick={togglePlay}
          onMouseEnter={hoverPlay}
          onMouseLeave={hoverPause}
          onError={onVideoError}
          onCanPlay={onCanPlay}
        >
          <source src={props.videoUrl || props.videoDataUrl} />
          <p><FormattedMessage id="errors.video.not-supported" /></p>
        </video>
      </div>
    </div>
  )
}

export default NativeVideoPlayer
