import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'
import { LegacyList, LegacyPage } from 'shared'

export const ENDPOINT_DOWNGRADE_FREE = `${LEGACY_URL}/settings/account_info/downgrade_to_free`

export interface SettingsDefaultsResponse {
  pages?: LegacyPage[]
  lists?: LegacyList[]
  userTimezone?: string
  timezones: Array<{ zone: string, label: string }>
}

export const V1 = Object.freeze({
  getAccountInfo: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/settings/account_info`).asGet().expectJSON()

    return function () {
      return authorizedPipe<any>(
        builder
          .build()
      )
    }
  }(),
  downgradeFree: function () {
    const builder = new RequestBuilder(ENDPOINT_DOWNGRADE_FREE).asPost().expectJSON()

    return function (appId: string) {
      return authorizedPipe<any>(
        builder.body(JSON.stringify({ app_id: appId })).build()
      )
    }
  }(),
  getTeam: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/settings/team`).asGet().expectJSON()

    return function () {
      return authorizedPipe<any>(
        builder.build()
      )
    }
  }(),
  addTeamMember: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/settings/team/add_admin`).asPost().expectJSON().asFormUrlEncoded()

    return function (email: string, ppIds: string[]) {
      return authorizedPipe<any>(
        builder
          .body({
            email,
            pp_page_ids: ppIds
          })
          .build()
      )
    }
  }(),
  removeTeamMember: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/settings/team/remove_admin`).asPost().expectJSON().asFormUrlEncoded()

    return function (id: string) {
      return authorizedPipe<any>(
        builder
          .body({ admin_id: id })
          .build()
      )
    }
  }(),
  addPermission: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/settings/team/add_permission`).asPost().expectJSON().asFormUrlEncoded()

    return function (
      memberId: string,
      memberName: string,
      ppid: string,
      memberFbId?: string
    ) {
      const data: any = {
        admin_id: memberId,
        admin_name: memberName,
        pp_page_id: ppid
      }

      if (memberFbId) {
        data.admin_fb_id = memberFbId
      }

      return authorizedPipe<any>(
        builder
          .body(data)
          .build()
      )
    }
  }(),
  removePermission: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/settings/team/remove_permission`)
      .asPost()
      .expectJSON()

      .asFormUrlEncoded()

    return function (permissionId: string, ppid: string) {
      return authorizedPipe<any>(
        builder
          .body({ permission_id: permissionId, pp_page_id: ppid })
          .build()
      )
    }
  }(),
  updateGroupPublisher: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/settings/networks/set_publish_on_behalf`).asPost().expectJSON()

    return function (ppid: string, publishBy: string) {
      const data = {
        pp_group_id: ppid,
        pp_publish_by: publishBy
      }

      return authorizedPipe<any>(
        builder.bodyJSONstringify(data).build()
      )
    }
  }(),
  getDefaults: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/settings/defaults`).asGet().expectJSON()

    return function () {
      return authorizedPipe<SettingsDefaultsResponse>(
        builder.build()
      )
    }
  }(),
  updateDefaults: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/settings/defaults/update`).asPost().asFormUrlEncoded().expectJSON()

    return function (profileIds: string[], timezone?: string, setPlansTimezones?: boolean) {
      const data = {
        default_post_to: profileIds.join(','),
        timezone: {
          value: timezone,
          apply_to_plans: Boolean(setPlansTimezones)
        }
      }
      return authorizedPipe<any>(
        builder
          .bodyJSONstringify(data)
          .build()
      )
    }
  }()
})
