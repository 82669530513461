import { FB_SDK_VERSION, Facebook, FacebookLoginOptions, FacebookLoginResponse, FacebookInitOptions } from 'interfaces'
import { NotAuthenticatedException } from '../../exceptions'
import { Observable } from 'rxjs/Observable'
import { FB_APP_ID } from 'config'

declare const FB: Facebook

class PromiseFBClass {

  api<T>(url: string, options?: any): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      try {
        FB.api(url, options || {}, resolve)
      } catch (error) {
        reject(error)
      }
    })
  }

  init(options?: FacebookInitOptions): Promise<void> {
    return new Promise<void>((resolve) => {
      FB.init(Object.assign({ appId: FB_APP_ID, version: FB_SDK_VERSION, status: true, cookie: true }, options))
      resolve()
    })
  }

  login(options?: FacebookLoginOptions): Promise<FacebookLoginResponse> {
    return new Promise<FacebookLoginResponse>((resolve, reject) => {
      FB.login((response: FacebookLoginResponse) => {
        console.log('[FB.login] authResponse: ', JSON.stringify({ status: response.status, auth: Boolean(response.authResponse) }))
        if (!response.authResponse) {
          reject(new NotAuthenticatedException('Could not connect to Facebook'))
          return
        }
        resolve(response)
      }, options)

    })
  }

  getLoginStatus(force?: boolean): Promise<FacebookLoginResponse> {
    return new Promise<FacebookLoginResponse>((resolve, reject) => {
      if (typeof FB === 'undefined') {
        return reject('FB not initialized')
      }
      FB.getLoginStatus((response: FacebookLoginResponse) => {
        console.log(
          '[FB.getLoginStatus] authResponse: ',
          JSON.stringify({ status: response.status, auth: Boolean(response.authResponse) })
        )
        if (response.status === 'unknown') {
          // EXPL: Expire fblo_ cookie. This fixes some issues with FB.getLoginStatus and FB.login
          document.cookie = `fblo_${FB_APP_ID}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
        }
        if (!response.authResponse) {
          reject(new NotAuthenticatedException('Could not get auth status from FB.'))
          return
        }
        resolve(response)
      }, force)
    })
  }

  logout(): Promise<void> {
    return new Promise<void>((resolve) => {
      FB.logout(() => {
        // EXPL: Expire fblo_ cookie. This fixes some issues with FB.getLoginStatus and FB.login
        document.cookie = `fblo_${FB_APP_ID}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
        resolve()
      })
    })
  }

  searchLocation(location: string): Observable<any[]> {
    const token = FB.getAccessToken()
    const url = `https://graph.facebook.com/${FB_SDK_VERSION}/search?type=adgeolocation&q=${location}&access_token=${token}`
    return Observable.ajax({ url, method: 'GET' }).map(result => result.response.data)
  }
}

export const PromiseFB = new PromiseFBClass()
export default PromiseFB
