import { RangeFilter, WEEK_RANGE_FILTER } from 'interfaces'

/**
 * Define multi-file constants and other constant configuration here
 */

declare const __PROD__: boolean

export const PERSIST_CONFIG_KEY = 'primary'
export const REDUX_STORE_VERSION = 32

export const FB_APP_ID = __PROD__ ? process.env.FACEBOOK_APP_ID : process.env.FACEBOOK_APP_ID_DEV
export const PP_HOTJAR_SITE_ID = process.env.PP_HOTJAR_SITE_ID
export const GOOGLE_ANALYTICS_TRACKING_ID = process.env.GOOGLE_ANALYTICS_TRACKING_ID
export const PROFITWELL_PUBLIC_API_TOKEN = process.env.PROFITWELL_PUBLIC_API_TOKEN
export const MAINTENANCE_ON = process.env.MAINTENANCE_ON
export const MAINTENANCE_FIND_ON = process.env.MAINTENANCE_FIND_ON
export const MAINTENANCE_PLAN_ON = process.env.MAINTENANCE_PLAN_ON
export const MAINTENANCE_POST_ON = process.env.MAINTENANCE_POST_ON
export const MAINTENANCE_SEARCH_ON = process.env.MAINTENANCE_SEARCH_ON
export const MAINTENANCE_SETTINGS_ON = process.env.MAINTENANCE_SETTINGS_ON

export const LINKEDIN_CLIENT_ID = __PROD__ ? process.env.LINKEDIN_CLIENT_ID : process.env.LINKEDIN_CLIENT_ID_DEV
export const LINKEDIN_AUTH_STATE_KEY = '5H94WMVc4f4Pm3R'
export const GOOGLE_TAG_MANAGER_ID = 'GTM-PQFG2W5'
export const INTERCOM_APP_ID = __PROD__ ? process.env.INTERCOM_APP_ID : process.env.INTERCOM_APP_ID_DEV
export const CANVA_API_KEY = __PROD__ ? process.env.CANVA_API_KEY : process.env.CANVA_API_KEY_DEV

export const ALGOLIA_APP_ID = process.env.ALGOLIA_APP_ID as string
export const ALGOLIA_API_KEY = process.env.ALGOLIA_API_KEY as string
export const ALGOLIA_STATUSES_INDEX = 'statuses'
export const ALGOLIA_FACEBOOK_INDEX = __PROD__ ? 'facebook_production' : 'facebook_development'
export const ALGOLIA_TWITTER_INDEX = __PROD__ ? 'twitter_production' : 'twitter_development'

export const LEGACY_URL = __PROD__ ? process.env.LEGACY_URL as string : process.env.LEGACY_URL_DEV as string
export const CONTENT_SERVICE_URL = __PROD__ ? process.env.CONTENT_SERVICE_URL : process.env.CONTENT_SERVICE_URL_DEV

export const FACEBOOK_MINIMAL_SCOPES = ['email', 'public_profile']
export const TIMERANGE_FILTER_DEFAULT: RangeFilter = WEEK_RANGE_FILTER

export const URL_SEGMENT_STREAM = 'stream'
export const URL_SEGMENT_FEED = 'feed'
export const URL_SEGMENT_STATUS = 'statuses'
export const URL_SEGMENT_FILE_FOLDER = 'library'

export const ADMIN_SERVICE_URL = `${LEGACY_URL}/administration`
export const PHOTO_EDITOR_LICENSE = process.env.PHOTOEDITORSDK_LICENSE as string

export const MERCHYNT_USERS = [
  '10212', '203814', '103017', '225603', '136620', '211312', '1345', '29877',
  '221607', '56', '213547', '221608', '203288', '216758', '213609'
]
export const MIN_MERCHYNT_ENABLED_PLAN_ORDER = 3
export const PAGE_CALLOUTS_URL = 'https://staticpp.postplanner.com/config/callouts.json'
export const MUI_X_LICENSE_KEY = '9d250f9e8dd585ea162a3826f793e2cfTz04NDI5MyxFPTE3Mzk1NTM0MDcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
export const SIGNUP_UI_CONFIG_URL = `https://staticpp.postplanner.com/config/ui.config${__PROD__ ? '' : '.dev'}.json`
export const GOOGLE_CAPTCHA_SITE_KEY = '6Ldj-MkZAAAAAHwM5DY1-eRPUVhLpl2CIwhTESgC'
export const ALLOW_PERSIST_TIKTOK_SETTINGS = true
export const CREATIVE_EDITOR_SDK_LICENSE = process.env.CREATIVE_EDITOR_SDK_LICENSE as string
export const CREATIVE_EDITOR_USER_ID = 'cus_JdoTG6CfptnnbW'
