import toCamelCase from 'utils/toCamelCase'
import { PPProduct, User, sortByKeyAscending } from 'shared'
import StoreState, { StoreThunkDispatch, StoreThunkAction, getState } from 'store/state'
import { getAccountInfo } from 'services/settings/actions'
import { impersonationSelector } from 'admin/services/selectors'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as api from './net'
import { trialTasksSelector } from './selectors'
import { TrainingVideo } from 'admin/interfaces'

export const trackTrainingEvent = createAsyncThunk(
  'training/trackEvent',
  async (args: { eventCode: string, value?: number }, { dispatch, getState }) => {
    const tasks = trialTasksSelector(getState() as StoreState)
    const task = tasks.find(task => task.code === args.eventCode)
    if (task?.completed) {
      return Promise.resolve()
    }
    await dispatch(api.trackEvent(args.eventCode, args.value)).toPromise()
  }
)
