import PlanState, { initialState } from './state'
import StoreState from 'store/state'
import { createSelector } from 'reselect'
import { sortByKeyAscending } from 'shared'

export const planSelector = (state: StoreState) => state.plan || initialState()
export const plansSelector = createSelector(planSelector, plan => plan.plans)
export const lastViewedPlanSelector = createSelector(planSelector, plan => plan.lastViewedPlan)
export const userTimezoneSelector = createSelector(planSelector, plan => plan.defaultTimezone)
export const allTimezonesSelector = createSelector(planSelector, plan => plan.timezones)
export const allTimezonesArraySelector = createSelector(planSelector, plan => Object.keys(plan.timezones).sort())
export const planViewExpandedSelector = createSelector(planSelector, plan => plan.planViewExpanded)
export const planToProfilesSelector = createSelector(planSelector, plan => plan.planToProfilesMap)
export const planToProfilesArraySelector = createSelector(planToProfilesSelector, map => {
  return Object.values(map).sort(sortByKeyAscending('order'))
})
