import { Component, createElement, ReactChild } from 'react'
import { AnyContent, STATUS_TYPE, Stream } from 'interfaces'
import AnyCard from 'components/Card'
import LoadingCard from 'components/Card/LoadingCard'
import CardList, { ITEM_WIDTH_DEFAULT, CARD_LIST_HEIGHT_DEFAULT } from 'components/CardList'
import styles from './CuratedFolderListView.pcss'

const LOADING_CARDS_COUNT = 16
export interface CuratedFolderListViewProps {
  stream: Stream
  loading: boolean
  items: AnyContent[]

  onScrolled?(stream: Stream, direction: 'left' | 'right'): void
  onItemPinned?(stream: Stream, content: AnyContent): void
  onItemShared?(stream: Stream, content: AnyContent): void
  onGotoClicked?(stream: Stream, content: AnyContent, by: string): void

  onScrollLimit?(): void
}
export class CuratedFolderListView extends Component<CuratedFolderListViewProps, any> {

  constructor(props: CuratedFolderListViewProps) {
    super(props)

    this.renderListItem = this.renderListItem.bind(this)
    this.onScrolled = this.onScrolled.bind(this)
    this.onItemShared = this.onItemShared.bind(this)
    this.onItemPinned = this.onItemPinned.bind(this)
    this.onGotoClicked = this.onGotoClicked.bind(this)
    this.onFeedClicked = this.onFeedClicked.bind(this)
  }

  onScrolled(direction: 'left' | 'right') {
    if (this.props.onScrolled) {
      this.props.onScrolled(this.props.stream, direction)
    }
  }

  onItemShared(content: AnyContent) {
    if (this.props.onItemShared) {
      this.props.onItemShared(this.props.stream, content)
    }
  }

  onItemPinned(content: AnyContent) {
    if (this.props.onItemPinned) {
      this.props.onItemPinned(this.props.stream, content)
    }
  }

  onGotoClicked(content: AnyContent, by: string) {
    if (this.props.onGotoClicked) {
      this.props.onGotoClicked(this.props.stream, content, by)
    }
  }

  onFeedClicked(content: AnyContent) {
    this.onGotoClicked(content, 'attribution')
  }

  renderLoading(): ReactChild[] {
    const active = this.props.loading
    return new Array(LOADING_CARDS_COUNT)
      .fill('')
      .map((_, index) => createElement(LoadingCard, { key: index, square: true, active, small: true }))
  }

  renderListContent() {
    const items = this.props.loading || this.props.items.length === 0
      ? this.props.items.map(this.renderListItem).concat(this.renderLoading())
      : this.props.items.map(this.renderListItem)

    return items
  }

  renderListItem(item: AnyContent): ReactChild {
    return createElement(AnyCard, {
      key: item.id,
      children: item,
      square: true,
      small: true,
      onCompose: this.onItemShared,
      ...(item.type === STATUS_TYPE
        ? {
          className: styles['status-card']
        }
        : {
          onPin: this.onItemPinned,
          onSourceClick: this.onGotoClicked,
          onFeedClick: this.onFeedClicked
        }
      )
    } as any)
  }

  render() {
    return createElement(CardList, {
      key: 'content',
      itemWidth: ITEM_WIDTH_DEFAULT,
      listHeight: CARD_LIST_HEIGHT_DEFAULT,
      containerClassName: styles.list,
      onScrolled: this.onScrolled,
      onScrollLimit: this.props.onScrollLimit
    }, this.renderListContent())
  }
}

export default CuratedFolderListView
