import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { initialState } from './state'
import {
  createFolder,
  deleteFile,
  deleteFolder,
  getStorageInfo,
  getUploads,
  reorderFolders,
  setEditFolder,
  setFileInPreview,
  setSelectedFile,
  setUploaderFile,
  setUploaderFiles,
  setUploadsDialogOpen,
  updateFolder,
  uploadFile
} from './actions'

const uploadsSlice = createSlice({
  name: 'uploads',
  initialState: initialState(),
  reducers: {
    setSorting: (state, action: PayloadAction<{ folderId: string, sortBy?: 'name' | 'date', sortDirection?: 'asc' | 'desc' }>) => {
      const { folderId, sortBy, sortDirection } = action.payload
      if (state.sorting[folderId]) {
        state.sorting[folderId].sortBy = sortBy || state.sorting[folderId].sortBy || 'date'
        state.sorting[folderId].sortDirection = sortDirection || state.sorting[folderId].sortDirection || 'desc'
      } else {
        state.sorting[folderId] = {
          sortBy: sortBy || 'date',
          sortDirection: sortDirection || 'desc'
        }
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(setSelectedFile, (state, action) => {
        state.selectedFile = action.payload
      })
      .addCase(getUploads.fulfilled, (state, action) => {
        state.folders = action.payload.folders
        Object.keys(action.payload.folders).forEach((id: string) => {
          if (!state.sorting[id]) {
            state.sorting[id] = {
              sortBy: 'date',
              sortDirection: 'desc'
            }
          }
        })
      })
      .addCase(getStorageInfo.fulfilled, (state, action) => {
        state.storage = action.payload.storage
      })
      .addCase(setFileInPreview, (state, action) => {
        state.preview = action.payload
      })
      .addCase(setUploaderFile, (state, action) => {
        state.uploaderFile = action.payload
      })
      .addCase(setUploadsDialogOpen, (state, action) => {
        state.uploadDialogOpen = action.payload
      })
      .addCase(setUploaderFiles, (state, action) => {
        state.uploadDialogFiles = action.payload
      })
      .addCase(setEditFolder, (state, action) => {
        state.editFolder = action.payload
      })
      .addCase(deleteFile.pending, (state, action) => {
        const fileToDelete = state.folders[action.meta.arg.file.folderId]?.files[action.meta.arg.file.key]
        if (fileToDelete) {
          fileToDelete.loading = true
        }
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        const folder = state.folders[action.payload.file.folderId]
        if (folder) {
          folder.filesCount -= 1
          folder.loading = false
          if (folder.filesCountByType) {
            folder.filesCountByType[action.payload.file.type] -= 1

            if (folder.filesCountByType[action.payload.file.type] === 0) {
              folder.filters.splice(folder.filters.indexOf(action.payload.file.type), 1)
            }
          }
          delete folder.files[action.payload.file.key]
        }
      })
      .addCase(deleteFile.rejected, (state, action) => {
        const fileToDelete = state.folders[action.meta.arg.file.folderId]?.files[action.meta.arg.file.key]
        if (fileToDelete) {
          fileToDelete.loading = false
        }
      })
      .addCase(reorderFolders.fulfilled, (state, action) => {
        action.payload.ids.forEach((id, index) => {
          state.folders[id].order = index
        })
      })
      .addCase(updateFolder.pending, (state, action) => {
        state.folders[action.meta.arg.id].loading = true
      })
      .addCase(updateFolder.rejected, (state, action) => {
        state.folders[action.meta.arg.id].loading = false
      })
      .addCase(deleteFolder.fulfilled, (state, action) => {
        delete state.folders[action.payload.id]
      })
      .addCase(updateFolder.fulfilled, (state, action) => {
        state.folders[action.payload.id] = {
          ...state.folders[action.payload.id],
          ...action.payload,
          loading: false
        }
      })
      .addCase(uploadFile.pending, (state, action) => {
        const uploadFolderId = action.meta.arg.folder.id
        if (uploadFolderId && state.folders[uploadFolderId]) {
          state.folders[uploadFolderId].loading = true
        }
      })
      .addCase(uploadFile.rejected, (state, action) => {
        const uploadFolderId = action.meta.arg.folder.id
        if (uploadFolderId && state.folders[uploadFolderId]) {
          state.folders[uploadFolderId].loading = false
        }
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        const folder = state.folders[action.payload.folderId]
        const createdFile = action.payload.file

        if (folder) {
          folder.loading = false
          folder.filesCount += 1
          folder.files[createdFile.key] = createdFile
          folder.filters = folder.filters.includes(createdFile.type)
            ? folder.filters
            : [...folder.filters, createdFile.type]
          if (folder.filesCountByType) {
            if (typeof folder.filesCountByType[createdFile.type] === 'number') {
              folder.filesCountByType[createdFile.type] += 1
            } else {
              folder.filesCountByType[createdFile.type] = 1
            }
          }
        } else {
          state.folders[action.payload.folderId] = {
            id: action.payload.folderId,
            name: action.payload.folderName as string,
            color: action.payload.folderColor as string,
            filesCount: 1,
            isFileFolder: true,
            isPrivate: Boolean(action.payload.folderPrivate),
            filters: [createdFile.type],
            files: {
              [createdFile.key]: createdFile
            },
            order: Object.keys(state.folders).length
          }
        }
      })
      .addCase(createFolder.fulfilled, (state, action) => {
        const folder = action.payload.folder
        state.folders[folder.id] = { ...folder, isFileFolder: true, order: Object.keys(state.folders).length }
      })
  }
})

export const { setSorting } = uploadsSlice.actions

export default uploadsSlice.reducer
