import { V1 } from './net'
import { Observable } from 'rxjs/Observable'
import { getState, StoreThunkAction, StoreThunkDispatch } from 'store/state'
import {
  Article,
  ARTICLE_TYPE,
  ContentItem,
  Gif,
  GIF_TYPE,
  Photo,
  PHOTO_TYPE,
  PostDestinationType,
  Video,
  VIDEO_TYPE
} from 'interfaces'
import {
  aiWriterPromptIdSelector,
  composerBulkFileUrlSelector,
  composerContentItemSelector,
  composerImageUrlsSelector,
  composerLinkSelector,
  composerPostAsPhotoSelector,
  composerRecycleSelector,
  composerScheduleSelector,
  composerSelectedProfilesSelector,
  composerVideoThumbnailOffsetSelector,
  composerVideoThumbnailSelector,
  composerVideoUrlSelector,
  composerPostSelector,
  featuredImageUrlSelector,
  pinterestProfilesBoardsSelector
} from '../selectors'
import { ComposerFacebookPost, ComposerInstagramPost, ComposerPinterestPost, ComposerTwitterPost } from '../interfaces'
import { getFBPostTextFromHtml, getTagsInfoFromHtml, getLinkedinFormattedText } from 'utils/composer'
import { tap } from 'rxjs/operators/tap'
import { createPostFailure, createPostRequest, createPostSuccess, trackPostCreated } from './actions'
import { defaultTimezoneSelector } from 'services/settings/selectors'
import { getPostScheduleFromComposerState } from '../utils'
import { UploaderFilesState } from 'components/Composer/UploadContext'
import { ComposerGooglePost } from '../interfaces/ComposerGooglePost'
import { getBuckets } from 'services/post/actions'
import {
  BRAND_TIKTOK,
  BRAND_YOUTUBE,
  CreatePostRequestData,
  BRAND_FACEBOOK,
  BRAND_INSTAGRAM,
  BRAND_LINKEDIN,
  BRAND_PINTEREST,
  BRAND_TWITTER,
  BRAND_GOOGLE
} from 'shared'
import { ComposerTikTokPost } from '../interfaces/ComposerTikTokPost'
import { ComposerYoutubePost } from '../interfaces/ComposerYoutubePost'
import { ComposerLinkedinPost } from '../interfaces/ComposerLinkedinPost'

export function getPostData(
  shareType?: 'now' | 'next' | 'queue',
  scheduled?: boolean,
  uploads?: UploaderFilesState
): StoreThunkAction<CreatePostRequestData> {
  return (_dispatch: StoreThunkDispatch, getState: getState) => {
    const state = getState()
    const recycle = composerRecycleSelector(state)
    const profiles = composerSelectedProfilesSelector(state)
    const photos = composerImageUrlsSelector(state).slice() // copy the array so we can sort it
    const videoUrl = composerVideoUrlSelector(state)
    const videoThumbnail = composerVideoThumbnailSelector(state)
    const videoThumbnailOffset = composerVideoThumbnailOffsetSelector(state)
    const contentItem = composerContentItemSelector(state)
    const bulkUploadFileUrl = composerBulkFileUrlSelector(state)
    const link = composerLinkSelector(state)
    const featuredImageUrl = featuredImageUrlSelector(state)
    const pinterestBoards = pinterestProfilesBoardsSelector(state)
    const networks: PostDestinationType[] = []
    const ppPageIds: number[] = []
    const composerSchedule = composerScheduleSelector(state)
    const timezone = defaultTimezoneSelector(state)
    const postAsPhoto = composerPostAsPhotoSelector(state)
    const aiWriterPromptId = aiWriterPromptIdSelector(state)

    const files: any = Object.values(uploads || {}).reduce((map: Record<string, any>, file: any) => {
      if (file.url) {
        map[file.url] = file.order
      }
      return map
    }, {})

    if (Object.keys(files).length > 0 && photos.length > 0) {
      photos.sort((url1, url2) => {
        if (files[url1] < files[url2]) {
          return -1
        }
        return 1
      })
    }

    profiles.forEach(profile => {
      ppPageIds.push(parseInt(profile.ppid, 10)) // eslint-disable-line no-magic-numbers
      if (!networks.includes(profile.type)) {
        networks.push(profile.type)
      }
    })

    const data: CreatePostRequestData = {
      postType: 'status',
      ppPageIds,
      networks: {}
    }

    if (shareType) {
      data.shareType = shareType
    }

    if (featuredImageUrl) {
      data.featuredImageUrl = featuredImageUrl
    }

    if (scheduled) {
      data.schedule = getPostScheduleFromComposerState(composerSchedule, timezone)
    }

    if (videoUrl) {
      data.postType = 'video'
      data.videoUrl = videoUrl
      if (videoThumbnail) {
        data.videoThumbnail = videoThumbnail
      }
      if (videoThumbnailOffset) {
        data.videoThumbnailOffset = videoThumbnailOffset
      }
    }

    if (recycle) {
      data.recycle = true
      if (typeof recycle === 'object') {
        data.recycleUntil = recycle.toISOString()
      } else if (typeof recycle === 'number') {
        data.recycleTimes = recycle
      }
    }

    if (bulkUploadFileUrl) {
      data.postType = 'bulk'
      data.bulkUploadFileUrl = bulkUploadFileUrl
    }

    if (photos.length > 0) {
      data.postType = 'photo'
      data.photos = photos
    }

    if (contentItem) {
      let date = (contentItem as ContentItem).createdAt
      if (typeof date === 'number' || typeof date === 'string') {
        date = new Date(date).toISOString()
        data.sourcePost = { date }
      } else {
        data.sourcePost = { date: date.toISOString() }
      }
      data.sourcePost.feedName = contentItem.feed.name
      data.sourcePost.feedType = contentItem.feed.type
      data.sourcePost.socialLink = contentItem.socialLink
      data.sourcePost.sourceLink = (contentItem as any).sourceLink || contentItem.socialLink
    }

    if (contentItem?.type === PHOTO_TYPE) {
      data.postType = 'photo'
      data.photos = [(contentItem as Photo).imageUrl]
    } else if (contentItem?.type === ARTICLE_TYPE && link?.type !== 'link') {
      data.postType = postAsPhoto ? 'photo' : 'link'
      data.photos = [(contentItem as Photo).imageUrl]
      data.link = {
        title: (contentItem as Article).title,
        description: (contentItem as Article).description,
        url: (contentItem as Article).sourceLink
      }
    } else if (contentItem?.type === VIDEO_TYPE) {
      data.postType = 'video_link'
      data.photos = [(contentItem as Video).imageUrl]
      data.link = {
        title: (contentItem as Video).title,
        description: (contentItem as Video).description,
        url: contentItem.socialLink
      }
    } else if (contentItem?.type === GIF_TYPE) {
      const gifUrl = (contentItem as Gif).gifUrl.split('?')[0]
      const ext = gifUrl.substring(gifUrl.lastIndexOf('.')).toLowerCase()
      data.postType = 'gif'
      // NOTE: Twitter gifs are actually mp4 videos
      if (ext === '.mp4') {
        data.postType = 'video'
        data.videoUrl = gifUrl
      }
    } else if (link) {
      data.postType = postAsPhoto ? 'photo' : link.type
      data.link = {
        title: link.title,
        url: link.url,
        description: link.description
      }
      data.photos = !data.photos || data.photos.length === 0 ? [link.image[0]] : data.photos
    }

    if (aiWriterPromptId) {
      data.sourceFeature = `prompt_${aiWriterPromptId}`
    }

    networks.forEach(network => {
      const post = composerPostSelector(state, network)

      if (network === BRAND_FACEBOOK) {
        const status = getFBPostTextFromHtml(post.status.html)
        const tags = getTagsInfoFromHtml(post.status.html)
        const facebookPost: any = { status, details: {} }
        const selectedAlbums = (post as ComposerFacebookPost).albums
        const firstComment = (post as ComposerFacebookPost).firstComment
        const postType = (post as ComposerFacebookPost).postType
        const textBackground = (post as ComposerFacebookPost).textBackground

        if (textBackground) {
          facebookPost.details.textBackground = textBackground
        }

        if (postType) {
          facebookPost.details.postType = postType
        }
        if (firstComment) {
          facebookPost.details.firstComment = firstComment
        }
        if (tags.length > 0) {
          facebookPost.details.tags = tags
        }
        if (selectedAlbums.length > 0) {
          facebookPost.details.albums = selectedAlbums.map(a => ({ ...a, ppPageId: a.ppid }))
        }
        // NOTE: Targeting is disabled for now
        // if ((post as ComposerFacebookPost).targeting.selectedTargets.length > 0) {
        //   facebookPost.details.targeting = (post as ComposerFacebookPost).targeting.selectedTargets
        // }
        if ((post as ComposerFacebookPost).reshare) {
          facebookPost.reshare = true
          if (contentItem?.feed.type === BRAND_FACEBOOK) {
            facebookPost.details.reshareUrl = contentItem.socialLink
            facebookPost.details.reshareName = contentItem.feed.name
          }
        }
        data.networks.facebook = facebookPost
      } else if (network === BRAND_TWITTER) {
        const status = post.status.text
        const twitterPost: any = {
          status,
          details: {
            tweetType: (post as ComposerTwitterPost).tweetType
          }
        }
        if ((contentItem as ContentItem)?.feed.type === BRAND_TWITTER) {
          twitterPost.details.tweetUrl = (contentItem as ContentItem).socialLink
          data.sourcePost = data.sourcePost || {}
          data.sourcePost.feedName = (contentItem as ContentItem).feed.name
          data.sourcePost.text = (contentItem as ContentItem).tweetText
        }
        data.networks.twitter = twitterPost
      } else if (network === BRAND_INSTAGRAM) {
        const instagramPost: any = { status: post.status.text, details: {} }
        const { firstComment, postType: igPostType, locations, tags } = post as ComposerInstagramPost
        const locationsArray = Object.keys(locations).reduce((arr: any[], ppid) => {
          if (locations[ppid]) {
            arr.push({ ...locations[ppid], ppPageId: ppid })
          }
          return arr
        }, [])
        if (firstComment) {
          instagramPost.details.firstComment = firstComment
        }
        if (locationsArray.length > 0) {
          instagramPost.details.locations = locationsArray
        }
        if (igPostType) {
          instagramPost.details.ig_post_type = igPostType
        }
        if (tags && !videoUrl && photos.length === 1) {
          instagramPost.details.photoTags = tags
        }
        if (Object.keys(instagramPost.details).length === 0) {
          delete instagramPost.details
        }
        data.networks.instagram = instagramPost
      } else if (network === BRAND_PINTEREST) {
        const pin = post as ComposerPinterestPost

        const pinterestPost: any = {
          status: pin.title,
          description: pin.description,
          destinationUrl: postAsPhoto ? undefined : pin.destinationUrl,
          details: { boards: [] }
        }
        if (Object.keys(pinterestBoards).length > 0) {
          const boardsArray = Object.keys(pinterestBoards).reduce((arr: any[], ppid: string) => {
            const selectedBoard = pinterestBoards[ppid].find(b => b.selected)
            if (selectedBoard) {
              arr.push({
                id: selectedBoard.id,
                name: selectedBoard.name,
                ppPageId: ppid,
                url: selectedBoard.url
              })
            }

            return arr
          }, [])
          pinterestPost.details.boards = boardsArray
        }
        data.networks.pinterest = pinterestPost
      } else if (network === BRAND_LINKEDIN) {
        const { tags, text } = getLinkedinFormattedText(post.status.html)
        const firstComment = (post as ComposerLinkedinPost).firstComment
        const doc = (post as ComposerLinkedinPost).document

        const linkedInPost: any = {
          status: post.status.text,
          details: { tags, text }
        }
        if (firstComment) {
          linkedInPost.details.firstComment = firstComment
        }
        if (doc) {
          linkedInPost.details.documentUrl = doc.url
          linkedInPost.details.documentName = doc.name
        }
        data.networks.linkedin = linkedInPost
      } else if (network === BRAND_GOOGLE) {
        data.networks.google = {
          status: post.status.text,
          details: {
            buttonUrl: (post as ComposerGooglePost).link || '',
            buttonType: (post as ComposerGooglePost).buttonType
          }
        }
      } else if (network === BRAND_TIKTOK) {
        data.networks.tiktok = {
          status: post.status.text,
          details: {
            settings: Object.values((post as ComposerTikTokPost).settings)
          }
        }
      } else if (network === BRAND_YOUTUBE) {
        const ytPost = post as ComposerYoutubePost
        data.networks.youtube = {
          status: ytPost.title,
          description: ytPost.description,
          firstComment: ytPost.firstComment,
          details: {
            privacyStatus: ytPost.privacyStatus
          }
        }
      }
    })
    return data
  }
}

export function createPost(data: CreatePostRequestData, bucketId?: string, withContent?: boolean): StoreThunkAction<Observable<any>> {
  return (dispatch) => {
    const id = Date.now().toString()
    dispatch(createPostRequest({ post: data, id }))
    return dispatch(V1.createPost(data as any, bucketId)).pipe(
      tap((response: any) => {
        dispatch(trackPostCreated(data, response, bucketId, withContent))
        dispatch(createPostSuccess({ post: data, id }))
        if (bucketId) {
          dispatch(getBuckets())
        }
      }, (error) => {
        dispatch(createPostFailure({ error: error.response?.error || error, source: { post: data, id } }))
      })
    )
  }
}
