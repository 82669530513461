import { Observable } from 'rxjs/Observable'
import { StoreThunkAction, StoreThunkDispatch, getState } from 'store/state'
import * as net from './net'
import { tap } from 'rxjs/operators/tap'
import { setProducts, formatProducts } from 'services/product'
import { PPProduct } from 'interfaces'
import { accessTokenSelector } from 'services/users/selectors'
import { catchError } from 'rxjs/operators/catchError'

export function createAccount(
  firstName: string,
  lastName: string,
  email: string,
  captchaToken: string,
  productHandle?: string,
  fbId?: string,
  securityCode?: string,
  teamHash?: string
): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(net.createAccount(firstName, lastName, email, captchaToken, productHandle, fbId, securityCode, teamHash))
      .pipe(
        tap((response: any) => {
          if (response.subscriptions?.products) {
            const products = formatProducts(response.subscriptions.products as PPProduct[])
            dispatch(setProducts(products))
          }
        }),
        catchError((e) => Observable.of({ error: e.response.error }))
      )
  }
}

export function checkout(
  user: {
    firstName: string
    lastName: string
    email: string,
    loginOtt?: string
  },
  productHandle: string,
  chargifyToken: string,
  currency: string,
  couponCode?: string,
  referralCode?: string
) {
  return (dispatch: StoreThunkDispatch, getState: getState) => {
    const isLoggedIn = Boolean(accessTokenSelector(getState()))
    return dispatch(net.checkout(user, productHandle, chargifyToken, currency, isLoggedIn, couponCode, referralCode))
      .pipe(catchError((e) => Observable.of({ error: e.response.error })))
  }
}

export function validateCouponCode(code: string, productHandle: string) {
  return (dispatch: StoreThunkDispatch) => dispatch(net.validateCouponCode(code, productHandle)).map(response => ({ ...response, code }))
}

export function validateReferralCode(code: string) {
  return (dispatch: StoreThunkDispatch) => dispatch(net.validateReferralCode(code)).map(response => ({ ...response, code }))
}
