import * as React from 'react'
import { PP_HOTJAR_SITE_ID } from 'config'
import { connect } from 'react-redux'
import StoreState from 'store/state'
import { currentUserSelector } from 'services/users/selectors'

function injectTrackingScript() {
  return (function (h: any, o: any, t: any, j: any) {
    h.hj = h.hj || function () {
      (h.hj.q = h.hj.q || []).push(arguments)
    }
    h._hjSettings = { hjid: PP_HOTJAR_SITE_ID, hjsv: 6 }
    const a: any = o.getElementsByTagName('head')[0]
    const r: any = o.createElement('script')
    r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)
  })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=')
}

function identifyUser(userId: string) {
  (window as any).hj('identify', userId, {
    // Custom attributes list
    user_id: userId
  })
}

export function HotjarTracker(props: { userId?: string }) {
  const [identified, setIdentified] = React.useState(false)
  const [initialized, setInitialized] = React.useState(false)

  React.useEffect(() => {
    if (!initialized) {
      injectTrackingScript()
      setInitialized(true)
    }

    if (props.userId && !identified) {
      identifyUser(props.userId)
      setIdentified(true)
    }
  }, [props.userId])

  return null
}

function mapStateToProps(state: StoreState) {
  return {
    userId: currentUserSelector(state)?.userId
  }
}

export default connect(mapStateToProps)(HotjarTracker)
