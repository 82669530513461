import * as React from 'react'
import { PHOTO_EDITOR_CONFIG_BASE } from './editor-config'
import styles from './PhotoEditor.pcss'

declare const PhotoEditorSDK: any

interface PhotoEditorProps {
  image: string | HTMLImageElement // image file url, data-url or <img> element
  fileName?: string
  config?: any
  className?: string
  onExport: (src: string) => void
  onError: (error: Error) => void
  onInitialized?: (editorApi: any) => void
  onClose?: () => void
}

export function PhotoEditor(props: PhotoEditorProps) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const editorRef = React.useRef<any>(null)

  React.useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setImage(props.image)
    }
  }, [props.image])

  async function initEditor(container: HTMLDivElement) {
    const overrides = props.config || {}
    try {
      editorRef.current = await PhotoEditorSDK.PhotoEditorSDKUI.init({
        ...PHOTO_EDITOR_CONFIG_BASE,
        ...overrides,
        container,
        image: props.image,
        export: {
          ...PHOTO_EDITOR_CONFIG_BASE.export,
          filename: props.fileName
        }
      })

      if (props.onInitialized) {
        props.onInitialized(editorRef.current)
      }

      editorRef.current.on(PhotoEditorSDK.UIEvent.EXPORT, props.onExport)
      editorRef.current.on(PhotoEditorSDK.UIEvent.CLOSE, () => {
        if (props.onClose) {
          props.onClose()
        }
      })
      editorRef.current.on(PhotoEditorSDK.UIEvent.ERROR_IMAGE_LOADING, props.onError)
    } catch (e) {
      console.log(e)
      props.onError(new Error('PhotoEditor initialization failed!'))
    }
  }

  React.useEffect(() => {
    if (containerRef.current) {
      initEditor(containerRef.current)
    }

    return () => {
      if (editorRef.current) {
        editorRef.current.dispose()
      }
    }
  }, [])

  return (
    <div className={`${styles['editor-box']} ${props.className || ''}`} ref={containerRef}></div>
  )
}
