import { Subscriber } from 'rxjs/Subscriber'
import { StoreThunkDispatch } from 'store/state'
import { INPUT_ACCEPT_PHOTO, INPUT_ACCEPT_VIDEO } from 'utils/file'
import { getMediaDimensions, getVideoDuration } from 'utils/file/helpers'
import { V1 } from './net'
import { WritableDraft } from 'immer/dist/internal'
import ComposerState from '../state'
import { ActionReducerMapBuilder, PayloadAction, createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { GIF_TYPE, PHOTO_TYPE, STOCK_GIF_TYPE, STOCK_PHOTO_TYPE } from 'shared/constants'
import { Photo } from 'interfaces'

export const mediaReducers = {
  setComposerImages: (state: WritableDraft<ComposerState>, action: PayloadAction<string[]>) => {
    state.imageUrls = action.payload
    state.featuredImageUrl = action.payload[0]
    state.contentItem = undefined
    state.videoUrl = undefined
    state.videoThumbnailUrl = undefined
    state.videoThumbnailOffset = undefined
    state.videoDuration = undefined
    state.videoSize = undefined
    state.videoMetadata = undefined
    state.links = []
    state.posts.facebook.textBackground = undefined
  },
  setComposerVideoThumbnail: (state: WritableDraft<ComposerState>, action: PayloadAction<string>) => {
    state.videoThumbnailUrl = action.payload
    state.contentItem = undefined
  },
  setComposerVideoThumbnailOffset: (state: WritableDraft<ComposerState>, action: PayloadAction<number>) => {
    state.videoThumbnailOffset = action.payload
    state.contentItem = undefined
  },
  removeComposerFileUrl: (state: WritableDraft<ComposerState>, action: PayloadAction<string>) => {
    if (state.videoUrl === action.payload) {
      state.videoUrl = undefined
      state.videoThumbnailUrl = undefined
      state.videoThumbnailOffset = undefined
      state.videoDuration = undefined
      state.videoSize = undefined
      state.videoMetadata = undefined
    } else {
      const nextImageUrls = [...state.imageUrls]
      const index = nextImageUrls.indexOf(action.payload)
      nextImageUrls.splice(index, 1)
      // If removing image of content item, clear the content too
      const withImageContentItem = state.contentItem?.type === PHOTO_TYPE
        || state.contentItem?.type === STOCK_PHOTO_TYPE
        || state.contentItem?.type === GIF_TYPE
        || state.contentItem?.type === STOCK_GIF_TYPE
      const shouldClearContentItem = withImageContentItem && action.payload === (state.contentItem as Photo)?.imageUrl
      const featuredImageUrl = state.featuredImageUrl && nextImageUrls.includes(state.featuredImageUrl) ? state.featuredImageUrl : undefined

      state.imageUrls = nextImageUrls
      state.featuredImageUrl = featuredImageUrl
      if (shouldClearContentItem) {
        state.contentItem = undefined
      }
    }
  },
  clearComposerFiles: (state: WritableDraft<ComposerState>) => {
    state.videoUrl = undefined
    state.videoThumbnailUrl = undefined
    state.videoThumbnailOffset = undefined
    state.featuredImageUrl = undefined
    state.imageUrls = []
    state.videoDuration = undefined
    state.videoMetadata = undefined
    state.videoSize = undefined
  },
  setComposerBulkUploadFileUrl: (
    state: WritableDraft<ComposerState>,
    action: PayloadAction<{ url: string | undefined, name?: string, rows?: number }>
  ) => {
    state.bulkUploadFile = action.payload.url ? {
      url: action.payload.url,
      rows: action.payload.rows || 0,
      name: action.payload.name || ''
    } : undefined
  }
}

export const setComposerVideoUrl = createAction<string>('composer/setVideoUrl')
export const addComposerImages = createAction<string[]>('composer/addImages')

export const mediaExtraReducers = (builder: ActionReducerMapBuilder<ComposerState>) => {
  builder
    .addCase(setComposerVideoMetadata.fulfilled, (state, action) => {
      const { duration, metadata, size } = action.payload
      state.videoDuration = state.videoUrl ? duration : undefined
      state.videoMetadata = metadata ? { ...state.videoMetadata, ...metadata } : undefined
      state.videoSize = size && state.videoUrl ? size : undefined
    })
    .addCase(setComposerVideoUrl, (state, action) => {
      state.videoUrl = action.payload
      state.imageUrls = []
      state.featuredImageUrl = undefined
      state.contentItem = undefined
      state.links = []
      state.posts.facebook.textBackground = undefined
    })
    .addCase(addComposerImages, (state, action) => {
      const urlsToAdd = action.payload.filter(url => !state.imageUrls.includes(url))
      const images = [...state.imageUrls, ...urlsToAdd]
      if (!state.featuredImageUrl) {
        state.featuredImageUrl = images[0]
      } else if (!images.includes(state.featuredImageUrl as string)) {
        state.featuredImageUrl = undefined
      }

      state.imageUrls = images
      state.contentItem = undefined
      state.videoUrl = undefined
      state.videoThumbnailUrl = undefined
      state.videoThumbnailOffset = undefined
      state.videoDuration = undefined
      state.videoSize = undefined
      state.videoMetadata = undefined
      state.links = []
      state.posts.facebook.textBackground = undefined
    })
}

export const setComposerVideoMetadata = createAsyncThunk('composer/setVideoMetadata', async (url: string, { rejectWithValue }) => {
  try {
    const duration = await getVideoDuration(url)
    const dimensions = getMediaDimensions('video', '[data-testid="composer-media"] video')
    const fileType = url.split('?')[0].substring(url.lastIndexOf('.') + 1).toLowerCase()
    const metadata: any = dimensions ? { ...dimensions } : {}
    metadata.fileType = fileType
    const response = await fetch(url, { method: 'head' })
    const size = response.headers.get('content-length')

    return {
      duration,
      metadata,
      size: size ? parseInt(size, 10) : undefined
    }
  } catch (e) {
    console.log('Could not get video metadata', e)
    return rejectWithValue(e)
  }
})

export function setComposerFile(url: string) {
  return (dispatch: StoreThunkDispatch) => {
    const linkExt = url.split('?')[0].substring(url.lastIndexOf('.'))
    const isImageLink = INPUT_ACCEPT_PHOTO.includes(linkExt?.toLowerCase())
    if (isImageLink) {
      return dispatch(addComposerImages([url]))
    }

    const isNativeVideoLink = INPUT_ACCEPT_VIDEO.includes(linkExt?.toLowerCase() + '')
    if (isNativeVideoLink) {
      return dispatch(setComposerVideoUrl(url))
    }
    return null
  }
}

export function uploadFile(file: File, progressSubscriber?: Subscriber<any>) {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.uploadFile(file, progressSubscriber))
}

export function uploadPreview(file: File, videoUrl: string) {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.uploadPreview(file, videoUrl))
}

export function deleteFile(url: string) {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.deleteFile(url))
}
