import * as React from 'react'
import styles from './GooglePostPreview.pcss'
import { PostPreviewDefaultHeader } from './PostPreviewDefaultHeader'
import PlayIcon from '@mui/icons-material/PlayArrowRounded'
import { PostPreviewVideoPlayer } from './PostPreviewVideoPlayer'
import PostPreviewText from './PostPreviewText'
import { GooglePostButtonType } from 'services/compose/interfaces/ComposerGooglePost'

const MAX_LINES = 4
const TRUNCATE_TEXT = 'more'
const COLLAPSE_TEXT_LABEL = 'less'

interface GooglePostPreviewProps {
  profile?: { name: string, image: string }
  featuredImageUrl?: string
  statusText?: string
  empty?: boolean
  videoUrl?: string
  videoThumbnailUrl?: string
  buttonType?: GooglePostButtonType
  buttonLink?: string
}

const labels = {
  BOOK: 'BOOK',
  ORDER: 'ORDER ONLINE',
  SHOP: 'BUY',
  LEARN_MORE: 'LEARN MORE',
  SIGN_UP: 'SIGN UP',
  CALL: 'CALL'
}

export function GooglePostPreview(props: GooglePostPreviewProps) {
  const isEmpty = props.empty || (!props.statusText && !props.featuredImageUrl)

  return (
    <div className={`${styles.post} ${!props.featuredImageUrl ? styles['no-img'] : ''} ${isEmpty ? styles.empty : ''}`}>
      <PostPreviewDefaultHeader profile={props.profile} />
      {props.videoUrl && (
        <PostPreviewVideoPlayer
          videoUrl={props.videoUrl}
          thumbnailUrl={props.videoThumbnailUrl}
          icon={<PlayIcon className={`${styles['icon-centered']} ${styles.icon}`} />}
        />
      )}
      {props.featuredImageUrl && (
        <div className={styles['img-box']}>
          <img src={props.featuredImageUrl} />
        </div>
      )}
      <footer className={`${styles.footer} ${!props.statusText ? styles['text-empty'] : ''}`}>
        {props.statusText && (
          <PostPreviewText
            text={props.statusText || ''}
            maxLinesDefault={MAX_LINES}
            expandLabel={TRUNCATE_TEXT}
            collapseLabel={COLLAPSE_TEXT_LABEL}
            className={styles['g-text']}
          />
        )}
        {props.buttonType && (
          <a href={props.buttonLink} target="_blank" className={styles['g-btn']}>
            {labels[props.buttonType]}
          </a>
        )}
      </footer>
    </div>
  )
}
