import * as React from 'react'
const icon = require('static/img/icons/stream-icon.svg')
const iconWhite = require('static/img/icons/stream-icon-white.svg')
const DEFAULT_SIZE = 40

export function StreamIcon({ className, size, white }: { className?: string, size?: number, white?: boolean }) {
  const cssClass = className || ''
  const iconSize = size || DEFAULT_SIZE
  const style = { width: iconSize + 'px', height: iconSize + 'px' }
  const src = white ? iconWhite : icon

  return <img src={src} className={cssClass} style={style} />
}

export default StreamIcon
