import * as React from 'react'
import styles from './BucketCreatePopup.pcss'
import { Bucket, WithIntl } from 'interfaces'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import { FormattedMessage, injectIntl } from 'react-intl'
import { EditableBucket } from '../EditableBucket'
import { useDispatch, useSelector } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { createBucket } from 'services/post/actions'
import { message } from 'services/snackbar'
import { connectedDestinationsSelector } from 'services/destinations'
import { bucketsSelector } from 'services/post/selectors'
import { Subject } from 'rxjs/Subject'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { defaultBucketColor } from 'utils/colors'
import { checkProductLimit } from 'services/product'
import { LIMIT_POST_BUCKETS } from 'shared/constants'
import BucketProfilesPicker from '../BucketProfilesPicker'
import { BucketOptions } from '../BucketOptions'
import { DateRange } from '@mui/x-date-pickers-pro'
import PPSwitch from 'components/PPSwitch'
import { BucketCard } from 'components/Buckets'
import { add, format } from 'date-fns'

interface BucketCreatePopupProps {
  open: boolean
  init?: {
    name: string
    description: string
    color: string
  }
  onCreated?: (bucket: Bucket) => void
  onClose: () => void
}

const newBucket: Omit<Bucket, 'pages' | 'postsCount'> = Object.freeze({
  id: 'new',
  name: '',
  description: '',
  color: defaultBucketColor,
  ppids: [],
  isPublic: false,
  isActive: false,
  order: 0
})

export function BucketCreatePopup(props: BucketCreatePopupProps & WithIntl) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const now = new Date()
  // eslint-disable-next-line no-magic-numbers
  const [dateRange, setDateRange] = React.useState<DateRange<Date>>([now, add(now, { days: 30 })])
  const [withTimePeriod, setWithTimePeriod] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [bucket, setBucket] = React.useState<Omit<Bucket, 'pages' | 'postsCount'>>(newBucket)
  const [selectedProfileIds, setSelectedProfileIds] = React.useState<string[]>([])
  const profiles = useSelector(connectedDestinationsSelector)
  const buckets = useSelector(bucketsSelector)

  React.useEffect(() => {
    if (props.init) {
      setBucket(current => ({ ...current, ...props.init }))
    }
  }, [props.init])

  React.useEffect(() => {
    if (!props.open) {
      setBucket(newBucket)
      setSelectedProfileIds([])
    }
  }, [props.open])

  const onChange = React.useCallback((b: Partial<Bucket>) => {
    setBucket(current => ({ ...current, ...b }))
  }, [])

  const onCreate = () => {
    const name = bucket.name.trim()
    if (!name) {
      dispatch(message(props.intl.formatMessage({ id: 'post.buckets.form.error-name-empty' }), 'warning'))
      return
    }
    if (buckets.find(b => b.name.toLowerCase() === name.toLowerCase())) {
      dispatch(message(props.intl.formatMessage({ id: 'post.buckets.notification-bucket-exists' }), 'warning'))
      return
    }
    const isWithinLimit = dispatch(checkProductLimit(LIMIT_POST_BUCKETS, buckets.length + 1))
    if (!isWithinLimit) {
      return
    }
    const ppids = selectedProfileIds.map(id => profiles[id].ppid)
    let from: string | undefined = dateRange[0] ? format(dateRange[0], 'yyyy-LL-dd') : undefined
    let to: string | undefined = dateRange[1] ? format(dateRange[1], 'yyyy-LL-dd') : undefined
    if (!withTimePeriod) {
      from = undefined
      to = undefined
    }
    const description = bucket.description?.trim() || `Sharing posts with ${name}`
    setLoading(true)
    dispatch(createBucket({
      name,
      color: bucket.color,
      description,
      isPublic: Boolean(bucket.isPublic),
      ppids,
      activeFrom: from,
      activeTo: to
    }))
      .unwrap()
      .then(bucket => {
        dispatch(message(props.intl.formatMessage({ id: 'post.buckets.notification-bucket-created' }), 'success'))
        if (props.onCreated) {
          props.onCreated(bucket)
        }
      })
      .catch(() => {
        dispatch(message(props.intl.formatMessage({ id: 'post.notifications.error-generic' }), 'error'))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const toggleWithTimePeriod = () => {
    setWithTimePeriod(!withTimePeriod)
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} disableEnforceFocus>
      <div className={styles.container}>
        <div className={styles.top}>
          <div>
            <h2 className={styles['main-title']}>
              <FormattedMessage id="post.buckets.form.title-create" />
            </h2>
          </div>
          <BucketCard bucket={bucket as any} noSelection className={styles['bucket-card']} />
          <div className={styles['section-title']}>
            <span className={styles.step}>1</span>
            <FormattedMessage id="post.buckets.form.title-name-color" />
          </div>
          <EditableBucket
            bucket={bucket}
            loading={loading}
            onChange={onChange}
          />
        </div>
        <div className={styles.bottom}>
          <div>
            <div className={styles['section-title']}>
              <span className={styles.step}>2</span>
              <FormattedMessage id="post.buckets.form.title-select-profiles" />
            </div>
            <div className={styles.subtitle}>
              <FormattedMessage id="post.buckets.form.subtitle-select-profiles" />
            </div>
          </div>
          <BucketProfilesPicker
            allSelectedInitial
            selectedProfileIds={selectedProfileIds}
            onSelectedProfilesChange={setSelectedProfileIds}
          />

          <div className={styles.options}>
            <div className={styles['section-title']}>
              <span className={styles.step}>3</span>
              <FormattedMessage id="post.buckets.form.title-time-period" />
            </div>
            <div style={{ marginLeft: '40px' }}>
              <PPSwitch
                selectedValue={withTimePeriod ? 'on' : 'off'}
                options={[{ value: 'off', label: 'Always' }, { value: 'on', label: 'Custom time period' }]}
                className={styles.switch}
                onSelectedValueChange={toggleWithTimePeriod}
              />
              <BucketOptions
                dateRange={dateRange}
                disabled={!withTimePeriod}
                onChange={setDateRange}
              />
            </div>
          </div>

          <div className={styles.actions}>
            <Button variant="text" onClick={props.onClose}>
              <FormattedMessage id="actions.cancel" />
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={styles['btn-save']}
              disabled={loading || selectedProfileIds.length === 0}
              onClick={onCreate}
            >
              <FormattedMessage id="actions.save-bucket" />
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default injectIntl(BucketCreatePopup)
