export const ALL_RANGE_FILTER = 'all'
export const NEW_RANGE_FILTER = 'new'
export const WEEK_RANGE_FILTER = 'week'
export const MONTH_RANGE_FILTER = 'month'
export const YEAR_RANGE_FILTER = 'year'
export const VALID_RANGE_FILTERS = [NEW_RANGE_FILTER, WEEK_RANGE_FILTER, MONTH_RANGE_FILTER, YEAR_RANGE_FILTER, ALL_RANGE_FILTER]

export const rangeFiltersAscending = [NEW_RANGE_FILTER, WEEK_RANGE_FILTER, MONTH_RANGE_FILTER, YEAR_RANGE_FILTER, ALL_RANGE_FILTER]

export type RangeFilter = typeof ALL_RANGE_FILTER
  | typeof NEW_RANGE_FILTER
  | typeof WEEK_RANGE_FILTER
  | typeof MONTH_RANGE_FILTER
  | typeof YEAR_RANGE_FILTER
