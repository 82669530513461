import { Component, createElement, Fragment, ReactElement } from 'react'
import { connect } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import {
  Stream,
  AnyContent,
  ContentType,
  INTERACTION_EVENT_OPEN,
  ContentItem,
  FilterType,
  RangeFilter,
  ContentSource,
  NEW_RANGE_FILTER
} from 'interfaces'
import FetchStreamPageContent from 'components/Fetch/FetchStreamPageContent'
import SourcesBricksView, {
  FetchableProps,
  BricksSize
} from 'components/SourcesView/SourcesBricksView'
import { addRecentSource } from 'services/content/recommended/actions'
import { trackStreamInteraction } from 'services/content/streams/actions'
import { Observable } from 'rxjs/Observable'
import { Subject } from 'rxjs/Subject'
import { Subscription } from 'rxjs/Subscription'
import { FormattedMessage } from 'react-intl'
import styles from './StreamBricksView.pcss'

export { BricksSize }

export interface ScrollConfiguration {
  element?: Element
  useWindow?: boolean
}

export interface StreamBricksViewProps {
  stream: Stream
  filter: FilterType
  items: AnyContent[]
  contentTypes: ContentType[]

  align?: 'left' | 'center'
  sizes?: BricksSize[]
  scroll?: ScrollConfiguration
  range?: RangeFilter
  itemWidth?: number

  onItemPinned?(stream: Stream, content: AnyContent): void
  onItemShared?(stream: Stream, content: AnyContent): void
  onGotoClicked?(stream: Stream, content: AnyContent, by: string): void
  onRangeChanged?(stream: Stream, range: RangeFilter): boolean
  onFilterChanged(filter: FilterType): void
  onContentItemClick?(item: ContentItem): void
}

interface ConnectedStreamBricksViewProps extends StreamBricksViewProps {
  trackStreamInteraction: (id: string) => Observable<any>
  addRecentSource: (source: ContentSource) => void
}

export class StreamBricksView extends Component<ConnectedStreamBricksViewProps, any> {

  private unmount$: Subject<boolean>
  private trackStreamInteraction$: Subscription

  constructor(props: ConnectedStreamBricksViewProps) {
    super(props)

    this.createLoadMore = this.createLoadMore.bind(this)

    this.onItemPinned = this.onItemPinned.bind(this)
    this.onItemShared = this.onItemShared.bind(this)
    this.onGotoClicked = this.onGotoClicked.bind(this)
    this.onFeedClicked = this.onFeedClicked.bind(this)
    this.onRangeChanged = this.onRangeChanged.bind(this)
    this.trackInteraction = this.trackInteraction.bind(this)

    this.unmount$ = new Subject()
    this.trackInteraction(this.props.stream)
  }

  trackInteraction(stream: Stream) {
    this.trackStreamInteraction$ = this.props.trackStreamInteraction(stream.id)
      .takeUntil(this.unmount$.asObservable())
      .subscribe()
    this.props.addRecentSource(stream)
  }

  componentWillUnmount() {
    this.unmount$.next(true)

    if (this.trackStreamInteraction$) {
      this.trackStreamInteraction$.unsubscribe()
    }
  }

  componentDidUpdate(prevProps: StreamBricksViewProps) {
    if (this.props.stream.id !== prevProps.stream.id) {
      this.trackInteraction(this.props.stream)
    }
  }

  onItemPinned(content: AnyContent) {
    if (this.props.onItemPinned) {
      this.props.onItemPinned(this.props.stream, content)
    }
  }

  onItemShared(content: AnyContent) {
    if (this.props.onItemShared) {
      this.props.onItemShared(this.props.stream, content)
    }
  }

  onGotoClicked(content: AnyContent, by: string) {
    if (this.props.onGotoClicked) {
      this.props.onGotoClicked(this.props.stream, content, by)
    }
  }

  onFeedClicked(content: AnyContent) {
    this.onGotoClicked(content, 'attribution')
  }

  onRangeChanged(range: RangeFilter) {
    if (this.props.onRangeChanged) {
      return this.props.onRangeChanged(this.props.stream, range)
    }

    return false
  }

  createLoadMore(props: FetchableProps): ReactElement<any> {
    const stream = this.props.stream
    const page = props.page
    const range = props.range
    console.log('[stream bricks] creating load more', `fetch[${stream.id}.${page}:${range}]`)

    return createElement(FetchStreamPageContent, {
      ...props,
      stream,
      key: `fetch[${stream.id}.${page}:${range}:${props.sortBy}]`
    })
  }

  render(): ReactElement<any> {
    const { stream, filter, items, align, sizes, scroll, range, itemWidth, contentTypes } = this.props

    return createElement(Fragment, undefined, [
      range === NEW_RANGE_FILTER ? createElement('div', {
        key: 'message',
        className: styles['msg-range']
      }, [
        createElement('h5', { key: 'title', className: styles['msg-title'] },
          createElement(FormattedMessage, { id: 'content.msg.stream-range-new-title' })
        ),
        createElement('p', { key: 'subtitle', className: styles['msg-subtitle'] },
          createElement(FormattedMessage, { id: 'content.msg.stream-range-new-subtitle' })
        )
      ]) : null,
      createElement(SourcesBricksView, {
        key: 'content',
        filter,
        items,
        align,
        sizes,
        scroll,
        range,
        itemWidth,
        contentTypes,
        isStream: true,
        sourcesHash: stream.slug + '',
        preventTopOverflow: true,
        createLoadMore: this.createLoadMore,

        onFilterChange: this.props.onFilterChanged,
        onRangeChanged: this.onRangeChanged,
        onItemShared: this.onItemShared,
        onItemPinned: this.onItemPinned,
        onSourceClick: this.onGotoClicked,
        onFeedClick: this.onFeedClicked,
        onContentItemClick: this.props.onContentItemClick
      })
    ])
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    trackStreamInteraction: (id: string) => Observable.fromPromise(
      dispatch(trackStreamInteraction({ streamIds: [id], event: INTERACTION_EVENT_OPEN })).unwrap()
    ),
    addRecentSource: (source: ContentSource) => dispatch(addRecentSource(source))
  }
}

export default connect(undefined, mapDispatchToProps)(StreamBricksView)
