import { FEED_TYPE_RSS, FEED_TYPE_KEYWORD, ContentFeedType, FEED_TYPE_FACEBOOK, FB_SDK_VERSION, FEED_TYPE_TWITTER } from 'interfaces'
import { FEED_TYPE_REDDIT } from 'shared'

export interface WithAvatar {
  handle?: string
  url?: string
  fbUrl?: string
}

export function mapAvatar(type: ContentFeedType, item: WithAvatar) {
  let handle = item.handle
  if (!handle || type === FEED_TYPE_KEYWORD || type === FEED_TYPE_TWITTER) {
    return ''
  }
  // remove trailing '/'
  if (handle.lastIndexOf('/') === handle.length - 1) {
    handle = handle.slice(0, -1)
  }
  handle = handle.indexOf('@') === 0 ? handle.slice(1) : handle.slice(handle.lastIndexOf('/') + 1)

  switch (type) {
    case FEED_TYPE_FACEBOOK:
      return `//graph.facebook.com/${handle}/picture?type=large`
    case FEED_TYPE_RSS:
      return '/static/img/feeds/rss_browser_bg.jpg'
    case FEED_TYPE_REDDIT:
      return '/static/img/feeds/reddit-bg.png'
    default:
      return ''
  }
}

export default mapAvatar
