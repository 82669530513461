import * as React from 'react'
import Chip from '@mui/material/Chip'
import { CHIP_ACTIVE_CLASS } from 'theme/theme-md'
import DeleteIcon from '@mui/icons-material/Close'
import Tooltip from '@mui/material/Tooltip'
import { FormattedMessage } from 'react-intl'
import { mdiTag } from '@mdi/js'
import Icon from '@mdi/react'

export function QueryChip(props: {
  label: string,
  active: boolean,
  isTag?: boolean,
  isQuote?: boolean,
  className?: string
  onClick: (value: string, isTag?: boolean, isQuote?: boolean) => void,
  onDelete?: (value: string) => void
}) {
  const onClick = () => {
    props.onClick(props.label, props.isTag, props.isQuote)
  }

  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete(props.label)
    }
  }

  return (
    <Chip
      label={props.label}
      className={`${props.className || ''} ${props.active ? CHIP_ACTIVE_CLASS : ''}`}
      icon={props.isTag ? <Icon path={mdiTag} size="16px" /> : undefined}
      onClick={onClick}
      deleteIcon={(
        <Tooltip title={<FormattedMessage id="general.tooltips.remove-search" />}>
          <DeleteIcon style={{ width: '16px' }} />
        </Tooltip>
      )}
      onDelete={props.onDelete ? onDelete : undefined}
    />
  )
}
