import { createElement } from 'react'
import Loader from 'components/SimpleLoader'
import loadable, { LoadableComponent } from '@loadable/component'

const AsyncListings: LoadableComponent<any> = loadable<any>(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: 'listings' */
  './ListingsRoute'
), {
  fallback: createElement(Loader, { title: 'Loading Listings route...' })
})

export default AsyncListings
