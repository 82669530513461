import { Component, createElement, ReactElement } from 'react'
import { Stream, AnyContent, RangeFilter, FilterType } from 'interfaces'
import { FetchStreamPageContent } from 'components/Fetch'
import SourcesBricksView, {
  FetchableProps,
  BricksSize
} from 'components/SourcesView/SourcesBricksView'
import { DEFAULT_STREAM_TYPES } from 'utils/filter-store'

export { BricksSize }

export interface ScrollConfiguration {
  element?: Element
  useWindow?: boolean
}

export interface CuratedFolderBricksViewProps {
  stream: Stream
  filter: FilterType
  items: AnyContent[]

  align?: 'left' | 'center'
  sizes?: BricksSize[]
  scroll?: ScrollConfiguration
  range?: RangeFilter
  itemWidth?: number
  startPage?: number

  onMount(): void
  onFilterChange(filter: FilterType): void
  onRangeChanged?(stream: Stream, range: RangeFilter): boolean
  onItemPinned?(stream: Stream, content: AnyContent): void
  onItemShared?(stream: Stream, content: AnyContent): void
  onGotoClicked?(stream: Stream, content: AnyContent, by: string): void
}

interface CuratedFolderBricksViewState {
}

export class CuratedFolderBricksView extends Component<CuratedFolderBricksViewProps, CuratedFolderBricksViewState> {

  constructor(props: CuratedFolderBricksViewProps) {
    super(props)

    this.createLoadMore = this.createLoadMore.bind(this)
    this.onItemPinned = this.onItemPinned.bind(this)
    this.onItemShared = this.onItemShared.bind(this)
    this.onGotoClicked = this.onGotoClicked.bind(this)
    this.onFeedClicked = this.onFeedClicked.bind(this)
    this.onRangeChanged = this.onRangeChanged.bind(this)

    this.state = {}
  }

  componentDidMount() {
    this.props.onMount()
  }

  onItemPinned(content: AnyContent) {
    if (this.props.onItemPinned) {
      this.props.onItemPinned(this.props.stream, content)
    }
  }

  onItemShared(content: AnyContent) {
    if (this.props.onItemShared) {
      this.props.onItemShared(this.props.stream, content)
    }
  }

  onGotoClicked(content: AnyContent, by: string) {
    if (this.props.onGotoClicked) {
      this.props.onGotoClicked(this.props.stream, content, by)
    }
  }

  onRangeChanged(range: RangeFilter): boolean {
    if (this.props.onRangeChanged) {
      return this.props.onRangeChanged(this.props.stream, range)
    }

    return false
  }

  onFeedClicked(content: AnyContent) {
    this.onGotoClicked(content, 'attribution')
  }

  createLoadMore(props: FetchableProps): ReactElement<any> {
    const stream = this.props.stream
    const page = (this.props.startPage || 0) + props.page
    const range = props.range
    return createElement(FetchStreamPageContent, {
      ...props,
      stream,
      key: `fetch[${stream.slug}.${page}:${range}]`
    })
  }

  render(): ReactElement<any> {
    const { stream, filter, items, align, sizes, scroll, range, itemWidth } = this.props
    const contentTypes = stream.filters.length ? stream.filters : DEFAULT_STREAM_TYPES

    return createElement(SourcesBricksView, {
      filter,
      items,
      align,
      sizes,
      scroll,
      range,
      itemWidth,
      contentTypes,
      isStream: true,
      sourcesHash: stream.slug + '',
      createLoadMore: this.createLoadMore,

      onItemShared: this.onItemShared,
      onItemPinned: this.onItemPinned,
      onSourceClick: this.onGotoClicked,
      onFeedClick: this.onFeedClicked,
      onRangeChanged: this.onRangeChanged,
      onFilterChange: this.props.onFilterChange
    })
  }

}

export default CuratedFolderBricksView
