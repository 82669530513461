import { RequestBuilder, authorizedPipe } from 'services/net'
import { TYPE_FEED_TYPE_MAP } from 'services/content/adapters/mappings'
import { CONTENT_SERVICE_URL, LEGACY_URL } from 'config'
import {
  ContentFeedType,
  FEED_TYPE_KEYWORD,
  FEED_TYPE_KEYWORD_ALIAS,
  Feed,
  FeedsResponse,
  LegacyFeed,
  StreamResponse,
  StreamsResponse
} from 'interfaces'

export const api = Object.freeze({
  copyFeeds: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/users/streams/:streamId/feeds`).asPost().asJSON().withoutCredentials()

    return function (feeds: Feed[], streamId: string) {
      const data = {
        feeds: feeds.map(feed => ({
          id: feed.uniqueSource || feed.id,
          type: Object.keys(TYPE_FEED_TYPE_MAP).find(key => TYPE_FEED_TYPE_MAP[key] === feed.type),
          name: feed.name,
          handle: feed.handle,
          order: 0
        }))
      }

      return authorizedPipe<any>(
        builder
          .partial(':streamId', streamId)
          .body(data)
          .build()
      )
    }
  }(),
  createFeed: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/discover_content/source_add`).asPost().expectJSON()

    return function (handle: string, type: ContentFeedType, image?: string, name?: string, networkId?: string) {
      const sourceType = type === FEED_TYPE_KEYWORD ? FEED_TYPE_KEYWORD_ALIAS : type
      const data = {
        url: handle,
        type: sourceType,
        image,
        name,
        networkId,
        default_view: type === FEED_TYPE_KEYWORD ? 'tc' : 'vp',
        origin: 'v3',
        source_id: -1,
        unique_only: 1
      }

      return authorizedPipe<{ originalSource: string }>(
        builder
          .body(data)
          .asFormUrlEncoded()
          .build()
      )
    }
  }(),
  deleteFeed: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/users/streams/feeds/:feedId`).asDelete().expectJSON().withoutCredentials()

    return function (feedId: string) {
      return authorizedPipe<any>(
        builder
          .partial(':feedId', feedId)
          .asFormUrlEncoded()
          .build()
      )
    }
  }(),
  getFeed: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/users/feeds/:feed`).asGet().expectJSON().withoutCredentials()

    return function (feedId: string) {
      return authorizedPipe<{ feeds: LegacyFeed[] }>(
        builder
          .partial(':feed', feedId)
          .build()
      )
    }
  }(),
  getFeeds: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/users/feeds`).asGet().expectJSON().withoutCredentials()

    return function (page: number) {
      return authorizedPipe<FeedsResponse>(
        builder
          .param('page', page.toString())
          .build()
      )
    }
  }(),
  getOriginalFeed: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/feeds/:feedId`).asGet().expectJSON().withoutCredentials()

    return function (feedId: string) {
      return authorizedPipe<LegacyFeed>(
        builder
          .partial(':feedId', feedId)
          .build()
      )
    }
  }()
})
