import * as React from 'react'

import styles from './EditableLabel.pcss'

export interface EditableLabelProps {
  value: string
  editMode: boolean
  allowEmpty?: boolean
  inputClassName?: string
  className?: string
  onChange: (name: string | undefined) => any
}

interface EditableLabelState {
  value: string
}

export class EditableLabel extends React.Component<EditableLabelProps, EditableLabelState> {
  private input: HTMLInputElement

  constructor(props: EditableLabelProps) {
    super(props)
    this.state = { value: this.props.value }

    this.onValueChanged = this.onValueChanged.bind(this)
    this.saveChanges = this.saveChanges.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.cancelChanges = this.cancelChanges.bind(this)
  }

  componentDidMount() {
    this.input.onblur = this.saveChanges
  }

  onValueChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    this.setState((prevState) => ({ ...prevState, value }))
  }

  componentDidUpdate(prevProps: EditableLabelProps) {
    if (this.props.editMode && !prevProps.editMode) {
      this.input.focus()
      this.input.selectionStart = this.props.value.length
    }
  }

  saveChanges() {
    const newValue = this.state.value.trim()
    if (!this.props.allowEmpty && newValue === '' || newValue === this.props.value) {
      this.setState({ value: this.props.value })
      this.props.onChange(undefined)
      return
    }

    this.props.onChange(this.state.value.trim())
  }

  cancelChanges() {
    this.setState({ value: this.props.value })
    this.props.onChange(undefined)
  }

  onKeyDown(e: any) {
    const enterKeyCode = 13
    const escapeKeyCode = 27

    if (e.keyCode === enterKeyCode) {
      this.input.blur()
    }

    if (e.keyCode === escapeKeyCode) {
      this.cancelChanges()
    }
  }

  render() {
    return (
      <div className={`text-ellipsis ${this.props.className || ''}`}>
        <span className={`${this.props.editMode ? styles.hidden : ''}`}>{this.state.value}</span>
        <input
          className={`${styles.input} ${this.props.inputClassName || ''} ${!this.props.editMode ? styles.hidden : ''}`}
          type="text"
          value={this.state.value}
          ref={(ref: HTMLInputElement) => { this.input = ref }}
          onChange={this.onValueChanged}
          onKeyDown={this.onKeyDown}
          readOnly={!this.props.editMode}
        />
      </div>
    )
  }
}

export default EditableLabel
