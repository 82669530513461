export function resetScroll() {
  const mainSection = document.querySelector('[data-test="main"]')
  if (mainSection && mainSection.scrollTop !== 0) {
    mainSection.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }
}

export function isElementInViewport(element: HTMLElement, topOffset?: number, bottomOffset?: number) {
  const rect = element.getBoundingClientRect()
  const minTop = topOffset || 0
  const maxBottom = window.innerHeight + (bottomOffset || 0)
  return rect.top >= minTop && rect.bottom <= maxBottom
}
