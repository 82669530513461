import React from 'react'
import Paper from '@mui/material/Paper'
import styles from './PromptCard.pcss'
import Tooltip from '@mui/material/Tooltip'
import IconDelete from '@mui/icons-material/Close'
import { FormattedMessage } from 'react-intl'
import Icon from '@mdi/react'
import { mdiAutoFix, mdiFeather } from '@mdi/js'
import { FeatherIcon } from 'components/Icons'
import IconButton from '@mui/material/IconButton'

const Clipboard = require('clipboard')

export type GeneratedCaption = {
  id: string
  messages: string[]
  images: string[]
  isImage?: boolean
  prompt: string
  promptImage?: string
}

interface CaptionCardProps {
  caption: GeneratedCaption
  className?: string
  onCopy: (id: string) => void
  onCopyPrompt?: (text: string, imageUrl?: string) => void
  onImageSelected?: (url: string, id: string) => void
  onDelete?: (id: string) => void
}

export function CaptionCard({ caption, className, onImageSelected, onCopy, onCopyPrompt, onDelete }: CaptionCardProps) {
  const clipboard = React.useRef(null)
  const { messages, images, isImage, prompt, id } = caption

  const setPromptRef = (element: HTMLElement | null) => {
    if (element) {
      if (clipboard.current) {
        (clipboard.current as any).destroy()
      }

      clipboard.current = new Clipboard(
        element,
        {
          text: () => {
            if (onCopyPrompt) {
              onCopyPrompt(prompt, caption.promptImage)
            }
            return ''
          },
          container: element
        }
      )
      ; (clipboard.current as any).on('success', () => {
        onCopy(id)
      })
    }
  }

  const handleDelete = () => {
    if (onDelete) {
      onDelete(id)
    }
  }

  const onCopied = () => {
    onCopy(id)
  }

  const onImageClick = (url: string) => {
    if (onImageSelected) {
      onImageSelected(url, id)
    }
  }

  return (
    <Paper className={`${styles['caption-box']} ${messages && messages.length > 1 ? styles.multi : ''} ${className || ''}`}>
      {messages.map((msg, index) => (
        <Text
          key={index}
          value={msg}
          index={index}
          className={onCopyPrompt ? styles['w-actions'] : ''}
          onCopied={onCopied}
        />
      ))}
      {isImage && (
        <p className={`${styles['img-prompt']} ${onCopyPrompt ? styles['w-actions'] : ''}`}>{prompt}</p>
      )}
      {images.length > 0 && (
        <div className={styles.grid}>
          {images.map(img => (
            <Image key={img} url={img} onCompose={onImageClick} />
          ))}
        </div>
      )}
      {onCopyPrompt && (
        <div className={`${styles.icon} ${styles['icon-r']}`} ref={setPromptRef}>
          <Tooltip title="Use prompt" placement="top">
            <Icon path={mdiAutoFix} size="24px" />
          </Tooltip>
        </div>
      )}
      {onDelete && (
        <div role="button" className={styles['btn-delete']} onClick={handleDelete}>
          <Tooltip title={<FormattedMessage id="actions.delete" />} placement="top">
            <IconDelete />
          </Tooltip>
        </div>
      )}
    </Paper>
  )
}

const Text = ({ value, index, className, onCopied }: { value: string, index: number, className?: string, onCopied: () => void }) => {
  const clipboard = React.useRef(null)
  const getClipboardText = () => value

  const setRef = (element: HTMLElement | null) => {
    if (element) {
      if (clipboard.current) {
        (clipboard.current as any).destroy()
      }

      clipboard.current = new Clipboard(element, { text: getClipboardText, container: element })
      ; (clipboard.current as any).on('success', onCopied)
    }
  }

  return (
    <div className={styles.variation}>
      <div className={styles.icon} data-index={index} ref={setRef}>
        <Tooltip title="Copy Text to Create Post" placement="top">
          <IconButton size="small" color="primary">
            <Icon path={mdiFeather} size="24px" />
          </IconButton>
        </Tooltip>
      </div>
      <div className={`${styles.caption} ${className || ''}`}>
        <span className={styles.index}>{`Variation ${index + 1}:`}</span>
        {value}
      </div>
    </div>
  )
}

const Image = ({ url, onCompose }: { url: string, onCompose: (url: string) => void }) => {
  const compose = () => {
    onCompose(url)
  }

  return (
    <div className={styles['img-box']}>
      <img src={url} className={styles.image} />
      <div className={styles['img-actions']}>
        <Tooltip title={<FormattedMessage id="content-card.actions.create-post" />} placement="top">
          <IconButton
            size="small"
            color="primary"
            onClick={compose}
          >
            <FeatherIcon className={styles['icon-post']} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}
