import * as React from 'react'
import { ContentItem, ContentType, GIF_TYPE, PHOTO_TYPE, VIDEO_TYPE } from 'interfaces'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { searchStockContent } from 'services/search/actions'
import { getRandomSearchQuery } from 'routes/search/state/actions'
import {
  STOCK_CONTENT_PROVIDER_GIPHY,
  STOCK_CONTENT_PROVIDER_PEXELS,
  STOCK_CONTENT_PROVIDER_PIXABAY,
  STOCK_CONTENT_PROVIDER_UNSPLASH
} from 'interfaces/Content/StockContentProvider'

export function FetchRandomMedia(props: { onFetched: (type: ContentType, items: ContentItem[], provider: string) => void }) {
  const { onFetched } = props
  const dispatch = useDispatch<StoreThunkDispatch>()
  const query = React.useMemo(() => getRandomSearchQuery(), [])

  React.useEffect(() => {
    const sub = dispatch(searchStockContent(query, PHOTO_TYPE, STOCK_CONTENT_PROVIDER_PEXELS))
      .zip(
        dispatch(searchStockContent(query, PHOTO_TYPE, STOCK_CONTENT_PROVIDER_PIXABAY)),
        dispatch(searchStockContent(query, PHOTO_TYPE, STOCK_CONTENT_PROVIDER_UNSPLASH))
      )
      .subscribe(response => {
        onFetched(PHOTO_TYPE, response[0], STOCK_CONTENT_PROVIDER_PEXELS)
        onFetched(PHOTO_TYPE, response[1], STOCK_CONTENT_PROVIDER_PIXABAY)
        onFetched(PHOTO_TYPE, response[2], STOCK_CONTENT_PROVIDER_UNSPLASH)
      })
    return () => sub.unsubscribe()
  }, [dispatch, onFetched, query])

  React.useEffect(() => {
    const sub = dispatch(searchStockContent(query, VIDEO_TYPE, STOCK_CONTENT_PROVIDER_PEXELS))
      .zip(
        dispatch(searchStockContent(query, VIDEO_TYPE, STOCK_CONTENT_PROVIDER_PIXABAY)),
        dispatch(searchStockContent(query, VIDEO_TYPE, 'google'))
      )
      .subscribe(response => {
        onFetched(VIDEO_TYPE, response[0], STOCK_CONTENT_PROVIDER_PEXELS)
        onFetched(VIDEO_TYPE, response[1], STOCK_CONTENT_PROVIDER_PIXABAY)
        onFetched(VIDEO_TYPE, response[2], 'google')
      })
    return () => sub.unsubscribe()
  }, [dispatch, onFetched, query])

  React.useEffect(() => {
    const sub = dispatch(searchStockContent(query, GIF_TYPE, STOCK_CONTENT_PROVIDER_GIPHY))
      .subscribe(response => {
        onFetched(GIF_TYPE, response, STOCK_CONTENT_PROVIDER_GIPHY)
      })
    return () => sub.unsubscribe()
  }, [dispatch, onFetched, query])

  return null
}
