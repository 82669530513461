import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { currentUserSelector } from 'services/users/selectors'

export default function DefaultRedirect() {
  const user = useSelector(currentUserSelector)

  return (
    <Navigate to={user?.account.trial === 'active' ? '/training' : '/posts'} replace />
  )
}
