import React from 'react'
import styles from './BucketCard.pcss'
import { Bucket } from 'shared'
import Icon from '@mdi/react'
import { mdiPail, mdiCheck, mdiPencil } from '@mdi/js'
import IconButton from '@mui/material/IconButton'

interface BucketCardProps {
  bucket: Bucket
  selected?: boolean
  noSelection?: boolean
  className?: string
  actionIconBoxClassName?: string
  actionIcon?: React.ReactNode
  onClick?: (id: string) => void
  onEdit?: (id: string) => void
}

export function BucketCard({
  bucket, selected, noSelection, className, actionIcon, actionIconBoxClassName, onClick, onEdit
}: BucketCardProps) {

  const handleClick = () => {
    if (onClick) {
      onClick(bucket.id)
    }
  }

  const handleEdit = () => {
    if (onEdit) {
      onEdit(bucket.id)
    }
  }

  const clickableClass = onClick ? styles.clickable : ''
  const editableClass = onEdit ? styles.editable : ''

  return (
    <article
      className={`${styles.card} ${className || ''} ${selected ? styles.checked : ''} ${editableClass} ${clickableClass}`}
      onClick={handleClick}
    >
      {!noSelection && (
        <div className={`${styles['check-box']} ${actionIconBoxClassName || ''}`}>
          {actionIcon || <Icon path={mdiCheck} className={styles.check} size="18px" />}
        </div>
      )}
      {onEdit && (
        <IconButton size="small" className={styles['btn-edit']} onClick={handleEdit}><Icon path={mdiPencil} size="20px" /></IconButton>
      )}
      <div className={styles['icon-box']}>
        <Icon path={mdiPail} size="70px" color={bucket.color} />
      </div>
      <div className={styles.right}>
        <h5 className={styles.name}>{bucket.name}</h5>
        <p className={styles.description} title={bucket.description}>
          {bucket.description}
        </p>
      </div>
    </article>
  )
}
