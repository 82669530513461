import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import StreamGroupCarousel from './StreamGroupCarousel'
import { StoreThunkDispatch } from 'store/state'
import { getStreamGroups } from 'admin/services/actions'
import { contentGroupsSelector } from 'services/content/selectors'

const StreamGroupCarouselsContainer = React.memo(function () {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const groups = useSelector(contentGroupsSelector)
  const visibleGroups = Object.values(groups).filter(g => g.active)

  React.useEffect(() => {
    const sub = dispatch(getStreamGroups()).subscribe()
    return () => sub.unsubscribe()
  }, [])

  return (
    <React.Fragment>
      {visibleGroups.map(group => (
        <StreamGroupCarousel
          key={group.id}
          group={group}
        />
      ))}
    </React.Fragment>
  )
})

export default StreamGroupCarouselsContainer
