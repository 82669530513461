import React from 'react'
import { ContentFeedType, FEED_TYPE_KEYWORD } from 'interfaces'

// Icons
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined'
import CommentIconAlt from '@mui/icons-material/ChatOutlined'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined'
import ForwardOutlinedIcon from '@mui/icons-material/ForwardOutlined'
import CallSplitOutlinedIcon from '@mui/icons-material/CallSplitOutlined'

// Utils
import numberToHuman from 'utils/format/numberToHuman'

// Styles
import styles from './StatsBar.pcss'

const statsIconMappings: any = {
  facebook: {
    like: ThumbUpAltOutlinedIcon,
    comment: CommentIconAlt,
    share: ShareOutlinedIcon
  },
  twitter: {
    like: FavoriteBorderOutlinedIcon,
    comment: CommentIconAlt,
    share: RepeatOutlinedIcon
  },
  reddit: {
    like: ({ className }: any) => <ForwardOutlinedIcon className={className} style={{ transform: 'rotate(-90deg)' }} />,
    comment: CommentIconAlt,
    share: ({ className }: any) => <CallSplitOutlinedIcon className={className} style={{ transform: 'rotate(90deg)' }} />
  }
}

function StatsIcon({ type, network }: {
  type: 'like' | 'comment' | 'share'
  network: ContentFeedType
}) {
  if (!['facebook', 'twitter', 'reddit'].includes(network)) {
    return null
  }

  const Icon = statsIconMappings[network][type]

  return <Icon className={styles.icon} />
}

interface StatsBarProps {
  type: ContentFeedType
  likes: number | undefined
  comments: number | undefined
  shares: number | undefined
}

function StatsBar({ type, likes, comments, shares }: StatsBarProps) {
  const withShares = type !== FEED_TYPE_KEYWORD

  return (
    <div className={styles['stats-bar']}>
      <div className={styles.item}>
        <span className={styles.text}>{numberToHuman(likes || 0)}</span>
        <StatsIcon type="like" network={type} />
      </div>

      <div className={styles.item}>
        <span className={styles.text}>{numberToHuman(comments || 0)}</span>
        <StatsIcon type="comment" network={type} />
      </div>

      {withShares && (
        <div className={styles.item}>
          <span className={styles.text}>{numberToHuman(shares || 0)}</span>
          <StatsIcon type="share" network={type} />
        </div>
      )}
    </div>
  )
}

export default StatsBar
