import { FileType } from './file'
import { BRAND_FACEBOOK, BRAND_INSTAGRAM, BRAND_LINKEDIN, BRAND_PINTEREST, BRAND_REDDIT, BRAND_TWITTER } from './socials'

export const GIF_TYPE = 'gifs'
export const PHOTO_TYPE = 'photos'
export const ARTICLE_TYPE = 'articles'
export const VIDEO_TYPE = 'videos'
export const STATUS_TYPE = 'status'
export const TEXT_TYPE = 'texts'
export const STOCK_PHOTO_TYPE = 'stock-photos'
export const STOCK_VIDEO_TYPE = 'stock-videos'
export const STOCK_GIF_TYPE = 'stock-gifs'

export const ALL_FILTER = 'all'
export const STOCK_ALL_FILTER = 'stock-all'
export const POST_TYPES = [PHOTO_TYPE, ARTICLE_TYPE, VIDEO_TYPE, STATUS_TYPE]

export const VALID_TYPE_FILTERS = [
  PHOTO_TYPE,
  ARTICLE_TYPE,
  VIDEO_TYPE,
  GIF_TYPE,
  STATUS_TYPE,
  STOCK_PHOTO_TYPE,
  STOCK_VIDEO_TYPE,
  STOCK_GIF_TYPE,
  STOCK_ALL_FILTER,
  TEXT_TYPE
]
export const CONTENT_TYPE_TO_FILE_TYPE: { [key: string]: FileType } = {
  [PHOTO_TYPE]: FileType.Image,
  [GIF_TYPE]: FileType.Gif,
  [VIDEO_TYPE]: FileType.Video
}

export const FEED_TYPE_FACEBOOK = BRAND_FACEBOOK
export const FEED_TYPE_TWITTER = BRAND_TWITTER
export const FEED_TYPE_INSTAGRAM = BRAND_INSTAGRAM
export const FEED_TYPE_PINTEREST = BRAND_PINTEREST
export const FEED_TYPE_LINKEDIN = BRAND_LINKEDIN
export const FEED_TYPE_REDDIT = BRAND_REDDIT
export const FEED_TYPE_RSS = 'rss'
export const FEED_TYPE_KEYWORD = 'keywords'
export const FEED_TYPE_KEYWORD_ALIAS = 'trending'
export const FEED_TYPE_STATUS_IDEAS = 'status-ideas'
export const FEED_TYPE_UNSUPPORTED = 'unsupported'
