import { Component } from 'react'
import { connect } from 'react-redux'
import { WindowWithFacebook, Facebook, BRAND_FACEBOOK, FacebookLoginResponse } from 'interfaces'
import PromiseFB from 'services/auth/identities/facebook/promisify'
import { StoreDispatch } from 'store/state'
import { sdkInitialized, sdkRemoved } from 'services/external'
import { updateFacebookLoginStatus } from 'services/auth'
import { message } from 'services/snackbar'

declare const window: Window & WindowWithFacebook
declare const FB: Facebook
const ERROR_NOTIFICATION_DURATION = 16000 // ms
const FB_SDK_LOAD_ERROR_MESSAGE = `Your browser is blocking access to resources needed to run the app. Please update your browser settings
   to allow 3rd party scripts or try a different browser. Email support@postplanner.com if you have questions.`

/**
 * Inject the FacebookSDK into the DOM
 *
 * @returns {HTMLScriptElement}
 */
function injectFaceBookSDK(onError: () => void): HTMLScriptElement {
  return (function (d, s, id) {
    const fjs = d.getElementsByTagName(s)[0]
    const element = d.getElementById(id) as HTMLScriptElement
    if (element) {
      return element
    }

    const target = (fjs && fjs.parentNode) || document.head
    const js = d.createElement(s) as HTMLScriptElement
    js.id = id
    js.src = '//connect.facebook.net/en_US/sdk.js'
    js.async = true
    js.defer = true
    js.crossOrigin = 'anonymous'
    js.onerror = onError
    target.insertBefore(js, fjs)
    return js
  }(document, 'script', 'facebook-jssdk'))
}

export class FacebookSDK extends Component<any, any> {
  private scriptFacebookSDK: HTMLScriptElement | undefined

  constructor(props: any) {
    super(props)

    this.onInit = this.onInit.bind(this)
    this.onInitFailed = this.onInitFailed.bind(this)
  }

  onInit() {
    console.log('[FBSDK] Facebook Async Initialization')
    if (typeof FB !== 'undefined') {
      FB.Event.subscribe('auth.statusChange', (response: FacebookLoginResponse) => {
        console.log('[FBSDK] login status changed: ', response.status)
        this.props.updateStatus(response)
      })
    }
    PromiseFB.init().then(this.props.onInitialized).catch(this.onInitFailed)
  }

  onInitFailed() {
    console.error('[FBSDK] Facebook Initialization error', arguments)
    console.log('[FBSDK] Have you set the FACEBOOK_APP_ID_XXX in .env?')
  }

  componentDidMount() {
    const wasDefined = typeof FB !== 'undefined'
    window.fbAsyncInit = this.onInit
    this.scriptFacebookSDK = injectFaceBookSDK(() => {
      this.props.message(FB_SDK_LOAD_ERROR_MESSAGE, ERROR_NOTIFICATION_DURATION)
    })

    if (wasDefined) {
      this.onInit()
    }
  }

  componentWillUnmount() {
    if (this.scriptFacebookSDK) {
      this.scriptFacebookSDK.remove()
      this.scriptFacebookSDK = undefined
    }
    this.props.onRemoved()
  }

  render() {
    return null
  }
}

function mapDispatchToProps(dispatch: StoreDispatch) {
  return {
    onInitialized: () => dispatch(sdkInitialized(BRAND_FACEBOOK)),
    updateStatus: (response: FacebookLoginResponse) => dispatch(updateFacebookLoginStatus(response)),
    onRemoved: () => dispatch(sdkRemoved(BRAND_FACEBOOK)),
    message: (text: string, timeout: number) => dispatch(message(text, 'error', timeout))
  }
}

export default connect(undefined, mapDispatchToProps)(FacebookSDK)
