export interface ColorGradient {
  from: string
  to: string
}

export const colors: ColorGradient[] = [
  { from: '#f48b43', to: '#eb6d66' },
  { from: '#48b0e1', to: '#3a5a98' },
  { from: '#eb6d66', to: '#8f5ba6' },
  { from: '#f48b43', to: '#8f5ba6' },
  { from: '#48b0e1', to: '#8f5ba6' },
  { from: '#eb6d66', to: '#3a5a98' },
  { from: '#fbe37b', to: '#81b32e' },
  { from: '#81b32e', to: '#5f8619' },
  { from: '#fbe37b', to: '#f48b43' },
  { from: '#81b32e', to: '#3a5a98' },
  { from: '#81b32e', to: '#eb6d66' },
  { from: '#48b0e1', to: '#eb6d66' },
  { from: '#fbe37b', to: '#eb6d66' },
  { from: '#48b0e1', to: '#5f8619' },
  { from: '#8f5ba6', to: '#3a5a98' },
  { from: '#eb6d66', to: '#f48b43' },
  { from: '#3a5a98', to: '#48b0e1' },
  { from: '#8f5ba6', to: '#eb6d66' },
  { from: '#8f5ba6', to: '#f48b43' },
  { from: '#8f5ba6', to: '#48b0e1' },
  { from: '#3a5a98', to: '#eb6d66' },
  { from: '#81b32e', to: '#fbe37b' },
  { from: '#5f8619', to: '#81b32e' },
  { from: '#f48b43', to: '#fbe37b' },
  { from: '#3a5a98', to: '#81b32e' },
  { from: '#eb6d66', to: '#81b32e' },
  { from: '#eb6d66', to: '#48b0e1' },
  { from: '#5f8619', to: '#48b0e1' },
  { from: '#f7d559', to: '#2f60a3' },
  { from: '#f7d559', to: '#945168' }
]

export const solidColors: string[] = [
  '#48b0e1',
  '#eb6d66',
  '#f48b43',
  '#fbe37b',
  '#8f5ba6',
  '#3a5a98',
  '#81b32e',
  '#5f8619',
  '#f7d559',
  '#2f60a3',
  '#945168'
]

export const ppGreenColorString = '#81b32e,#5f8619'
export const defaultStreamColor = colors[0]
export const defaultBucketColor = solidColors[1]
export const defaultStreamColorString = `${defaultStreamColor.from},${defaultStreamColor.to}`

export function getGradientFromString(color: string): ColorGradient {
  if (!color) {
    return defaultStreamColor
  }

  const colors = color.split(',')

  if (colors.length !== 2) {
    return defaultStreamColor
  }

  return {
    from: colors[0],
    to: colors[1]
  }
}

export function getGradientStyle(colors: string, direction?: string) {
  if (!colors) {
    return {}
  }

  const gradient = getGradientFromString(colors)
  return {
    backgroundImage: `linear-gradient(to ${direction || 'top right'}, ${gradient.from}, ${gradient.to})`
  }
}

export function generateGradient() {
  // get random color set
  const index = Math.floor(Math.random() * colors.length)
  const gradient = colors[index]
  return `radial-gradient(farthest-corner at 8% 140%, ${gradient.from} 0%, ${gradient.to} 100%)`
}

export default generateGradient

export const bucketColorsPreset = [
  '#830000',
  '#fb0104',
  '#fd8703',
  '#feff07',
  '#22fe04',
  '#1fffff',
  '#396de2',
  '#0d00ff',
  '#8401fe',
  '#fa01fe',
  '#d16859',
  '#e28486',
  '#f6c089',
  '#fedf87',
  '#a7d096',
  '#93b7bd',
  '#92b2f1',
  '#96b8e3',
  '#a494cc',
  '#c992af',
  '#910d01',
  '#bc0100',
  '#df7d2b',
  '#ebb825',
  '#59993d',
  '#376f7b',
  '#2f61ce',
  '#2f6eba',
  '#533696',
  '#943765'
]
