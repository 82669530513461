import * as React from 'react'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { getTags as getQuotesTags } from 'services/content/quotes/actions'
import { getTags as getStatusesTags, searchStatusIdeas } from 'services/content/statuses/actions'
import { getRandomElements } from 'shared/utils'
import { searchStockContent } from 'services/search/actions'
import { getRandomSearchQuery } from 'routes/search/state/actions'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { ContentItem } from 'interfaces'
import { getQuotes } from 'admin/services/quotes/actions'

export function FetchRandomContent(props: { onFetched: (source: 'quotes' | 'statuses' | 'articles', items: ContentItem[]) => void }) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [quotesTags, setQuotesTags] = React.useState<string[]>([])
  const [statusesTags, setStatusesTags] = React.useState<string[]>([])

  React.useEffect(() => {
    const fetchTags = async () => {
      const response = await Promise.all([
        dispatch(getQuotesTags(false)).toPromise(),
        dispatch(getStatusesTags()).toPromise()
      ])

      setQuotesTags(response[0].map((t: any) => t.name))
      setStatusesTags(response[1].map((t: any) => t.name))
    }

    fetchTags()
  }, [dispatch])

  React.useEffect(() => {
    const sub = dispatch(searchStockContent(getRandomSearchQuery(), 'articles', 'google')).pipe(catchError((error) => {
      return Observable.of({ error })
    }))
      .subscribe(response => {
        if (!response.error) {
          props.onFetched('articles', response)
        }
      })

    return () => sub.unsubscribe()
  }, [dispatch])

  React.useEffect(() => {
    let sub: any
    if (quotesTags.length > 0) {
      sub = dispatch(getQuotes(0, '', getRandomElements(quotesTags, 1)[0]))
        .subscribe((response: any) => props.onFetched('quotes', response))
    }
    return () => {
      sub?.unsubscribe()
    }
  }, [dispatch, quotesTags.length])

  React.useEffect(() => {
    let sub: any
    if (statusesTags.length > 0) {
      sub = dispatch(searchStatusIdeas('', 0, getRandomElements(statusesTags, 1)[0]))
        .subscribe((response: any) => props.onFetched('statuses', response))
    }
    return () => {
      sub?.unsubscribe()
    }
  }, [dispatch, statusesTags.length])

  return null
}
export default FetchRandomContent
