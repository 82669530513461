import React from 'react'
import styles from './YoutubePostPreview.pcss'
import { PostPreviewVideoPlayer } from './PostPreviewVideoPlayer'

interface YoutubePostPreviewProps {
  profile?: { name: string, image: string }
  videoUrl?: string
  title?: string
}

export function YoutubePostPreview({ profile, videoUrl, title }: YoutubePostPreviewProps) {
  return (
    <article className={styles.post}>
      <main className={`${styles['yt-video-box']} ${!videoUrl ? styles.empty : ''}`}>
        {videoUrl && (
          <PostPreviewVideoPlayer videoUrl={videoUrl} />
        )}
      </main>
      <div className={styles.bottom}>
        <div className={styles.avatarBox}>
          <img src={profile?.image} className={styles.avatar} />
        </div>
        <div className={styles.attr}>
          <h3 className={`${styles.title} ${title ? '' : styles['title-placeholder']}`}>{title}</h3>
          <div className={styles.name}>{profile?.name}</div>
        </div>
      </div>
    </article>
  )
}
