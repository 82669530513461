import { ContentType, ARTICLE_TYPE, PHOTO_TYPE, VIDEO_TYPE } from 'interfaces'
import { articleAdapter } from '../adapters/articles'
import { photoAdapter } from '../adapters/photos'
import { videoAdapter } from '../adapters/videos'
import { V1 } from './net'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getStreamsContent = createAsyncThunk(
  'content/getStreamsContentBatch',
  async (streams: { [key: string]: { filters: ContentType[], originalStreamId?: string } }, { dispatch, rejectWithValue }) => {
    try {
      const response = await dispatch(V1.getContent(streams)).toPromise()
      const streamsData = response.streams
      if (!streamsData) {
        return []
      }

      return Object.keys(streams).map(id => {
        const content = streamsData[id]
        if (!content) {
          return { id, content: [] }
        }

        const items = Object.keys(content)
          .map(filter => {
            switch (filter) {
              case ARTICLE_TYPE:
                return content[filter].data.map(articleAdapter)
              case PHOTO_TYPE:
                return content[filter].data.map(photos => photoAdapter(photos))
              case VIDEO_TYPE:
                return content[filter].data.map(videos => videoAdapter(videos))
              default:
                return []
            }
          })
          .reduce((allItems: any[], itemsByFilter) => allItems.concat(itemsByFilter), [])

        return { id, content: items }
      })
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
