import * as React from 'react'
import styles from './TrialPopups.pcss'
import Dialog from '@mui/material/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import { currentUserSelector, isCCTrialSelector } from 'services/users/selectors'
import { FormattedMessage, useIntl } from 'react-intl'
import { defaultProductUpgradeHandleSelector, activeProductsSelector } from 'services/product'
import { NavLink, useNavigate } from 'react-router-dom'
import { StoreThunkDispatch } from 'store/state'
import { downgradeFree, getAccountInfo } from 'services/settings'
import { message } from 'services/snackbar'
import { Subject } from 'rxjs/Subject'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { checkTokens } from 'services/auth'
import { ProductSelect } from 'components/ProductSelect'
import { PPProduct } from 'interfaces'

export function TrialExpiredPopup() {
  const intl = useIntl()
  const [loading, setLoading] = React.useState(true)
  const dispatch = useDispatch<StoreThunkDispatch>()
  const navigate = useNavigate()
  const isCCTrial = useSelector(isCCTrialSelector)
  const plans = useSelector(activeProductsSelector)
  // NOTE: Do not show Free plan or plans above Business to CC-trial users
  // eslint-disable-next-line no-magic-numbers
  const visiblePlans = isCCTrial ? plans.filter(p => p.order > 0 && p.order < 500) : plans.filter(p => p.order < 500)
  const freePlan = plans.find(p => p.order === 0) as PPProduct
  const user = useSelector(currentUserSelector)
  const currency = user?.currency as string
  const trialExpired = user?.account.trial === 'expired' && !user?.account.hasChargifyInfo
  const [selectedProduct, setSelectedProduct] = React.useState({
    handle: 'prod-400-monthly',
    isAnnual: false
  })
  const checkStatus$ = React.useRef<Subject<any>>()

  React.useEffect(() => {
    checkStatus$.current = new Subject()
    checkStatus$.current
      .flatMap(() => {
        return dispatch(checkTokens())
          .pipe(catchError((error) => Observable.of(error)))
      })
      .subscribe(() => { setLoading(false) })

    checkStatus$.current.next()

    return () => {
      checkStatus$.current?.unsubscribe()
    }

  }, [dispatch])

  const onUpgrade = () => {
    if ([freePlan.handles.monthly, freePlan.handles.annual].includes(selectedProduct.handle)) {
      dispatch(downgradeFree()).unwrap()
        .then(() => {
          checkStatus$.current?.next()
          navigate('/settings/account')
        })
        .then(() => dispatch(getAccountInfo()).unwrap())
        .catch(() => {
          dispatch(message(intl.formatMessage({ id: 'errors.generic-support' }), 'error'))
        })
      return
    }
    navigate(`/signup/payment-details?plan=${selectedProduct.handle}`)
  }

  return (
    <Dialog
      open={!loading && trialExpired}
      maxWidth="md"
      classes={{ root: styles['dialog-root'] }}
    >
      <div className={`${styles.content} ${styles.trial}`}>
        <div className={styles['title-top']}>
          <h1>
            <FormattedMessage id="popups.trial-end.title-top" />
          </h1>
        </div>
        <h1 className={styles.title}>
          <FormattedMessage id="popups.trial-end.title" />
        </h1>
        <p className={styles.hint}>
          <a
            href="https://help.postplanner.com/en/articles/8797516-what-are-the-differences-between-plans"
            target="_blank"
          >
            <FormattedMessage id="popups.trial-end.plan-differences-link" />
          </a>
        </p>
        <div className={styles['plan-box']}>
          <div className={styles['select-box']}>
            <ProductSelect
              products={visiblePlans}
              selectedProduct={selectedProduct}
              currency={currency}
              className={styles['plan-select']}
              selectClassName={styles['select-inner']}
              menuItemClassName={styles.li}
              MenuProps={{
                PopoverClasses: { root: styles['select-popover'] },
                classes: { list: styles.menu }
              }}
              onSelectionChange={setSelectedProduct}
            />
          </div>
        </div>
        <div className={styles.actions}>
          <Button color="primary" variant="contained" size="large" onClick={onUpgrade}>
            <FormattedMessage id="popups.trial-end.action" />
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
