import { useState, useLayoutEffect, useRef, RefObject } from 'react'

export type NodeRect = Omit<DOMRect, 'toJSON'>

export function useDimensions<T extends HTMLElement>(deps: any[] = [], updateOnResize?: boolean): [RefObject<T>, NodeRect] {
  const [dimensions, setDimensions] = useState<NodeRect>({ width: 0, height: 0, x: 0, y: 0, left: 0, top: 0, bottom: 0, right: 0 })
  const ref = useRef<T>(null)

  useLayoutEffect(() => {
    const node = ref.current
    if (!node) {
      return
    }

    const measure = () => {
      window.requestAnimationFrame(() => {
        const rect = node.getBoundingClientRect().toJSON()
        setDimensions(rect)
      })
    }
    measure()

    if (updateOnResize) {
      window.addEventListener('resize', measure)
    }

    return () => {
      if (updateOnResize) {
        window.removeEventListener('resize', measure)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ...deps, updateOnResize])

  return [ref, dimensions]
}
