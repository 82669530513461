import * as React from 'react'
import ErrorOverlay from 'components/ErrorOverlay'
import styles from './SearchResultsCarousel.pcss'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'
import CardList from 'components/CardList'
import { AnyCard, LoadingCard } from 'components/Card'
import { SEARCH_FILTER_KEY_POST_IDEAS, SEARCH_FILTER_KEY_QUOTES, SEARCH_FILTER_KEY_STOCK, SearchFilterKey } from 'routes/search/state/types'
import StatusIdeaCard from 'components/Card/StatusIdeaCard'
import { SearchContext } from 'routes/search/state/context'
import { searchRequest } from 'routes/search/state/actions'
import ContentTypeFilter from 'components/ContentTypeFilter'
import { STOCK_PHOTO_TYPE, STOCK_VIDEO_TYPE } from 'shared/constants'
import HorizontalList from 'components/HorizontalList'
import { QueryChip } from 'components/ContentPanels/components/QueryChip'

export function SearchContentCarousel({ filter }: { filter: SearchFilterKey }) {
  const [{ results, query, quotesTags, selectedFilters, postIdeasTags }, searchDispatch] = React.useContext(SearchContext)
  const { loading, items, error, hasNextPage, page } = results[filter]
  const [activeStockType, setActiveStockType] = React.useState(STOCK_PHOTO_TYPE)
  const [activeTag, setActiveTag] = React.useState<string | null>(null)
  const withQuery = !loading && query.length
  const isEmpty = items.length === 0 && !withQuery
  const isTextFilter = filter === SEARCH_FILTER_KEY_QUOTES || filter === SEARCH_FILTER_KEY_POST_IDEAS

  const loadNextPage = () => {
    if (hasNextPage && items.length !== 0 && !loading) {
      searchDispatch(searchRequest(query, [filter], page + 1))
    }
  }

  const onTagClick = (value: string, _isTag?: boolean, isQuote?: boolean) => {
    const shouldClearTag = activeTag === value
    if (isQuote) {
      searchDispatch(searchRequest(query, selectedFilters, 0, shouldClearTag ? undefined : value, undefined))
    } else {
      searchDispatch(searchRequest(query, selectedFilters, 0, undefined, shouldClearTag ? undefined : value))
    }
    setActiveTag(shouldClearTag ? null : value)
  }

  React.useEffect(() => {
    if (query) {
      setActiveTag(null)
    }
  }, [query])

  const cards = React.useMemo(() => {
    const defaultCardsCount = 20
    const loadingCards = []
    const emptyCards = []
    if (loading) {
      for (let i = 0; i < defaultCardsCount; i++) {
        loadingCards.push(<LoadingCard active key={`loading-${i}`} small square />)
      }
    }
    if (!loading && items.length === 0) {
      for (let i = 0; i < defaultCardsCount; i++) {
        emptyCards.push(<LoadingCard square active={false} small key={`empty-${i}`} />)
      }
    }

    const filteredItems = filter === SEARCH_FILTER_KEY_STOCK
      ? items.filter(item => item.type === activeStockType)
      : items

    const contentCards = isTextFilter
      ? items.map((quote: any) => <StatusIdeaCard key={quote.id} small content={quote} />)
      : filteredItems.map((item) => <AnyCard content={item} small key={item.id} square children={item} />)

    return contentCards.concat(loadingCards).concat(emptyCards)
  }, [loading, items, filter, activeStockType, isTextFilter])

  const tags = filter === SEARCH_FILTER_KEY_QUOTES
    ? quotesTags
    : filter === SEARCH_FILTER_KEY_POST_IDEAS ? postIdeasTags : []

  return (
    <section
      className={`${styles.container} ${isEmpty && tags.length === 0 ? styles.empty : ''}`}
      data-testid="search-result-section"
    >
      <header className={styles.header}>
        <h2 className="text-ellipsis">
          <FormattedMessage id={`search.navigation.${filter}`} />
        </h2>
        {items.length !== 0 && (
          <NavLink
            to={{ pathname: `/content/search/content/${filter}`, search: query }}
            state={{ stockFilter: activeStockType }}
          >
            <FormattedMessage id="general.carousel.nav.see-all" />
          </NavLink>
        )}
        {filter === SEARCH_FILTER_KEY_STOCK && (
          <div className={styles.filters}>
            <ContentTypeFilter
              options={[STOCK_PHOTO_TYPE, STOCK_VIDEO_TYPE]}
              selected={activeStockType}
              onFilterSelected={setActiveStockType}
            />
          </div>
        )}
        {tags.length > 0 && (
          <HorizontalList>
            {tags.map(tag => (
              <QueryChip
                isQuote={filter === SEARCH_FILTER_KEY_QUOTES}
                key={tag}
                label={tag}
                active={tag === activeTag}
                onClick={onTagClick}
              />
            ))}
          </HorizontalList>
        )}
      </header>
      <CardList
        key={`${query}${activeTag}`}
        containerClassName={styles.carousel}
        disabled={items.length === 0}
        onScrollLimit={loadNextPage}
      >
        {cards}
      </CardList>
      {error && (
        <ErrorOverlay
          error={error}
          subtitle=""
          originalError={error}
          className={`${styles['error-box']} ${isTextFilter ? styles['error-box-text'] : ''}`}
          small
        />
      )}
    </section>
  )
}

export default SearchContentCarousel
