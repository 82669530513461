import { StatusIdea, STATUS_TYPE, Paginated, ErrorResponse, StatusesResponse, LegacyStatusIdea } from 'interfaces'
import assert from 'services/errors'

export function statusIdeaAdapter(status: LegacyStatusIdea): StatusIdea {
  return {
    id: '' + status.id,
    status: status.status,
    type: STATUS_TYPE,
    favCount: status.favCount || 0
  }
}

export function statusIdeasAdapter(response: StatusesResponse | ErrorResponse): Paginated<StatusIdea[]> {
  const statusIdeasResponse = assert<StatusesResponse>(response)
  return {
    items: (statusIdeasResponse.statusIdeas || []).filter(Boolean).map(statusIdeaAdapter),
    hasNext: !!(statusIdeasResponse.pagination && statusIdeasResponse.pagination.next && statusIdeasResponse.pagination.next.length)
  }
}

export function quoteToStatusIdea(s: any, index: number) {
  return {
    ...s,
    author: s.author?.trim(),
    id: s.id?.toString() || index.toString(),
    tags: s.tags || [],
    type: STATUS_TYPE,
    status: s.quote.trim(),
    favCount: s.favoritedCount || s.favoritedBy?.length || 0,
    isQuote: true,
    isFavorite: Boolean(s.isFavorited)
  }
}
