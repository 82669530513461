/* eslint-disable max-len */
import * as React from 'react'
import PlusIcon from '@mui/icons-material/Add'
import styles from './StreamIcon.pcss'

function StreamIconInverted({ className, style }: { className?: string, style?: React.CSSProperties }) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      className={className || ''}
      style={Object.assign({ width: 24, height: 24 }, style)}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="600 360 80 80"
      xmlSpace="preserve"
    >
      <path d="M673.8,385.4c0,0-2.4-5.8-6.8-10.2c-6-6.1-12.7-8.8-12.7-8.8s-5-2.9-16.8-2.9c-8.7,0-15.3,4.1-15.3,4.1s9.7,8.1,22.6,12.5
   C657.7,384.6,673.8,385.4,673.8,385.4z"
      />
      <path d="M676.4,392.8c0,0-24.5-0.2-42.3-3.8c-16.1-3.3-25.7-10-25.7-10s-1.8,1.5-3.5,6c-1.1,2-1.9,6.6-1.9,6.6s12.7,3,31,4.6
   c18.5,1.7,42.6,2,42.6,2s0.2-1.8,0.1-2.7C676.7,394.8,676.4,392.8,676.4,392.8z"
      />
      <path d="M676.4,404.6c0,0-25.4,0.5-43.4,2.4c-18.3,1.9-29.2,5-29.2,5s0,2.6,1.4,5.7c2.2,4.4,3.8,5.1,3.8,5.1s9.2-6.1,26.2-9.2
   c16.8-3,40.4-3,40.4-3s0.5-1.6,0.6-2.4C676.5,406.8,676.4,404.6,676.4,404.6z"
      />
      <path d="M672.6,417c0,0-17,2.1-29.5,6.5c-12.2,4.3-19.9,10.9-19.9,10.9s7.6,4.2,19.9,3.6c9.5-0.7,17.4-5.8,17.4-5.8s4.9-3.7,7.8-7.1
   C671.4,421.2,672.6,417,672.6,417z"
      />
    </svg>
  )
}

export function AddStreamIcon({ className, style }: { className?: string, style?: React.CSSProperties }) {
  return (
    <div className={`${styles['icon-box']} ${className || ''}`}>
      <StreamIconInverted style={style} />
      <PlusIcon className={styles.badge} />
    </div>
  )
}

export default StreamIconInverted
