import * as React from 'react'
import CardList from 'components/CardList'
import { FetchFeedPageContent, FetchStreamPageContent } from 'components/Fetch'
import AnyCard from 'components/Card'
import {
  ContentSource,
  AnyContent,
  PostDestination,
  Feed,
  Stream,
  ContentItem,
  PHOTO_TYPE,
  ARTICLE_TYPE,
  VIDEO_TYPE,
  GIF_TYPE,
  Article,
  WithIntl,
  FilterType,
  RangeFilter,
  ALL_FILTER,
  ALL_RANGE_FILTER,
  rangeFiltersAscending,
  STATUS_TYPE
} from 'interfaces'
import { STOCK_ALL_FILTER, STOCK_GIF_TYPE, STOCK_PHOTO_TYPE, STOCK_VIDEO_TYPE } from 'interfaces/Content/StockContentTypes'
import LoadingCard from 'components/Card/LoadingCard'
import { FetchableProps } from 'components/SourcesView/SourcesBricksView'
import { NO_CONTENT_MESSAGE_CAROUSEL, PROFILES_NOT_CONNECTED_MESSAGE } from 'components/ErrorOverlay'
import { TIMERANGE_FILTER_DEFAULT } from 'config'
import { dedupeArticles, dedupeMixedArticles, instanceOfStream } from 'services/content/util'
import { injectIntl } from 'react-intl'
import styles from './ContentLayout.pcss'
import { sortByDateKeyAscending } from 'utils/sort/order'
import EmptyView from 'components/EmptyView'
import { mdiImage, mdiLink, mdiGif, mdiVideo, mdiConnection } from '@mdi/js'
import { TEXT_TYPE } from 'interfaces/Content/ContentType'

const VIEWPORT_VISIBLE_CARDS = 10
const isEqual = require('react-fast-compare')

export interface CarouselViewOwnProps {
  filter: FilterType
  source?: ContentSource
  items?: AnyContent[]
  loading?: boolean
  defaultRange?: RangeFilter
  destinations?: { [key: string]: Readonly<PostDestination> }
  sortByDate?: boolean
  skipContentFeatureChecks?: boolean
  className?: string

  onContentItemClick?(content: ContentItem): void
  onContentItemCompose?(content: ContentItem): void | boolean
}

type CarouselViewProps = CarouselViewOwnProps & WithIntl

type CarouselContentState = {
  [key: string]: {
    items: AnyContent[]
    page: number
    hasMore: boolean
  }
}

interface CarouselViewState {
  loading: boolean
  range: RangeFilter
  content: CarouselContentState
  error?: any
}

export class CarouselView extends React.Component<CarouselViewProps, CarouselViewState> {
  get myProfilesNotConnected() {
    if (!this.props.source) {
      return false
    }
    return Object.keys(this.props.destinations || {}).length === 0 && (this.props.source as any).protected
  }

  constructor(props: CarouselViewProps) {
    super(props)

    const initialItems = this.props.items || []

    this.state = {
      loading: props.loading !== undefined ? props.loading : true,
      range: props.defaultRange || TIMERANGE_FILTER_DEFAULT,
      content: this.getContentState(initialItems, true)
    }

    this.fetchNextPage = this.fetchNextPage.bind(this)
    this.renderContentItems = this.renderContentItems.bind(this)
    this.onContentFetched = this.onContentFetched.bind(this)
    this.onFetchFailed = this.onFetchFailed.bind(this)
    this.renderError = this.renderError.bind(this)
    this.createLoadMore = this.createLoadMore.bind(this)
  }

  getContentState = (contentItems: AnyContent[], initial?: boolean) => {
    const items: any[] = contentItems || []
    const hasMore = (type: FilterType) => initial ? true : this.state.content[type].hasMore

    return {
      [PHOTO_TYPE]: {
        items: items.filter(i => i.type === PHOTO_TYPE),
        page: initial ? 0 : this.state.content[PHOTO_TYPE].page,
        hasMore: hasMore(PHOTO_TYPE)
      },
      [ARTICLE_TYPE]: {
        items: items.filter(i => i.type === ARTICLE_TYPE),
        page: initial ? 0 : this.state.content[ARTICLE_TYPE].page,
        hasMore: hasMore(ARTICLE_TYPE)
      },
      [VIDEO_TYPE]: {
        items: items.filter(i => i.type === VIDEO_TYPE),
        page: initial ? 0 : this.state.content[VIDEO_TYPE].page,
        hasMore: hasMore(VIDEO_TYPE)
      },
      [GIF_TYPE]: {
        items: items.filter(i => i.type === GIF_TYPE),
        page: initial ? 0 : this.state.content[GIF_TYPE].page,
        hasMore: hasMore(GIF_TYPE)
      },
      [STOCK_PHOTO_TYPE]: {
        items: items.filter(i => i.type === STOCK_PHOTO_TYPE),
        page: initial ? 0 : this.state.content[STOCK_PHOTO_TYPE].page,
        hasMore: hasMore(STOCK_PHOTO_TYPE)
      },
      [STOCK_VIDEO_TYPE]: {
        items: items.filter(i => i.type === STOCK_VIDEO_TYPE),
        page: initial ? 0 : this.state.content[STOCK_VIDEO_TYPE].page,
        hasMore: hasMore(VIDEO_TYPE)
      },
      [STOCK_GIF_TYPE]: {
        items: items.filter(i => i.type === STOCK_GIF_TYPE),
        page: initial ? 0 : this.state.content[STOCK_GIF_TYPE].page,
        hasMore: hasMore(GIF_TYPE)
      },
      [STOCK_ALL_FILTER]: {
        items: items.filter(i => i.isStock),
        page: initial ? 0 : this.state.content[STOCK_ALL_FILTER].page,
        hasMore: hasMore(STOCK_ALL_FILTER)
      },
      [STATUS_TYPE]: {
        items: items.filter(i => i.type === STATUS_TYPE),
        page: initial ? 0 : this.state.content[STATUS_TYPE].page,
        hasMore: hasMore(STATUS_TYPE)
      },
      [TEXT_TYPE]: {
        items: items.filter(i => i.type === TEXT_TYPE),
        page: initial ? 0 : this.state.content[TEXT_TYPE].page,
        hasMore: hasMore(TEXT_TYPE)
      },
      [ALL_FILTER]: {
        items,
        page: initial ? 0 : this.state.content[ALL_FILTER].page,
        hasMore: hasMore(ALL_FILTER)
      }
    }
  }

  shouldComponentUpdate(nextProps: CarouselViewProps, nextState: CarouselViewState) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)
  }

  componentDidUpdate(prevProps: CarouselViewProps) {
    const hasItems = !!this.props.items
    const currentContent = this.state.content[this.props.filter]

    if (this.props.source && prevProps.source && this.props.source.id !== prevProps.source.id) {
      this.setState(prevState => ({ ...prevState, loading: true, content: this.getContentState([], true) }))
    }

    // EXPL: On Changing the type filter, start loading items if it "hasMore", and only if there are currently no items loaded
    if (this.props.filter !== prevProps.filter && currentContent.hasMore && currentContent.items.length === 0) {
      this.setState(prevState => ({ ...prevState, loading: true }))
    }

    if ((hasItems && !prevProps.items) || (this.props.items || []).length !== (prevProps.items || []).length) {
      this.setState(prevState => ({ ...prevState, content: this.getContentState(this.props.items || []), loading: false }))
    }

    if (this.props.loading !== prevProps.loading) {
      this.setState(prevState => ({ ...prevState, loading: !!this.props.loading }))
    }
  }

  renderError(filter: FilterType) {
    if (this.state.loading) {
      return null
    }

    let icon = ''
    let typeString = ''
    switch (filter) {
      case ARTICLE_TYPE:
        icon = mdiLink
        typeString = ARTICLE_TYPE
        break
      case PHOTO_TYPE:
        icon = mdiImage
        typeString = PHOTO_TYPE
        break
      case GIF_TYPE:
        icon = mdiGif
        typeString = GIF_TYPE
        break
      case VIDEO_TYPE:
        icon = mdiVideo
        typeString = VIDEO_TYPE
        break
      case STATUS_TYPE:
      case TEXT_TYPE:
        typeString = 'texts'
        break
      default:
        icon = ''
    }

    if (this.myProfilesNotConnected) {
      return (
        <EmptyView
          title={PROFILES_NOT_CONNECTED_MESSAGE(this.props.intl.formatMessage)}
          icon={mdiConnection}
          carousel
          subtitle={(
            <p key="error-link">
              <a href="/settings/networks">Click here</a> to connect your accounts.
            </p>
          )}
        />
      )
    }

    return (
      <EmptyView
        title={NO_CONTENT_MESSAGE_CAROUSEL(filter, this.props.intl.formatMessage)}
        icon={icon}
        carousel
      />
    )
  }

  onFetchFailed(error: any) {
    const currentTypeFilter = this.props.filter
    const currentRangeFilter = this.state.range
    const filteredItems = this.state.content[currentTypeFilter].items

    if (filteredItems.length === 0 && error.type === currentTypeFilter) {
      const nextRange = rangeFiltersAscending.indexOf(currentRangeFilter) + 1

      if (nextRange !== 0 && nextRange < rangeFiltersAscending.length && currentRangeFilter !== ALL_RANGE_FILTER) {
        const nextContentState = Object.assign({}, this.state.content, { [currentTypeFilter]: { page: 0, items: [], hasMore: true } })
        this.setState(prevState => ({
          ...prevState,
          content: nextContentState,
          range: rangeFiltersAscending[nextRange] as RangeFilter
        }))
        return
      }

      // EXPL: If no items are returned for "all" range, set "hasMore" for this type to false
      if (currentRangeFilter === ALL_RANGE_FILTER) {
        const nextContentState = Object.assign(
          {},
          this.state.content,
          {
            [currentTypeFilter]: {
              ...this.state.content[currentTypeFilter],
              hasMore: false
            }
          }
        )

        this.setState(prevState => ({
          ...prevState,
          content: nextContentState,
          loading: false,
          error
        }))
        return
      }
    }

    this.setState(prevState => ({ ...prevState, error }))
  }

  mergeContentToState = (newContent: CarouselContentState) => {
    const mergeByType = (type: FilterType) => {
      return Object.assign({}, this.state.content[type], { items: this.state.content[type].items.concat(newContent[type].items) })
    }

    return {
      [PHOTO_TYPE]: mergeByType(PHOTO_TYPE),
      [ARTICLE_TYPE]: mergeByType(ARTICLE_TYPE),
      [VIDEO_TYPE]: mergeByType(VIDEO_TYPE),
      [GIF_TYPE]: mergeByType(GIF_TYPE),
      [ALL_FILTER]: mergeByType(ALL_FILTER),
      [STOCK_PHOTO_TYPE]: mergeByType(STOCK_PHOTO_TYPE),
      [STOCK_VIDEO_TYPE]: mergeByType(STOCK_VIDEO_TYPE),
      [STOCK_GIF_TYPE]: mergeByType(STOCK_GIF_TYPE),
      [STATUS_TYPE]: mergeByType(STATUS_TYPE),
      [TEXT_TYPE]: mergeByType(TEXT_TYPE),
      [STOCK_ALL_FILTER]: mergeByType(STOCK_ALL_FILTER)
    }
  }

  onContentFetched(content: AnyContent[]) {
    const newContent = this.getContentState(content)
    const nextContent = this.mergeContentToState(newContent)

    this.setState(prevState => ({
      ...prevState,
      content: nextContent,
      loading: content.length === 0 && prevState.range !== ALL_RANGE_FILTER
    }))
  }

  fetchNextPage() {
    if (this.state.loading || (this.state.error && this.state.error.type === this.props.filter)) {
      return
    }

    const currentTypeFilter = this.props.filter
    const page = this.state.content[currentTypeFilter].page + 1
    this.setState((previousState) => ({
      ...previousState,
      loading: true,
      content: {
        ...previousState.content,
        [currentTypeFilter]: {
          items: previousState.content[currentTypeFilter].items,
          page,
          hasMore: previousState.content[currentTypeFilter].hasMore
        }
      }
    }))
  }

  createLoadMore(props: FetchableProps) {
    const currentTypeFilter = this.props.filter
    const currentContent = this.state.content[currentTypeFilter]
    // EXPL: If items have been passed as props, skip loading first page
    if (this.props.items && this.props.items.length && currentContent.page === 0) {
      return null
    }

    if (!currentContent.hasMore || currentContent.items.length !== 0 && currentContent.page === 0) {
      return null
    }

    if (!this.props.source) {
      return null
    }

    return instanceOfStream(this.props.source)
      ? (
        <FetchStreamPageContent
          key="stream-fetch"
          {...props as any}
          stream={this.props.source as Stream}
          only={currentTypeFilter}
        />
      )
      : (
        <FetchFeedPageContent
          key="feed-fetch"
          feed={this.props.source as Feed}
          {...props}
          only={currentTypeFilter}
        />
      )
  }

  renderLoadingCards() {
    const cards: any[] = []
    const active = this.state.loading

    for (let i = 0; i < VIEWPORT_VISIBLE_CARDS; i++) {
      cards.push(<LoadingCard square active={active} key={i} small />)
    }
    return cards
  }

  renderContentItems() {
    let visibleItems = this.state.content[this.props.filter].items
    const loadingCards = this.state.loading || this.props.loading || visibleItems.length === 0 ? this.renderLoadingCards() : []

    if (this.props.filter === ARTICLE_TYPE) {
      visibleItems = dedupeArticles(visibleItems as Article[])
    }

    if (this.props.filter === ALL_FILTER) {
      visibleItems = dedupeMixedArticles(visibleItems)
    }

    if (this.props.sortByDate) {
      visibleItems.sort(sortByDateKeyAscending('createdAt'))
    }

    return visibleItems
      .map(item => (
        <AnyCard
          key={item.id}
          content={item as ContentItem}
          children={item as ContentItem}
          square
          small
          skipFeatureChecks={this.props.skipContentFeatureChecks}
          onClick={this.props.onContentItemClick}
          onCompose={this.props.onContentItemCompose}
        />
      ))
      .concat(loadingCards)
  }

  render() {
    const currentTypeFilter = this.props.filter
    const currentContent = this.state.content[currentTypeFilter]
    const fetchProps: FetchableProps = {
      page: currentContent.page,
      range: this.state.range,
      onFailed: this.onFetchFailed,
      onFetched: this.onContentFetched,
      errors: {}
    }

    const fetch = this.state.loading ? this.createLoadMore(fetchProps) : null

    const items = currentContent.items
    const shouldRenderError = (items.length === 0 && !this.state.loading)
      || this.myProfilesNotConnected
    const error = shouldRenderError
      ? this.renderError(currentTypeFilter)
      : null

    const list = (
      <CardList
        key="list-view"
        onScrollLimit={this.fetchNextPage}
      >
        {this.renderContentItems()}
      </CardList>
    )

    return (
      <div className={`${styles['list-wrapper']} ${this.props.className || ''}`} data-testid="carousel-view">
        {fetch}
        {list}
        {error}
      </div>
    )
  }
}

export default injectIntl(CarouselView)
