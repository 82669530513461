export const STOCK_CONTENT_PROVIDER_UNSPLASH = 'unsplash'
export const STOCK_CONTENT_PROVIDER_GIPHY = 'giphy'
export const STOCK_CONTENT_PROVIDER_SHUTTERSTOCK = 'shutterstock'
export const STOCK_CONTENT_PROVIDER_PIXABAY = 'pixabay'
export const STOCK_CONTENT_PROVIDER_PEXELS = 'pexels'
export const STOCK_CONTENT_PROVIDERS_ALL = 'all'

export type StockContentProvider = typeof STOCK_CONTENT_PROVIDER_UNSPLASH
  | typeof STOCK_CONTENT_PROVIDER_GIPHY
  | typeof STOCK_CONTENT_PROVIDER_PIXABAY
  | typeof STOCK_CONTENT_PROVIDER_PEXELS
  | typeof STOCK_CONTENT_PROVIDER_SHUTTERSTOCK
  | typeof STOCK_CONTENT_PROVIDERS_ALL
