import * as React from 'react'
import styles from './AppLayout.pcss'
import AppNavigationSidebar from '../AppNavigationSidebar'
import CalloutManager from '../CalloutManager/CalloutManager'
import ContentSelectionDrawer from 'routes/find/components/ContentSelectionDrawer'
import { Outlet } from 'react-router-dom'
import { TrialWidget } from 'components/TrialWidget'

interface AppLayoutProps {
  hidden?: boolean
  blurred?: boolean
  hideSidebar?: boolean
}

export function AppLayout(props: AppLayoutProps) {
  const { hidden, blurred, hideSidebar } = props
  return (
    <section className={`${styles['app-container']} ${blurred ? styles.blur : ''} ${hideSidebar ? styles['no-sb'] : ''}`}>
      <main className={styles.main} data-test="main">
        <div className={`${styles.layout} ${hidden ? styles.hidden : ''}`}>
          <div className={styles['side-panel']}>
            <AppNavigationSidebar />
          </div>
          <div className={styles.content}>
            <CalloutManager />
            <ContentSelectionDrawer />
            <Outlet />
          </div>
        </div>
      </main>
      <TrialWidget />
    </section>
  )
}
