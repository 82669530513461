import React from 'react'
import styles from './TextBackgroundSelector.pcss'
import { useDispatch, useSelector } from 'react-redux'
import { getFacebookPostTemplates, setFacebookTextBackground } from 'services/compose'
import {
  composerImageUrlsSelector,
  composerLinkSelector,
  composerPostSelector,
  facebookBackgroundTemplatesSelector
} from 'services/compose/selectors'
import StoreState, { StoreThunkDispatch } from 'store/state'
import { BRAND_FACEBOOK, FEATURE_POST_FB_TEXT_ON_IMAGE } from 'shared'
import { ComposerFacebookPost } from 'services/compose/interfaces'
import Icon from '@mdi/react'
import { mdiViewGrid, mdiChevronLeft } from '@mdi/js'
import Dialog from '@mui/material/Dialog'
import { BackgroundTemplate } from 'services/compose/interfaces/ComposerFacebookPost'
import Collapse from '@mui/material/Collapse'
import { checkFeatureAvailability } from 'services/product'

const VISIBLE_LIST_TILES = 10

export function TextBackgroundSelector({ className }: { className?: string }) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const link = useSelector(composerLinkSelector)
  const images = useSelector(composerImageUrlsSelector)
  const fbPost = useSelector((state: StoreState) => composerPostSelector(state, BRAND_FACEBOOK)) as ComposerFacebookPost
  const selectedTemplate = fbPost.textBackground
  const templates = useSelector(facebookBackgroundTemplatesSelector)
  const [popupOpen, setPopupOpen] = React.useState(false)
  const [expanded, setExpanded] = React.useState(false)

  const openPopup = () => {
    setPopupOpen(true)
  }

  const closePopup = () => {
    setPopupOpen(false)
  }

  React.useEffect(() => {
    dispatch(getFacebookPostTemplates())
  }, [dispatch])

  // NOTE: Clear text background when link or images are added
  React.useEffect(() => {
    if (link || images.length > 0) {
      dispatch(setFacebookTextBackground(undefined))
    }
  }, [link, images.length, dispatch])

  const onItemClick = (template: BackgroundTemplate) => {
    const featureAllowed = dispatch(checkFeatureAvailability(FEATURE_POST_FB_TEXT_ON_IMAGE))
    if (!featureAllowed) {
      return
    }
    if (template.id === selectedTemplate?.id) {
      dispatch(setFacebookTextBackground(undefined))
    } else {
      dispatch(setFacebookTextBackground(template))
    }
  }

  const onPopupItemClick = (template: BackgroundTemplate) => {
    const featureAllowed = dispatch(checkFeatureAvailability(FEATURE_POST_FB_TEXT_ON_IMAGE))
    if (!featureAllowed) {
      return
    }
    if (template.id === selectedTemplate?.id) {
      dispatch(setFacebookTextBackground(undefined))
    } else {
      dispatch(setFacebookTextBackground(template))
    }
  }

  const toggle = () => {
    setExpanded((current) => !current)
  }

  return (
    <div className={className}>
      <Collapse in={expanded} timeout="auto" orientation="horizontal" collapsedSize={40}>
        <div className={styles.list}>
          <div className={styles['btn-toggle']} onClick={toggle}>
            {expanded && (
              <div className={styles['btn-collapse']}>
                <Icon path={mdiChevronLeft} size="28px" className={styles['icon-left']} />
              </div>
            )}
          </div>
          {templates.slice(0, VISIBLE_LIST_TILES).map((template) => {
            return (
              <BackgroundTile
                key={template.id}
                template={template}
                selected={selectedTemplate?.id === template.id}
                onClick={onItemClick}
              />
            )
          })}
          <div className={`${styles.tile} ${styles.btn}`} onClick={openPopup}>
            <Icon path={mdiViewGrid} size="20px" />
          </div>
        </div>
      </Collapse>

      <Dialog open={popupOpen} onClose={closePopup} classes={{ paper: styles.popup }}>
        <div className={styles.header}>
          <h1 className={styles.title}>
            Choose Background
          </h1>
        </div>
        <div className={styles.grid}>
          {templates.map((template) => {
            return (
              <BackgroundTile
                key={template.id}
                big
                template={template}
                selected={selectedTemplate?.id === template.id}
                onClick={onPopupItemClick}
              />
            )
          })}
        </div>
      </Dialog>
    </div>
  )
}

interface BackgroundTileProps {
  template: BackgroundTemplate
  selected: boolean
  big?: boolean
  onClick: (template: BackgroundTemplate) => void
}
function BackgroundTile({ template, selected, big, onClick }: BackgroundTileProps) {
  const style = {
    backgroundImage: `url(${template.thumbUrl})`
  }

  const handleClick = () => {
    onClick(template)
  }

  return (
    <div
      className={`${styles.tile} ${selected ? styles.selected : ''} ${big ? styles.big : ''}`}
      style={style}
      onClick={handleClick}
    >
    </div>
  )
}
