import * as React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import classnames from 'classnames'

// Components
import Paper from '@mui/material/Paper'
import LinearProgress from '@mui/material/LinearProgress'
import ProgressMedia from './ProgressMedia'

// Styles
import styles from './ContentCard.pcss'
import loadingCardStyles from './LoadingCard.pcss'

export const CONTENT_TRANSITION_NAME = {
  enterActive: styles.loading as string,
  appearActive: styles.loading as string,
  exitActive: styles.loading as string,
  enter: styles.in as string,
  appear: styles.in as string,
  exit: styles.out as string
}

export interface LoadingCardProps {
  active: boolean

  width?: number
  height?: number
  className?: string
  square?: boolean
  circle?: boolean
  small?: boolean
}

export const LoadingCard = React.forwardRef(
  ({
    active,
    width,
    height,
    className,
    square,
    circle,
    small
  }: LoadingCardProps,
  ref: React.Ref<any>
  ) => {
    if (circle && square) {
      console.warn('You\'ve set cards to be square and circle at the same time!')
    }

    const progressbarProps = {
      variant: active ? undefined : 'determinate' as 'determinate',
      value: active ? undefined : 0
    }
    const transitionName = CONTENT_TRANSITION_NAME
    const style = {
      ...(height && !square
        ? { height: height + 'px' }
        : {}
      ),

      ...(width
        ? { width: width + 'px' }
        : {}
      )
    }

    return (
      <Paper
        ref={ref}
        className={classnames(
          styles.card,
          {
            [styles.square]: square,
            [styles.circle]: circle,
            [styles.small]: small
          },
          styles.loading,
          className
        )}
        style={style}
      >
        <div className={`${styles['content-wrapper']} ${loadingCardStyles['loading-wrapper']}`}>
          <TransitionGroup key="content" component="div" className={styles['content-transition']}>
            <CSSTransition classNames={transitionName} timeout={250}>
              {circle ? (
                <div className={loadingCardStyles['circle-progress']}>
                  <LinearProgress key="top" className={styles['progress-linear']} {...progressbarProps} />
                  <LinearProgress key="bottom" className={styles['progress-linear']} {...progressbarProps} />
                </div>
              ) : (
                <ProgressMedia key="progress-media" image={null} className={styles.progress} active={active} />
              )}
            </CSSTransition>
          </TransitionGroup>
        </div>
      </Paper>
    )
  }
)

export default LoadingCard
