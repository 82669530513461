import { BRAND_INSTAGRAM, BRAND_PINTEREST, IndexedObject } from 'interfaces'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { ComposerInstagramPostOptions } from '../CustomizePostBlock/ComposerInstagramPostOptions'
import { ComposerPinterestPostOptions } from '../CustomizePostBlock/ComposerPinterestPostOptions'
import {
  composerBulkFileSelector,
  composerPinterestPostStatusSelector,
  composerSelectedProfilesSelector,
  PINTEREST_BOARD_NOT_SELECTED_MESSAGE_KEY
} from 'services/compose/selectors'
import styles from './BulkUploadNetworkSettings.pcss'

export function BulkUploadNetworkSettings() {
  const bulkUploadFile = useSelector(composerBulkFileSelector)
  const selectedProfiles = useSelector(composerSelectedProfilesSelector)
  const pinterestProfiles = selectedProfiles.filter(p => p.type === BRAND_PINTEREST)
  const pinterestPostStatus = useSelector(composerPinterestPostStatusSelector)
  const withError = pinterestPostStatus.caption.errors.includes(PINTEREST_BOARD_NOT_SELECTED_MESSAGE_KEY)

  const types = selectedProfiles.reduce((types: IndexedObject<boolean>, profile) => {
    types[profile.type] = true
    return types
  }, {})

  if (!bulkUploadFile) {
    return null
  }

  return (
    <div className={styles.content}>
      <div className={styles.message}>
        <FormattedMessage
          id="composer.bulk.summary-1"
          values={{ posts: bulkUploadFile.rows, fileName: bulkUploadFile.name }}
        />
      </div>
      <div className={styles.message}>
        <FormattedMessage
          id="composer.bulk.summary-2"
          values={{ posts: bulkUploadFile.rows, profiles: selectedProfiles.length }}
        />
      </div>
      <div className={styles.options}>
        {types[BRAND_PINTEREST] && (
          <div className={styles.settings}>
            <span className={styles.network}>Pinterest:</span>
            <ComposerPinterestPostOptions profiles={pinterestProfiles} selectionDone={!withError} />
            {withError && (
              <div className={styles.warning}>
                <FormattedMessage id={PINTEREST_BOARD_NOT_SELECTED_MESSAGE_KEY} />
              </div>
            )}
          </div>
        )}
        {types[BRAND_INSTAGRAM] && (
          <div className={styles.settings}>
            <span className={styles.network}>Instagram:</span>
            <ComposerInstagramPostOptions hidePostTypeSelector />
          </div>
        )}
      </div>
    </div>
  )
}

export default BulkUploadNetworkSettings
