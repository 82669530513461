import * as React from 'react'
import Button from '@mui/material/Button'
import Popper, { PopperProps } from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import styles from './PPSelect.pcss'
import { IndexedObject } from 'interfaces'
import { FormattedMessage } from 'react-intl'
import { useDimensions } from 'hooks/useDimensions'
import { PPSelectItem } from './PPSelectItem'
import IconDropdown from '@mui/icons-material/ArrowDropDown'
import { sortByKeyAscending } from 'utils/sort/order'

export type PPSelectOptions = IndexedObject<{
  label: string | React.ReactNode,
  disabled?: boolean
  order?: number
  hidden?: boolean
}>

interface PPSelectProps {
  options: PPSelectOptions
  selectedValue: string
  name?: React.ReactNode
  icon?: React.ReactNode
  multiSelection?: boolean
  className?: string
  buttonClassName?: string
  labelClassName?: string
  listClassName?: string
  popperProps?: Partial<PopperProps>
  disabled?: boolean
  withCaret?: boolean
  noLabelCaps?: boolean
  raised?: boolean
  footerElement?: React.ReactNode
  id?: string
  onSelectionChange: (option: string) => void
}

export function PPSelect(props: PPSelectProps) {
  const { multiSelection, selectedValue, onSelectionChange } = props
  const [popupAnchor, setAnchor] = React.useState<HTMLElement | null>(null)
  const [menuWidth, setMenuWidth] = React.useState('auto')
  const [buttonRef, rect] = useDimensions<HTMLButtonElement>([props.selectedValue, props.name])
  const hasOptions = Object.keys(props.options).length > 0

  React.useEffect(() => {
    setMenuWidth(rect.width + 'px')
  }, [rect.width])

  const onChange = (option: string) => {
    if (multiSelection) {
      const selected = selectedValue.split(',')
      const index = selected.indexOf(option)
      if (index === -1) {
        selected.push(option)
      } else {
        selected.splice(index, 1)
      }
      onSelectionChange(selected.join(','))
    } else {
      onSelectionChange(option)
      close()
    }
  }

  const close = React.useCallback(() => {
    setAnchor(null)
  }, [])

  const open = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (buttonRef.current && hasOptions) {
      setAnchor(buttonRef.current)
    }
    e.stopPropagation()
    e.preventDefault()
  }

  const items = Object.keys(props.options)
    .map(value => ({ value, ...props.options[value] }))
    .sort(sortByKeyAscending('order'))
  const transformClass = props.noLabelCaps ? styles['no-transform'] : ''

  const popperProps = props.popperProps || {}
  if (props.raised) {
    popperProps.style = Object.assign({}, popperProps.style, { zIndex: '1303' })
  }

  const selectedValueLabel = multiSelection
    ? selectedValue.split(',').filter(Boolean).map(value => props.options[value]?.label || value).join(', ')
    : props.options[selectedValue]?.label || selectedValue

  return (
    <div className={`${styles.box} ${props.className || ''} ${transformClass}`} data-testid={props.id || 'pp-select'}>
      <Button
        variant="text"
        className={props.buttonClassName}
        ref={buttonRef}
        disabled={props.disabled}
        endIcon={props.withCaret && (
          <IconDropdown className={styles.icon} />
        )}
        onClick={open}
      >
        {(props.name || props.icon) && (
          <span className={styles.label}>
            {props.icon && <span className={styles.icon}>{props.icon}</span>}
            {props.name}{props.name && ':'}
          </span>
        )}
        <span className={`${styles['btn-value']} ${props.labelClassName || ''}`}>
          {/* {props.options[props.selectedValue as string]?.label || props.selectedValue} */}
          {selectedValueLabel}
        </span>
      </Button>
      <Popper
        open={Boolean(popupAnchor)}
        anchorEl={popupAnchor}
        placement="bottom"
        className={styles.popper}
        {...popperProps}
      >
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={close}>
          <Paper style={{ minWidth: menuWidth }}>
            <ul className={`${styles.list} ${transformClass} ${props.listClassName || ''}`}>
              {items.filter(item => !item.hidden).map(item => {
                const isSelected = multiSelection ? selectedValue.split(',').includes(item.value) : selectedValue === item.value
                return (
                  <PPSelectItem
                    key={item.value}
                    label={item.label}
                    disabled={item.disabled}
                    value={item.value}
                    selected={isSelected}
                    onClick={onChange}
                  />
                )
              })}
            </ul>
            {props.footerElement && (
              <div onClick={close}>{props.footerElement}</div>
            )}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

export const OPTIONS_TOGGLE_DEFAULT: PPSelectOptions = {
  on: { label: <FormattedMessage id="label.generic.on" /> },
  off: { label: <FormattedMessage id="label.generic.off" /> }
}

export default PPSelect
