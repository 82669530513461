import * as React from 'react'
import { CardList, ITEM_WIDTH_SMALL, CARD_LIST_HEIGHT_SMALL } from 'components/CardList'
import { StreamCard, FeedCard } from 'components/Cards'
import FileFolderCard from 'components/FileFolderCard'
import { FormattedMessage, injectIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { SECTION_URL_RECENTLY_VIEWED } from '../../home.config'
import {
  FileFolder,
  Stream,
  Feed,
  FEED_TYPE_STATUS_IDEAS,
  WithIntl,
  InteractionTrackingSourceType,
  InteractionTrackingSource
} from 'interfaces'
import { renderLoadingCards } from '../utils'
import { URL_SEGMENT_STATUS, URL_SEGMENT_FILE_FOLDER } from 'config'

import styles from '../HomeSection.pcss'
import { QuoteFeedCard } from 'components/Cards/QuoteFeedCard'
import { QuoteFeed } from 'interfaces/Content/QuoteFeed'
const LOADING_CARDS_COUNT = 10

export interface RecentlyViewedCarouselProps extends WithIntl {
  sources: Array<Feed | Stream | FileFolder | QuoteFeed>
  loading: boolean
  location: string
  navPrefix?: string
  title?: string
  onSourceClick: (type: 'stream' | 'feed' | 'file-folder', source: Feed | Stream | FileFolder) => void
  onRemoveSource: (type: InteractionTrackingSourceType, source: InteractionTrackingSource) => void
  onScrollLimit: () => void
}

export const RecentlyViewedCarousel = React.memo(function RecentlyViewedCarousel(props: RecentlyViewedCarouselProps) {
  const navPrefix = props.navPrefix || SECTION_URL_RECENTLY_VIEWED

  const onStreamClick = (stream: Stream) => {
    props.onSourceClick('stream', stream)
  }

  const onDeleteStreamClick = (stream: Stream) => {
    props.onRemoveSource('streams', stream)
  }

  const onDeleteFeedClick = (feed: Feed) => {
    props.onRemoveSource(feed.isStatusIdeasFeed ? 'status_idea_status_tag' : 'feeds', feed)
  }

  const onDeleteFileFolderClick = (folder: FileFolder) => {
    props.onRemoveSource('my_library_folders', folder)
  }

  const onDeleteQuoteFeedClick = (id: string) => {
    props.onRemoveSource('quote_feed', { id } as any)
  }

  const onFeedClick = (feed: Feed) => {
    props.onSourceClick('feed', feed)
  }

  const onFileFolderClick = (folder: FileFolder) => {
    props.onSourceClick('file-folder', folder)
  }

  const renderContentSourceCard = (item: Feed | Stream | FileFolder, index: number) => {
    const deleteConfirmationMessage = props.intl.formatMessage({ id: 'message.recent.confirm-remove-source' })
    if (item.isStream) {
      return (
        <StreamCard
          key={`${item.id}-${index}`}
          stream={item as Stream}
          urlBrowse={`${navPrefix}/stream/${item.id}`}
          location={props.location}
          deleteConfirmationMessage={deleteConfirmationMessage}
          onDelete={onDeleteStreamClick}
          onClick={onStreamClick}
        />
      )
    }

    if (item.isFeed || item.isStatusIdeasFeed) {
      const navigation = (item as Feed).type === FEED_TYPE_STATUS_IDEAS
        ? `${navPrefix}/${URL_SEGMENT_STATUS}`
        : navPrefix

      return (
        <FeedCard
          navigation={navigation}
          key={`${item.id}-${index}`}
          feed={item as Feed}
          location={props.location}
          deleteConfirmationMessage={deleteConfirmationMessage}
          onClick={onFeedClick}
          onDelete={onDeleteFeedClick}
        />
      )
    }

    if (item.isFileFolder) {
      return (
        <FileFolderCard
          key={`${item.id}-${index}`}
          folder={item as FileFolder}
          navUrl={`${navPrefix}/${URL_SEGMENT_FILE_FOLDER}`}
          location={props.location}
          deleteConfirmationMessage={deleteConfirmationMessage}
          onDelete={onDeleteFileFolderClick}
          onClick={onFileFolderClick}
        />
      )
    }

    if ((item as any).isQuoteFeed) {
      return (
        <QuoteFeedCard
          feed={item}
          key={`${item.id}-${index}`}
          deleteConfirmationMessage={deleteConfirmationMessage}
          onDelete={onDeleteQuoteFeedClick}
        />
      )
    }
    return null
  }

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h2 className={`${styles.title} text-ellipsis`}>
          {props.title || <FormattedMessage id="find.home.sections.recently-viewed" />}
        </h2>
        <NavLink to={navPrefix} className={`${styles.navigation} text-ellipsis`}>
          <FormattedMessage id="general.carousel.nav.see-all" />
        </NavLink>
      </header>

      <CardList
        itemWidth={ITEM_WIDTH_SMALL}
        listHeight={CARD_LIST_HEIGHT_SMALL}
        containerClassName={styles['card-list']}
        onScrollLimit={props.onScrollLimit}
      >
        {
          props.sources
            .map(renderContentSourceCard)
            .concat(props.loading ? renderLoadingCards(LOADING_CARDS_COUNT, true, true) : [])
        }
      </CardList>
    </section>
  )
})

export default injectIntl(RecentlyViewedCarousel)
