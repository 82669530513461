import * as React from 'react'
import IconPrev from '@mui/icons-material/ChevronLeft'
import IconNext from '@mui/icons-material/ChevronRight'
import { FormattedMessage } from 'react-intl'
import { MAX_YEAR_DEFAULT, MONTHS_OF_YEAR } from 'utils/calendar'
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import styles from './MonthPicker.pcss'

const VISIBLE_YEARS_COUNT = 3
const BASE_TEN = 10
const LAST_MONTH_INDEX = MONTHS_OF_YEAR.length - 1

interface MonthPickerProps {
  selectedMonthIndex: number
  selectedYear: number
  startMonthIndex: number
  labelRange?: [number, number]
  minYear?: number
  maxYear?: number
  className?: string
  onSelectionChange: (year: number, monthIndex: number) => void
}

export function MonthPicker(props: MonthPickerProps) {
  const [popupAnchor, setPopupAnchor] = React.useState<HTMLElement | null>(null)

  const minYear = React.useMemo(() => props.minYear !== undefined ? props.minYear : new Date().getFullYear(), [
    props.minYear
  ])

  const maxYear = React.useMemo(() => props.maxYear !== undefined ? props.maxYear : MAX_YEAR_DEFAULT, [
    props.maxYear
  ])

  const monthText = MONTHS_OF_YEAR[props.selectedMonthIndex]
  const rangeLabel = props.labelRange ? `${MONTHS_OF_YEAR[props.labelRange[0]]} - ${MONTHS_OF_YEAR[props.labelRange[1]]}` : null

  const selectPrev = React.useCallback(() => {
    const nextMonthIndex = MONTHS_OF_YEAR[props.selectedMonthIndex - 1] ? props.selectedMonthIndex - 1 : LAST_MONTH_INDEX
    const nextSelectedYear = nextMonthIndex === LAST_MONTH_INDEX ? props.selectedYear - 1 : props.selectedYear
    if (nextSelectedYear >= minYear) {
      props.onSelectionChange(nextSelectedYear, nextMonthIndex)
    }
  }, [props, minYear])

  const selectNext = React.useCallback(() => {
    const nextMonthIndex = MONTHS_OF_YEAR[props.selectedMonthIndex + 1] ? props.selectedMonthIndex + 1 : 0
    const nextSelectedYear = nextMonthIndex === 0 ? props.selectedYear + 1 : props.selectedYear
    if (nextSelectedYear <= maxYear) {
      props.onSelectionChange(nextSelectedYear, nextMonthIndex)
    }
  }, [props, maxYear])

  const onMonthSelected = React.useCallback((month: string) => {
    const monthIndex = MONTHS_OF_YEAR.indexOf(month)
    if (monthIndex !== -1) {
      props.onSelectionChange(props.selectedYear, monthIndex)
    }
    closePopup()
  }, [props])

  const onYearClick = React.useCallback((y: string) => {
    const year = parseInt(y, BASE_TEN)
    if (year > maxYear || year < minYear) {
      console.log('Year not in range')
      return
    }
    // NOTE: Reset to startMonth if switching to minYear and currently selected month is before the startMonth
    const monthIndex = year === minYear && props.selectedMonthIndex < props.startMonthIndex
      ? props.startMonthIndex
      : props.selectedMonthIndex
    props.onSelectionChange(year, monthIndex)
  }, [props, maxYear, minYear])

  const onYearKeyDown = React.useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const value = (e.target as HTMLInputElement).value.toLowerCase().trim()
      if (value) {
        const year = parseInt(value, BASE_TEN)
        if (year > maxYear || year < minYear) {
          console.log('Year not in range')
          return
        }
        props.onSelectionChange(year, props.selectedMonthIndex)
        closePopup()
      }
    }
  }, [props, maxYear, minYear])

  const showPrev = React.useMemo(() => {
    if (props.startMonthIndex === props.selectedMonthIndex && props.selectedYear === minYear) {
      return false
    }
    return props.selectedYear > minYear || props.selectedYear === minYear && props.selectedMonthIndex > 0
  }, [minYear, props.startMonthIndex, props.selectedMonthIndex, props.selectedYear])

  const openPopup = (e: React.MouseEvent<HTMLElement>) => {
    setPopupAnchor(e.target as HTMLElement)
    e.stopPropagation()
  }

  const closePopup = () => {
    setPopupAnchor(null)
  }

  const thisYear = new Date().getFullYear()
  const visibleYears = [thisYear]
  for (let i = 1; i < VISIBLE_YEARS_COUNT; i++) {
    visibleYears.push(thisYear + i)
  }

  return (
    <div className={`${styles['month-picker']} ${props.className || ''}`}>
      <div className={styles.selection}>
        {showPrev && (
          <IconPrev className={styles['btn-nav']} role="button" onClick={selectPrev} data-testid="month-nav-prev" />
        )}
        <div className={styles.active} data-testid="btn-open-month-picker" onClick={openPopup}>
          {rangeLabel || (
            <React.Fragment>
              <FormattedMessage id={`calendar.months.${monthText}`} />
              {props.selectedYear !== thisYear && `, ${props.selectedYear}`}
            </React.Fragment>
          )}
        </div>
        <IconNext className={styles['btn-nav']} role="button" onClick={selectNext} data-testid="month-nav-next" />
      </div>
      <Popper
        open={Boolean(popupAnchor)}
        anchorEl={popupAnchor}
        disablePortal
        placement="bottom"
        className={styles.popper}
      >
        <ClickAwayListener onClickAway={closePopup}>
          <Paper data-testid="month-picker-popup">
            <div className={styles.top}>
              {visibleYears.map(year => (
                <LabelButton key={year} active={year === props.selectedYear} label={year.toString()} onClick={onYearClick}>
                  {year}
                </LabelButton>
              ))}
              <input
                type="number"
                className={`${styles.btn} ${styles['input-year']}`}
                placeholder="Year"
                onKeyDown={onYearKeyDown}
              />
            </div>
            <div className={styles.grid}>
              {MONTHS_OF_YEAR.map((m, index) => (
                <LabelButton
                  key={m}
                  active={m === monthText}
                  label={m}
                  disabled={props.selectedYear === minYear && index < props.startMonthIndex}
                  onClick={onMonthSelected}
                >
                  <FormattedMessage id={`calendar.months.${m}`} />
                </LabelButton>
              ))}
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

function LabelButton(props: React.PropsWithChildren<{
  label: string,
  active: boolean,
  disabled?: boolean,
  onClick: (label: string) => void
}>) {
  const onClick = () => {
    props.onClick(props.label)
  }

  const disabledClass = props.disabled ? styles.disabled : ''
  const activeClass = props.active ? styles['btn-active'] : ''

  return (
    <div className={`${styles.btn} ${disabledClass} ${activeClass}`} role="button" onClick={onClick}>
      {props.children}
    </div>
  )
}

export default MonthPicker
