import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from './api'
import { Feed, InteractionEvent, Stream } from 'interfaces'
import StoreState from 'store/state'
import { currentUserSelector } from 'services/users/selectors'
import { sortByKeyAscending } from 'utils/sort/order'
import { EVENT_SAVE_STREAM } from 'routes/trial/interfaces'
import { trackTrainingEvent } from 'services/users/actions'
import { HS_EVENT_SOURCE_SAVED, trackHubspotEvent } from 'services/tracking/hubspot'
import { streamsSelector, userStreamsSelector } from '../selectors'
import { streamAdapter } from '../adapters'
import { impersonationSelector } from 'admin/services/selectors'

export const createStream = createAsyncThunk(
  'content/streams/createStream',
  async (
    args: {
      name: string,
      id?: string,
      color?: string,
      feeds?: Feed[],
      isPrivate?: boolean,
      file?: File,
      featuredImage?: string,
      secondaryImages?: string[]
    },
    { dispatch, getState, rejectWithValue }) => {
    const state = getState() as StoreState
    const user = currentUserSelector(state)
    const userStreams = [...userStreamsSelector(state)]
    const streamsOrder = userStreams.sort(sortByKeyAscending('order')).map(s => s.id)
    const userId = user?.userId as string
    const { name, id, color, feeds, isPrivate, file, featuredImage, secondaryImages } = args
    try {
      const response = await dispatch(api.createStream(name, userId, 0, id, color, feeds, isPrivate, file, featuredImage, secondaryImages))
        .toPromise()
      dispatch(trackStreamInteraction({ streamIds: [response.id], event: 'open' }))
      dispatch(trackTrainingEvent({ eventCode: EVENT_SAVE_STREAM }))
      if (id) {
        dispatch(trackHubspotEvent(HS_EVENT_SOURCE_SAVED, { type: 'stream' }))
      }
      return dispatch(reorderStreams([response.id, ...streamsOrder]))
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getStreams = createAsyncThunk(
  'content/streams/getStreams',
  async (force: boolean = false, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as StoreState
    const storedStreams = userStreamsSelector(state) as Stream[]
    if (!force && storedStreams.length > 0) {
      return storedStreams
    }
    try {
      const response = await dispatch(api.getStreams()).toPromise()
      const streams = response.map(streamAdapter).filter(s => !s.protected)
      return streams
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getRandomStreams = createAsyncThunk(
  'content/streams/getRandomStreams',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await dispatch(api.getRandomStreams()).toPromise()
      return response.map(streamAdapter)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const deleteStream = createAsyncThunk(
  'content/streams/deleteStream',
  async (streamId: string, { dispatch, rejectWithValue }) => {
    try {
      await dispatch(api.deleteStream(streamId)).toPromise()
      dispatch(getStreams(true))
      return streamId
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const reorderStreams = createAsyncThunk(
  'content/streams/reorderStreams',
  async (streams: Array<string>, { dispatch }) => {
    await dispatch(api.reorderStreams(streams)).toPromise().then(() => dispatch(getStreams(true)))
    return streams
  }
)

export const getUserStream = createAsyncThunk(
  'content/streams/getUserStream',
  async (args: { streamId: string, force?: boolean }, { dispatch, getState, rejectWithValue }) => {
    const { streamId, force } = args
    if (!force) {
      const state = getState() as StoreState
      const storedStreams = userStreamsSelector(state)
      const stream = storedStreams.find(s => s.id === streamId)
      if (stream) {
        return stream
      }
    }

    try {
      const response = await dispatch(api.getUserStream(streamId)).toPromise()
      return streamAdapter(response[0])
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const updateStream = createAsyncThunk(
  'content/streams/updateStream',
  async (
    args: {
      id: string,
      name: string,
      color?: string,
      file?: File,
      isPrivate?: boolean,
      featuredImage?: string,
      secondaryImages?: string[]
    },
    { dispatch, getState, rejectWithValue }) => {
    const userId = currentUserSelector(getState() as StoreState)?.userId as string
    const { id, name, color, file, isPrivate, featuredImage, secondaryImages } = args
    try {
      const response = await dispatch(api.updateStream(id, name, userId, color, file, isPrivate, featuredImage, secondaryImages))
        .toPromise().then((str) => dispatch(getUserStream({ streamId: str.id, force: true })))
      return response
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getStream = createAsyncThunk(
  'content/streams/getStream',
  async (args: { streamId: string, force?: boolean, withFeeds?: boolean }, { dispatch, getState, rejectWithValue }) => {
    const { streamId, force, withFeeds } = args
    if (!force) {
      const storedStreams = streamsSelector(getState() as StoreState)
      const stream = storedStreams[streamId]
      if (stream) {
        return stream
      }
    }

    try {
      const response = await dispatch(api.getStream(streamId, withFeeds)).toPromise()
      return streamAdapter(response[0])
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const trackStreamInteraction = createAsyncThunk(
  'content/recommended/trackStreamInteraction',
  async (args: { streamIds: string[], event: InteractionEvent }, { dispatch, getState }) => {
    const { streamIds, event } = args
    const impersonate = impersonationSelector(getState() as StoreState)
    if (impersonate) {
      console.log('[tracking] Tracking is disabled when impersonating user')
      return streamIds
    }

    await dispatch(api.registerStreamInteraction(streamIds, event)).toPromise()
    return streamIds
  }
)
