const snakeCase = require('snake-case')

type IndexedObject = { [index: string]: any }

/**
 * Converts object keys to snake_case
 *
 * @param {IndexedObject} obj
 * @returns {IndexedObject}
 */
export function toSnakeCase(obj: IndexedObject): IndexedObject
export function toSnakeCase(obj: undefined): undefined
export function toSnakeCase(obj: null): null
export function toSnakeCase(obj: number): number
export function toSnakeCase(obj: string): string
export function toSnakeCase(obj: IndexedObject | undefined | null | string | number): IndexedObject | undefined | null | string | number {
  if (typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean' || !obj) {
    return obj
  }

  // converts each value to underscoredProps
  if (Array.isArray(obj)) {
    return obj.map(toSnakeCase) as IndexedObject
  }

  // Underscore the keys and call for each value
  return Object.keys(obj).reduce((result, key) => {
    const underscoredKey = snakeCase(key)
    result[underscoredKey] = toSnakeCase(obj[key])
    return result
  }, {} as IndexedObject)
}

export default toSnakeCase
