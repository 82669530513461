import * as React from 'react'
import { FileFolder, MyFile, WithIntl, ALL_FILTER, ContentType } from 'interfaces'
import FileCard, { FileUploadCTACard } from 'components/FileCard'
import CardList from 'components/CardList'
import FileFolderViewHeader from '../FileFolderViewHeader'
import { injectIntl, FormattedMessage } from 'react-intl'
import { ConnectedFileInput } from 'components/FileHelpers'
import styles from './FileFolderCarousel.pcss'
import EmptyView from 'components/EmptyView'
import { mdiAlertCircleOutline } from '@mdi/js'
import { useSelector, useDispatch } from 'react-redux'
import { selectedContentItemsSelector } from 'services/content/selectors'
import { librarySortingSelector } from 'services/uploads/selectors'
import { setSorting } from 'services/uploads'

const MY_LIB_URL = '/content/library'
const PAGE_SIZE = 20

export interface FileFolderCarouselProps extends WithIntl {
  folder: FileFolder
  navPrefix?: string
  withEmptyViewCTA?: boolean
  withCTACard?: boolean
  hideHeader?: boolean
  title?: React.ReactNode
  onCreatePost: (file: MyFile) => void
  onCardClick?: (file: MyFile) => void
  navigate?: (url: string) => void
}

export function FileFolderCarousel(props: FileFolderCarouselProps) {
  const dispatch = useDispatch()
  const folder = props.folder
  const filters = folder.filters
  const selectedItems = useSelector(selectedContentItemsSelector)
  const sorting = useSelector(librarySortingSelector)
  const folderSorting = sorting[folder.id]
  const defaultFilter = filters.length !== 1 ? ALL_FILTER : folder.filters[0]
  const [filter, setFilter] = React.useState(defaultFilter)
  const [page, setPage] = React.useState(1)

  const visibleFiles = React.useMemo(() => {
    const filtered = folder
      ? filter === ALL_FILTER
        ? Object.values(folder.files)
        : Object.values(folder.files).filter(f => f.type === filter)
      : []

    const sorted = filtered.sort((f1: MyFile, f2: MyFile) => {
      // sort files based on sortBy and sortDirection
      if (folderSorting?.sortBy === 'name') {
        return folderSorting?.sortDirection === 'asc'
          ? f2.name.localeCompare(f1.name)
          : f1.name.localeCompare(f2.name)
      }
      return folderSorting?.sortDirection === 'asc'
        ? f1.createdAt.localeCompare(f2.createdAt)
        : f2.createdAt.localeCompare(f1.createdAt)
    })
    return sorted
  }, [filter, folder, folderSorting?.sortBy, folderSorting?.sortDirection])

  const visibleFilesEndIndex = page * PAGE_SIZE

  const onRequestNextPage = () => {
    if (page * PAGE_SIZE < visibleFiles.length) {
      setPage(page + 1)
    }
  }

  const navigateToMyLibrary = () => {
    if (props.navigate) {
      props.navigate(MY_LIB_URL)
    }
  }

  const onSortChange = (sortBy: 'name' | 'date', sortDirection: 'asc' | 'desc') => {
    dispatch(setSorting({ folderId: folder.id, sortBy, sortDirection }))
  }

  if (filter !== defaultFilter && !filters.includes(filter as ContentType)) {
    setFilter(defaultFilter)
  }

  const isEmptyFolder = Object.keys(props.folder.files).length === 0
  const showEmptyCTA = isEmptyFolder && props.withEmptyViewCTA

  return (
    <div className={styles.container} data-test="file-folder-carousel">
      {
        !props.hideHeader && (
          <FileFolderViewHeader
            folderId={folder.id}
            title={props.title}
            folderName={folder.name}
            navPrefix={props.navPrefix}
            activeFilter={filter}
            filters={filters}
            sorting={{
              sortBy: folderSorting?.sortBy || 'date',
              sortDirection: folderSorting?.sortDirection || 'desc',
              onChange: onSortChange
            }}
            onFilterChange={setFilter}
          />
        )
      }
      <div className={`${styles['list-box']} ${showEmptyCTA ? styles['with-cta'] : ''}`}>
        {
          visibleFiles.length > 0 ? (
            <CardList
              containerClassName={styles.list}
              skipCardHoverCheck
              onScrollLimit={onRequestNextPage}
            >
              {
                visibleFiles.slice(0, visibleFilesEndIndex).map(f => (
                  <FileCard
                    small
                    file={f}
                    selected={Boolean(selectedItems[f.id])}
                    key={f.id}
                    onClick={props.onCardClick}
                    onCreatePost={props.onCreatePost}
                  />
                ))
              }
              {props.withCTACard && <FileUploadCTACard small />}
            </CardList>
          ) : !(props.withEmptyViewCTA && isEmptyFolder) && (
            <EmptyView
              title={props.intl.formatMessage({ id: `uploads.error.carousel-no-${filter}` })}
              icon={mdiAlertCircleOutline}
              carousel
            />
          )
        }
        {
          showEmptyCTA && (
            <EmptyView
              title={props.intl.formatMessage({ id: `uploads.error.empty-view-carousel-title` })}
              subtitle={(
                <ConnectedFileInput
                  key="file-input"
                  openDialogOnChange
                  onChange={navigateToMyLibrary}
                >
                  <div className={styles['upload-link']} key="link">
                    <FormattedMessage id="uploads.upload-content-cta" key="message" />
                  </div>
                </ConnectedFileInput>
              )}
              icon={mdiAlertCircleOutline}
              carousel
            />
          )
        }
      </div>
    </div>
  )
}

export default injectIntl(FileFolderCarousel)
