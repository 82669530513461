import * as React from 'react'
import { IndexedObject, PinterestBoard, PostDestination } from 'interfaces'
import PinterestBoardSelector from '../AlbumSelector/PinterestBoardSelector'
import styles from './CustomizePostBlock.pcss'
import { useSelector } from 'react-redux'
import {
  pinterestProfilesBoardsSelector,
  composerPinterestPostStatusSelector,
  PINTEREST_BOARD_NOT_SELECTED_MESSAGE_KEY
} from 'services/compose/selectors'
import Dialog from '@mui/material/Dialog'
import DeleteIcon from '@mui/icons-material/Close'
import AlbumIcon from '@mui/icons-material/Collections'
import WarningIcon from '@mui/icons-material/WarningRounded'
import IconButton from '@mui/material/IconButton'
import Chip from '@mui/material/Chip'
import { FormattedMessage } from 'react-intl'

interface ComposerPinterestPostOptionsProps {
  profiles: PostDestination[]
  className?: string
  boards?: IndexedObject<Array<{
    id: string
    name: string
    url?: string
    selected?: boolean
  }>>
  selectionDone?: boolean
  onSelectedBoardChange?: (ppid: string, board: PinterestBoard) => void
}

export function ComposerPinterestPostOptions(props: ComposerPinterestPostOptionsProps) {
  const composerBoards = useSelector(pinterestProfilesBoardsSelector)
  const boards = props.boards || composerBoards
  const pinterestPostStatus = useSelector(composerPinterestPostStatusSelector)
  const [popupOpen, setPopupOpen] = React.useState(false)

  const closePopup = () => {
    setPopupOpen(false)
  }

  const openPopup = () => {
    setPopupOpen(true)
  }

  const isBoardsSelectionComplete = props.selectionDone
    || (!pinterestPostStatus.isEmpty && !pinterestPostStatus.caption.errors.includes(PINTEREST_BOARD_NOT_SELECTED_MESSAGE_KEY))

  return (
    <div className={props.className}>
      {props.profiles.length === 1 && (
        <div className={styles['option-box']}>
          <PinterestBoardSelector
            profile={props.profiles[0]}
            boards={boards[props.profiles[0].ppid]}
            onSelectedBoardChange={props.onSelectedBoardChange}
          />
        </div>
      )}
      {props.profiles.length > 1 && (
        <div className={styles['option-box']}>
          <Chip
            label={
              <FormattedMessage
                id={isBoardsSelectionComplete ? 'composer.labels.selected-boards-change' : 'composer.labels.select-multiple-boards'}
              />
            }
            avatar={isBoardsSelectionComplete ? (
              <AlbumIcon className={styles['icon-album']} />
            ) : (
              <WarningIcon className={styles['icon-album']} />
            )}
            classes={{ root: styles.chip, label: styles.label }}
            onClick={openPopup}
          />
        </div>
      )}
      <Dialog open={popupOpen} onClose={closePopup} classes={{ paper: styles['albums-popup'] }}>
        <IconButton className={styles['btn-close']} onClick={closePopup} size="large">
          <DeleteIcon />
        </IconButton>
        <h3>
          <FormattedMessage id="composer.labels.select-multiple-boards" />
        </h3>
        <div className={styles['albums-popup-content']}>
          {props.profiles.map(p => (
            <div key={p.id} className={styles['option-box']}>
              <PinterestBoardSelector
                profile={p}
                boards={boards[p.ppid]}
                raised
                onSelectedBoardChange={props.onSelectedBoardChange}
              />
            </div>
          ))}
        </div>
      </Dialog>
    </div>
  )
}
