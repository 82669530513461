import {
  PHOTO_TYPE,
  ARTICLE_TYPE,
  VIDEO_TYPE,
  STATUS_TYPE,
  ContentType,
  BRAND_FACEBOOK,
  PostDestinationType,
  BRAND_TWITTER,
  PostType,
  POST_TYPE_POSTED,
  NO_TIME_SLOT_VALUE,
  BRAND_LINKEDIN,
  BRAND_INSTAGRAM,
  BRAND_PINTEREST
} from 'interfaces'
import { BRAND_GOOGLE, BRAND_TIKTOK } from 'interfaces/Content/SocialBrand'
import numberToHuman from 'utils/format/numberToHuman'
import { decodeHtml } from 'utils/browser'
import { INPUT_ACCEPT_VIDEO } from 'utils/file/constants'
import { MS_IN_SEC } from 'utils/constants'
import { LEGACY_BUCKET_NAMES_MAP } from 'services/plan/adapter'
import { AutoPostSlot } from '../state'
import toCamelCase from 'utils/toCamelCase'
import { BRAND_YOUTUBE, INPUT_ACCEPT_PHOTO } from 'shared'
import { getFileThumbUrl } from 'services/uploads/util'
import { format } from 'date-fns'

import { formatInTimeZone } from 'date-fns-tz'

const BASE_TEN = 10
const PREVIEW_PROCESSING_KEYWORD = 'waiting_processing'

export const LEGACY_TYPE_MAP: { [legacyType: string]: ContentType } = {
  photo: PHOTO_TYPE,
  link: ARTICLE_TYPE,
  video: VIDEO_TYPE,
  status: STATUS_TYPE
}

const POST_NETWORK_MAP: { [network: string]: PostDestinationType } = {
  [BRAND_FACEBOOK]: BRAND_FACEBOOK,
  fb: BRAND_FACEBOOK,
  [BRAND_TWITTER]: BRAND_TWITTER,
  tw: BRAND_TWITTER,
  [BRAND_LINKEDIN]: BRAND_LINKEDIN,
  ig: BRAND_INSTAGRAM,
  [BRAND_INSTAGRAM]: BRAND_INSTAGRAM,
  [BRAND_PINTEREST]: BRAND_PINTEREST,
  pi: BRAND_PINTEREST,
  [BRAND_TIKTOK]: BRAND_TIKTOK,
  [BRAND_GOOGLE]: BRAND_GOOGLE,
  [BRAND_YOUTUBE]: BRAND_YOUTUBE
}

function formatStats(stats: string | null) {
  if (stats === null) {
    return 0
  }

  const count = parseInt(stats, BASE_TEN)
  if (Number.isNaN(count)) {
    return '--'
  }

  return numberToHuman(count)
}

function getPostStats(post: any) {
  const stats: any = {
    likes: parseInt(post.likes, BASE_TEN),
    likesFormatted: formatStats(post.likes),
    shares: parseInt(post.shares, BASE_TEN),
    sharesFormatted: formatStats(post.shares),
    comments: parseInt(post.comments, BASE_TEN),
    commentsFormatted: formatStats(post.comments),
    clicks: parseInt(post.clicks, BASE_TEN),
    clicksFormatted: formatStats(post.clicks)
  }
  if (POST_NETWORK_MAP[post.networkType] === BRAND_PINTEREST) {
    const data = JSON.parse(post.insightsDetails)
    stats.clicks = data?.pin_click || post.clicks || 0
    stats.clicksFormatted = formatStats(stats.clicks.toString())
    stats.saves = data?.save || 0
    stats.savesFormatted = formatStats(stats.saves.toString())
    stats.impressions = data?.impression || 0
    stats.impressionsFormatted = formatStats(stats.impressions.toString())
    stats.isRepin = Boolean(data?.is_repin)
  }
  return stats
}

export function postAdapter<T>(post: any, index: number): any {
  const contentType = LEGACY_TYPE_MAP[post.typeText]
  let specialType
  const videoUrl = post.linkUrl || post.file
  const fileUrl = post.file?.split(/\#|\?/)[0]
  const fileExtension = '.' + fileUrl?.split('.').pop().toLowerCase()
  const images: string[] = []
  if (contentType === PHOTO_TYPE) {
    const files = post.files ? JSON.parse(post.files) : []
    images.push(post.file, ...files)
  } else if (contentType === ARTICLE_TYPE || contentType === VIDEO_TYPE) {
    if (post.linkImage) {
      images.push(post.linkImage)
    } else if (contentType === ARTICLE_TYPE && post.file) {
      images.push(post.file)
    }
    if (INPUT_ACCEPT_PHOTO.includes(fileExtension)) {
      images.push(fileUrl)
    }
  }

  const nativeVideoUrl = contentType === VIDEO_TYPE && fileExtension
    && (INPUT_ACCEPT_VIDEO.includes(fileExtension) || post.file?.includes('licdn')) && videoUrl

  let details: any
  try {
    details = toCamelCase(JSON.parse(post.details))
  } catch (e) {
    details = undefined
  }

  // EXPL: Map IG location to profile
  if (details && details.locations) {
    details.location = details.locations.find((loc: any) => loc.ppPageId === post.ppPageId)
  }
  if (details?.videoThumbnail && nativeVideoUrl) {
    details.videoThumbnailUrl = getFileThumbUrl(nativeVideoUrl, 'videos')
  }
  if (details?.postType) {
    specialType = details.postType
  }
  if (details?.igPostType) {
    details.postType = details.igPostType
    specialType = details.igPostType
  } else if (details?.textBackground) {
    specialType = 'tobi'
  } else if (details?.documentUrl) {
    specialType = 'document'
  }

  const isEmpty = post.id === 'empty'
  const now = Date.now()
  const buckets = Array.isArray(post.postTypes) ? post.postTypes : []
  const network = POST_NETWORK_MAP[post.networkType]
  const linkTitle = decodeHtml(post.linkTitle || '')
  let attributes: any = network === BRAND_PINTEREST ? {
    title: linkTitle,
    description: post.linkDescription,
    url: post.linkUrl,
    pinterestBoardUrl: details?.photoAlbum?.url
  } : null
  if (network === BRAND_YOUTUBE) {
    attributes = {
      title: post.status,
      description: post.description
    }
  }

  const baseTime = post.baseTime ? parseInt(post.baseTime, BASE_TEN) * MS_IN_SEC : null
  const timestamp = parseInt(post.time, BASE_TEN)
  const time = timestamp * MS_IN_SEC

  let recycle: Date | number | boolean = post.reQueue === '1'
  if (recycle) {
    if (post.recycleUntil) {
      recycle = new Date(post.recycleUntil)
    } else if (post.recycleTimes) {
      recycle = parseInt(post.recycleTimes, BASE_TEN)
    }
  }

  const repeatUntil = post.repeatUntil || 'forever'

  let networkLink = post.realLink
  const networkId = JSON.parse(post.networkResponse || '{}').id
  if (!networkLink && network === BRAND_LINKEDIN && post.statusIdV3) {
    networkLink = `https://www.linkedin.com/feed/update/${post.statusIdV3}`
  } else if (networkId && !networkLink && network === BRAND_YOUTUBE) {
    networkLink = `https://www.youtube.com/watch?v=${networkId}`
  }

  return {
    ...post,
    createdAt: post.firstCreatedAt || post.createdAt,
    weekday: post.weekday || NO_TIME_SLOT_VALUE,
    network,
    attributes,
    id: isEmpty ? `empty-${index}-${now}` : post.postId,
    isEmpty,
    contentType,
    specialType,
    recycle,
    images: images.filter(Boolean),
    profilePictureUrl: post.picture,
    profileId: post.networkIdV3,
    time,
    baseTime,
    timezone: post.timeTimezone,
    twPostType: post.twPostType,
    repeatUntil,
    status: post.status || '',
    dateString: post.date,
    calendarDate: time ? formatInTimeZone(time, post.timeTimezone, 'LL/dd/yy') : '',
    hoursLocal: time ? parseInt(formatInTimeZone(time, post.timeTimezone, 'H'), 10) : 0,
    linkTitle,
    linkDescription: decodeHtml(post.linkDescription || ''),
    timeString: post.date === 'Unknown' ? NO_TIME_SLOT_VALUE : post.baseTimeString || post.timeString,
    nativeVideoUrl,
    fbVideoUrl: contentType === VIDEO_TYPE && post.linkDomain === 'facebook.com' && post.linkUrl,
    buckets: buckets.map((b: string) => LEGACY_BUCKET_NAMES_MAP[b]),
    originalTweetUrl: post.attachmentUrl,
    processingPreview: post.linkTitle === PREVIEW_PROCESSING_KEYWORD
      || post.linkDescription === PREVIEW_PROCESSING_KEYWORD
      || post.linkImage === PREVIEW_PROCESSING_KEYWORD
      || post.linkImage === 'autodetect',
    details,
    realLink: networkLink
  } as T
}

export function postedPostAdapter(post: any, index: number): any {
  const stats = getPostStats(post)
  const data = postAdapter(post, index)

  return {
    ...data,
    ...stats
  }
}

export function postsResponseAdapter<T>(response: { posts: any[] }, type?: PostType) {
  if (type === POST_TYPE_POSTED) {
    return response.posts.map(postedPostAdapter) as T[]
  }

  return response.posts.map(postAdapter) as T[]
}

export function autoPostSlotAdapter(slot: any): AutoPostSlot {
  const buckets = Array.isArray(slot.postTypes) ? slot.postTypes : []
  const time = slot.time * MS_IN_SEC
  return {
    id: `${slot.postId || slot.id}-${time}`,
    time,
    ppPageId: slot.ppPageId,
    buckets: buckets.map((b: string) => LEGACY_BUCKET_NAMES_MAP[b]),
    dateString: format(time, 'dd.LLL.yy hh:mm a').toString(),
    timeString: formatInTimeZone(time, slot.timeTimezone, 'hh:mm a'),
    weekday: slot.weekday
  }
}
