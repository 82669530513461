import * as React from 'react'

function injectScript() {
  return (function (doc: any, src: any) {
    const a: any = doc.getElementsByTagName('head')[0]
    const r: any = doc.createElement('script')
    r.dataset.rewardful = 'f1dfff'
    r.async = 1
    r.src = src
    a.appendChild(r)
  })(document, 'https://r.wdfl.co/rw.js')
}

export function Rewardful() {
  React.useEffect(() => {
    (function (w: any, r) { w._rwq = r; w[r] = w[r] || function () { (w[r].q = w[r].q || []).push(arguments) } })(window, 'rewardful')
    injectScript()
  }, [])

  return null
}
