import Paper from '@mui/material/Paper'
import React from 'react'
import { Prompt } from 'shared'
import styles from './PromptCard.pcss'
import { fontSizesFor } from 'utils/fontSize'
import Tooltip from '@mui/material/Tooltip'
import { FormattedMessage } from 'react-intl'
import IconButton from '@mui/material/IconButton'
import Icon from '@mdi/react'
import { mdiFeather, mdiTrashCan } from '@mdi/js'
import FavButton from 'components/Card/FavButton'
import { Observable } from 'rxjs/Observable'

const Clipboard = require('clipboard')

interface PromptCardProps {
  prompt: Prompt
  isFavorite?: boolean
  selected: boolean
  className?: string
  contentClassName?: string
  onCompose?: (p: Prompt) => void
  onDelete?: (id: string) => void
  onClick: (p: Prompt) => void
  onFavorite?: (p: Prompt) => Observable<any>
  onUnfavorite?: (p: Prompt) => Observable<any>
}

export function PromptCard({
  prompt, selected, className, contentClassName, isFavorite, onFavorite, onUnfavorite, onCompose, onDelete, onClick
}: PromptCardProps) {
  const clipboard = React.useRef(null)
  const getClipboardText = () => prompt.text

  const setRef = (element: HTMLElement | null) => {
    if (element) {
      if (clipboard.current) {
        (clipboard.current as any).destroy()
      }

      clipboard.current = new Clipboard(
        element,
        {
          text: getClipboardText,
          container: element
        }
      )
      ; (clipboard.current as any).on('success', () => {
        if (onCompose) {
          onCompose(prompt)
        }
      })
      ; (clipboard.current as any).on('error', (e: any) => {
        console.log('Clipboard error', e)
      })
    }
  }

  const handleClick = () => {
    onClick(prompt)
  }

  const handleDelete = () => {
    if (onDelete) {
      onDelete(prompt.id)
    }
  }

  const handleCompose = () => {
    if (onCompose) {
      onCompose(prompt)
    }
  }

  const favorite = () => {
    return onFavorite ? onFavorite(prompt) : Observable.of(null)
  }

  const unfavorite = () => {
    return onUnfavorite ? onUnfavorite(prompt) : Observable.of(null)
  }

  const fontSizeStyles = fontSizesFor(prompt.text)
  const showActions = onCompose || onDelete

  return (
    <Paper className={`${styles.card} ${selected ? styles.active : ''} ${className || ''}`} onClick={handleClick}>
      <div className={`${styles.text} ${contentClassName || ''}`} style={fontSizeStyles.normal}>{prompt.text}</div>
      {showActions && (
        <div className={styles.actions}>
          {onCompose && (
            <Tooltip title={<FormattedMessage id="content-card.actions.create-post" />} placement="left">
              <IconButton
                size="small"
                color="primary"
                className={styles['btn-compose']}
                ref={setRef}
                onClick={handleCompose}
              >
                <Icon path={mdiFeather} size="20px" />
              </IconButton>
            </Tooltip>
          )}
          {onDelete && (
            <Tooltip title={<FormattedMessage id="actions.delete" />} placement="left">
              <IconButton
                size="small"
                className={styles['btn-delete']}
                onClick={handleDelete}
              >
                <Icon color="#fff" path={mdiTrashCan} size="20px" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      )}
      {onFavorite && onUnfavorite && (
        <FavButton
          isFavorite={Boolean(isFavorite)}
          hover={false}
          className={styles['btn-fav']}
          onFavorite={favorite}
          onUnfavorite={unfavorite}
        />
      )}
    </Paper>
  )
}
