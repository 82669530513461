import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { AppNavLink } from '../AppNavLink'
import IconButton from '@mui/material/IconButton'
import IconEdit from '@mui/icons-material/Edit'
import { myFileFoldersArraySelector, myUploadsStorageSelector } from 'services/uploads/selectors'
import { ConnectedFileInput } from 'components/FileHelpers'
import Tooltip from '@mui/material/Tooltip'
import { reorder, sortByKeyAscending } from 'utils/sort/order'
import { useDispatch, useSelector } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import styles from '../AppNavigation.pcss'
import { DndContext, useDroppable, useSensors, useSensor, PointerSensor } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { SortableContext, rectSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { FileFolder } from 'interfaces'
import { Subject } from 'rxjs/Subject'
import { catchError } from 'rxjs/operators/catchError'
import { message } from 'services/snackbar'
import { getStorageInfo, reorderFolders, setEditFolder, setUploadsDialogOpen } from 'services/uploads/actions'
import { Observable } from 'rxjs/Observable'
import LinearProgress from '@mui/material/LinearProgress'
import { bytesToSize } from 'shared'

interface LibraryNavMenuProps {
  expanded: boolean
  onToggleExpanded: () => void
}

export function LibraryNavMenu(props: LibraryNavMenuProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const intl = useIntl()
  const fileFolders = useSelector(myFileFoldersArraySelector)
  const filesCount = fileFolders.reduce((count, folder) => count + folder.filesCount, 0)
  const storage = useSelector(myUploadsStorageSelector)
  const foldersMap = React.useMemo(() => fileFolders.reduce((map: Record<string, FileFolder>, f: FileFolder) => {
    map[f.id] = f
    return map
  }, {}), [fileFolders])
  const idOrder = React.useMemo(() => fileFolders.slice().sort(sortByKeyAscending('order')).map(f => f.id), [fileFolders])
  const [order, setOrder] = React.useState<string[]>(idOrder)
  const { setNodeRef: setDroppableNodeRef } = useDroppable({ id: 'droppable' })
  const orderRef = React.useRef<string[]>(idOrder)
  const reorder$ = React.useRef<Subject<string[]>>()

  React.useEffect(() => {
    dispatch(getStorageInfo())
  }, [filesCount, dispatch])

  React.useEffect(() => {
    setOrder(idOrder)
    orderRef.current = idOrder
  }, [idOrder])

  React.useEffect(() => {
    reorder$.current = new Subject()
    reorder$.current.switchMap((ids) => {
      return Observable.fromPromise(dispatch(reorderFolders(ids)).unwrap()).pipe(catchError((error) => {
        dispatch(message(intl.formatMessage({ id: 'notifications.generic.update-failed' }), 'error'))
        return Observable.of({ error })
      }))
    })
      .subscribe((response: any) => {
        if (response.error) {
          // revert to previous ordering
          setOrder(orderRef.current)
        } else {
          setOrder(response.ids)
          orderRef.current = response.ids
        }
      })
  }, [dispatch, intl])

  const editFileFolder = (f: FileFolder) => {
    dispatch(setEditFolder(f))
  }

  const openFileUpload = () => {
    dispatch(setUploadsDialogOpen(true))
  }

  const onDragEnd = (event: any) => {
    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = order.indexOf(active.id)
      const newIndex = order.indexOf(over.id)
      const reordered = reorder(order, oldIndex, newIndex)
      setOrder(reordered)
      reorder$.current?.next(reordered)
    }
  }

  // EXPL: Do not show value less than 5% as it will not be visible in the progress bar
  // eslint-disable-next-line no-magic-numbers
  const storageProgress = Math.max(5, storage.used * 100 / storage.limit)

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5
      }
    })
  )

  return (
    <DndContext sensors={sensors} onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]}>
      <SortableContext items={order} strategy={rectSortingStrategy} modifiers={[restrictToParentElement]}>
        <div ref={setDroppableNodeRef}>
          <AppNavLink
            expanded={props.expanded}
            toggleExpandedOnClick
            label={<FormattedMessage id="app.nav.my-library" />}
            to="/content/library"
            withTooltip={!props.expanded}
            items={
              <React.Fragment>
                {order.map(id => {
                  const folder = foldersMap[id]
                  if (!folder) {
                    return null
                  }
                  return (
                    <DraggableFolderLink
                      key={folder.id}
                      folder={folder}
                      onEdit={editFileFolder}
                    />
                  )
                })}
                <ConnectedFileInput onChange={openFileUpload} className={styles['btn-upload']}>
                  <div className={`${styles['child-link']} ${styles['link-action']}`}>
                    + <FormattedMessage id="app.nav.my-library-create" />
                  </div>
                </ConnectedFileInput>
                {storage.limit > 0 && (
                  <div className={styles.progress}>
                    <LinearProgress
                      variant="determinate"
                      value={storageProgress}
                      classes={{ root: styles.line, bar: styles.bar }}
                    />
                    <div className={styles['progress-label']}>{`${bytesToSize(storage.used)} of ${bytesToSize(storage.limit)} used`}</div>
                  </div>
                )}
              </React.Fragment>
            }
            onToggleExpanded={props.onToggleExpanded}
          />
        </div>
      </SortableContext>
    </DndContext>
  )
}

interface DraggableFolderLinkProps {
  folder: FileFolder
  onEdit: (f: FileFolder) => void
}
function DraggableFolderLink(props: DraggableFolderLinkProps) {
  const { folder } = props
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: folder.id })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    pointerEvents: isDragging ? 'none' : 'all'
  } as any

  const edit = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    props.onEdit(folder)
  }

  return (
    <div className={styles['folders-nav']} ref={setNodeRef} style={style}>
      <AppNavLink
        id={folder.id}
        to={`/content/library/${folder.id}`}
        small
        label={(
          <div className={`${styles['name-box']}`} {...listeners} {...attributes}>
            <span className={`${styles.name} text-ellipsis`}>{folder.name}</span>
            <span className={styles.count}>{`(${folder.filesCount})`}</span>
          </div>
        )}
        actions={(
          <Tooltip title={<FormattedMessage id="actions.edit" />} placement="top">
            <IconButton size="small" onClick={edit}>
              <IconEdit className={styles['link-icon']} />
            </IconButton>
          </Tooltip>
        )}
        className={styles['child-link']}
      />
    </div>
  )
}
