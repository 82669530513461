import * as React from 'react'
import { ARTICLE_TYPE, ContentType, PHOTO_TYPE, STATUS_TYPE, VIDEO_TYPE } from 'interfaces'
import LinkIcon from '@mui/icons-material/Link'
import PhotoIcon from '@mui/icons-material/InsertPhoto'
import VideoIcon from '@mui/icons-material/Videocam'
import Icon from '@mdi/react'
import { mdiCommentTextOutline, mdiTextBoxOutline, mdiFileDocumentPlusOutline } from '@mdi/js'

import styles from './PostBase.pcss'

export function PostContentTypeIcon(props: { type: ContentType | 'reels' | 'stories' | 'tobi' | 'document', className?: string }) {
  switch (props.type) {
    case ARTICLE_TYPE:
      return <LinkIcon className={`${styles.icon} ${props.className || ''}`} />
    case PHOTO_TYPE:
      return <PhotoIcon className={`${styles.icon} ${props.className || ''}`} />
    case VIDEO_TYPE:
      return <VideoIcon className={`${styles.icon} ${props.className || ''}`} />
    case STATUS_TYPE:
      return <Icon path={mdiCommentTextOutline} data-icon-text size="26px" className={`${styles.icon} ${props.className || ''}`} />
    case 'reels':
      return (
        <img
          src="/static/img/icons/reel.svg"
          alt="reels"
          className={`${styles.icon} ${props.className || ''} ${styles['img-icon']}`}
        />
      )
    case 'stories':
      return (
        <img
          src="/static/img/icons/story.svg"
          alt="stories"
          className={`${styles.icon} ${props.className || ''} ${styles['img-icon']}`}
        />
      )
    case 'tobi':
      return <Icon path={mdiTextBoxOutline} data-icon-text size="26px" className={`${styles.icon} ${props.className || ''}`} />
    case 'document':
      return <Icon path={mdiFileDocumentPlusOutline} data-icon-text size="26px" className={`${styles.icon} ${props.className || ''}`} />
    default:
      return null
  }
}
