function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  /* eslint-disable no-magic-numbers */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */
  /* eslint-enable no-magic-numbers */

  return color
}

export function stringAvatar(name: string, size?: string, fontSize?: string) {
  const names = name.split(' ')
  const style: any = { bgcolor: stringToColor(name) }
  if (size) {
    style.width = size
    style.height = size
  }
  if (fontSize) {
    style.fontSize = fontSize
  }
  return {
    sx: style,
    children: names.length > 1 ? `${names[0][0]}${names[1][0]}` : names[0].slice(0, 2)
  }
}
