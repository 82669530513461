import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'
import { ContentType } from 'interfaces'

const ENDPOINT_REQUEUE_POSTS = `${LEGACY_URL}/v3/posts/requeue_v3`

export const V1 = Object.freeze({
  requeuePost: function () {
    return function (
      ids: string[],
      ppids: string[],
      all?: boolean,
      posted?: boolean,
      bucketId?: string,
      query?: string,
      types?: ContentType[]
    ) {
      const builder = new RequestBuilder(ENDPOINT_REQUEUE_POSTS).asPost().expectJSON()
      const data: any = {
        type: posted ? 'posted' : 'failed',
        pp_page_ids: ppids
      }

      if (bucketId) {
        data.bucket_id = bucketId
      }

      if (all) {
        data.selected = 'all'
        data.post_ids = []
      } else {
        data.post_ids = ids
      }
      if (query) {
        data.search = query
      }
      if (types) {
        data.post_types = types.join(',')
      }

      return authorizedPipe<any>(
        builder
          .body(data)
          .build()
      )
    }
  }()
})
