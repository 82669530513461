import { ARTICLE_TYPE, ContentType, PHOTO_TYPE, STATUS_TYPE, VIDEO_TYPE } from '../Content'
import { IndexedObject } from '../misc'

export interface RepeatInfo {
  willRepeat: boolean
  length: number
  period: RepeatPeriod
  until: 'forever' | Date
}

export interface PostScheduleInfo {
  postDate: string
  repeat: boolean
  repeatLength?: number
  repeatPeriod?: RepeatPeriod
  repeatUntil?: string
}

export const REPEAT_PERIOD_DAYS = 'days'
export const REPEAT_PERIOD_WEEKS = 'weeks'
export const REPEAT_PERIOD_MONTHS = 'months'
export const REPEAT_OPTIONS = [
  { value: REPEAT_PERIOD_DAYS, label: REPEAT_PERIOD_DAYS },
  { value: REPEAT_PERIOD_WEEKS, label: REPEAT_PERIOD_WEEKS },
  { value: REPEAT_PERIOD_MONTHS, label: REPEAT_PERIOD_MONTHS }
]
export const POST_TYPE_VIDEO_LINK = 'video_link'
export const POST_TYPE_PHOTO_LINK = 'photo'
export const POST_TYPE_ARTICLE_LINK = 'link'

export type RepeatPeriod = typeof REPEAT_PERIOD_DAYS
  | typeof REPEAT_PERIOD_MONTHS
  | typeof REPEAT_PERIOD_WEEKS

export const POST_TYPE_TO_CONTENT_TYPE: IndexedObject<ContentType> = {
  status: STATUS_TYPE,
  link: ARTICLE_TYPE,
  gif: PHOTO_TYPE,
  photo: PHOTO_TYPE,
  video: VIDEO_TYPE,
  video_link: VIDEO_TYPE
}
