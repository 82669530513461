import { useDispatch, useSelector } from 'react-redux'
import { createElement, useCallback, useEffect, useMemo, useState } from 'react'
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar'
import Button from '@mui/material/Button'
import { next } from 'services/snackbar/actions'
import { peekSnackbarSelector } from 'services/snackbar/selectors'
import SnackbarContent from '@mui/material/SnackbarContent'
import styles from './ConnectedSnackbar.pcss'
import { TreatWithAction } from 'services/snackbar/interfaces/PendingTreat'

const FORCE_HIDE_IN_MS = 250
const DEFAULT_TIMEOUT = 4000 // ms
export const NOTIFICATION_DURATION_LONG = 6000 // ms

function InnerSnackbar() {
  const dispatch = useDispatch()
  const peek = useSelector(peekSnackbarSelector)
  const [key, setKey] = useState(0)

  useEffect(() => {
    if (peek?.immediate) {
      setKey(current => current + 1)
    }
  }, [peek])

  const showNext = useCallback((_e: any, reason: string) => {
    if (reason === 'clickaway') {
      return
    }

    setTimeout(() => {
      dispatch(next())
      setKey(current => current + 1)
    }, FORCE_HIDE_IN_MS)
  }, [dispatch])

  const snackbarProps = useMemo(() => {
    const props: SnackbarProps = { open: Boolean(peek) }

    if (peek) {
      props.message = peek.content
      props.autoHideDuration = peek.timeout || DEFAULT_TIMEOUT
      props.onClose = showNext

      const onClick = () => {
        if ((peek as any).action) {
          (peek as any).action()
        }
        showNext(null, '')
      }

      if ((peek as TreatWithAction).action) {
        props.action = createElement(Button, {
          onClick,
          className: styles.button
        }, (peek as TreatWithAction).label)
      }
    }
    return props
  }, [peek, showNext])

  const commonProps: Partial<SnackbarProps> = {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center'
    },
    classes: { anchorOriginTopCenter: styles.top }
  }

  const { message, action, ...rest } = snackbarProps

  const closeSnack = () => {
    showNext(null, '')
  }

  return createElement(Snackbar, {
    ...commonProps,
    ...rest,
    onClick: closeSnack,
    key
  }, createElement(SnackbarContent, {
    message,
    action,
    classes: {
      message: styles.message,
      root: `${styles.content} ${styles[peek?.type || 'info']}`
    }
  }))
}

export default InnerSnackbar
