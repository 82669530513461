import * as React from 'react'
import { DatePicker as MDDatePicker, DatePickerProps as IDatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import styles from './DatePicker.pcss'

export interface DatePickerProps {
  value: Date
  minDate?: Date
  MDPickerProps?: Partial<IDatePickerProps<any>>
  disabled?: boolean
  className?: string
  calendarClassName?: string
  onChange: (value: Date) => void
}

export function DatePicker(props: DatePickerProps) {
  const [open, setOpen] = React.useState(false)
  const pickerProps = props.MDPickerProps || {}

  const openPicker = () => {
    setOpen(true)
  }

  const closePicker = () => {
    setOpen(false)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={`${props.className || ''} ${styles.box}`}>
        <MDDatePicker
          {...pickerProps}
          open={open}
          value={props.value}
          disabled={props.disabled}
          minDate={props.minDate}
          className={`${styles.root} ${props.disabled ? styles.disabled : ''}`}
          slotProps={{
            textField: {
              InputProps: { classes: { root: styles.pointer }, 'data-testid': 'date-picker-input' } as any,
              onClick: openPicker
            },
            inputAdornment: {
              className: styles['icon-btn']
            }
          }}
          reduceAnimations
          onChange={props.onChange}
          onOpen={openPicker}
          onClose={closePicker}
        />
      </div>
    </LocalizationProvider>
  )
}

export default DatePicker
