import * as React from 'react'
import { createElement } from 'react'
import { createRoot } from 'react-dom/client'
import App from 'components/App'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

require('utils/polyfills/url')

declare const __PROD__: boolean
if (__PROD__) {
  require('./pwa')
}

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(createElement(App))
