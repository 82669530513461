import React, { createElement, Component, ChangeEvent, useEffect, useState } from 'react'
import { findDOMNode } from 'react-dom'

import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import RotateIcon from '@mui/icons-material/RotateRight'
import {
  ALLOWED_PHOTO_FORMATS,
  ALLOWED_VIDEO_FORMATS,
  INPUT_ACCEPT_VIDEO,
  INPUT_ACCEPT_PHOTO,
  INPUT_ACCEPT_BULK,
  INPUT_ACCEPT_ALL,
  ALLOWED_BULK_EXTENSIONS,
  INPUT_ACCEPT_GIF,
  INPUT_ACCEPT_PHOTO_STATIC,
  ALLOWED_STATIC_PHOTO_FORMATS,
  FILE_FORMAT_GIF,
  INPUT_ACCEPT_ALL_MEDIA,
  MAX_VIDEO_SIZE_BYTES, MAX_VIDEO_SIZE_MB, MAX_PHOTO_SIZE_BYTES, MAX_GIF_SIZE_BYTES } from 'utils/file/constants'
import TallImageWarning from 'components/TallImageWarning'
import { isMobileBrowser, isIOS } from 'utils/browser'
import { LEGACY_URL } from 'config'
import { FormattedMessage, injectIntl } from 'react-intl'
import { WithIntl, FileType } from 'interfaces'

import { bytesToSize } from 'utils/format/byteToSize'
import { getFileTypeByMimeType } from 'utils/file'

const variables = require('styles/variables')
import styles from './FileUploader.pcss'

const PROGRESS_TRANSITION_TIME = variables['--transition-duration-simple']
const LOADED_MAX_VALUE = 100
const PROGRESSBAR_SIZE = 60
const BASE_TEN = 10

export enum FileUploaderView {
  Photo,
  Video,
  Bulk,
  Gif,
  All,
  Inactive
}

const labels: { [key: number]: string } = {
  [FileUploaderView.Photo]: 'image',
  [FileUploaderView.Video]: 'video',
  [FileUploaderView.Bulk]: 'spreadsheet file',
  [FileUploaderView.All]: 'file',
  [FileUploaderView.Inactive]: 'file',
  [FileUploaderView.Gif]: 'gif'
}

export interface FileUploaderProps extends WithIntl {
  view: FileUploaderView
  file?: File
  className?: string
  hideCloseButton?: boolean
  noGifAsPhoto?: boolean // excludes .gif extension from allowed photo file formats
  photoSizeLimit?: number // bytes
  gifSizeLimit?: number // bytes
  videoSizeLimit?: number // bytes
  rotatePhotoBy?: number
  imagePreviewClassName?: string
  videoPreviewClassName?: string
  clearExcelFileButton?: React.ReactNode
  onPhotoRotateClick?: () => void
  onClose?: () => void
  onFileUploaded: (file: File) => void
  onError: (error: string) => void
}

interface FileUploaderState {
  activeState: FileUploadState
  loaded: number
  imageSource?: string
  videoSource?: string
  bulkUploadFileName?: string
  fileType?: FileType
}

enum FileUploadState {
  Normal,
  Uploading,
  Completed
}

export class FileUploader extends Component<FileUploaderProps, FileUploaderState> {
  private loaded = 0
  private updateProgressBarId: number
  private fileReader: FileReader
  private imageElement: HTMLImageElement
  private videoElement: HTMLVideoElement | null
  private canvasElement: HTMLCanvasElement | null
  private ERROR_FORMAT_NOT_SUPPORTED: string

  get contentElement(): HTMLElement | null {
    const element = document.querySelector(`.${styles.content}`)
    return element ? element as HTMLElement : null
  }

  get allowedPhotoFormats() {
    if (this.props.view === FileUploaderView.Gif) {
      return [FILE_FORMAT_GIF]
    }
    return this.props.noGifAsPhoto ? ALLOWED_STATIC_PHOTO_FORMATS : ALLOWED_PHOTO_FORMATS
  }

  get photoSizeLimit() {
    return this.props.photoSizeLimit || MAX_PHOTO_SIZE_BYTES
  }

  get videoSizeLimit() {
    return this.props.videoSizeLimit || MAX_VIDEO_SIZE_BYTES
  }

  get gifSizeLimit() {
    return this.props.gifSizeLimit || MAX_GIF_SIZE_BYTES
  }

  constructor(props: FileUploaderProps) {
    super(props)

    this.state = { activeState: FileUploadState.Normal, loaded: 0 }

    this.onFileSelected = this.onFileSelected.bind(this)
    this.onFileDropped = this.onFileDropped.bind(this)
    this.loadFile = this.loadFile.bind(this)
    this.onDragEnter = this.onDragEnter.bind(this)
    this.onDragLeave = this.onDragLeave.bind(this)
    this.updateProgressBar = this.updateProgressBar.bind(this)
    this.validateVideo = this.validateVideo.bind(this)
    this.validatePhoto = this.validatePhoto.bind(this)
    this.error = this.error.bind(this)
    this.videoLoadedData = this.videoLoadedData.bind(this)
    this.renderVideoPreview = this.renderVideoPreview.bind(this)
    this.onVideoPreviewClick = this.onVideoPreviewClick.bind(this)
    this.getUploaderAcceptedTypes = this.getUploaderAcceptedTypes.bind(this)
    this.getUploaderHint = this.getUploaderHint.bind(this)
    this.renderUploadCompletedView = this.renderUploadCompletedView.bind(this)
    this.onFileLoaded = this.onFileLoaded.bind(this)

    this.ERROR_FORMAT_NOT_SUPPORTED = props.intl.formatMessage({ id: 'file.messages.invalid-type' })
  }

  componentDidMount() {
    window.addEventListener('dragover', this.cancelWindowDragEvents, false)
    window.addEventListener('drop', this.cancelWindowDragEvents, false)

    if (this.props.file) {
      this.loadFile(this.props.file)
    }
  }

  componentWillUnmount() {
    if (this.fileReader) {
      this.fileReader.abort()
    }

    if (this.updateProgressBarId) {
      cancelAnimationFrame(this.updateProgressBarId)
    }

    window.removeEventListener('dragover', this.cancelWindowDragEvents)
    window.removeEventListener('drop', this.cancelWindowDragEvents)
  }

  componentDidUpdate(prevProps: FileUploaderProps, prevState: FileUploaderState) {
    if (this.props.file && this.props.file !== prevProps.file) {
      this.loadFile(this.props.file)
      return
    }

    if ((this.props.view !== prevProps.view) || (prevProps.file && !this.props.file)) {
      this.setState(prevState => ({ ...prevState, activeState: FileUploadState.Normal }))

      if (this.fileReader) {
        this.fileReader.abort()
      }
    }

    if (
      isIOS()
      && this.state.videoSource
      && this.state.activeState === FileUploadState.Completed
      && prevState.activeState === FileUploadState.Uploading
    ) {
      const domElement = findDOMNode(this) as HTMLElement
      if (domElement) {
        this.videoElement = domElement.querySelector('video') as HTMLVideoElement
        this.canvasElement = domElement.querySelector('canvas') as HTMLCanvasElement
        this.renderVideoPreview()
      }
    }
  }

  renderVideoPreview() {
    if (this.videoElement) {
      this.videoElement.addEventListener('loadeddata', this.videoLoadedData)
    }
    if (this.canvasElement) {
      this.canvasElement.addEventListener('click', this.onVideoPreviewClick)
    }
  }

  onVideoPreviewClick() {
    if (this.videoElement && this.canvasElement) {
      this.videoElement.play()
      this.canvasElement.style.display = 'none'
    }
  }

  videoLoadedData() {
    if (this.videoElement && this.canvasElement) {
      const context = this.canvasElement.getContext('2d')

      if (!context) {
        return
      }

      // EXPL: Draw video preview and a play button on top of it.
      const canvasWidth = this.canvasElement.width
      context.drawImage(this.videoElement, 0, 0, canvasWidth, this.videoElement.clientHeight)
      this.drawPlayButton(context)
    }
  }

  drawPlayButton = (context: CanvasRenderingContext2D) => {
    if (!this.canvasElement) {
      return
    }

    const centerX = this.canvasElement.width / 2
    const centerY = this.canvasElement.height / 2
    const radius = 30
    const triangleSize = 24
    const centerXOffset = 2
    const btnX = centerX + centerXOffset
    const transparentWhite = 'rgba(255, 255, 255, 0.7)'
    const darkGray = '#333333'

    context.beginPath()
    context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false)
    context.fillStyle = transparentWhite
    context.fill()

    context.fillStyle = darkGray
    context.beginPath()
    // eslint-disable no-magic-numbers
    context.moveTo(btnX - triangleSize / 2, centerY - triangleSize / 2)
    context.lineTo(btnX + triangleSize / 2, centerY)
    context.lineTo(btnX - triangleSize / 2, centerY + triangleSize / 2)
    // eslint-enable no-magic-numbers
    context.fill()
  }

  cancelWindowDragEvents(e: Event) {
    e.preventDefault()
  }

  onFileLoaded(fileReader: FileReader, file: File) {
    let nextState: Partial<FileUploaderState>

    switch (this.state.fileType) {
      case FileType.Image:
      case FileType.Gif:
        nextState = { imageSource: fileReader.result as string }
        break
      case FileType.Video:
        nextState = { videoSource: URL.createObjectURL ? URL.createObjectURL(file) : fileReader.result as string }
        break
      case FileType.Spreadsheet:
        nextState = { bulkUploadFileName: file.name }
        break
      default:
        throw new Error('Invalid file type')
    }

    this.setState(prevState => ({ ...prevState, ...nextState }))
    this.props.onFileUploaded(file)
  }

  loadFile(file: File) {
    // EXPL: Some browsers don't set file type properly for excel - Assume it's excel if in Bulk view.
    const fileType = this.props.view === FileUploaderView.Bulk ? FileType.Spreadsheet : getFileTypeByMimeType(file.type)
    if (
      fileType === FileType.Image && !this.validatePhoto(file)
      || fileType === FileType.Gif && !this.validatePhoto(file)
      || fileType === FileType.Video && !this.validateVideo(file)
      || fileType === FileType.Spreadsheet && !this.validateBulk(file)
    ) {
      // Validation didn't pass
      return
    }

    this.fileReader = new FileReader()
    this.setState(prevState => ({ ...prevState, activeState: FileUploadState.Uploading, fileType }))

    this.fileReader.onprogress = (progress) => {
      const percentageMultiplier = 100
      const loaded = (progress.loaded / progress.total) * percentageMultiplier
      if (this.loaded !== Math.ceil(loaded)) {
        this.loaded = Math.ceil(loaded)
        this.updateProgressBarId = requestAnimationFrame(this.updateProgressBar)
      }
    }

    this.fileReader.onload = (event) => {
      this.loaded = LOADED_MAX_VALUE + 1
      this.updateProgressBarId = requestAnimationFrame(this.updateProgressBar)
      this.onFileLoaded(event.target as FileReader, file)
    }

    this.fileReader.onerror = () => this.error(this.props.intl.formatMessage({ id: 'composer.errors.upload-generic' }))

    this.fileReader.readAsDataURL(file)
  }

  updateProgressBar() {
    if (this.loaded <= LOADED_MAX_VALUE) {
      this.setState(prevState => ({ ...prevState, loaded: this.loaded }))
      return
    }
    // EXPL: make sure loader animation hits 100% before switching to Complete state
    this.setState(prevState => ({ ...prevState, loaded: 100 }), () => {
      setTimeout(() => {
        this.setState(prevState => ({ ...prevState, activeState: FileUploadState.Completed }))
      }, PROGRESS_TRANSITION_TIME)
    })
  }

  validateBulk = (file: File) => {
    const extension = file.name.substring(file.name.lastIndexOf('.'))
    const isValid = ALLOWED_BULK_EXTENSIONS.indexOf(extension) !== -1
    if (!isValid) {
      this.error(this.ERROR_FORMAT_NOT_SUPPORTED)
    }

    return isValid
  }

  validateVideo(file: File): boolean {
    const maxSize = this.videoSizeLimit
    const maxSizeFormatted = bytesToSize(maxSize)
    const maxSizePrettyString = `${parseInt(maxSizeFormatted, BASE_TEN)} ${maxSizeFormatted.split(' ')[1]}`
    let error
    if (file.size > maxSize) {
      error = this.props.intl.formatMessage({ id: 'file.messages.video-size-exceeded' }, { size: maxSizePrettyString })
    }

    if (ALLOWED_VIDEO_FORMATS.indexOf(file.type) === -1) {
      error = this.ERROR_FORMAT_NOT_SUPPORTED
    }

    if (error) {
      this.error(error)
      return false
    }
    return true
  }

  validatePhoto(file: File): boolean {
    const maxSize = file.type === FILE_FORMAT_GIF ? this.gifSizeLimit : this.photoSizeLimit
    const maxSizeFormatted = bytesToSize(maxSize)
    const maxSizePrettyString = `${parseInt(maxSizeFormatted, BASE_TEN)} ${maxSizeFormatted.split(' ')[1]} `
    if (file.size > maxSize) {
      const error = this.props.intl.formatMessage({ id: 'file.messages.photo-size-exceeded' }, { size: maxSizePrettyString })
      this.error(error)
      return false
    }

    if (this.allowedPhotoFormats.indexOf(file.type) === -1) {
      this.error(this.ERROR_FORMAT_NOT_SUPPORTED)
      return false
    }
    return true
  }

  error(message: string) {
    this.setState(prevState => ({ ...prevState, activeState: FileUploadState.Normal }))
    this.props.onError(message)
  }

  onFileSelected(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files ? e.target.files[0] : undefined
    e.target.value = ''
    if (!file) {
      return
    }
    this.loadFile(file)
  }

  onFileDropped(e: any) {
    e.preventDefault()
    if (this.contentElement) {
      this.contentElement.classList.remove(styles['drag-hover'])
    }
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      this.loadFile(e.dataTransfer.files[0])
    }
  }

  onDragEnter() {
    if (this.contentElement && !this.contentElement.classList.contains(styles['drag-hover'])) {
      this.contentElement.classList.add(styles['drag-hover'])
    }
  }

  onDragLeave() {
    if (this.contentElement) {
      this.contentElement.classList.remove(styles['drag-hover'])
    }
  }

  renderSampleExcelFileLink() {
    const bulkUploadSampleUrl = `${LEGACY_URL}/resources/sample_bulk_posts_import.xlsx`
    return createElement('a', {
      key: 'bulk-sample',
      className: styles['bulk-download-link'],
      href: bulkUploadSampleUrl
    }, createElement(FormattedMessage, { id: 'composer.actions.download-excel' }))
  }

  getUploaderHint() {
    switch (this.props.view) {
      case FileUploaderView.Video:
        return createElement('p', { key: 'video-info', className: styles.info },
          createElement(FormattedMessage, {
            id: 'composer.labels.video-note',
            defaultMessage: '(We only support video files under {size} MB at this time.)',
            values: {
              size: MAX_VIDEO_SIZE_MB
            }
          })
        )

      case FileUploaderView.Bulk:
        return createElement('p', { key: 'bulk-info' }, this.renderSampleExcelFileLink())

      default:
        return null
    }
  }

  getUploaderAcceptedTypes() {
    switch (this.props.view) {
      case FileUploaderView.Photo:
        return this.props.noGifAsPhoto ? INPUT_ACCEPT_PHOTO_STATIC : INPUT_ACCEPT_PHOTO
      case FileUploaderView.Gif:
        return INPUT_ACCEPT_GIF
      case FileUploaderView.Video:
        return INPUT_ACCEPT_VIDEO
      case FileUploaderView.Bulk:
        return INPUT_ACCEPT_BULK
      case FileUploaderView.All:
        return INPUT_ACCEPT_ALL_MEDIA
      default:
        return INPUT_ACCEPT_ALL
    }
  }

  renderUploadCompletedView() {
    switch (this.state.fileType) {
      case FileType.Image:
      case FileType.Gif:
        const style = this.props.rotatePhotoBy !== undefined && this.state.fileType !== FileType.Gif
          ? { transform: `rotate(${this.props.rotatePhotoBy}deg)` }
          : undefined
        return createElement('img', {
          key: 'image',
          style,
          src: this.state.imageSource,
          className: `${styles.image} ${this.props.imagePreviewClassName || ''}`,
          ref: (ref: HTMLImageElement) => { this.imageElement = ref }
        })

      case FileType.Video:
        const textNoPreview = 'No preview available.'
        return createElement('div', { key: 'video-wrapper', className: styles['video-wrapper'] }, [
          createElement('video', {
            key: 'video',
            src: this.state.videoSource,
            controls: 'controls',
            className: `${styles.video} ${this.props.videoPreviewClassName || ''}`
          }, textNoPreview),
          isIOS() ? createElement('canvas', { key: 'canvas', className: styles.canvas }) : null
        ])

      case FileType.Spreadsheet:
        const fileUploadedText = 'Uploaded excel file'
        return createElement('div', { key: 'bulk-upload-complete' }, [
          createElement('p', { key: 'bulk-upload-p1', className: styles['bulk-complete'] }, fileUploadedText),
          createElement('p', { key: 'bulk-file-name', className: `${styles['bulk-file-name']} text-ellipsis` }, [
            this.state.bulkUploadFileName,
            this.props.clearExcelFileButton
          ]),
          this.renderSampleExcelFileLink()
        ])

      default:
        return null
    }
  }

  renderState(state: FileUploadState) {
    switch (state) {
      case FileUploadState.Normal:
        const accept = this.getUploaderAcceptedTypes()

        return [
          createElement(UploaderTransitionHint, { view: this.props.view, key: 'hint' }),
          createElement('p', { key: 'message-line-2' }, 'or'),
          createElement('p', { key: 'upload-btn-wrapper', className: styles['btn-upload'] },
            createElement(FormattedMessage, {
              id: `composer.actions.choose-${this.props.view === FileUploaderView.Bulk ? 'excel' : 'file'}`
            }),
            createElement('input', {
              key: 'input',
              type: 'file',
              accept,
              onChange: this.onFileSelected
            })
          ),
          this.getUploaderHint()
        ]

      case FileUploadState.Uploading:
        return [
          createElement('div', { key: 'loading-anim', className: styles['loading-container'] }, [
            createElement(CircularProgress, {
              key: 'progress',
              color: 'primary',
              value: this.state.loaded,
              variant: 'determinate',
              size: PROGRESSBAR_SIZE
            }),
            createElement('span', { key: 'percent' }, `${this.state.loaded}%`)
          ]),
          createElement('p', { key: 'loading-msg' },
            createElement(FormattedMessage, {
              id: 'composer.labels.uploading',
              defaultMessage: `Uploading your ${labels[this.props.view]}...`,
              values: {
                file: labels[this.props.view]
              }
            }))
        ]

      case FileUploadState.Completed:
        return this.renderUploadCompletedView()

      default:
        throw new Error('Invalid FileUploader State')
    }
  }

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    const imageUploadedState = this.state.imageSource
      && this.state.fileType === FileType.Image
      && this.state.activeState === FileUploadState.Completed

    const tallImageWarning = imageUploadedState
      && this.imageElement
      && this.imageElement.height > parseInt(variables['--card-image-max-height'], BASE_TEN)
      ? createElement(TallImageWarning, { key: 'tall-image', withWrapper: true })
      : ''

    const classNameImage = imageUploadedState ? `${styles['preview-image']}` : ''
    const mobileClass = this.state.activeState === FileUploadState.Normal && isMobileBrowser() ? styles['mobile-hide'] : ''
    const className = `${styles.wrapper} ${this.props.className || ''} ${mobileClass}`

    return createElement('section', {
      key: 'uploader',
      className,
      onDrop: this.onFileDropped,
      onDragEnter: this.onDragEnter,
      onDragLeave: this.onDragLeave
    }, [
      this.props.onClose ? createElement(IconButton, {
        key: 'btn-close',
        className: `${styles['btn-close']} ${this.props.hideCloseButton ? styles['btn-close-hidden'] : ''}`,
        size: 'small',
        onClick: this.onClose
      }, createElement(CloseIcon)) : null,
      imageUploadedState && this.props.rotatePhotoBy !== undefined && createElement(IconButton, {
        key: 'btn-rotate',
        className: styles['btn-rotate'],
        size: 'small',
        onClick: this.props.onPhotoRotateClick
      }, createElement(RotateIcon)),
      createElement('div', { key: 'content', className: `${styles.content} ${classNameImage}` }, [
        this.renderState(this.state.activeState),
        tallImageWarning
      ])
    ])
  }
}

export default injectIntl(FileUploader)

const HINT_TRANSITION_TIMEOUT = 2000 // ms
const HINT_LOOP_MAP: any = {
  [FileUploaderView.Photo]: {
    label: labels[FileUploaderView.Photo],
    next: FileUploaderView.Video
  },
  [FileUploaderView.Video]: {
    label: labels[FileUploaderView.Video],
    next: FileUploaderView.Gif
  },
  [FileUploaderView.Gif]: {
    label: labels[FileUploaderView.Gif],
    next: FileUploaderView.Photo
  }
}

export const UploaderTransitionHint = (props: { view: FileUploaderView }) => {
  const [current, setCurrent] = useState(HINT_LOOP_MAP[props.view] || HINT_LOOP_MAP[FileUploaderView.Photo])

  useEffect(() => {
    let timeout: any = ''
    if (props.view === FileUploaderView.All) {
      timeout = setTimeout(() => {
        setCurrent(HINT_LOOP_MAP[current.next])
      }, HINT_TRANSITION_TIMEOUT)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [props.view, current])

  return createElement('p', { key: 'message-line-1' }, [
    'Drop',
    createElement('span', {
      key: `${current.label}`,
      className: `${styles['hint-transition']} ${props.view === FileUploaderView.All ? styles['with-anim'] : ''}`
    }, props.view !== FileUploaderView.All ? labels[props.view] : current.label),
    'here'
  ])
}
