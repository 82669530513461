import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'
import toSnakeCase from 'utils/toSnakeCase'
import { CreatePostRequestData } from 'shared/types'

export const ENDPOINT_CREATE_POST = `${LEGACY_URL}/v3/composer_multi_posts`

export const V1 = Object.freeze({
  createPost: function () {
    return function (posts: CreatePostRequestData[], bucketId?: string) {
      const builder = new RequestBuilder(ENDPOINT_CREATE_POST).asPost().asFormUrlEncoded().expectJSON()
      const requestData = JSON.stringify(posts.map(post => toSnakeCase({ ...post, bucketId })))

      return authorizedPipe<any>(
        builder
          .body({ data: requestData, bucket_id: bucketId })
          .build()
      )
    }
  }()
})
