import * as React from 'react'
import { Article, FEED_TYPE_KEYWORD, WithIntl } from 'interfaces'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { composerContentItemSelector } from 'services/compose/selectors'
import { message } from 'services/snackbar'
import CopyText from './CopyText'

export function Credits(props: WithIntl) {
  const dispatch = useDispatch()
  const content = useSelector(composerContentItemSelector) as Article

  if (!content) {
    return null
  }

  const credits = content && content.feed.type !== FEED_TYPE_KEYWORD
    ? `📸 ${content.feed.name} \n${content.shortLink || content.socialLink || (content as Article).sourceLink} `
    : null

  const caption = content?.status

  const onCopy = () => {
    dispatch(message(props.intl.formatMessage({ id: 'notifications.status-copied' })))
  }

  return (
    <div>
      {caption && (
        <CopyText
          title={<FormattedMessage id="composer.labels.text-copy.caption" />}
          messageText={caption}
          onTextCopied={onCopy}
        />
      )}
      {credits && (
        <CopyText
          title={<FormattedMessage id="composer.labels.text-copy.credit" />}
          messageText={credits}
          onTextCopied={onCopy}
        />
      )}
    </div>
  )
}

export default injectIntl(Credits)
