import { FileType } from 'interfaces'
import {
  ALLOWED_PHOTO_FORMATS,
  ALLOWED_VIDEO_FORMATS,
  MAX_PHOTO_SIZE_BYTES,
  MAX_VIDEO_SIZE_BYTES,
  ALLOWED_STATIC_PHOTO_FORMATS,
  MAX_GIF_SIZE_BYTES,
  FILE_FORMAT_GIF
} from './constants'

export function getFileTypeByMimeType(type?: string): FileType | undefined {
  if (!type) {
    return undefined
  }
  const ext = type.toLowerCase()
  if (ALLOWED_STATIC_PHOTO_FORMATS.includes(ext)) {
    return FileType.Image
  }

  if (ALLOWED_VIDEO_FORMATS.includes(ext)) {
    return FileType.Video
  }

  if (ext === FILE_FORMAT_GIF) {
    return FileType.Gif
  }

  return undefined
}

export function getFileMaxSizeByType(fileType: FileType): number | undefined {
  switch (fileType) {
    case FileType.Image:
      return MAX_PHOTO_SIZE_BYTES
    case FileType.Video:
      return MAX_VIDEO_SIZE_BYTES
    case FileType.Gif:
      return MAX_GIF_SIZE_BYTES
    default:
      return undefined
  }
}

export function getAllowedFormatsByFileType(fileType: FileType): string[] {
  if (fileType === FileType.Image) {
    return ALLOWED_PHOTO_FORMATS
  }

  if (fileType === FileType.Video) {
    return ALLOWED_VIDEO_FORMATS
  }

  if (fileType === FileType.Gif) {
    return [FILE_FORMAT_GIF]
  }

  return []
}

export function getMediaDimensions(type?: 'image' | 'video', selector?: string, swapWidthAndHeight?: boolean) {
  if (!type) {
    throw new Error('Cannot get media dimensions: Invalid media type!')
  }

  if (!selector) {
    throw new Error('Cannot get media dimensions: selector not provided!')
  }

  switch (type) {
    case 'image':
      const imgElement = document.querySelector(selector)
      if (!imgElement) {
        throw new Error('Cannot find image element!')
      }
      const imgWidth = (imgElement as HTMLImageElement).naturalWidth
      const imgHeight = (imgElement as HTMLImageElement).naturalHeight
      if (swapWidthAndHeight) {
        return { width: imgHeight, height: imgWidth }
      }

      return { width: imgWidth, height: imgHeight }

    case 'video':
      const videoElement = document.querySelector(selector)
      if (!videoElement) {
        throw new Error('Cannot find video element!')
      }
      const videoWidth = (videoElement as HTMLVideoElement).videoWidth
      const videoHeight = (videoElement as HTMLVideoElement).videoHeight
      if (swapWidthAndHeight) {
        return { width: videoHeight, height: videoWidth }
      }

      return { width: videoWidth, height: videoHeight }

    default:
      throw new Error('Cannot get media dimensions: Invalid media type!')
  }
}

export function mapFileTypeToSizeLimit(fileType: string, limits: { [key: string]: number }) {
  if (ALLOWED_STATIC_PHOTO_FORMATS.includes(fileType)) {
    return limits.photos
  }

  if (ALLOWED_VIDEO_FORMATS.includes(fileType)) {
    return limits.videos
  }

  if (fileType === FILE_FORMAT_GIF) {
    return limits.gifs
  }
  return undefined
}

export async function getVideoDuration(src: File | string) {
  const video = document.createElement('video')
  video.preload = 'metadata'

  const loadVideo = () => new Promise((resolve, reject) => {
    video.onloadedmetadata = (data) => {
      resolve((data.currentTarget as HTMLVideoElement).duration)
    }
    video.onerror = reject
    if (typeof src === 'string') {
      video.src = src
    } else {
      video.src = URL.createObjectURL(src)
    }
  })

  await loadVideo()
  return video.duration
}

export function getImageDimensionsFromUrl(url: string): Promise<{ width: number, height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight
      })
    }
    img.onerror = reject
    img.src = url
  })
}
