import * as React from 'react'
import MultiUploadManager from './MultiUploadManager'
import { connect } from 'react-redux'
import StoreState, { StoreThunkDispatch } from 'store/state'
import {
  myFileFoldersArraySelector,
  uploadDiaogOpenSelector,
  myUploadsStorageSelector,
  uploadDialogFilesSelector,
  selectedFileSelector,
  editFolderSelector
} from 'services/uploads/selectors'
import {
  createFolder,
  uploadFile,
  getUploads,
  setUploadsDialogOpen,
  setUploaderFiles,
  setSelectedFile,
  copyFileToFolder,
  getStorageInfo
} from 'services/uploads/actions'
import { message } from 'services/snackbar'
import { FileFolder, WithIntl, MyFile, PPProduct } from 'interfaces'
import { Subject } from 'rxjs/Subject'
import { injectIntl } from 'react-intl'
import { Subscription } from 'rxjs/Subscription'
import { Observable } from 'rxjs/Observable'
import { userProductSelector } from 'services/users/selectors'
import { FileUploadInfo, StorageInfo } from 'services/uploads/state'
import { checkProductLimit, getProductUpgradeByLimitKey } from 'services/product'
import { LIMIT_MY_LIBRARY_STORAGE, MAX_PRODUCT_ORDER } from 'shared/constants'
import { NOTIFICATION_DURATION_LONG } from 'components/ConnectedSnackbar'
import { Subscriber } from 'rxjs/Subscriber'
import FileUploadSnackbar from 'components/FileUploadSnackbar'
import Backdrop from '@mui/material/Backdrop'
import { catchError } from 'rxjs/operators/catchError'
import 'rxjs/add/operator/distinctUntilChanged'
import styles from './MultiUploadManager.pcss'
import { tap } from 'rxjs/operators/tap'
import { DEFAULT_STUDIO_EXPORT_FILE_NAME } from 'utils/file'
import { HTTP_STATUS_PAYMENT_REQUIRED } from 'services/net'
import { EditFileFolderDialog } from 'components/EditFileFolderDialog'
import { SnackType } from 'services/snackbar/interfaces/PendingTreat'
const MAX_CONCURRENT_REQUESTS = 5
const PERCENT_MULTIPLIER = 100

interface ConnectedMultiUploadManagerContainerProps {
  folders: FileFolder[]
  files: { [name: string]: File }
  dialogOpen: boolean
  fileSizeLimits: { [key: string]: number }
  storage: StorageInfo
  product?: PPProduct
  copyMyFile?: MyFile
  editFolder?: FileFolder
  fetchUploads: () => Promise<any>
  createFolder: (name: string, color: string, isPrivate: boolean, featuredImage?: File, coverImages?: File[]) => Promise<any>
  uploadFile: (
    file: File,
    folder: Partial<FileFolder>,
    width: number,
    height: number,
    rotate?: number,
    progressSubscriber?: Subscriber<any>,
    uploadId?: string,
    compress?: boolean
  ) => Promise<any>
  showNotification: (text: string, type?: SnackType, timeout?: number) => void
  onCloseDialog: () => void
  setUploaderFiles: (files: { [name: string]: File }) => void
  checkLimit: (limitKey: string, limitValue: number) => boolean
  clearCopyFile: () => void
  copyFile: (file: MyFile, folder: { id: string, name: string }) => Promise<any>
  getProductUpgrade: (value: number) => PPProduct | undefined

}

type MultiUploadManagerContainerProps = ConnectedMultiUploadManagerContainerProps & WithIntl
interface MultiUploadsState {
  uploads: {
    [key: string]: {
      file: File
      folder: Partial<FileFolder>
      width: number
      height: number
      progress: number
      error: boolean
      rotate?: number
    }
  }
}

class MultiUploadManagerContainer extends React.Component<MultiUploadManagerContainerProps, MultiUploadsState> {
  private fileUpload$: Subject<any>
  private copyFileToNewFolder$: Subscription
  private unmount$: Subject<boolean>
  constructor(props: any) {
    super(props)

    this.state = { uploads: {} }
  }

  componentDidMount() {
    this.props.fetchUploads()
    this.fileUpload$ = new Subject()
    this.fileUpload$.flatMap(
      ({ id, file, folder, width, height, rotateBy }) => {
        const progress$ = new Subscriber(this.onProgress(id))
        const shouldCompress = file.name === DEFAULT_STUDIO_EXPORT_FILE_NAME
        return Observable.fromPromise(this.props.uploadFile(file, folder, width, height, rotateBy, progress$, id, shouldCompress))
          .pipe(catchError((error) => {
            return Observable.of({ error })
          }))
      },
      MAX_CONCURRENT_REQUESTS
    )
      .subscribe(res => {
        if (res.error) {
          if (res.error.source?.status === HTTP_STATUS_PAYMENT_REQUIRED) {
            this.props.showNotification(this.props.intl.formatMessage({ id: 'uploads.notifications.error-storage-full' }), 'error')
          } else {
            this.props.showNotification(this.props.intl.formatMessage({ id: 'uploads.error.upload-failed' }), 'error')
          }
          this.onFileUploadError(res.error.uploadId)
        }
      })

    this.unmount$ = new Subject()
  }

  componentWillUnmount() {
    this.fileUpload$.unsubscribe()
    this.unmount$.next(true)

    if (this.copyFileToNewFolder$) {
      this.copyFileToNewFolder$.unsubscribe()
    }
  }

  onProgress = (fileName: string) => (e: ProgressEvent) => {
    const value = (e.loaded / e.total) * PERCENT_MULTIPLIER

    this.setState(prevState => ({
      ...prevState,
      uploads: {
        ...prevState.uploads,
        [fileName]: {
          ...prevState.uploads[fileName],
          progress: value
        }
      }
    }))
  }

  uploadFiles = (files: Array<FileUploadInfo>, folders: FileFolder[]) => {
    const filesSize = files.reduce((size, fileData) => {
      size += fileData.file.size
      return size
    }, 0)

    const usedBytesNext = this.props.storage.used + filesSize
    const canUpload = this.props.checkLimit(LIMIT_MY_LIBRARY_STORAGE, usedBytesNext)
    const hasUpgrade = this.props.getProductUpgrade(usedBytesNext) !== undefined

    if (!canUpload) {
      if (!hasUpgrade) {
        this.props.showNotification(
          this.props.intl.formatMessage({ id: 'uploads.notifications.max-limit-reached' }),
          'warning',
          NOTIFICATION_DURATION_LONG
        )
      }
      return
    }

    this.props.showNotification(this.props.intl.formatMessage({ id: 'uploads.notifications.multi-upload-started' }), 'info')

    folders.forEach((folder: FileFolder) => {
      files.forEach(upload => {
        const id = `${upload.file.name}-${Date.now()}`
        this.fileUpload$.next({
          id,
          file: upload.file,
          folder: { id: folder.id, name: folder.name },
          width: upload.width,
          height: upload.height,
          rotateBy: upload.rotateBy
        })

        this.setState(prevState => ({
          ...prevState,
          uploads: {
            ...prevState.uploads,
            [id]: {
              file: upload.file,
              folder: { id: folder.id, name: folder.name },
              width: upload.width,
              height: upload.height,
              progress: 0,
              error: false,
              rotateBy: upload.rotateBy
            }
          }
        }))
      })
    })
  }

  onFileUploadComplete = (id: string) => {
    const uploads = { ...this.state.uploads }
    delete uploads[id]
    this.setState(prevState => ({ ...prevState, uploads }))
  }

  onFileUploadError = (id: string) => {
    this.setState(prevState => ({
      ...prevState,
      uploads: {
        ...prevState.uploads,
        [id]: {
          ...prevState.uploads[id],
          error: true
        }
      }
    }))
  }

  checkSpaceForFile = (fileSize: number) => {
    const usedBytesNext = this.props.storage.used + fileSize
    const noAvailableUpgrades = this.props.product?.order === MAX_PRODUCT_ORDER
    const hasSpace = this.props.checkLimit(LIMIT_MY_LIBRARY_STORAGE, usedBytesNext)

    if (!hasSpace && noAvailableUpgrades) {
      this.props.showNotification(
        this.props.intl.formatMessage({ id: 'uploads.notifications.max-limit-reached' }),
        'warning',
        NOTIFICATION_DURATION_LONG
      )
    }
    return hasSpace
  }

  copyFile = (file: MyFile, folder: { id: string, name: string }) => {
    const canUpload = this.checkSpaceForFile(file.size)
    if (!canUpload) {
      return
    }

    this.props.copyFile(file, folder)
      .then(() => {
        this.props.showNotification(this.props.intl.formatMessage({ id: 'uploads.notifications.file-copied' }), 'success')
      })
      .catch(() => {
        this.props.showNotification(this.props.intl.formatMessage({ id: 'uploads.notifications.file-copy-failed' }), 'error')
      })

    this.props.showNotification(this.props.intl.formatMessage({ id: 'uploads.notifications.multi-upload-started' }))
  }

  copyFileToNewFolder = (file: MyFile, folder: { name: string, color: string, isPrivate: boolean }) => {
    this.setState(prevState => ({ ...prevState, uploadingFile: true, creatingFolder: true }))
    this.props.showNotification(this.props.intl.formatMessage({ id: 'uploads.notifications.multi-upload-started' }))

    this.copyFileToNewFolder$ = Observable.fromPromise(this.props.createFolder(folder.name, folder.color, folder.isPrivate))
      .takeUntil(this.unmount$)
      .mergeMap((response) => {
        this.setState(prevState => ({ ...prevState, uploadingFile: false, creatingFolder: false }))
        return this.props.copyFile(file, { id: response.id, name: folder.name })
      })
      .subscribe(() => {
        this.props.showNotification(this.props.intl.formatMessage({ id: 'uploads.notifications.file-copied' }), 'success')
      }, () => {
        this.props.showNotification(this.props.intl.formatMessage({ id: 'uploads.notifications.file-copy-failed' }), 'error')
        this.setState(prevState => ({ ...prevState, uploadingFile: false, creatingFolder: false }))
        this.props.showNotification(this.props.intl.formatMessage({ id: 'upload.error.uploading-error' }), 'error')
      })
  }

  render() {
    const isUploading = Object.keys(this.state.uploads).length !== 0
    const uploads = this.state.uploads

    return (
      <React.Fragment>
        {this.props.editFolder ? (
          <EditFileFolderDialog
            folder={this.props.editFolder}
            fileUploads={this.props.files}
            setFileUploads={this.props.setUploaderFiles}
            onUploadFiles={this.uploadFiles}
          />
        ) : (
          <MultiUploadManager
            folders={this.props.folders}
            files={this.props.files}
            dialogOpen={this.props.dialogOpen}
            fileSizeLimits={this.props.fileSizeLimits}
            copyFile={this.props.copyMyFile}
            uploadFiles={this.uploadFiles}
            setFiles={this.props.setUploaderFiles}
            createFolder={this.props.createFolder}
            onCloseDialog={this.props.onCloseDialog}
            showNotification={this.props.showNotification}
            clearCopyFile={this.props.clearCopyFile}
            onCopyFile={this.copyFile}
            onCopyFileToNewFolder={this.copyFileToNewFolder}
          />
        )}
        <Backdrop open={isUploading} invisible classes={{ root: styles.backdrop }}>
          <div className={styles['backdrop-panel']}>
            {
              Object.keys(uploads).map(id => (
                <FileUploadSnackbar
                  id={id}
                  key={id}
                  file={uploads[id].file}
                  progress={uploads[id].progress}
                  error={uploads[id].error}
                  onClose={this.onFileUploadComplete}
                />
              ))
            }
          </div>
        </Backdrop>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state: StoreState) {
  return {
    folders: myFileFoldersArraySelector(state),
    files: uploadDialogFilesSelector(state),
    dialogOpen: uploadDiaogOpenSelector(state),
    fileSizeLimits: myUploadsStorageSelector(state).fileSizeLimits,
    storage: myUploadsStorageSelector(state),
    product: userProductSelector(state),
    copyMyFile: selectedFileSelector(state),
    editFolder: editFolderSelector(state)
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    fetchUploads: () => dispatch(getUploads()).unwrap(),
    createFolder: (name: string, color: string, isPrivate: boolean, featuredImage?: File, coverImages?: File[]) => {
      return dispatch(createFolder({ name, color, isPrivate, featuredImage, coverImages })).unwrap()
    },
    uploadFile: (
      file: File,
      folder: Partial<FileFolder>,
      width: number,
      height: number,
      rotateByDegrees?: number,
      progressSubscriber?: Subscriber<any>,
      uploadId?: string,
      compress?: boolean
    ) => {
      return dispatch(uploadFile({ file, folder, width, height, rotateByDegrees, progressSubscriber, uploadId, compress }))
        .unwrap()
    },
    showNotification: (text: string, type?: SnackType, timeout?: number) => dispatch(message(text, type, timeout)),
    onCloseDialog: () => {
      dispatch(setUploadsDialogOpen(false))
      dispatch(setSelectedFile(undefined))
    },
    copyFile: (file: MyFile, folder: { id: string, name: string }) => dispatch(copyFileToFolder({ file, folder }))
      .unwrap()
      .then(() => dispatch(getStorageInfo())),
    setUploaderFiles: (files: { [name: string]: File }) => dispatch(setUploaderFiles(files)),
    checkLimit: (limitKey: string, limitValue: number) => dispatch(checkProductLimit(limitKey, limitValue)),
    clearCopyFile: () => dispatch(setSelectedFile(undefined)),
    getProductUpgrade: (value: number) => dispatch(getProductUpgradeByLimitKey(LIMIT_MY_LIBRARY_STORAGE, value))
  }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MultiUploadManagerContainer))
