import { Component, createElement } from 'react'
import { connect } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { checkFeatureAvailability } from 'services/product'
import { FEATURE_POST_CANVA } from 'shared/constants'
import { PostDestinationType, BRAND_INSTAGRAM, BRAND_TWITTER } from 'interfaces'

declare const CanvaAPI: any

interface CanvaButtonOwnProps {
  className?: string
  onGeneratedImage(url: string): void
  onClick?(): void
}

interface CanvaButtonConnectedProps {
  isCanvaAvailable: () => boolean
}

export type CanvaButtonProps = CanvaButtonOwnProps & CanvaButtonConnectedProps

export class CanvaButton extends Component<CanvaButtonProps, any> {
  onDesignPublish = (options: { exportUrl: string }) => {
    console.log('[canva-result]', options)
    this.props.onGeneratedImage(options.exportUrl)
  }

  openCanva = () => {
    const canvaAvailable = this.props.isCanvaAvailable()
    if (this.props.onClick) {
      this.props.onClick()
    }

    if (!canvaAvailable) {
      return
    }

    if (!CanvaAPI) {
      console.log('Canva error: not initialized')
      return
    }

    CanvaAPI.createDesign({
      design: {
        type: 'InstagramPost'
      },
      onDesignPublish: this.onDesignPublish
    })
  }

  render() {
    return createElement('button', {
      key: 'canva',
      type: 'button',
      className: this.props.className,
      onClick: this.openCanva
    }, 'Canva')
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    isCanvaAvailable: () => dispatch(checkFeatureAvailability(FEATURE_POST_CANVA))
  }
}

export default connect(null, mapDispatchToProps)(CanvaButton)
