import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {
  activeDraftSelector,
  activePostIdSelector,
  composerBulkFileSelector,
  composerIsEmptySelector,
  composerSelectedProfilesIdsSelector,
  multiPostsCountSelector
} from 'services/compose/selectors'
import styles from './Header.pcss'
import Logo from 'components/PPLogo'
import ConfirmDialog from 'components/ConfirmDialog'
import Button from '@mui/material/Button'
import { resetComposer } from 'services/compose'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import { calloutsSelector } from 'services/ui/selectors'
import IconButton from '@mui/material/IconButton'
import Icon from '@mdi/react'
import { mdiHelp } from '@mdi/js'
import { toggleCallout } from 'services/ui/actions'

interface HeaderProps {
  activeView: 'default' | 'bulk'
  onActiveViewChange: (value: 'default' | 'bulk') => void
  onClose: (reset?: boolean) => void
}

export function Header(props: HeaderProps) {
  const dispatch = useDispatch()
  const selectedProfilesCount = useSelector(composerSelectedProfilesIdsSelector).length
  const draft = useSelector(activeDraftSelector)
  const isEditingPost = Boolean(useSelector(activePostIdSelector))
  const isComposerEmpty = useSelector(composerIsEmptySelector)
  const multiPostsCount = useSelector(multiPostsCountSelector)
  const bulkFile = useSelector(composerBulkFileSelector)
  const calloutIsHidden = useSelector(calloutsSelector).find(c => c.slug === 'composer')?.hidden

  const isDefaultView = props.activeView === 'default'

  const onClear = () => {
    dispatch(resetComposer())
  }

  const showInfobox = () => {
    dispatch(toggleCallout('composer'))
  }

  const close = () => {
    // reset composer on close if editing post or draft
    const shouldReset = Boolean(draft) || isEditingPost
    props.onClose(shouldReset)
  }

  const viewOptions: PPSelectOptions = {
    default: { label: <FormattedMessage id="composer.header-labels.default-view" /> },
    bulk: { label: <FormattedMessage id="composer.header-labels.bulk-view" /> }
  }

  const shouldConfirmSwitchToDefault = !isDefaultView && (multiPostsCount > 0 || Boolean(bulkFile))
  const hideClearBtn = isComposerEmpty && multiPostsCount === 0 && !bulkFile

  return (
    <div className={styles.header} data-testid="composer-header">
      <div>
        <Logo className={styles.logo} />
        <ConfirmDialog
          message={<FormattedMessage id="composer.notifications.clear-bulk-content" />}
          labelOK={<FormattedMessage id="actions.confirm" />}
        >
          {(confirm) => (
            <h3 className={styles.title}>
              <FormattedMessage
                id={isEditingPost ? 'composer.labels.editing-posts-count' : 'composer.labels.creating-posts-count'}
                values={{
                  posts: multiPostsCount === 0 ? selectedProfilesCount : multiPostsCount,
                  profiles: selectedProfilesCount,
                  select: (_text) => (
                    <PPSelect
                      options={viewOptions}
                      selectedValue={props.activeView}
                      withCaret
                      buttonClassName={styles['header-btn']}
                      className={styles.select}
                      noLabelCaps
                      onSelectionChange={shouldConfirmSwitchToDefault ? confirm(props.onActiveViewChange) : props.onActiveViewChange}
                    />
                  )
                }}
              />
            </h3>
          )}
        </ConfirmDialog>
      </div>
      <div className={styles.right}>
        {!isEditingPost && !hideClearBtn && (
          <ConfirmDialog
            message={<FormattedMessage id="composer.labels.confirm-clear" />}
            labelOK={<FormattedMessage id="actions.clear" />}
          >
            {(confirm) => (
              <Button
                size="small"
                className={styles.btn}
                onClick={confirm(onClear)}
              >
                <FormattedMessage id="actions.clear" />
              </Button>
            )}
          </ConfirmDialog>
        )}
        <Button size="small" className={styles.btn} onClick={close}>
          <FormattedMessage id="actions.close" />
        </Button>
        {calloutIsHidden && (
          <IconButton size="small" color="primary" className={`${styles.btn} ${styles['btn-help']}`} onClick={showInfobox}>
            <Icon size="20px" path={mdiHelp} />
          </IconButton>
        )}
      </div>
    </div>
  )
}

export default Header
