import { RequestBuilder, authorizedPipe } from 'services/net'
import { CONTENT_SERVICE_URL } from 'config'
import { ContentType, FilterType, RangeFilter } from 'interfaces'

const ENDPOINT_FAVORITES_QUOTES = `${CONTENT_SERVICE_URL}/quotes/favorites`
const ENDPOINT_REMOVE_FAVORITE_QUOTE = `${CONTENT_SERVICE_URL}/quotes/favorites/:id`
const ENDPOINT_REMOVE_FAVORITE_STATUS_IDEA = `${CONTENT_SERVICE_URL}/status_ideas/favorites/:id`
export const ENDPOINT_FAVORITES = `${CONTENT_SERVICE_URL}/favorites`
export const ENDPOINT_FAVORITE_LIVE = `${CONTENT_SERVICE_URL}/favorite_live_content`
export const ENDPOINT_FAVORITE_STATUS_IDEAS = `${CONTENT_SERVICE_URL}/status_ideas/favorites`
const PAGE_SIZE = 20

export const V1 = Object.freeze({
  getFavorites: function () {
    const builder = new RequestBuilder(ENDPOINT_FAVORITES).asGet().expectJSON().withoutCredentials()

    return function (page: number, type: FilterType, range: RangeFilter) {
      return authorizedPipe<any>(
        builder
          .param('page', page.toString())
          .param('page_size', PAGE_SIZE.toString())
          .param('content_type', type)
          .param('content_range', range)
          .build()
      )
    }
  }(),
  addFavorite: function () {
    const builder = new RequestBuilder(ENDPOINT_FAVORITES).asPost().expectJSON().withoutCredentials().asFormUrlEncoded()

    return function (id: string, type: ContentType) {
      return authorizedPipe<any>(
        builder
          .body({
            content_item_id: id,
            content_item_type: type
          })
          .build()
      )
    }
  }(),
  addFavoriteLive: function () {
    const builder = new RequestBuilder(ENDPOINT_FAVORITE_LIVE).asPost().expectJSON().withoutCredentials().asJSON()

    return function (itemData: any) {
      return authorizedPipe<any>(
        builder
          .body(itemData)
          .build()
      )
    }
  }(),
  removeFavoriteLive: function () {
    const builder = new RequestBuilder(ENDPOINT_FAVORITE_LIVE).asDelete().expectJSON().withoutCredentials().asJSON()
    return function (searchId: string, type: ContentType, sourceId: string) {
      return authorizedPipe<any>(
        builder
          .body({
            search_id: searchId,
            content_item_type: type,
            source_id: sourceId
          })
          .build()
      )
    }
  }(),
  removeFavorite: function () {
    const builder = new RequestBuilder(ENDPOINT_FAVORITES).asDelete().expectJSON().withoutCredentials().asFormUrlEncoded()

    return function (id: string, type: ContentType) {

      return authorizedPipe<any>(
        builder
          .body({
            content_item_id: id,
            content_item_type: type
          })
          .build()
      )
    }
  }(),
  getFavoriteQuotes: function () {
    const builder = new RequestBuilder(ENDPOINT_FAVORITES_QUOTES).asGet().expectJSON().withoutCredentials()

    return function (page: number) {
      return authorizedPipe<any>(
        builder
          .param('page', page.toString())
          .param('page_size', PAGE_SIZE.toString())
          .build()
      )
    }
  }(),
  getFavoriteStatuses: function () {
    const builder = new RequestBuilder(ENDPOINT_FAVORITE_STATUS_IDEAS).asGet().expectJSON().withoutCredentials()

    return function (page: number) {
      return authorizedPipe<any>(
        builder
          .param('page', page.toString())
          .param('page_size', PAGE_SIZE.toString())
          .build()
      )
    }
  }(),
  addFavoriteStatusIdea: function () {
    return function (id: string, type: 'quote' | 'si') {
      let endpoint = ENDPOINT_FAVORITES_QUOTES
      let data: any = {
        quote_item_id: id,
        quote_item_type: type
      }

      if (type === 'si') {
        endpoint = ENDPOINT_FAVORITE_STATUS_IDEAS
        data = {
          status_idea_item_id: id,
          status_idea_item_type: type
        }
      }

      const builder = new RequestBuilder(endpoint).asPut().expectJSON().withoutCredentials().asFormUrlEncoded()
      return authorizedPipe<any>(
        builder
          .body(data)
          .build()
      )
    }
  }(),
  removeFavoriteStatusIdea: function () {

    return function (id: string, type: 'quote' | 'si') {
      const endpoint = type === 'si' ? ENDPOINT_REMOVE_FAVORITE_STATUS_IDEA : ENDPOINT_REMOVE_FAVORITE_QUOTE
      const builder = new RequestBuilder(endpoint)
        .asDelete().expectJSON().withoutCredentials().asFormUrlEncoded()
      return authorizedPipe<any>(
        builder
          .partial(':id', id)
          .build()
      )
    }
  }()
})
