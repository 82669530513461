import { IMAGE_THUMBNAILS_DIR } from '../../constants'
/**
 * Returns mapping of thumbnail to original image URL.
 * Works for images stored in PP CDN with URLS in the format "{S3_bucket_path}/{o|300xauto|600xauto}/{image_file_name}"
 * @export
 * @param {string[]} imageUrls
 * @returns { [thumbUrl: string]: string }
 */
export function getImagesUrlsMap(imageUrls: string[]) {
  return imageUrls.reduce((map: Record<string, string>, url) => {
    const urlArr = url.split('/')
    urlArr.splice(urlArr.length - 2, 1, IMAGE_THUMBNAILS_DIR)
    const fileName = urlArr.pop()
    if (fileName) {
      const extStart = fileName.lastIndexOf('.')
      const name = fileName.substring(0, extStart)
      urlArr.push(name + '.jpg')
    }
    const thumbUrl = urlArr.join('/')
    map[thumbUrl] = url
    return map
  }, {})
}
