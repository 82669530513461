export const BASE64_MARKER = ';base64,'
export function dataURLtoFile(dataUrl: string, fileName: string): File {
  // Format of a base64-encoded URL:
  // data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAYAAAAEOCAIAAAAPH1dAAAAK
  const mimeType = dataUrl.split(BASE64_MARKER)[0].split(':')[1]
  const bytes = atob(dataUrl.split(BASE64_MARKER)[1])
  const writer = new Uint8Array(new ArrayBuffer(bytes.length))

  for (let i = 0; i < bytes.length; i++) {
    writer[i] = bytes.charCodeAt(i)
  }
  return new File([writer.buffer], fileName, { type: mimeType })
}
