import { StatusIdea, IndexedObject } from 'interfaces'
import STATUSES_CATEGORIES from 'routes/find/routes/statuses/statuses.config'
import { unique } from 'utils/unique'

export interface StatusesData {
  page: number
  items: StatusIdea[]
}

const categories = STATUSES_CATEGORIES.map(cat => ({ ...cat, name: cat.title }))
export const initialData = categories.reduce((map: IndexedObject<StatusesData>, cat) => {
  map[cat.id] = { items: [], page: 0 }
  return map
}, {})

const ACTION_ITEMS_FETCHED = 'ACTION_ITEMS_FETCHED'
type ItemsFetchedAction = {
  type: typeof ACTION_ITEMS_FETCHED,
  payload: {
    categoryId: string
    page: number
    items: StatusIdea[]
  }
}
export function addItems(categoryId: string, page: number, items: StatusIdea[]): ItemsFetchedAction {
  return {
    type: ACTION_ITEMS_FETCHED,
    payload: { categoryId, page, items }
  }
}

const ACTION_INCREMENT_PAGE = 'ACTION_INCREMENT_PAGE'
type IncrementPageAction = {
  type: typeof ACTION_INCREMENT_PAGE,
  payload: {
    categoryId: string
  }
}
export function incrementPage(categoryId: string): IncrementPageAction {
  return {
    type: ACTION_INCREMENT_PAGE,
    payload: { categoryId }
  }
}

const ACTION_RESET_DATA = 'ACTION_RESET_DATA'
type ResetDataAction = {
  type: typeof ACTION_RESET_DATA
}
export function resetData(): ResetDataAction {
  return {
    type: ACTION_RESET_DATA
  }
}

export function statusesReducer(state: IndexedObject<StatusesData>, action: ItemsFetchedAction | IncrementPageAction | ResetDataAction) {
  if (action.type === ACTION_ITEMS_FETCHED) {
    const currentData = state[action.payload.categoryId]
    const items = action.payload.page === 0 ? action.payload.items : currentData.items.concat(action.payload.items)

    return {
      ...state,
      [action.payload.categoryId]: {
        ...currentData,
        page: action.payload.page,
        items: unique(items, 'id')
      }
    }
  }

  if (action.type === ACTION_INCREMENT_PAGE) {
    const currentData = state[action.payload.categoryId]
    return {
      ...state,
      [action.payload.categoryId]: {
        ...currentData,
        page: currentData.page + 1
      }
    }
  }

  if (action.type === ACTION_RESET_DATA) {
    return { ...initialData }
  }
  return state
}
