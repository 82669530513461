import { RequestBuilder, authorizedPipe, Result, createUnwrap } from 'services/net'
import { LEGACY_URL } from 'config'
import { LegacyList } from 'interfaces'

export type GetListsResult = Result<LegacyList[], 'lists'>
const unwrapGetLists = createUnwrap<LegacyList[], 'lists', GetListsResult>('lists') as any

export const V1 = Object.freeze({
  getLists: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/lists`).asGet().expectJSON()

    return function () {
      return authorizedPipe<LegacyList[]>(
        builder.build(),
        unwrapGetLists
      )
    }
  }(),
  updateList: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/lists/update_pages_list`).asPost().expectJSON()

    return function (id: string, name: string, pages: string[]) {
      return authorizedPipe<LegacyList>(
        builder
          .body({
            list_name: name,
            pages: JSON.stringify(pages),
            list_id: id
          })
          .asFormUrlEncoded()
          .build()
      )
    }
  }(),
  createList: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/lists/save_pages_list`).asPost().expectJSON()

    return function (name: string, pages: string[]) {
      return authorizedPipe<{ listId: string }>(
        builder
          .body({ list_name: name, pages: JSON.stringify(pages) })
          .asFormUrlEncoded()
          .build()
      )
    }
  }(),
  deleteList: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/lists/delete_pages_list`).asPost().expectJSON()

    return function (id: string) {
      return authorizedPipe<any>(
        builder
          .body({ list_id: id })
          .asFormUrlEncoded()
          .build()
      )
    }
  }(),
  reorderLists: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/lists/reorder`).asPost().expectJSON()

    return function (ids: string[]) {
      return authorizedPipe(
        builder
          .body(JSON.stringify(ids))
          .asFormUrlEncoded()
          .build()
      )
    }
  }()
})
