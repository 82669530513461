import * as React from 'react'
import Paper from '@mui/material/Paper'

import styles from './FeedCard.pcss'
import LinearProgress from '@mui/material/LinearProgress'

export function EmptyFeedCard(props: { loading?: boolean, typeIcon?: React.ReactElement, className?: string }) {
  return (
    <Paper className={`${styles.card} ${styles['card-empty']} ${props.className || ''}`}>
      <div className={`${styles['item-info']} ${styles['item-info-empty']}`}>
        {props.loading ? (
          <LinearProgress className={styles['empty-name']} />
        ) : (
          <p className={styles['empty-name']}></p>
        )}
        <p className={`${styles['feed-type']} ${styles['empty-type']}`}>
          {props.typeIcon}
        </p>
      </div>
    </Paper>
  )
}

export default EmptyFeedCard
