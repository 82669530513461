import * as React from 'react'
import Drawer from '@mui/material/Drawer'
import styles from './TextContentSearchPanel.pcss'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ContentItem, IndexedObject, STATUS_TYPE, WithIntl } from 'interfaces'
import StoreState, { StoreThunkDispatch } from 'store/state'
import { Observable } from 'rxjs/Observable'
import { useDispatch, useSelector } from 'react-redux'
import { Subject } from 'rxjs/Subject'
import { tap } from 'rxjs/operators/tap'
import { getQuotes } from 'admin/services/quotes/actions'
import { getFavoriteStatusIdeas } from 'services/content/favorites'
import { catchError } from 'rxjs/operators/catchError'
import CollapsibleTextInput from 'components/CollapsibleTextInput'
import { message } from 'services/snackbar'
import Chip from '@mui/material/Chip'
import CardList from 'components/CardList'
import AnyCard from 'components/Card'
import { renderLoadingCards } from 'routes/find/routes/home/components/utils'
import { CHIP_ACTIVE_CLASS } from 'theme/theme-md'
import EmptyView from 'components/EmptyView'
import { mdiHeartOutline } from '@mdi/js'
import FetchRandomContent from './FetchRandomContent'
import { addedFavoritesSelectorForType } from 'services/content/selectors'
import { searchStatusIdeas } from 'services/content/statuses/actions'

const TEXT_FAVORITES_TYPE = 'text-favorites'
const QUOTES_TYPE = 'quotes'

type PanelContentType = typeof TEXT_FAVORITES_TYPE
  | typeof QUOTES_TYPE
  | typeof STATUS_TYPE

const TEXT_TYPE_FILTERS: PanelContentType[] = [STATUS_TYPE, QUOTES_TYPE, TEXT_FAVORITES_TYPE]

const initialResults: Readonly<IndexedObject<ContentItem[]>> = {
  [QUOTES_TYPE]: [],
  [STATUS_TYPE]: []
}

interface TextContentSearchPanelOwnProps {
  open: boolean
  onClose: () => void
}

type TextContentSearchPanelProps = TextContentSearchPanelOwnProps & WithIntl

export function TextContentSearchPanel(props: TextContentSearchPanelProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [activeType, setActiveType] = React.useState<PanelContentType>(STATUS_TYPE)
  const [query, setQuery] = React.useState('')
  const [items, setItems] = React.useState<IndexedObject<ContentItem[]>>(initialResults)
  const [favTexts, setFavTexts] = React.useState<ContentItem[]>([])
  const [loading, setLoading] = React.useState(true)
  const [favLoading, setFavLoading] = React.useState(false)
  const addedFavTexts = useSelector((state: StoreState) => addedFavoritesSelectorForType(state, STATUS_TYPE))
  const search$ = React.useRef<Subject<{ query: string }>>(new Subject())
  const inputRef = React.useRef<HTMLInputElement>(null)
  const page$ = React.useRef(0)
  const [randomQuotes, setRandomQuotes] = React.useState<ContentItem[]>([])
  const [randomStatuses, setRandomStatuses] = React.useState<ContentItem[]>([])

  React.useEffect(() => {
    const fetchFavorites = async () => {
      setFavLoading(true)
      try {
        const response = await dispatch(getFavoriteStatusIdeas(0)).unwrap()
        setFavTexts(response as any)
      } catch (e) {
        console.log(`Error fetching favorites: ${e}`)
      }
      setFavLoading(false)
    }
    if (props.open) {
      fetchFavorites()
    }
  }, [props.open, dispatch])

  React.useEffect(() => {
    search$.current = new Subject()
    search$.current
      .pipe(
        tap(() => setLoading(true))
      )
      .flatMap(({ query }) => {
        return dispatch(getQuotes(page$.current, query))
          .zip(dispatch(searchStatusIdeas(query, page$.current)))
          .pipe(
            catchError(() => {
              dispatch(message(props.intl.formatMessage({ id: 'errors.generic' }), 'error'))
              return Observable.of({ error: true })
            })
          )
      })
      .subscribe((response: any) => {
        if (!response.error) {
          const [quotes, statuses] = response
          setItems(current => ({
            [QUOTES_TYPE]: current[QUOTES_TYPE].concat(quotes),
            [STATUS_TYPE]: current[STATUS_TYPE].concat(statuses)
          }))
        }
        setLoading(false)
      })

    return () => {
      search$.current.unsubscribe()
    }
  }, [dispatch, props.intl])

  const search = (value: string) => {
    const q = value.trim()
    if (q) {
      setQuery(q)
      setLoading(true)
      setItems(initialResults)
      page$.current = 0
      search$.current?.next({ query: q })
    }
  }

  const onLoadNextPage = () => {
    page$.current = page$.current + 1
  }

  const onRandomContentFetched = (type: 'quotes' | 'statuses', items: ContentItem[]) => {
    setLoading(false)
    switch (type) {
      case 'quotes':
        setRandomQuotes(items)
        break
      case 'statuses':
        setRandomStatuses(items)
    }
  }

  const visibleItems = React.useMemo(() => {
    if (activeType === TEXT_FAVORITES_TYPE) {
      return favTexts.concat(Object.values(addedFavTexts))
    }
    return items[activeType]
  }, [activeType, favTexts, items, addedFavTexts])

  const randomVisibleItems = React.useMemo(() => {
    switch (activeType) {
      case 'quotes':
        return randomQuotes
      case 'status':
        return randomStatuses
      default:
        return []
    }
  }, [activeType, randomQuotes, randomStatuses])

  const loadingCards = React.useMemo(() => {
    if (!favLoading && (activeType === TEXT_FAVORITES_TYPE)) {
      return null
    }
    if (!loading) {
      return null
    }

    return renderLoadingCards(undefined, false, true, loading)
  }, [favLoading, activeType, loading])

  const isFavoritesView = activeType === TEXT_FAVORITES_TYPE
  const isEmpty = visibleItems.length === 0

  const close = () => {
    props.onClose()
    setQuery('')
    setItems(initialResults)
  }

  return (
    <React.Fragment>
      <Drawer
        anchor="bottom"
        open={props.open}
        disableEnforceFocus
        classes={{ paper: styles.container }}
        data-testid="text-content-search-panel"
        onClose={close}
      >
        <div className={styles.top}>
          <div className={styles['top-header']}>
            <CollapsibleTextInput
              ref={inputRef}
              initialActive
              changeOnEnter
              disabled={isFavoritesView}
              placeholder={props.intl.formatMessage({
                id: activeType === 'quotes' ? 'find.quotes.search-hint' : 'find.status-ideas.search-hint'
              })}
              className={styles['search-box']}
              activeClassName={styles['search-active']}
              id="text-content-search-input"
              onValueChanged={search}
            />
          </div>
          <div className={styles.nav}>
            {TEXT_TYPE_FILTERS.map(type => (
              <NavChip key={type} value={type} isActive={activeType === type} onClick={setActiveType} />
            ))}
          </div>
        </div>
        <div className={styles.content}>
          <CardList onScrollLimit={onLoadNextPage} key={activeType + query}>
            {visibleItems.map(item => (
              <AnyCard
                key={item.id}
                content={item}
                children={item}
                square
                small
                hideDetails
                actions={['create-post', 'open-studio']}
              />
            ))}
            {isEmpty && !loading && !isFavoritesView && randomVisibleItems.map(item => (
              <AnyCard
                key={item.id}
                content={item}
                children={item}
                square
                small
                hideDetails
                actions={['create-post', 'open-studio']}
              />
            ))}
            {loadingCards}
          </CardList>
          {isFavoritesView && !favLoading && isEmpty && (
            <EmptyView
              title="No texts favorited yet"
              icon={mdiHeartOutline}
              subtitle={(
                <div>
                  Click ♡ on any content to save it to your favorites.
                </div>
              )}
              carousel
              className={styles.error}
            />
          )}
          {!isFavoritesView && !loading && isEmpty && Boolean(query) && (
            <EmptyView
              carousel
              title="No texts available for that search"
              subtitle="Please try a different keyword, topic, brand or industry."
              className={styles.error}
            />
          )}
        </div>
      </Drawer>
      <FetchRandomContent onFetched={onRandomContentFetched} />
    </React.Fragment>
  )
}

function NavChip(props: { value: PanelContentType, isActive: boolean, onClick: (value: PanelContentType) => void }) {
  const onClick = () => {
    props.onClick(props.value)
  }

  return (
    <Chip
      label={<FormattedMessage id={`general.content.filters.${props.value}`} />}
      className={`${styles.chip} ${props.isActive ? CHIP_ACTIVE_CLASS : ''}`}
      onClick={onClick}
    />
  )
}

export default injectIntl(TextContentSearchPanel)
