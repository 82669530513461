import { Article, ContentFeedType, ContentItem, PostDestination, PostDestinationType, Video } from 'interfaces'
import { createPostFailure, createPostRequest, createPostSuccess } from './create/actions'
import { getLinkDetails } from './getLinkDetails/actions'
import ComposerState, { ComposerScheduleState, COMPOSER_STATUS_KEY_GENERIC, initialState, ContentCardComposeAction } from './state'
import { youtubeReducers } from './youtube'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  ARTICLE_TYPE,
  BRAND_GOOGLE,
  BRAND_INSTAGRAM,
  BRAND_PINTEREST,
  BRAND_TWITTER,
  BRAND_YOUTUBE,
  INPUT_ACCEPT_PHOTO,
  INPUT_ACCEPT_VIDEO,
  PHOTO_TYPE,
  POST_TYPE_ARTICLE_LINK,
  POST_TYPE_PHOTO_LINK,
  POST_TYPE_VIDEO_LINK,
  STOCK_GIF_TYPE,
  STOCK_PHOTO_TYPE,
  VIDEO_TYPE
} from 'shared/constants'
import { ComposerBasePost, ComposerPinterestPost, TweetType } from './interfaces'
import {
  generateBulkPostCaptionFromHtml,
  getPinterestStatusFromContent,
  getTwitterStatusFromContent,
  removeTagsFromHtml
} from 'utils/composer'
import { logoutAction } from 'services/auth'
import { deleteBucket, updateBucket, setSelectedProfiles } from 'services/post/actions'
import { Bucket, GooglePostButtonType, LocationInfo, TikTokPostProfileSettings } from 'shared/types'
import { getDestinationsSuccess } from 'services/destinations'
import { facebookExtraReducers, facebookReducers } from './facebook/actions'
import { mediaReducers, mediaExtraReducers } from './media/actions'
import { multipostReducers } from './multi'
import { pinterestReducers } from './pinterest'
import { savedTextsExtraReducers } from './saved'
import { assistantExtraReducers } from './assistant'
import { resetComposer } from './actions'

export * from './facebook/actions'
export * from './getLinkDetails/actions'
export * from './create/actions'
export * from './update/actions'
export * from './pinterest'
export { resetComposer }
export * from './media/actions'
export * from './assistant'

const composerSlice = createSlice({
  name: 'composer',
  initialState: initialState(),
  reducers: {
    setComposerProfiles: (state, action: PayloadAction<PostDestination[]>) => {
      const ids: string[] = []
      const types: { [key: string]: boolean } = {}
      action.payload.forEach(profile => {
        ids.push(profile.id)
        types[profile.type] = true
      })

      const networks = Object.keys(types)
      state.activeTab = networks.includes(state.activeTab) ? state.activeTab : networks[0] as PostDestinationType
      state.selectedProfiles = ids
    },
    setComposerActiveTab: (state, action: PayloadAction<PostDestinationType>) => {
      const isStatusEdited = Object.values(state.posts).some(p => p.status.touched)
      // NOTE: If status is already present in any tab, mark all tabs as "touched"
      if (isStatusEdited) {
        Object.values(state.posts).forEach(p => {
          p.status.touched = true
        })
      }
      state.activeTab = action.payload
    },
    setComposerStatus: (
      state,
      action: PayloadAction<{ network: PostDestinationType | typeof COMPOSER_STATUS_KEY_GENERIC, text: string, html: string }>
    ) => {
      const { network, text, html } = action.payload
      Object.keys(state.posts).forEach(key => {
        if (key === network) {
          state.posts[key].status.text = text
          state.posts[key].status.html = html
          state.posts[key].status.touched = true
        } else if (state.useGenericStatus && network === COMPOSER_STATUS_KEY_GENERIC) {
          if (key === BRAND_PINTEREST || key === BRAND_YOUTUBE) {
            (state.posts[key] as ComposerPinterestPost).description = text
          } else {
            (state.posts as any)[key].status.text = text
            ; (state.posts as any)[key].status.html = removeTagsFromHtml(html)
          }
          // NOTE: For twitter, if an article is added, copy the generic text and append the article url, if not present
          if (key === BRAND_TWITTER && state.contentItem?.type === ARTICLE_TYPE) {
            const articleUrl = (state.contentItem as Article).sourceLink
            if (text.indexOf(articleUrl)) {
              state.posts[key].status.text = `${text}\n\n${articleUrl}`
              state.posts[key].status.html = `${text}<br/><br/>${articleUrl}`
            }
          }
        }
      })

      state.useGenericStatus = network === COMPOSER_STATUS_KEY_GENERIC ? state.useGenericStatus : false
      if (state.useGenericStatus && network === COMPOSER_STATUS_KEY_GENERIC) {
        Object.values(state.multiPost.posts).forEach(post => {
          const { html, text } = generateBulkPostCaptionFromHtml(action.payload.html, post)
          post.data.status = {
            html,
            text,
            touched: false
          }

          if (post.network === BRAND_PINTEREST || post.network === BRAND_YOUTUBE) {
            (post.data as ComposerPinterestPost).description = text
          }
        })
      }
    },
    setComposerContent: (state, action: PayloadAction<{ content: ContentItem, sourceType?: ContentFeedType, isRepost?: boolean }>) => {
      const { content, isRepost } = action.payload
      const autoFill = content.type === ARTICLE_TYPE || content.type === VIDEO_TYPE
      const autoTwitterText = getTwitterStatusFromContent(content)
      const twitterStatus = autoFill ? {
        text: autoTwitterText,
        html: autoTwitterText,
        touched: false
      } : { ...state.posts.twitter.status }
      const images = [...state.imageUrls]
      const isImage = content.type === PHOTO_TYPE || content.type === STOCK_PHOTO_TYPE || content.type === STOCK_GIF_TYPE

      if (isImage && !images.includes((content as any).imageUrl)) {
        images.push((content as any).imageUrl)
      }

      let imageUrls = isImage ? images : [] // Clear image urls if adding article or video post
      let featuredImageUrl = imageUrls.length === 1 ? imageUrls[0] : state.featuredImageUrl
      if (isRepost && isImage) {
        imageUrls = content.imageUrls || [content.imageUrl]
        featuredImageUrl = undefined
      }
      if (!isImage) {
        featuredImageUrl = undefined
      }
      state.imageUrls = imageUrls
      state.featuredImageUrl = featuredImageUrl

      state.posts.twitter.status = twitterStatus
      state.posts.twitter.tweetType = 'new'
      state.posts.pinterest = { ...state.posts.pinterest, ...getPinterestStatusFromContent(content) }
      state.posts.facebook.textBackground = undefined
      if (content.type === ARTICLE_TYPE) {
        state.posts.google.link = (content as Article).sourceLink || (content as Article).shortLink || ''
      }
      // Clear contentItem if multiple images are selected
      state.contentItem = imageUrls.length > 1 ? undefined : content
      state.videoUrl = undefined
      state.videoThumbnailUrl = undefined
      state.videoDuration = undefined
      state.videoSize = undefined
      state.videoMetadata = undefined
      state.links = content.type === ARTICLE_TYPE ? state.links.map(link => ({ ...link, selected: false })) : []
      state.postAsPhoto = false
    },
    clearComposerContent: (state) => {
      // When removing article, mark first link (if any) as selected
      if (state.contentItem?.type === ARTICLE_TYPE && state.links.length > 0) {
        if (!state.links.some(l => l.selected)) {
          state.links[0].selected = true
        }
      }
      state.postAsPhoto = false
      state.contentItem = undefined
    },
    setRecycle: (state, action: PayloadAction<boolean | Date | number>) => {
      state.recycle = action.payload
    },
    setIsComposerUploading: (state, action: PayloadAction<boolean | 'video'>) => {
      state.isUploading = action.payload
      if (action.payload) {
        state.links = []
        state.contentItem = undefined
      }
    },
    setFeaturedImage: (state, action: PayloadAction<string | undefined>) => {
      state.featuredImageUrl = action.payload
    },
    setComposerExternalFile: (state, action: PayloadAction<File | undefined>) => {
      state.externalFile = action.payload
    },
    setDraft: (state, action: PayloadAction<{ id: string, updatedAt: string } | undefined>) => {
      state.draft = action.payload
      state.postEdit = undefined
    },
    mergeState: (state, action: PayloadAction<Partial<ComposerState>>) => {
      const pinterestBoards = { ...action.payload.pinterestBoards }
      if (Object.keys(pinterestBoards).length > 0) {
        const ppid = Object.keys(pinterestBoards)[0]
        const boardIds = Object.values(pinterestBoards[ppid]).map((b: any) => b.id)
        const currentBoardsForProfile = state.pinterestBoards[ppid] || []
        const boardsToAdd = currentBoardsForProfile.filter(b => !boardIds.includes(b.id)).map(b => ({ ...b, selected: false }))

        if (currentBoardsForProfile) {
          pinterestBoards[ppid].push(...boardsToAdd)
        }
      }

      const igPost = action.payload.posts?.instagram || state.posts.instagram
      igPost.locations = { ...state.posts.instagram.locations, ...igPost.locations }

      return {
        ...state,
        ...action.payload,
        writer: state.writer,
        pinterestBoards,
        posts: {
          ...initialState().posts,
          ...action.payload.posts,
          [BRAND_INSTAGRAM]: igPost
        }
      }
    },
    setPostEdit: (state, action: PayloadAction<{ id: string, ppid: string, bucketId?: string } | undefined>) => {
      state.postEdit = action.payload
      state.useGenericStatus = typeof action.payload === 'undefined' ? state.useGenericStatus : false
      state.selectedBucketId = undefined
    },
    setSchedule: (state, action: PayloadAction<Partial<ComposerScheduleState>>) => {
      state.schedule = { ...state.schedule, ...action.payload }
    },
    setComposerBucket: (state, action: PayloadAction<string | undefined>) => {
      state.selectedBucketId = action.payload
      state.draft = undefined
      state.schedule.isActive = action.payload ? false : state.schedule.isActive
    },
    setPostAsPhoto: (state, action: PayloadAction<boolean>) => {
      state.postAsPhoto = action.payload
    },
    setNetworksView: (state, action: PayloadAction<'list' | 'tabs'>) => {
      state.networksView = action.payload
    },
    toggleGenericStatus: (state, action: PayloadAction<boolean | undefined>) => {
      const next = typeof action.payload === 'undefined' ? !state.useGenericStatus : action.payload
      const genericStatus = state.posts[COMPOSER_STATUS_KEY_GENERIC].status
      state.useGenericStatus = next
      if (next) {
        Object.values(state.posts).forEach(post => {
          post.status = genericStatus
        })

        Object.values(state.multiPost.posts).forEach(post => {
          const { html, text } = generateBulkPostCaptionFromHtml(genericStatus.html, post)
          post.data.status = {
            html,
            text,
            touched: false
          }
        })
      }
    },
    setPromptId: (state, action: PayloadAction<string | undefined>) => {
      state.writer.aiWriterPromptId = action.payload
    },
    clearComposerLink: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload) {
        const index = state.links.findIndex(l => l.url === action.payload)
        if (index !== -1) {
          state.links.splice(index, 1)
        }
        if (state.links[0]) {
          state.links[0].selected = true
        }
      } else {
        state.links = []
      }
    },
    setSelectedLink: (state, action: PayloadAction<string>) => {
      state.links = state.links.map(l => ({ ...l, selected: l.url === action.payload }))
    },
    setInstagramPostLocation: (state, action: PayloadAction<{ location: LocationInfo | undefined, ppid: string }>) => {
      const { location, ppid } = action.payload
      state.posts.instagram.locations[ppid] = location
    },
    toggleRememberIGLocation: (state) => {
      state.rememberIGLocations = !state.rememberIGLocations
    },
    setInstagramFirstComment: (state, action: PayloadAction<string>) => {
      state.posts.instagram.firstComment = action.payload
    },
    setComposerGoogleButtonType: (state, action: PayloadAction<GooglePostButtonType | undefined>) => {
      state.posts.google.buttonType = action.payload
    },
    setComposerGoogleLink: (state, action: PayloadAction<string>) => {
      state.posts.google.link = action.payload
    },
    setTikTokProfileSettings: (state, action: PayloadAction<TikTokPostProfileSettings>) => {
      state.posts.tiktok.settings[action.payload.ppid] = action.payload
    },
    removePostProgress: (state, action: PayloadAction<string>) => {
      delete state.progress[action.payload]
    },
    setInstagramPostType: (state, action: PayloadAction<string | undefined>) => {
      state.posts.instagram.postType = action.payload
    },
    setInstagramTags: (state, action: PayloadAction<string[]>) => {
      state.posts.instagram.tags = action.payload
    },
    promptCompose: (state, action: PayloadAction<{ content: ContentItem, action: ContentCardComposeAction }>) => {
      state.prompt = action.payload
    },
    closeComposerPrompt: (state) => {
      state.prompt = {}
    },
    setComposerTweetType: (state, action: PayloadAction<TweetType>) => {
      state.posts.twitter.tweetType = action.payload
      if (state.contentItem?.type === ARTICLE_TYPE || state.contentItem?.type === VIDEO_TYPE && !state.posts.twitter.status.text) {
        state.posts.twitter.status.text = (state.contentItem as Article).sourceLink || (state.contentItem as Video).shortLink || ''
        state.posts.twitter.status.html = state.posts.twitter.status.text
      }
    },
    setLinkedinFirstComment: (state, action: PayloadAction<string>) => {
      state.posts.linkedin.firstComment = action.payload
    },
    setLinkedinDocument: (state, action: PayloadAction<{ url: string, name: string } | undefined>) => {
      state.posts.linkedin.document = action.payload
    },
    // Imported actions
    ...facebookReducers,
    ...mediaReducers,
    ...multipostReducers,
    ...pinterestReducers,
    ...youtubeReducers
  },
  extraReducers: builder => {
    builder
      .addCase(resetComposer, (state) => {
        const initial = initialState()
        return {
          ...initial,
          activeTab: state.activeTab,
          selectedProfiles: state.selectedProfiles,
          selectedBucketId: state.selectedBucketId,
          savedStatuses: state.savedStatuses,
          resetKey: state.resetKey + 1,
          networksView: state.networksView,
          rememberIGLocations: state.rememberIGLocations,
          pinterestBoards: state.pinterestBoards,
          bulkUploadFile: undefined,
          posts: {
            ...initial.posts,
            // Do not clear selected Google Button type
            [BRAND_GOOGLE]: { ...initial.posts.google, buttonType: state.posts.google.buttonType },
            [BRAND_INSTAGRAM]: { ...initial.posts.instagram, locations: state.rememberIGLocations ? state.posts.instagram.locations : {} }
          }
        }
      })
      .addCase(logoutAction, () => initialState())
      .addCase(updateBucket.fulfilled, (state, action) => {
        const data = (action as any).payload
        const bucket: Bucket = data.bucket
        if (bucket.id === state.selectedBucketId) {
          state.selectedProfiles = data.profiles.map((p: PostDestination) => p.id)
        }
      })
      .addCase(deleteBucket.fulfilled, (state, action) => {
        const deletedBucketId = (action as any).payload
        state.selectedBucketId = state.selectedBucketId === deletedBucketId ? undefined : state.selectedBucketId
      })
      .addCase(setSelectedProfiles, (state, action) => {
        const ids: string[] = []
        const types: { [key: string]: boolean } = {}
        action.payload.profiles.forEach((profile: PostDestination) => {
          ids.push(profile.id)
          types[profile.type] = true
        })

        const networks = Object.keys(types)
        state.activeTab = networks.includes(state.activeTab) ? state.activeTab : networks[0] as PostDestinationType
        state.selectedProfiles = ids
      })
      .addCase(getDestinationsSuccess, (state, action) => {
        if (state.selectedProfiles.length === 0) {
          const ids: string[] = []
          action.payload.forEach((p: PostDestination) => {
            if (p.connected) {
              ids.push(p.id)
            }
          })
          state.selectedProfiles = ids
        }
      })
      .addCase(getLinkDetails.fulfilled, (state, action) => {
        const { link, isPinterestDestinationUrl } = action.payload
        // Do not set link if type is missing and link has no title or files are uploading
        if ((!link.type && !link.title) || state.isUploading) {
          return state
        }

        let statusTouched = false
        if (isPinterestDestinationUrl) {
          for (const network of Object.keys(state.posts)) {
            if (network === BRAND_PINTEREST) {
              continue
            }
            if (((state.posts as any)[network] as ComposerBasePost).status.touched) {
              statusTouched = true
              break
            }
          }
        }
        const shouldUpdateGlobalStateLink = !isPinterestDestinationUrl || !statusTouched
        const url = link.url.split('?')[0]
        const linkExt = url.substring(url.lastIndexOf('.'))
        const isNativeVideoLink = INPUT_ACCEPT_VIDEO.includes(linkExt?.toLowerCase() + '')
        const isImageLink = INPUT_ACCEPT_PHOTO.includes(linkExt?.toLowerCase())

        if (isImageLink) {
          return {
            ...state,
            imageUrls: [...state.imageUrls, link.image[0]],
            links: [],
            videoUrl: undefined,
            videoThumbnailUrl: undefined,
            videoDuration: undefined,
            videoMetadata: undefined,
            videoSize: undefined,
            posts: {
              ...state.posts,
              facebook: {
                ...state.posts.facebook,
                textBackground: undefined
              }
            }
          }
        }

        if (isNativeVideoLink) {
          return {
            ...state,
            videoUrl: link.url,
            imageUrls: [],
            postAsPhoto: false,
            links: [],
            posts: {
              ...state.posts,
              facebook: {
                ...state.posts.facebook,
                textBackground: undefined
              }
            }
          }
        }

        const pin = Object.assign({}, state.posts.pinterest)
        const googlePost = Object.assign({}, state.posts.google)
        if (!isImageLink && !isNativeVideoLink) {
          pin.destinationUrl = pin.destinationUrl || link.url
          pin.title = pin.title || link.title || ''
          pin.description = pin.description || link.description || ''
          googlePost.link = link.url
        }

        const newLink = Object.assign({}, link)
        newLink.selected = state.links.length === 0 && state.contentItem?.type !== ARTICLE_TYPE
        // If link type has invalid value, set it to the default "link"
        if (![POST_TYPE_PHOTO_LINK, POST_TYPE_VIDEO_LINK, POST_TYPE_ARTICLE_LINK].includes(newLink.type)) {
          newLink.type = POST_TYPE_ARTICLE_LINK
        }

        const linksArray = state.links.find(l => l.url === newLink.url) ? state.links : state.links.concat(newLink)

        return {
          ...state,
          links: shouldUpdateGlobalStateLink ? linksArray : state.links,
          videoUrl: undefined,
          videoThumbnailUrl: undefined,
          videoDuration: undefined,
          videoSize: undefined,
          videoMetadata: undefined,
          postAsPhoto: false,
          posts: {
            ...state.posts,
            pinterest: pin,
            google: googlePost,
            facebook: {
              ...state.posts.facebook,
              textBackground: undefined
            }
          }
        }
      })
      .addCase(createPostSuccess, (state, action) => {
        state.progress[action.payload.id] = { post: action.payload.post, error: undefined, complete: true }
      })
      .addCase(createPostRequest, (state, action) => {
        const { post, id } = action.payload
        state.progress[id] = { post, error: undefined, complete: false }
      })
      .addCase(createPostFailure, (state, action) => {
        const { error, source } = action.payload
        const { post, id } = source
        state.progress[id] = { post, error: error.message, complete: true }
      })

    // Imported extra reducers
    facebookExtraReducers(builder)
    savedTextsExtraReducers(builder)
    assistantExtraReducers(builder)
    mediaExtraReducers(builder)
  },
  selectors: {
    getSelectedProfiles: state => state.selectedProfiles
  }
})

export const {
  setComposerActiveTab,
  setComposerStatus,
  setComposerContent,
  clearComposerContent,
  setRecycle,
  setIsComposerUploading,
  setFeaturedImage,
  setComposerExternalFile,
  setDraft,
  mergeState,
  setPostEdit,
  setSchedule,
  setComposerBucket,
  setPostAsPhoto,
  setNetworksView,
  toggleGenericStatus,
  setPromptId,
  setComposerProfiles,
  clearComposerLink,
  setSelectedLink,
  setComposerGoogleButtonType,
  setComposerGoogleLink,
  setInstagramPostLocation,
  toggleRememberIGLocation,
  setInstagramFirstComment,
  setTikTokProfileSettings,
  removePostProgress,
  selectComposerFBAlbum,
  toggleFBPostReshare,
  setFBPostType,
  setFacebookTextBackground,
  setFbFirstComment,
  clearComposerFiles,
  setComposerBulkUploadFileUrl,
  removeComposerFileUrl,
  setComposerImages,
  setComposerVideoThumbnail,
  setComposerVideoThumbnailOffset,
  setInstagramPostType,
  setInstagramTags,
  promptCompose,
  closeComposerPrompt,
  addArticlePosts,
  addImagePosts,
  addVideoPosts,
  addImageUploadPosts,
  addMultipostVideoUrl,
  applyGenericStatusToPosts,
  editMultiPosts,
  handleMultiPostError,
  imageUploadUpdate,
  setMultiPostTweetTypeAll,
  imagesUploadDone,
  initializeTikTokSettings,
  removeMultiPosts,
  setMultiPostNetworkSettings,
  setMultiPostError,
  setMultiPostIGLocations,
  reorderMultiPosts,
  resetMultiPostState,
  setMultiPostFBAlbums,
  setMultiPostFBReshareAll,
  setMultiPostPiBoards,
  setMultiPostsRecycle,
  setMultipostTikTokProfileSettings,
  setMultipostFirstComment,
  updateArticlePost,
  clearPinterestBoards,
  selectPinterestBoard,
  setPinDescription,
  setPinDestinationUrl,
  setPinTitle,
  setPinterestProfileBoards,
  setYtFirstComment,
  setYtDescription,
  setComposerTweetType,
  setYtPrivacy,
  setYtTitle,
  setLinkedinFirstComment,
  setLinkedinDocument
} = composerSlice.actions

export default composerSlice.reducer

export const { getSelectedProfiles } = composerSlice.selectors
