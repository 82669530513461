import { LEGACY_URL } from 'config'
import { RequestBuilder, authorizedPipe, unauthorizedPipe } from 'services/net'

const ENDPOINT_CREATE_ACCOUNT = `${LEGACY_URL}/v3/signup/create_account`
const ENDPOINT_CHECKOUT = `${LEGACY_URL}/v3/signup/payment_details`
const ENDPOINT_VALIDATE_COUPON_CODE = `${LEGACY_URL}/v3/signup/check_coupon`
const ENDPOINT_VALIDATE_REFERRAL_CODE = `${LEGACY_URL}/v3/signup/check_referral`

export const createAccount = function (
  firstName: string,
  lastName: string,
  email: string,
  captchaToken: string,
  productHandle?: string,
  fbId?: string,
  securityCode?: string,
  teamHash?: string
) {
  const builder = new RequestBuilder(ENDPOINT_CREATE_ACCOUNT).asPost().expectJSON()
  const data: any = {
    first_name: firstName,
    last_name: lastName,
    email,
    captcha_token: captchaToken
  }
  if (productHandle) {
    data.product_handle = productHandle
  }
  if (fbId) {
    data.fbid = fbId
  }
  if (securityCode) {
    data.security_code = securityCode
  }
  if (teamHash) {
    data.team_hash = teamHash
  }
  return unauthorizedPipe<any>(builder.body(data).build())
}

export const checkout = function (
  user: {
    firstName: string
    lastName: string
    email: string
    loginOtt?: string
  },
  productHandle: string,
  chargifyToken: string,
  currency: string,
  isLoggedIn: boolean,
  couponCode?: string,
  referralCode?: string
) {
  const builder = new RequestBuilder(ENDPOINT_CHECKOUT).asPost().expectJSON()
  const data: any = {
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    product_handle: productHandle,
    chargify_token: chargifyToken,
    currency
  }
  if (couponCode) {
    data.coupon_code = couponCode
  }
  if (referralCode) {
    data.referral_code = referralCode
  }
  if (user.loginOtt) {
    data.login_ott = user.loginOtt
  }

  const request = builder.body(data).build()
  return isLoggedIn ? authorizedPipe(request) : unauthorizedPipe(request)
}

type ValidateCodeResponse = {
  result: 'valid' | 'invalid'
  discountAmount: number
  discountPercentage: number
}
export const validateCouponCode = function (code: string, productHandle: string) {
  const builder = new RequestBuilder(ENDPOINT_VALIDATE_COUPON_CODE).asPost().expectJSON()
  const data = { code, product: productHandle }
  return unauthorizedPipe<ValidateCodeResponse>(builder.body(data).build())
  // return () => Observable.of({ result: 'valid', discountAmount: 5, discountPercentage: 0.1 })
}

export const validateReferralCode = function (code: string) {
  const builder = new RequestBuilder(ENDPOINT_VALIDATE_REFERRAL_CODE).asPost().expectJSON()
  const data = { code }
  return unauthorizedPipe<ValidateCodeResponse>(builder.body(data).build())
  // return () => Observable.of({ result: 'valid', discountAmount: 5, discountPercentage: 0.5 })
}
