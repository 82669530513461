import * as React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import IconCheck from '@mui/icons-material/Check'
import IconError from '@mui/icons-material/Error'
import IconImage from '@mui/icons-material/Image'
import IconGif from '@mui/icons-material/Gif'
import IconVideo from '@mui/icons-material/Videocam'
import IconText from '@mui/icons-material/Comment'
import IconLink from '@mui/icons-material/Link'
import Tooltip from '@mui/material/Tooltip'

import styles from './PostProgressSnackbar.pcss'
import { CreatePostRequestData } from 'shared/types'
import { PlayIcon } from 'components/Icons'
const CARD_ELEVATION = 4
const AUTOCLOSE_TIMEOUT = 5000 // ms
const PROGRESS_VALUE_COMPLETE = 100
const TEXT_EMPTY = 'Creating post...'

interface PostProgressSnackbarProps {
  id: string
  post: CreatePostRequestData
  error?: string
  complete?: boolean
  onClose: (id: string) => void
}

export function PostProgressSnackbar(props: PostProgressSnackbarProps) {
  const { post, error, complete } = props

  const close = () => {
    props.onClose(props.id)
  }

  const [autoClose, setAutoClose] = React.useState(false)

  React.useEffect(() => {
    let timer: any
    if (autoClose) {
      timer = setTimeout(close, AUTOCLOSE_TIMEOUT)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [autoClose])

  React.useEffect(() => {
    if (props.complete || props.error) {
      setAutoClose(true)
    }
  }, [props.complete, props.error])

  let imageUrl = post.featuredImageUrl
  if (!imageUrl) {
    imageUrl = post.photos ? post.photos[0] : ''
  }
  let text = ''
  for (const network of Object.keys(post.networks)) {
    const status = (post.networks as any)[network].status
    if (status) {
      text = status
      break
    }
  }

  let icon = <IconText className={styles['icon-text']} />
  if (post.postType === 'photo') {
    icon = <IconImage className={styles['icon-image']} />
  } else if (post.postType === 'gif') {
    icon = <IconGif className={styles['icon-gif']} />
  } else if (post.postType === 'video' || post.postType === 'video_link') {
    icon = <IconVideo className={styles['icon-video']} />
  } else if (post.postType === 'link') {
    icon = <IconLink className={styles['icon-link']} />
  }

  const withImage = Boolean(imageUrl)
  const imgStyle = withImage ? {
    backgroundImage: `url(${imageUrl})`
  } : undefined

  const withVideo = Boolean(post.videoUrl)

  return (
    <Paper classes={{ root: styles.paper }} elevation={CARD_ELEVATION} onClick={close}>
      <div className={styles.content}>
        <div className={styles['img-box']} style={imgStyle}>
          {!withImage && !withVideo && icon}
          {withVideo && (
            <React.Fragment>
              <video src={post.videoUrl} className={styles.video} />
              <PlayIcon size={24} className={styles['icon-play']} />
            </React.Fragment>
          )}
        </div>
        <div className={styles['content-inner']}>
          <span className={styles.message}>{text || TEXT_EMPTY}</span>
          {complete && !error && <IconCheck color="primary" className={styles['icon-complete']} />}
          {Boolean(error) && (
            <Tooltip
              title={error}
              placement="top"
            >
              <IconError className={styles['icon-error']} />
            </Tooltip>
          )}
        </div>
      </div>
      <LinearProgress
        variant={complete ? 'determinate' : 'indeterminate'}
        color="primary"
        value={complete ? PROGRESS_VALUE_COMPLETE : undefined}
        classes={{ barColorPrimary: styles['loader-bar'] }}
      />
    </Paper>
  )
}
