import { createElement } from 'react'
import Loader from 'components/SimpleLoader'
import loadable, { LoadableComponent } from '@loadable/component'
import { lazyLoad } from 'utils/chunk'

const AsyncPost: LoadableComponent<any> = loadable(() => lazyLoad(
  () => import(
    /* webpackPrefetch: true */
    /* webpackChunkName: 'post' */
    './Publishing'
  ),
  'publishing'
), {
  fallback: createElement(Loader, { title: 'Loading Publishing...' })
})

export default AsyncPost
