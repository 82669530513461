import * as React from 'react'
import { PostDestinationType, BRAND_FACEBOOK, BRAND_TWITTER, WithIntl } from 'interfaces'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ShareIcon from '@mui/icons-material/ArrowForward'
import LikeIcon from '@mui/icons-material/ThumbUp'
import CommentIcon from '@mui/icons-material/ChatBubbleOutline'
import ClickIcon from '@mui/icons-material/CallMade'
import TimeIcon from '@mui/icons-material/AccessTime'
import RetweetIcon from '@mui/icons-material/Repeat'
import TwitterLikeIcon from '@mui/icons-material/StarBorder'
import CheckIcon from '@mui/icons-material/Check'
import { FormattedMessage, injectIntl } from 'react-intl'

import styles from './BulkActionMenus.pcss'
import { useDispatch } from 'react-redux'
import { message } from 'services/snackbar'
import IconButton from '@mui/material/IconButton'
import Icon from '@mdi/react'
import { mdiSortAscending } from '@mdi/js'
import Tooltip from '@mui/material/Tooltip'

/* eslint-disable no-magic-numbers */
export enum PostedSortBy {
  Shares = 1,
  Likes = 2,
  Comments = 3,
  Clicks = 4,
  Time = 5
}
/* eslint-enable no-magic-numbers */
export type SortDirection = 'ascending' | 'descending'

export interface PostedSortMenuProps extends WithIntl {
  sortBy?: PostedSortBy
  sortDirection: SortDirection
  className?: string
  network?: PostDestinationType
  disabled?: boolean
  disabledMessage?: string
  onSortChange: (sortBy: PostedSortBy) => void
  onSortDirectionChange: (dir: SortDirection) => void
}

export function PostedSortMenu(props: PostedSortMenuProps) {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (props.disabled) {
      if (props.disabledMessage) {
        dispatch(message(props.disabledMessage, 'warning'))
      }
      return
    }
    setAnchorEl(event.currentTarget as any)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const onChange = (sortBy: PostedSortBy) => () => {
    props.onSortChange(sortBy)
    closeMenu()
  }

  const toggleSortDirection = () => {
    props.onSortDirectionChange(props.sortDirection === 'ascending' ? 'descending' : 'ascending')
  }

  const itemClasses = { selected: styles.selected }
  const className = `${styles.posted} ${props.className || ''}`
  const value = React.useMemo(() => {
    if (props.network === BRAND_TWITTER) {
      if (props.sortBy === PostedSortBy.Shares) {
        return props.intl.formatMessage({ id: 'post.actions.sort-by.retweets' })
      }
      if (props.sortBy === PostedSortBy.Likes) {
        return props.intl.formatMessage({ id: 'post.actions.sort-by.favorites' })
      }
    }

    return props.sortBy ? PostedSortBy[props.sortBy] : props.intl.formatMessage({ id: 'post.actions.sort-by.recent' })
  }, [props.sortBy, props.network, props.intl])

  return (
    <div data-test="posted-sort-menu" className={className}>
      <Button
        onClick={openMenu}
        className={`${styles['btn-menu']} ${props.disabled ? styles.disabled : ''}`}
      >
        {`
          ${props.intl.formatMessage({ id: 'post.actions.sort-by' })}:
           ${value}
        `}
      </Button>
      <Tooltip title={<FormattedMessage id={`post.actions.sort-direction-${props.sortDirection}`} />}>
        <IconButton size="small" className={`${styles['btn-sort-dir']} solid`} onClick={toggleSortDirection}>
          <Icon
            path={mdiSortAscending}
            size="20px"
            className={`${styles['sort-icon']} ${props.sortDirection === 'descending' ? styles.flip : ''}`}
          />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        classes={{ paper: styles['menu-posted'] }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={closeMenu}
      >
        {
          props.network === BRAND_TWITTER && [
            <MenuItem
              key="shares"
              className={styles.item}
              classes={itemClasses}
              onClick={onChange(PostedSortBy.Shares)}
              selected={props.sortBy === PostedSortBy.Shares}
            >
              <RetweetIcon className={styles.icon} />
              <FormattedMessage id="post.actions.sort-by.retweets" defaultMessage="Retweets" />
              {props.sortBy === PostedSortBy.Shares && <CheckIcon fontSize="small" className={styles['icon-check']} />}
            </MenuItem>,
            <MenuItem
              key="likes"
              className={styles.item}
              classes={itemClasses}
              onClick={onChange(PostedSortBy.Likes)}
              selected={props.sortBy === PostedSortBy.Likes}
            >
              <TwitterLikeIcon className={styles.icon} />
              <FormattedMessage id="post.actions.sort-by.favorites" defaultMessage="Favorites" />
              {props.sortBy === PostedSortBy.Likes && <CheckIcon fontSize="small" className={styles['icon-check']} />}
            </MenuItem>
          ]
        }

        {
          props.network === BRAND_FACEBOOK && [
            <MenuItem
              key="shares"
              className={styles.item}
              classes={itemClasses}
              onClick={onChange(PostedSortBy.Shares)}
              selected={props.sortBy === PostedSortBy.Shares}
            >
              <ShareIcon className={styles.icon} />
              <FormattedMessage id="post.actions.sort-by.shares" defaultMessage="Shares" />
              {props.sortBy === PostedSortBy.Shares && <CheckIcon fontSize="small" className={styles['icon-check']} />}
            </MenuItem>,
            <MenuItem
              key="likes"
              className={styles.item}
              classes={itemClasses}
              onClick={onChange(PostedSortBy.Likes)}
              selected={props.sortBy === PostedSortBy.Likes}
            >
              <LikeIcon className={styles.icon} />
              <FormattedMessage id="post.actions.sort-by.likes" defaultMessage="Likes" />
              {props.sortBy === PostedSortBy.Likes && <CheckIcon fontSize="small" className={styles['icon-check']} />}
            </MenuItem>,
            <MenuItem
              key="comments"
              className={styles.item}
              classes={itemClasses}
              onClick={onChange(PostedSortBy.Comments)}
              selected={props.sortBy === PostedSortBy.Comments}
            >
              <CommentIcon className={styles.icon} />
              <FormattedMessage id="post.actions.sort-by.comments" defaultMessage="Comments" />
              {props.sortBy === PostedSortBy.Comments && <CheckIcon fontSize="small" className={styles['icon-check']} />}
            </MenuItem>,
            <MenuItem
              key="clicks"
              className={styles.item}
              classes={itemClasses}
              onClick={onChange(PostedSortBy.Clicks)}
              selected={props.sortBy === PostedSortBy.Clicks}
            >
              <ClickIcon className={`${styles.icon} ${styles['icon-click']}`} />
              <FormattedMessage id="post.actions.sort-by.clicks" defaultMessage="Clicks" />
              {props.sortBy === PostedSortBy.Clicks && <CheckIcon fontSize="small" className={styles['icon-check']} />}
            </MenuItem>
          ]
        }

        <MenuItem
          key="time"
          className={styles.item}
          classes={itemClasses}
          onClick={onChange(PostedSortBy.Time)}
          selected={props.sortBy === PostedSortBy.Time}
        >
          <TimeIcon className={styles.icon} />
          <FormattedMessage id="post.actions.sort-by.recent" />
          {props.sortBy === PostedSortBy.Time && <CheckIcon fontSize="small" className={styles['icon-check']} />}
        </MenuItem>
      </Menu>
    </div>
  )
}

export default injectIntl(PostedSortMenu)
