import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import { FeatherIcon } from 'components/Icons'
import { Observable } from 'rxjs/Observable'
import styles from './ContentCardActions.pcss'
import FavButton from './FavButton'
import {
  ContentItem,
  ARTICLE_TYPE,
  FEED_TYPE_KEYWORD,
  FEED_TYPE_FACEBOOK,
  FEED_TYPE_INSTAGRAM,
  PHOTO_TYPE,
  Article
} from 'interfaces'
import { TEXT_TYPE } from 'interfaces/Content/ContentType'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  feedTypeFeatureMap,
  contentTypeFeatureMap,
  getContentAgeFeature,
  checkFeatureAvailability
} from 'services/product'
import { FEATURE_FIND_FAVORITES } from 'shared/constants'
import Tooltip from '@mui/material/Tooltip'
import IconAdd from '@mui/icons-material/Add'
import IconCheck from '@mui/icons-material/Check'
import PaletteIcon from '@mui/icons-material/Palette'
import { STOCK_PHOTO_TYPE } from 'interfaces/Content/StockContentTypes'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'services/snackbar'
import { mdiAutoFix } from '@mdi/js'
import Icon from '@mdi/react'
import { currentUserSelector } from 'services/users/selectors'

export type ContentCardAction = 'favorite' | 'create-post' | 'select' | 'open-studio' | 'ai'

const Clipboard = require('clipboard')

interface ContentCardActionsProps {
  content: ContentItem
  isFavorite: boolean
  isFavButtonVisible: boolean
  favCount: number
  className: string
  isSelected: boolean
  isStock?: boolean
  skipFeatureChecks?: boolean
  studioButtonIcon?: React.ReactNode
  actions?: ContentCardAction[]
  createPostLabel?: React.ReactNode
  isContentBlocked: (aiWriter: boolean) => void | boolean
  onCreatePost: (skipAddToComposer?: boolean) => boolean
  onAddFavorite: () => Observable<any>
  onRemoveFavorite: () => Observable<any>
  onSelected: () => void
  onDeselected: () => void
}

export function ContentCardActions(props: ContentCardActionsProps) {
  const { content } = props
  const isBeta = useSelector(currentUserSelector)?.roles?.isBeta
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const intl = useIntl()
  const clipboard = React.useRef(null)
  const getClipboardText = () => content.status?.trim() || ''

  const setRef = (element: HTMLElement | null) => {
    if (element) {
      if (clipboard.current) {
        (clipboard.current as any).destroy()
      }

      clipboard.current = new Clipboard(
        element,
        {
          text: getClipboardText,
          container: element
        }
      )
      ; (clipboard.current as any).on('error', (e: any) => {
        console.log('Clipboard error', e)
      })
    }
  }

  const feedType = content.feed.type
  const isPhoto = content.type === PHOTO_TYPE || content.type === STOCK_PHOTO_TYPE
  const isArticle = content.type === ARTICLE_TYPE
  const isText = content.type === TEXT_TYPE

  const isContentBlocked = React.useCallback((aiWriter: boolean) => {
    if (props.skipFeatureChecks) {
      return false
    }

    // FB feeds are accessible for all plans
    const shouldCheckFeed = ![FEED_TYPE_FACEBOOK, FEED_TYPE_INSTAGRAM, FEED_TYPE_KEYWORD].includes(feedType)

    // Skip feed type check for Stock content
    if (!content.isStock && shouldCheckFeed && !checkFeatureAvailability(feedTypeFeatureMap[feedType])) {
      return true
    }

    if (!checkFeatureAvailability(contentTypeFeatureMap[content.type])) {
      return true
    }

    const contentAgeFeature = getContentAgeFeature(content.createdAt)

    if (!content.isStock && contentAgeFeature && !checkFeatureAvailability(contentAgeFeature)) {
      return true
    }

    if (content.isFavorite && !checkFeatureAvailability(FEATURE_FIND_FAVORITES)) {
      return true
    }

    const isBlockedFromParent = props.isContentBlocked(aiWriter)

    if (isBlockedFromParent) {
      return true
    }
    return false
  }, [
    feedType,
    props.skipFeatureChecks,
    content.type,
    props.isContentBlocked,
    content.isStock,
    content.isFavorite,
    content.createdAt
  ])

  const onCreatePost = (e: React.MouseEvent) => {
    e.stopPropagation()
    goToComposer()
  }

  const goToComposer = (aiWriter?: boolean) => {
    if (isContentBlocked(Boolean(aiWriter))) {
      return
    }

    if (content.type === TEXT_TYPE && !aiWriter) {
      dispatch(message(intl.formatMessage({ id: 'notifications.status-copied' }), 'info'))
    }

    const disableOpenComposer = props.onCreatePost(aiWriter) || location.pathname.startsWith('/composer')
    if (!disableOpenComposer) {
      if (aiWriter) {
        const path = location.pathname === '/composer' ? '/composer/ai' : location.pathname + `?view=ai`
        navigate(
          path,
          {
            state: {
              text: isArticle ? (content as Article).sourceLink : isText ? content.status || '' : undefined,
              imageUrl: isPhoto ? content.imageUrl : undefined,
              opener: location.pathname,
              background: location
            }
          })
      } else {
        navigate(
          '/composer',
          {
            state: {
              floating: true,
              modal: 'compose',
              opener: location.pathname + location.search,
              background: location
            }
          })
      }
    }
  }

  const openStudio = () => {
    if (isContentBlocked(false)) {
      return
    }
    navigate('/content/studio', { state: { imageUrl: content.imageUrl } })
  }

  const openAIWriter = () => {
    goToComposer(true)
  }

  const showSelectButton = content.type !== TEXT_TYPE && (!props.actions || props.actions.includes('select'))
  const showStudioButton = !props.actions || props.actions.includes('open-studio')
  const isCreatePostPrimary = Boolean(props.studioButtonIcon || !showSelectButton)

  return (
    <div className={`${styles.actions} ${props.className}`} data-testid="content-card-actions">
      {content.feed.id && props.isFavButtonVisible && (
        <FavButton
          isFavorite={props.isFavorite}
          count={props.favCount}
          hover={false}
          className={`${styles.btn} ${styles.secondary}`}
          onFavorite={props.onAddFavorite}
          onUnfavorite={props.onRemoveFavorite}
        />
      )}

      {showSelectButton && (
        <React.Fragment>
          {props.isSelected ? (
            <Tooltip
              title={<FormattedMessage id="content-card.actions.deselect" />}
              placement="left"
            >
              <IconButton
                size="small"
                className={styles.btn}
                color="primary"
                onClick={props.onDeselected}
              >
                <IconCheck />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title={<FormattedMessage id="content-card.actions.add-content" />}
              placement="left"
            >
              <IconButton
                size="small"
                className={styles.btn}
                data-btn-toggle
                color="primary"
                onClick={props.onSelected}
              >
                <IconAdd />
              </IconButton>
            </Tooltip>
          )}
        </React.Fragment>
      )}
      <Tooltip
        title={props.createPostLabel || <FormattedMessage id="content-card.actions.create-post" />}
        placement="left"
      >
        <IconButton
          size="small"
          className={`${styles.btn} ${!isCreatePostPrimary ? styles.secondary : ''}`}
          data-btn-toggle
          color={isCreatePostPrimary ? 'primary' : 'default'}
          ref={setRef}
          onClick={onCreatePost}
        >
          {props.studioButtonIcon || <FeatherIcon className={styles['icon-post']} />}
        </IconButton>
      </Tooltip>
      {/* TEMP: Hide AI btn {isBeta && (isPhoto || isArticle || isText) && (
        <Tooltip title={<FormattedMessage id="content-card.actions.ai" />} placement="left">
          <IconButton
            size="small"
            data-btn-toggle
            data-ui-dark
            className={`${styles.btn} ${styles.secondary}`}
            onClick={openAIWriter}
          >
            <Icon path={mdiAutoFix} />
          </IconButton>
        </Tooltip>
      )} */}
      {isPhoto && showStudioButton && (
        <Tooltip title={<FormattedMessage id="content-card.actions.edit-image" />} placement="left">
          <IconButton
            size="small"
            data-btn-toggle
            data-ui-dark
            className={`${styles.btn} ${styles.secondary}`}
            onClick={openStudio}
          >
            <PaletteIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}

export default ContentCardActions
