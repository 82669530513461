import * as React from 'react'
import { createPortal } from 'react-dom'
import {
  Bucket,
  ContentType,
  IndexedObject,
  PlannedPost,
  PostBase,
  PostDestination,
  PostDestinationType,
  PostedPost,
  sortNetworksDefault,
  WithIntl
} from 'interfaces'
import { POST_TYPES } from 'interfaces/Content/ContentType'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { connectedDestinationsSelector } from 'services/destinations'
import styles from './BucketPostsView.pcss'
import { Subject } from 'rxjs/Subject'
import { tap } from 'rxjs/operators/tap'
import { StoreThunkDispatch } from 'store/state'
import {
  bulkDeletePosts,
  getBucketPosts,
  getBuckets,
  getPostById,
  getPostedPosts,
  recyclePosts,
  reorderPosts,
  requeuePost,
  shufflePosts,
  updatePostRecycle
} from 'services/post/actions'
import { Observable } from 'rxjs/Observable'
import { catchError } from 'rxjs/operators/catchError'
import { message } from 'services/snackbar'
import SimpleLoader from 'components/SimpleLoader'
import ScrollListener from 'components/ScrollListener'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import PlannedPostsList from 'routes/publishing/routes/posts/components/PlannedPostsList'
import {
  FEATURE_POST_COPY,
  FEATURE_POST_INSIGHTS_ACTIONS_REPLAN,
  FEATURE_POST_INSIGHTS_ACTIONS_SORT,
  FEATURE_POST_RECYCLE,
  FEATURE_POST_SHUFFLE
} from 'shared/constants'
import { checkFeatureAvailability } from 'services/product'
import { getDerivedStateFromPost, mergeState, resetComposer, setComposerBucket, setComposerProfiles } from 'services/compose'
import { sortAscending } from 'utils/sort/sortByKey'
import { exhaustMap } from 'rxjs/operators/exhaustMap'
import { useEffectUpdateOnly } from 'hooks/useEffectUpdateOnly'
import { PostsAction, postsReducer } from 'routes/publishing/routes/posts/reducer'
import { addPosts, recyclePostsSuccess, replacePost, setPosts, updateRecyclePost } from 'routes/publishing/routes/posts/actions'
import PostFilesDialog from 'components/Posts/PostFilesDialog'
import CopyPostsPopup from 'routes/publishing/components/CopyPostsPopup'
import PublishingTopBar from 'routes/publishing/routes/posts/components/PublishingTopBar'
import { filter } from 'rxjs/operators/filter'
import PostPreviewWithInfo from 'routes/publishing/routes/posts/components/PostPreviewWithInfo'
import { newPostSelector } from 'services/post/selectors'
import SidebarProfileSelector from 'components/App/components/AppNavigationSidebar/publishing/SidebarProfileSelector'
import PostedPostsList from 'routes/publishing/routes/posts/components/PostedPostsList'
import { sortByKeyAlphabetically } from 'utils/sort/order'
import BucketUpdatePopup from 'routes/publishing/routes/buckets/components/BucketUpdatePopup'
import EmptyView from 'components/EmptyView'
import { mdiPail } from '@mdi/js'
import { NOTIFICATION_DURATION_LONG } from 'components/ConnectedSnackbar'
import PostedSortMenu, { PostedSortBy, SortDirection } from 'routes/publishing/components/BulkActionsMenus/PostedSortMenu'
import { publishPosts } from 'services/post/shareNow/actions'
import { HelpToggleButton } from 'components/App/components/HelpToggleButton'
import { RecycleDialog } from 'components/Posts/components/RecycleSelector'
import { BucketCard } from 'components/Buckets'
import { groupByKey } from 'shared'
import SocialIcon from 'components/SocialIcon'
import { BucketProfile } from './BucketProfile'
import { format } from 'date-fns'
import { initPlans } from 'services/plan/actions'

const SCROLL_TRESHOLD = 350

interface BucketPostsViewProps {
  bucket: Bucket
  activeView: 'planned' | 'posted'
  onBucketPostsUpdated: () => void
}

export function BucketPostsView(props: BucketPostsViewProps & WithIntl) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const location = useLocation()
  const navigate = useNavigate()
  const connectedProfiles = useSelector(connectedDestinationsSelector)
  const [selectedProfilePpid, setSelectedProfilePpid] = React.useState<string | undefined>(props.bucket.ppids[0])
  const bucketPpidsString = props.bucket.ppids.join(',')
  // const [page, setPage] = React.useState(0)
  const [refreshKey, setRefreshKey] = React.useState(0)
  // const [hasNext, setHasNext] = React.useState(true)
  const [postInPreview, setPostInPreview] = React.useState<PlannedPost | null>(null)
  const [withPreview, setWithPreview] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [loadingOverlayActive, setLoadingOverlayActive] = React.useState(false)
  const [posts, postsDispatch] = React.useReducer<React.Reducer<PlannedPost[], PostsAction>>(postsReducer, [])
  const [selectedPostsIds, setSelectedPostsIds] = React.useState<IndexedObject<boolean> | 'all'>({})
  const [postsToCopy, setPostsToCopy] = React.useState<PlannedPost[]>([])
  const [postInMediaPreviewDialog, setPostInMediaPreviewDialog] = React.useState<PostBase | null>(null)
  const [totalPosts, setTotalPosts] = React.useState(0)
  const [updatePopupOpen, setUpdatePopupOpen] = React.useState(false)
  const [search, setSearch] = React.useState<{ query: string, types: ContentType[], searchInSummary: boolean }>(
    { query: '', types: POST_TYPES, searchInSummary: true }
  )
  const [sidebarActiveView, setSidebarActiveView] = React.useState<'grid' | 'list' | 'network'>('grid')
  const newPostTimestamp = useSelector(newPostSelector)
  const [sortBy, setSortBy] = React.useState<PostedSortBy>(PostedSortBy.Time)
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('descending')
  const [isEmptyViewClosed, setIsEmptyViewClosed] = React.useState(false)
  const [recycleDialogOpen, setRecycleDialogOpen] = React.useState(false)

  const requeue$ = React.useRef<Subject<{ postIds: string[], ppids: string[], all: boolean, query?: string, types?: ContentType[] }>>()
  const pageRef = React.useRef(0)
  const hasNextRef = React.useRef(true)
  const ppidRef = React.useRef('')
  const delete$ = React.useRef<Subject<{ postIds: string[], ppids: string[], all: boolean, query?: string, types?: ContentType[] }>>()
  const selectionRef = React.useRef<{ postIds: string[], profileIds: string[], ppids: string[], all: boolean }>()
  const fetch$ = React.useRef<Subject<{
    type: 'planned' | 'posted',
    query?: string,
    types?: ContentType[],
    sortBy?: PostedSortBy,
    sortDirection?: SortDirection,
    searchInSummary?: boolean
  }>>()
  const shuffle$ = React.useRef<Subject<{ postIds: string[], ppids: string[], all?: boolean }>>()
  const reorder$ = React.useRef<Subject<{ ids: string[], moveToBottom?: boolean }>>()
  const recycle$ = React.useRef<Subject<{ postId: string, value: boolean | Date | number }>>()
  const share$ = React.useRef<Subject<{
    postIds: string[],
    ppids: string[],
    bucketId?: string
  }>>()
  const batchRecycle$ = React.useRef<Subject<{
    postIds: string[], ppids: string[], all?: boolean, query?: string, types?: ContentType[], value?: boolean | Date | number
  }>>()

  React.useEffect(() => {
    dispatch(initPlans())
  }, [dispatch])

  const closeEmptyView = () => {
    setIsEmptyViewClosed(true)
  }

  React.useEffect(() => {
    ppidRef.current = selectedProfilePpid || ''
  }, [selectedProfilePpid])

  React.useEffect(() => {
    if (!props.bucket.ppids.includes(selectedProfilePpid as string)) {
      setSelectedProfilePpid(props.bucket.ppids[0])
    }
    setWithPreview(false)
    setPostInPreview(null)
  }, [props.bucket.id, props.bucket.ppids, selectedProfilePpid])

  React.useEffect(() => {
    if (location.state?.updatedPostId) {
      dispatch(getPostById(location.state?.updatedPostId))
        .unwrap()
        .then((post: any) => {
          postsDispatch(replacePost(post))
        })
        .catch(refreshPosts)
    }
    if (location.state?.search) {
      setSearch(location.state.search)
    }
    if (location.state?.ppid) {
      setSelectedProfilePpid(location.state.ppid)
    }
  }, [location.state?.search, location.state?.refresh, location.state?.ppid, location.state?.updatedPostId, dispatch])

  const previewSelection = React.useMemo(() => {
    const selectedPostIndex = postInPreview
      ? posts.findIndex((p: any) => p.id === postInPreview.id)
      : -1
    let hasNext = false
    let hasPrev = false

    if (selectedPostIndex !== -1) {
      hasNext = selectedPostIndex < posts.length - 1
      hasPrev = selectedPostIndex >= 1
    }
    return {
      selectedPostIndex,
      hasNext,
      hasPrev
    }
  }, [postInPreview, posts])

  // Update posts bucket info
  React.useEffect(() => {
    postsDispatch(setPosts<PlannedPost>(posts.map((p: PlannedPost) => ({ ...p, bucket: props.bucket }))))
    setPostInPreview(current => {
      if (!current) {
        return null
      }
      return { ...current, bucket: props.bucket }
    })
  }, [props.bucket.name, props.bucket.color, posts.length])

  React.useEffect(() => {
    share$.current = new Subject()
    share$.current
      .pipe(tap(() => {
        setLoadingOverlayActive(true)
      }))
      .flatMap(({ ppids, postIds, bucketId }) => {
        return dispatch(publishPosts(postIds, ppids, 'planned', false, bucketId))
          .pipe(catchError((error: Error) => {
            dispatch(message(props.intl.formatMessage({ id: 'notifications.error-w-message' }, { error: error.message }), 'error'))
            return Observable.of({ error })
          }))
      })
      .subscribe((response: any) => {
        if (!response.error) {
          setSelectedPostsIds({})
          dispatch(message(props.intl.formatMessage({ id: 'notifications.post-posted' }), 'success'))
          setRefreshKey(current => current + 1)
        }
        setLoadingOverlayActive(false)
      })

    return () => share$.current?.unsubscribe()
  }, [dispatch, props.intl])

  const onShareNow = React.useCallback((post: PlannedPost) => {
    share$.current?.next({ postIds: [post.id], ppids: [post.ppPageId], bucketId: post.bucketId })
  }, [])

  const onPostClick = React.useCallback((post: PlannedPost) => {
    setWithPreview(true)
    setPostInPreview(post)
  }, [])

  const selectNextPostForPreview = React.useCallback(() => {
    if (previewSelection.hasNext) {
      const next = posts[previewSelection.selectedPostIndex + 1]
      setPostInPreview(next)
    }
  }, [previewSelection, posts])

  const selectPrevPostForPreview = React.useCallback(() => {
    if (previewSelection.hasPrev) {
      const prev = posts[previewSelection.selectedPostIndex - 1]
      setPostInPreview(prev)
    }
  }, [previewSelection, posts])

  React.useEffect(() => {
    if (props.activeView === 'posted') {
      setWithPreview(false)
      setPostInPreview(null)
      setSearch({ query: '', types: POST_TYPES, searchInSummary: true })
    }
  }, [props.activeView])

  React.useEffect(() => {
    setWithPreview(false)
    setPostInPreview(null)
  }, [selectedProfilePpid])

  React.useEffect(() => {
    fetch$.current = new Subject()
    fetch$.current
      .pipe(
        filter(() => hasNextRef.current && Boolean(ppidRef.current)),
        tap(() => {
          setLoading(true)
        })
      )
      .flatMap((data) => {
        if (data.type === 'posted') {
          const sortString = data.sortBy ? PostedSortBy[data.sortBy].toLowerCase() : undefined
          return dispatch(getPostedPosts({
            page: pageRef.current,
            pageIds: [],
            sortBy: sortString,
            ppids: [ppidRef.current],
            bucketId: props.bucket.id,
            query: data.query,
            types: data.types,
            sortDirection: data.sortDirection,
            searchInArticleSummary: data.searchInSummary
          }))
        }
        return dispatch(getBucketPosts(props.bucket.id, pageRef.current, ppidRef.current, data.query, data.types, data.searchInSummary))
          .pipe(catchError((e) => {
            return Observable.of({ posts: [], error: e })
          }))
      })
      .subscribe((response: any) => {
        setLoading(false)
        setLoadingOverlayActive(false)
        setIsEmptyViewClosed(false)
        if (response.error) {
          dispatch(message(props.intl.formatMessage({ id: 'post.notifications.bucket-posts-fetch-failed' }), 'error'))
          hasNextRef.current = false
          return
        }
        hasNextRef.current = response.paging.pages > response.page + 1
        setTotalPosts(response.totalPostsCount)
        props.onBucketPostsUpdated()
        const withVisibleIndex = response.posts.map((p: PlannedPost, index: number) => ({ ...p, visibleIndex: index }))
        if (response.page === 0) {
          postsDispatch(setPosts(withVisibleIndex))
        } else {
          postsDispatch(addPosts(response.posts))
        }
      })

    return () => {
      fetch$.current?.unsubscribe()
    }
  }, [dispatch, props.bucket.id, props.intl])

  // Check for newly created posts. Reload posts to fetch the new records
  useEffectUpdateOnly(() => {
    if (location.state?.refresh && props.activeView === 'planned' && !location.state?.updatedPostId) {
      hasNextRef.current = true
      fetch$.current?.next({ type: props.activeView })
    }
  }, [location.state?.refresh, props.activeView])

  React.useEffect(() => {
    if (newPostTimestamp) {
      setRefreshKey(current => current + 1)
    }
  }, [newPostTimestamp])

  React.useEffect(() => {
    if (!props.bucket.ppids.includes(selectedProfilePpid as any)) {
      setSelectedProfilePpid(props.bucket.ppids[0])
    }
  }, [props.bucket.ppids, selectedProfilePpid])

  React.useEffect(() => {
    if (bucketPpidsString.split(',').includes(selectedProfilePpid as any)) {
      pageRef.current = 0
      hasNextRef.current = true
      postsDispatch(setPosts([]))
      fetch$.current?.next({
        type: props.activeView,
        query: search.query,
        types: search.types,
        sortBy,
        sortDirection,
        searchInSummary: search.searchInSummary
      })
    }
  }, [props.bucket.id, selectedProfilePpid, refreshKey, props.activeView, bucketPpidsString,
    search.query, search.searchInSummary, search.types, sortBy, sortDirection])

  React.useEffect(() => {
    delete$.current = new Subject()
    delete$.current
      .pipe(tap((data) => {
        dispatch(message(props.intl.formatMessage({ id: 'post.notifications.deleting-posts' })))
        setLoadingOverlayActive(true)
        setPostInPreview(current => {
          if (data.postIds.includes(current?.id as any)) {
            return null
          }
          return current
        })
      }))
      .flatMap(({ ppids, postIds, all, query, types }) => {
        return dispatch(bulkDeletePosts(postIds, ppids, 'queued', all, props.bucket.id, query, types))
          .pipe(catchError((error: Error) => {
            dispatch(message(props.intl.formatMessage({ id: 'post.notifications.delete-posts-error' }, { error: error.message }), 'error'))
            return Observable.of({ error })
          }))
      })
      .subscribe((response: any) => {
        if (!response.error) {
          setSelectedPostsIds({})
          setRefreshKey(current => current + 1)
        }
        setLoadingOverlayActive(false)
      })

    return () => delete$.current?.unsubscribe()
  }, [props.bucket.id, props.activeView, dispatch, props.intl])

  React.useEffect(() => {
    shuffle$.current = new Subject()
    shuffle$.current.pipe(tap(() => {
      setLoadingOverlayActive(true)
      dispatch(message(props.intl.formatMessage({ id: 'post.notifications.shuffling-posts' })))
    }))
      .flatMap(({ postIds, ppids, all }) => dispatch(shufflePosts(postIds, ppids, 'planned', all, props.bucket.id)).pipe(catchError(() => {
        return Observable.of({ error: true })
      })))
      .subscribe(response => {
        setLoadingOverlayActive(false)
        if (!response.error) {
          setSelectedPostsIds({})
          setRefreshKey(current => current + 1)
        }
      })

    return () => shuffle$.current?.unsubscribe()
  }, [dispatch, props.bucket.id, props.intl])

  React.useEffect(() => {
    reorder$.current = new Subject()
    reorder$.current.pipe(
      tap(() => {
        setLoadingOverlayActive(true)
      }),
      exhaustMap(({ ids, moveToBottom }) => dispatch(reorderPosts(ids, moveToBottom)).pipe(catchError(() => {
        dispatch(message(props.intl.formatMessage({ id: 'post.notifications.update-failed' }), 'error'))
        return Observable.of({ error: true })
      })))
    )
      .subscribe(() => {
        setRefreshKey(current => current + 1)
      })

    return () => reorder$.current?.unsubscribe()
  }, [])

  React.useEffect(() => {
    recycle$.current = new Subject()
    recycle$.current
      .flatMap(({ postId, value }) => dispatch(updatePostRecycle(postId, value))
        .pipe(catchError((e) => {
          const msg = e.response?.error.message || props.intl.formatMessage({ id: 'errors.generic' })
          dispatch(message(msg, 'error'))
          return Observable.of({ error: true })
        }))
      )
      .subscribe(response => {
        if (!response.error) {
          setSelectedPostsIds({})
        }
        postsDispatch(updateRecyclePost(response.postId, response.value))
      })

    return () => recycle$.current?.unsubscribe()
  }, [])

  React.useEffect(() => {
    const profile = Object.values(connectedProfiles).find(p => p.ppid === selectedProfilePpid)
    if (!selectedProfilePpid || !profile) {
      return
    }

    if (selectedPostsIds === 'all') {
      selectionRef.current = {
        all: true,
        postIds: posts.map(p => p.id),
        ppids: [selectedProfilePpid],
        profileIds: [profile.idNew]
      }
    } else {
      selectionRef.current = {
        all: false,
        ppids: [selectedProfilePpid],
        postIds: Object.keys(selectedPostsIds).filter(id => selectedPostsIds[id]),
        profileIds: [profile.idNew]
      }
    }
  }, [selectedPostsIds, posts, connectedProfiles, selectedProfilePpid])

  React.useEffect(() => {
    requeue$.current = new Subject()
    requeue$.current.concatMap(({ postIds, ppids, all, query, types }) => {
      return dispatch(requeuePost(postIds, ppids, all, true, props.bucket.id, query, types))
        .pipe(catchError((error) => {
          if (error.message) {
            dispatch(message(error.message, 'error', NOTIFICATION_DURATION_LONG))
          } else {
            dispatch(message(props.intl.formatMessage({ id: 'errors.generic' }), 'error'))
          }
          return Observable.of({ error })
        }))
    })
      .subscribe((response) => {
        if (!response.error) {
          dispatch(message(props.intl.formatMessage({ id: 'notifications.post-replanned' }), 'success'))
        }
      })

    return () => requeue$.current?.unsubscribe()
  }, [dispatch, props.bucket.id, props.intl])

  React.useEffect(() => {
    batchRecycle$.current = new Subject()
    batchRecycle$.current.pipe(tap(() => {
      dispatch(message(props.intl.formatMessage({ id: 'post.notifications.recycling-posts' })))
    }))
      .flatMap(({ ppids, postIds, all, query, types, value }) => {
        return dispatch(recyclePosts(postIds, ppids, 'planned', all, props.bucket.id, query, types, value))
          .pipe(catchError((e) => {
            const msg = e.response?.error.message || props.intl.formatMessage({ id: 'errors.generic' })
            dispatch(message(msg, 'error'))
            return Observable.of({ error: true })
          }))
      })
      .subscribe(response => {
        if (!response.error) {
          setSelectedPostsIds({})
          postsDispatch(recyclePostsSuccess(response.postIds, response.all))
          for (const postId of response.postIds) {
            postsDispatch(updateRecyclePost(postId, response.recycle))
          }
        }
      })

    return () => batchRecycle$.current?.unsubscribe()
  }, [dispatch, props.bucket.id, props.intl])

  const loadNextPage = React.useCallback(() => {
    if (hasNextRef.current && !loading) {
      pageRef.current += 1
      fetch$.current?.next({
        type: props.activeView,
        sortBy,
        sortDirection,
        ...search
      })
    }
  }, [props.activeView, loading, search, sortBy, sortDirection])

  const onDeletePost = React.useCallback((post: string | PlannedPost) => {
    const id = typeof post === 'string' ? post : post.id
    if (selectedProfilePpid) {
      delete$.current?.next({
        postIds: [id],
        ppids: [selectedProfilePpid],
        all: false,
        query: search.query,
        types: search.types
      })
    }
  }, [selectedProfilePpid, search.query, search.types])

  const profiles = React.useMemo(() => {
    return Object.values(connectedProfiles).filter(profile => props.bucket.ppids.includes(profile.ppid))
  }, [connectedProfiles, props.bucket.ppids])

  const onProfileClick = React.useCallback((ppid: string) => {
    setSelectedProfilePpid(ppid)
  }, [])

  const toggleAllPostsSelected = React.useCallback(() => {
    setSelectedPostsIds(current => current === 'all' ? {} : 'all')
  }, [])

  const deleteSelectedPosts = React.useCallback((forAllProfiles: boolean) => {
    delete$.current?.next({
      ...(selectionRef.current as any),
      ppids: forAllProfiles ? props.bucket.ppids : selectionRef.current?.ppids,
      query: search.query,
      types: search.types
    })
  }, [search.query, search.types, props.bucket.ppids])

  const onShuffle = React.useCallback((applyToAllProfiles?: boolean) => {
    const shuffleAvailable = dispatch(checkFeatureAvailability(FEATURE_POST_SHUFFLE))
    if (!shuffleAvailable) {
      return
    }

    const selection = selectionRef.current
    if (selection && (selection.all || selection.postIds.length > 0)) {
      const ppids = applyToAllProfiles ? bucketPpidsString.split(',') : selection.ppids
      shuffle$.current?.next({ ...selection, ppids })
    }
  }, [dispatch, bucketPpidsString])

  const onCopyPosts = React.useCallback(() => {
    const copyAllowed = dispatch(checkFeatureAvailability(FEATURE_POST_COPY))
    if (copyAllowed) {
      if (selectionRef.current?.all) {
        setPostsToCopy(posts)
      } else if (selectionRef.current?.postIds) {
        setPostsToCopy(posts.filter(p => selectionRef.current?.postIds.includes(p.id)))
      }
    }
  }, [posts])

  const togglePostSelected = React.useCallback((id: string) => {
    setSelectedPostsIds(current => {
      if (current === 'all') {
        return posts.reduce((selection: IndexedObject<boolean>, post) => {
          selection[post.id] = post.id !== id
          return selection
        }, {})
      }
      return { ...current, [id]: !current[id] }
    })
  }, [posts])

  const movePost = React.useCallback((id: string, position: 'top' | 'bottom') => {
    const autoPosts = posts.reduce((autoPosts: any[], p: any) => {
      if (p.id !== id) {
        autoPosts.push(p)
      }
      return autoPosts
    }, [])
    const ids: string[] = autoPosts.sort(sortAscending('autoPostOrder')).map(p => p.id)
    if (position === 'top') {
      ids.unshift(id)
    } else {
      ids.push(id)
    }
    reorder$.current?.next({ ids, moveToBottom: position === 'bottom' })
  }, [posts])

  const onReorderPosts = React.useCallback((movedPost: PlannedPost, targetPost: PlannedPost) => {
    const sortedPosts = posts.sort(sortAscending('autoPostOrder')) as PlannedPost[]
    const currentIndex = sortedPosts.findIndex(p => p.id === movedPost.id)
    const nextIndex = sortedPosts.findIndex(p => p.id === targetPost.id)

    const ids: string[] = []
    const orderedPosts: PlannedPost[] = []
    sortedPosts.splice(currentIndex, 1)
    sortedPosts.splice(nextIndex, 0, movedPost)
    sortedPosts.forEach((p, i) => {
      p.autoPostOrder = i
      orderedPosts.push(p)
      if (!p.isEmpty) {
        ids.push(p.id)
      }
    })

    reorder$.current?.next({ ids })
  }, [posts])

  const loadPostInComposer = React.useCallback((post: PlannedPost | PostedPost, type?: 'planned' | 'posted') => {
    dispatch(resetComposer())
    dispatch(mergeState(getDerivedStateFromPost(post, type)))
    navigate('/composer', { state: { floating: true, modal: 'compose', opener: location.pathname, search, background: location } })
  }, [dispatch, location, navigate, search])

  const onCopyPost = React.useCallback((post: PlannedPost | PostedPost) => {
    const copyAllowed = dispatch(checkFeatureAvailability(FEATURE_POST_COPY))
    if (copyAllowed) {
      setPostsToCopy([post as any])
    }
  }, [])

  const onRecycleChange = React.useCallback((id: string, value: number | boolean | Date) => {
    const recycleAvailable = dispatch(checkFeatureAvailability(FEATURE_POST_RECYCLE))
    if (!recycleAvailable) {
      return
    }
    recycle$.current?.next({ postId: id, value })
  }, [])

  const scrollElement = React.useMemo(() => {
    return document.querySelector('[data-test="main"]') as HTMLElement
  }, [])

  const closePostMediaPreviewDialog = React.useCallback(() => {
    setPostInMediaPreviewDialog(null)
  }, [])

  const closeCopyPostsPopup = React.useCallback((updated?: boolean) => {
    setPostsToCopy([])
    setSelectedPostsIds({})
    if (updated) {
      dispatch(getBuckets(true))
    }
  }, [dispatch])

  const onFilter = React.useCallback((query: string, types: ContentType[], searchInSummary: boolean) => {
    setSearch({ query, types, searchInSummary })
  }, [])

  const togglePreview = () => {
    setWithPreview(current => !current)
  }

  React.useEffect(() => {
    if (withPreview) {
      const firstNonEmptyPost = posts.find(p => !p.isEmpty)

      setPostInPreview(current => {
        const postInPreview = current ? posts.find(p => p.id === current.id) : null
        if (!postInPreview && firstNonEmptyPost) {
          return firstNonEmptyPost
        }
        if (!firstNonEmptyPost) {
          return null
        }
        return postInPreview || null
      })
    } else {
      setPostInPreview(null)
    }
  }, [withPreview, posts])

  const onSidebarSelectionChange = React.useCallback((ids: string[]) => {
    const ppid = profiles.find(p => p.id === ids[0])?.ppid
    if (ppid) {
      setSelectedProfilePpid(ppid)
    }
  }, [profiles])

  const closeBucketUpdatePopup = (refresh?: boolean) => {
    setUpdatePopupOpen(false)
    if (refresh) {
      setRefreshKey(refreshKey + 1)
    }
  }

  const openBucketUpdatePopup = () => {
    setUpdatePopupOpen(true)
  }

  const refreshPosts = () => {
    setRefreshKey(refreshKey + 1)
  }

  const sidebarContainer = React.useMemo(() => document.querySelector('[data-buckets-nav]'), [])

  const sidebarProfileSelector = React.useMemo(() => {
    if (!selectedProfilePpid) {
      return null
    }

    const profilesMap = profiles.reduce((map: any, p) => {
      map[p.ppid] = p
      return map
    }, {})

    return (
      <div className={styles['sidebar-panel']}>
        <div className={styles['sidebar-title']}>
          <FormattedMessage id="general.labels.profiles" />
        </div>
        <SidebarProfileSelector
          profiles={profilesMap}
          activeView={sidebarActiveView}
          selectedProfileIds={[profilesMap[selectedProfilePpid]?.id].filter(Boolean)}
          setActiveView={setSidebarActiveView}
          onSelectedProfilesChange={onSidebarSelectionChange}
        />
      </div>
    )
  }, [profiles, selectedProfilePpid, onSidebarSelectionChange, sidebarActiveView])

  const onRepost = React.useCallback((post: PostedPost) => {
    loadPostInComposer(post, 'posted')
  }, [loadPostInComposer])

  const onRequeue = React.useCallback((id: string, ppid: string) => {
    const requeueAvailable = dispatch(checkFeatureAvailability(FEATURE_POST_INSIGHTS_ACTIONS_REPLAN))
    if (!requeueAvailable) {
      return
    }

    requeue$.current?.next({ postIds: [id], ppids: [ppid], all: false })
  }, [])

  const requeuePosts = React.useCallback(() => {
    const requeueAvailable = dispatch(checkFeatureAvailability(FEATURE_POST_INSIGHTS_ACTIONS_REPLAN))
    if (!requeueAvailable || selectionRef.current?.postIds.length === 0) {
      return
    }
    requeue$.current?.next({
      postIds: selectionRef.current?.postIds || [],
      ppids: selectionRef.current?.ppids || [],
      all: Boolean(selectionRef.current?.all),
      query: search.query,
      types: search.types
    })
  }, [dispatch, search.query, search.types])

  const bucketProfiles = React.useMemo(() => {
    const groupedVisibleProfiles = profiles.reduce((map: IndexedObject<PostDestination[]>, profile: PostDestination) => {
      if (map[profile.type]) {
        map[profile.type].push(profile)
      } else {
        map[profile.type] = [profile]
      }
      return map
    }, {})
    const groupedVisibleProfilesArray: PostDestination[] = []
    Object.keys(groupedVisibleProfiles).sort(sortNetworksDefault).forEach(network => {
      groupedVisibleProfilesArray.push(...groupedVisibleProfiles[network].sort(sortByKeyAlphabetically('name')))
    })
    return groupedVisibleProfilesArray
  }, [profiles])

  const selectedProfile = bucketProfiles.find(p => p.ppid === selectedProfilePpid)

  const selectedPostsCount = React.useMemo(() => {
    return selectedPostsIds !== 'all'
      ? Object.values(selectedPostsIds).filter(Boolean).length
      : totalPosts
  }, [totalPosts, selectedPostsIds])

  const onSortChange = React.useCallback((value: PostedSortBy) => {
    const sortAvailable = dispatch(checkFeatureAvailability(FEATURE_POST_INSIGHTS_ACTIONS_SORT))
    if (!sortAvailable) {
      return
    }

    // If changing the sort key, reset page to 0
    if (value !== sortBy) {
      setSortBy(value)
    }
  }, [sortBy, dispatch])

  const activeProfile = Object.values(connectedProfiles).find(p => p.ppid === selectedProfilePpid)
  const sortMenuDisabled = posts.length === 0 || loading

  const onOpenComposer = () => {
    dispatch(setComposerBucket(props.bucket.id))
    dispatch(setComposerProfiles(profiles))
  }

  const onBulkRecycle = React.useCallback(() => {
    setRecycleDialogOpen(true)
  }, [])

  const closeRecycleDialog = () => {
    setRecycleDialogOpen(false)
  }

  const bulkRecyclePosts = (value: boolean | Date | number) => {
    batchRecycle$.current?.next({
      ...(selectionRef.current as any),
      query: search.query,
      types: search.types,
      value
    })

    closeRecycleDialog()
  }

  const bucketActivePeriod = React.useMemo(() => {
    if (!props.bucket.activeFrom || !props.bucket.activeTo) {
      return 'always'
    }
    return `${format(props.bucket.activeFrom, 'LLL d, yyyy')} to ${format(props.bucket.activeTo, 'LLL d, yyyy')}`
  }, [props.bucket.activeFrom, props.bucket.activeTo])

  const selectedProfileActiveSlotsCount = React.useMemo(() => {
    return props.bucket.pages.find(p => p.ppid === selectedProfilePpid)?.slotsCount || 0
  }, [props.bucket.pages, selectedProfilePpid])

  const slotsCountByProfile = React.useMemo(() => {
    return props.bucket.pages.reduce((acc: Record<string, number>, p) => {
      acc[p.ppid] = p.slotsCount
      return acc
    }, {})
  }, [props.bucket.pages])

  const goToPlan = () => {
    navigate('/plans', { state: { bucketId: props.bucket.id } })
  }

  const postsPerProfile = props.bucket.pages.reduce((acc, p) => {
    acc[p.ppid] = p.plannedPostsCount
    return acc
  }, {} as Record<string, number>)

  const onCopyPostsFinished = () => {
    setRefreshKey(current => current + 1)
    closeCopyPostsPopup(true)
  }

  const bulkMoveToTop = React.useCallback(() => {
    if (selectedPostsIds === 'all') {
      setSelectedPostsIds({})
      return
    }
    const autoPosts = posts.reduce((autoPosts: any[], p: any) => {
      if (p.autoPost && !p.isEmpty && !p.isVirtual) {
        autoPosts.push(p)
      }
      return autoPosts
    }, [])

    let ids: string[] = autoPosts.sort(sortAscending('autoPostOrder')).map(p => p.id)
    const selectedIdsSorted = {} as Record<number, string>

    Object.keys(selectedPostsIds).forEach(id => {
      const index = ids.indexOf(id)
      if (index !== -1) {
        selectedIdsSorted[index] = id
      }
    })
    const selectedIdsSortedArray = Object.keys(selectedIdsSorted).map(Number).sort((a, b) => a - b).map(i => selectedIdsSorted[i])
    ids = ids.filter(id => !selectedPostsIds[id])
    ids.unshift(...selectedIdsSortedArray)

    reorder$.current?.next({ ids })
    setSelectedPostsIds({})
  }, [selectedPostsIds, posts])

  const bulkMoveToBottom = React.useCallback(() => {
    if (selectedPostsIds === 'all') {
      setSelectedPostsIds({})
      return
    }
    const autoPosts = posts.reduce((autoPosts: any[], p: any) => {
      if (p.autoPost && !p.isEmpty && !p.isVirtual) {
        autoPosts.push(p)
      }
      return autoPosts
    }, [])
    let ids: string[] = autoPosts.sort(sortAscending('autoPostOrder')).map(p => p.id)
    const selectedIdsSorted = {} as Record<number, string>
    Object.keys(selectedPostsIds).forEach(id => {
      const index = ids.indexOf(id)
      if (index !== -1) {
        selectedIdsSorted[index] = id
      }
    })
    const selectedIdsSortedArray = Object.keys(selectedIdsSorted).map(Number).sort((a, b) => a - b).map(i => selectedIdsSorted[i])
    ids = ids.filter(id => !selectedPostsIds[id])

    ids.push(...selectedIdsSortedArray)
    reorder$.current?.next({ ids })
    setSelectedPostsIds({})
  }, [selectedPostsIds, posts])

  const withBulkMove = selectedPostsIds !== 'all' && selectedPostsCount > 1 && selectedPostsCount < totalPosts

  return (
    <section>
      <PublishingTopBar
        totalCount={undefined}
        activeTypes={search.types}
        postsCountLabel={(
          <React.Fragment>
            <span className={styles.count}>
              <FormattedMessage id="post.buckets.label-total-posts" values={{ count: props.bucket.postsCount }} />
            </span>
            <span className={styles.count}>
              <FormattedMessage id="post.buckets.label-profile-posts" values={{ count: totalPosts }} />
            </span>
          </React.Fragment>
        )}
        children={(
          <React.Fragment>
            {props.activeView === 'planned' ? null : (
              <PostedSortMenu
                sortBy={sortBy}
                sortDirection={sortDirection}
                network={activeProfile?.type}
                disabled={sortMenuDisabled}
                className={styles['posted-sort-menu']}
                onSortChange={onSortChange}
                onSortDirectionChange={setSortDirection}
              />
            )}
            <HelpToggleButton />
          </React.Fragment>
        )}
        onFilter={onFilter}
        withPreviewToggle={props.activeView === 'planned'}
        showPreview={withPreview}
        onTogglePreview={togglePreview}
      />
      <header className={styles.header}>
        <div className={styles.grid}>
          <BucketCard bucket={props.bucket} noSelection onEdit={openBucketUpdatePopup} />
          <div className={styles.right}>
            <div className={styles['bucket-info']}>
              <span className={`${styles.label} text-ellipsis`}>
                <FormattedMessage
                  id="post.buckets.subtitle-status-period"
                  values={{
                    status: props.bucket.isExpired ? 'inactive' : 'active',
                    period: bucketActivePeriod,
                    s: (text: any) => (
                      <b onClick={openBucketUpdatePopup} className={styles[`label-${props.bucket.isExpired ? 'red' : 'green'}`]}>
                        {text}
                      </b>
                    ),
                    p: (text: any) => (
                      <b onClick={openBucketUpdatePopup} className={styles[`label-${props.bucket.isExpired ? 'red' : 'green'}`]}>
                        {text}
                      </b>
                    )
                  }}
                />
              </span>
              <span className={`${styles.label} text-ellipsis`}>
                <FormattedMessage
                  id="post.buckets.subtitle-profiles"
                  values={{
                    count: props.bucket.ppids.length,
                    b: (text: any) => <b onClick={openBucketUpdatePopup} className={styles['label-green']}>{text}</b>
                  }}
                />
              </span>
              <span className={`${styles.label} text-ellipsis`}>
                <FormattedMessage
                  id="post.buckets.subtitle-networks"
                  values={{
                    networks: () => (
                      <div className={styles.networks}>
                        {Object.keys(groupByKey(bucketProfiles, 'type')).map((network: PostDestinationType) => (
                          <SocialIcon icon={network} circle key={network} />
                        ))}
                      </div>
                    )
                  }}
                />
              </span>
              <span className={`${styles.label} text-ellipsis`}>
                <FormattedMessage
                  id="post.buckets.subtitle-total-posts"
                  values={{
                    count: props.bucket.postsCount,
                    b: (text: any) => (
                      <b>{text}</b>
                    )
                  }}
                />
              </span>
            </div>
            <div className={styles['profile-info']}>
              <div className={styles['profile-icon']}>
                {selectedProfile && (
                  <BucketProfile
                    key={selectedProfile.id}
                    profile={selectedProfile}
                    postsCount={totalPosts}
                    isSelected
                    postingTimesCount={selectedProfileActiveSlotsCount}
                    onClick={() => { }}
                  />
                )}
              </div>
              <div className={styles['profile-info-content']}>
                <span className={`${styles.label} text-ellipsis`}>
                  <FormattedMessage
                    id={selectedProfileActiveSlotsCount === 0 ? 'post.buckets.subtitle-slots-none' : 'post.buckets.subtitle-slots'}
                    values={{
                      count: selectedProfileActiveSlotsCount,
                      b: (text: any) => (
                        <b onClick={goToPlan} className={styles[`label-${selectedProfileActiveSlotsCount === 0 ? 'red' : 'green'}`]}>
                          {text}
                        </b>
                      )
                    }}
                  />
                </span>
                <span className={`${styles.label} text-ellipsis`}>
                  <FormattedMessage
                    id="post.buckets.subtitle-total-posts-profile"
                    values={{
                      count: totalPosts,
                      b: (text: any) => (
                        <b>{text}</b>
                      )
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['profiles-box']}>
          <div className={styles['profiles-list']}>
            {bucketProfiles.map(p => {
              const isSelected = p.ppid === selectedProfilePpid
              return (
                <BucketProfile
                  key={p.id}
                  profile={p}
                  postsCount={postsPerProfile[p.ppid] || 0}
                  isSelected={isSelected}
                  postingTimesCount={slotsCountByProfile[p.ppid]}
                  onClick={onProfileClick}
                />
              )
            })}
          </div>
        </div>
      </header>
      <ScrollListener emitTreshold={SCROLL_TRESHOLD} scrollElement={scrollElement} onScroll={loadNextPage}>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            {posts.length > 0 && props.activeView === 'planned' && (
              <PlannedPostsList
                posts={posts}
                selectedPosts={selectedPostsIds}
                bucketsView
                skipReorderChecks
                activePreviewPostId={postInPreview?.id}
                bulkActionsProps={{
                  hidePostActions: false,
                  withDelete: true,
                  withCopy: true,
                  withShuffle: true,
                  bucketMode: true,
                  withRecycle: true,
                  selectedCount: selectedPostsCount,
                  disabled: selectedPostsCount === 0,
                  allSelected: selectedPostsIds === 'all',
                  splitDeleteAll: true,
                  splitShuffleAll: true,
                  onToggleAllSelected: toggleAllPostsSelected,
                  onBulkDeletePosts: deleteSelectedPosts,
                  onShuffle,
                  onCopy: onCopyPosts,
                  onBulkRecycle,
                  onMoveToTop: withBulkMove ? bulkMoveToTop : undefined,
                  onMoveToBottom: withBulkMove ? bulkMoveToBottom : undefined
                }}
                onLoadNextPage={loadNextPage}
                onTogglePostSelected={togglePostSelected}
                onDeletePost={onDeletePost}
                onEditPost={loadPostInComposer}
                onCopyPost={onCopyPost}
                onMediaPreviewBtnClick={setPostInMediaPreviewDialog}
                onMovePost={movePost}
                onPostOrderChanged={onReorderPosts}
                onRecycleChange={onRecycleChange}
                onPostClick={onPostClick}
                onShareNow={onShareNow}
              />
            )}
            {posts.length > 0 && props.activeView === 'posted' && (
              <PostedPostsList
                posts={posts as any[]}
                sortBy={sortBy}
                selectedPosts={selectedPostsIds}
                bulkActionsProps={{
                  hidePostActions: false,
                  withCopy: true,
                  withRequeue: true,
                  bucketMode: true,
                  selectedCount: selectedPostsCount,
                  disabled: selectedPostsCount === 0,
                  allSelected: selectedPostsIds === 'all',
                  onToggleAllSelected: toggleAllPostsSelected,
                  onBulkRequeue: requeuePosts,
                  onCopy: onCopyPosts
                }}
                actionsClassName={styles['actions-posted']}
                onTogglePostSelected={togglePostSelected}
                onLoadNextPage={loadNextPage}
                onMediaPreviewBtnClick={setPostInMediaPreviewDialog}
                onRepost={onRepost}
                onRequeuePosted={onRequeue}
                onCopy={onCopyPost}
              />
            )}
            {!loading && posts.length === 0 && !isEmptyViewClosed && (
              <EmptyView
                icon={mdiPail}
                title={<FormattedMessage
                  id={props.activeView === 'planned' ? 'post.buckets.empty-view.title' : 'post.buckets.empty-view-posted.title'}
                />}
                subtitle={(
                  <div>
                    <NavLink
                      to={{ pathname: '/composer' }}
                      state={{ floating: true, modal: 'compose', opener: location.pathname, search, background: location }}
                      onClick={onOpenComposer}
                    >
                      Create a new post
                    </NavLink>
                    <span>
                      &nbsp;or&nbsp;
                      {props.activeView === 'planned'
                        ? <NavLink to={`/posts/buckets/${props.bucket.id}/posted`}>add posts from Posted</NavLink>
                        : <NavLink to="/content/search/content">search for content</NavLink>}
                    </span>
                  </div>
                )}
                backdrop
                backdropClassName={styles['empty-view-backdrop']}
                onClose={closeEmptyView}
              />
            )}
            {loadingOverlayActive && (
              <div className={styles['loading-overlay']}>
                <SimpleLoader small />
              </div>
            )}
          </div>
          <div
            className={`${styles['preview-box']} ${withPreview ? styles.visible : ''}`}
          >
            {postInPreview && (
              <PostPreviewWithInfo
                post={postInPreview}
                hasNextPost={previewSelection.hasNext}
                hasPreviousPost={previewSelection.hasPrev}
                className={styles.preview}
                onSelectNext={selectNextPostForPreview}
                onSelectPrevious={selectPrevPostForPreview}
                onDelete={onDeletePost}
                onMove={movePost}
                onEdit={loadPostInComposer}
                onCopy={onCopyPost}
                onRecycleChange={onRecycleChange}
                onClose={togglePreview}
                onPostUpdated={refreshPosts}
              />
            )}
          </div>
        </div>
      </ScrollListener>
      {loading && !loadingOverlayActive && (
        <SimpleLoader small />
      )}
      <PostFilesDialog
        active={Boolean(postInMediaPreviewDialog)}
        images={postInMediaPreviewDialog?.images || []}
        onClose={closePostMediaPreviewDialog}
      />
      <RecycleDialog open={recycleDialogOpen} onClose={closeRecycleDialog} onSubmit={bulkRecyclePosts} />
      <CopyPostsPopup
        open={postsToCopy.length > 0}
        posts={postsToCopy}
        postType={props.activeView}
        allSelected={Boolean(selectionRef.current?.all)}
        bucketId={props.bucket.id}
        onClose={closeCopyPostsPopup}
        onCopyStarted={closeCopyPostsPopup}
        onCopyFinished={onCopyPostsFinished}
      />
      {updatePopupOpen && (
        <BucketUpdatePopup bucket={props.bucket} onClose={closeBucketUpdatePopup} />
      )}
      {createPortal(sidebarProfileSelector, sidebarContainer as HTMLElement)}
    </section>
  )
}

export default injectIntl(BucketPostsView)
