import { createSelector } from 'reselect'
import StoreState from 'store/state'
import { impersonationSelector } from 'admin/services/selectors'
import { User } from 'interfaces'
import { sortByKeyAscending } from 'utils/sort/order'

export const usersSelector = (state: StoreState) => state.user
export const sliceUsersCurrent = createSelector(usersSelector, (state) => state.current)
export const currentUserSelector = createSelector(sliceUsersCurrent, impersonationSelector,
  (current, impersonate) => {
    if (!current) {
      return null
    }

    if (impersonate && impersonate.userId) {
      return Object.assign({}, current, { userId: impersonate.userId, productInfo: impersonate.productInfo })
    }

    return current
  }
)
export const isCCTrialSelector = createSelector(currentUserSelector, user => {
  return user?.account.status === 'trialing' && !user.account.trial
})
export const isNOCCTrialSelector = createSelector(currentUserSelector, user => {
  return user?.account.trial === 'active' && user?.account.status !== 'trialing'
})
export const isTrialActiveSelector = createSelector(isCCTrialSelector, isNOCCTrialSelector, (isCCTrial, isNOCCTrial) => {
  return isCCTrial || isNOCCTrial
})
export const trialEndsAtSelector = createSelector(currentUserSelector, user => {
  return user?.account.trialEndsAt
})

export const userProductSelector = createSelector(currentUserSelector, (user: User) => user && user.productInfo)
export const userProductDetailsSelector = createSelector(currentUserSelector, user => user?.account.productDetails)
export const defaultPostToSelector = createSelector(currentUserSelector, (user) => user && user.defaultPostTo.split(',') || [])

export const isAuthenticated = createSelector(currentUserSelector, (user: User | null) => {
  if (!user || !user.accessToken) {
    return false
  }

  return true
})

export const lastLoginTimeSelector = createSelector(currentUserSelector, user => user?.lastLoginTime)

export const accessTokenSelector = createSelector(currentUserSelector, impersonationSelector, (user, impersonation) => {
  if (impersonation && impersonation.token) {
    return impersonation.token
  }
  return user && user.accessToken
})

export const userIsAdminSelector = createSelector(currentUserSelector, user => user?.account?.masterAdmin === '1')
export const trialTasksSelector = createSelector(usersSelector, state => {
  return [...state.trialTasks].sort(sortByKeyAscending('order'))
})
export const trainingVideosSelector = createSelector(usersSelector, state => state.trainingVideos)
