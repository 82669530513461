import { createSelector } from 'reselect'
import { StoreState } from 'store/state'
import { connectedDestinationsSelector } from 'services/destinations'
import { IndexedObject, List } from 'interfaces'
import { sortByKeyAscending } from 'shared'

export const ALL_LIST_KEY = 'ALL'
export const listsSelector = (state: StoreState) => state.lists
export const userListsSelector = createSelector(listsSelector, connectedDestinationsSelector, (lists, profiles) => {
  const connectedPageIds = Object.keys(profiles)
  const listsMap = Object.values(lists.lists).reduce((map: IndexedObject<List>, list: List) => {
    map[list.id] = {
      ...list,
      connectedPages: connectedPageIds.filter(id => list.pages.includes(id))
    }
    return map
  }, {})
  return {
    ...listsMap,
    [ALL_LIST_KEY]: {
      name: ALL_LIST_KEY,
      id: ALL_LIST_KEY,
      pages: connectedPageIds || [],
      connectedPages: connectedPageIds || [],
      teamId: '',
      order: -1
    }
  } as IndexedObject<List>
})
export const listsOrderSelector = createSelector(userListsSelector, (lists) => {
  return Object.values(lists).sort(sortByKeyAscending('order')).map(l => l.id).filter(id => id !== ALL_LIST_KEY)
})
