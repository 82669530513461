import { List, ErrorResponse, LegacyList } from 'interfaces'

import assert from 'services/errors'

export function listAdapter(list: LegacyList, connectedPageIds: string[], index: number): List {
  const pages = list.postDestinations || []
  let order
  try {
    order = parseInt(list.order, 10)
  } catch (e) {
    order = index
  }
  return {
    id: list.id,
    teamId: list.appId,
    name: list.listName,
    pages,
    order,
    connectedPages: pages.filter((id: string) => connectedPageIds.includes(id))
  }
}

export function listsAdapter(response: LegacyList[] | ErrorResponse, connectedPageIds: string[]): List[] {
  const listsResponse = assert<LegacyList[]>(response)

  return listsResponse.map((response, index) => listAdapter(response, connectedPageIds, index))
}
