import TextField from '@mui/material/TextField'
import PPSelect from 'components/PPSelect'
import { BRAND_GOOGLE } from 'interfaces/Content/SocialBrand'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { setComposerGoogleButtonType, setComposerGoogleLink } from 'services/compose'
import { ComposerGooglePost, GooglePostButtonType } from 'services/compose/interfaces/ComposerGooglePost'
import { composerPostSelector } from 'services/compose/selectors'
import { GOOGLE_BUTTON_TYPES } from 'shared/constants'
import styles from './CustomizePostBlock.pcss'
import StoreState from 'store/state'

interface ComposerGoogleOptionsProps {
  post?: { link: string, buttonType?: GooglePostButtonType }
  align?: 'right' | 'left'
  onButtonTypeChange?: (type?: GooglePostButtonType) => void
  onLinkChange?: (url: string) => void
}

export default function ComposerGoogleOptions(props: React.PropsWithChildren<ComposerGoogleOptionsProps>) {
  const dispatch = useDispatch()
  const intl = useIntl()
  const composerPost = useSelector((state: StoreState) => composerPostSelector(state, BRAND_GOOGLE)) as ComposerGooglePost
  const post = props.post || composerPost

  const onChange = (e: any) => {
    const value = e.target.value
    if (props.onLinkChange) {
      props.onLinkChange(value)
    } else {
      dispatch(setComposerGoogleLink(value))
    }
  }

  const onBtnTypeChange = (type: GooglePostButtonType | 'NONE') => {
    const value = type === 'NONE' ? undefined : type
    if (props.onButtonTypeChange) {
      props.onButtonTypeChange(value)
    } else {
      dispatch(setComposerGoogleButtonType(value))
    }
  }

  return (
    <div className={styles['g-box']}>
      <div className={styles['option-box']}>
        <TextField
          type="text"
          placeholder={intl.formatMessage({ id: 'composer.labels.google.link-placeholder' })}
          value={post.link}
          onChange={onChange}
          className={styles['glink-input']}
        />
      </div>
      <div className={`${styles['option-box']} ${props.align ? styles[props.align] : ''}`}>
        <PPSelect
          name={intl.formatMessage({ id: 'composer.labels.google.btn-type' })}
          options={GOOGLE_BUTTON_TYPES}
          selectedValue={post.buttonType || 'NONE'}
          className={styles['g-select']}
          onSelectionChange={onBtnTypeChange}
        />
        {props.children}
      </div>
    </div>
  )
}
