import { PaymentViewConfig } from '../state'
const css = require('styles/variables')

export const config = (options: PaymentViewConfig, currency: string) => ({
  publicKey: 'chjs_sf82v3vgg8xw528qbqbv759b',
  type: 'card',
  currency,
  serverHost: 'https://postplanner.chargify.com',
  hideCardImage: true,
  style: {
    field: {
      padding: '8px 4px',
      margin: '0',
      maxHeight: '100px'
      // minWidth: '100px'
    },
    input: {
      fontSize: '16px',
      lineHeight: '40px',
      padding: '4px 12px'
    },
    label: {
      fontSize: '14px',
      lineHeight: '28px',
      fontWeight: 'normal',
      margin: '0',
      ...options.inputLabelStyles
    },
    message: {
      color: css['--alert-red']
    }
  },
  fields: {
    number: {
      selector: '#card-info-box',
      label: options.cardNumberLabel,
      placeholder: '0000 0000 0000 0000',
      message: 'Card number is invalid'
    },
    month: {
      selector: '#card-exp-month',
      label: 'Month',
      placeholder: 'MM',
      message: 'Month is invalid'
    },
    year: {
      selector: '#card-exp-year',
      label: 'Year',
      placeholder: 'YYYY',
      message: 'Year is invalid'
    },
    cvv: {
      selector: '#card-code',
      label: options.cardCvvLabel,
      placeholder: '000',
      required: true,
      message: 'Code is invalid'
    }
  }
})
