import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './CreateLocationDialog.pcss'

interface CreateLocationDialogProps {
  open: boolean
  onCancel: () => void
  onCreate: (name: string) => void
}

export function CreateLocationDialog(props: CreateLocationDialogProps) {
  const [name, setName] = React.useState('')
  const [error, setError] = React.useState(false)

  React.useEffect(() => {
    if (!props.open) {
      setName('')
      setError(false)
    }
  }, [props.open])

  const create = () => {
    const locationName = name.trim()
    if (locationName) {
      props.onCreate(locationName)
    } else {
      setError(true)
    }
  }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false)
    setName(e.target.value)
  }

  return (
    <Dialog open={props.open}>
      <div className={styles['dialog-content']}>
        <div className={styles.label}><FormattedMessage id="listings.labels.enter-name" /></div>
        <input type="text" className={`${styles.input} ${error ? styles.highlight : ''}`} value={name} onChange={onNameChange} />
      </div>
      <DialogActions className={styles.actions}>
        <Button variant="text" onClick={props.onCancel}>
          <FormattedMessage id="actions.cancel" />
        </Button>
        <Button variant="contained" color="primary" onClick={create}>
          <FormattedMessage id="listings.actions.create-location" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateLocationDialog
