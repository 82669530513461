import { Component, createElement } from 'react'
import ContentCard, { ContentCardProps } from './ContentCard'
import StatusIdeaCard, { StatusIdeaCardProps } from './StatusIdeaCard'
import { StatusIdea, ContentItem, STATUS_TYPE, ARTICLE_TYPE, GIF_TYPE, PHOTO_TYPE, VIDEO_TYPE } from 'interfaces'
import { STOCK_PHOTO_TYPE, STOCK_VIDEO_TYPE, STOCK_GIF_TYPE } from 'interfaces/Content/StockContentTypes'
import { TEXT_TYPE } from 'interfaces/Content/ContentType'

export type AnyCardProps = ContentCardProps & { children: ContentItem }
  | StatusIdeaCardProps & { children: StatusIdea }

export class AnyCard extends Component<AnyCardProps, any> {
  shouldComponentUpdate(nextProps: AnyCardProps) {
    const content = this.props.content || this.props.children as ContentItem
    const nextContent = nextProps.content || nextProps.children as ContentItem

    return content.id !== nextContent.id
      || this.props.className !== nextProps.className
      || (content as any).highlighted !== (nextContent as any).highlighted
  }

  render() {
    const { children, ...cardProps } = this.props
    const item = this.props.children
    switch (item.type) {
      case PHOTO_TYPE:
      case ARTICLE_TYPE:
      case VIDEO_TYPE:
      case GIF_TYPE:
      case STOCK_PHOTO_TYPE:
      case STOCK_VIDEO_TYPE:
      case STOCK_GIF_TYPE:
      case TEXT_TYPE:
        return createElement(ContentCard, { key: item.id, ...(cardProps as ContentCardProps), content: item as ContentItem })
      case STATUS_TYPE:
        return createElement(StatusIdeaCard, { key: item.id, ...(cardProps as StatusIdeaCardProps), content: item as StatusIdea })

      default:
        console.error('Invalid content type. Need an "AnyContent" child such as a "Photo" or "Article".')
        return null
    }
  }
}

export default AnyCard
