import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Stream, Feed } from 'interfaces'
import { StoreThunkDispatch } from 'store/state'
import FeedIcon from 'components/FeedIcon'
import { deleteFeed } from 'services/content/feeds/actions'
import { getUserStream } from 'services/content/streams/actions'
import 'rxjs/add/operator/takeUntil'
import HorizontalList from 'components/HorizontalList'
import { FormattedMessage } from 'react-intl'
import { currentUserSelector } from 'services/users/selectors'
import styles from './StreamFeedsNavigation.pcss'
import ConfirmDialog from 'components/ConfirmDialog'
import { message } from 'services/snackbar'

const FEEDS_LIST_SCROLL_BY = 480

export interface StreamFeedsNavigationProps {
  stream: Stream
  prefix: string
  search?: string // query string
  filterUrl?: string
  rangeUrl?: string
  linkToUserFeedId?: boolean
  activeFeedId?: string
  onFeedClicked?(feed: Feed): void
}

export const StreamFeedsNavigation: React.FC<StreamFeedsNavigationProps> = (props) => {
  const { stream, prefix, onFeedClicked, activeFeedId, search, filterUrl, rangeUrl } = props
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const userId = useSelector(currentUserSelector)?.userId

  const refreshStream = useCallback(() => {
    const streamId = stream.originalId || stream.id
    dispatch(getUserStream({ streamId, force: true }))
  }, [dispatch, stream.originalId, stream.id])

  useEffect(() => {
    if (stream.feeds.length === 0) {
      refreshStream()
    }
  }, [stream.feeds.length, refreshStream])

  const onDeleteFeed = (id: string) => {
    dispatch(deleteFeed({ feedId: id })).unwrap()
      .catch(() => {
        dispatch(message('Deleting feed failed. Please try again.'))
      })
      .then(() => {
        refreshStream()

        if (props.activeFeedId === id) {
          navigate(props.prefix)
        }
      })
  }

  const feeds = props.stream.feeds
  const isOwnStream = !props.stream.originalId && !props.stream.protected && userId === props.stream.userId

  if (feeds.length === 0) {
    return null
  }

  const withDelete = isOwnStream && feeds.length > 1 && !props.prefix.startsWith('/content/search')

  return (
    <ConfirmDialog message={<FormattedMessage id="content.delete-feed-prompt" />}>
      {(confirm) => (
        <HorizontalList
          scrollBy={FEEDS_LIST_SCROLL_BY}
          className={`${styles.list} ${styles.sticky}`}
          key="list"
        >
          {feeds.map((feed) => {
            const active = feed.id === activeFeedId

            return (
              <div key={feed.id}>
                <StreamFeedLink
                  feed={feed}
                  prefix={prefix}
                  search={search}
                  filterUrl={filterUrl}
                  rangeUrl={rangeUrl}
                  locationState={location.state}
                  linkToUserFeedId={props.linkToUserFeedId}
                  onFeedClicked={onFeedClicked}
                  active={active}
                  onDelete={withDelete ? confirm(onDeleteFeed) : undefined}
                />
              </div>
            )
          })}
        </HorizontalList>
      )}
    </ConfirmDialog>
  )
}

interface StreamFeedLinkProps {
  prefix: string
  feed: Feed
  active: boolean
  search?: string // query string
  locationState?: any
  filterUrl?: string
  rangeUrl?: string
  // indicates if the user feed id or unique feed id should be used for feed urls
  linkToUserFeedId?: boolean
  onFeedClicked?(feed: Feed): void
  onDelete?(id: string): void
}

export const StreamFeedLink: React.FC<StreamFeedLinkProps> = ({
  feed,
  prefix,
  linkToUserFeedId,
  active,
  search,
  locationState,
  filterUrl,
  rangeUrl,
  onFeedClicked,
  onDelete
}) => {
  const onClick = () => {
    if (onFeedClicked) {
      onFeedClicked(feed)
    }
  }

  const range = filterUrl && rangeUrl ? rangeUrl : ''
  const pathname = `${prefix}/feeds/${linkToUserFeedId ? feed.id : feed.uniqueSource}${filterUrl ? '/' + filterUrl + '/' : ''
  }${range}`

  return (
    <Link
      to={{ pathname, search }}
      state={locationState}
      onClick={onClick}
      key={feed.id}
      className={`${styles.link} ${active ? styles['link-active'] : ''}`}
    >
      <FeedIcon feed={feed} showTooltip onDelete={onDelete} />
    </Link>
  )
}

export default StreamFeedsNavigation
