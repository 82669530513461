import * as React from 'react'
import Paper from '@mui/material/Paper'
import styles from './StreamCard.pcss'
import LinearProgress from '@mui/material/LinearProgress'

export function EmptyStreamCard(props: { className?: string, flat?: boolean, loading?: boolean }) {
  return (
    <Paper
      elevation={props.flat ? 0 : 1}
      className={`${styles.card} ${styles['card-empty']} ${props.className || ''} ${props.loading ? styles.loading : ''}`}
    >
      <div className={styles['title-box']}>
        <div className={styles['icon-placeholder']}></div>
        {props.loading ? <LinearProgress className={styles['title-placeholder']} /> : <div className={styles['title-placeholder']}></div>}
      </div>
      <div className={styles['img-placeholder']}></div>
    </Paper>
  )
}

export default EmptyStreamCard
