import * as React from 'react'
import ColorSelector from 'components/ColorSelector'
import Button from '@mui/material/Button'
import { Feed, Stream, WithIntl } from 'interfaces'
import { FormattedMessage, injectIntl } from 'react-intl'
import { defaultStreamColor, ColorGradient, getGradientFromString } from 'utils/colors'
import ImageSelector from './ImageSelector'
import { getRandomElements } from 'shared/utils'
import { StreamCard, StreamCardPreview } from 'components/Cards'
import TextField from '@mui/material/TextField'
import styles from './CreateStreamForm.pcss'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import { useDispatch } from 'react-redux'
import { message } from 'services/snackbar'
import PPSwitch from 'components/PPSwitch'
import ConfirmDialog from 'components/ConfirmDialog'
import Collapse from '@mui/material/Collapse'
import Icon from '@mdi/react'
import { mdiChevronUp, mdiChevronDown } from '@mdi/js'

interface CreateStreamFormProps extends WithIntl {
  updateStream?: Stream
  loading: boolean
  images: string[]
  actionsMessage?: React.ReactNode
  feeds: Feed[]
  streams: Stream[]
  onSubmit: (
    name: string,
    color: ColorGradient,
    image: File | null,
    isPrivate?: boolean,
    featuredImage?: string,
    secondaryImages?: string[]
  ) => void | boolean
  onCancel?: () => void
  onSaveToStream?: (feeds: Feed[], stream: Stream) => void
  isPrivateOptionAllowed?: () => boolean
  onDelete?: () => void
}

export function CreateStreamForm(props: CreateStreamFormProps) {
  const dispatch = useDispatch()
  const updateMode = Boolean(props.updateStream)
  const strColor = props.updateStream ? getGradientFromString(props.updateStream.color) : null
  const [name, setName] = React.useState(props.updateStream?.title || '')
  const [color, setColor] = React.useState(strColor || defaultStreamColor)
  const [imageFile, setImageFile] = React.useState<File | null>(null)
  const [fileSrc, setFileSrc] = React.useState<null | string>(null)
  const [imageSrc, setImageSrc] = React.useState(props.images[0] || '')
  const [isPrivate, setIsPrivate] = React.useState(props.updateStream?.isPrivate || false)
  const [colorPickerKey, setColorPickerKey] = React.useState(0)
  const [selectedImageIndex, setSelectedImageIndex] = React.useState<number | null>(null)
  const [secondaryImages, setSecondaryImages] = React.useState<string[]>(props.updateStream?.imageUrls || props.images.slice(1))
  const singleImageMode = props.images.length <= 2 // Display single image when feed images are less than 3
  const [selectedStreamId, setSelectedStreamId] = React.useState<string | null>(null)
  const [mode, setMode] = React.useState<'create' | 'save'>('create')
  const [errorsExpanded, setErrorsExpanded] = React.useState(false)

  const toggleErrorsExpanded = () => {
    setErrorsExpanded(!errorsExpanded)
  }

  const resetForm = () => {
    setName('')
    setColor(defaultStreamColor)
    setImageFile(null)
    setFileSrc(null)
    setIsPrivate(false)
    setImageSrc('')
    setSecondaryImages([])
    setSelectedImageIndex(null)
    setColorPickerKey(current => current + 1)
  }

  const onCancel = () => {
    if (props.onCancel) {
      props.onCancel()
    }
    resetForm()
  }

  const onSubmit = () => {
    const streamName = name.trim()
    if (streamName.length === 0) {
      dispatch(message('Please enter a stream name', 'warning'))
      return
    }

    if (isPrivate && props.isPrivateOptionAllowed && !props.isPrivateOptionAllowed()) {
      return
    }

    const featuredImage = imageFile || !imageSrc ? undefined : imageSrc

    const preventReset = props.onSubmit(name, color, imageFile, isPrivate, featuredImage, secondaryImages)
    if (!preventReset) {
      resetForm()
    }
  }

  const onFileChanged = (file: File) => {
    setImageFile(file)
    const fileReader = new FileReader()
    fileReader.onload = () => {
      setImageSrc(fileReader.result as string)
      setFileSrc(fileReader.result as string)
      setSelectedImageIndex(null)
      randomizeSecondaryImages()
    }
    fileReader.onerror = () => console.error('File could not be loaded.')
    fileReader.readAsDataURL(file)
  }

  const onSelectedImageIndexChange = (index: number) => {
    setImageSrc(props.images[index] || '')
    setSelectedImageIndex(index)
    randomizeSecondaryImages(index)
  }

  const randomizeSecondaryImages = (excludeIndex?: number) => {
    if (singleImageMode) {
      return
    }
    const secondarySelection = [...props.images]
    if (typeof excludeIndex === 'number') {
      secondarySelection.splice(excludeIndex, 1)
    }
    const randomPick = getRandomElements(secondarySelection, 2)
    setSecondaryImages(randomPick)
  }

  const onValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setName(value)
  }

  const onAccessChange = (value: 'public' | 'private') => {
    setIsPrivate(value === 'private')
  }

  const modeOptions = [{
    label: 'New stream',
    value: 'create'
  }, {
    label: 'Existing stream',
    value: 'save'
  }]

  const onStreamClick = (s: Stream) => {
    setSelectedStreamId(s.id)
  }

  const accessSelectOptions: PPSelectOptions = {
    private: { label: 'Private (me and my team only)' },
    public: { label: 'Public (available to all users)' }
  }

  const onSave = () => {
    const stream = props.streams.find(s => s.id === selectedStreamId)
    if (stream && props.onSaveToStream) {
      const feedsToSave = props.feeds.filter(f => !duplicateFeeds.some(feed => feed.uniqueSource === f.uniqueSource))
      props.onSaveToStream(feedsToSave, stream)
    }
  }

  const selectedStream = props.streams.find(s => s.id === selectedStreamId)

  const duplicateFeeds = React.useMemo(() => {
    if (!selectedStream || mode !== 'save') {
      return []
    }
    return props.feeds.filter(f => selectedStream.feeds.some(feed => feed.uniqueSource === f.uniqueSource))
  }, [mode, props.feeds, selectedStream])

  return (
    <div className={`${styles.wrapper} ${styles[mode]}`} data-testid="create-stream-form">
      <div className={styles.top}>
        <div className={styles.heading}>
          {updateMode ? (
            <React.Fragment>
              <span><FormattedMessage id="find.my-streams.manage.edit-window-title" /></span>
              {props.onDelete && (
                <ConfirmDialog
                  message={<FormattedMessage id="find.my-streams.manage.delete-owned-title" />}
                  labelOK={<FormattedMessage id="actions.delete" />}
                >
                  {(confirm) => (
                    <Button disabled={props.loading} onClick={confirm(props.onDelete)}>
                      <FormattedMessage id="actions.delete" />
                    </Button>
                  )}
                </ConfirmDialog>
              )}
            </React.Fragment>
          ) : (
            <div className={styles.title}>
              <FormattedMessage id="create-stream-feeds-label" values={{ count: props.feeds.length }} />:
              <PPSwitch
                options={modeOptions}
                selectedValue={mode}
                className={styles.select}
                withButtonStyles
                onSelectedValueChange={setMode as any}
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.content}>
        <div>
          {mode === 'save' ? (
            <React.Fragment>
              {selectedStream && duplicateFeeds.length > 0 && (
                <div className={styles.errors}>
                  <Collapse in={errorsExpanded} timeout="auto" collapsedSize="24px">
                    {duplicateFeeds.length > 1 && (
                      <span className={styles.toggle} onClick={toggleErrorsExpanded}>
                        <Icon path={errorsExpanded ? mdiChevronUp : mdiChevronDown} size="24px" />
                      </span>
                    )}
                    {duplicateFeeds.map(f => (
                      <div key={f.id} className={styles.error}>
                        {`The ${f.name} feed is already included in the ${selectedStream.title} stream.`}
                      </div>
                    ))}
                  </Collapse>
                </div>
              )}
              <div className={styles['streams-grid']}>
                {props.streams.map(s => (
                  <StreamCard
                    key={s.id}
                    stream={s}
                    disableNavigation
                    noHoverEffect
                    persistentFeedsCount
                    className={`${styles.stream} ${s.id === selectedStreamId ? styles.highlight : ''}`}
                    onClick={onStreamClick}
                  />
                ))}
              </div>
            </React.Fragment>

          ) : (
            <React.Fragment>
              <div className={styles.label}>
                <span className={styles.step}>1</span>
                Choose a stream name and color
              </div>
              <div className={styles.row}>
                <div className={styles['stream-card-box']}>
                  {mode === 'create' && (
                    <div className={styles.left}>
                      <StreamCardPreview
                        name={name}
                        color={color}
                        imageSrc={imageSrc || props.updateStream?.featuredImageUrl}
                        displaySingleImage={singleImageMode}
                        secondaryImages={secondaryImages}
                      />
                    </div>
                  )}
                </div>
                <div className={styles['row-value']}>
                  <TextField
                    value={name}
                    placeholder={props.intl.formatMessage({ id: 'find.save-feed-panel.stream-name-hint' })}
                    classes={{ root: styles['input-box'] }}
                    onChange={onValueChanged}
                  />
                  <div className={styles['colors-box']}>
                    <ColorSelector
                      key={colorPickerKey}
                      initialColor={color}
                      onSelectionChanged={setColor as any}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.label}>
                <span className={styles.step}>2</span>
                Select the featured image for your stream
              </div>
              <div className={`${styles.row} ${styles.shift}`}>
                <div className={styles['row-value']}>
                  <ImageSelector
                    imageUrls={props.images}
                    imageFileSrc={fileSrc || undefined}
                    selectedIndex={selectedImageIndex}
                    hideTitle
                    onFileChanged={onFileChanged}
                    onSelectedIndexChange={onSelectedImageIndexChange}
                  />
                </div>
              </div>

              {props.isPrivateOptionAllowed && (
                <React.Fragment>
                  <div className={styles.label}>
                    <span className={styles.step}>3</span>
                    Choose who can access your stream
                  </div>
                  <div className={styles.row}>
                    <div className={styles['row-value']}>
                      <PPSelect
                        raised
                        options={accessSelectOptions}
                        selectedValue={isPrivate ? 'private' : 'public'}
                        onSelectionChange={onAccessChange}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
      <div className={styles.actions}>
        {props.onCancel && (
          <Button variant="text" onClick={onCancel}>
            <FormattedMessage id="actions.cancel" />
          </Button>
        )}
        {mode === 'create' ? (
          <Button
            variant="contained"
            color="primary"
            disabled={name.trim().length === 0 || props.loading}
            className={styles['btn-save']}
            onClick={onSubmit}
          >
            <FormattedMessage
              id={updateMode ? 'actions.update' : 'actions.create-stream'}
              values={{ feedsCount: props.feeds.length }}
            />
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={styles['btn-save']}
            disabled={props.loading || !selectedStreamId || duplicateFeeds.length === props.feeds.length}
            onClick={onSave}
          >
            <FormattedMessage
              id="actions.save-feeds-to-stream"
              values={{ feedsCount: props.feeds.length - duplicateFeeds.length }}
            />
          </Button>
        )}
        {props.actionsMessage && (
          <div className={styles.message}>{props.actionsMessage}</div>
        )}
      </div>
    </div>
  )
}

export default injectIntl(CreateStreamForm)
