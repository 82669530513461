import * as React from 'react'
import { useState, useRef } from 'react'
import ReactPlayer from 'react-player/youtube'
import { useDispatch } from 'react-redux'
import { Video } from 'interfaces'

import { PlayIcon } from 'components/Icons'
import { openContentPreviewWindow } from 'services/contentPreview'

const THROTTLE_HOVER_PLAY_MS = 500

import styles from './YTVideoPlayer.pcss'

interface YTVideoPlayerOwnProps {
  videoUrl: string
  videoId: string
  parseOnMount?: boolean
  togglePlayOnHover?: boolean
  controls?: boolean
  contentItem?: Video
  thumbnailUrl?: string
  width?: number
  windowMode?: boolean
  withBackground?: boolean
  className?: string
  videoContainerClassName?: string
  imageClassName?: string
}

interface YTVideoPlayerState {
  active: boolean
  playing: boolean,
  parsed: boolean
}

function YTVideoPlayer({
  videoUrl,
  videoId,
  parseOnMount,
  togglePlayOnHover,
  controls,
  contentItem,
  thumbnailUrl,
  width,
  windowMode,
  withBackground,
  className,
  videoContainerClassName,
  imageClassName
}: YTVideoPlayerOwnProps): React.ReactElement {
  const startTimeout = useRef<any>()
  const dispatch = useDispatch()
  const [state, setState] = useState<YTVideoPlayerState>({
    active: false,
    playing: false,
    parsed: !!parseOnMount
  })
  const backgroundStyles = { backgroundImage: `url('${thumbnailUrl}')` }
  const classFixed = !state.parsed ? styles.fixed : ''

  const togglePlay = () => {
    setState(prev => ({
      ...prev,
      playing: !state.playing
    }))
  }
  const handleOverlayClick = () => {
    if (contentItem) {
      dispatch(openContentPreviewWindow(contentItem))
      return
    }

    togglePlay()
  }

  const onMouseEnter = () => {
    startTimeout.current = setTimeout(() => {
      setState(prev => ({
        ...prev,
        parsed: true,
        playing: !!togglePlayOnHover
      }))
    }, THROTTLE_HOVER_PLAY_MS)
  }

  const onMouseLeave = () => {
    clearTimeout(startTimeout.current)
    setState(prev => ({
      ...prev,
      parsed: false,
      playing: false
    }))
  }

  return (
    <div
      className={`${styles.container} ${className || ''}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {!windowMode && (
        <div className={styles.overlay} onClick={handleOverlayClick}></div>
      )}

      {withBackground && (
        <div className={styles.background} style={backgroundStyles}></div>
      )}

      {!state.playing && !windowMode && (
        <div className={styles.thumbnail}>
          {thumbnailUrl && (
            <img src={thumbnailUrl} className={`${imageClassName || ''} ${styles['thumb-img']}`} />
          )}
        </div>
      )}

      {!windowMode && !state.playing && (
        <PlayIcon center className={styles['icon-play']} />
      )}

      {state.parsed && (
        <ReactPlayer
          id={videoId}
          className={`${styles['yt-box']} ${classFixed} ${videoContainerClassName || ''}`}
          url={videoUrl}
          playing={state.playing}
          muted
          volume={1}
          width={width}
          controls={controls}
        />
      )}
    </div>
  )
}

export default YTVideoPlayer
