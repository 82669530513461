import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import ErrorIcon from '@mui/icons-material/ErrorOutline'

import styles from './MobileWarningDialog.pcss'

export class MobileWarningDialog extends React.Component<{
  title?: string,
  message?: string
}, any> {
  render() {
    const title = 'Post Planner is not available on mobile browsers. Please install our mobile app.'
    const subtitle = 'If you\'re on a tablet or iPad, please turn your device sideways (landscape) to use the app here in the browser.'

    return (
      <Dialog open classes={{ paper: styles.container }} maxWidth="sm">
        <div className={styles.dialog}>
          <h5 className={styles.title}>
            {this.props.title || title}
          </h5>
          <div className={styles.actions}>
            <a
              href="https://apps.apple.com/app/apple-store/id1573125897"
              rel="noopener"
              target="_blank"
              className={styles['img-link']}
            >
              <img src="/static/img/misc/ios-350px.png" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.postplanner.app"
              rel="noopener"
              target="_blank"
              className={styles['img-link']}
            >
              <img src="/static/img/misc/play-350px.png" />
            </a>
          </div>
          <div className={styles.subtitle}>
            {this.props.message || subtitle}
          </div>
        </div>
      </Dialog>
    )
  }
}

export default MobileWarningDialog
