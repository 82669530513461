import { FeatureInfo, PlanLimitInfo, PPProduct } from 'interfaces'
import { createSelector } from 'reselect'
import { userProductSelector } from 'services/users/selectors'
import StoreState from 'store/state'
import { sortByKeyAscending } from 'utils/sort/order'
import { initialState, ProductState } from './state'

export const productStateSelector = (state: StoreState) => state.product || initialState()
export const productsSelector = createSelector(productStateSelector, state => {
  return [...state.products].sort(sortByKeyAscending('order'))
})
export const activeProductsSelector = createSelector(productStateSelector, state => {
  return [...state.products].sort(sortByKeyAscending('order')).filter(p => !p.legacy)
})
export const productsByHandleSelector = createSelector(productsSelector, products => {
  return products.reduce((map: Record<string, PPProduct>, product) => {
    map[product.handles.monthly] = product
    map[product.handles.annual] = product
    return map
  }, {})
})
export const featureUpgradeSelector = createSelector(productStateSelector, (state: ProductState) => state.featureUpgrade)
export const productUpgradeSelector = createSelector(productStateSelector, (state: ProductState) => state.productUpgrade)
export const onCloseUpgradeSelector = createSelector(productStateSelector, (state: ProductState) => state.closeUpgradePopupCallback)
export const contentUpgradePupupDismissedSelector = createSelector(productStateSelector, state => !state.displayContentUpgradePopup)
// Returns the next upgrade of user's current product
export const userProductUpgradeSelector = createSelector(userProductSelector, activeProductsSelector, (current, all) => {
  const index = all.findIndex(p => p.order === current?.order)
  return all[index + 1]
})
export const featuresSelector = createSelector(
  productStateSelector, state => state.features.reduce((map: { [key: string]: FeatureInfo }, feature: FeatureInfo) => {
    map[feature.key] = feature
    return map
  }, {})
)
export const limitsSelector = createSelector(
  productStateSelector, state => state.limits.reduce((map: { [key: string]: PlanLimitInfo }, limit: PlanLimitInfo) => {
    map[limit.key] = limit
    return map
  }, {})
)
export const defaultProductUpgradeHandleSelector = createSelector(
  userProductUpgradeSelector,
  userProductSelector,
  (upgrade: PPProduct | undefined, current: PPProduct) => {
    return upgrade?.handles.annual || current?.handles.annual
  }
)
