import { RequestBuilder, authorizedPipe } from 'services/net'
import { CONTENT_SERVICE_URL } from 'config'
import { Observable } from 'rxjs/Observable'

export const ENDPOINT_GET_QUOTES_TAGS = `${CONTENT_SERVICE_URL}/quotes/tags`
export const ENDPOINT_UPDATE_TAG = `${CONTENT_SERVICE_URL}/quotes/tags/:id`
export const ENDPOINT_QUOTES_SEARCHES = `${CONTENT_SERVICE_URL}/quotes/search/recent`
export const ENDPOINT_DELETE_QUOTE_SEARCH = `${CONTENT_SERVICE_URL}/quotes/search/recent/:term`
export const ENDPOINT_GET_RANDOM_QUOTES = `${CONTENT_SERVICE_URL}/quotes/:seed`

export const V1 = Object.freeze({
  getTags: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_QUOTES_TAGS).asGet().expectJSON()
    return function () {
      return authorizedPipe<any>(
        builder.build()
      )
    }
  }(),
  updateTag: function () {
    const builder = new RequestBuilder(ENDPOINT_UPDATE_TAG).asPost().expectJSON().asJSON()
    return function (id: number, active: boolean) {
      return authorizedPipe<any>(
        builder
          .partial(':id', id.toString())
          .body({ is_visible: active })
          .build()
      )
    }
  }(),
  getRandomQuotes: function () {
    const builder = new RequestBuilder(ENDPOINT_GET_RANDOM_QUOTES).asGet().expectJSON()
    return function () {
      const seed = Date.now()
      return authorizedPipe<any>(
        builder.partial(':seed', seed.toString()).build()
      )
    }
  }(),
  getPastSearches: function () {
    const builder = new RequestBuilder(ENDPOINT_QUOTES_SEARCHES).asGet().expectJSON()
    return function () {
      return authorizedPipe<any>(
        builder.build()
      )
    }
  }(),
  saveSearch: function () {
    const builder = new RequestBuilder(ENDPOINT_QUOTES_SEARCHES).asPost().expectJSON().asJSON()
    return function (query: string) {
      return authorizedPipe<any>(
        builder
          .body({ term: query })
          .build()
      )
    }
  }(),
  deleteSavedSearch: function () {
    const builder = new RequestBuilder(ENDPOINT_DELETE_QUOTE_SEARCH).asDelete().expectJSON()
    return function (text: string) {
      return authorizedPipe<any>(
        builder
          .partial(':term', text)
          .build()
      )
    }
  }()
})
