import { ContentType } from 'interfaces'
import { POST_TYPES } from 'interfaces/Content/ContentType'
import { UploaderFilesState } from 'components/Composer/UploadContext'
import { Observable } from 'rxjs/Observable'
import { tap } from 'rxjs/operators/tap'
import { getPostData } from 'services/compose/create/createPost'
import { composerSelector } from 'services/compose/selectors'
import ComposerState from 'services/compose/state'
import { connectedDestinationsSelector, userDestinationsSelector } from 'services/destinations'
import { HS_EVENT_DRAFT_SAVED, trackHubspotEvent } from 'services/tracking/hubspot'
import { getState, StoreThunkAction, StoreThunkDispatch } from 'store/state'
import { V1 } from './net'

const PAGE_SIZE = 50

export function getDrafts(
  ppids: string[],
  page: number,
  query?: string,
  types?: ContentType[],
  searchInSummary?: boolean,
  pageSize: number = PAGE_SIZE
): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch, getState: getState) => {
    const postTypes = types?.length === POST_TYPES.length ? undefined : types
    const profiles = connectedDestinationsSelector(getState())

    return dispatch(V1.getDrafts(ppids, page, pageSize, query, postTypes, searchInSummary))
      .map((response: any) => {
        const drafts = response.drafts.map((data: any) => {
          const state = data.payload.state
          let recycle = state.recycle
          if (typeof recycle === 'string') {
            recycle = new Date(recycle)
          } else if (typeof recycle === 'object') {
            recycle = true
          }
          return {
            id: data.id,
            updatedAt: data.updatedAt,
            ...data.payload,
            state: {
              ...state,
              recycle,
              schedule: state.schedule ? {
                ...state.schedule,
                date: new Date(state.schedule.date).toISOString(),
                time: new Date(state.schedule.time).toISOString()
              } : {
                isActive: false,
                time: new Date().toISOString(),
                date: new Date().toISOString(),
                repeat: false,
                repeatCount: 1,
                repeatPeriod: 'weeks',
                repeatUntil: 'forever',
                repeatUntilDate: new Date().toISOString()
              }
            },
            profiles: data.payload.state.selectedProfiles.map((id: string) => profiles[id]).filter(Boolean)
          }
        })
        return {
          drafts,
          totalDraftsCount: response.totalCount,
          paging: {
            ...response.paging,
            page
          }
        }
      })
  }
}

function getDraftComposerState() {
  return (_dispatch: StoreThunkDispatch, getState: getState) => {
    const state = { ...composerSelector(getState()) } as Partial<ComposerState>
    delete state.activeTab
    delete state.draft
    delete state.externalFile
    delete state.isUploading
    delete state.progress
    delete state.savedStatuses
    delete (state as any)._persist
    return state
  }
}

export function createDraft(files?: UploaderFilesState): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => {
    const draft = dispatch(getPostData(undefined, undefined, files))
    const state = dispatch(getDraftComposerState())
    return dispatch(V1.createDraft({ ...draft, state, profiles: [] }))
      .pipe(tap(() => {
        dispatch(trackHubspotEvent(HS_EVENT_DRAFT_SAVED))
      }))
  }
}

export function updateDraft(id: string, files?: UploaderFilesState): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => {
    const draft = dispatch(getPostData(undefined, undefined, files))
    const state = dispatch(getDraftComposerState())
    return dispatch(V1.updateDraft(id, { ...draft, state, profiles: [] }))
  }
}

export function deleteDrafts(ids: string[], all?: boolean): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.deleteDrafts(ids, all))
  }
}

export function getTotalDraftsCount() {
  return (dispatch: StoreThunkDispatch, getState: getState) => {
    const profiles = userDestinationsSelector(getState())
    const ppids = Object.values(profiles).map(p => p.ppid)
    return dispatch(getDrafts(ppids, 0, undefined, undefined, undefined, 1)).map((response: any) => response.totalDraftsCount)
  }
}
