import * as api from './net'
import { StoreThunkDispatch } from 'store/state'

export function getTikTokProfileSettings(ppid: string) {
  return (dispatch: StoreThunkDispatch) => dispatch(api.getTikTokProfileSettings(ppid)).map(response => ({
    ...response,
    enableComment: Boolean(response.enableComment),
    enableDuet: Boolean(response.enableDuet),
    enableStitch: Boolean(response.enableStitch)
  }))
}
