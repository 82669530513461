import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'
import { PlanResponse } from './adapter'
import capitalize from 'utils/format/capitalize'

export type GetPlanResponse = {
  plan: PlanResponse
  ppPageIds: string[] | null
  error?: string
}

interface GetProfilesResponse {
  profiles?: string[]
  error?: string
}

export type AddTimeParams = {
  planId: string
  hour: string
  minute: string
  timeOfDay: string
  bucketId?: string
  scheduleId?: string
  scheduleDay?: string
  range?: boolean
}
const DEFAULT_TIMESLOT_TIME_RANGE = 20

export const api = Object.freeze({
  getPlan: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/plan/profile_v2/content/:id`).asGet().expectJSON()

    return function (planId: string) {
      const id = planId?.toLowerCase() === 'main' ? '' : planId
      return authorizedPipe<GetPlanResponse>(
        builder.partial(':id', id).build()
      )
    }
  }(),
  createPlan: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/plan/profile_v2/create`).asPost().expectJSON()

    return function (ppids: string[], name: string, copyFromPlanId?: string) {
      return authorizedPipe<any>(
        builder
          .bodyJSONstringify({ pp_page_ids: ppids, name, copy_from_plan_id: copyFromPlanId })
          .build()
      )
    }
  }(),
  deletePlan: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/plan/profile_v2/delete`).asPost().expectJSON()

    return function (ppid: string) {
      return authorizedPipe<any>(
        builder
          .bodyJSONstringify({ pp_page_id: ppid })
          .build()
      )
    }
  }(),
  updatePlan: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/plan/profile_v2/update`).asPost().expectJSON()

    return function (planId: string, name: string, addPpids?: string[], copyFrom?: string) {
      const data: any = { plan_id: planId, name }
      if (addPpids) {
        data.pp_page_ids = addPpids
      }
      if (copyFrom) {
        data.copy_from_plan_id = copyFrom
      }
      return authorizedPipe<any>(
        builder
          .bodyJSONstringify(data)
          .build()
      )
    }
  }(),
  getProfiles: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/plan/profile_v2/all`).asGet().expectJSON()

    return function () {
      return authorizedPipe<GetProfilesResponse>(builder.build())
    }
  }(),
  getPostingPlans: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/plan/profile_v2/all`).asGet().expectJSON()

    return function () {
      return authorizedPipe<any>(builder.build())
    }
  }(),
  getTimezones: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/settings/defaults/get_timezones`).asGet().expectJSON()

    return function () {
      return authorizedPipe<any>(
        builder.build()
      )
    }
  }(),
  updateTimezone: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/plan/profile_v2/update_timezone`).asPost().expectJSON()

    return function (planId: string, timezone: string) {
      return authorizedPipe<any>(
        builder.bodyJSONstringify({ plan_id: planId, timezone }).build()
      )
    }
  }(),
  togglePlanPaused: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/plan/profile_v2/plan_pause_toggle`).asPost().expectJSON()

    return function (planId: string) {
      return authorizedPipe<any>(
        builder.bodyJSONstringify({ plan_id: planId }).build()
      )
    }
  }(),
  addProfilesToPlan: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/plan/profile_v2/add_pages_to_plan`).asPost().expectJSON()

    return function (planId: string, ppids: string[]) {
      return authorizedPipe<any>(
        builder.bodyJSONstringify({ plan_id: planId, pp_page_ids: ppids }).build()
      )
    }
  }(),
  addSchedule: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/plan/tab/create`).asPost().expectJSON()

    return function (planId: string, scheduleId: string) {
      return authorizedPipe<any>(
        builder
          .bodyJSONstringify({ plan_id: planId, tab_id: scheduleId })
          .build()
      )
    }
  }(),
  deleteSchedule: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/plan/tab/delete`).asPost().expectJSON()

    return function (scheduleId: string) {
      return authorizedPipe<any>(
        builder
          .bodyJSONstringify({ tab_id: scheduleId })
          .build()
      )
    }
  }(),

  toggleDay: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/plan/tab/day_toggle`).asPost().expectJSON()

    return function (ppid: string, scheduleId: string, day: string) {
      return authorizedPipe<any>(
        builder
          .bodyJSONstringify({ plan_id: ppid, tab_id: scheduleId, day: capitalize(day) })
          .build()
      )
    }
  }(),

  addTime: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/plan/slot/create`).asPost().expectJSON()

    return function ({ planId, scheduleId, scheduleDay, hour, minute, timeOfDay, bucketId, range }: AddTimeParams) {
      return authorizedPipe<any>(
        builder
          .bodyJSONstringify({
            plan_id: planId,
            tab: scheduleId,
            tab_day: capitalize(scheduleDay),
            hour,
            minute,
            day_period: timeOfDay,
            bucket_id: bucketId,
            range: range ? DEFAULT_TIMESLOT_TIME_RANGE : null
          })
          .build()
      )
    }
  }(),

  deleteTime: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/plan/slot/delete`).asPost().expectJSON()

    return function (timeIds: string[]) {
      return authorizedPipe<any>(
        builder
          .bodyJSONstringify({ slot_ids: timeIds })
          .build()
      )
    }
  }(),

  updateTime: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/plan/slot/update`).asPost().expectJSON()

    return function (ids: string[], hour: string, minute: string, timeOfDay: string, bucketId?: string, range?: boolean) {
      return authorizedPipe<any>(
        builder
          .bodyJSONstringify({
            ids,
            hour,
            minute,
            day_period: timeOfDay,
            bucket_id: bucketId,
            range: range ? DEFAULT_TIMESLOT_TIME_RANGE : null
          })
          .build()
      )
    }
  }(),

  updateTimeslotsBucket: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/plan/slot/update_batch`).asPost().expectJSON()

    return function (ids: string[], postBucketId?: string) {
      return authorizedPipe<any>(
        builder
          .bodyJSONstringify({ slot_ids: ids, bucket_id: postBucketId })
          .build()
      )
    }
  }()
})
