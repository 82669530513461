import * as React from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { FormattedMessage, injectIntl } from 'react-intl'
import { WithIntl } from 'interfaces'
import { isEmailValid } from 'utils/email'
import { authErrorSelector, login, logout, PASSWORD_MIN_LENGTH, requestPassword } from 'services/auth'
import StoreState, { StoreThunkDispatch } from 'store/state'
import { connect } from 'react-redux'
import { Observable } from 'rxjs/Observable'
import useAsyncAction from 'hooks/useAsyncAction'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import Input from '@mui/material/Input'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { message } from 'services/snackbar'
import { fromNow } from 'utils/format/date'
import styles from './EmailLoginForm.pcss'
import { useLocation, useNavigate, NavLink } from 'react-router-dom'
import { SnackType } from 'services/snackbar/interfaces/PendingTreat'

const LOADER_SIZE = 20

interface EmailLoginFormOwnProps {
  fbLoginSection: React.ReactNode
  loginParams?: { [key: string]: string }
}

interface ConnectedEmailLoginFormProps {
  authError?: {
    retryAt?: number
    restrictedAccess?: boolean
  }
  login: (email: string, password: string) => Observable<any>
  logout: (callback: () => void) => void
  requestNewPassword: (email: string) => Observable<any>
  message: (text: string, type?: SnackType) => void
}

export type EmailLoginFormProps = EmailLoginFormOwnProps & ConnectedEmailLoginFormProps & WithIntl

export function EmailLoginForm(props: EmailLoginFormProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const [login, loginResponse, loginError, loginLoading] = useAsyncAction(props.login)
  const [logoutLoading, setLogoutLoading] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState<null | string>(null)
  const [passwordResetDialogOpen, setPasswordResetDialogOpen] = React.useState(false)
  const [passwordResetEmail, setPasswordResetEmail] = React.useState('')
  const [passwordResetError, setPasswordResetError] = React.useState(false)

  React.useEffect(() => {
    // If One-time-token is provided, automatically log the user in with it.
    if (props.loginParams?.ott) {
      setLogoutLoading(true)
      props.logout(() => {
        login('', '', props.loginParams)
        setLogoutLoading(false)
      })
    } else if (props.loginParams?.hash) {
      // If hash param is provided, logout the user and let them manually log in
      props.logout(() => {
        setLogoutLoading(false)
      })
    }
  }, [props.loginParams?.ott, props.loginParams?.hash])

  React.useEffect(() => {
    if (loginError) {
      setError(loginError.message)
    } else if (loginResponse && props.loginParams?.ott) {
      navigate('/content')
    }
  }, [loginError, loginResponse])

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(null)
    const value = e.target.value
    setEmail(value)
  }

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(null)
    const value = e.target.value
    setPassword(value)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    setError(null)
    const mail = email.trim()
    if (!isEmailValid(mail)) {
      setError(props.intl.formatMessage({ id: 'login.error.wrong-mail-or-password' }))
      return
    }

    if (password.length < PASSWORD_MIN_LENGTH) {
      setError(props.intl.formatMessage({ id: 'login.error.wrong-mail-or-password' }))
      return
    }

    login(email, password, props.loginParams)
  }

  const closePasswordResetDialog = () => {
    setPasswordResetDialogOpen(false)
    setPasswordResetEmail('')
    setPasswordResetError(false)
  }

  const openPasswordResetDialog = () => {
    setPasswordResetDialogOpen(true)
  }

  const onPasswordResetEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordResetEmail(event.target.value)
  }

  const requestNewPassword = () => {
    setPasswordResetError(false)
    const email = passwordResetEmail.trim()
    if (!isEmailValid(email)) {
      setPasswordResetError(true)
      return
    }

    closePasswordResetDialog()
    props.requestNewPassword(email).subscribe(() => {
      props.message(props.intl.formatMessage({ id: 'login.pw-reset.email-sent' }), 'success')
    }, (e: Error) => {
      console.log('Forgotten password reset error: ', e)
      props.message(props.intl.formatMessage({ id: 'errors.generic-support' }), 'error')
    })
  }

  const loginBlocked = Boolean(props.authError?.retryAt) && new Date(props.authError?.retryAt as number).getTime() > Date.now()
  const retryAtTime = loginBlocked && fromNow(props.authError?.retryAt as any)

  return (
    <section className={styles.wrapper}>
      <form className={styles.form}>
        <div className={styles['row-input']}>
          <TextField
            type="email"
            autoComplete="username"
            className={styles.input}
            placeholder={props.intl.formatMessage({ id: 'login.input.email-hint' })}
            onChange={onEmailChange}
            onKeyDown={onKeyDown}
          />
        </div>

        <div className={styles['row-input']}>
          <TextField
            type="password"
            autoComplete="password"
            className={styles.input}
            placeholder={props.intl.formatMessage({ id: 'login.input.password-hint' })}
            onChange={onPasswordChange}
            onKeyDown={onKeyDown}
          />
          <a className={styles['label-pw-reset']} onClick={openPasswordResetDialog}>
            <FormattedMessage id="login.actions.reset-password" />
          </a>
        </div>

        <Button
          className={`${loginLoading || logoutLoading ? styles['btn-loading'] : ''} ${styles['btn-login']}`}
          variant="contained"
          color="primary"
          disabled={loginLoading || loginBlocked || logoutLoading}
          onClick={handleSubmit}
        >
          {loginLoading || logoutLoading ? (
            <CircularProgress className={styles.loader} size={LOADER_SIZE} />
          ) : (
            <FormattedMessage id="login.btn-email-login" />
          )}
        </Button>
        {props.fbLoginSection}

        {error && <p className={styles.error}>{error}</p>}
        {loginBlocked && (
          <p className={styles.error}>
            <FormattedMessage id="login.error.max-attempts-reached" values={{ time: retryAtTime }} />
          </p>
        )}
        {props.authError?.restrictedAccess && (
          <p className={styles.error}>
            <FormattedMessage id="login.error.restricted-access" />
          </p>
        )}
        <p className={styles.signup}>
          <FormattedMessage id="login.no-account" />
          <NavLink to="/signup/create-account">
            <FormattedMessage id="login.signup" />
          </NavLink>
        </p>
      </form>
      <Dialog open={passwordResetDialogOpen} onClose={closePasswordResetDialog}>
        <DialogTitle className={styles['pw-reset-title']}>
          <FormattedMessage id="login.pw-reset.title" />
        </DialogTitle>
        <DialogContent className={styles['pw-reset-content']}>
          <Input
            placeholder={props.intl.formatMessage({ id: 'login.pw-reset.email-placeholder' })}
            type="email"
            className={styles['pw-reset-input']}
            value={passwordResetEmail}
            onChange={onPasswordResetEmailChange}
          />
          <p className={styles['pw-reset-error']}>{passwordResetError && <FormattedMessage id="login.pw-reset.label-invalid-email" />}</p>
          <Button
            variant="contained"
            className={styles['btn-pw-reset']}
            color="primary"
            disabled={loginBlocked}
            onClick={requestNewPassword}
          >
            <FormattedMessage id="login.pw-reset.actions.request-password" />
          </Button>
        </DialogContent>
      </Dialog>
    </section>
  )
}

function mapStateToProps(state: StoreState) {
  return {
    authError: authErrorSelector(state)
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    login: (email: string, password: string, params?: { [key: string]: string }) => dispatch(login(email, password, params)),
    requestNewPassword: (email: string) => dispatch(requestPassword(email)),
    message: (text: string, type?: SnackType) => dispatch(message(text, type)),
    logout: (callback: () => void) => dispatch(logout(callback))
  }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EmailLoginForm))
