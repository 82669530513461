import * as React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { PageCallout } from '../../../PageCallout/PageCallout'
import { useDispatch, useSelector } from 'react-redux'
import { calloutsSelector } from '../../../../services/ui/selectors'
import { StoreThunkDispatch } from 'store/state'
import { infoBoxesFetched, setActiveCallout, toggleCallout } from 'services/ui/actions'
import { RouteErrorView } from '../ErrorFallback/RouteErrorView'

export function CalloutManager() {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const callouts = useSelector(calloutsSelector)
  const location = useLocation()

  React.useEffect(() => {
    const configUrl = `https://staticpp.postplanner.com/config/callouts.json?v=${Date.now()}`
    fetch(configUrl)
      .then(response => response.json())
      .then(res => {
        dispatch(infoBoxesFetched(res))
      }).catch((e) => {
        console.log('Error: ', e)
      })
  }, [dispatch])

  const dismissCallout = (slug: string) => {
    dispatch(toggleCallout(slug))
  }

  React.useEffect(() => {
    const active = callouts.find(c => {
      return (c.exact && c.slug === location.pathname.slice(1)) || (!c.exact && location.pathname.startsWith(`/${c.slug}`))
    })
    dispatch(setActiveCallout(active))
  }, [callouts, dispatch, location.pathname])

  return (
    <Routes>
      <Route errorElement={<RouteErrorView />}>
        {callouts.map(c => {
          const dismissable = !c.slug.startsWith('settings')
          const hidden = c.slug.startsWith('onboarding')

          if (hidden) {
            return null
          }

          return (
            <Route
              key={c.slug}
              path={`/${c.slug}${c.exact ? '' : '/*'}`}
              element={<PageCallout data={c} onClose={dismissable ? dismissCallout : undefined} />}
            />
          )
        })}
        <Route path="/" element={null} />
      </Route>
    </Routes>
  )
}

export default CalloutManager
