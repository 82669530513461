import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { PlannedPostsLayout } from 'services/post/state'
import { useSelector } from 'react-redux'
import { plannedPostsLayoutSelector } from 'services/post/selectors'
import PPSwitch from 'components/PPSwitch'
import { useLocation, useNavigate } from 'react-router-dom'

export function ActiveViewSelector({ className, hideOptions }: { className?: string, hideOptions?: Array<'calendar' | 'list' | 'grid'> }) {
  const location = useLocation()
  const navigate = useNavigate()
  const selectedValue = useSelector(plannedPostsLayoutSelector)

  const onItemClick = React.useCallback((value: PlannedPostsLayout) => {
    const url = value === 'calendar' ? '/posts/planned/all?view=calendar' : location.pathname + `?view=${value}`
    navigate(url)
  }, [location.pathname, navigate])

  const options = [{
    label: (
      <span>
        <FormattedMessage id="post.views.calendar" />
      </span>
    ),
    value: 'calendar'
  }, {
    label: <FormattedMessage id="post.views.timeline" />,
    value: 'list'
  }, {
    label: (
      <span>
        <FormattedMessage id="post.views.grid" />
      </span>
    ),
    value: 'grid'
  }].filter(option => !hideOptions || !hideOptions.includes(option.value as any))

  return (
    <PPSwitch
      selectedValue={selectedValue}
      options={options}
      className={className}
      onSelectedValueChange={onItemClick}
    />
  )
}

export default ActiveViewSelector
