import { Gif, Paginated, GIF_TYPE, LegacyGif } from 'interfaces'
import { STOCK_GIF_TYPE } from 'interfaces/Content/StockContentTypes'
import { videoAdapter } from './videos'

export function gifsAdapter(gifs: LegacyGif[], stock?: boolean): Paginated<Gif[]> {
  return {
    items: gifs.map(g => gifAdapter(g, stock)) as any[],
    hasNext: gifs.length !== 0
  }
}

export function gifAdapter(gif: LegacyGif, stock?: boolean) {
  return {
    ...videoAdapter(gif as any, stock),
    type: stock ? STOCK_GIF_TYPE : GIF_TYPE,
    isStock: Boolean(stock),
    gifUrl: gif.gifUrl
  }
}
