import * as React from 'react'
import Button from '@mui/material/Button'
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import IconCheck from '@mui/icons-material/Check'
import styles from './FilterDropdown.pcss'
import { FormattedMessage } from 'react-intl'
import { IndexedObject } from 'interfaces'
import IconDropdown from '@mui/icons-material/ArrowDropDown'
import IconImage from '@mui/icons-material/Image'
import { mdiMessageReplyTextOutline, mdiImage, mdiVideo } from '@mdi/js'
import Icon from '@mdi/react'

const imageOptions: IndexedObject<React.ReactNode> = {
  'photo-favorites': (
    <span className={styles['fav-label']}>
      <FormattedMessage id="content-studio.filters.photo-favorites" />
    </span>
  ),
  'stock-image': <FormattedMessage id="content-studio.filters.stock" />,
  'library-image': <FormattedMessage id="content-studio.filters.library" />
}

const videoOptions: IndexedObject<React.ReactNode> = {
  'stock-video': <FormattedMessage id="content-studio.filters.stock" />,
  'library-video': <FormattedMessage id="content-studio.filters.library" />
}

const textOptions: IndexedObject<React.ReactNode> = {
  'text-favorites': (
    <span className={styles['fav-label']}>
      <FormattedMessage id="content-studio.filters.text-favorites" />
    </span>
  ),
  quotes: <FormattedMessage id="content-studio.filters.quotes" />,
  statuses: <FormattedMessage id="content-studio.filters.statuses" />
}

export function FilterDropdown(props: { selectedValue: string, mode: 'image' | 'video', onSelectedValueChange: (value: string) => void }) {
  const { selectedValue, mode, onSelectedValueChange } = props
  const [popupAnchor, setPopupAnchor] = React.useState<HTMLElement | null>(null)
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const openPopup = (e: any) => {
    if (buttonRef.current) {
      setPopupAnchor(buttonRef.current)
    }
    e.stopPropagation()
    e.preventDefault()
  }

  const closePopup = () => {
    setPopupAnchor(null)
  }

  const onItemClick = (value: string) => {
    onSelectedValueChange(value)
    closePopup()
  }

  const label = React.useMemo(() => {
    if (imageOptions[selectedValue] || videoOptions[selectedValue]) {
      return (
        <FormattedMessage id={`content-studio.filters.${selectedValue}-full`} />
      )
    }
    if (selectedValue === 'text-favorites') {
      return (
        <FormattedMessage id={`content-studio.filters.${selectedValue}-full`} />
      )
    }
    return <FormattedMessage id={`content-studio.filters.${selectedValue}`} />
  }, [selectedValue])

  const isImageOption = mode === 'image' && Object.keys(imageOptions).includes(selectedValue)
  const isVideoOption = mode === 'video' && Object.keys(videoOptions).includes(selectedValue)
  const mediaOptions = mode === 'image' ? imageOptions : videoOptions

  return (
    <div className={styles.container}>
      <Button
        ref={buttonRef}
        startIcon={<Icon size="18px" path={isImageOption ? mdiImage : isVideoOption ? mdiVideo : mdiMessageReplyTextOutline} />}
        endIcon={<IconDropdown className={styles.icon} />}
        onClick={openPopup}
      >
        {label}
      </Button>
      <Popper
        open={Boolean(popupAnchor)}
        anchorEl={popupAnchor}
        disablePortal
        placement="bottom"
        className={styles.popper}
      >
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={closePopup}>
          <Paper classes={{ root: styles.paper }}>
            <div className={styles.option}>
              <div className={styles.label}>
                <Icon size="18px" className={styles['item-icon']} path={mdiMessageReplyTextOutline} />
                <FormattedMessage id="content-studio.filters-group.text" />:
              </div>
              <div className={styles.switch}>
                {Object.keys(textOptions).map(option => (
                  <FilterListItem
                    key={option}
                    value={option}
                    label={textOptions[option]}
                    selected={selectedValue === option}
                    onClick={onItemClick}
                  />
                ))}
              </div>
            </div>
            <div className={styles.option}>
              <div className={styles.label}>
                <Icon size="18px" className={styles['item-icon']} path={mode === 'image' ? mdiImage : mdiVideo} />
                <FormattedMessage id={`content-studio.filters-group.${mode}`} />:
              </div>
              <div className={styles.switch}>
                {Object.keys(mediaOptions).map(option => (
                  <FilterListItem
                    key={option}
                    value={option}
                    label={mediaOptions[option]}
                    selected={selectedValue === option}
                    onClick={onItemClick}
                  />
                ))}
              </div>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

function FilterListItem(props: { value: string, label: React.ReactNode, selected: boolean, onClick: (value: string) => void }) {
  const onClick = () => {
    props.onClick(props.value)
  }

  return (
    <div onClick={onClick} className={props.selected ? styles['tab-active'] : ''}>
      {props.label}
      {props.selected && <IconCheck color="primary" fontSize="small" className={styles.icon} />}
    </div>
  )
}

export default FilterDropdown
