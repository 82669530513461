import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'
import styles from '../HomeSection.pcss'
import { CarouselView } from 'components/ContentLayout'
import { ALL_RANGE_FILTER, ARTICLE_TYPE, Feed, FEED_TYPE_RSS } from 'interfaces'

export const PP_BLOG_NAV_LINK = 'post-planner-latest'
export const PP_RSS_FEED_ID = '30983'
export const PPRSSFeed: Feed = {
  handle: 'http://postplanner.com/blog/rss.xml',
  id: PP_RSS_FEED_ID,
  isFeed: true,
  name: 'Post Planner Blog',
  type: FEED_TYPE_RSS,
  order: 0,
  sources: [ARTICLE_TYPE],
  uniqueSource: PP_RSS_FEED_ID
}

export function PostPlannerBlogCarousel(props: { title?: string }) {
  const link = {
    pathname: PP_BLOG_NAV_LINK,
    state: { id: PPRSSFeed.id }
  }

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h2 className={`${styles.title} text-ellipsis`}>
          <FormattedMessage id="find.home.sections.pp-blog" />
        </h2>
        <NavLink to={link} className={`${styles.navigation} text-ellipsis`}>
          {props.title || <FormattedMessage id="general.carousel.nav.see-all" />}
        </NavLink>
      </header>
      <CarouselView
        source={PPRSSFeed}
        filter={ARTICLE_TYPE}
        defaultRange={ALL_RANGE_FILTER}
        sortByDate
        skipContentFeatureChecks
      />
    </section>
  )
}

export default PostPlannerBlogCarousel
