import { connect } from 'react-redux'

import {
  RangeFilter,
  ContentType,
  PHOTO_TYPE,
  ARTICLE_TYPE,
  VIDEO_TYPE,
  GIF_TYPE,
  Feed,
  FilterType,
  NEW_RANGE_FILTER
} from 'interfaces'
import { TEXT_TYPE } from 'interfaces/Content/ContentType'
import { getPhotos } from 'services/content/photos/actions'
import { getArticles } from 'services/content/articles/actions'
import { getVideos } from 'services/content/videos/actions'
import { getGifs } from 'services/content/gifs/actions'
import { getTexts } from 'services/content/texts/actions'
import { StoreState, StoreThunkDispatch } from 'store/state'

import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/of'
import 'rxjs/add/observable/empty'
import 'rxjs/add/operator/mergeMap'

import { FetchFeedContent } from './FetchFeedContent'
import { SortBy } from 'services/content/util'
import { STOCK_ALL_FILTER, STOCK_GIF_TYPE, STOCK_PHOTO_TYPE, STOCK_VIDEO_TYPE } from 'interfaces/Content/StockContentTypes'
import { StockContentProvider, STOCK_CONTENT_PROVIDERS_ALL } from 'interfaces/Content/StockContentProvider'
import { fetchStockContent } from 'services/content/stock/actions'

export interface FetchFeedPageContentOwnProps {
  feed: Feed
  page: number
  range: RangeFilter
  sortBy?: SortBy
  stockSource?: StockContentProvider

  skip?: ContentType[]
  only?: FilterType
}

function mapStateToProps(_state: StoreState, ownProps: FetchFeedPageContentOwnProps) {
  const { page, range, feed, only, sortBy } = ownProps
  return {
    ...ownProps,
    hash: `feed:${feed.id}:${only || 'any'}:${page}:${range}:${sortBy || 'any'}` as string | undefined
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch, ownProps: FetchFeedPageContentOwnProps) {
  const { page, range, feed, skip, only, sortBy, stockSource } = ownProps

  const skipVideos = (only && only !== VIDEO_TYPE) || (skip && skip.includes(VIDEO_TYPE))
  const skipGifs = (only && only !== GIF_TYPE) || (skip && skip.includes(GIF_TYPE))
  const skipPhotos = (only && only !== PHOTO_TYPE) || (skip && skip.includes(PHOTO_TYPE))
  const skipTexts = (only && only !== TEXT_TYPE) || (skip && skip.includes(TEXT_TYPE))
  const skipArticles = (only && only !== ARTICLE_TYPE) || (skip && skip.includes(ARTICLE_TYPE))
  const skipStockVideos = (only && only !== STOCK_VIDEO_TYPE)
  const skipStockGifs = (only && only !== STOCK_GIF_TYPE)
  const skipStockPhotos = (only && only !== STOCK_PHOTO_TYPE)
  const stockFilter = stockSource || STOCK_CONTENT_PROVIDERS_ALL

  return {
    getVideos: () => skipVideos
      ? Observable.empty()
      : dispatch(getVideos(feed.uniqueSource + '', false, page, range, sortBy)).flatMap((v: any) => Observable.of(...v.items)),
    getStockVideos: () => skipStockVideos
      ? Observable.empty()
      : dispatch(getVideos(feed.uniqueSource + '', false, page, NEW_RANGE_FILTER, sortBy, true, stockFilter))
        .flatMap((v: any) => Observable.of(...v.items)),
    getGifs: () => skipGifs
      ? Observable.empty()
      : dispatch(getGifs(feed.uniqueSource + '', false, page, range, sortBy)).flatMap((v: any) => Observable.of(...v.items)),
    getStockGifs: () => skipStockGifs
      ? Observable.empty()
      : dispatch(getGifs(feed.uniqueSource + '', false, page, NEW_RANGE_FILTER, sortBy, true, stockFilter))
        .flatMap((v: any) => Observable.of(...v.items)),
    getPhotos: () => skipPhotos
      ? Observable.empty()
      : dispatch(getPhotos(feed.uniqueSource + '', false, page, range, sortBy)).flatMap((p: any) => Observable.of(...p.items)),
    getTexts: () => skipTexts
      ? Observable.empty()
      : dispatch(getTexts(feed.uniqueSource + '', false, page, range, sortBy)).flatMap((p: any) => Observable.of(...p.items)),
    getStockPhotos: () => skipStockPhotos
      ? Observable.empty()
      : dispatch(getPhotos(feed.uniqueSource + '', false, page, NEW_RANGE_FILTER, sortBy, true, stockFilter))
        .flatMap((p: any) => Observable.of(...p.items)),
    getArticles: () => skipArticles
      ? Observable.empty()
      : dispatch(getArticles(feed.uniqueSource + '', false, page, range, sortBy)).flatMap((a: any) => Observable.of(...a.items)),
    getStockAll: () => only === STOCK_ALL_FILTER
      ? dispatch(fetchStockContent(feed.uniqueSource + '', page, NEW_RANGE_FILTER, stockSource))
        .flatMap((a: any) => Observable.of(...a.items))
      : Observable.empty()
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FetchFeedContent)
