import * as React from 'react'
import { PlayIcon } from 'components/Icons'
import styles from './Common.pcss'

export function PostPreviewVideoPlayer(props: { videoUrl: string, className?: string, thumbnailUrl?: string, icon?: React.ReactNode }) {
  const videoRef = React.useRef<HTMLVideoElement>(null)
  const [playing, setPlaying] = React.useState(false)

  const togglePlay = () => {
    if (!videoRef.current) {
      return
    }

    if (videoRef.current.paused) {
      videoRef.current.play()
      setPlaying(true)
    } else {
      videoRef.current.pause()
      setPlaying(false)
    }
  }

  const onVideoEnded = () => {
    setPlaying(false)
  }

  return (
    <div className={`${styles['video-box']} ${props.className || ''}`}>
      <video
        poster={props.thumbnailUrl}
        src={props.videoUrl}
        className={styles.video}
        ref={videoRef}
        onClick={togglePlay}
        onEnded={onVideoEnded}
      />
      {!playing && (
        props.icon || <PlayIcon className={`${styles['icon-centered']} ${styles.icon}`} />
      )}
    </div>
  )
}
