import React, { createElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { userStreamsSelector, userFeedsSelector } from 'services/content/selectors'
import { trackFeedInteraction, addRecentSource, setFeedToSave as setSaveFeed } from 'services/content/recommended/actions'
import { Observable } from 'rxjs/Observable'
import { Subject } from 'rxjs/Subject'
import 'rxjs/add/operator/takeUntil'
import {
  Feed,
  ContentType,
  AnyContent,
  RangeFilter,
  Stream,
  INTERACTION_EVENT_OPEN,
  ContentItem,
  FilterType
} from 'interfaces'
import FeedViewHeader from './FeedViewHeader'
import FeedBricksView, { BricksSize } from './FeedBricksView'
import * as FilterStore from 'utils/filter-store'
import { CONTENT_TYPE_TO_URL_SEGMENT_MAP } from 'services/content/adapters/mappings'

export { BricksSize }

import styles from './FeedView.pcss'
import { catchError } from 'rxjs/operators/catchError'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'

export interface FeedViewProps {
  feed: Feed
  range: RangeFilter
  defaultFilter: FilterType
  basePath: string
  sizes?: BricksSize[]
  align?: 'left' | 'center'
  itemWidth?: number
  withHeader?: boolean
  linkToUserFeedId?: boolean
  skipContentFeatureChecks?: boolean
  onSaveFeed?(feed: Feed): boolean
  onItemPinned?(feed: Feed, content: AnyContent): void
  onItemShared?(feed: Feed, content: AnyContent): void | boolean
  onFilterChanged?(feed: Feed, filter: FilterType): void
  onGotoClicked?(feed: Feed, content: AnyContent, by: string): void
  onRangeChanged?(feed: Feed, range: RangeFilter): void
  onContentItemClick?(item: ContentItem): void
}

export function FeedView(props: FeedViewProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const match = useMatch(`${props.basePath}/:id/:type/:range`)
  const dispatch = useDispatch<StoreThunkDispatch>()
  const streams = useSelector(userStreamsSelector)
  const userFeeds = useSelector(userFeedsSelector)

  const trackInteraction$ = React.useRef<Subject<string>>()

  React.useEffect(() => {
    trackInteraction$.current = new Subject()
    trackInteraction$.current
      .flatMap((id: string) => {
        return Observable.fromPromise(dispatch(trackFeedInteraction({ feedIds: [id], event: INTERACTION_EVENT_OPEN })).unwrap())
          .pipe(catchError(() => Observable.of({ error: true })))
      })
      .subscribe()
    return () => trackInteraction$.current?.unsubscribe()
  }, [])

  const isFeedOwned = React.useMemo(() => {
    const { id, uniqueSource } = props.feed
    return userFeeds.some((feed: any) => feed.uniqueSource === uniqueSource || feed.id === id)
  }, [])

  React.useEffect(() => {
    FilterStore.setFeedFilters(props.feed, props.defaultFilter, props.range)
  }, [props.defaultFilter, props.feed.id, props.range])

  React.useEffect(() => {
    trackInteraction$.current?.next(props.feed.id)
    dispatch(addRecentSource(props.feed))
  }, [props.feed.id])

  const onRangeChanged = (feed: Feed, range: RangeFilter) => {
    navigateTo(undefined, range)

    if (props.onRangeChanged) {
      props.onRangeChanged(feed, range)
    }
  }

  const onFilterChange = (filter: ContentType) => {
    navigateTo(filter)
    if (props.onFilterChanged) {
      props.onFilterChanged(props.feed, filter)
    }
  }

  const navigateTo = (filter?: FilterType, range?: RangeFilter) => {
    const feedId = props.linkToUserFeedId ? props.feed.id : props.feed.uniqueSource
    const filterSlug = filter ? CONTENT_TYPE_TO_URL_SEGMENT_MAP[filter] : match?.params.type
    const rangeSlug = range || match?.params.range
    const nextPath = match?.pattern.path
      .replace(':id', feedId)
      .replace(':type', filterSlug || '')
      .replace(':range', rangeSlug || '')
    const { state, search } = location

    setTimeout(() => {
      navigate(nextPath + search, { state, replace: true })
    })
  }

  const onSaveFeed = () => {
    const preventDefault = props.onSaveFeed && props.onSaveFeed(props.feed)
    if (!preventDefault) {
      dispatch(setSaveFeed(props.feed))
    }
  }

  const onSelectedSourceChange = (sourceUrl: string) => {
    navigate(sourceUrl)
  }

  const { feed, sizes, align, itemWidth, onItemPinned, onItemShared, onGotoClicked, range, withHeader, skipContentFeatureChecks } = props

  const filter = props.feed.sources.find(s => s === props.defaultFilter) ? props.defaultFilter : props.feed.sources[0]

  return createElement('section', { className: styles.folder }, [
    withHeader ? createElement(FeedViewHeader, {
      key: 'header',
      feed,
      allFeeds: userFeeds,
      streams: streams as Stream[],
      ownFeed: isFeedOwned,
      onSave: onSaveFeed,
      onSourceChange: onSelectedSourceChange
    }) : null,
    createElement(FeedBricksView, {
      key: 'items',
      filter,
      feed,
      sizes,
      align,
      itemWidth,
      items: [],
      range,
      skipContentFeatureChecks,
      onItemPinned,
      onItemShared,
      onRangeChanged,
      onFilterChange,
      onGotoClicked,
      onContentItemClick: props.onContentItemClick
    })
  ])
}

export default FeedView
