import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'
import { ContentType } from 'interfaces'

const ENDPOINT_PUBLISH_POSTS = `${LEGACY_URL}/v3/posts/share_now`

export const V1 = Object.freeze({
  publishPosts: function () {
    return function (
      ids: string[],
      ppids: string[],
      postType: 'planned' | 'pending' | 'posted' | 'failed',
      all?: boolean,
      bucketId?: string,
      query?: string,
      contentTypes?: ContentType[]
    ) {
      const builder = new RequestBuilder(ENDPOINT_PUBLISH_POSTS).asPost().expectJSON()
      const data: any = {
        pp_page_ids: ppids,
        type: postType
      }

      if (all) {
        data.selected = 'all'
        data.post_ids = []
      } else {
        data.post_ids = ids
      }

      if (bucketId) {
        data.type = postType === 'planned' ? 'planned_bucket' : postType
        data.bucket_id = bucketId
      }

      if (query) {
        data.search = query
      }
      if (contentTypes) {
        data.post_types = contentTypes.join(',')
      }

      return authorizedPipe<any>(
        builder
          .body(data)
          .build()
      )
    }
  }()
})
