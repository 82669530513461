import { useState, useEffect } from 'react'
import { Observable } from 'rxjs/Observable'

export function useAsyncAction(action: (...args: any) => Observable<any>): [
  (...args: any) => void,
  any,
  Error,
  boolean
] {
  const [requestArgs, setRequestArgs] = useState<any>(null)
  const [response, setResponse] = useState<any>(null)
  const [error, setError] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  function updateArgs(...args: any[]) {
    setRequestArgs(args)
  }

  useEffect(() => {
    if (requestArgs === null) {
      return
    }

    setLoading(true)
    const subscription$ = action(...requestArgs).subscribe(
      (response: any) => {
        setResponse(response)
        setLoading(false)
      }, (error: Error) => {
        setError(error)
        setLoading(false)
      }
    )

    return function cleanup() {
      if (subscription$) {
        subscription$.unsubscribe()
      }
    }
  }, [requestArgs])

  return [updateArgs, response, error, loading]
}

export default useAsyncAction
