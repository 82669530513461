import { ErrorResponse } from '../../types'

function isError(response: any | ErrorResponse): response is ErrorResponse {
  return typeof response === 'object' && 'error' in response
}

export function assert<T>(response: T | ErrorResponse): T {
  if (isError(response)) {
    throw new Error(response.error)
  }

  return response
}
