import React from 'react'
import styles from './LinkedinPostOptions.pcss'
import { ConnectedFileInput } from 'components/FileHelpers'
import FileUpload from 'components/FileUpload'
import { useDispatch, useSelector } from 'react-redux'
import { deleteFile, setIsComposerUploading, setLinkedinDocument } from 'services/compose'
import { linkedinPostSelector } from 'services/compose/selectors'
import { StoreThunkDispatch } from 'store/state'
import { mdiClose, mdiFilePdfBox, mdiFilePowerpointOutline, mdiFileWordOutline } from '@mdi/js'
import Icon from '@mdi/react'
import { LINKEDIN_DOCUMENT_EXTENSIONS, LINKEDIN_DOCUMENT_MIME_TYPES, MAX_LINKEDIN_DOCUMENT_SIZE_BYTES } from 'shared'
import Tooltip from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'
import { message } from 'services/snackbar'
import Button from '@mui/material/Button'

export function LinkedinPostOptions() {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [file, setFile] = React.useState<File | null>(null)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)
  const post = useSelector(linkedinPostSelector)
  const document = post.document

  const icon = React.useMemo(() => {
    const fileExtension = file?.name.substring(file.name.lastIndexOf('.'))
    switch (fileExtension) {
      case '.pdf':
        return mdiFilePdfBox
      case '.doc':
      case '.docx':
        return mdiFileWordOutline
      case '.ppt':
      case '.pptx':
        return mdiFilePowerpointOutline
      default:
        return null
    }
  }, [file?.name])

  const onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const f = e.target.files?.item(0)
    if (f) {
      if (LINKEDIN_DOCUMENT_MIME_TYPES.indexOf(f.type) === -1) {
        dispatch(message('Invalid file type. Please select a PDF, DOC, DOCX, PPT or PPTX file.', 'error'))
        return
      }
      if (f.size > MAX_LINKEDIN_DOCUMENT_SIZE_BYTES) {
        dispatch(message('The file size exceeds the limit. Please select a file smaller than 100MB.', 'error'))
        return
      }
      setFile(f)
      setLoading(true)
      setError(null)
      dispatch(setIsComposerUploading(true))
      if (document) {
        dispatch(deleteFile(document.url)).toPromise()
      }
    }
  }

  const clearFile = () => {
    setFile(null)
    setError(null)
    setLoading(false)
    dispatch(setIsComposerUploading(false))
    dispatch(setLinkedinDocument(undefined))
    if (document) {
      dispatch(deleteFile(document.url)).toPromise()
    }
  }

  const onFileUploaded = (url: string, f: File) => {
    dispatch(setLinkedinDocument({ url, name: f.name }))
    dispatch(setIsComposerUploading(false))
    setLoading(false)
  }

  const onUploadError = (_: any, e: string) => {
    setError(e)
    setLoading(false)
    dispatch(setIsComposerUploading(false))
  }

  return (
    <div className={`${styles['option-box']} ${styles['li-container']}`}>
      {file ? (
        <div className={styles['uploader-box']}>
          <div className={styles['file-info']}>
            <Tooltip title="Remove document">
              <span className={styles['btn-remove-file']} data-testid="btn-remove-doc" onClick={clearFile}>
                <Icon path={mdiClose} size="16px" />
              </span>
            </Tooltip>
            {loading ? <div className={styles.loader}><CircularProgress size={16} color="primary" /></div>
              : icon && <Icon className={styles['icon-doc']} path={icon} size="24px" />}
            <span className={`${styles.filename} text-ellipsis`}>{file.name}</span>
          </div>
          {error && <p className={styles['error-msg']}>{error}</p>}
          <FileUpload
            file={file}
            abortOnUnmount
            skipValidation
            uploadId={file.name}
            withProgress={false}
            className={styles.uploader}
            onFileUploaded={onFileUploaded}
            onError={onUploadError}
          />
        </div>
      ) : (
        <ConnectedFileInput
          accept={LINKEDIN_DOCUMENT_EXTENSIONS.join(',')}
          children={(
            <Button variant="text" className={styles['btn-upload']}>Attach PDF or document</Button>
          )}
          tooltip="Attach PDF, DOCX or PPTX file"
          className={styles['file-input-box']}
          onChange={onFileSelected}
        />
      )}
    </div>
  )
}
