import { PlannedPost, ContentType } from 'interfaces'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Observable } from 'rxjs/Observable'
import { Subject } from 'rxjs/Subject'
import { catchError } from 'rxjs/operators/catchError'
import { tap } from 'rxjs/operators/tap'
import { fetchPlannedPosts, PlannedPostFetchResponseData } from 'services/post/actions'
import {
  selectedProfilesFetchIdsSelector,
  emptySlotsVisibleSelector,
  virtualPostsVisibleSelector,
  plannedPostsLayoutSelector
} from 'services/post/selectors'
import { StoreThunkDispatch } from 'store/state'
import { setPosts, addPosts } from '../actions'
import { PostsAction, postsReducer } from '../reducer'
import { message } from 'services/snackbar'

export function usePlannedPosts(
  search: { query: string, types: ContentType[], searchInSummary: boolean },
  viewFilter: string | undefined,
  onBeforeFetch?: () => void,
  onFetched?: (response: any) => void
) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const selectedProfilesFetchIds = useSelector(selectedProfilesFetchIdsSelector)
  const activeView = useSelector(plannedPostsLayoutSelector)
  const emptySlotsVisible = useSelector(emptySlotsVisibleSelector)
  const virtualPostsVisible = useSelector(virtualPostsVisibleSelector)

  const [posts, postsDispatch] = React.useReducer<React.Reducer<PlannedPost[], PostsAction>>(postsReducer, [])
  const [loading, setLoading] = React.useState(false)
  const [page, setPage] = React.useState(0)
  const [hasNextPage, setHasNextPage] = React.useState(true)
  const [hasNonEmptyPosts, setHasNonEmptyPosts] = React.useState(false)
  const [totalPostsCount, setTotalPostsCount] = React.useState(0)
  const [hasPendingPosts, setHasPendingPosts] = React.useState(false)
  const [refreshKey, setRefreshKey] = React.useState(0)

  const fetch$ = React.useRef<Subject<{
    ids: string[],
    page: number,
    query?: string,
    types?: ContentType[],
    withEmptySlots: boolean,
    filter?: 'scheduled' | 'queued',
    searchInSummary?: boolean,
    withVirtualPosts?: boolean
  }>>()

  React.useEffect(() => {
    fetch$.current = new Subject()
    fetch$.current
      .pipe(
        tap(({ page }) => {
          setLoading(true)
          setPage(page)
        })
      )
      .switchMap(({ ids, page, query, types, withEmptySlots, filter, searchInSummary, withVirtualPosts }) => {
        const f = filter as any === 'planned' ? undefined : filter
        return dispatch(fetchPlannedPosts(page, ids, query, types, withEmptySlots, f, searchInSummary, withVirtualPosts))
          .pipe(
            catchError(() => {
              dispatch(message('Error fetching posts! Please refresh.', 'error'))
              return Observable.of({ error: true })
            })
          )
      })
      .subscribe((response: PlannedPostFetchResponseData) => {
        setLoading(false)
        if (onFetched) {
          onFetched(response)
        }

        if ((response as any).error) {
          return
        }

        setHasNextPage(response.posts.length > 0)
        setHasNonEmptyPosts(current => current || response.posts.some(p => !p.isEmpty))
        setTotalPostsCount(response.totalPostsCount)
        setHasPendingPosts(response.hasPendingPosts)

        const withVisibleIndex = response.posts.map((p, index) => ({ ...p, visibleIndex: index }))
        if (response.page === 0) {
          postsDispatch(setPosts(withVisibleIndex))
        } else {
          postsDispatch(addPosts(response.posts))
        }
      })

    return () => fetch$.current?.unsubscribe()
  }, [dispatch, onFetched])

  // Fetch first page of posts when profile selection changes or posts refresh is requested
  React.useEffect(() => {
    if (selectedProfilesFetchIds.length > 0) {
      const ids = selectedProfilesFetchIds.split(',')
      if (onBeforeFetch) {
        onBeforeFetch()
      }
      fetch$.current?.next({
        page: 0,
        ids,
        query: search.query,
        types: search.types,
        withEmptySlots: emptySlotsVisible,
        filter: viewFilter as any,
        searchInSummary: search.searchInSummary,
        withVirtualPosts: virtualPostsVisible
      })
    }
  }, [
    selectedProfilesFetchIds,
    refreshKey,
    search.query,
    search.types,
    activeView,
    emptySlotsVisible,
    viewFilter,
    search.searchInSummary,
    virtualPostsVisible,
    onBeforeFetch
  ])

  const loadNextPage = React.useCallback(() => {
    if (hasNextPage && !loading) {
      fetch$.current?.next({
        page: page + 1,
        ids: selectedProfilesFetchIds.split(','),
        query: search.query,
        types: search.types,
        filter: viewFilter as any,
        withEmptySlots: emptySlotsVisible,
        searchInSummary: search.searchInSummary,
        withVirtualPosts: virtualPostsVisible
      })
    }
  }, [page, selectedProfilesFetchIds, hasNextPage, loading, search, emptySlotsVisible, viewFilter, virtualPostsVisible])

  const refresh = React.useCallback(() => {
    setRefreshKey(key => key + 1)
  }, [])

  return {
    posts,
    loading,
    hasNonEmptyPosts,
    totalPostsCount,
    hasPendingPosts,
    postsDispatch,
    loadNextPage,
    refresh
  }
}
