import * as React from 'react'
const defaultSize = 16
const darkSizeIncrement = 8
const RADIX = 10

interface TikTokIconProps {
  className?: string
  style?: React.CSSProperties
  size?: string
  dark?: boolean
}

function TikTokIcon({ className, style, size, dark }: TikTokIconProps) {
  const actualSize = React.useMemo(() => {
    const sizeNum = parseInt(size || '0', RADIX)
    if (sizeNum) {
      return dark ? sizeNum + darkSizeIncrement : sizeNum
    }
    return dark ? defaultSize + darkSizeIncrement : defaultSize
  }, [dark, size])

  const styles = Object.assign(
    {
      width: `${actualSize}px`,
      height: `${actualSize}px`,
      backgroundColor: dark ? '#000' : '#fff',
      borderRadius: '50%'
    },
    style,
    dark ? {
      maxWidth: 'initial'
    } : {}
  )

  return (
    <img
      src={dark ? '/static/img/icons/tiktok-dark.svg' : '/static/img/icons/tiktok-icon.svg'}
      style={styles}
      className={className || ''}
    />
  )
}

export default TikTokIcon
