export interface CuratedStream {
  id: string
  name: string
  prettyName: string
  feeds: number
  cover: {
    url: string
  }
  updatedAt: string
}

export const CURATED_STREAM_CATEGORY_POPULAR = 'popular'
export const CURATED_STREAM_CATEGORY_INDUSTRIES = 'industries'
export const CURATED_STREAM_CATEGORY_MY_STREAMS = 'my-streams'

export type CuratedStreamCategory = typeof CURATED_STREAM_CATEGORY_POPULAR
  | typeof CURATED_STREAM_CATEGORY_INDUSTRIES
  | typeof CURATED_STREAM_CATEGORY_MY_STREAMS

export default CuratedStream
