import * as React from 'react'
import { connect } from 'react-redux'
import MostRecentSourceCarousel from './MostRecentSourceCarousel'
import StoreState from 'store/state'
import { mostRecentSourceSelector } from 'services/content/selectors'
import { Stream, FileFolder, Feed, ContentType, RangeFilter } from 'interfaces'
import { SECTION_URL_MOST_RECENT } from '../../home.config'
import { instanceOfFileFolder } from 'services/content/util'
import { getFeedFilters, getStreamFilters, setFeedFilters, setStreamFilters } from 'utils/filter-store'
import { myFileFoldersSelector } from 'services/uploads/selectors'
import { QuoteFeed } from 'interfaces/Content/QuoteFeed'

interface MostRecentSourceCarouselContainerOwnProps {
  title?: string
  navPrefix?: string
  loading?: boolean
}

interface MostRecentSourceCarouselContainerConnectedProps {
  source?: Feed | Stream | FileFolder | QuoteFeed
}

export type MostRecentSourceCarouselContainerProps = MostRecentSourceCarouselContainerOwnProps
  & MostRecentSourceCarouselContainerConnectedProps

function MostRecentSourceCarouselContainer(props: MostRecentSourceCarouselContainerProps) {
  const source = props.source
  const [filter, setFilter] = React.useState<ContentType | undefined>(undefined)
  const [range, setRange] = React.useState<RangeFilter>('week')
  let navPrefix = props.navPrefix || SECTION_URL_MOST_RECENT
  if (source?.isQuoteFeed) {
    navPrefix = `/content/quotes/${(source as any).name}`
  }

  React.useEffect(() => {
    let storedFilter
    if (source?.isFeed) {
      storedFilter = getFeedFilters(source as Feed)
    }
    if (source?.isStream) {
      storedFilter = getStreamFilters(source as Stream)
    }
    if (storedFilter) {
      setFilter(storedFilter.type as ContentType)
      setRange(storedFilter.range)
    }
    if (source?.isFileFolder) {
      setFilter((source as FileFolder).filters[0])
    }
  }, [source])

  React.useEffect(() => {
    if (!filter) {
      return
    }
    if (source?.isFeed) {
      setFeedFilters(source as Feed, filter, range)
    }
    if (source?.isStream) {
      setStreamFilters(source.id, filter, range)
    }
  }, [filter])

  if (!source && props.loading === false) {
    return null
  }

  let filters: ContentType[] = []
  if (source?.isFeed) {
    filters = (source as Feed).sources
  }
  if (source?.isStream || source?.isFileFolder) {
    // remove stock filters for streams
    filters = (source as FileFolder).filters.filter(f => !f.startsWith('stock'))
  }

  return (
    <MostRecentSourceCarousel
      title={props.title}
      source={source}
      navPrefix={navPrefix}
      activeFilter={filter}
      filters={filters}
      onActiveFilterChange={setFilter}
    />
  )
}

function mapStateToProps(state: StoreState) {
  const fileFolders = myFileFoldersSelector(state)
  const mostRecentSource = mostRecentSourceSelector(state)

  return {
    source: mostRecentSource && instanceOfFileFolder(mostRecentSource as any)
      ? fileFolders[mostRecentSource.id]
      : mostRecentSource
  }
}

export default connect(mapStateToProps)(MostRecentSourceCarouselContainer)
