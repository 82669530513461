import { RequestBuilder, authorizedPipe } from 'services/net'
import { CONTENT_SERVICE_URL, LEGACY_URL } from 'config'
import { Stream, Feed, LegacyFolder, InteractionEvent } from 'interfaces'
import { FEED_TYPE_NUMBER_MAP } from 'services/content/adapters/mappings'

export const api = Object.freeze({
  createStream: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/streams`).asPost().withoutCredentials().expectJSON()

    return function (
      name: string,
      userId: string,
      order: number,
      id?: string,
      color?: string,
      feeds?: Feed[],
      isPrivate?: boolean,
      file?: File,
      featuredImage?: string,
      secondaryImages?: string[]
    ) {
      const data = new FormData()
      data.append('origin', 'v3')
      data.append('name', name)
      data.append('order', order.toString())
      data.append('user_id', userId)
      if (isPrivate) {
        data.append('is_private', '1')
      }

      if (id) {
        data.append('original_folder', id)
      }

      if (color) {
        data.append('color', color)
      }

      if (file) {
        data.append('file', file)
      }

      if (featuredImage && !file) {
        data.append('image_url', featuredImage)
      }

      if (secondaryImages && secondaryImages.length > 0) {
        data.append('images', JSON.stringify(secondaryImages))
      }

      if (feeds) {
        data.append('feeds', JSON.stringify(feeds.map(feed => ({
          unique_source: feed.uniqueSource,
          user_id: userId,
          name: feed.name,
          handle: feed.handle,
          type: FEED_TYPE_NUMBER_MAP[feed.type]
        }))))
      }

      return authorizedPipe<Stream>(
        builder
          .form(data)
          .build()
      )
    }
  }(),
  deleteStream: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/users/streams/:streamId`).asDelete().expectJSON().withoutCredentials()

    return function (streamId: string) {
      return authorizedPipe<{ success: string }>(
        builder
          .partial(':streamId', streamId)
          .build()
      )
    }
  }(),
  getStream: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/streams/:streamId`).asGet().expectJSON().withoutCredentials()

    return function (streamId: string, withFeeds?: boolean) {
      return authorizedPipe<LegacyFolder[]>(
        builder
          .param('include_feeds', Boolean(withFeeds).toString())
          .partial(':streamId', streamId)
          .build()
      )
    }
  }(),
  getUserStream: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/users/streams/:streamId`).asGet().expectJSON().withoutCredentials()

    return function (streamId: string) {
      return authorizedPipe<LegacyFolder[]>(
        builder
          .partial(':streamId', streamId)
          .build()
      )
    }
  }(),
  getStreams: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/users/streams`).asGet().expectJSON().withoutCredentials()

    return function () {
      return authorizedPipe<Array<LegacyFolder>>(
        builder.build()
      )
    }
  }(),
  getRandomStreams: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/streams/random_followed`).asGet().expectJSON().withoutCredentials()

    return function (page?: string) {
      return authorizedPipe<Array<LegacyFolder>>(
        builder.param('page', page || '0').build()
      )
    }
  }(),
  reorderStreams: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/content/folders_reorder`).asPost().expectJSON()

    return function (streams: Array<string>) {
      return authorizedPipe<any>(
        builder
          .body({ items: JSON.stringify(streams) })
          .asFormUrlEncoded()
          .build()
      )
    }
  }(),
  updateStream: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/streams`).asPatch().withoutCredentials().expectJSON()

    return function (
      id: string,
      name: string,
      userId: string,
      color?: string,
      file?: File,
      isPrivate?: boolean,
      featuredImage?: string,
      secondaryImages?: string[]
    ) {
      const data = new FormData()
      data.append('id', id)
      data.append('name', name)
      data.append('user_id', userId)

      if (color) {
        data.append('color', color)
      }

      if (file) {
        data.append('file', file)
      }

      if (featuredImage && !file) {
        data.append('image_url', featuredImage)
      }

      if (secondaryImages && secondaryImages.length > 0) {
        data.append('images', JSON.stringify(secondaryImages))
      }

      if (isPrivate !== undefined) {
        data.append('is_private', isPrivate ? '1' : '0')
      }

      return authorizedPipe<Stream>(
        builder
          .form(data)
          .build()
      )
    }
  }(),
  registerStreamInteraction: function () {
    const builder = new RequestBuilder(`${CONTENT_SERVICE_URL}/interactions/streams`).asPost().withoutCredentials().asFormUrlEncoded()

    return function (streamIds: string[], event: InteractionEvent) {
      return authorizedPipe<any>(
        builder
          .body({
            event,
            ids: streamIds.join(',')
          })
          .build()
      )
    }
  }()
})
