import * as React from 'react'
import IconCheck from '@mui/icons-material/Check'
import styles from './PPSelect.pcss'

export function PPSelectItem(props: {
  label: string | React.ReactNode,
  value: string,
  selected: boolean,
  disabled?: boolean,
  onClick: (value: string) => void
}) {
  const onClick = () => {
    if (props.disabled) {
      return
    }
    props.onClick(props.value)
  }

  return (
    <li
      key={props.value}
      data-testid="pp-select-item"
      className={`${styles.li} ${props.disabled ? styles.disabled : ''} ${props.selected ? styles.highlight : ''}`}
      onClick={onClick}
    >
      <div className={`${styles['li-label']} text-ellipsis`}>{props.label}</div>
      {props.selected && <IconCheck color="primary" fontSize="small" className={styles.icon} />}
    </li>
  )
}
