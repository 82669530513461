import React from 'react'
import EmojiPicker from 'components/EmojiPicker'
import { useDispatch, useSelector } from 'react-redux'
import { tap } from 'rxjs/operators/tap'
import { Subject } from 'rxjs/Subject'
import { composerResetKeySelector } from 'services/compose/selectors'
import { checkFeatureAvailability } from 'services/product'
import { FEATURE_POST_IG_FIRST_COMMENT, BRAND_FACEBOOK, BRAND_INSTAGRAM, BRAND_YOUTUBE, BRAND_LINKEDIN } from 'shared/constants'
import styles from './FirstCommentTextBox.pcss'
import {
  setFbFirstComment,
  setInstagramFirstComment,
  setLinkedinFirstComment,
  setMultipostFirstComment,
  setYtFirstComment
} from 'services/compose'
import { useIntl } from 'react-intl'
import { StoreThunkDispatch } from 'store/state'

const DEBOUNCE_TIME = 200
const DEFAULT_HEIGHT = 30

interface FirstCommentTextBoxProps {
  initialValue: string
  network: typeof BRAND_INSTAGRAM | typeof BRAND_FACEBOOK | typeof BRAND_YOUTUBE | typeof BRAND_LINKEDIN
  className?: string
  multiPostId?: string
}

export function FirstCommentTextBox(props: FirstCommentTextBoxProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const intl = useIntl()
  const composerKey = useSelector(composerResetKeySelector)
  const [text, setText] = React.useState(props.initialValue)
  const change$ = React.useRef<Subject<string>>()
  const inputElementRef = React.useRef<HTMLTextAreaElement>(null)
  const didMountRef = React.useRef(false)
  const [height, setHeight] = React.useState(DEFAULT_HEIGHT)

  React.useEffect(() => {
    change$.current = new Subject()
    change$.current
      .pipe(tap(value => setText(value)))
      .debounceTime(DEBOUNCE_TIME)
      .subscribe(value => {
        if (props.multiPostId) {
          dispatch(setMultipostFirstComment({ postId: props.multiPostId, comment: value }))
        } else {
          switch (props.network) {
            case BRAND_INSTAGRAM:
              dispatch(setInstagramFirstComment(value))
              break
            case BRAND_FACEBOOK:
              dispatch(setFbFirstComment(value))
              break
            case BRAND_YOUTUBE:
              dispatch(setYtFirstComment(value))
              break
            case BRAND_LINKEDIN:
              dispatch(setLinkedinFirstComment(value))
          }
        }
      })

    return () => change$.current?.unsubscribe()
  }, [dispatch, props.network, props.multiPostId])

  React.useEffect(() => {
    if (didMountRef.current) {
      change$.current?.next('')
    } else {
      didMountRef.current = true
    }
  }, [composerKey])

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const isFeatureAvailable = (props.network !== BRAND_INSTAGRAM && props.network !== BRAND_FACEBOOK)
      || dispatch(checkFeatureAvailability(FEATURE_POST_IG_FIRST_COMMENT))
    if (!isFeatureAvailable) {
      return
    }
    const value = e.target.value
    const scrollHeight = inputElementRef.current?.scrollHeight
    const clientHeight = inputElementRef.current?.clientHeight

    if (scrollHeight && scrollHeight !== clientHeight) {
      const h = scrollHeight + 2 // EXPL: add 2px to avoid scrollbar
      setHeight(h)
    }
    if (!value) {
      setHeight(DEFAULT_HEIGHT)
    }
    change$.current?.next(value)
  }

  const insertEmoji = React.useCallback((emoji: any) => {
    const selection = getSelection()
    if (inputElementRef.current && selection) {
      const currentValue = inputElementRef.current?.value
      const focusNode = selection.focusNode as any
      const focusWithinEditor = Boolean(focusNode?.querySelector('[data-ig-text]'))
      if (focusWithinEditor) {
        const selectionStart = inputElementRef.current.selectionStart
        inputElementRef.current.value = currentValue.slice(0, selectionStart)
          + emoji.native
          + currentValue.slice(selectionStart)
        // EXPL: use setTimeout to avoid weird bug with resetting selection after change
        setTimeout(() => {
          if (inputElementRef.current) {
            inputElementRef.current.selectionStart = selectionStart + emoji.native.length
            inputElementRef.current.selectionEnd = selectionStart + emoji.native.length
          }
        })
      } else {
        inputElementRef.current.value = currentValue + emoji.native
      }
      change$.current?.next(inputElementRef.current.value)
    }
  }, [])

  return (
    <div className={`${styles.box} ${props.className || ''}`} data-testid="first-comment-textbox" data-network={props.network}>
      <textarea
        ref={inputElementRef}
        value={text}
        style={{ height: `${height}px` }}
        data-ig-text
        className={styles.input}
        placeholder={intl.formatMessage({ id: 'composer.placeholder.first-comment' })}
        onChange={onChange}
      />
      <EmojiPicker
        className={styles['btn-emoji']}
        onSelect={insertEmoji}
      />
    </div>

  )
}

export default FirstCommentTextBox
