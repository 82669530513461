import { StoreThunkDispatch } from 'store/state'
import * as api from './net'
import { Prompt, sortByKeyAscending } from 'shared'
import { tap } from 'rxjs/operators/tap'
import { format } from 'date-fns'
import ComposerState from '../state'
import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit'

function promptsAdapter(response: any): Prompt[] {
  const today = new Date().toISOString()

  return response
    .map((item: any, index: number) => {
      const date = item.dcFormatted || item.dc || today
      const createdAt = new Date(date).getTime()
      const response: string[] = []
      const images: string[] = []
      const text = item.response || item.text
      const isImage = item.feature === 'images'
      try {
        if (isImage) {
          images.push(...JSON.parse(item.response))
        } else {
          response.push(...JSON.parse(item.response))
        }
      } catch (_e) {
        if (!isImage) {
          response.push(text)
        }
      }

      return ({
        id: item.id,
        originalPromptId: item.promptId || item.id,
        text,
        prompt: item.text,
        categoryId: item.categoryId,
        order: index,
        createdAt,
        response,
        images,
        isImage,
        imageUrl: item.imageUrl,
        dateFormatted: format(createdAt, 'EEEE, LLLL do')
      })
    })
}

const setUserPrompts = createAction<Prompt[]>('composer/assistant/setUserPrompts')
const userPromptDeleted = createAction<string>('composer/assistant/userPromptDeleted')
const setFavotitePrompts = createAction<Prompt[]>('composer/assistant/setFavoritePrompts')
const addedFavoritePrompt = createAction<Prompt>('composer/assistant/addedFavoritePrompt')
const removedFavoritePrompt = createAction<string>('composer/assistant/removedFavoritePrompt')

export const assistantExtraReducers = (builder: ActionReducerMapBuilder<ComposerState>) => {
  builder
    .addCase(setUserPrompts, (state, action) => {
      state.writer.userPrompts = action.payload
    })
    .addCase(userPromptDeleted, (state, action) => {
      state.writer.userPrompts = state.writer.userPrompts.filter(prompt => prompt.id !== action.payload)
    })
    .addCase(setFavotitePrompts, (state, action) => {
      state.writer.favoritePrompts = action.payload
    })
    .addCase(addedFavoritePrompt, (state, action) => {
      state.writer.favoritePrompts.push(action.payload)
    })
    .addCase(removedFavoritePrompt, (state, action) => {
      state.writer.favoritePrompts = state.writer.favoritePrompts.filter(prompt => prompt.id !== action.payload)
    })
}

export function getPromptCategories(admin?: boolean) {
  return (dispatch: StoreThunkDispatch) => dispatch(api.getPromptCategories(admin)).map(response => {
    return response.map((item: any) => ({
      ...item,
      isVisible: item.isVisible === '1',
      order: parseInt(item.order, 10)
    })).sort(sortByKeyAscending('order'))
  })
}

export function generateCaptions(text: string, promptId?: string, variations?: number, imageUrl?: string, getImage?: boolean) {
  return (dispatch: StoreThunkDispatch) => dispatch(api.getCaptionSuggestions(text, promptId, variations, imageUrl, getImage))
}

export function getUserPrompts() {
  return (dispatch: StoreThunkDispatch) => dispatch(api.getUserPrompts()).pipe(tap(response => {
    dispatch(setUserPrompts(promptsAdapter(response)))
  }))
}

export function deleteUserPrompt(id: string) {
  return (dispatch: StoreThunkDispatch) => dispatch(api.deleteUserPrompt(id)).pipe(tap(() => {
    dispatch(userPromptDeleted(id))
  }))
}

export function getFavoritePrompts() {
  return (dispatch: StoreThunkDispatch) => dispatch(api.getFavoritePrompts()).pipe(tap(response => {
    const p = response.filter((item: any) => Boolean(item.text))
    dispatch(setFavotitePrompts(promptsAdapter(p)))
  }))
}

export function addFavoritePrompt(prompt: Prompt | { text: string, imageUrl?: string }) {
  return (dispatch: StoreThunkDispatch) => {
    const id = (prompt as Prompt).id
    return dispatch(api.addFavoritePrompt(id, id ? undefined : prompt.text, id ? undefined : (prompt as any).imageUrl))
      .pipe(tap((response) => {
        dispatch(addedFavoritePrompt(id ? prompt as Prompt : promptsAdapter([response])[0]))
      }))
  }
}

export function removeFavoritePrompt(id: string) {
  return (dispatch: StoreThunkDispatch) => dispatch(api.removeFavoritePrompt(id))
    .pipe(tap(() => {
      dispatch(removedFavoritePrompt(id))
    }))
}

export function getPrompts(categoryId?: string, admin?: boolean) {
  return (dispatch: StoreThunkDispatch) => dispatch(api.getPrompts(categoryId, admin)).map(response => {
    return response.map((item: any) => ({
      ...item,
      order: parseInt(item.order, 10)
    })).sort(sortByKeyAscending('order'))
  })
}
