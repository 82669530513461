import StoreState, { StoreThunkDispatch } from 'store/state'
import { userStreamsSelector, userFeedsSelector, selectedFeedsToSaveSelector } from 'services/content/selectors'
import { currentUserSelector } from 'services/users/selectors'
import { getStreams, createStream, getUserStream } from 'services/content/streams/actions'
import { addFeeds, deleteFeed } from 'services/content/feeds/actions'
import { clearFeedToSave, trackFeedInteraction } from 'services/content/recommended/actions'
import { Feed, Stream } from 'interfaces'
import { checkFeatureAvailability } from 'services/product'
import { message } from 'services/snackbar'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { SaveFeedDialog } from './SaveFeedDialog'
import { SnackType } from 'services/snackbar/interfaces/PendingTreat'
import { Observable } from 'rxjs/Observable'
import { clearSelectedFeedsToSave } from 'services/content'

const mapStateToProps = (state: StoreState) => ({
  selectedFeeds: selectedFeedsToSaveSelector(state) as Feed[],
  streams: userStreamsSelector(state),
  feeds: userFeedsSelector(state),
  userId: currentUserSelector(state)?.userId
})

const mapDispatchToProps = (dispatch: StoreThunkDispatch) => ({
  addFeeds: (feeds: Feed[], stream: Stream) => Observable.fromPromise(dispatch(addFeeds({ feeds, stream })).unwrap())
    .flatMap(() => Observable.fromPromise(dispatch(getUserStream({ streamId: stream.id, force: true })).unwrap()))
    .catch((error: Error) => {
      dispatch(message('Error saving feeds: ' + error.message, 'error'))
      return Observable.fromPromise(dispatch(getUserStream({ streamId: stream.id, force: true })).unwrap())
    }),
  createStream: (
    name: string,
    color?: string,
    feeds?: Feed[],
    isPrivate?: boolean,
    file?: File,
    featuredImage?: string,
    secondaryImages?: string[]
  ) => {
    return dispatch(createStream({ name, id: undefined, color, feeds, isPrivate, file, featuredImage, secondaryImages })).unwrap()
  },
  removeFeed: (id: string) => Observable.fromPromise(dispatch(deleteFeed({ feedId: id })).unwrap()),
  getUserStreams: (force?: boolean) => dispatch(getStreams(force)).unwrap(),
  onClose: () => {
    dispatch(clearFeedToSave())
    dispatch(clearSelectedFeedsToSave())
  },
  trackFeedInteraction: (ids: string[]) => dispatch(trackFeedInteraction({ feedIds: ids, event: 'open' })).unwrap(),
  checkFeatureAvailability: (feature: string) => dispatch(checkFeatureAvailability(feature)),
  displayNotification: (text: string, type?: SnackType) => dispatch(message(text, type))
})

const ConnectedSaveFeedDialog = connect(mapStateToProps, mapDispatchToProps)(SaveFeedDialog)
export default injectIntl(ConnectedSaveFeedDialog)
