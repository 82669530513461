import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'
import { ContentType } from 'interfaces'

const ENDPOINT_UPDATE_RECYCLE_POST = `${LEGACY_URL}/v3/posts/edit_re_queue`
const ENDPOINT_RECYCLE_POSTS = `${LEGACY_URL}/v3/posts/bulk_recycle`

export const V1 = Object.freeze({
  updateRecycle: function () {
    return function (postId: string, value: boolean | Date | number) {
      const builder = new RequestBuilder(ENDPOINT_UPDATE_RECYCLE_POST).asPost().expectJSON()
      const data: any = {
        post_id: postId,
        recycle: Boolean(value)
      }

      if (typeof value === 'object') {
        data.recycle_until = (value as Date).toUTCString()
      } else if (typeof value === 'number') {
        data.recycle_times = value
      }

      return authorizedPipe<any>(
        builder
          .body(data)
          .build()
      )
    }
  }(),

  recyclePosts: function () {
    return function (
      ids: string[],
      ppids: string[],
      postsType: 'queued' | 'pending' | 'scheduled' | 'failed' | 'planned',
      all?: boolean,
      bucketId?: string,
      query?: string,
      types?: ContentType[],
      value?: boolean | Date | number
    ) {
      const endpoint = ENDPOINT_RECYCLE_POSTS
      const builder = new RequestBuilder(endpoint).asPost().expectJSON()
      const data: any = {
        type: postsType,
        pp_page_ids: ppids,
        selected_count: ids.length.toString()
      }

      if (all) {
        data.selected = 'all'
      } else {
        data.post_ids = ids
      }

      if (bucketId) {
        data.bucket_id = bucketId
      }

      if (value) {
        data.recycle = true
        if (typeof value === 'object') {
          data.recycle_until = (value as Date).toUTCString()
        } else if (typeof value === 'number') {
          data.recycle_times = (value as number).toString()
        }
      } else {
        data.recycle = false
      }

      if (query) {
        data.search = query
      }

      if (types) {
        data.post_types = types.join(',')
      }

      return authorizedPipe<any>(
        builder
          .body(data)
          .build()
      )
    }
  }()
})
