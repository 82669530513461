import { PlannedPost, PendingPost, PHOTO_TYPE, VIDEO_TYPE, ARTICLE_TYPE, ContentType } from 'interfaces'
import { StoreThunkAction, StoreThunkDispatch } from 'store/state'
import { Observable } from 'rxjs/Observable'
import { V1 } from './net'
import { postsResponseAdapter, autoPostSlotAdapter, postAdapter } from '../adapters/posts'
import { AutoPostSlot } from '../state'
import { tap } from 'rxjs/operators/tap'
import { mapPostsToBuckets } from './mapPostsToBuckets'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

export const getPostSlotsSuccess = createAction<{ slots: AutoPostSlot[], page: number }>('posts/planned/getSlotsSuccess')

/** FETCH Planned */
const PAGE_SIZE_DEFAULT = 50
export type PlannedPostFetchResponseData = {
  posts: PlannedPost[],
  totalPostsCount: number,
  hasPendingPosts: boolean,
  page: number
}

export function fetchPlannedPosts(
  page: number,
  pageIds: string[],
  query?: string,
  types?: ContentType[],
  withEmptySlots?: boolean,
  filter?: 'scheduled' | 'queued',
  searchInSummary?: boolean,
  withVirtualPosts?: boolean
): StoreThunkAction<Observable<PlannedPostFetchResponseData>> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(
      V1.getPlannedPosts(page, pageIds, PAGE_SIZE_DEFAULT, query, types, withEmptySlots, filter, searchInSummary, withVirtualPosts)
    )
      .pipe(tap((response) => {
        const slots = response.posts.filter(p => p.autoPost).map(autoPostSlotAdapter)
        dispatch(getPostSlotsSuccess({ slots, page }))
      }))
      .map(response => {
        const posts: PlannedPost[] = postsResponseAdapter<PlannedPost>(response)
        const filteredPosts = posts.reduce((result: PlannedPost[], post: PlannedPost) => {
          if (!post.isEmpty) {
            result.push(post)
            return result
          }

          if (post.buckets.length === 0) {
            return result
          } if (post.network === 'instagram') {
            const buckets = post.buckets.filter(b => b === PHOTO_TYPE || b === VIDEO_TYPE)
            if (buckets.length > 0) {
              result.push({ ...post, buckets })
            }
          } else if (post.network === 'pinterest') {
            const buckets = post.buckets.filter(b => b === PHOTO_TYPE || b === ARTICLE_TYPE)
            if (buckets.length > 0) {
              result.push({ ...post, buckets })
            }
          } else {
            result.push(post)
          }

          return result
        }, [])
        return {
          posts: dispatch(mapPostsToBuckets(filteredPosts)),
          totalPostsCount: response.postsCount,
          hasPendingPosts: response.hasPendingPosts,
          page
        }
      })
  }
}

type PendingResponse = { posts: PendingPost[], page: number, totalPostsCount: number }
/** FETCH Pending */
export function fetchPendingPosts(
  page: number, pageIds: string[], query?: string, types?: ContentType[], searchInSummary?: boolean
): StoreThunkAction<Observable<PendingResponse>> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.getPendingPosts(page, pageIds, query, types, searchInSummary))
      .map(response => {
        const posts = postsResponseAdapter<PendingPost>(response)
        return ({
          posts: dispatch(mapPostsToBuckets(posts)),
          page,
          totalPostsCount: response.postsCount
        })
      })
  }
}

export function fetchPlannedPostsForRange(
  ppids: string[],
  startDate: string,
  endDate: string,
  withEmptySlots?: boolean,
  withVirtualPosts?: boolean
): StoreThunkAction<Observable<{ posts: PlannedPost[], totalPostsCount: number }>> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.getPlannedPostsForRange(startDate, endDate, ppids, withEmptySlots, withVirtualPosts))
      .map(response => {
        const posts = postsResponseAdapter<PlannedPost>(response)
        return {
          posts: dispatch(mapPostsToBuckets(posts)),
          totalPostsCount: response.postsCount,
          hasPendingPosts: response.hasPendingPosts
        }
      })
  }
}

export const getPostById = createAsyncThunk('posts/planned/getById', async (postId: string, { dispatch }) => {
  const response = await dispatch(V1.getPostById(postId)).toPromise()
  const post = postAdapter<PlannedPost>(response, 0)
  const [postWithBucket] = dispatch(mapPostsToBuckets([post]))
  return postWithBucket
})
