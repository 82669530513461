import toCamelCase from 'utils/toCamelCase'
import { PPProduct, User, sortByKeyAscending } from 'shared'
import StoreState, { StoreThunkDispatch, StoreThunkAction, getState } from 'store/state'
import { getAccountInfo } from 'services/settings/actions'
import { impersonationSelector } from 'admin/services/selectors'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import * as api from './net'
import { TrainingVideo } from 'admin/interfaces'
import { trainingVideosSelector } from './selectors'
import { BasePickerProps } from '@mui/x-date-pickers/internals'

const BASE_TEN = 10

export const getUserSuccess = createAction(
  'ACTION_GET_USER_SUCCESS',
  (payload: any, product: PPProduct) => {
    const user: User = toCamelCase(payload) as any
    const v3 = (user as any).v3

    return {
      payload: {
        ...user,
        lastLoginTime: Date.now(),
        v3: v3 !== null && v3 > 0,
        v3Status: parseInt(v3, BASE_TEN),
        productInfo: product,
        finishedSetup: parseInt(user.finishedSetup as any, BASE_TEN)
      }
    }
  }

)

export function checkSubscription(): StoreThunkAction<Promise<any>> {
  return (dispatch: StoreThunkDispatch, getState: getState) => {
    const isImpersonating = impersonationSelector(getState()) !== undefined
    return dispatch(getAccountInfo())
      .unwrap()
      .then((data: any) => {
        const productInfo = data.productInfo
        if (isImpersonating) {
          return
        }

        dispatch(checkSubscriptionSuccess(productInfo.name, productInfo, undefined, data.trialEndsAt))
      })
      .catch((error: any) => {
        console.log('[checkSubscription()] Error: ', error)
      })
  }
}

export const checkSubscriptionSuccess = createAction(
  'ACTION_CHECK_SUBSCRIPTION_SUCCESS',
  (product: string, productInfo: PPProduct, roles?: { isAdmin: boolean, isBeta: boolean }, trialEndsAt?: string) => ({
    payload: {
      product,
      productInfo,
      roles,
      trialEndsAt
    }
  })
)

export const updateUserProfile = createAsyncThunk(
  'user/updateProfile',
  async (args: { firstName?: string, lastName?: string, introProgress?: number, email?: string }, { dispatch, rejectWithValue }) => {
    const { firstName, lastName, introProgress, email } = args
    const response = await dispatch(api.updateProfile(firstName, lastName, introProgress, email)).toPromise()
    if (response.error) {
      return rejectWithValue(response.error.message)
    }
    return response
  }
)

export const updateIntroProgress = createAsyncThunk(
  'user/updateIntroProgress',
  async (step: number, { dispatch }) => {
    await dispatch(api.updateProfile(undefined, undefined, step)).toPromise()
    return step
  }
)

export const getTrialTasks = createAsyncThunk(
  'training/getTasks',
  async (_, { dispatch }) => {
    const data = await dispatch(api.getTrialEvents()).toPromise()

    const formatted = data.map((task: any) => ({
      ...task,
      order: parseInt(task.order, 10),
      value: task.totalQuantity,
      maxValue: task.maxQuantity,
      discount: task.percent,
      completed: Boolean(task.completed)
    })).sort(sortByKeyAscending('order'))

    return formatted
  }
)

export const getTrialTaskGroups = createAsyncThunk(
  'trial/getTaskGroups',
  async (_, { dispatch }) => {
    const response = await dispatch(api.getTrialEventGroups()).toPromise()
    return response.map((data: any) => data.map((group: any) => ({
      ...group,
      order: parseInt(group.order, 10),
      tasks: []
    })))
  }
)

export const fetchCouponCode = createAsyncThunk(
  'trial/fetchCouponCode',
  async () => {
    const response = await api.getCouponCode()
    return response
  }
)

export const fetchTrainingVideos = createAsyncThunk(
  'training/fetchTrainingVideos',
  async (sectionId: string = '', { dispatch }) => {
    const res = await dispatch(api.getTrainingVideos(sectionId)).toPromise()
    return res
  }
)

export const fetchTrainingVideoSections = createAsyncThunk(
  'training/fetchTrainingVideoSections',
  async (force: boolean = false, { dispatch, getState }) => {
    if (!force) {
      const cached = trainingVideosSelector(getState() as StoreState)
      if (cached) {
        return cached
      }
    }
    const [sections, videos] = await Promise.all([
      dispatch(api.getTrainingVideoSections()).toPromise(),
      dispatch(api.getTrainingVideos()).toPromise()
    ])

    const vidsBySection = videos.reduce((acc: Record<string, TrainingVideo[]>, video: any) => {
      if (!acc[video.sectionId]) {
        acc[video.sectionId] = []
      }
      acc[video.sectionId].push({
        id: video.id,
        url: video.url,
        title: video.title,
        order: parseInt(video.order, 10),
        sectionId: video.sectionId,
        thumbnailUrl: video.thumbnailUrl
      })
      return acc
    }, {} as Record<string, TrainingVideo[]>)

    const formatted = sections.map((section: any) => ({
      id: section.id,
      title: section.title,
      order: parseInt(section.order, 10),
      slug: section.slug || section.title.toLowerCase().split(' ').join('-'),
      label: section.label || section.title,
      isVisible: section.isVisible === '1',
      videos: (vidsBySection[section.id] || []).sort(sortByKeyAscending('order'))
    })).sort(sortByKeyAscending('order'))

    return formatted
  }
)

export { trackTrainingEvent } from './tracking'
