import React from 'react'
import styles from '../AppNavigation.pcss'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { fetchTrainingVideoSections } from 'services/users/actions'
import { trainingVideosSelector } from 'services/users/selectors'
import { AppNavLink } from '../AppNavLink'

export function TrainingNav() {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const sections = useSelector(trainingVideosSelector)

  React.useEffect(() => {
    dispatch(fetchTrainingVideoSections())
  }, [dispatch])

  return (
    <nav>
      <h1 className={styles['subnav-title']}>
        <FormattedMessage id="app.nav.title-training" />
      </h1>
      <AppNavLink to="/training" label="Getting Started" end />
      {sections.map(section => (
        <AppNavLink
          to={`/training/${section.slug}`}
          label={section.label}
        />
      ))}
    </nav>
  )
}
