export function debounce(fn: Function, wait: number, immediate?: boolean) {
  let timeout: any

  return function (...args: any) {
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      timeout = null
      if (!immediate) fn.apply(null, args)
    }, wait)
    if (immediate && !timeout) fn.apply(null, args)
  }
}
