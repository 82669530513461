import { initialize, InitializeOptions } from 'react-ga'
import { GOOGLE_ANALYTICS_TRACKING_ID } from 'config'

export function initializeGoogleAnalytics(options?: InitializeOptions) {
  if (!GOOGLE_ANALYTICS_TRACKING_ID) {
    console.error('[google-analytics] Initialize failed: tracking id must be provided')
    return
  }

  initialize(GOOGLE_ANALYTICS_TRACKING_ID, { ...options })
}
