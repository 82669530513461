import React from 'react'
import styles from './ImageCarousel.pcss'
import Icon from '@mdi/react'
import { mdiChevronRight, mdiChevronLeft, mdiClose } from '@mdi/js'

interface ImageCarouselProps {
  images: string[]
  activeImageIndex: number
  className?: string
  onClose?: () => void
  onActiveImageIndexChange: (index: number) => void
}

export function ImageCarousel({ images, activeImageIndex, className, onClose, onActiveImageIndexChange }: ImageCarouselProps) {
  const style = {
    backgroundImage: `url(${images[activeImageIndex]})`
  }

  const prev = () => {
    if (activeImageIndex > 0) {
      onActiveImageIndexChange(activeImageIndex - 1)
    }
  }

  const next = () => {
    if (activeImageIndex < images.length - 1) {
      onActiveImageIndexChange(activeImageIndex + 1)
    }
  }

  const close = () => {
    if (onClose) {
      onClose()
    }
  }

  const hasNext = activeImageIndex < images.length - 1
  const hasPrev = activeImageIndex > 0

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.carousel}>
        <div className={styles.image} style={style}></div>
      </div>
      {images.length > 1 && (
        <div className={styles.actions}>
          <span onClick={prev}>
            <Icon path={mdiChevronLeft} size="20px" className={`${styles.btn} ${!hasPrev ? styles.disabled : ''}`} />
          </span>
          <span onClick={next}>
            <Icon path={mdiChevronRight} size="20px" className={`${styles.btn} ${!hasNext ? styles.disabled : ''}`} />
          </span>
        </div>
      )}
      {onClose && (
        <span className={styles['btn-close']} onClick={close}>
          <Icon path={mdiClose} size="16px" className={styles.btn} />
        </span>
      )}
    </div>
  )
}
