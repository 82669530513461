import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { SignupContext } from '../state/context'
import styles from './PaymentDetails.pcss'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import { activeProductsSelector } from 'services/product'
import { ProductSelect } from 'components/ProductSelect'
import { message } from 'services/snackbar'
import { Navigate, useNavigate } from 'react-router-dom'
import { StoreThunkDispatch } from 'store/state'
import { checkout } from 'services/checkout'
import { PromoCode, PromoCodeValidator } from './PromoCodeValidator'
import { config } from './chargify.config'
import { PPSwitch } from 'components/PPSwitch'
import { CURRENCY_SIGN_MAP, sortDescending } from 'shared'
import { currentUserSelector } from 'services/users/selectors'
import { parse } from 'query-string'
import { LEGACY_URL } from 'config'
import { CheckoutProgress } from '../CheckoutProgress'
import { PaymentDetailsSidebar } from './PaymentDetailsSidebar'

declare const __DEV__: boolean

export function PaymentDetails() {
  const dataLayer = (window as any).dataLayer || [] // Google Tag Manager dataLayer
  const [loading, setLoading] = useState(false)
  const chargifyForm = useRef<HTMLFormElement>(null)
  const chargifyRef = useRef(new (window as any).Chargify())
  const dispatch = useDispatch<StoreThunkDispatch>()
  const navigate = useNavigate()
  const user = useSelector(currentUserSelector)
  const isNoCCTrial = ['active', 'expired'].includes(user?.account.trial as any) && !user?.account.hasChargifyInfo
  const [{ paymentViewConfig, account }] = useContext(SignupContext)
  const initialCurrency = account?.currency || user?.currency || 'usd'
  const [selectedCurrency, setSelectedCurrency] = React.useState<'usd' | 'inr'>(initialCurrency)
  const [codeBoxOpen, setCodeBoxOpen] = useState(false)
  const [couponCode, setCouponCode] = useState<PromoCode>({ value: '', isValid: null })
  const [referralCode, setReferralCode] = useState<PromoCode>({ value: '', isValid: null })
  const products = useSelector(activeProductsSelector)
    .filter(p => p.order !== 0)
    .sort(sortDescending('order'))
  const queryParams = parse(location.search)
  const queryHandle = queryParams.plan?.toString() || ''
  const handle = account?.productHandle || queryHandle || products[1].handles.annual
  const initialSelectedProduct = { handle, isAnnual: handle.indexOf('annual') !== -1 }
  const [selectedProduct, setSelectedProduct] = useState<{ handle: string, isAnnual: boolean }>(initialSelectedProduct)
  const [tosAccepted, setTosAccepted] = useState(false)
  const [chargifyLoaded, setChargifyLoaded] = useState(false)
  const [orderCompleted, setOrderCompleted] = useState(false)

  const selectedProductInfo = products.find(p => Object.values(p.handles).includes(selectedProduct.handle))
  let currentPrice = selectedProductInfo?.price[`${selectedProduct.isAnnual ? 'annual' : 'monthly'}`][selectedCurrency] || 0
  if (currentPrice && couponCode.isValid && couponCode.discountPercentage) {
    currentPrice = currentPrice - currentPrice * couponCode.discountPercentage
  } else if (currentPrice && referralCode.isValid && referralCode.discountPercentage) {
    currentPrice = currentPrice - currentPrice * referralCode.discountPercentage
  }

  useEffect(() => {
    // NOTE: If customer hasn't placed an order and leaves the page for some other reason, register a lost deal
    const registerLostDeal = () => {
      if (!orderCompleted) {
        const data = new FormData()
        data.append('first_name', account?.firstName || user?.name)
        data.append('last_name', account?.lastName || user?.lastName)
        data.append('email', account?.email || user?.email)
        data.append('product_name', selectedProductInfo?.name || '')
        data.append('deal_stage', 'closedlost')
        data.append('deal_amount', currentPrice.toString())
        data.append('deal_currency', selectedCurrency)
        navigator.sendBeacon(`${LEGACY_URL}/v3/signup/register_lost_deal`, data)
      }
    }
    window.addEventListener('unload', registerLostDeal, false)
    return () => {
      window.removeEventListener('unload', registerLostDeal)
    }
  }, [account, user, currentPrice, orderCompleted, selectedCurrency, selectedProductInfo?.name])

  useEffect(() => {
    const chargify = chargifyRef.current
    chargify?.load(config(paymentViewConfig, selectedCurrency)).then(() => {
      setTimeout(() => {
        setChargifyLoaded(true)
      }, 500) // eslint-disable-line no-magic-numbers
    })
    return () => chargify?.unload()
  }, [paymentViewConfig, selectedCurrency])

  const onCouponValidationDone = useCallback((code: PromoCode) => {
    setCouponCode(code)
  }, [])

  const onReferralValidationDone = useCallback((code: PromoCode) => {
    setReferralCode(code)
  }, [])

  // NOTE: Redirect to first step if it's not completed and there's no active user
  if (!account && !user) {
    return <Navigate to="/signup/create-account" replace />
  }

  const toggleTosAccepted = () => {
    setTosAccepted(!tosAccepted)
  }

  const submit = () => {
    if (!tosAccepted) {
      dispatch(message('Please read and accept the Terms of Service first.', 'warning'))
      return
    }

    setLoading(true)
    chargifyRef.current.token(
      chargifyForm.current,
      (token: string) => {
        const cCode = couponCode.isValid ? couponCode.value : undefined
        const rCode = referralCode.isValid ? referralCode.value : undefined
        const userInfo = account || {
          firstName: user?.name,
          lastName: user?.lastName,
          email: user?.email
        }
        dispatch(checkout(userInfo, selectedProduct.handle, token, selectedCurrency, cCode, rCode)).subscribe(
          (response: any) => {
            setLoading(false)
            const msg = response?.error?.message || 'An error ocurred. Please try again or submit a support request.'
            if (response.completed) {
              setOrderCompleted(true)
              dataLayer.push({
                'event': 'pp.form_submission',
                'event_parameters': {
                  'form': 'payment_details',
                  'plan': selectedProduct.handle,
                  'trial_type': 'cc_trial'
                }
              })
              if ((window as any).rewardful && !(window as any).Cypress) {
                (window as any).rewardful('convert', { email: userInfo.email })
              }
              setTimeout(() => {
                navigate(`/login${account?.loginOtt ? '?ott=' + account.loginOtt : ''}`)
              }, 250) // eslint-disable-line no-magic-numbers
            } else {
              dispatch(message(msg, 'error'))
            }
          },
          (error) => {
            dispatch(message('An error ocurred. Please try again or submit a support request.', 'error'))
            console.log(error)
            setLoading(false)
          }
        )
      },
      (error: any) => {
        let msg = error.message || ''
        const errors = error.errors
        if (!msg && errors) {
          msg = typeof errors === 'string' ? errors : errors.join(' ')
        }
        dispatch(message(`${msg} Please try again.`, 'error'))
        setLoading(false)
        console.log('{host} token ERROR - err: ', error)
      }
    )
  }

  const toggleCodeBox = () => {
    setCodeBoxOpen(!codeBoxOpen)
  }

  const onReferralCodeValueChange = (value: string) => {
    setReferralCode({ value, isValid: null })
    setCouponCode({ value: '', isValid: null })
  }

  const onCouponCodeValueChange = (value: string) => {
    setCouponCode({ value, isValid: null })
    setReferralCode({ value: '', isValid: null })
  }

  const currencyOptions = [{
    value: 'usd',
    label: 'USD'
  }, {
    value: 'inr',
    label: 'INR'
  }]

  const isNewAccount = !account && ['active', 'expired'].includes(user?.account.trial as any)
  const todaysPrice = isNoCCTrial ? currentPrice.toFixed(2).replace('.00', '') : '0'
  const title = user?.account.hasChargifyInfo ? paymentViewConfig.titleCC : paymentViewConfig.title

  return (
    <div>
      {!isNoCCTrial && <CheckoutProgress activeStep="payment-details" />}
      <section className={`${styles.layout} ${chargifyLoaded ? '' : styles.hidden}`}>
        <h1 className={styles.title} style={paymentViewConfig.titleStyles}>{title}</h1>
        <div className={styles.content}>
          <div className={styles.sidebar}>
            {/* <img src={`${imageUrl}?v=${Date.now()}`} className={styles['sidebar-img']} /> */}
            {selectedProductInfo && (
              <PaymentDetailsSidebar
                plan={selectedProductInfo}
                currency={selectedCurrency}
                isAnnual={selectedProduct.isAnnual}
                isTrial={!isNewAccount}
                isNoCCTrial={isNoCCTrial}
                price={currentPrice}
              />
            )}
          </div>
          <form id="chargify-form" ref={chargifyForm} className={styles.form} data-testid="chargify-form">
            <div className={`${styles.row} ${styles.pad}`}>
              <div className={styles['flex-100']}>
                <div className={styles.label} style={paymentViewConfig.inputLabelStyles}>Select Plan</div>
                <div className={styles['select-box']}>
                  <ProductSelect
                    products={products}
                    currency={selectedCurrency}
                    selectedProduct={selectedProduct}
                    className={styles['plan-select']}
                    menuItemClassName={styles['product-li']}
                    MenuProps={{
                      classes: { list: styles.menu }
                    }}
                    onSelectionChange={setSelectedProduct}
                  />
                </div>
                <p className={styles['plan-note']}>
                  {paymentViewConfig.planSelectHint}
                </p>
              </div>
            </div>
            {__DEV__ && (
              <div className={`${styles.row} ${styles.pad}`}>
                <div className={styles['flex-100']}>
                  <div className={styles.label} style={paymentViewConfig.inputLabelStyles}>Select Currency</div>
                  <PPSwitch
                    options={currencyOptions}
                    onSelectedValueChange={setSelectedCurrency as any}
                    selectedValue={selectedCurrency}
                  />
                </div>
              </div>
            )}
            <div className={styles.row}>
              <div id="card-info-box" data-testid="card-number-box" className={styles.cfield}></div>
            </div>
            <div className={styles.row}>
              <div className={styles['flex-25']}>
                <div id="card-exp-month" data-testid="card-exp-month-box" className={styles.cfield}></div>
              </div>
              <div className={styles['flex-25']}>
                <div id="card-exp-year" data-testid="card-exp-year-box" className={styles.cfield}></div>
              </div>
              <div className={styles['flex-25']}>
                <div id="card-code" data-testid="card-security-code-box" className={styles.cfield}></div>
              </div>
            </div>
            <div className={`${styles.row} ${styles.pad}`}>
              <div className={styles['flex-100']}>
                <div
                  className={`${styles.label} ${styles['text-btn']}`}
                  style={paymentViewConfig.inputLabelStyles}
                  onClick={toggleCodeBox}
                >
                  Got a coupon or referral code?
                </div>
                <div className={`${styles.toggle} ${codeBoxOpen ? styles.open : ''}`}>
                  <PromoCodeValidator
                    value={couponCode.value}
                    type="coupon"
                    isValid={couponCode.isValid}
                    productHandle={selectedProduct.handle}
                    disabled={referralCode.value !== ''}
                    onValueChange={onCouponCodeValueChange}
                    onValidationDone={onCouponValidationDone}
                  />
                  <PromoCodeValidator
                    value={referralCode.value}
                    type="referral"
                    isValid={referralCode.isValid}
                    productHandle={selectedProduct.handle}
                    disabled={couponCode.value !== ''}
                    onValueChange={onReferralCodeValueChange}
                    onValidationDone={onReferralValidationDone}
                  />
                </div>
              </div>
            </div>
            <div className={styles.actions}>
              <div>
                <FormControlLabel
                  label={(
                    <span>I agree to the <a href="https://www.postplanner.com/terms" target="_blank">Terms of Service</a></span>
                  )}
                  classes={{ label: styles['cb-label'] }}
                  labelPlacement="end"
                  control={(
                    <Checkbox
                      checked={tosAccepted}
                      size="small"
                      color="primary"
                      onChange={toggleTosAccepted}
                    />
                  )}
                />
              </div>
              <div>
                <Button
                  disabled={!tosAccepted || loading}
                  color="primary"
                  variant="contained"
                  size="large"
                  style={paymentViewConfig.mainButtonStyles}
                  className={styles['btn-submit']}
                  data-testid="btn-submit"
                  onClick={submit}
                >
                  {paymentViewConfig.mainButtonLabel}
                </Button>
              </div>
              <div className={styles.summary}>
                <div className={styles['summary-note']}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <title>lock</title>
                    {/* eslint-disable-next-line max-len */}
                    <path d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" />
                  </svg>
                  <span>All payments are secure and encrypted</span>
                </div>
                <div className={styles['summary-1']}>
                  <span style={paymentViewConfig.priceLabelStyles}>{paymentViewConfig.priceLabel}</span>
                  <span style={paymentViewConfig.priceLabelStyles}>{` ${CURRENCY_SIGN_MAP[selectedCurrency]}${todaysPrice}`}</span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  )
}
