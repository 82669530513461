import { WritableDraft } from 'immer/dist/internal'
import { PrivacyStatus } from 'shared/types'
import ComposerState from '../state'
import { PayloadAction } from '@reduxjs/toolkit'

export const youtubeReducers = {
  setYtTitle: (state: WritableDraft<ComposerState>, action: PayloadAction<string>) => {
    state.posts.youtube.title = action.payload
    state.posts.youtube.status.touched = true
  },
  setYtDescription: (state: WritableDraft<ComposerState>, action: PayloadAction<string>) => {
    state.posts.youtube.description = action.payload
    state.posts.youtube.status.touched = true
  },
  setYtFirstComment: (state: WritableDraft<ComposerState>, action: PayloadAction<string>) => {
    state.posts.youtube.firstComment = action.payload
  },
  setYtPrivacy: (state: WritableDraft<ComposerState>, action: PayloadAction<PrivacyStatus>) => {
    state.posts.youtube.privacyStatus = action.payload
  }
}
