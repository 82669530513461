import { PostDestination } from 'shared'

export const POST_DESTINATIONS_STATE_VERSION = 'destinations:3'

export function initialState(): PostDestinationsState {
  return Object.freeze({
    destinations: Object.freeze({}),
    facebookPersonalProfiles: [],
    pinterestBoardsByPpid: {}
  })
}

export interface PostDestinationsState {
  destinations: Record<string, PostDestination>
  facebookPersonalProfiles: PostDestination[]
  pinterestBoardsByPpid: Record<string, Array<{ id: string, name: string }>>
}

export default PostDestinationsState
