import * as React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { MyFile, FileFolder, WithIntl, ALL_FILTER, ContentType, INTERACTION_EVENT_OPEN, InteractionTrackingSource } from 'interfaces'
import StoreState, { StoreThunkDispatch } from 'store/state'
import FileFolderViewHeader from '../../components/FileFolderViewHeader'
import FileCard from 'components/FileCard'
import ScrollListener from 'components/ScrollListener'
import { setFileInPreview } from 'services/uploads/actions'
import { setComposerFile } from 'services/compose'
import { librarySortingSelector, myFileFoldersArraySelector } from 'services/uploads/selectors'
import ErrorOverlay from 'components/ErrorOverlay'
import { injectIntl } from 'react-intl'
import { trackFileFolderInteraction, addRecentSource } from 'services/content/recommended/actions'
import { Observable } from 'rxjs/Observable'

import styles from './MyUploadsFolderView.pcss'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { selectedContentItemsSelector } from 'services/content/selectors'
import { setVisibleSelectableItems } from 'services/content'
import { arrayToRecord } from 'shared'
import { setSorting } from 'services/uploads'

const SCROLL_THRESHOLD = 350
const PAGE_SIZE = 40

interface MyUploadsFolderViewOwnProps {
  backUrl?: string
}

interface ConnectedMyUploadsFolderViewProps {
  folders: FileFolder[]
  createPostFromFile: (url: string) => void
  setFileInPreview: (file: MyFile) => void
  trackOpen: (id: string) => Promise<any>
  addRecentSource: (source: InteractionTrackingSource) => void
}

export type MyUploadsFolderViewProps = MyUploadsFolderViewOwnProps & ConnectedMyUploadsFolderViewProps & WithIntl

export function MyUploadsFolderView(props: MyUploadsFolderViewProps) {
  const dispatch = useDispatch()
  const selectedItems = useSelector(selectedContentItemsSelector)
  const sorting = useSelector(librarySortingSelector)
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()

  const [filter, setFilter] = React.useState(ALL_FILTER)
  const folder = props.folders.find(f => f.id.toString() === params.folderId)
  const folderSorting = folder ? sorting[folder.id] : null
  const [page, setPage] = React.useState(1)
  const visibleFilesEndIndex = page * PAGE_SIZE

  // Record "open" event for the active folder
  React.useEffect(() => {
    if (folder) {
      props.trackOpen(folder.id)
      props.addRecentSource(folder)
    }
  }, [params.folderId])

  const visibleFiles = React.useMemo(() => {
    const filtered = folder
      ? filter === ALL_FILTER
        ? Object.values(folder.files)
        : Object.values(folder.files).filter(f => f.type === filter)
      : []

    const sorted = filtered.sort((f1: MyFile, f2: MyFile) => {
      // sort files based on sortBy and sortDirection
      if (folderSorting?.sortBy === 'name') {
        return folderSorting?.sortDirection === 'asc'
          ? f2.name.localeCompare(f1.name)
          : f1.name.localeCompare(f2.name)
      }
      return folderSorting?.sortDirection === 'asc'
        ? f1.createdAt.localeCompare(f2.createdAt)
        : f2.createdAt.localeCompare(f1.createdAt)
    })
    return sorted
  }, [filter, folder, folderSorting?.sortBy, folderSorting?.sortDirection])

  React.useEffect(() => {
    dispatch(setVisibleSelectableItems(arrayToRecord(visibleFiles, 'id')))
  }, [dispatch, visibleFiles])

  if (!folder) {
    return (
      <ErrorOverlay
        error={props.intl.formatMessage({ id: 'uploads.error.folder-not-found-title' })}
        subtitle={props.intl.formatMessage({ id: 'uploads.error.folder-not-found-subtitle' })}
        originalError="folder not found"
        className={styles['error-box']}
      />
    )
  }

  const defaultFilter = folder.filters.length !== 1 ? ALL_FILTER : folder.filters[0]

  if (filter !== defaultFilter && !folder.filters.includes(filter as ContentType)) {
    setFilter(defaultFilter)
  }

  const loadMore = () => {
    if (page * PAGE_SIZE < visibleFiles.length) {
      setPage(page + 1)
    }
  }

  const createPostFromFile = (file: MyFile) => {
    props.createPostFromFile(file.url)
    navigate('/composer', { state: { floating: true, modal: 'compose', opener: location.pathname, background: location } })
  }

  const onSortChange = (sortBy: 'name' | 'date', sortDirection: 'asc' | 'desc') => {
    dispatch(setSorting({ folderId: folder.id, sortBy, sortDirection }))
  }

  const scrollEl = document.getElementsByTagName('main')[0]

  return (
    <ScrollListener
      scrollElement={scrollEl}
      emitInitial
      emitTreshold={SCROLL_THRESHOLD}
      onScroll={loadMore}
    >
      <div>
        <FileFolderViewHeader
          folderId={folder.id}
          folderName={folder.name}
          activeFilter={filter}
          filters={folder.filters}
          backUrl={props.backUrl}
          sorting={{
            sortBy: folderSorting?.sortBy || 'date',
            sortDirection: folderSorting?.sortDirection || 'desc',
            onChange: onSortChange
          }}
          onFilterChange={setFilter}
        />
        <section className={styles.grid}>
          {
            visibleFiles.length > 0 ? visibleFiles.slice(0, visibleFilesEndIndex).map(file => (
              <FileCard
                small
                file={file}
                key={file.key}
                selected={Boolean(selectedItems[file.id])}
                className={styles.file}
                onClick={props.setFileInPreview}
                onCreatePost={createPostFromFile}
              />
            )) : (
              <ErrorOverlay
                error={props.intl.formatMessage({ id: `uploads.error.carousel-no-${filter}` })}
                subtitle=""
                originalError="no uploads"
                className={`${styles['error-box']} ${styles['error-grid']}`}
              />
            )
          }
        </section>
      </div>
    </ScrollListener>
  )
}

function mapStateToProps(state: StoreState) {
  return {
    folders: myFileFoldersArraySelector(state)
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    createPostFromFile: (url: string) => dispatch(setComposerFile(url)),
    setFileInPreview: (file: MyFile) => dispatch(setFileInPreview(file)),
    trackOpen: (id: string) => dispatch(trackFileFolderInteraction({ folderIds: [id], event: INTERACTION_EVENT_OPEN })).unwrap(),
    addRecentSource: (source: InteractionTrackingSource) => dispatch(addRecentSource(source))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MyUploadsFolderView))
