import { ContentPanel, HomeContentBlock, StreamGroup } from 'admin/interfaces'
import {
  Stream,
  Feed,
  InteractionTrackingSource,
  AnyContent,
  Paginated,
  PHOTO_TYPE,
  GIF_TYPE,
  VIDEO_TYPE,
  ARTICLE_TYPE,
  ContentItem,
  IndexedObject,
  STATUS_TYPE,
  Photo,
  Article,
  Gif,
  Video,
  MyFile,
  LiveArticleData
} from 'interfaces'
import { STOCK_PHOTO_TYPE, STOCK_VIDEO_TYPE, STOCK_GIF_TYPE } from 'interfaces/Content/StockContentTypes'
import { TEXT_TYPE } from 'interfaces/Content/ContentType'

export const CONTENT_STATE_VERSION = 'content:11'
export interface ContentCacheEntity {
  content: Readonly<Paginated<string[]>>
  timer: Readonly<number>
}

export interface FavoritesState {
  [type: string]: { [id: string]: boolean }
}

export type FavoritesStore = {
  saved: FavoritesState
  added: { [type: string]: { [id: string]: ContentItem } }
  removed: FavoritesState
  isFetching: boolean
}

export type SelectableContent = Photo | Article | Gif | Video | MyFile
export interface ContentSelectionState {
  items: IndexedObject<SelectableContent>
  itemsOnPage: Record<string, SelectableContent>
  liveArticleData: Record<string, LiveArticleData & { id: string }>
  panelOpen: boolean
}

export interface ContentState {
  streams: Readonly<{ [key: string]: Readonly<Stream> }>
  feeds: Readonly<{ [key: string]: Readonly<Feed> }>
  user: Readonly<{
    streams: Array<Stream>
    feeds: Array<string>
  }>
  curatedStreams: Readonly<{
    popular: ReadonlyArray<Stream>
    industries: ReadonlyArray<Stream>
  }>
  recentSources: Readonly<{ [key: string]: ReadonlyArray<InteractionTrackingSource> }>
  selectedFeedsToSave: Readonly<Feed[]>
  cache: Readonly<{
    data: Readonly<{
      [id: string]: Readonly<{
        item: AnyContent
        count: number
      }>
    }>
    sources: Readonly<{ [key: string]: ContentCacheEntity }>
  }>
  favorites: FavoritesStore
  blocks: HomeContentBlock[]
  panels: ContentPanel[]
  groups: IndexedObject<StreamGroup>
  recentStatusesSearches: string[]
  recentQuotesSearches: Array<{ term: string, isTag: boolean }>
  selection: ContentSelectionState
}

export function initialState(): ContentState {
  return Object.freeze({
    streams: Object.freeze({}),
    feeds: Object.freeze({}),
    user: Object.freeze({
      streams: [],
      feeds: []
    }),
    curatedStreams: Object.freeze({
      popular: Object.freeze([]),
      industries: Object.freeze([])
    }),
    recentSources: Object.freeze({}),
    selectedFeedsToSave: [],
    cache: Object.freeze({
      data: Object.freeze({}),
      sources: Object.freeze({})
    }),
    favorites: Object.freeze({
      saved: Object.freeze({
        [PHOTO_TYPE]: {},
        [ARTICLE_TYPE]: {},
        [VIDEO_TYPE]: {},
        [GIF_TYPE]: {},
        [STOCK_PHOTO_TYPE]: {},
        [STOCK_VIDEO_TYPE]: {},
        [STOCK_GIF_TYPE]: {},
        [STATUS_TYPE]: {},
        [TEXT_TYPE]: {}
      }),
      added: Object.freeze({
        [PHOTO_TYPE]: {},
        [ARTICLE_TYPE]: {},
        [VIDEO_TYPE]: {},
        [GIF_TYPE]: {},
        [STOCK_PHOTO_TYPE]: {},
        [STOCK_VIDEO_TYPE]: {},
        [STOCK_GIF_TYPE]: {},
        [STATUS_TYPE]: {},
        [TEXT_TYPE]: {}
      }),
      removed: Object.freeze({
        [PHOTO_TYPE]: {},
        [ARTICLE_TYPE]: {},
        [VIDEO_TYPE]: {},
        [GIF_TYPE]: {},
        [STOCK_PHOTO_TYPE]: {},
        [STOCK_VIDEO_TYPE]: {},
        [STOCK_GIF_TYPE]: {},
        [STATUS_TYPE]: {},
        [TEXT_TYPE]: {}
      }),
      isFetching: false
    }),
    streamGroups: [],
    blocks: [],
    panels: [],
    groups: {},
    recentStatusesSearches: [],
    recentQuotesSearches: [],
    selection: {
      items: {},
      itemsOnPage: {},
      panelOpen: false,
      liveArticleData: {}
    }
  })
}

export default ContentState
