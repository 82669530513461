import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './CustomizePostBlock.pcss'

export function EmptyNetworkPostOptions() {
  return (
    <div className={styles['option-box']}>
      <div className={styles['msg-empty']}>
        <FormattedMessage id="composer.labels.network-settings-empty" />
      </div>
    </div>
  )
}
