import * as React from 'react'
import QuoteIcon from '@mui/icons-material/FormatQuoteOutlined'
import RetweetIcon from '@mui/icons-material/Repeat'
import { ContentFeedType, FEED_TYPE_TWITTER } from 'interfaces'
import { StoreDispatch } from 'store/state'
import { openContentPreviewWindow } from 'services/contentPreview'
import { connect } from 'react-redux'
import styles from './TweetBlock.pcss'
import { format } from 'date-fns'

interface TweetBlockOwnProps {
  text: string
  authorName?: string
  authorHandle?: string
  date?: Date
  tweetUrl?: string
  className?: string
  clampLines?: number
  headlineOnly?: boolean
  isRetweet?: boolean
  isQuote?: boolean
}

interface TweetBlockConnectedProps {
  openTweetPreview: (item: { socialLink: string, feed: { type: ContentFeedType } }) => void
}

type TweetBlockProps = TweetBlockOwnProps & TweetBlockConnectedProps

export function TweetBlock(props: TweetBlockProps) {
  let clampClass = styles['clamp-3']
  if (props.clampLines) {
    clampClass = props.clampLines === 1 ? 'text-ellipsis' : styles[`clamp-${props.clampLines}`]
  }

  const dateFormatted = props.date ? format(props.date, 'LLL d') : null

  const openPreview = () => {
    if (props.tweetUrl) {
      const item = {
        socialLink: props.tweetUrl,
        feed: {
          type: FEED_TYPE_TWITTER as ContentFeedType
        }
      }

      props.openTweetPreview(item)
    }
  }

  return (
    <div className={`${styles.wrapper} ${props.className || ''}`} onClick={openPreview}>
      <div className={styles['icon-box']}>
        {props.isRetweet && <RetweetIcon className={styles.icon} />}
        {props.isQuote && <QuoteIcon className={styles.icon} />}
      </div>
      <div className={styles.content}>
        <div className={`${styles.attr} text-ellipsis`}>
          {props.authorName && <span className={styles.name}>{props.authorName}</span>}
          {props.authorHandle && <span>{props.authorHandle}</span>}
          {!props.authorHandle && !props.authorName && props.isRetweet && <span className={styles.name}>Retweet</span>}
          {!props.authorHandle && !props.authorName && props.isQuote && <span className={styles.name}>Quoted Tweet</span>}
          {dateFormatted && (
            <React.Fragment>
              <span className={styles.circle}>&#x25CF;</span>
              <span>{dateFormatted}</span>
            </React.Fragment>
          )}
        </div>
        {!props.headlineOnly && <p className={`${styles.text} ${clampClass}`}>{props.text}</p>}
      </div>
    </div>
  )
}

export function TweetLink(props: { url: string, retweet?: boolean, quote?: boolean }) {
  return (
    <div className={`${styles.wrapper} ${styles.inline}`}>
      {props.retweet && <RetweetIcon className={styles.icon} />}
      {props.quote && <QuoteIcon className={styles.icon} />}
      <a href={props.url} className={styles.name} target="_blank" rel="noopener">
        {props.retweet && 'Retweet'}
        {props.quote && 'Quoted tweet'}
      </a>
    </div>
  )
}

function mapDispatchToProps(dispatch: StoreDispatch) {
  return {
    openTweetPreview: (item: { socialLink: string, feed: { type: ContentFeedType } }) => dispatch(openContentPreviewWindow(item as any))
  }
}

export default connect(null, mapDispatchToProps)(TweetBlock)
