import * as React from 'react'
import { CalendarDay as ICalendarDay } from 'interfaces/Post/Calendar'
import styles from './CalendarDay.pcss'

interface CalendarDayProps {
  day: ICalendarDay
  disabled?: boolean
  active?: boolean
}

export function CalendarDay(props: React.PropsWithChildren<CalendarDayProps>) {
  return (
    <div
      data-testid="calendar-day"
      data-active={props.active}
      className={`${styles['day-box']} ${props.disabled ? styles.dim : ''} ${props.active ? styles.active : ''}`}
    >
      <div className={styles.top}>
        <div className={styles.date}>
          {props.day.date.getDate()}
        </div>
      </div>
      <div className={styles['cell-content']}>{props.children}</div>
    </div>
  )
}

export default CalendarDay
