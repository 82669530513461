import React, { useEffect } from 'react'
import styles from './HashtagsGenerator.pcss'
import Button from '@mui/material/Button'
import { Subject } from 'rxjs/Subject'
import { useDispatch, useSelector } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { fetchHashtagsSuggestions } from 'services/compose/suggest/actions'
import { Observable } from 'rxjs/Observable'
import { catchError } from 'rxjs/operators/catchError'
import CopyText from 'components/Composer/Credits/CopyText'
import { message } from 'services/snackbar'
import { useIntl } from 'react-intl'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import {
  composerContentItemSelector,
  composerLinkSelector,
  composerResetKeySelector,
  genericPostSelector
} from 'services/compose/selectors'
import { ARTICLE_TYPE, BRAND_LINKEDIN } from 'shared'
import { Article } from 'interfaces'
import IconRefresh from '@mui/icons-material/Refresh'
import IconButton from '@mui/material/IconButton'
import { useEffectUpdateOnly } from 'hooks/useEffectUpdateOnly'

const MIN_PROMPT_LENGTH = 10

export function HashtagsGenerator() {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const intl = useIntl()
  const initialLoadComplete$ = React.useRef(false)
  const content = useSelector(composerContentItemSelector)
  const originalCaption = React.useMemo(() => {
    const useContentCaption = content?.feed.type !== BRAND_LINKEDIN || content?.status?.indexOf('@') === -1
    return useContentCaption ? content?.status : undefined
  }, [content?.feed.type, content?.status])
  const link = useSelector(composerLinkSelector)
  const statusText = useSelector(genericPostSelector).status.text
  const composerResetKey = useSelector(composerResetKeySelector)
  const get$ = React.useRef<Subject<{ prompt: string, linkUrl?: string }>>()
  const [loading, setLoading] = React.useState(false)
  const [hashtags, setHashtags] = React.useState<string[]>([])
  const [prompt, setPrompt] = React.useState<string>('')
  const [withRefresh, setWithRefresh] = React.useState(false)
  const [refreshDisabled, setRefreshDisabled] = React.useState(false)

  React.useEffect(() => {
    setHashtags([])
    setPrompt('')
  }, [composerResetKey])

  const url = React.useMemo(() => {
    if (link?.type === 'link') {
      return link.url
    }
    return content?.type === ARTICLE_TYPE ? (content as Article).sourceLink : undefined
  }, [link, content])

  const text = React.useMemo(() => {
    const value = prompt.trim()
    const composerStatus = statusText.trim()

    return value || composerStatus || originalCaption || ''
  }, [originalCaption, prompt, statusText])

  const isEnabled = Boolean(url || text)

  useEffectUpdateOnly(() => {
    setWithRefresh(true)
    setRefreshDisabled(false)
  }, [statusText, url, prompt])

  useEffect(() => {
    get$.current = new Subject()
    get$.current
      .map(({ prompt, linkUrl }) => {
        if (!linkUrl && prompt.length < MIN_PROMPT_LENGTH) {
          const msg = `Please enter URL in the text box on the left or a prompt of at least ${MIN_PROMPT_LENGTH} characters.`
          dispatch(message(msg, 'info'))
          return false
        }

        return { prompt, linkUrl }
      })
      .filter(Boolean)
      .flatMap(({ prompt, linkUrl }: { prompt: string, linkUrl: string }) => {
        setLoading(true)
        setRefreshDisabled(true)
        setWithRefresh(true)
        return dispatch(fetchHashtagsSuggestions(prompt, linkUrl)).pipe(
          catchError((res) => {
            return Observable.of({ error: res.response?.error || {} })
          })
        )
      }).subscribe(response => {
        setLoading(false)
        if (response.error) {
          const msg = response.error.message || intl.formatMessage({ id: 'errors.generic' })
          dispatch(message(msg, 'error'))
          return
        }
        setHashtags(response.items)
      })

    return () => {
      get$.current?.unsubscribe()
    }
  }, [dispatch, intl])

  React.useEffect(() => {
    if (!initialLoadComplete$.current) {
      const value = statusText.trim() || originalCaption || ''
      if (value.length >= MIN_PROMPT_LENGTH || url) {
        get$.current?.next({ linkUrl: url, prompt: value })
      }
      initialLoadComplete$.current = true
    }
  }, [originalCaption, prompt, statusText, url])

  const submit = () => {
    if (isEnabled) {
      get$.current?.next({ linkUrl: url, prompt: text })
    }
  }

  const onCopy = () => {
    dispatch(message(intl.formatMessage({ id: 'notifications.status-copied' }), 'info'))
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(e.target.value)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value.trim()
    if (e.key === 'Enter') {
      submit()
    }
  }

  return (
    <div data-testid="composer-hashtags">
      <div className={styles.top}>
        <TextField
          value={prompt}
          classes={{ root: styles['prompt-box'] }}
          placeholder="Add keywords to get hashtags"
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        {withRefresh ? (
          <IconButton
            className={styles['btn-refresh']}
            disabled={refreshDisabled || loading || !isEnabled}
            onClick={submit}
          >
            <IconRefresh fontSize="small" />
          </IconButton>
        ) : (
          <Button disabled={!isEnabled || loading} onClick={submit}>
            Generate
          </Button>
        )}
      </div>
      {!isEnabled && (
        <p className={styles.hint}>Add keywords above to get hashtag suggestions.</p>
      )}
      <div className={styles.list}>
        {loading && (
          <div className={styles.loader}><CircularProgress size="20px" /></div>
        )}
        {!loading && hashtags.map((text, index) => (
          <CopyText
            key={index}
            messageText={text}
            title={`RECOMMENDED HASHTAGS — SET ${index + 1}`}
            showFullText
            onTextCopied={onCopy}
          />
        ))}
      </div>
    </div>
  )
}
