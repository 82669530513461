import * as React from 'react'
import Chip from '@mui/material/Chip'
import styles from './SuggestedStatusSearches.pcss'
import { useDispatch, useSelector } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import {
  addRecentStatusSearch,
  deleteSavedSearch,
  getPastSearches,
  setRecentStatusSearches
} from 'services/content/statuses/actions'
import CardList from 'components/CardList'
import { Subject } from 'rxjs/Subject'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { CategoryChip } from './CategoryChip'
import { STATUSES_CATEGORIES } from 'routes/find/routes/statuses/statuses.config'
import { QueryChip } from './QueryChip'
import { recentStatusesSearchesSelector } from 'services/content/selectors'

const categories = STATUSES_CATEGORIES.map(cat => ({ ...cat, name: cat.title }))
const CATEGORY_LIST_HEIGHT = 40

interface SuggestedStatusSearchesProps {
  activeQuery: string
  activeCategoryId?: string
  className?: string
  hideCategories?: boolean
  hideSavedSearches?: boolean
  searchElement?: React.ReactNode
  onQueryClick: (value: string) => void
  onActiveCategoryChange?: (id: string, name: string) => void
}

export function SuggestedStatusSearches(props: SuggestedStatusSearchesProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const deleteSearch$ = React.useRef<Subject<string>>()
  const recentSearches = useSelector(recentStatusesSearchesSelector)
  const [loading, setLoading] = React.useState(recentSearches.length === 0)

  const arr = new Array(6).fill('') // eslint-disable-line no-magic-numbers
  const loadingChips = arr.map(() => <Chip className={`${styles['loading-chip']} ${styles.gradient}`} />)

  React.useEffect(() => {
    const sub = dispatch(getPastSearches())
      .pipe(catchError(() => Observable.of([])))
      .subscribe(() => {
        setLoading(false)
      })

    return () => {
      sub.unsubscribe()
    }
  }, [])

  React.useEffect(() => {
    deleteSearch$.current = new Subject()
    deleteSearch$.current
      .flatMap((text: string) => dispatch(deleteSavedSearch(text))
        .pipe(
          catchError(() => Observable.of({ error: true }))
        )
      ).subscribe((response: any) => {
        if (response.error) {
          return
        }

        dispatch(setRecentStatusSearches(recentSearches.filter(t => t !== response)))
      })

    return () => {
      deleteSearch$.current?.unsubscribe()
    }
  }, [recentSearches, dispatch])

  React.useEffect(() => {
    if (props.activeQuery && !props.activeCategoryId) {
      dispatch(addRecentStatusSearch(props.activeQuery))
    }
  }, [props.activeQuery, dispatch, props.activeCategoryId])

  const deleteSearch = (text: string) => {
    deleteSearch$.current?.next(text)
  }

  const onActiveCategoryChange = (id: string, name: string) => {
    if (props.onActiveCategoryChange) {
      props.onActiveCategoryChange(id, name)
    }
  }

  return (
    <div className={props.className || ''}>
      {(!props.hideSavedSearches || props.searchElement) && (
        <div className={styles.list}>
          {props.searchElement}
          {!props.hideSavedSearches && loading && (
            <div className={styles['loading-box']}>
              {loadingChips}
            </div>
          )}
          {!props.hideSavedSearches && !loading && (
            recentSearches.map(q => (
              <QueryChip
                key={q}
                label={q}
                className={styles.chip}
                active={q === props.activeQuery}
                onClick={props.onQueryClick}
                onDelete={deleteSearch}
              />
            ))
          )}
        </div>
      )}
      {!props.hideCategories && (
        <CardList listHeight={CATEGORY_LIST_HEIGHT} containerClassName={styles.carousel}>
          {categories.map(cat => (
            <CategoryChip
              key={cat.id}
              id={cat.id}
              name={cat.name}
              active={props.activeCategoryId === cat.id}
              onClick={onActiveCategoryChange}
            />
          ))}
        </CardList>
      )}
    </div>
  )
}
