import { Component } from 'react'
import { connect } from 'react-redux'
import {
  Photo,
  Video,
  Article,
  StatusIdea,
  AnyContent,
  ARTICLE_TYPE,
  PHOTO_TYPE,
  STATUS_TYPE,
  VIDEO_TYPE,
  CuratedFolder,
  ContentType
} from 'interfaces'
import { getPhotos } from 'services/content/photos/actions'
import { getArticles } from 'services/content/articles/actions'
import { getStatusIdeas } from 'services/content/statuses/actions'
import { getVideos } from 'services/content/videos/actions'
import { StoreState, StoreThunkDispatch } from 'store/state'
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/of'
import 'rxjs/add/observable/empty'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/mergeAll'
import 'rxjs/add/operator/toArray'
import 'rxjs/add/operator/observeOn'
import 'rxjs/add/operator/defaultIfEmpty'
import { animationFrame } from 'rxjs/scheduler/animationFrame'
import { Subscription } from 'rxjs/Subscription'

import { createHandledPipe } from './FetchError'

export interface FetchCuratedFolderContentProps {
  folder: CuratedFolder
  hash?: string
  // eslint-disable-next-line react/no-unused-prop-types
  statusesSeed?: string

  onFetched(content: AnyContent[]): void
  onFailed?(err: any): void
}

interface ConnectedFetchCuratedFolderContentProps extends FetchCuratedFolderContentProps {
  getVideos(source: string): Observable<Video>
  getPhotos(source: string): Observable<Photo>
  getArticles(source: string): Observable<Article>
  getStatusIdeas(source: string[]): Observable<StatusIdea>
}

export class FetchCuratedFolderContent extends Component<ConnectedFetchCuratedFolderContentProps, any> {

  private subscription: Subscription

  constructor(props: ConnectedFetchCuratedFolderContentProps) {
    super(props)

    this.onFetched = this.onFetched.bind(this)
    this.onFailed = this.onFailed.bind(this)
  }

  shouldComponentUpdate(nextProps: ConnectedFetchCuratedFolderContentProps) {
    return nextProps.folder.streams !== this.props.folder.streams
      || nextProps.hash !== this.props.hash
  }

  onFetched(content: AnyContent[]) {
    this.props.onFetched(content)
  }

  onFailed(err: any) {
    console.log(err)
    if (this.props.onFailed) {
      this.props.onFailed(err)
    }
  }

  fetch() {
    const grouped = this.props.folder.streams.reduce((result: { [key: string]: string[] }, source: any) => {
      source.types.forEach((type: ContentType) => {
        result[type] = result[type] || []
        result[type].push(source.id + '')
      })
      return result
    }, {} as { [key: string]: string[] })

    const requests = [] as Observable<AnyContent>[]

    if (grouped[VIDEO_TYPE] && grouped[VIDEO_TYPE].length) {
      requests.push(
        createHandledPipe(
          VIDEO_TYPE,
          Observable.of(...(grouped[VIDEO_TYPE]).map(this.props.getVideos)).mergeAll(),
          this.onFailed
        )
      )
    }

    if (grouped[PHOTO_TYPE] && grouped[PHOTO_TYPE].length) {
      requests.push(
        createHandledPipe(
          PHOTO_TYPE,
          Observable.of(...(grouped[PHOTO_TYPE]).map(this.props.getPhotos)).mergeAll(),
          this.onFailed
        )
      )
    }

    if (grouped[ARTICLE_TYPE] && grouped[ARTICLE_TYPE].length) {
      requests.push(
        createHandledPipe(
          ARTICLE_TYPE,
          Observable.of(...(grouped[ARTICLE_TYPE]).map(this.props.getArticles)).mergeAll(),
          this.onFailed
        )
      )
    }

    if (grouped[STATUS_TYPE] && grouped[STATUS_TYPE].length) {
      requests.push(
        createHandledPipe(
          STATUS_TYPE,
          this.props.getStatusIdeas(grouped[STATUS_TYPE]),
          this.onFailed
        )
      )
    }

    return Observable.of(...requests)
      .mergeAll()
      .toArray()
      .observeOn(animationFrame)
      .subscribe(this.onFetched, this.onFailed)
  }

  componentDidMount() {
    this.subscription = this.fetch()
  }

  componentDidUpdate() {
    this.subscription.unsubscribe()
    this.subscription = this.fetch()
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    return null
  }
}

function mapStateToProps(_state: StoreState, ownProps: FetchCuratedFolderContentProps) {
  return ownProps
}

function mapDispatchToProps(dispatch: StoreThunkDispatch, ownProps: FetchCuratedFolderContentProps) {
  const { statusesSeed } = ownProps

  return {
    getVideos: (feedId: string) => dispatch(getVideos(feedId, true, undefined, undefined))
      .flatMap(v => Observable.of(...v.items)),
    getPhotos: (feedId: string) => dispatch(getPhotos(feedId, true, undefined, undefined))
      .flatMap(p => Observable.of(...p.items)),
    getArticles: (feedId: string) => dispatch(getArticles(feedId, true, undefined, undefined))
      .flatMap(a => Observable.of(...a.items)),
    getStatusIdeas: (categoryIds: Array<string>) => {
      if (categoryIds.length === 0) {
        return Observable.empty()
      }
      return dispatch(getStatusIdeas(categoryIds, undefined, statusesSeed)).flatMap(s => Observable.of(...s.items))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FetchCuratedFolderContent)
