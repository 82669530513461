import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'

const ENDPOINT_SHUFFLE_POSTS = `${LEGACY_URL}/v3/posts/bulk_shuffle`

export const V1 = Object.freeze({
  shufflePosts: function () {
    return function (
      postIds: string[],
      ppids: string[],
      postsType: 'queued' | 'pending' | 'scheduled' | 'failed' | 'planned',
      all?: boolean,
      bucketId?: string
    ) {
      const endpoint = ENDPOINT_SHUFFLE_POSTS
      const builder = new RequestBuilder(endpoint).asPost().expectJSON()
      const data: any = {
        type: postsType,
        pp_page_ids: ppids,
        selected_count: postIds.length.toString()
      }

      if (all) {
        data.selected = 'all'
      } else {
        data.post_ids = postIds
      }

      if (bucketId) {
        data.bucket_id = bucketId
      }

      return authorizedPipe<any>(
        builder
          .body(data)
          .build()
      )
    }
  }()
})
