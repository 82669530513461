import * as React from 'react'
import { WithIntl, FileType } from 'interfaces'
import { injectIntl } from 'react-intl'
import { bytesToSize } from 'utils/format/byteToSize'
import { getFileTypeByMimeType, getFileMaxSizeByType, getAllowedFormatsByFileType } from 'utils/file'

const BASE_TEN = 10

export interface FileValidatorProps extends WithIntl {
  file: File
  /** max file size in bytes */
  maxSize?: number
  allowedFileFormats?: string[]
  children?: React.ReactElement
  disabled?: boolean
  onValidationSuccess: () => void
  onValidationError: (error: string) => void
}

export const FileValidator = React.memo(function FileValidator(props: FileValidatorProps) {
  const file = props.file
  const type = getFileTypeByMimeType(file.type)
  const defaultFileFormats = type && getAllowedFormatsByFileType(type)
  const defaultMaxSize = type && getFileMaxSizeByType(type)
  const allowedFileFormats = props.allowedFileFormats || defaultFileFormats
  const allowedMaxSize = props.maxSize || defaultMaxSize

  React.useEffect(() => {
    if (props.disabled) {
      props.onValidationSuccess()
      return
    }

    if (allowedMaxSize && file.size > allowedMaxSize) {
      const maxSizeFormatted = bytesToSize(allowedMaxSize)
      const maxSizePrettyString = `${parseInt(maxSizeFormatted, BASE_TEN)} ${maxSizeFormatted.split(' ')[1]}`

      if (type === FileType.Image || type === FileType.Gif) {
        const imageErrorMessage = props.intl.formatMessage({ id: 'file.messages.photo-size-exceeded' }, { size: maxSizePrettyString })
        props.onValidationError(imageErrorMessage)
        return
      }

      if (type === FileType.Video) {
        const videoErrorMessage = props.intl.formatMessage({ id: 'file.messages.video-size-exceeded' }, { size: maxSizePrettyString })
        props.onValidationError(videoErrorMessage)
        return
      }

      props.onValidationError(`Your file is bigger than the maximum allowed of ${maxSizePrettyString}`)
      return
    }

    if (allowedFileFormats && !allowedFileFormats.includes(file.type)) {
      props.onValidationError(props.intl.formatMessage({ id: 'file.messages.invalid-type' }))
      return
    }

    props.onValidationSuccess()
  }, [props.file, props.maxSize, props.allowedFileFormats])

  return <React.Fragment>{props.children}</React.Fragment>
})

export default injectIntl(FileValidator)
