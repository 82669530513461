import * as React from 'react'
import { AppNavLink } from './AppNavLink'
import Icon from '@mdi/react'
import { mdiMapMarker, mdiStar } from '@mdi/js'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import IconAdd from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { locationsLimitSelector, locationsSelector } from 'services/listings/selectors'
import { createLocation, fetchLocations, setUpgradeLocationDialogOpen } from 'services/listings/actions'
import { WithIntl } from 'interfaces'
import styles from './AppNavigation.pcss'
import CreateLocationDialog from 'routes/listings/components/CreateLocationDialog'
import { catchError } from 'rxjs/operators/catchError'
import { message } from 'services/snackbar'
import { Observable } from 'rxjs/Observable'
import { tap } from 'rxjs/operators/tap'
import { PPNavLink } from 'components/PPNavLink'

declare const Intercom: any

interface SeoNavProps {
  expanded: boolean
}

export function SeoNav(props: SeoNavProps & WithIntl) {
  const navigate = useNavigate()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false)
  const locations = useSelector(locationsSelector)
  const locationsLimit = useSelector(locationsLimitSelector)
  const newLocationIdRef = React.useRef<string | null>()

  const openChat = () => {
    if (typeof Intercom !== 'undefined') {
      const text = locations.length === 0
        ? 'Hey Josh, I want to try Local SEO. Can you tell me more?'
        : 'Hey Josh, I\'d like to add an additional location. Can you help me?'
      Intercom('showNewMessage', text)
    }
  }

  const onAddLocation = React.useCallback(() => {
    if (locationsLimit === 0) {
      openChat()
      return
    }
    if (locationsLimit > locations.length) {
      setCreateDialogOpen(true)
    } else {
      dispatch(setUpgradeLocationDialogOpen(true))
    }
  }, [dispatch, locations.length, locationsLimit])

  const onCreateLocation = (name: string) => {
    if (locations.find(l => l.name === name)) {
      dispatch(message('Location with this name already exist!'))
      return
    }

    closeCreateDialog()

    dispatch(createLocation(name))
      .pipe(tap(response => {
        newLocationIdRef.current = response.id
      }))
      .flatMap(() => dispatch(fetchLocations()).pipe(catchError(() => {
        dispatch(message(props.intl.formatMessage({ id: 'errors.generic-support' })))
        return Observable.of({ error: true })
      })))
      .subscribe((response: any) => {
        if (!response.error) {
          navigate(`/reviews/locations/${newLocationIdRef.current || ''}`)
        }
        newLocationIdRef.current = null
      })
  }

  const onLocationsLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (locationsLimit === 0 && locations.length === 0) {
      openChat()
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const closeCreateDialog = () => {
    setCreateDialogOpen(false)
  }

  return (
    <div className={styles['sidebar-seo']}>
      <nav>
        <h1 className={styles['subnav-title']}>
          <FormattedMessage id="app.nav.title-reviews" />
        </h1>
        <AppNavLink
          icon={<Icon path={mdiMapMarker} />}
          label={<FormattedMessage id="app.nav.publishing-listings" />}
          to="/reviews/locations"
          withTooltip={!props.expanded}
          hideToggle
          expanded={props.expanded}
          items={(
            <React.Fragment>
              {locations.map(location => (
                <PPNavLink
                  key={location.id}
                  to={`/reviews/locations/${location.id}`}
                  className={styles['child-link']}
                  activeClassName={styles.active}
                >
                  <div className={styles['name-box']}>
                    <span className={`${styles.name} text-ellipsis`}>
                      {location.name}
                    </span>
                  </div>
                </PPNavLink>
              ))}
              <div className={`${styles['child-link']} ${styles['link-action']}`} onClick={onAddLocation}>
                <IconAdd className={styles['link-icon']} />
                <FormattedMessage id="listings.sidebar.action-add-location" />
              </div>
            </React.Fragment>
          )}
          onClick={onLocationsLinkClick}
        />
        <div className={styles['reviews-box']}>
          {locationsLimit === 0 && locations.length === 0 && (
            <div className={styles['overlay-disable']} onClick={openChat}></div>
          )}
          {(locations.length > 0 || locationsLimit === 0) && (
            <AppNavLink
              to="/reviews/reviews-manager"
              icon={<Icon path={mdiStar} />}
              label={<FormattedMessage id="app.nav.publishing-reviews" />}
              withTooltip={!props.expanded}
              hideToggle
              expanded={props.expanded}
              items={(
                <React.Fragment>
                  <PPNavLink
                    to="/reviews/reviews-manager"
                    className={styles['child-link']}
                    activeClassName={styles.active}
                  >
                    <div className={`${styles['name-box']} text-ellipsis`}>
                      <span className={styles.name}>
                        <FormattedMessage id="app.nav.publishing-manager" />
                      </span>
                    </div>
                  </PPNavLink>
                  <PPNavLink
                    to="/reviews/reviews-request"
                    className={styles['child-link']}
                    activeClassName={styles.active}
                  >
                    <div className={styles['name-box']}>
                      <span className={`${styles.name} text-ellipsis`}>
                        <FormattedMessage id="app.nav.publishing-request-reviews" />
                      </span>
                    </div>
                  </PPNavLink>
                </React.Fragment>
              )}
            />
          )}
        </div>
      </nav>
      <div className={styles.bottom}>
        <div className={styles.divider}>
          <span><FormattedMessage id="label.generic.helpdesk" /></span>
        </div>
        <ul className={styles['ul-help']}>
          <li>
            <a
              href="https://help.postplanner.com/en/articles/8797723-how-do-i-use-listings-and-reviews"
              target="_blank"
            >
              How do I use Listings and Reviews?
            </a>
          </li>
        </ul>
      </div>
      <CreateLocationDialog open={createDialogOpen} onCancel={closeCreateDialog} onCreate={onCreateLocation} />
    </div>
  )
}

export default injectIntl(SeoNav)
