import { statusIdeaFeeds } from '../statuses/statuses.config'
import {
  CURATED_STREAM_CATEGORY_POPULAR,
  CURATED_STREAM_CATEGORY_INDUSTRIES,
  CURATED_STREAM_CATEGORY_MY_STREAMS,
  Stream,
  ARTICLE_TYPE
} from 'interfaces'
import { ppGreenColorString } from 'utils/colors'

export const KEY_POPULAR = 'popular'
export const KEY_INDUSTRIES = 'industries'
export const KEY_MY_STREAMS = 'my-streams'
export const KEY_MOST_RECENT = 'most-recent'
export const KEY_RECENTLY_VIEWED = 'recently-viewed'
export const KEY_MY_PROFILES = 'my-profiles'
export const KEY_STATUS_IDEAS = 'status-ideas'
export const KEY_FAVORITES = 'favorites'
export const KEY_MY_LIBRARY = 'my-library'

export type SECTION_KEY = typeof KEY_POPULAR
  | typeof KEY_INDUSTRIES
  | typeof KEY_MY_STREAMS
  | typeof KEY_MOST_RECENT
  | typeof KEY_RECENTLY_VIEWED
  | typeof KEY_MY_PROFILES
  | typeof KEY_STATUS_IDEAS
  | typeof KEY_FAVORITES
  | typeof KEY_MY_LIBRARY

export const SECTION_NAME_POPULAR = 'Popular'
export const SECTION_NAME_INDUSTRIES = 'Industries'
export const SECTION_NAME_MY_STREAMS = 'My Streams'
export const SECTION_NAME_MOST_RECENT = 'Most Recent'
export const SECTION_NAME_RECENTLY_VIEWED = 'Recently Viewed'
export const SECTION_NAME_MY_PROFILES = 'Top Content from My Profiles'
export const SECTION_NAME_STATUS_IDEAS = 'Status Ideas'
export const SECTION_NAME_FAVORITES = 'Favorites'
export const SECTION_NAME_MY_LIBRARY = 'Recent Uploads to My Library'

export const SECTION_URL_POPULAR = '/content/popular'
export const SECTION_URL_INDUSTRIES = '/content/industries'
export const SECTION_URL_MY_STREAMS = '/content/my-streams'
export const SECTION_URL_RECENTLY_VIEWED = '/content/recently-viewed'
export const SECTION_URL_MOST_RECENT = '/content/most-recent'
export const SECTION_URL_MY_PROFILES = '/content/my-streams'
export const SECTION_URL_STATUS_IDEAS = '/content/statuses'
export const SECTION_URL_FAVORITES = '/content/favorites'
export const SECTION_URL_MY_LIBRARY = '/content/library'
export const SECTION_URL_MY_BRAND_MENTIONS = '/content/my-brand-mentions'

export const SECTIONS = [
  {
    key: KEY_MOST_RECENT,
    name: SECTION_NAME_MOST_RECENT,
    browseText: '',
    browseUrl: SECTION_URL_MOST_RECENT,
    items: [],
    loading: true,
    // indicates if the section displays content items or stram/feed links
    isContentSection: true
  }, {
    key: KEY_RECENTLY_VIEWED,
    name: SECTION_NAME_RECENTLY_VIEWED,
    browseUrl: SECTION_URL_RECENTLY_VIEWED,
    items: [],
    loading: true,
    isContentSection: false
  }, {
    key: KEY_FAVORITES,
    name: SECTION_NAME_FAVORITES,
    browseUrl: SECTION_URL_FAVORITES,
    items: [],
    loading: true,
    isContentSection: true
  }, {
    key: KEY_MY_PROFILES,
    name: SECTION_NAME_MY_PROFILES,
    browseUrl: SECTION_URL_MY_PROFILES,
    items: [],
    loading: true,
    isContentSection: true
  }, {
    key: KEY_MY_LIBRARY,
    name: SECTION_NAME_MY_LIBRARY,
    browseUrl: SECTION_URL_MY_LIBRARY,
    items: [],
    loading: true,
    isContentSection: false
  }, {
    key: KEY_POPULAR,
    name: SECTION_NAME_POPULAR,
    browseUrl: SECTION_URL_POPULAR,
    items: [],
    loading: true,
    curatedStreamCategory: CURATED_STREAM_CATEGORY_POPULAR,
    isContentSection: false
  }, {
    key: KEY_INDUSTRIES,
    name: SECTION_NAME_INDUSTRIES,
    browseUrl: SECTION_URL_INDUSTRIES,
    items: [],
    loading: true,
    curatedStreamCategory: CURATED_STREAM_CATEGORY_INDUSTRIES,
    isContentSection: false
  }, {
    key: KEY_MY_STREAMS,
    name: SECTION_NAME_MY_STREAMS,
    browseUrl: SECTION_URL_MY_STREAMS,
    items: [],
    loading: true,
    curatedStreamCategory: CURATED_STREAM_CATEGORY_MY_STREAMS,
    isContentSection: false
  },
  {
    key: KEY_STATUS_IDEAS,
    name: SECTION_NAME_STATUS_IDEAS,
    browseUrl: SECTION_URL_STATUS_IDEAS,
    items: statusIdeaFeeds,
    loading: false,
    isContentSection: false
  }
]

export const MBMStream: Stream = {
  id: 'mbm',
  title: 'My Brand Mentions',
  color: ppGreenColorString,
  feedIds: [],
  feeds: [],
  filters: [ARTICLE_TYPE],
  isStream: true,
  order: 0,
  public: false,
  slug: 'my-brand-mentions',
  createdAt: new Date().getTime()
}

export const BLOCK_MOST_RECENT = 'BLOCK_MOST_RECENT'
export const BLOCK_RECENTLY_VIEWED = 'BLOCK_RECENTLY_VIEWED'
export const BLOCK_QUOTES = 'BLOCK_QUOTES'
export const BLOCK_FAVORITES = 'BLOCK_FAVORITES'
export const BLOCK_POPULAR_STREAMS = 'BLOCK_POPULAR_STREAMS'
export const BLOCK_MY_STREAMS = 'BLOCK_MY_STREAMS'
export const BLOCK_MY_LIBRARY = 'BLOCK_MY_LIBRARY'
export const BLOCK_MY_LIBRARY_FOLDERS = 'BLOCK_MY_LIBRARY_FOLDERS'
export const BLOCK_MY_PROFILES_CONTENT = 'BLOCK_MY_PROFILES_CONTENT'
export const BLOCK_STATUS_IDEAS = 'BLOCK_STATUS_IDEAS'
export const BLOCK_MBM = 'BLOCK_MBM'
export const BLOCK_LATEST_FROM_BLOG = 'BLOCK_LATEST_FROM_BLOG'
