import * as React from 'react'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import styles from './DeclinedPermissions.pcss'
import FBAllowPermissionsButton from '../../components/LoginButton/FBAllowPermissionsButton'

export function DeclinedPermissions() {
  const navigate = useNavigate()

  const onDeclined = () => {
    navigate('/login')
  }

  return (
    <section data-test="declined" className={styles.declined}>
      <div className={styles.center}>
        <header>
          <h1 key="tagline" className={styles.tagline}>
            <FormattedMessage id="login.declined.title" defaultMessage="Better Posts. More Results." />
          </h1>
          <p key="notice" className={styles.notice}>
            <FormattedMessage id="login.declined.message" />
          </p>
        </header>

        <figure className={styles.hero}>
          <figcaption>
            <FormattedMessage id="login.declined.pic-caption" />
          </figcaption>
        </figure>

        <footer className={styles.actions}>
          <Button onClick={onDeclined}>
            <FormattedMessage id="login.declined.btn-decline" defaultMessage="Don't Allow" />
          </Button>
          <FBAllowPermissionsButton key="action-facebook" className={styles.primary} />
        </footer>
      </div>
    </section>
  )
}

export default DeclinedPermissions
