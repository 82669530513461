import { Component, createElement, Children, ReactNode, cloneElement, ReactChild } from 'react'
import BricksSize from './BricksSize'
import { getKey } from 'components/Card'
export { BricksSize }
import styles from './CardBricks.pcss'
import Bricks from './Bricks'

export const DEFAULT_SIZES = [
  { columns: 1, gutter: 16 },
  { columns: 2, gutter: 12, mq: '860px' },
  { columns: 3, gutter: 12, mq: '1210px' },
  { columns: 4, gutter: 12, mq: '1484px' },
  { columns: 5, gutter: 16, mq: '1780px' },
  { columns: 6, gutter: 16, mq: '2058px' },
  { columns: 7, gutter: 16, mq: '2340px' },
  { columns: 8, gutter: 12, mq: '2586px' }
]

export const FIVE_COLUMN_LAYOUT_SIZES = [
  { columns: 1, gutter: 16 },
  { columns: 2, gutter: 12, mq: '720px' },
  { columns: 3, gutter: 12, mq: '960px' },
  { columns: 4, gutter: 10, mq: '1200px' },
  { columns: 5, gutter: 16, mq: '1460px' }
]

export interface CardBricksProps {
  sizes: BricksSize[]
  packed?: string
  className?: string
  children?: ReactNode
  align?: 'left' | 'center'

  onPacked?(): void
  onLayout?(): void
}

export class CardBricks extends Component<CardBricksProps, any> {

  static defaultProps = {
    sizes: DEFAULT_SIZES,
    packed: 'data-packed'
  }

  private container: HTMLDivElement
  private bricks: any
  private requestAnimationFrameId: number | undefined

  private elementsToUpdate: Element[]

  constructor(props: CardBricksProps) {
    super(props)

    this.elementsToUpdate = []

    this.onItemChanged = this.onItemChanged.bind(this)
    this.renderItem = this.renderItem.bind(this)
    this.processItemChanged = this.processItemChanged.bind(this)
  }

  get length() {
    return Children.count(this.props.children)
  }

  componentDidMount() {
    const instance = new Bricks({
      container: this.container,
      packed: this.props.packed || 'packed',
      sizes: this.props.sizes,
      position: false,
      onLayout: this.props.onLayout
    })

    instance.resize(true)

    if (this.length > 0) {
      instance.pack()
      if (this.props.onPacked) {
        this.props.onPacked()
      }
    }

    this.bricks = instance
  }

  componentDidUpdate(prevProps: CardBricksProps) {
    const prevLength = Children.count(prevProps.children)
    if (prevLength === 0 && this.length === 0) {
      return
    }

    if (prevLength === 0 && this.length > 0) {
      this.bricks.pack()
      if (this.props.onPacked) {
        this.props.onPacked()
      }
      return
    }

    if (prevLength !== this.length) {
      return this.bricks.update()
    }
  }

  componentWillUnmount() {
    this.bricks.resize(false)
  }

  onItemChanged(element: Element | undefined) {
    if (element) {
      this.elementsToUpdate.push(element)
    } else {
      this.bricks.pack()
      if (this.props.onPacked) {
        this.props.onPacked()
      }
      return
    }

    if (this.requestAnimationFrameId) {
      return
    }

    this.requestAnimationFrameId = requestAnimationFrame(this.processItemChanged)
  }

  processItemChanged() {
    this.requestAnimationFrameId = undefined
    this.bricks.resizeNodes(this.elementsToUpdate)
    this.elementsToUpdate = []
    this.bricks.pack()

    if (this.props.onPacked) {
      this.props.onPacked()
    }
  }

  isLoadingCard(element: ReactNode) {
    // EXPL: Only loading cards has active prop
    return (element as any).props.active !== undefined
  }

  renderItem(index: number, key: string): ReactNode {
    const brickRef = {} as { ref: HTMLLIElement | undefined }
    const element = (this.props.children as Array<any>)[index]
    const isLoadingCard = this.isLoadingCard(element)
    const classLoading = isLoadingCard ? styles.loading : ''
    const cardProps: any = { key }
    if (!isLoadingCard) {
      cardProps.onSizeChanged = () => this.onItemChanged(brickRef.ref as HTMLLIElement)
    }

    return createElement('li', { key, className: `${styles.brick} ${classLoading}`, ref: (ref: HTMLLIElement) => { brickRef.ref = ref } },
      cloneElement(element, cardProps)
    )
  }

  renderBricks(): ReactNode[] {
    return Array.prototype.map.call(this.props.children, (child: ReactChild, index: number) => this.renderItem(index, getKey(child, index)))
  }

  render() {
    const { className, align } = this.props
    return createElement('ul', {
      className: `${className || ''} ${styles.bricks} ${align === 'left' ? '' : styles.center}`,
      ref: (ref: HTMLDivElement) => { this.container = ref }
    }, this.renderBricks())
  }
}

export default CardBricks
