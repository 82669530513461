import * as React from 'react'
import { FetchCuratedFolderPageContent } from 'components/Fetch'
import CuratedFolderListView from './CuratedFolderListView'
import { TIMERANGE_FILTER_DEFAULT } from 'config'
import { StatusIdea, CuratedFolder } from 'interfaces'

interface StatusIdeasCategoryListViewProps {
  folder: CuratedFolder
}

export const StatusIdeasCategoryListView = React.memo(function StatusIdeasCategoryListView(props: StatusIdeasCategoryListViewProps) {
  const [page, setPage] = React.useState(1)
  const [hasNextPage, setHasNextPage] = React.useState(true)
  const [items, setItems] = React.useState<StatusIdea[]>([])
  const [loading, setLoading] = React.useState(true)

  const onFetched = (newItems: StatusIdea[]) => {
    setLoading(false)
    if (newItems.length === 0) {
      setHasNextPage(false)
      return
    }
    setItems([...items, ...newItems])
  }

  const onFailed = () => {
    setLoading(false)
  }

  const fetchNextPage = () => {
    if (!loading && hasNextPage) {
      setPage(prev => prev + 1)
    }
  }

  return (
    <React.Fragment>
      <FetchCuratedFolderPageContent
        folder={props.folder}
        page={page}
        statusesSeed={Date.now()}
        range={TIMERANGE_FILTER_DEFAULT}
        onFetched={onFetched}
        onFailed={onFailed}
      />
      <CuratedFolderListView
        stream={props.folder as any}
        items={items}
        loading={loading}
        onScrollLimit={fetchNextPage}
      />
    </React.Fragment>
  )
})

export default StatusIdeasCategoryListView
