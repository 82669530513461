import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import { FormattedMessage, injectIntl } from 'react-intl'
import styles from './ComposerFilesDropHandler.pcss'
import { BRAND_TWITTER, BRAND_FACEBOOK, WithIntl } from 'interfaces'
import { MAX_COMPOSER_PHOTOS_COUNT_TWITTER, MAX_COMPOSER_PHOTOS_COUNT_FACEBOOK } from 'shared/constants'
import { addFiles, ComposerUploaderContext } from '../UploadContext'
import { useDispatch, useSelector } from 'react-redux'
import { composerTabsSelector } from 'services/compose/selectors'
import { message } from 'services/snackbar'
import { ALLOWED_PHOTO_FORMATS, ALLOWED_VIDEO_FORMATS } from 'utils/file'

interface ComposerFilesDropHandlerProps extends WithIntl {
  visible: boolean
  onClose: () => void
}

export function ComposerFilesDropHandler(props: ComposerFilesDropHandlerProps) {
  const { visible, intl, onClose } = props
  const storeDispatch = useDispatch()
  const { dispatch } = React.useContext(ComposerUploaderContext)
  const tabs = useSelector(composerTabsSelector)

  const handleAddedFiles = React.useCallback((files: FileList) => {
    const filesMap: { [name: string]: File } = {}
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)
      if (file) {
        filesMap[file.name] = file
      }
    }

    const filesTotalCount = Object.keys(filesMap).length

    if (filesTotalCount > 0) {
      const photos: File[] = []
      const videos: File[] = []

      Object.values(filesMap).forEach(file => {
        if (ALLOWED_PHOTO_FORMATS.includes(file.type)) {
          photos.push(file)
        } else if (ALLOWED_VIDEO_FORMATS.includes(file.type)) {
          videos.push(file)
        }
      })

      if (photos.length + videos.length === 0) {
        storeDispatch(message(intl.formatMessage({ id: 'composer.notifications.dropped-invalid-files' }), 'warning'))
        onClose()
        return
      }

      if (photos.length + videos.length < filesTotalCount) {
        storeDispatch(message(intl.formatMessage({ id: 'composer.notifications.dropped-some-invalid-files' }), 'warning'))
      }

      if (tabs.includes(BRAND_TWITTER) && photos.length > MAX_COMPOSER_PHOTOS_COUNT_TWITTER) {
        storeDispatch(message(intl.formatMessage(
          { id: 'composer.notifications.tw-photos-count-limit-hit' },
          { count: MAX_COMPOSER_PHOTOS_COUNT_TWITTER }
        ), 'warning'))
      }

      if (tabs.includes(BRAND_FACEBOOK) && photos.length > MAX_COMPOSER_PHOTOS_COUNT_FACEBOOK) {
        storeDispatch(message(intl.formatMessage(
          { id: 'composer.notifications.fb-photos-count-limit-hit' },
          { count: MAX_COMPOSER_PHOTOS_COUNT_FACEBOOK }
        ), 'warning'))
      }

      if (photos.length > 0) {
        const filesState = photos.map(photo => ({ file: photo }))
        dispatch(addFiles(filesState))
      } else if (videos.length > 1) {
        storeDispatch(message(intl.formatMessage({ id: 'composer.notifications.one-video-only' }), 'warning'))
      } else if (videos.length === 1) {
        dispatch(addFiles([{ file: videos[0], isVideo: true }]))
      }
    }
  }, [dispatch, intl, onClose, storeDispatch, tabs])

  const onPaste = React.useCallback(async (e: any) => {
    handleAddedFiles(e.clipboardData.files)
  }, [handleAddedFiles])

  React.useEffect(() => {
    document.addEventListener('paste', onPaste)
    return () => {
      document.removeEventListener('paste', onPaste)
    }
  }, [onPaste])

  const onDrop = React.useCallback((e: React.DragEvent) => {
    e.preventDefault()
    handleAddedFiles(e.dataTransfer.files)
    onClose()
  }, [handleAddedFiles, onClose])

  return (
    <Backdrop open={visible} className={styles.backdrop}>
      <div className={styles.dropbox} onDrop={onDrop} onClick={onClose}>
        <div className={styles.border}>
          <h3 className={styles.title}>
            <FormattedMessage id="composer.labels.drop-hint" />
          </h3>
        </div>
      </div>
    </Backdrop>
  )
}

export default injectIntl(ComposerFilesDropHandler)
