import * as React from 'react'
import styles from './FileCard.pcss'
import UploadIcon from '@mui/icons-material/CloudUpload'
import { FormattedMessage } from 'react-intl'
import { ConnectedFileInput } from 'components/FileHelpers'
import { useNavigate } from 'react-router-dom'

interface FileUploadCTACardProps {
  small?: boolean
}

export const FileUploadCTACard = React.memo(function FileUploadCTACard(props: FileUploadCTACardProps) {
  const navigate = useNavigate()

  const navigateToMyLibrary = () => {
    navigate('/content/library')
  }

  return (
    <div className={`${styles['cta-card']} ${props.small ? styles.small : ''}`}>
      <ConnectedFileInput
        openDialogOnChange
        onChange={navigateToMyLibrary}
      >
        <React.Fragment>
          <UploadIcon className={styles['icon-cta']} />
          <FormattedMessage id="uploads.upload-content-cta" />
        </React.Fragment>
      </ConnectedFileInput>
    </div>
  )
})

export default FileUploadCTACard
