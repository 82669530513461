import * as React from 'react'
import Avatar, { AvatarProps } from '@mui/material/Avatar'

import styles from './SquareAvatar.pcss'

export interface SquareAvatarProps extends AvatarProps {
  active?: boolean
  background?: string

  /**
   * Overrides the default radius by a number of pixels or a css size string
   *
   * @type {(number | string)}
   * @memberOf SquareAvatarProps
   */
  radius?: number | string

  /**
   * Overrides the default size by a number of pixels or a css size string
   *
   * @type {number}
   * @memberOf SquareAvatarProps
   */
  size?: number | string

  /**
   * If true, sets the default radius to 50%
   *
   * @type {boolean}
   * @memberOf SquareAvatarProps
   */
  circle?: boolean

  /**
   * If true, show hashtag sign with random gradient background
   *
   * @type {boolean}
   * @memberof SquareAvatarProps
   */
  hashtag?: boolean

  /**
   * Indicates a keyword avatar - Uppercase first letter of the feed
   *
   * @type {boolean}
   * @memberof SquareAvatarProps
   */
  keyword?: string
}

function SquareAvatar(props: SquareAvatarProps, ref: React.RefObject<any>) {
  const { background, className, circle, style, radius, size, hashtag, keyword, active, ...otherProps } = props
  const combinedClassName = `${className || ''} ${styles.avatar} `
      + `${circle ? styles.circle : styles.square} ${active ? styles.active : ''}`

  if (keyword) {
    return (
      <div {...otherProps as any} className={`${combinedClassName} ${styles.keyword}`}>
        {keyword}
      </div>
    )
  }

  const cssRadius = typeof radius === 'number' ? `${radius}px` : radius
  const cssSize = typeof size === 'number' ? `${size}px` : size
  const styleRadius = cssRadius ? { borderRadius: cssRadius } : undefined
  const styleSize = cssSize ? { width: cssSize, height: cssSize } : undefined
  const backgroundStyle = background ? { background } : undefined

  const combinedStyle = Object.assign({}, style,
    styleRadius,
    styleSize,
    backgroundStyle
  )

  return (
    <Avatar
      {...otherProps}
      ref={ref}
      style={combinedStyle}
      className={combinedClassName}
    />
  )
}

export default React.forwardRef(SquareAvatar)
