import * as React from 'react'
import { List } from 'interfaces'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import styles from './SidebarListSelector.pcss'
import { ALL_LIST_KEY, listsOrderSelector, userListsSelector } from 'services/lists/selectors'
import { DndContext, useDroppable, useSensors, useSensor, PointerSensor } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { SortableContext, rectSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { reorder, sortByKeyAscending } from 'shared'
import { Subject } from 'rxjs/Subject'
import { useDispatch, useSelector } from 'react-redux'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { message } from 'services/snackbar'
import { useIntl } from 'react-intl'
import { reorderLists } from 'services/lists/actions'
import { StoreThunkDispatch } from 'store/state'

interface SidebarListSelectorProps {
  selectedList?: List
  onSelectedListChange: (list: List) => void
}

export function SidebarListSelector(props: SidebarListSelectorProps) {
  const lists = useSelector(userListsSelector)
  const listsOrder = useSelector(listsOrderSelector)
  const dispatch = useDispatch<StoreThunkDispatch>()
  const intl = useIntl()
  const [order, setOrder] = React.useState<string[]>(listsOrder)
  const reorder$ = React.useRef<Subject<string[]>>()
  const orderRef = React.useRef<string[]>(listsOrder)

  React.useEffect(() => {
    orderRef.current = listsOrder
  }, [listsOrder])

  React.useEffect(() => {
    setOrder(listsOrder)
  }, [listsOrder])

  React.useEffect(() => {
    reorder$.current = new Subject()
    reorder$.current.switchMap((ids) => {
      return Observable.fromPromise(dispatch(reorderLists(ids)).unwrap()).pipe(catchError((error) => {
        dispatch(message(intl.formatMessage({ id: 'notifications.generic.update-failed' }), 'error'))
        return Observable.of({ error })
      }))
    })
      .subscribe((response: any) => {
        if (response.error) {
          // revert to previous ordering
          setOrder(orderRef.current)
        }
      })
  }, [dispatch, intl])

  const onSelectedListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const listId = (event.target as HTMLInputElement).value
    props.onSelectedListChange(lists[listId])
  }

  const onDragEnd = (event: any) => {
    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = order.indexOf(active.id)
      const newIndex = order.indexOf(over.id)
      const reordered = reorder(order, oldIndex, newIndex)
      setOrder(reordered)
      reorder$.current?.next(reordered)
    }
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5
      }
    })
  )

  const { setNodeRef: setDroppableNodeRef } = useDroppable({ id: 'droppable' })

  return (
    <DndContext sensors={sensors} onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]}>
      <SortableContext items={order} strategy={rectSortingStrategy} modifiers={[restrictToParentElement]}>
        <div ref={setDroppableNodeRef} data-testid="lists-selector">
          <RadioGroup
            aria-label="lists"
            name="lists"
            value={props.selectedList ? props.selectedList.id : false}
            classes={{ root: styles['lists-box'] }}
            onChange={onSelectedListChange}
          >
            <FormControlLabel
              key={ALL_LIST_KEY}
              value={lists[ALL_LIST_KEY].id}
              label={(
                <span className={styles['list-label']}>
                  <span className="text-ellipsis">{lists[ALL_LIST_KEY].name}</span>
                  {lists[ALL_LIST_KEY].connectedPages && <span>{`(${lists[ALL_LIST_KEY].connectedPages.length})`}</span>}
                </span>
              )}
              classes={{ label: styles['list-name'] }}
              control={<Radio size="small" color="primary" className={styles.radio} />}
            />
            {order.map(id => lists[id] ? <DraggableList list={lists[id]} key={id} /> : null)}
          </RadioGroup>
        </div>
      </SortableContext>
    </DndContext>
  )
}

function DraggableList({ list }: { list: List }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: list.id })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    pointerEvents: isDragging ? 'none' : 'all'
  } as any

  return (
    <FormControlLabel
      key={list.id}
      value={list.id}
      ref={setNodeRef}
      style={style}
      label={(
        <span className={styles['list-label']} {...listeners} {...attributes}>
          <span className="text-ellipsis">{list.name}</span>
          {list.connectedPages && <span>{`(${list.connectedPages.length})`}</span>}
        </span>
      )}
      classes={{ label: styles['list-name'] }}
      control={<Radio size="small" color="primary" className={styles.radio} />}
    />
  )
}
