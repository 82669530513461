import * as React from 'react'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { FormattedMessage } from 'react-intl'
import styles from './ComposerActionsButton.pcss'

interface AddToBucketButtonProps {
  disabled: boolean
  postsCount: number
  className?: string
  btnClassName?: string
  onSubmit: (shareType: 'queue' | 'next') => void
}

export function AddToBucketButton(props: AddToBucketButtonProps) {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: MouseEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const onShareNext = () => {
    props.onSubmit('next')
    setOpen(false)
  }

  const onAddToPlan = () => {
    props.onSubmit('queue')
    setOpen(false)
  }

  return (
    <div className={`${styles.container} ${props.className || ''}`}>
      <ButtonGroup
        disabled={props.disabled}
        variant="contained"
        color="primary"
        ref={anchorRef}
        disableRipple
        className={`${styles['btn-group']} ${props.btnClassName || ''}`}
      >
        <Button className={styles['btn-main']} onClick={onAddToPlan}>
          <FormattedMessage id="composer.actions.save-to-bucket" values={{ posts: props.postsCount }} />
        </Button>
        <Button
          color="primary"
          size="small"
          disableElevation
          data-testid="btn-menu-toggle"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} disablePortal className={styles.popper}>
        <Paper>
          <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClose}>
            <MenuList>
              <MenuItem onClick={onShareNext} className={styles['menu-item']}>
                <FormattedMessage id="composer.actions.share-next" values={{ count: props.postsCount }} />
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  )
}

export default AddToBucketButton
