import { PostDestination, PostDestinationType, ErrorResponse, BRAND_TWITTER, BRAND_FACEBOOK, LegacyPage } from 'interfaces'
import assert from 'services/errors'

function getTypedId(network: PostDestinationType, type: string, id: string, token: string) {
  if (type === '2') { // Facebook group
    return `fb_group-${id}-grouptoken-${token}`
  }

  if (network === BRAND_FACEBOOK) {
    return `fb_page-${id}`
  }

  if (network === BRAND_TWITTER) {
    return `tw_profile-${id}`
  }

  if (type === '6') { // Linkedin page
    return `li_page-${id}`
  }

  if (type === '7') { // Linkedin page
    return `li_brandpage-${id}`
  }

  if (type === '5') { // Linkedin profile
    return `li_profile-${id}`
  }

  if (type === '11') {
    return `ig_business_account-${id}`
  }

  if (type === '21') {
    return `pi_account-${id}`
  }

  return id
}

export function destinationAdapter(page: LegacyPage): PostDestination {
  const network = page.typeText as PostDestinationType
  const token = page.token
  const typedId = getTypedId(network, page.type, page.networkId, token)
  let image = page.picture
  if (network === BRAND_FACEBOOK) {
    image += `&access_token=${token}`
  }

  return {
    id: page.networkId,
    type: network,
    idNew: `${page.type}_${page.networkId}`,
    image,
    ppid: page.ppPageId,
    name: page.pageName || page.username || '',
    connected: !!+page.connected,
    default: !!+page.default,
    fbGroup: page.type === '2',
    isLinkedinPersonalProfile: page.type === '5',
    typedId,
    token,
    tokenV2: page.tokenV2,
    isMigrated: !!page.isMigrated,
    ownedBy: page.ownedBy,
    ownerId: page.ownerId,
    publishBy: page.publishBy,
    ppPublishBy: page.ppPublishBy,
    extraAdmin: Boolean(page.extraAdmin)
  }
}

export function destinationsAdapter(response: LegacyPage[] | ErrorResponse): PostDestination[] {
  const pagesResponse = assert<LegacyPage[]>(response)
  return pagesResponse.map(destinationAdapter)
}
