import { Paginated, Text } from 'interfaces'
import { TEXT_TYPE } from 'interfaces/Content/ContentType'
import { TYPE_FEED_TYPE_MAP } from './mappings'
import mapAvatar from './avatar'
import { parseDate } from 'utils/format/date'

const BASE_TEN = 10

export function textAdapter(text: any): Text {
  const feed = text.feed || {}
  const feedType = TYPE_FEED_TYPE_MAP[feed.type]
  const feedId = feed.id?.toString()

  return {
    id: `${feedType}:${text.id}`,
    rawId: text.id + '',
    hash: text.searchId,
    type: TEXT_TYPE,
    imageUrl: '',
    comments: text.comments,
    createdAt: parseDate(text.createdAt),
    likes: text.likes,
    rating: text.rating / BASE_TEN,
    shares: text.shares,
    socialLink: text.socialLink,
    live: text.live,
    isFavorite: text.isFavorited,
    tweetText: text.tweetTitle,
    feed: {
      id: feedId,
      uniqueSource: feedId,
      isFeed: true,
      image: feed.image || mapAvatar(feedType, feed),
      name: feed.name,
      type: feedType,
      sources: feed.sources,
      order: 0,
      handle: feed.handle,
      networkId: feed.networkId,
      title: feed.title
    },
    favCount: text.favCount || 0,
    provider: text.adapter,
    status: text.status || text.tweetTitle
  }
}

export function textsAdapter(texts: any[]): Paginated<Text[]> {
  return {
    items: texts.map(textAdapter),
    hasNext: texts.length !== 0
  }
}
