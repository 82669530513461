import * as React from 'react'
import { connect } from 'react-redux'
import { PROFITWELL_PUBLIC_API_TOKEN } from 'config'
import StoreState from 'store/state'
import { currentUserSelector } from 'services/users/selectors'

export const ProfitwellScript = React.memo(function ProfitwellScript(props: { customerId?: number }) {
  React.useEffect(() => {
    if (!PROFITWELL_PUBLIC_API_TOKEN || !props.customerId) {
      return
    }

    const script = document.createElement('script')
    script.id = 'profitwell-js'
    script.setAttribute('data-pw-auth', PROFITWELL_PUBLIC_API_TOKEN)
    document.head.appendChild(script)

    /** Inject profitwell script */
    /* eslint-disable */
    ; (function (i: any, s: any, o: any, g: any, r: any) {
      i[o] = i[o] || function () { (i[o].q = i[o].q || []).push(arguments) }
      const a = s.createElement(g) as any
      const m = s.getElementsByTagName(g)[0]
      a.async = 1
      a.src = r + `?auth=${PROFITWELL_PUBLIC_API_TOKEN}`
      m.parentNode.insertBefore(a, m)
    })(window, document, 'profitwell', 'script', 'https://public.profitwell.com/js/profitwell.js')

    ; (window as any).profitwell('start', { 'user_id': props.customerId })
  }, [])

  return null
})

function mapStateToProps(state: StoreState) {
  const user = currentUserSelector(state)

  return {
    customerId: user?.account.customerId
  }
}

export default connect(mapStateToProps)(ProfitwellScript)
