import * as React from 'react'
import { Stream } from 'interfaces'
import StreamView, { StreamViewProps } from './StreamView'
import { FetchStream } from 'components/Fetch'
import SimpleLoader from 'components/SimpleLoader'

export interface StreamViewWithFetchProps extends Omit<StreamViewProps, 'stream'> {
  streamId: string
}

export function StreamViewWithFetch(props: StreamViewWithFetchProps) {
  const { streamId, ...rest } = props
  const [stream, setStream] = React.useState<Stream | null>(null)

  if (!stream || stream.id !== streamId) {
    return (
      <div>
        <FetchStream id={streamId} onFetched={setStream} />
        <SimpleLoader />
      </div>
    )
  }

  return (
    <StreamView {...rest} stream={stream} />
  )
}

export default StreamViewWithFetch
