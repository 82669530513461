import * as React from 'react'
import classnames from 'classnames'
import { ARTICLE_TYPE, ContentType } from 'interfaces'
import { useSelector } from 'react-redux'

// Components
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'

// Utils
import { featuresSelector } from 'services/product'
import { userProductSelector } from 'services/users/selectors'

// Styles
import styles from './ContentCardUpgradeOverlay.pcss'

export interface ContentCardUpgradeOverlayProps {
  feature: string
  contentType: ContentType
  imageUrl?: string
  className?: string
  onUpgrade: (feature: string) => void
  favButton: any
}

export function ContentCardUpgradeOverlay({
  feature,
  onUpgrade,
  contentType,
  imageUrl,
  className,
  favButton
}: ContentCardUpgradeOverlayProps) {
  const product = useSelector(userProductSelector)
  const features = useSelector(featuresSelector)
  const featureInfo = features[feature]
  const withAttr = contentType === ARTICLE_TYPE

  return (
    <div className={classnames(styles.container, { [styles['with-attr']]: withAttr }, className)}>
      {favButton}

      <div className={styles['img-box']}>
        {imageUrl && <img src={imageUrl} />}
      </div>

      <div className={styles.attr}>
        <div className={classnames(styles.placeholder, styles.title)}></div>

        <div className={classnames(styles.placeholder, styles.subtitle)}></div>

        <div className={styles.box}>
          <div className={classnames(styles.placeholder, styles.icon)}></div>

          <div className={classnames(styles.placeholder, styles.source)}></div>

          <div className={classnames(styles.placeholder, styles.rating)}></div>
        </div>
      </div>

      <div className={styles.content}>
        <p className={styles.message}>The <em>{product?.name}</em> plan does<br /> not include:</p>

        <p className={styles.message}>{featureInfo?.name}</p>

        <p className={styles.message}>Get access by moving to<br /> a higher plan.</p>

        <Button variant="contained" color="primary" className={styles.btn} onClick={() => onUpgrade(feature)}>
          <FormattedMessage id="actions.learn-more" />
        </Button>
      </div>
    </div>
  )
}

export default ContentCardUpgradeOverlay
