import { createSelector } from 'reselect'
import { StoreState } from 'store/state'
import { initialState } from './state'
import { connectedDestinationsSelector } from 'services/destinations'
import { WEEKDAY_SUNDAY } from 'utils/calendar'
import { Bucket, IndexedObject } from 'interfaces'
import { sortByKeyAscending } from 'utils/sort/order'

const postsSelector = (state: StoreState) => state.post || initialState()
export const newPostSelector = createSelector(postsSelector, (posts) => posts.newPost)
export const autoPostSlotsSelector = createSelector(postsSelector, state => state.autoPostSlots)
export const postsUISelector = createSelector(postsSelector, state => state.ui)
export const sidebarActiveViewSelector = createSelector(postsUISelector, ui => ui.selectionActiveView)
export const emptySlotsVisibleSelector = createSelector(postsUISelector, ui => ui.showEmptySlots)
export const virtualPostsVisibleSelector = createSelector(postsUISelector, ui => ui.showVirtualPosts)
export const selectedListSelector = createSelector(postsUISelector, (ui) => ui.selectedListId)
export const postsWeekViewExpandedSelector = createSelector(postsUISelector, ui => ui.weekViewExpanded)
export const bucketPresetsSelector = createSelector(postsSelector, state => state.bucketPresets)
export const bucketsSelector = createSelector(postsSelector, connectedDestinationsSelector, (state, profiles) => {
  const ppids = Object.values(profiles).map(p => p.ppid)
  return [...state.buckets].sort(sortByKeyAscending('order')).map(b => ({
    ...b,
    ppids: b.ppids.filter(id => ppids.includes(id))
  }))
})
export const bucketsMapSelector = createSelector(bucketsSelector, buckets => buckets.reduce((map: IndexedObject<Bucket>, b: Bucket) => {
  map[b.id] = b
  return map
}, {}))

export const selectedPostProfilesSelector = createSelector(
  postsUISelector,
  connectedDestinationsSelector,
  (postsUI, connectedProfiles) => {
    const ids = postsUI.selectedProfiles.map(p => p.id)
    return ids.map(id => connectedProfiles[id]).filter(Boolean)
  }
)

export const selectedProfilesSelector = createSelector(
  postsUISelector,
  connectedDestinationsSelector,
  (postsUI, connectedProfiles) => {
    return postsUI.selectedProfiles.filter(p => Boolean(connectedProfiles[p.id]))
  }
)

export const formattedSelectedProfilesSelector = createSelector(
  selectedProfilesSelector,
  connectedDestinationsSelector,
  (profiles, connectedProfiles) => {
    const ids = profiles.map(p => p.id)
    return ids.map(id => connectedProfiles[id] && connectedProfiles[id].idNew).filter(Boolean)
  }
)

export const selectedProfilesFetchIdsSelector = createSelector(
  selectedProfilesSelector,
  connectedDestinationsSelector,
  (profiles, connectedProfiles) => {
    const ids = profiles.map(p => p.id)
    return ids.map(id => connectedProfiles[id] && connectedProfiles[id].idNew).filter(Boolean).join(',')
  }
)

export const selectedProfilesPPIdsSelector = createSelector(
  selectedProfilesSelector,
  connectedDestinationsSelector,
  (profiles, connectedProfiles) => {
    const ids = profiles.map(p => p.id)
    return ids.map(id => connectedProfiles[id] && connectedProfiles[id].ppid).filter(Boolean)
  }
)

export const selectedProfilePostedSelector = createSelector(
  connectedDestinationsSelector,
  selectedProfilesSelector,
  (profiles, selectedProfiles) => {
    const profile = selectedProfiles[0]
    if (profile && profiles[profile.id]) {
      return profile
    }
    return Object.values(profiles)[0]
  }
)

export const plannedPostsLayoutSelector = createSelector(postsUISelector, state => state.plannedPostsView)
export const calendarPostSizeSelector = createSelector(postsUISelector, state => state.calendarPostSize)
export const calendarWeekStartSelector = createSelector(postsUISelector, state => state.calendarWeekStart || WEEKDAY_SUNDAY)
export const calendarMonthStartSelector = createSelector(postsUISelector, state => state.calendarMonthStart || 'this-week')
export const calendarPostedVisibleSelector = createSelector(postsUISelector, state => state.showPostedInCalendar)
export const failedPostsCountSelector = createSelector(postsSelector, state => state.failed)
