import * as React from 'react'
import { CalendarDay } from 'interfaces/Post/Calendar'
import { FormattedMessage } from 'react-intl'
import { mdiArrowExpandDown } from '@mdi/js'
import { Icon } from '@mdi/react'
import styles from './CalendarViewHeader.pcss'
import Tooltip from '@mui/material/Tooltip'
import { format } from 'date-fns'

interface CalendarWeekViewHeaderProps {
  days: CalendarDay[]
  expanded: boolean
  className?: string
  cellClassName?: string
  highlightCurrentDay?: boolean
  expandBtnTooltip?: string
  collapseBtnTooltip?: string
  onToggleExpanded?: () => void
  renderHeaderSubtitle?: (day: CalendarDay) => React.ReactNode
}

export function CalendarWeekViewHeader(props: CalendarWeekViewHeaderProps) {
  const activeClass = props.onToggleExpanded ? styles.active : ''
  const flipClass = props.expanded ? styles.flip : ''
  const btnToggleTooltip = props.expanded ? props.collapseBtnTooltip : props.expandBtnTooltip

  return (
    <header className={`${styles.header} ${props.className || ''}`}>
      <Tooltip title={btnToggleTooltip}>
        <div
          className={`${styles['cell-toggle']} ${activeClass} ${flipClass}`}
          onClick={props.onToggleExpanded}
          data-testid="calendar-week-view-toggle"
        >
          {props.onToggleExpanded && <Icon path={mdiArrowExpandDown} />}
        </div>
      </Tooltip>
      {props.days && props.days.map((day) => {
        const label = format(day.date, 'EEEE').toLowerCase()
        const activeClass = day.isToday && props.highlightCurrentDay ? styles.active : ''
        return (
          <div
            key={day.dayIndex}
            className={`${styles['header-cell']} ${activeClass} ${props.cellClassName || ''}`}
            data-testid={activeClass ? 'calendar-week-view-header-today' : undefined}
          >
            <FormattedMessage id={`calendar.days.short.${label}`} />
            {props.renderHeaderSubtitle
              ? props.renderHeaderSubtitle(day)
              : (
                <div className={styles.date}>
                  {day.date.getDate()}
                </div>
              )}
          </div>
        )
      })}
    </header>
  )
}

export default CalendarWeekViewHeader
