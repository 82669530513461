import { createSlice } from '@reduxjs/toolkit'
import { VideoTrainingSection } from 'admin/interfaces'
import { User } from 'interfaces'
import { TrialTask } from 'routes/trial/interfaces'
import { checkSubscriptionSuccess, fetchTrainingVideoSections, getTrialTasks, getUserSuccess, updateIntroProgress } from './actions'
import { logoutAction, setTrialStatus } from 'services/auth'
import { getAccountSuccess } from 'services/settings'
import { upgradeSuccess } from 'services/product'

export const USER_STATE_VERSION = 'user:1'

export interface UserState {
  current: User | null
  trialTasks: TrialTask[]
  trainingVideos: VideoTrainingSection[]
}

const initialState: UserState = {
  current: null,
  trialTasks: [],
  trainingVideos: []
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(updateIntroProgress.fulfilled, (state, action) => {
        if (state.current) {
          state.current.finishedSetup = action.payload
        }
      })
      .addCase(checkSubscriptionSuccess, (state, action) => {
        if (state.current) {
          state.current.productInfo = { ...action.payload.productInfo, updatedAt: new Date().toISOString() }
          state.current.product = action.payload.product
          state.current.account.trialEndsAt = action.payload.trialEndsAt
          if (action.payload.roles && state.current.roles) {
            state.current.roles.isAdmin = action.payload.roles.isAdmin
            state.current.roles.isBeta = action.payload.roles.isBeta
          }
        }
      })
      .addCase(getUserSuccess, (state, action) => {
        const user = action.payload
        state.current = {
          ...user,
          account: {
            ...user.account,
            trialEndsAt: user.account.trialEndsAt || (user.account as any).trialEndedAt
          }
        }
      })
      .addCase(getTrialTasks.fulfilled, (state, action) => {
        state.trialTasks = action.payload
      })
      .addCase(fetchTrainingVideoSections.fulfilled, (state, action) => {
        state.trainingVideos = action.payload
      })
      .addCase(setTrialStatus, (state, action) => {
        const { trial, status, hasChargifyInfo, chargifySubscriptionId } = action.payload
        if (state.current) {
          state.current.account.trial = trial
          state.current.account.status = status
          state.current.account.hasChargifyInfo = hasChargifyInfo
          state.current.account.chargifySubscriptionId = chargifySubscriptionId
        }
      })
      .addCase(getAccountSuccess, (state, action) => {
        if (state.current) {
          if (action.payload.isImpersonating) {
            state.current.account.masterAdmin = action.payload.subscription.masterAdmin
          }
          state.current.account.chargifySubscriptionId = action.payload.subscription.chargifySubscriptionId
          state.current.account.trialEndsAt = action.payload.trialEndsAt
        }
      })
      .addCase(upgradeSuccess, (state, action) => {
        const { newPlan, productInfo } = action.payload
        if (state.current) {
          state.current.product = newPlan
          state.current.productInfo = productInfo
        }
      })
      .addCase(logoutAction, state => {
        state.current = null
      })
  }
})

export default userSlice.reducer
