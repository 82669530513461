import * as React from 'react'
import { StatusIdea, CuratedFolder } from 'interfaces'
import { CuratedFolderListView } from 'components/CuratedFolderView'
import { Subject } from 'rxjs/Subject'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { getStatusIdeas } from 'services/content/statuses/actions'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'

interface StatusIdeasCarouselProps {
  items: StatusIdea[]
  page: number
  category: CuratedFolder
  onStatusesFetched: (categoryId: string, items: StatusIdea[], page: number) => void
  onLoadMore: (categoryId: string) => void
}

export function StatusIdeasCarousel(props: StatusIdeasCarouselProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [loading, setLoading] = React.useState(true)
  const fetch$ = React.useRef<Subject<number>>()
  const seedRef = React.useRef(Date.now().toString())
  const pageRef = React.useRef(props.page)
  const categoryRef = React.useRef(props.category)

  React.useEffect(() => {
    fetch$.current = new Subject()
    fetch$.current.flatMap(() => {
      setLoading(true)
      const ids = [categoryRef.current.streams[0].id.toString()]
      // EXPL: We want to fetch random first page every time
      const seed = pageRef.current === 0 ? Date.now().toString() : seedRef.current
      return dispatch(getStatusIdeas(ids, pageRef.current, seed))
        .pipe(catchError(() => {
          return Observable.of({ items: [] })
        }))
    })
      .subscribe((data) => {
        setLoading(false)
        props.onStatusesFetched(categoryRef.current.id, data.items, pageRef.current)
      })
  }, [])

  React.useEffect(() => {
    categoryRef.current = props.category
    if (props.page === 0 || pageRef.current !== props.page) {
      pageRef.current = props.page
      fetch$.current?.next(props.page)
    }
  }, [props.category.id, props.page])

  const loadNextPage = () => {
    props.onLoadMore(categoryRef.current.id)
  }

  return (
    <CuratedFolderListView
      stream={categoryRef.current as any}
      items={props.items}
      loading={loading}
      onScrollLimit={loadNextPage}
    />
  )
}
