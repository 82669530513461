import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { activePostIdSelector, composerSelectedBucketIdSelector, composerSelectedProfilesIdsSelector } from 'services/compose/selectors'
import { sortedConnectedProfilesSelector } from 'services/destinations'
import { bucketsSelector } from 'services/post/selectors'
import Accordion from '../Accordion'
import styles from './ProfileSelection.pcss'
import Icon from '@mdi/react'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import { SelectableAvatar } from 'components/ProfileAvatar'
import { Bucket, PostDestination } from 'interfaces'
import { mdiPail } from '@mdi/js'
import { setComposerBucket, setComposerProfiles } from 'services/compose'
import { FetchPostDestinations } from 'components/Fetch'
import { FetchBuckets } from 'components/Fetch/FetchBuckets'
import { ConnectedProfilesWarningPopup } from './ConnectedProfilesWarningPopup'
import ProfilesDropdown from '../ProfilesDropdown'

export function ProfileSelection() {
  const dispatch = useDispatch()
  const connectedProfiles: PostDestination[] = useSelector(sortedConnectedProfilesSelector)
  const selectedProfilesIds: string[] = useSelector(composerSelectedProfilesIdsSelector)
  const selectedBucketId: string | undefined = useSelector(composerSelectedBucketIdSelector)
  const buckets: Bucket[] = useSelector(bucketsSelector)
  const bucketProfiles = selectedBucketId ? buckets.find(b => b.id === selectedBucketId)?.ppids || null : null
  const [selectedBucketProfiles, setSelectedBucketProfiles] = React.useState<{ ppids: string[], selectedPpids: string[] } | null>(
    bucketProfiles ? { ppids: bucketProfiles, selectedPpids: bucketProfiles } : null
  )
  const isEditMode = Boolean(useSelector(activePostIdSelector))
  const [loading, setLoading] = React.useState(true)

  const visibleBuckets = buckets.filter(b => b.ppids.length > 0)
  const visibleProfiles = React.useMemo(() => {
    if (selectedBucketProfiles) {
      return connectedProfiles.filter(p => selectedBucketProfiles.ppids.includes(p.ppid))
    }
    return connectedProfiles.filter(p => selectedProfilesIds.includes(p.id))
  }, [connectedProfiles, selectedBucketProfiles, selectedProfilesIds])

  const bucketSelectOptions: PPSelectOptions = React.useMemo(() => {
    const bucketOptions = visibleBuckets.reduce((options: PPSelectOptions, bucket) => {
      options[bucket.id] = {
        label: (
          <span className={styles['bucket-name']}>
            <Icon path={mdiPail} className={styles['icon-bucket']} style={{ color: bucket.color }} />
            <div className="text-ellipsis">{bucket.name}</div>
          </span>
        ),
        order: bucket.order
      }
      return options
    }, {})

    bucketOptions.none = {
      label: (
        <span className={styles['bucket-name']}>
          <FormattedMessage id="label.generic.none" />
        </span>
      )
      // order: -1
    }

    return bucketOptions
  }, [visibleBuckets])

  const onBucketSelected = (id: string) => {
    if (id === 'none') {
      dispatch(setComposerBucket(undefined))
      setSelectedBucketProfiles(null)
    }
    const bucket = buckets.find(b => b.id === id)
    if (bucket) {
      const profiles = Object.values(connectedProfiles).filter(p => bucket.ppids.includes(p.ppid))
      setSelectedBucketProfiles({ ppids: bucket.ppids, selectedPpids: bucket.ppids })
      dispatch(setComposerBucket(bucket.id))
      dispatch(setComposerProfiles(profiles))
    }
  }

  const stopLoading = () => {
    setLoading(false)
  }

  const onProfileClick = (profile: PostDestination) => {
    let nextSelection: PostDestination[] = []
    if (selectedBucketProfiles) {
      let selectedPpids = selectedBucketProfiles.selectedPpids.includes(profile.ppid)
        ? selectedBucketProfiles.selectedPpids.filter(p => p !== profile.ppid)
        : [...selectedBucketProfiles.selectedPpids, profile.ppid]

      if (selectedPpids.length === 0) {
        selectedPpids = selectedBucketProfiles.selectedPpids
      }
      nextSelection = visibleProfiles.filter(p => selectedPpids.includes(p.ppid))

      setSelectedBucketProfiles({
        ppids: selectedBucketProfiles.ppids,
        selectedPpids
      })
    } else {
      nextSelection = visibleProfiles.filter(p => p.id !== profile.id)
    }
    dispatch(setComposerProfiles(nextSelection))

  }

  const onProfileDblClick = (profile: PostDestination) => {
    if (selectedBucketProfiles) {
      setSelectedBucketProfiles(current => {
        if (!current) {
          return null
        }
        return { ...current, selectedPpids: [profile.ppid] }
      })
    }
    dispatch(setComposerProfiles([profile]))
  }

  const onDropdownProfilesSelectionChange = React.useCallback(() => {
    setSelectedBucketProfiles(null)
  }, [])

  return (
    <Accordion
      title={(
        <FormattedMessage id="composer.titles.profile-selection" />
      )}
      id="composer-profile-selection"
      order="1"
      headerElements={(
        <React.Fragment>
          {visibleBuckets.length > 0 && !isEditMode && (
            <PPSelect
              name={(
                <span className={styles['btn-label']}><FormattedMessage id="label.generic.bucket" /></span>
              )}
              options={bucketSelectOptions}
              selectedValue={selectedBucketId || 'none'}
              withCaret
              raised
              listClassName={styles['bucket-dd-list']}
              onSelectionChange={onBucketSelected}
            />
          )}
          <ProfilesDropdown btnClassName={styles.dropdown} onSelectionChange={onDropdownProfilesSelectionChange} />
        </React.Fragment>
      )}
    >
      <div className={styles.grid} data-testid="composer-selected-profiles-list">
        {visibleProfiles.map(profile => (
          <ProfileGridItem
            profile={profile}
            key={profile.id}
            selected={!selectedBucketId || Boolean(selectedBucketProfiles?.selectedPpids.includes(profile.ppid))}
            onClick={onProfileClick}
            onDoubleClick={onProfileDblClick}
          />
        ))}
      </div>
      <FetchPostDestinations onFetched={stopLoading} onFailed={stopLoading} />
      <FetchBuckets />
      <ConnectedProfilesWarningPopup open={!loading && Object.keys(connectedProfiles).length === 0} />
    </Accordion>
  )
}

function ProfileGridItem({ profile, selected, onClick, onDoubleClick }: {
  profile: PostDestination,
  selected: boolean,
  onClick: (p: PostDestination) => void,
  onDoubleClick: (p: PostDestination) => void
}) {
  const cancelClick = React.useRef(false)

  const onProfileClick = (e: any) => {
    if (e.detail > 1) {
      cancelClick.current = true
      onDoubleClick(profile)
    } else {
      setTimeout(() => {
        if (!cancelClick.current) {
          onClick(profile)
        }
        cancelClick.current = false
      }, 300) // eslint-disable-line no-magic-numbers
    }
  }

  return (
    <div className={styles['grid-item']} data-id={profile.id} onClick={onProfileClick}>
      <SelectableAvatar
        circle={!profile.fbGroup}
        src={profile.image}
        brand={profile.type}
        title={profile.name}
        selected={selected}
      />
    </div>
  )
}

export default ProfileSelection
