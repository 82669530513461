import {
  FEED_TYPE_FACEBOOK,
  FEED_TYPE_TWITTER,
  FEED_TYPE_INSTAGRAM,
  FEED_TYPE_RSS,
  FEED_TYPE_KEYWORD,
  ContentFeedType,
  ARTICLE_TYPE,
  PHOTO_TYPE,
  VIDEO_TYPE,
  GIF_TYPE,
  ALL_FILTER,
  FilterType,
  FEED_TYPE_REDDIT,
  FEED_TYPE_LINKEDIN
} from 'interfaces'
import { TEXT_TYPE } from 'interfaces/Content/ContentType'

import {
  STOCK_GIF_TYPE,
  STOCK_VIDEO_TYPE,
  STOCK_ALL_FILTER,
  STOCK_PHOTO_TYPE
} from 'interfaces/Content/StockContentTypes'

export const URL_SEGMENT_PHOTOS = 'images'
export const URL_SEGMENT_VIDEOS = VIDEO_TYPE
export const URL_SEGMENT_ARTICLES = ARTICLE_TYPE
export const URL_SEGMENT_GIFS = GIF_TYPE
export const URL_SEGMENT_ALL = ALL_FILTER
export const URL_SEGMENT_STOCK_PHOTOS = 'stock-images'
export const URL_SEGMENT_STOCK_VIDEOS = STOCK_VIDEO_TYPE
export const URL_SEGMENT_STOCK_GIFS = STOCK_GIF_TYPE
export const URL_SEGMENT_STOCK = 'stock'
export const URL_SEGMENT_TEXTS = 'texts'

export const KEYWORD_FEEDS_CONTENT_TYPES_DEFAULT = [
  ARTICLE_TYPE,
  // VIDEO_TYPE,
  STOCK_PHOTO_TYPE,
  STOCK_VIDEO_TYPE,
  STOCK_GIF_TYPE
]

export const TYPE_FEED_TYPE_MAP: { [key: string]: ContentFeedType } = {
  0: FEED_TYPE_RSS,
  1: FEED_TYPE_FACEBOOK,
  2: FEED_TYPE_TWITTER,
  3: FEED_TYPE_KEYWORD,
  4: FEED_TYPE_INSTAGRAM,
  5: FEED_TYPE_REDDIT,
  6: FEED_TYPE_LINKEDIN
}

export const FEED_TYPE_NUMBER_MAP: { [key: string]: number } = {
  [FEED_TYPE_RSS]: 0,
  [FEED_TYPE_FACEBOOK]: 1,
  [FEED_TYPE_TWITTER]: 2,
  [FEED_TYPE_KEYWORD]: 3,
  [FEED_TYPE_INSTAGRAM]: 4,
  [FEED_TYPE_REDDIT]: 5,
  [FEED_TYPE_LINKEDIN]: 6
}

export const URL_SEGMENT_TO_CONTENT_TYPE_MAP: { [type: string]: FilterType } = {
  [URL_SEGMENT_PHOTOS]: PHOTO_TYPE,
  [URL_SEGMENT_VIDEOS]: VIDEO_TYPE,
  [URL_SEGMENT_ARTICLES]: ARTICLE_TYPE,
  [URL_SEGMENT_GIFS]: GIF_TYPE,
  [URL_SEGMENT_TEXTS]: TEXT_TYPE,
  [URL_SEGMENT_ALL]: ALL_FILTER,
  [URL_SEGMENT_STOCK]: STOCK_ALL_FILTER,
  [URL_SEGMENT_STOCK_PHOTOS]: STOCK_PHOTO_TYPE,
  [URL_SEGMENT_STOCK_VIDEOS]: STOCK_VIDEO_TYPE,
  [URL_SEGMENT_STOCK_GIFS]: STOCK_GIF_TYPE
}

export const CONTENT_TYPE_TO_URL_SEGMENT_MAP: { [type: string]: string } = {
  [PHOTO_TYPE]: URL_SEGMENT_PHOTOS,
  [VIDEO_TYPE]: URL_SEGMENT_VIDEOS,
  [ARTICLE_TYPE]: URL_SEGMENT_ARTICLES,
  [GIF_TYPE]: URL_SEGMENT_GIFS,
  [ALL_FILTER]: URL_SEGMENT_ALL,
  [STOCK_ALL_FILTER]: URL_SEGMENT_STOCK,
  [STOCK_PHOTO_TYPE]: URL_SEGMENT_STOCK_PHOTOS,
  [STOCK_VIDEO_TYPE]: URL_SEGMENT_STOCK_VIDEOS,
  [STOCK_GIF_TYPE]: URL_SEGMENT_STOCK_GIFS,
  [TEXT_TYPE]: URL_SEGMENT_TEXTS
}
