import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import ToBottomIcon from '@mui/icons-material/ArrowDownward'
import ToTopIcon from '@mui/icons-material/ArrowUpward'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import CopyIcon from '@mui/icons-material/FileCopyOutlined'
import MoveIcon from '@mui/icons-material/DragIndicator'
import { FormattedMessage, injectIntl } from 'react-intl'
import ConfirmDialog from 'components/ConfirmDialog'
import Tooltip from '@mui/material/Tooltip'
import styles from './PlannedActionsMenu.pcss'
import { WithIntl } from 'interfaces'
import { RecycleSelector } from './components/RecycleSelector'
import { RepeatIndicator } from './components/RepeatIndicator'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ShareIcon from '@mui/icons-material/Send'

export interface PlannedActionsMenuProps {
  withRecycle: boolean
  selected: boolean
  recycle?: boolean | Date | number
  recycleReadonly?: boolean
  withRepeat?: boolean
  repeatLength?: number
  repeatPeriod?: string
  repeatUntil?: string
  className?: string
  btnClassName?: string
  withMoveControls: boolean | null
  dragHandleProps?: any
  virtual?: {
    repeatingLabel: string
    isForever: boolean
    type: 'repeat' | 'recycle'
  }
  checkboxProps?: CheckboxProps
  checkboxContainerClassName?: string
  menuBtnClassName?: string
  virtualLabelClassName?: string
  onToggleSelected: () => void
  onEdit: () => void
  onShareNow?: () => void
  onDelete?: () => void
  onCopy?: () => void
  onMoveToTop?: () => void
  onMoveToBottom?: () => void
  onRecycleChange?: (value: boolean | Date | number) => void
}

export function PlannedActionsMenu(props: PlannedActionsMenuProps & WithIntl) {
  const { virtual, recycle } = props
  const [anchorEl, setAnchorEl] = React.useState(null)

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget as any)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const menuAction = (fn: () => void) => () => {
    closeMenu()
    fn()
  }

  const onDelete = () => {
    closeMenu()
    if (props.onDelete) {
      props.onDelete()
    }
  }

  const stopPropagation = (e: any) => {
    e.stopPropagation()
  }

  const virtualPostLabel = React.useMemo(() => {
    if (!virtual) {
      return null
    }
    const labelArr = virtual.repeatingLabel.split(' ')
    return typeof recycle === 'number' || virtual.isForever
      ? labelArr.shift() + `\n${labelArr.join(' ')}`
      : virtual.repeatingLabel
  }, [virtual, recycle])

  const isVirtual = Boolean(virtual)

  return (
    <div className={`${styles['flex-row']} ${props.className || ''} ${virtual ? styles.virtual : ''}`}>
      {props.withMoveControls && (
        <div className={`${styles['flex-col']} ${styles.box} ${virtual?.type === 'recycle' ? styles.hidden : ''}`}>
          <Tooltip title={<FormattedMessage id="post.actions.move-to-top" />} placement="top">
            <span>
              <IconButton
                key="move-top"
                size="small"
                disabled={isVirtual}
                className={`${styles['btn-action']} ${props.btnClassName || ''}`}
                onClick={props.onMoveToTop}
              >
                <ToTopIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={<FormattedMessage id="post.actions.move-to-bottom" />} placement="top">
            <span>
              <IconButton
                key="move-bottom"
                size="small"
                disabled={isVirtual}
                className={`${styles['btn-action']} ${props.btnClassName || ''}`}
                onClick={props.onMoveToBottom}
              >
                <ToBottomIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      )}
      {props.withMoveControls && props.dragHandleProps && (
        <div className={styles.move} {...props.dragHandleProps}>
          <MoveIcon />
        </div>
      )}

      <div className={`${styles['flex-row']} ${styles.box} ${styles.mid}`}>
        {props.withRecycle && props.recycle !== undefined && props.onRecycleChange && (
          <RecycleSelector
            value={props.recycle}
            className={styles['btn-action']}
            activeClassName={styles.on}
            disabled={props.recycleReadonly}
            onChange={props.onRecycleChange}
          />
        )}
        {props.withRepeat && (
          <RepeatIndicator
            className={styles['btn-action']}
            repeatLength={props.repeatLength as number}
            repeatPeriod={props.repeatPeriod as string}
            repeatUntil={props.repeatUntil as string}
          />
        )}
        {virtual && (
          <div className={`${styles['recycle-label']} ${props.virtualLabelClassName || ''}`}>{virtualPostLabel}</div>
        )}
      </div>
      {!virtual && (
        <IconButton
          aria-label="More"
          aria-haspopup="true"
          size="small"
          className={props.menuBtnClassName || ''}
          onClick={openMenu}
        >
          <MoreVertIcon />
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        classes={{ paper: styles.menu }}
        onClose={closeMenu}
        onClick={stopPropagation}
      >
        <MenuItem onClick={menuAction(props.onEdit)}>
          <EditIcon className={styles.icon} />
          <FormattedMessage id="post.actions.edit" />
        </MenuItem>
        {props.onCopy && (
          <MenuItem onClick={menuAction(props.onCopy)}>
            <CopyIcon className={styles.icon} />
            <FormattedMessage id="post.actions.copy" />
          </MenuItem>
        )}
        {props.onShareNow && (
          <MenuItem onClick={menuAction(props.onShareNow)}>
            <ShareIcon className={styles.icon} />
            <FormattedMessage id="post.actions.share-now" />
          </MenuItem>
        )}
        {props.onDelete && (
          <ConfirmDialog
            message={props.intl.formatMessage({ id: 'post.delete-confirm' })}
            labelOK={props.intl.formatMessage({ id: 'actions.yes' })}
            labelCancel={props.intl.formatMessage({ id: 'actions.no' })}
          >
            {(confirm) => (
              <MenuItem onClick={confirm(onDelete)}>
                <DeleteIcon className={styles.icon} />
                <FormattedMessage id="post.actions.delete" />
              </MenuItem>
            )}
          </ConfirmDialog>
        )}
      </Menu>
      {!virtual && (
        <div className={`${styles.box} ${props.checkboxContainerClassName || ''}`}>
          <Checkbox
            {...props.checkboxProps}
            color="primary"
            onClick={stopPropagation}
            checked={props.selected}
            onChange={props.onToggleSelected}
          />
        </div>
      )}
    </div>
  )
}

export default injectIntl(PlannedActionsMenu)
