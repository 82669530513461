import contentReducer from 'services/content'
import composerReducer from 'services/compose'
import destinationsReducer from 'services/destinations'
import { listsReducer } from 'services/lists'
import snackbarReducer from 'services/snackbar/reducer'
import userReducer from 'services/users'
import planReducer from 'services/plan'
import postReducer from 'services/post'
import contentPreviewReducer from 'services/contentPreview'
import externalServicesReducer from 'services/external'
import authReducer from 'services/auth/reducer'
import settingsReducer from 'services/settings'
import contentPopupReducer from 'services/contentPopup'
import searchReducer from 'services/search/reducer'
import hydrationReducer from './hydration'
import versionReducer from './version'
import adminReducer from 'admin/services/reducer'
import uploadsReducer from 'services/uploads'
import listingsReducer from 'services/listings/reducer'
import appUIReducer from 'services/ui'
import productReducer from 'services/product'

export default {
  content: contentReducer,
  composer: composerReducer,
  destinations: destinationsReducer,
  lists: listsReducer,
  snackbar: snackbarReducer,
  user: userReducer,
  plan: planReducer,
  post: postReducer,
  contentPreview: contentPreviewReducer,
  external: externalServicesReducer,
  auth: authReducer,
  settings: settingsReducer,
  contentPopup: contentPopupReducer,
  uploads: uploadsReducer,
  search: searchReducer,
  hydration: hydrationReducer,
  version: versionReducer,
  admin: adminReducer,
  listings: listingsReducer,
  ui: appUIReducer,
  product: productReducer
}
