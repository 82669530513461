import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'
import { ContentType, PinterestBoard, PostType } from 'interfaces'
import { FBAlbum } from 'services/compose/interfaces/ComposerFacebookPost'

const ENDPOINT_COPY_POSTS = `${LEGACY_URL}/v3/posts/copy_v3`

export interface CopyPostsData {
  postIds: string[]
  copyToPPIds: string[]
  copyFromPPIds: string[]
  fbAlbums: FBAlbum[]
  piBoards: PinterestBoard[]
  all: boolean
  postType: PostType | 'scheduled'
  query?: string
  types?: ContentType[]
  bucketId?: string
  fromBucket?: string
  googleButtonType?: string
}

export const V1 = Object.freeze({
  copyPosts: function () {
    return function (data: CopyPostsData) {
      const builder = new RequestBuilder(ENDPOINT_COPY_POSTS).asPost().expectJSON()
      const body: any = {
        type: data.postType,
        pp_page_ids: data.copyFromPPIds,
        post_ids: data.postIds,
        destination_pp_page_ids: data.copyToPPIds,
        share_type: 'queue',
        networks: {
          facebook: {
            details: {
              albums: data.fbAlbums.map(a => ({ ...a, ppPageId: a.ppid }))
            }
          },
          pinterest: {
            details: {
              boards: data.piBoards.map(b => ({ ...b, pp_page_id: b.ppid }))
            }
          },
          google: {
            details: {
              button_type: data.googleButtonType
            }
          }
        }
      }

      if (data.all) {
        body.selected = 'all'
        body.post_ids = []
      }

      if (data.query) {
        body.search = data.query
      }

      if (data.types) {
        body.post_types = data.types.join(',')
      }

      if (data.bucketId) {
        body.destination_bucket_id = data.bucketId
      }

      if (data.fromBucket) {
        body.bucket_id = data.fromBucket
        if (body.type === 'planned') {
          body.type = 'planned_bucket'
        }
      }

      return authorizedPipe<any>(
        builder.body(body).build()
      )
    }
  }()
})
