import { StoreThunkAction } from 'store/state'
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/of'
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/filter'

import { TIMERANGE_FILTER_DEFAULT } from 'config'
import { Paginated, RangeFilter, Text } from 'interfaces'
import { TEXT_TYPE } from 'interfaces/Content/ContentType'
import { V1 } from './net'
import { getCacheKey, SortBy } from '../util'
import { cacheContentTemp, getCachedContent } from '../cache'
import { textsAdapter } from '../adapters/texts'

export function getTexts(
  sourceId: string,
  isStream?: boolean,
  page: number = 0,
  rangeFilter: RangeFilter = TIMERANGE_FILTER_DEFAULT,
  sortBy: SortBy = SortBy.Engagement
): StoreThunkAction<Observable<Paginated<Text[]>>> {
  return (dispatch) => {
    const cacheKey = getCacheKey(Boolean(isStream), sourceId, TEXT_TYPE, rangeFilter, page, sortBy)
    const cachedTexts = dispatch(getCachedContent(cacheKey))

    if (cachedTexts) {
      return Observable.of(cachedTexts as any)
    }

    const getTexts = isStream
      ? dispatch(V1.getTextsFromStream(sourceId, page, rangeFilter, sortBy))
      : dispatch(V1.getTexts(sourceId, page, rangeFilter, sortBy))

    return getTexts
      .map(response => textsAdapter(response.content))
      .do({
        next: (response) => {
          // Do not cache empty response
          if (response.items.length > 0) {
            dispatch(cacheContentTemp(cacheKey, response as any))
          }
        }
      })
  }
}
