import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialState } from './state'
import {
  initPlans,
  addSchedule,
  addTime,
  createPlan,
  deletePlan,
  deleteSchedule,
  deleteTime,
  getPlan,
  togglePlanPaused,
  addProfilesToPlan,
  updatePlanTimezone,
  updateTime,
  updatePlan
} from './actions'
import { PostingPlan } from 'interfaces'
import { uniqueStrings } from 'utils/unique'
import produce from 'immer'
import { ContentType } from 'shared/types'
import { LEGACY_BUCKET_NAMES_MAP } from './adapter'

const planSlice = createSlice({
  name: 'plan',
  initialState: initialState(),
  reducers: {
    setLastViewedPlan(state, action: PayloadAction<string>) {
      state.lastViewedPlan = action.payload
    },
    togglePlanViewExpanded(state) {
      state.planViewExpanded = !state.planViewExpanded
    },
    updateAllPlansTimezones: (state, action: PayloadAction<string>) => {
      Object.values(state.plans).forEach(plan => {
        plan.timezone = action.payload
      })
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getPlan.fulfilled, (state, action) => {
        const { planId, data } = action.payload
        state.plans[planId] = { ...data, name: state.planToProfilesMap[planId]?.name || data.name }
      })
      .addCase(createPlan.fulfilled, (state, action) => {
        const { id, ppids, name } = action.payload
        const count = Object.keys(state.planToProfilesMap).length
        // Update all plans profiles
        Object.values(state.planToProfilesMap).forEach(plan => {
          const nextPpids = plan.ppids.filter(ppid => !ppids.includes(ppid))
          plan.ppids = nextPpids
          if (nextPpids.length === 0 && !plan.isDefault) {
            delete state.planToProfilesMap[plan.id]
          }
        })

        Object.values(state.plans).forEach((plan) => {
          const nextPpids = plan.ppids.filter(ppid => !ppids.includes(ppid))
          plan.ppids = nextPpids
          if (nextPpids.length === 0 && !state.planToProfilesMap[plan.id]?.isDefault) {
            delete state.plans[plan.id]
          }
        })

        state.planToProfilesMap[id] = {
          id,
          ppids,
          isDefault: false,
          isPaused: false,
          order: count + 1,
          name
        }
      })
      .addCase(deletePlan.fulfilled, (state, action) => {
        const ppid = action.payload
        delete state.plans[ppid]
        delete state.planToProfilesMap[ppid]
      })
      .addCase(initPlans.fulfilled, (state, action) => {
        const { plans, userTimezone, timezones } = action.payload
        state.planToProfilesMap = plans
        if (userTimezone) {
          state.defaultTimezone = userTimezone
        }
        if (timezones) {
          state.timezones = timezones
        }
      })
      .addCase(updatePlanTimezone.fulfilled, (state, action) => {
        const { planId, timezone } = action.payload
        if (state.plans[planId]) {
          state.plans[planId].timezone = timezone
        }
      })
      .addCase(togglePlanPaused.fulfilled, (state, action) => {
        const { planId } = action.payload
        const plan = state.plans[planId]
        if (plan) {
          plan.isPaused = !plan.isPaused
        }
      })
      .addCase(addProfilesToPlan.fulfilled, (state, action) => {
        const { planId, ppids } = action.payload
        Object.values(state.plans).forEach(plan => {
          if (plan.id === planId) {
            plan.ppids = uniqueStrings(plan.ppids.concat(ppids))
          } else {
            const nextPpids = plan.ppids.filter(ppid => !ppids.includes(ppid))
            plan.ppids = nextPpids
            if (nextPpids.length === 0 && !state.planToProfilesMap[plan.id].isDefault) {
              delete state.plans[plan.id]
            }
          }
        })

        Object.keys(state.planToProfilesMap).forEach(id => {
          if (id === planId) {
            state.planToProfilesMap[id].ppids = uniqueStrings(state.planToProfilesMap[id].ppids.concat(ppids))
          } else {
            const nextPpids = state.planToProfilesMap[id].ppids.filter(ppid => !ppids.includes(ppid))
            state.planToProfilesMap[id].ppids = nextPpids
            if (nextPpids.length === 0 && !state.planToProfilesMap[id].isDefault) {
              delete state.planToProfilesMap[id]
            }
          }
        })
      })
      .addCase(addSchedule.fulfilled, (state, action) => {
        const { schedule, ppid } = action.payload
        if (state.plans[ppid]) {
          state.plans[ppid].schedules[schedule.id] = schedule
        }
      })
      .addCase(deleteSchedule.fulfilled, (state, action) => {
        const { scheduleId, ppid } = action.payload
        if (state.plans[ppid]) {
          delete state.plans[ppid].schedules[scheduleId]
        }
      })
      .addCase(addTime.fulfilled, (state, action) => {
        const { planId, scheduleId, hour, minute, timeOfDay, ids, bucketId } = action.payload
        const timeKey = `${hour}:${minute} ${timeOfDay.toUpperCase()}`
        const plan = state.plans[planId]
        const schedule = plan && plan.schedules[scheduleId]
          ? plan.schedules[scheduleId]
          : undefined
        let updatedSchedule

        if (schedule) {
          const newTime = `${hour}:${minute} ${timeOfDay.toUpperCase()}`
          const slot = schedule.hours[timeKey]

          updatedSchedule = produce(schedule, s => {
            delete s.hours[timeKey]

            s.hours[newTime] = {
              ids,
              postBucketId: bucketId,
              time: newTime,
              buckets: slot ? slot.buckets : Object.values(LEGACY_BUCKET_NAMES_MAP) as ContentType[]
            }
          })

          state.plans[planId].schedules[scheduleId] = updatedSchedule
        }
      })
      .addCase(updateTime.fulfilled, (state, action) => {
        const { planId, scheduleId, hour, minute, timeOfDay, ids, timeKey, bucketId, range } = action.payload
        const plan = state.plans[planId]
        const schedule = plan && plan.schedules[scheduleId]
          ? plan.schedules[scheduleId]
          : undefined
        let updatedSchedule

        if (schedule) {
          const newTime = `${hour}:${minute} ${timeOfDay.toUpperCase()}`
          const slot = schedule.hours[timeKey]

          updatedSchedule = produce(schedule, s => {
            delete s.hours[timeKey]
            s.hours[newTime] = {
              ids,
              postBucketId: bucketId,
              time: newTime,
              baseTime: range ? newTime : undefined,
              buckets: slot ? slot.buckets : Object.values(LEGACY_BUCKET_NAMES_MAP) as ContentType[]
            }
          })
          state.plans[planId].schedules[scheduleId] = updatedSchedule
        }
      })
      .addCase(deleteTime.fulfilled, (state, action) => {
        const updatedPlan: PostingPlan | undefined = state.plans[action.payload.planId]
        const updatedSchedule = Object.assign({}, updatedPlan?.schedules[action.payload.scheduleId])
        const removedTime = updatedSchedule?.hours[action.payload.timeslot.time]
        if (removedTime) {
          delete updatedSchedule.hours[action.payload.timeslot.time]
        }
      })
      .addCase(updatePlan.fulfilled, (state, action) => {
        const { planId, name, addedPpids } = action.payload
        state.plans[planId].name = name
        state.planToProfilesMap[planId].name = name
        if (addedPpids) {
          state.planToProfilesMap[planId].ppids = uniqueStrings(state.planToProfilesMap[planId].ppids.concat(addedPpids))
          state.plans[planId].ppids = uniqueStrings(state.plans[planId].ppids.concat(addedPpids))
          Object.values(state.planToProfilesMap).forEach(plan => {
            if (plan.id !== planId) {
              const nextPpids = plan.ppids.filter(ppid => !addedPpids.includes(ppid))
              plan.ppids = nextPpids
              if (nextPpids.length === 0 && !plan.isDefault) {
                delete state.planToProfilesMap[plan.id]
              }
            }
          })
          Object.values(state.plans).forEach((plan) => {
            if (plan.id !== planId) {
              const nextPpids = plan.ppids.filter(ppid => !addedPpids.includes(ppid))
              plan.ppids = nextPpids
              if (nextPpids.length === 0 && !state.planToProfilesMap[plan.id]?.isDefault) {
                delete state.plans[plan.id]
              }
            }
          })
        }
      })
  }
})

const { actions, reducer } = planSlice
export const { setLastViewedPlan, togglePlanViewExpanded, updateAllPlansTimezones } = actions
export default reducer
