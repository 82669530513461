import { Observable } from 'rxjs/Observable'
import 'rxjs/add/operator/map'

import { AjaxResponse } from 'rxjs/observable/dom/AjaxObservable'
import { ApiException, DeserializationException, NotAuthorizedException, SerializationException, Tags } from './exceptions'

import toCamelCase from 'utils/toCamelCase'
import toSnakeCase from 'utils/toSnakeCase'

const HTTP_MINIMUM_FAILURE_STATUS_CODE = 400
const HTTP_UNAUTHORIZED = 401

/**
 * Creates a standard NotAuthorizedException
 *
 * @param {string} message
 * @param {Tags} tags
 * @returns
 */
function createNotAuthorized(message: string, tags: Tags) {
  return new NotAuthorizedException(message, tags)
}

/**
 * Deserializes the response into T
 *
 * @export
 * @template T
 * @param {any} [onNotAuthorized=NotAuthorizedException]
 * @param {AjaxResponse} response
 * @returns {T}
 */
export function deserialize<T extends { [key: string]: any }>(response: AjaxResponse, onNotAuthorized = createNotAuthorized): T {
  if (response.status < HTTP_MINIMUM_FAILURE_STATUS_CODE) {
    const unwrapped: T = response.response || {}

    if (!unwrapped) {
      throw new DeserializationException('Response is empty', { xhr: response.xhr, request: response.request })
    }

    const camelizedData = toCamelCase(unwrapped) as T
    const headerString = response.xhr.getAllResponseHeaders()
    if (headerString.toLocaleLowerCase().indexOf('etag') !== -1) {
      Object.defineProperty(camelizedData, '__etag', {
        value: response.xhr.getResponseHeader('etag'),
        writable: false,
        configurable: false,
        enumerable: false
      })
    }

    return camelizedData
  }

  if (response.status === HTTP_UNAUTHORIZED) {
    const error = onNotAuthorized(response.response, { xhr: response.xhr, request: response.request })
    throw error
  }

  throw new ApiException(response.response, { xhr: response.xhr, response: response.request })
}

/**
 * Serializes data {T} so it may be send to the server
 *
 * @export
 * @template T the type T
 * @param {T | string} data the data to serialize
 * @returns {T} Serialized T
 */
export function serialize<T extends { [key: string]: any }>(data: T | string): T | string {

  if (!data) {
    throw new SerializationException('Data is empty')
  }

  if (typeof data === 'string') {
    return data as string
  }

  if (data instanceof FormData) {
    return data
  }

  return toSnakeCase(Object.assign({}, data as T)) as T
}

/**
 * Serializes each item in the stream item$
 *
 * @export
 * @template T the type of the item
 * @param {Observable<T>} item$ the stream
 * @returns {Observable<T>} the mapped stream
 */
export function serialize$<T>(item$: Observable<T>): Observable<T | string> {
  return item$.map((data: any) => serialize(data))
}

/**
 * Deserializes each item in the stream item$
 *
 * @export
 * @template T the type after deserialization
 * @param {Observable<AjaxResponse>} item$ the stream
 * @returns {Observable<T>} the mapped stream
 */
export function deserialize$<T>(item$: Observable<AjaxResponse>): Observable<T> {
  return item$.map((item: any) => deserialize(item)) as Observable<T>
}
