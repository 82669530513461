import { AnyContent, IndexedObject, LegacyGif, LegacyPhoto, LegacyVideo, NEW_RANGE_FILTER, Paginated, Photo, RangeFilter } from 'interfaces'
import { StockContentProvider, STOCK_CONTENT_PROVIDERS_ALL } from 'interfaces/Content/StockContentProvider'
import { StoreThunkDispatch } from 'store/state'
import { gifAdapter, photoAdapter, videoAdapter } from '../adapters'
import { V1 as PhotosFetch } from '../photos/net'
import { V1 as VideosFetch } from '../videos/net'
import { V1 as GifsFetch } from '../gifs/net'
import { getCacheKey, PAGE_SIZE_DEFAULT, SortBy } from '../util'
import { STOCK_ALL_FILTER } from 'interfaces/Content/StockContentTypes'
import { cacheContentTemp, getCachedContent } from '../cache'
import { Observable } from 'rxjs/Observable'
import { createMixedArrayFromObject } from 'utils/sort/mixedArray'

export function fetchStockContent(
  feedId: string,
  page: number = 0,
  range: RangeFilter = NEW_RANGE_FILTER,
  source: StockContentProvider = STOCK_CONTENT_PROVIDERS_ALL
) {
  return (dispatch: StoreThunkDispatch) => {
    const sortBy = SortBy.Engagement
    const cacheKey = getCacheKey(false, feedId, STOCK_ALL_FILTER as any, range, 0, sortBy, source)
    const cachedContent = dispatch(getCachedContent(cacheKey))

    if (cachedContent) {
      const startIndex = page * PAGE_SIZE_DEFAULT
      const endIndex = startIndex + PAGE_SIZE_DEFAULT
      const contentForPage: Paginated<any[]> = {
        items: cachedContent.items.slice(startIndex, endIndex) as any[],
        hasNext: cachedContent.items.length > endIndex - 1
      }

      return Observable.of(contentForPage)
    }

    const photos$ = dispatch(PhotosFetch.getPhotos(feedId, page, range, sortBy, source))
    const videos$ = dispatch(VideosFetch.getVideos(feedId, page, range, sortBy, source))
    const gifs$ = dispatch(GifsFetch.getGifs(feedId, page, range, sortBy, source))

    return photos$.zip(videos$, gifs$)
      .map((response: any[]) => {
        const photosFormatted = response[0].content.map((p: LegacyPhoto) => photoAdapter(p, true))
        const videosFormatted = response[1].content.map((v: LegacyVideo) => videoAdapter(v, true))
        const gifsFormatted = response[2].content.map((g: LegacyGif) => gifAdapter(g, true))
        const photosByProvider = photosFormatted.reduce((map: IndexedObject<any[]>, item: any) => {
          if (!map[item.provider]) {
            map[item.provider] = []
          }
          map[item.provider].push(item)
          return map
        }, {})
        const mixedPhotos = createMixedArrayFromObject<Photo>(photosByProvider)
        const photosCount = mixedPhotos.length
        const videosCount = videosFormatted.length
        const gifsCount = gifsFormatted.length
        const maxLength = Math.max(photosCount, videosCount, gifsCount)
        const allContent: AnyContent[] = []
        for (let i = 0; i < maxLength; i++) {
          if (i < photosCount) {
            allContent.push(mixedPhotos[i])
          }
          if (i < videosCount) {
            allContent.push(videosFormatted[i])
          }
          if (i < gifsCount) {
            allContent.push(gifsFormatted[i])
          }
        }

        dispatch(cacheContentTemp(cacheKey, { items: allContent, hasNext: false }))
        return {
          items: allContent.slice(0, PAGE_SIZE_DEFAULT),
          hasNext: true
        }
      })
  }
}
