export interface SignupViewConfig {
  title: string
  titleNoCC: string
  titleStyles: any
  emailPlaceholder: string
  firstNamePlaceholder: string
  lastNamePlaceholder: string
  mainButtonLabel: string
  mainButtonStyles: any
  facebookButtonStyles: any
}

export interface PaymentViewConfig {
  title: string
  titleCC: string
  titleStyles: any
  cardNumberLabel: string
  cardCvvLabel: string
  cardExpDateLabel: string
  imageUrl: string
  trialImageUrl: string
  mainButtonLabel: string
  mainButtonStyles: any
  sidebarTitle: string
  sidebarMessage: string
  priceLabel: string
  inputLabelStyles: any
  priceLabelStyles: any
  priceAfterTrialLabel: string
  priceAfterTrialLabelStyles: any
  faqs: Record<string, { id: string, q: string, a: string, isTrial: boolean }>
  planSelectHint: string
}

export interface SignupState {
  signupViewConfig: SignupViewConfig
  paymentViewConfig: PaymentViewConfig
  progress: Record<string, string>
  isLoadingConfig: boolean
  account?: any
  currency: string
}

export const signupViewDefaultConfig: SignupViewConfig = {
  title: 'Add your name and email',
  titleNoCC: 'Add your name and email',
  emailPlaceholder: 'Email',
  firstNamePlaceholder: 'First Name',
  lastNamePlaceholder: 'Last Name',
  mainButtonLabel: 'Next Step',
  mainButtonStyles: {},
  facebookButtonStyles: {},
  titleStyles: {}
}

export const paymentViewDefaultConfig: PaymentViewConfig = {
  title: 'Payment details',
  titleCC: 'Payment details',
  cardNumberLabel: 'Card Number',
  cardCvvLabel: 'Security Code',
  cardExpDateLabel: 'Expiration Date',
  imageUrl: 'https://www.postplanner.com/hubfs/free-trial-explanation-new-green2-1.png',
  trialImageUrl: 'https://www.postplanner.com/hubfs/free-trial-explanation-new-green2-1.png',
  mainButtonLabel: 'Start your free trial',
  mainButtonStyles: {},
  inputLabelStyles: {},
  sidebarTitle: 'One step away',
  sidebarMessage: 'Start scheduling posts today. More Likes and followers tomorrow',
  priceLabel: 'Today\'s total: $',
  priceLabelStyles: {},
  priceAfterTrialLabel: 'when 7 day trial ends',
  priceAfterTrialLabelStyles: {},
  titleStyles: {},
  faqs: {},
  planSelectHint: '* You can switch to any plan during your free trial, without being charged'
}

export const initialState: SignupState = {
  signupViewConfig: signupViewDefaultConfig,
  paymentViewConfig: paymentViewDefaultConfig,
  progress: {
    'Step-1': 'Start signup',
    'Step-2': 'Create account',
    'Step-3': 'Payment details',
    'Step-4': 'Go to app'
  },
  currency: 'usd',
  isLoadingConfig: true
}
