import { createTheme, adaptV4Theme } from '@mui/material/styles'
import { checkboxClasses } from '@mui/material/Checkbox'
const variables = require('../styles/variables')

export const theme = createTheme({
  palette: {
    primary: {
      main: variables['--pp-green'],
      light: variables['--pp-light-green']
    },
    secondary: {
      main: variables['--pp-accent']
    },
    error: {
      main: variables['--pp-orange']
    }
  },
  typography: {
    fontFamily: variables['--graphik'],
    htmlFontSize: 10
  },
  components: {
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'top'
      },
      styleOverrides: {
        tooltip: {
          boxShadow: '0px 4px 12px 4px rgb(0 0 0 / 20%)',
          backgroundColor: '#fff',
          fontSize: '1.3rem',
          padding: '4px 10px',
          color: variables['--pp-primary'],
          fontWeight: 'bold'
        },
        arrow: {
          color: '#fff'
        }
      }
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true
      }
    },
    MuiInputBase: {
      styleOverrides: {
        adornedStart: {
          border: `1px solid ${variables['--lighter-gray']}`,
          borderRadius: '4px'
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true
      },
      styleOverrides: {
        containedPrimary: {
          color: variables['--pp-white'],
          backgroundColor: variables['--pp-green'],
          borderRadius: '4px',
          '&:hover': {
            color: variables['--pp-white'],
            backgroundColor: variables['--dark-green']
          }
        },
        text: {
          backgroundColor: variables['--lightest-gray'],
          padding: '4px 12px',
          color: variables['--pp-primary']
        },
        root: {
          textTransform: 'initial',
          borderRadius: '4px',
          padding: '4px 12px',
          lineHeight: '1.7',
          fontSize: '1.3rem',
          fontWeight: 'bold',
          '&:hover': {
            color: variables['--pp-green'],
            backgroundColor: variables['--pp-light-green'],
            border: 'none'
          }
        }
      }
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          borderRadius: '50%',
          '&:hover': {
            backgroundColor: variables['--pp-light-green'],
            color: variables['--pp-green']
          },
          '&.solid': {
            backgroundColor: variables['--lightest-gray']
          },
          '&.solid:hover': {
            backgroundColor: variables['--pp-light-green']
          },
          '&[data-ui-dark]': {
            backgroundColor: '#aaa',
            color: variables['--pp-white']
          },
          '&[data-ui-dark]:hover': {
            backgroundColor: variables['--pp-light-green'],
            color: variables['--pp-green']
          }
        },
        colorPrimary: {
          color: variables['--pp-white'],
          backgroundColor: variables['--pp-green'],
          '&:hover': {
            color: variables['--pp-white'],
            backgroundColor: variables['--dark-green']
          }
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        button: {
          fontWeight: 'bold',
          '&:focus': {
            backgroundColor: ''
          },
          '&:hover': {
            backgroundColor: '',
            color: variables['--pp-green']
          }
        },
        root: {
          fontWeight: 'bold'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          '&:hover': {
            color: variables['--pp-green']
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          overflow: 'hidden'
        },
        rounded: {
          borderRadius: '4px'
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '6px',
          [`& ${checkboxClasses.checked}`]: {
            color: variables['--pp-light-green']
          },
          '&:hover': {
            backgroundColor: 'transparent'
          }
        },
        colorPrimary: {
          color: 'rgba(0, 0, 0, 0.54)',
          backgroundColor: 'transparent',
          '&:hover': {
            color: 'rgba(0, 0, 0, 0.54)',
            backgroundColor: variables['--pp-light-green']
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        colorPrimary: {
          color: 'rgba(0, 0, 0, 0.54)',
          backgroundColor: 'transparent',
          '&:hover': {
            color: 'rgba(0, 0, 0, 0.54)',
            backgroundColor: variables['--pp-light-green']
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthLg: {
          maxWidth: 960
        },
        paperWidthSm: {
          maxWidth: 960
        },
        paperWidthMd: {
          maxWidth: 960
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '& button:first-child': {
            color: variables['--pp-primary']
          },
          '& button:last-child': {
            color: variables['--pp-white'],
            backgroundColor: variables['--dark-green']
          }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: variables['--pp-black-12']
        },
        barColorPrimary: {
          backgroundColor: variables['--pp-black-12']
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: variables['--z-index-tooltip']
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          backgroundColor: variables['--lightest-gray'],
          fontSize: '1.3rem',
          fontWeight: 'bold',
          color: variables['--pp-primary'],
          '&.chip-active': {
            color: `${variables['--pp-green']} !important`,
            backgroundColor: `${variables['--pp-light-green']} !important`
          },
          '&:hover': {
            backgroundColor: variables['--pp-light-green'],
            color: variables['--pp-green']
          }
        },
        label: {
          lineHeight: '1.4'
        },
        clickable: {
          '&:hover': {
            backgroundColor: variables['--pp-light-green'],
            color: variables['--pp-green']
          },
          '&:active': {
            backgroundColor: variables['--pp-light-green'],
            color: variables['--pp-green'],
            boxShadow: 'none'
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          zIndex: 300
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      },
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: variables['--lightest-gray'] + ' !important'
          }
        }
      }
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          zIndex: 1600
        }
      }
    }
  }
})

export const CHIP_ACTIVE_CLASS = 'chip-active'
