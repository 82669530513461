import * as React from 'react'
import { connect } from 'react-redux'
import { Facebook, FacebookVideoPlayer, FacebookVideoPlayerEventHandler, Video } from 'interfaces'

import { PlayIcon } from 'components/Icons'
import CircularProgressBar from '@mui/material/CircularProgress'
import { Subject } from 'rxjs/Subject'
import { StoreDispatch } from 'store/state'
import { openContentPreviewWindow } from 'services/contentPreview'
import 'rxjs/add/operator/takeUntil'
import 'rxjs/add/operator/debounceTime'
import 'rxjs/add/operator/filter'

declare const FB: Facebook
export const FB_PARSE_VIDEO_CLASSNAME = 'fb-video'
export const FB_NO_PARSE_CLASSNAME = 'fb-xfbml-parse-ignore'
const THROTTLE_HOVER_PLAY_MS = 500

import styles from './FBVideoPlayer.pcss'

export interface FBVideoPlayerOwnProps {
  videoUrl: string
  videoId: string
  parseOnMount?: boolean
  autoplay?: boolean
  togglePlayOnHover?: boolean
  controls?: boolean
  contentItem?: Video
  thumbnailUrl?: string
  width?: number
  windowMode?: boolean
  withBackground?: boolean
  className?: string
  videoContainerClassName?: string
  imageClassName?: string

  onVideoParsed?: () => void
}

interface ConnectedFBVideoPlayerProps {
  openInWindow: (content: Video) => void
}

export type FBVideoPlayerProps = FBVideoPlayerOwnProps & ConnectedFBVideoPlayerProps

interface FBVideoPlayerState {
  loading: boolean
  active: boolean
  playing: boolean
}

export class FBVideoPlayer extends React.Component<FBVideoPlayerProps, FBVideoPlayerState> {
  // eslint-disable-next-line no-magic-numbers
  private readonly loaderSize = 48
  private elementRef: React.RefObject<HTMLDivElement>
  private player: FacebookVideoPlayer | null
  private playing$: Subject<boolean>
  private unmount$: Subject<boolean>
  private startedPlaying: FacebookVideoPlayerEventHandler
  private finishedPlaying: FacebookVideoPlayerEventHandler
  private pausedPlaying: FacebookVideoPlayerEventHandler
  private isMouseOver$: Subject<boolean>

  constructor(props: FBVideoPlayerProps) {
    super(props)

    this.elementRef = React.createRef<HTMLDivElement>()
    this.player = null

    this.state = {
      active: Boolean(props.windowMode),
      loading: false,
      playing: false
    }
  }

  componentDidMount() {
    this.unmount$ = new Subject()
    this.isMouseOver$ = new Subject()
    this.playing$ = new Subject()

    this.playing$
      .takeUntil(this.unmount$)
      .subscribe((willPlay: boolean) => {
        if (!this.player) {
          return
        }

        if (this.state.active && willPlay) {
          this.player.mute()
          this.player.play()
          if (this.props.windowMode) {
            this.player.unmute()
          }
          return
        }

        if (!this.state.active || !willPlay) {
          this.player.pause()
        }
      })

    this.isMouseOver$
      .takeUntil(this.unmount$)
      .debounceTime(THROTTLE_HOVER_PLAY_MS)
      .filter(() => Boolean(this.props.togglePlayOnHover))
      .subscribe((isMouseOver: boolean) => {
        if (isMouseOver) {
          this.play()
        } else {
          this.pause()
        }
      })

    FB.Event.subscribe('xfbml.ready', this.onVideoParsed)

    if (this.props.parseOnMount) {
      this.parseVideoElement()
    }
  }

  componentWillUnmount() {
    FB.Event.unsubscribe('xfbml.ready', this.onVideoParsed)
    this.playing$.unsubscribe()
    this.unmount$.next(true)

    if (this.startedPlaying) {
      this.startedPlaying.release()
    }

    if (this.finishedPlaying) {
      this.finishedPlaying.release()
    }

    if (this.pausedPlaying) {
      this.pausedPlaying.release()
    }
  }

  onVideoParsed = (msg: any) => {
    if (msg.type === 'video' && msg.id === this.props.videoId) {
      this.player = msg.instance as FacebookVideoPlayer

      this.setState(prevState => ({ ...prevState, loading: false }))
      this.startedPlaying = this.player.subscribe('startedPlaying', () => {
        this.setState(prevState => ({ ...prevState, loading: false, playing: true }))
      })

      this.finishedPlaying = this.player.subscribe('finishedPlaying', () => {
        this.setState(prevState => ({ ...prevState, playing: false }))
      })

      this.pausedPlaying = this.player.subscribe('paused', () => {
        this.setState(prevState => ({ ...prevState, playing: false }))
      })

      if (this.props.autoplay) {
        this.playing$.next(true)
      }

      if (this.props.onVideoParsed) {
        this.props.onVideoParsed()
      }
    }
  }

  parseVideoElement = () => {
    if (!this.player && this.elementRef.current) {
      this.setState(prevState => ({ ...prevState, loading: true }))
      FB.XFBML.parse(this.elementRef.current)
    }
  }

  onMouseEnter = () => {
    this.isMouseOver$.next(true)
  }

  onMouseLeave = () => {
    this.isMouseOver$.next(false)
  }

  play = () => {
    this.setState(prevState => ({ ...prevState, active: true }), () => {
      if (this.player) {
        this.playing$.next(true)
      } else {
        this.parseVideoElement()
      }
    })
  }

  pause = () => {
    this.setState(prevState => ({ ...prevState, active: false, loading: false }), () => {
      this.playing$.next(false)
    })
  }

  togglePlay = () => {
    if (this.state.playing) {
      this.pause()
    } else {
      this.play()
    }
  }

  handleOverlayClick = () => {
    if (this.props.contentItem) {
      this.props.openInWindow(this.props.contentItem)
    }
  }

  render() {
    const width = this.props.width || 'auto'
    const isParsed = Boolean(this.player)
    const windowMode = Boolean(this.props.windowMode)
    const backgroundStyles = { backgroundImage: `url('${this.props.thumbnailUrl}')` }
    const classFixed = !isParsed ? styles.fixed : ''

    return (
      <div
        data-test="fb-video-player"
        ref={this.elementRef}
        className={`${styles.container} ${this.props.className || ''}`}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div
          id={this.props.videoId}
          className={`${FB_PARSE_VIDEO_CLASSNAME} ${styles['fb-box']} ${classFixed} ${this.props.videoContainerClassName || ''}`}
          data-href={this.props.videoUrl}
          data-width={width}
          data-allowfullscreen={windowMode}
          data-autoplay={Boolean(this.props.autoplay)}
          data-show-text={windowMode}
          data-show-captions={windowMode}
          data-lazy
        >
        </div>
        {!this.props.windowMode && this.props.contentItem && (
          <div className={styles.overlay} onClick={this.handleOverlayClick}></div>
        )}
        {this.props.withBackground && <div className={styles.background} style={backgroundStyles}></div>}
        {(!this.player || !this.state.playing) && !windowMode && !isParsed && (
          <div className={styles.thumbnail}>
            {this.props.thumbnailUrl && (
              <img src={this.props.thumbnailUrl} className={`${this.props.imageClassName || ''} ${styles['thumb-img']}`} />
            )}
            {this.state.loading && (
              <div className={styles.loader}>
                <CircularProgressBar color="primary" size={this.loaderSize} className={styles['icon-loader']} />
              </div>
            )}
          </div>
        )}
        {!windowMode && !this.state.loading && !isParsed && (!this.player || !this.state.playing) && (
          <PlayIcon center className={styles['icon-play']} onClick={this.togglePlay} />
        )}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch: StoreDispatch) {
  return {
    openInWindow: (content: Video) => dispatch(openContentPreviewWindow(content))
  }
}

export default connect<{}, ConnectedFBVideoPlayerProps, FBVideoPlayerOwnProps>(undefined, mapDispatchToProps)(FBVideoPlayer)
