import * as React from 'react'
import styles from './LinkedinPostPreview.pcss'
import { PostPreviewDefaultHeader } from './PostPreviewDefaultHeader'
import { PlayIcon } from 'components/Icons'
import { getDomainFromUrl } from 'shared/utils'
import { ImagesPreviewGrid } from './ImagesPreviewGrid'
import { PostPreviewVideoPlayer } from './PostPreviewVideoPlayer'
import PostPreviewText from './PostPreviewText'
import Icon from '@mdi/react'
import { mdiFilePdfBox, mdiFilePowerpointOutline, mdiFileWordOutline } from '@mdi/js'

const MAX_LINES = 3
const TRUNCATE_TEXT = 'see more'
const COLLAPSE_TEXT_LABEL = 'see less'

interface LinkedinPostPreviewProps {
  profile?: { name: string, image: string }
  featuredImageUrl?: string
  imageUrls?: string[]
  empty?: boolean
  videoUrl?: string
  videoThumbnailUrl?: string
  isVideoPost?: boolean
  statusText?: string
  documentName?: string
  linkData?: {
    url: string;
    title: string;
    description?: string | undefined;
    isVideo?: boolean | undefined;
  }
}

export function LinkedinPostPreview(props: LinkedinPostPreviewProps) {
  const { featuredImageUrl, documentName, statusText, imageUrls } = props
  const additionalImages = imageUrls ? imageUrls.filter(url => url !== featuredImageUrl) : []

  const isEmpty = props.empty
    || (!props.videoUrl && !imageUrls?.length && !statusText && !props.linkData && !featuredImageUrl && !documentName)
  const emptyClass = isEmpty ? styles.empty : ''
  const docClass = documentName ? styles.doc : ''
  const imgClass = !featuredImageUrl || documentName ? styles['no-img'] : ''

  const docIcon = React.useMemo(() => {
    const fileExtension = documentName?.substring(documentName.toLowerCase().lastIndexOf('.'))
    switch (fileExtension) {
      case '.pdf':
        return mdiFilePdfBox
      case '.doc':
      case '.docx':
        return mdiFileWordOutline
      case '.ppt':
      case '.pptx':
        return mdiFilePowerpointOutline
      default:
        return null
    }
  }, [documentName])

  return (
    <div className={`${styles.post} ${imgClass} ${emptyClass} ${docClass}`}>
      <PostPreviewDefaultHeader withSubtitle profile={props.profile} />
      {statusText && (
        <PostPreviewText
          text={statusText || ''}
          maxLinesDefault={MAX_LINES}
          expandLabel={TRUNCATE_TEXT}
          collapseLabel={COLLAPSE_TEXT_LABEL}
          className={styles['li-text']}
        />
      )}
      {!props.videoUrl && additionalImages.length === 0 && (
        <div className={styles['img-box']}>
          <img src={featuredImageUrl} className={styles.image} />
          {props.isVideoPost && <PlayIcon className={`${styles['icon-centered']} ${styles.icon}`} />}
        </div>
      )}
      {!props.videoUrl && featuredImageUrl && additionalImages.length > 0 && !documentName && (
        <ImagesPreviewGrid featuredImageUrl={featuredImageUrl} imageUrls={additionalImages} />
      )}
      {props.linkData && (
        <div className={styles['article-data']}>
          <h5>{props.linkData.title}</h5>
          <a href={props.linkData.url} rel="noopener" target="_blank" className="text-ellipsis">
            {getDomainFromUrl(props.linkData.url).replace('http://', '').replace('https://', '')}
          </a>
        </div>
      )}
      {props.videoUrl && !documentName && (
        <PostPreviewVideoPlayer thumbnailUrl={props.videoThumbnailUrl} videoUrl={props.videoUrl} />
      )}
      {documentName && (
        <div className={styles['doc-info']}>
          {docIcon && <Icon className={styles['icon-doc']} path={docIcon} size="28px" />}
          <span className={`${styles.filename} text-ellipsis`}>{documentName}</span>
        </div>
      )}
      <footer className={styles.footer}>
      </footer>
    </div>
  )
}
