import { createElement } from 'react'
import { CuratedStream, Stream } from 'interfaces'
import LoadingCard from 'components/Card/LoadingCard'
import { defaultStreamColorString } from 'utils/colors'

export const createStream = (curatedStream: CuratedStream): Stream => ({
  id: curatedStream.id,
  feedIds: new Array(curatedStream.feeds),
  lastUpdated: curatedStream.updatedAt,
  order: 0,
  public: true,
  isStream: true,
  slug: curatedStream.prettyName,
  title: curatedStream.name,
  coverImageUrl: curatedStream.cover.url,
  feeds: [],
  filters: [],
  color: defaultStreamColorString,
  createdAt: new Date().getTime()
})

const DEFAULT_COUNT = 10
export function renderLoadingCards(count: number = DEFAULT_COUNT, circle?: boolean, small?: boolean, active = true) {
  const cards: any[] = []
  for (let i = 0; i < count; i++) {
    cards.push(createElement(LoadingCard, { key: `loading-${i}`, square: !circle, circle, active, small }))
  }
  return cards
}
