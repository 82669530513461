import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Backdrop from '@mui/material/Backdrop'
import { removePostProgress } from 'services/compose'
import { composerPostProgressSelector } from 'services/compose/selectors'
import { PostProgressSnackbar } from './PostProgressSnackbar'

import styles from './PostProgressOverlay.pcss'

export function PostProgressOverlay() {
  const posts = useSelector(composerPostProgressSelector)
  const dispatch = useDispatch()

  const removeSnack = (id: string) => {
    dispatch(removePostProgress(id))
  }

  return (
    <Backdrop open={Object.keys(posts).length > 0} invisible classes={{ root: styles.backdrop }}>
      <div className={styles['backdrop-panel']}>
        {Object.keys(posts).map(id => {
          const post = posts[id]
          return (
            <PostProgressSnackbar
              key={id}
              id={id}
              post={post.post}
              error={post.error}
              complete={post.complete}
              onClose={removeSnack}
            />
          )
        })}
      </div>
    </Backdrop>
  )
}

export default PostProgressOverlay
