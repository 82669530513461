import { PaymentViewConfig, SignupViewConfig } from '.'

export const ACTION_SET_CONFIG = 'ACTION_SET_CONFIG'
export function updateViewConfig(data: { createAccountConfig: SignupViewConfig, paymentDetailsConfig: PaymentViewConfig }) {
  return {
    type: ACTION_SET_CONFIG,
    payload: data
  }
}

export const ACTION_SET_DEFAULT_CONFIG = 'ACTION_SET_DEFAULT_CONFIG'
export function setDefaultViewConfig() {
  return {
    type: ACTION_SET_DEFAULT_CONFIG
  }
}

export const ACTION_SET_ACCOUNT = 'ACTION_SET_ACCOUNT'
export function setAccount(account: any) {
  return {
    type: ACTION_SET_ACCOUNT,
    payload: account
  }
}

export const ACTION_SET_CURRENCY = 'ACTION_SET_CURRENCY'
export function setCurrency(c: string) {
  return {
    type: ACTION_SET_CURRENCY,
    payload: c
  }
}
