import * as React from 'react'
import FileCard from 'components/FileCard'
import { MyFile } from 'interfaces'
import styles from './LibraryPhotosGrid.pcss'
import SimpleLoader from 'components/SimpleLoader'
import { FormattedMessage } from 'react-intl'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { mdiImagePlusOutline } from '@mdi/js'
import { Icon } from '@mdi/react'

const PAGE_SIZE = 20

export function LibraryPhotosGrid(props: {
  files: MyFile[],
  page: number,
  loading: boolean,
  error: boolean,
  onSelect: (url: string) => void
}) {
  const { files, page, loading, error, onSelect } = props
  const visibleItems = React.useMemo(() => {
    return files.slice(0, (page + 1) * PAGE_SIZE)
  }, [files, page])

  return (
    <section className={styles.grid}>
      {loading && (
        <SimpleLoader small />
      )}
      {!loading && error && (
        <p className={styles.error}>
          <FormattedMessage id="errors.generic-support" />
        </p>
      )}
      {visibleItems.map(file => (
        <FileCardWithAction key={file.key} file={file} onClick={onSelect} />
      ))}
    </section>
  )
}

function FileCardWithAction(props: { file: MyFile, onClick: (imageUrl: string) => void }) {
  const onClick = () => {
    props.onClick(props.file.url)
  }

  const type = props.file.type === 'photos' ? 'image' : 'video'

  return (
    <FileCard
      small
      file={props.file}
      hideDetails
      className={styles.file}
      actions={
        <Tooltip title={<FormattedMessage id={`actions.add-${type}`} defaultMessage="Add content" />} placement="left">
          <IconButton
            size="small"
            color="primary"
            className={styles['btn-select']}
            onClick={onClick}
          >
            <Icon path={mdiImagePlusOutline} size="20px" />
          </IconButton>
        </Tooltip>
      }
    />
  )
}

export default LibraryPhotosGrid
