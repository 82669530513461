import { ADMIN_PATHNAME } from 'admin'
import AdminApp from 'admin/AdminApp'
import AuthenticatedApp from 'components/App/components/Authenticated'
import Logout from 'components/App/Logout'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import HubspotProvider from 'components/HubspotProvider'
import UnAuthenticatedRoute from 'components/UnAuthenticatedRoute'
import React from 'react'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import Authorize from './authorize'
import Search from 'routes/search'
import Settings from 'routes/settings'
import Listings from 'routes/listings'
import Training from 'routes/trial'
import Calendar from 'routes/publishing/routes/posts/CalendarRoute'
import HistoryRoute from 'routes/publishing/routes/posts/HistoryRoute'
import Find from 'routes/find'
import Publishing from 'routes/publishing'
import Compose from 'routes/compose'
import DefaultRedirect from './DefaultRedirect'
import Plan from './plan'
import PendingPostsManager from './publishing/routes/posts/PendingPostsManager'
import PostsRoute from './publishing/routes/posts/PostsRoute'
import BucketsRoute from './publishing/routes/buckets'
import DraftsManager from './publishing/routes/posts/DraftsManager'
import PostedPostsManager from './publishing/routes/posts/PostedPostsManager'
import FailedPostsManager from './publishing/routes/posts/FailedPostsManager'
import { RouteErrorView } from 'components/App/components/ErrorFallback/RouteErrorView'
import { SearchStreamRoute } from './search/routes/SearchStreamRoute'
import SearchFeedRoute from './search/routes/SearchFeedRoute'
import SearchStockRoute from './search/routes/SearchStockRoute'
import SearchQuotesRoute from './search/routes/SearchQuotesRoute'
import SearchStatusIdeasRoute from './search/routes/SearchStatusIdeasRoute'
import SearchGoogleRoute from './search/routes/SearchGoogleRoute'
import FilteredSearchResultsRoute from './search/routes/FilteredSearchResultsRoute'
import MyUploads from './find/routes/my-uploads'
import Statuses from './find/routes/statuses'
import SingleFeedView from './find/routes/feeds'
import SingleStreamView from './find/routes/streams'
import QuotesRoute from './find/routes/quotes'
import ContentStudio from './find/routes/studio'
import { QuotesFeed } from './find/routes/quotes/QuotesFeed'
import MostRecent from './find/routes/home/routes/most-recent'
import RecentlyViewed from './find/routes/home/routes/recent/RecentlyViewed'
import PostPlannerBlogContentView from './find/routes/home/routes/pp-blog'
import Favorites from './find/routes/home/routes/favorites'
import StreamGroupView from './find/routes/home/routes/stream-group/StreamGroupView'
import HomeView from './find/routes/home/components/HomeView'
import { PP_BLOG_NAV_LINK } from './find/routes/home/components/PostPlannerBlogCarousel'
import MyStreamsView from './find/routes/my-streams/routes/streams/MyStreamsView'
import MyUploadsFolderView from './find/routes/my-uploads/routes/MyUploadsFolderView'
import MyUploadsRootView from './find/routes/my-uploads/routes/MyUploadsRootView'
import Landing from './login/routes/landing'
import DeclinedPermissions from './login/routes/declined/DeclinedPermissions'
import Login from './login'
import Signup from './signup'
import { CreateAccountForm } from './signup/CreateAccountForm'
import { PaymentDetails } from './signup/PaymentDetails'
import { SearchRoot } from './search/routes/SearchRoot'
import Onboarding from './onboarding'
import { PostsRoot } from './publishing/routes/posts/PostsRoot'

export const routes = (
  <React.Fragment>
    <Route
      path="/*"
      element={(
        <AuthenticatedRoute>
          <AuthenticatedApp />
          <HubspotProvider />
        </AuthenticatedRoute>
      )}
      errorElement={<RouteErrorView />}
    >
      <Route path="content/*" element={<Find />}>
        <Route path="search/*" element={<Search />}>
          <Route index element={<SearchRoot />} />
          <Route path="sources/streams/:id/*" element={<SearchStreamRoute />} />
          <Route path="sources/:feedType/:id/*" element={<SearchFeedRoute />} />
          <Route path="content/stock/*" element={<SearchStockRoute />} />
          <Route path="content/gifs" element={<SearchStockRoute gifsRoute />} />
          <Route path="content/quotes" element={<SearchQuotesRoute />} />
          <Route path="content/ideas" element={<SearchStatusIdeasRoute />} />
          <Route path="content/:type" element={<SearchGoogleRoute />} />
          <Route path=":category/:type" element={<FilteredSearchResultsRoute />} />
          <Route path=":category" element={<SearchRoot />} />
          <Route path="*" element={<Navigate to="/content/search/sources" replace />} />
        </Route>
        <Route path="my-streams/*">
          <Route path="*" element={<MyStreamsView />} />
        </Route>
        <Route path="library/*" element={<MyUploads />}>
          <Route path=":folderId" element={<MyUploadsFolderView />} />
          <Route index element={<MyUploadsRootView />} />
        </Route>
        <Route path="feeds/:id/:filter/:range" element={<SingleFeedView />} />
        <Route path="feeds/:id" element={<SingleFeedView />} />
        <Route path="statuses/:category" element={<Statuses />} />
        <Route path="statuses/*" element={<Statuses />} />
        <Route path="quotes/:name/*" element={<QuotesFeed />} />
        <Route path="quotes" element={<QuotesRoute />} />
        <Route path="studio" element={<ContentStudio />} />
        <Route path="streams/:stream/*" element={<SingleStreamView />} />
        <Route path="most-recent/*" element={<MostRecent />} />
        <Route path="recently-viewed/*" element={<RecentlyViewed />} />
        <Route path={PP_BLOG_NAV_LINK} element={<PostPlannerBlogContentView />} />
        <Route path="favorites" element={<Favorites />} />
        <Route path=":group/*" element={<StreamGroupView />} />
        <Route path="*" element={<HomeView />} />
      </Route>
      <Route path="calendar/*" element={<Calendar />} />
      <Route path="plans/*" element={<Plan />}>
        <Route path=":profile/*" element={<Plan />} />
      </Route>
      <Route path="posts/*" element={<Publishing />}>
        <Route path="planned/queued/pending" element={<PendingPostsManager />} />
        <Route path="planned/drafts" element={<DraftsManager />} />
        <Route path="planned/:type" element={<PostsRoot />} />
        {/* <Route path="planned/:type" element={<PostsRoute />} /> */}
        {/* <Route path="planned" element={<PostsRoute />} /> */}
        <Route path="buckets/:id/:type" element={<BucketsRoute />} />
        <Route path="buckets/:id" element={<BucketsRoute />} />
        <Route path="buckets/*" element={<BucketsRoute />} />
        <Route path="*" element={<Navigate to="/posts/planned/all" />} />
      </Route>
      <Route path="history/*" element={<HistoryRoute />}>
        <Route path="posted/:type" element={<PostedPostsManager />} />
        <Route path="failed" element={<FailedPostsManager />} />
        <Route path="*" element={<Navigate to="/history/posted/post-planner" />} />
      </Route>
      <Route path="composer/*" element={<Compose />} />
      <Route path="settings/*" element={<Settings />} />
      <Route path="reviews/:view/:locationId" element={<Listings />} />
      <Route path="onboarding/*" element={<Onboarding />} />
      <Route path="training/*" element={<Training />} />
      <Route path="*" element={<DefaultRedirect />} />
    </Route>
    <Route path="signup/*" element={<Signup />} errorElement={<RouteErrorView />}>
      <Route path="create-account" index element={<CreateAccountForm />} />
      <Route path="payment-details" element={<PaymentDetails />} />
      <Route path="*" element={<Navigate to="/signup/create-account" />} />
    </Route>
    <Route path="login" element={<UnAuthenticatedRoute><Outlet /></UnAuthenticatedRoute>} errorElement={<RouteErrorView />}>
      <Route path="*" element={<Login />}>
        <Route path="declined" element={<DeclinedPermissions />} />
        <Route path="*" element={<Landing />} />
      </Route>
    </Route>
    <Route path="/logout" element={<Logout />} />
    <Route path="/authorize" element={<Authorize />} />
    <Route path={`/${ADMIN_PATHNAME}/*`} element={<AdminApp />} />
  </React.Fragment>
)

export function Root() {
  const location = useLocation()
  const background = location.state?.background

  return (
    <React.Fragment>
      <Routes location={background || location}>
        {routes}
      </Routes>
      {background && (
        <Routes>
          <Route path="composer/*" element={<Compose />} />
        </Routes>
      )}
    </React.Fragment>
  )
}
