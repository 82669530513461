import * as React from 'react'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'
import { INPUT_ACCEPT_PHOTO } from 'utils/file'
import IconCheck from '@mui/icons-material/Check'
import IconAdd from '@mui/icons-material/Add'

import styles from './ImageSelector.pcss'
import Tooltip from '@mui/material/Tooltip'

interface ImageSelectorProps {
  imageUrls: string[]
  imageFileSrc?: string
  selectedIndex: number | null
  className?: string
  hideTitle?: boolean
  onBackClick?: () => void
  onFileChanged: (file: File) => void
  onSelectedIndexChange: (index: number) => void
}

export function ImageSelector(props: ImageSelectorProps) {
  const [fileUpload, setFileUpload] = React.useState<File | null>(null)

  const onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]
    if (file) {
      setFileUpload(file)
      props.onFileChanged(file)
    }
  }

  const onSelectedIndexChange = (index: number) => {
    props.onSelectedIndexChange(index)
  }

  const onFileClick = () => {
    if (fileUpload) {
      props.onFileChanged(fileUpload)
    }
  }

  const fileImageStyles = props.imageFileSrc ? {
    backgroundImage: `url(${props.imageFileSrc})`
  } : undefined

  return (
    <div className={props.className || ''}>
      <div className={styles.title}>
        {!props.hideTitle && (
          <h5>
            <FormattedMessage id="find.save-feed-dialog.label.select-featured-image" />
            {Boolean(props.onBackClick) && (
              <Button variant="contained" color="primary" size="small" onClick={props.onBackClick}>
                <FormattedMessage id="actions.done" />
              </Button>
            )}
          </h5>
        )}
        <p>(<FormattedMessage id="find.save-feed-dialog.label.stream-images-hint" />)</p>
      </div>
      <div className={styles.grid}>
        <div className={`${styles.box} ${styles['box-input']}`} onClick={onFileClick}>
          {Boolean(props.imageFileSrc) && props.selectedIndex === null && (
            <div className={styles['check-box']}>
              <IconCheck fontSize="small" className={styles['icon-check']} />
            </div>
          )}
          <Tooltip title="Upload image">
            <div className={`${styles.circle} ${styles['img-file']}`} style={fileImageStyles}>
              <IconAdd className={styles['icon-add']} />
              <input
                type="file"
                accept={INPUT_ACCEPT_PHOTO.join(',')}
                onChange={onFileSelected}
                className={styles.input}
              />
            </div>
          </Tooltip>
        </div>
        {props.imageUrls.map((src, index) => (
          <ImageBox
            key={index}
            index={index}
            active={props.selectedIndex === index}
            src={src}
            onClick={onSelectedIndexChange}
          />
        ))}
      </div>
    </div>
  )
}

const ImageBox = (props: { src: string, index: number, active: boolean, onClick: (index: number) => void }) => {
  const onClick = () => {
    props.onClick(props.index)
  }

  return (
    <div className={styles.box} onClick={onClick}>
      {props.active && (
        <div className={styles['check-box']}>
          <IconCheck fontSize="small" className={styles['icon-check']} />
        </div>
      )}
      <div className={styles.circle}>
        <img src={props.src} />
      </div>
    </div>
  )
}

export default ImageSelector
