import { IndexedObject } from 'interfaces'
/**
 * Creates a single array of mixed elements from all provided arrays.
 *
 * @export
 * @template T
 * @param {IndexedObject<T[]>} obj
 * @returns {Array<T>}
 */
export function createMixedArrayFromObject<T>(obj: IndexedObject<T[]>): Array<T> {
  const result: T[] = []
  const lengths = Object.values(obj).map(values => values.length)
  const maxLength = Math.max(...lengths)
  for (let i = 0; i < maxLength; i++) {
    Object.values(obj).forEach(array => {
      const element = array.shift()
      if (element) {
        result.push(element)
      }
    })
  }

  return result
}
