import * as React from 'react'
import {
  Article,
  ARTICLE_TYPE,
  BRAND_FACEBOOK,
  BRAND_INSTAGRAM,
  BRAND_LINKEDIN,
  BRAND_PINTEREST,
  BRAND_TWITTER,
  ContentItem,
  Gif,
  GIF_TYPE,
  LinkDetails,
  PostDestination,
  PostDestinationType,
  SocialProfileTag,
  Video,
  VIDEO_TYPE
} from 'interfaces'
import { BRAND_GOOGLE, BRAND_TIKTOK } from 'interfaces/Content/SocialBrand'
import { ComposerBasePost } from 'services/compose/interfaces/ComposerBasePost'
import { ComposerTwitterPost } from 'services/compose/interfaces/ComposerTwitterPost'
import { ComposerPostStatus } from 'services/compose/selectors'
import { FacebookPostPreview } from './components/FacebookPostPreview'
import { InstagramPostPreview } from './components/InstagramPostPreview'
import { LinkedinPostPreview } from './components/LinkedinPostPreview'
import { PinterestPostPreview } from './components/PinterestPostPreview'
import { TwitterPostPreview } from './components/TwitterPostPreview'
import { ComposerPinterestPost } from 'services/compose/interfaces/ComposerPinterestPost'
import { TikTokPostPreview } from './components/TikTokPostPreview'
import { GooglePostPreview } from './components/GooglePostPreview'
import { ComposerGooglePost } from 'services/compose/interfaces/ComposerGooglePost'
import { BRAND_YOUTUBE } from 'shared'
import { YoutubePostPreview } from './components/YoutubePostPreview'
import { ComposerYoutubePost } from 'services/compose/interfaces/ComposerYoutubePost'
import { ComposerFacebookPost, ComposerInstagramPost } from 'services/compose/interfaces'

interface PostPreviewProps {
  postNetwork: PostDestinationType
  post: ComposerBasePost
  tags?: SocialProfileTag[]
  postAsPhoto?: boolean
  imageUrls: string[]
  videoUrl?: string
  videoThumbnailUrl?: string
  content?: ContentItem
  link?: LinkDetails
  firstComment?: string
  twitterPostStatus?: ComposerPostStatus
  featuredImageUrl: string | undefined
  previewProfile?: PostDestination
  className?: string
  reshare?: boolean
  textBackgroundUrl?: string
  textColor?: string
  documentName?: string
}

export function PostPreview(props: PostPreviewProps) {
  const statusText = props.post?.status.text || ''
  const contentType = props.content?.type
  const featuredImageUrl = props.featuredImageUrl || (props.content as any)?.imageUrl || props.imageUrls[0] || props.link?.image[0]
  const isTwitterGif = contentType === GIF_TYPE && props.content?.feed.type === BRAND_TWITTER
  const isVideoPost = Boolean(props.videoUrl) || contentType === VIDEO_TYPE || isTwitterGif
  const videoUrl = isTwitterGif ? (props.content as Gif).gifUrl : props.videoUrl

  let linkData: { url: string, title: string, description?: string, isVideo?: boolean } | undefined
  if (contentType === ARTICLE_TYPE) {
    linkData = {
      url: (props.content as Article).sourceLink || props.content?.socialLink || '',
      description: (props.content as Article).description,
      title: (props.content as Article).title
    }
  } else if (contentType === VIDEO_TYPE) {
    linkData = {
      url: (props.content as Video).shortLink || props.content?.socialLink || '',
      description: (props.content as Video).description,
      title: (props.content as Video).title,
      isVideo: true
    }
  } else if (props.link) {
    linkData = {
      url: props.link.domain || props.link.url,
      description: props.link.description,
      title: props.link.title
    }
  }

  if (props.postAsPhoto || !linkData?.url) {
    linkData = undefined
  }

  const wrapperClass = props.className || ''

  switch (props.postNetwork) {
    case BRAND_FACEBOOK: {
      const post = props.post as ComposerFacebookPost
      return (
        <div className={wrapperClass}>
          <FacebookPostPreview
            profile={props.previewProfile}
            statusText={statusText}
            tags={props.tags}
            article={linkData}
            featuredImageUrl={featuredImageUrl}
            imageUrls={props.imageUrls}
            videoUrl={videoUrl}
            isVideoPost={isVideoPost || isTwitterGif}
            contentItem={props.content}
            reshare={props.reshare}
            isStory={post.postType === 'stories'}
            withFirstComment={Boolean(props.firstComment)}
            textBackgroundUrl={props.textBackgroundUrl}
            textColor={props.textColor}
          />
        </div>
      )
    }

    case BRAND_TWITTER: {
      const post = props.post as ComposerTwitterPost
      const articleUrlMissing = post.tweetType === 'new'
        && props.twitterPostStatus?.caption.hints.includes('composer.hints.twitter.no-article-url')

      return (
        <div className={wrapperClass}>
          <TwitterPostPreview
            profile={props.previewProfile}
            status={statusText}
            tweetText={props.content?.tweetText}
            quote={post.tweetType === 'quote'}
            retweet={post.tweetType === 'retweet'}
            featuredImageUrl={articleUrlMissing ? undefined : featuredImageUrl}
            imageUrls={props.imageUrls}
            article={articleUrlMissing ? undefined : linkData}
            videoUrl={videoUrl}
            isVideoPost={isVideoPost}
          />
        </div>
      )
    }

    case BRAND_INSTAGRAM: {
      const post = props.post as ComposerInstagramPost
      return (
        <div className={wrapperClass}>
          <InstagramPostPreview
            profile={props.previewProfile}
            statusText={statusText}
            comment={props.firstComment}
            featuredImageUrl={featuredImageUrl}
            imageUrls={props.imageUrls}
            isLink={Boolean(linkData)}
            videoUrl={videoUrl}
            type={post.postType as any}
            location={Object.values(post.locations || {})[0]?.name}
            videoThumbnailUrl={props.videoThumbnailUrl}
          />
        </div>
      )
    }

    case BRAND_PINTEREST: {
      const post = props.post as ComposerPinterestPost
      return (
        <div className={wrapperClass}>
          <PinterestPostPreview
            text={post.title}
            imageUrl={featuredImageUrl}
          />
        </div>
      )
    }

    case BRAND_LINKEDIN: {
      return (
        <div className={wrapperClass}>
          <LinkedinPostPreview
            profile={props.previewProfile}
            statusText={statusText}
            featuredImageUrl={featuredImageUrl}
            imageUrls={props.imageUrls}
            videoUrl={videoUrl}
            isVideoPost={isVideoPost}
            linkData={linkData}
            documentName={props.documentName}
          />
        </div>
      )
    }

    case BRAND_TIKTOK: {
      return (
        <div className={wrapperClass}>
          <TikTokPostPreview
            profile={props.previewProfile}
            videoUrl={videoUrl}
            videoThumbnailUrl={props.videoThumbnailUrl}
            status={props.post.status.text}
          />
        </div>
      )
    }

    case BRAND_GOOGLE: {
      const post = props.post as ComposerGooglePost
      return (
        <div className={wrapperClass}>
          <GooglePostPreview
            profile={props.previewProfile}
            featuredImageUrl={featuredImageUrl}
            statusText={post.status.text}
            videoUrl={props.videoUrl}
            buttonLink={post.link}
            buttonType={post.buttonType}
          />
        </div>
      )
    }

    case BRAND_YOUTUBE: {
      const post = props.post as ComposerYoutubePost
      return (
        <div className={wrapperClass}>
          <YoutubePostPreview
            profile={props.previewProfile}
            videoUrl={videoUrl}
            title={post.title}
          />
        </div>
      )
    }

    default:
      return null
  }
}

export default PostPreview
