import * as React from 'react'
import CardList from 'components/CardList'
import styles from './HorizontalList.pcss'

const LIST_HEIGHT = 48
const ITEM_WIDTH = 40

export function HorizontalList(props: {
  children: React.ReactElement[],
  listHeight?: number
  verticalSpacing?: number
  scrollBy?: number
  className?: string
 }) {
  return (
    <CardList
      itemWidth={ITEM_WIDTH}
      listHeight={props.listHeight || LIST_HEIGHT}
      skipCardHoverCheck
      scrollBy={props.scrollBy}
      verticalSpacing={props.verticalSpacing}
      containerClassName={`${styles.list} ${props.className || ''}`}
    >
      {props.children}
    </CardList>
  )
}

export default HorizontalList
