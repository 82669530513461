import * as React from 'react'
import styles from './EmptyView.pcss'
import { Icon } from '@mdi/react'
import DeleteIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Backdrop from '@mui/material/Backdrop'

interface EmptyViewProps {
  title: React.ReactNode
  icon?: string | React.ReactNode
  subtitle?: React.ReactNode
  carousel?: boolean
  contained?: boolean
  top?: string
  className?: string
  hidden?: boolean
  backdrop?: boolean
  backdropClassName?: string
  onClose?: () => void
}

export function EmptyView(props: EmptyViewProps) {
  const { hidden } = props
  const layoutClasses = `${props.contained ? styles.contained : ''} ${props.carousel ? styles.carousel : ''}`
  const style = props.top !== undefined ? {
    top: props.top
  } : undefined

  if (hidden) {
    return null
  }

  const content = (
    <div style={style} className={`${styles.wrapper} ${layoutClasses} ${props.className || ''}`}>
      {props.onClose && (
        <IconButton className={styles['btn-close']} onClick={props.onClose}>
          <DeleteIcon />
        </IconButton>
      )}
      {props.icon && (
        <div className={styles['icon-box']}>
          {typeof props.icon === 'string' && (
            <Icon className={styles.icon} path={props.icon} size="40px" />
          )}
          {typeof props.icon !== 'string' && props.icon}
        </div>
      )}
      <h1 className={styles.title}>
        {props.title}
      </h1>
      {props.subtitle && (
        <div className={styles.subtitle}>{props.subtitle}</div>
      )}
    </div>
  )

  if (!props.backdrop) {
    return content
  }

  return (
    <Backdrop className={`${styles.backdrop} ${props.backdropClassName || ''}`} open={!hidden}>
      {content}
    </Backdrop>
  )
}

export default EmptyView
