import { FileFolder, MyFile } from 'interfaces'
import { getFileThumbUrl } from './util'
import { ContentType, PHOTO_TYPE } from 'shared'

export function storageInfoAdapter(data: any) {
  return {
    used: data.storageUsed || 0,
    free: data.storageLeft || 0,
    limit: data.maxStorageAllowed || 0,
    fileSizeLimits: {
      photos: data.fileSizeLimits.photos,
      videos: data.fileSizeLimits.videos,
      gifs: data.fileSizeLimits.gifs
    }
  }
}

export function myLibraryFoldersAdapter(data: any[]) {
  return data.reduce((foldersMap: { [id: string]: FileFolder }, folder: any) => {
    const files: { [id: string]: MyFile } = {}
    const filters: any = {}
    const filesCountByType: Record<string, number> = {}
    folder.files.forEach((f: any) => {
      const key = `${f.id}-${f.type}`
      filesCountByType[f.type] = filesCountByType[f.type] || 0
      filesCountByType[f.type] += 1

      files[key] = {
        id: f.id,
        key,
        name: f.filename,
        url: f.link,
        thumbUrl: getFileThumbUrl(f.link, f.type),
        folderId: folder.folderId,
        type: f.type,
        size: f.size,
        width: f.width,
        height: f.height,
        createdAt: f.createdAt,
        updatedAt: f.updatedAt
      }
      filters[f.type] = true
    })

    let coverImages: string[] = []
    try {
      coverImages = JSON.parse(folder.folderImages)
    } catch (e: any) {
      // eslint-disable-next-line no-magic-numbers
      coverImages = Object.values(files).filter(f => f.type === PHOTO_TYPE).slice(0, 3).map(f => f.url).filter(Boolean)
    }

    if (!coverImages) {
      coverImages = []
    }

    foldersMap[folder.folderId] = {
      id: folder.folderId.toString(),
      filesCountByType,
      featuredImage: coverImages[0],
      coverImages: coverImages.slice(1),
      name: folder.folderName,
      color: folder.folderColor,
      filesCount: folder.filesCount || 0,
      files,
      order: folder.folderOrder,
      isFileFolder: true,
      isPrivate: folder.folderPrivate > 0,
      filters: Object.keys(filters) as ContentType[]
    }
    return foldersMap
  }, {})
}
