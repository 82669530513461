import * as React from 'react'
import { Icon } from '@mdi/react'
import { mdiPail, mdiAlertCircle } from '@mdi/js'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import styles from './BucketSelect.pcss'
import { Bucket } from 'shared/types'
import IconCheck from '@mui/icons-material/Check'
import Tooltip from '@mui/material/Tooltip'

export const NO_POST_BUCKET = 'empty'
export function BucketSelect(props: {
  buckets: Bucket[],
  emptySelectionLabel: React.ReactNode,
  selectedBucketId?: string,
  className?: string,
  emptyItemLast?: boolean,
  onSelectedBucketChange?: (id: string | undefined) => void
}) {
  const onChange = (e: SelectChangeEvent) => {
    if (props.onSelectedBucketChange) {
      const id = e.target.value
      props.onSelectedBucketChange(id === NO_POST_BUCKET ? undefined : id)
    }
  }

  const selectedBucket = props.buckets.find(b => b.id === props.selectedBucketId)
  const emptyItem = (
    <MenuItem key="empty" value={NO_POST_BUCKET} className={`${styles['li-bucket']} ${!props.selectedBucketId ? styles.selected : ''}`}>
      <div className={styles['bucket-name']}>
        {props.emptySelectionLabel}
      </div>
    </MenuItem>
  )

  return (
    <Select
      value={props.selectedBucketId || NO_POST_BUCKET}
      renderValue={() => (
        <div className={styles['select-value']}>
          <div className={`${styles.value} text-ellipsis`}>
            {selectedBucket && (
              <Icon path={mdiPail} size="18px" color={selectedBucket.color} />
            )}
            {selectedBucket?.name || props.emptySelectionLabel || ''}
          </div>
          {selectedBucket?.isExpired && (
            <Tooltip title="Bucket is currently inactive">
              <div className={styles['value-icon']}>
                <Icon path={mdiAlertCircle} color="#ff7676" size="20px" />
              </div>
            </Tooltip>
          )}
        </div>
      )}
      classes={{ select: `${styles.select} ${props.className || ''}` }}
      disableUnderline
      variant="standard"
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        classes: { root: styles['menu-root'] }
      }}
      onChange={onChange}
    >
      {!props.emptyItemLast && emptyItem}
      {props.buckets.map(bucket => {
        const active = props.selectedBucketId === bucket.id
        return (
          <MenuItem
            value={bucket.id}
            key={bucket.id}
            className={`${styles['li-bucket']} ${active ? styles.selected : ''}`}
          >
            <Icon path={mdiPail} size="18px" color={bucket.color} />
            <div className={`${styles['bucket-name']} text-ellipsis`}>
              {bucket.name}
              {active && <IconCheck color="primary" fontSize="small" className={styles.icon} />}
            </div>
          </MenuItem>
        )
      })}
      {props.emptyItemLast && emptyItem}
    </Select>
  )
}

export default BucketSelect
