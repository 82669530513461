import { V1 } from './net'
import { StoreThunkAction, StoreThunkDispatch } from 'store/state'
import { PPProduct } from 'interfaces'
import { checkTokens } from 'services/auth'
import { Observable } from 'rxjs/Observable'

export function getPlans(): StoreThunkAction<Observable<PPProduct[]>> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(checkTokens()).map((response: any) => response.subscriptions.products as PPProduct[])
  }
}

export function updatePlan(plan: PPProduct) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.updatePlan(plan))
  }
}
