import * as React from 'react'
import { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { WithIntl } from 'interfaces'
import { injectIntl } from 'react-intl'

import styles from './ConfirmDialog.pcss'

interface ConfirmDialogProps extends WithIntl {
  message: string | React.ReactNode
  children: (confirm: (callback: any) => (e: any) => void) => React.ReactElement<any>
  disableConfirm?: boolean
  labelOK?: string | React.ReactNode
  labelCancel?: string
  className?: string
  btnCancelClassName?: string
  btnOKClassName?: string
  onDecline?: () => void
}

export function ConfirmDialog(props: ConfirmDialogProps) {
  const [active, setActive] = useState(false)
  const [submit, setSubmit] = useState<{ handler:(args: any) => any, params: any }>({ handler: () => null, params: null })

  const openDialog = (submitHandler: any) => (event: Event) => {
    if (event?.preventDefault && event?.stopPropagation) {
      event.preventDefault()
      event.stopPropagation()
    }

    if (props.disableConfirm) {
      submitHandler()
    } else {
      setSubmit({ handler: submitHandler, params: event })
      setActive(true)
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (submit.handler) {
      submit.handler(submit.params)
    }
    setActive(false)
  }

  const close = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setActive(false)
  }

  const handleCancel = () => {
    if (props.onDecline) {
      props.onDecline()
    }
    setActive(false)
  }

  const labelConfirm = props.labelOK ? props.labelOK : props.intl.formatMessage({ id: 'actions.ok', defaultMessage: 'OK' })
  const labelCancel = props.labelCancel ? props.labelCancel : props.intl.formatMessage({ id: 'actions.cancel', defaultMessage: 'Cancel' })

  return (
    <React.Fragment>
      {props.children(openDialog)}
      <Dialog
        open={active}
        onClose={close}
        classes={{ paper: `${styles.dialog} ${props.className || ''}` }}
        data-testid="confirm-dialog"
      >
        <h4 className={styles.message}>{props.message}</h4>
        <div className={styles.actions}>
          <Button onClick={handleCancel} className={props.btnCancelClassName}>{labelCancel}</Button>
          <Button
            variant="contained"
            color="primary"
            className={`${styles['btn-submit']} ${props.btnOKClassName || ''}`}
            onClick={handleSubmit}
          >
            {labelConfirm}
          </Button>
        </div>
      </Dialog>
    </React.Fragment>
  )
}

export default injectIntl(ConfirmDialog)
