import * as React from 'react'
import { connect } from 'react-redux'
import CuratedStreamsCarousel from './CuratedStreamsCarousel'
import StoreState, { StoreThunkDispatch } from 'store/state'
import { getCuratedStreams, getSavedSources } from 'services/content/recommended/actions'
import { curatedStreamsSelector, userStreamsSelector } from 'services/content/selectors'
import { Observable } from 'rxjs/Observable'
import { Stream } from 'interfaces'
import { SECTION_URL_POPULAR, SECTION_URL_INDUSTRIES, SECTION_URL_MY_STREAMS } from '../../home.config'
import { Subscription } from 'rxjs/Subscription'

interface CuratedStreamsCarouselContainerOwnProps {
  category: 'popular' | 'industries' | 'my-streams'
  navPrefix?: string
}

interface CuratedStreamsCarouselContainerConnectedProps {
  streams: {
    popular: Stream[]
    industries: Stream[]
    'my-streams': Stream[]
  }
  title?: string
  getPopular: () => Observable<any>
  getIndustries: () => Observable<any>
  getMyStreams: () => Observable<any>
}

type CuratedStreamsCarouselContainerProps = CuratedStreamsCarouselContainerConnectedProps & CuratedStreamsCarouselContainerOwnProps

const CuratedStreamsCarouselContainer = React.memo(function (props: CuratedStreamsCarouselContainerProps) {
  const [loading, setLoading] = React.useState(true)
  const [userStreams, setUserStreams] = React.useState<Stream[]>(props.streams['my-streams'])
  const stopLoading = () => {
    setLoading(false)
  }

  React.useEffect(() => {
    setLoading(true)
    let sub: Subscription
    switch (props.category) {
      case 'popular':
        sub = props.getPopular().subscribe(stopLoading)
        break
      case 'industries':
        sub = props.getIndustries().subscribe(stopLoading)
        break
      case 'my-streams':
        sub = props.getMyStreams().subscribe(response => {
          stopLoading()
          const streams = response.map((s: Stream) => {
            const userStream = props.streams['my-streams'].find(str => str.id === s.id)
            return {
              ...s,
              ...userStream
            }
          })
          setUserStreams(streams)
        })
        break
      default:
        stopLoading()
    }
    return () => {
      if (sub) {
        sub.unsubscribe()
      }
    }
  }, [props.category])

  let navPrefix
  let streams = props.streams[props.category]

  switch (props.category) {
    case 'popular':
      navPrefix = SECTION_URL_POPULAR
      break
    case 'industries':
      navPrefix = SECTION_URL_INDUSTRIES
      break
    case 'my-streams':
      navPrefix = SECTION_URL_MY_STREAMS
      streams = userStreams
      break
  }

  navPrefix = props.navPrefix || navPrefix

  return (
    <CuratedStreamsCarousel
      category={props.category}
      streams={streams}
      navPrefix={navPrefix}
      title={props.title}
      loading={loading}
    />
  )
})

function mapStateToProps(state: StoreState) {
  const curatedStreams = curatedStreamsSelector(state)
  const userStreams = userStreamsSelector(state) as Stream[]

  return {
    streams: {
      ...curatedStreams,
      'my-streams': userStreams
    }
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    getPopular: () => Observable.fromPromise(dispatch(getCuratedStreams('popular')).unwrap()),
    getIndustries: () => Observable.fromPromise(dispatch(getCuratedStreams('industries')).unwrap()),
    getMyStreams: () => Observable.fromPromise(dispatch(getSavedSources()).unwrap())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CuratedStreamsCarouselContainer)
