import * as React from 'react'
import styles from './Accordion.pcss'
import IconDropdown from '@mui/icons-material/KeyboardArrowRight'

interface AccordionProps {
  title: string | React.ReactNode
  order?: string
  headerElements?: React.ReactNode
  open?: boolean
  className?: string
  contentClassName?: string
  id?: string
  onToggle?: (open: boolean) => void
}

export function Accordion(props: React.PropsWithChildren<AccordionProps>) {
  const [open, setOpen] = React.useState(typeof props.open === 'boolean' ? props.open : true)

  React.useEffect(() => {
    if (typeof props.open === 'boolean') {
      setOpen(props.open)
    }
  }, [props.open])

  const toggle = () => {
    setOpen(current => !current)
    if (props.onToggle) {
      props.onToggle(!open)
    }
  }

  return (
    <section className={`${styles.accordion} ${open ? styles.open : ''} ${props.className || ''}`} data-testid={props.id}>
      <header className={styles.header}>
        <h4 className={styles.title} onClick={toggle} data-testid="accordion-toggle">
          {props.order && (
            <div className={styles.order}>{props.order}</div>
          )}
          {props.title}
          <IconDropdown className={styles['icon-toggle']} />
        </h4>
        {props.headerElements}
      </header>
      <div className={`${styles.content} ${props.contentClassName || ''}`}>
        {props.children}
      </div>
    </section>
  )
}

export default Accordion
