import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'
import { Subscriber } from 'rxjs/Subscriber'
import { ALLOWED_BULK_EXTENSIONS } from 'utils/file'

export const ENDPOINT_UPLOAD = `${LEGACY_URL}/v3/upload/form_data`
export const ENDPOINT_UPLOAD_PREVIEW = `${LEGACY_URL}/v3/upload/upload_preview`
export const ENDPOINT_DELETE_FILE = `${LEGACY_URL}/v3/upload/remove`

export const V1 = Object.freeze({
  uploadFile: function () {
    const builder = new RequestBuilder(ENDPOINT_UPLOAD).asPost().expectJSON()

    return function (file: File, progressSubscriber?: Subscriber<any>) {
      const data = new FormData()
      data.append('file', file)
      const extension = `.${file.name.split('.').pop()}`
      if (ALLOWED_BULK_EXTENSIONS.includes(extension)) {
        data.append('post_type', 'bulk')
      }
      const request = builder.form(data).build()

      return authorizedPipe<any>({
        ...request,
        progressSubscriber
      })
    }
  }(),
  uploadPreview: function () {
    const builder = new RequestBuilder(ENDPOINT_UPLOAD_PREVIEW).asPost().expectJSON()

    return function (file: File, videoUrl: string) {
      const data = new FormData()
      data.append('file', file)
      data.append('video_url', videoUrl)
      return authorizedPipe<any>(builder.form(data).build())
    }
  }(),
  deleteFile: function () {
    const builder = new RequestBuilder(ENDPOINT_DELETE_FILE).asPost().expectJSON().asFormUrlEncoded()

    return function (url: string) {
      return authorizedPipe<any>(builder.body({ url }).build())
    }
  }()
})
