import { ContentFeedType, Feed, IndexedObject } from 'interfaces'
import { SourcesSearchSuccessResponse } from 'services/search/actions'
import { getRandomElements } from 'shared/utils'
import { SearchState } from './interfaces'
import { SearchFilter, SearchFilterKey, SearchResultsCategory } from './types'

export const ADD_FEED = 'ADD_FEED'
export const REMOVE_FEED = 'REMOVE_FEED'
export const REPLACE_FEED = 'REPLACE_FEED'
export const CLEAR_ALL_FEEDS = 'CLEAR_ALL_FEEDS'

type ActionSelectFeed = { type: typeof ADD_FEED, payload: Feed }
type ActionDeselectFeed = { type: typeof REMOVE_FEED, payload: { id: string, type: ContentFeedType } }
type ActionReplaceFeedInSelection = { type: typeof REPLACE_FEED, payload: { id: string, newFeed: Feed } }
type ActionClearSelectedFeeds = { type: typeof CLEAR_ALL_FEEDS }
export type FeedSelectionAction = ActionSelectFeed | ActionDeselectFeed | ActionReplaceFeedInSelection | ActionClearSelectedFeeds

export const UPDATE_STATE = 'UPDATE_STATE'
export function updateState(update: Partial<SearchState>): SearchAction {
  return {
    type: UPDATE_STATE,
    payload: update
  }
}

export const SET_SEARCH_FILTERS = 'SET_SEARCH_FILTERS'
export function setSearchFilters(filters: SearchFilter[]): SearchAction {
  return {
    type: SET_SEARCH_FILTERS,
    payload: filters
  }
}

export const SET_SEARCH_CATEGORY = 'SET_SEARCH_CATEGORY'
export function setSearchCategory(c: SearchResultsCategory): SearchAction {
  return {
    type: SET_SEARCH_CATEGORY,
    payload: c
  }
}

export const SEARCH_REQUEST = 'SEARCH_REQUEST'
export function searchRequest(query: string, scope: SearchFilterKey[], page: number, qTag?: string, siTag?: string): SearchAction {
  return {
    type: SEARCH_REQUEST,
    payload: { query, scope, page, quotesTag: qTag, ideasTag: siTag }
  }
}

export const SEARCH_ERROR = 'SEARCH_ERROR'
export function searchError(errorsByScope: IndexedObject<string>): SearchAction {
  return {
    type: SEARCH_ERROR,
    payload: { errors: errorsByScope }
  }
}

export const SOURCES_SEARCH_SUCCESS = 'SOURCES_SEARCH_SUCCESS'
export type SourcesSearchSuccessAction = { type: typeof SOURCES_SEARCH_SUCCESS, payload: SourcesSearchSuccessResponse }
export function sourcesSearchSuccess(response: SourcesSearchSuccessResponse): SearchAction {
  return {
    type: SOURCES_SEARCH_SUCCESS,
    payload: response
  }
}

export const CONTENT_SEARCH_SUCCESS = 'CONTENT_SEARCH_SUCCESS'
export type ContentSearchSuccessAction = { type: typeof CONTENT_SEARCH_SUCCESS, payload: SourcesSearchSuccessResponse }
export function contentSearchSuccess(type: SearchFilterKey, data: any[], page: number): SearchAction {
  return {
    type: CONTENT_SEARCH_SUCCESS,
    payload: { type, data, page }
  }
}

export type SearchAction = FeedSelectionAction | SourcesSearchSuccessAction | ContentSearchSuccessAction | { type: string, payload?: any }

const PRESET_SEARCHES = ['cats', 'dogs', 'memes', 'happiness', 'marketing']
export function getRandomSearchQuery() {
  return getRandomElements(PRESET_SEARCHES, 1)[0]
}
