import * as React from 'react'
import { PostedPost as IPostedPost, POST_TYPE_POSTED } from 'interfaces'
import PostBase from './PostBase'
import PostedStats from './PostedStats'
import { RecycleSelector } from './components/RecycleSelector'
import styles from './PostBase.pcss'
import { noop } from 'utils/noop'
import Checkbox from '@mui/material/Checkbox'
import PostedActionsMenu from './PostedActionsMenu'
import { RepeatIndicator } from './components/RepeatIndicator'
import { useSelector } from 'react-redux'
import { profilesByPpidSelector } from 'services/destinations'

export interface PostedPostProps {
  post: IPostedPost
  selected: boolean
  withDate?: boolean
  onRequeue: (id: string, ppid: string) => void
  onRepost: (post: IPostedPost) => void
  onCopy: (post: IPostedPost) => void
  onShareNow?: (post: IPostedPost) => void
  onMediaPreviewBtnClick: (post: IPostedPost) => void
  onToggleSelected: (postId: string) => void
}

export function PostedPost(props: PostedPostProps) {
  const { post, onRequeue, onRepost, onCopy, onShareNow } = props
  const profiles = useSelector(profilesByPpidSelector)
  const profile = profiles[post.ppPageId]

  const onMediaPreviewBtnClick = React.useCallback(() => {
    props.onMediaPreviewBtnClick(post)
  }, [props.post.id])

  const requeue = React.useCallback(() => {
    onRequeue(post.id, post.ppPageId)
  }, [props.post.id])

  const toggleSelected = React.useCallback(() => {
    props.onToggleSelected(post.id)
  }, [props.post.id, props.onToggleSelected])

  const repost = React.useCallback(() => {
    onRepost(post)
  }, [props.post.id])

  const copy = React.useCallback(() => {
    onCopy(post)
  }, [props.post.id])

  const shareNow = React.useCallback(() => {
    if (onShareNow) {
      onShareNow(post)
    }
  }, [props.post.id])

  const actions = React.useMemo(() => (
    <div className={styles['actions-posted']}>
      {props.post.repeatLength ? (
        <RepeatIndicator
          repeatLength={props.post.repeatLength}
          repeatPeriod={props.post.repeatPeriod}
          repeatUntil={props.post.repeatUntil}
        />
      ) : (
        <RecycleSelector
          value={post.recycle}
          className={styles.recycle}
          activeClassName={styles.on}
          disabled
          onChange={noop}
        />
      )}
      <PostedStats
        shares={post.sharesFormatted}
        likes={post.likesFormatted}
        comments={post.commentsFormatted}
        clicks={post.clicksFormatted}
        saves={post.savesFormatted}
        impressions={post.impressionsFormatted}
        isRepin={post.isRepin}
        network={post.network}
      />
      <div className={styles['posted-actions-btn-box']}>
        <PostedActionsMenu
          link={post.realLink}
          isBucketPost={Boolean(post.bucket)}
          onCopy={copy}
          onRepost={repost}
          onRequeue={requeue}
          onShareNow={props.onShareNow ? shareNow : undefined}
        />
        <Checkbox
          color="primary"
          classes={{ root: styles.checkbox }}
          checked={props.selected}
          onChange={toggleSelected}
        />
      </div>
    </div>
  ), [
    props.post.repeatLength, props.post.repeatPeriod,
    props.post.repeatUntil, props.onShareNow, props.selected, post.recycle, post.sharesFormatted,
    post.likesFormatted, post.commentsFormatted, post.clicksFormatted,
    post.savesFormatted, post.impressionsFormatted, post.isRepin, post.network,
    post.realLink, post.bucket, copy, repost, requeue, shareNow, toggleSelected
  ])

  return (
    <PostBase
      actions={actions}
      postId={post.id}
      postType={POST_TYPE_POSTED}
      contentType={post.contentType}
      text={post.status}
      images={post.images}
      timeFormatted={post.timeString}
      timezone={post.timezone}
      profileNetwork={post.network}
      profile={profile}
      title={post.linkTitle}
      description={post.linkDescription}
      linkUrl={post.linkUrl}
      nativeVideoUrl={post.nativeVideoUrl}
      fbVideoUrl={post.fbVideoUrl}
      specialType={post.specialType}
      twitterPostType={post.twPostType}
      linkDomain={post.linkDomain}
      onMediaPreviewBtnClick={onMediaPreviewBtnClick}
      attributes={post.attributes}
      date={post.calendarDate}
      withDate={props.withDate}
      details={post.details}
      bucket={post.bucket}
    />
  )
}

export default React.memo(PostedPost)
