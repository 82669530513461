import { ARTICLE_TYPE, PHOTO_TYPE, STATUS_TYPE, VIDEO_TYPE } from './content'

export const REPEAT_PERIOD_DAYS = 'days'
export const REPEAT_PERIOD_WEEKS = 'weeks'
export const REPEAT_PERIOD_MONTHS = 'months'
export const REPEAT_OPTIONS = [
  { value: REPEAT_PERIOD_DAYS, label: REPEAT_PERIOD_DAYS },
  { value: REPEAT_PERIOD_WEEKS, label: REPEAT_PERIOD_WEEKS },
  { value: REPEAT_PERIOD_MONTHS, label: REPEAT_PERIOD_MONTHS }
]
export const POST_TYPE_VIDEO_LINK = 'video_link'
export const POST_TYPE_PHOTO_LINK = 'photo'
export const POST_TYPE_ARTICLE_LINK = 'link'

export const POST_TYPE_TO_CONTENT_TYPE = {
  status: STATUS_TYPE,
  link: ARTICLE_TYPE,
  gif: PHOTO_TYPE,
  photo: PHOTO_TYPE,
  video: VIDEO_TYPE,
  video_link: VIDEO_TYPE
}

export const MIN_INSTAGRAM_VIDEO_DURATION = 3 // sec
export const MAX_INSTAGRAM_VIDEO_DURATION = 60 // sec
export const MAX_INSTAGRAM_USER_TAGS = 13
export const MAX_INSTAGRAM_HASHTAGS = 30
export const MAX_PINTEREST_HASHTAGS = 30
export const MAX_TIKTOK_FILE_SIZE_MB = 50
export const MAX_TIKTOK_VIDEO_DURATION_SEC = 600
export const TIKTOK_ALLOWED_FILE_EXTENSIONS = ['webm', 'mp4']
export const LINKEDIN_ALLOWED_IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'gif', 'png']
export const MAX_COMPOSER_PHOTOS_COUNT_TWITTER = 4
export const MAX_COMPOSER_PHOTOS_COUNT_FACEBOOK = 30
export const MAX_COMPOSER_PHOTOS_COUNT_LINKEDIN = 9
export const PINTEREST_TITLE_MAX_LENGTH = 100
export const PINTEREST_DESCRIPTION_MAX_LENGTH = 500
export const MAX_LINKEDIN_STATUS_LENGTH = 3000
export const MAX_GMB_STATUS_LENGTH = 1500
export const MAX_YT_TITLE_LENGTH = 100
export const MAX_INSTAGRAM_STATUS_LENGTH = 2200
export const MAX_FACEBOOK_STATUS_LENGTH = 63205
export const MAX_TWEET_LENGTH = 280
export const MAX_TWEET_LENGTH_SPECIAL = 140
export const MAX_FB_STORY_ASPECT_RATIO = 0.5625
export const MIN_FB_STORY_DURATION = 3 // seconds
export const MAX_FB_STORY_DURATION = 60 // seconds
export const MIN_FB_STORY_WIDTH = 540
export const MIN_FB_STORY_HEIGHT = 960
export const GOOGLE_BUTTON_TYPES: Record<string, { label: string }> = {
  NONE: { label: 'None' },
  BOOK: { label: 'Book' },
  ORDER: { label: 'Order online' },
  SHOP: { label: 'Buy' },
  LEARN_MORE: { label: 'Learn more' },
  SIGN_UP: { label: 'Sign up' },
  CALL: { label: 'Call' }
}

export const HASHTAG_REGEXP = /\B\#\w\w+\b/g
