import { LocationInfo } from 'services/compose/interfaces'

export const ACTION_SET_POSTS = 'ACTION_SET_POSTS'
export function setPosts<T>(posts: T[]) {
  return {
    type: ACTION_SET_POSTS,
    payload: posts
  }
}

export const ACTION_REPLACE_POST = 'ACTION_REPLACE_POST'
export function replacePost<T>(post: T) {
  return {
    type: ACTION_REPLACE_POST,
    payload: post
  }
}

export const ACTION_ADD_POSTS = 'ACTION_ADD_POSTS'
export function addPosts<T>(posts: T[]) {
  return {
    type: ACTION_ADD_POSTS,
    payload: posts
  }
}

export const ACTION_RECYCLE_POSTS_SUCCESS = 'ACTION_RECYCLE_POSTS_SUCCESS'
export function recyclePostsSuccess(postIds: string[], all?: boolean) {
  return {
    type: ACTION_RECYCLE_POSTS_SUCCESS,
    payload: { postIds, all }
  }
}

export const ACTION_UPDATE_RECYCLE_POST = 'ACTION_UPDATE_RECYCLE_POST'
export function updateRecyclePost(id: string, value: number | boolean | Date) {
  return {
    type: ACTION_UPDATE_RECYCLE_POST,
    payload: { id, value }
  }
}

export const ACTION_UPDATE_POST_LOCATION = 'ACTION_UPDATE_POST_LOCATION'
export function updatePostLocation(postId: string, location: LocationInfo | undefined) {
  return {
    type: ACTION_UPDATE_POST_LOCATION,
    payload: { postId, location }
  }
}

export const ACTION_DELETE_POSTS = 'ACTION_DELETE_POSTS'
export function deleteMultiplePosts(ids: string[]) {
  return {
    type: ACTION_DELETE_POSTS,
    payload: ids
  }
}
