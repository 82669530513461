import * as React from 'react'
import Logo from 'components/PPLogo'
import { FormattedMessage } from 'react-intl'
import styles from './MainNav.pcss'
import Tooltip from '@mui/material/Tooltip'
import { Icon } from '@mdi/react'
import {
  mdiCalendarMonthOutline,
  mdiFormatListBulletedSquare,
  mdiTooltipImageOutline,
  mdiCogOutline,
  mdiBeta,
  mdiCalendarClockOutline,
  mdiChartBoxOutline,
  mdiPlayCircle
} from '@mdi/js'
import { useDispatch, useSelector } from 'react-redux'
import { impersonationSelector } from 'admin/services/selectors'
import IconButton from '@mui/material/IconButton'
import ExitIcon from '@mui/icons-material/ExitToApp'
import { StoreThunkDispatch } from 'store/state'
import { stopImpersonateUser } from 'admin/services/actions'
import { logout } from 'services/auth'
import { currentUserSelector, isTrialActiveSelector, trialTasksSelector } from 'services/users/selectors'
import { PPNavLink } from 'components/PPNavLink'
import { NavLink, useNavigate } from 'react-router-dom'
import { getFailedPostsCount } from 'services/post/actions'
import { failedPostsCountSelector } from 'services/post/selectors'

export function MainNav(props: { className: string }) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const navigate = useNavigate()
  const isImpersonating = useSelector(impersonationSelector) !== undefined
  const trialTasks = useSelector(trialTasksSelector)
  const isTrialUser = useSelector(isTrialActiveSelector)
  const user = useSelector(currentUserSelector)
  const failedPosts = useSelector(failedPostsCountSelector)

  const showTrialBadge = isTrialUser && trialTasks.find(t => !t.completed) !== undefined
  const showFailedPostsBadge = failedPosts.total > 0

  React.useEffect(() => {
    dispatch(getFailedPostsCount())
  }, [dispatch])

  const stopImpersonating = () => {
    dispatch(stopImpersonateUser())
    dispatch(logout())
  }

  const onBetaBtnClick = () => {
    navigate('/training')
  }

  return (
    <div className={`${styles['nav-box']} ${props.className}`}>
      <NavLink className={styles['logo-link']} to={isTrialUser ? '/training' : '/posts'}>
        <Logo className={styles.logo} />
        {showTrialBadge && (
          <div className={styles.badge}></div>
        )}
      </NavLink>

      <div className={styles.nav}>
        <Tooltip
          title={<FormattedMessage id="header.nav.content" />}
          placement="right"
          enterDelay={0}
          classes={{ tooltip: styles.tooltip }}
        >
          <PPNavLink to="/content" className={styles.link} activeClassName={styles['link-active']}>
            <div className={styles['round-top']}></div>
            <div className={styles['round-bottom']}></div>
            <Icon path={mdiTooltipImageOutline} size="34px" />
          </PPNavLink>
        </Tooltip>
        <Tooltip
          title={<FormattedMessage id="header.nav.publishing" />}
          placement="right"
          enterDelay={0}
          classes={{ tooltip: styles.tooltip }}
        >
          <PPNavLink to="/posts" className={styles.link} activeClassName={styles['link-active']}>
            <div className={styles['round-top']}></div>
            <div className={styles['round-bottom']}></div>
            <Icon path={mdiFormatListBulletedSquare} size="34px" />
          </PPNavLink>
        </Tooltip>
        <Tooltip
          title={<FormattedMessage id="header.nav.plans" />}
          placement="right"
          enterDelay={0}
          classes={{ tooltip: styles.tooltip }}
        >
          <PPNavLink to="/plans" className={styles.link} activeClassName={styles['link-active']}>
            <div className={styles['round-top']}></div>
            <div className={styles['round-bottom']}></div>
            <Icon path={mdiCalendarClockOutline} size="34px" />
          </PPNavLink>
        </Tooltip>
        <Tooltip
          title={<FormattedMessage id="header.nav.history" />}
          placement="right"
          enterDelay={0}
          classes={{ tooltip: styles.tooltip }}
        >
          <PPNavLink to="/history" className={styles.link} activeClassName={styles['link-active']}>
            <div className={styles['round-top']}></div>
            <div className={styles['round-bottom']}></div>
            <Icon path={mdiChartBoxOutline} size="34px" />
            {showFailedPostsBadge && (
              <div className={styles['failed-badge']}></div>
            )}
          </PPNavLink>
        </Tooltip>

        {/* <Tooltip
          title={<FormattedMessage id="header.nav.reviews" />}
          placement="right"
          enterDelay={0}
          classes={{ tooltip: styles.tooltip }}
        >
          <PPNavLink to="/reviews" className={styles.link} activeClassName={styles['link-active']}>
            <div className={styles['round-top']}></div>
            <div className={styles['round-bottom']}></div>
            <Icon path={mdiCommentCheckOutline} size="34px" />
          </PPNavLink>
        </Tooltip> */}
      </div>
      <div className={styles['nav-bottom']}>
        {isImpersonating && (
          <Tooltip
            title="Stop impersonating"
            placement="right"
            enterDelay={0}
            classes={{ tooltip: styles.tooltip }}
          >
            <IconButton
              className={`${styles.link} ${styles['link-btn']}`}
              onClick={stopImpersonating}
              size="large"
            >
              <ExitIcon />
            </IconButton>
          </Tooltip>
        )}
        {user?.roles?.isBeta && (
          <Tooltip
            title="You have access to beta features"
            placement="right"
            enterDelay={0}
            classes={{ tooltip: styles.tooltip }}
          >
            <IconButton
              className={`${styles.link} ${styles['link-btn']}`}
              // onClick={onBetaBtnClick}
              style={{ cursor: 'default' }}
              size="large"
            >
              <Icon path={mdiBeta} size="24px" color="#333" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip
          title={<FormattedMessage id="header.nav.training" />}
          placement="right"
          enterDelay={0}
          classes={{ tooltip: styles.tooltip }}
        >
          <PPNavLink to="/training" className={styles.link} activeClassName={styles['link-active']}>
            <div className={styles['round-top']}></div>
            <div className={styles['round-bottom']}></div>
            <Icon path={mdiPlayCircle} size="34px" />
          </PPNavLink>
        </Tooltip>
        <Tooltip
          title={<FormattedMessage id="header.nav.settings" />}
          placement="right"
          enterDelay={0}
          classes={{ tooltip: styles.tooltip }}
        >
          <PPNavLink to="/settings/networks" className={styles.link} activeClassName={styles['link-active']}>
            <div className={styles['round-top']}></div>
            <div className={styles['round-bottom']}></div>
            <Icon path={mdiCogOutline} size="34px" />
          </PPNavLink>
        </Tooltip>
      </div>
    </div>
  )
}
