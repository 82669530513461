import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CalendarMonthStartOption, CalendarPostSize, PlannedPostsLayout, initialState } from './state'
import { PostDestination, sortByKeyAlphabetically, sortByKeyAscending } from 'shared'
import { createPostSuccess, setComposerProfiles } from 'services/compose'
import { startImpersonation } from 'admin/services/actions'
import { Weekday } from 'utils/calendar'
import {
  createBucket,
  deleteBucket,
  getBuckets,
  getDefaultBuckets,
  getPostSlotsSuccess,
  reorderBuckets,
  updateBucket,
  getFailedPostsCount,
  setSelectedProfiles
} from './actions'
import { getDestinationsSuccess } from 'services/destinations'

const postsSlice = createSlice({
  name: 'posts',
  initialState: initialState(),
  reducers: {
    setSelectedList: (state, action: PayloadAction<string | undefined>) => {
      state.ui.selectedListId = action.payload
    },
    setActiveSidebarView: (state, action: PayloadAction<'grid' | 'list' | 'network'>) => {
      state.ui.selectionActiveView = action.payload
    },
    toggleEmptySlotsVisible: (state) => {
      const next = !state.ui.showEmptySlots
      state.ui.showEmptySlots = next
      state.ui.showVirtualPosts = next ? state.ui.showVirtualPosts : false
    },
    toggleVirtualPostsVisible: (state) => {
      const next = !state.ui.showVirtualPosts
      state.ui.showVirtualPosts = next
      state.ui.showEmptySlots = next ? true : state.ui.showEmptySlots
    },
    setPlannedPostsView: (state, action: PayloadAction<PlannedPostsLayout>) => {
      state.ui.plannedPostsView = action.payload
    },
    setCalendarViewMode: (state, action: PayloadAction<'month' | 'week'>) => {
      state.ui.calendarMode = action.payload
    },
    setWeekViewExpanded: (state, action: PayloadAction<boolean>) => {
      state.ui.weekViewExpanded = action.payload
    },
    setCalendarPostSize: (state, action: PayloadAction<CalendarPostSize>) => {
      state.ui.calendarPostSize = action.payload
    },
    setCalendarMonthStart: (state, action: PayloadAction<CalendarMonthStartOption>) => {
      state.ui.calendarMonthStart = action.payload
    },
    setCalendarWeekStart: (state, action: PayloadAction<Weekday>) => {
      state.ui.calendarWeekStart = action.payload
    },
    setCalendarPostedVisible: (state, action: PayloadAction<boolean>) => {
      state.ui.showPostedInCalendar = action.payload
    },
    toggleSidebarProfilesExpanded: (state) => {
      state.ui.sidebarProfilesExpanded = !state.ui.sidebarProfilesExpanded
    }
  },
  extraReducers: builder => {
    builder
      .addCase(setComposerProfiles, (state, action) => {
        state.ui.selectedProfiles = action.payload
      })
      .addCase(startImpersonation, (state) => {
        const initial = initialState()
        state.autoPostSlots = initial.autoPostSlots
        state.ui = initial.ui
        state.buckets = initial.buckets
        state.bucketPresets = initial.bucketPresets
      })
      .addCase(createPostSuccess, (state) => {
        state.newPost = Date.now().toString()
      })
      .addCase(getPostSlotsSuccess, (state, action) => {
        const newSlots = action.payload.slots.sort(sortByKeyAscending('time'))
        const slots = action.payload.page === 0 ? newSlots : [...state.autoPostSlots, ...newSlots]
        state.autoPostSlots = slots
      })
      .addCase(getDestinationsSuccess, (state, action) => {
        // NOTE: If any of the currently selected profiles is not connected, reset selection to default profiles
        const currentSelectedProfileIds = state.ui.selectedProfiles.map(p => p.id)
        const connectedProfileIds: string[] = []
        const defaultProfiles: PostDestination[] = []
        let selectedProfilesConnected = true

        for (const p of action.payload) {
          if (p.connected) {
            connectedProfileIds.push(p.id)
            if (p.default) {
              defaultProfiles.push(p)
            }
          }
        }

        for (const id of currentSelectedProfileIds) {
          if (!connectedProfileIds.includes(id)) {
            selectedProfilesConnected = false
            break
          }
        }

        if (selectedProfilesConnected) {
          return
        }

        state.ui.selectedListId = undefined
        state.ui.selectedProfiles = defaultProfiles
      })
      .addCase(getBuckets.fulfilled, (state, action) => {
        state.buckets = action.payload
      })
      .addCase(createBucket.fulfilled, (state, action) => {
        state.buckets = [...state.buckets, action.payload]
      })
      .addCase(updateBucket.fulfilled, (state, action) => {
        const bucket = action.payload.bucket
        const index = state.buckets.findIndex(b => b.id === bucket.id)
        if (index !== -1) {
          state.buckets.splice(index, 1, bucket)
        }
      })
      .addCase(deleteBucket.fulfilled, (state, action) => {
        state.buckets = state.buckets.filter(b => b.id !== action.payload)
      })
      .addCase(reorderBuckets.fulfilled, (state, action) => {
        state.buckets = state.buckets.map(bucket => ({ ...bucket, order: (action.payload as any).indexOf(bucket.id) }))
      })
      .addCase(getDefaultBuckets.fulfilled, (state, action) => {
        state.bucketPresets = action.payload
      })
      .addCase(getFailedPostsCount.pending, (state) => {
        state.failed.loading = true
      })
      .addCase(getFailedPostsCount.fulfilled, (state, action) => {
        let total = 0
        const byPpid: { [ppid: string]: number } = {}
        action.payload.data.forEach(item => {
          const count = parseInt(item.totalCount, 10)
          total += count
          byPpid[item.ppPageId] = count
        })
        state.failed = {
          byPpid,
          total,
          loading: false
        }
      })
      .addCase(getFailedPostsCount.rejected, (state) => {
        state.failed = {
          byPpid: {},
          total: 0,
          loading: false
        }
      })
      .addCase(setSelectedProfiles, (state, action) => {
        const selectedProfilesNext = action.payload.profiles
        const plannedPostsView = state.ui.plannedPostsView

        state.ui.selectedProfiles = selectedProfilesNext
        state.ui.plannedPostsView = plannedPostsView
      })
  }
})

export const {
  setSelectedList,
  setActiveSidebarView,
  toggleEmptySlotsVisible,
  toggleVirtualPostsVisible,
  setPlannedPostsView,
  setCalendarViewMode,
  setWeekViewExpanded,
  setCalendarPostSize,
  setCalendarMonthStart,
  setCalendarWeekStart,
  setCalendarPostedVisible,
  toggleSidebarProfilesExpanded
} = postsSlice.actions
export { setSelectedProfiles }
export default postsSlice.reducer
