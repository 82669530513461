import {
  BRAND_FACEBOOK,
  BRAND_TWITTER,
  BRAND_INSTAGRAM,
  BRAND_PINTEREST,
  BRAND_LINKEDIN,
  BRAND_REDDIT
} from './SocialBrand'

export const FEED_TYPE_FACEBOOK = BRAND_FACEBOOK
export const FEED_TYPE_TWITTER = BRAND_TWITTER
export const FEED_TYPE_INSTAGRAM = BRAND_INSTAGRAM
export const FEED_TYPE_PINTEREST = BRAND_PINTEREST
export const FEED_TYPE_LINKEDIN = BRAND_LINKEDIN
export const FEED_TYPE_REDDIT = BRAND_REDDIT
export const FEED_TYPE_RSS = 'rss'
export const FEED_TYPE_KEYWORD = 'keywords'
export const FEED_TYPE_KEYWORD_ALIAS = 'trending'
export const FEED_TYPE_STATUS_IDEAS = 'status-ideas'

export const FEED_TYPE_UNSUPPORTED = 'unsupported'

export type ContentFeedType = typeof FEED_TYPE_FACEBOOK
  | typeof FEED_TYPE_TWITTER
  | typeof FEED_TYPE_INSTAGRAM
  | typeof FEED_TYPE_PINTEREST
  | typeof FEED_TYPE_LINKEDIN
  | typeof FEED_TYPE_RSS
  | typeof FEED_TYPE_KEYWORD
  | typeof FEED_TYPE_UNSUPPORTED
  | typeof FEED_TYPE_STATUS_IDEAS
  | typeof FEED_TYPE_REDDIT

export default ContentFeedType
