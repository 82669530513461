import React from 'react'
import styles from './CreateUpdatePlanPopup.pcss'
import { useDispatch, useSelector } from 'react-redux'
import { sidebarActiveViewSelector } from 'services/post/selectors'
import { connectedDestinationsSelector } from 'services/destinations'
import { setActiveSidebarView } from 'services/post'
import { FormattedMessage, useIntl } from 'react-intl'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import SidebarProfileSelector from '../SidebarProfileSelector'
import PPSelect from 'components/PPSelect'
import { PostingPlan } from 'interfaces'
import TextField from '@mui/material/TextField'
import { message } from 'services/snackbar'
import PlanProfileSelector from 'routes/plan/components/PlanHeader/PlanProfileSelector'
import ProfileAvatar from 'components/ProfileAvatar'

const MAX_PLAN_NAME_LENGTH = 30

interface CreatePlanPopupProps {
  plan: { id: string, name: string, ppids: string[] } | null
  // plans: Record<string, { name: string, ppids: string[], isPaused: boolean, isDefault: boolean }>
  onClose: () => void
  onCreate: (profileIds: string[], name: string, copyFrom?: string) => Promise<any>
  onUpdate: (id: string, name: string, addedPpids?: string[], copyFrom?: string) => Promise<any>
}

export function CreateUpdatePlanPopup({ plan, onClose, onCreate, onUpdate }: CreatePlanPopupProps) {
  const intl = useIntl()
  const dispatch = useDispatch()
  const postSelectorView = useSelector(sidebarActiveViewSelector)
  const profiles = useSelector(connectedDestinationsSelector)
  const ppidToId = React.useMemo(() => Object.values(profiles).reduce((acc, profile) => {
    acc[profile.ppid] = profile.id
    return acc
  }, {} as Record<string, string>), [profiles])
  const planProfiles = plan?.ppids.map(ppid => profiles[ppidToId[ppid]]).filter(Boolean) || []
  const [selectedNewPlanProfilesIds, setSelectedNewPlanProfilesIds] = React.useState<string[]>([])
  const [addedProfilePpids, setAddedProfilePpids] = React.useState<string[]>([])
  const addedProfiles = addedProfilePpids.map(ppid => profiles[ppidToId[ppid]]).filter(Boolean)
  // const [copyFromPlanId, setCopyFromPlanId] = React.useState<string>('none')
  const [name, setName] = React.useState('')
  const [loading, setLoading] = React.useState<boolean>(false)

  const setProfileSelectorView = (view: 'grid' | 'list' | 'network') => {
    dispatch(setActiveSidebarView(view))
  }

  React.useEffect(() => {
    if (!open) {
      setSelectedNewPlanProfilesIds([])
    }
  }, [open])

  React.useEffect(() => {
    if (plan) {
      setName(plan.name)
    }
  }, [plan])

  const submit = () => {
    const planName = name.trim()
    if (planName.length > MAX_PLAN_NAME_LENGTH) {
      dispatch(message('Please choose a shorter plan name. Limit is 30 characters.', 'warning'))
      return
    }
    // const copyFrom = copyFromPlanId === 'none' ? undefined : copyFromPlanId
    const ppids = selectedNewPlanProfilesIds.map(id => profiles[id]?.ppid).filter(Boolean) as string[]
    setLoading(true)
    if (plan) {
      onUpdate(plan.id, planName, addedProfilePpids.length > 0 ? addedProfilePpids : undefined).finally(() => {
        setLoading(false)
        onClose()
      })
    } else {
      onCreate(ppids, planName).finally(() => {
        setLoading(false)
        onClose()
      })
    }
  }

  const selectAll = () => {
    setSelectedNewPlanProfilesIds(Object.keys(profiles))
  }

  // const copyFromOptions = {
  //   none: { label: 'None' },
  //   ...Object.keys(plans).reduce((acc, id) => {
  //     acc[id] = { label: plans[id].name }
  //     return acc
  //   }, {} as { [id: string]: { label: string } })
  // }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const title = plan ? 'Edit plan' : 'Create a new posting plan'

  return (
    <Dialog open maxWidth="md" classes={{ paper: styles.container }} onClose={onClose}>
      <div className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
      </div>
      <div className={styles.section}>
        <TextField
          value={name}
          placeholder={intl.formatMessage({ id: 'plan.placeholder.plan-name' })}
          classes={{ root: styles['input-box'] }}
          onChange={onNameChange}
        />
      </div>
      <div className={styles.section}>
        {plan ? (
          <div>
            <div className={styles.subtitle}><FormattedMessage id="general.labels.profiles" /></div>
            <div className={styles['profiles-list']}>
              {planProfiles.concat(addedProfiles).map(p => (
                <div key={p.id} className={styles['avatar-box']}>
                  <ProfileAvatar
                    key={p.id}
                    brand={p.type}
                    src={p.image}
                    title={p.name}
                    circle
                  />
                </div>
              ))}
              <PlanProfileSelector
                currentSelectedIds={plan.ppids.map(ppid => ppidToId[ppid]).filter(Boolean) as string[]}
                planName={plan.name}
                onConfirm={setAddedProfilePpids}
              />
            </div>
            {addedProfilePpids.length > 0 && (
              <section className={`${styles.section} ${styles.warning}`}>
                {`Warning: this action will (1) remove these profiles from their current posting plans immediately and `
                  + `(2) cause their posts to start following ${name || 'this plan'} immediately.`}
              </section>
            )}
          </div>
        ) : (
          <React.Fragment>
            <p><FormattedMessage id="general.labels.profiles" /></p>
            <SidebarProfileSelector
              actionsClassName={styles['actions-profiles']}
              contentClassName={styles['profiles-box']}
              profiles={profiles}
              activeView={postSelectorView}
              selectedProfileIds={selectedNewPlanProfilesIds}
              multipleProfileSelection
              onSelectedProfilesChange={setSelectedNewPlanProfilesIds}
              setActiveView={setProfileSelectorView}
            />
          </React.Fragment>
        )}
      </div>
      {/* <div className={`${styles.section} ${styles.bottom}`}>
        <div className={styles.subtitle}>Posting times</div>
        <PPSelect
          name="Copy from"
          raised
          selectedValue={copyFromPlanId}
          options={copyFromOptions}
          onSelectionChange={setCopyFromPlanId}
        />
      </div> */}
      <div className={styles.actions}>
        {!plan && (
          <span role="button" className={styles['btn-select']} onClick={selectAll}>
            <FormattedMessage id="actions.select-all" />
          </span>
        )}
        <Button onClick={onClose}><FormattedMessage id="actions.cancel" /></Button>
        <Button
          color="primary"
          variant="contained"
          disabled={loading || !name.trim() || (!plan && selectedNewPlanProfilesIds.length === 0)}
          onClick={submit}
        >
          <FormattedMessage id={`plan.actions.${plan ? 'update' : 'create'}-plan`} />
        </Button>
      </div>
    </Dialog>
  )
}
