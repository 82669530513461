import * as React from 'react'
import Button from '@mui/material/Button'
import styles from './MultiImageUpload.pcss'
import { ConnectedFileInput } from 'components/FileHelpers/ConnectedFileInput'
import { INPUT_ACCEPT_PHOTO, ALLOWED_PHOTO_FORMATS } from 'utils/file'
import { IndexedObject } from 'interfaces'
import { FormattedMessage, useIntl } from 'react-intl'
import { noop } from 'utils/noop'
import { useDispatch } from 'react-redux'
import { message } from 'services/snackbar'
import { bytesToSize } from 'utils/format/byteToSize'
import { MAX_PHOTO_SIZE_BYTES } from 'utils/file/constants'
import { NOTIFICATION_DURATION_LONG } from 'components/ConnectedSnackbar'
import { NavLink } from 'react-router-dom'

export function MultiImageUpload(props: { onPhotosAdded: (photos: IndexedObject<{ file: File }>) => void }) {
  const dispatch = useDispatch()
  const intl = useIntl()
  const maxSizeFormatted = bytesToSize(MAX_PHOTO_SIZE_BYTES)

  const cancelWindowDragEvents = (e: Event) => {
    e.preventDefault()
  }

  React.useEffect(() => {
    window.addEventListener('dragover', cancelWindowDragEvents, false)
    window.addEventListener('drop', cancelWindowDragEvents, false)

    return () => {
      window.removeEventListener('dragover', cancelWindowDragEvents)
      window.removeEventListener('drop', cancelWindowDragEvents)
    }
  }, [])

  const onFilesDropped = (e: any) => {
    const files = e.dataTransfer.files
    const photosMap: IndexedObject<{ file: File, url?: string }> = {}
    let sizeLimitExceeded = false

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)
      if (ALLOWED_PHOTO_FORMATS.includes(file.type)) {
        photosMap[file.name] = { file }

        if (file.size > MAX_PHOTO_SIZE_BYTES) {
          sizeLimitExceeded = true
        }
      }
    }
    if (Object.keys(photosMap).length === 0) {
      dispatch(message(intl.formatMessage({ id: 'composer.multi-post.notifications.invalid-upload' }), 'warning'))
      return
    }
    if (sizeLimitExceeded) {
      dispatch(message(intl.formatMessage(
        { id: 'composer.multi-post.notifications.max-image-size' },
        { size: `${parseInt(maxSizeFormatted, 10)} ${maxSizeFormatted.split(' ')[1]}` }
      ), 'warning', NOTIFICATION_DURATION_LONG))
    }
    props.onPhotosAdded(photosMap)
  }

  const onPhotosSelected = (selection: { [name: string]: File }) => {
    let sizeLimitExceeded = false
    // Filter only photos
    const filtered = Object.values(selection).reduce((map: IndexedObject<{ file: File }>, file: File) => {
      if (ALLOWED_PHOTO_FORMATS.includes(file.type)) {
        map[file.name] = { file }
        if (file.size > MAX_PHOTO_SIZE_BYTES) {
          sizeLimitExceeded = true
        }
      }
      return map
    }, {})

    if (Object.keys(filtered).length === 0) {
      dispatch(message(intl.formatMessage({ id: 'composer.multi-post.notifications.invalid-upload' }), 'warning'))
      return
    }
    if (sizeLimitExceeded) {
      dispatch(message(intl.formatMessage(
        { id: 'composer.multi-post.notifications.max-image-size' },
        { size: `${parseInt(maxSizeFormatted, 10)} ${maxSizeFormatted.split(' ')[1]}` }
      ), 'warning', NOTIFICATION_DURATION_LONG))
    }
    props.onPhotosAdded(filtered)
  }

  return (
    <div className={styles.container} data-testid="multi-image-upload">
      <div className={styles['content-box']}>
        <div className={styles['drop-target']} onDrop={onFilesDropped}>
          <p><FormattedMessage id="composer.labels.drag-drop-image" values={{ b: (...text) => <b>{text}</b> }} /></p>
          <p><FormattedMessage id="label.generic.or" /></p>
          <ConnectedFileInput
            accept={INPUT_ACCEPT_PHOTO.join(',')}
            className={styles.action}
            openFileDialog={noop}
            setUploaderFiles={onPhotosSelected}
          >
            <Button color="primary" variant="contained">
              <FormattedMessage id="composer.labels.upload-photos" />
            </Button>
          </ConnectedFileInput>
          <p><NavLink to="/content/library"><FormattedMessage id="composer.labels.bulk-upload-library-link" /></NavLink></p>
        </div>
      </div>
    </div>
  )
}

export default MultiImageUpload
