export interface LocationListing {
  id: string
  name: string
  url: string
}

export interface ListingsState {
  locationsUrl?: string
  reviewsUrl?: string
  reviewCampaignsUrl?: string
  locations: Array<LocationListing>
  locationsLimit: number
  upgradeLocationDialogOpen: boolean
}

export function initialState(): ListingsState {
  return {
    locationsLimit: 0,
    upgradeLocationDialogOpen: false,
    locations: []
  }
}

export default ListingsState
