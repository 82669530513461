import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import Accordion from '../Accordion'
import { useDispatch, useSelector } from 'react-redux'
import {
  activePostIdSelector,
  composerActiveTabSelector,
  composerBulkFileSelector,
  composerIsEmptySelector,
  composerNetworksViewSelector,
  ComposerPostStatus,
  composerPostStatusByNetworkSelector,
  composerResetKeySelector,
  composerTabsSelector,
  genericPostSelector,
  multiPostsCountSelector,
  useGenericStatusSelector
} from 'services/compose/selectors'
import Optimize from './Optimize'
import { IndexedObject, PostDestinationType, sortNetworksDefault } from 'interfaces'
import styles from './Network.pcss'
import Button from '@mui/material/Button'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import { setComposerActiveTab, setNetworksView, toggleGenericStatus } from 'services/compose'
import SocialIcon from 'components/SocialIcon'
import { NETWORKS_NAMES } from 'interfaces/User/PostDestinationType'
import IconArrowRight from '@mui/icons-material/TrendingFlat'
import ConfirmDialog from 'components/ConfirmDialog'
import Media from '../Content/Media'
import BulkUploadNetworkSettings from '../BulkUploadNetworkSettings/BulkUploadNetworkSettings'
import BulkPostsManager from '../BulkView/components/BulkPostsManager'
import { BRAND_YOUTUBE } from 'shared'

export function Network(props: { activeView: 'default' | 'bulk' }) {
  const isDefaultView = props.activeView === 'default'
  const dispatch = useDispatch()
  const bulkUploadFile = useSelector(composerBulkFileSelector)
  const multiPostsCount = useSelector(multiPostsCountSelector)
  // const view = useSelector(composerNetworksViewSelector)
  const activeTab = useSelector(composerActiveTabSelector)
  const isEditMode = Boolean(useSelector(activePostIdSelector))
  const networks = useSelector(composerTabsSelector)
  const ordered = networks.sort(sortNetworksDefault)
  const statusByNetwork: IndexedObject<ComposerPostStatus> = useSelector(composerPostStatusByNetworkSelector)
  const isEmpty = useSelector(composerIsEmptySelector)
  const useGenericStatus = useSelector(useGenericStatusSelector)
  const genericStatus = useSelector(genericPostSelector).status.text
  const resetKey = useSelector(composerResetKeySelector)
  const [isOpen, setIsOpen] = React.useState(isEmpty)
  const [captionSwitchVisible, setCaptionSwitchVisible] = React.useState(false)

  const isUsingGenericStatus = React.useRef(useGenericStatus)

  React.useEffect(() => {
    setCaptionSwitchVisible(false)
  }, [resetKey])

  React.useEffect(() => {
    isUsingGenericStatus.current = useGenericStatus
  }, [useGenericStatus])

  React.useEffect(() => {
    if (genericStatus && !isUsingGenericStatus.current) {
      setCaptionSwitchVisible(true)
    }
  }, [genericStatus])

  const onSectionExpandedToggle = (nextValue: boolean) => {
    setIsOpen(nextValue)
  }

  React.useEffect(() => {
    setIsOpen(isEditMode || !isDefaultView)
  }, [resetKey, isDefaultView, isEditMode])

  React.useEffect(() => {
    if (!isEmpty) {
      setIsOpen(true)
    }
  }, [isEmpty])

  const captionSwitchOptions: PPSelectOptions = {
    generic: { label: <FormattedMessage id="composer.labels.switch-caption.generic" /> },
    custom: { label: <FormattedMessage id="composer.labels.switch-caption.custom" /> }
  }

  const onViewChange = (view: 'list' | 'tabs') => {
    dispatch(setNetworksView(view))
  }

  const onActiveTabChange = (tab: PostDestinationType) => {
    dispatch(setComposerActiveTab(tab))
    setIsOpen(true)
  }

  const onToggleCaptionSwitch = () => {
    dispatch(toggleGenericStatus())
    setIsOpen(true)
  }

  // NOTE: Do not render empty section in bulk mode
  if (!isDefaultView && !bulkUploadFile && multiPostsCount === 0) {
    return null
  }

  // TEMP: disable view select:
  // const withViewSelect = networks.length > 1 && (multiPostsCount > 0 || (!bulkUploadFile && !isEditMode && isOpen))

  return (
    <Accordion
      key={resetKey}
      title={<FormattedMessage id={isEditMode ? 'composer.titles.post-edit' : 'composer.titles.optimize'} />}
      order={isEditMode ? '1' : '3'}
      open={isOpen}
      id={isEditMode ? 'composer-optimize-edit' : 'composer-optimize'}
      contentClassName={styles['acc-content']}
      headerElements={(
        <div className={styles['header-content']}>
          {!bulkUploadFile && !isEditMode && Boolean(genericStatus) && !useGenericStatus && captionSwitchVisible && (
            <div className={styles['switch-box']}>
              <ConfirmDialog
                message={(
                  <div>
                    <h3><FormattedMessage id="composer.prompt.reset-caption" /></h3>
                    <div><FormattedMessage id="composer.prompt.reset-caption-subtitle" /></div>
                  </div>
                )}
                labelOK={<FormattedMessage id="actions.confirm" />}
              >
                {(confirm) => (
                  <PPSelect
                    name={<FormattedMessage id="label.generic.caption" />}
                    options={captionSwitchOptions}
                    selectedValue={useGenericStatus ? 'generic' : 'custom'}
                    withCaret
                    className={`${styles.switch} ${useGenericStatus ? '' : styles.highlight}`}
                    onSelectionChange={!useGenericStatus ? confirm(onToggleCaptionSwitch) : onToggleCaptionSwitch}
                  />
                )}
              </ConfirmDialog>
            </div>
          )}
          {/* TEMP: disable view select {withViewSelect && (
            <PPSelect
              name={<FormattedMessage id="general.labels.view" />}
              selectedValue={view}
              options={{
                list: { label: <FormattedMessage id="general.labels.list" /> },
                tabs: { label: <FormattedMessage id="general.labels.tabs" /> }
              }}
              withCaret
              onSelectionChange={onViewChange}
            />
          )}
          {view === 'tabs' && withViewSelect && (
            <div className={styles.tabs}>
              <IconArrowRight className={styles.arrow} />
              {ordered.map(network => (
                <NetworkButton
                  small
                  key={network}
                  network={network}
                  hideStatus={!isDefaultView}
                  error={!isEmpty && statusByNetwork[network].hasErrors}
                  warning={!isEmpty && statusByNetwork[network].hasHints}
                  active={activeTab === network}
                  onClick={onActiveTabChange}
                />
              ))}
            </div>
          )} */}
        </div>
      )}
      onToggle={onSectionExpandedToggle}
    >
      {isDefaultView ? (
        <div>
          {ordered.map(network => (
            <Optimize key={network} network={network} contentElement={isEditMode && <Media />} />
          ))}
          {/* TEMP: disable view select {view === 'list' && ordered.map(network => (
            <Optimize key={network} network={network} contentElement={isEditMode && <Media />} />
          ))}
          {view === 'tabs' && (
            <Optimize key={activeTab} network={activeTab} contentElement={isEditMode && <Media />} />
          )} */}
        </div>
      ) : (
        <React.Fragment>
          {multiPostsCount > 0 && (
            <BulkPostsManager />
          )}
          {bulkUploadFile && (
            <BulkUploadNetworkSettings />
          )}
        </React.Fragment>
      )}
    </Accordion>
  )
}

function NetworkButton({ network, active, error, warning, small, hideStatus, onClick }: {
  network: PostDestinationType,
  active: boolean,
  error: boolean,
  warning: boolean,
  hideStatus: boolean,
  small?: boolean,
  onClick: (network: PostDestinationType) => void
}) {
  const click = () => {
    onClick(network)
  }

  return (
    <Button
      variant="text"
      className={`${styles.btn} ${active ? styles.active : ''} ${small ? styles.small : ''}`}
      classes={{ startIcon: styles['start-icon'] }}
      startIcon={(
        <SocialIcon className={styles.icon} icon={network} size={network === BRAND_YOUTUBE ? '20px' : '16px'} outline />
      )}
      onClick={click}
    >
      {!small && NETWORKS_NAMES[network]}
      {!hideStatus && error && <div className={`${styles.badge} ${styles.error}`}></div>}
      {!hideStatus && !error && warning && <div className={`${styles.badge} ${styles.warning}`}></div>}
    </Button>
  )
}

export default Network
