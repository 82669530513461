export interface TrialTask {
  code: string
  groupCode: string
  order: number
  value: number
  maxValue: number
  earningsMax: number
  discount: number
  videoUrl: string | null
  completed: boolean
}

export interface TrialTasksGroup {
  code: string
  order: number
  tasks: TrialTask[]
}

export const EVENT_ACTIVATE_PROFILES = 'activate_social_profile'
export const EVENT_SAVE_STREAM = 'save_stream'
export const EVENT_ADD_BUCKET = 'add_post_to_bucket'
export const EVENT_QUEUE_POSTS = 'create_a_post'
export const EVENT_SETUP_PLAN = 'setup_plan'
