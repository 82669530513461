import * as React from 'react'
import {
  ALL_FILTER,
  ARTICLE_TYPE,
  GIF_TYPE,
  IndexedObject,
  PHOTO_TYPE,
  STATUS_TYPE,
  VIDEO_TYPE
} from 'interfaces'
import { TEXT_TYPE } from 'interfaces/Content/ContentType'
import { STOCK_GIF_TYPE, STOCK_PHOTO_TYPE, STOCK_VIDEO_TYPE } from 'interfaces/Content/StockContentTypes'
import { FormattedMessage } from 'react-intl'
import PPSelect, { PPSelectOptions } from 'components/PPSelect'
import IconAll from '@mui/icons-material/DoneAll'
import PhotoIcon from '@mui/icons-material/Image'
import ArticleIcon from '@mui/icons-material/Link'
import VideoIcon from '@mui/icons-material/Videocam'
import GifIcon from '@mui/icons-material/Gif'
import styles from './ContentTypeFilter.pcss'
import TextIcon from '@mui/icons-material/CommentOutlined'

const FILTERS = [
  STOCK_PHOTO_TYPE,
  STOCK_VIDEO_TYPE,
  STOCK_GIF_TYPE,
  VIDEO_TYPE,
  PHOTO_TYPE,
  ARTICLE_TYPE,
  STATUS_TYPE,
  GIF_TYPE,
  TEXT_TYPE
]

interface ContentTypeFilterProps {
  options: string[]
  selected?: string
  all?: boolean
  renderSingle?: boolean
  className?: string
  onFilterSelected(filter: string): void
}

export function ContentTypeFilter(props: ContentTypeFilterProps) {
  const icons: IndexedObject<React.ReactNode> = {
    [PHOTO_TYPE]: <PhotoIcon className={styles.icon} />,
    [STOCK_PHOTO_TYPE]: <PhotoIcon className={styles.icon} />,
    [ARTICLE_TYPE]: <ArticleIcon className={styles.icon} />,
    [VIDEO_TYPE]: <VideoIcon className={styles.icon} />,
    [STOCK_VIDEO_TYPE]: <VideoIcon className={styles.icon} />,
    [GIF_TYPE]: <GifIcon className={styles.icon} />,
    [TEXT_TYPE]: <TextIcon className={styles.icon} />,
    [STOCK_GIF_TYPE]: <GifIcon className={styles.icon} />
  }
  const visibleOptions: PPSelectOptions = React.useMemo(() => {
    const options: PPSelectOptions = {}
    if (props.all) {
      options[ALL_FILTER] = {
        label: (
          <span className={styles.label}>
            <IconAll />
            <FormattedMessage id="label.generic.all" />
          </span>
        )
      }
    }

    FILTERS.filter(f => props.options.includes(f)).forEach(filter => {
      options[filter] = {
        label: (
          <span className={styles.label}>
            {icons[filter]}
            <FormattedMessage id={`general.content.filters.${filter}`} />
          </span>
        )
      }
    })

    return options
  }, [props.all, props.options])

  if (!props.all && (props.options.length === 1 && !props.renderSingle)) {
    return null
  }

  return (
    <PPSelect
      options={visibleOptions}
      selectedValue={props.selected || PHOTO_TYPE}
      className={props.className || ''}
      withCaret
      onSelectionChange={props.onFilterSelected}
    />
  )
}

export default ContentTypeFilter
