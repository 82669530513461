import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import styles from './VideoEditorDialog.pcss'
import { dataURLtoFile } from 'utils/file'
import CreativeEditorSDK from '@cesdk/cesdk-js'
import { CreativeEditor } from 'components/CreativeEditor'

const getFileName = () => `video-export-${Date.now()}.mp4`

interface VideoEditorDialogProps {
  open?: boolean
  /** render inline in the parent component instead of a dialog */
  inline?: boolean
  video?: string
  scene?: string
  exportFileName?: string
  editorClassName?: string
  hideExportButton?: boolean
  onExport: (file: File, scene?: string) => void
  onInit?: (sdk: CreativeEditorSDK) => void
  onClose?: () => void
}

export function VideoEditorDialog(props: VideoEditorDialogProps) {
  const { video, scene } = props
  const cesdk = React.useRef<CreativeEditorSDK>()

  const onExport = React.useCallback((blobs: Blob[]) => {
    const reader = new FileReader()
    reader.onload = () => {
      exportFile(reader.result as string)
    }
    reader.readAsDataURL(blobs[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const exportFile = async (dataUrl: string) => {
    const file = dataURLtoFile(dataUrl, props.exportFileName || getFileName())
    const scene = await cesdk.current?.engine.scene.saveToString()
    props.onExport(file, scene)
  }

  const onInit = React.useCallback((sdk: CreativeEditorSDK) => {
    cesdk.current = sdk
    if (props.onInit) {
      props.onInit(sdk)
    }
  }, [props.onInit])

  const configureEditor = React.useCallback(async (instance: CreativeEditorSDK) => {
    const engine = instance.engine
    if (typeof video === 'string') {
      await engine.scene.createFromVideo(video)
    }

    if (scene) {
      engine.scene.loadFromString(scene)
    }

    return Promise.resolve()
  }, [video, scene])

  const editor = <CreativeEditor
    containerClassName={`${styles.editor} ${props.editorClassName || ''}`}
    video
    configure={configureEditor}
    onInstanceChange={onInit}
    onExport={props.hideExportButton ? undefined : onExport}
  />

  if (props.inline) {
    return editor
  }

  return (
    <Dialog open={Boolean(props.open)} classes={{ paper: styles.dialog }} data-testid="video-editor-dialog" onClose={props.onClose}>
      <div className={styles['dialog-content']}>
        {editor}
      </div>
    </Dialog>
  )
}
