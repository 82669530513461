import * as React from 'react'
import styles from './CollapsibleTextInput.pcss'
import Icon from '@mdi/react'
import { mdiMagnify, mdiClose } from '@mdi/js'

interface CollapsibleTextInputProps {
  type?: string
  icon?: React.ReactNode
  changeOnEnter?: boolean
  className?: string
  activeClassName?: string
  initialActive?: boolean
  placeholder?: string
  actionButton?: React.ReactNode
  disabled?: boolean
  disableCollapse?: boolean
  id?: string
  onValueChanged: (value: string) => void
}

function CollapsibleTextInput(props: CollapsibleTextInputProps, ref: React.RefObject<HTMLInputElement>) {
  const [active, setActive] = React.useState(Boolean(props.initialActive))
  const [isFocused, setIsFocused] = React.useState(false)
  const fallbackRef = React.useRef<HTMLInputElement>()
  const inputRef = ref || fallbackRef

  const toggle = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
    if (props.disableCollapse && active) {
      return
    }
    setActive(current => !current)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (props.changeOnEnter && e.key === 'Enter') {
      const newValue = (e.target as HTMLInputElement).value.toLowerCase().trim()
      props.onValueChanged(newValue)
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!props.changeOnEnter) {
      props.onValueChanged(e.target.value)
    }
  }

  const activeClassName = React.useMemo(() => {
    if (!active) {
      return ''
    }
    return `${styles.active} ${props.activeClassName || ''}`
  }, [active, props.activeClassName])

  const disabledClass = props.disabled ? styles.disabled : ''

  const focus = () => {
    if (active) {
      inputRef.current?.focus()
    }
  }

  const onFocus = () => {
    setIsFocused(true)
  }

  const onBlur = () => {
    setIsFocused(false)
  }

  return (
    <div
      className={`${styles.box} ${activeClassName} ${disabledClass} ${isFocused ? styles.focus : ''} ${props.className || ''}`}
      onTransitionEnd={focus}
      data-testid={props.id}
    >
      {props.icon || (
        <div className={styles.toggle} onClick={toggle}>
          <Icon path={mdiMagnify} color="rgba(0, 0, 0, 0.54)" size="20px" />
        </div>
      )}
      <input
        ref={inputRef}
        type={props.type || 'text'}
        className={styles.input}
        placeholder={props.placeholder}
        disabled={props.disabled}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {props.actionButton && (
        <div className={styles.action}>
          {props.actionButton}
        </div>
      )}
    </div>
  )
}

export default React.forwardRef(CollapsibleTextInput)
