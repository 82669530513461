import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'

export const V1 = Object.freeze({
  getTags: function () {
    const builder = new RequestBuilder(`${LEGACY_URL}/v3/AI_completions`).asPost().expectJSON()

    return function (text?: string, link?: string) {
      const data: FormData = new FormData()
      if (text) {
        data.append('post_status', text)
      }
      if (link) {
        data.append('post_link', link)
      }
      return authorizedPipe<any>(
        builder
          .form(data)
          .param('feature', 'tags')
          .build()
      )
    }
  }()
})
