import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CircularProgress from '@mui/material/CircularProgress'
import styles from './PostFilesDialog.pcss'
import { getImagesUrlsMap } from 'shared'

const LOADER_SIZE = 40
export interface PostFilesDialogProps {
  active: boolean
  images: string[]
  onClose: () => void
}

export function PostFilesDialog(props: PostFilesDialogProps) {
  const urls = React.useMemo(() => getImagesUrlsMap(props.images), [props.images])
  const urlsRef = React.useRef(urls)

  React.useEffect(() => {
    if (props.images.length > 0) {
      urlsRef.current = urls
    }
  }, [props.images.length, urls])

  // EXPL: This is needed to keep the current images displayed while closing the dialog
  const visibleImages = React.useMemo(() => {
    return props.active ? urls : urlsRef.current
  }, [props.active, urls])

  const onError = (e: any) => {
    if (e.target.src !== urls[e.target.src]) {
      e.target.src = urls[e.target.src]
    }
  }

  return (
    <Dialog open={props.active} onClose={props.onClose} classes={{ paper: styles.dialog }}>
      <IconButton className={styles['btn-close']} onClick={props.onClose} size="large">
        <CloseIcon />
      </IconButton>
      <div className={styles.content}>
        <div className={styles.grid}>
          {Object.keys(visibleImages).map((image, index) => (
            <div className={styles['grid-item']} key={index}>
              <CircularProgress size={LOADER_SIZE} className={styles.loader} />
              <img src={image} className={styles.image} onError={onError} />
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  )
}

export default PostFilesDialog
