import { StoreThunkDispatch } from 'store/state'
import { V1 } from './net'

export function toggleExternalPostsVisible(ppid: string) {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.toggleExternalPosts(ppid))
}

export function forceFetchExternalPosts(ppid: string) {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.forceFetchExternalPosts(ppid))
  }
}
