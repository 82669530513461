import * as React from 'react'
import Brandify, { BrandifyProps } from '../Brandify'
import SquareAvatar, { SquareAvatarProps } from '../SquareAvatar'
import IconCheck from '@mui/icons-material/Check'
import IconWarning from '@mui/icons-material/WarningRounded'
import IconDelete from '@mui/icons-material/Close'
import { withTooltip } from 'components/App/withTooltip'
import styles from './ProfileAvatar.pcss'

export const BrandifiedAvatar = Brandify<SquareAvatarProps>(SquareAvatar as any)
export const ProfileAvatar = withTooltip(BrandifiedAvatar)

interface SelectableAvatarOwnProps {
  selected: boolean
  notSelectable?: boolean
  error?: boolean
  radio?: boolean
}

type SelectableAvatarProps = SelectableAvatarOwnProps & SquareAvatarProps & BrandifyProps & { title: string, badgeSrc?: string }

export function SelectableAvatar(props: SelectableAvatarProps) {
  const { selected, radio, error, notSelectable, badgeSrc, className, ...avatarProps } = props
  const selectableClass = notSelectable ? styles.blank : ''
  const selectedClass = props.selected && !props.error ? styles.selected : ''
  const errorClass = props.error ? styles.error : ''

  return (
    <div
      className={`${styles.box} ${props.radio ? styles.radio : ''}`}
      data-testid="selectable-avatar"
      data-selected={selected}
      data-network={props.brand}
    >
      <span className={`${styles.badge} ${selectedClass} ${errorClass} ${selectableClass}`}>
        {!props.radio && !error && <IconCheck className={styles.icon} />}
        {error && <IconWarning className={`${styles.icon} ${styles['icon-error']}`} />}
      </span>
      {badgeSrc && (
        <div className={styles['badge-secondary']} style={{ backgroundImage: `url(${badgeSrc})` }}></div>
      )}
      <ProfileAvatar {...avatarProps} className={`${styles.avatar} ${selected ? styles['avatar-highlight'] : ''} ${className || ''}`} />
    </div>
  )
}

type ProfileAvatarProps = SquareAvatarProps & BrandifyProps & { title: string }
interface ProfileAvatarWithDeleteProps extends ProfileAvatarProps {
  profileId: string
  disableDelete?: boolean
  onDelete: (profileId: string) => void
}

export function ProfileAvatarWithDelete(props: ProfileAvatarWithDeleteProps) {
  const { profileId, onDelete, disableDelete, ...profileAvatarProps } = props
  const onDeleteClick = () => {
    onDelete(props.profileId)
  }

  return (
    <div className={styles.box}>
      {!props.disableDelete && (
        <IconDelete
          className={styles['btn-delete']}
          onClick={onDeleteClick}
        />
      )}
      <ProfileAvatar {...profileAvatarProps} />
    </div>
  )
}

export default ProfileAvatar
