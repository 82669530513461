import React from 'react'
import { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { PPProduct } from 'interfaces'
import capitalize from 'utils/format/capitalize'
import { CURRENCY_SIGN_MAP } from 'shared/constants'

interface ProductSelectProps {
  products: PPProduct[]
  currency: string
  selectedProduct: { handle: string, isAnnual: boolean }
  hiddenProductHandles?: string[]
  className?: string
  selectClassName?: string
  menuItemClassName?: string
  MenuProps?: Partial<MenuProps>
  onSelectionChange: (data: { handle: string, isAnnual: boolean }) => void
}

export function ProductSelect(props: ProductSelectProps) {
  const { currency } = props
  const value = JSON.stringify(props.selectedProduct)

  const onChange = (e: SelectChangeEvent) => {
    const data = JSON.parse(e.target.value)
    props.onSelectionChange(data)
  }

  return (
    <Select
      value={value}
      displayEmpty
      disableUnderline
      onChange={onChange}
      className={props.className}
      MenuProps={props.MenuProps}
      classes={{ select: props.selectClassName }}
      data-testid="product-select"
    >
      {props.products.map(product => {
        const { name, price, handles, order, id } = product
        // eslint-disable-next-line no-magic-numbers
        const annualPricePerMonth = (price.annual[currency] / 12).toFixed(2).replace('.00', '')
        const monthlyPrice = price.monthly[currency].toFixed(2).replace('.00', '')
        const isFree = order === 0
        const hiddenMonthly = props.hiddenProductHandles?.includes(handles.monthly)
        const hiddenAnnual = props.hiddenProductHandles?.includes(handles.annual)

        if (isFree) {
          const value = JSON.stringify({ handle: handles.monthly, isAnnual: false })
          return (
            <MenuItem
              key={handles.monthly}
              value={value}
              classes={{ root: props.menuItemClassName }}
              style={hiddenMonthly ? { display: 'none' } : undefined}
              data-testid={id}
            >
              <b>{capitalize(name)}</b>
            </MenuItem>
          )
        }

        return ([
          <MenuItem
            key={handles.annual}
            value={JSON.stringify({ handle: handles.annual, isAnnual: true })}
            classes={{ root: props.menuItemClassName }}
            style={hiddenAnnual ? { display: 'none' } : undefined}
            data-testid={`${id}-annual`}
          >
            <b>{capitalize(name)} annual&nbsp;</b>
            {`(${CURRENCY_SIGN_MAP[currency]}${annualPricePerMonth}/mo${' billed yearly'})`}
          </MenuItem>,
          <MenuItem
            key={handles.monthly}
            value={JSON.stringify({ handle: handles.monthly, isAnnual: false })}
            classes={{ root: props.menuItemClassName }}
            style={hiddenMonthly ? { display: 'none' } : undefined}
            data-testid={id}
          >
            <b>{capitalize(name)}&nbsp;</b>
            {`(${CURRENCY_SIGN_MAP[currency]}${monthlyPrice}/mo${' billed monthly'})`}
          </MenuItem>
        ])
      })}
    </Select>
  )
}

export default ProductSelect
