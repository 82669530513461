import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import useAsyncAction from 'hooks/useAsyncAction'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { validateCouponCode, validateReferralCode } from 'services/checkout'
import { message } from 'services/snackbar'
import { StoreThunkDispatch } from 'store/state'
import styles from './PromoCodeValidator.pcss'
import { SignupContext } from '../state/context'
const css = require('styles/variables')

export type PromoCode = {
  value: string
  isValid: boolean | null
  discountAmount?: number
  discountPercentage?: number
}

interface PromoCodeValidatorProps {
  value: string
  type: 'coupon' | 'referral'
  productHandle: string
  isValid: boolean | null
  disabled: boolean
  onValueChange: (value: string) => void
  onValidationDone: (code: PromoCode) => void
}

export function PromoCodeValidator(props: PromoCodeValidatorProps) {
  const { onValidationDone, disabled } = props
  const dispatch = useDispatch() as StoreThunkDispatch
  const [{ paymentViewConfig }] = useContext(SignupContext)
  const [statusMessage, setStatusMessage] = useState<string | undefined>(undefined)

  const validate = React.useCallback(() => {
    if (props.type === 'coupon') {
      return dispatch(validateCouponCode(props.value, props.productHandle))
    }
    return dispatch(validateReferralCode(props.value))
  }, [props.type, props.value, props.productHandle, dispatch])
  const [triggerValidation, response, error, loading] = useAsyncAction(validate)

  useEffect(() => {
    if (response) {
      onValidationDone({
        value: response.code,
        isValid: response.result === 'valid',
        discountAmount: response.discountAmount,
        discountPercentage: response.discountPercentage
      })
    }
  }, [onValidationDone, response])

  useEffect(() => {
    if (error) {
      dispatch(message('An error ocurred. Please try again or submit a support request.', 'error'))
    }
  }, [error, dispatch])

  useEffect(() => {
    if (typeof props.isValid === 'boolean') {
      setStatusMessage(`Code is ${props.isValid ? 'valid' : 'invalid'}`)
    } else {
      setStatusMessage(undefined)
    }
  }, [props.isValid])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onValueChange(e.target.value)
    setStatusMessage(undefined)
  }

  const withError = props.isValid === false

  return (
    <div className={disabled ? styles.dim : ''} data-testid="promocode-input">
      <div
        className={styles.label}
        style={paymentViewConfig.inputLabelStyles}
      >
        {props.type === 'coupon' ? 'Coupon Code' : 'Referral Code'}
      </div>
      <div className={styles['code-box']}>
        <div className={styles.left}>
          <TextField
            value={props.value}
            variant="outlined"
            size="small"
            disabled={loading || disabled}
            helperText={statusMessage}
            sx={{ '& .MuiFormHelperText-root': { color: withError ? css['--alert-red'] : css['--pp-green'] } }}
            onChange={onChange}
          />
        </div>
        <div className={styles.actions}>
          <Button
            disabled={loading || Boolean(props.isValid) || disabled}
            color="primary"
            variant="contained"
            className={styles.btn}
            onClick={triggerValidation}
          >
            Validate
          </Button>
        </div>
      </div>
    </div>
  )
}
