import * as React from 'react'
import { WithIntl } from 'interfaces'
import styles from './MediaPreview.pcss'
import IconButton from '@mui/material/IconButton'
import { ALLOWED_PHOTO_FORMATS, ALLOWED_VIDEO_FORMATS } from 'utils/file'
import StoreState, { StoreThunkDispatch } from 'store/state'
import {
  composerImageUrlsSelector,
  composerIsUploadingSelector,
  composerResetKeySelector,
  composerVideoThumbnailSelector,
  composerVideoUrlSelector
} from 'services/compose/selectors'
import { message } from 'services/snackbar'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import IconClose from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import FileUpload from 'components/FileUpload'
import {
  addComposerImages,
  deleteFile,
  removeComposerFileUrl,
  setComposerVideoMetadata,
  setComposerVideoUrl,
  setFeaturedImage,
  setIsComposerUploading
} from 'services/compose'
import { useEffectUpdateOnly } from 'hooks/useEffectUpdateOnly'
import { PhotoEditorDialog } from 'components/PhotoEditor'
import {
  addFiles,
  ComposerUploaderContext,
  FileState,
  removeFile as removeUploaderFile,
  resetState,
  updateFileState,
  UploaderFilesState
} from '../../UploadContext'
import { Observable } from 'rxjs/Observable'
import { Subject } from 'rxjs/Subject'
import { catchError } from 'rxjs/operators/catchError'
import { FileInfo } from 'components/FileInfo'
import { UploaderVideoPreview } from './UploaderVideoPreview'
import { DndContext, useDroppable, useSensors, PointerSensor, useSensor } from '@dnd-kit/core'
import { SortableContext, rectSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { CSS } from '@dnd-kit/utilities'
import { reorder, sortByKeyAscending } from 'utils/sort/order'
import { FileUploadProps } from 'components/FileUpload/FileUpload'
import { VideoEditorDialog } from 'components/VideoEditorDialog'

interface ComposerUploaderOwnProps {
  className?: string
}

interface ComposerUploaderConnectedProps {
  imageUrls: string[]
  videoUrl?: string
  videoThumbnailUrl?: string
  resetKey: number
  isUploading: boolean
  setIsUploading: (value: boolean | 'video') => void
  message: (text: string, type?: any) => void
  addImageUrls: (urls: string[]) => void
  removeFile: (url: string) => void
  deleteUploadByUrl: (url: string) => Observable<any>
  setVideoUrl: (url: string) => void
  setFeaturedImageUrl: (url: string | undefined) => void
}

type ComposerUploaderProps = ComposerUploaderOwnProps & ComposerUploaderConnectedProps & WithIntl
type VideoEditState = {
  id?: string
  videoUrl?: string
  scene?: string
  editorOpen: boolean
}

export function MediaPreview(props: ComposerUploaderProps) {
  const { removeFile } = props
  const { files, dispatch } = React.useContext(ComposerUploaderContext)
  const [photoEditorImage, setPhotoEditorImage] = React.useState<FileState | null>(null)
  const [videoEdit, setVideoEdit] = React.useState<VideoEditState>({ editorOpen: false })
  const { setNodeRef: setDroppableNodeRef } = useDroppable({
    id: 'droppable'
  })
  const filesRef = React.useRef<UploaderFilesState>()
  const delete$ = React.useRef<Subject<string>>()

  const withFiles = Object.keys(files).length > 0
  const withVideoUpload = Object.values(files).find(f => f.isVideo)
  const order = React.useMemo(() => Object.values(files).sort(sortByKeyAscending('order')).map(f => f.id), [files])

  const photoEditorImageRef = React.useRef<FileState | null>()

  React.useEffect(() => {
    delete$.current = new Subject()
    delete$.current.flatMap((url: string) => props.deleteUploadByUrl(url).pipe(
      catchError(error => {
        console.log('[ComposerUploader] error deleting file', error)
        return Observable.of({})
      })
    ))
      .subscribe()

    return () => {
      delete$.current?.unsubscribe()
    }
  }, [])

  React.useEffect(() => {
    filesRef.current = files
    let uploading: any = false
    for (const file of Object.values(files)) {
      if (file.isUploading) {
        uploading = file.isVideo ? 'video' : true
        if (uploading === 'video') {
          break
        }
      }
    }

    if (props.isUploading !== uploading) {
      props.setIsUploading(uploading)
    }
  }, [files])

  // EXPL: Adds files to local upload context from outside - e.g. when user selects images from Content or Library
  React.useEffect(() => {
    const currentUrls = Object.values(filesRef.current || {}).map(f => f.url).filter(Boolean)
    const newFiles = props.imageUrls.filter(url => !currentUrls.includes(url)).map(url => ({ url }))
    if (newFiles.length > 0) {
      dispatch(addFiles(newFiles))
    }
  }, [props.imageUrls])

  // Clear files on composer reset
  useEffectUpdateOnly(() => {
    dispatch(resetState())
    Object.values(files).forEach(file => {
      if (file.url && file.upload) {
        delete$.current?.next(file.url)
      }
    })
  }, [props.resetKey])

  const onRemoveFile = (_fileName: string, fileId: string) => {
    dispatch(removeUploaderFile(fileId))
    const file = filesRef.current ? filesRef.current[fileId] : null
    if (!file) {
      return
    }

    if (file.upload && file.isUploading) {
      file.upload.abort(true)
    }

    if (file.url) {
      removeFile(file.url)

      if (file.upload) {
        delete$.current?.next(file.url)
      }
    }
  }

  const onFileUploaded = (url: string, file: File, fileId: string) => {
    const prevFile = filesRef.current ? filesRef.current[fileId] : null
    dispatch(updateFileState(fileId, { url, isUploading: false, error: undefined }))
    if (prevFile?.url) {
      removeFile(prevFile.url)
    }

    if (ALLOWED_PHOTO_FORMATS.includes(file.type)) {
      // EXPL: Delay updating global composer state to avoid race condition with file upload state.
      // UploadContext state must be updated first. Otherwise the image will be added twice.
      setTimeout(() => {
        props.addImageUrls([url])
        if (prevFile?.order === 1) {
          props.setFeaturedImageUrl(url)
        }
        // eslint-disable-next-line no-magic-numbers
      }, 50)
    } else if (ALLOWED_VIDEO_FORMATS.includes(file.type)) {
      props.setVideoUrl(url)
    }
  }

  const onUploadStarted = (upload: { abort: () => void }, id: string) => {
    dispatch(updateFileState(id, { upload, isUploading: true }))
  }

  const onUploadError = React.useCallback((_fileName: string, error: string, uploadId?: string) => {
    const file = uploadId ? files[uploadId] : null
    if (uploadId && file) {
      dispatch(updateFileState(uploadId, { error, isUploading: false }))
    }
  }, [files])

  const onEditFile = (fileId: string) => {
    const file = filesRef.current && filesRef.current[fileId]
    if (!file) {
      return
    }
    if (file.isVideo) {
      setVideoEdit({ scene: file.scene, videoUrl: file.url || '', id: fileId, editorOpen: true })
    } else {
      setPhotoEditorImage(file)
      photoEditorImageRef.current = file
    }
  }

  const closePhotoEditor = () => {
    setPhotoEditorImage(null)
    photoEditorImageRef.current = null
  }

  const onEditorError = (error: Error) => {
    console.log('photo editor error: ', error)
    props.message(props.intl.formatMessage({ id: 'general.media-editor.error' }), 'error')
    closePhotoEditor()
  }

  const onPhotoEditorExport = React.useCallback((file: File) => {
    const editorImg = photoEditorImageRef.current
    if (!editorImg) {
      return
    }

    const image = filesRef.current ? filesRef.current[editorImg.id] : editorImg

    if (image.url) {
      // If it's uploaded file, delete previous upload
      if (image.upload) {
        delete$.current?.next(editorImg.url)
      }
      removeFile(image.url)
    }
    dispatch(updateFileState(
      editorImg.id,
      { ...editorImg, upload: undefined, url: undefined, file, error: undefined, isEdited: true }
    ))
    closePhotoEditor()
  }, [removeFile, dispatch])

  const onVideoEditorExport = React.useCallback((file: File, scene?: string) => {
    const currentVid = Object.values(filesRef.current || {}).find(f => f.isVideo)
    const videoUrl = props.videoUrl || currentVid?.url
    if (videoUrl) {
      removeFile(videoUrl)
      delete$.current?.next(videoUrl)
    }
    setVideoEdit(current => ({ ...current, scene, editorOpen: false }))
    if (currentVid) {
      dispatch(updateFileState(currentVid.id, { file, scene, isEdited: true, error: undefined, url: undefined }))
    } else {
      dispatch(resetState())
      dispatch(addFiles([{ file, isVideo: true, scene }]))
    }
  }, [props.videoUrl, removeFile, dispatch])

  const onDragEnd = (event: any) => {
    const { active, over } = event
    if (over && (active.id !== over.id)) {
      const oldIndex = order.indexOf(active.id)
      const newIndex = order.indexOf(over.id)
      const reordered = reorder(order, oldIndex, newIndex)
      const featuredUrl = files[reordered[0]].url
      props.setFeaturedImageUrl(featuredUrl)

      reordered.forEach((id, index) => {
        dispatch(updateFileState(id, { ...files[id], order: index + 1 }))
      })
    }
  }

  const updateOrientation = React.useCallback((id: string, orientation: 'landscape' | 'portrait') => {
    dispatch(updateFileState(id, { orientation }))
  }, [dispatch])

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8
      }
    })
  )

  const openVideoEditor = () => {
    setVideoEdit({ scene: undefined, videoUrl: props.videoUrl || '', id: 'composer-video', editorOpen: true })
  }

  const closeVideoEditor = () => {
    setVideoEdit(current => ({ ...current, editorOpen: false }))
  }

  const removeVideo = (url: string) => {
    setVideoEdit({ editorOpen: false, videoUrl: '', scene: undefined })
    removeFile(url)
    delete$.current?.next(url)
  }

  return (
    <div className={`${styles.container} ${props.className || ''}`} data-testid="composer-media-preview">
      {withFiles && (
        <DndContext sensors={sensors} autoScroll={false} onDragEnd={onDragEnd}>
          <SortableContext items={order} strategy={rectSortingStrategy} modifiers={[restrictToParentElement]}>
            <div className={`${styles['files-grid']} ${order.length < 2 ? styles['no-featured'] : ''}`} ref={setDroppableNodeRef}>
              {order.map(id => {
                if (!files[id]) {
                  return null
                }
                const { file, url, isEdited, order: fileOrder } = files[id]
                if (file) {
                  return (
                    <FileGridItem
                      key={id}
                      data={files[id]}
                      isFeatured={fileOrder === 1}
                      fileUploadProps={{
                        file,
                        fileUrl: url,
                        uploadId: id,
                        rotateBy: '0',
                        className: styles['no-border'],
                        skipValidation: isEdited,
                        loaderClassName: styles.hidden,
                        onDelete: onRemoveFile,
                        onEdit: onEditFile,
                        onFileUploaded,
                        onUploadStarted,
                        onError: onUploadError
                      }}
                      onMetadataLoaded={updateOrientation}
                    />
                  )
                }
                if (url) {
                  return (
                    <ExternalImageGridItem
                      data={files[id]}
                      key={id}
                      featured={fileOrder === 1}
                      onRemove={onRemoveFile}
                      onEdit={onEditFile}
                      onMetadataLoaded={updateOrientation}
                    />
                  )
                }
                return null
              })}
            </div>
          </SortableContext>
        </DndContext>
      )}
      {props.videoUrl && !withVideoUpload && (
        <div className={styles['files-grid']}>
          <div className={styles['file-box-wrapper']}>
            <div className={`${styles['file-box']} ${styles['video-file']}`}>
              <UploaderVideoPreview
                videoUrl={props.videoUrl}
                thumbnailUrl={props.videoThumbnailUrl}
                onEdit={openVideoEditor}
                onDelete={removeVideo}
              />
            </div>
            <FileInfo fileUrl={props.videoUrl} className={styles['file-box-footer']} />
          </div>
        </div>
      )}
      <PhotoEditorDialog
        open={Boolean(photoEditorImage)}
        editId={photoEditorImage?.id || ''}
        image={photoEditorImage?.file || photoEditorImage?.url || ''}
        fileName={photoEditorImage?.file?.name || ''}
        onExport={onPhotoEditorExport}
        onError={onEditorError}
        onClose={closePhotoEditor}
      />
      <VideoEditorDialog
        open={videoEdit.editorOpen}
        video={videoEdit?.videoUrl}
        scene={videoEdit.scene}
        onExport={onVideoEditorExport}
        onClose={closeVideoEditor}
      />
    </div>
  )
}

function ExternalImageGridItem(props: {
  data: FileState,
  featured: boolean,
  onRemove: (name: string, id: string) => void,
  onEdit: (id: string) => void,
  onMetadataLoaded?: (fileId: string, orientation: 'landscape' | 'portrait') => void
}) {
  const { onMetadataLoaded } = props
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: props.data.id })

  const remove = (e: React.MouseEvent) => {
    e.stopPropagation()
    props.onRemove('', props.data.id)
  }

  const edit = (e: React.MouseEvent) => {
    e.stopPropagation()
    props.onEdit(props.data.id)
  }

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? '3' : '2',
    cursor: isDragging ? 'grabbing' : 'grab'
  }

  const metadataLoaded = React.useCallback((orientation: 'landscape' | 'portrait') => {
    if (onMetadataLoaded) {
      onMetadataLoaded(props.data.id, orientation)
    }
  }, [props.data.id, onMetadataLoaded])

  return (
    <div
      className={styles['file-box-wrapper']}
      {...listeners}
      {...attributes}
      ref={setNodeRef}
      style={style}
      data-testid="preview-ext-image"
    >
      {props.featured && (
        <div className={styles['label-featured']}>
          <FormattedMessage id="label.generic.featured" />
        </div>
      )}
      <div className={`${styles['file-box']} ${styles.ext}`}>
        <IconButton size="small" className={styles['btn-remove']} onClick={remove}>
          <IconClose className={styles.icon} />
        </IconButton>
        <IconButton size="small" className={styles['btn-edit']} onClick={edit}>
          <EditIcon className={styles.icon} />
        </IconButton>
        <img className={styles.image} src={props.data.url} />
      </div>
      <FileInfo fileUrl={props.data.url} className={styles['file-box-footer']} onMetadataLoaded={metadataLoaded} />
    </div>
  )
}

function FileGridItem(props: {
  data: FileState,
  fileUploadProps: FileUploadProps,
  isFeatured: boolean,
  onMetadataLoaded?: (fileId: string, orientation: 'landscape' | 'portrait') => void
}) {
  const { onMetadataLoaded } = props
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: props.data.id })
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? '3' : '2',
    cursor: isDragging ? 'grabbing' : 'grab'
  }

  const metadataLoaded = React.useCallback((orientation: 'landscape' | 'portrait') => {
    if (onMetadataLoaded) {
      onMetadataLoaded(props.data.id, orientation)
    }
  }, [props.data.id, onMetadataLoaded])

  return (
    <div
      className={styles['file-box-wrapper']}
      {...listeners}
      {...attributes}
      ref={setNodeRef}
      style={style}
      data-testid="file-grid-item"
    >
      {props.isFeatured && (
        <div className={styles['label-featured']}>
          <FormattedMessage id="label.generic.featured" />
        </div>
      )}
      <div className={`${styles['file-box']} ${props.data.isVideo ? styles['video-file'] : ''}`}>
        <FileUpload {...props.fileUploadProps} abortOnUnmount />
      </div>
      <FileInfo file={props.data.file} className={styles['file-box-footer']} onMetadataLoaded={metadataLoaded} />
    </div>
  )
}

function mapStateToProps(state: StoreState) {
  return {
    imageUrls: composerImageUrlsSelector(state),
    videoUrl: composerVideoUrlSelector(state),
    videoThumbnailUrl: composerVideoThumbnailSelector(state),
    resetKey: composerResetKeySelector(state),
    isUploading: composerIsUploadingSelector(state)
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    addImageUrls: (urls: string[]) => dispatch(addComposerImages(urls)),
    setVideoUrl: (url: string) => {
      dispatch(setComposerVideoUrl(url))
      dispatch(setComposerVideoMetadata(url))
    },
    removeFile: (url: string) => dispatch(removeComposerFileUrl(url)),
    message: (text: string, type?: any) => dispatch(message(text, type)),
    setIsUploading: (value: boolean | 'video') => dispatch(setIsComposerUploading(value)),
    deleteUploadByUrl: (url: string) => dispatch(deleteFile(url)),
    setFeaturedImageUrl: (url: string | undefined) => dispatch(setFeaturedImage(url))
  }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MediaPreview))
