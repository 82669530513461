import React from 'react'
import styles from './RecycleBadge.pcss'
import { FormattedMessage } from 'react-intl'
import Tooltip from '@mui/material/Tooltip'
import { format } from 'date-fns'

interface RecycleBadgeProps {
  recycle: number | boolean | Date
  autoPost: boolean | null
  repeatLength: number
  repeatPeriod: string
  repeatUntil: string
  className?: string
}

export function RecycleBadge(props: RecycleBadgeProps) {
  const { recycle, repeatLength, repeatPeriod, repeatUntil, autoPost, className } = props
  const recycleLabel = React.useMemo(() => {
    if (typeof recycle === 'number') {
      return `Recycle ${recycle} times`
    }

    if (recycle === true) {
      return 'Recycle forever'
    }

    if (typeof recycle === 'object') {
      return `Recycle until ${(recycle as Date).toLocaleDateString()}`
    }

    return ''
  }, [recycle])

  const repeatLabel = React.useMemo(() => {
    if (autoPost || repeatLength === 0) {
      return null
    }

    return <FormattedMessage
      id="post.planned.recycle-msg"
      values={{
        repeatLength,
        repeatPeriod,
        repeatUntil: repeatUntil === 'forever' ? 'forever' : format(repeatUntil, 'd LLL yyyy h:mm a')
      }}
    />
  }, [repeatLength, repeatPeriod, repeatUntil, autoPost])

  if (recycle) {
    return (
      <Tooltip title={recycleLabel} placement="top">
        <span className={`${styles.badge} ${className || ''}`}></span>
      </Tooltip>
    )
  }

  return !autoPost && repeatLength > 0 ? (
    <Tooltip title={repeatLabel} placement="top">
      <span className={`${styles.badge} ${className || ''}`}></span>
    </Tooltip>
  ) : null
}
