import { RequestBuilder, authorizedPipe } from 'services/net'
import { LEGACY_URL } from 'config'
import { ContentType, RangeFilter } from 'interfaces'

export const ENDPOINT_CONTENT_EXTRACT_BATCH = `${LEGACY_URL}/v3/streams_content`

export interface BatchResponse {
  streams: {
    [key: string]: {
      [key: string]: {
        range: RangeFilter,
        data: any[]
      }
    }
  }
}

export const V1 = Object.freeze({
  getContent: function () {
    const builder = new RequestBuilder(ENDPOINT_CONTENT_EXTRACT_BATCH).asPost().expectJSON().asFormUrlEncoded()

    return function (streams: { [key: string]: { filters: ContentType[], originalStreamId?: string }}) {
      return authorizedPipe<BatchResponse>(
        builder
          .body({ streams: JSON.stringify(streams) })
          .build()
      )
    }
  }()
})
