import * as React from 'react'
import Fab from '@mui/material/Fab'
import IconNext from '@mui/icons-material/KeyboardArrowRight'
import IconPrev from '@mui/icons-material/KeyboardArrowLeft'

import styles from './ImageCarousel.pcss'

interface ImageCarouselProps {
  imageUrls: string[]
  width: number
  className?: string
  onImageChanged?: (url: string) => void
}

export function ImageCarousel(props: ImageCarouselProps) {
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(0)

  const next = () => {
    const nextIndex = selectedImageIndex + 1
    if (props.imageUrls[nextIndex]) {
      setSelectedImageIndex(nextIndex)
      if (props.onImageChanged) {
        props.onImageChanged(props.imageUrls[nextIndex])
      }
    }
  }

  const prev = () => {
    const nextIndex = selectedImageIndex - 1
    if (nextIndex >= 0) {
      setSelectedImageIndex(nextIndex)
      if (props.onImageChanged) {
        props.onImageChanged(props.imageUrls[nextIndex])
      }
    }
  }

  const carouselStyle = {
    transform: `translateX(-${selectedImageIndex * props.width}px)`
  }

  const imgBoxStyle = {
    width: `${props.width}px`,
    flexBasis: `${props.width}px`
  }

  return (
    <div className={`${styles.container} ${props.className || ''}`} style={imgBoxStyle}>
      {selectedImageIndex > 0 && (
        <Fab className={`${styles.btn} ${styles.prev}`} size="small" onClick={prev}>
          <IconPrev className={styles.icon} />
        </Fab>
      )}
      {selectedImageIndex + 1 < props.imageUrls.length && (
        <Fab className={`${styles.btn} ${styles.next}`} size="small" onClick={next}>
          <IconNext className={styles.icon} />
        </Fab>
      )}
      <div className={styles.carousel} style={carouselStyle}>
        {props.imageUrls.map(src => (
          <div key={src} className={styles['image-box']} style={imgBoxStyle}>
            <img src={src} className={styles.image} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default ImageCarousel
