import * as React from 'react'
import { connect } from 'react-redux'
import { MyFile, FileFolder } from 'interfaces'
import StoreState, { StoreThunkDispatch } from 'store/state'
import FileFolderCarousel from '../../components/FileFolderCarousel'
import ScrollListener from 'components/ScrollListener'
import { setFileInPreview } from 'services/uploads/actions'
import { setComposerFile } from 'services/compose'
import { myFileFoldersArraySelector } from 'services/uploads/selectors'
import { sortByKeyAscending } from 'utils/sort/order'
import { useNavigate, useLocation } from 'react-router-dom'

const SCROLL_THRESHOLD = 350
export const VISIBLE_FOLDERS_BATCH_COUNT = 4

interface ConnectedMyUploadsRootViewProps {
  folders: FileFolder[]
  createPostFromFile: (url: string) => void
  setFileInPreview: (file: MyFile) => void
}

export type MyUploadsRootViewProps = ConnectedMyUploadsRootViewProps

export function MyUploadsRootView(props: MyUploadsRootViewProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const [visibleCount, setVisibleCount] = React.useState(VISIBLE_FOLDERS_BATCH_COUNT)
  const scrollElement = document.getElementsByTagName('main')[0] as HTMLElement

  const loadMore = () => {
    if (visibleCount < props.folders.length) {
      setVisibleCount(visibleCount + VISIBLE_FOLDERS_BATCH_COUNT)
    }
  }

  const createPostFromFile = (file: MyFile) => {
    props.createPostFromFile(file.url)
    navigate('/composer', { state: { floating: true, modal: 'compose', opener: location.pathname, background: location } })
  }

  return (
    <ScrollListener
      emitTreshold={SCROLL_THRESHOLD}
      scrollElement={scrollElement}
      onScroll={loadMore}
    >
      <div>
        {
          props.folders.sort(sortByKeyAscending('order')).slice(0, visibleCount).map(folder => (
            <FileFolderCarousel
              key={folder.id}
              folder={folder}
              navPrefix="/content/library"
              onCardClick={props.setFileInPreview}
              onCreatePost={createPostFromFile}
            />
          ))
        }
      </div>
    </ScrollListener>
  )
}

function mapStateToProps(state: StoreState) {
  return {
    folders: myFileFoldersArraySelector(state)
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    createPostFromFile: (url: string) => dispatch(setComposerFile(url)),
    setFileInPreview: (file: MyFile) => dispatch(setFileInPreview(file))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyUploadsRootView)
