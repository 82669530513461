import * as React from 'react'
import { connect } from 'react-redux'
import { BRAND_TWITTER } from 'interfaces'
import { StoreDispatch } from 'store/state'
import { sdkInitialized, sdkRemoved } from 'services/external'

interface TwitterSDKOwnProps {
  onReady?: Array<() => void>
}

interface ConnectedTwitterSDKProps {
  onInitialized: () => void
  onRemoved: () => void
}

type TwitterSDKProps = TwitterSDKOwnProps & ConnectedTwitterSDKProps

export class TwitterSDK extends React.Component<TwitterSDKProps, any> {
  private twitterSDK: any
  private scriptElementId = 'twitter-wjs'

  injectTwitterSDK() {
    return (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0] as HTMLScriptElement
      const t = (window as any).twttr || {}
      if (d.getElementById(id)) return t
      const js = d.createElement(s) as HTMLScriptElement
      js.id = id
      js.src = 'https://platform.twitter.com/widgets.js'
      const target = (fjs && fjs.parentNode) || document.head
      target.insertBefore(js, fjs)

      t._e = []
      t.ready = function (f: any) {
        t._e.push(f)
      }

      return t
    }(document, 'script', this.scriptElementId))
  }

  componentDidMount() {
    this.twitterSDK = this.injectTwitterSDK()
    ; (window as any).twttr = this.twitterSDK
    const onReady = [this.props.onInitialized].concat(this.props.onReady || [])
    this.twitterSDK.ready(onReady)
  }

  componentWillUnmount() {
    this.twitterSDK = null
    const element = document.getElementById(this.scriptElementId)
    if (element) {
      element.remove()
    }
    this.props.onRemoved()
  }

  render() {
    return null
  }
}

function mapDispatchToProps(dispatch: StoreDispatch) {
  return {
    onInitialized: () => dispatch(sdkInitialized(BRAND_TWITTER)),
    onRemoved: () => dispatch(sdkRemoved(BRAND_TWITTER))
  }
}

export default connect(undefined, mapDispatchToProps)(TwitterSDK)
