import { PayloadAction, createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { ProductState, initialState } from './state'
import { PPProduct } from 'shared/types'
import { V1 } from './net'
import { StoreThunkAction, getState, StoreDispatch, StoreThunkDispatch } from 'store/state'
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/of'
import 'rxjs/add/operator/zip'
import { userProductSelector } from 'services/users/selectors'
import { tap } from 'rxjs/operators/tap'
import { HS_EVENT_SUBSCRIPTION_CHANGE, trackHubspotEvent } from 'services/tracking/hubspot'
import { contentUpgradePupupDismissedSelector, productsSelector } from './selectors'
import { CONTENT_BLOCKING_FEATURE_KEYS } from './constants'
import { getFeatures, getLimits } from 'admin/services/features/actions'
import { impersonationSelector } from 'admin/services/selectors'
import { catchError } from 'rxjs/operators/catchError'

export * from './selectors'
export * from './constants'
export * from './utils'

export function getProductById(id: string) {
  return (_dispatch: StoreDispatch, getState: getState) => {
    const products = productsSelector(getState())
    return products.find(p => p.id === id)
  }
}

export function getProductByHandle(handle: string): StoreThunkAction<PPProduct | undefined> {
  return (_dispatch: any, getState: getState) => {
    const products = productsSelector(getState())
    return products.find(p => Object.values(p.handles).includes(handle))
  }
}

export const upgradeSuccess = createAction(
  'ACTION_UPGRADE_SUCCESS',
  (newPlan: string, productInfo: PPProduct | undefined) => ({ payload: { newPlan, productInfo } })
)

export function upgradeProduct(handle: string): StoreThunkAction<Observable<any>> {
  return (dispatch, getState) => {
    const currentProduct = userProductSelector(getState())
    const nextProduct = dispatch(getProductByHandle(handle))
    const isDowngrade = currentProduct?.order as number > (nextProduct?.order as number)
    const isImpersonating = impersonationSelector(getState())
    if (isImpersonating) {
      throw new Error('Cannot upgrade product while impersonating')
    }
    return dispatch(V1.upgrade(handle))
      .map(response => {
        if (response.status === 'error') {
          throw new Error(response.msg)
        }

        dispatch(upgradeSuccess(handle, nextProduct))
        return response.msg
      })
      .pipe(tap(() => {
        dispatch(trackHubspotEvent(HS_EVENT_SUBSCRIPTION_CHANGE, { type: isDowngrade ? 'downgraded' : 'upgraded' }))
      }))
  }
}

export function getProductUpgradeByFeature(feature: string) {
  return (_dispatch: StoreDispatch, getState: getState) => {
    const products = productsSelector(getState())
    return products.find(product => product.features[feature])
  }
}

export function getProductUpgradeByLimitKey(limit: string, value: number) {
  return (_dispatch: StoreDispatch, getState: getState) => {
    const products = productsSelector(getState())
    return products.find(product => {
      return product.limits[limit] === -1 || product.limits[limit] >= value
    })
  }
}

/**
 * Checks if user is allowed to use specific feature and opens the Upgrade dialog if not
 *
 * @export
 * @param {string} feature - the feature to check
 * @param {boolean} [checkOnly] - if true, do not show the upgrade dialog
 * @returns {StoreThunkAction<boolean>}
 */
export function checkFeatureAvailability(feature: string, checkOnly?: boolean, closeCallback?: () => void): StoreThunkAction<boolean> {
  return (dispatch: StoreThunkDispatch, getState: getState) => {
    const state = getState()
    const product = userProductSelector(state)
    if (!product || product.features[feature]) {
      return true
    }

    const productUpgrade = dispatch(getProductUpgradeByFeature(feature))
    if (productUpgrade && !checkOnly) {
      const contentPopupDismissed = contentUpgradePupupDismissedSelector(state)
      const preventPopupOpen = contentPopupDismissed && CONTENT_BLOCKING_FEATURE_KEYS.includes(feature)
      if (preventPopupOpen) {
        return false
      }
      dispatch(promptUpgrade({ feature, product: productUpgrade.handles.annual, closeCallback }))
    }

    return false
  }
}

/**
 * Checks if a value is within current user's Product limits
 * If not - requests to display Upgrade dialog
 *
 * @returns Boolean
 */
export function checkProductLimit(limitKey: string, value: number, customLimit?: number) {
  return (dispatch: StoreThunkDispatch, getState: getState) => {
    const state = getState()
    const product = userProductSelector(state)

    if (product) {
      const compareWithLimit = customLimit !== undefined && customLimit > product.limits[limitKey]
        ? customLimit
        : product.limits[limitKey]
      if (compareWithLimit === undefined || compareWithLimit === -1 || compareWithLimit >= value) {
        return true
      }
    }

    const productUpgrade = dispatch(getProductUpgradeByLimitKey(limitKey, value))
    if (productUpgrade) {
      dispatch(promptUpgrade({ feature: limitKey, product: productUpgrade.handles.annual }))
    }

    return false
  }
}

export const getFeaturesAndLimits = createAsyncThunk('product/getFeaturesAndLimits', async (_, { dispatch, rejectWithValue }) => {
  try {
    const features = await dispatch(getFeatures()).toPromise()
    const limits = await dispatch(getLimits()).toPromise()
    return { features, limits }
  } catch (e) {
    return rejectWithValue(e)
  }
})

export function endTrial(product: string) {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.endTrial(product))
    .pipe(catchError(err => {
      return Observable.throw(err.response.error)
    }))
}

const productSlice = createSlice({
  name: 'product',
  initialState: initialState(),
  reducers: {
    setProducts: (state, action: PayloadAction<PPProduct[]>) => {
      state.products = action.payload
    },
    promptUpgrade: (state: ProductState, action: PayloadAction<{ feature: string, product: string, closeCallback?: () => void }>) => {
      state.featureUpgrade = action.payload.feature
      state.productUpgrade = action.payload.product
      state.closeUpgradePopupCallback = action.payload.closeCallback
    },
    closeUpgradePrompt: (state: ProductState) => {
      state.featureUpgrade = undefined
      state.productUpgrade = undefined
      state.closeUpgradePopupCallback = undefined
    },
    toggleContentUpgradePopupDismissed: (state: ProductState, action: PayloadAction<boolean>) => {
      state.displayContentUpgradePopup = !action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getFeaturesAndLimits.fulfilled, (state, action) => {
      state.features = action.payload.features
      state.limits = action.payload.limits
    })
  }
})

export const { setProducts, promptUpgrade, closeUpgradePrompt, toggleContentUpgradePopupDismissed } = productSlice.actions
export default productSlice.reducer
