import { CalendarDay } from 'interfaces/Post/Calendar'
import { DAYS_IN_WEEK, WEEKDAY_LABELS } from './constants'

/**
 * Returns the number of days in a month
 *
 * @export
 * @param {number} month - month index [0, 11]
 * @param {number} year
 * @returns {number}
 */
export function getDaysInMonth(month: number, year: number): number {
  /* eslint-disable no-magic-numbers */
  if (month < 0 || month > 11) {
    throw new Error('"month" must be between 0 and 11')
  }

  // EXPL: Passing 32 for day here *new Date(year, month, day)* returns the 32nd day from the month start, going into the next month.
  // if we subtract that date from 32, we'll get the number of days of the month provided
  return 32 - new Date(year, month, 32).getDate()
}

export function generateDaysOfMonth(year: number, month: number): CalendarDay[] {
  const days: CalendarDay[] = []
  const daysCount = getDaysInMonth(month, year)
  for (let i = 0; i < daysCount; i++) {
    const date = new Date(year, month, i + 1)
    const index = date.getDay()
    days.push({ date, dayIndex: index, label: WEEKDAY_LABELS[index] })
  }

  return days
}
/**
 * Generates list of CalendarDays that will be visible in a calendar month view,
 * including days from previous / next months
 *
 * @export
 * @param {number} year
 * @param {number} month
 * @param {number} [startDayIndex=1] the first column (day) of the month view table
 * @returns {CalendarDay[]}
 */
export function getMonthViewDays(year: number, month: number, startDayIndex: number = 1): CalendarDay[] {
  if (startDayIndex < 0 || startDayIndex > 6) {
    throw new Error('"startDayIndex" must be between 0 and 6')
  }
  const days: CalendarDay[] = []
  const daysInMonth = generateDaysOfMonth(year, month)
  const firstDayOfMonthIndex = daysInMonth[0].dayIndex
  const lastDayOfMonth = daysInMonth[daysInMonth.length - 1]

  let startOffset = 0
  if (startDayIndex > firstDayOfMonthIndex) {
    startOffset = DAYS_IN_WEEK - startDayIndex - firstDayOfMonthIndex - 1
  } else {
    startOffset = firstDayOfMonthIndex - startDayIndex - 1
  }
  while (startOffset >= 0) {
    const date = new Date(year, month, -startOffset)
    const index = date.getDay()
    days.push({ date, dayIndex: index, label: WEEKDAY_LABELS[index] })
    startOffset -= 1
  }

  days.push(...daysInMonth)
  const endOffset = DAYS_IN_WEEK - (days.length % DAYS_IN_WEEK)

  for (let i = 1; i <= endOffset; i++) {
    const date = new Date(year, month, lastDayOfMonth.date.getDate() + i)
    const index = date.getDay()
    days.push({ date, dayIndex: index, label: WEEKDAY_LABELS[index] })
  }
  return days
}

export function getWeekViewDays(referenceDate: Date, weekOffset: number, startDayIndex: number = 1): CalendarDay[] {
  if (startDayIndex < 0 || startDayIndex > 6) {
    throw new Error('"startDayIndex" must be between 0 and 6')
  }

  const refDate = new Date(referenceDate.getTime())
  refDate.setDate(referenceDate.getDate() + DAYS_IN_WEEK * weekOffset)

  const refIndex = refDate.getDay()
  const days: CalendarDay[] = []
  let currentDayIndex = startDayIndex
  while (days.length < 7) {
    days.push({
      date: refDate,
      dayIndex: currentDayIndex,
      label: WEEKDAY_LABELS[currentDayIndex]
    })

    if (currentDayIndex < 6) {
      currentDayIndex += 1
    } else {
      currentDayIndex = 0
    }
  }
  const refDateIndex = days.findIndex(d => d.dayIndex === refIndex)
  days.forEach((day, index) => {
    const daysOffset = index - refDateIndex
    const date = new Date(day.date.getTime())
    date.setDate(date.getDate() + daysOffset)
    date.setHours(0, 0, 0)
    day.date = date
  })
  return days
}

export function getMonthViewDaysByWeeks(startDate: Date, startDayIndex: number = 1, weeksCount: number = 5): CalendarDay[] {
  const days: CalendarDay[] = []
  for (let i = 0; i < weeksCount; i++) {
    days.push(...getWeekViewDays(startDate, i, startDayIndex))
  }
  return days
}

/**
 * @param hour 24-hour format hour (0-23)
 * @returns time of day - am/pm
 */
export function ampm(hour: number) {
  // eslint-disable-next-line no-magic-numbers
  return hour < 12 ? 'am' : 'pm'
}

// Converts AM/PM hours to 24-hour format
export function to24HourFormat(hour: number, isPm: boolean) {
  if (hour === 12) {
    return isPm ? 12 : 0
  }
  return isPm ? hour + 12 : hour
}
export const DAY_HOURS_SHORT = [
  '12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM', '6 AM', '7 AM', '8 AM',
  '9 AM', '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM',
  '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM',
  '11 PM'
]
