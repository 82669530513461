import React from 'react'
import {
  BRAND_FACEBOOK,
  BRAND_PINTEREST,
  BRAND_TWITTER,
  IndexedObject,
  PinterestBoard,
  PostDestination,
  PostDestinationType
} from 'interfaces'
import { BRAND_GOOGLE } from 'interfaces/Content/SocialBrand'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { ComposerTwitterPost, LocationInfo, TweetType } from 'services/compose/interfaces'
import { ComposerFacebookPost, FBAlbum } from 'services/compose/interfaces/ComposerFacebookPost'
import {
  editMultiPosts,
  removeMultiPosts,
  reorderMultiPosts,
  setMultiPostFBAlbums,
  setMultiPostIGLocations,
  setMultiPostPiBoards,
  setMultiPostsRecycle,
  setMultiPostFBReshareAll,
  setMultiPostTweetTypeAll,
  applyGenericStatusToPosts,
  setMultiPostNetworkSettings,
  setMultipostTikTokProfileSettings,
  initializeTikTokSettings
} from 'services/compose'
import {
  composerSelectedProfilesSelector,
  multiPostsCountSelector,
  useGenericStatusSelector,
  visibleMultiPostsSelector
} from 'services/compose/selectors'
import { SortableBulkPost } from './BulkPost'
import styles from './BulkPostsManager.pcss'
import { DndContext, useDroppable, MeasuringStrategy } from '@dnd-kit/core'
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers'
import { reorder } from 'shared/utils'
import { BulkContentPost } from 'services/compose/state'
import MultiPostBulkActions from './MultiPostBulkActions'
import { RecycleDialog } from 'components/Posts/components/RecycleSelector'
import { NetworkOptionsPopup } from './NetworkOptionsPopup'
import { ComposerGooglePost, GooglePostButtonType } from 'services/compose/interfaces/ComposerGooglePost'
import { BRAND_YOUTUBE, TikTokPostProfileSettings, PrivacyStatus, BRAND_TIKTOK } from 'shared'
import { useTikTokDataFetch } from 'hooks/useTikTokDataFetch'
import { getPinterestBoards, pinterestBoardsByPpidSelector } from 'services/destinations'
import { StoreThunkDispatch } from 'store/state'

const deepEqual = require('react-fast-compare')

export const BulkPostsManager = React.memo(() => {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [linkedPostsCaptions, setLinkedPostsCaption] = React.useState<Record<string, boolean>>({})
  const [recycleDialogOpen, setRecycleDialogOpen] = React.useState(false)
  const posts: BulkContentPost[] = useSelector(visibleMultiPostsSelector)
  const count = useSelector(multiPostsCountSelector, shallowEqual)
  const postsMap = React.useMemo(() => posts.reduce((map: IndexedObject<BulkContentPost>, post) => {
    map[post.id] = post
    return map
  }, {}), [posts])
  const boards = useSelector(pinterestBoardsByPpidSelector, shallowEqual)
  const selectedProfiles: PostDestination[] = useSelector(composerSelectedProfilesSelector, deepEqual)
  const tkIds = React.useMemo(() => selectedProfiles.filter(p => p.type === BRAND_TIKTOK).map(p => p.ppid), [selectedProfiles])
  const profilesByNetwork = React.useMemo(() => {
    return selectedProfiles.reduce((map: { [network: string]: PostDestination[] }, profile) => {
      if (map[profile.type]) {
        map[profile.type].push(profile)
      } else {
        map[profile.type] = [profile]
      }
      return map
    }, {})
  }, [selectedProfiles])
  const [tiktokData] = useTikTokDataFetch(tkIds.join(','))
  const isUsingGenericStatus = useSelector(useGenericStatusSelector)
  const [selectedPostIds, setSelectedPostIds] = React.useState<string[]>([])
  const [networkOptionsPopupOpen, setNetworkOptionsPopupOpen] = React.useState(false)

  const orderRef = React.useRef<string[]>([])
  const pinterestBoardsFetched = React.useRef<Record<string, boolean>>({})

  React.useEffect(() => {
    profilesByNetwork[BRAND_PINTEREST]?.forEach(profile => {
      if (!pinterestBoardsFetched.current[profile.ppid]) {
        dispatch(getPinterestBoards(profile)).unwrap()
        pinterestBoardsFetched.current[profile.ppid] = true
      }
    })
  }, [profilesByNetwork, dispatch])

  React.useEffect(() => {
    const ppids = Object.keys(tiktokData)
    const tiktokSettings = ppids.reduce((acc, ppid: string) => {
      acc[ppid] = tiktokData[ppid].settings
      return acc
    }, {} as Record<string, TikTokPostProfileSettings>)
    dispatch(initializeTikTokSettings(tiktokSettings))
  }, [tiktokData, dispatch])

  React.useEffect(() => {
    setSelectedPostIds(current => {
      if (current.some(id => !postsMap[id])) {
        return current.filter(id => postsMap[id])
      }
      return current
    })
  }, [postsMap])

  const order = React.useMemo(() => {
    const next = posts.map(p => p.id)
    orderRef.current = next
    return next
  }, [posts])

  const visiblePosts = React.useMemo(() => {
    const networks = selectedProfiles.reduce((map: { [network: string]: boolean }, profile) => {
      map[profile.type] = true
      return map
    }, {})
    return posts.filter(post => networks[post.network])
  }, [posts, selectedProfiles])

  const { setNodeRef: setDroppableNodeRef } = useDroppable({
    id: 'droppable'
  })

  const openRecycleDialog = () => {
    setRecycleDialogOpen(true)
  }

  const closeRecycleDialog = () => {
    setRecycleDialogOpen(false)
  }

  const onBulkRecycle = (value: boolean | Date | number) => {
    dispatch(setMultiPostsRecycle({ postIds: selectedPostIds, value }))
    closeRecycleDialog()
  }

  const onDragEnd = (event: any) => {
    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = order.indexOf(active.id)
      const newIndex = order.indexOf(over.id)
      const reordered = reorder(order, oldIndex, newIndex)
      dispatch(reorderMultiPosts(reordered))
    }
  }

  const toggleSelected = React.useCallback((id: string) => {
    setSelectedPostIds(current => {
      const index = current.indexOf(id)
      return index === -1 ? [...current, id] : current.filter(postId => postId !== id)
    })
  }, [])

  const moveToTop = React.useCallback((id: string) => {
    const next = [...orderRef.current]
    const index = next.indexOf(id)
    next.splice(index, 1)
    next.unshift(id)
    dispatch(reorderMultiPosts(next))
  }, [dispatch])

  const onMoveToBottom = React.useCallback((id: string) => {
    const next = [...orderRef.current]
    const index = next.indexOf(id)
    next.splice(index, 1)
    next.push(id)
    dispatch(reorderMultiPosts(next))
  }, [dispatch])

  const remove = React.useCallback((id: string) => {
    dispatch(removeMultiPosts({ ids: [id] }))
    setSelectedPostIds(current => current.filter(postId => postId !== id))
  }, [dispatch])

  const onSelectedFBAlbumsChange = React.useCallback((postId: string, albums: IndexedObject<FBAlbum | undefined>, applyToAll: boolean) => {
    dispatch(setMultiPostFBAlbums({ postId, albums, applyToAll }))
  }, [dispatch])

  const onSelectedPIBoardsChange = React.useCallback((postId: string, selection: Record<string, PinterestBoard>, applyToAll?: boolean) => {
    dispatch(setMultiPostPiBoards({ postId, selection, applyToAll }))
  }, [dispatch])

  const onSelectedIGLocationsChange = React.useCallback(
    (postId: string, locations: IndexedObject<LocationInfo | undefined>, applyToAll: boolean) => {
      dispatch(setMultiPostIGLocations({ postId, locations, applyToAll }))
    },
    [dispatch]
  )

  const onStatusChange = React.useCallback((postId: string, text: string, html: string, network: PostDestinationType) => {
    dispatch(editMultiPosts({ id: postId, network, data: { status: { text, html, touched: true } } }))
    setLinkedPostsCaption(current => {
      if (current[postId]) {
        return { ...current, [postId]: false }
      }
      return current
    })
  }, [dispatch])

  const onRecycleChange = React.useCallback(
    (postId: string, value: number | boolean | Date, network: PostDestinationType, applyToAll?: boolean) => {
      if (applyToAll) {
        dispatch(setMultiPostsRecycle({ postIds: [postId], value, applyToAll: true }))
      } else {
        dispatch(editMultiPosts({ id: postId, network, data: {}, imageUrl: undefined, recycle: value }))
      }
    },
    [dispatch]
  )

  const toggleAllSelected = () => {
    if (selectedPostIds.length < posts.length) {
      setSelectedPostIds(posts.map(p => p.id))
    } else {
      setSelectedPostIds([])
    }
  }

  const onPinDataChange = React.useCallback(
    (postId: string, data: { title: string, description: string, destinationUrl: string, applyToAll?: boolean }) => {
      dispatch(editMultiPosts({ id: postId, network: BRAND_PINTEREST, data }))
    },
    [dispatch]
  )

  const onYtStatusChange = React.useCallback((postId: string, data: { title: string, description: string }) => {
    dispatch(editMultiPosts({ id: postId, network: BRAND_YOUTUBE, data }))
  }, [dispatch])

  const allSelected = selectedPostIds.length === posts.length

  const bulkDeletePosts = () => {
    dispatch(removeMultiPosts({ ids: selectedPostIds, all: allSelected }))
    setSelectedPostIds([])
  }

  const toggleFBReshare = React.useCallback((postId: string, value: boolean) => {
    dispatch(editMultiPosts({
      id: postId,
      network: BRAND_FACEBOOK,
      data: { reshare: value } as Partial<ComposerFacebookPost>
    }))
  }, [dispatch])

  const toggleFBReshareAll = React.useCallback((value: boolean) => {
    dispatch(setMultiPostFBReshareAll(value))
  }, [dispatch])

  const onTweetTypeChange = React.useCallback((postId: string, value: TweetType) => {
    dispatch(editMultiPosts({ id: postId, network: BRAND_TWITTER, data: { tweetType: value } as Partial<ComposerTwitterPost> }))
  }, [dispatch])

  const applyTweetTypeToAll = React.useCallback((value: TweetType) => {
    dispatch(setMultiPostTweetTypeAll(value))
  }, [dispatch])

  const onPostTypeChange = React.useCallback((postId: string, network: PostDestinationType, postType: string) => {
    dispatch(editMultiPosts({ id: postId, network, data: { postType } }))
  }, [dispatch])

  const applyGenericStatus = () => {
    dispatch(applyGenericStatusToPosts(selectedPostIds))
    setLinkedPostsCaption(current => ({
      ...current,
      ...selectedPostIds.reduce((map: Record<string, boolean>, id) => {
        map[id] = true
        return map
      }, {})
    }))
    setSelectedPostIds([])
  }

  const openNetworkSettingsDialog = () => {
    setNetworkOptionsPopupOpen(true)
  }

  const closeSettingsPopup = () => {
    setNetworkOptionsPopupOpen(false)
  }

  const onBulkUpdateNetworkSettings = (
    fbAlbums: IndexedObject<FBAlbum | undefined>,
    piBoards: IndexedObject<PinterestBoard>,
    igLocations: IndexedObject<LocationInfo | undefined>,
    tkSettings: Record<string, TikTokPostProfileSettings>,
    ytPrivacy?: PrivacyStatus,
    fbPostType?: string,
    igPostType?: string
  ) => {
    dispatch(setMultiPostNetworkSettings({
      postIds: selectedPostIds,
      fbAlbums,
      piBoards,
      igLocations,
      tkSettings,
      ytPrivacy,
      fbPostType,
      igPostType
    }))
    closeSettingsPopup()
  }

  const onGoogleButtonTypeChange = React.useCallback((postId: string, type?: GooglePostButtonType) => {
    dispatch(editMultiPosts({ id: postId, network: BRAND_GOOGLE, data: { buttonType: type } as Partial<ComposerGooglePost> }))
  }, [dispatch])

  const onGooglePostLinkChange = React.useCallback((postId: string, url?: string) => {
    dispatch(editMultiPosts({ id: postId, network: BRAND_GOOGLE, data: { link: url } as Partial<ComposerGooglePost> }))
  }, [dispatch])

  const onTikTokSettingsChange = React.useCallback((postId: string, settings: TikTokPostProfileSettings) => {
    dispatch(setMultipostTikTokProfileSettings({ postId, settings }))
  }, [dispatch])

  const onYoutubePrivacyChange = React.useCallback((postId: string, privacy: PrivacyStatus) => {
    dispatch(editMultiPosts({ id: postId, network: BRAND_YOUTUBE, data: { privacyStatus: privacy } }))
  }, [dispatch])

  return (
    <React.Fragment>
      <DndContext onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]} layoutMeasuring={{ strategy: MeasuringStrategy.Always }}>
        <SortableContext items={order} strategy={rectSortingStrategy} modifiers={[restrictToParentElement]}>
          <div className={styles.container} ref={setDroppableNodeRef}>
            <div className={styles.header}>
              <div className={styles.left}>
                <div className={styles.count}>
                  <FormattedMessage id="composer.multi-post.labels.count" values={{ count }} />
                </div>
              </div>
              {posts.length > 1 && (
                <div className={styles.right}>
                  <MultiPostBulkActions
                    disabled={selectedPostIds.length === 0}
                    allSelected={Object.values(selectedPostIds).filter(Boolean).length === posts.length}
                    onRecycle={openRecycleDialog}
                    onDelete={bulkDeletePosts}
                    onToggleAllSelected={toggleAllSelected}
                    onApplyGenericStatus={applyGenericStatus}
                    onEditNetworkSettings={openNetworkSettingsDialog}
                  />
                </div>
              )}
            </div>
            <div className={styles.list}>
              {visiblePosts.map((post: BulkContentPost, index: number) => (
                <SortableBulkPost
                  key={post.id}
                  post={post}
                  profiles={profilesByNetwork[post.network] || []}
                  tabIndex={index + 1}
                  tiktokData={post.network === BRAND_TIKTOK ? tiktokData : undefined}
                  selected={selectedPostIds.includes(post.id)}
                  boards={boards}
                  allowStatusOverride={isUsingGenericStatus || linkedPostsCaptions[post.id]}
                  onMoveToBottom={onMoveToBottom}
                  onMoveToTop={moveToTop}
                  onRemove={remove}
                  onToggleSelected={toggleSelected}
                  onSelectedFBAlbumsChange={onSelectedFBAlbumsChange}
                  onSelectedPIBoardsChange={onSelectedPIBoardsChange}
                  onSelectedIGLocationsChange={onSelectedIGLocationsChange}
                  onRecycleChange={onRecycleChange}
                  onStatusChange={onStatusChange}
                  onPinChange={onPinDataChange}
                  onYtStatusChange={onYtStatusChange}
                  onUpdateFBReshare={toggleFBReshare}
                  onTweetTypeChange={onTweetTypeChange}
                  onToggleFBReshareAll={toggleFBReshareAll}
                  onApplyTweetTypeToAll={applyTweetTypeToAll}
                  onGoogleButtonTypeChange={onGoogleButtonTypeChange}
                  onGooglePostLinkChange={onGooglePostLinkChange}
                  onTikTokSettingsChange={onTikTokSettingsChange}
                  onYoutubePrivacyChange={onYoutubePrivacyChange}
                  onPostTypeChange={onPostTypeChange}
                />
              ))}
            </div>
          </div>
        </SortableContext>
      </DndContext>
      <RecycleDialog open={recycleDialogOpen} onClose={closeRecycleDialog} onSubmit={onBulkRecycle} />
      {networkOptionsPopupOpen && (
        <NetworkOptionsPopup
          open
          posts={selectedPostIds.map(id => postsMap[id]).filter(Boolean)}
          profiles={profilesByNetwork}
          onCancel={closeSettingsPopup}
          onConfirm={onBulkUpdateNetworkSettings}
        />
      )}
    </React.Fragment>
  )
})

  ; (BulkPostsManager as any).whyDidYouRender = true

export default BulkPostsManager
