import { RequestBuilder, authorizedPipe } from 'services/net'
import { ADMIN_SERVICE_URL, LEGACY_URL } from 'config'

const ENDPOINT_GET_PROMPT_CATEGORIES_ADMIN = `${LEGACY_URL}/administration/AI/writer/get_categories`
const ENDPOINT_GET_PROMPTS_ADMIN = `${LEGACY_URL}/administration/AI/writer/get_prompts`
const ENDPOINT_GET_PROMPT_CATEGORIES = `${LEGACY_URL}/AI_writer/get_categories`
const ENDPOINT_GET_PROMPTS = `${LEGACY_URL}/AI_writer/get_prompts`
const ENDPOINT_SAVE_PROMPT_CATEGORY = `${LEGACY_URL}/administration/AI/writer/save_category`
const ENDPOINT_DELETE_PROMPT_CATEGORY = `${LEGACY_URL}/administration/AI/writer/delete_category/:id`
const ENDPOINT_DELETE_PROMPT = `${LEGACY_URL}/administration/AI/writer/delete_prompt/:id`
const ENDPOINT_SAVE_PROMPT = `${LEGACY_URL}/administration/AI/writer/save_prompt`
const ENDPOINT_GET_LIMIT = `${LEGACY_URL}/v3/AI_completions/get_limits`

export function getPromptCategories(admin?: boolean) {
  const endpoint = admin ? ENDPOINT_GET_PROMPT_CATEGORIES_ADMIN : ENDPOINT_GET_PROMPT_CATEGORIES
  const builder = new RequestBuilder(endpoint).asGet().expectJSON()
  return authorizedPipe<any>(
    builder.build()
  )
}

export function getPrompts(categoryId?: string, admin?: boolean) {
  const endpoint = admin ? ENDPOINT_GET_PROMPTS_ADMIN : ENDPOINT_GET_PROMPTS
  const builder = new RequestBuilder(endpoint).asGet().expectJSON()
  if (categoryId) {
    builder.param('category_id', categoryId)
  }
  return authorizedPipe<any>(
    builder.build()
  )
}

export function savePromptCategory(name: string, type: string, visible?: boolean, id?: string) {
  const builder = new RequestBuilder(ENDPOINT_SAVE_PROMPT_CATEGORY).asPost().expectJSON()
  const data: any = { name, is_visible: visible ? 1 : 0, type }
  if (id) {
    data.id = id
  }
  return authorizedPipe<any>(
    builder.bodyJSONstringify(data).build()
  )
}

export function deletePromptCategory(id: string) {
  const builder = new RequestBuilder(ENDPOINT_DELETE_PROMPT_CATEGORY).asGet().expectJSON()
  return authorizedPipe<any>(
    builder.partial(':id', id).build()
  )
}

export function deletePrompt(id: string) {
  const builder = new RequestBuilder(ENDPOINT_DELETE_PROMPT).asGet().expectJSON()
  return authorizedPipe<any>(
    builder.partial(':id', id).build()
  )
}

export function getAssistantLimits() {
  const builder = new RequestBuilder(ENDPOINT_GET_LIMIT).asGet().expectJSON()
  return authorizedPipe<any>(
    builder.build()
  )
}

export function savePrompt(text: string, categoryId: string, id?: string) {
  const builder = new RequestBuilder(ENDPOINT_SAVE_PROMPT).asPost().expectJSON()
  const data: any = { text, category_id: categoryId }
  if (id) {
    data.id = id
    builder.param('id', id)
  }
  return authorizedPipe<any>(
    builder.bodyJSONstringify(data).build()
  )
}

export function getCaptionSuggestions(text: string, promptId?: string, variations: number = 1, imageUrl?: string, getImage = false) {
  const builder = new RequestBuilder(`${LEGACY_URL}/v3/AI_completions`).asPost().expectJSON()
  const data: FormData = new FormData()
  data.append('prompt_text', text)
  if (promptId) {
    data.append('prompt_id', promptId)
  }
  if (imageUrl) {
    data.append('image_url', imageUrl)
  }
  data.append('options', JSON.stringify({ variations }))

  return authorizedPipe<any>(
    builder
      .body(data)
      .param('feature', getImage ? 'images' : 'writer')
      .build()
  )
}

export function reorderPrompts(type: 'category' | 'prompt', ids: number[]) {
  const builder = new RequestBuilder(`${ADMIN_SERVICE_URL}/AI/writer/reorder`).asPost().expectJSON()

  return authorizedPipe<any>(
    builder
      .body({ ids })
      .param('type', type)
      .build()
  )
}

export function trackSuggestionUsage(id: string) {
  const builder = new RequestBuilder(`${LEGACY_URL}/AI_writer/mark_prompt_used`).asPost().expectJSON()

  return authorizedPipe<any>(
    builder
      .param('id', id)
      .build()
  )
}

export function getUserPrompts() {
  const builder = new RequestBuilder(`${LEGACY_URL}/AI_writer/get_user_prompts`).asGet().expectJSON()

  return authorizedPipe<any>(
    builder.build()
  )
}

export function deleteUserPrompt(id: string) {
  const builder = new RequestBuilder(`${LEGACY_URL}/AI_writer/delete_user_prompt`).asGet().expectJSON()

  return authorizedPipe<any>(
    builder.param('id', id).build()
  )
}

export function addFavoritePrompt(id?: string, text?: string, imageUrl?: string) {
  const builder = new RequestBuilder(`${LEGACY_URL}/AI_writer/add_favorite`).asPost().expectJSON()

  return authorizedPipe<any>(
    builder.body({ prompt_id: id, prompt_text: text, image_url: imageUrl }).build()
  )
}

export function removeFavoritePrompt(id: string) {
  const builder = new RequestBuilder(`${LEGACY_URL}/AI_writer/delete_favorite/:id`).asGet().expectJSON()

  return authorizedPipe<any>(
    builder.partial(':id', id).build()
  )
}

export function getFavoritePrompts() {
  const builder = new RequestBuilder(`${LEGACY_URL}/AI_writer/get_favorites`).asGet().expectJSON()

  return authorizedPipe<any>(
    builder.build()
  )
}
