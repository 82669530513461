import * as React from 'react'
import { AppNavLink } from './AppNavLink'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { getStreams } from 'services/content/streams/actions'
import styles from './AppNavigation.pcss'
import { getStorageInfo, getUploads } from 'services/uploads/actions'
import { WithIntl } from 'interfaces'
import { StreamsNavMenu } from './StreamsNavMenu'
import { LibraryNavMenu } from './LibraryNavMenu'
import { getUserPrompts } from 'services/compose'

export function ContentNav(props: { expanded: boolean } & WithIntl) {
  const [openSubnav, setOpenSubnav] = React.useState<null | 'streams' | 'library'>(null)
  const [expandedStreamId, setExpandedStreamId] = React.useState<string | undefined>(undefined)
  const dispatch = useDispatch<StoreThunkDispatch>()

  React.useEffect(() => {
    setExpandedStreamId(undefined)
  }, [openSubnav])

  React.useEffect(() => {
    dispatch(getUploads(true)).unwrap()
    dispatch(getStreams(true)).unwrap()
    const aiPromptsSub = dispatch(getUserPrompts()).subscribe()
    dispatch(getStorageInfo())

    return () => {
      aiPromptsSub.unsubscribe()
    }
  }, [dispatch])

  const toggleMLExpanded = () => {
    setOpenSubnav(current => current === 'library' ? null : 'library')
  }

  const toggleMSExpanded = () => {
    setOpenSubnav(current => current === 'streams' ? null : 'streams')
  }

  const onToggleExpandedStream = (id: string) => {
    setExpandedStreamId(current => current === id ? undefined : id)
  }

  return (
    <nav>
      <h1 className={styles['subnav-title']}>
        <FormattedMessage id="app.nav.title-content" />
      </h1>
      <AppNavLink
        label={<FormattedMessage id="app.nav.home" />}
        to="/content"
        end
        withTooltip={!props.expanded}
      />
      <StreamsNavMenu
        expanded={props.expanded && openSubnav === 'streams'}
        expandedStreamId={expandedStreamId}
        onToggleExpanded={toggleMSExpanded}
        onToggleExpandedStream={onToggleExpandedStream}
      />
      <LibraryNavMenu
        expanded={props.expanded && openSubnav === 'library'}
        onToggleExpanded={toggleMLExpanded}
      />
      <AppNavLink
        label={<FormattedMessage id="app.nav.favorites" />}
        to="/content/favorites"
        withTooltip={!props.expanded}
      />
      <AppNavLink
        label={<FormattedMessage id="app.nav.content-studio" />}
        to="/content/studio"
        withTooltip={!props.expanded}
      />
    </nav>
  )
}

export default injectIntl(ContentNav)
