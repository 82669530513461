import * as React from 'react'
import DatePicker from 'components/DatePicker'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import { FormattedMessage } from 'react-intl'
import { MS_IN_MINUTE } from 'utils/constants'
import styles from './ComposerScheduleForm.pcss'
import { RepeatPeriod, REPEAT_OPTIONS } from 'interfaces/Composer/ComposerPostTypes'
import TimePicker from 'components/TimePicker'
import { useDispatch, useSelector } from 'react-redux'
import HelpIcon from '@mui/icons-material/HelpOutline'
import Tooltip from '@mui/material/Tooltip'
import { defaultTimezoneSelector, getDefaults } from 'services/settings'
import { StoreThunkDispatch } from 'store/state'
import { useAsyncAction } from 'hooks/useAsyncAction'
import { setSchedule } from 'services/compose'
import { composerScheduleSelector, composerSelectedProfilesIdsSelector } from 'services/compose/selectors'
import { getPostScheduleFromComposerState } from 'services/compose/utils'
import { checkFeatureAvailability } from 'services/product'
import { FEATURE_POST_REPEAT } from 'shared/constants'
import { isBefore } from 'date-fns'
import { Observable } from 'rxjs/Observable'

const SCHEDULE_ERROR_INVALID_TIME = 'Please select a time to post that is in the future.'
const BASE_TEN = 10

export interface ComposerScheduleFormProps {
  submitDisabled: boolean
  className?: string
  hideActions?: boolean
  initialTime: Date
  onSubmit: () => void
  onCancel: () => void
  onError: (e: Error) => void
}

export function ComposerScheduleForm(props: ComposerScheduleFormProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const schedule = useSelector(composerScheduleSelector)
  const selectedProfiles = useSelector(composerSelectedProfilesIdsSelector)
  const [error, setError] = React.useState<null | string>(null)
  const timezone = useSelector(defaultTimezoneSelector)
  const [fetchDefaults] = useAsyncAction(() => Observable.fromPromise(dispatch(getDefaults()).unwrap()))

  React.useEffect(() => {
    fetchDefaults()
  }, [])

  const onTimeChange = (t: Date) => {
    dispatch(setSchedule({ time: t.toISOString() }))
  }

  const onDateChange = (value: Date) => {
    dispatch(setSchedule({
      date: value.toISOString(),
      repeatUntilDate: isBefore(value, schedule.repeatUntilDate) ? schedule.repeatUntilDate : value.toISOString()
    }))
  }

  const onRepeatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isRepeatAvailable = dispatch(checkFeatureAvailability(FEATURE_POST_REPEAT))
    if (!isRepeatAvailable) {
      return
    }
    const value = e.target.checked
    dispatch(setSchedule({ repeat: value }))
  }

  const onRepeatCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let count: any = e.target.value
    count = parseInt(count, BASE_TEN)
    if (Number.isNaN(count) || count < 0) {
      count = 0
    }
    dispatch(setSchedule({ repeatCount: count }))
  }

  const onRepeatPeriodChange = (e: SelectChangeEvent) => {
    dispatch(setSchedule({ repeatPeriod: e.target.value as RepeatPeriod }))
  }

  const onRepeatDateChange = (value: Date) => {
    dispatch(setSchedule({
      repeatUntil: 'date',
      repeatUntilDate: value.toISOString()
    }))
  }

  const onRepeatUntilChange = (e: React.ChangeEvent<any>) => {
    const value = e.target.value
    dispatch(setSchedule({ repeatUntil: value }))
  }

  const submit = React.useCallback(() => {
    try {
      const postSchedule = getPostScheduleFromComposerState(schedule, timezone)
      const now = new Date()
      if (now.getTime() + MS_IN_MINUTE - new Date(postSchedule.postDate).getTime() > 0) {
        setError(SCHEDULE_ERROR_INVALID_TIME)
        return
      }
      setError(null)

      props.onSubmit()
    } catch (e) {
      props.onError(e)
    }
  }, [props.onSubmit, props.onError, schedule, timezone])

  // EXPL: there's an issue with DatePicker, using `new Date()` for minDate doesn't work as expected.
  // A date with Year, Month and Date set works ok
  const minDate = new Date(props.initialTime.getFullYear(), props.initialTime.getMonth(), props.initialTime.getDate())

  return (
    <section className={props.className || ''} data-testid="composer-schedule">
      <h4 className={styles.heading}>
        <FormattedMessage id="composer.labels.schedule-title" />
      </h4>
      <div className={styles.row}>
        <div>
          <TimePicker value={new Date(schedule.time)} ampm onChange={onTimeChange} />
        </div>
        <div>
          <DatePicker
            value={new Date(schedule.date)}
            onChange={onDateChange}
            minDate={minDate}
          />
        </div>
        <div className={styles['tz-box']}>
          {timezone}
          <Tooltip placement="top" title={<FormattedMessage id="composer.labels.timezone-hint" />}>
            <HelpIcon className={styles['icon-help']} />
          </Tooltip>
        </div>
      </div>

      <div className={styles.row}>
        <div>
          <span>
            <FormControlLabel
              label={<FormattedMessage id="composer.labels.repeat" />}
              classes={{ label: styles.label, root: styles['cb-root'] }}
              control={(
                <Checkbox
                  checked={schedule.repeat}
                  color="primary"
                  onChange={onRepeatChange}
                />
              )}
            />
          </span>
        </div>
      </div>
      <div className={`${styles.row} ${styles['row-until']}`}>
        <div>
          <span>
            <TextField
              type="number"
              value={schedule.repeatCount}
              className={styles['input-number']}
              disabled={!schedule.repeat}
              onChange={onRepeatCountChange}
            />
          </span>
        </div>
        <div>
          <span>
            <Select
              value={schedule.repeatPeriod}
              onChange={onRepeatPeriodChange}
              disabled={!schedule.repeat}
              className={styles['repeat-period']}
              classes={{ select: styles.select }}
              MenuProps={{
                classes: { paper: styles['repeat-for-menu'] }
              }}
            >
              {
                REPEAT_OPTIONS.map(option => (
                  <MenuItem
                    key={option.value}
                    className={styles['repeat-for-menu-item']}
                    value={option.value}
                  >
                    <FormattedMessage id={`composer.labels.${option.label}`} />
                  </MenuItem>
                ))
              }
            </Select>
          </span>
        </div>
        <div className={styles['group-until']}>
          <span>
            <span className={`${styles.label}`}>
              <FormattedMessage id="composer.labels.until" />:
            </span>
          </span>
          <span className={styles['radio-group-wrapper']}>
            <RadioGroup
              value={schedule.repeatUntil}
              onChange={onRepeatUntilChange}
              className={styles['radio-group']}
            >
              <FormControlLabel
                className={styles.label}
                value="forever"
                classes={{ label: styles.label }}
                control={<Radio className={styles['radio-btn']} color="primary" disabled={!schedule.repeat} />}
                label={<FormattedMessage id="composer.labels.forever" />}
              />
              <FormControlLabel
                className={styles.label}
                value="date"
                control={<Radio className={styles['radio-btn']} color="primary" disabled={!schedule.repeat} />}
                label={(
                  <span className={styles['calendar-until']}>
                    <DatePicker
                      value={new Date(schedule.repeatUntilDate)}
                      minDate={new Date(schedule.date)}
                      disabled={!schedule.repeat}
                      onChange={onRepeatDateChange}
                    />
                  </span>
                )}
              />
            </RadioGroup>
          </span>
        </div>
      </div>

      <div className={styles.actions} data-testid="composer-schedule-actions">
        {error && (
          <div className={styles.error}>{error}</div>
        )}
        {!props.hideActions && (
          <div className={styles['buttons-wrapper']}>
            <Button onClick={props.onCancel} className={styles.button}>
              <FormattedMessage id="actions.cancel" />
            </Button>
            <Button
              className={styles.button}
              variant="contained"
              color="primary"
              disabled={props.submitDisabled}
              onClick={submit}
            >
              <FormattedMessage id="composer.actions.schedule" values={{ count: selectedProfiles.length }} />
            </Button>
          </div>
        )}
      </div>
    </section>
  )
}

export default ComposerScheduleForm
