import { initialState } from './state'
import {
  getLatestSearches,
  addLatestSearch,
  getRecentlyViewed,
  addRecentlyViewed
} from './latest/actions'

import { createSlice } from '@reduxjs/toolkit'

const searchSlice = createSlice({
  name: 'search',
  initialState: initialState(),
  extraReducers: builder => {
    builder.addCase(getLatestSearches.fulfilled, (state, action) => {
      state.latest.searches = action.payload
    })

    builder.addCase(addLatestSearch.fulfilled, (state, action) => {
      const newQuery = action.payload
      const qIndex = state.latest.searches.indexOf(newQuery)
      if (qIndex !== -1) {
        state.latest.searches.splice(qIndex, 1)
      }
      state.latest.searches.unshift(newQuery)
    })

    builder.addCase(getRecentlyViewed.fulfilled, (state, action) => {
      state.latest.sources = action.payload
    })

    builder.addCase(addRecentlyViewed.fulfilled, (state, action) => {
      const newSource = action.payload
      const sourceIndex = state.latest.sources
        .findIndex(s => s.id === newSource.id && (s as any).name === newSource.name) // compare id and type (feed/stream)
      if (sourceIndex !== -1) {
        state.latest.sources.splice(sourceIndex, 1)
      }
      state.latest.sources.unshift(newSource)
    })

  },
  reducers: {}
})

export default searchSlice.reducer
