import * as React from 'react'
import { BRAND_TWITTER, BRAND_LINKEDIN, PostDestinationType, Feed, BRAND_FACEBOOK } from 'interfaces'
import { Subject } from 'rxjs/Subject'
import { debounceTime } from 'rxjs/operators/debounceTime'
import { filter } from 'rxjs/operators/filter'
import { map } from 'rxjs/operators/map'
import { distinctUntilChanged } from 'rxjs/operators/distinctUntilChanged'
import { tap } from 'rxjs/operators/tap'
import useAsyncAction from 'hooks/useAsyncAction'
import FeedsList from 'components/FeedsList'
import EmojiPicker from 'components/EmojiPicker'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Paper from '@mui/material/Paper'
import TagPopup from 'components/TagPopup'
import { Observable } from 'rxjs/Observable'

import styles from './TextEditor.pcss'
import { useDispatch } from 'react-redux'
import { checkFeatureAvailability } from 'services/product'
import { FEATURE_POST_TAGGING } from 'shared/constants'
import { COMPOSER_STATUS_KEY_GENERIC } from 'services/compose/state'
import { FormattedMessage } from 'react-intl'
import Button from '@mui/material/Button'
import { getTextFromHtml } from 'utils/composer'
import { StoreThunkDispatch } from 'store/state'

const NODE_NAME_TEXT = '#text'
const MIN_TAG_SEARCH_LENGTH = 2
const MIN_LI_TAG_SEARCH_LENGTH = 3

const CHANGE_DEBOUNCE_TIME = 250
const TAG_DEBOUNCE_TIME = 50

export type CaptionPlaceholder = 'title' | 'description' | 'link' | 'caption' | 'credit'
export interface TextEditorProps {
  /**
   * Optional HTML string to override the current input value
   *
   * @type {string}
   * @memberof TextEditorProps
   */
  id?: string
  html?: string
  initialHTML: string
  hidden?: boolean
  disabled?: boolean
  network?: PostDestinationType | typeof COMPOSER_STATUS_KEY_GENERIC
  withEmojis?: boolean
  inputClassName?: string
  footerElements?: React.ReactNode
  footerClassName?: string
  placeholder?: string
  disabledClassName?: string
  containerClassName?: string
  templateStrings?: CaptionPlaceholder[]
  tabIndex?: number
  onChange: (text: string, html: string) => void
  searchTags?: (needle: string) => Observable<Feed[]>
}

export function TextEditor(props: TextEditorProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  let searchTagsFn = props.searchTags
  if (!searchTagsFn) {
    searchTagsFn = () => Observable.of([])
  }
  const selection = React.useRef<Selection | null>(window.getSelection())
  const inputElementRef = React.useRef<HTMLDivElement>(null)
  const change$ = React.useRef<Subject<string>>(new Subject<string>())
  const [tagsSearch, tagsResponse, _tagsError, tagsLoading] = useAsyncAction(searchTagsFn)
  const [tags, setTags] = React.useState<Feed[]>([])
  const [tagsDropdownAnchor, setTagsDropdownAnchor] = React.useState<HTMLElement | null>(null)
  const [tagPopupAnchor, setTagPopupAnchor] = React.useState<HTMLElement | null>(null)

  React.useEffect(() => {
    if (!tagsLoading && tagsResponse) {
      setTags(tagsResponse)
    }
  }, [tagsLoading, tagsResponse])

  // NOTE: Observe input subtree changes.
  // If active tag element is removed, we must close the tag suggestions dropdown
  const onDOMChanged = (mutations: MutationRecord[], _observer: MutationObserver) => {
    for (const m of mutations) {
      if (m.removedNodes.length > 0) {
        setTagPopupAnchor(null)
        closeTagsDropdown()
        return
      }
    }
  }
  const DOMObserverRef = React.useRef<MutationObserver>(new MutationObserver(onDOMChanged))

  React.useEffect(() => {
    const observer = DOMObserverRef.current
    const element = inputElementRef.current
    if (observer && element) {
      observer.observe(element, { subtree: true, childList: true })
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  const onTagMouseEnter = React.useCallback((e: Event) => {
    const element = e.target as HTMLElement
    setTagPopupAnchor(element)
  }, [])

  const onTagMouseLeave = React.useCallback(() => {
    setTagPopupAnchor(null)
  }, [])

  React.useEffect(() => {
    const input = inputElementRef.current
    if (input) {
      input.innerHTML = props.initialHTML
    }
  }, [])

  React.useEffect(() => {
    const input = inputElementRef.current
    if (typeof props.html === 'string' && input && input.innerHTML !== props.html) {
      input.innerHTML = props.html
    }
  }, [props.html])

  React.useEffect(() => {
    document.querySelectorAll('[data-tag-id]').forEach(element => {
      element.addEventListener('mouseenter', onTagMouseEnter)
      element.addEventListener('mouseleave', onTagMouseLeave)
    })
  }, [onTagMouseEnter, onTagMouseLeave])

  /**
   * Replaces the tag element with new text node with the same text contents.
   * @param node
   * @param active
   * @returns {Text} the new created node
   */
  const removeTagFromNode = React.useCallback((node: HTMLElement): Text => {
    const text = node.textContent || ''
    const newElement = document.createTextNode(text)
    node.replaceWith(newElement)
    return newElement
  }, [])

  const saveChanges = React.useCallback(() => {
    const inputElement = inputElementRef.current
    if (inputElement) {
      // EXPL: Save selection to restore it after text is properly generated from html
      const currentRange = selection.current && selection.current.rangeCount > 0 ? selection.current.getRangeAt(0) : null
      const html = inputElement.innerHTML
      // NOTE: innerText does not work here because of how new lines are presented. Use `getTextFromHtml` instead
      const text = getTextFromHtml(html)
      props.onChange(text, html)
      // Restore selection
      if (currentRange && selection.current) {
        selection.current.removeAllRanges()
        selection.current.addRange(currentRange)
      }
    }
  }, [props])

  React.useEffect(() => {
    const sub = change$.current.pipe(
      debounceTime(CHANGE_DEBOUNCE_TIME),
      tap(saveChanges),
      filter(() => Boolean(props.searchTags)),
      // Allow for changes to be propagated (props.html to be updated) before altering the html for tags search
      debounceTime(TAG_DEBOUNCE_TIME),
      map(() => {
        if (!selection.current) {
          return ''
        }

        const { focusNode, focusOffset } = selection.current
        if (!focusNode || focusNode.isSameNode(inputElementRef.current)) {
          return ''
        }

        const parent = focusNode.nodeName === NODE_NAME_TEXT
          ? focusNode.parentElement as HTMLElement
          : focusNode as HTMLElement
        const focusString = focusNode.textContent?.substring(0, focusOffset)
        const tagStart = parent.dataset.tagId ? 0 : focusString?.lastIndexOf('@')
        const needle = tagStart !== undefined && tagStart !== -1
          ? focusString?.substring(tagStart + 1)
          : ''
        if (!needle || tagStart === undefined || tagStart === -1) {
          setTags([])
          closeTagsDropdown()
          return ''
        }

        if (parent.dataset.tagId) {
          removeTagFromNode(parent)
          return ''
        }

        if (parent.dataset.tagActive) {
          setTagsDropdownAnchor(parent)
        } else {
          const range = new Range()
          const wrapper = document.createElement('span')
          wrapper.dataset.tagActive = 'true'
          range.setStart(focusNode, tagStart)
          range.setEnd(focusNode, focusOffset)
          range.surroundContents(wrapper)
          selection.current.removeAllRanges()
          selection.current.addRange(range)
          selection.current.collapse(wrapper.firstChild, (wrapper.firstChild as any)?.length || 0)
          setTagsDropdownAnchor(wrapper)
        }
        return needle.trim()
      }),
      distinctUntilChanged(),
      filter(needle => !!needle && needle.length >= (props.network === BRAND_LINKEDIN ? MIN_LI_TAG_SEARCH_LENGTH : MIN_TAG_SEARCH_LENGTH))
    ).subscribe(tagsSearch)
    return () => {
      sub.unsubscribe()
    }
  }, [])

  const onKeyboardInput = React.useCallback(() => {
    change$.current.next()
  }, [])

  const createTagNode = React.useCallback((feed: Feed) => {
    const newTagElement = document.createElement('a')
    newTagElement.contentEditable = 'false'
    // NOTE: For FB feeds handle is the feed url. For twitter, it's only the handle so we build the full url here.
    let tagUrl
    switch (props.network) {
      case BRAND_TWITTER:
        tagUrl = `https://twitter.com/${feed.handle}`
        break
      case BRAND_FACEBOOK:
        tagUrl = feed.handle
        break
      case BRAND_LINKEDIN:
        tagUrl = `https://linkedin.com/company/${feed.handle}`
        break
      default: tagUrl = ''
    }
    newTagElement.href = tagUrl
    newTagElement.target = '_blank'
    newTagElement.rel = 'noopener'
    newTagElement.dataset.tagId = feed.id
    newTagElement.dataset.tagUrl = tagUrl
    newTagElement.dataset.tagName = feed.title || feed.name
    newTagElement.dataset.tagHandle = feed.name
    newTagElement.dataset.tagImage = feed.image
    newTagElement.className = styles.tag
    newTagElement.innerText = feed.name

    newTagElement.addEventListener('mouseenter', onTagMouseEnter)
    newTagElement.addEventListener('mouseleave', onTagMouseLeave)
    return newTagElement
  }, [props.network, onTagMouseEnter, onTagMouseLeave])

  const closeTagsDropdown = React.useCallback(() => {
    setTagsDropdownAnchor(null)
    if (tagsDropdownAnchor?.dataset.tagActive) {
      tagsDropdownAnchor.replaceWith(tagsDropdownAnchor.innerText)
    }
    inputElementRef.current?.querySelector('[data-tag-active]')?.removeAttribute('data-tag-active')
  }, [tagsDropdownAnchor])

  const onTagSelected = React.useCallback((feed: Feed) => {
    const isTaggingAvailable = dispatch(checkFeatureAvailability(FEATURE_POST_TAGGING))
    if (!isTaggingAvailable) {
      closeTagsDropdown()
      return
    }
    const activeTagElement = document.querySelector('[data-tag-active]') as HTMLElement

    if (activeTagElement) {
      const newTagElement = createTagNode(feed)
      activeTagElement.replaceWith(newTagElement)
      newTagElement.insertAdjacentHTML('afterend', '&nbsp;')
      if (newTagElement.nextSibling) {
        const range = new Range()
        range.setStartAfter(newTagElement.nextSibling)
        range.setEndAfter(newTagElement.nextSibling)
        selection.current?.removeAllRanges()
        selection.current?.addRange(range)
      }
      saveChanges()
    }
    closeTagsDropdown()
  }, [createTagNode, saveChanges, closeTagsDropdown])

  const onPaste = React.useCallback((event: React.ClipboardEvent) => {
    event.preventDefault()
    // Paste text only, without formatting
    const text = event.clipboardData.getData('text/plain').replace(/\r/g, '')
    if (inputElementRef.current && selection.current && selection.current.rangeCount > 0) {
      const range = selection.current.getRangeAt(0)
      const node = document.createElement('span')
      node.dataset.external = 'true'
      node.textContent = text.replace(/\n+$/, '') // remove trailing newline
      node.style.whiteSpace = 'pre-wrap'
      range.deleteContents()
      range.insertNode(node)
      // Remove extra new line if it exists
      if (node.nextElementSibling?.nodeName === 'BR') {
        node.nextElementSibling.remove()
      }
      range.collapse()
      change$.current.next()
    }
  }, [])

  const insertEmoji = React.useCallback((emoji: any) => {
    if (inputElementRef.current && selection.current) {
      const currentValue = inputElementRef.current?.innerText
      const focusNode = selection.current.focusNode as Text
      const focusWithinEditor = Boolean(focusNode?.parentElement?.closest('[data-composer-editor]'))
      if (focusWithinEditor && focusNode.insertData) {
        focusNode.insertData(selection.current.focusOffset, emoji.native)
      } else {
        inputElementRef.current.innerText = currentValue + emoji.native
      }
      change$.current.next()
    }
  }, [])

  const onKeyDown = React.useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      closeTagsDropdown()
    }
    if (!selection.current || selection.current.isCollapsed) {
      return
    }

    // EXPL: Bugfix - can't delete a tag when selected.
    // Check if a tag is selected and remove the node programatically.
    const data = selection.current.focusNode?.parentElement?.dataset
    if (selection.current.focusNode === selection.current.anchorNode && (data?.tagId || data?.code)) {
      const tagElement = selection.current.focusNode?.parentElement
      tagElement?.remove()
      if (e.key === 'Backspace' || e.key === 'Delete') {
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }, [closeTagsDropdown])

  const preventDefaultEvent = React.useCallback((e: any) => {
    e.preventDefault()
  }, [])

  const addPlaceholder = (placeholder: CaptionPlaceholder) => {
    if (selection.current) {
      const focusNode = selection.current.focusNode
      const focusWithinEditor = Boolean(focusNode?.parentElement?.closest('[data-composer-editor]'))
        && inputElementRef.current?.contains(focusNode)
      const placeholderElement = document.createElement('span')
      placeholderElement.textContent = `[[${placeholder}]]`
      placeholderElement.classList.add(styles.placeholder)
      placeholderElement.contentEditable = 'false'
      placeholderElement.dataset.code = placeholder

      if (focusWithinEditor && selection.current?.rangeCount > 0) {
        const range = selection.current.getRangeAt(0)
        range.deleteContents()
        range.insertNode(placeholderElement)
        selection.current.collapseToEnd()
      } else {
        inputElementRef.current?.appendChild(placeholderElement)
      }
      change$.current.next()
    }
  }

  const disabledClassName = props.disabled ? props.disabledClassName : ''
  const withTextActions = props.templateStrings && props.templateStrings.length > 0

  return (
    <div className={`${styles.container} ${props.containerClassName}`} data-testid={props.id}>
      <div
        ref={inputElementRef}
        tabIndex={props.tabIndex}
        contentEditable={!props.disabled}
        data-placeholder={props.disabled ? '' : props.placeholder || 'Add your text or link here'}
        data-composer-editor
        className={`${styles.content} ${disabledClassName} ${props.inputClassName || ''} ${props.hidden ? styles.hidden : ''}`}
        onInput={onKeyboardInput}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        onDragOver={preventDefaultEvent}
        onDrop={preventDefaultEvent}
      >
      </div>
      {!props.disabled && (
        <div className={`${styles.actions} ${props.footerClassName || ''} ${withTextActions ? styles['w-text-actions'] : ''}`}>
          {props.footerElements}
          {props.withEmojis && (
            <EmojiPicker
              className={styles['btn-emoji']}
              onSelect={insertEmoji}
            />
          )}
        </div>
      )}
      {withTextActions && (
        <div className={styles['text-actions']}>
          {props.templateStrings && props.templateStrings.map(placeholder => (
            <PlaceholderActionButton key={placeholder} placeholder={placeholder} onClick={addPlaceholder} />
          ))}
          <div className={styles.label}>
            <FormattedMessage id="composer.caption-placeholder.hint" />
          </div>
        </div>
      )}
      <ClickAwayListener onClickAway={closeTagsDropdown}>
        <Popper
          open={Boolean(tagsDropdownAnchor)}
          anchorEl={tagsDropdownAnchor}
          placement="bottom-start"
          className={styles.popper}
        // MUI Not supported: modifiers={{ preventOverflow: { priority: ['top', 'bottom'] } }}
        >
          <Paper className={styles['popper-content']}>
            <FeedsList
              feeds={tags}
              loading={tagsLoading}
              emptyResultsMessage="No results found."
              onFeedClick={onTagSelected}
            />
          </Paper>
        </Popper>
      </ClickAwayListener>
      <TagPopup anchor={tagPopupAnchor} />
    </div>
  )
}

function PlaceholderActionButton(props: { placeholder: CaptionPlaceholder, onClick: (value: CaptionPlaceholder) => void }) {
  const click = (e: any) => {
    props.onClick(props.placeholder)
    // EXPL: prevent subsequent clicks triggered by "space" or "enter" key press
    ; (e.target as HTMLButtonElement).blur()
  }

  return (
    <Button className={styles.btn} onClick={click}>
      <FormattedMessage id={`composer.caption-codes.${props.placeholder}`} />
    </Button>
  )
}

export default TextEditor
