import React from 'react'
import styles from './BucketOptions.pcss'
import { LocalizationProvider, DateRange } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { useIntl } from 'react-intl'
import Icon from '@mdi/react'
import { mdiCalendarClock } from '@mdi/js'

interface BucketOptionsProps {
  dateRange: DateRange<Date>
  disabled?: boolean
  onChange: (range: DateRange<Date>) => void
}

export function BucketOptions(props: BucketOptionsProps) {
  const intl = useIntl()
  const { dateRange, disabled } = props

  const onChange = (range: DateRange<Date>) => {
    if (range[0] && range[1]) {
      props.onChange(range)
    }
  }

  return (
    <div className={`${styles.container} ${disabled ? styles.disabled : ''}`}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={{
          start: intl.formatMessage({ id: 'label.generic.from' }),
          end: intl.formatMessage({ id: 'label.generic.to' })
        }}
      >
        <DateRangePicker
          autoFocus={false}
          value={[dateRange[0], dateRange[1]]}
          format="MMMM d yyyy"
          className={styles['range-box']}
          slotProps={{
            textField: {
              InputProps: {
                startAdornment: <Icon path={mdiCalendarClock} size="20px" />,
                classes: { root: styles['btn-inner'], input: styles['btn-input'] }
              },
              classes: { root: styles['btn-root'] }
            },
            fieldSeparator: { children: <span className={styles.divider}>to</span> }
          }}
          localeText={{ start: '', end: '' }}
          onChange={onChange}
        />
      </LocalizationProvider>
    </div>
  )
}
