import ErrorOverlay from 'components/ErrorOverlay'
import { WithIntl } from 'interfaces'
import * as React from 'react'
import { injectIntl } from 'react-intl'
import styles from './EmptyContentView.pcss'

export type ContentGridView = 'stock-image'
  | 'library-image'
  | 'stock-video'
  | 'library-video'
  | 'photo-favorites'
  | 'text-favorites'
  | 'quotes'
  | 'statuses'
interface EmptyContentViewProps {
  contentView: ContentGridView
  className?: string
}

export function EmptyContentView(props: EmptyContentViewProps & WithIntl) {
  const messages = React.useMemo(() => {
    return {
      'stock-image': props.intl.formatMessage({ id: 'search.label.no-stock-results' }),
      'library-image': props.intl.formatMessage({ id: 'uploads.error.empty-view-carousel-title' }),
      'stock-video': props.intl.formatMessage({ id: 'search.label.no-stock-results' }),
      'library-video': props.intl.formatMessage({ id: 'uploads.error.empty-view-carousel-title' }),
      'photo-favorites': props.intl.formatMessage(
        { id: 'content.msg.carousel.favorites-empty' },
        { contentType: props.intl.formatMessage({ id: 'general.content.filters.photos' }) }
      ),
      'text-favorites': props.intl.formatMessage(
        { id: 'content.msg.carousel.favorites-empty' },
        { contentType: props.intl.formatMessage({ id: 'content-studio.filters.text-favorites' }) }
      ),
      quotes: props.intl.formatMessage({ id: 'content.msg.status-ideas-search-empty' }),
      statuses: props.intl.formatMessage({ id: 'content.msg.status-ideas-search-empty' })
    }
  }, [props.intl])

  return (
    <div className={`${styles['empty-container']} ${props.className || ''}`}>
      <ErrorOverlay
        error={messages[props.contentView]}
        subtitle=""
        originalError="empty content view"
        className={styles['error-box']}
      />
    </div>
  )
}

export default injectIntl(EmptyContentView)
