/* eslint-disable max-len */
import * as React from 'react'

function FeatherIcon({ className, style, color }: { className?: string, style?: React.CSSProperties, color?: string }) {
  return (
    <svg className={className || ''} style={Object.assign({ width: 24, height: 24 }, style)} viewBox="0 0 24 24">
      <path fill={color || '#fff'} d="M22,2C22,2 14.36,1.63 8.34,9.88C3.72,16.21 2,22 2,22L3.94,21C5.38,18.5 6.13,17.47 7.54,16C10.07,16.74 12.71,16.65 15,14C13,13.44 11.4,13.57 9.04,13.81C11.69,12 13.5,11.6 16,12L17,10C15.2,9.66 14,9.63 12.22,10.04C14.19,8.65 15.56,7.87 18,8L19.21,6.07C17.65,5.96 16.71,6.13 14.92,6.57C16.53,5.11 18,4.45 20.14,4.32C20.14,4.32 21.19,2.43 22,2Z" />
    </svg>
  )
}

export default FeatherIcon
