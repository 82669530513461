import { createElement } from 'react'
import Loader from 'components/SimpleLoader'
import loadable, { LoadableComponent } from '@loadable/component'
import { lazyLoad } from 'utils/chunk'

const AsyncSingleFeedView: LoadableComponent<any> = loadable(() => lazyLoad(
  () => import(
    /* webpackPrefetch: true */
    /* webpackChunkName: 'feed-view' */
    './SingleFeedView'
  ),
  'feed-view'
), {
  fallback: createElement(Loader, { title: 'Loading Feed...' })
})

export default AsyncSingleFeedView
