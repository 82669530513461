import { AnyAction } from 'store/state'
import { ACTION_SET_LOCATIONS, ACTION_SET_UPGRADE_LOCATION_DIALOG_OPEN } from './actions'
import { ListingsState, initialState } from './state'

export function listingsReducer(state = initialState(), action: AnyAction): ListingsState {
  switch (action.type) {
    case ACTION_SET_LOCATIONS:
      return {
        ...state,
        locationsUrl: action.payload.locationsUrl,
        reviewsUrl: action.payload.reviewsUrl,
        reviewCampaignsUrl: action.payload.reviewCampaignsUrl,
        locationsLimit: action.payload.allowedLocations,
        locations: (action.payload.locations || []).map((loc: any) => ({ ...loc, url: loc.listingsUrl }))
      }

    case ACTION_SET_UPGRADE_LOCATION_DIALOG_OPEN:
      return {
        ...state,
        upgradeLocationDialogOpen: action.payload
      }

    default:
      return state
  }
}

export default listingsReducer
