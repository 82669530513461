import { CANVA_API_KEY } from 'config'
import { Component, Children } from 'react'

type WindowWithCanva = Window & {
  canva: any
}

declare const window: WindowWithCanva

export interface CanvaProviderProps {

}

interface CanvaProviderState {
}

/**
 * Inject the Canva SDK into the DOM
 *
 * @returns {HTMLScriptElement}
 */
function injectCanvaAPI(onLoad: () => void): HTMLScriptElement {
  return (function (d, s, id) {

    const element = d.getElementById(id) as HTMLScriptElement
    if (typeof window.canva === 'function') {
      return element as HTMLScriptElement
    }

    const fjs = d.getElementsByTagName(s)[0]
    const target = (fjs && fjs.parentNode) || document.head
    const js = d.createElement(s) as HTMLScriptElement
    js.id = id
    js.type = 'text/javascript'
    js.async = true
    js.onload = onLoad
    js.src = 'https://sdk.canva.com/designbutton/v2/api.js'
    target.insertBefore(js, fjs)
    return js
  })(document, 'script', 'canva-api')

}

export class CanvaProvider extends Component<CanvaProviderProps, CanvaProviderState> {

  private scriptCanvaAPI: HTMLScriptElement | undefined

  constructor(props: CanvaProviderProps) {
    super(props)
    this.scriptCanvaAPI = injectCanvaAPI(() => {
      if ((window as any).Canva && (window as any).Canva.DesignButton) {
        (window as any).Canva.DesignButton.initialize({
          apiKey: CANVA_API_KEY
        }).then((api: any) => {
          (window as any).CanvaAPI = api
        })
      }
    })
  }

  shouldComponentUpdate(_: CanvaProviderProps) {
    return false
  }

  componentWillUnmount() {
    if (this.scriptCanvaAPI) {
      this.scriptCanvaAPI.remove()
      this.scriptCanvaAPI = undefined
    }
  }

  render() {
    return null
  }
}

export default CanvaProvider
