import TextField from '@mui/material/TextField/TextField'
import React from 'react'
import { useIntl } from 'react-intl'
import styles from './BulkPost.pcss'
import { ComposerYoutubePost } from 'services/compose/interfaces/ComposerYoutubePost'
import { MAX_YT_TITLE_LENGTH } from 'shared/constants'

interface BulkPostYoutubeStatusProps {
   post: ComposerYoutubePost
    onTitleChange: (e: any) => void
    onDescriptionChange: (e: any) => void
  }
export function BulkPostYoutubeStatus({ post, onTitleChange, onDescriptionChange }: BulkPostYoutubeStatusProps) {
  const intl = useIntl()

  return (
    <div>
      <TextField
        value={post.title}
        placeholder={intl.formatMessage({ id: 'composer.labels.youtube-title-placeholder' })}
        classes={{ root: styles['title-box'] }}
        FormHelperTextProps={{ className: styles.helper }}
        helperText={(MAX_YT_TITLE_LENGTH - post.title.length).toString()}
        onChange={onTitleChange}
      />
      <TextField
        value={post.description}
        placeholder={intl.formatMessage({ id: 'composer.labels.youtube-description-placeholder' })}
        className={styles['description-box']}
        InputProps={{
          classes: {
            root: styles['description-input']
          }
        }}
        FormHelperTextProps={{ className: styles.helper }}
        onChange={onDescriptionChange}
      />
    </div>
  )
}
