import { TIMESTAMP_MULTIPLIER } from './parseDate'
import { formatDistanceToNow } from 'date-fns'

const TIMESTAMP_CONTROL_VALUE = 2147483647

export function fromNow(date: string | Date | number) {
  // Timestamp as string
  if ((typeof date === 'string' && (+date + '').length === date.length)
    // EXPL: Temp fix for number-formatted dates
    || (typeof date === 'number' && date < TIMESTAMP_CONTROL_VALUE)) {
    date = +date * TIMESTAMP_MULTIPLIER
  }

  return formatDistanceToNow(date, { addSuffix: true })
}

export default fromNow
