import * as React from 'react'
import { injectIntl } from 'react-intl'
import { WithIntl, MyFile, PHOTO_TYPE, GIF_TYPE, VIDEO_TYPE } from 'interfaces'
import { NativeVideoPlayer } from 'components/VideoPlayer'
import DeleteIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import GifPlayer from 'components/GifPlayer'

import styles from './MemoryFilePreviewCard.pcss'

export interface MyFilePreviewProps extends WithIntl {
  file: MyFile
  className?: string
  onClick?: (fileName: string) => void
  onDelete?: (fileName: string) => void
}

export const MyFilePreview = React.memo(function (props: MyFilePreviewProps) {
  const handleClick = () => {
    if (props.onClick) {
      props.onClick(props.file.name)
    }
  }

  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete(props.file.name)
    }
  }

  const fileSrc = props.file.url
  const fileType = props.file.type

  return (
    <div
      className={`${styles.wrapper} ${props.className || ''}`}
      onClick={handleClick}
    >
      <div className={styles.content}>
        {props.onDelete && (
          <IconButton size="small" className={styles['btn-delete']} onClick={onDelete}>
            <DeleteIcon className={styles.icon} />
          </IconButton>
        )}
        {fileType === PHOTO_TYPE && (
          <img src={fileSrc} className={styles.image} />
        )}
        {fileType === GIF_TYPE && (
          <GifPlayer gifDataUrl={fileSrc} gifUrl="" />
        )}
        {fileType === VIDEO_TYPE && (
          <NativeVideoPlayer
            videoUrl={fileSrc}
            togglePlayOnHover
            muted
            className={styles.video}
            playBtnClassName={styles['btn-play']}
          />
        )}
      </div>
    </div>
  )
})

export default injectIntl(MyFilePreview)
