import * as React from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import styles from './AppNavigation.pcss'
import ContentNav from './ContentNav'
import { SettingsNav } from './SettingsNav'
import PublishingNav from './publishing/PublishingNav'
import SeoNav from './SeoNav'
import { MainNav } from './MainNav'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'
import { mdiFeather } from '@mdi/js'
import Icon from '@mdi/react'
import { useSelector } from 'react-redux'
import ImageIcon from '@mui/icons-material/Collections'
import VideoIcon from '@mui/icons-material/Videocam'
import LinkIcon from '@mui/icons-material/Link'
import BulkIcon from '@mui/icons-material/PlaylistAdd'
import {
  composerContentItemSelector,
  composerImageUrlsSelector,
  composerLinkSelector,
  composerMultiPostSelector,
  composerSelectedProfilesSelector,
  composerTabsSelector,
  composerVideoUrlSelector,
  multiPostsCountSelector
} from 'services/compose/selectors'
import { ARTICLE_TYPE, GIF_TYPE, BRAND_TWITTER, VIDEO_TYPE, IndexedObject } from 'interfaces'
import { selectedContentItemsSelector } from 'services/content/selectors'
import { BulkContentPost } from 'services/compose/state'
import { groupByKey, numberToHuman } from 'shared/utils'
import { RouteErrorView } from '../ErrorFallback/RouteErrorView'
import { TrainingNav } from './TrainingNav'

export function AppNavigationSidebar() {
  const location = useLocation()
  const navigate = useNavigate()

  const openComposer = React.useCallback(() => {
    navigate('/composer', { state: { floating: true, modal: 'compose', opener: location.pathname, background: location } })
  }, [navigate, location])

  return (
    <div className={`${styles.nav} ${styles.expanded}`}>
      <MainNav className={styles['main-nav']} />
      <div className={styles.subnav}>
        <div className={styles['btn-box']}>
          <ComposerButton onClick={openComposer} />
        </div>
        <div className={styles.scrollable}>
          <Routes>
            <Route path="*" errorElement={<RouteErrorView />}>
              <Route path="content/search/*" element={<ContentNav expanded />} />
              <Route path="content/*" element={<ContentNav expanded />} />
              <Route path="reviews/*" element={<SeoNav expanded />} />
              <Route path="settings/*" element={<SettingsNav />} />
              <Route path="calendar/*" element={<PublishingNav activeView="calendar" />} />
              <Route path="plans/*" element={<PublishingNav activeView="plans" />} />
              <Route path="posts/buckets/*" element={<PublishingNav activeView="posts" hideProfiles />} />
              <Route path="posts/*" element={<PublishingNav activeView="posts" />} />
              <Route path="history/*" element={<PublishingNav activeView="history" />} />
              <Route path="training/*" element={<TrainingNav />} />
              <Route path="*" element={null} />
            </Route>
          </Routes>
        </div>
      </div>
    </div>
  )
}

function ComposerButton({ onClick }: { onClick: () => void }) {
  const selectedContentItems = useSelector(selectedContentItemsSelector)
  const multiPost = useSelector(composerMultiPostSelector)
  const multiPostsCount = useSelector(multiPostsCountSelector)
  const selectedNetworks = useSelector(composerTabsSelector)
  const profiles = useSelector(composerSelectedProfilesSelector)
  const profilesByNetwork = groupByKey(profiles, 'type')

  const postsByType = Object.values(multiPost.posts).reduce((map: IndexedObject<number>, post: BulkContentPost) => {
    if (!selectedNetworks.includes(post.network)) {
      return map
    }

    const profilesCount = profilesByNetwork[post.network]?.length || 0
    if (post.imageUrl) {
      map.photos += profilesCount
    } else {
      map.articles += profilesCount
    }
    return map
  }, { photos: 0, articles: 0 })
  const composerImages = useSelector(composerImageUrlsSelector)
  const composerContentItem = useSelector(composerContentItemSelector)
  const composerLink = useSelector(composerLinkSelector)
  const composerVideoUrl = useSelector(composerVideoUrlSelector)
  const isLinkPost = composerContentItem?.type === ARTICLE_TYPE || composerLink?.type === 'link'
  const isTwitterGif = composerContentItem?.type === GIF_TYPE && composerContentItem?.feed.type === BRAND_TWITTER
  const isVideoPost = Boolean(composerVideoUrl)
    || composerLink?.type === 'video_link'
    || isTwitterGif
    || composerContentItem?.type === VIDEO_TYPE

  if (isLinkPost) {
    postsByType.articles += 1
  }

  postsByType.photos = composerImages.length || postsByType.photos
  const withContentSelection = Object.keys(selectedContentItems).length > 0

  return (
    <Button
      color="primary"
      variant="contained"
      size="small"
      startIcon={<Icon path={mdiFeather} size="20px" />}
      disabled={withContentSelection}
      className={styles['btn-compose']}
      data-testid="btn-create-post"
      onClick={onClick}
    >
      <FormattedMessage id="header.btn-compose" />
      {!withContentSelection && (
        <React.Fragment>
          {multiPostsCount > 0 ? (
            <span className={styles['btn-img']}>
              <BulkIcon className={styles['btn-bulk']} />
              {numberToHuman(multiPostsCount)}
            </span>
          ) : (
            <React.Fragment>
              {postsByType.photos > 0 && (
                <span className={styles['btn-img']}>
                  <ImageIcon className={styles['btn-icon']} />
                  {numberToHuman(postsByType.photos)}
                </span>
              )}
              {(isLinkPost || postsByType.articles > 0) && (
                <span className={styles['btn-img']}>
                  <LinkIcon className={styles['btn-icon']} />
                  {numberToHuman(postsByType.articles)}
                </span>
              )}
              {isVideoPost && (
                <span className={styles['btn-img']}>
                  <VideoIcon className={styles['btn-icon']} />
                </span>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Button>
  )
}

export default AppNavigationSidebar
