import * as React from 'react'
import ScrollListener from 'components/ScrollListener'
import { Navigate, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ADD_FEED, REMOVE_FEED, REPLACE_FEED, searchRequest, updateState } from '../state/actions'
import { SearchContext } from '../state/context'
import styles from './SearchRoot.pcss'
import { AddFeedCard, EmptyFeedCard, EmptyStreamCard, FeedCard, StreamCard } from 'components/Cards'
import {
  ContentFeedType,
  Feed,
  FEED_TYPE_FACEBOOK,
  FEED_TYPE_LINKEDIN,
  FEED_TYPE_REDDIT,
  FEED_TYPE_RSS,
  Stream,
  WithIntl
} from 'interfaces'
import { MAX_FEEDS_IN_STREAM } from 'components/SaveFeedDialog'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'services/snackbar'
import { FormattedMessage, injectIntl } from 'react-intl'
import EmptyView from 'components/EmptyView'
import { SEARCH_FILTER_KEY_RSS, SEARCH_FILTER_KEY_STREAMS, SearchFilterKey } from '../state/types'
import { BRAND_FACEBOOK, BRAND_LINKEDIN } from 'shared'
import CardList, { CARD_LIST_HEIGHT_SMALL, ITEM_WIDTH_SMALL } from 'components/CardList'
import { resetScroll } from 'utils/dom'
import { userDestinationsSelector } from 'services/destinations'

const emitScrollTreshold = 350

function FilteredSearchResultsRoute() {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [state, searchDispatch] = React.useContext(SearchContext)
  const type = params.type?.toLowerCase() as SearchFilterKey
  const category = params.category?.toLowerCase()
  const allowedTypes = Object.keys(state.results).concat(['google'])

  React.useEffect(() => {
    if (type) {
      searchDispatch(updateState({ activeFilter: type, activeCategory: category as any }))
    }
  }, [type, category, searchDispatch])

  const navigateTo = React.useCallback((path: string) => {
    navigate(`${path}?q=${state.query}`, { state: { ...(location.state as any), backUrl: location.pathname } })
  }, [state.query, navigate, location.state, location.pathname])

  if (!allowedTypes.includes(type)) {
    return (
      <Navigate replace to={`/content/search/${category}/${category === 'content' ? 'quotes' : 'streams'}`} />
    )
  }

  return (
    <FilteredSearchResultsView type={type} navigate={navigateTo} />
  )
}

const FilteredSearchResultsView = injectIntl((props: { type: SearchFilterKey, navigate: (path: string) => void } & WithIntl) => {
  const { type, navigate } = props
  const dispatch = useDispatch()
  const profiles = useSelector(userDestinationsSelector)
  const [fbView, setFbView] = React.useState<'default' | 'all'>('default')
  const [state, searchDispatch] = React.useContext(SearchContext)
  const scrollElementRef = React.useRef<HTMLElement | undefined>(undefined)
  const { hasNextPage, items, loading, page } = state.results[type]

  React.useLayoutEffect(() => {
    scrollElementRef.current = document.querySelector('[data-test="main"]') as HTMLElement
    resetScroll()
  }, [])

  const requestNextPage = React.useCallback(() => {
    if (hasNextPage && items.length !== 0 && !loading) {
      searchDispatch(searchRequest(state.query, [type], page + 1))
    }
  }, [hasNextPage, items.length, loading, searchDispatch, state.query, type, page])

  React.useEffect(() => {
    const scrollElement = scrollElementRef.current
    if (scrollElement) {
      const { scrollHeight, scrollTop, clientHeight } = scrollElement
      if (!loading && scrollHeight - clientHeight - scrollTop <= emitScrollTreshold) {
        requestNextPage()
      }
    }
  }, [loading, requestNextPage])

  const onStreamClick = React.useCallback((stream: Stream) => {
    searchDispatch(updateState({ activeFeed: undefined, activeStream: stream }))
    navigate(`/content/search/sources/streams/${stream.id}`)
  }, [navigate, searchDispatch])

  const onFeedClick = React.useCallback((feed: Feed) => {
    searchDispatch(updateState({ activeFeed: feed, activeStream: undefined }))
    const typeSlug = feed.type
    navigate(`/content/search/sources/${typeSlug}/${feed.id}`)
  }, [navigate, searchDispatch])

  const onFeedCreated = React.useCallback((feed: Feed, liveId: string) => {
    // Replace live feed with newly created feed from db
    searchDispatch({
      type: REPLACE_FEED,
      payload: {
        id: liveId,
        newFeed: { ...feed, live: true }
      }
    })
  }, [searchDispatch])

  const onFeedCreateError = React.useCallback((handle: string, type: ContentFeedType) => {
    searchDispatch({
      type: REMOVE_FEED,
      payload: { id: handle, type }
    })
  }, [searchDispatch])

  const toggleFeedSelected = React.useCallback((feed: Feed, replaceFeed?: Feed) => {
    if (state.selectedFeeds?.length >= MAX_FEEDS_IN_STREAM) {
      dispatch(message(props.intl.formatMessage(
        { id: 'search.notifications.max-feeds-limit-reached' },
        { count: MAX_FEEDS_IN_STREAM }
      ), 'warning'))
      return
    }

    if (state.selectedFeeds.find((f: Feed) => f.handle === feed.handle && f.type === feed.type)) {
      searchDispatch({
        type: REMOVE_FEED,
        payload: { id: feed.id, type: feed.type }
      })
      return
    }

    if (replaceFeed) {
      searchDispatch({
        type: REMOVE_FEED,
        payload: { id: replaceFeed.id, newFeed: feed }
      })
      return
    }

    searchDispatch({
      type: ADD_FEED,
      payload: feed
    })
  }, [state.selectedFeeds, searchDispatch, dispatch, props.intl])

  const cards = React.useMemo(() => {
    const defaultCardsCount = 20
    const emptyCardsCount = 140
    const loadingCards: any[] = []
    const emptyCards = []
    if (loading) {
      const count = items.length === 0 ? emptyCardsCount : defaultCardsCount
      if (type === 'streams') {
        for (let i = 0; i < count; i++) {
          loadingCards.push(<EmptyStreamCard loading key={`loading-${i}`} className={styles.card} />)
        }
      } else {
        for (let i = 0; i < count; i++) {
          loadingCards.push(<EmptyFeedCard loading key={`loading-${i}`} className={styles.card} />)
        }
      }
    }
    if (!loading && items.length === 0) {
      if (type === 'streams') {
        for (let i = 0; i < emptyCardsCount; i++) {
          emptyCards.push(<EmptyStreamCard flat key={`empty-${i}`} className={styles.card} />)
        }
      } else {
        for (let i = 0; i < emptyCardsCount; i++) {
          emptyCards.push(<EmptyFeedCard key={`empty-${i}`} className={styles.card} />)
        }
      }
    }

    if (type === 'streams') {
      return items.map((stream: Stream) => (
        <StreamCard
          stream={stream}
          key={stream.id}
          onClick={onStreamClick}
          className={styles.card}
          persistentFeedsCount
          urlBrowse={`/content/search/sources/streams/${stream.id}`}
        />
      )).concat(loadingCards).concat(emptyCards)
    }

    const visibleItems = type === BRAND_FACEBOOK && fbView === 'default'
      ? items.filter(i => !i.live)
      : items

    const cards = visibleItems.map((feed: Feed) => {
      const selected = state.selectedFeeds.find(f => f.handle === feed.handle && f.type === feed.type) !== undefined
      return feed.live ? (
        <AddFeedCard
          key={feed.id}
          type={feed.type as ContentFeedType}
          handle={feed.handle}
          className={styles.card}
          displayName={feed.name}
          titleHTML={feed.titleHTML}
          pictureUrl={feed.image}
          selected={selected}
          networkId={feed.networkId}
          onNewFeedClick={onFeedClick}
          onFeedCreated={onFeedCreated}
          onFeedCreateError={onFeedCreateError}
          onSelect={toggleFeedSelected}
        />
      ) : (
        <FeedCard
          className={styles.card}
          key={feed.id}
          feed={feed}
          selected={selected}
          showFansCount="always"
          disableNavigation
          onClick={onFeedClick}
          onSelect={toggleFeedSelected}
        />
      )
    }).concat(...loadingCards, ...emptyCards)

    const template = state.newFeedTemplate as any
    const newFeedCard = template && type === template.type && type !== BRAND_FACEBOOK ? (
      <AddFeedCard
        key="new-feed-template"
        selected={false}
        selectedIds={state.selectedFeeds.map(f => f.uniqueSource)}
        type={template.type as ContentFeedType}
        handle={template.handle}
        className={styles.card}
        networkId={template.networkId}
        displayName={template.displayName}
        titleHTML={`<em>${template.displayName}</em>`}
        onNewFeedClick={onFeedClick}
        onFeedCreated={onFeedCreated}
        onFeedCreateError={onFeedCreateError}
        onSelect={toggleFeedSelected}
      />
    ) : null
    if (newFeedCard) {
      cards.unshift(newFeedCard)
    }

    return cards
  }, [
    loading,
    items,
    type,
    fbView,
    state.newFeedTemplate,
    state.selectedFeeds,
    onFeedClick,
    onFeedCreated,
    onFeedCreateError,
    toggleFeedSelected,
    onStreamClick
  ])

  const showNoResultsView = state.query && !loading && items.length === 0 && state.newFeedTemplate?.type !== type

  const categoryLabel = React.useMemo(() => {
    switch (type) {
      case 'streams':
        return 'content streams'
      case FEED_TYPE_FACEBOOK:
        return 'Facebook pages'
      case FEED_TYPE_REDDIT:
        return 'Subreddits'
      case FEED_TYPE_LINKEDIN:
        return 'LinkedIn companies'
      case FEED_TYPE_RSS:
        return 'RSS feeds'
      default:
        return 'content sources'
    }
  }, [type])

  const noResultsSubtitle = React.useMemo(() => {
    switch (type) {
      case 'streams':
        return 'Please try a different keyword, topic, brand or industry.'
      case FEED_TYPE_FACEBOOK:
        return 'Please try a different page name, or add the full page url.'
      case FEED_TYPE_REDDIT:
        return 'Please try a different keyword, topic, brand or industry.'
      case FEED_TYPE_LINKEDIN:
        return 'Please try a different company name.'
      case FEED_TYPE_RSS:
        return 'Please try a different site name, or add the full RSS feed url.'
      default:
        return 'Please try a different keyword, topic, brand or industry.'
    }
  }, [type])

  const error = React.useMemo(() => {
    if (type === BRAND_LINKEDIN) {
      const linkedinProfiles = Object.values(profiles).filter(p => p.type === BRAND_LINKEDIN)
      if (linkedinProfiles.length === 0) {
        return {
          message: 'You must connect your LinkedIn account in order to access LinkedIn feeds',
          subtitle: <NavLink className={styles['err-link']} to="/settings/networks">Go here to connect your LinkedIn account</NavLink>
        }
      }
    }
    return { message: `No ${categoryLabel} available for that search`, subtitle: noResultsSubtitle }
  }, [categoryLabel, noResultsSubtitle, type, profiles])

  const fbLiveFeeds = React.useMemo(() => {
    if (type !== BRAND_FACEBOOK) {
      return []
    }
    const loadingCards: any[] = []
    if (loading) {
      const count = 20
      for (let i = 0; i < count; i++) {
        loadingCards.push(<EmptyFeedCard loading key={`loading-${i}`} className={styles.card} />)
      }
    }
    const template = state.newFeedTemplate as any
    const newFeedCard = template && template.type === BRAND_FACEBOOK ? (
      <AddFeedCard
        key="new-feed-template"
        selected={false}
        selectedIds={state.selectedFeeds.map(f => f.uniqueSource)}
        type={template.type as ContentFeedType}
        handle={template.handle}
        className={styles.card}
        networkId={template.networkId}
        displayName={template.displayName}
        titleHTML={`<em>${template.displayName}</em>`}
        onNewFeedClick={onFeedClick}
        onFeedCreated={onFeedCreated}
        onFeedCreateError={onFeedCreateError}
        onSelect={toggleFeedSelected}
      />
    ) : null

    const fbCards = items.filter(i => i.live).map(feed => {
      const selected = state.selectedFeeds.find(f => f.handle === feed.handle && f.type === feed.type) !== undefined
      return (
        <AddFeedCard
          key={feed.id}
          type={feed.type as ContentFeedType}
          handle={feed.handle}
          className={styles.card}
          displayName={feed.name}
          titleHTML={feed.titleHTML}
          pictureUrl={feed.image}
          selected={selected}
          networkId={feed.networkId}
          onNewFeedClick={onFeedClick}
          onFeedCreated={onFeedCreated}
          onFeedCreateError={onFeedCreateError}
          onSelect={toggleFeedSelected}
        />
      )
    }).concat(loadingCards)
    if (newFeedCard) {
      fbCards.unshift(newFeedCard)
    }
    return fbCards
  }, [type, loading, state.newFeedTemplate, state.selectedFeeds, onFeedClick, onFeedCreated, onFeedCreateError, toggleFeedSelected, items])

  React.useEffect(() => {
    if (type === BRAND_FACEBOOK && items.length === 0) {
      setFbView('all')
    }
  }, [type, items.length])

  React.useEffect(() => {
    if (type === BRAND_FACEBOOK && state.query) {
      setFbView('default')
    }
  }, [type, state.query])

  if ((type !== BRAND_FACEBOOK && cards.length === 0) || (type === BRAND_FACEBOOK && cards.length === 0 && fbLiveFeeds.length === 0)) {
    return null
  }

  const withNewFeedCard = state.newFeedTemplate?.type === type

  return (
    <div key={type}>
      <header className={`${styles.header} ${styles['header-grid']}`}>
        <h2 className={`text-ellipsis ${styles.title}`}>
          <FormattedMessage id={`search.navigation.${type}`} />
        </h2>
        <NavLink to={`/content/search?q=${state.query}`}>
          <FormattedMessage id="general.carousel.nav.back" />
        </NavLink>
        {type === SEARCH_FILTER_KEY_STREAMS && (
          <p className={styles.hint}>
            <FormattedMessage id="search.sections.streams.no-results-hint" />
          </p>
        )}
        {type === SEARCH_FILTER_KEY_RSS && (
          <p className={styles.hint}>
            <FormattedMessage id="search.sections.rss.no-results-hint" />
          </p>
        )}
      </header>
      {type === BRAND_FACEBOOK && fbView === 'default' && fbLiveFeeds.length > 0 && (
        <div>
          <div className={styles.subtitle}>
            <span>Exact match from Facebook</span>
            <span className={styles['btn-expand']} onClick={() => setFbView('all')}>
              <FormattedMessage id={`general.carousel.nav.${fbView === 'default' ? 'see-all' : 'back'}`} />
            </span>
          </div>
          <div className={styles['carousel-box']}>
            <CardList
              key={state.query}
              itemWidth={ITEM_WIDTH_SMALL}
              listHeight={CARD_LIST_HEIGHT_SMALL}
            >
              {fbLiveFeeds}
            </CardList>
          </div>
        </div>
      )}
      {type === BRAND_FACEBOOK && fbView === 'default' && cards.length > 0 && (
        <div className={styles.subtitle}>
          <span>Popular on Post Planner</span>
        </div>
      )}
      <ScrollListener
        emitTreshold={emitScrollTreshold}
        emitInitial
        className={styles['grid-wrapper']}
        disabled={loading}
        onScroll={requestNextPage}
        scrollElement={scrollElementRef.current}
      >
        <div
          className={`${styles.grid} ${items.length === 0 && !withNewFeedCard ? styles['grid-empty'] : ''}`}
          data-testid="search-results-grid"
        >
          {cards}
        </div>
      </ScrollListener>
      {showNoResultsView && (
        <EmptyView
          title={error.message}
          subtitle={error.subtitle}
          contained
        />
      )}
    </div>
  )
})

export default FilteredSearchResultsRoute
