export function getRandomElements<T>(array: T[], count: number): T[] {
  const result: T[] = []
  const source = [...array]
  const length = source.length
  if (length < count) {
    throw new Error('Source array length is less than the required elements count')
  }

  while (result.length < count) {
    const index = Math.floor(Math.random() * source.length)
    result.push(source.splice(index, 1)[0])
  }

  return result
}

// Moves an item in an array from startIndex to endIndex position
export function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export function randomize<T>(array: T[]): T[] {
  return array.map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
}

export function groupByKey<T extends Record<string, any>>(array: T[], byKey: string): Record<string, T[]> {
  return array.reduce((map: Record<string, T[]>, item: T) => {
    const key = item[byKey]
    if (typeof key !== 'string') {
      console.error('Invalid key value: ', byKey, key)
      return map
    }
    if (map[key]) {
      map[key].push(item)
    } else {
      map[key] = [item]
    }
    return map
  }, {})
}

export function arrayToRecord<T>(arr: T[], key: string) {
  return arr.reduce((map: Record<string, T>, obj: any) => {
    map[obj[key] as string] = obj
    return map
  }, {})
}
