import * as React from 'react'
import SimpleLoader from 'components/SimpleLoader'
import { ContentType, IndexedObject, PlannedPost } from 'interfaces'
import { POST_TYPES } from 'interfaces/Content/ContentType'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { PostErrorView, ERROR_NO_PROFILES_TITLE, ERROR_NO_PROFILES_SUBTITLE } from 'routes/publishing/components/PostErrorView'
import { Observable } from 'rxjs/Observable'
import { catchError } from 'rxjs/operators/catchError'
import { tap } from 'rxjs/operators/tap'
import { map } from 'rxjs/operators/map'
import { exhaustMap } from 'rxjs/operators/exhaustMap'
import { Subject } from 'rxjs/Subject'
import { bulkDeletePosts, getPostById, recyclePosts, reorderPosts, shufflePosts, updatePostRecycle } from 'services/post/actions'
import { toggleEmptySlotsVisible, toggleVirtualPostsVisible } from 'services/post'
import {
  autoPostSlotsSelector,
  emptySlotsVisibleSelector,
  newPostSelector,
  plannedPostsLayoutSelector,
  selectedProfilesFetchIdsSelector,
  selectedProfilesSelector,
  virtualPostsVisibleSelector
} from 'services/post/selectors'
import { message } from 'services/snackbar'
import { StoreThunkDispatch } from 'store/state'
import PlannedPostsList from './components/PlannedPostsList'
import PostFilesDialog from 'components/Posts/PostFilesDialog'
import styles from './PostViews.pcss'
import { sortAscending } from 'utils/sort/sortByKey'
import { checkFeatureAvailability } from 'services/product'
import { FEATURE_POST_COPY, FEATURE_POST_RECYCLE, FEATURE_POST_SHUFFLE } from 'shared/constants'
import { setPosts, recyclePostsSuccess, updateRecyclePost, updatePostLocation, replacePost } from './actions'
import { connectedDestinationsSelector } from 'services/destinations'
import { MS_IN_SEC, SEC_IN_MIN } from 'utils/constants'
import PostPreviewWithInfo from './components/PostPreviewWithInfo'
import PublishingTopBar from './components/PublishingTopBar'
import CopyPostsPopup from 'routes/publishing/components/CopyPostsPopup'
import { mergeState, resetComposer } from 'services/compose'
import { getDerivedStateFromPost } from 'services/compose/update/actions'
import EmptyView from 'components/EmptyView'
import { mdiFeather } from '@mdi/js'
import { NavLink, useNavigate, useLocation, useParams } from 'react-router-dom'
import { publishPosts } from 'services/post/shareNow/actions'
import { RecycleDialog } from 'components/Posts/components/RecycleSelector'
import { HelpToggleButton } from 'components/App/components/HelpToggleButton'
import { usePlannedPosts } from './hooks/usePlannedPosts'
import { PostsGridView } from './components/PostsGridView'
import { reorder } from 'shared'
import { initPlans } from 'services/plan/actions'

const LIVE_PUBLISHED_CHECK_INTERVAL = MS_IN_SEC * SEC_IN_MIN

function PostsRoute() {
  const intl = useIntl()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const viewFilter = params.type === 'all' ? 'planned' : params.type
  const selectedProfilesIds = useSelector(selectedProfilesSelector)
  const selectedProfilesFetchIds = useSelector(selectedProfilesFetchIdsSelector)
  const newPost = useSelector(newPostSelector)
  const autoPostSlots = useSelector(autoPostSlotsSelector)
  const connectedProfiles = useSelector(connectedDestinationsSelector)

  const [loadingOverlayActive, setLoadingOverlayActive] = React.useState(false)

  const onBeforeFetch = React.useCallback(() => {
    setLoadingOverlayActive(true)
  }, [])

  const onPostsFetched = React.useCallback(() => {
    setLoadingOverlayActive(false)
    setIsEmptyViewClosed(false)
  }, [])

  const [search, setSearch] = React.useState<{ query: string, types: ContentType[], searchInSummary: boolean }>(
    { query: '', types: POST_TYPES, searchInSummary: true }
  )
  const {
    posts,
    loading,
    hasNonEmptyPosts,
    totalPostsCount,
    hasPendingPosts,
    loadNextPage,
    refresh,
    postsDispatch
  } = usePlannedPosts(search, viewFilter, onBeforeFetch, onPostsFetched)

  const [selectedPostsIds, setSelectedPostsIds] = React.useState<IndexedObject<boolean> | 'all'>({})
  const [postInMediaPreviewDialog, setPostInMediaPreviewDialog] = React.useState<PlannedPost | null>(null)
  const [postInPreview, setPostInPreview] = React.useState<PlannedPost | null>(null)
  const [withPreview, setWithPreview] = React.useState(false)
  const [postsToCopy, setPostsToCopy] = React.useState<PlannedPost[]>([])
  const activeView = useSelector(plannedPostsLayoutSelector)
  const emptySlotsVisible = useSelector(emptySlotsVisibleSelector)
  const virtualPostsVisible = useSelector(virtualPostsVisibleSelector)
  const [recycleDialogOpen, setRecycleDialogOpen] = React.useState(false)
  const [isEmptyViewClosed, setIsEmptyViewClosed] = React.useState(false)

  const closeEmptyView = () => {
    setIsEmptyViewClosed(true)
  }

  React.useEffect(() => {
    if (location.state?.updatedPostId) {
      dispatch(getPostById(location.state?.updatedPostId))
        .unwrap()
        .then((post: any) => {
          postsDispatch(replacePost(post))
        })
        .catch(refresh)
    }
    if (location.state?.search) {
      setSearch(location.state.search)
    }
  }, [location.state?.search, postsDispatch, location.state?.updatedPostId, dispatch])

  const delete$ = React.useRef<Subject<{ postIds: string[], ppids: string[], all: boolean, query?: string, types?: ContentType[] }>>()
  const reorder$ = React.useRef<Subject<{ ids: string[], moveToBottom?: boolean }>>()
  const share$ = React.useRef<Subject<{
    postIds: string[],
    ppids: string[],
    all: boolean,
    query?: string,
    types?: ContentType[],
    bucketId?: string
  }>>()
  const shuffle$ = React.useRef<Subject<{ postIds: string[], ppids: string[], all?: boolean }>>()
  const batchRecycle$ = React.useRef<Subject<{
    postIds: string[], ppids: string[], all?: boolean, query?: string, types?: ContentType[], value?: boolean | Date | number
  }>>()
  const recycle$ = React.useRef<Subject<{ postId: string, value: boolean | Date | number, refresh?: boolean }>>()
  const livePublishedCheckTimer = React.useRef<any>()
  const selectionRef = React.useRef<{ postIds: string[], profileIds: string[], ppids: string[], all: boolean }>()

  React.useEffect(() => {
    dispatch(initPlans())
  }, [dispatch])

  const onEdit = React.useCallback((post: PlannedPost) => {
    dispatch(resetComposer())
    dispatch(mergeState(getDerivedStateFromPost(post)))
    const stateParams = {
      floating: true,
      modal: 'compose',
      opener: location.pathname + location.search,
      search
    }
    navigate('/composer', { state: { ...stateParams, background: location } })
  }, [dispatch, navigate, search, location])

  const onFilter = React.useCallback((query: string, types: ContentType[], searchInSummary: boolean) => {
    setSearch({ query, types, searchInSummary })
  }, [])

  const nonEmptyPosts = React.useMemo(() => {
    return posts.filter(p => !p.isEmpty)
  }, [posts])

  const onToggleEmptySlotsVisible = React.useCallback(() => {
    dispatch(toggleEmptySlotsVisible())
  }, [dispatch])

  const onToggleVirtualPostsVisible = React.useCallback(() => {
    dispatch(toggleVirtualPostsVisible())
  }, [dispatch])

  const onTogglePreview = () => {
    setWithPreview(current => !current)
  }

  // Check for newly created/updated posts while posts page is active. Reload posts to fetch the new records
  React.useEffect(() => {
    if ((newPost || location.state?.refresh) && !location.state?.updatedPostId) {
      refresh()
    }
  }, [newPost, location.state?.refresh, refresh, location.state?.updatedPostId])

  // Initialize a timer that checks for posts that were published while the page was active and mark them as "published"
  React.useEffect(() => {
    if (livePublishedCheckTimer.current) {
      clearInterval(livePublishedCheckTimer.current)
    }

    livePublishedCheckTimer.current = setInterval(() => {
      const now = Date.now()
      const updatedPosts: PlannedPost[] = []
      let hasUpdated = false
      posts.forEach(p => {
        const published = new Date(p.time).getTime() - now < 0
        if (published) {
          hasUpdated = true
        }
        updatedPosts.push({ ...p, published })
      })
      if (hasUpdated) {
        postsDispatch(setPosts(updatedPosts))
      }
    }, LIVE_PUBLISHED_CHECK_INTERVAL)

    return () => {
      clearInterval(livePublishedCheckTimer.current)
    }
  }, [posts, postsDispatch])

  // Clear posts and preview when profile selection changes
  React.useEffect(() => {
    postsDispatch(setPosts([]))
    setPostInPreview(null)
  }, [postsDispatch, selectedProfilesFetchIds])

  React.useEffect(() => {
    delete$.current = new Subject()
    delete$.current
      .pipe(tap(() => {
        dispatch(message(intl.formatMessage({ id: 'post.notifications.deleting-posts' })))
        setLoadingOverlayActive(true)
      }))
      .flatMap(({ ppids, postIds, all, query, types }) => {
        const type: 'queued' | 'scheduled' | 'planned' = viewFilter as any
        return dispatch(bulkDeletePosts(postIds, ppids, type, all, undefined, query, types))
          .pipe(catchError((error: Error) => {
            dispatch(message(intl.formatMessage({ id: 'post.notifications.delete-posts-error' }, { error: error.message }), 'error'))
            return Observable.of({ error })
          }))
      })
      .subscribe((response: any) => {
        if (!response.error) {
          setSelectedPostsIds({})
          refresh()
        } else {
          setLoadingOverlayActive(false)
        }
      })

    return () => delete$.current?.unsubscribe()
  }, [dispatch, viewFilter, intl, refresh])

  React.useEffect(() => {
    reorder$.current = new Subject()
    reorder$.current.pipe(
      tap(() => {
        setLoadingOverlayActive(true)
      }),
      exhaustMap(({ ids, moveToBottom }) => dispatch(reorderPosts(ids, moveToBottom)).pipe(catchError(() => {
        dispatch(message(intl.formatMessage({ id: 'post.notifications.update-failed' }), 'error'))
        return Observable.of({ error: true })
      })))
    )
      .subscribe(refresh)

    return () => reorder$.current?.unsubscribe()
  }, [dispatch, intl, refresh])

  React.useEffect(() => {
    shuffle$.current = new Subject()
    shuffle$.current.pipe(tap(() => {
      setLoadingOverlayActive(true)
      dispatch(message(intl.formatMessage({ id: 'post.notifications.shuffling-posts' })))
    }))
      .flatMap(({ postIds, ppids, all }) => dispatch(shufflePosts(postIds, ppids, viewFilter as any, all))
        .pipe(catchError(() => {
          return Observable.of({ error: true })
        }))
      )
      .subscribe(response => {
        setLoadingOverlayActive(false)
        if (!response.error) {
          setSelectedPostsIds({})
          refresh()
        }
      })

    return () => shuffle$.current?.unsubscribe()
  }, [dispatch, viewFilter, intl, refresh])

  React.useEffect(() => {
    batchRecycle$.current = new Subject()
    batchRecycle$.current.pipe(tap(() => {
      dispatch(message(intl.formatMessage({ id: 'post.notifications.recycling-posts' })))
    }))
      .flatMap(({ ppids, postIds, all, query, types, value }) => {
        return dispatch(recyclePosts(postIds, ppids, viewFilter as any, all, undefined, query, types, value))
          .pipe(catchError((e) => {
            const msg = e.response?.error.message || intl.formatMessage({ id: 'errors.generic' })
            dispatch(message(msg, 'error'))
            return Observable.of({ error: true })
          }))
      })
      .subscribe(response => {
        if (!response.error) {
          setSelectedPostsIds({})
          postsDispatch(recyclePostsSuccess(response.postIds, response.all))
          for (const postId of response.postIds) {
            postsDispatch(updateRecyclePost(postId, response.recycle))
          }
        }
      })

    return () => batchRecycle$.current?.unsubscribe()
  }, [dispatch, viewFilter, intl, postsDispatch])

  React.useEffect(() => {
    recycle$.current = new Subject()
    recycle$.current
      .flatMap(({ postId, value, refresh }) => dispatch(updatePostRecycle(postId, value))
        .pipe(
          catchError((e) => {
            const msg = e.response?.error.message || intl.formatMessage({ id: 'errors.generic' })
            dispatch(message(msg, 'error'))
            return Observable.of({ error: true })
          }),
          map((response: any) => ({ ...response, refresh }))
        )
      )
      .subscribe(response => {
        if (!response.error) {
          setSelectedPostsIds({})
          postsDispatch(updateRecyclePost(response.postId, response.value))
        }
        if (response.refresh) {
          refresh()
        }
      })

    return () => recycle$.current?.unsubscribe()
  }, [dispatch, postsDispatch, intl])

  React.useEffect(() => {
    share$.current = new Subject()
    share$.current
      .pipe(tap(() => {
        setLoadingOverlayActive(true)
      }))
      .flatMap(({ ppids, postIds, all, query, types, bucketId }) => {
        return dispatch(publishPosts(postIds, ppids, 'planned', all, bucketId, query, types))
          .pipe(catchError((error: Error) => {
            dispatch(message(intl.formatMessage({ id: 'notifications.error-w-message' }, { error: error.message }), 'error'))
            return Observable.of({ error })
          }))
      })
      .subscribe((response: any) => {
        if (!response.error) {
          setSelectedPostsIds({})
          dispatch(message(intl.formatMessage({ id: 'notifications.post-posted' }), 'success'))
          refresh()
        }
        setLoadingOverlayActive(false)
      })

    return () => share$.current?.unsubscribe()
  }, [dispatch, intl, refresh])

  React.useEffect(() => {
    const profileIds = selectedProfilesFetchIds.split(',')
    const ppids = Object.values(connectedProfiles).filter(p => profileIds.includes(p.idNew)).map(p => p.ppid)
    if (selectedPostsIds === 'all') {
      selectionRef.current = {
        all: true,
        postIds: posts.filter(p => !p.isVirtual).map(p => p.id),
        profileIds,
        ppids
      }
    } else {
      selectionRef.current = {
        all: false,
        profileIds,
        ppids,
        postIds: Object.keys(selectedPostsIds).filter(id => selectedPostsIds[id])
      }
    }
  }, [selectedPostsIds, posts, selectedProfilesFetchIds, connectedProfiles])

  const previewSelection = React.useMemo(() => {
    const selectedPostIndex = postInPreview
      ? nonEmptyPosts.findIndex((p: any) => p.id === postInPreview.id)
      : -1
    let hasNext = false
    let hasPrev = false

    if (selectedPostIndex !== -1) {
      hasNext = selectedPostIndex < nonEmptyPosts.length - 1
      hasPrev = selectedPostIndex >= 1
    }
    return {
      selectedPostIndex,
      hasNext,
      hasPrev
    }
  }, [postInPreview, nonEmptyPosts])

  React.useEffect(() => {
    if (withPreview) {
      const firstNonEmptyPost = posts.find(p => !p.isEmpty)

      setPostInPreview(current => {
        const postInPreview = current ? posts.find(p => p.id === current.id) : null
        if (!postInPreview && firstNonEmptyPost) {
          return firstNonEmptyPost
        }
        if (!firstNonEmptyPost) {
          return null
        }
        return postInPreview || null
      })
    } else {
      setPostInPreview(null)
    }
  }, [withPreview, posts])

  const toggleAllPostsSelected = React.useCallback(() => {
    setSelectedPostsIds(current => current === 'all' ? {} : 'all')
  }, [])

  const onDeletePost = React.useCallback((id: string) => {
    delete$.current?.next({
      postIds: [id],
      ppids: selectionRef.current?.ppids || [],
      all: false
    })
  }, [])

  const movePost = React.useCallback((id: string, position: 'top' | 'bottom') => {
    const autoPosts = posts.reduce((autoPosts: any[], p: any) => {
      if (p.autoPost && !p.isEmpty && p.id !== id && !p.isVirtual) {
        autoPosts.push(p)
      }
      return autoPosts
    }, [])
    const ids: string[] = autoPosts.sort(sortAscending('autoPostOrder')).map(p => p.id)
    if (position === 'top') {
      ids.unshift(id)
    } else {
      ids.push(id)
    }
    reorder$.current?.next({ ids, moveToBottom: position === 'bottom' })
  }, [posts])

  const onReorderPosts = React.useCallback((movedPost: PlannedPost, targetPost: PlannedPost) => {
    const profileId = movedPost.profileId
    const ppid = movedPost.ppPageId
    const bucketId = movedPost.bucketId
    const autoPostsByProfile = posts.filter(p => p.autoPost && p.profileId === profileId && !p.isVirtual)
      .sort(sortAscending('autoPostOrder')) as PlannedPost[]
    const currentIndex = autoPostsByProfile.findIndex(p => p.id === movedPost.id)
    const nextIndex = autoPostsByProfile.findIndex(p => p.id === targetPost.id)

    const ids: string[] = []
    const orderedPosts: PlannedPost[] = []
    autoPostsByProfile.splice(currentIndex, 1)
    autoPostsByProfile.splice(nextIndex, 0, movedPost)
    autoPostsByProfile.forEach((p, i) => {
      p.autoPostOrder = i
      orderedPosts.push(p)
      // Send only post ids that belong to the same bucket/queue
      if (!p.isEmpty && p.bucketId === bucketId) {
        ids.push(p.id)
      }
    })

    const slots = autoPostSlots.filter(s => s.ppPageId === ppid).map(s => ({ ...s, used: false }))
    orderedPosts.forEach((post, index) => {
      const matchingSlot = slots.find(slot => !slot.used && slot.buckets.includes(post.contentType))
      if (matchingSlot) {
        matchingSlot.used = true
        post.time = matchingSlot.time
        post.dateString = matchingSlot.dateString
        post.weekday = matchingSlot.weekday
        post.autoPostOrder = index
        post.timeString = matchingSlot.timeString
      }
    })

    // Apply new posts order in the UI while request is running
    const prev = posts.findIndex(p => p.id === movedPost.id)
    const next = posts.findIndex(p => p.id === targetPost.id)
    const reordered = reorder(posts, prev, next)
    postsDispatch(setPosts(reordered))

    reorder$.current?.next({ ids })
  }, [posts, autoPostSlots, postsDispatch])

  const onRecycleChange = React.useCallback((id: string, value: number | boolean | Date, forceRefresh?: boolean) => {
    const recycleAvailable = dispatch(checkFeatureAvailability(FEATURE_POST_RECYCLE))
    if (!recycleAvailable) {
      return
    }
    const shouldRefresh = forceRefresh || virtualPostsVisible
    recycle$.current?.next({ postId: id, value, refresh: shouldRefresh })
  }, [dispatch, virtualPostsVisible])

  const onCopyPost = React.useCallback((post: PlannedPost) => {
    const copyAllowed = dispatch(checkFeatureAvailability(FEATURE_POST_COPY))
    if (copyAllowed) {
      setPostsToCopy([post])
    }
  }, [dispatch])

  const onCopyPosts = React.useCallback(() => {
    const copyAllowed = dispatch(checkFeatureAvailability(FEATURE_POST_COPY))
    if (copyAllowed) {
      if (selectionRef.current?.all) {
        setPostsToCopy(nonEmptyPosts)
      } else if (selectionRef.current?.postIds) {
        setPostsToCopy(nonEmptyPosts.filter(p => selectionRef.current?.postIds.includes(p.id)))
      }
    }
  }, [dispatch, nonEmptyPosts])

  const closeRecycleDialog = () => {
    setRecycleDialogOpen(false)
  }

  const onBulkRecycle = (value: boolean | Date | number) => {
    batchRecycle$.current?.next({ ...(selectionRef.current as any), query: search.query, types: search.types, value })

    closeRecycleDialog()
  }

  const onBatchRecycle = React.useCallback(() => {
    setRecycleDialogOpen(true)
  }, [])

  const onPostUpdated = (post: PlannedPost) => {
    postsDispatch(updatePostLocation(post.id, post.details?.location))
  }

  const onShuffle = React.useCallback(() => {
    const shuffleAvailable = dispatch(checkFeatureAvailability(FEATURE_POST_SHUFFLE))
    if (!shuffleAvailable) {
      return
    }

    const selection = selectionRef.current
    if (selection && (selection.all || selection.postIds.length > 0)) {
      shuffle$.current?.next(selection)
    }
  }, [dispatch])

  const togglePostSelected = React.useCallback((id: string) => {
    setSelectedPostsIds(current => {
      if (current === 'all') {
        return posts.filter(p => !p.isEmpty && !p.isVirtual).reduce((selection: IndexedObject<boolean>, post) => {
          selection[post.id] = post.id !== id
          return selection
        }, {})
      }
      return { ...current, [id]: !current[id] }
    })
  }, [posts])

  const closePostMediaPreviewDialog = React.useCallback(() => {
    setPostInMediaPreviewDialog(null)
  }, [])

  const deleteSelectedPosts = React.useCallback(() => {
    delete$.current?.next({
      ...(selectionRef.current as any),

      query: search.query,
      types: search.types
    })
  }, [search.query, search.types])

  const closeCopyPostsPopup = React.useCallback(() => {
    setPostsToCopy([])
    setSelectedPostsIds({})
  }, [])

  const onPostClick = React.useCallback((post: PlannedPost) => {
    setWithPreview(true)
    setPostInPreview(post)
  }, [])

  const selectNextPostForPreview = React.useCallback(() => {
    if (previewSelection.hasNext) {
      const next = nonEmptyPosts[previewSelection.selectedPostIndex + 1]
      setPostInPreview(next)
    }
  }, [previewSelection, nonEmptyPosts])

  const selectPrevPostForPreview = React.useCallback(() => {
    if (previewSelection.hasPrev) {
      const prev = nonEmptyPosts[previewSelection.selectedPostIndex - 1]
      setPostInPreview(prev)
    }
  }, [previewSelection, nonEmptyPosts])

  const deletePost = React.useCallback((post: PlannedPost) => {
    onDeletePost(post.id)
  }, [onDeletePost])

  const visiblePosts = React.useMemo(() => {
    if (emptySlotsVisible) {
      return posts
    }
    return posts.filter(p => !p.isEmpty)
  }, [posts, emptySlotsVisible])

  const showEmptyPostsMessage = !loading && selectedProfilesIds.length > 0 && nonEmptyPosts.length === 0 && totalPostsCount === 0

  const selectedPostsCount = React.useMemo(() => {
    return selectedPostsIds !== 'all'
      ? Object.values(selectedPostsIds).filter(Boolean).length
      : totalPostsCount
  }, [totalPostsCount, selectedPostsIds])

  const onShareNow = React.useCallback((post: PlannedPost) => {
    share$.current?.next({ all: false, postIds: [post.id], ppids: [post.ppPageId], bucketId: post.bucketId })
  }, [])

  const bulkMoveToTop = React.useCallback(() => {
    if (selectedPostsIds === 'all') {
      setSelectedPostsIds({})
      return
    }
    const autoPosts = posts.reduce((autoPosts: any[], p: any) => {
      if (p.autoPost && !p.isEmpty && !p.isVirtual) {
        autoPosts.push(p)
      }
      return autoPosts
    }, [])

    let ids: string[] = autoPosts.sort(sortAscending('autoPostOrder')).map(p => p.id)
    const selectedIdsSorted = {} as Record<number, string>

    Object.keys(selectedPostsIds).forEach(id => {
      const index = ids.indexOf(id)
      if (index !== -1) {
        selectedIdsSorted[index] = id
      }
    })
    const selectedIdsSortedArray = Object.keys(selectedIdsSorted).map(Number).sort((a, b) => a - b).map(i => selectedIdsSorted[i])
    ids = ids.filter(id => !selectedPostsIds[id])
    ids.unshift(...selectedIdsSortedArray)

    reorder$.current?.next({ ids })
    setSelectedPostsIds({})
  }, [selectedPostsIds, posts])

  const bulkMoveToBottom = React.useCallback(() => {
    if (selectedPostsIds === 'all') {
      setSelectedPostsIds({})
      return
    }
    const autoPosts = posts.reduce((autoPosts: any[], p: any) => {
      if (p.autoPost && !p.isEmpty && !p.isVirtual) {
        autoPosts.push(p)
      }
      return autoPosts
    }, [])
    let ids: string[] = autoPosts.sort(sortAscending('autoPostOrder')).map(p => p.id)
    const selectedIdsSorted = {} as Record<number, string>
    Object.keys(selectedPostsIds).forEach(id => {
      const index = ids.indexOf(id)
      if (index !== -1) {
        selectedIdsSorted[index] = id
      }
    })
    const selectedIdsSortedArray = Object.keys(selectedIdsSorted).map(Number).sort((a, b) => a - b).map(i => selectedIdsSorted[i])
    ids = ids.filter(id => !selectedPostsIds[id])

    ids.push(...selectedIdsSortedArray)
    reorder$.current?.next({ ids })
    setSelectedPostsIds({})
  }, [selectedPostsIds, posts])

  const bulkActionsProps = React.useMemo(() => {
    const deletePostsConfirmMessage = selectedPostsIds === 'all'
      ? intl.formatMessage({ id: 'post.delete-all-confirm' }, { count: totalPostsCount, type: 'planned' })
      : undefined

    const withBulkMove = selectedPostsIds !== 'all' && selectedPostsCount > 1 && selectedPostsCount < totalPostsCount

    return {
      hidePostActions: !hasNonEmptyPosts,
      withDelete: true,
      withRecycle: true,
      withShuffle: true,
      withCopy: true,
      allSelected: selectedPostsIds === 'all',
      disabled: selectedPostsCount === 0,
      selectedCount: selectedPostsCount,
      deleteAllConfirmMessage: deletePostsConfirmMessage,
      onToggleAllSelected: toggleAllPostsSelected,
      onBulkDeletePosts: deleteSelectedPosts,
      onBulkRecycle: onBatchRecycle,
      onShuffle,
      onCopy: onCopyPosts,
      onMoveToTop: withBulkMove ? bulkMoveToTop : undefined,
      onMoveToBottom: withBulkMove ? bulkMoveToBottom : undefined
    }
  }, [
    intl,
    hasNonEmptyPosts,
    selectedPostsIds,
    selectedPostsCount,
    totalPostsCount,
    toggleAllPostsSelected,
    deleteSelectedPosts,
    onBatchRecycle,
    onCopyPosts,
    onShuffle,
    bulkMoveToTop,
    bulkMoveToBottom
  ])

  return (
    <div className={styles.page}>
      <PublishingTopBar
        withViewPicker
        withPreviewToggle
        withPostsViewToggle
        withEmptySlotsToggle={viewFilter !== 'scheduled'}
        postsView={`posts/planned${params.type ? '/' + params.type : ''}`}
        activeTypes={search.types}
        showPreview={withPreview}
        emptySlotsVisible={emptySlotsVisible}
        totalCount={totalPostsCount}
        virtualPostsVisible={virtualPostsVisible}
        showPendingLink={!loading && hasPendingPosts && viewFilter !== 'pending'}
        onToggleEmptySlotsVisible={onToggleEmptySlotsVisible}
        onToggleVirtualPostsVisible={onToggleVirtualPostsVisible}
        onTogglePreview={onTogglePreview}
        onFilter={onFilter}
        children={<HelpToggleButton />}
      />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {loadingOverlayActive && (
            <div className={styles['loading-overlay']}>
              <SimpleLoader small />
            </div>
          )}
          {activeView === 'list' && (
            <PlannedPostsList
              posts={visiblePosts}
              selectedPosts={selectedPostsIds}
              activePreviewPostId={postInPreview?.id}
              bulkActionsProps={bulkActionsProps}
              onLoadNextPage={loadNextPage}
              onTogglePostSelected={togglePostSelected}
              onDeletePost={onDeletePost}
              onEditPost={onEdit}
              onCopyPost={onCopyPost}
              onMediaPreviewBtnClick={setPostInMediaPreviewDialog}
              onMovePost={movePost}
              onPostOrderChanged={onReorderPosts}
              onRecycleChange={onRecycleChange}
              onPostClick={onPostClick}
              onShareNow={onShareNow}
            />
          )}
          {activeView === 'grid' && (
            <PostsGridView
              posts={visiblePosts}
              selectedPosts={selectedPostsIds}
              bulkActionsProps={bulkActionsProps}
              withDragAndDrop
              onPostOrderChanged={onReorderPosts}
              onLoadNextPage={loadNextPage}
              onPostClick={onPostClick}
              onTogglePostSelected={togglePostSelected}
              onDeletePost={onDeletePost}
              onEditPost={onEdit}
              onCopyPost={onCopyPost}
              onShareNow={onShareNow}
            />
          )}
          {!loading && selectedProfilesIds.length === 0 && (
            <PostErrorView title={ERROR_NO_PROFILES_TITLE} subtitle={ERROR_NO_PROFILES_SUBTITLE} />
          )}
          {showEmptyPostsMessage && !loading && !isEmptyViewClosed && (
            <EmptyView
              icon={mdiFeather}
              title={`No ${viewFilter} posts`}
              subtitle={(
                <div>
                  <NavLink to={{ pathname: '/composer' }} state={{ floating: true, modal: 'compose', opener: location.pathname }}>
                    Create a new post
                  </NavLink>
                  &nbsp;or <NavLink to="/content/search/content">search for content</NavLink>
                </div>
              )}
              backdrop
              onClose={closeEmptyView}
            />
          )}
          {loading && !loadingOverlayActive && <SimpleLoader small />}
        </div>
        <div
          className={`${styles['preview-box']} ${withPreview ? styles.visible : ''}`}
        >
          {postInPreview && (
            <PostPreviewWithInfo
              post={postInPreview}
              hasNextPost={previewSelection.hasNext}
              hasPreviousPost={previewSelection.hasPrev}
              className={styles.preview}
              onClose={onTogglePreview}
              onSelectNext={selectNextPostForPreview}
              onSelectPrevious={selectPrevPostForPreview}
              onDelete={deletePost}
              onMove={movePost}
              onEdit={onEdit}
              onCopy={onCopyPost}
              onRecycleChange={onRecycleChange}
              onPostUpdated={onPostUpdated}
            />
          )}
        </div>
        <PostFilesDialog
          active={Boolean(postInMediaPreviewDialog)}
          images={postInMediaPreviewDialog?.images || []}
          onClose={closePostMediaPreviewDialog}
        />
      </div>
      <RecycleDialog open={recycleDialogOpen} onClose={closeRecycleDialog} onSubmit={onBulkRecycle} />
      <CopyPostsPopup
        open={postsToCopy.length > 0}
        posts={postsToCopy}
        allSelected={Boolean(selectionRef.current?.all)}
        query={search.query}
        types={search.types}
        postType={viewFilter as any}
        onClose={closeCopyPostsPopup}
        onCopyStarted={closeCopyPostsPopup}
        onCopyFinished={refresh}
      />
    </div>
  )
}

export default React.memo(PostsRoute)
