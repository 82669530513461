import * as React from 'react'
import { PostedPost as IPostedPost, IndexedObject } from 'interfaces'
import { ScrollListener } from 'components/ScrollListener'
import PostGroup from '../PostGroup'
import { PostedPost } from 'components/Posts'
import { PostedSortBy, BulkPostActions } from 'routes/publishing/components/BulkActionsMenus'
import { BulkPostActionsProps } from 'routes/publishing/components/BulkActionsMenus/BulkPostActions'
import styles from '../PlannedPostsList/PlannedPostsList.pcss'

const SCROLL_TRESHOLD = 350

interface PostGroupData {
  posts: IPostedPost[]
  index?: number
  key?: string
}

export interface PostedPostsListProps {
  posts: IPostedPost[]
  sortBy?: PostedSortBy
  className?: string
  selectedPosts: { [id: string]: boolean } | 'all'
  bulkActionsProps: BulkPostActionsProps
  actionsClassName?: string
  onLoadNextPage: () => void
  onRequeuePosted: (id: string, ppid: string) => void
  onRepost: (post: IPostedPost) => void
  onCopy: (post: IPostedPost) => void
  onMediaPreviewBtnClick: (post: IPostedPost) => void
  onShareNow?: (post: IPostedPost) => void
  onTogglePostSelected: (id: string) => void
}

export function PostedPostsList(props: PostedPostsListProps) {
  const scrollElementRef = React.useRef<HTMLElement | undefined>(undefined)
  React.useLayoutEffect(() => {
    scrollElementRef.current = document.querySelector('[data-test="main"]') as HTMLElement
  }, [])
  const [postGroups, setPostGroups] = React.useState<IndexedObject<PostGroupData>>({})

  React.useEffect(() => {
    const groups: IndexedObject<PostGroupData> = props.posts.reduce((groups: IndexedObject<PostGroupData>, post: IPostedPost) => {
      const group = groups[post.weekday]
      if (!group) {
        groups[post.weekday] = {
          posts: [post]
        }
      } else {
        group.posts.push(post)
      }
      return groups
    }, {})

    setPostGroups(groups)
  }, [props.posts])

  const showGrouped = !props.sortBy || props.sortBy === PostedSortBy.Time

  return (
    <div className={`${styles.wrapper} ${props.className || ''}`} data-test="post-list">
      <div className={`${styles.actions} ${styles.posted} ${props.actionsClassName || ''}`}>
        {props.posts.length > 0 && (
          <BulkPostActions {...props.bulkActionsProps} />
        )}
      </div>
      <ScrollListener
        scrollElement={scrollElementRef.current}
        emitTreshold={SCROLL_TRESHOLD}
        onScroll={props.onLoadNextPage}
      >
        <div>
          {!showGrouped && props.posts.map(post => (
            <PostedPost
              key={post.id}
              post={post as IPostedPost}
              onRequeue={props.onRequeuePosted}
              onRepost={props.onRepost}
              onCopy={props.onCopy}
              onToggleSelected={props.onTogglePostSelected}
              selected={props.selectedPosts === 'all' || !!props.selectedPosts[post.id]}
              withDate={props.sortBy && props.sortBy !== PostedSortBy.Time}
              onMediaPreviewBtnClick={props.onMediaPreviewBtnClick}
              onShareNow={props.onShareNow}
            />
          ))}
          {showGrouped && Object.keys(postGroups).map((title) => (
            <PostGroup title={title} key={`group-${title}`}>
              {postGroups[title].posts.map(post => (
                <PostedPost
                  key={post.id}
                  post={post as IPostedPost}
                  selected={props.selectedPosts === 'all' || !!props.selectedPosts[post.id]}
                  onRequeue={props.onRequeuePosted}
                  onRepost={props.onRepost}
                  onCopy={props.onCopy}
                  onToggleSelected={props.onTogglePostSelected}
                  withDate={props.sortBy && props.sortBy !== PostedSortBy.Time}
                  onMediaPreviewBtnClick={props.onMediaPreviewBtnClick}
                  onShareNow={props.onShareNow}
                />
              ))}
            </PostGroup>
          ))}
        </div>
      </ScrollListener>
    </div>
  )
}

export default PostedPostsList
