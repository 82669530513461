import * as React from 'react'
import SimpleLoader from 'components/SimpleLoader'
import { ContentType, IndexedObject, PostedPost } from 'interfaces'
import { POST_TYPES } from 'interfaces/Content/ContentType'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { PostErrorView, ERROR_NO_PROFILES_TITLE, ERROR_NO_PROFILES_SUBTITLE } from 'routes/publishing/components/PostErrorView'
import { Observable } from 'rxjs/Observable'
import { catchError } from 'rxjs/operators/catchError'
import { tap } from 'rxjs/operators/tap'
import { Subject } from 'rxjs/Subject'
import { selectedProfilesSelector } from 'services/post/selectors'
import { message } from 'services/snackbar'
import { StoreThunkDispatch } from 'store/state'
import PostFilesDialog from 'components/Posts/PostFilesDialog'
import styles from './PostViews.pcss'
import { getPostedPosts, requeuePost } from 'services/post/actions'
import { PostedSortBy, PostedSortMenu, SortDirection } from 'routes/publishing/components/BulkActionsMenus'
import PostedFilter from 'routes/publishing/components/PostedFilter'
import { checkFeatureAvailability } from 'services/product'
import {
  FEATURE_POST_INSIGHTS_ACTIONS_REPLAN,
  FEATURE_POST_INSIGHTS_ACTIONS_SORT,
  FEATURE_POST_COPY,
  BRAND_TIKTOK,
  BRAND_YOUTUBE,
  BRAND_GOOGLE
} from 'shared/constants'
import PostedPostsList from './components/PostedPostsList'
import { forceFetchExternalPosts, toggleExternalPostsVisible } from 'services/post/toggleExternal/actions'
import { getDerivedStateFromPost, mergeState, resetComposer } from 'services/compose'
import { postsReducer, PostsAction } from './reducer'
import { addPosts, setPosts } from './actions'
import PostsFilter from './components/PostsFilter'
import { connectedDestinationsSelector } from 'services/destinations'
import CopyPostsPopup from 'routes/publishing/components/CopyPostsPopup'
import EmptyView from 'components/EmptyView'
import { NavLink, useNavigate, useLocation, useParams } from 'react-router-dom'
import { mdiFeather } from '@mdi/js'
import { NOTIFICATION_DURATION_LONG } from 'components/ConnectedSnackbar'
import { HS_EVENT_POSTS_SORTED, trackHubspotEvent } from 'services/tracking/hubspot'
import { HistoryPostsTimeRange } from './components/HistoryRangePicker/HistoryRangePicker'
import { PostedPostsSearchParams } from 'shared/types'
import { HelpToggleButton } from 'components/App/components/HelpToggleButton'

export function PostedPostsManager() {
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const params = useParams()
  const selectedProfiles = useSelector(selectedProfilesSelector)
  const connectedProfiles = useSelector(connectedDestinationsSelector)
  const fetchIdsString = React.useMemo(() => selectedProfiles.map(p => p.idNew).join(','), [selectedProfiles])
  const [externalVisible, setExternalVisible] = React.useState<boolean | undefined>(undefined)
  const [sortBy, setSortBy] = React.useState<PostedSortBy>(PostedSortBy.Time)
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('descending')
  const [posts, postsDispatch] = React.useReducer<React.Reducer<PostedPost[], PostsAction>>(postsReducer, [])
  const postsIdsRef = React.useRef<string[]>([])
  const [loading, setLoading] = React.useState(false)
  const [page, setPage] = React.useState(0)
  const [hasNextPage, setHasNextPage] = React.useState(true)
  const [totalPostsCount, setTotalPostsCount] = React.useState<null | number>(null)
  const [refreshKey, setRefreshKey] = React.useState(0)
  const [postInMediaPreviewDialog, setPostInMediaPreviewDialog] = React.useState<PostedPost | null>(null)
  const [search, setSearch] = React.useState<{ query: string, types: ContentType[], searchInArticleSummary?: boolean }>(
    { query: '', types: POST_TYPES, searchInArticleSummary: true }
  )
  const [selectedPostsIds, setSelectedPostsIds] = React.useState<IndexedObject<boolean> | 'all'>({})
  const [postsToCopy, setPostsToCopy] = React.useState<PostedPost[]>([])
  const [rangeStartDate, setRangeStartDate] = React.useState<string | null>(null)
  const [rangeEndDate, setRangeEndDate] = React.useState<string | null>(null)
  const [range, setRange] = React.useState<HistoryPostsTimeRange>('all')

  const fetch$ = React.useRef<Subject<PostedPostsSearchParams & { sort: PostedSortBy }>>()
  const toggleExternalPostedVisible$ = React.useRef<Subject<string>>()
  const forceExternalPostsRefresh$ = React.useRef<Subject<string>>()
  const requeue$ = React.useRef<Subject<{ postIds: string[], ppids: string[], all: boolean, query?: string, types?: ContentType[] }>>()
  const selectionRef = React.useRef<{ postIds: string[], profileIds: string[], ppids: string[], all: boolean }>()

  React.useEffect(() => {
    // Update postsIdsRef when posts are loaded
    postsIdsRef.current = posts.map(p => p.id)
  }, [posts.length]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    fetch$.current = new Subject()
    fetch$.current
      .pipe(tap(({ page }) => {
        setLoading(true)
        setPage(page)
      }))
      .switchMap((params) => {
        const sortString = params.sort ? PostedSortBy[params.sort].toLowerCase() : undefined
        return dispatch(getPostedPosts({ ...params, sortBy: sortString })).pipe(
          catchError((e) => {
            console.log(e)
            dispatch(message('Error fetching posts! Please refresh.', 'error'))
            return Observable.of({ posts: [], error: true })
          })
        )
      })
      .subscribe((response: any) => {
        if (!response.error) {
          setHasNextPage(response.posts.length > 0)
          setExternalVisible(response.showExternal)
          setTotalPostsCount(response.totalPostsCount)
          if (response.page === 0) {
            postsDispatch(setPosts(response.posts))
          } else {
            postsDispatch(addPosts(response.posts))
          }
        }
        setLoading(false)
      })

    return () => fetch$.current?.unsubscribe()
  }, [])

  React.useEffect(() => {
    toggleExternalPostedVisible$.current = new Subject()
    toggleExternalPostedVisible$.current.concatMap(ppid => dispatch(toggleExternalPostsVisible(ppid))
      .pipe(catchError(() => {
        dispatch(message(intl.formatMessage({ id: 'errors.generic' }), 'error'))
        return Observable.of({ error: true })
      }))
    )
      .subscribe((response) => {
        if (!response.error) {
          setRefreshKey(current => current + 1)
        }
      })

    return () => toggleExternalPostedVisible$.current?.unsubscribe()
  }, [])

  React.useEffect(() => {
    forceExternalPostsRefresh$.current = new Subject()
    forceExternalPostsRefresh$.current.concatMap(ppid => dispatch(forceFetchExternalPosts(ppid))
      .pipe(catchError(() => {
        dispatch(message(intl.formatMessage({ id: 'errors.generic' }), 'error'))
        return Observable.of({ error: true })
      }))
    )
      .subscribe((response) => {
        if (!response.error) {
          setRefreshKey(current => current + 1)
          dispatch(message(intl.formatMessage({ id: 'post.hint.fetching-posted-insights' })))
        }
      })

    return () => forceExternalPostsRefresh$.current?.unsubscribe()
  }, [])

  React.useEffect(() => {
    requeue$.current = new Subject()
    requeue$.current.concatMap(({ ppids, postIds, all, query, types }) => {
      return dispatch(requeuePost(postIds, ppids, all, true, undefined, query, types))
        .pipe(
          catchError((error) => {
            if (error.message) {
              dispatch(message(error.message, 'error', NOTIFICATION_DURATION_LONG))
            } else {
              dispatch(message(intl.formatMessage({ id: 'errors.generic' }), 'error'))
            }
            return Observable.of({ error })
          })
        )
    })
      .subscribe((response) => {
        if (!response.error) {
          dispatch(message(intl.formatMessage({ id: 'notifications.post-replanned' }), 'success'))
        }
      })

    return () => requeue$.current?.unsubscribe()
  }, [dispatch, intl])

  React.useEffect(() => {
    if (fetchIdsString) {
      postsDispatch(setPosts([]))
      fetch$.current?.next({
        page: 0,
        pageIds: fetchIdsString.split(','),
        sort: sortBy,
        sortDirection,
        query: search.query,
        types: search.types,
        startDate: rangeStartDate,
        endDate: rangeEndDate,
        searchInArticleSummary: search.searchInArticleSummary
      })
    }
  }, [
    fetchIdsString,
    sortBy,
    refreshKey,
    search.query,
    search.types,
    sortDirection,
    rangeStartDate,
    rangeEndDate,
    search.searchInArticleSummary
  ])

  React.useEffect(() => {
    const profileIds = fetchIdsString.split(',')
    const ppids = Object.values(connectedProfiles).filter(p => profileIds.includes(p.idNew)).map(p => p.ppid)
    if (selectedPostsIds === 'all') {
      selectionRef.current = {
        all: true,
        postIds: posts.map(p => p.id),
        profileIds,
        ppids
      }
    } else {
      selectionRef.current = {
        all: false,
        profileIds,
        ppids,
        postIds: Object.keys(selectedPostsIds).filter(id => selectedPostsIds[id])
      }
    }
  }, [selectedPostsIds, posts, fetchIdsString, connectedProfiles])

  const togglePostSelected = React.useCallback((id: string) => {
    setSelectedPostsIds(current => {
      if (current === 'all') {
        return postsIdsRef.current.reduce((selection: IndexedObject<boolean>, postId) => {
          selection[postId] = postId !== id
          return selection
        }, {})
      }
      return { ...current, [id]: !current[id] }
    })
  }, [])

  const toggleAllSelected = React.useCallback(() => {
    setSelectedPostsIds(current => {
      if (current === 'all') {
        return {}
      }
      return 'all'
    })
  }, [])

  const loadNextPage = React.useCallback(() => {
    if (hasNextPage && !loading && fetchIdsString) {
      fetch$.current?.next({
        page: page + 1,
        pageIds: fetchIdsString.split(','),
        sort: sortBy,
        sortDirection,
        query: search.query,
        types: search.types,
        startDate: rangeStartDate,
        endDate: rangeEndDate,
        searchInArticleSummary: search.searchInArticleSummary
      })
    }
  }, [
    hasNextPage,
    loading,
    fetchIdsString,
    page,
    sortBy,
    sortDirection,
    search.query,
    search.types,
    search.searchInArticleSummary,
    rangeStartDate,
    rangeEndDate
  ])

  const closePostMediaPreviewDialog = React.useCallback(() => {
    setPostInMediaPreviewDialog(null)
  }, [])

  // EXPL: Reset externalVisible when switching profiles. It must be set onlt when posts are fetched
  const firstProfilePpid = selectedProfiles[0]?.ppid
  React.useEffect(() => {
    setExternalVisible(undefined)
  }, [firstProfilePpid])

  React.useEffect(() => {
    const ppid = selectedProfiles[0]?.ppid
    if (!ppid) {
      return
    }

    if (params.type === 'post-planner' && externalVisible) {
      toggleExternalPostedVisible$.current?.next(ppid)
      return
    }

    if (params.type === 'all-publishers' && externalVisible === false) {
      dispatch(message(intl.formatMessage({ id: 'post.hint.fetching-posted-insights' })))
      toggleExternalPostedVisible$.current?.next(ppid)
    }
  }, [params.type, externalVisible, dispatch, selectedProfiles, intl])

  const onToggleExternalPostedVisible = React.useCallback(() => {
    if (params.type === 'post-planner') {
      navigate('/history/posted/all-publishers', { replace: true })
    } else {
      navigate('/history/posted/post-planner', { replace: true })
    }
  }, [navigate, params.type])

  const onForceExternalPostsRefresh = React.useCallback(() => {
    const profile = selectedProfiles[0]
    const ppid = profile?.ppid
    forceExternalPostsRefresh$.current?.next(ppid)
  }, [selectedProfiles])

  const onSortChange = React.useCallback((value: PostedSortBy) => {
    const sortAvailable = dispatch(checkFeatureAvailability(FEATURE_POST_INSIGHTS_ACTIONS_SORT))
    if (!sortAvailable) {
      return
    }

    // If changing the sort key, reset page to 0
    if (value !== sortBy) {
      setSortBy(value)
      dispatch(trackHubspotEvent(HS_EVENT_POSTS_SORTED))
    }
  }, [sortBy, dispatch])

  const onRequeue = React.useCallback((id: string, ppid: string) => {
    const requeueAvailable = dispatch(checkFeatureAvailability(FEATURE_POST_INSIGHTS_ACTIONS_REPLAN))
    if (!requeueAvailable) {
      return
    }

    requeue$.current?.next({ postIds: [id], ppids: [ppid], all: false })
  }, [dispatch])

  const requeuePosts = React.useCallback(() => {
    const requeueAvailable = dispatch(checkFeatureAvailability(FEATURE_POST_INSIGHTS_ACTIONS_REPLAN))
    if (!requeueAvailable || selectionRef.current?.postIds.length === 0) {
      return
    }
    requeue$.current?.next({
      postIds: selectionRef.current?.postIds || [],
      ppids: selectionRef.current?.ppids || [],
      all: Boolean(selectionRef.current?.all),
      query: search.query,
      types: search.types
    })
  }, [dispatch, search.query, search.types])

  const onRepost = React.useCallback((post: PostedPost) => {
    dispatch(resetComposer())
    dispatch(mergeState(getDerivedStateFromPost(post, 'posted')))
    navigate('/composer', { state: { floating: true, modal: 'compose', opener: '/history/posted', background: location } })
  }, [dispatch, location, navigate])

  const onFilterPosts = React.useCallback((query: string, types: ContentType[], searchInArticleSummary?: boolean) => {
    setSearch({ query, types, searchInArticleSummary })
  }, [])

  const onCopyPosts = React.useCallback(() => {
    const copyAllowed = dispatch(checkFeatureAvailability(FEATURE_POST_COPY))
    if (copyAllowed) {
      if (selectionRef.current?.all) {
        setPostsToCopy(posts)
      } else if (selectionRef.current?.postIds) {
        setPostsToCopy(posts.filter(p => selectionRef.current?.postIds.includes(p.id)))
      }
    }
  }, [posts, dispatch])

  const closeCopyPostsPopup = React.useCallback(() => {
    setPostsToCopy([])
    setSelectedPostsIds({})
  }, [])

  const onCopyPost = React.useCallback((post: PostedPost) => {
    const copyAllowed = dispatch(checkFeatureAvailability(FEATURE_POST_COPY))
    if (copyAllowed) {
      setPostsToCopy([post])
    }
  }, [dispatch])

  const onSelectedRangeChange = (value: HistoryPostsTimeRange, start?: string, end?: string) => {
    setRange(value)
    setRangeStartDate(start || null)
    setRangeEndDate(end || null)
  }

  const sortMenuDisabled = selectedProfiles.length !== 1 || posts.length === 0 || loading

  const selectedPostsCount = React.useMemo(() => {
    return selectedPostsIds !== 'all'
      ? Object.values(selectedPostsIds).filter(Boolean).length
      : totalPostsCount || 0
  }, [totalPostsCount, selectedPostsIds])

  const isFBGroupSelected = selectedProfiles.length === 1 && selectedProfiles[0].fbGroup
  const externalUnsupported = [BRAND_TIKTOK, BRAND_YOUTUBE, BRAND_GOOGLE].includes(selectedProfiles[0]?.type as any) || isFBGroupSelected
  const allowExternalPosts = selectedProfiles.length === 1 && !selectedProfiles[0].fbGroup && !externalUnsupported
  const bulkActionsProps = React.useMemo(() => {
    return {
      withRequeue: true,
      withCopy: true,
      hidePostActions: false,
      allSelected: selectedPostsIds === 'all',
      disabled: selectedPostsCount === 0,
      selectedCount: selectedPostsCount,
      onBulkRequeue: requeuePosts,
      onToggleAllSelected: toggleAllSelected,
      onCopy: onCopyPosts
    }
  }, [selectedPostsIds, selectedPostsCount, toggleAllSelected, requeuePosts, onCopyPosts])

  return (
    <div>
      <div className={styles['header-posted']}>
        <PostedFilter
          allowExternal={allowExternalPosts}
          isFBGroupSelected={isFBGroupSelected}
          isUnsupportedNetwork={externalUnsupported}
          externalPostedVisible={Boolean(externalVisible)}
          className={styles['posted-via']}
          toggleExternalPostedVisible={onToggleExternalPostedVisible}
          onRefresh={onForceExternalPostsRefresh}
        />
        <PostsFilter
          activeTypes={search.types}
          range={range}
          onRangeChange={onSelectedRangeChange}
          onFilter={onFilterPosts}
        />
        <div className={styles['align-right']}>
          {typeof totalPostsCount === 'number' && (
            <div className={styles.count}>
              <FormattedMessage id="post.planned.posts-count-label" values={{ count: totalPostsCount }} />
            </div>
          )}
          <PostedSortMenu
            sortBy={sortBy}
            sortDirection={sortDirection}
            network={selectedProfiles[0]?.type}
            disabled={sortMenuDisabled}
            disabledMessage={selectedProfiles.length !== 1
              ? intl.formatMessage({ id: 'post.notifications.sort-by-single-profile' })
              : undefined}
            className={styles['posted-sort-menu']}
            onSortChange={onSortChange}
            onSortDirectionChange={setSortDirection}
          />
          <HelpToggleButton />
        </div>
      </div>
      <div className={`${styles.content} ${styles['content-posted']} ${sortBy !== PostedSortBy.Time ? styles['with-padding'] : ''}`}>
        <PostedPostsList
          posts={posts}
          sortBy={sortBy}
          selectedPosts={selectedPostsIds}
          bulkActionsProps={bulkActionsProps}
          actionsClassName={styles['actions-posted']}
          onLoadNextPage={loadNextPage}
          onMediaPreviewBtnClick={setPostInMediaPreviewDialog}
          onRepost={onRepost}
          onRequeuePosted={onRequeue}
          onCopy={onCopyPost}
          onTogglePostSelected={togglePostSelected}
        />
        {!loading && selectedProfiles.length === 0 && (
          <PostErrorView title={ERROR_NO_PROFILES_TITLE} subtitle={ERROR_NO_PROFILES_SUBTITLE} />
        )}
        {!loading && selectedProfiles.length !== 0 && posts.length === 0 && (
          <EmptyView
            icon={mdiFeather}
            title="No posted posts"
            subtitle={(
              <div>
                <NavLink
                  to={{ pathname: '/composer' }}
                  state={{ floating: true, modal: 'compose', opener: location.pathname, background: location }}
                >
                  Create a new post
                </NavLink>
                &nbsp;or&nbsp;<NavLink to="/content/search/content">search for content</NavLink>
              </div>
            )}
            backdrop
          />
        )}
        {loading && <SimpleLoader small />}
      </div>
      <PostFilesDialog
        active={Boolean(postInMediaPreviewDialog)}
        images={postInMediaPreviewDialog?.images || []}
        onClose={closePostMediaPreviewDialog}
      />
      <CopyPostsPopup
        open={postsToCopy.length > 0}
        posts={postsToCopy as any[]}
        allSelected={Boolean(selectionRef.current?.all)}
        query={search.query}
        types={search.types}
        postType="posted"
        onClose={closeCopyPostsPopup}
        onCopyStarted={closeCopyPostsPopup}
      />
    </div>
  )
}

export default PostedPostsManager
