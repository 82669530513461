import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Stream, RangeFilter, Feed, AnyContent, FilterType, ContentItem } from 'interfaces'
import { BricksSize, ScrollConfiguration } from './StreamBricksView'
import StreamViewNavigation from './StreamViewNavigation'
import { Route, Routes, useLocation, useMatch, useNavigate } from 'react-router-dom'
import { StreamRoute } from './StreamRoute'
import FeatureChecker from 'components/FeatureChecker'
import { checkFeatureAvailability, checkProductLimit } from 'services/product'
import { FEATURE_FIND_MY_STREAMS_SAVE, FEATURE_FIND_PRIVATE_STREAMS, LIMIT_SAVED_STREAMS } from 'shared/constants'
import { useDispatch, useSelector } from 'react-redux'
import { userSavedStreamsSelector, userStreamIdsSelector, userStreamsSelector } from 'services/content/selectors'
import { Subject } from 'rxjs/Subject'
import { Observable } from 'rxjs/Observable'
import { catchError } from 'rxjs/operators/catchError'
import { deleteStream, createStream, getStreams } from 'services/content/streams/actions'
import { StoreThunkDispatch } from 'store/state'
import StreamViewHeader from './StreamViewHeader'
import { useIntl } from 'react-intl'
import { message } from 'services/snackbar'
import { StreamFeedRoute } from './StreamFeedRoute'
import { RouteErrorView } from 'components/App/components/ErrorFallback/RouteErrorView'

export interface StreamViewProps {
  stream: Stream
  navigation: StreamViewNavigation
  headerButton?: React.ReactElement
  showBackLink?: boolean
  hideSaveButton?: boolean
  sizes?: BricksSize[]
  align?: 'left' | 'center'
  itemWidth?: number
  scroll?: ScrollConfiguration

  // These two methods are used to fix an issue with Redirect not working in SearchStreamRoute.
  renderFeedRedirect?: (pathname: string) => React.ReactNode
  renderStreamRedirect?: (pathname: string) => React.ReactNode

  onBackClick?(): void
  onFeedItemPinned?(stream: Stream, feed: Feed, content: AnyContent): void
  onFeedItemShared?(stream: Stream, feed: Feed, content: AnyContent): void | boolean
  onFeedFilterChanged?(stream: Stream, feed: Feed, filter: FilterType): void
  onFeedRangeChanged?(stream: Stream, feed: Feed, range: RangeFilter): void
  onFeedGotoClicked?(stream: Stream, feed: Feed, content: AnyContent, by: string): void
  onStreamItemPinned?(stream: Stream, content: AnyContent): void
  onStreamItemShared?(stream: Stream, content: AnyContent): void | boolean
  onStreamFilterChanged?(stream: Stream, filter: FilterType): void
  onStreamRangeChanged?(stream: Stream, range: RangeFilter): void
  onStreamGotoClicked?(stream: Stream, content: AnyContent, by: string): void
  onViewFeed?(stream: Stream, feed: Feed): void
  onContentItemClick?(item: ContentItem): void
  onStreamSaved?(stream: Stream): void
  onMount?(stream: Stream): void
}

export function StreamView(props: StreamViewProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const location = useLocation()
  const intl = useIntl()
  const navigate = useNavigate()
  const streams = useSelector(userStreamsSelector)
  const savedStreamsCount = useSelector(userSavedStreamsSelector).length
  const streamIds = useSelector(userStreamIdsSelector)
  const [loading, setLoading] = useState(false)
  const match = useMatch(`${props.navigation.prefix}/feeds/:feedId/*`)

  const activeFeed = props.stream.feeds.find(f => f.id === match?.params.feedId || f.uniqueSource === match?.params.feedId)
  const isMyStreamsView = location.pathname.indexOf('/content/my-streams') === 0

  const delete$ = useRef<Subject<string>>()
  const save$ = useRef<Subject<void>>()

  React.useEffect(() => {
    if (props.onMount) {
      props.onMount(props.stream)
    }
  }, [props.stream])

  useEffect(() => {
    delete$.current = new Subject()
    delete$.current
      .flatMap(id => {
        return Observable.fromPromise(dispatch(deleteStream(id)).unwrap()).pipe(catchError((error: any) => {
          return Observable.of({ error })
        }))
      })
      .subscribe((response: any) => {
        setLoading(false)
        if (response.error) {
          dispatch(message(intl.formatMessage({ id: 'errors.generic' }), 'error'))
        }
      })
    return () => {
      delete$.current?.unsubscribe()
    }
  }, [dispatch, intl])

  useEffect(() => {
    save$.current = new Subject()
    save$.current
      .flatMap(() => {
        const id = props.stream.originalId || props.stream.id
        return Observable.fromPromise(
          dispatch(createStream({ name: props.stream.title, id, color: props.stream.color })).unwrap()
        )
          .flatMap(() => Observable.fromPromise(dispatch(getStreams(true)).unwrap()))
          .pipe(catchError((error) => {
            return Observable.of({ error })
          }))
      })
      .subscribe((response: any) => {
        setLoading(false)
        if (response.error) {
          dispatch(message(intl.formatMessage({ id: 'errors.generic' }), 'error'))
        } else if (props.onStreamSaved) {
          props.onStreamSaved(props.stream)
        }
      })

    return () => {
      save$.current?.unsubscribe()
    }
  }, [dispatch, intl, props.stream])

  const isSavedStream = useMemo(() => {
    const stream = props.stream
    const id = stream.id
    const originalId = stream.originalId || stream.id
    const ids = { [originalId]: true, [id]: true }
    return streamIds.indexOf(id) !== -1
      || streamIds.indexOf(originalId) !== -1
      || !!streams.find(s => ids[s.originalId || ''] || ids[s.id])
  }, [props.stream, streamIds, streams])

  const toggleStreamSaved = () => {
    // If it's already saved stream, delete it
    if (isSavedStream) {
      const originalId = props.stream.originalId || props.stream.id
      const userStreamToDelete = streams.find(s => s.id === props.stream.id || s.originalId === originalId)
      if (userStreamToDelete) {
        setLoading(true)
        delete$.current?.next(userStreamToDelete.id)
      }
    } else {
      const saveStreamAllowed = dispatch(checkFeatureAvailability(FEATURE_FIND_MY_STREAMS_SAVE))
      if (!saveStreamAllowed) {
        return
      }

      const withinLimit = dispatch(checkProductLimit(LIMIT_SAVED_STREAMS, savedStreamsCount + 1))
      if (!withinLimit) {
        return
      }

      setLoading(true)
      save$.current?.next()
    }
  }

  const goBack = () => navigate(-1)

  const gotoHome = () => {
    navigate('/content')
  }

  return (
    <section data-test="stream-view">
      {props.stream.isPrivate && (
        <FeatureChecker features={[FEATURE_FIND_PRIVATE_STREAMS]} closeCallback={gotoHome} />
      )}
      <StreamViewHeader
        isSaved={isSavedStream}
        saving={loading}
        stream={props.stream}
        navigation={props.navigation}
        location={{
          search: location.search,
          state: location.state
        }}
        activeFeed={activeFeed}
        hideSaveButton={props.hideSaveButton}
        customButton={props.headerButton}
        showBackLink={props.showBackLink === undefined ? isMyStreamsView : props.showBackLink}
        onSave={toggleStreamSaved}
        onBackClick={props.onBackClick || goBack}
      />
      <Routes>
        <Route
          path="feeds/:feed/*"
          element={(
            <StreamFeedRoute
              stream={props.stream}
              align={props.align}
              sizes={props.sizes}
              itemWidth={props.itemWidth}
              isSavedStream={isSavedStream}
              isMyStreamsView={isMyStreamsView}
              navigation={props.navigation}
              streamIds={streamIds}
              renderFeedRedirect={props.renderFeedRedirect}
              onContentItemClick={props.onContentItemClick}
              onFilterChanged={props.onFeedFilterChanged}
              onRangeChanged={props.onFeedRangeChanged}
              onGotoClicked={props.onFeedGotoClicked}
              onItemPinned={props.onFeedItemPinned}
              onItemShared={props.onFeedItemShared}
              onView={props.onViewFeed}
            />
          )}
          errorElement={<RouteErrorView />}
        />
        <Route
          path="/*"
          element={(
            <StreamRoute
              stream={props.stream}
              navigation={props.navigation}
              align={props.align}
              sizes={props.sizes}
              scroll={props.scroll}
              itemWidth={props.itemWidth}
              isSavedStream={isSavedStream}
              isMyStreamsView={isMyStreamsView}
              streamIds={streamIds}
              onContentItemClick={props.onContentItemClick}
              onItemPinned={props.onStreamItemPinned}
              onItemShared={props.onStreamItemShared}
              onFilterChanged={props.onStreamFilterChanged}
              onRangeChanged={props.onStreamRangeChanged}
              onGotoClicked={props.onStreamGotoClicked}
              renderStreamRedirect={props.renderStreamRedirect}
            />
          )}
          errorElement={<RouteErrorView />}
        />
      </Routes>
    </section>
  )
}

export default StreamView
