export * from './actions'
export * from './selectors'

import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './state'
import { getAlbums, getDestinations, getPinterestBoards, removeProfile, toggleProfileConnected } from './actions'
import { arrayToRecord } from 'shared'

const profilesSlice = createSlice({
  name: 'profiles',
  initialState: initialState(),
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(getAlbums.fulfilled, (state, action) => {
      const { profileId, albums } = action.payload
      state.destinations[profileId].fbAlbums = albums
    })

    builder.addCase(getPinterestBoards.fulfilled, (state, action) => {
      const { ppid, boards, profileId } = action.payload
      state.destinations[profileId].pinterestBoards = boards
      state.pinterestBoardsByPpid[ppid] = Object.values(boards)
    })

    builder.addCase(toggleProfileConnected.fulfilled, (state, action) => {
      const profileId = action.payload
      const current = state.destinations[profileId]

      if (current) {
        current.connected = !current.connected
      }
    })

    builder.addCase(getDestinations.fulfilled, (state, action) => {
      const { destinations, fbPersonalProfiles } = action.payload
      state.destinations = arrayToRecord(destinations, 'id')
      if (fbPersonalProfiles) {
        state.facebookPersonalProfiles = fbPersonalProfiles
      }
    })

    builder.addCase(removeProfile.fulfilled, (state, action) => {
      const id = action.payload
      const facebookPersonalProfileIndex = state.facebookPersonalProfiles.findIndex(p => p.id === id)

      if (facebookPersonalProfileIndex !== -1) {
        const fbProfile = state.facebookPersonalProfiles[facebookPersonalProfileIndex]
        state.facebookPersonalProfiles.splice(facebookPersonalProfileIndex, 1)
        Object.values(state.destinations).forEach(profile => {
          if (profile.ownedBy === fbProfile.ownerId) {
            delete state.destinations[profile.id]
          }
        })
      } else {
        delete state.destinations[id]
      }
    })
  }
})

export default profilesSlice.reducer
