import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { userStreamsSelector } from 'services/content/selectors'
import { AppNavLink } from '../AppNavLink'
import styles from '../AppNavigation.pcss'
import { PPNavLink } from 'components/PPNavLink'
import SocialIcon from 'components/SocialIcon'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { NavLink } from 'react-router-dom'
import IconEdit from '@mui/icons-material/Edit'
import IconDelete from '@mui/icons-material/Close'
import IconLock from '@mui/icons-material/Lock'
import { Stream } from 'interfaces'
import { Subject } from 'rxjs/Subject'
import { StoreThunkDispatch } from 'store/state'
import { deleteStream, reorderStreams } from 'services/content/streams/actions'
import { catchError } from 'rxjs/operators/catchError'
import { message } from 'services/snackbar'
import { Observable } from 'rxjs/Observable'
import EditStreamDialog from 'components/EditStreamDialog'
import { DndContext, useDroppable, useSensors, useSensor, PointerSensor } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { SortableContext, rectSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { sortByKeyAscending } from 'utils/sort/order'
import { reorder } from 'shared/utils'
import {
  SEARCH_FILTER_KEY_FACEBOOK,
  SEARCH_FILTER_KEY_LINKEDIN,
  SEARCH_FILTER_KEY_REDDIT,
  SEARCH_FILTER_KEY_RSS
} from 'routes/search/state/types'
import { BRAND_TWITTER } from 'shared'

interface StreamsNavMenuProps {
  expanded: boolean
  expandedStreamId?: string
  onToggleExpanded: () => void
  onToggleExpandedStream: (id: string) => void
}

export function StreamsNavMenu(props: StreamsNavMenuProps) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const intl = useIntl()
  const streams = useSelector(userStreamsSelector) as Stream[]
  const streamsMap = React.useMemo(() => streams.reduce((map: Record<string, Stream>, s: Stream) => {
    map[s.id] = s
    return map
  }, {}), [streams])
  const idOrder = React.useMemo(() => streams.slice().sort(sortByKeyAscending('order')).map(s => s.id), [streams])
  const [order, setOrder] = React.useState<string[]>(idOrder)
  const [streamToEdit, setStreamToEdit] = React.useState<Stream | null>(null)
  const deleteStream$ = React.useRef<Subject<string>>()
  const orderRef = React.useRef<string[]>(idOrder)
  const { setNodeRef: setDroppableNodeRef } = useDroppable({ id: 'droppable' })

  React.useEffect(() => {
    setOrder(idOrder)
    orderRef.current = idOrder
  }, [idOrder])

  const reorder$ = React.useRef<Subject<string[]>>()

  React.useEffect(() => {
    reorder$.current = new Subject()
    reorder$.current.switchMap((ids) => {
      return Observable.fromPromise(dispatch(reorderStreams(ids)).unwrap()).pipe(catchError((error) => {
        dispatch(message(intl.formatMessage({ id: 'notifications.generic.update-failed' }), 'error'))
        return Observable.of({ error })
      }))
    })
      .subscribe((response: any) => {
        if (response.error) {
          // revert to previous ordering
          setOrder(orderRef.current)
        } else {
          setOrder(response)
          orderRef.current = response
        }
      })
  }, [dispatch, intl])

  React.useEffect(() => {
    deleteStream$.current = new Subject()
    deleteStream$.current
      .flatMap(id => Observable.fromPromise(dispatch(deleteStream(id)).unwrap()).pipe(catchError((e) => {
        dispatch(message('An error occured, please try again!', 'error'))
        return Observable.of({ error: e })
      })))
      .subscribe((res: any) => {
        if (!res.error) {
          dispatch(message('Stream deleted!', 'success'))
        }
      })

    return () => deleteStream$.current?.unsubscribe()
  }, [dispatch])

  const removeStream = (id: string) => {
    deleteStream$.current?.next(id)
  }

  const editStream = (s: Stream) => {
    setStreamToEdit(s)
  }

  const closeEditStreamDialog = () => {
    setStreamToEdit(null)
  }

  const onDragEnd = (event: any) => {
    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = order.indexOf(active.id)
      const newIndex = order.indexOf(over.id)
      const reordered = reorder(order, oldIndex, newIndex)
      setOrder(reordered)
      reorder$.current?.next(reordered)
    }
  }

  const onStreamCreateClick = () => {
    dispatch(message(intl.formatMessage({ id: 'app.nav.my-streams-create-hint' }), 'info', 5000)) // eslint-disable-line no-magic-numbers
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5
      }
    })
  )

  return (
    <DndContext sensors={sensors} onDragEnd={onDragEnd}>
      <SortableContext items={order} strategy={rectSortingStrategy} modifiers={[restrictToParentElement]}>
        <div ref={setDroppableNodeRef}>
          <AppNavLink
            expanded={props.expanded}
            toggleExpandedOnClick
            label={<FormattedMessage id="app.nav.my-streams" />}
            to="/content/my-streams"
            withTooltip={!props.expanded}
            expandedItemKey={props.expandedStreamId}
            items={
              <React.Fragment>
                {order.map(id => {
                  const stream = streamsMap[id]
                  if (!stream) {
                    return null
                  }
                  return (
                    <DraggableStreamLink
                      key={stream.id}
                      stream={stream}
                      isExpanded={props.expandedStreamId === stream.id}
                      onEdit={editStream}
                      onRemove={removeStream}
                      onToggleExpanded={props.onToggleExpandedStream}
                    />
                  )
                })}
                <NavLink
                  to="/content/search/sources"
                  state={{
                    filters: [SEARCH_FILTER_KEY_FACEBOOK, SEARCH_FILTER_KEY_LINKEDIN, SEARCH_FILTER_KEY_REDDIT, SEARCH_FILTER_KEY_RSS]
                  }}
                  className={`${styles['child-link']} ${styles['link-action']}`}
                  onClick={onStreamCreateClick}
                >
                  + <FormattedMessage id="app.nav.my-streams-create" />
                </NavLink>
              </React.Fragment>
            }
            onToggleExpanded={props.onToggleExpanded}
          />
          {Boolean(streamToEdit) && (
            <EditStreamDialog
              open
              stream={streamToEdit as Stream}
              onClose={closeEditStreamDialog}
            />
          )}
        </div>
      </SortableContext>
    </DndContext>
  )
}

interface DraggableStreamLinkProps {
  stream: Stream
  isExpanded: boolean
  onEdit: (s: Stream) => void
  onRemove: (id: string) => void
  onToggleExpanded: (id: string) => void
}
function DraggableStreamLink(props: DraggableStreamLinkProps) {
  const { stream } = props
  const isEditable = !(stream.originalId || stream.protected)
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: stream.id })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    pointerEvents: isDragging ? 'none' : 'all'
  }

  const edit = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    props.onEdit(stream)
  }

  const remove = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    props.onRemove(stream.id)
  }

  const toggleExpanded = () => {
    props.onToggleExpanded(stream.id)
  }

  return (
    <div ref={setNodeRef} className={styles['streams-nav']} style={style as any}>
      <AppNavLink
        id={stream.id}
        key={stream.id}
        to={`/content/my-streams/${stream.id}`}
        className={styles['child-link']}
        small
        expanded={props.isExpanded}
        label={
          <div className={`${styles['name-box']}`} {...listeners} {...attributes}>
            {stream.isPrivate && <IconLock className={styles['stream-icon-lock']} />}
            <span className={`${styles.name} text-ellipsis`}>{stream.title}</span>
            <span className={styles.count}>{`(${stream.feedIds.length})`}</span>
          </div>
        }
        items={stream.feeds.map(feed => (
          <PPNavLink
            key={feed.id}
            to={`/content/my-streams/${stream.id}/feeds/${feed.id}`}
            className={styles['child-link']}
            activeClassName={styles.active}
          >
            <div className={`${styles['name-box']}`}>
              <SocialIcon outline={feed.type !== BRAND_TWITTER} icon={feed.type} className={styles['feed-icon']} />
              <span className={`${styles.name} text-ellipsis`}>{feed.name}</span>
            </div>
          </PPNavLink>
        ))}
        actions={
          <React.Fragment>
            {isEditable ? (
              <Tooltip title={<FormattedMessage id="actions.edit" />} placement="top">
                <IconButton size="small" onClick={edit}>
                  <IconEdit className={styles['link-icon']} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={<FormattedMessage id="actions.delete" />} placement="top">
                <IconButton size="small" onClick={remove}>
                  <IconDelete className={styles['link-icon']} />
                </IconButton>
              </Tooltip>
            )}
          </React.Fragment>
        }
        onToggleExpanded={toggleExpanded}
      />
    </div>
  )
}
