import * as React from 'react'
import styles from './QuotesFeedView.pcss'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Subject } from 'rxjs/Subject'
import { tap } from 'rxjs/operators/tap'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { StatusIdea, WithIntl } from 'interfaces'
import { getQuotes } from 'admin/services/quotes/actions'
import { QUOTES } from 'routes/find/routes/statuses/statuses.config'
import { CuratedFolderListView } from 'components/CuratedFolderView'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { QuoteFeed } from 'interfaces/Content/QuoteFeed'
import { NavLink, useNavigate } from 'react-router-dom'
import { StatusesSearchResults } from 'components/CuratedFolderView/Statuses'
import EmptyView from 'components/EmptyView'

interface QuotesFeedViewProps {
  feed: QuoteFeed
  gridView?: boolean
  hideHeader?: boolean
  className?: string
}

export function QuotesFeedView(props: QuotesFeedViewProps & WithIntl) {
  const dispatch = useDispatch<StoreThunkDispatch>()
  const navigate = useNavigate()
  const [page, setPage] = React.useState(0)
  const [items, setItems] = React.useState<StatusIdea[]>([])
  const [loading, setLoading] = React.useState(false)
  const [hasNextPage, setHasNextPage] = React.useState(true)
  const fetch$ = React.useRef<Subject<number>>()

  React.useEffect(() => {
    fetch$.current = new Subject()
    fetch$.current
      .pipe(tap(() => {
        setLoading(true)
      }))
      .switchMap((page) => {
        return dispatch(getQuotes(page, props.feed.name))
          .pipe(catchError(() => {
            return Observable.of([])
          }))
      })
      .subscribe((data: StatusIdea[]) => {
        setLoading(false)
        setHasNextPage(data.length > 0)
        setItems(current => ([...current, ...data]))
      })

    return () => {
      fetch$.current?.unsubscribe()
    }
  }, [])

  React.useEffect(() => {
    fetch$.current?.next(page)
  }, [page])

  const loadNextPage = () => {
    if (!loading && hasNextPage) {
      setPage(current => current + 1)
    }
  }

  const goBack = () => {
    navigate(-1)
  }

  return (
    <section className={props.className || ''}>
      {!props.hideHeader && (
        <header className={styles['quotes-header']}>
          <h2 className={`${styles.title} text-ellipsis`}>
            {props.feed.name}
          </h2>
          {props.gridView ? (
            <span className={styles.link} onClick={goBack}>
              <FormattedMessage id="general.carousel.nav.back" />
            </span>
          ) : (
            <NavLink to={`/content/quotes/${props.feed.name}`} className={styles.link}>
              <FormattedMessage id="general.carousel.nav.see-all" />
            </NavLink>
          )}
        </header>
      )}
      <div className={`${styles.content} ${props.gridView ? '' : styles.carousel}`}>
        {!props.gridView && (
          <CuratedFolderListView
            stream={QUOTES as any}
            items={items}
            loading={loading}
            onScrollLimit={loadNextPage}
          />
        )}
        {props.gridView && (
          <StatusesSearchResults
            items={items}
            search={{ active: true, loading, page, hasNextPage, query: props.feed.name }}
            onLoadMore={loadNextPage}
          />
        )}
        {!loading && items.length === 0 && (
          <EmptyView
            carousel={!props.gridView}
            title={<FormattedMessage id="content.msg.quotes-search-empty" />}
            subtitle={<FormattedMessage id="content.msg.quotes-search-empty-subtitle" />}
          />
        )}
      </div>
    </section>
  )
}

export default injectIntl(QuotesFeedView)
