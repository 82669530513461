import React, { Component, createElement, ReactElement, createRef, RefObject } from 'react'
import { connect } from 'react-redux'
import { findDOMNode } from 'react-dom'
import {
  AnyContent,
  RangeFilter,
  STATUS_TYPE,
  ContentType,
  ALL_RANGE_FILTER,
  NEW_RANGE_FILTER,
  WEEK_RANGE_FILTER,
  MONTH_RANGE_FILTER,
  ContentItem,
  ARTICLE_TYPE,
  Article,
  WithIntl,
  FilterType,
  ALL_FILTER,
  VIDEO_TYPE,
  FEED_TYPE_KEYWORD,
  ContentFeedType,
  VALID_RANGE_FILTERS,
  LiveArticleData
} from 'interfaces'
import AnyCard from 'components/Card'
import LoadingCard from 'components/Card/LoadingCard'
import CardBricks, { BricksSize } from 'components/CardBricks'
import ScrollListener from 'components/ScrollListener'
import RangeFilterSelect from 'components/RangeFilterSelect'
import { EmptyFetchError, FetchError } from 'components/Fetch/FetchError'
import ErrorOverlay, {
  NO_CONTENT_MESSAGE,
  AJAX_ERROR_STREAM_SUBTITLE,
  NO_CONTENT_MESSAGE_FAVORITES
} from 'components/ErrorOverlay'
import { TIMERANGE_FILTER_DEFAULT } from 'config'
import { StoreThunkDispatch } from 'store/state'
import { Observable } from 'rxjs/Observable'
import { Subject } from 'rxjs/Subject'
import { Subscription } from 'rxjs/Subscription'
import 'rxjs/add/observable/fromEvent'
import { checkFeatureAvailability, rangeFeatureMap, contentTypeFeatureMap } from 'services/product'
import { dedupeArticles, dedupeMixedArticles, SortBy } from 'services/content/util'
import { injectIntl } from 'react-intl'
import { resetScroll } from 'utils/dom'
import { SortBySelector } from './SortBySelector'
import StockContentSourceFilter from 'components/StockContentSourceFilter'
import ContentTypeFilter from 'components/ContentTypeFilter'
import { STOCK_ALL_FILTER } from 'interfaces/Content/StockContentTypes'
import { StockContentProvider, STOCK_CONTENT_PROVIDERS_ALL } from 'interfaces/Content/StockContentProvider'
import { getLastUsedSortBy, setLastUsedFilters, setLastUsedSortBy } from 'utils/filter-store'

export { BricksSize }

const PAGE_SIZE = 20
const isEqual = require('react-fast-compare')
import styles from './SourcesBricksView.pcss'
import Tooltip from '@mui/material/Tooltip'
import { addLiveArticleData, setVisibleSelectableItems } from 'services/content'
import { SelectableContent } from 'services/content/state'
import { PPProduct, STOCK_VIDEO_TYPE, arrayToRecord } from 'shared'
import { userProductSelector } from 'services/users/selectors'

export interface ScrollConfiguration {
  element?: Element
  useWindow?: boolean
}

export interface FetchableProps {
  page: number
  range: RangeFilter
  errors: { [key: string]: any }
  only?: ContentType
  sortBy?: SortBy
  stockSource?: StockContentProvider

  onFetched(content: AnyContent[]): void
  onFailed(error: any): void
}

export interface SourcesBricksViewOwnProps {
  filter: FilterType
  items: AnyContent[]
  contentTypes: ContentType[]
  feedType?: ContentFeedType
  hideSorting?: boolean
  hideFilters?: boolean
  isStream?: boolean
  favoritesMode?: boolean
  sourcesHash?: string
  className?: string
  align?: 'left' | 'center'
  sizes?: BricksSize[]
  scroll?: ScrollConfiguration
  range?: RangeFilter
  itemWidth?: number
  errorElement?: React.ReactElement
  skipContentCardsFeatureChecks?: boolean
  noContentRangeCheck?: boolean
  noContentTypeCheck?: boolean
  hideRangeFilter?: boolean
  hideEmptyCards?: boolean
  filtersContainerClassName?: string
  preventTopOverflow?: boolean

  createLoadMore<T extends FetchableProps>(props: FetchableProps): ReactElement<T>

  /**
   * Optional range change callback
   *
   * @param {RangeFilter} range
   * @returns {boolean} if true, won't handle reloading
   */
  onRangeChanged?(range: RangeFilter): boolean | void
  onFilterChange(filter: FilterType): void

  onItemPinned?(content: AnyContent): void
  onItemShared?(content: AnyContent): void | boolean
  onSourceClick?(content: AnyContent, by: string): void
  onFeedClick?(content: AnyContent): void
  onContentItemClick?(content: ContentItem): void
}

type SourcesBricksViewProps = SourcesBricksViewOwnProps & WithIntl & {
  product: PPProduct | undefined
  checkFeatureAvailability: (feature: string, checkOnly?: boolean, closeCallback?: () => void) => boolean
  setSelectableItems: (content: SelectableContent[]) => void
  addLiveArticleData: (id: string, data: LiveArticleData) => void
}

interface SourcesBricksViewState {
  loading: boolean
  range: RangeFilter,
  pages: {
    [range: string]: {
      [filter: string]: {
        page: number
        hasNext: boolean
      }
    }
  }
  items: { [range: string]: { [type: string]: AnyContent[] } }
  errors: { [range: string]: { [type: string]: any[] } }
  brokenPhotoIds: { next: string[], handled: string[] }
  stockSource: StockContentProvider
  sortBy: SortBy
  error?: any
  fetch?: ReactElement<FetchableProps>
  errorOffset: number
}

// eslint-disable-next-line no-magic-numbers
const LOADING_CARD_SIZES = [400, 260, 320, 380, 280, 320, 200, 320, 400, 260, 320, 380, 280, 320, 200, 320]

export class SourcesBricksView extends Component<SourcesBricksViewProps, SourcesBricksViewState> {

  private bricksPacked$: Subject<void>
  private windowResize$: Subscription
  private unmount$: Subject<boolean>
  private bricksRef: RefObject<CardBricks>
  private scrollListenerRef: RefObject<ScrollListener>
  private duplicateTracker: { [key: string]: { [key: string]: { [key: string]: boolean } } }
  private loadingCards: typeof LoadingCard[]

  constructor(props: SourcesBricksViewProps) {
    super(props)

    this.renderBricksItem = this.renderBricksItem.bind(this)
    this.onCompose = this.onCompose.bind(this)
    this.onBricksPacked = this.onBricksPacked.bind(this)
    this.onFetched = this.onFetched.bind(this)
    this.onFailed = this.onFailed.bind(this)
    this.onRangeChange = this.onRangeChange.bind(this)
    this.maybeLoadMore = this.maybeLoadMore.bind(this)
    this.onImageLoadingFailed = this.onImageLoadingFailed.bind(this)
    this.loadingCards = []

    this.scrollListenerRef = createRef<ScrollListener>()
    this.bricksRef = createRef<CardBricks>()
    this.bricksPacked$ = new Subject()

    const range = props.range || TIMERANGE_FILTER_DEFAULT

    this.duplicateTracker = { [range]: { [this.props.filter]: {} } }
    const currentDups = this.duplicateTracker[range]
    const pagesInitial = {
      [range]: {
        [props.filter]: {
          page: props.items.length > 0 ? 1 : 0,
          hasNext: true
        }
      }
    }

    this.state = {
      loading: false,
      range,
      pages: pagesInitial,
      items: {
        [range]: props.items.reduce((result, item) => {
          result[item.type] = result[item.type] || []
          result[item.type].push(item)

          currentDups[item.type] = currentDups[item.type] || {}
          currentDups[item.type][item.id] = true
          return result
        }, { all: props.items } as { [key: string]: AnyContent[] })
      },
      errors: { [range]: {} },
      stockSource: STOCK_CONTENT_PROVIDERS_ALL,
      error: undefined,
      fetch: undefined,
      errorOffset: 0,
      brokenPhotoIds: { next: [], handled: [] },
      sortBy: getLastUsedSortBy() as any || SortBy.Engagement
    }
  }

  shouldComponentUpdate(nextProps: any, nextState: SourcesBricksViewState) {
    return this.state.loading !== nextState.loading
      || this.state.error !== nextState.error
      || this.state.errorOffset !== nextState.errorOffset
      || this.state.sortBy !== nextState.sortBy
      || this.props.filter !== nextProps.filter
      || this.props.range !== nextProps.range
      || this.state.range !== nextState.range
      || !isEqual(this.state.brokenPhotoIds, nextState.brokenPhotoIds)
      || this.props.errorElement !== nextProps.errorElement
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: SourcesBricksViewProps) {
    const { sourcesHash, range, filter } = nextProps

    if (range && this.range !== range) {
      this.checkRangeLimits(range)
    }

    if (filter !== this.filter) {
      this.checkTypeLimits(filter as ContentType)
    }

    if (sourcesHash !== this.props.sourcesHash) {
      resetScroll()
      this.setState((previousState) => ({
        ...previousState,
        loading: false,
        pages: {
          [previousState.range]: {
            [filter]: {
              page: 0,
              hasNext: true
            }
          }
        },
        items: { [previousState.range]: {} },
        errors: { [previousState.range]: {} },
        error: undefined,
        fetch: undefined
      }), () => {
        this.duplicateTracker = { [this.state.range]: { [this.props.filter]: {} } }
        this.maybeLoadMore()
      })
    }

    // NOTE: Articles does not support sortBy Shares. Reset sort value.
    if (filter === ARTICLE_TYPE && this.state.sortBy === SortBy.Shares) {
      this.onSortByChange(SortBy.Engagement)
      return
    }

    if (!this.hasNextPageForFilters(range, filter) || !range) {
      if (range && range !== this.state.range) {
        this.setState(prevState => ({
          ...prevState,
          range,
          sortBy: range === NEW_RANGE_FILTER ? SortBy.Engagement : prevState.sortBy
        }))
      }
      return
    }

    if (range !== this.state.range || this.filter !== filter) {
      const page = this.getCurrentPage(range, filter)
      const errors = this.state.errors[range] || {}
      const loading = !this.invalidFilter
      const only = filter as ContentType

      this.setState((previousState) => ({
        ...previousState,
        range,
        loading,
        sortBy: range === NEW_RANGE_FILTER ? SortBy.Engagement : previousState.sortBy,
        fetch: this.props.createLoadMore({
          onFetched: this.onFetched,
          onFailed: this.onFailed,
          page,
          range,
          errors,
          only,
          sortBy: previousState.sortBy
        })
      }))
    }
  }

  resetStoredRangeFilter = () => {
    setLastUsedFilters(this.filter, WEEK_RANGE_FILTER)
  }

  checkRangeLimits = (range: RangeFilter, skipUpgradePopup?: boolean) => {
    // NEW and WEEK are available to all, skip check
    // Skip check for Stock content.
    const rangeAllowed = this.props.noContentRangeCheck
      || (range === NEW_RANGE_FILTER || range === WEEK_RANGE_FILTER || this.filter.startsWith('stock'))
      || this.props.checkFeatureAvailability(rangeFeatureMap[range], skipUpgradePopup, this.resetStoredRangeFilter)

    if (!rangeAllowed && skipUpgradePopup) {
      this.onRangeChange(WEEK_RANGE_FILTER)
    }
  }

  checkTypeLimits = (type: ContentType, skipUpgradePopup?: boolean) => {
    const typeAllowed = this.props.noContentTypeCheck
      || type === ALL_FILTER as string
      || this.props.checkFeatureAvailability(contentTypeFeatureMap[type], skipUpgradePopup)

    if (!typeAllowed && skipUpgradePopup) {
      this.onFilterChange(ARTICLE_TYPE)
    }
  }

  getCurrentPage = (range: RangeFilter, filter: FilterType) => {
    return this.state.pages[range] && this.state.pages[range][filter]
      ? this.state.pages[range][filter].page
      : 0
  }

  get invalidFilter() {
    return this.props.filter !== ALL_FILTER
      && this.props.filter !== STOCK_ALL_FILTER
      && this.props.contentTypes.indexOf(this.props.filter as any) === -1
  }

  get items() {
    this.state.items[this.range] = this.state.items[this.range] || []
    return this.state.items[this.range]
  }

  get errorForFilter() {
    return this.errorsForRange[this.props.filter]
  }

  get errorsForRange() {
    this.state.errors[this.range] = this.state.errors[this.range] || {}
    return this.state.errors[this.range]
  }

  get page() {
    if (!this.state.pages[this.range] || !this.state.pages[this.range][this.filter]) {
      return 0
    }

    return this.state.pages[this.range][this.filter].page
  }

  get range() {
    return this.state.range
  }

  get filter() {
    return this.props.filter
  }

  get hasNextPage() {
    if (!this.state.pages[this.range] || !this.state.pages[this.range][this.filter]) {
      return true
    }

    return this.state.pages[this.range][this.filter].hasNext
  }

  hasNextPageForFilters = (range?: RangeFilter, filter?: FilterType) => {
    const rangeKey = range || this.range
    const filterKey = filter || this.filter
    if (!this.state.pages[rangeKey] || !this.state.pages[rangeKey][filterKey]) {
      return true
    }

    return this.state.pages[rangeKey][filterKey].hasNext
  }

  onLiveArticleDataFetched = (id: string, data: LiveArticleData) => {
    this.props.addLiveArticleData(id, data)
  }

  renderBricksContent() {
    const { filter, itemWidth } = this.props
    const active = this.state.loading
    const error = this.errorForFilter

    const items = this.items[filter] || []
    const results: any[] = []
    const brokenPhotos = this.state.brokenPhotoIds.next.concat(this.state.brokenPhotoIds.handled)

    if (items.length !== 0) {
      let contentItems = items.filter(item => brokenPhotos.indexOf((item as any).hash) === -1)
      if (this.filter === ARTICLE_TYPE) {
        contentItems = dedupeArticles(contentItems as Article[])
      }

      if (this.filter === ALL_FILTER) {
        contentItems = dedupeMixedArticles(contentItems)
      }

      const itemCards = contentItems.map(this.renderBricksItem)
      if (!active && !error) {
        return itemCards
      }

      results.push(...itemCards)
    }

    const width = itemWidth
    const loadingCards: any[] = this.state.loading || (items.length === 0 && error && !this.props.hideEmptyCards)
      ? LOADING_CARD_SIZES.map((height, index) => {
        return (
          <LoadingCard
            ref={(ref: any) => { this.loadingCards[index] = ref }}
            key={`error-card-${index}:${filter}`}
            width={width}
            height={height}
            active={active}
          />
        )
      })
      : []

    // render error or loading cards
    return results.concat(loadingCards)
  }

  onImageLoadingFailed(item: ContentItem) {
    const brokenPhotosNext = this.state.brokenPhotoIds.next.slice()
    if (item.hash) {
      brokenPhotosNext.push(item.hash)
      this.setState((prevState) => ({ ...prevState, brokenPhotoIds: { ...prevState.brokenPhotoIds, next: brokenPhotosNext } }))
    }
  }

  // eslint-disable-next-line consistent-return
  onCompose(item: AnyContent) {
    if (this.props.onItemShared) {
      return this.props.onItemShared(item)
    }
  }

  renderBricksItem(item: AnyContent) {
    return createElement(AnyCard, {
      key: item.id,
      children: item,
      content: item,
      width: this.props.itemWidth,
      square: false,
      skipFeatureChecks: this.props.skipContentCardsFeatureChecks,
      onCompose: this.onCompose,
      onClick: this.props.onContentItemClick,
      onImageLoadingFailed: this.onImageLoadingFailed,
      onLiveArticleDataFetched: this.onLiveArticleDataFetched,
      ...(item.type === STATUS_TYPE
        ? {}
        : {
          onPin: this.props.onItemPinned,
          onSourceClick: this.props.onSourceClick,
          onFeedClick: this.props.onFeedClick
        })
    } as any)
  }

  maybeLoadMore() {
    const visibleItems = this.state.items[this.range][this.props.filter]
    const noItemsForFilter = !visibleItems && this.page !== 0
    if (this.state.loading || this.errorForFilter || this.invalidFilter || noItemsForFilter || !this.hasNextPage) {
      if (![VIDEO_TYPE, STOCK_VIDEO_TYPE, STATUS_TYPE].includes(this.filter)) {
        this.props.setSelectableItems(visibleItems as SelectableContent[] || [])
      }
      return
    }

    const page = this.page
    const range = this.range
    const errors = this.errorsForRange
    const only = this.filter as ContentType
    const sortBy = this.state.sortBy

    this.setState((previousState) => ({
      ...previousState,
      loading: true,
      fetch: this.props.createLoadMore({
        onFetched: this.onFetched,
        onFailed: this.onFailed,
        page,
        range,
        errors,
        only,
        sortBy,
        stockSource: previousState.stockSource
      })
    }))
  }

  emitBricksPacked = () => {
    this.bricksPacked$.next()
  }

  onBricksPacked() {
    if (this.scrollListenerRef.current) {
      this.scrollListenerRef.current.emitScroll()
    }
    this.updateErrorOffset()
  }

  updateErrorOffset() {
    if (!this.state.loading && (this.errorForFilter || (this.items[this.props.filter] || []).length === 0)) {
      if (!this.loadingCards.filter(Boolean).length) {
        const grid = findDOMNode(this.bricksRef.current!) as HTMLElement
        if (grid) {
          const padding = 68
          const offset = grid.scrollHeight + padding
          this.setState((pstate) => ({ ...pstate, errorOffset: offset }))
        }
        return
      }

      const tops = this.loadingCards
        .map((node: any) => node.parentElement)
        .filter(Boolean)
        .reduce((results, node: HTMLElement) => {
          const column = node.getAttribute('data-column') as string
          const top = node.getAttribute('data-top') as string
          if (typeof results[column] === 'undefined' || results[column] > +top) {
            results[column] = +top
          }
          return results
        }, {} as { [key: string]: number })

      const offset = Math.max(...Object.keys(tops).map(column => tops[column]))
      if (offset !== this.state.errorOffset) {
        this.setState((pstate) => ({ ...pstate, errorOffset: offset }))
      }
    }
  }

  onRangeChange(range: RangeFilter) {
    if (range === this.props.range) {
      return
    }
    resetScroll()

    if (this.props.onRangeChanged && this.props.onRangeChanged(range)) {
      return
    }

    const page = this.getCurrentPage(range, this.filter)
    const errors = this.state.errors[range] || {}
    const loading = !this.invalidFilter
    const only = this.filter as ContentType

    this.setState((previousState) => ({
      ...previousState,
      range,
      loading,
      fetch: this.props.createLoadMore({
        onFetched: this.onFetched,
        onFailed: this.onFailed,
        page,
        range,
        errors,
        only,
        sortBy: previousState.sortBy
      })
    }))
  }

  onStockSourceChange = (source: StockContentProvider) => {
    const range = this.range
    const errors = this.state.errors[range] || {}
    const loading = !this.invalidFilter
    const filter = this.filter as ContentType
    // Empty duplicate tracker
    this.duplicateTracker = { [range]: { [filter]: {} } }

    this.setState((previousState) => ({
      ...previousState,
      range,
      loading,
      stockSource: source,
      items: {}, // clear cached items
      errors: { [range]: {} }, // clear errors
      pages: { [range]: { [filter]: { page: 0, hasNext: true } } }, // reset paging for range and filter
      fetch: this.props.createLoadMore({
        onFetched: this.onFetched,
        onFailed: this.onFailed,
        page: 0, // reset page to 0
        range,
        errors,
        only: filter,
        stockSource: source,
        sortBy: previousState.sortBy
      })
    }))
  }

  onFetched(content: AnyContent[]) {
    this.duplicateTracker[this.range] = this.duplicateTracker[this.range] || {}
    const currentDups = this.duplicateTracker[this.range]
    const hasNextPage = content.length === PAGE_SIZE

    const pages = {
      ...this.state.pages,
      [this.range]: {
        ...this.state.pages[this.range],
        [this.filter]: {
          page: this.page + 1,
          hasNext: hasNextPage
        }
      }
    }

    const items = {
      ...this.state.items,
      [this.range]: content.reduce((result, item) => {
        currentDups[item.type] = currentDups[item.type] || {}

        // Check for undefined id and don't mark it as duplicate, it's a live fetch
        if (currentDups[item.type][item.id] && item.id.indexOf('undefined') === -1) {
          console.warn(`[duplicate] got a duplicate ${item.type} with id ${item.id} at page ${this.page} for range ${this.range}`)
          return result
        }

        result[item.type] = result[item.type] || []
        result[item.type].push(item)

        result[STOCK_ALL_FILTER] = result[STOCK_ALL_FILTER] || []
        if ((item as any).isStock) {
          result[STOCK_ALL_FILTER].push(item)
        }

        result[ALL_FILTER] = result[ALL_FILTER] || []
        result[ALL_FILTER].push(item)

        currentDups[item.type][item.id] = true
        return result
      }, this.items)
    }

    // Add selectable visible items to store
    if (this.filter !== VIDEO_TYPE && this.filter !== STOCK_VIDEO_TYPE && this.filter !== STATUS_TYPE) {
      const selectableItems = items[this.range][this.filter] as SelectableContent[]
      this.props.setSelectableItems(selectableItems)
    }

    // Show error if no items are returned for the active filter
    if (!this.state.items[this.range][this.props.filter] || !hasNextPage) {
      const error = new EmptyFetchError(this.props.filter as ContentType)
      const emptyContentError = Object.assign({}, this.state.errors[this.range], { [this.props.filter]: error })
      const errors = { ...this.state.errors, [this.range]: { ...emptyContentError } }

      this.setState(prevState => ({
        ...prevState,
        error,
        errors,
        loading: false,
        pages
      }))
      return
    }

    this.setState((previousState) => ({
      ...previousState,
      pages,
      items,
      loading: false
    }))
  }

  onFailed(error: any) {
    if (error instanceof FetchError) {
      const range = this.state.range
      const pages = {
        ...this.state.pages,
        [this.range]: {
          ...this.state.pages[this.range],
          [this.filter]: {
            page: this.page,
            hasNext: false
          }
        }
      }

      const errors = { ...this.state.errors, [range]: { ...this.state.errors[range], [error.type]: error } } as any
      const err = error.source ? error.source : error
      this.setState((previousState) => ({ ...previousState, error: err, errors, pages, loading: false }))
      if (this.page === 0) {
        this.props.setSelectableItems([])
      }
      return
    }

    this.setState((previousState) => ({ ...previousState, error }))
  }

  onFilterChange = (filter: FilterType) => {
    // reset stock source filter
    this.setState(prevState => ({
      ...prevState,
      stockSource: STOCK_CONTENT_PROVIDERS_ALL
    }))
    this.props.onFilterChange(filter)
    resetScroll()
  }

  renderError = () => {
    if (this.state.loading) {
      return null
    }
    const filter = this.props.filter
    const items = this.items[filter] || []
    const hasItems = items.length > 0

    if (typeof this.state.error?.response?.message === 'string') {
      const originalError = this.state.error
      return createElement(ErrorOverlay,
        { key: 'error', originalError, error: this.state.error.response.message, subtitle: '', offset: 0, contentType: filter })

    }

    if (this.props.errorElement && !hasItems) {
      return this.props.errorElement
    }

    const offset = this.state.errorOffset

    if (this.errorForFilter instanceof EmptyFetchError) {
      const originalError = this.state.error
      const error = hasItems
        ? this.props.intl.formatMessage({ id: 'content.msg-no-content-for-range' })
        : this.props.favoritesMode
          ? NO_CONTENT_MESSAGE_FAVORITES(filter, this.props.intl.formatMessage)
          : NO_CONTENT_MESSAGE(filter, this.props.intl.formatMessage)

      const subtitle = hasItems
        ? ''
        : this.props.intl.formatMessage({ id: 'content.msg-no-content-subtitle' })

      return createElement(ErrorOverlay, { key: 'error', originalError, error, subtitle, offset, contentType: filter, small: hasItems })
    }

    const error = this.props.intl.formatMessage({
      id: this.props.isStream ? 'content.msg-server-error-stream' : 'content.msg-server-error-feed'
    })
    const subtitle = AJAX_ERROR_STREAM_SUBTITLE(this.props.intl.formatMessage)

    if (this.errorForFilter instanceof FetchError) {
      const originalError = this.state.error
      return createElement(ErrorOverlay, { key: 'error', originalError, error, subtitle, offset, contentType: filter })
    }
    if (items.length > 0) {
      return null
    }

    const originalError = this.state.error || '<no error>'
    return createElement(ErrorOverlay, { key: 'error', originalError, error, subtitle, offset })
  }

  renderFetches() {
    return this.state.loading && this.state.fetch && !this.invalidFilter
      ? this.state.fetch
      : null
  }

  renderFilters() {
    const currentFilter = this.filter
    const showSortBy = !this.props.hideSorting && !currentFilter.startsWith('stock') // no sorting for stock content
    let sortByDisabled = false
    let sortByTooltip = ''

    if (currentFilter === VIDEO_TYPE && this.props.feedType === FEED_TYPE_KEYWORD) {
      // no sorting for youtube vids
      sortByDisabled = true
      sortByTooltip = this.props.intl.formatMessage({ id: 'content.tooltip-no-sorting-vids' })
    }

    const isGoogleFeed = this.props.feedType === FEED_TYPE_KEYWORD
    const sortByOptions = isGoogleFeed ? {
      [SortBy.Engagement]: { label: 'Stars' },
      [SortBy.Date]: { label: 'Most Recent' }
    } : undefined

    return createElement('div', { key: 'filters', className: `${styles.filters} ${this.props.filtersContainerClassName || ''}` }, [
      createElement(ContentTypeFilter, {
        key: 'content-type-filter',
        options: this.props.contentTypes,
        selected: currentFilter,
        onFilterSelected: this.onFilterChange
      }),
      currentFilter.startsWith('stock')
        ? createElement(StockContentSourceFilter, {
          key: 'stock-filter',
          filterType: currentFilter,
          selectedSource: this.state.stockSource,
          onSelectedSourceChange: this.onStockSourceChange
        })
        : this.props.hideRangeFilter ? null : createElement(RangeFilterSelect, {
          key: 'range-filter-select',
          range: this.range,
          onRangeSelected: this.onRangeChange
        }),
      showSortBy ? createElement(Tooltip, {
        key: 'sortby',
        title: sortByTooltip,
        children: createElement('div', undefined, createElement(SortBySelector, {
          key: 'sort-by',
          options: sortByOptions,
          value: this.state.sortBy,
          className: styles['sort-select'],
          withSharesOption: !isGoogleFeed,
          disabled: sortByDisabled,
          onChange: this.onSortByChange
        }))
      }) : null
    ])
  }

  onSortByChange = (value: SortBy) => {
    const range = this.range
    const filter = this.filter as ContentType
    resetScroll()
    // Empty duplicate tracker
    this.duplicateTracker = { [range]: { [filter]: {} } }

    setLastUsedSortBy(value)
    this.setState(prevState => ({
      ...prevState,
      sortBy: value,
      loading: true,
      fetch: this.props.createLoadMore({
        onFetched: this.onFetched,
        onFailed: this.onFailed,
        page: 0,
        range,
        errors: this.errorsForRange,
        only: filter,
        sortBy: value
      }),
      pages: {
        [range]: {
          [filter]: {
            page: 0,
            hasNext: true
          }
        }
      },
      items: { [range]: {} },
      errors: { [range]: {} }
    }))
  }

  onLayout = () => {
    if (this.props.preventTopOverflow) {
      document.querySelectorAll('[data-packed][data-touched]').forEach((el) => {
        if ((el as HTMLElement).dataset.top !== '0') {
          (el.children[0] as HTMLElement).style.transformOrigin = 'center'
        }
      })
      document.querySelectorAll('[data-packed][data-top="0"]').forEach((el: HTMLElement) => {
        (el.children[0] as HTMLDivElement).style.transformOrigin = 'top'
        el.dataset.touched = 'true'
      })
    }
  }

  render() {
    const className = `${styles.view} ${this.props.className || ''}`
    return createElement('div', { className, 'data-testid': 'sources-bricks-view' }, [
      !this.props.hideFilters && this.renderFilters(),

      createElement(ScrollListener, {
        key: 'scroller',
        ref: this.scrollListenerRef,
        className: styles.bricks,
        disabled: this.state.loading,
        emitTreshold: 250,
        onScrollDebounceTime: 50,
        emitInitial: false,
        onScroll: this.maybeLoadMore,
        useWindow: (this.props.scroll && this.props.scroll.useWindow),
        scrollElement: (this.props.scroll && this.props.scroll.element) || document.getElementsByTagName('main')[0]
      },
      createElement(CardBricks, {
        key: 'bricks',
        ref: this.bricksRef,
        sizes: this.props.sizes as BricksSize[],
        align: this.props.align,
        onPacked: this.emitBricksPacked,
        onLayout: this.onLayout
      },
      this.renderBricksContent()
      )
      ),

      this.renderError(),
      this.renderFetches()
    ])
  }

  componentDidMount() {
    const filter = this.props.filter

    if (this.invalidFilter) {
      // Set error for the invalid filter for all time ranges
      const error = new EmptyFetchError(this.props.filter as ContentType)
      const invalidFilterErrors = {
        [NEW_RANGE_FILTER]: { ...this.state.errors[NEW_RANGE_FILTER], [filter]: error },
        [MONTH_RANGE_FILTER]: { ...this.state.errors[MONTH_RANGE_FILTER], [filter]: error },
        [WEEK_RANGE_FILTER]: { ...this.state.errors[WEEK_RANGE_FILTER], [filter]: error },
        [ALL_RANGE_FILTER]: { ...this.state.errors[ALL_RANGE_FILTER], [filter]: error }
      }
      const errors = { ...this.state.errors, ...invalidFilterErrors } as any
      this.setState((previousState) => ({ ...previousState, error, errors }))
    }

    const features = this.props.product?.features || {}
    const current = rangeFeatureMap[this.range]
    const shouldUpdate = features[current] === false
    const next = VALID_RANGE_FILTERS.find(r => features[rangeFeatureMap[r]]) as RangeFilter || WEEK_RANGE_FILTER

    if (shouldUpdate) {
      // setLastUsedFilters(this.filter, next || WEEK_RANGE_FILTER)
      setLastUsedFilters(this.filter, next)
      this.setState(prev => ({ ...prev, range: next }))
      this.onRangeChange(next)
    }

    // this.checkRangeLimits(this.range, true)
    this.checkTypeLimits(this.filter as ContentType)

    const debounceBricksPacked = 150
    const debounceWindowResize = 250

    this.unmount$ = new Subject()
    resetScroll()
    this.bricksPacked$
      .takeUntil(this.unmount$)
      .debounceTime(debounceBricksPacked)
      .subscribe(this.onBricksPacked)
    this.windowResize$ = Observable
      .fromEvent(window, 'resize')
      .takeUntil(this.unmount$)
      .debounceTime(debounceWindowResize)
      .subscribe(this.updateErrorOffset.bind(this))

    if (this.scrollListenerRef.current) {
      this.scrollListenerRef.current.emitScroll()
    }

    if (!this.state.loading && this.items[ALL_FILTER].length === 0) {
      this.maybeLoadMore()
    }
  }

  componentWillUnmount() {
    this.unmount$.next(true)
    this.windowResize$.unsubscribe()
    this.bricksPacked$.unsubscribe()
    this.props.setSelectableItems([])
  }

  componentDidUpdate(previousProps: SourcesBricksViewProps, previousState: SourcesBricksViewState) {
    if (
      previousProps.filter !== this.props.filter
      || previousState.range !== this.state.range
      || previousState.loading !== this.state.loading
    ) {
      this.bricksRef.current!.onItemChanged(undefined)
      this.updateErrorOffset()
    }
  }
}

function mapStateToProps(state: any) {
  return {
    product: userProductSelector(state)
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    checkFeatureAvailability: (feature: string, checkOnly?: boolean, closeCallback?: () => void) => {
      return dispatch(checkFeatureAvailability(feature, checkOnly, closeCallback))
    },
    setSelectableItems: (content: SelectableContent[]) => {
      return dispatch(setVisibleSelectableItems(arrayToRecord(content || [], 'id')))
    },
    addLiveArticleData: (id: string, data: LiveArticleData) => {
      dispatch(addLiveArticleData({ [id]: { id, ...data } }))
    }
  }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SourcesBricksView))
