import * as React from 'react'
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Chip from '@mui/material/Chip'
import styles from './InstagramLocationSelector.pcss'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { Subject } from 'rxjs/Subject'
import { searchInstagramLocation } from 'services/compose/instagram/actions'
import CircularProgress from '@mui/material/CircularProgress'
import { FormattedMessage, useIntl } from 'react-intl'
import { LocationInfo } from 'services/compose/interfaces'
import { Observable } from 'rxjs/Observable'
import { catchError } from 'rxjs/operators/catchError'
import { PostDestination } from 'interfaces'
import Tooltip from '@mui/material/Tooltip'
import IconLocation from '@mui/icons-material/Place'
import Avatar from '@mui/material/Avatar'
import { stringAvatar } from 'utils/avatar'

const SEARCH_DEBOUNCE_TIME = 250
const LOADER_SIZE = 24
const MAX_LOCATIONS_DEFAULT = 40

interface InstagramLocationSelectorProps {
  profile: PostDestination
  selectedLocation?: LocationInfo
  className?: string
  hideProfileImage?: boolean
  raised?: boolean
  useGenericImage?: boolean
  hideAvatar?: boolean
  hideLabel?: boolean
  onSelectedLocationChange: (location: LocationInfo | undefined, ppid: string) => void
}

export function InstagramLocationSelector(props: InstagramLocationSelectorProps) {
  const intl = useIntl()
  const [dropdownAnchor, setDropdownAnchor] = React.useState<HTMLElement | null>(null)
  const [mode, setMode] = React.useState<'label' | 'input'>('label')
  const [profileImageError, setProfileImageError] = React.useState(false)
  const [locations, setLocations] = React.useState<LocationInfo[]>([])
  const [loading, setLoading] = React.useState(false)
  const [expanded, setExpanded] = React.useState(false)
  const [query, setQuery] = React.useState('')
  const dispatch = useDispatch<StoreThunkDispatch>()
  const search$ = React.useRef<Subject<string>>()
  const chipRef = React.useRef<HTMLDivElement>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const withToggle = locations.length > MAX_LOCATIONS_DEFAULT
  const visibleLocations = expanded ? locations : locations.slice(0, MAX_LOCATIONS_DEFAULT)

  React.useEffect(() => {
    setExpanded(false)
  }, [loading])

  React.useEffect(() => {
    setProfileImageError(false)
  }, [props.profile.image])

  React.useEffect(() => {
    search$.current = new Subject()
    const sub = search$.current
      .debounceTime(SEARCH_DEBOUNCE_TIME)
      .switchMap((value: string) => {
        setLoading(true)
        setLocations([])
        openDropdown()
        return dispatch(searchInstagramLocation(value))
          .pipe(
            catchError((error) => {
              console.log('[searchInstagramLocation] error:', error)
              return Observable.of([])
            })
          )
      })
      .subscribe((response: LocationInfo[]) => {
        setLocations(response)
        setLoading(false)
      })

    return () => {
      sub.unsubscribe()
    }
  }, [])

  React.useLayoutEffect(() => {
    if (mode === 'input') {
      inputRef.current?.focus()
    }
  }, [mode])

  const onClick = () => {
    setMode('input')
  }

  const closeDropdown = () => {
    setDropdownAnchor(null)
  }

  const openDropdown = () => {
    setDropdownAnchor(chipRef.current)
  }

  const onLocationSelected = (location: LocationInfo) => {
    props.onSelectedLocationChange(location, props.profile.ppid)
    setMode('label')
    setQuery('')
    closeDropdown()
  }

  const clearSelectedLocation = () => {
    props.onSelectedLocationChange(undefined, props.profile.ppid)
    setMode('label')
    setQuery('')
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setQuery(value)
    search$.current?.next(value)
  }

  const onChipClickAway = () => {
    if (!dropdownAnchor) {
      setMode('label')
      setQuery('')
    }
  }

  const onImageLoadError = () => {
    setProfileImageError(true)
  }
  const avatar = props.hideAvatar ? undefined : props.useGenericImage ? (
    <IconLocation fontSize="small" className={styles['icon-location']} />
  ) : (
    <Tooltip title={props.profile.name}>
      <div className={styles['img-profile-box']}>
        {profileImageError ? (
          <Avatar {...stringAvatar(props.profile.name, '22px', '12px')} />
        ) : (
          <img src={props.profile.image} onError={onImageLoadError} />
        )}
      </div>
    </Tooltip>
  )

  return (
    <div className={`${styles.container} ${props.className || ''}`} data-testid="ig-location-selector">
      <ClickAwayListener onClickAway={onChipClickAway}>
        <Chip
          avatar={avatar}
          label={
            <div>
              <input
                type="text"
                name="Location"
                value={query}
                ref={inputRef}
                className={`${styles.input} ${mode === 'label' ? styles.hidden : ''}`}
                onChange={onChange}
              />
              <span className={`${styles['input-label']} ${mode === 'input' ? styles.hidden : ''}`}>
                {props.selectedLocation
                  ? (
                    <React.Fragment>
                      {!props.hideLabel && `${intl.formatMessage({ id: 'label.generic.location' })}:`} {props.selectedLocation.name}
                    </React.Fragment>
                  ) : 'Location: none'}
              </span>
            </div>
          }
          classes={{ root: styles.chip, label: styles.label }}
          ref={chipRef}
          onClick={onClick}
          data-testid="ig-location-btn"
          onDelete={props.selectedLocation && mode === 'label' ? clearSelectedLocation : undefined}
        />
      </ClickAwayListener>
      <Popper
        open={Boolean(dropdownAnchor)}
        anchorEl={dropdownAnchor}
        placement="bottom"
        className={`${styles.popper} ${props.raised ? styles.raised : ''}`}
      >
        <ClickAwayListener onClickAway={closeDropdown}>
          <Paper>
            <ul className={styles.list}>
              {!loading && locations.length === 0 && (
                <li className={styles['item-empty']}>
                  <FormattedMessage id="composer.labels.instagram-location-not-found" />
                </li>
              )}
              {loading && (
                <li className={styles['loader-box']}>
                  <CircularProgress size={LOADER_SIZE} className={styles.loader} />
                </li>
              )}
              {visibleLocations.map((loc: LocationInfo) => (
                <LocationListItem key={loc.id} location={loc} onClick={onLocationSelected} />
              ))}
              {withToggle && (
                <li className={styles.toggle} onClick={toggleExpanded}>
                  <span className={styles['btn-toggle']}>
                    <FormattedMessage id={`actions.show-${expanded ? 'less' : 'all'}`} />
                  </span>
                </li>
              )}
            </ul>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

const LocationListItem = (props: { location: LocationInfo, onClick: (location: LocationInfo) => void }) => {
  const onClick = () => {
    props.onClick(props.location)
  }

  return (
    <li className={styles['loc-list-item']} data-testid="location-list-item" onClick={onClick}>
      <div className={styles['loc-img']}>
        <img src={props.location.imageUrl} />
      </div>
      <span>{props.location.name}</span>
      {props.location.city && props.location.country && (
        <span className={styles.dim}>{`${props.location.city}, ${props.location.country}`}</span>
      )}
    </li>
  )
}
