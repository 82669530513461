import * as React from 'react'
import { FetchStream } from 'components/Fetch'
import SimpleLoader from 'components/SimpleLoader'
import StreamView from 'components/StreamView'
import { Stream } from 'interfaces'
import { useNavigate, useLocation, useMatch } from 'react-router-dom'
import { SearchContext } from '../state/context'
import styles from './SearchRoot.pcss'
import { addRecentlyViewed } from 'services/search/actions'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { updateState } from '../state/actions'
import { resetScroll } from 'utils/dom'

export function SearchStreamRoute() {
  const match = useMatch('/content/search/sources/streams/:id/*')
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const [state, searchDispatch] = React.useContext(SearchContext)
  const [stream, setStream] = React.useState<Stream | null>(null)
  const scrollElementRef = React.useRef<HTMLElement | undefined>(undefined)
  const queryRef$ = React.useRef<string>(state.query)

  React.useEffect(() => {
    scrollElementRef.current = document.querySelector('[data-test="main"]') as HTMLElement
    resetScroll()
  }, [])

  React.useEffect(() => {
    searchDispatch(updateState({ activeFilter: 'streams', activeCategory: 'sources' }))
  }, [])

  React.useEffect(() => {
    if (stream) {
      dispatch(addRecentlyViewed({ source: stream, type: 'stream' }))
    }
  }, [stream, dispatch])

  const scrollConfig = scrollElementRef.current ? { element: scrollElementRef.current } : undefined

  React.useEffect(() => {
    if (!stream && state.activeStream?.id === match?.params.id) {
      setStream(state.activeStream || null)
    }
  }, [match?.params.id, state.activeStream, stream])

  const goBack = React.useCallback(() => {
    if (location.state?.backUrl) {
      navigate(location.state.backUrl)
    } else {
      navigate(`/content/search/sources/streams${location.search || ''}`)
    }
  }, [navigate, location.state?.backUrl, location.search])

  const renderRedirect = React.useCallback((pathname: string) => {
    navigate(`${pathname}?q=${state.query}`)

    return null
  }, [state.query])

  React.useEffect(() => {
    if (queryRef$.current !== state.query) {
      queryRef$.current = state.query
      searchDispatch(updateState({ activeStream: undefined }))

      // EXPL: Run this after the default location update done in the main Search component
      setTimeout(() => {
        navigate('/content/search/sources/streams', { state: { backUrl: location.state?.backUrl } })
      })
    }
  }, [location.state?.backUrl, state.query])

  if (!stream) {
    return (
      <div>
        <FetchStream id={match?.params.id as string} onFetched={setStream} />
        <SimpleLoader />
      </div>
    )
  }

  return (
    <div className={styles['grid-wrapper']}>
      <FetchStream id={match?.params.id as string} onFetched={setStream} />
      <StreamView
        stream={stream}
        showBackLink
        navigation={{ prefix: `/content/search/sources/streams/${stream.id}`, id: stream.id }}
        scroll={scrollConfig}
        onBackClick={goBack}
        renderFeedRedirect={renderRedirect}
        renderStreamRedirect={renderRedirect}
      />
    </div>
  )
}
