import useAsyncAction from 'hooks/useAsyncAction'
import { BRAND_FACEBOOK, PostDestination } from 'interfaces'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Observable } from 'rxjs/Observable'
import { selectComposerFBAlbum } from 'services/compose'
import { ComposerFacebookPost, FBAlbum } from 'services/compose/interfaces/ComposerFacebookPost'
import { composerPostSelector } from 'services/compose/selectors'
import { getAlbums } from 'services/destinations'
import StoreState, { StoreThunkDispatch } from 'store/state'
import { AlbumSelector } from './AlbumSelector'

interface FacebookAlbumSelectorOwnProps {
  profile: PostDestination
  hideProfileImage?: boolean
  raised?: boolean
  className?: string
  selectedAlbumsCustom?: FBAlbum[]
  onSelectedAlbumChangeCustom?: (profile: PostDestination, album: FBAlbum | undefined) => void
}

interface FacebookAlbumSelectorConnectedProps {
  selectedAlbums: FBAlbum[]
  fetchAlbums: (profile: PostDestination) => Promise<any>
  onSelectedAlbumChange: (profile: PostDestination, album: FBAlbum | undefined) => void
}

type FacebookAlbumSelectorProps = FacebookAlbumSelectorOwnProps & FacebookAlbumSelectorConnectedProps

export function FacebookAlbumSelector(props: FacebookAlbumSelectorProps) {
  const [albums, setAlbums] = React.useState(props.profile.fbAlbums || {})
  const [triggerFetchAlbums, response, _error, loading] = useAsyncAction((p) => Observable.fromPromise(props.fetchAlbums(p)))

  React.useEffect(() => {
    if (Object.keys(props.profile.fbAlbums || {}).length < 1) {
      triggerFetchAlbums(props.profile)
    }
  }, [props.profile.id])

  React.useEffect(() => {
    if (response) {
      setAlbums(response.albums)
    }
  }, [response])

  const onSelectedAlbumChange = (a: FBAlbum) => {
    if (props.onSelectedAlbumChangeCustom) {
      props.onSelectedAlbumChangeCustom(props.profile, a)
    } else {
      props.onSelectedAlbumChange(props.profile, a)
    }
  }

  const selectedAlbums = props.selectedAlbumsCustom || props.selectedAlbums
  const selected = selectedAlbums.find(a => a.ppid === props.profile.ppid)

  return (
    <AlbumSelector
      profile={props.profile}
      albums={albums}
      selectedAlbumId={selected?.id}
      allowEmpty
      loading={loading}
      messageEmptySelection={<FormattedMessage id="composer.labels.fb-album-default" />}
      errorMessageElement={<FormattedMessage id="composer.labels.albums-fetch-failed" />}
      className={props.className}
      raised={props.raised}
      hideProfileImage={props.hideProfileImage}
      onSelectionChange={onSelectedAlbumChange}
    />
  )
}

function mapStateToProps(state: StoreState) {
  const fbPost = composerPostSelector(state, BRAND_FACEBOOK) as ComposerFacebookPost

  return {
    selectedAlbums: fbPost.albums
  }
}

function mapDispatchToProps(dispatch: StoreThunkDispatch) {
  return {
    fetchAlbums: (profile: PostDestination) => dispatch(getAlbums(profile)).unwrap(),
    onSelectedAlbumChange: (profile: PostDestination, album: FBAlbum | undefined) => dispatch(selectComposerFBAlbum({ profile, album }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacebookAlbumSelector)
