import * as React from 'react'
import styles from './SavedTexts.pcss'
import { FormattedMessage, injectIntl, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { savedStatusesSelector } from 'services/compose/selectors'
import IconClose from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { StoreThunkDispatch } from 'store/state'
import { deleteSavedStatus, getSavedStatuses, reorderSavedTexts, saveStatusText, updateSavedText } from 'services/compose/saved'
import { Subject } from 'rxjs/Subject'
import { catchError } from 'rxjs/operators/catchError'
import { message } from 'services/snackbar'
import { Observable } from 'rxjs/Observable'
import { tap } from 'rxjs/operators/tap'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconSearch from '@mui/icons-material/Search'
import { SavedTextListItem } from './SavedTextListItem'
import IconCopy from '@mui/icons-material/FileCopyOutlined'
import IconAdd from '@mui/icons-material/Add'
import { checkProductLimit } from 'services/product'
import { LIMIT_SAVED_TEXTS } from 'shared/constants'

export function SavedTexts() {
  const intl = useIntl()
  const dispatch = useDispatch<StoreThunkDispatch>()
  const texts = useSelector(savedStatusesSelector)
  const [newText, setNewText] = React.useState({ text: '', title: '' })
  const [saving, setSaving] = React.useState(false)
  const [editingTextId, setEditingTextId] = React.useState<string | null>(null)
  const [filter, setFilter] = React.useState('')
  const textsRef = React.useRef(texts)
  const create$ = React.useRef<Subject<{ text: string, title: string }>>()
  const delete$ = React.useRef<Subject<string>>()
  const reorder$ = React.useRef<Subject<string[]>>()
  const update$ = React.useRef<Subject<{ id: string, text: string, title: string }>>()
  const [createTextMode, setCreateTextMode] = React.useState(false)

  React.useEffect(() => {
    textsRef.current = texts
  }, [texts])

  const refresh = () => {
    return Observable.fromPromise(dispatch(getSavedStatuses(true)).unwrap())
  }

  React.useEffect(() => {
    create$.current = new Subject()
    create$.current
      .flatMap(({ text, title }) => {
        setSaving(true)
        return dispatch(saveStatusText(text, title)).flatMap(() => refresh()).pipe(
          tap(() => {
            dispatch(message(intl.formatMessage({ id: 'composer.notifications.text-save-success' }), 'success'))
          }),
          catchError(() => {
            dispatch(message(intl.formatMessage({ id: 'composer.notifications.error-saving-text' }), 'error'))
            return Observable.of({})
          })
        )
      })
      .subscribe(() => {
        setSaving(false)
        setNewText({ text: '', title: '' })
        setCreateTextMode(false)
      })

    update$.current = new Subject()
    update$.current
      .flatMap(({ id, text, title }) => {
        setSaving(true)
        return dispatch(updateSavedText(id, text, title)).flatMap(() => refresh()).pipe(
          tap(() => {
            dispatch(message(intl.formatMessage({ id: 'composer.notifications.text-save-success' }), 'success'))
          }),
          catchError(() => {
            dispatch(message(intl.formatMessage({ id: 'composer.notifications.error-saving-text' }), 'error'))
            return Observable.of({})
          })
        )
      })
      .subscribe(() => {
        setSaving(false)
      })

    delete$.current = new Subject()
    delete$.current
      .flatMap(id => {
        setSaving(true)
        return Observable.fromPromise(dispatch(deleteSavedStatus(id)).unwrap())
          .flatMap(() => refresh()).pipe(
            tap(() => {
              dispatch(message(intl.formatMessage({ id: 'composer.notifications.text-delete-success' }), 'success'))
            }),
            catchError(() => {
              dispatch(message(intl.formatMessage({ id: 'composer.notifications.error-deleting-text' }), 'error'))
              return Observable.of({})
            })
          )
      })
      .subscribe(() => {
        setSaving(false)
      })
    return () => {
      create$.current?.unsubscribe()
      update$.current?.unsubscribe()
      delete$.current?.unsubscribe()
    }
  }, [dispatch, intl])

  React.useEffect(() => {
    reorder$.current = new Subject()
    reorder$.current.flatMap((ids: string[]) => {
      setSaving(true)
      return dispatch(reorderSavedTexts(ids))
        .pipe(catchError(() => {
          dispatch(message(intl.formatMessage({ id: 'composer.notifications.text-move-to-top-error' }), 'error'))
          return Observable.of({})
        }))
        .flatMap(() => refresh())
    })
      .subscribe(() => {
        setSaving(false)
      })
  }, [])

  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setFilter(value.toLowerCase())
  }

  const onNewTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewText({ ...newText, text: e.target.value })
  }

  const onNewTextTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewText({ ...newText, title: e.target.value })
  }

  const create = () => {
    const canAddMore = dispatch(checkProductLimit(LIMIT_SAVED_TEXTS, texts.length + 1))
    if (!canAddMore) {
      return
    }
    const text = newText.text.trim()
    const title = newText.title.trim()
    create$.current?.next({ text, title })
  }

  const update = (id: string, text: string, title: string) => {
    update$.current?.next({ id, text, title })
    setEditingTextId(null)
  }

  const onDelete = (id: string) => {
    delete$.current?.next(id)
    setEditingTextId(null)
  }

  const moveToTop = (id: string) => {
    const ids = texts.map(t => t.id)
    const index = ids.indexOf(id)
    if (index > 0) {
      ids.splice(index, 1)
      ids.unshift(id)
    }
    reorder$.current?.next(ids)
  }

  const clearEditingTextId = () => {
    setEditingTextId(null)
  }

  const clearFilter = () => {
    setFilter('')
  }

  const cancelCreate = () => {
    setCreateTextMode(false)
    setNewText({ text: '', title: '' })
  }

  const showCreateText = () => {
    setCreateTextMode(true)
  }

  const searchText = filter.trim()

  const visibleTexts = React.useMemo(() => {
    const filterText = filter.trim()
    return texts.filter(t => t.text.toLowerCase().indexOf(filterText) !== -1 || t.title?.toLowerCase().indexOf(filterText) !== -1)
  }, [texts, filter])

  return (
    <div data-testid="saved-texts">
      {createTextMode && (
        <div className={styles['create-wrapper']}>
          <div className={styles['create-box']}>
            <IconCopy className={styles['icon-copy']} />
            <div className={styles['create-content']}>
              <input
                type="text"
                value={newText.title}
                placeholder="Title"
                name="title"
                className={styles['input-title']}
                onChange={onNewTextTitleChange}
              />
              <textarea
                className={styles.textarea}
                value={newText.text}
                placeholder={intl.formatMessage({ id: 'composer.labels.saved-texts-create-placeholder' })}
                disabled={saving}
                name="content"
                onChange={onNewTextChange}
              />
            </div>
          </div>
          <div className={styles['create-actions']}>
            <Button size="small" onClick={cancelCreate}>
              <FormattedMessage id="actions.cancel" />
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={newText.text.trim().length === 0}
              data-testid="btn-create-saved-text"
              onClick={create}
            >
              <FormattedMessage id="actions.save" />
            </Button>
          </div>
        </div>
      )}
      <div className={styles.actions}>
        <Button
          variant="text"
          className={`${styles['btn-add']} ${createTextMode ? styles.active : ''}`}
          data-testid="btn-add-saved-text"
          onClick={showCreateText}
        >
          <IconAdd fontSize="small" />
        </Button>
        <TextField
          value={filter}
          onChange={onFilterChange}
          placeholder={intl.formatMessage({ id: 'composer.labels.saved-texts-search' }, { count: texts.length })}
          classes={{ root: styles['search-box'] }}
          InputProps={{
            className: styles['search-input'],
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <IconSearch className={styles['icon-search']} />
              </InputAdornment>
            ),
            endAdornment: searchText.length ? (
              <IconButton className={styles['btn-clear-filter']} onClick={clearFilter}>
                <IconClose fontSize="small" />
              </IconButton>
            ) : undefined
          }}
        />
      </div>
      <div className={`${styles['dialog-content']}  ${saving ? styles.disabled : ''}`}>
        <ul className={styles.list}>
          {visibleTexts
            .map(text => (
              <SavedTextListItem
                key={text.id}
                data={{ id: text.id, text: text.text, title: text.title }}
                isInEditMode={editingTextId === text.id}
                onStartEdit={setEditingTextId}
                onDelete={onDelete}
                onStopEdit={clearEditingTextId}
                onUpdate={update}
                onMoveToTop={moveToTop}
              />
            ))}
        </ul>
      </div>
    </div>
  )
}

export default injectIntl(SavedTexts)
