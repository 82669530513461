import { StoreThunkAction, StoreThunkDispatch } from 'store/state'
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/of'
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/filter'
import 'rxjs/add/operator/map'
import { StatusIdea, Paginated, STATUS_TYPE } from 'interfaces'
import { V1 } from './net'
import { statusIdeasAdapter } from '../util'
import { createAction } from '@reduxjs/toolkit'
import { tap } from 'rxjs/operators/tap'

export function getStatusIdeas(categoryIds: Array<string>, page = 0, seed?: string): StoreThunkAction<Observable<Paginated<StatusIdea[]>>> {
  return (dispatch, _getState) => {
    return dispatch(V1.getStatusIdeas(categoryIds, page, seed)).map(statusIdeasAdapter)
  }
}

function statusIdeaAdapter(s: any) {
  return { ...s, id: s.id.toString(), type: STATUS_TYPE }
}

export function searchStatusIdeas(query: string, page: number, tag?: string): StoreThunkAction<Observable<StatusIdea[]>> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.searchStatusIdeas(query, page, tag)).map((response: any) => {
      const data = typeof response.statusIdeas.length === 'number' ? response.statusIdeas : response.statusIdeas.statusIdeas
      return data.map(statusIdeaAdapter)
    })
    // .map(response => searchByTag ? response : response.statusIdeas.statusIdeas)
    // .map((response: any) => response.map(statusIdeaAdapter))
  }
}

export const addRecentStatusSearch = createAction<string>('content/statuses/addRecentStatusSearch')
export const setRecentStatusSearches = createAction<string[]>('content/statuses/setRecentStatusSearches')
export const setPastStatusesSearches = createAction<any[]>('content/statuses/setPastStatusesSearches')
export function getPastSearches() {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.getPastSearches())
    .pipe(tap((response) => {
      dispatch(setPastStatusesSearches(response.searches.map((s: any) => s.term)))
    }))
    .map(response => response.searches)
}

export function deleteSavedSearch(text: string) {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.deleteSavedSearch(text)).map(() => text)
}

export function saveSearch(query: string): StoreThunkAction<Observable<string>> {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.saveSearch(query)).map(() => query)
}

export function getStatusesRange(from: number, to: number): StoreThunkAction<Observable<StatusIdea[]>> {
  return (dispatch: StoreThunkDispatch) => {
    return dispatch(V1.getStatusesRange(from, to)).map(response => {
      return response.quotes.quotes.map(statusIdeaAdapter)
    })
  }
}

export function getTotalStatusesCount() {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.searchStatusIdeas('', 0)).map((response: any) => response.statusIdeas.count)
}

export function createStatusIdea(status: string, tags?: string[]): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.createStatusIdea(status, tags))
}

export function getTags(all?: boolean) {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.getTags()).map(response => {
    if (all) {
      return response.map((tag: any) => ({ ...tag, active: Boolean(tag.isVisible) }))
    }
    return response
      .filter((tag: any) => tag.isVisible)
      .map((tag: any) => ({ ...tag, active: Boolean(tag.isVisible) }))
  })
}

export function updateTag(id: number, active: boolean) {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.updateTag(id, active)).map((response) => {
    if (response.error) {
      throw new Error(response.msg)
    }
    return {
      id, active
    }
  })
}

export function updateStatus(id: string, status: string, author?: string, tags?: string[]): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.updateStatus(id, status, author, tags))
}

export function deleteStatus(id: string): StoreThunkAction<Observable<any>> {
  return (dispatch: StoreThunkDispatch) => dispatch(V1.deleteStatus(id))
}
