import { StatusIdea, WithIntl } from 'interfaces'
import * as React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { STATUSES_CATEGORIES } from 'routes/find/routes/statuses/statuses.config'
import { StatusIdeasCarousel } from './components/StatusIdeasCarousel'
import { addItems, incrementPage, initialData, resetData, statusesReducer } from './statuses-reducer'
import styles from './QuotesPanel.pcss'
import { Subject } from 'rxjs/Subject'
import { useDispatch } from 'react-redux'
import { StoreThunkDispatch } from 'store/state'
import { saveSearch, searchStatusIdeas } from 'services/content/statuses/actions'
import { catchError } from 'rxjs/operators/catchError'
import { Observable } from 'rxjs/Observable'
import { message } from 'services/snackbar'
import { NavLink } from 'react-router-dom'
import { CuratedFolderListView } from 'components/CuratedFolderView'
import { SuggestedStatusSearches } from './components/SuggestedStatusSearches'
import ErrorOverlay from 'components/ErrorOverlay'
import CollapsibleTextInput from 'components/CollapsibleTextInput'
import EmptyView from 'components/EmptyView'

const categories = STATUSES_CATEGORIES.map(cat => ({ ...cat, name: cat.title }))
const carouselHeight = 284

interface StatusIdeasPanelProps {
  title?: string
  className?: string
}

export function StatusIdeasPanel(props: StatusIdeasPanelProps & WithIntl) {
  const storeDispatch = useDispatch<StoreThunkDispatch>()
  const [activeCategory, setActiveCategory] = React.useState(categories[0])
  const [data, dispatch] = React.useReducer(statusesReducer, initialData)
  const [dataKey, setDataKey] = React.useState(0)
  const [searchKey, setSearchKey] = React.useState(0)
  const [query, setQuery] = React.useState('')
  const [searchPage, setSearchPage] = React.useState(0)
  const [searchResults, setSearchResults] = React.useState<StatusIdea[]>([])
  const [searchLoading, setSearchLoading] = React.useState(false)
  const search$ = React.useRef<Subject<{ query: string, page: number }>>()
  const title = props.title || props.intl.formatMessage({ id: 'find.home.sections.status-ideas' })

  React.useEffect(() => {
    search$.current = new Subject()
    search$.current.switchMap(({ query, page }) => {
      storeDispatch(saveSearch(query)).toPromise()
      setSearchLoading(true)
      return storeDispatch(searchStatusIdeas(query, page)).pipe(
        catchError(() => {
          return Observable.of({ error: true })
        })
      )
    })
      .subscribe((response: any) => {
        if (response.error) {
          storeDispatch(message(props.intl.formatMessage({ id: 'errors.generic' }), 'error'))
        } else {
          setSearchResults(current => [...current, ...response])
        }
        setSearchLoading(false)
      })
  }, [props.intl, storeDispatch])

  React.useEffect(() => {
    if (query) {
      search$.current?.next({ query, page: searchPage })
    }
  }, [query, searchPage])

  const navLink = {
    text: `see all ${query ? '"' + query + '"' : activeCategory.name}`,
    url: `/content/statuses${query ? '?q=' + query : '/' + activeCategory.slug}`
  }

  const onActiveCategoryChange = (id: string) => {
    const category = categories.find(c => c.id === id)
    if (category) {
      dispatch(resetData())
      setDataKey(current => current + 1)
      setActiveCategory(category)
      setQuery('')
      setSearchKey(current => current + 1)
      setSearchLoading(false)
    }
  }

  const onStatusesFetched = (categoryId: string, items: StatusIdea[], page: number) => {
    dispatch(addItems(categoryId, page, items))
  }

  const loadNextPage = (categoryId: string) => {
    dispatch(incrementPage(categoryId))
  }

  const loadSearchNextPage = () => {
    setSearchPage(current => current + 1)
  }

  const onSearch = (val: string) => {
    const q = val.trim()
    if (q) {
      setQuery(q)
      setSearchPage(0)
      setSearchResults([])
      setSearchLoading(true)
    }
  }

  return (
    <section className={`${styles.section} ${props.className || ''}`}>
      <header className={styles['quotes-header']}>
        <h2 className={`${styles.title} text-ellipsis`}>
          {title}
        </h2>
      </header>
      <SuggestedStatusSearches
        activeQuery={query}
        activeCategoryId={!query ? activeCategory.id : undefined}
        searchElement={(
          <CollapsibleTextInput
            changeOnEnter
            initialActive
            className={styles.searchbox}
            placeholder={props.intl.formatMessage({ id: 'find.status-ideas.search-hint' })}
            onValueChanged={onSearch}
          />
        )}
        onActiveCategoryChange={onActiveCategoryChange}
        onQueryClick={onSearch}
      />
      <div className={styles.content}>
        <NavLink to={navLink.url} className={styles.link}>{navLink.text}</NavLink>
        {!query && (
          <div style={{ height: `${carouselHeight}px` }}>
            <StatusIdeasCarousel
              key={dataKey}
              category={activeCategory}
              items={data[activeCategory.id]?.items || []}
              page={data[activeCategory.id]?.page || 0}
              onStatusesFetched={onStatusesFetched}
              onLoadMore={loadNextPage}
            />
          </div>
        )}
        {query && (
          <CuratedFolderListView
            stream={{} as any}
            items={searchResults}
            key={query}
            loading={searchLoading}
            onScrollLimit={loadSearchNextPage}
          />
        )}
        {query && !searchLoading && searchResults.length === 0 && (
          <EmptyView
            carousel
            title={<FormattedMessage id="content.msg.status-ideas-search-empty" />}
            subtitle={<FormattedMessage id="content.msg.status-ideas-search-empty-subtitle" />}
            top="30px"
          />
        )}
      </div>
    </section>
  )
}

export default injectIntl(StatusIdeasPanel)
