import { createElement } from 'react'
import Loader from 'components/SimpleLoader'
import loadable, { LoadableComponent } from '@loadable/component'
import { lazyLoad } from 'utils/chunk'

const AsyncOnboarding: LoadableComponent<any> = loadable(() => lazyLoad(
  () => import(
    /* webpackPrefetch: true */
    /* webpackChunkName: 'onboarding' */
    './Onboarding'
  ),
  'Onboarding'
), {
  fallback: createElement(Loader, { title: 'Loading Onboarding...' })
})

export default AsyncOnboarding
