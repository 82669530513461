import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ComposerInstagramPost, LocationInfo } from 'services/compose/interfaces'
import {
  composerImageUrlsSelector,
  composerSelectedProfilesSelector,
  composerVideoUrlSelector,
  composerPostSelector
} from 'services/compose/selectors'
import { InstagramLocationSelector } from './InstagramLocationSelector'
import styles from './CustomizePostBlock.pcss'
import { BRAND_INSTAGRAM } from 'interfaces'
import { IG_POST_TYPE_REEL, POST_TYPE_STORY } from 'services/compose/state'
import { setInstagramPostType, setInstagramPostLocation } from 'services/compose'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import IconLocation from '@mui/icons-material/Place'
import PPSwitch from 'components/PPSwitch'
import StoreState from 'store/state'

interface ComposerInstagramPostOptionsProps {
  className?: string
  hidePostTypeSelector?: boolean
  onSelectedLocationChange?: (location: LocationInfo | undefined, ppid: string) => void
}

export function ComposerInstagramPostOptions(props: ComposerInstagramPostOptionsProps) {
  const dispatch = useDispatch()
  const [popupOpen, setPopupOpen] = React.useState(false)

  // const rememberLocation = useSelector(rememberIGLocationsSelector)
  const post = useSelector((state: StoreState) => composerPostSelector(state, BRAND_INSTAGRAM)) as ComposerInstagramPost
  const videoUrl = useSelector(composerVideoUrlSelector)
  const images = useSelector(composerImageUrlsSelector)
  const profiles = useSelector(composerSelectedProfilesSelector).filter(p => p.type === BRAND_INSTAGRAM)

  // Only vids can be posted as reel. Reset post type if user adds images to a reel post
  React.useEffect(() => {
    if (post.postType === IG_POST_TYPE_REEL && images.length > 0) {
      dispatch(setInstagramPostType(undefined))
    }
  }, [images.length, post.postType, dispatch])

  const updateSelectedLocation = (location: LocationInfo | undefined, ppid: string) => {
    if (props.onSelectedLocationChange) {
      props.onSelectedLocationChange(location, ppid)
    } else {
      dispatch(setInstagramPostLocation({ location, ppid }))
    }
  }

  const onPostTypeChange = (postType: string) => {
    const selectedPostType = postType === 'post' ? undefined : postType
    dispatch(setInstagramPostType(selectedPostType))
  }

  const openPopup = () => {
    setPopupOpen(true)
  }

  const closePopup = () => {
    setPopupOpen(false)
  }

  // const toggleRememberLocation = () => {
  //   dispatch(toggleRememberIGLocation())
  // }

  const multipleLocationsSelected = profiles.length > 1 && Object.keys(post.locations).length > 1
  const postTypeOptions = [{
    value: 'post',
    label: videoUrl ? 'Post/Reel' : 'Post'
  }, {
    value: POST_TYPE_STORY,
    label: 'Story'
  }]

  return (
    <div className={props.className}>
      {!props.hidePostTypeSelector && (
        <div className={styles['ig-switch-group']}>
          <PPSwitch
            selectedValue={post.postType || 'post'}
            options={postTypeOptions}
            onSelectedValueChange={onPostTypeChange}
          />
        </div>
      )}
      {post.postType !== POST_TYPE_STORY && (
        <div className={styles['option-box']}>
          {profiles.length === 1 && (
            <InstagramLocationSelector
              profile={profiles[0]}
              useGenericImage
              selectedLocation={post.locations[profiles[0].ppid]}
              onSelectedLocationChange={updateSelectedLocation}
            />
          )}
          {profiles.length > 1 && (
            <React.Fragment>
              <Button startIcon={<IconLocation fontSize="small" className={styles['icon-location']} />} onClick={openPopup}>
                <FormattedMessage
                  id={
                    multipleLocationsSelected
                      ? 'composer.labels.instagram.locations-selected'
                      : 'composer.labels.instagram.empty-location'
                  }
                />
              </Button>
              <Dialog open={popupOpen} onClose={closePopup} classes={{ root: styles['popup-root'], paper: styles['albums-popup'] }}>
                <IconButton className={styles['btn-close']} onClick={closePopup} size="large">
                  <CloseIcon />
                </IconButton>
                <h3 className={styles['popup-title']}>
                  <FormattedMessage id="composer.labels.select-multiple-locations" />
                </h3>
                <div className={styles['albums-popup-content']}>
                  {profiles.map(profile => (
                    <div className={styles['option-box']} key={profile.id}>
                      <InstagramLocationSelector
                        profile={profile}
                        selectedLocation={post.locations[profile.ppid]}
                        onSelectedLocationChange={updateSelectedLocation}
                      />
                    </div>
                  ))}
                </div>
              </Dialog>
            </React.Fragment>
          )}
          {/* <FormControlLabel
          label={<FormattedMessage id="composer.labels.instagram.remember-location" />}
          classes={{ label: styles.label, root: styles['cb-root'] }}
          control={(
            <Checkbox
              checked={rememberLocation}
              color="primary"
              size="small"
              className={styles.cb}
              onChange={toggleRememberLocation}
            />
          )}
        /> */}
        </div>
      )}
    </div>
  )
}
