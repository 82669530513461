import React, { Component, Children } from 'react'
import { currentUserSelector } from 'services/users/selectors'
import { User } from 'interfaces'
import { connect } from 'react-redux'
import { WindowWithIntercom, IntercomSettings, boot, update, shutdown } from 'services/intercom'
import { INTERCOM_APP_ID } from 'config'
import { StoreState } from 'store/state'

declare const window: WindowWithIntercom
declare const __DEV__: boolean

type ConnectedIntercomProviderProps = {
  user: User | null
}

/**
 * Inject the Intercom SDK into the DOM
 *
 * @returns {HTMLScriptElement}
 */
function injectIntercomAPI(): HTMLScriptElement {
  return (function (d, s, id) {
    const element = d.getElementById(id) as HTMLScriptElement
    if (typeof window.Intercom === 'function') {
      window.Intercom('reattach_activator')
      window.Intercom('update')
      return element as HTMLScriptElement
    }

    const fjs = d.getElementsByTagName(s)[0]

    // Set up temporary holder that will be pushed to intercom once the script has loaded
    const i: any = function () {
      i.c(arguments)
    }
    i.q = []
    i.c = function (args: any) {
      i.q.push(args)
    }
    window.Intercom = i

    const target = (fjs && fjs.parentNode) || document.head
    const js = d.createElement(s) as HTMLScriptElement
    js.id = id
    js.type = 'text/javascript'
    js.async = true
    js.src = `https://widget.intercom.io/widget/${INTERCOM_APP_ID}`
    target.insertBefore(js, fjs)
    return js
  })(document, 'script', 'intercom-api')
}

function getIntercomSettings(props: ConnectedIntercomProviderProps): Partial<IntercomSettings> & { app_id: string } {
  const result = {
    app_id: INTERCOM_APP_ID as string,
    hide_default_launcher: __DEV__ // hide intercom launcher on dev env
  }

  if (props.user) {
    Object.assign(result, {
      email: props.user.email,
      account_id: props.user.account.id,
      pp_user_id: props.user.userId,
      name: props.user.fullName,
      user_hash: props.user.intercomUserHash
    })
  }

  return result
}

export class IntercomProvider extends Component<React.PropsWithChildren<ConnectedIntercomProviderProps>, any> {
  private scriptIntercomAPI: HTMLScriptElement | undefined

  constructor(props: ConnectedIntercomProviderProps) {
    super(props)

    if (!__DEV__) {
      this.scriptIntercomAPI = injectIntercomAPI()
    }
  }

  shouldComponentUpdate(nextProps: ConnectedIntercomProviderProps) {
    return nextProps.user !== this.props.user
  }

  componentDidMount() {
    if (!__DEV__) {
      boot(getIntercomSettings(this.props))
    }
  }

  componentDidUpdate() {
    if (!__DEV__) {
      update(getIntercomSettings(this.props))
    }
  }

  componentWillUnmount() {
    if (this.scriptIntercomAPI) {
      this.scriptIntercomAPI.remove()
      this.scriptIntercomAPI = undefined
    }

    if ((window as any).Intercom) {
      shutdown()
    }
  }

  render() {
    if (this.props.children) {
      return Children.only(this.props.children)
    }

    return null
  }
}

function mapStateToProps(state: StoreState) {
  return {
    user: currentUserSelector(state)
  }
}

export default connect(mapStateToProps)(IntercomProvider)
