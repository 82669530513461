import * as React from 'react'
import styles from './PostGroup.pcss'

export interface PostGroupProps {
  title: string
  className?: string
  children: React.ReactElement[]
}

export function PostGroup(props: PostGroupProps) {
  return (
    <section data-test="post-group" className={`${styles.group} ${props.className || ''}`}>
      <h3 className={styles.title}>{props.title}</h3>
      <div className={styles.posts}>
        {props.children}
      </div>
    </section>
  )
}

export default PostGroup
