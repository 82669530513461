// Search results categories
export const SEARCH_CATEGORY_CONTENT = 'content'
export const SEARCH_CATEGORY_SOURCES = 'sources'
export type SearchResultsCategory = typeof SEARCH_CATEGORY_CONTENT | typeof SEARCH_CATEGORY_SOURCES

// Search filters
export const SEARCH_FILTER_KEY_STREAMS = 'streams'
export const SEARCH_FILTER_KEY_FACEBOOK = 'facebook'
export const SEARCH_FILTER_KEY_REDDIT = 'reddit'
export const SEARCH_FILTER_KEY_RSS = 'rss'
export const SEARCH_FILTER_KEY_LINKEDIN = 'linkedin'

export const SEARCH_FILTER_KEY_QUOTES = 'quotes'
export const SEARCH_FILTER_KEY_POST_IDEAS = 'ideas'
export const SEARCH_FILTER_KEY_QUESTIONS = 'questions'
export const SEARCH_FILTER_KEY_FILL_THE_BLANK = 'fill-in-the-blank'
export const SEARCH_FILTER_KEY_TRIVIA = 'trivia'
export const SEARCH_FILTER_KEY_CONTESTS = 'contests'
export const SEARCH_FILTER_KEY_VIDEOS = 'videos'
export const SEARCH_FILTER_KEY_IMAGES = 'images'
export const SEARCH_FILTER_KEY_ARTICLES = 'articles'
export const SEARCH_FILTER_KEY_GIFS = 'gifs'
export const SEARCH_FILTER_KEY_STOCK = 'stock'

export type SearchFilterContentKey = typeof SEARCH_FILTER_KEY_QUOTES
| typeof SEARCH_FILTER_KEY_POST_IDEAS
// | typeof SEARCH_FILTER_KEY_QUESTIONS
// | typeof SEARCH_FILTER_KEY_FILL_THE_BLANK
// | typeof SEARCH_FILTER_KEY_TRIVIA
// | typeof SEARCH_FILTER_KEY_CONTESTS
| typeof SEARCH_FILTER_KEY_VIDEOS
| typeof SEARCH_FILTER_KEY_IMAGES
| typeof SEARCH_FILTER_KEY_ARTICLES
| typeof SEARCH_FILTER_KEY_GIFS
| typeof SEARCH_FILTER_KEY_STOCK

export type SearchFilterSourceKey = typeof SEARCH_FILTER_KEY_STREAMS
| typeof SEARCH_FILTER_KEY_FACEBOOK
| typeof SEARCH_FILTER_KEY_REDDIT
| typeof SEARCH_FILTER_KEY_RSS
| typeof SEARCH_FILTER_KEY_LINKEDIN

export type SearchFilterKey = SearchFilterContentKey | SearchFilterSourceKey

export type SearchFilter = {
  key: SearchFilterKey
  category: SearchResultsCategory
  isStock?: boolean
  postIdeasCategoryId?: string
}

export const SEARCH_FILTERS: SearchFilter[] = [{
  key: SEARCH_FILTER_KEY_STREAMS,
  category: SEARCH_CATEGORY_SOURCES
}, {
  key: SEARCH_FILTER_KEY_FACEBOOK,
  category: SEARCH_CATEGORY_SOURCES
}, {
  key: SEARCH_FILTER_KEY_LINKEDIN,
  category: SEARCH_CATEGORY_SOURCES
}, {
  key: SEARCH_FILTER_KEY_REDDIT,
  category: SEARCH_CATEGORY_SOURCES
}, {

  key: SEARCH_FILTER_KEY_RSS,
  category: SEARCH_CATEGORY_SOURCES
}, {
  key: SEARCH_FILTER_KEY_QUOTES,
  category: SEARCH_CATEGORY_CONTENT
}, {
  key: SEARCH_FILTER_KEY_POST_IDEAS,
  category: SEARCH_CATEGORY_CONTENT
}, {
  key: SEARCH_FILTER_KEY_ARTICLES,
  category: SEARCH_CATEGORY_CONTENT
}, {
//   key: SEARCH_FILTER_KEY_VIDEOS,
//   category: SEARCH_CATEGORY_CONTENT
// }, {
  key: SEARCH_FILTER_KEY_GIFS,
  category: SEARCH_CATEGORY_CONTENT
}, {
  key: SEARCH_FILTER_KEY_STOCK,
  category: SEARCH_CATEGORY_CONTENT,
  isStock: true
}]
//   key: SEARCH_FILTER_KEY_QUESTIONS,
//   postIdeasCategoryId: '1',
//   category: SEARCH_CATEGORY_CONTENT
// }, {
//   key: SEARCH_FILTER_KEY_FILL_THE_BLANK,
//   postIdeasCategoryId: '3',
//   category: SEARCH_CATEGORY_CONTENT
// }, {
//   key: SEARCH_FILTER_KEY_TRIVIA,
//   postIdeasCategoryId: '8',
//   category: SEARCH_CATEGORY_CONTENT
// }, {
//   key: SEARCH_FILTER_KEY_CONTESTS,
//   postIdeasCategoryId: '4',
//   category: SEARCH_CATEGORY_CONTENT
// }, {
//   key: SEARCH_FILTER_KEY_IMAGES,
//   category: SEARCH_CATEGORY_CONTENT
// }, {

export const SEARCH_FILTERS_BY_KEY: Record<SearchFilterKey, SearchFilter> = SEARCH_FILTERS.reduce((acc, f) => {
  acc[f.key] = f
  return acc
}, {} as Record<SearchFilterKey, SearchFilter>)

export type FiltersPreset = {
  id: string
  filters: SearchFilter[]
  message?: string
  url?: string
}

export const SEARCH_FILTERS_PRESETS: FiltersPreset[] = [{
  id: 'social',
  filters: [SEARCH_FILTERS_BY_KEY.streams, SEARCH_FILTERS_BY_KEY.facebook, SEARCH_FILTERS_BY_KEY.reddit, SEARCH_FILTERS_BY_KEY.linkedin]
}, {
  id: 'ai',
  filters: [],
  url: '/composer/ai'
}, {
  id: 'studio',
  filters: [],
  url: '/content/studio'
}, {
  id: 'rss',
  filters: [SEARCH_FILTERS_BY_KEY.rss]
}, {
  id: 'texts',
  filters: [SEARCH_FILTERS_BY_KEY.quotes, SEARCH_FILTERS_BY_KEY.ideas]
}, {
  id: 'stock',
  filters: [SEARCH_FILTERS_BY_KEY.stock, SEARCH_FILTERS_BY_KEY.gifs]
}, {
  id: 'history',
  filters: [],
  url: '/history'
}, {
  id: 'library',
  filters: [],
  url: '/content/library'
}]
