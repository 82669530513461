import * as React from 'react'
import ShareIcon from '@mui/icons-material/Reply'
import styles from './FBReshareLabel.pcss'
import { FEED_TYPE_FACEBOOK } from 'interfaces'
import { useDispatch } from 'react-redux'
import { openContentPreviewWindow } from 'services/contentPreview'

interface FBReshareLabelProps {
  url: string
  profileName?: string
  text?: string
  className?: string
}

export function FBReshareLabel(props: FBReshareLabelProps) {
  const dispatch = useDispatch()

  const showOriginalPost = () => {
    const contentItem = {
      socialLink: props.url,
      feed: {
        name: props.profileName,
        type: FEED_TYPE_FACEBOOK
      }
    } as any
    dispatch(openContentPreviewWindow(contentItem))
  }

  return (
    <div className={`${styles.wrapper} ${props.className || ''}`} onClick={showOriginalPost}>
      <div className={styles['icon-box']}>
        <ShareIcon className={styles.icon} />
      </div>
      <div className={styles.content}>
        {props.profileName && (
          <div className={`${styles.name} text-ellipsis`}>
            {props.profileName}
          </div>
        )}
        {props.text && (
          <div className={styles.text}>
            {props.text}
          </div>
        )}
        {!props.text && !props.profileName && (
          <div className={styles.text}>
            Re-shared post
          </div>
        )}
      </div>
    </div>
  )
}

export default FBReshareLabel
