import * as React from 'react'

import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import ShuffleIcon from '@mui/icons-material/Shuffle'
import DeleteIcon from '@mui/icons-material/Close'
import DeleteAllIcon from '@mui/icons-material/DeleteSweep'
import CopyIcon from '@mui/icons-material/FileCopyOutlined'
import PlusIcon from '@mui/icons-material/Add'
import ListIcon from '@mui/icons-material/FormatListBulleted'
import { FormattedMessage, injectIntl } from 'react-intl'
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog'
import { WithIntl } from 'interfaces'
import { mdiPailPlus } from '@mdi/js'
import Icon from '@mdi/react'
import ToBottomIcon from '@mui/icons-material/ArrowDownward'
import ToTopIcon from '@mui/icons-material/ArrowUpward'

import styles from './BulkActionMenus.pcss'
import { RecyclerIcon } from 'components/Icons'

export interface BulkPostActionsProps {
  allSelected: boolean
  selectedCount: number
  withDelete?: boolean
  withRecycle?: boolean
  withRequeue?: boolean
  withShuffle?: boolean
  withCopy?: boolean
  hidePostActions: boolean
  deleteAllConfirmMessage?: string
  splitDeleteAll?: boolean // If true - show 2 "delete all" options - for this profile/for all profiles
  splitShuffleAll?: boolean // If true - show 2 "shuffle all" options - for this profile/for all profiles
  disabled?: boolean
  bucketMode?: boolean
  onToggleAllSelected: () => void
  onBulkDeletePosts?: (applyToAllProfiles?: boolean) => void
  onBulkRecycle?: () => void
  onShuffle?: (applyToAllProfiles?: boolean) => void
  onBulkRequeue?: () => void
  onBulkShareNext?: () => void
  onCopy?: () => void
  onMoveToTop?: () => void
  onMoveToBottom?: () => void
}

export function BulkPostActions(props: BulkPostActionsProps & WithIntl) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget as any)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const onDelete = () => {
    if (props.onBulkDeletePosts) {
      props.onBulkDeletePosts()
    }
    closeMenu()
  }

  const onDeleteForAll = () => {
    if (props.onBulkDeletePosts) {
      props.onBulkDeletePosts(true)
    }
    closeMenu()
  }

  const onRecycle = () => {
    if (props.onBulkRecycle) {
      props.onBulkRecycle()
    }
    closeMenu()
  }

  const onShuffle = () => {
    if (props.onShuffle) {
      props.onShuffle()
    }
    closeMenu()
  }

  const onShuffleAll = () => {
    if (props.onShuffle) {
      props.onShuffle(true)
    }
    closeMenu()
  }

  const onRequeue = () => {
    if (props.onBulkRequeue) {
      props.onBulkRequeue()
    }
    closeMenu()
  }

  const onCopy = () => {
    if (props.onCopy) {
      props.onCopy()
    }
    closeMenu()
  }

  const onShareNext = () => {
    if (props.onBulkShareNext) {
      props.onBulkShareNext()
    }
    closeMenu()
  }

  const onMoveToTop = () => {
    if (props.onMoveToTop) {
      props.onMoveToTop()
    }
    closeMenu()
  }

  const onMoveToBottom = () => {
    if (props.onMoveToBottom) {
      props.onMoveToBottom()
    }
    closeMenu()
  }

  let bulkDeleteConfirmMessage = props.intl.formatMessage({ id: 'post.delete-bulk-confirm' })
  if (props.allSelected && props.deleteAllConfirmMessage) {
    bulkDeleteConfirmMessage = props.deleteAllConfirmMessage
  }

  return (
    <div data-testid="bulk-post-actions" className={styles['bulk-actions']}>
      {!props.hidePostActions && (
        <React.Fragment>
          {props.selectedCount !== 0 && (
            <div className={styles.count}>
              <FormattedMessage id="post.labels.selected-posts-count" values={{ count: props.selectedCount }} />
            </div>
          )}
          <div>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              disableRipple
              disabled={props.disabled}
              data-testid="bulk-post-actions-btn"
              className={styles['btn-menu']}
              onClick={openMenu}
            >
              <FormattedMessage id="post.actions.select-bulk-action" />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              classes={{ paper: styles.menu }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              data-testid="bulk-post-actions-menu"
              onClose={closeMenu}
            >
              {props.allSelected && [
                props.onBulkShareNext && (
                  <MenuItem key="share-next" onClick={onShareNext}><PlusIcon className={styles.icon} />
                    <FormattedMessage id="post.actions.share-next-all" />
                  </MenuItem>
                ),
                props.withShuffle && (
                  <MenuItem key="suffle" onClick={onShuffle}><ShuffleIcon className={styles.icon} />
                    <FormattedMessage id={`post.actions.shuffle-all${props.splitShuffleAll ? '-for-profile' : ''}`} />
                  </MenuItem>
                ),
                props.withShuffle && props.splitShuffleAll && (
                  <MenuItem key="suffle" onClick={onShuffleAll}><ShuffleIcon className={styles.icon} />
                    <FormattedMessage id="post.actions.shuffle-all-for-all-profiles" />
                  </MenuItem>
                ),
                props.withRecycle && (
                  <MenuItem key="recycle" onClick={onRecycle}>
                    <RecyclerIcon style={{ width: '20px', height: '20px' }} className={styles.icon} />
                    <FormattedMessage id="post.actions.recycle-all" defaultMessage="Recycle all" />
                  </MenuItem>
                ),
                props.withRequeue && (
                  <MenuItem key="requeue" onClick={onRequeue}>
                    {props.bucketMode ? (
                      <Icon path={mdiPailPlus} className={styles.icon} size="20px" />
                    ) : (
                      <ListIcon className={styles.icon} />
                    )}
                    <FormattedMessage id={props.bucketMode ? 'post.buckets.actions.requeue-all' : 'post.actions.requeue-all'} />
                  </MenuItem>
                ),
                props.withCopy && (
                  <MenuItem key="copy" onClick={onCopy}><CopyIcon className={styles.icon} />
                    <FormattedMessage id="post.actions.copy-all" />
                  </MenuItem>
                ),
                props.withDelete && (
                  <ConfirmDialog
                    key="confirm-delete"
                    message={bulkDeleteConfirmMessage}
                    labelOK={props.intl.formatMessage({ id: 'actions.yes' })}
                    labelCancel={props.intl.formatMessage({ id: 'actions.cancel' })}
                  >
                    {(confirm) => (
                      <MenuItem key="delete" onClick={confirm(onDelete)}><DeleteIcon className={styles.icon} />
                        <FormattedMessage id={props.splitDeleteAll ? 'post.actions.delete-all-for-profile' : 'post.actions.delete-all'} />
                      </MenuItem>
                    )}
                  </ConfirmDialog>
                ),
                props.withDelete && props.splitDeleteAll && (
                  <ConfirmDialog
                    key="confirm-delete-all"
                    message={bulkDeleteConfirmMessage}
                    labelOK={props.intl.formatMessage({ id: 'actions.yes' })}
                    labelCancel={props.intl.formatMessage({ id: 'actions.cancel' })}
                  >
                    {(confirm) => (
                      <MenuItem key="delete" onClick={confirm(onDeleteForAll)}><DeleteAllIcon className={styles.icon} />
                        <FormattedMessage id="post.actions.delete-all-for-all-profiles" />
                      </MenuItem>
                    )}
                  </ConfirmDialog>
                )
              ]}
              {!props.allSelected && [
                props.onBulkShareNext && (
                  <MenuItem key="share-next" onClick={onShareNext}><PlusIcon className={styles.icon} />
                    <FormattedMessage id="post.actions.share-next-selected" />
                  </MenuItem>
                ),
                props.withRecycle && (
                  <MenuItem key="recycle" onClick={onRecycle}>
                    <RecyclerIcon style={{ width: '20px', height: '20px' }} className={styles.icon} />
                    <FormattedMessage id="post.actions.recycle-selected" defaultMessage="Recycle selected" />
                  </MenuItem>
                ),
                props.withRequeue && (
                  <MenuItem key="requeue" onClick={onRequeue}>
                    {props.bucketMode ? (
                      <Icon path={mdiPailPlus} className={styles.icon} size="20px" />
                    ) : (
                      <ListIcon className={styles.icon} />
                    )}
                    <FormattedMessage id={props.bucketMode ? 'post.buckets.actions.requeue-selected' : 'post.actions.requeue-selected'} />
                  </MenuItem>
                ),
                props.withCopy && (
                  <MenuItem key="copy" onClick={onCopy}>
                    <CopyIcon className={styles.icon} />
                    <FormattedMessage id="post.actions.copy-selected" />
                  </MenuItem>
                ),
                props.onMoveToTop && (
                  <MenuItem key="move-top" onClick={onMoveToTop}>
                    <ToTopIcon className={styles.icon} />
                    <FormattedMessage id="post.actions.move-top-selected" />
                  </MenuItem>
                ),
                props.onMoveToBottom && (
                  <MenuItem key="move-bottom" onClick={onMoveToBottom}>
                    <ToBottomIcon className={styles.icon} />
                    <FormattedMessage id="post.actions.move-bottom-selected" />
                  </MenuItem>
                ),
                props.withDelete && (
                  <ConfirmDialog
                    key="confirm-delete"
                    message={props.intl.formatMessage({ id: 'post.delete-bulk-confirm' })}
                    labelOK={props.intl.formatMessage({ id: 'actions.yes' })}
                    labelCancel={props.intl.formatMessage({ id: 'actions.cancel' })}
                  >
                    {(confirm) => (
                      <MenuItem key="delete" onClick={confirm(onDelete)}><DeleteIcon className={styles.icon} />
                        <FormattedMessage id="post.actions.delete-selected" defaultMessage="Delete selected" />
                      </MenuItem>
                    )}
                  </ConfirmDialog>
                )
              ]}
            </Menu>
          </div>
          <Checkbox
            color="primary"
            checked={props.allSelected}
            disableRipple
            onChange={props.onToggleAllSelected}
          />
        </React.Fragment>
      )}
    </div>
  )
}

export default injectIntl(BulkPostActions)
