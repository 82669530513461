import { Component, createElement, ReactElement } from 'react'
import { StatusIdea, STATUS_TYPE, FilterType, InteractionEvent, INTERACTION_EVENT_OPEN, CuratedFolder } from 'interfaces'
import { FetchCuratedFolderPageContent } from 'components/Fetch'
import SourcesBricksView, {
  FetchableProps,
  BricksSize
} from 'components/SourcesView/SourcesBricksView'
import { Observable } from 'rxjs/Observable'

export { BricksSize }

export interface ScrollConfiguration {
  element?: Element
  useWindow?: boolean
}

export interface StatusesBricksViewProps {
  folder: CuratedFolder
  items: StatusIdea[]
  seed: string
  align?: 'left' | 'center'
  sizes?: BricksSize[]
  scroll?: ScrollConfiguration
  itemWidth?: number
  startPage?: number
  trackInteraction?(categoryIds: string[], event: InteractionEvent): Promise<any>
  onItemShared?(folder: CuratedFolder, content: StatusIdea): void
}

interface StatusesBricksViewState { }

export class StatusesBricksView extends Component<StatusesBricksViewProps, StatusesBricksViewState> {

  constructor(props: StatusesBricksViewProps) {
    super(props)

    this.createLoadMore = this.createLoadMore.bind(this)
    this.onItemShared = this.onItemShared.bind(this)
    if (props.trackInteraction) {
      props.trackInteraction(props.folder.streams.map(s => s.id.toString()), INTERACTION_EVENT_OPEN)
    }
  }

  onItemShared(content: StatusIdea) {
    if (this.props.onItemShared) {
      this.props.onItemShared(this.props.folder, content)
    }
  }

  createLoadMore(props: FetchableProps): ReactElement<any> {
    const folder = this.props.folder
    const page = (this.props.startPage || 0) + props.page
    return createElement(FetchCuratedFolderPageContent, {
      ...props,
      folder,
      statusesSeed: this.props.seed,
      key: `fetch[${folder.slug}.${page}]`
    })
  }

  render(): ReactElement<any> {
    const { folder, items, align, sizes, scroll, itemWidth } = this.props

    return createElement(SourcesBricksView, {
      filter: STATUS_TYPE,
      items,
      align,
      sizes,
      scroll,
      itemWidth,
      hideFilters: true,
      contentTypes: [STATUS_TYPE],
      sourcesHash: folder.slug + '',
      createLoadMore: this.createLoadMore,

      onFilterChange: (_filter: FilterType) => { /** no-op */ },
      onItemShared: this.onItemShared
    })
  }
}

export default StatusesBricksView
