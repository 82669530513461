import * as React from 'react'
import styles from './InstagramPostPreview.pcss'
import { PostPreviewDefaultHeader } from './PostPreviewDefaultHeader'
import PlayIcon from '@mui/icons-material/PlayArrowRounded'
import { PostPreviewVideoPlayer } from './PostPreviewVideoPlayer'
import PostPreviewText from './PostPreviewText'
import ImageCarousel from 'components/ImageCarousel'
import IconError from '@mui/icons-material/ErrorOutlineRounded'
import { IG_POST_TYPE_REEL, POST_TYPE_STORY } from 'services/compose/state'
import storyCss from './IGStory.pcss'
import Icon from '@mdi/react'
import { mdiImageOutline, mdiDotsHorizontal, mdiMusic, mdiMapMarker } from '@mdi/js'
import { useElementSize } from 'hooks/useElementSize'

const MAX_LINES = 2
const CAROUSEL_WIDTH = 398
const TRUNCATE_TEXT = 'more'
const COLLAPSE_TEXT_LABEL = 'less'
const STORY_ASPECT_RATIO = 0.5625

interface InstagramPostPreviewProps {
  profile?: { name: string, image: string }
  featuredImageUrl?: string
  imageUrls?: string[]
  empty?: boolean
  isLink?: boolean
  statusText?: string
  videoUrl?: string
  videoThumbnailUrl?: string
  comment?: string
  location?: string
  type?: typeof IG_POST_TYPE_REEL | typeof POST_TYPE_STORY
}

export function InstagramPostPreview(props: InstagramPostPreviewProps) {
  const isEmpty = props.isLink || props.empty || (!props.statusText && !props.imageUrls && !props.videoUrl)
  const additionalImages = props.imageUrls ? props.imageUrls.filter(url => url !== props.featuredImageUrl) : []
  const carouselImages = [props.featuredImageUrl].concat(additionalImages).filter(Boolean) as string[]
  const noImgClass = props.imageUrls?.length === 0 || props.isLink ? styles['no-img'] : ''

  const isReel = Boolean(props.videoUrl) && props.type !== POST_TYPE_STORY
  if (isReel || props.type === POST_TYPE_STORY) {
    return (
      <StoryPreview
        isReel={isReel}
        name={props.profile?.name || ''}
        profileImage={props.profile?.image || ''}
        imageUrl={props.featuredImageUrl}
        videoUrl={props.videoUrl}
        text={props.statusText}
        location={props.location}
        videoThumbnailUrl={props.videoThumbnailUrl}
      />
    )
  }

  return (
    <div className={`${styles.post} ${noImgClass} ${isEmpty ? styles.empty : ''}`}>
      <PostPreviewDefaultHeader instagram profile={props.profile} />
      {!props.videoUrl && props.featuredImageUrl && !props.isLink && (
        <ImageCarousel key={props.featuredImageUrl} imageUrls={carouselImages} width={CAROUSEL_WIDTH} />
      )}
      {isEmpty && (
        <div className={styles['img-box']}>
          {props.isLink && <IconError />}
        </div>
      )}
      {props.videoUrl && (
        <PostPreviewVideoPlayer
          videoUrl={props.videoUrl}
          thumbnailUrl={props.videoThumbnailUrl}
          icon={<PlayIcon className={`${styles['icon-centered']} ${styles.icon}`} />}
        />
      )}
      <footer className={`${styles.footer} ${!props.statusText && !props.comment ? styles['text-empty'] : ''}`}>
        <div className={styles.icons}>
          {/* eslint-disable max-len */}
          <svg aria-label="Like" color="rgb(38, 38, 38)" fill="rgb(38, 38, 38)" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Like</title><path d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z"></path></svg>
          <svg aria-label="Comment" color="rgb(38, 38, 38)" fill="rgb(38, 38, 38)" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Comment</title><path d="M20.656 17.008a9.993 9.993 0 1 0-3.59 3.615L22 22Z" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2"></path></svg>
          <svg aria-label="Share Post" color="rgb(38, 38, 38)" fill="rgb(38, 38, 38)" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Share Post</title><line fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2" x1="22" x2="9.218" y1="3" y2="10.083"></line><polygon fill="none" points="11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334" stroke="currentColor" strokeLinejoin="round" strokeWidth="2"></polygon></svg>
          {/* eslint-enable max-len */}
        </div>
        {props.statusText && (
          <PostPreviewText
            text={props.statusText || ''}
            maxLinesDefault={MAX_LINES}
            expandLabel={TRUNCATE_TEXT}
            collapseLabel={COLLAPSE_TEXT_LABEL}
            className={styles['ig-text']}
          />
        )}
        {props.comment && props.profile?.name && (
          <div className={styles.comment}>
            <b>{props.profile.name}</b>
            {props.comment}
          </div>
        )}
      </footer>
    </div>
  )
}

interface StoryPreviewProps {
  profileImage: string
  name: string
  text?: string
  isReel?: boolean
  imageUrl?: string
  videoUrl?: string
  location?: string
  videoThumbnailUrl?: string
  isFacebook?: boolean
}
export function StoryPreview({
  name, profileImage, imageUrl, videoUrl, videoThumbnailUrl, isReel, text, location, isFacebook
}: StoryPreviewProps) {
  const isEmpty = !imageUrl && !videoUrl
  const [imgRef, imgRect] = useElementSize<HTMLImageElement>([])
  const [aspectRatio, setAspectRatio] = React.useState(1)

  React.useEffect(() => {
    if (imgRect.width && imgRect.height) {
      const ratio = imgRect.width / imgRect.height
      // eslint-disable-next-line no-magic-numbers
      setAspectRatio(parseFloat(ratio.toFixed(4)))
    }
  }, [imgRect.width, imgRect.height])

  return (
    <div
      className={`${storyCss.container} ${isEmpty ? storyCss.empty : ''} ${isReel ? storyCss.reel : ''} ${isFacebook ? storyCss.fb : ''}`}
    >
      {!isReel && (
        <header className={storyCss.header}>
          <div className={storyCss.seek}></div>
          <div className={storyCss.profile}>
            <div className={storyCss['profile-img']}><img src={profileImage} /></div>
            <div className={`${storyCss.name} text-ellipsis`}>{name}</div>
            {videoUrl && (
              <div className={storyCss.mute}>
                {/* eslint-disable max-len */}
                <svg aria-label="Audio is muted" color="rgb(255, 255, 255)" fill="rgb(255, 255, 255)" height="16" role="img" viewBox="0 0 48 48" width="16">
                  <title>Audio is muted</title>
                  <path clipRule="evenodd" d="M1.5 13.3c-.8 0-1.5.7-1.5 1.5v18.4c0 .8.7 1.5 1.5 1.5h8.7l12.9 12.9c.9.9 2.5.3 2.5-1v-9.8c0-.4-.2-.8-.4-1.1l-22-22c-.3-.3-.7-.4-1.1-.4h-.6zm46.8 31.4-5.5-5.5C44.9 36.6 48 31.4 48 24c0-11.4-7.2-17.4-7.2-17.4-.6-.6-1.6-.6-2.2 0L37.2 8c-.6.6-.6 1.6 0 2.2 0 0 5.7 5 5.7 13.8 0 5.4-2.1 9.3-3.8 11.6L35.5 32c1.1-1.7 2.3-4.4 2.3-8 0-6.8-4.1-10.3-4.1-10.3-.6-.6-1.6-.6-2.2 0l-1.4 1.4c-.6.6-.6 1.6 0 2.2 0 0 2.6 2 2.6 6.7 0 1.8-.4 3.2-.9 4.3L25.5 22V1.4c0-1.3-1.6-1.9-2.5-1L13.5 10 3.3-.3c-.6-.6-1.5-.6-2.1 0L-.2 1.1c-.6.6-.6 1.5 0 2.1L4 7.6l26.8 26.8 13.9 13.9c.6.6 1.5.6 2.1 0l1.4-1.4c.7-.6.7-1.6.1-2.2z" fillRule="evenodd"></path>
                </svg>
              </div>
            )}
          </div>
        </header>
      )}
      <div className={`${storyCss.content} ${isFacebook && aspectRatio !== STORY_ASPECT_RATIO ? storyCss.transform : ''}`}>
        {isEmpty && (
          <Icon path={mdiImageOutline} size="48px" color="#777" />
        )}
        {imageUrl && <img src={imageUrl} ref={imgRef} data-story-img />}
        {videoUrl && (
          <PostPreviewVideoPlayer
            videoUrl={videoUrl}
            thumbnailUrl={videoThumbnailUrl}
            className={storyCss.video}
            icon={<PlayIcon className={`${styles['icon-centered']} ${styles.icon}`} />}
          />
        )}
      </div>
      {isReel ? (
        <React.Fragment>
          <div className={storyCss.footer}>
            <div className={storyCss.profile}>
              <div className={storyCss['profile-img']}><img src={profileImage} /></div>
              <div className={`${storyCss.name} text-ellipsis`}>{name}</div>
            </div>
            {text && (
              <PostPreviewText
                text={text}
                maxLinesDefault={MAX_LINES}
                expandLabel={TRUNCATE_TEXT}
                collapseLabel={COLLAPSE_TEXT_LABEL}
                className={storyCss.text}
              />
            )}
            <div className={storyCss.bottom}>
              <span>
                <Icon path={mdiMusic} className={storyCss.i} size="16px" color="#FFF" />
                {name}
              </span>
              <span>Original Audio</span>
              {location && <span><Icon path={mdiMapMarker} className={storyCss.i} size="16px" color="#FFF" />{location}</span>}
            </div>
          </div>
          <div className={storyCss.actions}>
            <svg aria-label="Like" color="rgb(38, 38, 38)" fill="rgb(38, 38, 38)" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Like</title><path d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z"></path></svg>
            <svg aria-label="Comment" color="rgb(38, 38, 38)" fill="rgb(38, 38, 38)" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Comment</title><path d="M20.656 17.008a9.993 9.993 0 1 0-3.59 3.615L22 22Z" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2"></path></svg>
            <svg aria-label="Share Post" color="rgb(38, 38, 38)" fill="rgb(38, 38, 38)" height="24" role="img" viewBox="0 0 24 24" width="24"><title>Share Post</title><line fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2" x1="22" x2="9.218" y1="3" y2="10.083"></line><polygon fill="none" points="11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334" stroke="currentColor" strokeLinejoin="round" strokeWidth="2"></polygon></svg>
            <Icon path={mdiDotsHorizontal} size="24px" color="#fff" />
            <div className={storyCss['img-sq']}>
              <img src={profileImage} />
            </div>
          </div>
        </React.Fragment>
      ) : isFacebook ? null : (
        <div className={storyCss.footer}>
          <span className={storyCss.msgbox}>Send message</span>
          <svg className={storyCss.icon} aria-label="Like" color="rgb(245, 245, 245)" fill="rgb(245, 245, 245)" height="24" role="img" viewBox="0 0 24 24" width="24">
            <title>Like</title>
            <path d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z"></path>
          </svg>
          <svg className={storyCss.icon} aria-label="Share Post" color="rgb(245, 245, 245)" fill="rgb(245, 245, 245)" height="24" role="img" viewBox="0 0 24 24" width="24">
            <title>Share Post</title>
            <line fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="2" x1="22" x2="9.218" y1="3" y2="10.083"></line>
            <polygon fill="none" points="11.698 20.334 22 3.001 2 3.001 9.218 10.084 11.698 20.334" stroke="currentColor" strokeLinejoin="round" strokeWidth="2"></polygon>
          </svg>
        </div>
      )}
    </div>
  )
}
